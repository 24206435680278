import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListSubheader from "@mui/material/ListSubheader";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "@mui/material/Select";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import {
  getFormByFormIdReducer,
  updateCompanyInfoReducer,
} from "../../../api/accountManager/forms/slice";
import { goToSpecificCompoHDMAM } from "../../../api/HDMAM/componentTracking/action";
import {adset_demo  as adsetDemoImage} from "../../../assets/icons/icon";
import { back_arrow as BACK} from "../../../assets/icons/icon";
import {arrow_back_nb  as BACK_AR} from "../../../assets/icons/icon";
import{man_give_presentation as  manGivePresentation} from "../../../assets/icons/icon";
import{preview_icon_ad as previewIconAd} from "../../../assets/icons/icon";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import GoToNextCompoButton from "../GoToNextCompoButton";
import { urlValidator } from "../../ClientDashboard/Questions/Validators/urlValidator";
//use this url validator for validation
import MenuRadioList from "./MenuRadioList";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import { uploadFile } from "../../../api/fileHandling/saga";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";
import { mappedAdsByCreative } from "../../../utils/strategyCreationFacebook";
import { CTAFrontendMapping } from "./constants";
import { useGetFBCreativeResources } from "../../../services/creative-resources-fb-ads";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1004,
  bgcolor: "background.paper",
  borderRadius: "20px",
  padding: "46px 47px 42px",
  boxShadow: 24,
};

const findUrl = (facebookAds, id) => {
  let idt = "";
  facebookAds.forEach((ad) => {
    if (ad.requestId === id) {
      idt = ad.imageUrl;
    }
  });
  return idt;
};

const truncateString = (str) => {
  return str?.length > 120 ? str?.substring(0, 120) + "..." : str;
};

const HDMAdset = (props) => { //{setShowAd, adsData, cta, index, updateReducer}
  const dispatch = useDispatch();
  const [adsData, setAdsData] = useState(props.adsData || []);
  const [addNewad, setAddNewad] = useState(false);
  const [editAd, setEditAd] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [currentCreative, setCurrentCreative] = useState("CREATIVE1");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const hiddenFileInput = useRef(null);
  const [createNewad, setcreateNewad] = useState(false);
  const [fileId, setFileId] = useState(null);

  const [primaryText, setPrimaryTextEdit] = useState("");
  const [headlineEdit, setHeadlineEdit] = useState("");
  const [descriptionEdit, setDescriptionEdit] = useState("");
  const [displayUrlEdit, setDisplayUrlEdit] = useState("");
  const [websiteUrlEdit, setWebsiteUrlEdit] = useState("");
  const [mappedCreativeAd, setMappedCreativesAd] = useState({});
  const [tempAds, setTempAds] = useState(null);
  const [addAdsType, setAddAdsType] = useState(null);
  const [maxAdNum, setMaxAdNum] = useState(0);

  // const facebookAds = useSelector(
  //   (state) => state.strategyCreationHdmAm.facebookAds
  // );

  const [facebookAds, setFacebookAds] = useState([]);
  useGetFBCreativeResources(props.formId, {
      onSuccess: (data) => {
          setFacebookAds(data?.ads);
      }
  });

  useEffect(() => {
    setMappedCreativesAd(mappedAdsByCreative(facebookAds));
  }, [facebookAds]);

  useEffect(() => {
    if (props.adsData) {
      setAdsData(props.adsData);
    }
  }, [props]);

  useEffect(() => {
    if (selectedImage) {
      // setImageUrl(URL.createObjectURL(selectedImage));
      let formData = new FormData();
      formData.append("file", selectedImage);
      uploadFile(formData).then((res) => {
        setFileId(res.fileId);
        setImageUrl(
          process.env.REACT_APP_BACKEND_BASE_URL + "/files/" + res.fileId
        );
      });
    }
  }, [selectedImage]);

  useEffect(() => {
    if (!editAd) {
      setEditIndex(null);
      setPrimaryTextEdit("");
      setHeadlineEdit("");
      setDescriptionEdit("");
      setDisplayUrlEdit("");
      setWebsiteUrlEdit("");
      setImageUrl(null);
      setSelectedImage(null);
      setFileId(null);
    }
  }, [editAd]);

  useEffect(() => {
    if (!createNewad) {
      setPrimaryTextEdit("");
      setHeadlineEdit("");
      setDescriptionEdit("");
      setDisplayUrlEdit("");
      setWebsiteUrlEdit("");
      setImageUrl(null);
      setSelectedImage(null);
      setFileId(null);
    }
  }, [createNewad]);

  useEffect(() => {
    setMaxAdNum(maxAddNumber(adsData) + 1);
  }, [adsData]);

  useEffect(() => {
    if (editIndex || editIndex === 0) {
      setPrimaryTextEdit(adsData[editIndex].primaryText);
      setHeadlineEdit(adsData[editIndex].headline);
      setDescriptionEdit(adsData[editIndex].description);
      setDisplayUrlEdit(adsData[editIndex].displayUrl);
      setWebsiteUrlEdit(adsData[editIndex].websiteUrl);
      setFileId(adsData[editIndex].imageUrl);
      setImageUrl(
        process.env.REACT_APP_BACKEND_BASE_URL +
          "/files/" +
          adsData[editIndex].imageUrl
      );
    }
  }, [editIndex]);

  const deleteAdHandler = (idx) => {
    const tempAdsData = [...adsData];
    tempAdsData.splice(idx, 1);
    setAdsData(tempAdsData);
  };

  const editAdHandler = () => {
    const tempAdsData = adsData.map((ad, index) => {
      if (index === editIndex) {
        return {
          ...ad,
          primaryText: primaryText,
          headline: headlineEdit,
          description: descriptionEdit,
          displayUrl: displayUrlEdit,
          websiteUrl: websiteUrlEdit,
          imageUrl: fileId,
        };
      } else {
        return {
          ...ad,
        };
      }
    });
    setAdsData(tempAdsData);
    setEditAd(false);
  };

  const createAdHandler = () => {
    const tempAdsData = [
      ...adsData,
      {
        primaryText: primaryText,
        headline: headlineEdit,
        description: descriptionEdit,
        displayUrl: displayUrlEdit,
        websiteUrl: websiteUrlEdit,
        adName: `Ad ${maxAdNum}`,
        imageUrl: fileId,
        fbPage: props?.pages[0] || {},
      },
    ];
    setAdsData(tempAdsData);
    setcreateNewad(false);
    setAddNewad(false);
  };

  const addAdHandler = () => {
    const tempAdsData = [
      ...adsData,
      {
        primaryText: tempAds.primaryText,
        headline: tempAds.headline,
        description: tempAds.description,
        displayUrl: props?.websiteUrl || '',
        websiteUrl: props?.websiteUrl || '',
        fbPage: props?.pages?.[0] || {},
        adName: `Ad ${maxAdNum}`,
        imageUrl: tempAds.imageUrl,
      },
    ];
    setAdsData(tempAdsData);
    setTempAds(null);
    setAddAdsType(null);
    setAddNewad(false);
    setCurrentCreative("CREATIVE1");
  };

  const changeFbPageHandler = (idx, value) => {
    const tempAdsData = adsData.map((ad, index) => {
      if(idx === index){
        return {
          ...ad,
          fbPage: value
        }
      }else{
        return {
          ...ad
        }
      }
    });
    setAdsData(tempAdsData);
  }

  const onConfirmClickHandler = () => {
    props.updateReducer(adsData, "facebookAds");
    props.setShowAd(false);
  };

  const handleUploadFile = (event) => {
    hiddenFileInput.current.click();
  };

  const maxAddNumber = (data) => {
    return data.reduce((max, curr) => {
      const currMax = parseInt(curr.adName.slice(3));
      return currMax > max ? parseInt(currMax) : parseInt(max);
      }, 0);
  };

  let settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    dots: false,
  };

  console.log('pages:', props.pages);

  return (
    <>
      <div className="hd_strategy_layout"
        style={{
            // paddingBottom: "200px",
            // overflowY: "scroll"
        }}
      >
        <div className="hd_strategy_head" style={{ display: "flex", justifyContent: "end" }}>
          <div className="hd_strategy_button_row" >
            {/* <PrimaryButton type="errorLight" className="edit_response_button" title="Clear All Ads" icon="clear"></PrimaryButton> */}
            <PrimaryButton
              type="light"
              className="edit_response_button"
              title="+ Add New Ad"
              onClick={() => setAddNewad(!addNewad)}
            ></PrimaryButton>
          </div>
        </div>
        <div className="hd_strategy_sections flex-center-space"
         style={{
             paddingBottom: "270px",
             height: "100vh",
             overflowY: "scroll"
         }}
        >
          <div className="hd_strategy_column">
            <div className="adset_slider">
              <Slider {...settings}>
                {adsData.map((adData, idx) => {
                  return (
                    <div className="adset_slide" key={idx + "1"}>
                      <div className="adset_slide_block">
                        <div className="adset_slide_head">
                          <h3>{adData.adName || "AD " + (idx + 1)}</h3>
                        </div>
                        <div className="adset_slide_head_skip">
                          <p>{adData.primaryText}</p>
                        </div>
                        <div className="adset_slide_image">
                          <img
                            src={
                              process.env.REACT_APP_BACKEND_BASE_URL +
                              "/files/" +
                              adData?.imageUrl
                            }
                          />
                        </div>
                        <div
                          className="adset_slide_footer flex-center-space"
                          style={{
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                        >
                          <div className="adset_slide_footer_info">
                            <h2>{adData.displayUrl}</h2>
                            <p>{adData.headline}</p>
                            <span>{adData.description}</span>
                          </div>
                          <div className="adset_slide_footer_action">
                            <a
                              href="javascript:;"
                              className="adset_book_button"
                            >
                              {CTAFrontendMapping[props?.cta]}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          background: "#F5F5F5",
                          border: "1.16348px solid #F0F0F0",
                          borderRadius: "0px 0px 9.30787px 9.30787px",
                          padding: "10px 12px",
                          gap: "7px",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "12px",
                            lineHeight: "15px",
                            color: "#333333",
                          }}
                        >
                          FB Page :
                        </Typography>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          value={adData.fbPage?.name || (props.pages?.[0] || {}).name || ""}
                          renderValue={(selected) => selected}
                          variant="outlined"
                          placeholder="Enter Text"
                          onChange={(e) => changeFbPageHandler(idx, e.target.value)}
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          }}
                          style={{
                            width: "80%",
                            height: 27,
                            background: "#FFFFFF",
                            border: "1px solid #DDDDDD",
                            borderRadius: 8,
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: 12,
                            outline: "none",
                            // color: selectedOption === "Enter Text" ? "#666666" : "#0869FB",
                          }}
                        >
                          {(props?.pages || []).map((page, indx) => (
                            <MenuItem key={indx} value={page}>
                              <FormControlLabel
                                value={page.name}
                                control={<BpRadio />}
                                label={<Typography>{page.name}</Typography>}
                                checked={page.name === adData.fbPage?.name}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div className="adset_slide_action_row flex-center-center">
                        <button
                          className="edit_button"
                          onClick={() => {
                            setEditIndex(idx);
                            setEditAd(!editAd);
                          }}
                        ></button>
                        <button
                          className="delete_button"
                          onClick={() => deleteAdHandler(idx)}
                        ></button>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="preview_footer_row">
        <div className="footer_con" style={{ justifyContent: "space-between" }}>
          <div className="hd_strategy_drop_head flex-center-space">
            <button
              style={{ background: "none", border: "transparent", cursor: "pointer" }}
              onClick={() => props.setShowAd(false)}
            >
              <img src={BACK} alt="" />
            </button>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                lineHeight: "19px",
                fontWeight: "600",
                marginLeft: "19px",
              }}
            >
              Ad Set {props.adsetNumber}
            </p>
           </div>
          <PrimaryButton title="Confirm" onClick={onConfirmClickHandler} />
        </div>
      </div>
      {/* Add new Ad */}
      {addNewad && (
        <Modal
          open={addNewad}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="large_addad_popup"
        >
          <Box sx={style}>
            <div className="addad_popup_layout">
              <div className="addad_modal_header">
                <p>Add Ad</p>
                <a
                  className="popup_close_button"
                  onClick={() => setAddNewad(!addNewad)}
                >
                  <span></span>
                </a>
              </div>
              <div className="addad_modal_layout">
                <div className="hd_drop_create">
                  <div className="addad_modal_dropdown">
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      value={currentCreative}
                      onChange={(e) => setCurrentCreative(e.target.value)}
                      renderValue={(selected) => selected}
                    >
                      {Object.entries(mappedCreativeAd)
                        .filter(([key, value]) => value.length !== 0)
                        .map(([key, value], index) => (
                          <MenuItem
                            key={index}
                            value={key}
                            className="drop_custom_list_row popup_drop_list"
                          >
                            <div className="drop_custom_list">
                              <div>
                                <Radio
                                  value={key}
                                  checked={
                                    key === currentCreative ? true : false
                                  }
                                />
                                <p>Creative {index + 1}</p>
                              </div>
                              <span>
                                <img
                                  src={
                                    process.env.REACT_APP_BACKEND_BASE_URL +
                                    "/files/" +
                                    findUrl(facebookAds, value[0].requestId)
                                  }
                                  style={{ width: "50px", height: "50px" }}
                                />
                              </span>
                            </div>
                          </MenuItem>
                        ))}
                    </Select>
                  </div>

                  <div className="hd_strategy_button_row hd_dis_bttn">
                    {/* <PrimaryButton type="errorLight" className="edit_response_button" title="Clear All Ads" icon="clear"></PrimaryButton> */}
                    <PrimaryButton
                      type="light"
                      className="edit_response_button"
                      title="Create New Ad"
                      onClick={() => {
                        setAddNewad(false);
                        setcreateNewad(true);
                      }}
                    ></PrimaryButton>
                  </div>
                </div>

                <div className="addad_modal_slider">
                  <div className="adset_slider">
                    <Slider {...settings}>
                      {mappedCreativeAd[currentCreative] &&
                        mappedCreativeAd[currentCreative].map(
                          (creative, index) => (
                            <div className="adset_slide" key={index}>
                              <div className="adset_slide_block">
                                <div className="adset_slide_head">
                                  <h3>Ad {index + 1}</h3>
                                </div>
                                <div className="adset_slide_head_skip">
                                  <Tooltip title={creative.primaryText} arrow>
                                    <p>
                                      {truncateString(creative.primaryText)}
                                    </p>
                                  </Tooltip>
                                </div>
                                <div className="adset_slide_image">
                                  <img
                                    src={
                                      process.env.REACT_APP_BACKEND_BASE_URL +
                                      "/files/" +
                                      creative.imageUrl
                                    }
                                  />
                                </div>
                                <div className="adset_slide_footer flex-center-space">
                                  <div className="adset_slide_footer_info">
                                    <h2>
                                      {props?.websiteUrl}
                                    </h2>
                                    <p>{creative.headline}</p>
                                    <span>{creative.description}</span>
                                  </div>
                                  <div className="adset_slide_footer_action">
                                    <a
                                      href="javascript:;"
                                      className="adset_book_button"
                                    >
                                      {CTAFrontendMapping[props.cta]}
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="adset_slide_action_row flex-center-center">
                                <div className="addad_action_row">
                                  <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    className="radio_group_popup"
                                    name="controlled-radio-buttons-group"
                                    onChange={(e) => {
                                      setAddAdsType(e.target.value);
                                      setTempAds(creative);
                                    }}
                                  >
                                    <FormControlLabel
                                      className="addad_lable_radio"
                                      value="ADD_TO_THIS_ADSET"
                                      control={
                                        <Radio className="addad_radio_action" />
                                      }
                                      label="Add to this adset"
                                    />
                              { /* <FormControlLabel
                                      className="addad_lable_radio"
                                      value="ADD_TO_ALL_ADSET"
                                      control={
                                        <Radio className="addad_radio_action" />
                                      }
                                      label="Add to all adsets"
                                    /> */}
                                  </RadioGroup>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="addad_modal_footer">
                <div className="addad_row_footer">
                  <button
                    className="cancle_button_modal"
                    onClick={() => setAddNewad(!addNewad)}
                  >
                    Cancel
                  </button>
                  <PrimaryButton
                    className="edit_response_button"
                    title="Save Changes"
                    onClick={addAdHandler}
                  ></PrimaryButton>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {/*Create new ad*/}
      {createNewad && (
        <Modal
          open={createNewad}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="large_addad_popup"
        >
          <Box sx={style}>
            <div className="addad_popup_layout">
              <div className="addad_modal_header">
                <button
                  style={{
                    background: "none",
                    border: "transparent",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    // dispatch(goToSpecificCompoHDMAM(lastActiveCompAM))
                    setAddNewad(true);
                    setcreateNewad(false);
                  }}
                >
                  <img src={BACK_AR} alt="" />
                  <p style={{ marginLeft: "15.05px" }}>Create New Ad</p>
                </button>
                <a
                  className="popup_close_button"
                  onClick={() => {
                    setAddNewad(false);
                    setcreateNewad(false);
                  }}
                >
                  <span></span>
                </a>
              </div>
              <div className="addad_modal_layout">
                <div className="addad_modal_sections">
                  <div className="addad_modal_preview">
                    <div className="ad_preview_title">
                      <p>Ad {maxAdNum} </p>
                    </div>
                    <div className="adset_slide_head_skip">
                      <p>{primaryText}</p>
                    </div>
                    <div className="ad_preview_image">
                      <img src={imageUrl ? imageUrl : adsetDemoImage} />
                      <div className="preview_ad_upload">
                        <input
                          ref={hiddenFileInput}
                          accept="image/*"
                          type="file"
                          id="select-image"
                          style={{ display: "none" }}
                          onChange={(e) => setSelectedImage(e.target.files[0])}
                        />
                        <label htmlFor="select-image">
                          <PrimaryButton
                            className="edit_response_button"
                            onClick={handleUploadFile}
                            title="Upload New Image"
                            icon="camera"
                          ></PrimaryButton>
                        </label>
                      </div>
                    </div>
                    <div className="adset_slide_footer flex-center-space">
                      <div className="adset_slide_footer_info">
                        <h2>{displayUrlEdit}</h2>
                        <p>{headlineEdit}</p>
                        <span>{descriptionEdit}</span>
                      </div>
                      <div className="adset_slide_footer_action">
                        <a href="javascript:;" className="adset_book_button">
                          {CTAFrontendMapping[props.cta]}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="addad_modal_edit_options">
                    <form>
                      <div className="form_row_edit">
                        <label>Primary Text</label>
                        <textarea
                          className="large_textarea"
                          style={{
                            height: "68px",
                          }}
                          value={primaryText}
                          onChange={(e) => setPrimaryTextEdit(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form_row_edit">
                        <label>Headline</label>
                        <input
                          type="text"
                          value={headlineEdit}
                          onChange={(e) => setHeadlineEdit(e.target.value)}
                        />
                      </div>
                      <div className="form_row_edit">
                        <label>Description</label>
                        <textarea
                          style={{
                            height: "68px",
                          }}
                          value={descriptionEdit}
                          onChange={(e) => setDescriptionEdit(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form_row_edit">
                        <label>Website URL</label>
                        <input
                          type="text"
                          value={websiteUrlEdit}
                          onChange={(e) => setWebsiteUrlEdit(e.target.value)}
                        />
                      </div>
                      <div className="form_row_edit">
                        <label>Display Link</label>
                        <input
                          type="text"
                          value={displayUrlEdit}
                          onChange={(e) => setDisplayUrlEdit(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="addad_modal_footer">
                <div className="addad_row_footer">
                  <button
                    className="cancle_button_modal"
                    onClick={() => {
                      setAddNewad(false);
                      setcreateNewad(false);
                    }}
                  >
                    Cancel
                  </button>
                  <PrimaryButton
                    className="edit_response_button"
                    title="Save Changes"
                    disabled={!urlValidator(websiteUrlEdit)}
                    onClick={createAdHandler}
                  ></PrimaryButton>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
      {/*Edit/delete ad */}
      {editAd && (
        <Modal
          open={editAd}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="large_addad_popup"
        >
          <Box sx={style}>
            <div className="addad_popup_layout">
              <div className="addad_modal_header">
                <p>Edit Ad</p>
                <a
                  className="popup_close_button"
                  onClick={() => setEditAd(!editAd)}
                >
                  <span></span>
                </a>
              </div>
              <div className="addad_modal_layout">
                <div className="addad_modal_sections">
                  <div className="addad_modal_preview">
                    <div className="ad_preview_title">
                      <p>
                        {adsData[editIndex].adName || "AD" + (editIndex + 1)}
                      </p>
                    </div>
                    <div className="adset_slide_head_skip">
                      <p>{primaryText}</p>
                    </div>
                    <div className="ad_preview_image">
                      <img src={imageUrl ? imageUrl : adsetDemoImage} />
                      <div className="preview_ad_upload">
                        <input
                          ref={hiddenFileInput}
                          accept="image/*"
                          type="file"
                          id="select-image"
                          style={{ display: "none" }}
                          onChange={(e) => setSelectedImage(e.target.files[0])}
                        />
                        <label htmlFor="select-image">
                          <PrimaryButton
                            className="edit_response_button"
                            onClick={handleUploadFile}
                            title="Upload New Image"
                            icon="camera"
                          ></PrimaryButton>
                        </label>
                      </div>
                    </div>
                    <div className="adset_slide_footer flex-center-space">
                      <div className="adset_slide_footer_info">
                        <h2>{displayUrlEdit}</h2>
                        <p>{headlineEdit}</p>
                        <span>{descriptionEdit}</span>
                      </div>
                      <div className="adset_slide_footer_action">
                        <a href="javascript:;" className="adset_book_button">
                          {CTAFrontendMapping[props.cta]}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="addad_modal_edit_options">
                    <form>
                      <div className="form_row_edit">
                        <label>Primary Text</label>
                        <textarea
                          className="large_textarea"
                          style={{
                            height: "68px",
                          }}
                          value={primaryText}
                          onChange={(e) => setPrimaryTextEdit(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form_row_edit">
                        <label>Headline</label>
                        <input
                          type="text"
                          value={headlineEdit}
                          onChange={(e) => setHeadlineEdit(e.target.value)}
                        />
                      </div>
                      <div className="form_row_edit">
                        <label>Description</label>
                        <textarea
                          style={{
                            height: "68px",
                          }}
                          value={descriptionEdit}
                          onChange={(e) => setDescriptionEdit(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="form_row_edit">
                        <label>Website URL</label>
                        <input
                          type="text"
                          value={websiteUrlEdit}
                          onChange={(e) => setWebsiteUrlEdit(e.target.value)}
                        />
                      </div>
                      <div className="form_row_edit">
                        <label>Display Link</label>
                        <input
                          type="text"
                          value={displayUrlEdit}
                          onChange={(e) => setDisplayUrlEdit(e.target.value)}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="addad_modal_footer">
                <div className="addad_row_footer">
                  <button
                    className="cancle_button_modal"
                    onClick={() => setEditAd(!editAd)}
                  >
                    Cancel
                  </button>
                  <PrimaryButton
                    className="edit_response_button"
                    title="Save Changes"
                    onClick={() => editAdHandler()}
                    disabled={!urlValidator(websiteUrlEdit)}
                  ></PrimaryButton>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

const creativeModal = [
  { creative: "Creative 1", preview: previewIconAd },
  { creative: "Creative 2", preview: previewIconAd },
  { creative: "Creative 3", preview: previewIconAd },
  { creative: "Creative 4", preview: previewIconAd },
  { creative: "Creative 5", preview: previewIconAd },
];

const audienceDropdown = {
  PAGE: [
    { group: "PAGE", name: "Audience name 1", reach: "123,345,231" },
    { group: "PAGE", name: "Audience name 2", reach: "123,345,2" },
    { group: "PAGE", name: "long Audience names", reach: "123,345,2" },
    { group: "PAGE", name: "Audience", reach: "123,345,2" },
  ],
  AUDIENCE: [
    { group: "AUDIENCE", name: "Audience name 1", reach: "123,345,231" },
    { group: "AUDIENCE", name: "Audience name 2", reach: "123,345,2" },
    { group: "AUDIENCE", name: "long Audience names", reach: "123,345,2" },
    { group: "AUDIENCE", name: "Audience", reach: "123,345,2" },
  ],
};

export default HDMAdset;
