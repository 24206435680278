import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
// import { countries } from '../mappedQuestion';
import { sourcesOfLeads } from '../HDMMappedQuestion';
import { updateHDMCustomerBaseCountriesField } from '../../../../api/HDMClient/action';
const MyChip = (props) => {
  return (
    <div className='custom_chips'>
      <span>{props.label}</span>
      <span className='close_tag' onClick={props.onDelete}></span>
    </div>
  );
};
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
const LocationAutocomplete = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateHDMCustomerBaseCountriesField(props.selectedTags));
  }, [dispatch, props.selectedTags]);

  //   if (selectedValues && !selectedValues.length) selectedValues = undefined;
  return (
    <Autocomplete
      multiple
      id='checkboxes-tags'
      options={sourcesOfLeads}
      defaultValue={props.selectedTags}
      onChange={(event, values) => {
        props.setSelectedTags(values);
      }}
      renderTags={(tagValue, getTagProps) => {
        console.log('Tag Value : ' + tagValue);
        return tagValue.map((option, index) => (
          <MyChip {...getTagProps({ index })} label={option} />
        ));
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option, inputValue, { insideWords: true });
        const parts = parse(option, matches);
        return (
          <li {...props} className='search_location_list'>
            <div className='location_list_content'>
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? '#0869FB' : '#333333',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
              {/* <span>{option.reach}</span> */}
            </div>
          </li>
        );
      }}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <div className='location_question_header'>
          <span className='search_icon'></span>
          <TextField
            {...params}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder={props.placeholder}
            variant='standard'
            onChange={(e) =>
              sourcesOfLeads.filter((country) =>
                country.includes(e.target.value)
              )
            }
          />
        </div>
      )}
      disablePortal={true}
      className='auto_location_search'
    />
  );
};

export default LocationAutocomplete;
