import { array, number, object, string, preprocess } from 'zod';

export const campaignTypeFormSchema = object({
  campaignName: string(),
  objective: string(),
  networks: array(string()).optional().default([]),
  budget: preprocess(value => Number(value), number()),
  bidding: string(),
  maxCpcBidLimit: preprocess(value => Number(value), number()).optional(),
  targetCpa: preprocess(value => Number(value), number()).optional(),
  selectTargetRoas: preprocess(value => Number(value), number()).optional(),
  conversionGoals: array(string()).optional().default([]),
  languages: array(object({
    language: string(), 
    languageConstant: number()
  })),
  locations: array(object({
    resourceName: string(),
    name: string(),
    title: string(),
  })),
});