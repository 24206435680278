import React from "react";
import { DateFormatter, UnitDataFormatter, GetColor, TooltipContent, transFormComparisonData, isDate, CustomizedLabel } from "./ChartUtils";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { displayCurrency } from "./ChartUtils";

export default function LineBarContainer({ data, comparedData, chartStyle, type }) {
  let useDateFormatter = false;
  const xField = data.dimensions?.[0]
  useDateFormatter = isDate(data.data[0][xField])

  const leftMetricUnit = data.leftMetrics.reduce((prev, curr) => prev === curr.unit ? prev : null, data.leftMetrics[0]?.unit)
  const rightMetricUnit = data.rightMetrics.reduce((prev, curr) => prev === curr.unit ? prev : null, data.rightMetrics[0]?.unit)

  const { sourceData, leftMetrics, rightMetrics } = transFormComparisonData(data, comparedData);

  const legendStyle = { wrapperStyle: { paddingLeft: "10px" }, layout: "horizontal", verticalAlign: "bottom", align: "center" }

  switch (chartStyle.legendPosition) {
    case "left":
    case "right":
      legendStyle.layout = "vertical";
      legendStyle.verticalAlign = "middle";
      legendStyle.align = chartStyle.legendPosition;
      break;
    case "top":
    case "bottom":
      legendStyle.layout = "horizontal";
      legendStyle.verticalAlign = chartStyle.legendPosition;
      legendStyle.align = "center";
      break;
  }
  const dataTypeToShowCurrency = displayCurrency(type);
  let currencyMapping = new Map();
  data.leftMetrics.concat(data.rightMetrics).forEach(d => {
    currencyMapping.set(d.name, d.currency && d.unit.toUpperCase() === dataTypeToShowCurrency ? d.currency : null);
    currencyMapping.set("X-" + d.name, d.currency && d.unit.toUpperCase() === dataTypeToShowCurrency ? d.currency : null);
  });

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        data={sourceData}
        margin={{
          top: 50,
          right: 0,
          bottom: 10,
          left: 0,
        }}
      >
        <XAxis
          tickFormatter={useDateFormatter && DateFormatter}
          dataKey={xField}
          tickLine={false}
          style={{ fontFamily: "Inter", fontSize: "12px" }}
        />
        {data.leftMetrics?.length > 0 && (
          <YAxis
            tickFormatter={v => UnitDataFormatter(v, leftMetricUnit)}
            tickLine={false}
            yAxisId="left"
            style={{ fontFamily: "Inter", fontSize: "12px" }}
          />
        )}
        {data.rightMetrics?.length > 0 && (
          <YAxis
            tickFormatter={v => UnitDataFormatter(v, rightMetricUnit)}
            tickLine={false}
            yAxisId="right"
            orientation="right"
            style={{ fontFamily: "Inter", fontSize: "12px" }}
          />
        )}
        <Tooltip content={(event) => TooltipContent(event, xField, useDateFormatter, currencyMapping)} />
        <Legend formatter={(value) => <span style={{ fontFamily: chartStyle.legendStyle.fontStyle, fontSize: chartStyle.legendStyle.fontSize }}>{currencyMapping.get(value) ? `${value} (${currencyMapping.get(value)})` : value}</span>} wrapperStyle={legendStyle.wrapperStyle} layout={legendStyle.layout} verticalAlign={legendStyle.verticalAlign} align={legendStyle.align} />
        {data.leftMetrics?.map((metric, index) => (
          <Bar
            key={index}
            type="monotone"
            yAxisId="left"
            dataKey={metric.name}
            unit={metric.unit}
            fill={GetColor(index, chartStyle.palette)}
            label={chartStyle.seriesStyle?.dataPoints
              ? <CustomizedLabel fill={GetColor(index, chartStyle.palette)} unit={leftMetricUnit} />
              : null
            }
          />
        ))}
        {leftMetrics?.map((metric, index) => (
          <Bar
            key={metric.id}
            type="monotone"
            yAxisId="left"
            dataKey={metric.name}
            unit={metric.unit}
            fill={GetColor(index, chartStyle.palette)}
            stroke={GetColor(index, chartStyle.palette)}
            strokeDasharray="2 2"
            fillOpacity="0.5"
            legendType="none"
            label={chartStyle.seriesStyle?.dataPoints
              ? <CustomizedLabel fill={`${GetColor(index, chartStyle.palette)}CC`} unit={leftMetricUnit} />
              : null
            }
          />
        ))}
        {data.rightMetrics?.map((metric, index) => (
          <Line
            key={index + data.leftMetrics?.length}
            type="monotone"
            yAxisId="right"
            dataKey={metric.name}
            unit={metric.unit}
            stroke="black"
            dot={false}
            strokeWidth={1.5}
            label={chartStyle.seriesStyle?.dataPoints
              ? <CustomizedLabel fill={"black"} unit={rightMetricUnit} />
              : null
            }
          />
        ))}
        {rightMetrics?.map((metric, index) => (
          <Line
            key={metric.id}
            type="monotone"
            yAxisId="right"
            dataKey={metric.name}
            unit={metric.unit}
            stroke="black"
            dot={false}
            strokeWidth={1.5}
            strokeDasharray={"5 5"}
            legendType="none"
            label={chartStyle.seriesStyle?.dataPoints
              ? <CustomizedLabel fill={`#000000CC`} unit={rightMetricUnit} />
              : null
            }
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
