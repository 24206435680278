import React from 'react';
import { Typography } from '@material-ui/core';
import lockIcon from '../assets/lock-icon.svg';
const UnlockButton = ({
}) => {
  return (
    <div className='unlock-button'>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 12,
          lineHeight: '20px',
          color: '#0869FB',
        }}
      >
        Click to Unlock
      </Typography>
      <div className='lock-icon-image-wrapper'>
        <img src={lockIcon} alt='' />
      </div>
    </div>
  );
};

export default UnlockButton;
