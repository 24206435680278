export const specialCharValidator = (text) => {
  let specialCharRegex = /^[A-Za-z0-9 ]+$/;
  if (text.length === 0) {
    return false;
  } else if (specialCharRegex.test(text)) {
    return false;
  } else {
    return true;
  }
};
