import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import { Auth0Provider } from "@auth0/auth0-react";
import { CookiesProvider } from 'react-cookie';

import Hotjar from "@hotjar/browser";
import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";

const siteId = process.env.REACT_APP_HOTJAR_SITE_ID;
const hotjarVersion = process.env.REACT_APP_HOTJAR_VERSION;

Hotjar.init(siteId, hotjarVersion);

const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ["bam.eu01.nr-data.net"] },
  }, // NREUM.init
  info: {
    beacon: "bam.eu01.nr-data.net",
    errorBeacon: "bam.eu01.nr-data.net",
    licenseKey: "NRJS-681981dacd4560c633f",
    applicationID: "538467368",
    sa: 1,
  }, // NREUM.info
  loader_config: {
    accountID: "3898929",
    trustKey: "3898929",
    agentID: "538467368",
    licenseKey: "NRJS-681981dacd4560c633f",
    applicationID: "538467368",
  }, // NREUM.loader_config
};

new BrowserAgent(options);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID}
        cacheLocation="localstorage"
        useRefreshTokens={true}
        authorizationParams={{
            redirect_uri: process.env.REACT_APP_BASE_URL,
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: process.env.REACT_APP_AUTH0_SCOPE
        }}>
        <Provider store={store}>
            <PersistGate loading={<>Loading...</>} persistor={persistor}>
              <CookiesProvider>
                <App/>
              </CookiesProvider>
            </PersistGate>
        </Provider>
    </Auth0Provider>
);

reportWebVitals();
