// const roles = {
//   ACCOUNT_MANAGER: "ACCOUNT_MANAGER",
//   CLIENT: "CLIENT",
//   SUPER_ADMIN: "SUPERADMIN",
//   HDM_CLIENT: "CLIENT",
//   HDM_ACCOUNT_MANAGER: "ACCOUNT_MANAGER",
//   HDM_SUPER_ADMIN: "SUPERADMIN",
// };
const AGENCY_ONBOARDING = "agency_onboarding";
const roles = {
  ACCOUNT_MANAGER: "agency-viewer",
  CLIENT: "brand-admin",
  SUPER_ADMIN: "agency-admin",
  HDM_CLIENT: "brand-admin",
  HDM_ACCOUNT_MANAGER: "agency-viewer",
  HDM_SUPER_ADMIN: "agency-admin",
  BP_ACCOUNT_MANAGER: "agency-viewer",
  BP_CLIENT: "brand-admin",
  BP_SUPER_ADMIN: "agency-admin",
};

export const permissions = {
  LOGIN_REDIRECT_ADMIN_TABLE: `${AGENCY_ONBOARDING}:login_redirect:admin_table`,
  LOGIN_REDIRECT_HOME_PAGE: `${AGENCY_ONBOARDING}:login_redirect:home_page`,
  LOGIN_REDIRECT_BRAND_PAGE: `${AGENCY_ONBOARDING}:login_redirect:brand_page`,
  HOME_PAGE: `${AGENCY_ONBOARDING}:home_page`,
  ADMIN_FLOW: `${AGENCY_ONBOARDING}:admin_flow`,
  BRAND_PAGE: `${AGENCY_ONBOARDING}:brand_page`,
  CLIENT_ONBOARDING_INPUTS: `${AGENCY_ONBOARDING}:client_onboarding_inputs`,
  PRICING: `${AGENCY_ONBOARDING}:pricing`,
  ACCESS_SHARING: `${AGENCY_ONBOARDING}:access_sharing`,
  AUDIT_GOOGLE: `${AGENCY_ONBOARDING}:audit_google`,
  AUDIT_FACEBOOK: `${AGENCY_ONBOARDING}:audit_facebook`,
  PROPOSAL_DOCUMENT: `${AGENCY_ONBOARDING}:proposal:proposal_document`,
  PROPOSAL_TAB: `${AGENCY_ONBOARDING}:proposal:proposal_tab`,
  PROPOSAL_CHANGES_SUGGESTED: `${AGENCY_ONBOARDING}:proposal:proposal_tab:changes_suggested`,
  PROPOSAL_PUBLISH: `${AGENCY_ONBOARDING}:proposal:proposal_publish`,
  PROPOSAL_DOWNLOAD: `${AGENCY_ONBOARDING}:proposal:proposal_download`,
  PROPOSAL_BACK: `${AGENCY_ONBOARDING}:proposal:proposal_back`,
  PROPOSAL_ACCEPT_CHECKBOX: `${AGENCY_ONBOARDING}:proposal:proposal_accept_checkbox`,
  PROPOSAL_CONFIRM: `${AGENCY_ONBOARDING}:proposal:proposal_confirm`,
  PROPOSAL_TAB_AUDIT: `${AGENCY_ONBOARDING}:proposal:proposal_tab:audit_edit_view`,
  PROPOSAL_TAB_AUDIT_EDIT_VIEW: `${AGENCY_ONBOARDING}:proposal:proposal_tab:audit_edit_view`,
  REVIEW_INTAKE_FORM: `${AGENCY_ONBOARDING}:review_intake_form`,
  INTAKE_FORM: `${AGENCY_ONBOARDING}:intake_form`,
  STRATEGY_CREATION_GOOGLE: `${AGENCY_ONBOARDING}:strategy_creation_google`,
  STRATEGY_CREATION_FACEBOOK: `${AGENCY_ONBOARDING}:strategy_creation_google`,
  STRATEGY_DECK_PUBLISH: `${AGENCY_ONBOARDING}:strategy_deck:publish`,
  STRATEGY_DECK_CROSS_BUTTON: `${AGENCY_ONBOARDING}:strategy_deck:cross_button`,
  STRATEGY_DECK_PROCEED: `${AGENCY_ONBOARDING}:strategy_deck:proceed`,
  CAMPAIGN_CREATION_GOOGLE: `${AGENCY_ONBOARDING}:campaign_creation_google`,
  CAMPAIGN_CREATION_FACEBOOK: `${AGENCY_ONBOARDING}:campaign_creation_facebook`,
  CAMPAIGN_PREVIEW: `${AGENCY_ONBOARDING}:campaign_preview`,
  CREATIVE_APPROVAL: `${AGENCY_ONBOARDING}:creative_approval`,
  CREATIVE_APPROVAL_APPROVE: `${AGENCY_ONBOARDING}:creative_approval:approve`,
  CREATIVE_APPROVAL_DISAPPROVE: `${AGENCY_ONBOARDING}:creative_approval:disapprove`,
  CREATIVE_APPROVAL_ADD_NEW_AB_TEST: `${AGENCY_ONBOARDING}:creative_approval:add_new_ab_test`,
  CREATIVE_APPROVAL_SAVE_THEME: `${AGENCY_ONBOARDING}:creative_approval:save_theme`,
  CREATIVE_APPROVAL_SAVE_AND_PROCEED: `${AGENCY_ONBOARDING}:creative_approval:save_and_proceed`,
  ANALYTICS_DASHBOARD: `${AGENCY_ONBOARDING}:analytics_dashboard`,
};
export const permissionAccess = {
  VIEW: ":view",
  EXECUTE: ":execute",
};
export const convertingPermissionStringToViewExecute = (permission) => {
  const splitDelimiter = permission.split(":");
  return [
    splitDelimiter.slice(0, splitDelimiter.length - 1).join(":"),
    splitDelimiter[splitDelimiter.length - 1],
  ];
};

export default roles;
