export const clientObjKeys = {
  onboardingInfo: {
    status: 'PENDING',
    clientAccess: {
      status: 'PENDING',
      businessName: '',
      primaryContactPerson: '',
      // facebookPartenerRequestSendContactPerson: false,
      fbPageUrl: '',
      // facebookPartenerRequestSendPageURL: false,
      websiteUrl: '',
      shopifyStoryAvailable: null,
      platforms: [],
      preferredEmailAddress: '',
      preferredPhoneNumber: '',
      streetAddress: '',
      city: '',
      provinceOrState: '',
      postalCode: '',
      country: ''
    },
    brandMessaging: {
      status: 'PENDING',
      customerBaseCountries: [],
      esthetic: '',
      tagline: '',
      emotionalResponse: '',
      feelWithBrand: '',
      engageWithBrand: '',
      uniqueSellingPoint: '',
      whatSetsYouApart: '',
      benefitsOfProducts: [],
      painPoints: [],
      storyToPromote: '',
      brandMessagingExampels: [],
      brandFont: '',
      brandColor: '',
      logoLink: '',
    },
    facebookTargeting: {
      status: 'PENDING',
      locationOptions: [],
      interestsOptions: [],
      minAgeRange: 0,
      maxAgeRange: 0,
      gender: '',
      advertisingLocations: [],
      activitiesInterest: [],
      excludes: '',
      formalCustomerPersonas: [],
      otherCharacteristics: '',
    },
    googleTargeting: {
      ageRanges: [],
      minAge: '',
      maxAge: '',
      geoTargets: [],
      gender: '',
      activitiesInterest: '',
      excludeAudiences: '',
      customerIncomeRange: [],
      customerEmployment: [],
    },
    aboutBusiness: {
      status: 'PENDING',
      topProducts: [''],
      productandservice: [
        {
          name: '',
          description: '',
        },
      ],
      noPromotionProduct: '',
      offers: '',
      averageOrderValue: '',
      cogsPerAov: '',
      shippingCostPerAov: '',
      breakEvenPoint: '',
      fbCamaignBudget: '',
      googleCamaignBudget: '',
      tiktokCamaignBudget: '',
      countryTargeted: [],
      brandDescription: '',
      keyCompetitors: [
        {
          name: '',
          website: '',
        },
      ],
      brandsInterests: ['', '', '', ''],
      commonJargons: '',
      monthlyRevenue: '',
      monthlyRevenueGoal3Months: '',
      monthlyRevenueGoal6Months: '',
      monthlyRevenueGoal1Year: '',
      maximizeEnterpriseValueProfit: '',
      requirementsForUs: '',
      dobHomeAddress: '',
      runMontageAds: true
    },
    creativeAndMontageAds: {
      status: 'PENDING',
      videoReviews: '',
      googleDriveEmail: '',
      googleDriveLink: '',
      pocForProductDeliveries: '',
      products: '',
      creatorPersona: '',
      shootNotes: '',
      desiredCta: '',
      promoCode: '',
      uspOfProductsSent: '',
      visualReferences: '',
      others: '',
    },
  },
  accessSharing: {
    status: 'PENDING',
    fbAccess: {
      page: { status: 'VERIFYING', accountId: '' },
      adAccount: { status: 'VERIFYING', accountId: '' },
      catalog: { status: 'VERIFYING', accountId: '' },
      pixel: { status: 'VERIFYING', accountId: '' },
    },
    googleAccess: {
      googleAnalytics: { status: 'VERIFYING', accountId: '' },
      googleAdAccount: { status: 'VERIFYING', accountId: '' },
      googleMerchant: { status: 'VERIFYING', accountId: '' },
      googleAndShopifyLinked: { status: 'VERIFYING', accountId: '' },
    },
    tiktokAccess: {
      tiktokAdsManager: { status: 'VERIFYING', accountId: '' },
      tiktokPixel: { status: 'VERIFYING', accountId: '' },
    },
    shopifyAccess: {
      fbSalesChannel: { status: 'VERIFYING', accountId: '' },
      tiktokSalesChannel: { status: 'VERIFYING', accountId: '' },
    },
  },
  auditInfo: {
    status: 'PENDING',
    accountAuditInfo: {
      status: 'PENDING',
      currentMonthlyRevenue: '',
      spend: '',
      historicalAvgPlatformRoas: '',
      historicalAvgBlendedRoas: '',
    },
  },
  accountAnalysis: {
    status: 'PENDING',
    google: '',
    tiktok: '',
    website: '',
    creatives: '',
    competitor: '',
  },
  fbAdsStrategyInfo: {
    status: 'PENDING',
    topOfTheFunnelLookalike: {
      status: 'PENDING',
      budget: 0,
      adsets: [],
    },
    topOfTheFunnelInterest: {
      status: 'PENDING',
      budget: 0,
      adsets: [],
    },
    middleOfTheFunnel: {
      status: 'PENDING',
      budget: 0,
      adsets: [],
    },
    bottomOfTheFunnel: {
      status: 'PENDING',
      budget: 0,
      adsets: [],
    },
  },
  strategyIterations: [],
  published: false,
  proposalStatus: 'PENDING',
  agencyId: 2,
};

export const HDM_CLIENT_STATUS_SAVE_DB = {
  [6]: {
    parentKey: 'onboardingInfo',
    childKey: 'clientAccess',
  },
  [18]: {
    parentKey: 'onboardingInfo',
    childKey: 'brandMessaging',
  },
  [25]: {
    parentKey: 'onboardingInfo',
    childKey: 'facebookTargeting',
  },
  [34]: {
    parentKey: 'onboardingInfo',
    childKey: 'googleTargeting',
  },
  [53]: {
    parentKey: 'onboardingInfo',
    childKey: 'aboutBusiness',
  },
  [66]: {
    parentKey: 'onboardingInfo',
    childKey: 'creativeAndMontageAds',
  },
};

export const GET_CLIENT_ACCESS = 'GET_CLIENT_ACCESS';
export const GET_BRAND_OR_MESSAGING = 'GET_BRAND_OR_MESSAGING';
export const GET_TARGET_AUDIENCE = 'GET_TARGET_AUDIENCE';
export const GOOGLE_TARGETING = 'GOOGLE_TARGETING';
export const GET_ABOUT_YOUR_BUSSINESS = 'GET_ABOUT_YOUR_BUSSINESS';
export const GET_CREATIVE_AND_MONTAGE_AD = 'GET_CREATIVE_AND_MONTAGE_AD';
export const GET_ACCESS_ACCOUNTID = 'GET_ACCESS_ACCOUNTID';

export const mappedReduxType = {
  clientAccess: GET_CLIENT_ACCESS,
  brandMessaging: GET_BRAND_OR_MESSAGING,
  facebookTargeting: GET_TARGET_AUDIENCE,
  googleTargeting: GOOGLE_TARGETING,
  aboutBusiness: GET_ABOUT_YOUR_BUSSINESS,
  creativeAndMontageAds: GET_CREATIVE_AND_MONTAGE_AD,
  accessSharing: GET_ACCESS_ACCOUNTID,
};

export const SAGA_UPDATE_HDM_CLIENT_DETAILS = 'SAGA_UPDATE_HDM_CLIENT_DETAILS';
export const GET_HDM_CLIENT_DETAILS_BY_FORM_ID =
  'GET_HDM_CLIENT_DETAILS_BY_FORM_ID';
export const UPDATE_WHOLE_CLIENT_FORMID = 'UPDATE_WHOLE_CLIENT_FORMID';
export const ADD_NEW_FIELD_IN_TOP_PRODUCTS = 'ADD_NEW_FIELD_IN_TOP_PRODUCTS';
export const UPDATE_HDM_ARRAY_PLATFORMS_CHECKBOX =
  'UPDATE_HDM_ARRAY_PLATFORMS_CHECKBOX';
export const SET_VALUE_IN_PLATFORMS_CHECKBOX =
  'SET_VALUE_IN_PLATFORMS_CHECKBOX';

export const UPDATE_STRATEGY_DECK = 'UPDATE_STRATEGY_DECK';
export const UPDATE_CITY_SUGGESTIONS_HDM = 'UPDATE_CITY_SUGGESTIONS_HDM';
export const APPROVE_STRATEGY_DECK_WITH_DETAILS =
  'APPROVE_STRATEGY_DECK_WITH_DETAILS';
