import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import GeneratePdf from '../../Common/GeneratePdf';
import AGENDA from '../../../assets/images/Onboarding/agenda-icon.svg';
import ASSET from '../../../assets/images/Onboarding/asset-icon.svg';
import { fb_icon as FB } from '../../../assets/icons/social/social';
import { google2 as GOOGLE } from '../../../assets/icons/social/social';
import TIKTOK from '../../../assets/icons/social/tiktok-icon.svg';
import SHOP from '../../../assets/icons/social/shopify-icon.svg';
import './onboard.css';
import trackingOnboarding from '../../../assets/images/TrackingStrategy.svg';
import AssetForYou from '../../../assets/images/Onboarding/assets-for-you.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateHDMCompoReduxClient,
  goToSpecificCompoHDMClient,
} from '../../../api/HDMClient/componentTracking/action';
import { generatePath, useNavigate } from 'react-router';
import hdmClientRoutePaths from '../../../pages/hdm-client/constants/hdmClientRoutePaths';

import Line from './line.svg';
const OnboardHDM = () => {
  const accountid = useSelector((state) => state.hdmClient.accessSharing);
  const navigate = useNavigate();
  return (
    <div style={{ width: '100%' }}>
      <div className='proposal_layout proposal_flex_top '>
        <div className='preview_head'>
          <h4>PROPOSAL</h4>
          <h2>Horizons Digital Marketing </h2>
          {/* <img src="./test.svg" /> */}
        </div>
      </div>
      <div className='preview_footer_row'>
        <div className='back_button'>
          {' '}
          <Button
            style={{ color: '#0869FB' }}
            onClick={() => navigate(generatePath(`${hdmClientRoutePaths.homePage.fullbase}`))}
          >
            BACK{' '}
          </Button>
        </div>
        <div className='footer_content'>
          <Button
            variant='contained'
            onClick={() => {
              navigate(generatePath(`${hdmClientRoutePaths.onboardingDoc.fullbase}/${hdmClientRoutePaths.onboardingDoc.sub.reviewBriefingDoc}`));
            }}
          >
            PROCEED
          </Button>
        </div>
      </div>
      <div className='hero12'></div>
      <div>
        <Typography
          style={{
            fontFamily: 'Lato',
            fontWeight: '700',
            color: '#000000',
            fontSize: '25px',
            lineHeight: '30px',
            marginLeft: '54px',
            marginTop: '44px',
            marginBottom: '72px',
          }}
        >
          Before we get Started
        </Typography>
        <div
          style={{
            position: 'relative',
          }}
        >
          <div
            style={{
              borderTop: '1px solid #5296FC',
              position: 'absolute',
              top: 19,
              width: '90%',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          ></div>
          <div className='onboarding-doc__before-started'>
            <div className='onboarding-doc_duration'>
              <div
                style={{
                  position: 'relative',
                }}
              >
                Sales Call
                <img
                  src={Line}
                  alt=''
                  style={{
                    position: 'absolute',
                    top: '36px',
                    left: '42px',
                  }}
                />
              </div>

              <div
                style={{
                  position: 'relative',
                }}
              >
                Now
                <img
                  src={Line}
                  alt=''
                  style={{
                    position: 'absolute',
                    top: '36px',
                    left: '26px',
                  }}
                />
              </div>
              <div
                style={{
                  position: 'relative',
                }}
              >
                Day 1-7
                <img
                  src={Line}
                  alt=''
                  style={{
                    position: 'absolute',
                    top: '36px',
                    left: '40px',
                  }}
                />
              </div>

              <div
                style={{
                  position: 'relative',
                }}
              >
                Day 7-10
                <img
                  src={Line}
                  alt=''
                  style={{
                    position: 'absolute',
                    top: '36px',
                    left: '42px',
                  }}
                />
              </div>
              <div style={{ position: 'relative' }}>
                Day 10
                <img
                  src={Line}
                  alt=''
                  style={{
                    position: 'absolute',
                    top: '36px',
                    left: '30px',
                  }}
                />
              </div>
            </div>
            <div className='onboarding-doc_steps'>
              <div>Call</div>
              <div>Onboarding Call</div>
              <div>We Build out the System</div>
              <div>Strategy Call</div>
              <div>Ads Live</div>
            </div>
          </div>
        </div>
      </div>
      <div className='scroll_items'>
        {/* <div className='phase'>
                <Typography style={{fontFamily:'Lato',fontWeight:'700', color:'#000000',fontSize:'30px',lineHeight:'36px',marginLeft:'52px'}}>Onboarding Phases</Typography>
                <img style={{height:"491.34px", width:'100%'}} src="../../../assets/images/phase.png" alt="" />
                
            </div> */}

        <div className='agenda'>
          <div className='agenda_head'>
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato',
                fontSize: '35.5237px',
                fontWeight: '700',
                lineHeight: '46px',
                margin: '0px',
              }}
            >
              <img
                style={{ position: 'relative', top: '15px' }}
                src={AGENDA}
                alt=''
              />{' '}
              Onboarding Agenda
            </h3>
          </div>
          <div>
            <Grid container xs={12}>
              <Grid item xs={3}>
                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      01
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    Asset Connection
                  </span>
                </div>

                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      02
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    Review Briefing Doc
                  </span>
                </div>

                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      03
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    High Level Strategy Review
                  </span>
                </div>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid item xs={3}>
                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      04
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    Expectations
                  </span>
                </div>

                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      05
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    Next Steps
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className='assets'>
          <div className='asset-head'>
            <h3
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato',
                fontSize: '35.5237px',
                fontWeight: '700',
                lineHeight: '46px',
                margin: '0px',
              }}
            >
              <img
                style={{ position: 'relative', top: '15px' }}
                src={ASSET}
                alt=''
              />{' '}
              Asset Connection
            </h3>
          </div>
          <div className='grid_home_page_hdm'>
            <div className='grid_colum_hdm'>
              <div className='grid_account_column_hdm'>
                <div className='social_list_home_hdm'>
                  <img src={FB} alt='' />
                  <p className='social_name_title_hdm'>Facebook</p>
                </div>

                <div className='social_media'>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ backgroundColor: '#F0F3FF' }}>
                            <b>Asset</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#F0F3FF' }}>
                            <b>Connected?(Yes/No)</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <div className='asset_title'>Page</div>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'flex-end',
                                color: ['VERIFYING', 'ACCESS_REVOKED'].includes(
                                  accountid.fbAccess.page.status
                                )
                                  ? '#F8862D'
                                  : '#10C8B1',
                              }}
                            >
                              {accountid.fbAccess.page.status === 'VERIFYING' ||
                              accountid.fbAccess.page.status ===
                                'ACCESS_REVOKED'
                                ? 'NO'
                                : 'YES'}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className='asset_title'>Ad Account </div>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'flex-end',
                                color: ['VERIFYING', 'ACCESS_REVOKED'].includes(
                                  accountid.fbAccess.adAccount.status
                                )
                                  ? '#F8862D'
                                  : '#10C8B1',
                              }}
                            >
                              {accountid.fbAccess.adAccount.status ===
                                'VERIFYING' ||
                              accountid.fbAccess.adAccount.status ===
                                'ACCESS_REVOKED'
                                ? 'NO'
                                : 'YES'}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className='asset_title'>Catalog</div>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'flex-end',
                                color: ['VERIFYING', 'ACCESS_REVOKED'].includes(
                                  accountid.fbAccess.catalog.status
                                )
                                  ? '#F8862D'
                                  : '#10C8B1',
                              }}
                            >
                              {accountid.fbAccess.catalog.status ===
                                'VERIFYING' ||
                              accountid.fbAccess.catalog.status ===
                                'ACCESS_REVOKED'
                                ? 'NO'
                                : 'YES'}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className='asset_title'>Pixel</div>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'flex-end',
                                color: ['VERIFYING', 'ACCESS_REVOKED'].includes(
                                  accountid.fbAccess.pixel.status
                                )
                                  ? '#F8862D'
                                  : '#10C8B1',
                              }}
                            >
                              {accountid.fbAccess.pixel.status ===
                                'VERIFYING' ||
                              accountid.fbAccess.pixel.status ===
                                'ACCESS_REVOKED'
                                ? 'NO'
                                : 'YES'}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
            <div className='grid_colum_hdm'>
              <div className='grid_account_column_hdm'>
                <div className='social_list_home_hdm'>
                  <img src={GOOGLE} alt='' />
                  <p className='social_name_title_hdm'>Google</p>
                </div>

                <div className='social_media'>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ backgroundColor: '#F0F3FF' }}>
                            <b>Asset</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#F0F3FF' }}>
                            <b>Connected?(Yes/No)</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <div className='asset_title'>Google Analytics</div>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'flex-end',
                                color: ['VERIFYING', 'ACCESS_REVOKED'].includes(
                                  accountid.googleAccess.googleAnalytics.status
                                )
                                  ? '#F8862D'
                                  : '#10C8B1',
                              }}
                            >
                              {accountid.googleAccess.googleAnalytics.status ===
                                'VERIFYING' ||
                              accountid.googleAccess.googleAnalytics.status ===
                                'ACCESS_REVOKED'
                                ? 'NO'
                                : 'YES'}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className='asset_title'>Google Ad Account</div>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'flex-end',
                                color: ['VERIFYING', 'ACCESS_REVOKED'].includes(
                                  accountid.googleAccess.googleAdAccount.status
                                )
                                  ? '#F8862D'
                                  : '#10C8B1',
                              }}
                            >
                              {accountid.googleAccess.googleAdAccount.status ===
                                'VERIFYING' ||
                              accountid.googleAccess.googleAdAccount.status ===
                                'ACCESS_REVOKED'
                                ? 'NO'
                                : 'YES'}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className='asset_title'>Google Merchant </div>
                          </TableCell>
                          <TableCell>
                            {' '}
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'flex-end',
                                color: ['VERIFYING', 'ACCESS_REVOKED'].includes(
                                  accountid.googleAccess.googleMerchant.status
                                )
                                  ? '#F8862D'
                                  : '#10C8B1',
                              }}
                            >
                              {accountid.googleAccess.googleMerchant.status ===
                                'VERIFYING' ||
                              accountid.googleAccess.googleMerchant.status ===
                                'ACCESS_REVOKED'
                                ? 'NO'
                                : 'YES'}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className='asset_title'>
                              Google & Shopify Linked{' '}
                            </div>
                          </TableCell>
                          <TableCell>
                            {' '}
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'flex-end',
                                color: ['VERIFYING', 'ACCESS_REVOKED'].includes(
                                  accountid.googleAccess.googleAndShopifyLinked
                                    .status
                                )
                                  ? '#F8862D'
                                  : '#10C8B1',
                              }}
                            >
                              {accountid.googleAccess.googleAndShopifyLinked
                                .status === 'VERIFYING' ||
                              accountid.googleAccess.googleAndShopifyLinked
                                .status === 'ACCESS_REVOKED'
                                ? 'NO'
                                : 'YES'}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
          <div className='grid_home_page_hdm'>
            <div
              className='grid_colum_hdm'
              style={{
                width: 'calc(48% - 8px)',
              }}
            >
              <div className='grid_account_column_hdm'>
                <div className='social_list_home_hdm'>
                  <img src={TIKTOK} alt='' />
                  <p className='social_name_title_hdm'>TikTok</p>
                </div>

                <div className='social_media'>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ backgroundColor: '#F0F3FF' }}>
                            <b>Asset</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: '#F0F3FF' }}>
                            <b>Connected?(Yes/No)</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <div className='asset_title'>Ad Account </div>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                fontFamily: 'Inter',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                display: 'flex',
                                alignItems: 'flex-end',
                                color: ['VERIFYING', 'ACCESS_REVOKED'].includes(
                                  accountid.fbAccess.adAccount.status
                                )
                                  ? '#F8862D'
                                  : '#10C8B1',
                              }}
                            >
                              {accountid.fbAccess.adAccount.status ===
                                'VERIFYING' ||
                              accountid.fbAccess.adAccount.status ===
                                'ACCESS_REVOKED'
                                ? 'NO'
                                : 'YES'}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
          {/*
            <div className="grid_colum_hdm">
              <div className="grid_account_column_hdm">
                <div className="social_list_home_hdm">
                  <img src={SHOP} alt="" />
                  <p className="social_name_title_hdm">Shopify</p>
                </div>

                <div className="social_media">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ backgroundColor: "#F0F3FF" }}>
                            <b>Asset</b>
                          </TableCell>
                          <TableCell style={{ backgroundColor: "#F0F3FF" }}>
                            <b>Connected?(Yes/No)</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <div className="asset_title">
                              Facebook sales channel{" "}
                            </div>
                          </TableCell>
                          <TableCell>
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: 14,
                                display: "flex",
                                alignItems: "flex-end",
                                color: "#F8862D",
                              }}
                            >
                              {accountid.shopifyAccess.fbSalesChannel.status ===
                                "VERIFYING" ||
                              accountid.shopifyAccess.fbSalesChannel.status ===
                                "ACCESS_REVOKED"
                                ? "NO"
                                : "YES"}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <div className="asset_title">
                              TikTok Sales Channel{" "}
                            </div>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <span
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: 400,
                                fontSize: 14,
                                display: "flex",
                                alignItems: "flex-end",
                                color: "#F8862D",
                              }}
                            >
                              {accountid.shopifyAccess.tiktokSalesChannel
                                .status === "VERIFYING" ||
                              accountid.shopifyAccess.tiktokSalesChannel
                                .status === "ACCESS_REVOKED"
                                ? "NO"
                                : "YES"}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>*/}
        </div>
        <div className='agenda'>
          <div className='asset-for-you-head'>
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato',
                fontSize: '35.5237px',
                fontWeight: '700',
                lineHeight: '46px',
                margin: '0px',
              }}
            >
              <img
                style={{ position: 'relative', top: '15px' }}
                src={AssetForYou}
                alt=''
              />{' '}
              Assets for you
            </h3>
          </div>
          <div>
            <Grid container xs={12}>
              <Grid item xs={3}>
                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      01
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    Agency Analytics
                  </span>
                </div>

                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      02
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    Ad Approval Doc
                  </span>
                </div>

                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      03
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    Slack Channel
                  </span>
                </div>
              </Grid>
              <Grid xs={1}></Grid>
              <Grid item xs={3}>
                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      04
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    Google Drive
                  </span>
                </div>

                <div className='list'>
                  <div className='circle'>
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: '400',
                        lineHeight: '28px',
                        color: '#FFFFFF',
                      }}
                    >
                      05
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: '500',
                      fontSize: '16px',
                      lineHeight: '28px',
                      color: '#000000',
                    }}
                  >
                    My Email
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OnboardHDM;
