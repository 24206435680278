import { Box, Modal, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';
import "./CommonModal.scss";
import "./CreateCampaignOrSegementSelectionModal.scss";
export { ALL_OPTIONS, PMAX_OPTIONS } from "./utils";

export default function CreateCampaignOrSegmentSelctionModal({
  open,
  onClose,
  options,
  onConfirm,
  disabled = true,
  defaultOption = options[0]
}) {
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const onSelect = (option) => () => {
    setSelectedOption(option);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box className="create-cs-modal c-modal">
        <div className='create-cs-modal__content'>
          <RadioGroup className="create-cs-modal__radio-group" name="select-creation-type" value={selectedOption?.id || ""}>
            {options.map(option => (
              <button className='create-cs-modal__card' key={option.id} onClick={onSelect(option)}>
                <div className='create-cs-modal__card-title'>
                  <Radio checked={selectedOption?.id === option.id} className='create-cs-modal__radio' /> {option.label}
                </div>
                {option.icon}
              </button>
            ))}
          </RadioGroup>
        </div>
        <footer className='c-modal__footer'>
          <button type="button" className='c-modal__button c-modal__plain-button' onClick={onClose}>Close</button>
          <button
            type="button"
            className='c-modal__button c-modal__cta-button'
            onClick={() =>{
              console.log("control")
              onConfirm(selectedOption.label)
            } 
          }
            disabled={disabled || !selectedOption}
          >
            Confirm
          </button>
        </footer>
      </Box>
    </Modal>
  )
}
