import { useEffect, useState } from "react";
import { usePutAdAccountforAccountsList } from "../services/adAccount";
import { useNavigate } from "react-router-dom";

export default function useGoogleAuthConnection(redirectionUrl) {
  //   console.log("useGoogleAuthConnection Called....");
  const [payloadFromGoogleAuth, setPayloadFromaGoogleAuth] = useState(null);
  const [accountListModal, openAccountListModal] = useState(false);
  const [selectAccounts, openSelectAccounts] = useState(false);
  const [accountSelected, setAccountSelected] = useState("");
  const [warningModal, setWarningModal] = useState(false);
  const [enableAllSelectedAccounts, setEnableAllSelectedAccounts] =
    useState(false);
  const [adAccountLocallyList, setAdAccountLocallyList] = useState([]);
  const onProceedCallback = () => {
    setEnableAllSelectedAccounts(true);
    openSelectAccounts(true);
    setWarningModal(false);
  };
  const onGoBackCallback = () => {
    openSelectAccounts(true);
  };
  const [brandSelected, setBrandSelected] = useState({
    brandId: "",
    sub: "",
    brandName: "",
  });
  const navigate = useNavigate();
  const {
    mutate: mutateAdAccount,
    data: adAccountList,
    isLoading: isLoadingAdAccounts,
  } = usePutAdAccountforAccountsList();
  useEffect(() => {
    // debugger;
    const querySearchParams = new URLSearchParams(
      window.location.href.split("?")[1]
    );
    if (querySearchParams) {
      const payload = JSON.parse(
        decodeURI(querySearchParams.get("state"))
          .split("#_=_")[0]
          .replace("%26", "&")
      );
      const error = querySearchParams.get("error");
      if (payload && !error) {
        setPayloadFromaGoogleAuth(payload);
        mutateAdAccount(
          {
            brandId: payload.brandId,
            platform: payload.platform,
            fields: {
              authCode: querySearchParams.get("code"),
              sub: payload.sub,
              redirectUrl: `${process.env.REACT_APP_BASE_URL}/${redirectionUrl}`,
            },
          },
          {
            onSuccess: () => {
              openSelectAccounts(true);
            },
          }
        );
        // navigate("/ld-am/brands");
        navigate(``);
      } else {
        if (error) {
          //   navigate("/ld-am/brands");
          navigate(``);
        }
      }
    }
  }, [mutateAdAccount, navigate, redirectionUrl]);
  return {
    payloadFromGoogleAuth,
    setPayloadFromaGoogleAuth,
    accountListModal,
    openAccountListModal,
    selectAccounts,
    openSelectAccounts,
    accountSelected,
    setAccountSelected,
    warningModal,
    setWarningModal,
    enableAllSelectedAccounts,
    setEnableAllSelectedAccounts,
    adAccountLocallyList,
    setAdAccountLocallyList,
    onProceedCallback,
    onGoBackCallback,
    adAccountList,
    isLoadingAdAccounts,
    brandSelected,
    setBrandSelected,
  };
}
