import {
  Autocomplete,
  Box,
  InputAdornment,
  TextField,
} from "@mui/material";
import React from "react";

export default function SegmentBox({ channel, segments, segmentRef }) {
  const [segment, setSegment] = React.useState(segmentRef.current);

  React.useEffect(() => {
    segmentRef.current = segment;
  }, [segment]);

  const isArray = segments[0].values;

  const SegmentListElement = ({ options, value, onChange, title }) => {
    return (
      <Autocomplete
        sx={{ m: 1, width: 300 }}
        style={{ marginBottom: 8, marginRight: 20 }}
        options={options}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.group}
        value={options.find(op => op.id === value?.id)}
        onChange={onChange}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => {
          if (params.inputProps.value) {
            params.InputProps.startAdornment = (
              <InputAdornment position="start">
                <img height="20px" width="20px" src={channel.icon} alt={channel.title} />
              </InputAdornment>
            );
            params.label = title ?? channel.title;
          } else {
            params.label = title ?? "New segment";
          }
          return <TextField {...params} size="small" />
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        marginTop: "20px",
        padding: "10px",
        paddingTop: "5px",
        border: "1px solid #DDDDDD",
        borderRadius: "4px",
      }}>
      <p>{channel.properties?.segmentBoxTitle ?? "Segments"}</p>
      {isArray ?
        <div style={{ display: "flex" }}>
          {segments.map((s, index) =>
            <SegmentListElement
              key={index}
              options={s.values}
              value={segment?.[index]}
              title={s.name}
              onChange={(event, newValue) => {
                const newSegments = Array.from(segment);
                newSegments[index] = newValue;
                setSegment(newSegments);
              }}
            />
          )}
        </div>
        :
        <SegmentListElement options={segments} value={segment} onChange={(event, newValue) => setSegment(newValue)} />
      }
    </Box>
  );
}
