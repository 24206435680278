import { Checkbox, Icon, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
export const StyledAccountContainer = styled("div")(
  ({ isAccountLinked }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 12px;
  margin-bottom: 12px;
  opacity:${isAccountLinked ? "0.5" : "1"}
`
);
export const StyledAccountDetailCheckBoxContainer = styled("div")`
  display: flex;
  align-items: center;
  gap: 12px;
`;
export const StyledAccountDetails = styled("div")`
  display: flex;
  flex-direction: column;
`;
export const StyledAccountName = styled(Typography)`
  color: #2e3044;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
export const StyledAccountId = styled(Typography)`
  color: #6d6e7c;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
export const StyledStatusLabel = styled("div")(
  ({ bgColor, textColor }) => `
     border-radius: 6px;
     padding: 4px 8px;
     background: ${bgColor};
     color: ${textColor};
     font-family: Inter;
     font-size: 11.5px;
     font-style: normal;
     font-weight: 400;
     line-height: 20px;
  `
);
export const StyledAlreadyConnectedText = styled(Typography)`
  color: #6d6e7c;
  /* text-align: right; */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
