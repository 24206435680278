import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "../api";
import { getLocalStorage } from "../api/localStorage/localStorage";

export const getAudiencesQueryKey = (fbAccountId) => [
  "facebook-ads/audiences?accountId",
  fbAccountId,
];
export const useGetAudiences = (fbAccountId, options = {}) => {
  const formId = getLocalStorage("formId");
  return useQuery(
    getAudiencesQueryKey(fbAccountId),
    async () => {
      const response = await api.get(
        `/facebook-ads/audiences/${formId}?ad_account_id=` + fbAccountId
      );
      return response.data;
    },
    options
  );
};

export const useSearchInterestKeywords = (options = {}) => {
  const formId = getLocalStorage("formId");
  return useMutation(async ({ searchString }) => {
    const response = await api.get(
      `/facebook-ads/interests-keywords/${formId}?interest_keyword=` +
        searchString
    );
    return response.data;
  }, options);
};
