import { UPDATE_CURRENT_COMPONENT_AM } from "./enum";

export const updateCompoReduxAM = () => {
  return {
    type: UPDATE_CURRENT_COMPONENT_AM,
  };
};

export const goBackAmCompo = () => {
  return {
    type: "GO_BACK_AM_COMPO",
  };
};

export const goToSpecificCompoClient = (payload) => {
  return {
    type: "GO_TO_SPECIFIC_COMPO_AM",
    payload: payload,
  };
};

export const goToSpecificCompoClientAm = (payload) => {
  return {
    type: "GO_TO_SPECIFIC_COMPO_AM",
    payload: payload,
  };
};

export const updateTab = (payload) => {
  return {
    type: "UPDATE_TAB_AM",
    payload: payload,
  };
};

export const updateSideBar = (payload) => {
  return {
    type: "UPDATE_SIDE_BAR_AM",
    payload: payload,
  };
};

export const goToReviewQuestionierAM = (payload) => {
  return {
    type: "REVIEW_QUESTIONIER_AM",
    payload: payload,
  };
};

export const goBackReviewQuestionierAM = (payload) => {
  return {
    type: "GO_BACK_REVIEW_QUESTIONIER_AM",
    payload: payload,
  };
};

export const openClientView = () => {
  return {
    type: "OPEN_CLIENT_VIEW",
  };
};

export const closeClientView = () => {
  return {
    type: "CLOSE_CLIENT_VIEW",
  };
};
