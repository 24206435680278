import React from 'react';
import { Modal, Typography, Box } from '@mui/material';
import {preloader as loaderGif} from '../../../assets/icons/readaccess/readaccess';
const LoaderModal = () => {
  const [confirmModal, setConfirmModal] = React.useState(true);
  const handleConfirmClose = () => setConfirmModal(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 450,
    boxShadow: 24,
    minHeight: '200px',
    background: '#FFFFFF',
    borderRadius: 1.75,
    padding: '1.5rem',
    outline: 'none',
  };
  return (
    <Modal
      open={confirmModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClose={handleConfirmClose}
    >
      <Box sx={style}>
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={loaderGif} alt='' width={120} height={120} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent:'center',
              gap: '0.25rem',
              //   width: '50%',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 28,
                color: '#333333',
              }}
            >
              Please Wait!
            </Typography>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                color: '#727272',
                width: '15rem',
                lineHeight: 1.5,
                textAlign:'center'
              }}
            >
              Crawling through the account to find the pain points..
            </Typography>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default LoaderModal;
