import verizonLogo from "../../../assets/icons/HDMAccount/verioznLogo.png";
import "./SelectBrand.css";
import {facebook_icon  as Facebook} from "../../../assets/icons/HDMAccount/HDMAccount";
import {google_icon as Google} from "../../../assets/icons/HDMAccount/HDMAccount";
import {network_icon as Network }from "../../../assets/icons/HDMAccount/HDMAccount";
import Shopify from "../../../assets/icons/HDMAccount/ShopifyIcon.png";
import { useEffect, useState } from "react";
import { getHDMForms } from "../../../api/HDMClient/saga";
import { setLocalStorage } from "../../../api/localStorage/localStorage";
import { useDispatch } from "react-redux";
import { updateCompoReduxHDMAM } from "../../../api/HDMAM/componentTracking/action";
import { Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { generatePath, useNavigate } from "react-router-dom";
import hdmAmRoutePaths from "../../../pages/hdm-am/constants/hdmAmRoutePaths";
import PleaseWaitLoaderModal from '../../modals/PleaseWaitLoaderModal';

const SelectBrand = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState([]);
  const [id, setId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [brandName, setBrandName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const getStatusTitle = (form) => {
    let title = "";
    let classes = "";
    if (["PENDING", "ONGOING"].includes(form?.onboardingInfo?.status)) {
      title = "Onboarding: In Progress";
      classes = "review";
    }
    if (form?.onboardingInfo?.status === "COMPLETED") {
      title = "Onboarding: Completed";
      classes = "progress";
    }
    if (form.proposalIteration?.length) {
      title = "Proposal: In Review";
      classes = "review";
      if (
        form.proposalIterations[form.proposalIterations.length - 1].state ===
        "APPROVED"
      ) {
        title = "Proposal: Accepted";
        classes = "progress";
      }
    }
    if (form?.strategyIterations?.length) {
      if (
        ["GENERATED", "CHANGES_APPLIED"].includes(
          form.strategyIterations[form.strategyIterations.length - 1].state
        )
      ) {
        title = "Strategy Acceptance: In Review";
        classes = "review";
      }
      if (
        form.strategyIterations[form.strategyIterations.length - 1].state ===
        "CHANGES_SUGGESTED"
      ) {
        title = "Strategy Acceptance: Changes Suggested";
        classes = "review";
      }
      if (
        form.strategyIterations[form.strategyIterations.length - 1].state ===
        "APPROVED"
      ) {
        title = "Strategy Acceptance: Accepted";
        classes = "progress";
      }
    }

    return [title, classes];
  };

  useEffect(() => {
    getHDMForms().then((res) => {
      setFile(res.values);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);
  return (
    <div className="brand_selection_layout">
      <PleaseWaitLoaderModal open={isLoading} />
      <div className="brand_selection_header">
        <h4>Hello</h4>
        <p>Please Select the brand that you want to work with</p>
      </div>
      <div className="preview_footer_row">
        <div className="footer_content">
          <Button
            variant="contained"
            onClick={() => {
              setLocalStorage("formId", id);
              setLocalStorage("brandId", brandId);
              setLocalStorage("brandName", brandName);
              navigate(generatePath(`${hdmAmRoutePaths.onboardingForm.fullbase}`, {formId: id}));
            }}
          >
            PROCEED
          </Button>
        </div>
      </div>

      <div className="brand_selection_body">
        <div className="selection_inner_layout success">
          {file.map((e) => {
            const [title, classes] = getStatusTitle(e);
            return (
              <div
                className={`selection_brand_block ${
                  id == e._id ? `selected ${classes}` : classes
                }`}
                onClick={() => {
                  setId(e._id);
                  setBrandId(e.brandId);
                  setBrandName(e.brandName);
                }}
                style={{ border: id == e._id ? "1px solid blue" : "" }}
              >
                <div className="brand_blocks_head">
                  <div
                    className="block_brand_logo"
                    // style={{ position: "absolute", top: 12, left: 24 }}
                  >
                    <Avatar
                      className="brand_avatar"
                      alt={e.brandName || ""}
                      src={"avatar.jpg"}
                    />
                  </div>
                  {/* {e.onboardingInfo.status == "PENDING" || e.onboardingInfo.status.length === 0 ? (
                    <span
                      className="mainContentVerizon"
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 12,
                        color: "#F99F35",
                        background: " #FEF3EA",
                        borderRadius: 5,
                      }}
                    >
                      Onboarding Form : PENDING
                    </span>
                  ) : (
                    <span
                      className="mainContentVerizon"
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 12,
                        color: "#0869FB",
                        background: "#E6F6FF",
                        borderRadius: 5,
                      }}
                    >
                      Proposal : {e.proposalStatus}
                    </span>
                  )} */}
                  <div className="brand_selection_status">{title}</div>
                </div>

                <div className="brand_blocks_details">
                  <h3>{e.brandName}</h3>
                  <div className="brand_campaing_row">
                    <div className="brand_name_selection">
                      <div className="campaign_connect_logo">
                        <img src={Facebook} />
                      </div>
                      <p className="campaign_connect_title">Facebook Ads</p>
                    </div>
                    <div
                      className={`${
                        e?.accessSharing?.fbAccess?.adAccount?.status ==
                          "READ_ACCESS" ||
                        e?.accessSharing?.fbAccess?.adAccount?.status ==
                          "COMPLETE_ACCESS"
                          ? "social_status_tag success"
                          : "social_status_tag warning"
                      }`}
                      style={{
                        justifyContent: "end",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 12,
                        borderRadius: 5,
                        padding: 4,
                      }}
                    >
                      {e?.accessSharing?.fbAccess?.adAccount?.status}
                    </div>
                  </div>
                  {/*              <div className="brand_campaing_row">          <div className="brand_name_selection">

                        <div className="campaign_connect_logo"><img src={Google} /></div>
                        <p className="campaign_connect_title">
                          Google Ads
                        </p>
                        <span
                          style={{
                            justifyContent: "end",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: 12,
                            color: "#1ABFA1",
                            background: "#E7F9F7",
                            borderRadius: 5,
                            padding: 4,
                          }}
                        >
                          {e.accessSharing.googleAccess.googleAdAccount.status}
                        </span>
                      </div> </div> */}
                  <div className="brand_campaing_row">
                    <div className="brand_name_selection">
                      <div className="campaign_connect_logo">
                        <img src={Google} />
                      </div>
                      <p className="campaign_connect_title">Google Ads</p>
                    </div>
                    <div
                      className={`${
                        e?.accessSharing?.googleAccess?.googleAdAccount?.status ==
                          "READ_ACCESS" ||
                        e?.accessSharing?.googleAccess?.googleAdAccount?.status ==
                          "COMPLETE_ACCESS"
                          ? "social_status_tag success"
                          : "social_status_tag warning"
                      }`}
                      style={{
                        justifyContent: "end",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 12,
                        borderRadius: 5,
                        padding: 4,
                      }}
                    >
                      {e?.accessSharing?.googleAccess?.googleAdAccount?.status}
                    </div>
                  </div>
                  {/*<div className="brand_campaing_row">
                    <div className="brand_name_selection">
                      <div className="campaign_connect_logo">
                        <img src={Shopify} />
                      </div>
                      <p className="campaign_connect_title">
                        Shopify(Facebook)
                      </p>
                    </div>
                    <div
                      className={`${
                        e.accessSharing.shopifyAccess.fbSalesChannel.status ==
                          "READ_ACCESS" ||
                        e.accessSharing.shopifyAccess.fbSalesChannel.status ==
                          "COMPLETE_ACCESS"
                          ? "social_status_tag success"
                          : "social_status_tag warning"
                      }`}
                      style={{
                        justifyContent: "end",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 12,
                        borderRadius: 5,
                        padding: 4,
                      }}
                    >
                      {e.accessSharing.shopifyAccess.fbSalesChannel.status}
                    </div>
                  </div>
                  <div className="brand_campaing_row">
                    <div className="brand_name_selection">
                      <div className="campaign_connect_logo">
                        <img src={Shopify} />
                      </div>
                      <p className="campaign_connect_title">Shopify(Tik-Tok)</p>
                    </div>
                    <div
                      className={`${
                        e.accessSharing.shopifyAccess.tiktokSalesChannel
                          .status == "READ_ACCESS" ||
                        e.accessSharing.shopifyAccess.tiktokSalesChannel
                          .status == "COMPLETE_ACCESS"
                          ? "social_status_tag success"
                          : "social_status_tag warning"
                      }`}
                      style={{
                        justifyContent: "end",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 12,
                        borderRadius: 5,
                        padding: 4,
                      }}
                    >
                      {e.accessSharing.shopifyAccess.tiktokSalesChannel.status}
                    </div>
                    </div>*/}
                  {/*               <div className="brand_campaing_row">         <div className="brand_name_selection">

                        <div className="campaign_connect_logo"><img src={Google} /></div>
                        <p className="campaign_connect_title">
                          Google Merchant Center
                        </p>
                        <span
                          style={{
                            justifyContent: "end",
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: 12,
                            borderRadius: 5,
                            padding: 4,
                          }}
                        >
                          {e.accessSharing.googleAccess.googleMerchant.status}
                        </span>
                      </div> </div>*/}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SelectBrand;
