import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { RadioGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import BpRadio from "./Radio";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};
const MenuRadioList = (props) => {
  const [selectedOption, setSelectedOption] = useState("Select");
  useEffect(() => {
    setSelectedOption(props.selectedOption);
  }, [props]);

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    if(props.setHasChange){
      props.setHasChange(true);
    }
    props.setSelectedOption(e.target.value);
  }
  return (
    <>
      <Select
        // disabled
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        value={selectedOption}
        renderValue={(selected) => selected}
        MenuProps={MenuProps}
        variant="outlined"
        placeholder="Select"
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
        style={{
          width: `${props.width}`,
          height: 27,
          background: "#FFFFFF",
          border: "1px solid #CCECFE",
          borderRadius: 4,
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 12,
          outline: "none",
          color: "#0869FB",
        }}
      >
        <RadioGroup>
          {props.list.map((name) => (
            <MenuItem key={name} value={name}>
              <FormControlLabel
              disabled={props.disabled}
                value={name}
                control={<BpRadio />}
                label={name}
                onClick={handleChange}
                checked={name === selectedOption}
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </Select>
    </>
  );
};

export default MenuRadioList;
