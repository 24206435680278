import { ChannelType } from "../../components/Admin/channel.js";

const INITIAL_STATE = {
  authenticatedChannel: {
    [ChannelType.GoogleAds]: false,
    [ChannelType.FacebookAds]: false,
  },
  filteredAccounts: {
    [ChannelType.GoogleAds]: [],
    [ChannelType.FacebookAds]: [],
  },
  accountsChannel: {
    [ChannelType.GoogleAds]: [],
    [ChannelType.FacebookAds]: [],
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_CHANNEL_ACCOUNTS_LIST":
      return {
        ...state,
        authenticatedChannel: {
          ...state.authenticatedChannel,
          [action.payload.channelName]: true,
        },
        filteredAccounts: {
          ...state.filteredAccounts,
          [action.payload.channelName]: action.payload.data,
        },
        accountsChannel: {
            ...state.accountsChannel,
            [action.payload.channelName]: action.payload.data
        }
      };
    case "CHANNEL_AUTH_FAILED":
      return {
        ...state,
        accountsChannel: {
          ...state.accountsChannel,
          [action.payload.channelName]: []
        },
        filteredAccounts:{
            ...state.filteredAccounts,
            [action.payload.channelName]: []
        },
        authenticatedChannel: {
            ...state.authenticatedChannel,
            [action.payload.channelName]: false
        }
      };
    default:
      return state;
  }
};

export default reducer;
