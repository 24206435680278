export const defaultFBReport = {
    "_id": "facebook-ads",
    "user_id": "3a6a5d52-71ee-451d-9fda-b8df74ba76ce",
    "organization_id": "1",
    "report_name": "Analytics",
    "report_type": null,
    "report_icon": "/static/media/logos_facebook.91b71a2ce3d132c597af28daa19fff42.svg",
    "tabs": [
        {
            "id": "64382f06-7c56-42e8-b8d3-7ca7cba238f4",
            "name": "FB Reports",
            "path": "overall",
            "position": 0
        }
    ],
    "grids": [
        {
            "id": "b14cbd42-e658-4e58-8cd7-effdaf9fc371",
            "tabId": "64382f06-7c56-42e8-b8d3-7ca7cba238f4",
            "title": "Overall",
            "gridStyle": {
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "subTitleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "color": "#00000088",
                    "fontFormat": [
                        "bold"
                    ]
                }
            }
        }
    ],
    "charts": [
        {
            "id": "202ea97c-433c-4c75-ac08-65a1325e54c2",
            "gridId": "b14cbd42-e658-4e58-8cd7-effdaf9fc371",
            "channel": "facebook-ads",
            "title": "",
            "type": "LINEBAR",
            "gridPosition": {
                "x": 0,
                "y": 0,
                "w": 6,
                "h": 2
            },
            "leftMetrics": [
                {
                    "id": "impressions",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "day"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": [
                {
                    "id": "spend",
                    "filter": null
                }
            ],
            "table": null
        },
        {
            "id": "25710c23-f992-490d-8f83-e0818a8e996e",
            "gridId": "b14cbd42-e658-4e58-8cd7-effdaf9fc371",
            "channel": "facebook-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "w": 3,
                "h": 1,
                "x": 0,
                "y": 2,
                "i": "25710c23-f992-490d-8f83-e0818a8e996e",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "impressions",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": null
        },
        {
            "id": "98308d9a-2b2f-44c2-aaf8-b1794872a476",
            "gridId": "b14cbd42-e658-4e58-8cd7-effdaf9fc371",
            "channel": "facebook-ads",
            "title": "",
            "type": "BAR",
            "gridPosition": {
                "w": 6,
                "h": 2,
                "x": 6,
                "y": 1,
                "i": "98308d9a-2b2f-44c2-aaf8-b1794872a476",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "spend",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "age"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": [
                {
                    "id": "impressions",
                    "filter": null
                }
            ],
            "table": null
        },
        {
            "id": "3fdd6118-6474-4632-877a-50d72ca46442",
            "gridId": "b14cbd42-e658-4e58-8cd7-effdaf9fc371",
            "channel": "facebook-ads",
            "title": "Table",
            "type": "TABLE",
            "gridPosition": {
                "w": 12,
                "h": 3,
                "x": 0,
                "y": 3,
                "i": "3fdd6118-6474-4632-877a-50d72ca46442",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "spend",
                    "filter": null
                },
                {
                    "id": "impressions",
                    "filter": null
                },
                {
                    "id": "clicks",
                    "filter": null
                },
                {
                    "id": "website_ctr_link_click",
                    "filter": null
                },
                {
                    "id": "cpm",
                    "filter": null
                },
                {
                    "id": "cost_per_inline_link_click",
                    "filter": null
                },
                {
                    "id": "cpp",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "campaign_name"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": null
        },
        {
            "id": "750ff650-82e2-4b36-9820-7616bf8c8cc1",
            "gridId": "b14cbd42-e658-4e58-8cd7-effdaf9fc371",
            "channel": "facebook-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "w": 3,
                "h": 1,
                "x": 6,
                "y": 0,
                "i": "750ff650-82e2-4b36-9820-7616bf8c8cc1",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "spend",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": null
        },
        {
            "id": "5f834104-50da-4ba4-9620-66a488ff0f17",
            "gridId": "b14cbd42-e658-4e58-8cd7-effdaf9fc371",
            "channel": "facebook-ads",
            "title": "",
            "type": "TABLE",
            "gridPosition": {
                "w": 12,
                "h": 3,
                "x": 0,
                "y": 6,
                "i": "5f834104-50da-4ba4-9620-66a488ff0f17",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "spend",
                    "filter": null
                },
                {
                    "id": "impressions",
                    "filter": null
                },
                {
                    "id": "clicks",
                    "filter": null
                },
                {
                    "id": "website_ctr_link_click",
                    "filter": null
                },
                {
                    "id": "cpm",
                    "filter": null
                },
                {
                    "id": "cost_per_inline_link_click",
                    "filter": null
                },
                {
                    "id": "cpp",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "adset_name"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": null
        },
        {
            "id": "cb36b4f9-6d70-471d-a147-a096a0880f23",
            "gridId": "b14cbd42-e658-4e58-8cd7-effdaf9fc371",
            "channel": "facebook-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "w": 3,
                "h": 1,
                "x": 3,
                "y": 2,
                "i": "cb36b4f9-6d70-471d-a147-a096a0880f23",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "clicks",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": null
        },
        {
            "id": "27a90145-158a-4d9a-baa5-903690b6fdcf",
            "gridId": "b14cbd42-e658-4e58-8cd7-effdaf9fc371",
            "channel": "facebook-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "w": 3,
                "h": 1,
                "x": 9,
                "y": 0,
                "i": "27a90145-158a-4d9a-baa5-903690b6fdcf",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "reach",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": null
        },
        {
            id: 'f8fd1300-8207-43ed-ae16-a661403fffb0',
            gridId: 'b14cbd42-e658-4e58-8cd7-effdaf9fc371',
            channel: 'facebook-ads',
            title: '',
            type: 'LINE',
            gridPosition: {
                w: 12,
                h: 2,
                x: 0,
                y: 9,
                i: 'f8fd1300-8207-43ed-ae16-a661403fffb0',
                moved: false,
                'static': false
            },
            leftMetrics: [
                {
                    id: 'impressions',
                    filter: null
                }
            ],
            dimensions: [
                {
                    id: 'day'
                }
            ],
            chartStyle: {
                legendPosition: 'bottom',
                legendStyle: {
                    fontStyle: 'Inter',
                    fontSize: 16
                },
                palette: 0,
                titleStyle: {
                    font: 'Inter',
                    fontSize: '16',
                    alignment: 'left',
                    color: '#000000',
                    fontFormat: [
                        'bold'
                    ]
                },
                tableStyle: {
                    tableHeader: {
                        font: 'Inter',
                        fontSize: 14
                    },
                    tableContent: {
                        font: 'Inter',
                        fontSize: 14
                    }
                }
            },
            compareWith: '',
            table: null,
            rightMetrics: [
                {
                    id: 'clicks',
                    filter: null
                }
            ],
            filter: null
        }
    ],
    "created_at": "2023-02-13T07:57:23.244Z",
    "updated_at": "2023-02-16T07:33:02.473Z",
    "__v": 0
}

export const defaultFBState = {
    "accountData": {
        "users": [
            {
                "username": "Anutosh Satyarthi",
                "email": "110134275175574"
            }
        ]
    },
    "segments": [
        {
            "name": "View through attribution window",
            "values": [
                {
                    "id": "1d_view",
                    "name": "1 day"
                },
                {
                    "id": "7d_view",
                    "name": "7 days"
                },
                {
                    "id": "28d_view",
                    "name": "28 days"
                }
            ]
        },
        {
            "name": "Click through attribution window",
            "values": [
                {
                    "id": "1d_click",
                    "name": "1 day"
                },
                {
                    "id": "7d_click",
                    "name": "7 days"
                },
                {
                    "id": "28d_click",
                    "name": "28 days"
                }
            ]
        },
        {
            "name": "Use Unified Attribution Setting",
            "values": [
                {
                    "id": "false",
                    "name": "No",
                    "value": false
                },
                {
                    "id": "true",
                    "name": "Yes",
                    "value": true
                }
            ]
        }
    ],
    "dimensions": [
        {
            "id": "campaign_name",
            "name": "Campaign name",
            "type": "DIMENSION",
            "group": "Popular breakdowns",
            "dataType": "String"
        },
        {
            "id": "adset_name",
            "name": "Ad set name",
            "type": "DIMENSION",
            "group": "Popular breakdowns",
            "dataType": "String"
        },
        {
            "id": "ad_name",
            "name": "Ad name",
            "type": "DIMENSION",
            "group": "Popular breakdowns",
            "dataType": "String"
        },
        {
            "id": "age",
            "name": "Age",
            "type": "DIMENSION",
            "group": "Popular breakdowns",
            "dataType": "String"
        },
        {
            "id": "gender",
            "name": "Gender",
            "type": "DIMENSION",
            "group": "Popular breakdowns",
            "dataType": "String"
        },
        {
            "id": "country",
            "name": "Country",
            "type": "DIMENSION",
            "group": "Popular breakdowns",
            "dataType": "String"
        },
        {
            "id": "region",
            "name": "Region",
            "type": "DIMENSION",
            "group": "Popular breakdowns",
            "dataType": "String"
        },
        {
            "id": "day",
            "name": "Day",
            "type": "DIMENSION",
            "group": "Popular breakdowns",
            "dataType": "Date"
        },
        {
            "id": "month",
            "name": "Month",
            "type": "DIMENSION",
            "group": "Popular breakdowns",
            "dataType": "String"
        },
        {
            "id": "campaign_name",
            "name": "Campaign name",
            "type": "DIMENSION",
            "group": "Level",
            "dataType": "String"
        },
        {
            "id": "adset_name",
            "name": "Ad set name",
            "type": "DIMENSION",
            "group": "Level",
            "dataType": "String"
        },
        {
            "id": "ad_name",
            "name": "Ad name",
            "type": "DIMENSION",
            "group": "Level",
            "dataType": "String"
        },
        {
            "id": "campaign_id",
            "name": "Campaign ID",
            "type": "DIMENSION",
            "group": "Level",
            "dataType": "String"
        },
        {
            "id": "adset_id",
            "name": "Ad set ID",
            "type": "DIMENSION",
            "group": "Level",
            "dataType": "String"
        },
        {
            "id": "ad_id",
            "name": "Ad ID",
            "type": "DIMENSION",
            "group": "Level",
            "dataType": "String"
        },
        {
            "id": "day",
            "name": "Day",
            "type": "DIMENSION",
            "group": "Time",
            "dataType": "Date"
        },
        {
            "id": "week",
            "name": "Week",
            "type": "DIMENSION",
            "group": "Time",
            "dataType": "String"
        },
        {
            "id": "month",
            "name": "Month",
            "type": "DIMENSION",
            "group": "Time",
            "dataType": "String"
        },
        {
            "id": "age",
            "name": "Age",
            "type": "DIMENSION",
            "group": "Delivery",
            "dataType": "String"
        },
        {
            "id": "gender",
            "name": "Gender",
            "type": "DIMENSION",
            "group": "Delivery",
            "dataType": "String"
        },
        {
            "id": "country",
            "name": "Country",
            "type": "DIMENSION",
            "group": "Delivery",
            "dataType": "String"
        },
        {
            "id": "region",
            "name": "Region",
            "type": "DIMENSION",
            "group": "Delivery",
            "dataType": "String"
        },
        {
            "id": "device_platform",
            "name": "Device platform",
            "type": "DIMENSION",
            "group": "Delivery",
            "dataType": "String"
        }
    ],
    "metrics": [
        {
            "id": "spend",
            "name": "Amount spent",
            "type": "METRIC",
            "group": "Popular metrics",
            "dataType": "Price"
        },
        {
            "id": "impressions",
            "name": "Impressions",
            "type": "METRIC",
            "group": "Popular metrics",
            "dataType": "Number"
        },
        {
            "id": "reach",
            "name": "Reach",
            "type": "METRIC",
            "group": "Popular metrics",
            "dataType": "Number"
        },
        {
            "id": "inline_link_clicks",
            "name": "Link clicks",
            "type": "METRIC",
            "group": "Popular metrics",
            "dataType": "Number"
        },
        {
            "id": "clicks",
            "name": "Clicks (all)",
            "type": "METRIC",
            "group": "Performance",
            "dataType": "Number"
        },
        {
            "id": "website_ctr_link_click",
            "name": "CTR (link click-through rate)",
            "type": "METRIC",
            "group": "Engagement",
            "dataType": "Percent"
        },
        {
            "id": "cpm",
            "name": "CPM (cost per 1,000 impressions)",
            "type": "METRIC",
            "group": "Performance",
            "dataType": "Price"
        },
        {
            "id": "cost_per_inline_link_click",
            "name": "CPC (cost per link click)",
            "type": "METRIC",
            "group": "Engagement",
            "dataType": "Price"
        },
        {
            "id": "cpp",
            "name": "CPP (Cost per 1,000 people reached)",
            "type": "METRIC",
            "group": "Performance",
            "dataType": "Price"
        },
        {
            "id": "purchases",
            "name": "Purchases",
            "type": "METRIC",
            "group": "Conversions",
            "dataType": "Price"
        },
        {
            "id": "purchases_conversion_value",
            "name": "Purchases Conversion Value",
            "type": "METRIC",
            "group": "Conversions",
            "dataType": "Price"
        },
        {
            "id": "purchase_roas_2",
            "name": "Purchase ROAS (return on ad spend)",
            "type": "METRIC",
            "group": "Conversions",
            "dataType": "Price"
        },
        {
            "id": "cost_per_app_install",
            "name": "Cost per App Install",
            "type": "METRIC",
            "group": "Conversions",
            "dataType": "Price"
        }
    ],
    "currency": "USD",
    "metricsUpdated": true
}

export const defaultGAReport = {
    "_id": "google-ads",
    "user_id": "3a6a5d52-71ee-451d-9fda-b8df74ba76ce",
    "organization_id": "1",
    "report_name": "Analytics",
    "report_icon": "/static/media/logos_google-ads.8fae42304be5c592e8094e8abb988cef.svg",
    "report_type": null,
    "tabs": [
        {
            "id": "8b4951c9-2769-4c93-a68f-7baf797fd48e",
            "name": "Google ads agency",
            "path": "google-ads-agency",
            "position": 0
        }
    ],
    "grids": [
        {
            "id": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "tabId": "8b4951c9-2769-4c93-a68f-7baf797fd48e",
            "title": "Overall"
        },
        {
            "id": "1e64db5a-c7a6-4b05-b2f4-96d2655d4006",
            "tabId": "8b4951c9-2769-4c93-a68f-7baf797fd48e",
            "title": "Sub Level View",
            "subtitle": "Campaign, Adgroup/Ad, Keywords"
        }
    ],
    "charts": [
        {
            "id": "36e99633-4400-4fc7-9243-6abe4ec53ec8",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "w": 3,
                "h": 1,
                "x": 0,
                "y": 0,
                "i": "36e99633-4400-4fc7-9243-6abe4ec53ec8",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.cost_micros",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "customer"
        },
        {
            "id": "33293d23-3f3a-4c52-90cf-ea272211d902",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "w": 3,
                "h": 1,
                "x": 6,
                "y": 0,
                "i": "33293d23-3f3a-4c52-90cf-ea272211d902",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.clicks",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "customer"
        },
        {
            "id": "b73ea215-0854-4593-bf10-adec053df9c9",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "w": 3,
                "h": 1,
                "x": 3,
                "y": 0,
                "i": "b73ea215-0854-4593-bf10-adec053df9c9",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.impressions",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "customer"
        },
        {
            "id": "8399383d-5064-4bb2-b28d-682435e61427",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "w": 3,
                "h": 1,
                "x": 9,
                "y": 0,
                "i": "8399383d-5064-4bb2-b28d-682435e61427",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.average_cpc",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "customer"
        },
        {
            "id": "25a98f9b-1378-4a7d-ae2c-31cf4bd6df37",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "x": 0,
                "y": 1,
                "w": 3,
                "h": 1
            },
            "leftMetrics": [
                {
                    "id": "metrics.average_cost",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "customer"
        },
        {
            "id": "56e0f03d-09a3-4d45-8d65-87cd1963ccad",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "x": 3,
                "y": 1,
                "w": 3,
                "h": 1
            },
            "leftMetrics": [
                {
                    "id": "metrics.average_cpe",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "customer"
        },
        {
            "id": "c6a53edb-eb2f-4151-9085-79bff3e74abe",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "x": 6,
                "y": 1,
                "w": 3,
                "h": 1
            },
            "leftMetrics": [
                {
                    "id": "metrics.average_cpm",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "customer"
        },
        {
            "id": "ccfc6bc3-638b-4a7a-a5ba-26280f79740a",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "",
            "type": "KPI",
            "gridPosition": {
                "x": 9,
                "y": 1,
                "w": 3,
                "h": 1
            },
            "leftMetrics": [
                {
                    "id": "metrics.average_cpv",
                    "filter": null
                }
            ],
            "dimensions": null,
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "customer"
        },
        {
            "id": "64bbd40b-bf8a-4f8e-921f-41fab8f339d1",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "Clicks vs Impressions",
            "type": "LINE",
            "gridPosition": {
                "w": 6,
                "h": 2,
                "x": 0,
                "y": 2,
                "i": "64bbd40b-bf8a-4f8e-921f-41fab8f339d1",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.clicks",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "segments.date"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": [
                {
                    "id": "metrics.impressions",
                    "filter": null
                }
            ],
            "table": "customer"
        },
        {
            "id": "68d68160-e396-4dec-81bc-9bb0c399daea",
            "gridId": "a6276b22-4dbc-4abe-9c7c-f03a3eccca26",
            "channel": "google-ads",
            "title": "",
            "type": "TABLE",
            "gridPosition": {
                "w": 6,
                "h": 2,
                "x": 6,
                "y": 2,
                "i": "68d68160-e396-4dec-81bc-9bb0c399daea",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.cost_micros",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpc",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpm",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "segments.date"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "customer"
        },
        {
            "id": "0f80db83-ebc8-44d3-b03c-5a831e2b612c",
            "gridId": "1e64db5a-c7a6-4b05-b2f4-96d2655d4006",
            "channel": "google-ads",
            "title": "",
            "type": "TABLE",
            "gridPosition": {
                "w": 12,
                "h": 2,
                "x": 0,
                "y": 6,
                "i": "0f80db83-ebc8-44d3-b03c-5a831e2b612c",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.cost_micros",
                    "filter": null
                },
                {
                    "id": "metrics.impressions",
                    "filter": null
                },
                {
                    "id": "metrics.clicks",
                    "filter": null
                },
                {
                    "id": "metrics.interactions",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpm",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpv",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "ad_group_criterion.keyword.text"
                },
                {
                    "id": "ad_group_criterion.keyword.match_type"
                },
                {
                    "id": "campaign.name"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "keyword_view"
        },
        {
            "id": "b1d4725c-9d3b-45f2-adfc-b4399cbeba7e",
            "gridId": "1e64db5a-c7a6-4b05-b2f4-96d2655d4006",
            "channel": "google-ads",
            "title": "Campaign View",
            "type": "TABLE",
            "gridPosition": {
                "w": 12,
                "h": 2,
                "x": 0,
                "y": 0,
                "i": "b1d4725c-9d3b-45f2-adfc-b4399cbeba7e",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.cost_micros",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpv",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpm",
                    "filter": null
                },
                {
                    "id": "metrics.interaction_rate",
                    "filter": null
                },
                {
                    "id": "metrics.impressions",
                    "filter": null
                },
                {
                    "id": "metrics.clicks",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "campaign.name"
                },
                {
                    "id": "segments.ad_network_type"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "campaign"
        },
        {
            "id": "5eac25aa-33a1-4b94-9208-0913d167ed15",
            "gridId": "1e64db5a-c7a6-4b05-b2f4-96d2655d4006",
            "channel": "google-ads",
            "title": "",
            "type": "TABLE",
            "gridPosition": {
                "w": 12,
                "h": 2,
                "x": 0,
                "y": 4,
                "i": "5eac25aa-33a1-4b94-9208-0913d167ed15",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.cost_micros",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpv",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpm",
                    "filter": null
                },
                {
                    "id": "metrics.interaction_rate",
                    "filter": null
                },
                {
                    "id": "metrics.impressions",
                    "filter": null
                },
                {
                    "id": "metrics.clicks",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "ad_group_ad.ad.name"
                },
                {
                    "id": "ad_group.name"
                },
                {
                    "id": "campaign.name"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "ad_group_ad"
        },
        {
            "id": "24999409-a0c5-494d-8460-f6e8d7c043ef",
            "gridId": "1e64db5a-c7a6-4b05-b2f4-96d2655d4006",
            "channel": "google-ads",
            "title": "Ad Group View",
            "type": "TABLE",
            "gridPosition": {
                "w": 12,
                "h": 2,
                "x": 0,
                "y": 2,
                "i": "24999409-a0c5-494d-8460-f6e8d7c043ef",
                "moved": false,
                "static": false
            },
            "leftMetrics": [
                {
                    "id": "metrics.cost_micros",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpv",
                    "filter": null
                },
                {
                    "id": "metrics.average_cpm",
                    "filter": null
                },
                {
                    "id": "metrics.interaction_rate",
                    "filter": null
                },
                {
                    "id": "metrics.impressions",
                    "filter": null
                },
                {
                    "id": "metrics.clicks",
                    "filter": null
                }
            ],
            "dimensions": [
                {
                    "id": "ad_group.name"
                },
                {
                    "id": "campaign.name"
                },
                {
                    "id": "segments.ad_network_type"
                }
            ],
            "chartStyle": {
                "legendPosition": "bottom",
                "legendStyle": {
                    "fontStyle": "Inter",
                    "fontSize": 16
                },
                "palette": 0,
                "titleStyle": {
                    "font": "Inter",
                    "fontSize": "16",
                    "alignment": "left",
                    "color": "#000000",
                    "fontFormat": [
                        "bold"
                    ]
                },
                "tableStyle": {
                    "tableHeader": {
                        "font": "Inter",
                        "fontSize": 14
                    },
                    "tableContent": {
                        "font": "Inter",
                        "fontSize": 14
                    }
                }
            },
            "compareWith": "",
            "filter": null,
            "rightMetrics": null,
            "table": "ad_group"
        }
    ],
    "created_at": "2022-09-23T06:59:10.145Z",
    "updated_at": "2023-02-20T09:09:21.662Z",
    "__v": 0
}

export const defaultGAState = {
    "accountData": {
        "users": [
            {
                "username": "Divyam Goel",
                "email": "divyam.goel@pixis.ai"
            }
        ],
        "accountList": [
            {
                "title": "Pyxis Social - OCUS",
                "subtitle": "Pyxis Social - OCUS",
                "customerId": "1826818684",
                "loginCustomerId": "1826818684"
            }
        ]
    },
    "tables": [
        {
            "id": "accessible_bidding_strategy",
            "name": "Accessible Bidding Strategy"
        },
        {
            "id": "account_budget",
            "name": "Account Budget"
        },
        {
            "id": "account_budget_proposal",
            "name": "Account Budget Proposal"
        },
        {
            "id": "account_link",
            "name": "Account Link"
        },
        {
            "id": "ad_group",
            "name": "Ad Group"
        },
        {
            "id": "ad_group_ad",
            "name": "Ad Group Ad"
        },
        {
            "id": "ad_group_ad_asset_combination_view",
            "name": "Ad Group Ad Asset Combination View"
        },
        {
            "id": "ad_group_ad_asset_view",
            "name": "Ad Group Ad Asset View"
        },
        {
            "id": "ad_group_ad_label",
            "name": "Ad Group Ad Label"
        },
        {
            "id": "ad_group_asset",
            "name": "Ad Group Asset"
        },
        {
            "id": "ad_group_asset_set",
            "name": "Ad Group Asset Set"
        },
        {
            "id": "ad_group_audience_view",
            "name": "Ad Group Audience View"
        },
        {
            "id": "ad_group_bid_modifier",
            "name": "Ad Group Bid Modifier"
        },
        {
            "id": "ad_group_criterion",
            "name": "Ad Group Criterion"
        },
        {
            "id": "ad_group_criterion_customizer",
            "name": "Ad Group Criterion Customizer"
        },
        {
            "id": "ad_group_criterion_label",
            "name": "Ad Group Criterion Label"
        },
        {
            "id": "ad_group_criterion_simulation",
            "name": "Ad Group Criterion Simulation"
        },
        {
            "id": "ad_group_customizer",
            "name": "Ad Group Customizer"
        },
        {
            "id": "ad_group_extension_setting",
            "name": "Ad Group Extension Setting"
        },
        {
            "id": "ad_group_feed",
            "name": "Ad Group Feed"
        },
        {
            "id": "ad_group_label",
            "name": "Ad Group Label"
        },
        {
            "id": "ad_group_simulation",
            "name": "Ad Group Simulation"
        },
        {
            "id": "ad_parameter",
            "name": "Ad Parameter"
        },
        {
            "id": "ad_schedule_view",
            "name": "Ad Schedule View"
        },
        {
            "id": "age_range_view",
            "name": "Age Range View"
        },
        {
            "id": "asset",
            "name": "Asset"
        },
        {
            "id": "asset_field_type_view",
            "name": "Asset Field Type View"
        },
        {
            "id": "asset_group",
            "name": "Asset Group"
        },
        {
            "id": "asset_group_asset",
            "name": "Asset Group Asset"
        },
        {
            "id": "asset_group_listing_group_filter",
            "name": "Asset Group Listing Group Filter"
        },
        {
            "id": "asset_group_product_group_view",
            "name": "Asset Group Product Group View"
        },
        {
            "id": "asset_group_signal",
            "name": "Asset Group Signal"
        },
        {
            "id": "asset_set",
            "name": "Asset Set"
        },
        {
            "id": "asset_set_asset",
            "name": "Asset Set Asset"
        },
        {
            "id": "asset_set_type_view",
            "name": "Asset Set Type View"
        },
        {
            "id": "audience",
            "name": "Audience"
        },
        {
            "id": "batch_job",
            "name": "Batch Job"
        },
        {
            "id": "bidding_data_exclusion",
            "name": "Bidding Data Exclusion"
        },
        {
            "id": "bidding_seasonality_adjustment",
            "name": "Bidding Seasonality Adjustment"
        },
        {
            "id": "bidding_strategy",
            "name": "Bidding Strategy"
        },
        {
            "id": "bidding_strategy_simulation",
            "name": "Bidding Strategy Simulation"
        },
        {
            "id": "billing_setup",
            "name": "Billing Setup"
        },
        {
            "id": "call_view",
            "name": "Call View"
        },
        {
            "id": "campaign",
            "name": "Campaign"
        },
        {
            "id": "campaign_asset",
            "name": "Campaign Asset"
        },
        {
            "id": "campaign_asset_set",
            "name": "Campaign Asset Set"
        },
        {
            "id": "campaign_audience_view",
            "name": "Campaign Audience View"
        },
        {
            "id": "campaign_bid_modifier",
            "name": "Campaign Bid Modifier"
        },
        {
            "id": "campaign_budget",
            "name": "Campaign Budget"
        },
        {
            "id": "campaign_conversion_goal",
            "name": "Campaign Conversion Goal"
        },
        {
            "id": "campaign_criterion",
            "name": "Campaign Criterion"
        },
        {
            "id": "campaign_criterion_simulation",
            "name": "Campaign Criterion Simulation"
        },
        {
            "id": "campaign_customizer",
            "name": "Campaign Customizer"
        },
        {
            "id": "campaign_draft",
            "name": "Campaign Draft"
        },
        {
            "id": "campaign_extension_setting",
            "name": "Campaign Extension Setting"
        },
        {
            "id": "campaign_feed",
            "name": "Campaign Feed"
        },
        {
            "id": "campaign_group",
            "name": "Campaign Group"
        },
        {
            "id": "campaign_label",
            "name": "Campaign Label"
        },
        {
            "id": "campaign_shared_set",
            "name": "Campaign Shared Set"
        },
        {
            "id": "campaign_simulation",
            "name": "Campaign Simulation"
        },
        {
            "id": "carrier_constant",
            "name": "Carrier Constant"
        },
        {
            "id": "change_event",
            "name": "Change Event"
        },
        {
            "id": "change_status",
            "name": "Change Status"
        },
        {
            "id": "click_view",
            "name": "Click View"
        },
        {
            "id": "combined_audience",
            "name": "Combined Audience"
        },
        {
            "id": "conversion_action",
            "name": "Conversion Action"
        },
        {
            "id": "conversion_custom_variable",
            "name": "Conversion Custom Variable"
        },
        {
            "id": "conversion_goal_campaign_config",
            "name": "Conversion Goal Campaign Config"
        },
        {
            "id": "conversion_value_rule",
            "name": "Conversion Value Rule"
        },
        {
            "id": "conversion_value_rule_set",
            "name": "Conversion Value Rule Set"
        },
        {
            "id": "currency_constant",
            "name": "Currency Constant"
        },
        {
            "id": "custom_audience",
            "name": "Custom Audience"
        },
        {
            "id": "custom_conversion_goal",
            "name": "Custom Conversion Goal"
        },
        {
            "id": "custom_interest",
            "name": "Custom Interest"
        },
        {
            "id": "customer",
            "name": "Customer"
        },
        {
            "id": "customer_asset",
            "name": "Customer Asset"
        },
        {
            "id": "customer_asset_set",
            "name": "Customer Asset Set"
        },
        {
            "id": "customer_client",
            "name": "Customer Client"
        },
        {
            "id": "customer_client_link",
            "name": "Customer Client Link"
        },
        {
            "id": "customer_conversion_goal",
            "name": "Customer Conversion Goal"
        },
        {
            "id": "customer_customizer",
            "name": "Customer Customizer"
        },
        {
            "id": "customer_extension_setting",
            "name": "Customer Extension Setting"
        },
        {
            "id": "customer_feed",
            "name": "Customer Feed"
        },
        {
            "id": "customer_label",
            "name": "Customer Label"
        },
        {
            "id": "customer_manager_link",
            "name": "Customer Manager Link"
        },
        {
            "id": "customer_negative_criterion",
            "name": "Customer Negative Criterion"
        },
        {
            "id": "customer_user_access",
            "name": "Customer User Access"
        },
        {
            "id": "customer_user_access_invitation",
            "name": "Customer User Access Invitation"
        },
        {
            "id": "customizer_attribute",
            "name": "Customizer Attribute"
        },
        {
            "id": "detail_placement_view",
            "name": "Detail Placement View"
        },
        {
            "id": "detailed_demographic",
            "name": "Detailed Demographic"
        },
        {
            "id": "display_keyword_view",
            "name": "Display Keyword View"
        },
        {
            "id": "distance_view",
            "name": "Distance View"
        },
        {
            "id": "domain_category",
            "name": "Domain Category"
        },
        {
            "id": "dynamic_search_ads_search_term_view",
            "name": "Dynamic Search Ads Search Term View"
        },
        {
            "id": "expanded_landing_page_view",
            "name": "Expanded Landing Page View"
        },
        {
            "id": "experiment",
            "name": "Experiment"
        },
        {
            "id": "experiment_arm",
            "name": "Experiment Arm"
        },
        {
            "id": "extension_feed_item",
            "name": "Extension Feed Item"
        },
        {
            "id": "feed",
            "name": "Feed"
        },
        {
            "id": "feed_item",
            "name": "Feed Item"
        },
        {
            "id": "feed_item_set",
            "name": "Feed Item Set"
        },
        {
            "id": "feed_item_set_link",
            "name": "Feed Item Set Link"
        },
        {
            "id": "feed_item_target",
            "name": "Feed Item Target"
        },
        {
            "id": "feed_mapping",
            "name": "Feed Mapping"
        },
        {
            "id": "feed_placeholder_view",
            "name": "Feed Placeholder View"
        },
        {
            "id": "gender_view",
            "name": "Gender View"
        },
        {
            "id": "geo_target_constant",
            "name": "Geo Target Constant"
        },
        {
            "id": "geographic_view",
            "name": "Geographic View"
        },
        {
            "id": "group_placement_view",
            "name": "Group Placement View"
        },
        {
            "id": "hotel_group_view",
            "name": "Hotel Group View"
        },
        {
            "id": "hotel_performance_view",
            "name": "Hotel Performance View"
        },
        {
            "id": "hotel_reconciliation",
            "name": "Hotel Reconciliation"
        },
        {
            "id": "income_range_view",
            "name": "Income Range View"
        },
        {
            "id": "keyword_plan",
            "name": "Keyword Plan"
        },
        {
            "id": "keyword_plan_ad_group",
            "name": "Keyword Plan Ad Group"
        },
        {
            "id": "keyword_plan_ad_group_keyword",
            "name": "Keyword Plan Ad Group Keyword"
        },
        {
            "id": "keyword_plan_campaign",
            "name": "Keyword Plan Campaign"
        },
        {
            "id": "keyword_plan_campaign_keyword",
            "name": "Keyword Plan Campaign Keyword"
        },
        {
            "id": "keyword_theme_constant",
            "name": "Keyword Theme Constant"
        },
        {
            "id": "keyword_view",
            "name": "Keyword View"
        },
        {
            "id": "label",
            "name": "Label"
        },
        {
            "id": "landing_page_view",
            "name": "Landing Page View"
        },
        {
            "id": "language_constant",
            "name": "Language Constant"
        },
        {
            "id": "lead_form_submission_data",
            "name": "Lead Form Submission Data"
        },
        {
            "id": "life_event",
            "name": "Life Event"
        },
        {
            "id": "location_view",
            "name": "Location View"
        },
        {
            "id": "managed_placement_view",
            "name": "Managed Placement View"
        },
        {
            "id": "media_file",
            "name": "Media File"
        },
        {
            "id": "mobile_app_category_constant",
            "name": "Mobile App Category Constant"
        },
        {
            "id": "mobile_device_constant",
            "name": "Mobile Device Constant"
        },
        {
            "id": "offline_user_data_job",
            "name": "Offline User Data Job"
        },
        {
            "id": "operating_system_version_constant",
            "name": "Operating System Version Constant"
        },
        {
            "id": "paid_organic_search_term_view",
            "name": "Paid Organic Search Term View"
        },
        {
            "id": "parental_status_view",
            "name": "Parental Status View"
        },
        {
            "id": "per_store_view",
            "name": "Per Store View"
        },
        {
            "id": "product_bidding_category_constant",
            "name": "Product Bidding Category Constant"
        },
        {
            "id": "product_group_view",
            "name": "Product Group View"
        },
        {
            "id": "recommendation",
            "name": "Recommendation"
        },
        {
            "id": "remarketing_action",
            "name": "Remarketing Action"
        },
        {
            "id": "search_term_view",
            "name": "Search Term View"
        },
        {
            "id": "shared_criterion",
            "name": "Shared Criterion"
        },
        {
            "id": "shared_set",
            "name": "Shared Set"
        },
        {
            "id": "shopping_performance_view",
            "name": "Shopping Performance View"
        },
        {
            "id": "smart_campaign_search_term_view",
            "name": "Smart Campaign Search Term View"
        },
        {
            "id": "smart_campaign_setting",
            "name": "Smart Campaign Setting"
        },
        {
            "id": "third_party_app_analytics_link",
            "name": "Third Party App Analytics Link"
        },
        {
            "id": "topic_constant",
            "name": "Topic Constant"
        },
        {
            "id": "topic_view",
            "name": "Topic View"
        },
        {
            "id": "user_interest",
            "name": "User Interest"
        },
        {
            "id": "user_list",
            "name": "User List"
        },
        {
            "id": "user_location_view",
            "name": "User Location View"
        },
        {
            "id": "video",
            "name": "Video"
        },
        {
            "id": "webpage_view",
            "name": "Webpage View"
        }
    ],
    "dimensions": {
        "accessible_bidding_strategy": [
            {
                "name": "Name",
                "id": "accessible_bidding_strategy.name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Descriptive Name",
                "id": "accessible_bidding_strategy.owner_descriptive_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "accessible_bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "accessible_bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "accessible_bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "account_budget": [
            {
                "name": "Adjusted Spending Limit Type",
                "id": "account_budget.adjusted_spending_limit_type",
                "dataType": "ENUM",
                "group": "Account Budget"
            },
            {
                "name": "Approved End Date Time",
                "id": "account_budget.approved_end_date_time",
                "dataType": "DATE",
                "group": "Account Budget"
            },
            {
                "name": "Approved End Time Type",
                "id": "account_budget.approved_end_time_type",
                "dataType": "ENUM",
                "group": "Account Budget"
            },
            {
                "name": "Approved Spending Limit Type",
                "id": "account_budget.approved_spending_limit_type",
                "dataType": "ENUM",
                "group": "Account Budget"
            },
            {
                "name": "Approved Start Date Time",
                "id": "account_budget.approved_start_date_time",
                "dataType": "DATE",
                "group": "Account Budget"
            },
            {
                "name": "Billing Setup",
                "id": "account_budget.billing_setup",
                "dataType": "STRING",
                "group": "Account Budget"
            },
            {
                "name": "Name",
                "id": "account_budget.name",
                "dataType": "STRING",
                "group": "Account Budget"
            },
            {
                "name": "Notes",
                "id": "account_budget.notes",
                "dataType": "STRING",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal Account Budget Proposal",
                "id": "account_budget.pending_proposal.account_budget_proposal",
                "dataType": "STRING",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal Creation Date Time",
                "id": "account_budget.pending_proposal.creation_date_time",
                "dataType": "DATE",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal End Date Time",
                "id": "account_budget.pending_proposal.end_date_time",
                "dataType": "DATE",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal End Time Type",
                "id": "account_budget.pending_proposal.end_time_type",
                "dataType": "ENUM",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal Name",
                "id": "account_budget.pending_proposal.name",
                "dataType": "STRING",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal Notes",
                "id": "account_budget.pending_proposal.notes",
                "dataType": "STRING",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal Proposal Type",
                "id": "account_budget.pending_proposal.proposal_type",
                "dataType": "ENUM",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal Purchase Order Number",
                "id": "account_budget.pending_proposal.purchase_order_number",
                "dataType": "STRING",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal Spending Limit Type",
                "id": "account_budget.pending_proposal.spending_limit_type",
                "dataType": "ENUM",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal Start Date Time",
                "id": "account_budget.pending_proposal.start_date_time",
                "dataType": "DATE",
                "group": "Account Budget"
            },
            {
                "name": "Proposed End Date Time",
                "id": "account_budget.proposed_end_date_time",
                "dataType": "DATE",
                "group": "Account Budget"
            },
            {
                "name": "Proposed End Time Type",
                "id": "account_budget.proposed_end_time_type",
                "dataType": "ENUM",
                "group": "Account Budget"
            },
            {
                "name": "Proposed Spending Limit Type",
                "id": "account_budget.proposed_spending_limit_type",
                "dataType": "ENUM",
                "group": "Account Budget"
            },
            {
                "name": "Proposed Start Date Time",
                "id": "account_budget.proposed_start_date_time",
                "dataType": "DATE",
                "group": "Account Budget"
            },
            {
                "name": "Purchase Order Number",
                "id": "account_budget.purchase_order_number",
                "dataType": "STRING",
                "group": "Account Budget"
            },
            {
                "name": "Resource Name",
                "id": "account_budget.resource_name",
                "dataType": "STRING",
                "group": "Account Budget"
            },
            {
                "name": "Status",
                "id": "account_budget.status",
                "dataType": "ENUM",
                "group": "Account Budget"
            },
            {
                "name": "End Date Time",
                "id": "billing_setup.end_date_time",
                "dataType": "DATE",
                "group": "Billing Setup"
            },
            {
                "name": "End Time Type",
                "id": "billing_setup.end_time_type",
                "dataType": "ENUM",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account",
                "id": "billing_setup.payments_account",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Account Id",
                "id": "billing_setup.payments_account_info.payments_account_id",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Account Name",
                "id": "billing_setup.payments_account_info.payments_account_name",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Profile Id",
                "id": "billing_setup.payments_account_info.payments_profile_id",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Profile Name",
                "id": "billing_setup.payments_account_info.payments_profile_name",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Secondary Payments Profile Id",
                "id": "billing_setup.payments_account_info.secondary_payments_profile_id",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Resource Name",
                "id": "billing_setup.resource_name",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Start Date Time",
                "id": "billing_setup.start_date_time",
                "dataType": "DATE",
                "group": "Billing Setup"
            },
            {
                "name": "Status",
                "id": "billing_setup.status",
                "dataType": "ENUM",
                "group": "Billing Setup"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "account_budget_proposal": [
            {
                "name": "Account Budget",
                "id": "account_budget_proposal.account_budget",
                "dataType": "STRING",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Approval Date Time",
                "id": "account_budget_proposal.approval_date_time",
                "dataType": "DATE",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Approved End Date Time",
                "id": "account_budget_proposal.approved_end_date_time",
                "dataType": "DATE",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Approved End Time Type",
                "id": "account_budget_proposal.approved_end_time_type",
                "dataType": "ENUM",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Approved Spending Limit Type",
                "id": "account_budget_proposal.approved_spending_limit_type",
                "dataType": "ENUM",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Approved Start Date Time",
                "id": "account_budget_proposal.approved_start_date_time",
                "dataType": "DATE",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Billing Setup",
                "id": "account_budget_proposal.billing_setup",
                "dataType": "STRING",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Creation Date Time",
                "id": "account_budget_proposal.creation_date_time",
                "dataType": "DATE",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Proposal Type",
                "id": "account_budget_proposal.proposal_type",
                "dataType": "ENUM",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Proposed End Date Time",
                "id": "account_budget_proposal.proposed_end_date_time",
                "dataType": "DATE",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Proposed End Time Type",
                "id": "account_budget_proposal.proposed_end_time_type",
                "dataType": "ENUM",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Proposed Name",
                "id": "account_budget_proposal.proposed_name",
                "dataType": "STRING",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Proposed Notes",
                "id": "account_budget_proposal.proposed_notes",
                "dataType": "STRING",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Proposed Purchase Order Number",
                "id": "account_budget_proposal.proposed_purchase_order_number",
                "dataType": "STRING",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Proposed Spending Limit Type",
                "id": "account_budget_proposal.proposed_spending_limit_type",
                "dataType": "ENUM",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Proposed Start Date Time",
                "id": "account_budget_proposal.proposed_start_date_time",
                "dataType": "DATE",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Resource Name",
                "id": "account_budget_proposal.resource_name",
                "dataType": "STRING",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Status",
                "id": "account_budget_proposal.status",
                "dataType": "ENUM",
                "group": "Account Budget Proposal"
            },
            {
                "name": "End Date Time",
                "id": "billing_setup.end_date_time",
                "dataType": "DATE",
                "group": "Billing Setup"
            },
            {
                "name": "End Time Type",
                "id": "billing_setup.end_time_type",
                "dataType": "ENUM",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account",
                "id": "billing_setup.payments_account",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Account Id",
                "id": "billing_setup.payments_account_info.payments_account_id",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Account Name",
                "id": "billing_setup.payments_account_info.payments_account_name",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Profile Id",
                "id": "billing_setup.payments_account_info.payments_profile_id",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Profile Name",
                "id": "billing_setup.payments_account_info.payments_profile_name",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Secondary Payments Profile Id",
                "id": "billing_setup.payments_account_info.secondary_payments_profile_id",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Resource Name",
                "id": "billing_setup.resource_name",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Start Date Time",
                "id": "billing_setup.start_date_time",
                "dataType": "DATE",
                "group": "Billing Setup"
            },
            {
                "name": "Status",
                "id": "billing_setup.status",
                "dataType": "ENUM",
                "group": "Billing Setup"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "account_link": [
            {
                "name": "Advertising Partner Customer",
                "id": "account_link.advertising_partner.customer",
                "dataType": "STRING",
                "group": "Account Link"
            },
            {
                "name": "Google Ads Customer",
                "id": "account_link.google_ads.customer",
                "dataType": "STRING",
                "group": "Account Link"
            },
            {
                "name": "Resource Name",
                "id": "account_link.resource_name",
                "dataType": "STRING",
                "group": "Account Link"
            },
            {
                "name": "Status",
                "id": "account_link.status",
                "dataType": "ENUM",
                "group": "Account Link"
            },
            {
                "name": "Third Party App Analytics App Id",
                "id": "account_link.third_party_app_analytics.app_id",
                "dataType": "STRING",
                "group": "Account Link"
            },
            {
                "name": "Third Party App Analytics App Vendor",
                "id": "account_link.third_party_app_analytics.app_vendor",
                "dataType": "ENUM",
                "group": "Account Link"
            },
            {
                "name": "Type",
                "id": "account_link.type",
                "dataType": "ENUM",
                "group": "Account Link"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "ad_group": [
            {
                "name": "Name",
                "id": "accessible_bidding_strategy.name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Descriptive Name",
                "id": "accessible_bidding_strategy.owner_descriptive_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "accessible_bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "accessible_bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "accessible_bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Destination Type",
                "id": "segments.ad_destination_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Asset",
                "id": "segments.asset_interaction_target.asset",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Interaction On This Asset",
                "id": "segments.asset_interaction_target.interaction_on_this_asset",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Auction Insight Domain",
                "id": "segments.auction_insight_domain",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Adjustment",
                "id": "segments.conversion_adjustment",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Conversion Lag Bucket",
                "id": "segments.conversion_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Or Adjustment Lag Bucket",
                "id": "segments.conversion_or_adjustment_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "ad_group_ad": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Action Items",
                "id": "ad_group_ad.action_items",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Added By Google Ads",
                "id": "ad_group_ad.ad.added_by_google_ads",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Descriptions",
                "id": "ad_group_ad.ad.app_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Headlines",
                "id": "ad_group_ad.ad.app_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles Asset",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images",
                "id": "ad_group_ad.ad.app_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images Asset",
                "id": "ad_group_ad.ad.app_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Mandatory Ad Text",
                "id": "ad_group_ad.ad.app_ad.mandatory_ad_text",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Descriptions",
                "id": "ad_group_ad.ad.app_engagement_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Headlines",
                "id": "ad_group_ad.ad.app_engagement_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images",
                "id": "ad_group_ad.ad.app_engagement_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos",
                "id": "ad_group_ad.ad.app_engagement_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Descriptions",
                "id": "ad_group_ad.ad.app_pre_registration_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Headlines",
                "id": "ad_group_ad.ad.app_pre_registration_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Business Name",
                "id": "ad_group_ad.ad.call_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Call Tracked",
                "id": "ad_group_ad.ad.call_ad.call_tracked",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Action",
                "id": "ad_group_ad.ad.call_ad.conversion_action",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Reporting State",
                "id": "ad_group_ad.ad.call_ad.conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Country Code",
                "id": "ad_group_ad.ad.call_ad.country_code",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description1",
                "id": "ad_group_ad.ad.call_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description2",
                "id": "ad_group_ad.ad.call_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Disable Call Conversion",
                "id": "ad_group_ad.ad.call_ad.disable_call_conversion",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline1",
                "id": "ad_group_ad.ad.call_ad.headline1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline2",
                "id": "ad_group_ad.ad.call_ad.headline2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path1",
                "id": "ad_group_ad.ad.call_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path2",
                "id": "ad_group_ad.ad.call_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number",
                "id": "ad_group_ad.ad.call_ad.phone_number",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number Verification Url",
                "id": "ad_group_ad.ad.call_ad.phone_number_verification_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Device Preference",
                "id": "ad_group_ad.ad.device_preference",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Business Name",
                "id": "ad_group_ad.ad.discovery_carousel_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_carousel_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Description",
                "id": "ad_group_ad.ad.discovery_carousel_ad.description",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Headline",
                "id": "ad_group_ad.ad.discovery_carousel_ad.headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Business Name",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Descriptions",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Headlines",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Lead Form Only",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.lead_form_only",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Display Upload Product Type",
                "id": "ad_group_ad.ad.display_upload_ad.display_upload_product_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle Asset",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Url",
                "id": "ad_group_ad.ad.display_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description2",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description",
                "id": "ad_group_ad.ad.expanded_text_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description2",
                "id": "ad_group_ad.ad.expanded_text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part1",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part2",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part3",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part3",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path1",
                "id": "ad_group_ad.ad.expanded_text_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path2",
                "id": "ad_group_ad.ad.expanded_text_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final App Urls",
                "id": "ad_group_ad.ad.final_app_urls",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Mobile Urls",
                "id": "ad_group_ad.ad.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Url Suffix",
                "id": "ad_group_ad.ad.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Urls",
                "id": "ad_group_ad.ad.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Hotel Ad",
                "id": "ad_group_ad.ad.hotel_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Image Url",
                "id": "ad_group_ad.ad.image_ad.image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Media File",
                "id": "ad_group_ad.ad.image_ad.media_file",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Mime Type",
                "id": "ad_group_ad.ad.image_ad.mime_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Name",
                "id": "ad_group_ad.ad.image_ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Image Url",
                "id": "ad_group_ad.ad.image_ad.preview_image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy App Install Ad",
                "id": "ad_group_ad.ad.legacy_app_install_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Description",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.long_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Short Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.short_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Call To Actions",
                "id": "ad_group_ad.ad.local_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Descriptions",
                "id": "ad_group_ad.ad.local_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Headlines",
                "id": "ad_group_ad.ad.local_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images",
                "id": "ad_group_ad.ad.local_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images Asset",
                "id": "ad_group_ad.ad.local_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images",
                "id": "ad_group_ad.ad.local_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.local_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path1",
                "id": "ad_group_ad.ad.local_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path2",
                "id": "ad_group_ad.ad.local_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos",
                "id": "ad_group_ad.ad.local_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos Asset",
                "id": "ad_group_ad.ad.local_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Name",
                "id": "ad_group_ad.ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Resource Name",
                "id": "ad_group_ad.ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Asset Enhancements",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_asset_enhancements",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Autogen Video",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_autogen_video",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_display_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Headlines",
                "id": "ad_group_ad.ad.responsive_display_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.responsive_display_ad.long_headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_search_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Headlines",
                "id": "ad_group_ad.ad.responsive_search_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path1",
                "id": "ad_group_ad.ad.responsive_search_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path2",
                "id": "ad_group_ad.ad.responsive_search_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Comparison Listing Ad Headline",
                "id": "ad_group_ad.ad.shopping_comparison_listing_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Product Ad",
                "id": "ad_group_ad.ad.shopping_product_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Smart Ad",
                "id": "ad_group_ad.ad.shopping_smart_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Descriptions",
                "id": "ad_group_ad.ad.smart_campaign_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Headlines",
                "id": "ad_group_ad.ad.smart_campaign_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad System Managed Resource Source",
                "id": "ad_group_ad.ad.system_managed_resource_source",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description1",
                "id": "ad_group_ad.ad.text_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description2",
                "id": "ad_group_ad.ad.text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Headline",
                "id": "ad_group_ad.ad.text_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Tracking Url Template",
                "id": "ad_group_ad.ad.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Type",
                "id": "ad_group_ad.ad.type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Collections",
                "id": "ad_group_ad.ad.url_collections",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Custom Parameters",
                "id": "ad_group_ad.ad.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Bumper Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.bumper.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description1",
                "id": "ad_group_ad.ad.video_ad.in_feed.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description2",
                "id": "ad_group_ad.ad.video_ad.in_feed.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Headline",
                "id": "ad_group_ad.ad.video_ad.in_feed.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Thumbnail",
                "id": "ad_group_ad.ad.video_ad.in_feed.thumbnail",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Button Label",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Headline",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.in_stream.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Button Label",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Headline",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.non_skippable.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Description",
                "id": "ad_group_ad.ad.video_ad.out_stream.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Headline",
                "id": "ad_group_ad.ad.video_ad.out_stream.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Video Asset",
                "id": "ad_group_ad.ad.video_ad.video.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb1",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb2",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Call To Actions",
                "id": "ad_group_ad.ad.video_responsive_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Descriptions",
                "id": "ad_group_ad.ad.video_responsive_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Long Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.long_headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos",
                "id": "ad_group_ad.ad.video_responsive_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_ad.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Strength",
                "id": "ad_group_ad.ad_strength",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Labels",
                "id": "ad_group_ad.labels",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "ad_group_ad.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "ad_group_ad.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "ad_group_ad.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Status",
                "id": "ad_group_ad.status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Destination Type",
                "id": "segments.ad_destination_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Adjustment",
                "id": "segments.conversion_adjustment",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Conversion Lag Bucket",
                "id": "segments.conversion_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Or Adjustment Lag Bucket",
                "id": "segments.conversion_or_adjustment_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Keyword Ad Group Criterion",
                "id": "segments.keyword.ad_group_criterion",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Keyword Info Match Type",
                "id": "segments.keyword.info.match_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Keyword Info Text",
                "id": "segments.keyword.info.text",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "ad_group_ad_asset_combination_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Action Items",
                "id": "ad_group_ad.action_items",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Added By Google Ads",
                "id": "ad_group_ad.ad.added_by_google_ads",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Descriptions",
                "id": "ad_group_ad.ad.app_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Headlines",
                "id": "ad_group_ad.ad.app_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles Asset",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images",
                "id": "ad_group_ad.ad.app_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images Asset",
                "id": "ad_group_ad.ad.app_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Mandatory Ad Text",
                "id": "ad_group_ad.ad.app_ad.mandatory_ad_text",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Descriptions",
                "id": "ad_group_ad.ad.app_engagement_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Headlines",
                "id": "ad_group_ad.ad.app_engagement_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images",
                "id": "ad_group_ad.ad.app_engagement_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos",
                "id": "ad_group_ad.ad.app_engagement_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Descriptions",
                "id": "ad_group_ad.ad.app_pre_registration_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Headlines",
                "id": "ad_group_ad.ad.app_pre_registration_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Business Name",
                "id": "ad_group_ad.ad.call_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Call Tracked",
                "id": "ad_group_ad.ad.call_ad.call_tracked",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Action",
                "id": "ad_group_ad.ad.call_ad.conversion_action",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Reporting State",
                "id": "ad_group_ad.ad.call_ad.conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Country Code",
                "id": "ad_group_ad.ad.call_ad.country_code",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description1",
                "id": "ad_group_ad.ad.call_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description2",
                "id": "ad_group_ad.ad.call_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Disable Call Conversion",
                "id": "ad_group_ad.ad.call_ad.disable_call_conversion",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline1",
                "id": "ad_group_ad.ad.call_ad.headline1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline2",
                "id": "ad_group_ad.ad.call_ad.headline2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path1",
                "id": "ad_group_ad.ad.call_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path2",
                "id": "ad_group_ad.ad.call_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number",
                "id": "ad_group_ad.ad.call_ad.phone_number",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number Verification Url",
                "id": "ad_group_ad.ad.call_ad.phone_number_verification_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Device Preference",
                "id": "ad_group_ad.ad.device_preference",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Business Name",
                "id": "ad_group_ad.ad.discovery_carousel_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_carousel_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Description",
                "id": "ad_group_ad.ad.discovery_carousel_ad.description",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Headline",
                "id": "ad_group_ad.ad.discovery_carousel_ad.headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Business Name",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Descriptions",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Headlines",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Lead Form Only",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.lead_form_only",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Display Upload Product Type",
                "id": "ad_group_ad.ad.display_upload_ad.display_upload_product_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle Asset",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Url",
                "id": "ad_group_ad.ad.display_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description2",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description",
                "id": "ad_group_ad.ad.expanded_text_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description2",
                "id": "ad_group_ad.ad.expanded_text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part1",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part2",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part3",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part3",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path1",
                "id": "ad_group_ad.ad.expanded_text_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path2",
                "id": "ad_group_ad.ad.expanded_text_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final App Urls",
                "id": "ad_group_ad.ad.final_app_urls",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Mobile Urls",
                "id": "ad_group_ad.ad.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Url Suffix",
                "id": "ad_group_ad.ad.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Urls",
                "id": "ad_group_ad.ad.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Hotel Ad",
                "id": "ad_group_ad.ad.hotel_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Image Url",
                "id": "ad_group_ad.ad.image_ad.image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Media File",
                "id": "ad_group_ad.ad.image_ad.media_file",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Mime Type",
                "id": "ad_group_ad.ad.image_ad.mime_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Name",
                "id": "ad_group_ad.ad.image_ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Image Url",
                "id": "ad_group_ad.ad.image_ad.preview_image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy App Install Ad",
                "id": "ad_group_ad.ad.legacy_app_install_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Description",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.long_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Short Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.short_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Call To Actions",
                "id": "ad_group_ad.ad.local_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Descriptions",
                "id": "ad_group_ad.ad.local_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Headlines",
                "id": "ad_group_ad.ad.local_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images",
                "id": "ad_group_ad.ad.local_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images Asset",
                "id": "ad_group_ad.ad.local_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images",
                "id": "ad_group_ad.ad.local_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.local_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path1",
                "id": "ad_group_ad.ad.local_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path2",
                "id": "ad_group_ad.ad.local_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos",
                "id": "ad_group_ad.ad.local_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos Asset",
                "id": "ad_group_ad.ad.local_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Name",
                "id": "ad_group_ad.ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Resource Name",
                "id": "ad_group_ad.ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Asset Enhancements",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_asset_enhancements",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Autogen Video",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_autogen_video",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_display_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Headlines",
                "id": "ad_group_ad.ad.responsive_display_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.responsive_display_ad.long_headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_search_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Headlines",
                "id": "ad_group_ad.ad.responsive_search_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path1",
                "id": "ad_group_ad.ad.responsive_search_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path2",
                "id": "ad_group_ad.ad.responsive_search_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Comparison Listing Ad Headline",
                "id": "ad_group_ad.ad.shopping_comparison_listing_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Product Ad",
                "id": "ad_group_ad.ad.shopping_product_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Smart Ad",
                "id": "ad_group_ad.ad.shopping_smart_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Descriptions",
                "id": "ad_group_ad.ad.smart_campaign_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Headlines",
                "id": "ad_group_ad.ad.smart_campaign_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad System Managed Resource Source",
                "id": "ad_group_ad.ad.system_managed_resource_source",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description1",
                "id": "ad_group_ad.ad.text_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description2",
                "id": "ad_group_ad.ad.text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Headline",
                "id": "ad_group_ad.ad.text_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Tracking Url Template",
                "id": "ad_group_ad.ad.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Type",
                "id": "ad_group_ad.ad.type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Collections",
                "id": "ad_group_ad.ad.url_collections",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Custom Parameters",
                "id": "ad_group_ad.ad.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Bumper Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.bumper.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description1",
                "id": "ad_group_ad.ad.video_ad.in_feed.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description2",
                "id": "ad_group_ad.ad.video_ad.in_feed.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Headline",
                "id": "ad_group_ad.ad.video_ad.in_feed.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Thumbnail",
                "id": "ad_group_ad.ad.video_ad.in_feed.thumbnail",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Button Label",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Headline",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.in_stream.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Button Label",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Headline",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.non_skippable.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Description",
                "id": "ad_group_ad.ad.video_ad.out_stream.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Headline",
                "id": "ad_group_ad.ad.video_ad.out_stream.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Video Asset",
                "id": "ad_group_ad.ad.video_ad.video.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb1",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb2",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Call To Actions",
                "id": "ad_group_ad.ad.video_responsive_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Descriptions",
                "id": "ad_group_ad.ad.video_responsive_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Long Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.long_headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos",
                "id": "ad_group_ad.ad.video_responsive_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_ad.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Strength",
                "id": "ad_group_ad.ad_strength",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Labels",
                "id": "ad_group_ad.labels",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "ad_group_ad.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "ad_group_ad.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "ad_group_ad.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Status",
                "id": "ad_group_ad.status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Enabled",
                "id": "ad_group_ad_asset_combination_view.enabled",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad Asset Combination View"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad_asset_combination_view.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad Asset Combination View"
            },
            {
                "name": "Served Assets",
                "id": "ad_group_ad_asset_combination_view.served_assets",
                "dataType": "OBJECT",
                "group": "Ad Group Ad Asset Combination View"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "ad_group_ad_asset_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Action Items",
                "id": "ad_group_ad.action_items",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Added By Google Ads",
                "id": "ad_group_ad.ad.added_by_google_ads",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Descriptions",
                "id": "ad_group_ad.ad.app_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Headlines",
                "id": "ad_group_ad.ad.app_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles Asset",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images",
                "id": "ad_group_ad.ad.app_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images Asset",
                "id": "ad_group_ad.ad.app_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Mandatory Ad Text",
                "id": "ad_group_ad.ad.app_ad.mandatory_ad_text",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Descriptions",
                "id": "ad_group_ad.ad.app_engagement_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Headlines",
                "id": "ad_group_ad.ad.app_engagement_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images",
                "id": "ad_group_ad.ad.app_engagement_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos",
                "id": "ad_group_ad.ad.app_engagement_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Descriptions",
                "id": "ad_group_ad.ad.app_pre_registration_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Headlines",
                "id": "ad_group_ad.ad.app_pre_registration_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Business Name",
                "id": "ad_group_ad.ad.call_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Call Tracked",
                "id": "ad_group_ad.ad.call_ad.call_tracked",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Action",
                "id": "ad_group_ad.ad.call_ad.conversion_action",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Reporting State",
                "id": "ad_group_ad.ad.call_ad.conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Country Code",
                "id": "ad_group_ad.ad.call_ad.country_code",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description1",
                "id": "ad_group_ad.ad.call_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description2",
                "id": "ad_group_ad.ad.call_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Disable Call Conversion",
                "id": "ad_group_ad.ad.call_ad.disable_call_conversion",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline1",
                "id": "ad_group_ad.ad.call_ad.headline1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline2",
                "id": "ad_group_ad.ad.call_ad.headline2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path1",
                "id": "ad_group_ad.ad.call_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path2",
                "id": "ad_group_ad.ad.call_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number",
                "id": "ad_group_ad.ad.call_ad.phone_number",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number Verification Url",
                "id": "ad_group_ad.ad.call_ad.phone_number_verification_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Device Preference",
                "id": "ad_group_ad.ad.device_preference",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Business Name",
                "id": "ad_group_ad.ad.discovery_carousel_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_carousel_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Description",
                "id": "ad_group_ad.ad.discovery_carousel_ad.description",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Headline",
                "id": "ad_group_ad.ad.discovery_carousel_ad.headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Business Name",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Descriptions",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Headlines",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Lead Form Only",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.lead_form_only",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Display Upload Product Type",
                "id": "ad_group_ad.ad.display_upload_ad.display_upload_product_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle Asset",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Url",
                "id": "ad_group_ad.ad.display_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description2",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description",
                "id": "ad_group_ad.ad.expanded_text_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description2",
                "id": "ad_group_ad.ad.expanded_text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part1",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part2",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part3",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part3",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path1",
                "id": "ad_group_ad.ad.expanded_text_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path2",
                "id": "ad_group_ad.ad.expanded_text_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final App Urls",
                "id": "ad_group_ad.ad.final_app_urls",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Mobile Urls",
                "id": "ad_group_ad.ad.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Url Suffix",
                "id": "ad_group_ad.ad.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Urls",
                "id": "ad_group_ad.ad.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Hotel Ad",
                "id": "ad_group_ad.ad.hotel_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Image Url",
                "id": "ad_group_ad.ad.image_ad.image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Media File",
                "id": "ad_group_ad.ad.image_ad.media_file",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Mime Type",
                "id": "ad_group_ad.ad.image_ad.mime_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Name",
                "id": "ad_group_ad.ad.image_ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Image Url",
                "id": "ad_group_ad.ad.image_ad.preview_image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy App Install Ad",
                "id": "ad_group_ad.ad.legacy_app_install_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Description",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.long_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Short Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.short_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Call To Actions",
                "id": "ad_group_ad.ad.local_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Descriptions",
                "id": "ad_group_ad.ad.local_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Headlines",
                "id": "ad_group_ad.ad.local_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images",
                "id": "ad_group_ad.ad.local_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images Asset",
                "id": "ad_group_ad.ad.local_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images",
                "id": "ad_group_ad.ad.local_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.local_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path1",
                "id": "ad_group_ad.ad.local_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path2",
                "id": "ad_group_ad.ad.local_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos",
                "id": "ad_group_ad.ad.local_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos Asset",
                "id": "ad_group_ad.ad.local_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Name",
                "id": "ad_group_ad.ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Resource Name",
                "id": "ad_group_ad.ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Asset Enhancements",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_asset_enhancements",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Autogen Video",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_autogen_video",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_display_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Headlines",
                "id": "ad_group_ad.ad.responsive_display_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.responsive_display_ad.long_headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_search_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Headlines",
                "id": "ad_group_ad.ad.responsive_search_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path1",
                "id": "ad_group_ad.ad.responsive_search_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path2",
                "id": "ad_group_ad.ad.responsive_search_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Comparison Listing Ad Headline",
                "id": "ad_group_ad.ad.shopping_comparison_listing_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Product Ad",
                "id": "ad_group_ad.ad.shopping_product_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Smart Ad",
                "id": "ad_group_ad.ad.shopping_smart_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Descriptions",
                "id": "ad_group_ad.ad.smart_campaign_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Headlines",
                "id": "ad_group_ad.ad.smart_campaign_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad System Managed Resource Source",
                "id": "ad_group_ad.ad.system_managed_resource_source",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description1",
                "id": "ad_group_ad.ad.text_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description2",
                "id": "ad_group_ad.ad.text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Headline",
                "id": "ad_group_ad.ad.text_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Tracking Url Template",
                "id": "ad_group_ad.ad.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Type",
                "id": "ad_group_ad.ad.type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Collections",
                "id": "ad_group_ad.ad.url_collections",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Custom Parameters",
                "id": "ad_group_ad.ad.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Bumper Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.bumper.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description1",
                "id": "ad_group_ad.ad.video_ad.in_feed.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description2",
                "id": "ad_group_ad.ad.video_ad.in_feed.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Headline",
                "id": "ad_group_ad.ad.video_ad.in_feed.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Thumbnail",
                "id": "ad_group_ad.ad.video_ad.in_feed.thumbnail",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Button Label",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Headline",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.in_stream.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Button Label",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Headline",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.non_skippable.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Description",
                "id": "ad_group_ad.ad.video_ad.out_stream.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Headline",
                "id": "ad_group_ad.ad.video_ad.out_stream.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Video Asset",
                "id": "ad_group_ad.ad.video_ad.video.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb1",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb2",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Call To Actions",
                "id": "ad_group_ad.ad.video_responsive_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Descriptions",
                "id": "ad_group_ad.ad.video_responsive_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Long Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.long_headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos",
                "id": "ad_group_ad.ad.video_responsive_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_ad.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Strength",
                "id": "ad_group_ad.ad_strength",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Labels",
                "id": "ad_group_ad.labels",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "ad_group_ad.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "ad_group_ad.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "ad_group_ad.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Status",
                "id": "ad_group_ad.status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group Ad",
                "id": "ad_group_ad_asset_view.ad_group_ad",
                "dataType": "STRING",
                "group": "Ad Group Ad Asset View"
            },
            {
                "name": "Asset",
                "id": "ad_group_ad_asset_view.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad Asset View"
            },
            {
                "name": "Enabled",
                "id": "ad_group_ad_asset_view.enabled",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad Asset View"
            },
            {
                "name": "Field Type",
                "id": "ad_group_ad_asset_view.field_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad Asset View"
            },
            {
                "name": "Performance Label",
                "id": "ad_group_ad_asset_view.performance_label",
                "dataType": "ENUM",
                "group": "Ad Group Ad Asset View"
            },
            {
                "name": "Pinned Field",
                "id": "ad_group_ad_asset_view.pinned_field",
                "dataType": "ENUM",
                "group": "Ad Group Ad Asset View"
            },
            {
                "name": "Policy Summary",
                "id": "ad_group_ad_asset_view.policy_summary",
                "dataType": "OBJECT",
                "group": "Ad Group Ad Asset View"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad_asset_view.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad Asset View"
            },
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "ad_group_ad_label": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Action Items",
                "id": "ad_group_ad.action_items",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Added By Google Ads",
                "id": "ad_group_ad.ad.added_by_google_ads",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Descriptions",
                "id": "ad_group_ad.ad.app_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Headlines",
                "id": "ad_group_ad.ad.app_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles Asset",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images",
                "id": "ad_group_ad.ad.app_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images Asset",
                "id": "ad_group_ad.ad.app_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Mandatory Ad Text",
                "id": "ad_group_ad.ad.app_ad.mandatory_ad_text",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Descriptions",
                "id": "ad_group_ad.ad.app_engagement_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Headlines",
                "id": "ad_group_ad.ad.app_engagement_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images",
                "id": "ad_group_ad.ad.app_engagement_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos",
                "id": "ad_group_ad.ad.app_engagement_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Descriptions",
                "id": "ad_group_ad.ad.app_pre_registration_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Headlines",
                "id": "ad_group_ad.ad.app_pre_registration_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Business Name",
                "id": "ad_group_ad.ad.call_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Call Tracked",
                "id": "ad_group_ad.ad.call_ad.call_tracked",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Action",
                "id": "ad_group_ad.ad.call_ad.conversion_action",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Reporting State",
                "id": "ad_group_ad.ad.call_ad.conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Country Code",
                "id": "ad_group_ad.ad.call_ad.country_code",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description1",
                "id": "ad_group_ad.ad.call_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description2",
                "id": "ad_group_ad.ad.call_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Disable Call Conversion",
                "id": "ad_group_ad.ad.call_ad.disable_call_conversion",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline1",
                "id": "ad_group_ad.ad.call_ad.headline1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline2",
                "id": "ad_group_ad.ad.call_ad.headline2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path1",
                "id": "ad_group_ad.ad.call_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path2",
                "id": "ad_group_ad.ad.call_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number",
                "id": "ad_group_ad.ad.call_ad.phone_number",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number Verification Url",
                "id": "ad_group_ad.ad.call_ad.phone_number_verification_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Device Preference",
                "id": "ad_group_ad.ad.device_preference",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Business Name",
                "id": "ad_group_ad.ad.discovery_carousel_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_carousel_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Description",
                "id": "ad_group_ad.ad.discovery_carousel_ad.description",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Headline",
                "id": "ad_group_ad.ad.discovery_carousel_ad.headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Business Name",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Descriptions",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Headlines",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Lead Form Only",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.lead_form_only",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Display Upload Product Type",
                "id": "ad_group_ad.ad.display_upload_ad.display_upload_product_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle Asset",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Url",
                "id": "ad_group_ad.ad.display_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description2",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description",
                "id": "ad_group_ad.ad.expanded_text_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description2",
                "id": "ad_group_ad.ad.expanded_text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part1",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part2",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part3",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part3",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path1",
                "id": "ad_group_ad.ad.expanded_text_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path2",
                "id": "ad_group_ad.ad.expanded_text_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final App Urls",
                "id": "ad_group_ad.ad.final_app_urls",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Mobile Urls",
                "id": "ad_group_ad.ad.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Url Suffix",
                "id": "ad_group_ad.ad.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Urls",
                "id": "ad_group_ad.ad.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Hotel Ad",
                "id": "ad_group_ad.ad.hotel_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Image Url",
                "id": "ad_group_ad.ad.image_ad.image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Media File",
                "id": "ad_group_ad.ad.image_ad.media_file",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Mime Type",
                "id": "ad_group_ad.ad.image_ad.mime_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Name",
                "id": "ad_group_ad.ad.image_ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Image Url",
                "id": "ad_group_ad.ad.image_ad.preview_image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy App Install Ad",
                "id": "ad_group_ad.ad.legacy_app_install_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Description",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.long_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Short Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.short_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Call To Actions",
                "id": "ad_group_ad.ad.local_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Descriptions",
                "id": "ad_group_ad.ad.local_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Headlines",
                "id": "ad_group_ad.ad.local_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images",
                "id": "ad_group_ad.ad.local_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images Asset",
                "id": "ad_group_ad.ad.local_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images",
                "id": "ad_group_ad.ad.local_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.local_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path1",
                "id": "ad_group_ad.ad.local_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path2",
                "id": "ad_group_ad.ad.local_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos",
                "id": "ad_group_ad.ad.local_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos Asset",
                "id": "ad_group_ad.ad.local_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Name",
                "id": "ad_group_ad.ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Resource Name",
                "id": "ad_group_ad.ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Asset Enhancements",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_asset_enhancements",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Autogen Video",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_autogen_video",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_display_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Headlines",
                "id": "ad_group_ad.ad.responsive_display_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.responsive_display_ad.long_headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_search_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Headlines",
                "id": "ad_group_ad.ad.responsive_search_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path1",
                "id": "ad_group_ad.ad.responsive_search_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path2",
                "id": "ad_group_ad.ad.responsive_search_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Comparison Listing Ad Headline",
                "id": "ad_group_ad.ad.shopping_comparison_listing_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Product Ad",
                "id": "ad_group_ad.ad.shopping_product_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Smart Ad",
                "id": "ad_group_ad.ad.shopping_smart_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Descriptions",
                "id": "ad_group_ad.ad.smart_campaign_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Headlines",
                "id": "ad_group_ad.ad.smart_campaign_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad System Managed Resource Source",
                "id": "ad_group_ad.ad.system_managed_resource_source",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description1",
                "id": "ad_group_ad.ad.text_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description2",
                "id": "ad_group_ad.ad.text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Headline",
                "id": "ad_group_ad.ad.text_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Tracking Url Template",
                "id": "ad_group_ad.ad.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Type",
                "id": "ad_group_ad.ad.type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Collections",
                "id": "ad_group_ad.ad.url_collections",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Custom Parameters",
                "id": "ad_group_ad.ad.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Bumper Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.bumper.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description1",
                "id": "ad_group_ad.ad.video_ad.in_feed.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description2",
                "id": "ad_group_ad.ad.video_ad.in_feed.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Headline",
                "id": "ad_group_ad.ad.video_ad.in_feed.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Thumbnail",
                "id": "ad_group_ad.ad.video_ad.in_feed.thumbnail",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Button Label",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Headline",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.in_stream.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Button Label",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Headline",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.non_skippable.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Description",
                "id": "ad_group_ad.ad.video_ad.out_stream.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Headline",
                "id": "ad_group_ad.ad.video_ad.out_stream.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Video Asset",
                "id": "ad_group_ad.ad.video_ad.video.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb1",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb2",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Call To Actions",
                "id": "ad_group_ad.ad.video_responsive_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Descriptions",
                "id": "ad_group_ad.ad.video_responsive_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Long Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.long_headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos",
                "id": "ad_group_ad.ad.video_responsive_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_ad.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Strength",
                "id": "ad_group_ad.ad_strength",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Labels",
                "id": "ad_group_ad.labels",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "ad_group_ad.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "ad_group_ad.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "ad_group_ad.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Status",
                "id": "ad_group_ad.status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group Ad",
                "id": "ad_group_ad_label.ad_group_ad",
                "dataType": "STRING",
                "group": "Ad Group Ad Label"
            },
            {
                "name": "Label",
                "id": "ad_group_ad_label.label",
                "dataType": "STRING",
                "group": "Ad Group Ad Label"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad_label.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad Label"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "label.name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Resource Name",
                "id": "label.resource_name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Status",
                "id": "label.status",
                "dataType": "ENUM",
                "group": "Label"
            },
            {
                "name": "Text Label Background Color",
                "id": "label.text_label.background_color",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Text Label Description",
                "id": "label.text_label.description",
                "dataType": "STRING",
                "group": "Label"
            }
        ],
        "ad_group_asset": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_asset.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Asset"
            },
            {
                "name": "Asset",
                "id": "ad_group_asset.asset",
                "dataType": "STRING",
                "group": "Ad Group Asset"
            },
            {
                "name": "Field Type",
                "id": "ad_group_asset.field_type",
                "dataType": "ENUM",
                "group": "Ad Group Asset"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_asset.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Asset"
            },
            {
                "name": "Source",
                "id": "ad_group_asset.source",
                "dataType": "ENUM",
                "group": "Ad Group Asset"
            },
            {
                "name": "Status",
                "id": "ad_group_asset.status",
                "dataType": "ENUM",
                "group": "Ad Group Asset"
            },
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Asset",
                "id": "segments.asset_interaction_target.asset",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Interaction On This Asset",
                "id": "segments.asset_interaction_target.interaction_on_this_asset",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "ad_group_asset_set": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_asset_set.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Asset Set"
            },
            {
                "name": "Asset Set",
                "id": "ad_group_asset_set.asset_set",
                "dataType": "STRING",
                "group": "Ad Group Asset Set"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_asset_set.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Asset Set"
            },
            {
                "name": "Status",
                "id": "ad_group_asset_set.status",
                "dataType": "ENUM",
                "group": "Ad Group Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Business Name",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.business_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Filter Type",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.filter_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Label Filters",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Business Name Filter",
                "id": "asset_set.location_set.business_profile_location_set.business_name_filter",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Label Filters",
                "id": "asset_set.location_set.business_profile_location_set.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Chain Location Set Relationship Type",
                "id": "asset_set.location_set.chain_location_set.relationship_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Location Ownership Type",
                "id": "asset_set.location_set.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Feed Label",
                "id": "asset_set.merchant_center_feed.feed_label",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Name",
                "id": "asset_set.name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Resource Name",
                "id": "asset_set.resource_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Status",
                "id": "asset_set.status",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Type",
                "id": "asset_set.type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "ad_group_audience_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_audience_view.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Audience View"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Access Reason",
                "id": "user_list.access_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Account User List Status",
                "id": "user_list.account_user_list_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Basic User List Actions",
                "id": "user_list.basic_user_list.actions",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Closing Reason",
                "id": "user_list.closing_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List App Id",
                "id": "user_list.crm_based_user_list.app_id",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Data Source Type",
                "id": "user_list.crm_based_user_list.data_source_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Upload Key Type",
                "id": "user_list.crm_based_user_list.upload_key_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Description",
                "id": "user_list.description",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Eligible For Display",
                "id": "user_list.eligible_for_display",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Eligible For Search",
                "id": "user_list.eligible_for_search",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Integration Code",
                "id": "user_list.integration_code",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Logical User List Rules",
                "id": "user_list.logical_user_list.rules",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Membership Status",
                "id": "user_list.membership_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Name",
                "id": "user_list.name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Read Only",
                "id": "user_list.read_only",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Resource Name",
                "id": "user_list.resource_name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Rule Operator",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Item Groups",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Type",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Exclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.exclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Rule Operator",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Prepopulation Status",
                "id": "user_list.rule_based_user_list.prepopulation_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Similar User List Seed User List",
                "id": "user_list.similar_user_list.seed_user_list",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Size Range For Display",
                "id": "user_list.size_range_for_display",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Size Range For Search",
                "id": "user_list.size_range_for_search",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Type",
                "id": "user_list.type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Hotel Date Selection Type",
                "id": "segments.hotel_date_selection_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "ad_group_bid_modifier": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_bid_modifier.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group_bid_modifier.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Bid Modifier Source",
                "id": "ad_group_bid_modifier.bid_modifier_source",
                "dataType": "ENUM",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Device Type",
                "id": "ad_group_bid_modifier.device.type",
                "dataType": "ENUM",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Hotel Check In Date Range End Date",
                "id": "ad_group_bid_modifier.hotel_check_in_date_range.end_date",
                "dataType": "STRING",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Hotel Check In Date Range Start Date",
                "id": "ad_group_bid_modifier.hotel_check_in_date_range.start_date",
                "dataType": "STRING",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Hotel Check In Day Day Of Week",
                "id": "ad_group_bid_modifier.hotel_check_in_day.day_of_week",
                "dataType": "ENUM",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Hotel Date Selection Type Type",
                "id": "ad_group_bid_modifier.hotel_date_selection_type.type",
                "dataType": "ENUM",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Preferred Content Type",
                "id": "ad_group_bid_modifier.preferred_content.type",
                "dataType": "ENUM",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_bid_modifier.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "ad_group_criterion": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "mobile_app_category_constant.name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Resource Name",
                "id": "mobile_app_category_constant.resource_name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Path",
                "id": "topic_constant.path",
                "dataType": "STRING",
                "group": "Topic Constant"
            },
            {
                "name": "Resource Name",
                "id": "topic_constant.resource_name",
                "dataType": "STRING",
                "group": "Topic Constant"
            },
            {
                "name": "Topic Constant Parent",
                "id": "topic_constant.topic_constant_parent",
                "dataType": "STRING",
                "group": "Topic Constant"
            },
            {
                "name": "Availabilities",
                "id": "user_interest.availabilities",
                "dataType": "OBJECT",
                "group": "User Interest"
            },
            {
                "name": "Launched To All",
                "id": "user_interest.launched_to_all",
                "dataType": "BOOLEAN",
                "group": "User Interest"
            },
            {
                "name": "Name",
                "id": "user_interest.name",
                "dataType": "STRING",
                "group": "User Interest"
            },
            {
                "name": "Resource Name",
                "id": "user_interest.resource_name",
                "dataType": "STRING",
                "group": "User Interest"
            },
            {
                "name": "Taxonomy Type",
                "id": "user_interest.taxonomy_type",
                "dataType": "ENUM",
                "group": "User Interest"
            },
            {
                "name": "User Interest Parent",
                "id": "user_interest.user_interest_parent",
                "dataType": "STRING",
                "group": "User Interest"
            },
            {
                "name": "Access Reason",
                "id": "user_list.access_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Account User List Status",
                "id": "user_list.account_user_list_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Basic User List Actions",
                "id": "user_list.basic_user_list.actions",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Closing Reason",
                "id": "user_list.closing_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List App Id",
                "id": "user_list.crm_based_user_list.app_id",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Data Source Type",
                "id": "user_list.crm_based_user_list.data_source_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Upload Key Type",
                "id": "user_list.crm_based_user_list.upload_key_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Description",
                "id": "user_list.description",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Eligible For Display",
                "id": "user_list.eligible_for_display",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Eligible For Search",
                "id": "user_list.eligible_for_search",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Integration Code",
                "id": "user_list.integration_code",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Logical User List Rules",
                "id": "user_list.logical_user_list.rules",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Membership Status",
                "id": "user_list.membership_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Name",
                "id": "user_list.name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Read Only",
                "id": "user_list.read_only",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Resource Name",
                "id": "user_list.resource_name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Rule Operator",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Item Groups",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Type",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Exclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.exclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Rule Operator",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Prepopulation Status",
                "id": "user_list.rule_based_user_list.prepopulation_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Similar User List Seed User List",
                "id": "user_list.similar_user_list.seed_user_list",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Size Range For Display",
                "id": "user_list.size_range_for_display",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Size Range For Search",
                "id": "user_list.size_range_for_search",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Type",
                "id": "user_list.type",
                "dataType": "ENUM",
                "group": "User List"
            }
        ],
        "ad_group_criterion_customizer": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Ad Group Criterion",
                "id": "ad_group_criterion_customizer.ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion Customizer"
            },
            {
                "name": "Customizer Attribute",
                "id": "ad_group_criterion_customizer.customizer_attribute",
                "dataType": "STRING",
                "group": "Ad Group Criterion Customizer"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion_customizer.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion Customizer"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion_customizer.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion Customizer"
            },
            {
                "name": "Value String Value",
                "id": "ad_group_criterion_customizer.value.string_value",
                "dataType": "STRING",
                "group": "Ad Group Criterion Customizer"
            },
            {
                "name": "Value Type",
                "id": "ad_group_criterion_customizer.value.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion Customizer"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "customizer_attribute.name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Resource Name",
                "id": "customizer_attribute.resource_name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Status",
                "id": "customizer_attribute.status",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            },
            {
                "name": "Type",
                "id": "customizer_attribute.type",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            }
        ],
        "ad_group_criterion_label": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Ad Group Criterion",
                "id": "ad_group_criterion_label.ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion Label"
            },
            {
                "name": "Label",
                "id": "ad_group_criterion_label.label",
                "dataType": "STRING",
                "group": "Ad Group Criterion Label"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion_label.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion Label"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "label.name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Resource Name",
                "id": "label.resource_name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Status",
                "id": "label.status",
                "dataType": "ENUM",
                "group": "Label"
            },
            {
                "name": "Text Label Background Color",
                "id": "label.text_label.background_color",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Text Label Description",
                "id": "label.text_label.description",
                "dataType": "STRING",
                "group": "Label"
            }
        ],
        "ad_group_criterion_simulation": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Point List Points",
                "id": "ad_group_criterion_simulation.cpc_bid_point_list.points",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion Simulation"
            },
            {
                "name": "End Date",
                "id": "ad_group_criterion_simulation.end_date",
                "dataType": "DATE",
                "group": "Ad Group Criterion Simulation"
            },
            {
                "name": "Modification Method",
                "id": "ad_group_criterion_simulation.modification_method",
                "dataType": "ENUM",
                "group": "Ad Group Criterion Simulation"
            },
            {
                "name": "Percent Cpc Bid Point List Points",
                "id": "ad_group_criterion_simulation.percent_cpc_bid_point_list.points",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion Simulation"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion_simulation.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion Simulation"
            },
            {
                "name": "Start Date",
                "id": "ad_group_criterion_simulation.start_date",
                "dataType": "DATE",
                "group": "Ad Group Criterion Simulation"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion_simulation.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion Simulation"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "ad_group_customizer": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_customizer.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Customizer"
            },
            {
                "name": "Customizer Attribute",
                "id": "ad_group_customizer.customizer_attribute",
                "dataType": "STRING",
                "group": "Ad Group Customizer"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_customizer.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Customizer"
            },
            {
                "name": "Status",
                "id": "ad_group_customizer.status",
                "dataType": "ENUM",
                "group": "Ad Group Customizer"
            },
            {
                "name": "Value String Value",
                "id": "ad_group_customizer.value.string_value",
                "dataType": "STRING",
                "group": "Ad Group Customizer"
            },
            {
                "name": "Value Type",
                "id": "ad_group_customizer.value.type",
                "dataType": "ENUM",
                "group": "Ad Group Customizer"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "customizer_attribute.name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Resource Name",
                "id": "customizer_attribute.resource_name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Status",
                "id": "customizer_attribute.status",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            },
            {
                "name": "Type",
                "id": "customizer_attribute.type",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            }
        ],
        "ad_group_extension_setting": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_extension_setting.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Extension Setting"
            },
            {
                "name": "Device",
                "id": "ad_group_extension_setting.device",
                "dataType": "ENUM",
                "group": "Ad Group Extension Setting"
            },
            {
                "name": "Extension Feed Items",
                "id": "ad_group_extension_setting.extension_feed_items",
                "dataType": "STRING",
                "group": "Ad Group Extension Setting"
            },
            {
                "name": "Extension Type",
                "id": "ad_group_extension_setting.extension_type",
                "dataType": "ENUM",
                "group": "Ad Group Extension Setting"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_extension_setting.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Extension Setting"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "ad_group_feed": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_feed.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Feed"
            },
            {
                "name": "Feed",
                "id": "ad_group_feed.feed",
                "dataType": "STRING",
                "group": "Ad Group Feed"
            },
            {
                "name": "Matching Function Function String",
                "id": "ad_group_feed.matching_function.function_string",
                "dataType": "STRING",
                "group": "Ad Group Feed"
            },
            {
                "name": "Matching Function Left Operands",
                "id": "ad_group_feed.matching_function.left_operands",
                "dataType": "OBJECT",
                "group": "Ad Group Feed"
            },
            {
                "name": "Matching Function Operator",
                "id": "ad_group_feed.matching_function.operator",
                "dataType": "ENUM",
                "group": "Ad Group Feed"
            },
            {
                "name": "Matching Function Right Operands",
                "id": "ad_group_feed.matching_function.right_operands",
                "dataType": "OBJECT",
                "group": "Ad Group Feed"
            },
            {
                "name": "Placeholder Types",
                "id": "ad_group_feed.placeholder_types",
                "dataType": "ENUM",
                "group": "Ad Group Feed"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_feed.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Feed"
            },
            {
                "name": "Status",
                "id": "ad_group_feed.status",
                "dataType": "ENUM",
                "group": "Ad Group Feed"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            }
        ],
        "ad_group_label": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_label.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Label"
            },
            {
                "name": "Label",
                "id": "ad_group_label.label",
                "dataType": "STRING",
                "group": "Ad Group Label"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_label.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Label"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "label.name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Resource Name",
                "id": "label.resource_name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Status",
                "id": "label.status",
                "dataType": "ENUM",
                "group": "Label"
            },
            {
                "name": "Text Label Background Color",
                "id": "label.text_label.background_color",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Text Label Description",
                "id": "label.text_label.description",
                "dataType": "STRING",
                "group": "Label"
            }
        ],
        "ad_group_simulation": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Cpc Bid Point List Points",
                "id": "ad_group_simulation.cpc_bid_point_list.points",
                "dataType": "OBJECT",
                "group": "Ad Group Simulation"
            },
            {
                "name": "Cpv Bid Point List Points",
                "id": "ad_group_simulation.cpv_bid_point_list.points",
                "dataType": "OBJECT",
                "group": "Ad Group Simulation"
            },
            {
                "name": "End Date",
                "id": "ad_group_simulation.end_date",
                "dataType": "DATE",
                "group": "Ad Group Simulation"
            },
            {
                "name": "Modification Method",
                "id": "ad_group_simulation.modification_method",
                "dataType": "ENUM",
                "group": "Ad Group Simulation"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_simulation.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Simulation"
            },
            {
                "name": "Start Date",
                "id": "ad_group_simulation.start_date",
                "dataType": "DATE",
                "group": "Ad Group Simulation"
            },
            {
                "name": "Target Cpa Point List Points",
                "id": "ad_group_simulation.target_cpa_point_list.points",
                "dataType": "OBJECT",
                "group": "Ad Group Simulation"
            },
            {
                "name": "Target Roas Point List Points",
                "id": "ad_group_simulation.target_roas_point_list.points",
                "dataType": "OBJECT",
                "group": "Ad Group Simulation"
            },
            {
                "name": "Type",
                "id": "ad_group_simulation.type",
                "dataType": "ENUM",
                "group": "Ad Group Simulation"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "ad_parameter": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Ad Group Criterion",
                "id": "ad_parameter.ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Parameter"
            },
            {
                "name": "Insertion Text",
                "id": "ad_parameter.insertion_text",
                "dataType": "STRING",
                "group": "Ad Parameter"
            },
            {
                "name": "Resource Name",
                "id": "ad_parameter.resource_name",
                "dataType": "STRING",
                "group": "Ad Parameter"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "ad_schedule_view": [
            {
                "name": "Resource Name",
                "id": "ad_schedule_view.resource_name",
                "dataType": "STRING",
                "group": "Ad Schedule View"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Ad Schedule Day Of Week",
                "id": "campaign_criterion.ad_schedule.day_of_week",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule End Minute",
                "id": "campaign_criterion.ad_schedule.end_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Minute",
                "id": "campaign_criterion.ad_schedule.start_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "campaign_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Campaign",
                "id": "campaign_criterion.campaign",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Carrier Carrier Constant",
                "id": "campaign_criterion.carrier.carrier_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "campaign_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Content Label Type",
                "id": "campaign_criterion.content_label.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "campaign_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "campaign_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Device Type",
                "id": "campaign_criterion.device.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Display Name",
                "id": "campaign_criterion.display_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Gender Type",
                "id": "campaign_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "campaign_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ip Block Ip Address",
                "id": "campaign_criterion.ip_block.ip_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "campaign_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "campaign_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Free Form Keyword Theme",
                "id": "campaign_criterion.keyword_theme.free_form_keyword_theme",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Keyword Theme Constant",
                "id": "campaign_criterion.keyword_theme.keyword_theme_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Language Language Constant",
                "id": "campaign_criterion.language.language_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions",
                "id": "campaign_criterion.listing_scope.dimensions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel City City Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel Country Region Country Region Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel State State Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Geo Target Constant",
                "id": "campaign_criterion.location.geo_target_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group",
                "id": "campaign_criterion.location_group",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Enable Customer Level Location Asset Set",
                "id": "campaign_criterion.location_group.enable_customer_level_location_asset_set",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Feed",
                "id": "campaign_criterion.location_group.feed",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Geo Target Constants",
                "id": "campaign_criterion.location_group.geo_target_constants",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "campaign_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "campaign_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "campaign_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Device Mobile Device Constant",
                "id": "campaign_criterion.mobile_device.mobile_device_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Negative",
                "id": "campaign_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Operating System Version Operating System Version Constant",
                "id": "campaign_criterion.operating_system_version.operating_system_version_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "campaign_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Placement Url",
                "id": "campaign_criterion.placement.url",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address City Name",
                "id": "campaign_criterion.proximity.address.city_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Country Code",
                "id": "campaign_criterion.proximity.address.country_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Postal Code",
                "id": "campaign_criterion.proximity.address.postal_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Code",
                "id": "campaign_criterion.proximity.address.province_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Name",
                "id": "campaign_criterion.proximity.address.province_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Street Address",
                "id": "campaign_criterion.proximity.address.street_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius Units",
                "id": "campaign_criterion.proximity.radius_units",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Resource Name",
                "id": "campaign_criterion.resource_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Status",
                "id": "campaign_criterion.status",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Path",
                "id": "campaign_criterion.topic.path",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "campaign_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Type",
                "id": "campaign_criterion.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "campaign_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "User List User List",
                "id": "campaign_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "campaign_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "campaign_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "campaign_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "campaign_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "campaign_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "age_range_view": [
            {
                "name": "Name",
                "id": "accessible_bidding_strategy.name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Descriptive Name",
                "id": "accessible_bidding_strategy.owner_descriptive_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "accessible_bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "accessible_bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "accessible_bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "age_range_view.resource_name",
                "dataType": "STRING",
                "group": "Age Range View"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "asset": [
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Adjustment",
                "id": "segments.conversion_adjustment",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Conversion Lag Bucket",
                "id": "segments.conversion_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Or Adjustment Lag Bucket",
                "id": "segments.conversion_or_adjustment_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            }
        ],
        "asset_field_type_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Field Type",
                "id": "asset_field_type_view.field_type",
                "dataType": "ENUM",
                "group": "Asset Field Type View"
            },
            {
                "name": "Resource Name",
                "id": "asset_field_type_view.resource_name",
                "dataType": "STRING",
                "group": "Asset Field Type View"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "asset_group": [
            {
                "name": "Ad Strength",
                "id": "asset_group.ad_strength",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Campaign",
                "id": "asset_group.campaign",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset_group.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Urls",
                "id": "asset_group.final_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Name",
                "id": "asset_group.name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path1",
                "id": "asset_group.path1",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path2",
                "id": "asset_group.path2",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Resource Name",
                "id": "asset_group.resource_name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Status",
                "id": "asset_group.status",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "asset_group_asset": [
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Ad Strength",
                "id": "asset_group.ad_strength",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Campaign",
                "id": "asset_group.campaign",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset_group.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Urls",
                "id": "asset_group.final_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Name",
                "id": "asset_group.name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path1",
                "id": "asset_group.path1",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path2",
                "id": "asset_group.path2",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Resource Name",
                "id": "asset_group.resource_name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Status",
                "id": "asset_group.status",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Asset",
                "id": "asset_group_asset.asset",
                "dataType": "STRING",
                "group": "Asset Group Asset"
            },
            {
                "name": "Asset Group",
                "id": "asset_group_asset.asset_group",
                "dataType": "STRING",
                "group": "Asset Group Asset"
            },
            {
                "name": "Field Type",
                "id": "asset_group_asset.field_type",
                "dataType": "ENUM",
                "group": "Asset Group Asset"
            },
            {
                "name": "Performance Label",
                "id": "asset_group_asset.performance_label",
                "dataType": "ENUM",
                "group": "Asset Group Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset_group_asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset Group Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset_group_asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset Group Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset_group_asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset Group Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset_group_asset.resource_name",
                "dataType": "STRING",
                "group": "Asset Group Asset"
            },
            {
                "name": "Status",
                "id": "asset_group_asset.status",
                "dataType": "ENUM",
                "group": "Asset Group Asset"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "asset_group_listing_group_filter": [
            {
                "name": "Ad Strength",
                "id": "asset_group.ad_strength",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Campaign",
                "id": "asset_group.campaign",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset_group.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Urls",
                "id": "asset_group.final_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Name",
                "id": "asset_group.name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path1",
                "id": "asset_group.path1",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path2",
                "id": "asset_group.path2",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Resource Name",
                "id": "asset_group.resource_name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Status",
                "id": "asset_group.status",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Asset Group",
                "id": "asset_group_listing_group_filter.asset_group",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Bidding Category Level",
                "id": "asset_group_listing_group_filter.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Brand Value",
                "id": "asset_group_listing_group_filter.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Channel Channel",
                "id": "asset_group_listing_group_filter.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Condition Condition",
                "id": "asset_group_listing_group_filter.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Custom Attribute Index",
                "id": "asset_group_listing_group_filter.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Custom Attribute Value",
                "id": "asset_group_listing_group_filter.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Item Id Value",
                "id": "asset_group_listing_group_filter.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Type Level",
                "id": "asset_group_listing_group_filter.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Type Value",
                "id": "asset_group_listing_group_filter.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Parent Listing Group Filter",
                "id": "asset_group_listing_group_filter.parent_listing_group_filter",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Resource Name",
                "id": "asset_group_listing_group_filter.resource_name",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Type",
                "id": "asset_group_listing_group_filter.type",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Vertical",
                "id": "asset_group_listing_group_filter.vertical",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "asset_group_product_group_view": [
            {
                "name": "Ad Strength",
                "id": "asset_group.ad_strength",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Campaign",
                "id": "asset_group.campaign",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset_group.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Urls",
                "id": "asset_group.final_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Name",
                "id": "asset_group.name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path1",
                "id": "asset_group.path1",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path2",
                "id": "asset_group.path2",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Resource Name",
                "id": "asset_group.resource_name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Status",
                "id": "asset_group.status",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Asset Group",
                "id": "asset_group_listing_group_filter.asset_group",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Bidding Category Level",
                "id": "asset_group_listing_group_filter.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Brand Value",
                "id": "asset_group_listing_group_filter.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Channel Channel",
                "id": "asset_group_listing_group_filter.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Condition Condition",
                "id": "asset_group_listing_group_filter.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Custom Attribute Index",
                "id": "asset_group_listing_group_filter.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Custom Attribute Value",
                "id": "asset_group_listing_group_filter.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Item Id Value",
                "id": "asset_group_listing_group_filter.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Type Level",
                "id": "asset_group_listing_group_filter.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Case Value Product Type Value",
                "id": "asset_group_listing_group_filter.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Parent Listing Group Filter",
                "id": "asset_group_listing_group_filter.parent_listing_group_filter",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Resource Name",
                "id": "asset_group_listing_group_filter.resource_name",
                "dataType": "STRING",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Type",
                "id": "asset_group_listing_group_filter.type",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Vertical",
                "id": "asset_group_listing_group_filter.vertical",
                "dataType": "ENUM",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Asset Group",
                "id": "asset_group_product_group_view.asset_group",
                "dataType": "STRING",
                "group": "Asset Group Product Group View"
            },
            {
                "name": "Asset Group Listing Group Filter",
                "id": "asset_group_product_group_view.asset_group_listing_group_filter",
                "dataType": "STRING",
                "group": "Asset Group Product Group View"
            },
            {
                "name": "Resource Name",
                "id": "asset_group_product_group_view.resource_name",
                "dataType": "STRING",
                "group": "Asset Group Product Group View"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "asset_group_signal": [
            {
                "name": "Ad Strength",
                "id": "asset_group.ad_strength",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Campaign",
                "id": "asset_group.campaign",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset_group.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Final Urls",
                "id": "asset_group.final_urls",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Name",
                "id": "asset_group.name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path1",
                "id": "asset_group.path1",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Path2",
                "id": "asset_group.path2",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Resource Name",
                "id": "asset_group.resource_name",
                "dataType": "STRING",
                "group": "Asset Group"
            },
            {
                "name": "Status",
                "id": "asset_group.status",
                "dataType": "ENUM",
                "group": "Asset Group"
            },
            {
                "name": "Asset Group",
                "id": "asset_group_signal.asset_group",
                "dataType": "STRING",
                "group": "Asset Group Signal"
            },
            {
                "name": "Audience Audience",
                "id": "asset_group_signal.audience.audience",
                "dataType": "STRING",
                "group": "Asset Group Signal"
            },
            {
                "name": "Resource Name",
                "id": "asset_group_signal.resource_name",
                "dataType": "STRING",
                "group": "Asset Group Signal"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "asset_set": [
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Business Name",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.business_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Filter Type",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.filter_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Label Filters",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Business Name Filter",
                "id": "asset_set.location_set.business_profile_location_set.business_name_filter",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Label Filters",
                "id": "asset_set.location_set.business_profile_location_set.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Chain Location Set Relationship Type",
                "id": "asset_set.location_set.chain_location_set.relationship_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Location Ownership Type",
                "id": "asset_set.location_set.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Feed Label",
                "id": "asset_set.merchant_center_feed.feed_label",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Name",
                "id": "asset_set.name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Resource Name",
                "id": "asset_set.resource_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Status",
                "id": "asset_set.status",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Type",
                "id": "asset_set.type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "asset_set_asset": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Business Name",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.business_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Filter Type",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.filter_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Label Filters",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Business Name Filter",
                "id": "asset_set.location_set.business_profile_location_set.business_name_filter",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Label Filters",
                "id": "asset_set.location_set.business_profile_location_set.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Chain Location Set Relationship Type",
                "id": "asset_set.location_set.chain_location_set.relationship_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Location Ownership Type",
                "id": "asset_set.location_set.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Feed Label",
                "id": "asset_set.merchant_center_feed.feed_label",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Name",
                "id": "asset_set.name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Resource Name",
                "id": "asset_set.resource_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Status",
                "id": "asset_set.status",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Type",
                "id": "asset_set.type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Asset",
                "id": "asset_set_asset.asset",
                "dataType": "STRING",
                "group": "Asset Set Asset"
            },
            {
                "name": "Asset Set",
                "id": "asset_set_asset.asset_set",
                "dataType": "STRING",
                "group": "Asset Set Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset_set_asset.resource_name",
                "dataType": "STRING",
                "group": "Asset Set Asset"
            },
            {
                "name": "Status",
                "id": "asset_set_asset.status",
                "dataType": "ENUM",
                "group": "Asset Set Asset"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Asset",
                "id": "segments.asset_interaction_target.asset",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Interaction On This Asset",
                "id": "segments.asset_interaction_target.interaction_on_this_asset",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "asset_set_type_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Asset Set Type",
                "id": "asset_set_type_view.asset_set_type",
                "dataType": "ENUM",
                "group": "Asset Set Type View"
            },
            {
                "name": "Resource Name",
                "id": "asset_set_type_view.resource_name",
                "dataType": "STRING",
                "group": "Asset Set Type View"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "audience": [
            {
                "name": "Description",
                "id": "audience.description",
                "dataType": "STRING",
                "group": "Audience"
            },
            {
                "name": "Dimensions",
                "id": "audience.dimensions",
                "dataType": "OBJECT",
                "group": "Audience"
            },
            {
                "name": "Dimensions Audience Segments Segments Custom Audience Custom Audience",
                "id": "audience.dimensions.audience_segments.segments.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Audience"
            },
            {
                "name": "Dimensions Audience Segments Segments Detailed Demographic Detailed Demographic",
                "id": "audience.dimensions.audience_segments.segments.detailed_demographic.detailed_demographic",
                "dataType": "STRING",
                "group": "Audience"
            },
            {
                "name": "Dimensions Audience Segments Segments Life Event Life Event",
                "id": "audience.dimensions.audience_segments.segments.life_event.life_event",
                "dataType": "STRING",
                "group": "Audience"
            },
            {
                "name": "Dimensions Audience Segments Segments User Interest User Interest Category",
                "id": "audience.dimensions.audience_segments.segments.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Audience"
            },
            {
                "name": "Dimensions Audience Segments Segments User List User List",
                "id": "audience.dimensions.audience_segments.segments.user_list.user_list",
                "dataType": "STRING",
                "group": "Audience"
            },
            {
                "name": "Exclusion Dimension",
                "id": "audience.exclusion_dimension",
                "dataType": "OBJECT",
                "group": "Audience"
            },
            {
                "name": "Exclusion Dimension Exclusions User List User List",
                "id": "audience.exclusion_dimension.exclusions.user_list.user_list",
                "dataType": "STRING",
                "group": "Audience"
            },
            {
                "name": "Name",
                "id": "audience.name",
                "dataType": "STRING",
                "group": "Audience"
            },
            {
                "name": "Resource Name",
                "id": "audience.resource_name",
                "dataType": "STRING",
                "group": "Audience"
            },
            {
                "name": "Status",
                "id": "audience.status",
                "dataType": "ENUM",
                "group": "Audience"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "batch_job": [
            {
                "name": "Long Running Operation",
                "id": "batch_job.long_running_operation",
                "dataType": "STRING",
                "group": "Batch Job"
            },
            {
                "name": "Metadata Completion Date Time",
                "id": "batch_job.metadata.completion_date_time",
                "dataType": "DATE",
                "group": "Batch Job"
            },
            {
                "name": "Metadata Creation Date Time",
                "id": "batch_job.metadata.creation_date_time",
                "dataType": "DATE",
                "group": "Batch Job"
            },
            {
                "name": "Metadata Start Date Time",
                "id": "batch_job.metadata.start_date_time",
                "dataType": "DATE",
                "group": "Batch Job"
            },
            {
                "name": "Next Add Sequence Token",
                "id": "batch_job.next_add_sequence_token",
                "dataType": "STRING",
                "group": "Batch Job"
            },
            {
                "name": "Resource Name",
                "id": "batch_job.resource_name",
                "dataType": "STRING",
                "group": "Batch Job"
            },
            {
                "name": "Status",
                "id": "batch_job.status",
                "dataType": "ENUM",
                "group": "Batch Job"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "bidding_data_exclusion": [
            {
                "name": "Advertising Channel Types",
                "id": "bidding_data_exclusion.advertising_channel_types",
                "dataType": "ENUM",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Campaigns",
                "id": "bidding_data_exclusion.campaigns",
                "dataType": "STRING",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Description",
                "id": "bidding_data_exclusion.description",
                "dataType": "STRING",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Devices",
                "id": "bidding_data_exclusion.devices",
                "dataType": "ENUM",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "End Date Time",
                "id": "bidding_data_exclusion.end_date_time",
                "dataType": "STRING",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Name",
                "id": "bidding_data_exclusion.name",
                "dataType": "STRING",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Resource Name",
                "id": "bidding_data_exclusion.resource_name",
                "dataType": "STRING",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Scope",
                "id": "bidding_data_exclusion.scope",
                "dataType": "ENUM",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Start Date Time",
                "id": "bidding_data_exclusion.start_date_time",
                "dataType": "STRING",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Status",
                "id": "bidding_data_exclusion.status",
                "dataType": "ENUM",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "bidding_seasonality_adjustment": [
            {
                "name": "Advertising Channel Types",
                "id": "bidding_seasonality_adjustment.advertising_channel_types",
                "dataType": "ENUM",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Campaigns",
                "id": "bidding_seasonality_adjustment.campaigns",
                "dataType": "STRING",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Description",
                "id": "bidding_seasonality_adjustment.description",
                "dataType": "STRING",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Devices",
                "id": "bidding_seasonality_adjustment.devices",
                "dataType": "ENUM",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "End Date Time",
                "id": "bidding_seasonality_adjustment.end_date_time",
                "dataType": "STRING",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Name",
                "id": "bidding_seasonality_adjustment.name",
                "dataType": "STRING",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Resource Name",
                "id": "bidding_seasonality_adjustment.resource_name",
                "dataType": "STRING",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Scope",
                "id": "bidding_seasonality_adjustment.scope",
                "dataType": "ENUM",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Start Date Time",
                "id": "bidding_seasonality_adjustment.start_date_time",
                "dataType": "STRING",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Status",
                "id": "bidding_seasonality_adjustment.status",
                "dataType": "ENUM",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "bidding_strategy": [
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "bidding_strategy_simulation": [
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "End Date",
                "id": "bidding_strategy_simulation.end_date",
                "dataType": "DATE",
                "group": "Bidding Strategy Simulation"
            },
            {
                "name": "Modification Method",
                "id": "bidding_strategy_simulation.modification_method",
                "dataType": "ENUM",
                "group": "Bidding Strategy Simulation"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy_simulation.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy Simulation"
            },
            {
                "name": "Start Date",
                "id": "bidding_strategy_simulation.start_date",
                "dataType": "DATE",
                "group": "Bidding Strategy Simulation"
            },
            {
                "name": "Target Cpa Point List Points",
                "id": "bidding_strategy_simulation.target_cpa_point_list.points",
                "dataType": "OBJECT",
                "group": "Bidding Strategy Simulation"
            },
            {
                "name": "Target Roas Point List Points",
                "id": "bidding_strategy_simulation.target_roas_point_list.points",
                "dataType": "OBJECT",
                "group": "Bidding Strategy Simulation"
            },
            {
                "name": "Type",
                "id": "bidding_strategy_simulation.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy Simulation"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "billing_setup": [
            {
                "name": "End Date Time",
                "id": "billing_setup.end_date_time",
                "dataType": "DATE",
                "group": "Billing Setup"
            },
            {
                "name": "End Time Type",
                "id": "billing_setup.end_time_type",
                "dataType": "ENUM",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account",
                "id": "billing_setup.payments_account",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Account Id",
                "id": "billing_setup.payments_account_info.payments_account_id",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Account Name",
                "id": "billing_setup.payments_account_info.payments_account_name",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Profile Id",
                "id": "billing_setup.payments_account_info.payments_profile_id",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Payments Profile Name",
                "id": "billing_setup.payments_account_info.payments_profile_name",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Payments Account Info Secondary Payments Profile Id",
                "id": "billing_setup.payments_account_info.secondary_payments_profile_id",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Resource Name",
                "id": "billing_setup.resource_name",
                "dataType": "STRING",
                "group": "Billing Setup"
            },
            {
                "name": "Start Date Time",
                "id": "billing_setup.start_date_time",
                "dataType": "DATE",
                "group": "Billing Setup"
            },
            {
                "name": "Status",
                "id": "billing_setup.status",
                "dataType": "ENUM",
                "group": "Billing Setup"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "call_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Call Status",
                "id": "call_view.call_status",
                "dataType": "ENUM",
                "group": "Call View"
            },
            {
                "name": "Call Tracking Display Location",
                "id": "call_view.call_tracking_display_location",
                "dataType": "ENUM",
                "group": "Call View"
            },
            {
                "name": "Caller Area Code",
                "id": "call_view.caller_area_code",
                "dataType": "STRING",
                "group": "Call View"
            },
            {
                "name": "Caller Country Code",
                "id": "call_view.caller_country_code",
                "dataType": "STRING",
                "group": "Call View"
            },
            {
                "name": "End Call Date Time",
                "id": "call_view.end_call_date_time",
                "dataType": "DATE",
                "group": "Call View"
            },
            {
                "name": "Resource Name",
                "id": "call_view.resource_name",
                "dataType": "STRING",
                "group": "Call View"
            },
            {
                "name": "Start Call Date Time",
                "id": "call_view.start_call_date_time",
                "dataType": "DATE",
                "group": "Call View"
            },
            {
                "name": "Type",
                "id": "call_view.type",
                "dataType": "ENUM",
                "group": "Call View"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "campaign": [
            {
                "name": "Name",
                "id": "accessible_bidding_strategy.name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Descriptive Name",
                "id": "accessible_bidding_strategy.owner_descriptive_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "accessible_bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "accessible_bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "accessible_bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Delivery Method",
                "id": "campaign_budget.delivery_method",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Explicitly Shared",
                "id": "campaign_budget.explicitly_shared",
                "dataType": "BOOLEAN",
                "group": "Campaign Budget"
            },
            {
                "name": "Has Recommended Budget",
                "id": "campaign_budget.has_recommended_budget",
                "dataType": "BOOLEAN",
                "group": "Campaign Budget"
            },
            {
                "name": "Name",
                "id": "campaign_budget.name",
                "dataType": "STRING",
                "group": "Campaign Budget"
            },
            {
                "name": "Period",
                "id": "campaign_budget.period",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Resource Name",
                "id": "campaign_budget.resource_name",
                "dataType": "STRING",
                "group": "Campaign Budget"
            },
            {
                "name": "Status",
                "id": "campaign_budget.status",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Type",
                "id": "campaign_budget.type",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Name",
                "id": "campaign_group.name",
                "dataType": "STRING",
                "group": "Campaign Group"
            },
            {
                "name": "Resource Name",
                "id": "campaign_group.resource_name",
                "dataType": "STRING",
                "group": "Campaign Group"
            },
            {
                "name": "Status",
                "id": "campaign_group.status",
                "dataType": "ENUM",
                "group": "Campaign Group"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Destination Type",
                "id": "segments.ad_destination_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Asset",
                "id": "segments.asset_interaction_target.asset",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Interaction On This Asset",
                "id": "segments.asset_interaction_target.interaction_on_this_asset",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Auction Insight Domain",
                "id": "segments.auction_insight_domain",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Adjustment",
                "id": "segments.conversion_adjustment",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Conversion Attribution Event Type",
                "id": "segments.conversion_attribution_event_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Lag Bucket",
                "id": "segments.conversion_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Or Adjustment Lag Bucket",
                "id": "segments.conversion_or_adjustment_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Value Rule Primary Dimension",
                "id": "segments.conversion_value_rule_primary_dimension",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Recommendation Type",
                "id": "segments.recommendation_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network Ad Event Type",
                "id": "segments.sk_ad_network_ad_event_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network Attribution Credit",
                "id": "segments.sk_ad_network_attribution_credit",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network Source App Sk Ad Network Source App Id",
                "id": "segments.sk_ad_network_source_app.sk_ad_network_source_app_id",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network User Type",
                "id": "segments.sk_ad_network_user_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            },
            {
                "name": "Optimization Score Url",
                "id": "metrics.optimization_score_url",
                "dataType": "STRING",
                "group": "Metrics"
            }
        ],
        "campaign_asset": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Asset",
                "id": "campaign_asset.asset",
                "dataType": "STRING",
                "group": "Campaign Asset"
            },
            {
                "name": "Campaign",
                "id": "campaign_asset.campaign",
                "dataType": "STRING",
                "group": "Campaign Asset"
            },
            {
                "name": "Field Type",
                "id": "campaign_asset.field_type",
                "dataType": "ENUM",
                "group": "Campaign Asset"
            },
            {
                "name": "Resource Name",
                "id": "campaign_asset.resource_name",
                "dataType": "STRING",
                "group": "Campaign Asset"
            },
            {
                "name": "Source",
                "id": "campaign_asset.source",
                "dataType": "ENUM",
                "group": "Campaign Asset"
            },
            {
                "name": "Status",
                "id": "campaign_asset.status",
                "dataType": "ENUM",
                "group": "Campaign Asset"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Asset",
                "id": "segments.asset_interaction_target.asset",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Interaction On This Asset",
                "id": "segments.asset_interaction_target.interaction_on_this_asset",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "campaign_asset_set": [
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Business Name",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.business_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Filter Type",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.filter_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Label Filters",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Business Name Filter",
                "id": "asset_set.location_set.business_profile_location_set.business_name_filter",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Label Filters",
                "id": "asset_set.location_set.business_profile_location_set.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Chain Location Set Relationship Type",
                "id": "asset_set.location_set.chain_location_set.relationship_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Location Ownership Type",
                "id": "asset_set.location_set.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Feed Label",
                "id": "asset_set.merchant_center_feed.feed_label",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Name",
                "id": "asset_set.name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Resource Name",
                "id": "asset_set.resource_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Status",
                "id": "asset_set.status",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Type",
                "id": "asset_set.type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Asset Set",
                "id": "campaign_asset_set.asset_set",
                "dataType": "STRING",
                "group": "Campaign Asset Set"
            },
            {
                "name": "Campaign",
                "id": "campaign_asset_set.campaign",
                "dataType": "STRING",
                "group": "Campaign Asset Set"
            },
            {
                "name": "Resource Name",
                "id": "campaign_asset_set.resource_name",
                "dataType": "STRING",
                "group": "Campaign Asset Set"
            },
            {
                "name": "Status",
                "id": "campaign_asset_set.status",
                "dataType": "ENUM",
                "group": "Campaign Asset Set"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "campaign_audience_view": [
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign_audience_view.resource_name",
                "dataType": "STRING",
                "group": "Campaign Audience View"
            },
            {
                "name": "Ad Schedule Day Of Week",
                "id": "campaign_criterion.ad_schedule.day_of_week",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule End Minute",
                "id": "campaign_criterion.ad_schedule.end_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Minute",
                "id": "campaign_criterion.ad_schedule.start_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "campaign_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Campaign",
                "id": "campaign_criterion.campaign",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Carrier Carrier Constant",
                "id": "campaign_criterion.carrier.carrier_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "campaign_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Content Label Type",
                "id": "campaign_criterion.content_label.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "campaign_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "campaign_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Device Type",
                "id": "campaign_criterion.device.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Display Name",
                "id": "campaign_criterion.display_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Gender Type",
                "id": "campaign_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "campaign_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ip Block Ip Address",
                "id": "campaign_criterion.ip_block.ip_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "campaign_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "campaign_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Free Form Keyword Theme",
                "id": "campaign_criterion.keyword_theme.free_form_keyword_theme",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Keyword Theme Constant",
                "id": "campaign_criterion.keyword_theme.keyword_theme_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Language Language Constant",
                "id": "campaign_criterion.language.language_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions",
                "id": "campaign_criterion.listing_scope.dimensions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel City City Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel Country Region Country Region Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel State State Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Geo Target Constant",
                "id": "campaign_criterion.location.geo_target_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group",
                "id": "campaign_criterion.location_group",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Enable Customer Level Location Asset Set",
                "id": "campaign_criterion.location_group.enable_customer_level_location_asset_set",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Feed",
                "id": "campaign_criterion.location_group.feed",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Geo Target Constants",
                "id": "campaign_criterion.location_group.geo_target_constants",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "campaign_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "campaign_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "campaign_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Device Mobile Device Constant",
                "id": "campaign_criterion.mobile_device.mobile_device_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Negative",
                "id": "campaign_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Operating System Version Operating System Version Constant",
                "id": "campaign_criterion.operating_system_version.operating_system_version_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "campaign_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Placement Url",
                "id": "campaign_criterion.placement.url",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address City Name",
                "id": "campaign_criterion.proximity.address.city_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Country Code",
                "id": "campaign_criterion.proximity.address.country_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Postal Code",
                "id": "campaign_criterion.proximity.address.postal_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Code",
                "id": "campaign_criterion.proximity.address.province_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Name",
                "id": "campaign_criterion.proximity.address.province_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Street Address",
                "id": "campaign_criterion.proximity.address.street_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius Units",
                "id": "campaign_criterion.proximity.radius_units",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Resource Name",
                "id": "campaign_criterion.resource_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Status",
                "id": "campaign_criterion.status",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Path",
                "id": "campaign_criterion.topic.path",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "campaign_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Type",
                "id": "campaign_criterion.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "campaign_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "User List User List",
                "id": "campaign_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "campaign_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "campaign_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "campaign_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "campaign_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "campaign_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Access Reason",
                "id": "user_list.access_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Account User List Status",
                "id": "user_list.account_user_list_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Basic User List Actions",
                "id": "user_list.basic_user_list.actions",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Closing Reason",
                "id": "user_list.closing_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List App Id",
                "id": "user_list.crm_based_user_list.app_id",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Data Source Type",
                "id": "user_list.crm_based_user_list.data_source_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Upload Key Type",
                "id": "user_list.crm_based_user_list.upload_key_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Description",
                "id": "user_list.description",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Eligible For Display",
                "id": "user_list.eligible_for_display",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Eligible For Search",
                "id": "user_list.eligible_for_search",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Integration Code",
                "id": "user_list.integration_code",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Logical User List Rules",
                "id": "user_list.logical_user_list.rules",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Membership Status",
                "id": "user_list.membership_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Name",
                "id": "user_list.name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Read Only",
                "id": "user_list.read_only",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Resource Name",
                "id": "user_list.resource_name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Rule Operator",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Item Groups",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Type",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Exclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.exclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Rule Operator",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Prepopulation Status",
                "id": "user_list.rule_based_user_list.prepopulation_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Similar User List Seed User List",
                "id": "user_list.similar_user_list.seed_user_list",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Size Range For Display",
                "id": "user_list.size_range_for_display",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Size Range For Search",
                "id": "user_list.size_range_for_search",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Type",
                "id": "user_list.type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Hotel Date Selection Type",
                "id": "segments.hotel_date_selection_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "campaign_bid_modifier": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign",
                "id": "campaign_bid_modifier.campaign",
                "dataType": "STRING",
                "group": "Campaign Bid Modifier"
            },
            {
                "name": "Interaction Type Type",
                "id": "campaign_bid_modifier.interaction_type.type",
                "dataType": "ENUM",
                "group": "Campaign Bid Modifier"
            },
            {
                "name": "Resource Name",
                "id": "campaign_bid_modifier.resource_name",
                "dataType": "STRING",
                "group": "Campaign Bid Modifier"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "campaign_budget": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Delivery Method",
                "id": "campaign_budget.delivery_method",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Explicitly Shared",
                "id": "campaign_budget.explicitly_shared",
                "dataType": "BOOLEAN",
                "group": "Campaign Budget"
            },
            {
                "name": "Has Recommended Budget",
                "id": "campaign_budget.has_recommended_budget",
                "dataType": "BOOLEAN",
                "group": "Campaign Budget"
            },
            {
                "name": "Name",
                "id": "campaign_budget.name",
                "dataType": "STRING",
                "group": "Campaign Budget"
            },
            {
                "name": "Period",
                "id": "campaign_budget.period",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Resource Name",
                "id": "campaign_budget.resource_name",
                "dataType": "STRING",
                "group": "Campaign Budget"
            },
            {
                "name": "Status",
                "id": "campaign_budget.status",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Type",
                "id": "campaign_budget.type",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Budget Campaign Association Status Campaign",
                "id": "segments.budget_campaign_association_status.campaign",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Budget Campaign Association Status Status",
                "id": "segments.budget_campaign_association_status.status",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "campaign_conversion_goal": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Biddable",
                "id": "campaign_conversion_goal.biddable",
                "dataType": "BOOLEAN",
                "group": "Campaign Conversion Goal"
            },
            {
                "name": "Campaign",
                "id": "campaign_conversion_goal.campaign",
                "dataType": "STRING",
                "group": "Campaign Conversion Goal"
            },
            {
                "name": "Category",
                "id": "campaign_conversion_goal.category",
                "dataType": "ENUM",
                "group": "Campaign Conversion Goal"
            },
            {
                "name": "Origin",
                "id": "campaign_conversion_goal.origin",
                "dataType": "ENUM",
                "group": "Campaign Conversion Goal"
            },
            {
                "name": "Resource Name",
                "id": "campaign_conversion_goal.resource_name",
                "dataType": "STRING",
                "group": "Campaign Conversion Goal"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "campaign_criterion": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Ad Schedule Day Of Week",
                "id": "campaign_criterion.ad_schedule.day_of_week",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule End Minute",
                "id": "campaign_criterion.ad_schedule.end_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Minute",
                "id": "campaign_criterion.ad_schedule.start_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "campaign_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Campaign",
                "id": "campaign_criterion.campaign",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Carrier Carrier Constant",
                "id": "campaign_criterion.carrier.carrier_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "campaign_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Content Label Type",
                "id": "campaign_criterion.content_label.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "campaign_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "campaign_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Device Type",
                "id": "campaign_criterion.device.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Display Name",
                "id": "campaign_criterion.display_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Gender Type",
                "id": "campaign_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "campaign_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ip Block Ip Address",
                "id": "campaign_criterion.ip_block.ip_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "campaign_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "campaign_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Free Form Keyword Theme",
                "id": "campaign_criterion.keyword_theme.free_form_keyword_theme",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Keyword Theme Constant",
                "id": "campaign_criterion.keyword_theme.keyword_theme_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Language Language Constant",
                "id": "campaign_criterion.language.language_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions",
                "id": "campaign_criterion.listing_scope.dimensions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel City City Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel Country Region Country Region Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel State State Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Geo Target Constant",
                "id": "campaign_criterion.location.geo_target_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group",
                "id": "campaign_criterion.location_group",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Enable Customer Level Location Asset Set",
                "id": "campaign_criterion.location_group.enable_customer_level_location_asset_set",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Feed",
                "id": "campaign_criterion.location_group.feed",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Geo Target Constants",
                "id": "campaign_criterion.location_group.geo_target_constants",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "campaign_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "campaign_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "campaign_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Device Mobile Device Constant",
                "id": "campaign_criterion.mobile_device.mobile_device_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Negative",
                "id": "campaign_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Operating System Version Operating System Version Constant",
                "id": "campaign_criterion.operating_system_version.operating_system_version_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "campaign_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Placement Url",
                "id": "campaign_criterion.placement.url",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address City Name",
                "id": "campaign_criterion.proximity.address.city_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Country Code",
                "id": "campaign_criterion.proximity.address.country_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Postal Code",
                "id": "campaign_criterion.proximity.address.postal_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Code",
                "id": "campaign_criterion.proximity.address.province_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Name",
                "id": "campaign_criterion.proximity.address.province_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Street Address",
                "id": "campaign_criterion.proximity.address.street_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius Units",
                "id": "campaign_criterion.proximity.radius_units",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Resource Name",
                "id": "campaign_criterion.resource_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Status",
                "id": "campaign_criterion.status",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Path",
                "id": "campaign_criterion.topic.path",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "campaign_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Type",
                "id": "campaign_criterion.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "campaign_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "User List User List",
                "id": "campaign_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "campaign_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "campaign_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "campaign_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "campaign_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "campaign_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Country Code",
                "id": "carrier_constant.country_code",
                "dataType": "STRING",
                "group": "Carrier Constant"
            },
            {
                "name": "Name",
                "id": "carrier_constant.name",
                "dataType": "STRING",
                "group": "Carrier Constant"
            },
            {
                "name": "Resource Name",
                "id": "carrier_constant.resource_name",
                "dataType": "STRING",
                "group": "Carrier Constant"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Code",
                "id": "language_constant.code",
                "dataType": "STRING",
                "group": "Language Constant"
            },
            {
                "name": "Name",
                "id": "language_constant.name",
                "dataType": "STRING",
                "group": "Language Constant"
            },
            {
                "name": "Resource Name",
                "id": "language_constant.resource_name",
                "dataType": "STRING",
                "group": "Language Constant"
            },
            {
                "name": "Targetable",
                "id": "language_constant.targetable",
                "dataType": "BOOLEAN",
                "group": "Language Constant"
            },
            {
                "name": "Name",
                "id": "mobile_app_category_constant.name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Resource Name",
                "id": "mobile_app_category_constant.resource_name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Manufacturer Name",
                "id": "mobile_device_constant.manufacturer_name",
                "dataType": "STRING",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Name",
                "id": "mobile_device_constant.name",
                "dataType": "STRING",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Operating System Name",
                "id": "mobile_device_constant.operating_system_name",
                "dataType": "STRING",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Resource Name",
                "id": "mobile_device_constant.resource_name",
                "dataType": "STRING",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Type",
                "id": "mobile_device_constant.type",
                "dataType": "ENUM",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Name",
                "id": "operating_system_version_constant.name",
                "dataType": "STRING",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Operator Type",
                "id": "operating_system_version_constant.operator_type",
                "dataType": "ENUM",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Resource Name",
                "id": "operating_system_version_constant.resource_name",
                "dataType": "STRING",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Path",
                "id": "topic_constant.path",
                "dataType": "STRING",
                "group": "Topic Constant"
            },
            {
                "name": "Resource Name",
                "id": "topic_constant.resource_name",
                "dataType": "STRING",
                "group": "Topic Constant"
            },
            {
                "name": "Topic Constant Parent",
                "id": "topic_constant.topic_constant_parent",
                "dataType": "STRING",
                "group": "Topic Constant"
            },
            {
                "name": "Availabilities",
                "id": "user_interest.availabilities",
                "dataType": "OBJECT",
                "group": "User Interest"
            },
            {
                "name": "Launched To All",
                "id": "user_interest.launched_to_all",
                "dataType": "BOOLEAN",
                "group": "User Interest"
            },
            {
                "name": "Name",
                "id": "user_interest.name",
                "dataType": "STRING",
                "group": "User Interest"
            },
            {
                "name": "Resource Name",
                "id": "user_interest.resource_name",
                "dataType": "STRING",
                "group": "User Interest"
            },
            {
                "name": "Taxonomy Type",
                "id": "user_interest.taxonomy_type",
                "dataType": "ENUM",
                "group": "User Interest"
            },
            {
                "name": "User Interest Parent",
                "id": "user_interest.user_interest_parent",
                "dataType": "STRING",
                "group": "User Interest"
            },
            {
                "name": "Access Reason",
                "id": "user_list.access_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Account User List Status",
                "id": "user_list.account_user_list_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Basic User List Actions",
                "id": "user_list.basic_user_list.actions",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Closing Reason",
                "id": "user_list.closing_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List App Id",
                "id": "user_list.crm_based_user_list.app_id",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Data Source Type",
                "id": "user_list.crm_based_user_list.data_source_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Upload Key Type",
                "id": "user_list.crm_based_user_list.upload_key_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Description",
                "id": "user_list.description",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Eligible For Display",
                "id": "user_list.eligible_for_display",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Eligible For Search",
                "id": "user_list.eligible_for_search",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Integration Code",
                "id": "user_list.integration_code",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Logical User List Rules",
                "id": "user_list.logical_user_list.rules",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Membership Status",
                "id": "user_list.membership_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Name",
                "id": "user_list.name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Read Only",
                "id": "user_list.read_only",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Resource Name",
                "id": "user_list.resource_name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Rule Operator",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Item Groups",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Type",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Exclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.exclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Rule Operator",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Prepopulation Status",
                "id": "user_list.rule_based_user_list.prepopulation_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Similar User List Seed User List",
                "id": "user_list.similar_user_list.seed_user_list",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Size Range For Display",
                "id": "user_list.size_range_for_display",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Size Range For Search",
                "id": "user_list.size_range_for_search",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Type",
                "id": "user_list.type",
                "dataType": "ENUM",
                "group": "User List"
            }
        ],
        "campaign_criterion_simulation": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Ad Schedule Day Of Week",
                "id": "campaign_criterion.ad_schedule.day_of_week",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule End Minute",
                "id": "campaign_criterion.ad_schedule.end_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Minute",
                "id": "campaign_criterion.ad_schedule.start_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "campaign_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Campaign",
                "id": "campaign_criterion.campaign",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Carrier Carrier Constant",
                "id": "campaign_criterion.carrier.carrier_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "campaign_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Content Label Type",
                "id": "campaign_criterion.content_label.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "campaign_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "campaign_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Device Type",
                "id": "campaign_criterion.device.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Display Name",
                "id": "campaign_criterion.display_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Gender Type",
                "id": "campaign_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "campaign_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ip Block Ip Address",
                "id": "campaign_criterion.ip_block.ip_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "campaign_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "campaign_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Free Form Keyword Theme",
                "id": "campaign_criterion.keyword_theme.free_form_keyword_theme",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Keyword Theme Constant",
                "id": "campaign_criterion.keyword_theme.keyword_theme_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Language Language Constant",
                "id": "campaign_criterion.language.language_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions",
                "id": "campaign_criterion.listing_scope.dimensions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel City City Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel Country Region Country Region Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel State State Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Geo Target Constant",
                "id": "campaign_criterion.location.geo_target_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group",
                "id": "campaign_criterion.location_group",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Enable Customer Level Location Asset Set",
                "id": "campaign_criterion.location_group.enable_customer_level_location_asset_set",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Feed",
                "id": "campaign_criterion.location_group.feed",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Geo Target Constants",
                "id": "campaign_criterion.location_group.geo_target_constants",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "campaign_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "campaign_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "campaign_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Device Mobile Device Constant",
                "id": "campaign_criterion.mobile_device.mobile_device_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Negative",
                "id": "campaign_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Operating System Version Operating System Version Constant",
                "id": "campaign_criterion.operating_system_version.operating_system_version_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "campaign_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Placement Url",
                "id": "campaign_criterion.placement.url",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address City Name",
                "id": "campaign_criterion.proximity.address.city_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Country Code",
                "id": "campaign_criterion.proximity.address.country_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Postal Code",
                "id": "campaign_criterion.proximity.address.postal_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Code",
                "id": "campaign_criterion.proximity.address.province_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Name",
                "id": "campaign_criterion.proximity.address.province_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Street Address",
                "id": "campaign_criterion.proximity.address.street_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius Units",
                "id": "campaign_criterion.proximity.radius_units",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Resource Name",
                "id": "campaign_criterion.resource_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Status",
                "id": "campaign_criterion.status",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Path",
                "id": "campaign_criterion.topic.path",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "campaign_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Type",
                "id": "campaign_criterion.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "campaign_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "User List User List",
                "id": "campaign_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "campaign_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "campaign_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "campaign_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "campaign_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "campaign_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Bid Modifier Point List Points",
                "id": "campaign_criterion_simulation.bid_modifier_point_list.points",
                "dataType": "OBJECT",
                "group": "Campaign Criterion Simulation"
            },
            {
                "name": "End Date",
                "id": "campaign_criterion_simulation.end_date",
                "dataType": "DATE",
                "group": "Campaign Criterion Simulation"
            },
            {
                "name": "Modification Method",
                "id": "campaign_criterion_simulation.modification_method",
                "dataType": "ENUM",
                "group": "Campaign Criterion Simulation"
            },
            {
                "name": "Resource Name",
                "id": "campaign_criterion_simulation.resource_name",
                "dataType": "STRING",
                "group": "Campaign Criterion Simulation"
            },
            {
                "name": "Start Date",
                "id": "campaign_criterion_simulation.start_date",
                "dataType": "DATE",
                "group": "Campaign Criterion Simulation"
            },
            {
                "name": "Type",
                "id": "campaign_criterion_simulation.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion Simulation"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "campaign_customizer": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign",
                "id": "campaign_customizer.campaign",
                "dataType": "STRING",
                "group": "Campaign Customizer"
            },
            {
                "name": "Customizer Attribute",
                "id": "campaign_customizer.customizer_attribute",
                "dataType": "STRING",
                "group": "Campaign Customizer"
            },
            {
                "name": "Resource Name",
                "id": "campaign_customizer.resource_name",
                "dataType": "STRING",
                "group": "Campaign Customizer"
            },
            {
                "name": "Status",
                "id": "campaign_customizer.status",
                "dataType": "ENUM",
                "group": "Campaign Customizer"
            },
            {
                "name": "Value String Value",
                "id": "campaign_customizer.value.string_value",
                "dataType": "STRING",
                "group": "Campaign Customizer"
            },
            {
                "name": "Value Type",
                "id": "campaign_customizer.value.type",
                "dataType": "ENUM",
                "group": "Campaign Customizer"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "customizer_attribute.name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Resource Name",
                "id": "customizer_attribute.resource_name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Status",
                "id": "customizer_attribute.status",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            },
            {
                "name": "Type",
                "id": "customizer_attribute.type",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            }
        ],
        "campaign_draft": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign_draft.base_campaign",
                "dataType": "STRING",
                "group": "Campaign Draft"
            },
            {
                "name": "Draft Campaign",
                "id": "campaign_draft.draft_campaign",
                "dataType": "STRING",
                "group": "Campaign Draft"
            },
            {
                "name": "Has Experiment Running",
                "id": "campaign_draft.has_experiment_running",
                "dataType": "BOOLEAN",
                "group": "Campaign Draft"
            },
            {
                "name": "Long Running Operation",
                "id": "campaign_draft.long_running_operation",
                "dataType": "STRING",
                "group": "Campaign Draft"
            },
            {
                "name": "Name",
                "id": "campaign_draft.name",
                "dataType": "STRING",
                "group": "Campaign Draft"
            },
            {
                "name": "Resource Name",
                "id": "campaign_draft.resource_name",
                "dataType": "STRING",
                "group": "Campaign Draft"
            },
            {
                "name": "Status",
                "id": "campaign_draft.status",
                "dataType": "ENUM",
                "group": "Campaign Draft"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "campaign_extension_setting": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign",
                "id": "campaign_extension_setting.campaign",
                "dataType": "STRING",
                "group": "Campaign Extension Setting"
            },
            {
                "name": "Device",
                "id": "campaign_extension_setting.device",
                "dataType": "ENUM",
                "group": "Campaign Extension Setting"
            },
            {
                "name": "Extension Feed Items",
                "id": "campaign_extension_setting.extension_feed_items",
                "dataType": "STRING",
                "group": "Campaign Extension Setting"
            },
            {
                "name": "Extension Type",
                "id": "campaign_extension_setting.extension_type",
                "dataType": "ENUM",
                "group": "Campaign Extension Setting"
            },
            {
                "name": "Resource Name",
                "id": "campaign_extension_setting.resource_name",
                "dataType": "STRING",
                "group": "Campaign Extension Setting"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "campaign_feed": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign",
                "id": "campaign_feed.campaign",
                "dataType": "STRING",
                "group": "Campaign Feed"
            },
            {
                "name": "Feed",
                "id": "campaign_feed.feed",
                "dataType": "STRING",
                "group": "Campaign Feed"
            },
            {
                "name": "Matching Function Function String",
                "id": "campaign_feed.matching_function.function_string",
                "dataType": "STRING",
                "group": "Campaign Feed"
            },
            {
                "name": "Matching Function Left Operands",
                "id": "campaign_feed.matching_function.left_operands",
                "dataType": "OBJECT",
                "group": "Campaign Feed"
            },
            {
                "name": "Matching Function Operator",
                "id": "campaign_feed.matching_function.operator",
                "dataType": "ENUM",
                "group": "Campaign Feed"
            },
            {
                "name": "Matching Function Right Operands",
                "id": "campaign_feed.matching_function.right_operands",
                "dataType": "OBJECT",
                "group": "Campaign Feed"
            },
            {
                "name": "Placeholder Types",
                "id": "campaign_feed.placeholder_types",
                "dataType": "ENUM",
                "group": "Campaign Feed"
            },
            {
                "name": "Resource Name",
                "id": "campaign_feed.resource_name",
                "dataType": "STRING",
                "group": "Campaign Feed"
            },
            {
                "name": "Status",
                "id": "campaign_feed.status",
                "dataType": "ENUM",
                "group": "Campaign Feed"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            }
        ],
        "campaign_group": [
            {
                "name": "Name",
                "id": "campaign_group.name",
                "dataType": "STRING",
                "group": "Campaign Group"
            },
            {
                "name": "Resource Name",
                "id": "campaign_group.resource_name",
                "dataType": "STRING",
                "group": "Campaign Group"
            },
            {
                "name": "Status",
                "id": "campaign_group.status",
                "dataType": "ENUM",
                "group": "Campaign Group"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Destination Type",
                "id": "segments.ad_destination_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "campaign_label": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign",
                "id": "campaign_label.campaign",
                "dataType": "STRING",
                "group": "Campaign Label"
            },
            {
                "name": "Label",
                "id": "campaign_label.label",
                "dataType": "STRING",
                "group": "Campaign Label"
            },
            {
                "name": "Resource Name",
                "id": "campaign_label.resource_name",
                "dataType": "STRING",
                "group": "Campaign Label"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "label.name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Resource Name",
                "id": "label.resource_name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Status",
                "id": "label.status",
                "dataType": "ENUM",
                "group": "Label"
            },
            {
                "name": "Text Label Background Color",
                "id": "label.text_label.background_color",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Text Label Description",
                "id": "label.text_label.description",
                "dataType": "STRING",
                "group": "Label"
            }
        ],
        "campaign_shared_set": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign",
                "id": "campaign_shared_set.campaign",
                "dataType": "STRING",
                "group": "Campaign Shared Set"
            },
            {
                "name": "Resource Name",
                "id": "campaign_shared_set.resource_name",
                "dataType": "STRING",
                "group": "Campaign Shared Set"
            },
            {
                "name": "Shared Set",
                "id": "campaign_shared_set.shared_set",
                "dataType": "STRING",
                "group": "Campaign Shared Set"
            },
            {
                "name": "Status",
                "id": "campaign_shared_set.status",
                "dataType": "ENUM",
                "group": "Campaign Shared Set"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "shared_set.name",
                "dataType": "STRING",
                "group": "Shared Set"
            },
            {
                "name": "Resource Name",
                "id": "shared_set.resource_name",
                "dataType": "STRING",
                "group": "Shared Set"
            },
            {
                "name": "Status",
                "id": "shared_set.status",
                "dataType": "ENUM",
                "group": "Shared Set"
            },
            {
                "name": "Type",
                "id": "shared_set.type",
                "dataType": "ENUM",
                "group": "Shared Set"
            }
        ],
        "campaign_simulation": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Budget Point List Points",
                "id": "campaign_simulation.budget_point_list.points",
                "dataType": "OBJECT",
                "group": "Campaign Simulation"
            },
            {
                "name": "Cpc Bid Point List Points",
                "id": "campaign_simulation.cpc_bid_point_list.points",
                "dataType": "OBJECT",
                "group": "Campaign Simulation"
            },
            {
                "name": "End Date",
                "id": "campaign_simulation.end_date",
                "dataType": "DATE",
                "group": "Campaign Simulation"
            },
            {
                "name": "Modification Method",
                "id": "campaign_simulation.modification_method",
                "dataType": "ENUM",
                "group": "Campaign Simulation"
            },
            {
                "name": "Resource Name",
                "id": "campaign_simulation.resource_name",
                "dataType": "STRING",
                "group": "Campaign Simulation"
            },
            {
                "name": "Start Date",
                "id": "campaign_simulation.start_date",
                "dataType": "DATE",
                "group": "Campaign Simulation"
            },
            {
                "name": "Target Cpa Point List Points",
                "id": "campaign_simulation.target_cpa_point_list.points",
                "dataType": "OBJECT",
                "group": "Campaign Simulation"
            },
            {
                "name": "Target Impression Share Point List Points",
                "id": "campaign_simulation.target_impression_share_point_list.points",
                "dataType": "OBJECT",
                "group": "Campaign Simulation"
            },
            {
                "name": "Target Roas Point List Points",
                "id": "campaign_simulation.target_roas_point_list.points",
                "dataType": "OBJECT",
                "group": "Campaign Simulation"
            },
            {
                "name": "Type",
                "id": "campaign_simulation.type",
                "dataType": "ENUM",
                "group": "Campaign Simulation"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "carrier_constant": [
            {
                "name": "Country Code",
                "id": "carrier_constant.country_code",
                "dataType": "STRING",
                "group": "Carrier Constant"
            },
            {
                "name": "Name",
                "id": "carrier_constant.name",
                "dataType": "STRING",
                "group": "Carrier Constant"
            },
            {
                "name": "Resource Name",
                "id": "carrier_constant.resource_name",
                "dataType": "STRING",
                "group": "Carrier Constant"
            }
        ],
        "change_event": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Ad Group",
                "id": "change_event.ad_group",
                "dataType": "STRING",
                "group": "Change Event"
            },
            {
                "name": "Asset",
                "id": "change_event.asset",
                "dataType": "STRING",
                "group": "Change Event"
            },
            {
                "name": "Campaign",
                "id": "change_event.campaign",
                "dataType": "STRING",
                "group": "Change Event"
            },
            {
                "name": "Change Date Time",
                "id": "change_event.change_date_time",
                "dataType": "DATE",
                "group": "Change Event"
            },
            {
                "name": "Change Resource Name",
                "id": "change_event.change_resource_name",
                "dataType": "STRING",
                "group": "Change Event"
            },
            {
                "name": "Change Resource Type",
                "id": "change_event.change_resource_type",
                "dataType": "ENUM",
                "group": "Change Event"
            },
            {
                "name": "Changed Fields",
                "id": "change_event.changed_fields",
                "dataType": "OBJECT",
                "group": "Change Event"
            },
            {
                "name": "Client Type",
                "id": "change_event.client_type",
                "dataType": "ENUM",
                "group": "Change Event"
            },
            {
                "name": "Feed",
                "id": "change_event.feed",
                "dataType": "STRING",
                "group": "Change Event"
            },
            {
                "name": "Feed Item",
                "id": "change_event.feed_item",
                "dataType": "STRING",
                "group": "Change Event"
            },
            {
                "name": "New Resource",
                "id": "change_event.new_resource",
                "dataType": "OBJECT",
                "group": "Change Event"
            },
            {
                "name": "Old Resource",
                "id": "change_event.old_resource",
                "dataType": "OBJECT",
                "group": "Change Event"
            },
            {
                "name": "Resource Change Operation",
                "id": "change_event.resource_change_operation",
                "dataType": "ENUM",
                "group": "Change Event"
            },
            {
                "name": "Resource Name",
                "id": "change_event.resource_name",
                "dataType": "STRING",
                "group": "Change Event"
            },
            {
                "name": "User Email",
                "id": "change_event.user_email",
                "dataType": "STRING",
                "group": "Change Event"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attribute Values",
                "id": "feed_item.attribute_values",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "End Date Time",
                "id": "feed_item.end_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Feed",
                "id": "feed_item.feed",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Geo Targeting Restriction",
                "id": "feed_item.geo_targeting_restriction",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Policy Infos",
                "id": "feed_item.policy_infos",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "Resource Name",
                "id": "feed_item.resource_name",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Start Date Time",
                "id": "feed_item.start_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Status",
                "id": "feed_item.status",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Url Custom Parameters",
                "id": "feed_item.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Feed Item"
            }
        ],
        "change_status": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Ad Group",
                "id": "change_status.ad_group",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Ad Group Ad",
                "id": "change_status.ad_group_ad",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Ad Group Asset",
                "id": "change_status.ad_group_asset",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Ad Group Bid Modifier",
                "id": "change_status.ad_group_bid_modifier",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Ad Group Criterion",
                "id": "change_status.ad_group_criterion",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Ad Group Feed",
                "id": "change_status.ad_group_feed",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Asset",
                "id": "change_status.asset",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Campaign",
                "id": "change_status.campaign",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Campaign Asset",
                "id": "change_status.campaign_asset",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Campaign Criterion",
                "id": "change_status.campaign_criterion",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Campaign Feed",
                "id": "change_status.campaign_feed",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Campaign Shared Set",
                "id": "change_status.campaign_shared_set",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Customer Asset",
                "id": "change_status.customer_asset",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Feed",
                "id": "change_status.feed",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Feed Item",
                "id": "change_status.feed_item",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Last Change Date Time",
                "id": "change_status.last_change_date_time",
                "dataType": "DATE",
                "group": "Change Status"
            },
            {
                "name": "Resource Name",
                "id": "change_status.resource_name",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Resource Status",
                "id": "change_status.resource_status",
                "dataType": "ENUM",
                "group": "Change Status"
            },
            {
                "name": "Resource Type",
                "id": "change_status.resource_type",
                "dataType": "ENUM",
                "group": "Change Status"
            },
            {
                "name": "Shared Set",
                "id": "change_status.shared_set",
                "dataType": "STRING",
                "group": "Change Status"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attribute Values",
                "id": "feed_item.attribute_values",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "End Date Time",
                "id": "feed_item.end_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Feed",
                "id": "feed_item.feed",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Geo Targeting Restriction",
                "id": "feed_item.geo_targeting_restriction",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Policy Infos",
                "id": "feed_item.policy_infos",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "Resource Name",
                "id": "feed_item.resource_name",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Start Date Time",
                "id": "feed_item.start_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Status",
                "id": "feed_item.status",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Url Custom Parameters",
                "id": "feed_item.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "Name",
                "id": "shared_set.name",
                "dataType": "STRING",
                "group": "Shared Set"
            },
            {
                "name": "Resource Name",
                "id": "shared_set.resource_name",
                "dataType": "STRING",
                "group": "Shared Set"
            },
            {
                "name": "Status",
                "id": "shared_set.status",
                "dataType": "ENUM",
                "group": "Shared Set"
            },
            {
                "name": "Type",
                "id": "shared_set.type",
                "dataType": "ENUM",
                "group": "Shared Set"
            }
        ],
        "click_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Ad Group Ad",
                "id": "click_view.ad_group_ad",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Area Of Interest City",
                "id": "click_view.area_of_interest.city",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Area Of Interest Country",
                "id": "click_view.area_of_interest.country",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Area Of Interest Metro",
                "id": "click_view.area_of_interest.metro",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Area Of Interest Most Specific",
                "id": "click_view.area_of_interest.most_specific",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Area Of Interest Region",
                "id": "click_view.area_of_interest.region",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Campaign Location Target",
                "id": "click_view.campaign_location_target",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Gclid",
                "id": "click_view.gclid",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Keyword",
                "id": "click_view.keyword",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Keyword Info Match Type",
                "id": "click_view.keyword_info.match_type",
                "dataType": "ENUM",
                "group": "Click View"
            },
            {
                "name": "Keyword Info Text",
                "id": "click_view.keyword_info.text",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Location Of Presence City",
                "id": "click_view.location_of_presence.city",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Location Of Presence Country",
                "id": "click_view.location_of_presence.country",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Location Of Presence Metro",
                "id": "click_view.location_of_presence.metro",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Location Of Presence Most Specific",
                "id": "click_view.location_of_presence.most_specific",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Location Of Presence Region",
                "id": "click_view.location_of_presence.region",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Resource Name",
                "id": "click_view.resource_name",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "User List",
                "id": "click_view.user_list",
                "dataType": "STRING",
                "group": "Click View"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            }
        ],
        "combined_audience": [
            {
                "name": "Description",
                "id": "combined_audience.description",
                "dataType": "STRING",
                "group": "Combined Audience"
            },
            {
                "name": "Name",
                "id": "combined_audience.name",
                "dataType": "STRING",
                "group": "Combined Audience"
            },
            {
                "name": "Resource Name",
                "id": "combined_audience.resource_name",
                "dataType": "STRING",
                "group": "Combined Audience"
            },
            {
                "name": "Status",
                "id": "combined_audience.status",
                "dataType": "ENUM",
                "group": "Combined Audience"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "conversion_action": [
            {
                "name": "App Id",
                "id": "conversion_action.app_id",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Attribution Model Settings Attribution Model",
                "id": "conversion_action.attribution_model_settings.attribution_model",
                "dataType": "ENUM",
                "group": "Conversion Action"
            },
            {
                "name": "Attribution Model Settings Data Driven Model Status",
                "id": "conversion_action.attribution_model_settings.data_driven_model_status",
                "dataType": "ENUM",
                "group": "Conversion Action"
            },
            {
                "name": "Category",
                "id": "conversion_action.category",
                "dataType": "ENUM",
                "group": "Conversion Action"
            },
            {
                "name": "Counting Type",
                "id": "conversion_action.counting_type",
                "dataType": "ENUM",
                "group": "Conversion Action"
            },
            {
                "name": "Firebase Settings Event Name",
                "id": "conversion_action.firebase_settings.event_name",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Firebase Settings Project Id",
                "id": "conversion_action.firebase_settings.project_id",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Firebase Settings Property Name",
                "id": "conversion_action.firebase_settings.property_name",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Include In Conversions Metric",
                "id": "conversion_action.include_in_conversions_metric",
                "dataType": "BOOLEAN",
                "group": "Conversion Action"
            },
            {
                "name": "Mobile App Vendor",
                "id": "conversion_action.mobile_app_vendor",
                "dataType": "ENUM",
                "group": "Conversion Action"
            },
            {
                "name": "Name",
                "id": "conversion_action.name",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Origin",
                "id": "conversion_action.origin",
                "dataType": "ENUM",
                "group": "Conversion Action"
            },
            {
                "name": "Owner Customer",
                "id": "conversion_action.owner_customer",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Primary For Goal",
                "id": "conversion_action.primary_for_goal",
                "dataType": "BOOLEAN",
                "group": "Conversion Action"
            },
            {
                "name": "Resource Name",
                "id": "conversion_action.resource_name",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Status",
                "id": "conversion_action.status",
                "dataType": "ENUM",
                "group": "Conversion Action"
            },
            {
                "name": "Tag Snippets",
                "id": "conversion_action.tag_snippets",
                "dataType": "OBJECT",
                "group": "Conversion Action"
            },
            {
                "name": "Third Party App Analytics Settings Event Name",
                "id": "conversion_action.third_party_app_analytics_settings.event_name",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Third Party App Analytics Settings Provider Name",
                "id": "conversion_action.third_party_app_analytics_settings.provider_name",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Type",
                "id": "conversion_action.type",
                "dataType": "ENUM",
                "group": "Conversion Action"
            },
            {
                "name": "Value Settings Always Use Default Value",
                "id": "conversion_action.value_settings.always_use_default_value",
                "dataType": "BOOLEAN",
                "group": "Conversion Action"
            },
            {
                "name": "Value Settings Default Currency Code",
                "id": "conversion_action.value_settings.default_currency_code",
                "dataType": "STRING",
                "group": "Conversion Action"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Conversion Last Conversion Date",
                "id": "metrics.conversion_last_conversion_date",
                "dataType": "DATE",
                "group": "Metrics"
            },
            {
                "name": "Conversion Last Received Request Date Time",
                "id": "metrics.conversion_last_received_request_date_time",
                "dataType": "DATE",
                "group": "Metrics"
            }
        ],
        "conversion_custom_variable": [
            {
                "name": "Name",
                "id": "conversion_custom_variable.name",
                "dataType": "STRING",
                "group": "Conversion Custom Variable"
            },
            {
                "name": "Owner Customer",
                "id": "conversion_custom_variable.owner_customer",
                "dataType": "STRING",
                "group": "Conversion Custom Variable"
            },
            {
                "name": "Resource Name",
                "id": "conversion_custom_variable.resource_name",
                "dataType": "STRING",
                "group": "Conversion Custom Variable"
            },
            {
                "name": "Status",
                "id": "conversion_custom_variable.status",
                "dataType": "ENUM",
                "group": "Conversion Custom Variable"
            },
            {
                "name": "Tag",
                "id": "conversion_custom_variable.tag",
                "dataType": "STRING",
                "group": "Conversion Custom Variable"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "conversion_goal_campaign_config": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign",
                "id": "conversion_goal_campaign_config.campaign",
                "dataType": "STRING",
                "group": "Conversion Goal Campaign Config"
            },
            {
                "name": "Custom Conversion Goal",
                "id": "conversion_goal_campaign_config.custom_conversion_goal",
                "dataType": "STRING",
                "group": "Conversion Goal Campaign Config"
            },
            {
                "name": "Goal Config Level",
                "id": "conversion_goal_campaign_config.goal_config_level",
                "dataType": "ENUM",
                "group": "Conversion Goal Campaign Config"
            },
            {
                "name": "Resource Name",
                "id": "conversion_goal_campaign_config.resource_name",
                "dataType": "STRING",
                "group": "Conversion Goal Campaign Config"
            },
            {
                "name": "Conversion Actions",
                "id": "custom_conversion_goal.conversion_actions",
                "dataType": "STRING",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Name",
                "id": "custom_conversion_goal.name",
                "dataType": "STRING",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Resource Name",
                "id": "custom_conversion_goal.resource_name",
                "dataType": "STRING",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Status",
                "id": "custom_conversion_goal.status",
                "dataType": "ENUM",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "conversion_value_rule": [
            {
                "name": "Action Operation",
                "id": "conversion_value_rule.action.operation",
                "dataType": "ENUM",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Audience Condition User Interests",
                "id": "conversion_value_rule.audience_condition.user_interests",
                "dataType": "STRING",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Audience Condition User Lists",
                "id": "conversion_value_rule.audience_condition.user_lists",
                "dataType": "STRING",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Device Condition Device Types",
                "id": "conversion_value_rule.device_condition.device_types",
                "dataType": "ENUM",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Geo Location Condition Excluded Geo Match Type",
                "id": "conversion_value_rule.geo_location_condition.excluded_geo_match_type",
                "dataType": "ENUM",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Geo Location Condition Excluded Geo Target Constants",
                "id": "conversion_value_rule.geo_location_condition.excluded_geo_target_constants",
                "dataType": "STRING",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Geo Location Condition Geo Match Type",
                "id": "conversion_value_rule.geo_location_condition.geo_match_type",
                "dataType": "ENUM",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Geo Location Condition Geo Target Constants",
                "id": "conversion_value_rule.geo_location_condition.geo_target_constants",
                "dataType": "STRING",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Owner Customer",
                "id": "conversion_value_rule.owner_customer",
                "dataType": "STRING",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Resource Name",
                "id": "conversion_value_rule.resource_name",
                "dataType": "STRING",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Status",
                "id": "conversion_value_rule.status",
                "dataType": "ENUM",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "conversion_value_rule_set": [
            {
                "name": "Attachment Type",
                "id": "conversion_value_rule_set.attachment_type",
                "dataType": "ENUM",
                "group": "Conversion Value Rule Set"
            },
            {
                "name": "Campaign",
                "id": "conversion_value_rule_set.campaign",
                "dataType": "STRING",
                "group": "Conversion Value Rule Set"
            },
            {
                "name": "Conversion Action Categories",
                "id": "conversion_value_rule_set.conversion_action_categories",
                "dataType": "ENUM",
                "group": "Conversion Value Rule Set"
            },
            {
                "name": "Conversion Value Rules",
                "id": "conversion_value_rule_set.conversion_value_rules",
                "dataType": "STRING",
                "group": "Conversion Value Rule Set"
            },
            {
                "name": "Dimensions",
                "id": "conversion_value_rule_set.dimensions",
                "dataType": "ENUM",
                "group": "Conversion Value Rule Set"
            },
            {
                "name": "Owner Customer",
                "id": "conversion_value_rule_set.owner_customer",
                "dataType": "STRING",
                "group": "Conversion Value Rule Set"
            },
            {
                "name": "Resource Name",
                "id": "conversion_value_rule_set.resource_name",
                "dataType": "STRING",
                "group": "Conversion Value Rule Set"
            },
            {
                "name": "Status",
                "id": "conversion_value_rule_set.status",
                "dataType": "ENUM",
                "group": "Conversion Value Rule Set"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "currency_constant": [
            {
                "name": "Code",
                "id": "currency_constant.code",
                "dataType": "STRING",
                "group": "Currency Constant"
            },
            {
                "name": "Name",
                "id": "currency_constant.name",
                "dataType": "STRING",
                "group": "Currency Constant"
            },
            {
                "name": "Resource Name",
                "id": "currency_constant.resource_name",
                "dataType": "STRING",
                "group": "Currency Constant"
            },
            {
                "name": "Symbol",
                "id": "currency_constant.symbol",
                "dataType": "STRING",
                "group": "Currency Constant"
            }
        ],
        "custom_audience": [
            {
                "name": "Description",
                "id": "custom_audience.description",
                "dataType": "STRING",
                "group": "Custom Audience"
            },
            {
                "name": "Members",
                "id": "custom_audience.members",
                "dataType": "OBJECT",
                "group": "Custom Audience"
            },
            {
                "name": "Name",
                "id": "custom_audience.name",
                "dataType": "STRING",
                "group": "Custom Audience"
            },
            {
                "name": "Resource Name",
                "id": "custom_audience.resource_name",
                "dataType": "STRING",
                "group": "Custom Audience"
            },
            {
                "name": "Status",
                "id": "custom_audience.status",
                "dataType": "ENUM",
                "group": "Custom Audience"
            },
            {
                "name": "Type",
                "id": "custom_audience.type",
                "dataType": "ENUM",
                "group": "Custom Audience"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "custom_conversion_goal": [
            {
                "name": "Conversion Actions",
                "id": "custom_conversion_goal.conversion_actions",
                "dataType": "STRING",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Name",
                "id": "custom_conversion_goal.name",
                "dataType": "STRING",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Resource Name",
                "id": "custom_conversion_goal.resource_name",
                "dataType": "STRING",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Status",
                "id": "custom_conversion_goal.status",
                "dataType": "ENUM",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "custom_interest": [
            {
                "name": "Description",
                "id": "custom_interest.description",
                "dataType": "STRING",
                "group": "Custom Interest"
            },
            {
                "name": "Members",
                "id": "custom_interest.members",
                "dataType": "OBJECT",
                "group": "Custom Interest"
            },
            {
                "name": "Name",
                "id": "custom_interest.name",
                "dataType": "STRING",
                "group": "Custom Interest"
            },
            {
                "name": "Resource Name",
                "id": "custom_interest.resource_name",
                "dataType": "STRING",
                "group": "Custom Interest"
            },
            {
                "name": "Status",
                "id": "custom_interest.status",
                "dataType": "ENUM",
                "group": "Custom Interest"
            },
            {
                "name": "Type",
                "id": "custom_interest.type",
                "dataType": "ENUM",
                "group": "Custom Interest"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            }
        ],
        "customer": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Auction Insight Domain",
                "id": "segments.auction_insight_domain",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Adjustment",
                "id": "segments.conversion_adjustment",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Conversion Lag Bucket",
                "id": "segments.conversion_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Or Adjustment Lag Bucket",
                "id": "segments.conversion_or_adjustment_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Value Rule Primary Dimension",
                "id": "segments.conversion_value_rule_primary_dimension",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Recommendation Type",
                "id": "segments.recommendation_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network Ad Event Type",
                "id": "segments.sk_ad_network_ad_event_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network Attribution Credit",
                "id": "segments.sk_ad_network_attribution_credit",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network Source App Sk Ad Network Source App Id",
                "id": "segments.sk_ad_network_source_app.sk_ad_network_source_app_id",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network User Type",
                "id": "segments.sk_ad_network_user_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            },
            {
                "name": "Optimization Score Url",
                "id": "metrics.optimization_score_url",
                "dataType": "STRING",
                "group": "Metrics"
            }
        ],
        "customer_asset": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Asset",
                "id": "customer_asset.asset",
                "dataType": "STRING",
                "group": "Customer Asset"
            },
            {
                "name": "Field Type",
                "id": "customer_asset.field_type",
                "dataType": "ENUM",
                "group": "Customer Asset"
            },
            {
                "name": "Resource Name",
                "id": "customer_asset.resource_name",
                "dataType": "STRING",
                "group": "Customer Asset"
            },
            {
                "name": "Source",
                "id": "customer_asset.source",
                "dataType": "ENUM",
                "group": "Customer Asset"
            },
            {
                "name": "Status",
                "id": "customer_asset.status",
                "dataType": "ENUM",
                "group": "Customer Asset"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Asset",
                "id": "segments.asset_interaction_target.asset",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Asset Interaction Target Interaction On This Asset",
                "id": "segments.asset_interaction_target.interaction_on_this_asset",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "customer_asset_set": [
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Business Name",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.business_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Business Name Filter Filter Type",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.business_name_filter.filter_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Business Profile Location Group Dynamic Business Profile Location Group Filter Label Filters",
                "id": "asset_set.business_profile_location_group.dynamic_business_profile_location_group_filter.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Business Name Filter",
                "id": "asset_set.location_set.business_profile_location_set.business_name_filter",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Label Filters",
                "id": "asset_set.location_set.business_profile_location_set.label_filters",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Chain Location Set Relationship Type",
                "id": "asset_set.location_set.chain_location_set.relationship_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Location Ownership Type",
                "id": "asset_set.location_set.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Feed Label",
                "id": "asset_set.merchant_center_feed.feed_label",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Name",
                "id": "asset_set.name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Resource Name",
                "id": "asset_set.resource_name",
                "dataType": "STRING",
                "group": "Asset Set"
            },
            {
                "name": "Status",
                "id": "asset_set.status",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Type",
                "id": "asset_set.type",
                "dataType": "ENUM",
                "group": "Asset Set"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Asset Set",
                "id": "customer_asset_set.asset_set",
                "dataType": "STRING",
                "group": "Customer Asset Set"
            },
            {
                "name": "Customer",
                "id": "customer_asset_set.customer",
                "dataType": "STRING",
                "group": "Customer Asset Set"
            },
            {
                "name": "Resource Name",
                "id": "customer_asset_set.resource_name",
                "dataType": "STRING",
                "group": "Customer Asset Set"
            },
            {
                "name": "Status",
                "id": "customer_asset_set.status",
                "dataType": "ENUM",
                "group": "Customer Asset Set"
            }
        ],
        "customer_client": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Applied Labels",
                "id": "customer_client.applied_labels",
                "dataType": "STRING",
                "group": "Customer Client"
            },
            {
                "name": "Client Customer",
                "id": "customer_client.client_customer",
                "dataType": "STRING",
                "group": "Customer Client"
            },
            {
                "name": "Currency Code",
                "id": "customer_client.currency_code",
                "dataType": "STRING",
                "group": "Customer Client"
            },
            {
                "name": "Descriptive Name",
                "id": "customer_client.descriptive_name",
                "dataType": "STRING",
                "group": "Customer Client"
            },
            {
                "name": "Hidden",
                "id": "customer_client.hidden",
                "dataType": "BOOLEAN",
                "group": "Customer Client"
            },
            {
                "name": "Manager",
                "id": "customer_client.manager",
                "dataType": "BOOLEAN",
                "group": "Customer Client"
            },
            {
                "name": "Resource Name",
                "id": "customer_client.resource_name",
                "dataType": "STRING",
                "group": "Customer Client"
            },
            {
                "name": "Status",
                "id": "customer_client.status",
                "dataType": "ENUM",
                "group": "Customer Client"
            },
            {
                "name": "Test Account",
                "id": "customer_client.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer Client"
            },
            {
                "name": "Time Zone",
                "id": "customer_client.time_zone",
                "dataType": "STRING",
                "group": "Customer Client"
            }
        ],
        "customer_client_link": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Client Customer",
                "id": "customer_client_link.client_customer",
                "dataType": "STRING",
                "group": "Customer Client Link"
            },
            {
                "name": "Hidden",
                "id": "customer_client_link.hidden",
                "dataType": "BOOLEAN",
                "group": "Customer Client Link"
            },
            {
                "name": "Resource Name",
                "id": "customer_client_link.resource_name",
                "dataType": "STRING",
                "group": "Customer Client Link"
            },
            {
                "name": "Status",
                "id": "customer_client_link.status",
                "dataType": "ENUM",
                "group": "Customer Client Link"
            }
        ],
        "customer_conversion_goal": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Biddable",
                "id": "customer_conversion_goal.biddable",
                "dataType": "BOOLEAN",
                "group": "Customer Conversion Goal"
            },
            {
                "name": "Category",
                "id": "customer_conversion_goal.category",
                "dataType": "ENUM",
                "group": "Customer Conversion Goal"
            },
            {
                "name": "Origin",
                "id": "customer_conversion_goal.origin",
                "dataType": "ENUM",
                "group": "Customer Conversion Goal"
            },
            {
                "name": "Resource Name",
                "id": "customer_conversion_goal.resource_name",
                "dataType": "STRING",
                "group": "Customer Conversion Goal"
            }
        ],
        "customer_customizer": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Customizer Attribute",
                "id": "customer_customizer.customizer_attribute",
                "dataType": "STRING",
                "group": "Customer Customizer"
            },
            {
                "name": "Resource Name",
                "id": "customer_customizer.resource_name",
                "dataType": "STRING",
                "group": "Customer Customizer"
            },
            {
                "name": "Status",
                "id": "customer_customizer.status",
                "dataType": "ENUM",
                "group": "Customer Customizer"
            },
            {
                "name": "Value String Value",
                "id": "customer_customizer.value.string_value",
                "dataType": "STRING",
                "group": "Customer Customizer"
            },
            {
                "name": "Value Type",
                "id": "customer_customizer.value.type",
                "dataType": "ENUM",
                "group": "Customer Customizer"
            },
            {
                "name": "Name",
                "id": "customizer_attribute.name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Resource Name",
                "id": "customizer_attribute.resource_name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Status",
                "id": "customizer_attribute.status",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            },
            {
                "name": "Type",
                "id": "customizer_attribute.type",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            }
        ],
        "customer_extension_setting": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Device",
                "id": "customer_extension_setting.device",
                "dataType": "ENUM",
                "group": "Customer Extension Setting"
            },
            {
                "name": "Extension Feed Items",
                "id": "customer_extension_setting.extension_feed_items",
                "dataType": "STRING",
                "group": "Customer Extension Setting"
            },
            {
                "name": "Extension Type",
                "id": "customer_extension_setting.extension_type",
                "dataType": "ENUM",
                "group": "Customer Extension Setting"
            },
            {
                "name": "Resource Name",
                "id": "customer_extension_setting.resource_name",
                "dataType": "STRING",
                "group": "Customer Extension Setting"
            }
        ],
        "customer_feed": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Feed",
                "id": "customer_feed.feed",
                "dataType": "STRING",
                "group": "Customer Feed"
            },
            {
                "name": "Matching Function Function String",
                "id": "customer_feed.matching_function.function_string",
                "dataType": "STRING",
                "group": "Customer Feed"
            },
            {
                "name": "Matching Function Left Operands",
                "id": "customer_feed.matching_function.left_operands",
                "dataType": "OBJECT",
                "group": "Customer Feed"
            },
            {
                "name": "Matching Function Operator",
                "id": "customer_feed.matching_function.operator",
                "dataType": "ENUM",
                "group": "Customer Feed"
            },
            {
                "name": "Matching Function Right Operands",
                "id": "customer_feed.matching_function.right_operands",
                "dataType": "OBJECT",
                "group": "Customer Feed"
            },
            {
                "name": "Placeholder Types",
                "id": "customer_feed.placeholder_types",
                "dataType": "ENUM",
                "group": "Customer Feed"
            },
            {
                "name": "Resource Name",
                "id": "customer_feed.resource_name",
                "dataType": "STRING",
                "group": "Customer Feed"
            },
            {
                "name": "Status",
                "id": "customer_feed.status",
                "dataType": "ENUM",
                "group": "Customer Feed"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            }
        ],
        "customer_label": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Customer",
                "id": "customer_label.customer",
                "dataType": "STRING",
                "group": "Customer Label"
            },
            {
                "name": "Label",
                "id": "customer_label.label",
                "dataType": "STRING",
                "group": "Customer Label"
            },
            {
                "name": "Resource Name",
                "id": "customer_label.resource_name",
                "dataType": "STRING",
                "group": "Customer Label"
            }
        ],
        "customer_manager_link": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Manager Customer",
                "id": "customer_manager_link.manager_customer",
                "dataType": "STRING",
                "group": "Customer Manager Link"
            },
            {
                "name": "Resource Name",
                "id": "customer_manager_link.resource_name",
                "dataType": "STRING",
                "group": "Customer Manager Link"
            },
            {
                "name": "Status",
                "id": "customer_manager_link.status",
                "dataType": "ENUM",
                "group": "Customer Manager Link"
            }
        ],
        "customer_negative_criterion": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Content Label Type",
                "id": "customer_negative_criterion.content_label.type",
                "dataType": "ENUM",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "customer_negative_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "customer_negative_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "customer_negative_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Placement Url",
                "id": "customer_negative_criterion.placement.url",
                "dataType": "STRING",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Resource Name",
                "id": "customer_negative_criterion.resource_name",
                "dataType": "STRING",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Type",
                "id": "customer_negative_criterion.type",
                "dataType": "ENUM",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "customer_negative_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "customer_negative_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Name",
                "id": "mobile_app_category_constant.name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Resource Name",
                "id": "mobile_app_category_constant.resource_name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            }
        ],
        "customer_user_access": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Access Creation Date Time",
                "id": "customer_user_access.access_creation_date_time",
                "dataType": "DATE",
                "group": "Customer User Access"
            },
            {
                "name": "Access Role",
                "id": "customer_user_access.access_role",
                "dataType": "ENUM",
                "group": "Customer User Access"
            },
            {
                "name": "Email Address",
                "id": "customer_user_access.email_address",
                "dataType": "STRING",
                "group": "Customer User Access"
            },
            {
                "name": "Inviter User Email Address",
                "id": "customer_user_access.inviter_user_email_address",
                "dataType": "STRING",
                "group": "Customer User Access"
            },
            {
                "name": "Resource Name",
                "id": "customer_user_access.resource_name",
                "dataType": "STRING",
                "group": "Customer User Access"
            }
        ],
        "customer_user_access_invitation": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Access Role",
                "id": "customer_user_access_invitation.access_role",
                "dataType": "ENUM",
                "group": "Customer User Access Invitation"
            },
            {
                "name": "Creation Date Time",
                "id": "customer_user_access_invitation.creation_date_time",
                "dataType": "DATE",
                "group": "Customer User Access Invitation"
            },
            {
                "name": "Email Address",
                "id": "customer_user_access_invitation.email_address",
                "dataType": "STRING",
                "group": "Customer User Access Invitation"
            },
            {
                "name": "Invitation Status",
                "id": "customer_user_access_invitation.invitation_status",
                "dataType": "ENUM",
                "group": "Customer User Access Invitation"
            },
            {
                "name": "Resource Name",
                "id": "customer_user_access_invitation.resource_name",
                "dataType": "STRING",
                "group": "Customer User Access Invitation"
            }
        ],
        "customizer_attribute": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "customizer_attribute.name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Resource Name",
                "id": "customizer_attribute.resource_name",
                "dataType": "STRING",
                "group": "Customizer Attribute"
            },
            {
                "name": "Status",
                "id": "customizer_attribute.status",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            },
            {
                "name": "Type",
                "id": "customizer_attribute.type",
                "dataType": "ENUM",
                "group": "Customizer Attribute"
            }
        ],
        "detail_placement_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Display Name",
                "id": "detail_placement_view.display_name",
                "dataType": "STRING",
                "group": "Detail Placement View"
            },
            {
                "name": "Group Placement Target Url",
                "id": "detail_placement_view.group_placement_target_url",
                "dataType": "STRING",
                "group": "Detail Placement View"
            },
            {
                "name": "Placement",
                "id": "detail_placement_view.placement",
                "dataType": "STRING",
                "group": "Detail Placement View"
            },
            {
                "name": "Placement Type",
                "id": "detail_placement_view.placement_type",
                "dataType": "ENUM",
                "group": "Detail Placement View"
            },
            {
                "name": "Resource Name",
                "id": "detail_placement_view.resource_name",
                "dataType": "STRING",
                "group": "Detail Placement View"
            },
            {
                "name": "Target Url",
                "id": "detail_placement_view.target_url",
                "dataType": "STRING",
                "group": "Detail Placement View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "detailed_demographic": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Availabilities",
                "id": "detailed_demographic.availabilities",
                "dataType": "OBJECT",
                "group": "Detailed Demographic"
            },
            {
                "name": "Launched To All",
                "id": "detailed_demographic.launched_to_all",
                "dataType": "BOOLEAN",
                "group": "Detailed Demographic"
            },
            {
                "name": "Name",
                "id": "detailed_demographic.name",
                "dataType": "STRING",
                "group": "Detailed Demographic"
            },
            {
                "name": "Parent",
                "id": "detailed_demographic.parent",
                "dataType": "STRING",
                "group": "Detailed Demographic"
            },
            {
                "name": "Resource Name",
                "id": "detailed_demographic.resource_name",
                "dataType": "STRING",
                "group": "Detailed Demographic"
            }
        ],
        "display_keyword_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "display_keyword_view.resource_name",
                "dataType": "STRING",
                "group": "Display Keyword View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "distance_view": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Distance Bucket",
                "id": "distance_view.distance_bucket",
                "dataType": "ENUM",
                "group": "Distance View"
            },
            {
                "name": "Metric System",
                "id": "distance_view.metric_system",
                "dataType": "BOOLEAN",
                "group": "Distance View"
            },
            {
                "name": "Resource Name",
                "id": "distance_view.resource_name",
                "dataType": "STRING",
                "group": "Distance View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "domain_category": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Campaign",
                "id": "domain_category.campaign",
                "dataType": "STRING",
                "group": "Domain Category"
            },
            {
                "name": "Category",
                "id": "domain_category.category",
                "dataType": "STRING",
                "group": "Domain Category"
            },
            {
                "name": "Domain",
                "id": "domain_category.domain",
                "dataType": "STRING",
                "group": "Domain Category"
            },
            {
                "name": "Has Children",
                "id": "domain_category.has_children",
                "dataType": "BOOLEAN",
                "group": "Domain Category"
            },
            {
                "name": "Language Code",
                "id": "domain_category.language_code",
                "dataType": "STRING",
                "group": "Domain Category"
            },
            {
                "name": "Resource Name",
                "id": "domain_category.resource_name",
                "dataType": "STRING",
                "group": "Domain Category"
            }
        ],
        "dynamic_search_ads_search_term_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Matching Keyword",
                "id": "dynamic_search_ads_search_term_view.has_matching_keyword",
                "dataType": "BOOLEAN",
                "group": "Dynamic Search Ads Search Term View"
            },
            {
                "name": "Has Negative Keyword",
                "id": "dynamic_search_ads_search_term_view.has_negative_keyword",
                "dataType": "BOOLEAN",
                "group": "Dynamic Search Ads Search Term View"
            },
            {
                "name": "Has Negative Url",
                "id": "dynamic_search_ads_search_term_view.has_negative_url",
                "dataType": "BOOLEAN",
                "group": "Dynamic Search Ads Search Term View"
            },
            {
                "name": "Headline",
                "id": "dynamic_search_ads_search_term_view.headline",
                "dataType": "STRING",
                "group": "Dynamic Search Ads Search Term View"
            },
            {
                "name": "Landing Page",
                "id": "dynamic_search_ads_search_term_view.landing_page",
                "dataType": "STRING",
                "group": "Dynamic Search Ads Search Term View"
            },
            {
                "name": "Page Url",
                "id": "dynamic_search_ads_search_term_view.page_url",
                "dataType": "STRING",
                "group": "Dynamic Search Ads Search Term View"
            },
            {
                "name": "Resource Name",
                "id": "dynamic_search_ads_search_term_view.resource_name",
                "dataType": "STRING",
                "group": "Dynamic Search Ads Search Term View"
            },
            {
                "name": "Search Term",
                "id": "dynamic_search_ads_search_term_view.search_term",
                "dataType": "STRING",
                "group": "Dynamic Search Ads Search Term View"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Webpage",
                "id": "segments.webpage",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "expanded_landing_page_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Expanded Final Url",
                "id": "expanded_landing_page_view.expanded_final_url",
                "dataType": "STRING",
                "group": "Expanded Landing Page View"
            },
            {
                "name": "Resource Name",
                "id": "expanded_landing_page_view.resource_name",
                "dataType": "STRING",
                "group": "Expanded Landing Page View"
            },
            {
                "name": "Resource Name",
                "id": "landing_page_view.resource_name",
                "dataType": "STRING",
                "group": "Landing Page View"
            },
            {
                "name": "Unexpanded Final Url",
                "id": "landing_page_view.unexpanded_final_url",
                "dataType": "STRING",
                "group": "Landing Page View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "experiment": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Description",
                "id": "experiment.description",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "End Date",
                "id": "experiment.end_date",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Goals",
                "id": "experiment.goals",
                "dataType": "OBJECT",
                "group": "Experiment"
            },
            {
                "name": "Long Running Operation",
                "id": "experiment.long_running_operation",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Name",
                "id": "experiment.name",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Promote Status",
                "id": "experiment.promote_status",
                "dataType": "ENUM",
                "group": "Experiment"
            },
            {
                "name": "Resource Name",
                "id": "experiment.resource_name",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Start Date",
                "id": "experiment.start_date",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Status",
                "id": "experiment.status",
                "dataType": "ENUM",
                "group": "Experiment"
            },
            {
                "name": "Suffix",
                "id": "experiment.suffix",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Type",
                "id": "experiment.type",
                "dataType": "ENUM",
                "group": "Experiment"
            }
        ],
        "experiment_arm": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Description",
                "id": "experiment.description",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "End Date",
                "id": "experiment.end_date",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Goals",
                "id": "experiment.goals",
                "dataType": "OBJECT",
                "group": "Experiment"
            },
            {
                "name": "Long Running Operation",
                "id": "experiment.long_running_operation",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Name",
                "id": "experiment.name",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Promote Status",
                "id": "experiment.promote_status",
                "dataType": "ENUM",
                "group": "Experiment"
            },
            {
                "name": "Resource Name",
                "id": "experiment.resource_name",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Start Date",
                "id": "experiment.start_date",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Status",
                "id": "experiment.status",
                "dataType": "ENUM",
                "group": "Experiment"
            },
            {
                "name": "Suffix",
                "id": "experiment.suffix",
                "dataType": "STRING",
                "group": "Experiment"
            },
            {
                "name": "Type",
                "id": "experiment.type",
                "dataType": "ENUM",
                "group": "Experiment"
            },
            {
                "name": "Campaigns",
                "id": "experiment_arm.campaigns",
                "dataType": "STRING",
                "group": "Experiment Arm"
            },
            {
                "name": "Control",
                "id": "experiment_arm.control",
                "dataType": "BOOLEAN",
                "group": "Experiment Arm"
            },
            {
                "name": "Experiment",
                "id": "experiment_arm.experiment",
                "dataType": "STRING",
                "group": "Experiment Arm"
            },
            {
                "name": "In Design Campaigns",
                "id": "experiment_arm.in_design_campaigns",
                "dataType": "STRING",
                "group": "Experiment Arm"
            },
            {
                "name": "Name",
                "id": "experiment_arm.name",
                "dataType": "STRING",
                "group": "Experiment Arm"
            },
            {
                "name": "Resource Name",
                "id": "experiment_arm.resource_name",
                "dataType": "STRING",
                "group": "Experiment Arm"
            }
        ],
        "extension_feed_item": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Schedules",
                "id": "extension_feed_item.ad_schedules",
                "dataType": "OBJECT",
                "group": "Extension Feed Item"
            },
            {
                "name": "Affiliate Location Feed Item Address Line 1",
                "id": "extension_feed_item.affiliate_location_feed_item.address_line_1",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Affiliate Location Feed Item Address Line 2",
                "id": "extension_feed_item.affiliate_location_feed_item.address_line_2",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Affiliate Location Feed Item Business Name",
                "id": "extension_feed_item.affiliate_location_feed_item.business_name",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Affiliate Location Feed Item Chain Name",
                "id": "extension_feed_item.affiliate_location_feed_item.chain_name",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Affiliate Location Feed Item City",
                "id": "extension_feed_item.affiliate_location_feed_item.city",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Affiliate Location Feed Item Country Code",
                "id": "extension_feed_item.affiliate_location_feed_item.country_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Affiliate Location Feed Item Phone Number",
                "id": "extension_feed_item.affiliate_location_feed_item.phone_number",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Affiliate Location Feed Item Postal Code",
                "id": "extension_feed_item.affiliate_location_feed_item.postal_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Affiliate Location Feed Item Province",
                "id": "extension_feed_item.affiliate_location_feed_item.province",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "App Feed Item App Id",
                "id": "extension_feed_item.app_feed_item.app_id",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "App Feed Item App Store",
                "id": "extension_feed_item.app_feed_item.app_store",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "App Feed Item Final Mobile Urls",
                "id": "extension_feed_item.app_feed_item.final_mobile_urls",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "App Feed Item Final Url Suffix",
                "id": "extension_feed_item.app_feed_item.final_url_suffix",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "App Feed Item Final Urls",
                "id": "extension_feed_item.app_feed_item.final_urls",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "App Feed Item Link Text",
                "id": "extension_feed_item.app_feed_item.link_text",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "App Feed Item Tracking Url Template",
                "id": "extension_feed_item.app_feed_item.tracking_url_template",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Call Feed Item Call Conversion Action",
                "id": "extension_feed_item.call_feed_item.call_conversion_action",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Call Feed Item Call Conversion Reporting State",
                "id": "extension_feed_item.call_feed_item.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "Call Feed Item Call Conversion Tracking Disabled",
                "id": "extension_feed_item.call_feed_item.call_conversion_tracking_disabled",
                "dataType": "BOOLEAN",
                "group": "Extension Feed Item"
            },
            {
                "name": "Call Feed Item Call Tracking Enabled",
                "id": "extension_feed_item.call_feed_item.call_tracking_enabled",
                "dataType": "BOOLEAN",
                "group": "Extension Feed Item"
            },
            {
                "name": "Call Feed Item Country Code",
                "id": "extension_feed_item.call_feed_item.country_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Call Feed Item Phone Number",
                "id": "extension_feed_item.call_feed_item.phone_number",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Callout Feed Item Callout Text",
                "id": "extension_feed_item.callout_feed_item.callout_text",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Device",
                "id": "extension_feed_item.device",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "End Date Time",
                "id": "extension_feed_item.end_date_time",
                "dataType": "DATE",
                "group": "Extension Feed Item"
            },
            {
                "name": "Extension Type",
                "id": "extension_feed_item.extension_type",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "Hotel Callout Feed Item Language Code",
                "id": "extension_feed_item.hotel_callout_feed_item.language_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Hotel Callout Feed Item Text",
                "id": "extension_feed_item.hotel_callout_feed_item.text",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Image Feed Item Image Asset",
                "id": "extension_feed_item.image_feed_item.image_asset",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Location Feed Item Address Line 1",
                "id": "extension_feed_item.location_feed_item.address_line_1",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Location Feed Item Address Line 2",
                "id": "extension_feed_item.location_feed_item.address_line_2",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Location Feed Item Business Name",
                "id": "extension_feed_item.location_feed_item.business_name",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Location Feed Item City",
                "id": "extension_feed_item.location_feed_item.city",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Location Feed Item Country Code",
                "id": "extension_feed_item.location_feed_item.country_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Location Feed Item Phone Number",
                "id": "extension_feed_item.location_feed_item.phone_number",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Location Feed Item Postal Code",
                "id": "extension_feed_item.location_feed_item.postal_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Location Feed Item Province",
                "id": "extension_feed_item.location_feed_item.province",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Price Feed Item Final Url Suffix",
                "id": "extension_feed_item.price_feed_item.final_url_suffix",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Price Feed Item Language Code",
                "id": "extension_feed_item.price_feed_item.language_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Price Feed Item Price Offerings",
                "id": "extension_feed_item.price_feed_item.price_offerings",
                "dataType": "OBJECT",
                "group": "Extension Feed Item"
            },
            {
                "name": "Price Feed Item Price Qualifier",
                "id": "extension_feed_item.price_feed_item.price_qualifier",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "Price Feed Item Tracking Url Template",
                "id": "extension_feed_item.price_feed_item.tracking_url_template",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Price Feed Item Type",
                "id": "extension_feed_item.price_feed_item.type",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Discount Modifier",
                "id": "extension_feed_item.promotion_feed_item.discount_modifier",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Final Mobile Urls",
                "id": "extension_feed_item.promotion_feed_item.final_mobile_urls",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Final Url Suffix",
                "id": "extension_feed_item.promotion_feed_item.final_url_suffix",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Final Urls",
                "id": "extension_feed_item.promotion_feed_item.final_urls",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Language Code",
                "id": "extension_feed_item.promotion_feed_item.language_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Money Amount Off Currency Code",
                "id": "extension_feed_item.promotion_feed_item.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Occasion",
                "id": "extension_feed_item.promotion_feed_item.occasion",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Orders Over Amount Currency Code",
                "id": "extension_feed_item.promotion_feed_item.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Promotion Code",
                "id": "extension_feed_item.promotion_feed_item.promotion_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Promotion End Date",
                "id": "extension_feed_item.promotion_feed_item.promotion_end_date",
                "dataType": "DATE",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Promotion Start Date",
                "id": "extension_feed_item.promotion_feed_item.promotion_start_date",
                "dataType": "DATE",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Promotion Target",
                "id": "extension_feed_item.promotion_feed_item.promotion_target",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Tracking Url Template",
                "id": "extension_feed_item.promotion_feed_item.tracking_url_template",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Resource Name",
                "id": "extension_feed_item.resource_name",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Sitelink Feed Item Final Mobile Urls",
                "id": "extension_feed_item.sitelink_feed_item.final_mobile_urls",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Sitelink Feed Item Final Url Suffix",
                "id": "extension_feed_item.sitelink_feed_item.final_url_suffix",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Sitelink Feed Item Final Urls",
                "id": "extension_feed_item.sitelink_feed_item.final_urls",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Sitelink Feed Item Line1",
                "id": "extension_feed_item.sitelink_feed_item.line1",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Sitelink Feed Item Line2",
                "id": "extension_feed_item.sitelink_feed_item.line2",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Sitelink Feed Item Link Text",
                "id": "extension_feed_item.sitelink_feed_item.link_text",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Sitelink Feed Item Tracking Url Template",
                "id": "extension_feed_item.sitelink_feed_item.tracking_url_template",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Start Date Time",
                "id": "extension_feed_item.start_date_time",
                "dataType": "DATE",
                "group": "Extension Feed Item"
            },
            {
                "name": "Status",
                "id": "extension_feed_item.status",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "Structured Snippet Feed Item Header",
                "id": "extension_feed_item.structured_snippet_feed_item.header",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Structured Snippet Feed Item Values",
                "id": "extension_feed_item.structured_snippet_feed_item.values",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Targeted Ad Group",
                "id": "extension_feed_item.targeted_ad_group",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Targeted Campaign",
                "id": "extension_feed_item.targeted_campaign",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Targeted Geo Target Constant",
                "id": "extension_feed_item.targeted_geo_target_constant",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Targeted Keyword Match Type",
                "id": "extension_feed_item.targeted_keyword.match_type",
                "dataType": "ENUM",
                "group": "Extension Feed Item"
            },
            {
                "name": "Targeted Keyword Text",
                "id": "extension_feed_item.targeted_keyword.text",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Text Message Feed Item Business Name",
                "id": "extension_feed_item.text_message_feed_item.business_name",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Text Message Feed Item Country Code",
                "id": "extension_feed_item.text_message_feed_item.country_code",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Text Message Feed Item Extension Text",
                "id": "extension_feed_item.text_message_feed_item.extension_text",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Text Message Feed Item Phone Number",
                "id": "extension_feed_item.text_message_feed_item.phone_number",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Text Message Feed Item Text",
                "id": "extension_feed_item.text_message_feed_item.text",
                "dataType": "STRING",
                "group": "Extension Feed Item"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Interaction On This Extension",
                "id": "segments.interaction_on_this_extension",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "feed": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            }
        ],
        "feed_item": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Action Items",
                "id": "ad_group_ad.action_items",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Added By Google Ads",
                "id": "ad_group_ad.ad.added_by_google_ads",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Descriptions",
                "id": "ad_group_ad.ad.app_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Headlines",
                "id": "ad_group_ad.ad.app_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles Asset",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images",
                "id": "ad_group_ad.ad.app_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images Asset",
                "id": "ad_group_ad.ad.app_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Mandatory Ad Text",
                "id": "ad_group_ad.ad.app_ad.mandatory_ad_text",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Descriptions",
                "id": "ad_group_ad.ad.app_engagement_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Headlines",
                "id": "ad_group_ad.ad.app_engagement_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images",
                "id": "ad_group_ad.ad.app_engagement_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos",
                "id": "ad_group_ad.ad.app_engagement_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Descriptions",
                "id": "ad_group_ad.ad.app_pre_registration_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Headlines",
                "id": "ad_group_ad.ad.app_pre_registration_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Business Name",
                "id": "ad_group_ad.ad.call_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Call Tracked",
                "id": "ad_group_ad.ad.call_ad.call_tracked",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Action",
                "id": "ad_group_ad.ad.call_ad.conversion_action",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Reporting State",
                "id": "ad_group_ad.ad.call_ad.conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Country Code",
                "id": "ad_group_ad.ad.call_ad.country_code",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description1",
                "id": "ad_group_ad.ad.call_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description2",
                "id": "ad_group_ad.ad.call_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Disable Call Conversion",
                "id": "ad_group_ad.ad.call_ad.disable_call_conversion",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline1",
                "id": "ad_group_ad.ad.call_ad.headline1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline2",
                "id": "ad_group_ad.ad.call_ad.headline2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path1",
                "id": "ad_group_ad.ad.call_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path2",
                "id": "ad_group_ad.ad.call_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number",
                "id": "ad_group_ad.ad.call_ad.phone_number",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number Verification Url",
                "id": "ad_group_ad.ad.call_ad.phone_number_verification_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Device Preference",
                "id": "ad_group_ad.ad.device_preference",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Business Name",
                "id": "ad_group_ad.ad.discovery_carousel_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_carousel_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Description",
                "id": "ad_group_ad.ad.discovery_carousel_ad.description",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Headline",
                "id": "ad_group_ad.ad.discovery_carousel_ad.headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Business Name",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Descriptions",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Headlines",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Lead Form Only",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.lead_form_only",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Display Upload Product Type",
                "id": "ad_group_ad.ad.display_upload_ad.display_upload_product_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle Asset",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Url",
                "id": "ad_group_ad.ad.display_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description2",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description",
                "id": "ad_group_ad.ad.expanded_text_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description2",
                "id": "ad_group_ad.ad.expanded_text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part1",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part2",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part3",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part3",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path1",
                "id": "ad_group_ad.ad.expanded_text_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path2",
                "id": "ad_group_ad.ad.expanded_text_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final App Urls",
                "id": "ad_group_ad.ad.final_app_urls",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Mobile Urls",
                "id": "ad_group_ad.ad.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Url Suffix",
                "id": "ad_group_ad.ad.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Urls",
                "id": "ad_group_ad.ad.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Hotel Ad",
                "id": "ad_group_ad.ad.hotel_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Image Url",
                "id": "ad_group_ad.ad.image_ad.image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Media File",
                "id": "ad_group_ad.ad.image_ad.media_file",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Mime Type",
                "id": "ad_group_ad.ad.image_ad.mime_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Name",
                "id": "ad_group_ad.ad.image_ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Image Url",
                "id": "ad_group_ad.ad.image_ad.preview_image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy App Install Ad",
                "id": "ad_group_ad.ad.legacy_app_install_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Description",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.long_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Short Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.short_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Call To Actions",
                "id": "ad_group_ad.ad.local_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Descriptions",
                "id": "ad_group_ad.ad.local_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Headlines",
                "id": "ad_group_ad.ad.local_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images",
                "id": "ad_group_ad.ad.local_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images Asset",
                "id": "ad_group_ad.ad.local_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images",
                "id": "ad_group_ad.ad.local_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.local_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path1",
                "id": "ad_group_ad.ad.local_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path2",
                "id": "ad_group_ad.ad.local_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos",
                "id": "ad_group_ad.ad.local_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos Asset",
                "id": "ad_group_ad.ad.local_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Name",
                "id": "ad_group_ad.ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Resource Name",
                "id": "ad_group_ad.ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Asset Enhancements",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_asset_enhancements",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Autogen Video",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_autogen_video",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_display_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Headlines",
                "id": "ad_group_ad.ad.responsive_display_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.responsive_display_ad.long_headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_search_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Headlines",
                "id": "ad_group_ad.ad.responsive_search_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path1",
                "id": "ad_group_ad.ad.responsive_search_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path2",
                "id": "ad_group_ad.ad.responsive_search_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Comparison Listing Ad Headline",
                "id": "ad_group_ad.ad.shopping_comparison_listing_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Product Ad",
                "id": "ad_group_ad.ad.shopping_product_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Smart Ad",
                "id": "ad_group_ad.ad.shopping_smart_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Descriptions",
                "id": "ad_group_ad.ad.smart_campaign_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Headlines",
                "id": "ad_group_ad.ad.smart_campaign_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad System Managed Resource Source",
                "id": "ad_group_ad.ad.system_managed_resource_source",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description1",
                "id": "ad_group_ad.ad.text_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description2",
                "id": "ad_group_ad.ad.text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Headline",
                "id": "ad_group_ad.ad.text_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Tracking Url Template",
                "id": "ad_group_ad.ad.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Type",
                "id": "ad_group_ad.ad.type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Collections",
                "id": "ad_group_ad.ad.url_collections",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Custom Parameters",
                "id": "ad_group_ad.ad.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Bumper Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.bumper.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description1",
                "id": "ad_group_ad.ad.video_ad.in_feed.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description2",
                "id": "ad_group_ad.ad.video_ad.in_feed.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Headline",
                "id": "ad_group_ad.ad.video_ad.in_feed.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Thumbnail",
                "id": "ad_group_ad.ad.video_ad.in_feed.thumbnail",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Button Label",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Headline",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.in_stream.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Button Label",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Headline",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.non_skippable.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Description",
                "id": "ad_group_ad.ad.video_ad.out_stream.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Headline",
                "id": "ad_group_ad.ad.video_ad.out_stream.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Video Asset",
                "id": "ad_group_ad.ad.video_ad.video.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb1",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb2",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Call To Actions",
                "id": "ad_group_ad.ad.video_responsive_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Descriptions",
                "id": "ad_group_ad.ad.video_responsive_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Long Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.long_headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos",
                "id": "ad_group_ad.ad.video_responsive_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_ad.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Strength",
                "id": "ad_group_ad.ad_strength",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Labels",
                "id": "ad_group_ad.labels",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "ad_group_ad.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "ad_group_ad.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "ad_group_ad.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Status",
                "id": "ad_group_ad.status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attribute Values",
                "id": "feed_item.attribute_values",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "End Date Time",
                "id": "feed_item.end_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Feed",
                "id": "feed_item.feed",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Geo Targeting Restriction",
                "id": "feed_item.geo_targeting_restriction",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Policy Infos",
                "id": "feed_item.policy_infos",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "Resource Name",
                "id": "feed_item.resource_name",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Start Date Time",
                "id": "feed_item.start_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Status",
                "id": "feed_item.status",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Url Custom Parameters",
                "id": "feed_item.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Interaction On This Extension",
                "id": "segments.interaction_on_this_extension",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Placeholder Type",
                "id": "segments.placeholder_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "feed_item_set": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Display Name",
                "id": "feed_item_set.display_name",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Dynamic Location Set Filter Business Name Filter Business Name",
                "id": "feed_item_set.dynamic_location_set_filter.business_name_filter.business_name",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Dynamic Location Set Filter Business Name Filter Filter Type",
                "id": "feed_item_set.dynamic_location_set_filter.business_name_filter.filter_type",
                "dataType": "ENUM",
                "group": "Feed Item Set"
            },
            {
                "name": "Dynamic Location Set Filter Labels",
                "id": "feed_item_set.dynamic_location_set_filter.labels",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Feed",
                "id": "feed_item_set.feed",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Resource Name",
                "id": "feed_item_set.resource_name",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Status",
                "id": "feed_item_set.status",
                "dataType": "ENUM",
                "group": "Feed Item Set"
            }
        ],
        "feed_item_set_link": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attribute Values",
                "id": "feed_item.attribute_values",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "End Date Time",
                "id": "feed_item.end_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Feed",
                "id": "feed_item.feed",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Geo Targeting Restriction",
                "id": "feed_item.geo_targeting_restriction",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Policy Infos",
                "id": "feed_item.policy_infos",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "Resource Name",
                "id": "feed_item.resource_name",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Start Date Time",
                "id": "feed_item.start_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Status",
                "id": "feed_item.status",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Url Custom Parameters",
                "id": "feed_item.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "Display Name",
                "id": "feed_item_set.display_name",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Dynamic Location Set Filter Business Name Filter Business Name",
                "id": "feed_item_set.dynamic_location_set_filter.business_name_filter.business_name",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Dynamic Location Set Filter Business Name Filter Filter Type",
                "id": "feed_item_set.dynamic_location_set_filter.business_name_filter.filter_type",
                "dataType": "ENUM",
                "group": "Feed Item Set"
            },
            {
                "name": "Dynamic Location Set Filter Labels",
                "id": "feed_item_set.dynamic_location_set_filter.labels",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Feed",
                "id": "feed_item_set.feed",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Resource Name",
                "id": "feed_item_set.resource_name",
                "dataType": "STRING",
                "group": "Feed Item Set"
            },
            {
                "name": "Status",
                "id": "feed_item_set.status",
                "dataType": "ENUM",
                "group": "Feed Item Set"
            },
            {
                "name": "Feed Item",
                "id": "feed_item_set_link.feed_item",
                "dataType": "STRING",
                "group": "Feed Item Set Link"
            },
            {
                "name": "Feed Item Set",
                "id": "feed_item_set_link.feed_item_set",
                "dataType": "STRING",
                "group": "Feed Item Set Link"
            },
            {
                "name": "Resource Name",
                "id": "feed_item_set_link.resource_name",
                "dataType": "STRING",
                "group": "Feed Item Set Link"
            }
        ],
        "feed_item_target": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attribute Values",
                "id": "feed_item.attribute_values",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "End Date Time",
                "id": "feed_item.end_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Feed",
                "id": "feed_item.feed",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Geo Targeting Restriction",
                "id": "feed_item.geo_targeting_restriction",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Policy Infos",
                "id": "feed_item.policy_infos",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "Resource Name",
                "id": "feed_item.resource_name",
                "dataType": "STRING",
                "group": "Feed Item"
            },
            {
                "name": "Start Date Time",
                "id": "feed_item.start_date_time",
                "dataType": "DATE",
                "group": "Feed Item"
            },
            {
                "name": "Status",
                "id": "feed_item.status",
                "dataType": "ENUM",
                "group": "Feed Item"
            },
            {
                "name": "Url Custom Parameters",
                "id": "feed_item.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Feed Item"
            },
            {
                "name": "Ad Group",
                "id": "feed_item_target.ad_group",
                "dataType": "STRING",
                "group": "Feed Item Target"
            },
            {
                "name": "Ad Schedule",
                "id": "feed_item_target.ad_schedule",
                "dataType": "OBJECT",
                "group": "Feed Item Target"
            },
            {
                "name": "Ad Schedule Day Of Week",
                "id": "feed_item_target.ad_schedule.day_of_week",
                "dataType": "ENUM",
                "group": "Feed Item Target"
            },
            {
                "name": "Campaign",
                "id": "feed_item_target.campaign",
                "dataType": "STRING",
                "group": "Feed Item Target"
            },
            {
                "name": "Device",
                "id": "feed_item_target.device",
                "dataType": "ENUM",
                "group": "Feed Item Target"
            },
            {
                "name": "Feed Item",
                "id": "feed_item_target.feed_item",
                "dataType": "STRING",
                "group": "Feed Item Target"
            },
            {
                "name": "Feed Item Target Type",
                "id": "feed_item_target.feed_item_target_type",
                "dataType": "ENUM",
                "group": "Feed Item Target"
            },
            {
                "name": "Geo Target Constant",
                "id": "feed_item_target.geo_target_constant",
                "dataType": "STRING",
                "group": "Feed Item Target"
            },
            {
                "name": "Keyword Match Type",
                "id": "feed_item_target.keyword.match_type",
                "dataType": "ENUM",
                "group": "Feed Item Target"
            },
            {
                "name": "Keyword Text",
                "id": "feed_item_target.keyword.text",
                "dataType": "STRING",
                "group": "Feed Item Target"
            },
            {
                "name": "Resource Name",
                "id": "feed_item_target.resource_name",
                "dataType": "STRING",
                "group": "Feed Item Target"
            },
            {
                "name": "Status",
                "id": "feed_item_target.status",
                "dataType": "ENUM",
                "group": "Feed Item Target"
            }
        ],
        "feed_mapping": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Relationship Type",
                "id": "feed.affiliate_location_feed_data.relationship_type",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attributes",
                "id": "feed.attributes",
                "dataType": "OBJECT",
                "group": "Feed"
            },
            {
                "name": "Name",
                "id": "feed.name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Origin",
                "id": "feed.origin",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Business Name Filter",
                "id": "feed.places_location_feed_data.business_name_filter",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Category Filters",
                "id": "feed.places_location_feed_data.category_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Email Address",
                "id": "feed.places_location_feed_data.email_address",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Places Location Feed Data Label Filters",
                "id": "feed.places_location_feed_data.label_filters",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Resource Name",
                "id": "feed.resource_name",
                "dataType": "STRING",
                "group": "Feed"
            },
            {
                "name": "Status",
                "id": "feed.status",
                "dataType": "ENUM",
                "group": "Feed"
            },
            {
                "name": "Attribute Field Mappings",
                "id": "feed_mapping.attribute_field_mappings",
                "dataType": "OBJECT",
                "group": "Feed Mapping"
            },
            {
                "name": "Criterion Type",
                "id": "feed_mapping.criterion_type",
                "dataType": "ENUM",
                "group": "Feed Mapping"
            },
            {
                "name": "Feed",
                "id": "feed_mapping.feed",
                "dataType": "STRING",
                "group": "Feed Mapping"
            },
            {
                "name": "Placeholder Type",
                "id": "feed_mapping.placeholder_type",
                "dataType": "ENUM",
                "group": "Feed Mapping"
            },
            {
                "name": "Resource Name",
                "id": "feed_mapping.resource_name",
                "dataType": "STRING",
                "group": "Feed Mapping"
            },
            {
                "name": "Status",
                "id": "feed_mapping.status",
                "dataType": "ENUM",
                "group": "Feed Mapping"
            }
        ],
        "feed_placeholder_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Action Items",
                "id": "ad_group_ad.action_items",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Added By Google Ads",
                "id": "ad_group_ad.ad.added_by_google_ads",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Descriptions",
                "id": "ad_group_ad.ad.app_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Headlines",
                "id": "ad_group_ad.ad.app_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles Asset",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images",
                "id": "ad_group_ad.ad.app_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images Asset",
                "id": "ad_group_ad.ad.app_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Mandatory Ad Text",
                "id": "ad_group_ad.ad.app_ad.mandatory_ad_text",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Descriptions",
                "id": "ad_group_ad.ad.app_engagement_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Headlines",
                "id": "ad_group_ad.ad.app_engagement_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images",
                "id": "ad_group_ad.ad.app_engagement_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos",
                "id": "ad_group_ad.ad.app_engagement_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Descriptions",
                "id": "ad_group_ad.ad.app_pre_registration_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Headlines",
                "id": "ad_group_ad.ad.app_pre_registration_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Business Name",
                "id": "ad_group_ad.ad.call_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Call Tracked",
                "id": "ad_group_ad.ad.call_ad.call_tracked",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Action",
                "id": "ad_group_ad.ad.call_ad.conversion_action",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Reporting State",
                "id": "ad_group_ad.ad.call_ad.conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Country Code",
                "id": "ad_group_ad.ad.call_ad.country_code",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description1",
                "id": "ad_group_ad.ad.call_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description2",
                "id": "ad_group_ad.ad.call_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Disable Call Conversion",
                "id": "ad_group_ad.ad.call_ad.disable_call_conversion",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline1",
                "id": "ad_group_ad.ad.call_ad.headline1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline2",
                "id": "ad_group_ad.ad.call_ad.headline2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path1",
                "id": "ad_group_ad.ad.call_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path2",
                "id": "ad_group_ad.ad.call_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number",
                "id": "ad_group_ad.ad.call_ad.phone_number",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number Verification Url",
                "id": "ad_group_ad.ad.call_ad.phone_number_verification_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Device Preference",
                "id": "ad_group_ad.ad.device_preference",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Business Name",
                "id": "ad_group_ad.ad.discovery_carousel_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_carousel_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Description",
                "id": "ad_group_ad.ad.discovery_carousel_ad.description",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Headline",
                "id": "ad_group_ad.ad.discovery_carousel_ad.headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Business Name",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Descriptions",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Headlines",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Lead Form Only",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.lead_form_only",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Display Upload Product Type",
                "id": "ad_group_ad.ad.display_upload_ad.display_upload_product_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle Asset",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Url",
                "id": "ad_group_ad.ad.display_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description2",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description",
                "id": "ad_group_ad.ad.expanded_text_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description2",
                "id": "ad_group_ad.ad.expanded_text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part1",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part2",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part3",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part3",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path1",
                "id": "ad_group_ad.ad.expanded_text_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path2",
                "id": "ad_group_ad.ad.expanded_text_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final App Urls",
                "id": "ad_group_ad.ad.final_app_urls",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Mobile Urls",
                "id": "ad_group_ad.ad.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Url Suffix",
                "id": "ad_group_ad.ad.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Urls",
                "id": "ad_group_ad.ad.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Hotel Ad",
                "id": "ad_group_ad.ad.hotel_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Image Url",
                "id": "ad_group_ad.ad.image_ad.image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Media File",
                "id": "ad_group_ad.ad.image_ad.media_file",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Mime Type",
                "id": "ad_group_ad.ad.image_ad.mime_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Name",
                "id": "ad_group_ad.ad.image_ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Image Url",
                "id": "ad_group_ad.ad.image_ad.preview_image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy App Install Ad",
                "id": "ad_group_ad.ad.legacy_app_install_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Description",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.long_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Short Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.short_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Call To Actions",
                "id": "ad_group_ad.ad.local_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Descriptions",
                "id": "ad_group_ad.ad.local_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Headlines",
                "id": "ad_group_ad.ad.local_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images",
                "id": "ad_group_ad.ad.local_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images Asset",
                "id": "ad_group_ad.ad.local_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images",
                "id": "ad_group_ad.ad.local_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.local_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path1",
                "id": "ad_group_ad.ad.local_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path2",
                "id": "ad_group_ad.ad.local_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos",
                "id": "ad_group_ad.ad.local_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos Asset",
                "id": "ad_group_ad.ad.local_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Name",
                "id": "ad_group_ad.ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Resource Name",
                "id": "ad_group_ad.ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Asset Enhancements",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_asset_enhancements",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Autogen Video",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_autogen_video",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_display_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Headlines",
                "id": "ad_group_ad.ad.responsive_display_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.responsive_display_ad.long_headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_search_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Headlines",
                "id": "ad_group_ad.ad.responsive_search_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path1",
                "id": "ad_group_ad.ad.responsive_search_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path2",
                "id": "ad_group_ad.ad.responsive_search_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Comparison Listing Ad Headline",
                "id": "ad_group_ad.ad.shopping_comparison_listing_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Product Ad",
                "id": "ad_group_ad.ad.shopping_product_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Smart Ad",
                "id": "ad_group_ad.ad.shopping_smart_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Descriptions",
                "id": "ad_group_ad.ad.smart_campaign_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Headlines",
                "id": "ad_group_ad.ad.smart_campaign_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad System Managed Resource Source",
                "id": "ad_group_ad.ad.system_managed_resource_source",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description1",
                "id": "ad_group_ad.ad.text_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description2",
                "id": "ad_group_ad.ad.text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Headline",
                "id": "ad_group_ad.ad.text_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Tracking Url Template",
                "id": "ad_group_ad.ad.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Type",
                "id": "ad_group_ad.ad.type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Collections",
                "id": "ad_group_ad.ad.url_collections",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Custom Parameters",
                "id": "ad_group_ad.ad.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Bumper Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.bumper.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description1",
                "id": "ad_group_ad.ad.video_ad.in_feed.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description2",
                "id": "ad_group_ad.ad.video_ad.in_feed.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Headline",
                "id": "ad_group_ad.ad.video_ad.in_feed.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Thumbnail",
                "id": "ad_group_ad.ad.video_ad.in_feed.thumbnail",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Button Label",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Headline",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.in_stream.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Button Label",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Headline",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.non_skippable.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Description",
                "id": "ad_group_ad.ad.video_ad.out_stream.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Headline",
                "id": "ad_group_ad.ad.video_ad.out_stream.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Video Asset",
                "id": "ad_group_ad.ad.video_ad.video.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb1",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb2",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Call To Actions",
                "id": "ad_group_ad.ad.video_responsive_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Descriptions",
                "id": "ad_group_ad.ad.video_responsive_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Long Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.long_headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos",
                "id": "ad_group_ad.ad.video_responsive_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_ad.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Strength",
                "id": "ad_group_ad.ad_strength",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Labels",
                "id": "ad_group_ad.labels",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "ad_group_ad.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "ad_group_ad.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "ad_group_ad.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Status",
                "id": "ad_group_ad.status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Placeholder Type",
                "id": "feed_placeholder_view.placeholder_type",
                "dataType": "ENUM",
                "group": "Feed Placeholder View"
            },
            {
                "name": "Resource Name",
                "id": "feed_placeholder_view.resource_name",
                "dataType": "STRING",
                "group": "Feed Placeholder View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "gender_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "gender_view.resource_name",
                "dataType": "STRING",
                "group": "Gender View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "geo_target_constant": [
            {
                "name": "Canonical Name",
                "id": "geo_target_constant.canonical_name",
                "dataType": "STRING",
                "group": "Geo Target Constant"
            },
            {
                "name": "Country Code",
                "id": "geo_target_constant.country_code",
                "dataType": "STRING",
                "group": "Geo Target Constant"
            },
            {
                "name": "Name",
                "id": "geo_target_constant.name",
                "dataType": "STRING",
                "group": "Geo Target Constant"
            },
            {
                "name": "Parent Geo Target",
                "id": "geo_target_constant.parent_geo_target",
                "dataType": "STRING",
                "group": "Geo Target Constant"
            },
            {
                "name": "Resource Name",
                "id": "geo_target_constant.resource_name",
                "dataType": "STRING",
                "group": "Geo Target Constant"
            },
            {
                "name": "Status",
                "id": "geo_target_constant.status",
                "dataType": "ENUM",
                "group": "Geo Target Constant"
            },
            {
                "name": "Target Type",
                "id": "geo_target_constant.target_type",
                "dataType": "STRING",
                "group": "Geo Target Constant"
            }
        ],
        "geographic_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Location Type",
                "id": "geographic_view.location_type",
                "dataType": "ENUM",
                "group": "Geographic View"
            },
            {
                "name": "Resource Name",
                "id": "geographic_view.resource_name",
                "dataType": "STRING",
                "group": "Geographic View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Geo Target Airport",
                "id": "segments.geo_target_airport",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Canton",
                "id": "segments.geo_target_canton",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target City",
                "id": "segments.geo_target_city",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target County",
                "id": "segments.geo_target_county",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target District",
                "id": "segments.geo_target_district",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Metro",
                "id": "segments.geo_target_metro",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Most Specific Location",
                "id": "segments.geo_target_most_specific_location",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Postal Code",
                "id": "segments.geo_target_postal_code",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Province",
                "id": "segments.geo_target_province",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Region",
                "id": "segments.geo_target_region",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target State",
                "id": "segments.geo_target_state",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "group_placement_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Display Name",
                "id": "group_placement_view.display_name",
                "dataType": "STRING",
                "group": "Group Placement View"
            },
            {
                "name": "Placement",
                "id": "group_placement_view.placement",
                "dataType": "STRING",
                "group": "Group Placement View"
            },
            {
                "name": "Placement Type",
                "id": "group_placement_view.placement_type",
                "dataType": "ENUM",
                "group": "Group Placement View"
            },
            {
                "name": "Resource Name",
                "id": "group_placement_view.resource_name",
                "dataType": "STRING",
                "group": "Group Placement View"
            },
            {
                "name": "Target Url",
                "id": "group_placement_view.target_url",
                "dataType": "STRING",
                "group": "Group Placement View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "hotel_group_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "hotel_group_view.resource_name",
                "dataType": "STRING",
                "group": "Hotel Group View"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "hotel_performance_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "hotel_performance_view.resource_name",
                "dataType": "STRING",
                "group": "Hotel Performance View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Geo Target Country",
                "id": "segments.geo_target_country",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Hotel Check In Date",
                "id": "segments.hotel_check_in_date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Hotel Check In Day Of Week",
                "id": "segments.hotel_check_in_day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Hotel City",
                "id": "segments.hotel_city",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Hotel Country",
                "id": "segments.hotel_country",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Hotel Date Selection Type",
                "id": "segments.hotel_date_selection_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Hotel Price Bucket",
                "id": "segments.hotel_price_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Hotel Rate Rule Id",
                "id": "segments.hotel_rate_rule_id",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Hotel Rate Type",
                "id": "segments.hotel_rate_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Hotel State",
                "id": "segments.hotel_state",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Partner Hotel Id",
                "id": "segments.partner_hotel_id",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "hotel_reconciliation": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Billed",
                "id": "hotel_reconciliation.billed",
                "dataType": "BOOLEAN",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Campaign",
                "id": "hotel_reconciliation.campaign",
                "dataType": "STRING",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Check In Date",
                "id": "hotel_reconciliation.check_in_date",
                "dataType": "DATE",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Check Out Date",
                "id": "hotel_reconciliation.check_out_date",
                "dataType": "DATE",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Commission Id",
                "id": "hotel_reconciliation.commission_id",
                "dataType": "STRING",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Hotel Id",
                "id": "hotel_reconciliation.hotel_id",
                "dataType": "STRING",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Order Id",
                "id": "hotel_reconciliation.order_id",
                "dataType": "STRING",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Resource Name",
                "id": "hotel_reconciliation.resource_name",
                "dataType": "STRING",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Status",
                "id": "hotel_reconciliation.status",
                "dataType": "ENUM",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "income_range_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "income_range_view.resource_name",
                "dataType": "STRING",
                "group": "Income Range View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "keyword_plan": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Forecast Period",
                "id": "keyword_plan.forecast_period",
                "dataType": "OBJECT",
                "group": "Keyword Plan"
            },
            {
                "name": "Name",
                "id": "keyword_plan.name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            }
        ],
        "keyword_plan_ad_group": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Forecast Period",
                "id": "keyword_plan.forecast_period",
                "dataType": "OBJECT",
                "group": "Keyword Plan"
            },
            {
                "name": "Name",
                "id": "keyword_plan.name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            },
            {
                "name": "Keyword Plan Campaign",
                "id": "keyword_plan_ad_group.keyword_plan_campaign",
                "dataType": "STRING",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Name",
                "id": "keyword_plan_ad_group.name",
                "dataType": "STRING",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan_ad_group.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Geo Targets",
                "id": "keyword_plan_campaign.geo_targets",
                "dataType": "OBJECT",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Geo Targets Geo Target Constant",
                "id": "keyword_plan_campaign.geo_targets.geo_target_constant",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Keyword Plan",
                "id": "keyword_plan_campaign.keyword_plan",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Keyword Plan Network",
                "id": "keyword_plan_campaign.keyword_plan_network",
                "dataType": "ENUM",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Language Constants",
                "id": "keyword_plan_campaign.language_constants",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Name",
                "id": "keyword_plan_campaign.name",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan_campaign.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            }
        ],
        "keyword_plan_ad_group_keyword": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Forecast Period",
                "id": "keyword_plan.forecast_period",
                "dataType": "OBJECT",
                "group": "Keyword Plan"
            },
            {
                "name": "Name",
                "id": "keyword_plan.name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            },
            {
                "name": "Keyword Plan Campaign",
                "id": "keyword_plan_ad_group.keyword_plan_campaign",
                "dataType": "STRING",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Name",
                "id": "keyword_plan_ad_group.name",
                "dataType": "STRING",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan_ad_group.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Keyword Plan Ad Group",
                "id": "keyword_plan_ad_group_keyword.keyword_plan_ad_group",
                "dataType": "STRING",
                "group": "Keyword Plan Ad Group Keyword"
            },
            {
                "name": "Match Type",
                "id": "keyword_plan_ad_group_keyword.match_type",
                "dataType": "ENUM",
                "group": "Keyword Plan Ad Group Keyword"
            },
            {
                "name": "Negative",
                "id": "keyword_plan_ad_group_keyword.negative",
                "dataType": "BOOLEAN",
                "group": "Keyword Plan Ad Group Keyword"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan_ad_group_keyword.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan Ad Group Keyword"
            },
            {
                "name": "Text",
                "id": "keyword_plan_ad_group_keyword.text",
                "dataType": "STRING",
                "group": "Keyword Plan Ad Group Keyword"
            },
            {
                "name": "Geo Targets",
                "id": "keyword_plan_campaign.geo_targets",
                "dataType": "OBJECT",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Geo Targets Geo Target Constant",
                "id": "keyword_plan_campaign.geo_targets.geo_target_constant",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Keyword Plan",
                "id": "keyword_plan_campaign.keyword_plan",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Keyword Plan Network",
                "id": "keyword_plan_campaign.keyword_plan_network",
                "dataType": "ENUM",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Language Constants",
                "id": "keyword_plan_campaign.language_constants",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Name",
                "id": "keyword_plan_campaign.name",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan_campaign.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            }
        ],
        "keyword_plan_campaign": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Forecast Period",
                "id": "keyword_plan.forecast_period",
                "dataType": "OBJECT",
                "group": "Keyword Plan"
            },
            {
                "name": "Name",
                "id": "keyword_plan.name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            },
            {
                "name": "Geo Targets",
                "id": "keyword_plan_campaign.geo_targets",
                "dataType": "OBJECT",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Geo Targets Geo Target Constant",
                "id": "keyword_plan_campaign.geo_targets.geo_target_constant",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Keyword Plan",
                "id": "keyword_plan_campaign.keyword_plan",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Keyword Plan Network",
                "id": "keyword_plan_campaign.keyword_plan_network",
                "dataType": "ENUM",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Language Constants",
                "id": "keyword_plan_campaign.language_constants",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Name",
                "id": "keyword_plan_campaign.name",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan_campaign.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            }
        ],
        "keyword_plan_campaign_keyword": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Forecast Period",
                "id": "keyword_plan.forecast_period",
                "dataType": "OBJECT",
                "group": "Keyword Plan"
            },
            {
                "name": "Name",
                "id": "keyword_plan.name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan"
            },
            {
                "name": "Geo Targets",
                "id": "keyword_plan_campaign.geo_targets",
                "dataType": "OBJECT",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Geo Targets Geo Target Constant",
                "id": "keyword_plan_campaign.geo_targets.geo_target_constant",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Keyword Plan",
                "id": "keyword_plan_campaign.keyword_plan",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Keyword Plan Network",
                "id": "keyword_plan_campaign.keyword_plan_network",
                "dataType": "ENUM",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Language Constants",
                "id": "keyword_plan_campaign.language_constants",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Name",
                "id": "keyword_plan_campaign.name",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan_campaign.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Keyword Plan Campaign",
                "id": "keyword_plan_campaign_keyword.keyword_plan_campaign",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign Keyword"
            },
            {
                "name": "Match Type",
                "id": "keyword_plan_campaign_keyword.match_type",
                "dataType": "ENUM",
                "group": "Keyword Plan Campaign Keyword"
            },
            {
                "name": "Negative",
                "id": "keyword_plan_campaign_keyword.negative",
                "dataType": "BOOLEAN",
                "group": "Keyword Plan Campaign Keyword"
            },
            {
                "name": "Resource Name",
                "id": "keyword_plan_campaign_keyword.resource_name",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign Keyword"
            },
            {
                "name": "Text",
                "id": "keyword_plan_campaign_keyword.text",
                "dataType": "STRING",
                "group": "Keyword Plan Campaign Keyword"
            }
        ],
        "keyword_theme_constant": [
            {
                "name": "Country Code",
                "id": "keyword_theme_constant.country_code",
                "dataType": "STRING",
                "group": "Keyword Theme Constant"
            },
            {
                "name": "Display Name",
                "id": "keyword_theme_constant.display_name",
                "dataType": "STRING",
                "group": "Keyword Theme Constant"
            },
            {
                "name": "Language Code",
                "id": "keyword_theme_constant.language_code",
                "dataType": "STRING",
                "group": "Keyword Theme Constant"
            },
            {
                "name": "Resource Name",
                "id": "keyword_theme_constant.resource_name",
                "dataType": "STRING",
                "group": "Keyword Theme Constant"
            }
        ],
        "keyword_view": [
            {
                "name": "Name",
                "id": "accessible_bidding_strategy.name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Descriptive Name",
                "id": "accessible_bidding_strategy.owner_descriptive_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "accessible_bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "accessible_bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "accessible_bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "keyword_view.resource_name",
                "dataType": "STRING",
                "group": "Keyword View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Auction Insight Domain",
                "id": "segments.auction_insight_domain",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Adjustment",
                "id": "segments.conversion_adjustment",
                "dataType": "BOOLEAN",
                "group": "Segments"
            },
            {
                "name": "Conversion Lag Bucket",
                "id": "segments.conversion_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Or Adjustment Lag Bucket",
                "id": "segments.conversion_or_adjustment_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Historical Creative Quality Score",
                "id": "metrics.historical_creative_quality_score",
                "dataType": "ENUM",
                "group": "Metrics"
            },
            {
                "name": "Historical Landing Page Quality Score",
                "id": "metrics.historical_landing_page_quality_score",
                "dataType": "ENUM",
                "group": "Metrics"
            },
            {
                "name": "Historical Search Predicted Ctr",
                "id": "metrics.historical_search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Metrics"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "label": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "label.name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Resource Name",
                "id": "label.resource_name",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Status",
                "id": "label.status",
                "dataType": "ENUM",
                "group": "Label"
            },
            {
                "name": "Text Label Background Color",
                "id": "label.text_label.background_color",
                "dataType": "STRING",
                "group": "Label"
            },
            {
                "name": "Text Label Description",
                "id": "label.text_label.description",
                "dataType": "STRING",
                "group": "Label"
            }
        ],
        "landing_page_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Expanded Final Url",
                "id": "expanded_landing_page_view.expanded_final_url",
                "dataType": "STRING",
                "group": "Expanded Landing Page View"
            },
            {
                "name": "Resource Name",
                "id": "expanded_landing_page_view.resource_name",
                "dataType": "STRING",
                "group": "Expanded Landing Page View"
            },
            {
                "name": "Resource Name",
                "id": "landing_page_view.resource_name",
                "dataType": "STRING",
                "group": "Landing Page View"
            },
            {
                "name": "Unexpanded Final Url",
                "id": "landing_page_view.unexpanded_final_url",
                "dataType": "STRING",
                "group": "Landing Page View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "language_constant": [
            {
                "name": "Code",
                "id": "language_constant.code",
                "dataType": "STRING",
                "group": "Language Constant"
            },
            {
                "name": "Name",
                "id": "language_constant.name",
                "dataType": "STRING",
                "group": "Language Constant"
            },
            {
                "name": "Resource Name",
                "id": "language_constant.resource_name",
                "dataType": "STRING",
                "group": "Language Constant"
            },
            {
                "name": "Targetable",
                "id": "language_constant.targetable",
                "dataType": "BOOLEAN",
                "group": "Language Constant"
            }
        ],
        "lead_form_submission_data": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Action Items",
                "id": "ad_group_ad.action_items",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Added By Google Ads",
                "id": "ad_group_ad.ad.added_by_google_ads",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Descriptions",
                "id": "ad_group_ad.ad.app_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Headlines",
                "id": "ad_group_ad.ad.app_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles Asset",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images",
                "id": "ad_group_ad.ad.app_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images Asset",
                "id": "ad_group_ad.ad.app_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Mandatory Ad Text",
                "id": "ad_group_ad.ad.app_ad.mandatory_ad_text",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Descriptions",
                "id": "ad_group_ad.ad.app_engagement_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Headlines",
                "id": "ad_group_ad.ad.app_engagement_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images",
                "id": "ad_group_ad.ad.app_engagement_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos",
                "id": "ad_group_ad.ad.app_engagement_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Descriptions",
                "id": "ad_group_ad.ad.app_pre_registration_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Headlines",
                "id": "ad_group_ad.ad.app_pre_registration_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Business Name",
                "id": "ad_group_ad.ad.call_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Call Tracked",
                "id": "ad_group_ad.ad.call_ad.call_tracked",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Action",
                "id": "ad_group_ad.ad.call_ad.conversion_action",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Reporting State",
                "id": "ad_group_ad.ad.call_ad.conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Country Code",
                "id": "ad_group_ad.ad.call_ad.country_code",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description1",
                "id": "ad_group_ad.ad.call_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description2",
                "id": "ad_group_ad.ad.call_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Disable Call Conversion",
                "id": "ad_group_ad.ad.call_ad.disable_call_conversion",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline1",
                "id": "ad_group_ad.ad.call_ad.headline1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline2",
                "id": "ad_group_ad.ad.call_ad.headline2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path1",
                "id": "ad_group_ad.ad.call_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path2",
                "id": "ad_group_ad.ad.call_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number",
                "id": "ad_group_ad.ad.call_ad.phone_number",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number Verification Url",
                "id": "ad_group_ad.ad.call_ad.phone_number_verification_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Device Preference",
                "id": "ad_group_ad.ad.device_preference",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Business Name",
                "id": "ad_group_ad.ad.discovery_carousel_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_carousel_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Description",
                "id": "ad_group_ad.ad.discovery_carousel_ad.description",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Headline",
                "id": "ad_group_ad.ad.discovery_carousel_ad.headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Business Name",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Descriptions",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Headlines",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Lead Form Only",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.lead_form_only",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Display Upload Product Type",
                "id": "ad_group_ad.ad.display_upload_ad.display_upload_product_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle Asset",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Url",
                "id": "ad_group_ad.ad.display_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description2",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description",
                "id": "ad_group_ad.ad.expanded_text_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description2",
                "id": "ad_group_ad.ad.expanded_text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part1",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part2",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part3",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part3",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path1",
                "id": "ad_group_ad.ad.expanded_text_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path2",
                "id": "ad_group_ad.ad.expanded_text_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final App Urls",
                "id": "ad_group_ad.ad.final_app_urls",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Mobile Urls",
                "id": "ad_group_ad.ad.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Url Suffix",
                "id": "ad_group_ad.ad.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Urls",
                "id": "ad_group_ad.ad.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Hotel Ad",
                "id": "ad_group_ad.ad.hotel_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Image Url",
                "id": "ad_group_ad.ad.image_ad.image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Media File",
                "id": "ad_group_ad.ad.image_ad.media_file",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Mime Type",
                "id": "ad_group_ad.ad.image_ad.mime_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Name",
                "id": "ad_group_ad.ad.image_ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Image Url",
                "id": "ad_group_ad.ad.image_ad.preview_image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy App Install Ad",
                "id": "ad_group_ad.ad.legacy_app_install_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Description",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.long_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Short Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.short_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Call To Actions",
                "id": "ad_group_ad.ad.local_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Descriptions",
                "id": "ad_group_ad.ad.local_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Headlines",
                "id": "ad_group_ad.ad.local_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images",
                "id": "ad_group_ad.ad.local_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images Asset",
                "id": "ad_group_ad.ad.local_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images",
                "id": "ad_group_ad.ad.local_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.local_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path1",
                "id": "ad_group_ad.ad.local_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path2",
                "id": "ad_group_ad.ad.local_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos",
                "id": "ad_group_ad.ad.local_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos Asset",
                "id": "ad_group_ad.ad.local_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Name",
                "id": "ad_group_ad.ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Resource Name",
                "id": "ad_group_ad.ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Asset Enhancements",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_asset_enhancements",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Autogen Video",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_autogen_video",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_display_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Headlines",
                "id": "ad_group_ad.ad.responsive_display_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.responsive_display_ad.long_headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_search_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Headlines",
                "id": "ad_group_ad.ad.responsive_search_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path1",
                "id": "ad_group_ad.ad.responsive_search_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path2",
                "id": "ad_group_ad.ad.responsive_search_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Comparison Listing Ad Headline",
                "id": "ad_group_ad.ad.shopping_comparison_listing_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Product Ad",
                "id": "ad_group_ad.ad.shopping_product_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Smart Ad",
                "id": "ad_group_ad.ad.shopping_smart_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Descriptions",
                "id": "ad_group_ad.ad.smart_campaign_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Headlines",
                "id": "ad_group_ad.ad.smart_campaign_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad System Managed Resource Source",
                "id": "ad_group_ad.ad.system_managed_resource_source",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description1",
                "id": "ad_group_ad.ad.text_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description2",
                "id": "ad_group_ad.ad.text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Headline",
                "id": "ad_group_ad.ad.text_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Tracking Url Template",
                "id": "ad_group_ad.ad.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Type",
                "id": "ad_group_ad.ad.type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Collections",
                "id": "ad_group_ad.ad.url_collections",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Custom Parameters",
                "id": "ad_group_ad.ad.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Bumper Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.bumper.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description1",
                "id": "ad_group_ad.ad.video_ad.in_feed.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description2",
                "id": "ad_group_ad.ad.video_ad.in_feed.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Headline",
                "id": "ad_group_ad.ad.video_ad.in_feed.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Thumbnail",
                "id": "ad_group_ad.ad.video_ad.in_feed.thumbnail",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Button Label",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Headline",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.in_stream.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Button Label",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Headline",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.non_skippable.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Description",
                "id": "ad_group_ad.ad.video_ad.out_stream.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Headline",
                "id": "ad_group_ad.ad.video_ad.out_stream.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Video Asset",
                "id": "ad_group_ad.ad.video_ad.video.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb1",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb2",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Call To Actions",
                "id": "ad_group_ad.ad.video_responsive_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Descriptions",
                "id": "ad_group_ad.ad.video_responsive_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Long Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.long_headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos",
                "id": "ad_group_ad.ad.video_responsive_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_ad.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Strength",
                "id": "ad_group_ad.ad_strength",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Labels",
                "id": "ad_group_ad.labels",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "ad_group_ad.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "ad_group_ad.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "ad_group_ad.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Status",
                "id": "ad_group_ad.status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Book On Google Asset",
                "id": "asset.book_on_google_asset",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Ad Schedule Targets",
                "id": "asset.call_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Action",
                "id": "asset.call_asset.call_conversion_action",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Call Conversion Reporting State",
                "id": "asset.call_asset.call_conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Call Asset Country Code",
                "id": "asset.call_asset.country_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call Asset Phone Number",
                "id": "asset.call_asset.phone_number",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Call To Action Asset Call To Action",
                "id": "asset.call_to_action_asset.call_to_action",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Ad Schedule Targets",
                "id": "asset.callout_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Callout Text",
                "id": "asset.callout_asset.callout_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Callout Asset End Date",
                "id": "asset.callout_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Callout Asset Start Date",
                "id": "asset.callout_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Call To Action Text",
                "id": "asset.discovery_carousel_card_asset.call_to_action_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Headline",
                "id": "asset.discovery_carousel_card_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Portrait Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.portrait_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Discovery Carousel Card Asset Square Marketing Image Asset",
                "id": "asset.discovery_carousel_card_asset.square_marketing_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Android App Link",
                "id": "asset.dynamic_custom_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Contextual Keywords",
                "id": "asset.dynamic_custom_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Price",
                "id": "asset.dynamic_custom_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Formatted Sale Price",
                "id": "asset.dynamic_custom_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id",
                "id": "asset.dynamic_custom_asset.id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Id2",
                "id": "asset.dynamic_custom_asset.id2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Image Url",
                "id": "asset.dynamic_custom_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Ios App Link",
                "id": "asset.dynamic_custom_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Address",
                "id": "asset.dynamic_custom_asset.item_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Category",
                "id": "asset.dynamic_custom_asset.item_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Description",
                "id": "asset.dynamic_custom_asset.item_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Subtitle",
                "id": "asset.dynamic_custom_asset.item_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Item Title",
                "id": "asset.dynamic_custom_asset.item_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Price",
                "id": "asset.dynamic_custom_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Sale Price",
                "id": "asset.dynamic_custom_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Custom Asset Similar Ids",
                "id": "asset.dynamic_custom_asset.similar_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Address",
                "id": "asset.dynamic_education_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Android App Link",
                "id": "asset.dynamic_education_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Contextual Keywords",
                "id": "asset.dynamic_education_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Image Url",
                "id": "asset.dynamic_education_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Link",
                "id": "asset.dynamic_education_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Location Id",
                "id": "asset.dynamic_education_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Description",
                "id": "asset.dynamic_education_asset.program_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Id",
                "id": "asset.dynamic_education_asset.program_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Program Name",
                "id": "asset.dynamic_education_asset.program_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset School Name",
                "id": "asset.dynamic_education_asset.school_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Similar Program Ids",
                "id": "asset.dynamic_education_asset.similar_program_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Subject",
                "id": "asset.dynamic_education_asset.subject",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Thumbnail Image Url",
                "id": "asset.dynamic_education_asset.thumbnail_image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Android App Link",
                "id": "asset.dynamic_flights_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Custom Mapping",
                "id": "asset.dynamic_flights_asset.custom_mapping",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Id",
                "id": "asset.dynamic_flights_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Destination Name",
                "id": "asset.dynamic_flights_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Description",
                "id": "asset.dynamic_flights_asset.flight_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Price",
                "id": "asset.dynamic_flights_asset.flight_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Flight Sale Price",
                "id": "asset.dynamic_flights_asset.flight_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Price",
                "id": "asset.dynamic_flights_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Formatted Sale Price",
                "id": "asset.dynamic_flights_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Image Url",
                "id": "asset.dynamic_flights_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Link",
                "id": "asset.dynamic_flights_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Id",
                "id": "asset.dynamic_flights_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Origin Name",
                "id": "asset.dynamic_flights_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Similar Destination Ids",
                "id": "asset.dynamic_flights_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Address",
                "id": "asset.dynamic_hotels_and_rentals_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Android App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Category",
                "id": "asset.dynamic_hotels_and_rentals_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Contextual Keywords",
                "id": "asset.dynamic_hotels_and_rentals_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Description",
                "id": "asset.dynamic_hotels_and_rentals_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Destination Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Formatted Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Image Url",
                "id": "asset.dynamic_hotels_and_rentals_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Link",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Property Name",
                "id": "asset.dynamic_hotels_and_rentals_asset.property_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Sale Price",
                "id": "asset.dynamic_hotels_and_rentals_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Similar Property Ids",
                "id": "asset.dynamic_hotels_and_rentals_asset.similar_property_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Address",
                "id": "asset.dynamic_jobs_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Android App Link",
                "id": "asset.dynamic_jobs_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Contextual Keywords",
                "id": "asset.dynamic_jobs_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Description",
                "id": "asset.dynamic_jobs_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Image Url",
                "id": "asset.dynamic_jobs_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Link",
                "id": "asset.dynamic_jobs_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Category",
                "id": "asset.dynamic_jobs_asset.job_category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Id",
                "id": "asset.dynamic_jobs_asset.job_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Subtitle",
                "id": "asset.dynamic_jobs_asset.job_subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Job Title",
                "id": "asset.dynamic_jobs_asset.job_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Location Id",
                "id": "asset.dynamic_jobs_asset.location_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Salary",
                "id": "asset.dynamic_jobs_asset.salary",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Similar Job Ids",
                "id": "asset.dynamic_jobs_asset.similar_job_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Address",
                "id": "asset.dynamic_local_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Android App Link",
                "id": "asset.dynamic_local_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Category",
                "id": "asset.dynamic_local_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Contextual Keywords",
                "id": "asset.dynamic_local_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Id",
                "id": "asset.dynamic_local_asset.deal_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Deal Name",
                "id": "asset.dynamic_local_asset.deal_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Description",
                "id": "asset.dynamic_local_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Price",
                "id": "asset.dynamic_local_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Formatted Sale Price",
                "id": "asset.dynamic_local_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Image Url",
                "id": "asset.dynamic_local_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Link",
                "id": "asset.dynamic_local_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Price",
                "id": "asset.dynamic_local_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Sale Price",
                "id": "asset.dynamic_local_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Similar Deal Ids",
                "id": "asset.dynamic_local_asset.similar_deal_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Subtitle",
                "id": "asset.dynamic_local_asset.subtitle",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Address",
                "id": "asset.dynamic_real_estate_asset.address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Android App Link",
                "id": "asset.dynamic_real_estate_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset City Name",
                "id": "asset.dynamic_real_estate_asset.city_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Contextual Keywords",
                "id": "asset.dynamic_real_estate_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Description",
                "id": "asset.dynamic_real_estate_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Formatted Price",
                "id": "asset.dynamic_real_estate_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Image Url",
                "id": "asset.dynamic_real_estate_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Link",
                "id": "asset.dynamic_real_estate_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Id",
                "id": "asset.dynamic_real_estate_asset.listing_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Name",
                "id": "asset.dynamic_real_estate_asset.listing_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Listing Type",
                "id": "asset.dynamic_real_estate_asset.listing_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Price",
                "id": "asset.dynamic_real_estate_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Property Type",
                "id": "asset.dynamic_real_estate_asset.property_type",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Similar Listing Ids",
                "id": "asset.dynamic_real_estate_asset.similar_listing_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Android App Link",
                "id": "asset.dynamic_travel_asset.android_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Category",
                "id": "asset.dynamic_travel_asset.category",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Contextual Keywords",
                "id": "asset.dynamic_travel_asset.contextual_keywords",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Address",
                "id": "asset.dynamic_travel_asset.destination_address",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Id",
                "id": "asset.dynamic_travel_asset.destination_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Destination Name",
                "id": "asset.dynamic_travel_asset.destination_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Price",
                "id": "asset.dynamic_travel_asset.formatted_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Formatted Sale Price",
                "id": "asset.dynamic_travel_asset.formatted_sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Image Url",
                "id": "asset.dynamic_travel_asset.image_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Link",
                "id": "asset.dynamic_travel_asset.ios_app_link",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Id",
                "id": "asset.dynamic_travel_asset.origin_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Origin Name",
                "id": "asset.dynamic_travel_asset.origin_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Price",
                "id": "asset.dynamic_travel_asset.price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Sale Price",
                "id": "asset.dynamic_travel_asset.sale_price",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Similar Destination Ids",
                "id": "asset.dynamic_travel_asset.similar_destination_ids",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Title",
                "id": "asset.dynamic_travel_asset.title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Mobile Urls",
                "id": "asset.final_mobile_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Url Suffix",
                "id": "asset.final_url_suffix",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Final Urls",
                "id": "asset.final_urls",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Language Code",
                "id": "asset.hotel_callout_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Hotel Callout Asset Text",
                "id": "asset.hotel_callout_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Url",
                "id": "asset.image_asset.full_size.url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Image Asset Mime Type",
                "id": "asset.image_asset.mime_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Background Image Asset",
                "id": "asset.lead_form_asset.background_image_asset",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Business Name",
                "id": "asset.lead_form_asset.business_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Description",
                "id": "asset.lead_form_asset.call_to_action_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Call To Action Type",
                "id": "asset.lead_form_asset.call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Disclosure",
                "id": "asset.lead_form_asset.custom_disclosure",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Custom Question Fields",
                "id": "asset.lead_form_asset.custom_question_fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Delivery Methods",
                "id": "asset.lead_form_asset.delivery_methods",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Description",
                "id": "asset.lead_form_asset.description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Desired Intent",
                "id": "asset.lead_form_asset.desired_intent",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Fields",
                "id": "asset.lead_form_asset.fields",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Headline",
                "id": "asset.lead_form_asset.headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Call To Action Type",
                "id": "asset.lead_form_asset.post_submit_call_to_action_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Description",
                "id": "asset.lead_form_asset.post_submit_description",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Post Submit Headline",
                "id": "asset.lead_form_asset.post_submit_headline",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Lead Form Asset Privacy Policy Url",
                "id": "asset.lead_form_asset.privacy_policy_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Location Asset Business Profile Locations",
                "id": "asset.location_asset.business_profile_locations",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Location Asset Location Ownership Type",
                "id": "asset.location_asset.location_ownership_type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Location Asset Place Id",
                "id": "asset.location_asset.place_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Id",
                "id": "asset.mobile_app_asset.app_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset App Store",
                "id": "asset.mobile_app_asset.app_store",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset End Date",
                "id": "asset.mobile_app_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Link Text",
                "id": "asset.mobile_app_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Mobile App Asset Start Date",
                "id": "asset.mobile_app_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Name",
                "id": "asset.name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Labels",
                "id": "asset.page_feed_asset.labels",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Page Feed Asset Page Url",
                "id": "asset.page_feed_asset.page_url",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "asset.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "asset.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "asset.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Language Code",
                "id": "asset.price_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Offerings",
                "id": "asset.price_asset.price_offerings",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Price Asset Price Qualifier",
                "id": "asset.price_asset.price_qualifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Price Asset Type",
                "id": "asset.price_asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Ad Schedule Targets",
                "id": "asset.promotion_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Discount Modifier",
                "id": "asset.promotion_asset.discount_modifier",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset End Date",
                "id": "asset.promotion_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Language Code",
                "id": "asset.promotion_asset.language_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Currency Code",
                "id": "asset.promotion_asset.money_amount_off.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Occasion",
                "id": "asset.promotion_asset.occasion",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Currency Code",
                "id": "asset.promotion_asset.orders_over_amount.currency_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Code",
                "id": "asset.promotion_asset.promotion_code",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Promotion Target",
                "id": "asset.promotion_asset.promotion_target",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption End Date",
                "id": "asset.promotion_asset.redemption_end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Redemption Start Date",
                "id": "asset.promotion_asset.redemption_start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Start Date",
                "id": "asset.promotion_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Resource Name",
                "id": "asset.resource_name",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Ad Schedule Targets",
                "id": "asset.sitelink_asset.ad_schedule_targets",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description1",
                "id": "asset.sitelink_asset.description1",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Description2",
                "id": "asset.sitelink_asset.description2",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset End Date",
                "id": "asset.sitelink_asset.end_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Link Text",
                "id": "asset.sitelink_asset.link_text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Sitelink Asset Start Date",
                "id": "asset.sitelink_asset.start_date",
                "dataType": "DATE",
                "group": "Asset"
            },
            {
                "name": "Source",
                "id": "asset.source",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Header",
                "id": "asset.structured_snippet_asset.header",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Structured Snippet Asset Values",
                "id": "asset.structured_snippet_asset.values",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Text Asset Text",
                "id": "asset.text_asset.text",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Tracking Url Template",
                "id": "asset.tracking_url_template",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Type",
                "id": "asset.type",
                "dataType": "ENUM",
                "group": "Asset"
            },
            {
                "name": "Url Custom Parameters",
                "id": "asset.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Id",
                "id": "asset.youtube_video_asset.youtube_video_id",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Youtube Video Asset Youtube Video Title",
                "id": "asset.youtube_video_asset.youtube_video_title",
                "dataType": "STRING",
                "group": "Asset"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Group",
                "id": "lead_form_submission_data.ad_group",
                "dataType": "STRING",
                "group": "Lead Form Submission Data"
            },
            {
                "name": "Ad Group Ad",
                "id": "lead_form_submission_data.ad_group_ad",
                "dataType": "STRING",
                "group": "Lead Form Submission Data"
            },
            {
                "name": "Asset",
                "id": "lead_form_submission_data.asset",
                "dataType": "STRING",
                "group": "Lead Form Submission Data"
            },
            {
                "name": "Campaign",
                "id": "lead_form_submission_data.campaign",
                "dataType": "STRING",
                "group": "Lead Form Submission Data"
            },
            {
                "name": "Custom Lead Form Submission Fields",
                "id": "lead_form_submission_data.custom_lead_form_submission_fields",
                "dataType": "OBJECT",
                "group": "Lead Form Submission Data"
            },
            {
                "name": "Gclid",
                "id": "lead_form_submission_data.gclid",
                "dataType": "STRING",
                "group": "Lead Form Submission Data"
            },
            {
                "name": "Id",
                "id": "lead_form_submission_data.id",
                "dataType": "STRING",
                "group": "Lead Form Submission Data"
            },
            {
                "name": "Lead Form Submission Fields",
                "id": "lead_form_submission_data.lead_form_submission_fields",
                "dataType": "OBJECT",
                "group": "Lead Form Submission Data"
            },
            {
                "name": "Resource Name",
                "id": "lead_form_submission_data.resource_name",
                "dataType": "STRING",
                "group": "Lead Form Submission Data"
            },
            {
                "name": "Submission Date Time",
                "id": "lead_form_submission_data.submission_date_time",
                "dataType": "DATE",
                "group": "Lead Form Submission Data"
            }
        ],
        "life_event": [
            {
                "name": "Availabilities",
                "id": "life_event.availabilities",
                "dataType": "OBJECT",
                "group": "Life Event"
            },
            {
                "name": "Launched To All",
                "id": "life_event.launched_to_all",
                "dataType": "BOOLEAN",
                "group": "Life Event"
            },
            {
                "name": "Name",
                "id": "life_event.name",
                "dataType": "STRING",
                "group": "Life Event"
            },
            {
                "name": "Parent",
                "id": "life_event.parent",
                "dataType": "STRING",
                "group": "Life Event"
            },
            {
                "name": "Resource Name",
                "id": "life_event.resource_name",
                "dataType": "STRING",
                "group": "Life Event"
            }
        ],
        "location_view": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Ad Schedule Day Of Week",
                "id": "campaign_criterion.ad_schedule.day_of_week",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule End Minute",
                "id": "campaign_criterion.ad_schedule.end_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Minute",
                "id": "campaign_criterion.ad_schedule.start_minute",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "campaign_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Campaign",
                "id": "campaign_criterion.campaign",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Carrier Carrier Constant",
                "id": "campaign_criterion.carrier.carrier_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "campaign_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Content Label Type",
                "id": "campaign_criterion.content_label.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "campaign_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "campaign_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Device Type",
                "id": "campaign_criterion.device.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Display Name",
                "id": "campaign_criterion.display_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Gender Type",
                "id": "campaign_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "campaign_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ip Block Ip Address",
                "id": "campaign_criterion.ip_block.ip_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "campaign_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "campaign_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Free Form Keyword Theme",
                "id": "campaign_criterion.keyword_theme.free_form_keyword_theme",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Keyword Theme Keyword Theme Constant",
                "id": "campaign_criterion.keyword_theme.keyword_theme_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Language Language Constant",
                "id": "campaign_criterion.language.language_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions",
                "id": "campaign_criterion.listing_scope.dimensions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel City City Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel Country Region Country Region Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Listing Scope Dimensions Hotel State State Criterion",
                "id": "campaign_criterion.listing_scope.dimensions.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Geo Target Constant",
                "id": "campaign_criterion.location.geo_target_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group",
                "id": "campaign_criterion.location_group",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Enable Customer Level Location Asset Set",
                "id": "campaign_criterion.location_group.enable_customer_level_location_asset_set",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Feed",
                "id": "campaign_criterion.location_group.feed",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Location Group Geo Target Constants",
                "id": "campaign_criterion.location_group.geo_target_constants",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "campaign_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "campaign_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "campaign_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Mobile Device Mobile Device Constant",
                "id": "campaign_criterion.mobile_device.mobile_device_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Negative",
                "id": "campaign_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Campaign Criterion"
            },
            {
                "name": "Operating System Version Operating System Version Constant",
                "id": "campaign_criterion.operating_system_version.operating_system_version_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "campaign_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Placement Url",
                "id": "campaign_criterion.placement.url",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address City Name",
                "id": "campaign_criterion.proximity.address.city_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Country Code",
                "id": "campaign_criterion.proximity.address.country_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Postal Code",
                "id": "campaign_criterion.proximity.address.postal_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Code",
                "id": "campaign_criterion.proximity.address.province_code",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Province Name",
                "id": "campaign_criterion.proximity.address.province_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Address Street Address",
                "id": "campaign_criterion.proximity.address.street_address",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius Units",
                "id": "campaign_criterion.proximity.radius_units",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Resource Name",
                "id": "campaign_criterion.resource_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Status",
                "id": "campaign_criterion.status",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Path",
                "id": "campaign_criterion.topic.path",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "campaign_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Type",
                "id": "campaign_criterion.type",
                "dataType": "ENUM",
                "group": "Campaign Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "campaign_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "User List User List",
                "id": "campaign_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "campaign_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "campaign_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "campaign_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "campaign_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "campaign_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Campaign Criterion"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "location_view.resource_name",
                "dataType": "STRING",
                "group": "Location View"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "managed_placement_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "managed_placement_view.resource_name",
                "dataType": "STRING",
                "group": "Managed Placement View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "media_file": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Image Full Size Image Url",
                "id": "media_file.image.full_size_image_url",
                "dataType": "STRING",
                "group": "Media File"
            },
            {
                "name": "Image Preview Size Image Url",
                "id": "media_file.image.preview_size_image_url",
                "dataType": "STRING",
                "group": "Media File"
            },
            {
                "name": "Media Bundle Url",
                "id": "media_file.media_bundle.url",
                "dataType": "STRING",
                "group": "Media File"
            },
            {
                "name": "Mime Type",
                "id": "media_file.mime_type",
                "dataType": "ENUM",
                "group": "Media File"
            },
            {
                "name": "Name",
                "id": "media_file.name",
                "dataType": "STRING",
                "group": "Media File"
            },
            {
                "name": "Resource Name",
                "id": "media_file.resource_name",
                "dataType": "STRING",
                "group": "Media File"
            },
            {
                "name": "Source Url",
                "id": "media_file.source_url",
                "dataType": "STRING",
                "group": "Media File"
            },
            {
                "name": "Type",
                "id": "media_file.type",
                "dataType": "ENUM",
                "group": "Media File"
            },
            {
                "name": "Video Advertising Id Code",
                "id": "media_file.video.advertising_id_code",
                "dataType": "STRING",
                "group": "Media File"
            },
            {
                "name": "Video Isci Code",
                "id": "media_file.video.isci_code",
                "dataType": "STRING",
                "group": "Media File"
            },
            {
                "name": "Video Youtube Video Id",
                "id": "media_file.video.youtube_video_id",
                "dataType": "STRING",
                "group": "Media File"
            }
        ],
        "mobile_app_category_constant": [
            {
                "name": "Name",
                "id": "mobile_app_category_constant.name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Resource Name",
                "id": "mobile_app_category_constant.resource_name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            }
        ],
        "mobile_device_constant": [
            {
                "name": "Manufacturer Name",
                "id": "mobile_device_constant.manufacturer_name",
                "dataType": "STRING",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Name",
                "id": "mobile_device_constant.name",
                "dataType": "STRING",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Operating System Name",
                "id": "mobile_device_constant.operating_system_name",
                "dataType": "STRING",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Resource Name",
                "id": "mobile_device_constant.resource_name",
                "dataType": "STRING",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Type",
                "id": "mobile_device_constant.type",
                "dataType": "ENUM",
                "group": "Mobile Device Constant"
            }
        ],
        "offline_user_data_job": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Customer Match User List Metadata User List",
                "id": "offline_user_data_job.customer_match_user_list_metadata.user_list",
                "dataType": "STRING",
                "group": "Offline User Data Job"
            },
            {
                "name": "Failure Reason",
                "id": "offline_user_data_job.failure_reason",
                "dataType": "ENUM",
                "group": "Offline User Data Job"
            },
            {
                "name": "Operation Metadata Match Rate Range",
                "id": "offline_user_data_job.operation_metadata.match_rate_range",
                "dataType": "ENUM",
                "group": "Offline User Data Job"
            },
            {
                "name": "Resource Name",
                "id": "offline_user_data_job.resource_name",
                "dataType": "STRING",
                "group": "Offline User Data Job"
            },
            {
                "name": "Status",
                "id": "offline_user_data_job.status",
                "dataType": "ENUM",
                "group": "Offline User Data Job"
            },
            {
                "name": "Store Sales Metadata Third Party Metadata Advertiser Upload Date Time",
                "id": "offline_user_data_job.store_sales_metadata.third_party_metadata.advertiser_upload_date_time",
                "dataType": "DATE",
                "group": "Offline User Data Job"
            },
            {
                "name": "Store Sales Metadata Third Party Metadata Bridge Map Version Id",
                "id": "offline_user_data_job.store_sales_metadata.third_party_metadata.bridge_map_version_id",
                "dataType": "STRING",
                "group": "Offline User Data Job"
            },
            {
                "name": "Type",
                "id": "offline_user_data_job.type",
                "dataType": "ENUM",
                "group": "Offline User Data Job"
            },
            {
                "name": "Access Reason",
                "id": "user_list.access_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Account User List Status",
                "id": "user_list.account_user_list_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Basic User List Actions",
                "id": "user_list.basic_user_list.actions",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Closing Reason",
                "id": "user_list.closing_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List App Id",
                "id": "user_list.crm_based_user_list.app_id",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Data Source Type",
                "id": "user_list.crm_based_user_list.data_source_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Upload Key Type",
                "id": "user_list.crm_based_user_list.upload_key_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Description",
                "id": "user_list.description",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Eligible For Display",
                "id": "user_list.eligible_for_display",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Eligible For Search",
                "id": "user_list.eligible_for_search",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Integration Code",
                "id": "user_list.integration_code",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Logical User List Rules",
                "id": "user_list.logical_user_list.rules",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Membership Status",
                "id": "user_list.membership_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Name",
                "id": "user_list.name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Read Only",
                "id": "user_list.read_only",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Resource Name",
                "id": "user_list.resource_name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Rule Operator",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Item Groups",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Type",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Exclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.exclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Rule Operator",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Prepopulation Status",
                "id": "user_list.rule_based_user_list.prepopulation_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Similar User List Seed User List",
                "id": "user_list.similar_user_list.seed_user_list",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Size Range For Display",
                "id": "user_list.size_range_for_display",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Size Range For Search",
                "id": "user_list.size_range_for_search",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Type",
                "id": "user_list.type",
                "dataType": "ENUM",
                "group": "User List"
            }
        ],
        "operating_system_version_constant": [
            {
                "name": "Name",
                "id": "operating_system_version_constant.name",
                "dataType": "STRING",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Operator Type",
                "id": "operating_system_version_constant.operator_type",
                "dataType": "ENUM",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Resource Name",
                "id": "operating_system_version_constant.resource_name",
                "dataType": "STRING",
                "group": "Operating System Version Constant"
            }
        ],
        "paid_organic_search_term_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "paid_organic_search_term_view.resource_name",
                "dataType": "STRING",
                "group": "Paid Organic Search Term View"
            },
            {
                "name": "Search Term",
                "id": "paid_organic_search_term_view.search_term",
                "dataType": "STRING",
                "group": "Paid Organic Search Term View"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Keyword Ad Group Criterion",
                "id": "segments.keyword.ad_group_criterion",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Keyword Info Match Type",
                "id": "segments.keyword.info.match_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Keyword Info Text",
                "id": "segments.keyword.info.text",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Search Engine Results Page Type",
                "id": "segments.search_engine_results_page_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "parental_status_view": [
            {
                "name": "Name",
                "id": "accessible_bidding_strategy.name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Descriptive Name",
                "id": "accessible_bidding_strategy.owner_descriptive_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "accessible_bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "accessible_bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "accessible_bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "parental_status_view.resource_name",
                "dataType": "STRING",
                "group": "Parental Status View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "per_store_view": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Place Id",
                "id": "per_store_view.place_id",
                "dataType": "STRING",
                "group": "Per Store View"
            },
            {
                "name": "Resource Name",
                "id": "per_store_view.resource_name",
                "dataType": "STRING",
                "group": "Per Store View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "product_bidding_category_constant": [
            {
                "name": "Country Code",
                "id": "product_bidding_category_constant.country_code",
                "dataType": "STRING",
                "group": "Product Bidding Category Constant"
            },
            {
                "name": "Language Code",
                "id": "product_bidding_category_constant.language_code",
                "dataType": "STRING",
                "group": "Product Bidding Category Constant"
            },
            {
                "name": "Level",
                "id": "product_bidding_category_constant.level",
                "dataType": "ENUM",
                "group": "Product Bidding Category Constant"
            },
            {
                "name": "Localized Name",
                "id": "product_bidding_category_constant.localized_name",
                "dataType": "STRING",
                "group": "Product Bidding Category Constant"
            },
            {
                "name": "Product Bidding Category Constant Parent",
                "id": "product_bidding_category_constant.product_bidding_category_constant_parent",
                "dataType": "STRING",
                "group": "Product Bidding Category Constant"
            },
            {
                "name": "Resource Name",
                "id": "product_bidding_category_constant.resource_name",
                "dataType": "STRING",
                "group": "Product Bidding Category Constant"
            },
            {
                "name": "Status",
                "id": "product_bidding_category_constant.status",
                "dataType": "ENUM",
                "group": "Product Bidding Category Constant"
            }
        ],
        "product_group_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "product_group_view.resource_name",
                "dataType": "STRING",
                "group": "Product Group View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "recommendation": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Delivery Method",
                "id": "campaign_budget.delivery_method",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Explicitly Shared",
                "id": "campaign_budget.explicitly_shared",
                "dataType": "BOOLEAN",
                "group": "Campaign Budget"
            },
            {
                "name": "Has Recommended Budget",
                "id": "campaign_budget.has_recommended_budget",
                "dataType": "BOOLEAN",
                "group": "Campaign Budget"
            },
            {
                "name": "Name",
                "id": "campaign_budget.name",
                "dataType": "STRING",
                "group": "Campaign Budget"
            },
            {
                "name": "Period",
                "id": "campaign_budget.period",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Resource Name",
                "id": "campaign_budget.resource_name",
                "dataType": "STRING",
                "group": "Campaign Budget"
            },
            {
                "name": "Status",
                "id": "campaign_budget.status",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Type",
                "id": "campaign_budget.type",
                "dataType": "ENUM",
                "group": "Campaign Budget"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Group",
                "id": "recommendation.ad_group",
                "dataType": "STRING",
                "group": "Recommendation"
            },
            {
                "name": "Call Extension Recommendation",
                "id": "recommendation.call_extension_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Callout Extension Recommendation",
                "id": "recommendation.callout_extension_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Campaign",
                "id": "recommendation.campaign",
                "dataType": "STRING",
                "group": "Recommendation"
            },
            {
                "name": "Campaign Budget",
                "id": "recommendation.campaign_budget",
                "dataType": "STRING",
                "group": "Recommendation"
            },
            {
                "name": "Campaign Budget Recommendation",
                "id": "recommendation.campaign_budget_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Dismissed",
                "id": "recommendation.dismissed",
                "dataType": "BOOLEAN",
                "group": "Recommendation"
            },
            {
                "name": "Display Expansion Opt In Recommendation",
                "id": "recommendation.display_expansion_opt_in_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Enhanced Cpc Opt In Recommendation",
                "id": "recommendation.enhanced_cpc_opt_in_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Forecasting Campaign Budget Recommendation",
                "id": "recommendation.forecasting_campaign_budget_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Forecasting Set Target Roas Recommendation",
                "id": "recommendation.forecasting_set_target_roas_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Impact",
                "id": "recommendation.impact",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Keyword Match Type Recommendation",
                "id": "recommendation.keyword_match_type_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Keyword Recommendation",
                "id": "recommendation.keyword_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Marginal Roi Campaign Budget Recommendation",
                "id": "recommendation.marginal_roi_campaign_budget_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Maximize Clicks Opt In Recommendation",
                "id": "recommendation.maximize_clicks_opt_in_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Maximize Conversions Opt In Recommendation",
                "id": "recommendation.maximize_conversions_opt_in_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Move Unused Budget Recommendation",
                "id": "recommendation.move_unused_budget_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Optimize Ad Rotation Recommendation",
                "id": "recommendation.optimize_ad_rotation_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Raise Target Cpa Bid Too Low Recommendation",
                "id": "recommendation.raise_target_cpa_bid_too_low_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Resource Name",
                "id": "recommendation.resource_name",
                "dataType": "STRING",
                "group": "Recommendation"
            },
            {
                "name": "Responsive Search Ad Asset Recommendation",
                "id": "recommendation.responsive_search_ad_asset_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Responsive Search Ad Improve Ad Strength Recommendation",
                "id": "recommendation.responsive_search_ad_improve_ad_strength_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Responsive Search Ad Recommendation",
                "id": "recommendation.responsive_search_ad_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Search Partners Opt In Recommendation",
                "id": "recommendation.search_partners_opt_in_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Sitelink Extension Recommendation",
                "id": "recommendation.sitelink_extension_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Target Cpa Opt In Recommendation",
                "id": "recommendation.target_cpa_opt_in_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Target Roas Opt In Recommendation",
                "id": "recommendation.target_roas_opt_in_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Text Ad Recommendation",
                "id": "recommendation.text_ad_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Type",
                "id": "recommendation.type",
                "dataType": "ENUM",
                "group": "Recommendation"
            },
            {
                "name": "Upgrade Local Campaign To Performance Max Recommendation",
                "id": "recommendation.upgrade_local_campaign_to_performance_max_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Upgrade Smart Shopping Campaign To Performance Max Recommendation",
                "id": "recommendation.upgrade_smart_shopping_campaign_to_performance_max_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            },
            {
                "name": "Use Broad Match Keyword Recommendation",
                "id": "recommendation.use_broad_match_keyword_recommendation",
                "dataType": "OBJECT",
                "group": "Recommendation"
            }
        ],
        "remarketing_action": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "remarketing_action.name",
                "dataType": "STRING",
                "group": "Remarketing Action"
            },
            {
                "name": "Resource Name",
                "id": "remarketing_action.resource_name",
                "dataType": "STRING",
                "group": "Remarketing Action"
            },
            {
                "name": "Tag Snippets",
                "id": "remarketing_action.tag_snippets",
                "dataType": "OBJECT",
                "group": "Remarketing Action"
            }
        ],
        "search_term_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Action Items",
                "id": "ad_group_ad.action_items",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Added By Google Ads",
                "id": "ad_group_ad.ad.added_by_google_ads",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Descriptions",
                "id": "ad_group_ad.ad.app_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Headlines",
                "id": "ad_group_ad.ad.app_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles Asset",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images",
                "id": "ad_group_ad.ad.app_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images Asset",
                "id": "ad_group_ad.ad.app_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Mandatory Ad Text",
                "id": "ad_group_ad.ad.app_ad.mandatory_ad_text",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Descriptions",
                "id": "ad_group_ad.ad.app_engagement_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Headlines",
                "id": "ad_group_ad.ad.app_engagement_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images",
                "id": "ad_group_ad.ad.app_engagement_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos",
                "id": "ad_group_ad.ad.app_engagement_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Descriptions",
                "id": "ad_group_ad.ad.app_pre_registration_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Headlines",
                "id": "ad_group_ad.ad.app_pre_registration_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Business Name",
                "id": "ad_group_ad.ad.call_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Call Tracked",
                "id": "ad_group_ad.ad.call_ad.call_tracked",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Action",
                "id": "ad_group_ad.ad.call_ad.conversion_action",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Reporting State",
                "id": "ad_group_ad.ad.call_ad.conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Country Code",
                "id": "ad_group_ad.ad.call_ad.country_code",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description1",
                "id": "ad_group_ad.ad.call_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description2",
                "id": "ad_group_ad.ad.call_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Disable Call Conversion",
                "id": "ad_group_ad.ad.call_ad.disable_call_conversion",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline1",
                "id": "ad_group_ad.ad.call_ad.headline1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline2",
                "id": "ad_group_ad.ad.call_ad.headline2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path1",
                "id": "ad_group_ad.ad.call_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path2",
                "id": "ad_group_ad.ad.call_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number",
                "id": "ad_group_ad.ad.call_ad.phone_number",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number Verification Url",
                "id": "ad_group_ad.ad.call_ad.phone_number_verification_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Device Preference",
                "id": "ad_group_ad.ad.device_preference",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Business Name",
                "id": "ad_group_ad.ad.discovery_carousel_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_carousel_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Description",
                "id": "ad_group_ad.ad.discovery_carousel_ad.description",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Headline",
                "id": "ad_group_ad.ad.discovery_carousel_ad.headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Business Name",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Descriptions",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Headlines",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Lead Form Only",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.lead_form_only",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Display Upload Product Type",
                "id": "ad_group_ad.ad.display_upload_ad.display_upload_product_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle Asset",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Url",
                "id": "ad_group_ad.ad.display_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description2",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description",
                "id": "ad_group_ad.ad.expanded_text_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description2",
                "id": "ad_group_ad.ad.expanded_text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part1",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part2",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part3",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part3",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path1",
                "id": "ad_group_ad.ad.expanded_text_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path2",
                "id": "ad_group_ad.ad.expanded_text_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final App Urls",
                "id": "ad_group_ad.ad.final_app_urls",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Mobile Urls",
                "id": "ad_group_ad.ad.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Url Suffix",
                "id": "ad_group_ad.ad.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Urls",
                "id": "ad_group_ad.ad.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Hotel Ad",
                "id": "ad_group_ad.ad.hotel_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Image Url",
                "id": "ad_group_ad.ad.image_ad.image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Media File",
                "id": "ad_group_ad.ad.image_ad.media_file",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Mime Type",
                "id": "ad_group_ad.ad.image_ad.mime_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Name",
                "id": "ad_group_ad.ad.image_ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Image Url",
                "id": "ad_group_ad.ad.image_ad.preview_image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy App Install Ad",
                "id": "ad_group_ad.ad.legacy_app_install_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Description",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.long_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Short Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.short_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Call To Actions",
                "id": "ad_group_ad.ad.local_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Descriptions",
                "id": "ad_group_ad.ad.local_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Headlines",
                "id": "ad_group_ad.ad.local_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images",
                "id": "ad_group_ad.ad.local_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images Asset",
                "id": "ad_group_ad.ad.local_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images",
                "id": "ad_group_ad.ad.local_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.local_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path1",
                "id": "ad_group_ad.ad.local_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path2",
                "id": "ad_group_ad.ad.local_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos",
                "id": "ad_group_ad.ad.local_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos Asset",
                "id": "ad_group_ad.ad.local_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Name",
                "id": "ad_group_ad.ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Resource Name",
                "id": "ad_group_ad.ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Asset Enhancements",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_asset_enhancements",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Autogen Video",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_autogen_video",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_display_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Headlines",
                "id": "ad_group_ad.ad.responsive_display_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.responsive_display_ad.long_headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_search_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Headlines",
                "id": "ad_group_ad.ad.responsive_search_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path1",
                "id": "ad_group_ad.ad.responsive_search_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path2",
                "id": "ad_group_ad.ad.responsive_search_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Comparison Listing Ad Headline",
                "id": "ad_group_ad.ad.shopping_comparison_listing_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Product Ad",
                "id": "ad_group_ad.ad.shopping_product_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Smart Ad",
                "id": "ad_group_ad.ad.shopping_smart_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Descriptions",
                "id": "ad_group_ad.ad.smart_campaign_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Headlines",
                "id": "ad_group_ad.ad.smart_campaign_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad System Managed Resource Source",
                "id": "ad_group_ad.ad.system_managed_resource_source",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description1",
                "id": "ad_group_ad.ad.text_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description2",
                "id": "ad_group_ad.ad.text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Headline",
                "id": "ad_group_ad.ad.text_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Tracking Url Template",
                "id": "ad_group_ad.ad.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Type",
                "id": "ad_group_ad.ad.type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Collections",
                "id": "ad_group_ad.ad.url_collections",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Custom Parameters",
                "id": "ad_group_ad.ad.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Bumper Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.bumper.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description1",
                "id": "ad_group_ad.ad.video_ad.in_feed.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description2",
                "id": "ad_group_ad.ad.video_ad.in_feed.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Headline",
                "id": "ad_group_ad.ad.video_ad.in_feed.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Thumbnail",
                "id": "ad_group_ad.ad.video_ad.in_feed.thumbnail",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Button Label",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Headline",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.in_stream.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Button Label",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Headline",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.non_skippable.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Description",
                "id": "ad_group_ad.ad.video_ad.out_stream.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Headline",
                "id": "ad_group_ad.ad.video_ad.out_stream.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Video Asset",
                "id": "ad_group_ad.ad.video_ad.video.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb1",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb2",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Call To Actions",
                "id": "ad_group_ad.ad.video_responsive_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Descriptions",
                "id": "ad_group_ad.ad.video_responsive_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Long Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.long_headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos",
                "id": "ad_group_ad.ad.video_responsive_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_ad.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Strength",
                "id": "ad_group_ad.ad_strength",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Labels",
                "id": "ad_group_ad.labels",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "ad_group_ad.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "ad_group_ad.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "ad_group_ad.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Status",
                "id": "ad_group_ad.status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Group",
                "id": "search_term_view.ad_group",
                "dataType": "STRING",
                "group": "Search Term View"
            },
            {
                "name": "Resource Name",
                "id": "search_term_view.resource_name",
                "dataType": "STRING",
                "group": "Search Term View"
            },
            {
                "name": "Search Term",
                "id": "search_term_view.search_term",
                "dataType": "STRING",
                "group": "Search Term View"
            },
            {
                "name": "Status",
                "id": "search_term_view.status",
                "dataType": "ENUM",
                "group": "Search Term View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Keyword Ad Group Criterion",
                "id": "segments.keyword.ad_group_criterion",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Keyword Info Match Type",
                "id": "segments.keyword.info.match_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Keyword Info Text",
                "id": "segments.keyword.info.text",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Search Term Match Type",
                "id": "segments.search_term_match_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "shared_criterion": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "mobile_app_category_constant.name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Resource Name",
                "id": "mobile_app_category_constant.resource_name",
                "dataType": "STRING",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Keyword Match Type",
                "id": "shared_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Shared Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "shared_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Shared Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "shared_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Shared Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "shared_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Shared Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "shared_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Shared Criterion"
            },
            {
                "name": "Placement Url",
                "id": "shared_criterion.placement.url",
                "dataType": "STRING",
                "group": "Shared Criterion"
            },
            {
                "name": "Resource Name",
                "id": "shared_criterion.resource_name",
                "dataType": "STRING",
                "group": "Shared Criterion"
            },
            {
                "name": "Shared Set",
                "id": "shared_criterion.shared_set",
                "dataType": "STRING",
                "group": "Shared Criterion"
            },
            {
                "name": "Type",
                "id": "shared_criterion.type",
                "dataType": "ENUM",
                "group": "Shared Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "shared_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Shared Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "shared_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Shared Criterion"
            },
            {
                "name": "Name",
                "id": "shared_set.name",
                "dataType": "STRING",
                "group": "Shared Set"
            },
            {
                "name": "Resource Name",
                "id": "shared_set.resource_name",
                "dataType": "STRING",
                "group": "Shared Set"
            },
            {
                "name": "Status",
                "id": "shared_set.status",
                "dataType": "ENUM",
                "group": "Shared Set"
            },
            {
                "name": "Type",
                "id": "shared_set.type",
                "dataType": "ENUM",
                "group": "Shared Set"
            }
        ],
        "shared_set": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign",
                "id": "campaign_shared_set.campaign",
                "dataType": "STRING",
                "group": "Campaign Shared Set"
            },
            {
                "name": "Resource Name",
                "id": "campaign_shared_set.resource_name",
                "dataType": "STRING",
                "group": "Campaign Shared Set"
            },
            {
                "name": "Shared Set",
                "id": "campaign_shared_set.shared_set",
                "dataType": "STRING",
                "group": "Campaign Shared Set"
            },
            {
                "name": "Status",
                "id": "campaign_shared_set.status",
                "dataType": "ENUM",
                "group": "Campaign Shared Set"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Name",
                "id": "shared_set.name",
                "dataType": "STRING",
                "group": "Shared Set"
            },
            {
                "name": "Resource Name",
                "id": "shared_set.resource_name",
                "dataType": "STRING",
                "group": "Shared Set"
            },
            {
                "name": "Status",
                "id": "shared_set.status",
                "dataType": "ENUM",
                "group": "Shared Set"
            },
            {
                "name": "Type",
                "id": "shared_set.type",
                "dataType": "ENUM",
                "group": "Shared Set"
            }
        ],
        "shopping_performance_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "shopping_performance_view.resource_name",
                "dataType": "STRING",
                "group": "Shopping Performance View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Product Bidding Category Level1",
                "id": "segments.product_bidding_category_level1",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Bidding Category Level2",
                "id": "segments.product_bidding_category_level2",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Bidding Category Level3",
                "id": "segments.product_bidding_category_level3",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Bidding Category Level4",
                "id": "segments.product_bidding_category_level4",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Bidding Category Level5",
                "id": "segments.product_bidding_category_level5",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Brand",
                "id": "segments.product_brand",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Channel",
                "id": "segments.product_channel",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Product Channel Exclusivity",
                "id": "segments.product_channel_exclusivity",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Product Condition",
                "id": "segments.product_condition",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Product Country",
                "id": "segments.product_country",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Custom Attribute0",
                "id": "segments.product_custom_attribute0",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Custom Attribute1",
                "id": "segments.product_custom_attribute1",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Custom Attribute2",
                "id": "segments.product_custom_attribute2",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Custom Attribute3",
                "id": "segments.product_custom_attribute3",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Custom Attribute4",
                "id": "segments.product_custom_attribute4",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Item Id",
                "id": "segments.product_item_id",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Language",
                "id": "segments.product_language",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Store Id",
                "id": "segments.product_store_id",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Title",
                "id": "segments.product_title",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Type L1",
                "id": "segments.product_type_l1",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Type L2",
                "id": "segments.product_type_l2",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Type L3",
                "id": "segments.product_type_l3",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Type L4",
                "id": "segments.product_type_l4",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Product Type L5",
                "id": "segments.product_type_l5",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "smart_campaign_search_term_view": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Campaign",
                "id": "smart_campaign_search_term_view.campaign",
                "dataType": "STRING",
                "group": "Smart Campaign Search Term View"
            },
            {
                "name": "Resource Name",
                "id": "smart_campaign_search_term_view.resource_name",
                "dataType": "STRING",
                "group": "Smart Campaign Search Term View"
            },
            {
                "name": "Search Term",
                "id": "smart_campaign_search_term_view.search_term",
                "dataType": "STRING",
                "group": "Smart Campaign Search Term View"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "smart_campaign_setting": [
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Ad Optimized Business Profile Setting Include Lead Form",
                "id": "smart_campaign_setting.ad_optimized_business_profile_setting.include_lead_form",
                "dataType": "BOOLEAN",
                "group": "Smart Campaign Setting"
            },
            {
                "name": "Advertising Language Code",
                "id": "smart_campaign_setting.advertising_language_code",
                "dataType": "STRING",
                "group": "Smart Campaign Setting"
            },
            {
                "name": "Business Name",
                "id": "smart_campaign_setting.business_name",
                "dataType": "STRING",
                "group": "Smart Campaign Setting"
            },
            {
                "name": "Business Profile Location",
                "id": "smart_campaign_setting.business_profile_location",
                "dataType": "STRING",
                "group": "Smart Campaign Setting"
            },
            {
                "name": "Campaign",
                "id": "smart_campaign_setting.campaign",
                "dataType": "STRING",
                "group": "Smart Campaign Setting"
            },
            {
                "name": "Final Url",
                "id": "smart_campaign_setting.final_url",
                "dataType": "STRING",
                "group": "Smart Campaign Setting"
            },
            {
                "name": "Phone Number Country Code",
                "id": "smart_campaign_setting.phone_number.country_code",
                "dataType": "STRING",
                "group": "Smart Campaign Setting"
            },
            {
                "name": "Phone Number Phone Number",
                "id": "smart_campaign_setting.phone_number.phone_number",
                "dataType": "STRING",
                "group": "Smart Campaign Setting"
            },
            {
                "name": "Resource Name",
                "id": "smart_campaign_setting.resource_name",
                "dataType": "STRING",
                "group": "Smart Campaign Setting"
            }
        ],
        "third_party_app_analytics_link": [
            {
                "name": "Advertising Partner Customer",
                "id": "account_link.advertising_partner.customer",
                "dataType": "STRING",
                "group": "Account Link"
            },
            {
                "name": "Google Ads Customer",
                "id": "account_link.google_ads.customer",
                "dataType": "STRING",
                "group": "Account Link"
            },
            {
                "name": "Resource Name",
                "id": "account_link.resource_name",
                "dataType": "STRING",
                "group": "Account Link"
            },
            {
                "name": "Status",
                "id": "account_link.status",
                "dataType": "ENUM",
                "group": "Account Link"
            },
            {
                "name": "Third Party App Analytics App Id",
                "id": "account_link.third_party_app_analytics.app_id",
                "dataType": "STRING",
                "group": "Account Link"
            },
            {
                "name": "Third Party App Analytics App Vendor",
                "id": "account_link.third_party_app_analytics.app_vendor",
                "dataType": "ENUM",
                "group": "Account Link"
            },
            {
                "name": "Type",
                "id": "account_link.type",
                "dataType": "ENUM",
                "group": "Account Link"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "third_party_app_analytics_link.resource_name",
                "dataType": "STRING",
                "group": "Third Party App Analytics Link"
            },
            {
                "name": "Shareable Link Id",
                "id": "third_party_app_analytics_link.shareable_link_id",
                "dataType": "STRING",
                "group": "Third Party App Analytics Link"
            }
        ],
        "topic_constant": [
            {
                "name": "Path",
                "id": "topic_constant.path",
                "dataType": "STRING",
                "group": "Topic Constant"
            },
            {
                "name": "Resource Name",
                "id": "topic_constant.resource_name",
                "dataType": "STRING",
                "group": "Topic Constant"
            },
            {
                "name": "Topic Constant Parent",
                "id": "topic_constant.topic_constant_parent",
                "dataType": "STRING",
                "group": "Topic Constant"
            }
        ],
        "topic_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Currency Code",
                "id": "bidding_strategy.currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Effective Currency Code",
                "id": "bidding_strategy.effective_currency_code",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Enhanced Cpc",
                "id": "bidding_strategy.enhanced_cpc",
                "dataType": "OBJECT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Name",
                "id": "bidding_strategy.name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Resource Name",
                "id": "bidding_strategy.resource_name",
                "dataType": "STRING",
                "group": "Bidding Strategy"
            },
            {
                "name": "Status",
                "id": "bidding_strategy.status",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location",
                "id": "bidding_strategy.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Type",
                "id": "bidding_strategy.type",
                "dataType": "ENUM",
                "group": "Bidding Strategy"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "topic_view.resource_name",
                "dataType": "STRING",
                "group": "Topic View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "user_interest": [
            {
                "name": "Availabilities",
                "id": "user_interest.availabilities",
                "dataType": "OBJECT",
                "group": "User Interest"
            },
            {
                "name": "Launched To All",
                "id": "user_interest.launched_to_all",
                "dataType": "BOOLEAN",
                "group": "User Interest"
            },
            {
                "name": "Name",
                "id": "user_interest.name",
                "dataType": "STRING",
                "group": "User Interest"
            },
            {
                "name": "Resource Name",
                "id": "user_interest.resource_name",
                "dataType": "STRING",
                "group": "User Interest"
            },
            {
                "name": "Taxonomy Type",
                "id": "user_interest.taxonomy_type",
                "dataType": "ENUM",
                "group": "User Interest"
            },
            {
                "name": "User Interest Parent",
                "id": "user_interest.user_interest_parent",
                "dataType": "STRING",
                "group": "User Interest"
            }
        ],
        "user_list": [
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Access Reason",
                "id": "user_list.access_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Account User List Status",
                "id": "user_list.account_user_list_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Basic User List Actions",
                "id": "user_list.basic_user_list.actions",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Closing Reason",
                "id": "user_list.closing_reason",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List App Id",
                "id": "user_list.crm_based_user_list.app_id",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Data Source Type",
                "id": "user_list.crm_based_user_list.data_source_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Crm Based User List Upload Key Type",
                "id": "user_list.crm_based_user_list.upload_key_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Description",
                "id": "user_list.description",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Eligible For Display",
                "id": "user_list.eligible_for_display",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Eligible For Search",
                "id": "user_list.eligible_for_search",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Integration Code",
                "id": "user_list.integration_code",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Logical User List Rules",
                "id": "user_list.logical_user_list.rules",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Membership Status",
                "id": "user_list.membership_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Name",
                "id": "user_list.name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Read Only",
                "id": "user_list.read_only",
                "dataType": "BOOLEAN",
                "group": "User List"
            },
            {
                "name": "Resource Name",
                "id": "user_list.resource_name",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Left Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.left_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Item Groups",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Right Operand Rule Type",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.right_operand.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Combined Rule User List Rule Operator",
                "id": "user_list.rule_based_user_list.combined_rule_user_list.rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Item Groups",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_item_groups",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Expression Rule User List Rule Rule Type",
                "id": "user_list.rule_based_user_list.expression_rule_user_list.rule.rule_type",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Exclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.exclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Operands",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_operands",
                "dataType": "OBJECT",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Flexible Rule User List Inclusive Rule Operator",
                "id": "user_list.rule_based_user_list.flexible_rule_user_list.inclusive_rule_operator",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Rule Based User List Prepopulation Status",
                "id": "user_list.rule_based_user_list.prepopulation_status",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Similar User List Seed User List",
                "id": "user_list.similar_user_list.seed_user_list",
                "dataType": "STRING",
                "group": "User List"
            },
            {
                "name": "Size Range For Display",
                "id": "user_list.size_range_for_display",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Size Range For Search",
                "id": "user_list.size_range_for_search",
                "dataType": "ENUM",
                "group": "User List"
            },
            {
                "name": "Type",
                "id": "user_list.type",
                "dataType": "ENUM",
                "group": "User List"
            }
        ],
        "user_location_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "user_location_view.resource_name",
                "dataType": "STRING",
                "group": "User Location View"
            },
            {
                "name": "Targeting Location",
                "id": "user_location_view.targeting_location",
                "dataType": "BOOLEAN",
                "group": "User Location View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Geo Target Airport",
                "id": "segments.geo_target_airport",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Canton",
                "id": "segments.geo_target_canton",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target City",
                "id": "segments.geo_target_city",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target County",
                "id": "segments.geo_target_county",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target District",
                "id": "segments.geo_target_district",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Metro",
                "id": "segments.geo_target_metro",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Most Specific Location",
                "id": "segments.geo_target_most_specific_location",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Postal Code",
                "id": "segments.geo_target_postal_code",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Province",
                "id": "segments.geo_target_province",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target Region",
                "id": "segments.geo_target_region",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Geo Target State",
                "id": "segments.geo_target_state",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ],
        "video": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Action Items",
                "id": "ad_group_ad.action_items",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Added By Google Ads",
                "id": "ad_group_ad.ad.added_by_google_ads",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Descriptions",
                "id": "ad_group_ad.ad.app_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Headlines",
                "id": "ad_group_ad.ad.app_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Html5 Media Bundles Asset",
                "id": "ad_group_ad.ad.app_ad.html5_media_bundles.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images",
                "id": "ad_group_ad.ad.app_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Images Asset",
                "id": "ad_group_ad.ad.app_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Mandatory Ad Text",
                "id": "ad_group_ad.ad.app_ad.mandatory_ad_text",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Descriptions",
                "id": "ad_group_ad.ad.app_engagement_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Headlines",
                "id": "ad_group_ad.ad.app_engagement_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images",
                "id": "ad_group_ad.ad.app_engagement_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Images Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos",
                "id": "ad_group_ad.ad.app_engagement_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Engagement Ad Videos Asset",
                "id": "ad_group_ad.ad.app_engagement_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Descriptions",
                "id": "ad_group_ad.ad.app_pre_registration_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Headlines",
                "id": "ad_group_ad.ad.app_pre_registration_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Images Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad App Pre Registration Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.app_pre_registration_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Business Name",
                "id": "ad_group_ad.ad.call_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Call Tracked",
                "id": "ad_group_ad.ad.call_ad.call_tracked",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Action",
                "id": "ad_group_ad.ad.call_ad.conversion_action",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Conversion Reporting State",
                "id": "ad_group_ad.ad.call_ad.conversion_reporting_state",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Country Code",
                "id": "ad_group_ad.ad.call_ad.country_code",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description1",
                "id": "ad_group_ad.ad.call_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Description2",
                "id": "ad_group_ad.ad.call_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Disable Call Conversion",
                "id": "ad_group_ad.ad.call_ad.disable_call_conversion",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline1",
                "id": "ad_group_ad.ad.call_ad.headline1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Headline2",
                "id": "ad_group_ad.ad.call_ad.headline2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path1",
                "id": "ad_group_ad.ad.call_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Path2",
                "id": "ad_group_ad.ad.call_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number",
                "id": "ad_group_ad.ad.call_ad.phone_number",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Call Ad Phone Number Verification Url",
                "id": "ad_group_ad.ad.call_ad.phone_number_verification_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Device Preference",
                "id": "ad_group_ad.ad.device_preference",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Business Name",
                "id": "ad_group_ad.ad.discovery_carousel_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_carousel_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Carousel Cards Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.carousel_cards.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Description",
                "id": "ad_group_ad.ad.discovery_carousel_ad.description",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Headline",
                "id": "ad_group_ad.ad.discovery_carousel_ad.headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Carousel Ad Logo Image Asset",
                "id": "ad_group_ad.ad.discovery_carousel_ad.logo_image.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Business Name",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Call To Action Text",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Descriptions",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Headlines",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Lead Form Only",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.lead_form_only",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Logo Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Portrait Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.portrait_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Discovery Multi Asset Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.discovery_multi_asset_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Display Upload Product Type",
                "id": "ad_group_ad.ad.display_upload_ad.display_upload_product_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Upload Ad Media Bundle Asset",
                "id": "ad_group_ad.ad.display_upload_ad.media_bundle.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Display Url",
                "id": "ad_group_ad.ad.display_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Dynamic Search Ad Description2",
                "id": "ad_group_ad.ad.expanded_dynamic_search_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description",
                "id": "ad_group_ad.ad.expanded_text_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Description2",
                "id": "ad_group_ad.ad.expanded_text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part1",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part2",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Headline Part3",
                "id": "ad_group_ad.ad.expanded_text_ad.headline_part3",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path1",
                "id": "ad_group_ad.ad.expanded_text_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Expanded Text Ad Path2",
                "id": "ad_group_ad.ad.expanded_text_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final App Urls",
                "id": "ad_group_ad.ad.final_app_urls",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Mobile Urls",
                "id": "ad_group_ad.ad.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Url Suffix",
                "id": "ad_group_ad.ad.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Final Urls",
                "id": "ad_group_ad.ad.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Hotel Ad",
                "id": "ad_group_ad.ad.hotel_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Image Url",
                "id": "ad_group_ad.ad.image_ad.image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Media File",
                "id": "ad_group_ad.ad.image_ad.media_file",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Mime Type",
                "id": "ad_group_ad.ad.image_ad.mime_type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Name",
                "id": "ad_group_ad.ad.image_ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Image Url",
                "id": "ad_group_ad.ad.image_ad.preview_image_url",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy App Install Ad",
                "id": "ad_group_ad.ad.legacy_app_install_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Description",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.long_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Short Headline",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.short_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Logo Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_logo_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Legacy Responsive Display Ad Square Marketing Image",
                "id": "ad_group_ad.ad.legacy_responsive_display_ad.square_marketing_image",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Call To Actions",
                "id": "ad_group_ad.ad.local_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Descriptions",
                "id": "ad_group_ad.ad.local_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Headlines",
                "id": "ad_group_ad.ad.local_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images",
                "id": "ad_group_ad.ad.local_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Logo Images Asset",
                "id": "ad_group_ad.ad.local_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images",
                "id": "ad_group_ad.ad.local_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.local_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path1",
                "id": "ad_group_ad.ad.local_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Path2",
                "id": "ad_group_ad.ad.local_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos",
                "id": "ad_group_ad.ad.local_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Local Ad Videos Asset",
                "id": "ad_group_ad.ad.local_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Name",
                "id": "ad_group_ad.ad.name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Resource Name",
                "id": "ad_group_ad.ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Accent Color",
                "id": "ad_group_ad.ad.responsive_display_ad.accent_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Allow Flexible Color",
                "id": "ad_group_ad.ad.responsive_display_ad.allow_flexible_color",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Business Name",
                "id": "ad_group_ad.ad.responsive_display_ad.business_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Call To Action Text",
                "id": "ad_group_ad.ad.responsive_display_ad.call_to_action_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Asset Enhancements",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_asset_enhancements",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Control Spec Enable Autogen Video",
                "id": "ad_group_ad.ad.responsive_display_ad.control_spec.enable_autogen_video",
                "dataType": "BOOLEAN",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_display_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Format Setting",
                "id": "ad_group_ad.ad.responsive_display_ad.format_setting",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Headlines",
                "id": "ad_group_ad.ad.responsive_display_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Long Headline",
                "id": "ad_group_ad.ad.responsive_display_ad.long_headline",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Main Color",
                "id": "ad_group_ad.ad.responsive_display_ad.main_color",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Price Prefix",
                "id": "ad_group_ad.ad.responsive_display_ad.price_prefix",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Promo Text",
                "id": "ad_group_ad.ad.responsive_display_ad.promo_text",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Logo Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_logo_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Square Marketing Images Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.square_marketing_images.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Display Ad Youtube Videos Asset",
                "id": "ad_group_ad.ad.responsive_display_ad.youtube_videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Descriptions",
                "id": "ad_group_ad.ad.responsive_search_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Headlines",
                "id": "ad_group_ad.ad.responsive_search_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path1",
                "id": "ad_group_ad.ad.responsive_search_ad.path1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Responsive Search Ad Path2",
                "id": "ad_group_ad.ad.responsive_search_ad.path2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Comparison Listing Ad Headline",
                "id": "ad_group_ad.ad.shopping_comparison_listing_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Product Ad",
                "id": "ad_group_ad.ad.shopping_product_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Shopping Smart Ad",
                "id": "ad_group_ad.ad.shopping_smart_ad",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Descriptions",
                "id": "ad_group_ad.ad.smart_campaign_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Smart Campaign Ad Headlines",
                "id": "ad_group_ad.ad.smart_campaign_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad System Managed Resource Source",
                "id": "ad_group_ad.ad.system_managed_resource_source",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description1",
                "id": "ad_group_ad.ad.text_ad.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Description2",
                "id": "ad_group_ad.ad.text_ad.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Text Ad Headline",
                "id": "ad_group_ad.ad.text_ad.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Tracking Url Template",
                "id": "ad_group_ad.ad.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Type",
                "id": "ad_group_ad.ad.type",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Collections",
                "id": "ad_group_ad.ad.url_collections",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Url Custom Parameters",
                "id": "ad_group_ad.ad.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Bumper Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.bumper.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description1",
                "id": "ad_group_ad.ad.video_ad.in_feed.description1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Description2",
                "id": "ad_group_ad.ad.video_ad.in_feed.description2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Headline",
                "id": "ad_group_ad.ad.video_ad.in_feed.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Feed Thumbnail",
                "id": "ad_group_ad.ad.video_ad.in_feed.thumbnail",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Button Label",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Action Headline",
                "id": "ad_group_ad.ad.video_ad.in_stream.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad In Stream Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.in_stream.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Button Label",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_button_label",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Action Headline",
                "id": "ad_group_ad.ad.video_ad.non_skippable.action_headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Non Skippable Companion Banner Asset",
                "id": "ad_group_ad.ad.video_ad.non_skippable.companion_banner.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Description",
                "id": "ad_group_ad.ad.video_ad.out_stream.description",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Out Stream Headline",
                "id": "ad_group_ad.ad.video_ad.out_stream.headline",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Ad Video Asset",
                "id": "ad_group_ad.ad.video_ad.video.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb1",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb1",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Breadcrumb2",
                "id": "ad_group_ad.ad.video_responsive_ad.breadcrumb2",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Call To Actions",
                "id": "ad_group_ad.ad.video_responsive_ad.call_to_actions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Companion Banners Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.companion_banners.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Descriptions",
                "id": "ad_group_ad.ad.video_responsive_ad.descriptions",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Long Headlines",
                "id": "ad_group_ad.ad.video_responsive_ad.long_headlines",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos",
                "id": "ad_group_ad.ad.video_responsive_ad.videos",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Video Responsive Ad Videos Asset",
                "id": "ad_group_ad.ad.video_responsive_ad.videos.asset",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_ad.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Strength",
                "id": "ad_group_ad.ad_strength",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Labels",
                "id": "ad_group_ad.labels",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Approval Status",
                "id": "ad_group_ad.policy_summary.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Policy Topic Entries",
                "id": "ad_group_ad.policy_summary.policy_topic_entries",
                "dataType": "OBJECT",
                "group": "Ad Group Ad"
            },
            {
                "name": "Policy Summary Review Status",
                "id": "ad_group_ad.policy_summary.review_status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_ad.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Ad"
            },
            {
                "name": "Status",
                "id": "ad_group_ad.status",
                "dataType": "ENUM",
                "group": "Ad Group Ad"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Channel Id",
                "id": "video.channel_id",
                "dataType": "STRING",
                "group": "Video"
            },
            {
                "name": "Id",
                "id": "video.id",
                "dataType": "STRING",
                "group": "Video"
            },
            {
                "name": "Resource Name",
                "id": "video.resource_name",
                "dataType": "STRING",
                "group": "Video"
            },
            {
                "name": "Title",
                "id": "video.title",
                "dataType": "STRING",
                "group": "Video"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            }
        ],
        "webpage_view": [
            {
                "name": "Ad Rotation Mode",
                "id": "ad_group.ad_rotation_mode",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "ad_group.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Base Ad Group",
                "id": "ad_group.base_ad_group",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Campaign",
                "id": "ad_group.campaign",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Display Custom Bid Dimension",
                "id": "ad_group.display_custom_bid_dimension",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Source",
                "id": "ad_group.effective_target_cpa_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas Source",
                "id": "ad_group.effective_target_roas_source",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "ad_group.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "ad_group.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Explorer Auto Optimizer Setting Opt In",
                "id": "ad_group.explorer_auto_optimizer_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Ad Group"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Labels",
                "id": "ad_group.labels",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Name",
                "id": "ad_group.name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Resource Name",
                "id": "ad_group.resource_name",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Status",
                "id": "ad_group.status",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "ad_group.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group"
            },
            {
                "name": "Type",
                "id": "ad_group.type",
                "dataType": "ENUM",
                "group": "Ad Group"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group",
                "id": "ad_group_criterion.ad_group",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Age Range Type",
                "id": "ad_group_criterion.age_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "App Payment Model Type",
                "id": "ad_group_criterion.app_payment_model.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Approval Status",
                "id": "ad_group_criterion.approval_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Audience Audience",
                "id": "ad_group_criterion.audience.audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Combined Audience Combined Audience",
                "id": "ad_group_criterion.combined_audience.combined_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Affinity Custom Affinity",
                "id": "ad_group_criterion.custom_affinity.custom_affinity",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Audience Custom Audience",
                "id": "ad_group_criterion.custom_audience.custom_audience",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Custom Intent Custom Intent",
                "id": "ad_group_criterion.custom_intent.custom_intent",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Disapproval Reasons",
                "id": "ad_group_criterion.disapproval_reasons",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Display Name",
                "id": "ad_group_criterion.display_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Source",
                "id": "ad_group_criterion.effective_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Source",
                "id": "ad_group_criterion.effective_cpm_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Source",
                "id": "ad_group_criterion.effective_cpv_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Source",
                "id": "ad_group_criterion.effective_percent_cpc_bid_source",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Mobile Urls",
                "id": "ad_group_criterion.final_mobile_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Url Suffix",
                "id": "ad_group_criterion.final_url_suffix",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Final Urls",
                "id": "ad_group_criterion.final_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Gender Type",
                "id": "ad_group_criterion.gender.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Income Range Type",
                "id": "ad_group_criterion.income_range.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Match Type",
                "id": "ad_group_criterion.keyword.match_type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Keyword Text",
                "id": "ad_group_criterion.keyword.text",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Labels",
                "id": "ad_group_criterion.labels",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel City City Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_city.city_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Country Region Country Region Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_country_region.country_region_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Id Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel State State Criterion",
                "id": "ad_group_criterion.listing_group.case_value.hotel_state.state_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Country Code",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.country_code",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Level",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Brand Value",
                "id": "ad_group_criterion.listing_group.case_value.product_brand.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Channel",
                "id": "ad_group_criterion.listing_group.case_value.product_channel.channel",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Channel Exclusivity Channel Exclusivity",
                "id": "ad_group_criterion.listing_group.case_value.product_channel_exclusivity.channel_exclusivity",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Condition Condition",
                "id": "ad_group_criterion.listing_group.case_value.product_condition.condition",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Index",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.index",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Custom Attribute Value",
                "id": "ad_group_criterion.listing_group.case_value.product_custom_attribute.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Item Id Value",
                "id": "ad_group_criterion.listing_group.case_value.product_item_id.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Level",
                "id": "ad_group_criterion.listing_group.case_value.product_type.level",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Type Value",
                "id": "ad_group_criterion.listing_group.case_value.product_type.value",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Parent Ad Group Criterion",
                "id": "ad_group_criterion.listing_group.parent_ad_group_criterion",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Type",
                "id": "ad_group_criterion.listing_group.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile App Category Mobile App Category Constant",
                "id": "ad_group_criterion.mobile_app_category.mobile_app_category_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application App Id",
                "id": "ad_group_criterion.mobile_application.app_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Mobile Application Name",
                "id": "ad_group_criterion.mobile_application.name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Negative",
                "id": "ad_group_criterion.negative",
                "dataType": "BOOLEAN",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parental Status Type",
                "id": "ad_group_criterion.parental_status.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Placement Url",
                "id": "ad_group_criterion.placement.url",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Creative Quality Score",
                "id": "ad_group_criterion.quality_info.creative_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Post Click Quality Score",
                "id": "ad_group_criterion.quality_info.post_click_quality_score",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Search Predicted Ctr",
                "id": "ad_group_criterion.quality_info.search_predicted_ctr",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Resource Name",
                "id": "ad_group_criterion.resource_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Status",
                "id": "ad_group_criterion.status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "System Serving Status",
                "id": "ad_group_criterion.system_serving_status",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Path",
                "id": "ad_group_criterion.topic.path",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Topic Topic Constant",
                "id": "ad_group_criterion.topic.topic_constant",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Tracking Url Template",
                "id": "ad_group_criterion.tracking_url_template",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Type",
                "id": "ad_group_criterion.type",
                "dataType": "ENUM",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Url Custom Parameters",
                "id": "ad_group_criterion.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User Interest User Interest Category",
                "id": "ad_group_criterion.user_interest.user_interest_category",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "User List User List",
                "id": "ad_group_criterion.user_list.user_list",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Conditions",
                "id": "ad_group_criterion.webpage.conditions",
                "dataType": "OBJECT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Criterion Name",
                "id": "ad_group_criterion.webpage.criterion_name",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Sample Sample Urls",
                "id": "ad_group_criterion.webpage.sample.sample_urls",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Channel Channel Id",
                "id": "ad_group_criterion.youtube_channel.channel_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Youtube Video Video Id",
                "id": "ad_group_criterion.youtube_video.video_id",
                "dataType": "STRING",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Accessible Bidding Strategy",
                "id": "campaign.accessible_bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Ad Serving Optimization Status",
                "id": "campaign.ad_serving_optimization_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Sub Type",
                "id": "campaign.advertising_channel_sub_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Advertising Channel Type",
                "id": "campaign.advertising_channel_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Id",
                "id": "campaign.app_campaign_setting.app_id",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting App Store",
                "id": "campaign.app_campaign_setting.app_store",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "App Campaign Setting Bidding Strategy Goal Type",
                "id": "campaign.app_campaign_setting.bidding_strategy_goal_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Audience Setting Use Audience Grouped",
                "id": "campaign.audience_setting.use_audience_grouped",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Base Campaign",
                "id": "campaign.base_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy",
                "id": "campaign.bidding_strategy",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy System Status",
                "id": "campaign.bidding_strategy_system_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Bidding Strategy Type",
                "id": "campaign.bidding_strategy_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Campaign Budget",
                "id": "campaign.campaign_budget",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Campaign Group",
                "id": "campaign.campaign_group",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Domain Name",
                "id": "campaign.dynamic_search_ads_setting.domain_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Feeds",
                "id": "campaign.dynamic_search_ads_setting.feeds",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Language Code",
                "id": "campaign.dynamic_search_ads_setting.language_code",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Dynamic Search Ads Setting Use Supplied Urls Only",
                "id": "campaign.dynamic_search_ads_setting.use_supplied_urls_only",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "End Date",
                "id": "campaign.end_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Field Types",
                "id": "campaign.excluded_parent_asset_field_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Excluded Parent Asset Set Types",
                "id": "campaign.excluded_parent_asset_set_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Experiment Type",
                "id": "campaign.experiment_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Final Url Suffix",
                "id": "campaign.final_url_suffix",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Frequency Caps",
                "id": "campaign.frequency_caps",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Negative Geo Target Type",
                "id": "campaign.geo_target_type_setting.negative_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Geo Target Type Setting Positive Geo Target Type",
                "id": "campaign.geo_target_type_setting.positive_geo_target_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Labels",
                "id": "campaign.labels",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Local Campaign Setting Location Source Type",
                "id": "campaign.local_campaign_setting.location_source_type",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Local Services Campaign Settings Category Bids",
                "id": "campaign.local_services_campaign_settings.category_bids",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpa",
                "id": "campaign.manual_cpa",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpc Enhanced Cpc Enabled",
                "id": "campaign.manual_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpv",
                "id": "campaign.manual_cpv",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Name",
                "id": "campaign.name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Content Network",
                "id": "campaign.network_settings.target_content_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Google Search",
                "id": "campaign.network_settings.target_google_search",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Partner Search Network",
                "id": "campaign.network_settings.target_partner_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Network Settings Target Search Network",
                "id": "campaign.network_settings.target_search_network",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Optimization Goal Setting Optimization Goal Types",
                "id": "campaign.optimization_goal_setting.optimization_goal_types",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Payment Mode",
                "id": "campaign.payment_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Enhanced Cpc Enabled",
                "id": "campaign.percent_cpc.enhanced_cpc_enabled",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Performance Max Campaign",
                "id": "campaign.performance_max_upgrade.performance_max_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Pre Upgrade Campaign",
                "id": "campaign.performance_max_upgrade.pre_upgrade_campaign",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Performance Max Upgrade Status",
                "id": "campaign.performance_max_upgrade.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status",
                "id": "campaign.primary_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Primary Status Reasons",
                "id": "campaign.primary_status_reasons",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Real Time Bidding Setting Opt In",
                "id": "campaign.real_time_bidding_setting.opt_in",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Resource Name",
                "id": "campaign.resource_name",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Selective Optimization Conversion Actions",
                "id": "campaign.selective_optimization.conversion_actions",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Serving Status",
                "id": "campaign.serving_status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Enable Local",
                "id": "campaign.shopping_setting.enable_local",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Feed Label",
                "id": "campaign.shopping_setting.feed_label",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Sales Country",
                "id": "campaign.shopping_setting.sales_country",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Use Vehicle Inventory",
                "id": "campaign.shopping_setting.use_vehicle_inventory",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Start Date",
                "id": "campaign.start_date",
                "dataType": "DATE",
                "group": "Campaign"
            },
            {
                "name": "Status",
                "id": "campaign.status",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location",
                "id": "campaign.target_impression_share.location",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Targeting Setting Target Restrictions",
                "id": "campaign.targeting_setting.target_restrictions",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Tracking Setting Tracking Url",
                "id": "campaign.tracking_setting.tracking_url",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Tracking Url Template",
                "id": "campaign.tracking_url_template",
                "dataType": "STRING",
                "group": "Campaign"
            },
            {
                "name": "Url Custom Parameters",
                "id": "campaign.url_custom_parameters",
                "dataType": "OBJECT",
                "group": "Campaign"
            },
            {
                "name": "Url Expansion Opt Out",
                "id": "campaign.url_expansion_opt_out",
                "dataType": "BOOLEAN",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Display Url Mode",
                "id": "campaign.vanity_pharma.vanity_pharma_display_url_mode",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Vanity Pharma Vanity Pharma Text",
                "id": "campaign.vanity_pharma.vanity_pharma_text",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Video Brand Safety Suitability",
                "id": "campaign.video_brand_safety_suitability",
                "dataType": "ENUM",
                "group": "Campaign"
            },
            {
                "name": "Auto Tagging Enabled",
                "id": "customer.auto_tagging_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Action",
                "id": "customer.call_reporting_setting.call_conversion_action",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Conversion Reporting Enabled",
                "id": "customer.call_reporting_setting.call_conversion_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Call Reporting Setting Call Reporting Enabled",
                "id": "customer.call_reporting_setting.call_reporting_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Accepted Customer Data Terms",
                "id": "customer.conversion_tracking_setting.accepted_customer_data_terms",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Status",
                "id": "customer.conversion_tracking_setting.conversion_tracking_status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Enhanced Conversions For Leads Enabled",
                "id": "customer.conversion_tracking_setting.enhanced_conversions_for_leads_enabled",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Google Ads Conversion Customer",
                "id": "customer.conversion_tracking_setting.google_ads_conversion_customer",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Currency Code",
                "id": "customer.currency_code",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Descriptive Name",
                "id": "customer.descriptive_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Final Url Suffix",
                "id": "customer.final_url_suffix",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Has Partners Badge",
                "id": "customer.has_partners_badge",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Manager",
                "id": "customer.manager",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Pay Per Conversion Eligibility Failure Reasons",
                "id": "customer.pay_per_conversion_eligibility_failure_reasons",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Remarketing Setting Google Global Site Tag",
                "id": "customer.remarketing_setting.google_global_site_tag",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "customer.resource_name",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Status",
                "id": "customer.status",
                "dataType": "ENUM",
                "group": "Customer"
            },
            {
                "name": "Test Account",
                "id": "customer.test_account",
                "dataType": "BOOLEAN",
                "group": "Customer"
            },
            {
                "name": "Time Zone",
                "id": "customer.time_zone",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Tracking Url Template",
                "id": "customer.tracking_url_template",
                "dataType": "STRING",
                "group": "Customer"
            },
            {
                "name": "Resource Name",
                "id": "webpage_view.resource_name",
                "dataType": "STRING",
                "group": "Webpage View"
            },
            {
                "name": "Ad Network Type",
                "id": "segments.ad_network_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Click Type",
                "id": "segments.click_type",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action",
                "id": "segments.conversion_action",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Category",
                "id": "segments.conversion_action_category",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Action Name",
                "id": "segments.conversion_action_name",
                "dataType": "STRING",
                "group": "Segments"
            },
            {
                "name": "Conversion Lag Bucket",
                "id": "segments.conversion_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Conversion Or Adjustment Lag Bucket",
                "id": "segments.conversion_or_adjustment_lag_bucket",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Date",
                "id": "segments.date",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Day Of Week",
                "id": "segments.day_of_week",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Device",
                "id": "segments.device",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "External Conversion Source",
                "id": "segments.external_conversion_source",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Month",
                "id": "segments.month",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Month Of Year",
                "id": "segments.month_of_year",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Quarter",
                "id": "segments.quarter",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Slot",
                "id": "segments.slot",
                "dataType": "ENUM",
                "group": "Segments"
            },
            {
                "name": "Week",
                "id": "segments.week",
                "dataType": "DATE",
                "group": "Segments"
            },
            {
                "name": "Interaction Event Types",
                "id": "metrics.interaction_event_types",
                "dataType": "ENUM",
                "group": "Metrics"
            }
        ]
    },
    "metrics": {
        "accessible_bidding_strategy": [
            {
                "name": "Id",
                "id": "accessible_bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "accessible_bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "accessible_bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Customer Id",
                "id": "accessible_bidding_strategy.owner_customer_id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "accessible_bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "accessible_bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "accessible_bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "accessible_bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "account_budget": [
            {
                "name": "Adjusted Spending Limit Micros",
                "id": "account_budget.adjusted_spending_limit_micros",
                "dataType": "INTEGER",
                "group": "Account Budget"
            },
            {
                "name": "Amount Served Micros",
                "id": "account_budget.amount_served_micros",
                "dataType": "INTEGER",
                "group": "Account Budget"
            },
            {
                "name": "Approved Spending Limit Micros",
                "id": "account_budget.approved_spending_limit_micros",
                "dataType": "INTEGER",
                "group": "Account Budget"
            },
            {
                "name": "Id",
                "id": "account_budget.id",
                "dataType": "INTEGER",
                "group": "Account Budget"
            },
            {
                "name": "Pending Proposal Spending Limit Micros",
                "id": "account_budget.pending_proposal.spending_limit_micros",
                "dataType": "INTEGER",
                "group": "Account Budget"
            },
            {
                "name": "Proposed Spending Limit Micros",
                "id": "account_budget.proposed_spending_limit_micros",
                "dataType": "INTEGER",
                "group": "Account Budget"
            },
            {
                "name": "Total Adjustments Micros",
                "id": "account_budget.total_adjustments_micros",
                "dataType": "INTEGER",
                "group": "Account Budget"
            },
            {
                "name": "Id",
                "id": "billing_setup.id",
                "dataType": "INTEGER",
                "group": "Billing Setup"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "account_budget_proposal": [
            {
                "name": "Approved Spending Limit Micros",
                "id": "account_budget_proposal.approved_spending_limit_micros",
                "dataType": "INTEGER",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Id",
                "id": "account_budget_proposal.id",
                "dataType": "INTEGER",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Proposed Spending Limit Micros",
                "id": "account_budget_proposal.proposed_spending_limit_micros",
                "dataType": "INTEGER",
                "group": "Account Budget Proposal"
            },
            {
                "name": "Id",
                "id": "billing_setup.id",
                "dataType": "INTEGER",
                "group": "Billing Setup"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "account_link": [
            {
                "name": "Account Link Id",
                "id": "account_link.account_link_id",
                "dataType": "INTEGER",
                "group": "Account Link"
            },
            {
                "name": "Data Partner Data Partner Id",
                "id": "account_link.data_partner.data_partner_id",
                "dataType": "INTEGER",
                "group": "Account Link"
            },
            {
                "name": "Hotel Center Hotel Center Id",
                "id": "account_link.hotel_center.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Account Link"
            },
            {
                "name": "Third Party App Analytics App Analytics Provider Id",
                "id": "account_link.third_party_app_analytics.app_analytics_provider_id",
                "dataType": "INTEGER",
                "group": "Account Link"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "ad_group": [
            {
                "name": "Id",
                "id": "accessible_bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "accessible_bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "accessible_bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Customer Id",
                "id": "accessible_bidding_strategy.owner_customer_id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "accessible_bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "accessible_bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "accessible_bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "accessible_bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Hour",
                "id": "segments.hour",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions By Conversion Date",
                "id": "metrics.all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value By Conversion Date",
                "id": "metrics.all_conversions_value_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Absolute Top Impression Percentage",
                "id": "metrics.auction_insight_search_absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Impression Share",
                "id": "metrics.auction_insight_search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Outranking Share",
                "id": "metrics.auction_insight_search_outranking_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Overlap Rate",
                "id": "metrics.auction_insight_search_overlap_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Position Above Rate",
                "id": "metrics.auction_insight_search_position_above_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Top Impression Percentage",
                "id": "metrics.auction_insight_search_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Page Views",
                "id": "metrics.average_page_views",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Time On Site",
                "id": "metrics.average_time_on_site",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Bounce Rate",
                "id": "metrics.bounce_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Content Impression Share",
                "id": "metrics.content_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Content Rank Lost Impression Share",
                "id": "metrics.content_rank_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions By Conversion Date",
                "id": "metrics.conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value By Conversion Date",
                "id": "metrics.conversions_value_by_conversion_date",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Current Model Attributed Conversion",
                "id": "metrics.cost_per_current_model_attributed_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions",
                "id": "metrics.current_model_attributed_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions Value",
                "id": "metrics.current_model_attributed_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Percent New Visitors",
                "id": "metrics.percent_new_visitors",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Phone Calls",
                "id": "metrics.phone_calls",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Impressions",
                "id": "metrics.phone_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Through Rate",
                "id": "metrics.phone_through_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Relative Ctr",
                "id": "metrics.relative_ctr",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Absolute Top Impression Share",
                "id": "metrics.search_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Absolute Top Impression Share",
                "id": "metrics.search_budget_lost_absolute_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Top Impression Share",
                "id": "metrics.search_budget_lost_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Exact Match Impression Share",
                "id": "metrics.search_exact_match_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Impression Share",
                "id": "metrics.search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Absolute Top Impression Share",
                "id": "metrics.search_rank_lost_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Impression Share",
                "id": "metrics.search_rank_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Top Impression Share",
                "id": "metrics.search_rank_lost_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Top Impression Share",
                "id": "metrics.search_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions By Conversion Date",
                "id": "metrics.value_per_all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversions By Conversion Date",
                "id": "metrics.value_per_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Current Model Attributed Conversion",
                "id": "metrics.value_per_current_model_attributed_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "ad_group_ad": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Id",
                "id": "ad_group_ad.ad.id",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Height",
                "id": "ad_group_ad.ad.image_ad.pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Width",
                "id": "ad_group_ad.ad.image_ad.pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Height",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Width",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions By Conversion Date",
                "id": "metrics.all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value By Conversion Date",
                "id": "metrics.all_conversions_value_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Page Views",
                "id": "metrics.average_page_views",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Time On Site",
                "id": "metrics.average_time_on_site",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Bounce Rate",
                "id": "metrics.bounce_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions By Conversion Date",
                "id": "metrics.conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value By Conversion Date",
                "id": "metrics.conversions_value_by_conversion_date",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Current Model Attributed Conversion",
                "id": "metrics.cost_per_current_model_attributed_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions",
                "id": "metrics.current_model_attributed_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions Value",
                "id": "metrics.current_model_attributed_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Percent New Visitors",
                "id": "metrics.percent_new_visitors",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions By Conversion Date",
                "id": "metrics.value_per_all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversions By Conversion Date",
                "id": "metrics.value_per_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Current Model Attributed Conversion",
                "id": "metrics.value_per_current_model_attributed_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "ad_group_ad_asset_combination_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Id",
                "id": "ad_group_ad.ad.id",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Height",
                "id": "ad_group_ad.ad.image_ad.pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Width",
                "id": "ad_group_ad.ad.image_ad.pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Height",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Width",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "ad_group_ad_asset_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Id",
                "id": "ad_group_ad.ad.id",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Height",
                "id": "ad_group_ad.ad.image_ad.pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Width",
                "id": "ad_group_ad.ad.image_ad.pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Height",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Width",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Biddable App Install Conversions",
                "id": "metrics.biddable_app_install_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Biddable App Post Install Conversions",
                "id": "metrics.biddable_app_post_install_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value Per Cost",
                "id": "metrics.conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "ad_group_ad_label": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Id",
                "id": "ad_group_ad.ad.id",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Height",
                "id": "ad_group_ad.ad.image_ad.pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Width",
                "id": "ad_group_ad.ad.image_ad.pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Height",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Width",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "label.id",
                "dataType": "INTEGER",
                "group": "Label"
            }
        ],
        "ad_group_asset": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Calls",
                "id": "metrics.phone_calls",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Impressions",
                "id": "metrics.phone_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Through Rate",
                "id": "metrics.phone_through_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "ad_group_asset_set": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "asset_set.id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Listing Id Filters",
                "id": "asset_set.location_set.business_profile_location_set.listing_id_filters",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Merchant Id",
                "id": "asset_set.merchant_center_feed.merchant_id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "ad_group_audience_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "user_list.id",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Match Rate Percentage",
                "id": "user_list.match_rate_percentage",
                "dataType": "PERCENT",
                "group": "User List"
            },
            {
                "name": "Membership Life Span",
                "id": "user_list.membership_life_span",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Display",
                "id": "user_list.size_for_display",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Search",
                "id": "user_list.size_for_search",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions By Conversion Date",
                "id": "metrics.all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value By Conversion Date",
                "id": "metrics.all_conversions_value_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions By Conversion Date",
                "id": "metrics.conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value By Conversion Date",
                "id": "metrics.conversions_value_by_conversion_date",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions By Conversion Date",
                "id": "metrics.value_per_all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversions By Conversion Date",
                "id": "metrics.value_per_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "ad_group_bid_modifier": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_bid_modifier.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_bid_modifier.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Hotel Advance Booking Window Max Days",
                "id": "ad_group_bid_modifier.hotel_advance_booking_window.max_days",
                "dataType": "INTEGER",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Hotel Advance Booking Window Min Days",
                "id": "ad_group_bid_modifier.hotel_advance_booking_window.min_days",
                "dataType": "INTEGER",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Hotel Length Of Stay Max Nights",
                "id": "ad_group_bid_modifier.hotel_length_of_stay.max_nights",
                "dataType": "INTEGER",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Hotel Length Of Stay Min Nights",
                "id": "ad_group_bid_modifier.hotel_length_of_stay.min_nights",
                "dataType": "INTEGER",
                "group": "Ad Group Bid Modifier"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "ad_group_criterion": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "mobile_app_category_constant.id",
                "dataType": "INTEGER",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Id",
                "id": "topic_constant.id",
                "dataType": "INTEGER",
                "group": "Topic Constant"
            },
            {
                "name": "User Interest Id",
                "id": "user_interest.user_interest_id",
                "dataType": "INTEGER",
                "group": "User Interest"
            },
            {
                "name": "Id",
                "id": "user_list.id",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Match Rate Percentage",
                "id": "user_list.match_rate_percentage",
                "dataType": "PERCENT",
                "group": "User List"
            },
            {
                "name": "Membership Life Span",
                "id": "user_list.membership_life_span",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Display",
                "id": "user_list.size_for_display",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Search",
                "id": "user_list.size_for_search",
                "dataType": "INTEGER",
                "group": "User List"
            }
        ],
        "ad_group_criterion_customizer": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customizer_attribute.id",
                "dataType": "INTEGER",
                "group": "Customizer Attribute"
            }
        ],
        "ad_group_criterion_label": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "label.id",
                "dataType": "INTEGER",
                "group": "Label"
            }
        ],
        "ad_group_criterion_simulation": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Ad Group Id",
                "id": "ad_group_criterion_simulation.ad_group_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion Simulation"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion_simulation.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion Simulation"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "ad_group_customizer": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customizer_attribute.id",
                "dataType": "INTEGER",
                "group": "Customizer Attribute"
            }
        ],
        "ad_group_extension_setting": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "ad_group_feed": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            }
        ],
        "ad_group_label": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "label.id",
                "dataType": "INTEGER",
                "group": "Label"
            }
        ],
        "ad_group_simulation": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Group Id",
                "id": "ad_group_simulation.ad_group_id",
                "dataType": "INTEGER",
                "group": "Ad Group Simulation"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "ad_parameter": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Parameter Index",
                "id": "ad_parameter.parameter_index",
                "dataType": "INTEGER",
                "group": "Ad Parameter"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "ad_schedule_view": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Ad Schedule End Hour",
                "id": "campaign_criterion.ad_schedule.end_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Hour",
                "id": "campaign_criterion.ad_schedule.start_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Bid Modifier",
                "id": "campaign_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "campaign_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Latitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.latitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Longitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.longitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius",
                "id": "campaign_criterion.proximity.radius",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "campaign_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "age_range_view": [
            {
                "name": "Id",
                "id": "accessible_bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "accessible_bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "accessible_bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Customer Id",
                "id": "accessible_bidding_strategy.owner_customer_id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "accessible_bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "accessible_bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "accessible_bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "accessible_bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "asset": [
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "asset_field_type_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Calls",
                "id": "metrics.phone_calls",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Impressions",
                "id": "metrics.phone_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Through Rate",
                "id": "metrics.phone_through_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "asset_group": [
            {
                "name": "Id",
                "id": "asset_group.id",
                "dataType": "INTEGER",
                "group": "Asset Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "asset_group_asset": [
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset_group.id",
                "dataType": "INTEGER",
                "group": "Asset Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "asset_group_listing_group_filter": [
            {
                "name": "Id",
                "id": "asset_group.id",
                "dataType": "INTEGER",
                "group": "Asset Group"
            },
            {
                "name": "Case Value Product Bidding Category Id",
                "id": "asset_group_listing_group_filter.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Id",
                "id": "asset_group_listing_group_filter.id",
                "dataType": "INTEGER",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "asset_group_product_group_view": [
            {
                "name": "Id",
                "id": "asset_group.id",
                "dataType": "INTEGER",
                "group": "Asset Group"
            },
            {
                "name": "Case Value Product Bidding Category Id",
                "id": "asset_group_listing_group_filter.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Id",
                "id": "asset_group_listing_group_filter.id",
                "dataType": "INTEGER",
                "group": "Asset Group Listing Group Filter"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "asset_group_signal": [
            {
                "name": "Id",
                "id": "asset_group.id",
                "dataType": "INTEGER",
                "group": "Asset Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "asset_set": [
            {
                "name": "Id",
                "id": "asset_set.id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Listing Id Filters",
                "id": "asset_set.location_set.business_profile_location_set.listing_id_filters",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Merchant Id",
                "id": "asset_set.merchant_center_feed.merchant_id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "asset_set_asset": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset_set.id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Listing Id Filters",
                "id": "asset_set.location_set.business_profile_location_set.listing_id_filters",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Merchant Id",
                "id": "asset_set.merchant_center_feed.merchant_id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Calls",
                "id": "metrics.phone_calls",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Impressions",
                "id": "metrics.phone_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Through Rate",
                "id": "metrics.phone_through_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            }
        ],
        "asset_set_type_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "audience": [
            {
                "name": "Id",
                "id": "audience.id",
                "dataType": "INTEGER",
                "group": "Audience"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "batch_job": [
            {
                "name": "Id",
                "id": "batch_job.id",
                "dataType": "INTEGER",
                "group": "Batch Job"
            },
            {
                "name": "Metadata Estimated Completion Ratio",
                "id": "batch_job.metadata.estimated_completion_ratio",
                "dataType": "FLOAT",
                "group": "Batch Job"
            },
            {
                "name": "Metadata Executed Operation Count",
                "id": "batch_job.metadata.executed_operation_count",
                "dataType": "INTEGER",
                "group": "Batch Job"
            },
            {
                "name": "Metadata Operation Count",
                "id": "batch_job.metadata.operation_count",
                "dataType": "INTEGER",
                "group": "Batch Job"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "bidding_data_exclusion": [
            {
                "name": "Data Exclusion Id",
                "id": "bidding_data_exclusion.data_exclusion_id",
                "dataType": "INTEGER",
                "group": "Bidding Data Exclusion"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "bidding_seasonality_adjustment": [
            {
                "name": "Conversion Rate Modifier",
                "id": "bidding_seasonality_adjustment.conversion_rate_modifier",
                "dataType": "FLOAT",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Seasonality Adjustment Id",
                "id": "bidding_seasonality_adjustment.seasonality_adjustment_id",
                "dataType": "INTEGER",
                "group": "Bidding Seasonality Adjustment"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "bidding_strategy": [
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Hour",
                "id": "segments.hour",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "bidding_strategy_simulation": [
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Bidding Strategy Id",
                "id": "bidding_strategy_simulation.bidding_strategy_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy Simulation"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "billing_setup": [
            {
                "name": "Id",
                "id": "billing_setup.id",
                "dataType": "INTEGER",
                "group": "Billing Setup"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "call_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Call Duration Seconds",
                "id": "call_view.call_duration_seconds",
                "dataType": "INTEGER",
                "group": "Call View"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "campaign": [
            {
                "name": "Id",
                "id": "accessible_bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "accessible_bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "accessible_bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Customer Id",
                "id": "accessible_bidding_strategy.owner_customer_id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "accessible_bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "accessible_bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "accessible_bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "accessible_bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Aligned Bidding Strategy Id",
                "id": "campaign_budget.aligned_bidding_strategy_id",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Amount Micros",
                "id": "campaign_budget.amount_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Id",
                "id": "campaign_budget.id",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Amount Micros",
                "id": "campaign_budget.recommended_budget_amount_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Clicks",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_clicks",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Cost Micros",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_cost_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Interactions",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_interactions",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Views",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_views",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Reference Count",
                "id": "campaign_budget.reference_count",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Total Amount Micros",
                "id": "campaign_budget.total_amount_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Id",
                "id": "campaign_group.id",
                "dataType": "INTEGER",
                "group": "Campaign Group"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Hour",
                "id": "segments.hour",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network Conversion Value",
                "id": "segments.sk_ad_network_conversion_value",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions By Conversion Date",
                "id": "metrics.all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Click To Call",
                "id": "metrics.all_conversions_from_click_to_call",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Directions",
                "id": "metrics.all_conversions_from_directions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Click To Call",
                "id": "metrics.all_conversions_from_location_asset_click_to_call",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Directions",
                "id": "metrics.all_conversions_from_location_asset_directions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Menu",
                "id": "metrics.all_conversions_from_location_asset_menu",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Order",
                "id": "metrics.all_conversions_from_location_asset_order",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Other Engagement",
                "id": "metrics.all_conversions_from_location_asset_other_engagement",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Store Visits",
                "id": "metrics.all_conversions_from_location_asset_store_visits",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Website",
                "id": "metrics.all_conversions_from_location_asset_website",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Menu",
                "id": "metrics.all_conversions_from_menu",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Order",
                "id": "metrics.all_conversions_from_order",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Other Engagement",
                "id": "metrics.all_conversions_from_other_engagement",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Store Visit",
                "id": "metrics.all_conversions_from_store_visit",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Store Website",
                "id": "metrics.all_conversions_from_store_website",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value By Conversion Date",
                "id": "metrics.all_conversions_value_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Absolute Top Impression Percentage",
                "id": "metrics.auction_insight_search_absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Impression Share",
                "id": "metrics.auction_insight_search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Outranking Share",
                "id": "metrics.auction_insight_search_outranking_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Overlap Rate",
                "id": "metrics.auction_insight_search_overlap_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Position Above Rate",
                "id": "metrics.auction_insight_search_position_above_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Top Impression Percentage",
                "id": "metrics.auction_insight_search_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Page Views",
                "id": "metrics.average_page_views",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Time On Site",
                "id": "metrics.average_time_on_site",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Bounce Rate",
                "id": "metrics.bounce_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Content Budget Lost Impression Share",
                "id": "metrics.content_budget_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Content Impression Share",
                "id": "metrics.content_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Content Rank Lost Impression Share",
                "id": "metrics.content_rank_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions By Conversion Date",
                "id": "metrics.conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value By Conversion Date",
                "id": "metrics.conversions_value_by_conversion_date",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Current Model Attributed Conversion",
                "id": "metrics.cost_per_current_model_attributed_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions",
                "id": "metrics.current_model_attributed_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions From Interactions Rate",
                "id": "metrics.current_model_attributed_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions From Interactions Value Per Interaction",
                "id": "metrics.current_model_attributed_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions Value",
                "id": "metrics.current_model_attributed_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions Value Per Cost",
                "id": "metrics.current_model_attributed_conversions_value_per_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Eligible Impressions From Location Asset Store Reach",
                "id": "metrics.eligible_impressions_from_location_asset_store_reach",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Invalid Click Rate",
                "id": "metrics.invalid_click_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Invalid Clicks",
                "id": "metrics.invalid_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Optimization Score Uplift",
                "id": "metrics.optimization_score_uplift",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Percent New Visitors",
                "id": "metrics.percent_new_visitors",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Phone Calls",
                "id": "metrics.phone_calls",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Impressions",
                "id": "metrics.phone_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Through Rate",
                "id": "metrics.phone_through_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Publisher Organic Clicks",
                "id": "metrics.publisher_organic_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Publisher Purchased Clicks",
                "id": "metrics.publisher_purchased_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Publisher Unknown Clicks",
                "id": "metrics.publisher_unknown_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Relative Ctr",
                "id": "metrics.relative_ctr",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Absolute Top Impression Share",
                "id": "metrics.search_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Absolute Top Impression Share",
                "id": "metrics.search_budget_lost_absolute_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Impression Share",
                "id": "metrics.search_budget_lost_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Top Impression Share",
                "id": "metrics.search_budget_lost_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Click Share",
                "id": "metrics.search_click_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Exact Match Impression Share",
                "id": "metrics.search_exact_match_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Impression Share",
                "id": "metrics.search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Absolute Top Impression Share",
                "id": "metrics.search_rank_lost_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Impression Share",
                "id": "metrics.search_rank_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Top Impression Share",
                "id": "metrics.search_rank_lost_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Top Impression Share",
                "id": "metrics.search_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Sk Ad Network Conversions",
                "id": "metrics.sk_ad_network_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions By Conversion Date",
                "id": "metrics.value_per_all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversions By Conversion Date",
                "id": "metrics.value_per_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Current Model Attributed Conversion",
                "id": "metrics.value_per_current_model_attributed_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Click To Call",
                "id": "metrics.view_through_conversions_from_location_asset_click_to_call",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Directions",
                "id": "metrics.view_through_conversions_from_location_asset_directions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Menu",
                "id": "metrics.view_through_conversions_from_location_asset_menu",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Order",
                "id": "metrics.view_through_conversions_from_location_asset_order",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Other Engagement",
                "id": "metrics.view_through_conversions_from_location_asset_other_engagement",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Store Visits",
                "id": "metrics.view_through_conversions_from_location_asset_store_visits",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Website",
                "id": "metrics.view_through_conversions_from_location_asset_website",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Gads_cc_test2",
                "id": "593b6247-c6ca-4d84-92dc-a8d3456907a2",
                "dataType": "Number",
                "group": "Customized"
            },
            {
                "name": "gads_imp",
                "id": "78e37af1-c296-47aa-b62b-b0f56d482377",
                "dataType": "Number",
                "group": "Customized"
            }
        ],
        "campaign_asset": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Calls",
                "id": "metrics.phone_calls",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Impressions",
                "id": "metrics.phone_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Through Rate",
                "id": "metrics.phone_through_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "campaign_asset_set": [
            {
                "name": "Id",
                "id": "asset_set.id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Listing Id Filters",
                "id": "asset_set.location_set.business_profile_location_set.listing_id_filters",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Merchant Id",
                "id": "asset_set.merchant_center_feed.merchant_id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "campaign_audience_view": [
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Ad Schedule End Hour",
                "id": "campaign_criterion.ad_schedule.end_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Hour",
                "id": "campaign_criterion.ad_schedule.start_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Bid Modifier",
                "id": "campaign_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "campaign_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Latitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.latitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Longitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.longitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius",
                "id": "campaign_criterion.proximity.radius",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "campaign_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "user_list.id",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Match Rate Percentage",
                "id": "user_list.match_rate_percentage",
                "dataType": "PERCENT",
                "group": "User List"
            },
            {
                "name": "Membership Life Span",
                "id": "user_list.membership_life_span",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Display",
                "id": "user_list.size_for_display",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Search",
                "id": "user_list.size_for_search",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions By Conversion Date",
                "id": "metrics.all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value By Conversion Date",
                "id": "metrics.all_conversions_value_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions By Conversion Date",
                "id": "metrics.conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value By Conversion Date",
                "id": "metrics.conversions_value_by_conversion_date",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions By Conversion Date",
                "id": "metrics.value_per_all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversions By Conversion Date",
                "id": "metrics.value_per_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "campaign_bid_modifier": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Bid Modifier",
                "id": "campaign_bid_modifier.bid_modifier",
                "dataType": "FLOAT",
                "group": "Campaign Bid Modifier"
            },
            {
                "name": "Criterion Id",
                "id": "campaign_bid_modifier.criterion_id",
                "dataType": "INTEGER",
                "group": "Campaign Bid Modifier"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "campaign_budget": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Aligned Bidding Strategy Id",
                "id": "campaign_budget.aligned_bidding_strategy_id",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Amount Micros",
                "id": "campaign_budget.amount_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Id",
                "id": "campaign_budget.id",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Amount Micros",
                "id": "campaign_budget.recommended_budget_amount_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Clicks",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_clicks",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Cost Micros",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_cost_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Interactions",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_interactions",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Views",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_views",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Reference Count",
                "id": "campaign_budget.reference_count",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Total Amount Micros",
                "id": "campaign_budget.total_amount_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "campaign_conversion_goal": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "campaign_criterion": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Ad Schedule End Hour",
                "id": "campaign_criterion.ad_schedule.end_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Hour",
                "id": "campaign_criterion.ad_schedule.start_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Bid Modifier",
                "id": "campaign_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "campaign_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Latitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.latitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Longitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.longitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius",
                "id": "campaign_criterion.proximity.radius",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "campaign_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Id",
                "id": "carrier_constant.id",
                "dataType": "INTEGER",
                "group": "Carrier Constant"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "language_constant.id",
                "dataType": "INTEGER",
                "group": "Language Constant"
            },
            {
                "name": "Id",
                "id": "mobile_app_category_constant.id",
                "dataType": "INTEGER",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Id",
                "id": "mobile_device_constant.id",
                "dataType": "INTEGER",
                "group": "Mobile Device Constant"
            },
            {
                "name": "Id",
                "id": "operating_system_version_constant.id",
                "dataType": "INTEGER",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Os Major Version",
                "id": "operating_system_version_constant.os_major_version",
                "dataType": "INTEGER",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Os Minor Version",
                "id": "operating_system_version_constant.os_minor_version",
                "dataType": "INTEGER",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Id",
                "id": "topic_constant.id",
                "dataType": "INTEGER",
                "group": "Topic Constant"
            },
            {
                "name": "User Interest Id",
                "id": "user_interest.user_interest_id",
                "dataType": "INTEGER",
                "group": "User Interest"
            },
            {
                "name": "Id",
                "id": "user_list.id",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Match Rate Percentage",
                "id": "user_list.match_rate_percentage",
                "dataType": "PERCENT",
                "group": "User List"
            },
            {
                "name": "Membership Life Span",
                "id": "user_list.membership_life_span",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Display",
                "id": "user_list.size_for_display",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Search",
                "id": "user_list.size_for_search",
                "dataType": "INTEGER",
                "group": "User List"
            }
        ],
        "campaign_criterion_simulation": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Ad Schedule End Hour",
                "id": "campaign_criterion.ad_schedule.end_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Hour",
                "id": "campaign_criterion.ad_schedule.start_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Bid Modifier",
                "id": "campaign_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "campaign_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Latitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.latitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Longitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.longitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius",
                "id": "campaign_criterion.proximity.radius",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "campaign_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Campaign Id",
                "id": "campaign_criterion_simulation.campaign_id",
                "dataType": "INTEGER",
                "group": "Campaign Criterion Simulation"
            },
            {
                "name": "Criterion Id",
                "id": "campaign_criterion_simulation.criterion_id",
                "dataType": "INTEGER",
                "group": "Campaign Criterion Simulation"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "campaign_customizer": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customizer_attribute.id",
                "dataType": "INTEGER",
                "group": "Customizer Attribute"
            }
        ],
        "campaign_draft": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Draft Id",
                "id": "campaign_draft.draft_id",
                "dataType": "INTEGER",
                "group": "Campaign Draft"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "campaign_extension_setting": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "campaign_feed": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            }
        ],
        "campaign_group": [
            {
                "name": "Id",
                "id": "campaign_group.id",
                "dataType": "INTEGER",
                "group": "Campaign Group"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Hour",
                "id": "segments.hour",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "campaign_label": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "label.id",
                "dataType": "INTEGER",
                "group": "Label"
            }
        ],
        "campaign_shared_set": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "shared_set.id",
                "dataType": "INTEGER",
                "group": "Shared Set"
            },
            {
                "name": "Member Count",
                "id": "shared_set.member_count",
                "dataType": "INTEGER",
                "group": "Shared Set"
            },
            {
                "name": "Reference Count",
                "id": "shared_set.reference_count",
                "dataType": "INTEGER",
                "group": "Shared Set"
            }
        ],
        "campaign_simulation": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Campaign Id",
                "id": "campaign_simulation.campaign_id",
                "dataType": "INTEGER",
                "group": "Campaign Simulation"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "carrier_constant": [
            {
                "name": "Id",
                "id": "carrier_constant.id",
                "dataType": "INTEGER",
                "group": "Carrier Constant"
            }
        ],
        "change_event": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed_item.id",
                "dataType": "INTEGER",
                "group": "Feed Item"
            }
        ],
        "change_status": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed_item.id",
                "dataType": "INTEGER",
                "group": "Feed Item"
            },
            {
                "name": "Id",
                "id": "shared_set.id",
                "dataType": "INTEGER",
                "group": "Shared Set"
            },
            {
                "name": "Member Count",
                "id": "shared_set.member_count",
                "dataType": "INTEGER",
                "group": "Shared Set"
            },
            {
                "name": "Reference Count",
                "id": "shared_set.reference_count",
                "dataType": "INTEGER",
                "group": "Shared Set"
            }
        ],
        "click_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Page Number",
                "id": "click_view.page_number",
                "dataType": "INTEGER",
                "group": "Click View"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "combined_audience": [
            {
                "name": "Id",
                "id": "combined_audience.id",
                "dataType": "INTEGER",
                "group": "Combined Audience"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "conversion_action": [
            {
                "name": "Click Through Lookback Window Days",
                "id": "conversion_action.click_through_lookback_window_days",
                "dataType": "INTEGER",
                "group": "Conversion Action"
            },
            {
                "name": "Firebase Settings Property Id",
                "id": "conversion_action.firebase_settings.property_id",
                "dataType": "INTEGER",
                "group": "Conversion Action"
            },
            {
                "name": "Id",
                "id": "conversion_action.id",
                "dataType": "INTEGER",
                "group": "Conversion Action"
            },
            {
                "name": "Phone Call Duration Seconds",
                "id": "conversion_action.phone_call_duration_seconds",
                "dataType": "INTEGER",
                "group": "Conversion Action"
            },
            {
                "name": "Value Settings Default Value",
                "id": "conversion_action.value_settings.default_value",
                "dataType": "FLOAT",
                "group": "Conversion Action"
            },
            {
                "name": "View Through Lookback Window Days",
                "id": "conversion_action.view_through_lookback_window_days",
                "dataType": "INTEGER",
                "group": "Conversion Action"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            }
        ],
        "conversion_custom_variable": [
            {
                "name": "Id",
                "id": "conversion_custom_variable.id",
                "dataType": "INTEGER",
                "group": "Conversion Custom Variable"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "conversion_goal_campaign_config": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "custom_conversion_goal.id",
                "dataType": "INTEGER",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "conversion_value_rule": [
            {
                "name": "Action Value",
                "id": "conversion_value_rule.action.value",
                "dataType": "FLOAT",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Id",
                "id": "conversion_value_rule.id",
                "dataType": "INTEGER",
                "group": "Conversion Value Rule"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "conversion_value_rule_set": [
            {
                "name": "Id",
                "id": "conversion_value_rule_set.id",
                "dataType": "INTEGER",
                "group": "Conversion Value Rule Set"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "currency_constant": [
            {
                "name": "Billable Unit Micros",
                "id": "currency_constant.billable_unit_micros",
                "dataType": "INTEGER",
                "group": "Currency Constant"
            }
        ],
        "custom_audience": [
            {
                "name": "Id",
                "id": "custom_audience.id",
                "dataType": "INTEGER",
                "group": "Custom Audience"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "custom_conversion_goal": [
            {
                "name": "Id",
                "id": "custom_conversion_goal.id",
                "dataType": "INTEGER",
                "group": "Custom Conversion Goal"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "custom_interest": [
            {
                "name": "Id",
                "id": "custom_interest.id",
                "dataType": "INTEGER",
                "group": "Custom Interest"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "customer": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Hour",
                "id": "segments.hour",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Sk Ad Network Conversion Value",
                "id": "segments.sk_ad_network_conversion_value",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions By Conversion Date",
                "id": "metrics.all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Click To Call",
                "id": "metrics.all_conversions_from_location_asset_click_to_call",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Directions",
                "id": "metrics.all_conversions_from_location_asset_directions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Menu",
                "id": "metrics.all_conversions_from_location_asset_menu",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Order",
                "id": "metrics.all_conversions_from_location_asset_order",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Other Engagement",
                "id": "metrics.all_conversions_from_location_asset_other_engagement",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Store Visits",
                "id": "metrics.all_conversions_from_location_asset_store_visits",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Website",
                "id": "metrics.all_conversions_from_location_asset_website",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value By Conversion Date",
                "id": "metrics.all_conversions_value_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Absolute Top Impression Percentage",
                "id": "metrics.auction_insight_search_absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Impression Share",
                "id": "metrics.auction_insight_search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Outranking Share",
                "id": "metrics.auction_insight_search_outranking_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Overlap Rate",
                "id": "metrics.auction_insight_search_overlap_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Position Above Rate",
                "id": "metrics.auction_insight_search_position_above_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Top Impression Percentage",
                "id": "metrics.auction_insight_search_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Content Budget Lost Impression Share",
                "id": "metrics.content_budget_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Content Impression Share",
                "id": "metrics.content_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Content Rank Lost Impression Share",
                "id": "metrics.content_rank_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions By Conversion Date",
                "id": "metrics.conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value By Conversion Date",
                "id": "metrics.conversions_value_by_conversion_date",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Eligible Impressions From Location Asset Store Reach",
                "id": "metrics.eligible_impressions_from_location_asset_store_reach",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Invalid Click Rate",
                "id": "metrics.invalid_click_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Invalid Clicks",
                "id": "metrics.invalid_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Optimization Score Uplift",
                "id": "metrics.optimization_score_uplift",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Impression Share",
                "id": "metrics.search_budget_lost_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Exact Match Impression Share",
                "id": "metrics.search_exact_match_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Impression Share",
                "id": "metrics.search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Impression Share",
                "id": "metrics.search_rank_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Sk Ad Network Conversions",
                "id": "metrics.sk_ad_network_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions By Conversion Date",
                "id": "metrics.value_per_all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversions By Conversion Date",
                "id": "metrics.value_per_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Click To Call",
                "id": "metrics.view_through_conversions_from_location_asset_click_to_call",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Directions",
                "id": "metrics.view_through_conversions_from_location_asset_directions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Menu",
                "id": "metrics.view_through_conversions_from_location_asset_menu",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Order",
                "id": "metrics.view_through_conversions_from_location_asset_order",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Other Engagement",
                "id": "metrics.view_through_conversions_from_location_asset_other_engagement",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Store Visits",
                "id": "metrics.view_through_conversions_from_location_asset_store_visits",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Website",
                "id": "metrics.view_through_conversions_from_location_asset_website",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Gads_cost_mic",
                "id": "22f68376-6973-472e-8c37-454ec30ff9a5",
                "dataType": "Number",
                "group": "Customized"
            },
            {
                "name": "G_customer",
                "id": "8c09129d-f624-4ae4-9ea0-2f6ba51cbf54",
                "dataType": "Number",
                "group": "Customized"
            }
        ],
        "customer_asset": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Calls",
                "id": "metrics.phone_calls",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Impressions",
                "id": "metrics.phone_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Through Rate",
                "id": "metrics.phone_through_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "customer_asset_set": [
            {
                "name": "Id",
                "id": "asset_set.id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Location Set Business Profile Location Set Listing Id Filters",
                "id": "asset_set.location_set.business_profile_location_set.listing_id_filters",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Merchant Center Feed Merchant Id",
                "id": "asset_set.merchant_center_feed.merchant_id",
                "dataType": "INTEGER",
                "group": "Asset Set"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "customer_client": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer_client.id",
                "dataType": "INTEGER",
                "group": "Customer Client"
            },
            {
                "name": "Level",
                "id": "customer_client.level",
                "dataType": "INTEGER",
                "group": "Customer Client"
            }
        ],
        "customer_client_link": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Manager Link Id",
                "id": "customer_client_link.manager_link_id",
                "dataType": "INTEGER",
                "group": "Customer Client Link"
            }
        ],
        "customer_conversion_goal": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "customer_customizer": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customizer_attribute.id",
                "dataType": "INTEGER",
                "group": "Customizer Attribute"
            }
        ],
        "customer_extension_setting": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "customer_feed": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            }
        ],
        "customer_label": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "customer_manager_link": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Manager Link Id",
                "id": "customer_manager_link.manager_link_id",
                "dataType": "INTEGER",
                "group": "Customer Manager Link"
            }
        ],
        "customer_negative_criterion": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer_negative_criterion.id",
                "dataType": "INTEGER",
                "group": "Customer Negative Criterion"
            },
            {
                "name": "Id",
                "id": "mobile_app_category_constant.id",
                "dataType": "INTEGER",
                "group": "Mobile App Category Constant"
            }
        ],
        "customer_user_access": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "User Id",
                "id": "customer_user_access.user_id",
                "dataType": "INTEGER",
                "group": "Customer User Access"
            }
        ],
        "customer_user_access_invitation": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Invitation Id",
                "id": "customer_user_access_invitation.invitation_id",
                "dataType": "INTEGER",
                "group": "Customer User Access Invitation"
            }
        ],
        "customizer_attribute": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customizer_attribute.id",
                "dataType": "INTEGER",
                "group": "Customizer Attribute"
            }
        ],
        "detail_placement_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "detailed_demographic": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "detailed_demographic.id",
                "dataType": "INTEGER",
                "group": "Detailed Demographic"
            }
        ],
        "display_keyword_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "distance_view": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "domain_category": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Category Rank",
                "id": "domain_category.category_rank",
                "dataType": "INTEGER",
                "group": "Domain Category"
            },
            {
                "name": "Coverage Fraction",
                "id": "domain_category.coverage_fraction",
                "dataType": "FLOAT",
                "group": "Domain Category"
            },
            {
                "name": "Recommended Cpc Bid Micros",
                "id": "domain_category.recommended_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Domain Category"
            }
        ],
        "dynamic_search_ads_search_term_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            }
        ],
        "expanded_landing_page_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Value Per Interaction",
                "id": "metrics.conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value Per Cost",
                "id": "metrics.conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Mobile Friendly Clicks Percentage",
                "id": "metrics.mobile_friendly_clicks_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Speed Score",
                "id": "metrics.speed_score",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Valid Accelerated Mobile Pages Clicks Percentage",
                "id": "metrics.valid_accelerated_mobile_pages_clicks_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "experiment": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Experiment Id",
                "id": "experiment.experiment_id",
                "dataType": "INTEGER",
                "group": "Experiment"
            }
        ],
        "experiment_arm": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Experiment Id",
                "id": "experiment.experiment_id",
                "dataType": "INTEGER",
                "group": "Experiment"
            }
        ],
        "extension_feed_item": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Item Chain Id",
                "id": "extension_feed_item.affiliate_location_feed_item.chain_id",
                "dataType": "INTEGER",
                "group": "Extension Feed Item"
            },
            {
                "name": "Id",
                "id": "extension_feed_item.id",
                "dataType": "INTEGER",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Money Amount Off Amount Micros",
                "id": "extension_feed_item.promotion_feed_item.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Orders Over Amount Amount Micros",
                "id": "extension_feed_item.promotion_feed_item.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Extension Feed Item"
            },
            {
                "name": "Promotion Feed Item Percent Off",
                "id": "extension_feed_item.promotion_feed_item.percent_off",
                "dataType": "INTEGER",
                "group": "Extension Feed Item"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Click To Call",
                "id": "metrics.all_conversions_from_click_to_call",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Directions",
                "id": "metrics.all_conversions_from_directions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Menu",
                "id": "metrics.all_conversions_from_menu",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Order",
                "id": "metrics.all_conversions_from_order",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Other Engagement",
                "id": "metrics.all_conversions_from_other_engagement",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Store Visit",
                "id": "metrics.all_conversions_from_store_visit",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Store Website",
                "id": "metrics.all_conversions_from_store_website",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Message Chat Rate",
                "id": "metrics.message_chat_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Message Chats",
                "id": "metrics.message_chats",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Message Impressions",
                "id": "metrics.message_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Calls",
                "id": "metrics.phone_calls",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Impressions",
                "id": "metrics.phone_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Phone Through Rate",
                "id": "metrics.phone_through_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "feed": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            }
        ],
        "feed_item": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Id",
                "id": "ad_group_ad.ad.id",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Height",
                "id": "ad_group_ad.ad.image_ad.pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Width",
                "id": "ad_group_ad.ad.image_ad.pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Height",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Width",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed_item.id",
                "dataType": "INTEGER",
                "group": "Feed Item"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "feed_item_set": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Dynamic Affiliate Location Set Filter Chain Ids",
                "id": "feed_item_set.dynamic_affiliate_location_set_filter.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed Item Set"
            },
            {
                "name": "Feed Item Set Id",
                "id": "feed_item_set.feed_item_set_id",
                "dataType": "INTEGER",
                "group": "Feed Item Set"
            }
        ],
        "feed_item_set_link": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed_item.id",
                "dataType": "INTEGER",
                "group": "Feed Item"
            },
            {
                "name": "Dynamic Affiliate Location Set Filter Chain Ids",
                "id": "feed_item_set.dynamic_affiliate_location_set_filter.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed Item Set"
            },
            {
                "name": "Feed Item Set Id",
                "id": "feed_item_set.feed_item_set_id",
                "dataType": "INTEGER",
                "group": "Feed Item Set"
            }
        ],
        "feed_item_target": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed_item.id",
                "dataType": "INTEGER",
                "group": "Feed Item"
            },
            {
                "name": "Feed Item Target Id",
                "id": "feed_item_target.feed_item_target_id",
                "dataType": "INTEGER",
                "group": "Feed Item Target"
            }
        ],
        "feed_mapping": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Affiliate Location Feed Data Chain Ids",
                "id": "feed.affiliate_location_feed_data.chain_ids",
                "dataType": "INTEGER",
                "group": "Feed"
            },
            {
                "name": "Id",
                "id": "feed.id",
                "dataType": "INTEGER",
                "group": "Feed"
            }
        ],
        "feed_placeholder_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Id",
                "id": "ad_group_ad.ad.id",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Height",
                "id": "ad_group_ad.ad.image_ad.pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Width",
                "id": "ad_group_ad.ad.image_ad.pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Height",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Width",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "gender_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "geo_target_constant": [
            {
                "name": "Id",
                "id": "geo_target_constant.id",
                "dataType": "INTEGER",
                "group": "Geo Target Constant"
            }
        ],
        "geographic_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Country Criterion Id",
                "id": "geographic_view.country_criterion_id",
                "dataType": "INTEGER",
                "group": "Geographic View"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "group_placement_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "hotel_group_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Hour",
                "id": "segments.hour",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Value Per Interaction",
                "id": "metrics.conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value Per Cost",
                "id": "metrics.conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Hotel Average Lead Value Micros",
                "id": "metrics.hotel_average_lead_value_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Hotel Eligible Impressions",
                "id": "metrics.hotel_eligible_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Search Absolute Top Impression Share",
                "id": "metrics.search_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Absolute Top Impression Share",
                "id": "metrics.search_budget_lost_absolute_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Impression Share",
                "id": "metrics.search_budget_lost_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Top Impression Share",
                "id": "metrics.search_budget_lost_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Click Share",
                "id": "metrics.search_click_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Impression Share",
                "id": "metrics.search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Absolute Top Impression Share",
                "id": "metrics.search_rank_lost_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Impression Share",
                "id": "metrics.search_rank_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Top Impression Share",
                "id": "metrics.search_rank_lost_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Top Impression Share",
                "id": "metrics.search_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            }
        ],
        "hotel_performance_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Booking Window Days",
                "id": "segments.hotel_booking_window_days",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Hotel Center Id",
                "id": "segments.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Hotel Class",
                "id": "segments.hotel_class",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Hotel Length Of Stay",
                "id": "segments.hotel_length_of_stay",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Hour",
                "id": "segments.hour",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Value Per Interaction",
                "id": "metrics.conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value Per Cost",
                "id": "metrics.conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Hotel Average Lead Value Micros",
                "id": "metrics.hotel_average_lead_value_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Hotel Eligible Impressions",
                "id": "metrics.hotel_eligible_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Hotel Price Difference Percentage",
                "id": "metrics.hotel_price_difference_percentage",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Search Absolute Top Impression Share",
                "id": "metrics.search_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Absolute Top Impression Share",
                "id": "metrics.search_budget_lost_absolute_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Impression Share",
                "id": "metrics.search_budget_lost_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Top Impression Share",
                "id": "metrics.search_budget_lost_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Click Share",
                "id": "metrics.search_click_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Impression Share",
                "id": "metrics.search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Absolute Top Impression Share",
                "id": "metrics.search_rank_lost_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Impression Share",
                "id": "metrics.search_rank_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Top Impression Share",
                "id": "metrics.search_rank_lost_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Top Impression Share",
                "id": "metrics.search_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            }
        ],
        "hotel_reconciliation": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Hotel Center Id",
                "id": "hotel_reconciliation.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Reconciled Value Micros",
                "id": "hotel_reconciliation.reconciled_value_micros",
                "dataType": "INTEGER",
                "group": "Hotel Reconciliation"
            },
            {
                "name": "Hotel Commission Rate Micros",
                "id": "metrics.hotel_commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Hotel Expected Commission Cost",
                "id": "metrics.hotel_expected_commission_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversions By Conversion Date",
                "id": "metrics.value_per_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            }
        ],
        "income_range_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "keyword_plan": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "keyword_plan.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan"
            }
        ],
        "keyword_plan_ad_group": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "keyword_plan.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "keyword_plan_ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Id",
                "id": "keyword_plan_ad_group.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "keyword_plan_campaign.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Id",
                "id": "keyword_plan_campaign.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan Campaign"
            }
        ],
        "keyword_plan_ad_group_keyword": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "keyword_plan.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "keyword_plan_ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Id",
                "id": "keyword_plan_ad_group.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan Ad Group"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "keyword_plan_ad_group_keyword.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Keyword Plan Ad Group Keyword"
            },
            {
                "name": "Id",
                "id": "keyword_plan_ad_group_keyword.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan Ad Group Keyword"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "keyword_plan_campaign.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Id",
                "id": "keyword_plan_campaign.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan Campaign"
            }
        ],
        "keyword_plan_campaign": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "keyword_plan.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "keyword_plan_campaign.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Id",
                "id": "keyword_plan_campaign.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan Campaign"
            }
        ],
        "keyword_plan_campaign_keyword": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "keyword_plan.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "keyword_plan_campaign.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Id",
                "id": "keyword_plan_campaign.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan Campaign"
            },
            {
                "name": "Id",
                "id": "keyword_plan_campaign_keyword.id",
                "dataType": "INTEGER",
                "group": "Keyword Plan Campaign Keyword"
            }
        ],
        "keyword_theme_constant": [],
        "keyword_view": [
            {
                "name": "Id",
                "id": "accessible_bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "accessible_bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "accessible_bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Customer Id",
                "id": "accessible_bidding_strategy.owner_customer_id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "accessible_bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "accessible_bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "accessible_bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "accessible_bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions By Conversion Date",
                "id": "metrics.all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value By Conversion Date",
                "id": "metrics.all_conversions_value_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Absolute Top Impression Percentage",
                "id": "metrics.auction_insight_search_absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Impression Share",
                "id": "metrics.auction_insight_search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Outranking Share",
                "id": "metrics.auction_insight_search_outranking_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Overlap Rate",
                "id": "metrics.auction_insight_search_overlap_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Position Above Rate",
                "id": "metrics.auction_insight_search_position_above_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Auction Insight Search Top Impression Percentage",
                "id": "metrics.auction_insight_search_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Page Views",
                "id": "metrics.average_page_views",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Time On Site",
                "id": "metrics.average_time_on_site",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Bounce Rate",
                "id": "metrics.bounce_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions By Conversion Date",
                "id": "metrics.conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value By Conversion Date",
                "id": "metrics.conversions_value_by_conversion_date",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value Per Cost",
                "id": "metrics.conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Current Model Attributed Conversion",
                "id": "metrics.cost_per_current_model_attributed_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions",
                "id": "metrics.current_model_attributed_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions Value",
                "id": "metrics.current_model_attributed_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Historical Quality Score",
                "id": "metrics.historical_quality_score",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Percent New Visitors",
                "id": "metrics.percent_new_visitors",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Absolute Top Impression Share",
                "id": "metrics.search_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Absolute Top Impression Share",
                "id": "metrics.search_budget_lost_absolute_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Budget Lost Top Impression Share",
                "id": "metrics.search_budget_lost_top_impression_share",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Search Click Share",
                "id": "metrics.search_click_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Exact Match Impression Share",
                "id": "metrics.search_exact_match_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Impression Share",
                "id": "metrics.search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Absolute Top Impression Share",
                "id": "metrics.search_rank_lost_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Impression Share",
                "id": "metrics.search_rank_lost_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Rank Lost Top Impression Share",
                "id": "metrics.search_rank_lost_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Top Impression Share",
                "id": "metrics.search_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions By Conversion Date",
                "id": "metrics.value_per_all_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversions By Conversion Date",
                "id": "metrics.value_per_conversions_by_conversion_date",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Current Model Attributed Conversion",
                "id": "metrics.value_per_current_model_attributed_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "label": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "label.id",
                "dataType": "INTEGER",
                "group": "Label"
            }
        ],
        "landing_page_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Value Per Interaction",
                "id": "metrics.conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value Per Cost",
                "id": "metrics.conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Mobile Friendly Clicks Percentage",
                "id": "metrics.mobile_friendly_clicks_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Speed Score",
                "id": "metrics.speed_score",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Valid Accelerated Mobile Pages Clicks Percentage",
                "id": "metrics.valid_accelerated_mobile_pages_clicks_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "language_constant": [
            {
                "name": "Id",
                "id": "language_constant.id",
                "dataType": "INTEGER",
                "group": "Language Constant"
            }
        ],
        "lead_form_submission_data": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Id",
                "id": "ad_group_ad.ad.id",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Height",
                "id": "ad_group_ad.ad.image_ad.pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Width",
                "id": "ad_group_ad.ad.image_ad.pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Height",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Width",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Dynamic Custom Asset Ios App Store Id",
                "id": "asset.dynamic_custom_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Education Asset Ios App Store Id",
                "id": "asset.dynamic_education_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Flights Asset Ios App Store Id",
                "id": "asset.dynamic_flights_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Ios App Store Id",
                "id": "asset.dynamic_hotels_and_rentals_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Hotels And Rentals Asset Star Rating",
                "id": "asset.dynamic_hotels_and_rentals_asset.star_rating",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Jobs Asset Ios App Store Id",
                "id": "asset.dynamic_jobs_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Local Asset Ios App Store Id",
                "id": "asset.dynamic_local_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Real Estate Asset Ios App Store Id",
                "id": "asset.dynamic_real_estate_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Dynamic Travel Asset Ios App Store Id",
                "id": "asset.dynamic_travel_asset.ios_app_store_id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Id",
                "id": "asset.id",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset File Size",
                "id": "asset.image_asset.file_size",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Height Pixels",
                "id": "asset.image_asset.full_size.height_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Image Asset Full Size Width Pixels",
                "id": "asset.image_asset.full_size.width_pixels",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Money Amount Off Amount Micros",
                "id": "asset.promotion_asset.money_amount_off.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Orders Over Amount Amount Micros",
                "id": "asset.promotion_asset.orders_over_amount.amount_micros",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Promotion Asset Percent Off",
                "id": "asset.promotion_asset.percent_off",
                "dataType": "INTEGER",
                "group": "Asset"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "life_event": [
            {
                "name": "Id",
                "id": "life_event.id",
                "dataType": "INTEGER",
                "group": "Life Event"
            }
        ],
        "location_view": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Ad Schedule End Hour",
                "id": "campaign_criterion.ad_schedule.end_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Ad Schedule Start Hour",
                "id": "campaign_criterion.ad_schedule.start_hour",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Bid Modifier",
                "id": "campaign_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "campaign_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Latitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.latitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Geo Point Longitude In Micro Degrees",
                "id": "campaign_criterion.proximity.geo_point.longitude_in_micro_degrees",
                "dataType": "INTEGER",
                "group": "Campaign Criterion"
            },
            {
                "name": "Proximity Radius",
                "id": "campaign_criterion.proximity.radius",
                "dataType": "FLOAT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "campaign_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Campaign Criterion"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "managed_placement_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "media_file": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Audio Ad Duration Millis",
                "id": "media_file.audio.ad_duration_millis",
                "dataType": "INTEGER",
                "group": "Media File"
            },
            {
                "name": "File Size",
                "id": "media_file.file_size",
                "dataType": "INTEGER",
                "group": "Media File"
            },
            {
                "name": "Id",
                "id": "media_file.id",
                "dataType": "INTEGER",
                "group": "Media File"
            },
            {
                "name": "Video Ad Duration Millis",
                "id": "media_file.video.ad_duration_millis",
                "dataType": "INTEGER",
                "group": "Media File"
            }
        ],
        "mobile_app_category_constant": [
            {
                "name": "Id",
                "id": "mobile_app_category_constant.id",
                "dataType": "INTEGER",
                "group": "Mobile App Category Constant"
            }
        ],
        "mobile_device_constant": [
            {
                "name": "Id",
                "id": "mobile_device_constant.id",
                "dataType": "INTEGER",
                "group": "Mobile Device Constant"
            }
        ],
        "offline_user_data_job": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "External Id",
                "id": "offline_user_data_job.external_id",
                "dataType": "INTEGER",
                "group": "Offline User Data Job"
            },
            {
                "name": "Id",
                "id": "offline_user_data_job.id",
                "dataType": "INTEGER",
                "group": "Offline User Data Job"
            },
            {
                "name": "Store Sales Metadata Loyalty Fraction",
                "id": "offline_user_data_job.store_sales_metadata.loyalty_fraction",
                "dataType": "FLOAT",
                "group": "Offline User Data Job"
            },
            {
                "name": "Store Sales Metadata Third Party Metadata Partner Id",
                "id": "offline_user_data_job.store_sales_metadata.third_party_metadata.partner_id",
                "dataType": "INTEGER",
                "group": "Offline User Data Job"
            },
            {
                "name": "Store Sales Metadata Third Party Metadata Partner Match Fraction",
                "id": "offline_user_data_job.store_sales_metadata.third_party_metadata.partner_match_fraction",
                "dataType": "FLOAT",
                "group": "Offline User Data Job"
            },
            {
                "name": "Store Sales Metadata Third Party Metadata Partner Upload Fraction",
                "id": "offline_user_data_job.store_sales_metadata.third_party_metadata.partner_upload_fraction",
                "dataType": "FLOAT",
                "group": "Offline User Data Job"
            },
            {
                "name": "Store Sales Metadata Third Party Metadata Valid Transaction Fraction",
                "id": "offline_user_data_job.store_sales_metadata.third_party_metadata.valid_transaction_fraction",
                "dataType": "FLOAT",
                "group": "Offline User Data Job"
            },
            {
                "name": "Store Sales Metadata Transaction Upload Fraction",
                "id": "offline_user_data_job.store_sales_metadata.transaction_upload_fraction",
                "dataType": "FLOAT",
                "group": "Offline User Data Job"
            },
            {
                "name": "Id",
                "id": "user_list.id",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Match Rate Percentage",
                "id": "user_list.match_rate_percentage",
                "dataType": "PERCENT",
                "group": "User List"
            },
            {
                "name": "Membership Life Span",
                "id": "user_list.membership_life_span",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Display",
                "id": "user_list.size_for_display",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Search",
                "id": "user_list.size_for_search",
                "dataType": "INTEGER",
                "group": "User List"
            }
        ],
        "operating_system_version_constant": [
            {
                "name": "Id",
                "id": "operating_system_version_constant.id",
                "dataType": "INTEGER",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Os Major Version",
                "id": "operating_system_version_constant.os_major_version",
                "dataType": "INTEGER",
                "group": "Operating System Version Constant"
            },
            {
                "name": "Os Minor Version",
                "id": "operating_system_version_constant.os_minor_version",
                "dataType": "INTEGER",
                "group": "Operating System Version Constant"
            }
        ],
        "paid_organic_search_term_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Combined Clicks",
                "id": "metrics.combined_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Combined Clicks Per Query",
                "id": "metrics.combined_clicks_per_query",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Combined Queries",
                "id": "metrics.combined_queries",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Organic Clicks",
                "id": "metrics.organic_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Organic Clicks Per Query",
                "id": "metrics.organic_clicks_per_query",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Organic Impressions",
                "id": "metrics.organic_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Organic Impressions Per Query",
                "id": "metrics.organic_impressions_per_query",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Organic Queries",
                "id": "metrics.organic_queries",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "parental_status_view": [
            {
                "name": "Id",
                "id": "accessible_bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "accessible_bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "accessible_bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Owner Customer Id",
                "id": "accessible_bidding_strategy.owner_customer_id",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "accessible_bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "accessible_bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "accessible_bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "accessible_bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "accessible_bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Accessible Bidding Strategy"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "per_store_view": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions From Location Asset Click To Call",
                "id": "metrics.all_conversions_from_location_asset_click_to_call",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Directions",
                "id": "metrics.all_conversions_from_location_asset_directions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Menu",
                "id": "metrics.all_conversions_from_location_asset_menu",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Order",
                "id": "metrics.all_conversions_from_location_asset_order",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Other Engagement",
                "id": "metrics.all_conversions_from_location_asset_other_engagement",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Store Visits",
                "id": "metrics.all_conversions_from_location_asset_store_visits",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Location Asset Website",
                "id": "metrics.all_conversions_from_location_asset_website",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Eligible Impressions From Location Asset Store Reach",
                "id": "metrics.eligible_impressions_from_location_asset_store_reach",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Click To Call",
                "id": "metrics.view_through_conversions_from_location_asset_click_to_call",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Directions",
                "id": "metrics.view_through_conversions_from_location_asset_directions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Menu",
                "id": "metrics.view_through_conversions_from_location_asset_menu",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Order",
                "id": "metrics.view_through_conversions_from_location_asset_order",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Other Engagement",
                "id": "metrics.view_through_conversions_from_location_asset_other_engagement",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Store Visits",
                "id": "metrics.view_through_conversions_from_location_asset_store_visits",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions From Location Asset Website",
                "id": "metrics.view_through_conversions_from_location_asset_website",
                "dataType": "FLOAT",
                "group": "Metrics"
            }
        ],
        "product_bidding_category_constant": [
            {
                "name": "Id",
                "id": "product_bidding_category_constant.id",
                "dataType": "INTEGER",
                "group": "Product Bidding Category Constant"
            }
        ],
        "product_group_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Benchmark Average Max Cpc",
                "id": "metrics.benchmark_average_max_cpc",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Benchmark Ctr",
                "id": "metrics.benchmark_ctr",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Search Absolute Top Impression Share",
                "id": "metrics.search_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Click Share",
                "id": "metrics.search_click_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Impression Share",
                "id": "metrics.search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "recommendation": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Aligned Bidding Strategy Id",
                "id": "campaign_budget.aligned_bidding_strategy_id",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Amount Micros",
                "id": "campaign_budget.amount_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Id",
                "id": "campaign_budget.id",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Amount Micros",
                "id": "campaign_budget.recommended_budget_amount_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Clicks",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_clicks",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Cost Micros",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_cost_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Interactions",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_interactions",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Recommended Budget Estimated Change Weekly Views",
                "id": "campaign_budget.recommended_budget_estimated_change_weekly_views",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Reference Count",
                "id": "campaign_budget.reference_count",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Total Amount Micros",
                "id": "campaign_budget.total_amount_micros",
                "dataType": "INTEGER",
                "group": "Campaign Budget"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "remarketing_action": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "remarketing_action.id",
                "dataType": "INTEGER",
                "group": "Remarketing Action"
            }
        ],
        "search_term_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Id",
                "id": "ad_group_ad.ad.id",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Height",
                "id": "ad_group_ad.ad.image_ad.pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Width",
                "id": "ad_group_ad.ad.image_ad.pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Height",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Width",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Value Per Interaction",
                "id": "metrics.conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value Per Cost",
                "id": "metrics.conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "shared_criterion": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "mobile_app_category_constant.id",
                "dataType": "INTEGER",
                "group": "Mobile App Category Constant"
            },
            {
                "name": "Criterion Id",
                "id": "shared_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Shared Criterion"
            },
            {
                "name": "Id",
                "id": "shared_set.id",
                "dataType": "INTEGER",
                "group": "Shared Set"
            },
            {
                "name": "Member Count",
                "id": "shared_set.member_count",
                "dataType": "INTEGER",
                "group": "Shared Set"
            },
            {
                "name": "Reference Count",
                "id": "shared_set.reference_count",
                "dataType": "INTEGER",
                "group": "Shared Set"
            }
        ],
        "shared_set": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "shared_set.id",
                "dataType": "INTEGER",
                "group": "Shared Set"
            },
            {
                "name": "Member Count",
                "id": "shared_set.member_count",
                "dataType": "INTEGER",
                "group": "Shared Set"
            },
            {
                "name": "Reference Count",
                "id": "shared_set.reference_count",
                "dataType": "INTEGER",
                "group": "Shared Set"
            }
        ],
        "shopping_performance_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Product Aggregator Id",
                "id": "segments.product_aggregator_id",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Product Merchant Id",
                "id": "segments.product_merchant_id",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Search Absolute Top Impression Share",
                "id": "metrics.search_absolute_top_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Click Share",
                "id": "metrics.search_click_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Search Impression Share",
                "id": "metrics.search_impression_share",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            }
        ],
        "smart_campaign_search_term_view": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "smart_campaign_setting": [
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "third_party_app_analytics_link": [
            {
                "name": "Account Link Id",
                "id": "account_link.account_link_id",
                "dataType": "INTEGER",
                "group": "Account Link"
            },
            {
                "name": "Data Partner Data Partner Id",
                "id": "account_link.data_partner.data_partner_id",
                "dataType": "INTEGER",
                "group": "Account Link"
            },
            {
                "name": "Hotel Center Hotel Center Id",
                "id": "account_link.hotel_center.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Account Link"
            },
            {
                "name": "Third Party App Analytics App Analytics Provider Id",
                "id": "account_link.third_party_app_analytics.app_analytics_provider_id",
                "dataType": "INTEGER",
                "group": "Account Link"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            }
        ],
        "topic_constant": [
            {
                "name": "Id",
                "id": "topic_constant.id",
                "dataType": "INTEGER",
                "group": "Topic Constant"
            }
        ],
        "topic_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Aligned Campaign Budget Id",
                "id": "bidding_strategy.aligned_campaign_budget_id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Campaign Count",
                "id": "bidding_strategy.campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Id",
                "id": "bidding_strategy.id",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversion_value.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "bidding_strategy.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Cpc Bid Floor Micros",
                "id": "bidding_strategy.maximize_conversions.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "bidding_strategy.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Non Removed Campaign Count",
                "id": "bidding_strategy.non_removed_campaign_count",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "bidding_strategy.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "bidding_strategy.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "bidding_strategy.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "bidding_strategy.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "bidding_strategy.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "bidding_strategy.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Bidding Strategy"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Value Per Interaction",
                "id": "metrics.all_conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value Per Cost",
                "id": "metrics.all_conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Forwards",
                "id": "metrics.gmail_forwards",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Saves",
                "id": "metrics.gmail_saves",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Gmail Secondary Clicks",
                "id": "metrics.gmail_secondary_clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "user_interest": [
            {
                "name": "User Interest Id",
                "id": "user_interest.user_interest_id",
                "dataType": "INTEGER",
                "group": "User Interest"
            }
        ],
        "user_list": [
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "user_list.id",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Match Rate Percentage",
                "id": "user_list.match_rate_percentage",
                "dataType": "PERCENT",
                "group": "User List"
            },
            {
                "name": "Membership Life Span",
                "id": "user_list.membership_life_span",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Display",
                "id": "user_list.size_for_display",
                "dataType": "INTEGER",
                "group": "User List"
            },
            {
                "name": "Size For Search",
                "id": "user_list.size_for_search",
                "dataType": "INTEGER",
                "group": "User List"
            }
        ],
        "user_location_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Country Criterion Id",
                "id": "user_location_view.country_criterion_id",
                "dataType": "INTEGER",
                "group": "User Location View"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "video": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Ad Id",
                "id": "ad_group_ad.ad.id",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Height",
                "id": "ad_group_ad.ad.image_ad.pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Pixel Width",
                "id": "ad_group_ad.ad.image_ad.pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Height",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_height",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Ad Image Ad Preview Pixel Width",
                "id": "ad_group_ad.ad.image_ad.preview_pixel_width",
                "dataType": "INTEGER",
                "group": "Ad Group Ad"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Duration Millis",
                "id": "video.duration_millis",
                "dataType": "INTEGER",
                "group": "Video"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Value Per Interaction",
                "id": "metrics.conversions_from_interactions_value_per_interaction",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value Per Cost",
                "id": "metrics.conversions_value_per_cost",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P100 Rate",
                "id": "metrics.video_quartile_p100_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P25 Rate",
                "id": "metrics.video_quartile_p25_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P50 Rate",
                "id": "metrics.video_quartile_p50_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Quartile P75 Rate",
                "id": "metrics.video_quartile_p75_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video View Rate",
                "id": "metrics.video_view_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Video Views",
                "id": "metrics.video_views",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ],
        "webpage_view": [
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Cpa Micros",
                "id": "ad_group.effective_target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Effective Target Roas",
                "id": "ad_group.effective_target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Id",
                "id": "ad_group.id",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpa Micros",
                "id": "ad_group.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Cpm Micros",
                "id": "ad_group.target_cpm_micros",
                "dataType": "INTEGER",
                "group": "Ad Group"
            },
            {
                "name": "Target Roas",
                "id": "ad_group.target_roas",
                "dataType": "FLOAT",
                "group": "Ad Group"
            },
            {
                "name": "Bid Modifier",
                "id": "ad_group_criterion.bid_modifier",
                "dataType": "FLOAT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpc Bid Micros",
                "id": "ad_group_criterion.cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpm Bid Micros",
                "id": "ad_group_criterion.cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Cpv Bid Micros",
                "id": "ad_group_criterion.cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Criterion Id",
                "id": "ad_group_criterion.criterion_id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpc Bid Micros",
                "id": "ad_group_criterion.effective_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpm Bid Micros",
                "id": "ad_group_criterion.effective_cpm_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Cpv Bid Micros",
                "id": "ad_group_criterion.effective_cpv_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Effective Percent Cpc Bid Micros",
                "id": "ad_group_criterion.effective_percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Hotel Class Value",
                "id": "ad_group_criterion.listing_group.case_value.hotel_class.value",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Listing Group Case Value Product Bidding Category Id",
                "id": "ad_group_criterion.listing_group.case_value.product_bidding_category.id",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Percent Cpc Bid Micros",
                "id": "ad_group_criterion.percent_cpc_bid_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Clicks At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_clicks_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Estimated Add Cost At First Position Cpc",
                "id": "ad_group_criterion.position_estimates.estimated_add_cost_at_first_position_cpc",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates First Position Cpc Micros",
                "id": "ad_group_criterion.position_estimates.first_position_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Position Estimates Top Of Page Cpc Micros",
                "id": "ad_group_criterion.position_estimates.top_of_page_cpc_micros",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Quality Info Quality Score",
                "id": "ad_group_criterion.quality_info.quality_score",
                "dataType": "INTEGER",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Webpage Coverage Percentage",
                "id": "ad_group_criterion.webpage.coverage_percentage",
                "dataType": "PERCENT",
                "group": "Ad Group Criterion"
            },
            {
                "name": "Commission Commission Rate Micros",
                "id": "campaign.commission.commission_rate_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Hotel Setting Hotel Center Id",
                "id": "campaign.hotel_setting.hotel_center_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Id",
                "id": "campaign.id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Manual Cpm",
                "id": "campaign.manual_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversion Value Target Roas",
                "id": "campaign.maximize_conversion_value.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Maximize Conversions Target Cpa Micros",
                "id": "campaign.maximize_conversions.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Optimization Score",
                "id": "campaign.optimization_score",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Percent Cpc Cpc Bid Ceiling Micros",
                "id": "campaign.percent_cpc.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Campaign Priority",
                "id": "campaign.shopping_setting.campaign_priority",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Shopping Setting Merchant Id",
                "id": "campaign.shopping_setting.merchant_id",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Ceiling Micros",
                "id": "campaign.target_cpa.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Cpc Bid Floor Micros",
                "id": "campaign.target_cpa.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpa Target Cpa Micros",
                "id": "campaign.target_cpa.target_cpa_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Cpm",
                "id": "campaign.target_cpm",
                "dataType": "CURRENCY",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Cpc Bid Ceiling Micros",
                "id": "campaign.target_impression_share.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Impression Share Location Fraction Micros",
                "id": "campaign.target_impression_share.location_fraction_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Ceiling Micros",
                "id": "campaign.target_roas.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Cpc Bid Floor Micros",
                "id": "campaign.target_roas.cpc_bid_floor_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Roas Target Roas",
                "id": "campaign.target_roas.target_roas",
                "dataType": "FLOAT",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Cpc Bid Ceiling Micros",
                "id": "campaign.target_spend.cpc_bid_ceiling_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Target Spend Target Spend Micros",
                "id": "campaign.target_spend.target_spend_micros",
                "dataType": "INTEGER",
                "group": "Campaign"
            },
            {
                "name": "Conversion Tracking Setting Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Conversion Tracking Setting Cross Account Conversion Tracking Id",
                "id": "customer.conversion_tracking_setting.cross_account_conversion_tracking_id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Id",
                "id": "customer.id",
                "dataType": "INTEGER",
                "group": "Customer"
            },
            {
                "name": "Optimization Score",
                "id": "customer.optimization_score",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Optimization Score Weight",
                "id": "customer.optimization_score_weight",
                "dataType": "FLOAT",
                "group": "Customer"
            },
            {
                "name": "Year",
                "id": "segments.year",
                "dataType": "INTEGER",
                "group": "Segments"
            },
            {
                "name": "Absolute Top Impression Percentage",
                "id": "metrics.absolute_top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Cpm",
                "id": "metrics.active_view_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Ctr",
                "id": "metrics.active_view_ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Impressions",
                "id": "metrics.active_view_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurability",
                "id": "metrics.active_view_measurability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Cost Micros",
                "id": "metrics.active_view_measurable_cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Active View Measurable Impressions",
                "id": "metrics.active_view_measurable_impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Active View Viewability",
                "id": "metrics.active_view_viewability",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions",
                "id": "metrics.all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions From Interactions Rate",
                "id": "metrics.all_conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "All Conversions Value",
                "id": "metrics.all_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cost",
                "id": "metrics.average_cost",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpc",
                "id": "metrics.average_cpc",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpe",
                "id": "metrics.average_cpe",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpm",
                "id": "metrics.average_cpm",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Average Cpv",
                "id": "metrics.average_cpv",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Clicks",
                "id": "metrics.clicks",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Conversions",
                "id": "metrics.conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Conversions From Interactions Rate",
                "id": "metrics.conversions_from_interactions_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Conversions Value",
                "id": "metrics.conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Micros",
                "id": "metrics.cost_micros",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per All Conversions",
                "id": "metrics.cost_per_all_conversions",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Conversion",
                "id": "metrics.cost_per_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cost Per Current Model Attributed Conversion",
                "id": "metrics.cost_per_current_model_attributed_conversion",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Cross Device Conversions",
                "id": "metrics.cross_device_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Ctr",
                "id": "metrics.ctr",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions",
                "id": "metrics.current_model_attributed_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Current Model Attributed Conversions Value",
                "id": "metrics.current_model_attributed_conversions_value",
                "dataType": "CURRENCY",
                "group": "Metrics"
            },
            {
                "name": "Engagement Rate",
                "id": "metrics.engagement_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Engagements",
                "id": "metrics.engagements",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Impressions",
                "id": "metrics.impressions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Interaction Rate",
                "id": "metrics.interaction_rate",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Interactions",
                "id": "metrics.interactions",
                "dataType": "INTEGER",
                "group": "Metrics"
            },
            {
                "name": "Top Impression Percentage",
                "id": "metrics.top_impression_percentage",
                "dataType": "PERCENT",
                "group": "Metrics"
            },
            {
                "name": "Value Per All Conversions",
                "id": "metrics.value_per_all_conversions",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Conversion",
                "id": "metrics.value_per_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "Value Per Current Model Attributed Conversion",
                "id": "metrics.value_per_current_model_attributed_conversion",
                "dataType": "FLOAT",
                "group": "Metrics"
            },
            {
                "name": "View Through Conversions",
                "id": "metrics.view_through_conversions",
                "dataType": "INTEGER",
                "group": "Metrics"
            }
        ]
    },
    "currency": "USD",
    "metricsUpdated": true
}