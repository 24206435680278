import { ChannelType } from "../../components/Admin/channel.js";
export const CONNECT_ACCOUNT="CONNECT_ACCOUNT"
export const CONNECT_ACCOUNT_FAILURE="CONNECT_ACCOUNT_FAILURE";
export const CHECK_AUTH_SUCCESS="CHECK_AUTH_SUCCESS";
export const AUTHENTICATED_CHANNEL="AUTHENTICATED_CHANNEL";
export const SEND_AUTH_CODE="SEND_AUTH_CODE";
export const GET_ACCOUNTS_CHANNEL="GET_ACCOUNTS_CHANNEL";
export const REVOKE_CHANNEL="REVOKE_CHANNEL";

export const removeAuthAccountMappedApi={
    [ChannelType.GoogleAds]:"/google-auth/authenticated-account",
    [ChannelType.FacebookAds]:"/facebook-auth/authenticated-account"
}

export const authChecMappedApi={
    [ChannelType.GoogleAds]:"/google-auth/authenticated-account",
    [ChannelType.FacebookAds]:"/facebook-auth/authenticated-account"
}

export const channelAccountsList = {
    [ChannelType.GoogleAds]:"/g-ads/customers",
    [ChannelType.FacebookAds]:"/facebook-ads/ad-accounts"
}
