import { ready as readyGif } from "../../assets/icons/proposal/proposal";
import GoToNextCompoButton from "../AccountManager/GoToNextCompoButton";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import PrimaryButton from "../ButtonPrimary/Buttons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "584px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "450px",
  borderRadius: "20px",
};
const ProposalPublishedModal = ({
  formSubm,
  setFormSubm,
  title,
  description,
  buttonTitle,
  displayButton=true,
}) => {
  const dispatch = useDispatch();
  console.log('1111')
  return (
    <Modal
      open={formSubm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Close
          style={{
            color: "black",
            position: "absolute",
            right: "24px",
            top: "24px",
            cursor: "pointer",
          }}
          onClick={() => setFormSubm(!formSubm)}
        />
        <div
          id="working_proposal"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            paddingRight: "0px",
            width: "100%",
          }}
        >
          <div
            className="proposal_status"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#ffffff",
              paddingRight: "0px",
              width: "100%",
              height: "100%",
              borderRadius: "8px",
            }}
          >
            <div className="proposal_status_layout">
              <span className="setIcon">
                <img src={readyGif} />
              </span>
              <h2 className="proposal_status_title">
                {title || "Proposal Published!"}
              </h2>
              <p
                style={{
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontWeight: "400",
                  lineHeight: "25.5px",
                  color: "#6F6C90",
                }}
              >
                {description ||
                  "The proposal has been published. The client will be notified shortly. "}
              </p>
              { displayButton &&
              <PrimaryButton
                title={buttonTitle || "PROCEED"}
                onClick={() => setFormSubm(!formSubm)}
              />
              }
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default ProposalPublishedModal;
