import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Popover } from '@mui/material';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import { Calendar, DateObject } from 'react-multi-date-picker';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import './datemodal.scss';
import moment from 'moment-timezone';
import { cross_icon as crossIcon } from '../../../assets/icons/common/common';
const weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
const DateModal = (props) => {
  const style = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    minHeight: '200px',
    // padding: '10px 10px',
    background: '#FFFFFF',
    borderRadius: 2,
    padding: '1.25rem',
  };
  const [confirmModal, setConfirmModal] = React.useState(true);
  const handleConfirmClose = () => setConfirmModal(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [dateRange, setDateRange] = useState(() => [
    new DateObject(),
    new DateObject(),
  ]);
  const [tempDateRange, setTempDateRange] = useState(dateRange);
  const [todayDate] = useState(() => new DateObject());
  const dateRefined = (date) =>
    `${date?.day} ${date?.month?.name} ${date?.year}`;
  const utc = moment
    .tz(todayDate.toDate().toDateString(), props.timeZone || moment.tz.guess())
    .format()
    .slice(-6);
  const setDatePickerRange = (startDate) => {
    setTempDateRange([startDate, todayDate]);
  };
  const save = () => {
    setDateRange(tempDateRange);
    props.setStartDate(moment(tempDateRange[0].toDate()).format('YYYY-MM-DD'));
    props.setEndDate(moment(todayDate.toDate()).format('YYYY-MM-DD'));
    setIsDatePickerOpen(false);
  };
  return (
    <Modal
      open={confirmModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      // onClose={handleConfirmClose}
    >
      <Box sx={style}>
        <div
          style={{
            position: 'absolute',
            right: '1rem',
            top: 8,
            cursor: 'pointer',
          }}
          onClick={() => {
            props.setShowDateModal(false);
            setConfirmModal(false);
          }}
        >
          <img src={crossIcon} alt='' />
        </div>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 16,
            marginBottom: '0.5rem',
          }}
        >
          Select Date
        </Typography>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 12,
            marginBottom: '1rem',
          }}
        >
          Select Date
        </Typography>
        <div
          style={{
            border: '1px solid #eee',
            height: '36px',
            maxWidth: '250px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '1rem',
          }}
        >
          <Button
            style={{ width: '100%', height: '100%', textTransform: 'none' }}
            onClick={() => setIsDatePickerOpen(true)}
          >
            <div
              style={{
                display: 'flex',
                // justifyContent: 'space-around',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                gap: '0.5rem',
              }}
            >
              <CalendarTodayIcon style={{ color: '#333333', opacity: '0.8' }} />
              <Typography
                style={{ color: '#333333', opacity: '0.8' }}
                variant='div'
              >
                {moment(dateRange[0].toDate()).format('MMM D YYYY')}
              </Typography>
              <Typography
                style={{ color: '#333333', opacity: '0.8' }}
                variant='div'
              >
                -
              </Typography>
              <Typography
                style={{ color: '#333333', opacity: '0.8' }}
                variant='div'
              >
                {moment(dateRange[1].toDate()).format('MMM D YYYY')}
              </Typography>
            </div>
          </Button>
        </div>
        <Popover
          open={isDatePickerOpen}
          anchorOrigin={{
            vertical: 60,
            horizontal: 735,
          }}
          className='calendar-popover'
          // style={{
          //   width: '352px',
          //   padding: '20px 16px',
          //   background: '#ffffff',
          //   border: '0.5px solid #e5e5e5',
          //   boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.05)',
          //   borderRadius: 8,
          // }}
        >
          <>
            <Calendar
              value={tempDateRange}
              onChange={(dates) => setDatePickerRange(dates[0])}
              range
              showOtherDays={true}
              weekDays={weekDays}
              shadow={false}
              maxDate={new DateObject()}
              highlightToday={true}
            />
            <div
              style={{
                marginTop: 24,
                display: 'flex',
                gap: 12,
              }}
            >
              <div className='start-date-container'>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '16px',
                    color: '#2E3044',
                  }}
                >
                  Start Date
                </Typography>
                <div className='start-date'>
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '20px',
                      color: '#6D6E7C',
                    }}
                  >
                    {dateRefined(tempDateRange[0])}
                  </Typography>
                </div>
              </div>
              <div className='end-date-container'>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '16px',
                    color: '#2E3044',
                    opacity: 0.54,
                  }}
                >
                  End Date
                </Typography>
                <div
                  className='start-date'
                  style={{
                    opacity: 0.54,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '20px',
                      color: '#6D6E7C',
                    }}
                  >
                    {dateRefined(tempDateRange[1])}
                  </Typography>
                </div>
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'italic',
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '20px',
                    color: '#82838F',
                    marginTop: -6,
                  }}
                >
                  <span
                    style={{
                      color: '#EF5854',
                    }}
                  >
                    *
                  </span>
                  Today's date
                </Typography>
              </div>
            </div>
            <div className='time-zone-view'>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#82838F',
                }}
              >
                Timezone: {props.timeZone || moment.tz.guess()}: UTC{utc}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: 16,
              }}
            >
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: '16px',
                  textTransform: 'capitalize',
                  color: '#0869FB',
                }}
                onClick={() => setIsDatePickerOpen(false)}
              >
                Cancel
              </Button>
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: '16px',
                  textTransform: 'capitalize',
                  background: '#0869FB',
                  borderRadius: 6,
                  color: '#ffffff',
                  padding: '8px 16px',
                }}
                onClick={save}
              >
                Save
              </Button>
            </div>
          </>
        </Popover>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1rem',
          }}
        >
          <Button
            variant='text'
            style={{
              textTransform: 'capitalize',
            }}
            onClick={() => {
              props.setShowDateModal(false);
              setConfirmModal(false);
            }}
          >
            Cancel
          </Button>
          <PrimaryButton
            title='Generate Report'
            onClick={props.onClickHandler}
          />
        </div>
      </Box>
    </Modal>
  );
};

export default DateModal;
