import React from "react";
import { Drawer, IconButton, Typography, Box, Tab, Tabs } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import "../../styles/editwidget.css";
// import "../../styles/sidebar.css";
import { styled } from "@mui/material/styles";
import ChartSettings from "./ChartSettings";
import EditChart from "./EditChart";
// import GridSettings from "../EditHeader/GridSettings";
// import EditHeader from "../EditHeader/EditHeader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Sidebar({ editDrawerState, onClose, drawerwidth, handleAllChartsForceReload }) {
  const [currentTab, setCurrentTab] = React.useState(0);
  const { chart, handleChartChange } = editDrawerState;
  const [chartType, setChartType] = React.useState("");
  const [newChart, setNewChart] = React.useState(false);
  const [renderNew, setRenderNew] = React.useState(false);

  const updateChart = (newChartType) => {
    if (newChartType !== chartType) {
      handleChartChange("type", newChartType, true);
      setChartType(newChartType);
    }
    setNewChart(true);
    setRenderNew(!renderNew);
  }

  const handleChange = (event, tab) => {
    setCurrentTab(tab);
  };

  return (
    <Drawer
      data-tut="sidebar_widget"
      sx={{
        width: drawerwidth,
        left: "auto",
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerwidth,
        },
      }}
      hideBackdrop={true}
      variant="temporary"
      anchor="right"
      open={editDrawerState.isOpen}
      onClose={() => {
        setNewChart(false);
        onClose();
      }}>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          position: 'relative'
        }}>
        <IconButton
          data-tut="close_sidebar"
          onClick={() => {
            setNewChart(false);
            onClose();
          }}
          sx={{ left: "10px", position: "absolute", bottom: "5px" }}>
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
        <h3 className="inter bold">{chart ? "Edit Widget" : "Edit Header"}</h3>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="sidebar-tabs">
          <Tabs
            value={currentTab}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="Edit Widget"
          >
            <Tab label="Data" />
            <Tab label="Settings" disabled={false} />
          </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
          {
            chart
              ? <EditChart
                key={chartType + newChart + renderNew}
                handleAllChartsForceReload={handleAllChartsForceReload}
                editDrawerState={editDrawerState}
                setChartType={updateChart}
                newChart={editDrawerState.chart?.type === "ADD" ? true : newChart}
              />
              : null
            // : <EditHeader key={chartType} editDrawerState={editDrawerState} />
          }
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          {chart
            ? <ChartSettings editDrawerState={editDrawerState} />
            : null
            // : <GridSettings editDrawerState={editDrawerState} />
          }
        </TabPanel>
      </Box>
    </Drawer>
  );
}
