import axios from "axios";

class API {
  clearLocalStorage() {
    localStorage.clear();
    window.location.href = "/";
  }

  post(url, data = {}, useToken = true) {
    if (useToken) {
      data["accessToken"] = localStorage.getItem("accessToken");
    }
    return axios
      .post(process.env.REACT_APP_BACKEND_BASE_URL + url, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Encoding": "gzip",
          "tz-offset": new Date().getTimezoneOffset(),
        },
      })
      .catch((err) => {
        if (
          err?.response?.data?.detail === "Incorrect password !" ||
          err?.response?.data?.detail === "Incorrect username !"
        ) {
          throw err;
        } else if (err.response.status === 401) {
          this.clearLocalStorage();
        } else {
          throw err;
        }
      });
  }
  get(url, isBlob = false) {
    return axios
      .get(process.env.REACT_APP_BACKEND_BASE_URL + url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        responseType: isBlob ? "blob" : "json",
      })
      .catch((err) => {
        let parsedData = null;
        if (err?.response?.data?.detail ) {
            try{
                parsedData=JSON.parse(err?.response?.data?.detail);
            } catch(error){
                console.log('error while parsing error details',error.message);
            }
        }
        if (
          parsedData &&
          parsedData?.error?.message ===
            "Request is missing required authentication credential. Expected OAuth 2 access token, login cookie or other valid authentication credential. See https://developers.google.com/identity/sign-in/web/devconsole-project."
        ) {
          console.log(parsedData);
        } else if (err?.response?.status === 401) {
          this.clearLocalStorage();
        }
        throw err;
      });
  }
  put(url, data = {}) {
    return axios
      .put(process.env.REACT_APP_BACKEND_BASE_URL + url, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.clearLocalStorage();
        }
        throw err;
      });
  }
  delete(url, data = {}) {
    return axios
      .delete(process.env.REACT_APP_BACKEND_BASE_URL + url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      })
      .catch((err) => {
        if (err.response.status === 401) {
          this.clearLocalStorage();
        }
        throw err;
      });
  }
}

export const api = new API();
