import React from "react";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { updateClientDetailsReducer } from "../../../api/client/action";
import { mappedReduxType } from "../../../api/client/enum";
import "./textfield.css";
// companyWebsite
const CompetitorTextField = (props) => {
  const dispatch = useDispatch();
  console.log(props.quesType);
  return (
    <div key={props.objKey}>
      <TextField
        // id='standard-basic'
        disabled={props.disabled ? props.disabled : false}
        error={props.error}
        type={props.type ? props.type : ""}
        placeholder={
          props.placeholder ? props.placeholder : "Type your answer here..."
        }
        sx={{
          "input::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "input::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },

          /* Firefox */
          "input[type=number]": {
            "-moz-appearance": "textfield",
          },
        }}
        value={props.value}
        variant={props.variant ? props.variant : "standard"}
        style={{
          width: props.width,
          height: props.height ? props.height : "",
          animation: props.bounce && `${props.bounceAnimation} 0.9s both`,
          // '&::-webkit-input-placeholder': { color: 'blue' },
        }}
        multiline={props.multiline}
        className={props.error ? "bounce-top" : ""}
        name="input"
        size={props.size ? props.size : ""}
        onChange={(e) => {
          // props.setError(!props.error);
          // if (
          //   props.infoType === "platformInfo" &&
          //   props.objKey === "brandDescription"
          // ) {
          //   dispatch({
          //     type: "UPDATE_PLATFORM_BRAND_DESCRIPTION",
          //     payload: { value: e.target.value },
          //   });
          // } else
          if (
            props.infoType === "platformInfo" &&
            (props.objKey || "").includes("competitors")
          ) {
            props.setPlatformInfo((prevState) => {
              // debugger;
              const tempArr = prevState.competitors;
              const [objKey, index, keyVal] = props.objKey.split(" ");
              tempArr[index][keyVal] = e.target.value;
              return {
                ...prevState,
                competitors: tempArr,
              };
            });
            // dispatch({
            //   type: "UPDATE_PLATFORM_COMPETITORS",
            //   payload: {value: e.target.value, key: props.objKey,}
            // })
          } else if (
            props.infoType === "productServiceInfo" &&
            (props.objKey || "").includes("descriptions")
          ) {
            props.setProductInfo((prevState) => {
              // debugger;
              const tempArr = prevState?.descriptions;
              const [objKey, index, keyVal] = props.objKey.split(" ");
              tempArr[index][keyVal] = e.target.value;
              return {
                ...prevState,
                descriptions: tempArr,
              };
            });
          } else if (
            props.infoType === "preferences" &&
            (props.objKey || "").includes("competitors")
          ) {
            props.setPreferences((prevState) => {
              const tempArr = prevState.competitors;
              const [objKey, index, keyVal] = props.objKey.split(" ");
              tempArr[index][keyVal] = e.target.value;
              return {
                ...prevState,
                competitors: tempArr,
              };
            });
          }
        }}
        inputProps={{
          style: { paddingBottom: 10, color: props.error ? "#800000" : "" },
        }}
        onKeyPress={props.onKeyPress}
      />
      {(props.quesType === "competitor" ||
        props.quesType === "productService") &&
        props.error && (
          <p
            style={{
              color: "red",
              position: "absolute",
              right: 132,
              top: 26,
            }}
          >
            {props.errorText}
          </p>
        )}
      {props.quesType === "productServiceName" && props.error && (
        <p
          style={{
            color: "red",
            position: "absolute",
            left: 22,
            top: 26,
          }}
        >
          {props.errorText}
        </p>
      )}
      {!["competitor", "productService", "productServiceName"].includes(
        props.quesType
      ) &&
        props.error && <p style={{ color: "red" }}>{props.errorText}</p>}
    </div>
  );
};

export default CompetitorTextField;
