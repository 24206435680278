import React from 'react';
import { Typography, Tooltip } from '@mui/material';

const AdCopies = ({
  ctr,
  cpm,
  cvr,
  cpa,
  primaryText,
  description,
  headline,
}) => {
  const headlineTextStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '15px',
    color: '#A6A6A6',
    paddingBottom: '4px',
  };
  const descriptionTextStyling = {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
  };
  const ctrValueStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 15.54,
    lineHeight: '14px',
  };
  const ctrTextStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 8.47,
    lineHeight: '12px',
    color: '#797979',
  };
  const truncateString = (str) => {
    return str?.length > 30 ? str?.substring(0, 29) + '...' : str;
  };
  const truncateString2 = (str) => {
    return str?.length > 80 ? str?.substring(0, 80) + '...' : str;
  };
  return (
    <div className='ad-single-copy-container' style={{ marginRight: '24px' }}>
      <div className='primary-text-container'>
        <Typography style={headlineTextStyling}>Primary Text</Typography>
        <Tooltip title={primaryText} arrow>
          <Typography
            style={descriptionTextStyling}
            sx={{
              maxWidth: '189px',
              height: '64px',
            }}
          >
            {truncateString2(primaryText)}
          </Typography>
        </Tooltip>
      </div>
      <div className='headline-container'>
        <Typography style={headlineTextStyling}>Headline</Typography>
        <Tooltip title={headline} arrow>
          <Typography style={descriptionTextStyling}>
            {truncateString(headline)}
          </Typography>
        </Tooltip>
      </div>
      <div className='description-container'>
        <Typography style={headlineTextStyling}>Description</Typography>
        <Tooltip title={description} arrow>
          <Typography style={descriptionTextStyling}>
            {truncateString(description)}
          </Typography>
        </Tooltip>
      </div>
      <div className='ad-copy-spends'>
        <div className='cpa-creative-container'>
          <Typography style={ctrValueStyling}>{cpa.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CPA</Typography>
          <div className='divider-ad-copies'></div>
        </div>
        <div className='ctr-creative-container'>
          <Typography style={ctrValueStyling}>{ctr.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CTR</Typography>
          <div className='divider-ad-copies'></div>
        </div>
        <div className='cpm-creative-container'>
          <Typography style={ctrValueStyling}>{cpm.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CPM</Typography>
          <div
            className='divider-ad-copies'
            style={{
              position: 'absolute',
              left: '3rem',
            }}
          ></div>
        </div>
        <div className='cvr-creative-container'>
          <Typography style={ctrValueStyling}>{cvr.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CVR</Typography>
        </div>
      </div>
    </div>
  );
};

export default AdCopies;
