import { IMAGE_ASPECT_RATIO_ENUM, LOGO_ASPECT_RATIO_ENUM, MAX_SIZE, MIN_RESOLUTION_IMAGE_ENUM, MIN_RESOLUTION_LOGO_ENUM } from "../api/pmaxStrategy/enum";

export const imageValidator = ({width, height, size, type}) => {
  let error;
  let errorMessage = '';
  console.log(width, height);
  const aspectRatio = Math.round((width/height) * 100) / 100;
  console.log('ASPECT_RATIO:', aspectRatio);

  if (size > MAX_SIZE)
    return {error: true, errorMessage: 'Size should be lower than 5120kb'};

  if (type === 'Image' || type === 'IMAGE')
  switch (aspectRatio) {
      case IMAGE_ASPECT_RATIO_ENUM['1.91:1']:
          if (width >= MIN_RESOLUTION_IMAGE_ENUM['1.91:1'].WIDTH &&
              height >= MIN_RESOLUTION_IMAGE_ENUM['1.91:1'].HEIGHT
          ) 
          {
              error = false;
              break;
          }
          error = true;
          errorMessage = `Mininum Resolution for aspect ratio 1.91:1 should be ${MIN_RESOLUTION_IMAGE_ENUM['1.91:1'].WIDTH} X ${MIN_RESOLUTION_IMAGE_ENUM['1.91:1'].HEIGHT}`;
          break;
      case IMAGE_ASPECT_RATIO_ENUM['1:1']:
          if (width >= MIN_RESOLUTION_IMAGE_ENUM['1:1'].WIDTH &&
              height >= MIN_RESOLUTION_IMAGE_ENUM['1:1'].HEIGHT
          ) 
          {
              error = false;
              break;
          }
          error = true;
          errorMessage = `Mininum Resolution for aspect ratio 1:1 should be ${MIN_RESOLUTION_IMAGE_ENUM['1:1'].WIDTH} X ${MIN_RESOLUTION_IMAGE_ENUM['1:1'].HEIGHT}`;
          break;
      case IMAGE_ASPECT_RATIO_ENUM['4:5']:
          if (width >= MIN_RESOLUTION_IMAGE_ENUM['4:5'].WIDTH &&
              height >= MIN_RESOLUTION_IMAGE_ENUM['4:5'].HEIGTH
          ) 
          {
              error = false;
              break;
          }
          error = true;
          errorMessage = `Mininum Resolution for aspect ratio 4:5 should be ${MIN_RESOLUTION_IMAGE_ENUM['1:1'].WIDTH} X ${MIN_RESOLUTION_IMAGE_ENUM['1:1'].HEIGHT}`;
          break;
      default:
          error = true;
          errorMessage = `Invalid aspect ratio. Allowed aspect ratios are: 1.91:1, 1:1, 4:5`;
  }
    else if (type === 'Logo' || type === 'LOGO') {
      switch (aspectRatio) {
          case LOGO_ASPECT_RATIO_ENUM['1:1']:
              if (width >= MIN_RESOLUTION_LOGO_ENUM['1:1'].WIDTH &&
                  height >= MIN_RESOLUTION_LOGO_ENUM['1:1'].HEIGHT
              ) 
              {
                  error = false;
                  break;
              }
              error = true;
              errorMessage = `Mininum Resolution for aspect ratio 1:1 should be ${MIN_RESOLUTION_LOGO_ENUM['1:1'].WIDTH} X ${MIN_RESOLUTION_LOGO_ENUM['1:1'].HEIGHT}`;
              break;
          case LOGO_ASPECT_RATIO_ENUM['4:1']:
              if (width >= MIN_RESOLUTION_LOGO_ENUM['4:1'].WIDTH &&
                  height >= MIN_RESOLUTION_LOGO_ENUM['4:1'].HEIGTH
              ) 
              {
                  error = false;
                  break;
              }
              error = true;
              errorMessage = `Mininum Resolution for aspect ratio 4:1 should be ${MIN_RESOLUTION_LOGO_ENUM['4:1'].WIDTH} X ${MIN_RESOLUTION_LOGO_ENUM['4:1'].HEIGTH}`;
              break;
          default:
              error = true;
              errorMessage = `Invalid aspect ratio. Allowed aspect ratios are: 1:1, 4:1`;
      }
    }

    return {error, errorMessage};
};
