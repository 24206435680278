import React, { useEffect, useRef, useState } from 'react';
import { Typography, Button, TextField } from '@material-ui/core';
import { crossIcon } from '../../../assets/icons/creative/creative';
import { CREATIVE_OBJECT_STATUS_ENUM, PRIMARY_TEXT_LIMIT } from '../../../api/creativeApproval/enums';
const PrimaryText = ({ isRead, setShowError, setIsRead, platform, isClient, status, primaryText, setPrimaryText }) => {
  
  const [addNewTerm, setAddNewTerm] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
      if (addNewTerm) {
          inputRef.current.focus();
      }
  }, [addNewTerm]);

  useEffect(() => {
      if (isRead) {
          setAddNewTerm(false);
          if (primaryText[primaryText?.length - 1] === '')
              setPrimaryText(prev => {
                  const newArray = prev.slice();
                  newArray.pop();
                  return newArray;
              });
      }
  }, [isRead]);

  const changePrimaryText = (e, index) => {
    setShowError(false);
    const newPrimaryText = primaryText.slice();
    newPrimaryText[index] = e.target.value;
    setPrimaryText(newPrimaryText);
  };

  const removePrimaryText = (index) => {
    setAddNewTerm(false);
    setShowError(false);
    const newPrimaryText = primaryText.slice();
    newPrimaryText.splice(index, 1);
    setPrimaryText(newPrimaryText);
  };
  const maxCharLimitPrimaryText = PRIMARY_TEXT_LIMIT.FACEBOOK;
  return (
    <div
      style={{
        border: '2px solid #E6F6FF',
        borderRadius: '8px',
        width: '100%',
      }}
    >
      <div
        style={{
          padding: '10px 0 11px 20px',
          borderBottom: '1px solid #F0F0F0',
        }}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '19px',
            color: '#333333',
          }}
        >
          Primary Text
        </Typography>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 10,
            lineHeight: '12px',
            color: '#827F7F',
            marginTop: 4,
          }}
        >
          Please add primary texts to be approved by the client.
        </Typography>
      </div>
      <div
        style={{
          maxHeight: 225,
          minHeight: 225,
          padding: '1rem',
          overflowY: 'scroll',
        }}
      >
        {primaryText.map((pText, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 15,
              width: '100%',
              paddingBottom: 12,
            }}
          >
            <div
              style={{
                background: '#FFFFFF',
                border: '1px solid #F0F0F0',
                boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.04)',
                borderRadius: '5px',
                width: '98%',
                paddingLeft: 15,
                paddingTop: 6,
                paddingBottom: 6,
                minHeight: 34,
              }}
            >
              {!addNewTerm && (
                  !isRead ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant='standard'
                    value={pText}
                    // disabled={isRead}
                    multiline
                    error={
                      pText.length === maxCharLimitPrimaryText ? true : false
                    }
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: 1.5,
                        width: 750,
                        // paddingBottom: 6,
                        border: 0,
                      },
                      maxLength: PRIMARY_TEXT_LIMIT.FACEBOOK,
                    }}
                    onChange={(e) => changePrimaryText(e, index)}
                  />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 1.5,
                      paddingRight: 8,
                    }}
                  >
                    {`${pText.length}/${PRIMARY_TEXT_LIMIT.FACEBOOK}`}
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: 1.5,
                    }}
                  >
                    {pText}
                  </Typography>
                  {pText.length !== 0 && (
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: 1.5,
                        paddingRight: 8,
                      }}
                    >
                      {`${pText.length}/${PRIMARY_TEXT_LIMIT.FACEBOOK}`}
                    </span>
                  )}
                </div>
              ))}
             { addNewTerm && ( isRead ? ((index === primaryText?.length - 1) ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant='standard'
                    value={pText}
                    inputRef={inputRef}
                    // disabled={isRead}
                    multiline
                    error={
                      pText.length === maxCharLimitPrimaryText ? true : false
                    }
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: 1.5,
                        width: 750,
                        // paddingBottom: 6,
                        border: 0,
                      },
                      maxLength: PRIMARY_TEXT_LIMIT.FACEBOOK,
                    }}
                    onChange={(e) => changePrimaryText(e, index)}
                    onBlur={() => {
                        if (pText === '')
                            removePrimaryText(index);
                        setAddNewTerm(false);
                    }}
                  />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 1.5,
                      paddingRight: 8,
                    }}
                  >
                    {`${pText.length}/${PRIMARY_TEXT_LIMIT.FACEBOOK}`}
                  </span>
                </div>
             ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: 1.5,
                    }}
                  >
                    {pText}
                  </Typography>
                  {pText.length !== 0 && (
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: 1.5,
                        paddingRight: 8,
                      }}
                    >
                      {`${pText.length}/${PRIMARY_TEXT_LIMIT.FACEBOOK}`}
                    </span>
                  )}
                </div>
             )) : (
             (( index === primaryText?.length - 1 ) ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant='standard'
                    value={pText}
                    inputRef={inputRef}
                    // disabled={isRead}
                    multiline
                    error={
                      pText.length === maxCharLimitPrimaryText ? true : false
                    }
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: 1.5,
                        width: 750,
                        // paddingBottom: 6,
                        border: 0,
                      },
                      maxLength: PRIMARY_TEXT_LIMIT.FACEBOOK,
                    }}
                    onChange={(e) => changePrimaryText(e, index)}
                  />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 1.5,
                      paddingRight: 8,
                    }}
                  >
                    {`${pText.length}/${PRIMARY_TEXT_LIMIT.FACEBOOK}`}
                  </span>
                </div>
             ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant='standard'
                    value={pText}
                    // disabled={isRead}
                    multiline
                    error={
                      pText.length === maxCharLimitPrimaryText ? true : false
                    }
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: 1.5,
                        width: 750,
                        // paddingBottom: 6,
                        border: 0,
                      },
                      maxLength: PRIMARY_TEXT_LIMIT.FACEBOOK,
                    }}
                    onChange={(e) => changePrimaryText(e, index)}
                  />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 1.5,
                      paddingRight: 8,
                    }}
                  >
                    {`${pText.length}/${PRIMARY_TEXT_LIMIT.FACEBOOK}`}
                  </span>
                </div>
             )
             )))}

              {/* { !isRead && 
                <span>
                    {`${pText.length}/${PRIMARY_TEXT_LIMIT.FACEBOOK}`}
                </span>
            } */}
            </div>
      { isClient && status === CREATIVE_OBJECT_STATUS_ENUM.PENDING &&
            <img
              src={crossIcon}
              alt='cross-icon'
              style={{
                  cursor: "pointer"
              }}
              onMouseDown={() => removePrimaryText(index)}
            />
      }
      { ! isClient &&
            <img
              src={crossIcon}
              alt='cross-icon'
              style={{
                  cursor: "pointer"
              }}
              onMouseDown={() => removePrimaryText(index)}
            />
      }
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#FFFFFF',
          borderTop: '1px solid #F0F0F0',
          boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.03)',
          padding: '8px 0',
          borderRadius: '0 0 8px 8px',
        }}
      >
      { isClient && status === CREATIVE_OBJECT_STATUS_ENUM.PENDING &&
        <Button
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '20px',
            color: '#0869FB',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
          }}
          onClick={() => {
              if (primaryText[primaryText?.length - 1] === '') return;
              setAddNewTerm(true);
              setPrimaryText([...primaryText, ''])
              // setIsRead(false);
          }}
        >
          +Add Primary Text
        </Button>
      }
      { ! isClient &&
        <Button
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '20px',
            color: '#0869FB',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
          }}
          onClick={() => {
              if (primaryText[primaryText?.length - 1] === '') return;
              setAddNewTerm(true);
              setPrimaryText([...primaryText, ''])
          }}
        >
          +Add Primary Text
        </Button>
      }
      </div>
    </div>
  );
};

export default PrimaryText;
