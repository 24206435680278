import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import BpRadio from "../../CustomRadio/Radio";
import { useDispatch } from "react-redux";

const AverageClosingPercent = ({
  value,
  objKey,
  infoType,
  outerKey,
  error,
  errorText,
  setProductInfo,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <FormControl>
        <RadioGroup
          defaultValue=""
          aria-labelledby="demo-customized-radios"
          name="customized-radios"
          style={{
            marginLeft: "0.8rem",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "1rem",
            width: 580,
          }}
          value={value}
          onChange={(e) => {
            setProductInfo((prevState) => {
              return {
                ...prevState,
                [objKey]: e.target.value,
              };
            });
          }}
        >
          <FormControlLabel
            value="10-25%"
            control={<BpRadio />}
            label="10-25%"
            style={{
              width: 180,
              height: 42,
              background: "#FFFFFF",
              border: "1px solid #DBDBDB",
              boxShadow: "0px 15px 53px rgba(30, 20, 165, 0.03)",
              borderRadius: 10,
              paddingLeft: "1rem",
              // marginBottom: '1rem',
              color: "#7F7D87",
            }}
          />
          <FormControlLabel
            value="26%-50%"
            control={<BpRadio />}
            label="26%-50%"
            style={{
              width: 180,
              height: 42,
              background: "#FFFFFF",
              border: "1px solid #DBDBDB",
              boxShadow: "0px 15px 53px rgba(30, 20, 165, 0.03)",
              borderRadius: 10,
              paddingLeft: "1rem",
              color: "#7F7D87",
            }}
          />
          <>
            <FormControlLabel
              value="51-75%"
              control={<BpRadio />}
              label="51-75%"
              style={{
                width: 180,
                height: 42,
                background: "#FFFFFF",
                border: "1px solid #DBDBDB",
                boxShadow: "0px 15px 53px rgba(30, 20, 165, 0.03)",
                borderRadius: 10,
                paddingLeft: "1rem",
                marginBottom: "1rem",
                color: "#7F7D87",
              }}
            />
            <FormControlLabel
              value="76-100%"
              control={<BpRadio />}
              label="76-100%"
              style={{
                width: 180,
                height: 42,
                background: "#FFFFFF",
                border: "1px solid #DBDBDB",
                boxShadow: "0px 15px 53px rgba(30, 20, 165, 0.03)",
                borderRadius: 10,
                paddingLeft: "1rem",
                color: "#7F7D87",
              }}
            />
          </>
        </RadioGroup>
        {error && <p style={{ color: "red" }}>{errorText}</p>}
      </FormControl>
    </>
  );
};

export default AverageClosingPercent;
