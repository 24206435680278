import { CONNECT_ACCOUNT,SEND_AUTH_CODE,GET_ACCOUNTS_CHANNEL,REVOKE_CHANNEL } from "./enum";

export const connectAccount = (type) => {
    return {
        type: CONNECT_ACCOUNT + "_" + type
    }
}

export const sendAuthCode = (type, payload) => {
    return {
        type: SEND_AUTH_CODE + "_" + type,
        authCode: payload
    }
}

export const resetData = () => {
    return {
        type: "RESET"
    }
}

export const getAccountsChannel = (payload) => {
    return {
        type:GET_ACCOUNTS_CHANNEL,
        payload:payload
    }
}

export const revokeChannel = (payload) => {
    return {
        type:REVOKE_CHANNEL,
        payload:payload
    }
}