import { useDispatch } from "react-redux";
import { goToSpecificCompoHDMAM } from "../../api/HDMAM/componentTracking/action";
import { accountManagerIdsHDM, componentWithIndexHDMAm } from "../../utils/accountManagerUtil";
import PmaxCampaignsListPage from "../PMaxStrategyCreation/PmaxCampaignsList/PmaxCampaignsListPage";
import { getLocalStorage } from "../../api/localStorage/localStorage";

export const PMaxStrategyListHDM = () => {
    const dispatch = useDispatch();

    const handleBackButton = () => {
        dispatch(
         goToSpecificCompoHDMAM(
           componentWithIndexHDMAm[
             accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY
           ]
         )
        )
    }

    const formId = getLocalStorage("amSelectedFormId");

    return <PmaxCampaignsListPage backButton={handleBackButton} formId={formId}/>
}
