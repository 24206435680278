import './auditloader.css';
import ProcessTime from '../../../assets/icons/Audit/processing-time.gif';
import ReadyIcon from '../../../assets/icons/proposal/ready.gif';
import { Button, Typography } from '@mui/material';
import PrimaryButton from '../ButtonPrimary/Buttons';
const AuditLoader = ({
  title = '',
  subTitle = '',
  isReady = false,
  redirectUrl = '',
  disabled = false,
  setShowAccountList=() => {}
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <div className='processtime'>
        <img
          src={isReady ? ReadyIcon : ProcessTime}
          style={{ width: '180px', height: '180px', objectFit: 'cover' }}
          alt=''
        />
      </div>
      <div
        style={{
          marginTop: '40px',
          marginBottom: '37px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{
            fontFamily: 'Lato',
            fontSize: '24px',
            lineHeight: '27px',
            fontWeight: '700',
            textAlign: 'center',
            marginBottom: '9px',
          }}
        >
          {title || 'The audit report is being generated.'}
        </Typography>
        <Typography
          style={{
            fontFamily: 'Lato',
            fontSize: '16px',
            lineHeight: '26px',
            fontWeight: '400',
            color: '#6F6C90',
            textAlign: 'center',
            width: '62%',
          }}
        >
          {subTitle || 'This usually takes 20-30 minutes.'}
        </Typography>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '16px',
        }}
      >
        {isReady && (
            <>
            <Button
              disabled={disabled}
              style={{
                textDecoration: 'none',
                width: '200px',
                height: '40px',
                borderRadius: '8px',
                border: '1.5px solid #0869FB',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#0869FB',
                textTransform: 'capitalize',
                background: '#ffffff',
                pointerEvents: disabled && 'none',
                opacity: disabled ? '0.4' : '1.0',
                fontWeight: 500,
              }}
              onClick={() => setShowAccountList(true)}
            >
              Generate Report Again
            </Button>
          <a
            href={redirectUrl}
            style={{
              textDecoration: 'none',
              pointerEvents: disabled && 'none',
              opacity: disabled ? '0.4' : '1.0',
            }}
            target='_blank'
            rel='noopener noreferrer'
          >
          </a>
          </>
        )}
        <a
          href={redirectUrl}
          style={{
            textDecoration: 'none',
            pointerEvents: disabled && 'none',
            opacity: disabled ? '0.4' : '1.0',
          }}
          target='_blank'
          rel='noopener noreferrer'
        >
          <Button
            disabled={disabled}
            style={{
              textDecoration: 'none',
              width: '172px',
              height: '40px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              textTransform: 'capitalize',
              background: '#0869FB',
              pointerEvents: disabled && 'none',
              opacity: disabled ? '0.4' : '1.0',
            }}
          >
            Audit Manager
          </Button>
        </a>
      </div>
    </div>
  );
};
export default AuditLoader;
