import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GetColor, UnitDataFormatter, DateFormatter, TooltipContent, transFormComparisonData, isDate, CustomizedLabel } from "./ChartUtils";
import {displayCurrency,dataBlendDisplayCurrency} from "./ChartUtils";

export default function SimpleLineChart({ data, comparedData, chartStyle, type }) {

  const { sourceData, leftMetrics, rightMetrics } = transFormComparisonData(data, comparedData);

  let useDateFormatter = false;
  const xField = data.dimensions?.[0]
  useDateFormatter = isDate(data.data[0][xField])

  const leftMetricUnit = data.leftMetrics.reduce((prev, curr) => prev === curr.unit ? prev : null, data.leftMetrics[0]?.unit)
  const rightMetricUnit = data.rightMetrics.reduce((prev, curr) => prev === curr.unit ? prev : null, data.rightMetrics[0]?.unit)

  const legendStyle = { wrapperStyle: { paddingLeft: "10px" }, layout: "horizontal", verticalAlign: "bottom", align: "center" };


  switch (chartStyle.legendPosition) {
    case "left":
    case "right":
      legendStyle.layout = "vertical";
      legendStyle.verticalAlign = "middle";
      legendStyle.align = chartStyle.legendPosition;
      break;
    case "top":
    case "bottom":
      legendStyle.layout = "horizontal";
      legendStyle.verticalAlign = chartStyle.legendPosition;
      legendStyle.align = "center";
      break;
  }
  const dataTypeToShowCurrency = displayCurrency(type);
  let currencyMapping = new Map();
  // data.leftMetrics.concat(data.rightMetrics).forEach(d => {
  //     currencyMapping.set(d.name,d.currency && d.unit.toUpperCase() === dataTypeToShowCurrency ? d.currency : dataBlendDisplayCurrency(d.name,d.unit,d.currency)?.length !== 0 ? d.currency : null);
  //     currencyMapping.set("X-"+d.name, d.currency && d.unit.toUpperCase() === dataTypeToShowCurrency ? d.currency : dataBlendDisplayCurrency(d.name,d.unit,d.currency)?.length !== 0 ? d.currency : null);
  // });
  return (
    <ResponsiveContainer>
      <LineChart
        data={sourceData}
        width={500}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <XAxis
          dataKey={xField}
          tickFormatter={useDateFormatter && DateFormatter}
          tickLine={false}
          style={{
            fontFamily: "Inter",
            fontSize: "12px",
            color: "#6D7D93",
            margin: "0",
          }}
        />
        {data.leftMetrics?.length > 0 && (
          <YAxis
            tickFormatter={v => UnitDataFormatter(v, leftMetricUnit)}
            tickLine={false}
            yAxisId="left"
            style={{ fontFamily: "Inter", fontSize: "12px", color: "#6D7D93" }}
          />
        )}
        {data.rightMetrics?.length > 0 && (
          <YAxis
            tickFormatter={v => UnitDataFormatter(v, rightMetricUnit)}
            tickLine={false}
            yAxisId="right"
            orientation="right"
            style={{ fontFamily: "Inter", fontSize: "12px", color: "#6D7D93" }}
          />
        )}
        <Tooltip content={(event) => TooltipContent(event, xField, useDateFormatter, currencyMapping)} />
        <Legend formatter={(value) => <span style={{ fontFamily: chartStyle.legendStyle.fontStyle, fontSize: chartStyle.legendStyle.fontSize }}>{currencyMapping.get(value) ? `${value} (${currencyMapping.get(value)})` : value}</span>} wrapperStyle={legendStyle.wrapperStyle} layout={legendStyle.layout} verticalAlign={legendStyle.verticalAlign} align={legendStyle.align} />

        {data.leftMetrics?.map((metric, index) => (
          <Line
            key={index}
            type="monotone"
            yAxisId="left"
            dataKey={metric.name}
            unit={metric.unit}
            stroke={GetColor(index, chartStyle.palette)}
            dot={false}
            strokeWidth={1.5}
            label={chartStyle.seriesStyle?.dataPoints
              ? <CustomizedLabel fill={GetColor(index, chartStyle.palette)} unit={leftMetricUnit} />
              : null
            }
          />
        ))}

        {data.rightMetrics?.map((metric, index) => (
          <Line
            key={index + data.leftMetrics?.length}
            type="monotone"
            yAxisId="right"
            dataKey={metric.name}
            unit={metric.unit}
            stroke={GetColor(
              index + data.leftMetrics.length,
              chartStyle.palette
            )}
            dot={false}
            strokeWidth={1.5}
            label={chartStyle.seriesStyle?.dataPoints
              ? <CustomizedLabel fill={GetColor(index + data.leftMetrics.length, chartStyle.palette)} unit={rightMetricUnit} />
              : null
            }
          />
        ))}

        {leftMetrics?.map((metric, index) => (
          <Line
            key={metric.id}
            type="monotone"
            yAxisId="left"
            dataKey={metric.name}
            unit={metric.unit}
            stroke={GetColor(index, chartStyle.palette)}
            dot={false}
            strokeWidth={1.5}
            strokeDasharray={"5 5"}
            legendType="none"
            label={chartStyle.seriesStyle?.dataPoints
              ? <CustomizedLabel fill={`${GetColor(index, chartStyle.palette)}CC`} unit={leftMetricUnit} />
              : null
            }
          />
        ))}

        {rightMetrics?.map((metric, index) => (
          <Line
            key={metric.id}
            type="monotone"
            yAxisId="right"
            dataKey={metric.name}
            unit={metric.unit}
            stroke={GetColor(
              index + data.leftMetrics.length,
              chartStyle.palette
            )}
            dot={false}
            strokeWidth={1.5}
            strokeDasharray={"5 5"}
            legendType="none"
            label={chartStyle.seriesStyle?.dataPoints
              ? <CustomizedLabel fill={`${GetColor(index + data.leftMetrics.length, chartStyle.palette)}CC`} unit={rightMetricUnit} />
              : null
            }
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
}
