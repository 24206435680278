import {
  Autocomplete,
  FormControl,
  ListSubheader,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React from "react";
import { ChannelType } from "../../../models/channel";
import { useSelector } from "react-redux";
import AddLogo from "../../../../assets/images/Analytics/logos_add_chart.svg";
import RemoveLogo from "../../../../assets/images/Analytics/logos_remove_chart.svg";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FilterOperators } from "../Utils";
const REMOVE_BOOL_OPERATOR = "-";
const boolOperators = [REMOVE_BOOL_OPERATOR, "OR", "AND"];

export default function FilterList({ channel, filters, currentFilter, onChange, onDelete }) {
  const [boolOperator, setBoolOperator] = React.useState(currentFilter.boolOperator);
  const [filter, setFilter] = React.useState(filters.find(f => f.id === currentFilter.filter?.id));
  const [operator, setOperator] = React.useState(currentFilter.operator);
  const [value, setValue] = React.useState(currentFilter.value
    ? (channel.type === ChannelType.GoogleAds
      ? currentFilter.value.map(val => filter?.dataType?.toUpperCase() === "PERCENT" ? (Number(val) * 100) : val)
      : currentFilter.value)
    : [""]);
  const [errors, setErrors] = React.useState({
    filter: false,
    operator: false,
    value: false
  });
  const [displayCol, setDisplayCol] = React.useState({
    METRIC: true,
    DIMENSION: true
  })

  const state = useSelector(state => channel.getState(state));
  React.useEffect(() => {
    const newErrors = { ...errors };
    if (filter) { newErrors.filter = false; }
    if (operator) { newErrors.operator = false; }
    if (value && (!Array.isArray(value) || (value.length && value[0]))) { newErrors.value = false; }
    setErrors(newErrors);
    const updateValue = Array.isArray(value) ? (channel.type === ChannelType.GoogleAds ? value.map(val => filter?.dataType?.toUpperCase() === "PERCENT" ? (Number(val) / 100) : val) : value) : value;
    onChange({
      boolOperator: boolOperator,
      filter: filter,
      operator: operator,
      value: updateValue,
      urns: " "
    });
  }, [boolOperator, filter, operator, value])

  const supportedOperators = FilterOperators.filter(operator => {
    if (!filter) { return false; }
    const type = filter.type ?? "METRIC";
    return operator.dataType === type || operator.dataType.includes(type);
  })

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <div style={{ display: "flex" }}>
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <Autocomplete
              id="grouped-dimensions"
              value={filter}
              options={filters}
              groupBy={(filter) => filter.group ?? filter.type}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderGroup={(props) => {
                const groupHeaders = [];
                if (displayCol[filters[props.key].type]) {
                  groupHeaders.push(
                    <ListSubheader key={props.key} component="div" style={{ lineHeight: "inherit", top: "20px", paddingTop: "10px" }}>
                      <div style={{ paddingLeft: "10px" }}>{props.group}</div>
                    </ListSubheader>
                  )
                  groupHeaders.push(
                    <ul style={{ paddingLeft: "20px" }}>
                      {props.children}
                    </ul>
                  );
                }
                return (
                  <>
                    {(props.key === 0 || filters[props.key].type !== filters[props.key - 1].type) ?
                      <ListSubheader key={props.key} component="div" style={{ lineHeight: "inherit", top: "-8px", paddingTop: "10px", zIndex: 5, height: "30px", color: "#1976d2CC" }}>
                        <div style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                          {filters[props.key].type ?? "CUSTOM"}
                          <ArrowDropDownIcon onClick={() => { setDisplayCol({ ...displayCol, [filters[props.key].type]: !displayCol[filters[props.key].type] }) }} />
                        </div>
                      </ListSubheader> : null
                    }
                    <li>{groupHeaders}</li>
                  </>
                )
              }}
              renderInput={(params) => <TextField {...params} error={errors.filter} required label="Select Filter" size="small" />}
              onChange={(event, newValue) => setFilter(newValue)}
            />
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <Autocomplete
              id="grouped-dimensions"
              value={supportedOperators.find(op => op.type === operator?.type)}
              options={supportedOperators}
              isOptionEqualToValue={(option, value) => option?.type === value?.type}
              renderInput={(params) => <TextField {...params} error={errors.operator} required label="Select Operator" size="small" />}
              onChange={(event, newValue) => {
                setOperator(newValue)
                if (Array.isArray(value)) {
                  setValue([value[0]])
                }
              }}
            />
          </FormControl>
          {Array.isArray(value) ?
            <div style={{ display: "flex", flexDirection: "column" }}>
              {value.map((val, index) => <FormControl style={{ display: "flex", flexDirection: "row", alignItems: "center" }} sx={{ m: 1, minWidth: 200 }} size="small">
                <TextField
                  label="Enter Value"
                  placeholder={channel.type === ChannelType.Bing ? "ex:value1,value2" : "Enter Value"}
                  id="outlined-basic"
                  value={val}
                  variant="outlined"
                  size="small"
                  error={errors.value}
                  required
                  onChange={event => {
                    let allVal = [...value];
                    allVal[index] = event.target.value
                    setValue(allVal);
                  }}
                />
                {(operator?.type === "IN_LIST" || operator?.type === "NOT_IN_LIST") &&
                  <>
                    {
                      value.length > 1 &&
                      < img style={{ marginLeft: "8px", height: "22px" }} onClick={() => setValue(value.filter((v, i) => i !== index))} src={RemoveLogo} alt="Remove Value" />
                    }
                    {index === (value.length - 1) &&
                      < img style={{ marginLeft: "8px", height: "22px" }} onClick={() => setValue([...value, ""])} src={AddLogo} alt="Add Value" />
                    }
                  </>
                }
              </FormControl>)}
            </div>
            : <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
              <TextField
                label="Enter Value"
                placeholder={channel.type === ChannelType.Bing ? "ex:value1,value2" : "Enter Value"}
                id="outlined-basic"
                value={value}
                variant="outlined"
                size="small"
                error={errors.value}
                required
                onChange={event => setValue(event.target.value)}
              />
            </FormControl>
          }
        </div>
        {boolOperator && <div style={{ padding: "10px", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
          <span>{boolOperator}</span>
          {
            (!channel.properties?.validFilterBoolOperators || channel.properties.validFilterBoolOperators.length !== 1) &&
            boolOperator === "AND" &&
            <div style={{ flex: 1, height: "1px", marginLeft: "10px", backgroundColor: "grey" }} />
          }
        </div>}
      </div>
      <div>
        <FormControl sx={{ m: 1 }} size="small">
          <ToggleButtonGroup
            color="primary"
            exclusive
            size="small"
            value={boolOperator}
            onChange={(event, newValue) => {
              if (!newValue) { return; }

              if (newValue === REMOVE_BOOL_OPERATOR) { return onDelete(); }

              const newErrors = { ...errors };
              if (!filter) { newErrors.filter = true; }
              if (!operator) { newErrors.operator = true; }
              if (!value || (Array.isArray(value) && value.length && !value[0])) { newErrors.value = true; }
              setErrors(newErrors);
              if (newErrors.filter || newErrors.operator || newErrors.value) { return; }

              setBoolOperator(newValue);
            }}
          >
            {boolOperators.filter(bo => (bo === REMOVE_BOOL_OPERATOR ? onDelete : (!channel.properties?.validFilterBoolOperators || channel.properties.validFilterBoolOperators.find(b => b === bo)))).map((bo, index) =>
              <ToggleButton
                key={index}
                sx={{ marginTop: 1, width: "48px", height: "24px" }}
                value={bo}
                disabled={bo !== REMOVE_BOOL_OPERATOR && channel.properties?.validFilterBoolOperators && !channel.properties.validFilterBoolOperators.find(b => b === bo)}
              >
                {bo}
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </FormControl>
      </div>
    </div>
  );
}
