import React, { useCallback, useState } from "react";
import {
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "./targeting-interest.css";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Close } from "@material-ui/icons";
import InterestLocationField from "./InterestLocationField";

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  bgcolor: "background.paper",
  borderRadius: "20px",
  padding: "46px 47px 42px",
  boxShadow: 24,
};
const InterestTargetting = (props) => {
  const [modal, showModal] = useState(false);
  const clearKeyword = (keyword) => {
    props.updateReducer((props.data.keywords || []).filter(keywd => keywd !== keyword), 'keywords')
  };

  const handleChange = (e, field) => {
    props.updateReducer(e.target.value, field);
  };

  return (
    <div className="targeting-container">
      <div className="header-targeting-container">
        <Typography className="targeting-header__title">Targeting</Typography>
      </div>
      <div className="targeting-form">
        <div className="targeting-form-element">
          <Typography className="targeting-form-element__title">
            Location
          </Typography>
          <InterestLocationField
            advertisingLocations={props.data.advertisingLocations}
            updateReducer={props.updateReducer}
          />
        </div>
        <div className="targeting-form-element age-form-element">
          <Typography className="targeting-form-element__title">Age</Typography>
          <div style={{ display: "flex", gap: "13px", alignItems: "center" }}>
            <TextField
              placeholder="From"
              variant="outlined"
              type="number"
              sx={{
                "input::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "input::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              value={props.data.minAge || ""}
              onChange={(e) => handleChange(e, "minAge")}
              // onChange={(e) => {
              //   handleChange(e, "ageFrom");
              // }}
              inputProps={{
                style: {
                  padding: "11px 16px",
                  background: "#FFFFFF",
                  border: "1px solid #DDDDDD",
                  borderRadius: 8,
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: "20px",
                },
              }}
            />

            <div className="divider-age-range"></div>
            <TextField
              placeholder="To"
              variant="outlined"
              type="number"
              sx={{
                "input::-webkit-outer-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "input::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "input[type=number]": {
                  "-moz-appearance": "textfield",
                },
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
              }}
              value={props.data.maxAge || ""}
              onChange={(e) => handleChange(e, "maxAge")}
              // onChange={(e) => {
              //   handleChange(e, "ageTo");
              // }}
              inputProps={{
                style: {
                  padding: "11px 16px",
                  background: "#FFFFFF",
                  border: "1px solid #DDDDDD",
                  borderRadius: 8,
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: "20px",
                },
              }}
            />
          </div>
        </div>
        <div className="targeting-form-element gender-form-element">
          <Typography className="targeting-form-element__title">
            Gender
          </Typography>
          <>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              style={{
                gap: "21px",
              }}
              value={props.data.gender}
              onChange={(e) => {
                handleChange(e, "gender");
              }}
            >
              <FormControlLabel
                value="Both"
                control={<BpRadio />}
                label="Both"
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px !important",
                  lineHeight: "20px",
                  color: "#999999",
                }}
              />
              <FormControlLabel
                value="Male"
                control={<BpRadio />}
                label="Male"
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px !important",
                  lineHeight: "20px",
                  color: "#999999",
                }}
              />
              <FormControlLabel
                value="Female"
                control={<BpRadio />}
                label="Female"
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "12px !important",
                  lineHeight: "20px",
                  color: "#999999",
                }}
              />
            </RadioGroup>
          </>
        </div>
        <div
          className="lookAlikeformelement"
          style={{
            width: "100%",
          }}
        >
          <Typography className="targeting-form-element__title">
            Keywords
          </Typography>
          <div className="add-view-interests-targetting">
            {(props.data.keywords || []).map((keyword, idx) => {
              if (idx < 12) {
                return (
                  <div
                    style={{
                      padding: "3.27481px 5.45802px",
                      background: "#FFFFFF",
                      borderRadius: "3.27481px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 12,
                        lineHeight: "15px",
                      }}
                    >
                      {keyword.name}
                    </Typography>
                  </div>
                );
              }
            })}
          </div>
          <Button
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 10,
              lineHeight: "12px",
              color: "#0869FB",
              textTransform: "capitalize",
              justifyContent: "flex-start",
            }}
            onClick={() => showModal(!modal)}
          >
            Add / View More
          </Button>
        </div>
      </div>
      <Modal
        open={modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="small_added_popup"
      >
        <Box sx={style1}>
          <div className="addad_popup_layout">
            <div className="addad_modal_header">
              <p>Interest Keywords</p>
              {/* <a className="popup_close_button" onClick={() => setEditAdkeyword(!editAdkeyword)}><span></span></a> */}
            </div>
            <Close
              style={{ position: "absolute", top: 16, right: 14 }}
              onClick={() => showModal(false)}
            />
            <div className="addad_modal_layout">
              <div className="keyword_modal_sections">
                <div className="tag_selected_blocks_popup">
                  <div className="tag_selected_chips_popup">
                    {(props.data.keywords || []).map((keyword) => (
                        <span className="chips_tag">
                          {keyword.name}{" "}
                          <button
                            className="clearchip"
                            onClick={() => clearKeyword(keyword)}
                          ></button>
                        </span>
                      ))}
                  </div>
                </div>
                {/* <div className="adset_list_hdm_popup">
                  <p className="addNew_keyword_title">Add New Keyword</p>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags"
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <MyChip
                          {...getTagProps({ index })}
                          label={option.name}
                        />
                      ));
                    }}
                    renderOption={(props, option, { inputValue, selected }) => {
                      const matches = match(option.title, inputValue, {
                        insideWords: true,
                      });
                      const parts = parse(option.title, matches);
                      return (
                        <li {...props} className="search_location_list">
                          <div className="location_list_head">
                            {" "}
                            <p>Interest Keywords</p>
                          </div>
                          <div className="location_list_content">
                            <div>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    color: part.highlight
                                      ? "#0869FB"
                                      : "#333333",
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </div>
                        </li>
                      );
                    }}
                    options={[...(props.data.keywords || [])]}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <div className="location_question_header">
                        <span className="search_icon"></span>
                        <TextField
                          {...params}
                          ref={params.InputProps.ref}
                          inputProps={params.inputProps}
                          autoFocus
                          placeholder="Search"
                          variant="standard"
                        />
                      </div>
                    )}
                    onChange={(eve, val) => autochange(eve, val, "popup")}
                    disablePortal={true}
                    className="auto_location_search"
                    defaultValue={[]}
                    value={
                      selectedKeyWord["popup"] ? selectedKeyWord["popup"] : []
                    }
                  />
                </div> */}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default InterestTargetting;
