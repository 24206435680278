import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import React from "react";
import { ldFbAuditComplete } from "../../api/accountManager/forms/saga";
import { updateFormReducer } from "../../api/accountManager/forms/slice";
import { button_bg_corner as corner } from "../../assets/icons/common/common";

export const onNextLD =
  (dispatch, navigation, extra = {}) =>
  (payload) => {
    if (extra?.type === "AUDIT_AD") {
      ldFbAuditComplete(extra?.data).then((res) => {
        navigation?.();
      });
    } else {
      if(payload){
        dispatch(updateFormReducer(payload));
      }
      navigation?.();
    }
  };

export const onNextHDM = (dispatch, navigation) => (payload) => {
  if(payload){
    dispatch(updateFormReducer(payload));
  }
  navigation?.();
};

const GoToNextCompoButton = (props) => {
  const CustomButton = styled(Button)({
    boxShadow: "none",
    textTransform: "none",
    fontSize: "14px",
    padding: props.type == "corner" ? "11px 25px" : "10px 24px",
    border: props.type == "corner" ? "0px solid" : "1px solid",
    lineHeight: "18px",
    background:
      props.type == "bordered"
        ? "#ffffff"
        : props.type == "corner"
        ? "#0869FB url(" + corner + ") no-repeat 0 0"
        : "#0869FB",
    borderColor: props.type == "bordered" ? "#0869FB" : "#0869FB",
    borderRadius: "8px",
    color: props.type == "bordered" ? "#0869FB" : "#FFFFFF",
    fontFamily: "Inter",
    fontWeight: 600,
    width: props.width ? props.width : "",
    "&:hover": {
      color: props.type == "bordered" ? "#FFFFFF" : "#FFFFFF",
    },
  });

  return (
    <CustomButton
      variant="contained"
      {...props}
      onClick={() => {
        if (props.onClickHandler) {
          props.onClickHandler();
        }
        props.onNext?.(props.getPayload?.());
      }}
    >
      {props.title}
    </CustomButton>
  );
};

export default GoToNextCompoButton;
