import { Box, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { preloader as loaderGif } from '../../assets/icons/readaccess/readaccess';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 450,
  boxShadow: 24,
  minHeight: '200px',
  background: '#FFFFFF',
  borderRadius: 1.75,
  padding: '1.5rem',
  outline: 'none',
};

const DEFAULT_CLOSE_DELAY = 300;

export default function PleaseWaitLoaderModal({
  open,
  title = "Please Wait!",
  description = "",
  closeDelay = DEFAULT_CLOSE_DELAY
}) {
  const [_open, setOpen] = useState(open);

  useEffect(() => {
    if (open) return setOpen(true);

    const id = setTimeout(() => setOpen(false), closeDelay);
    return () => {
      clearTimeout(id);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Modal
      open={_open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={loaderGif} alt='' width={120} height={120} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.25rem',
              //   width: '50%',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 28,
                color: '#333333',
              }}
            >
              {title}
            </Typography>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                color: '#727272',
                width: '15rem',
                lineHeight: 1.5,
                textAlign: 'center'
              }}
            >
              {description}
            </Typography>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
