import { CHANGE_DATE, SELECT_ACCOUNT, SWITCH_REDIRECT, UPDATE_CHART } from "./enum";

export const changeDate = payload => {
    return {
        type: CHANGE_DATE,
        payload: payload,
    };
};

export const updateChart = payload => {
    return {
        type: UPDATE_CHART,
        payload: payload
    };
};

export const selectAccount = payload => {
    return {
        type: SELECT_ACCOUNT,
        payload: payload
    };
};

export const switchRedirect = () => {
    return {
        type: SWITCH_REDIRECT
    }
}