import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';
import LZString from 'lz-string';

export const compressTransform = {
  in: (state) => LZString.compress(JSON.stringify(state)),
  out: (state) => JSON.parse(LZString.decompress(state)),
};
const persistedReducer = persistReducer(
  {
    key: "px", storage,
    whitelist: ['dashboard', 'client', 'sharedAssets', 'hdmClient', 'hdmAMCurrentComp', 'amCurrentComp', 'hdmClientCurrentComp', 'clientCurrentComp', 'form', 'googleCustomers'],
    transforms: [compressTransform]
  },
  rootReducer
);

// const reportPersistConfig = {
//   key: 'report',
//   storage,
//   transforms: [compressTransform],
// };


const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers = compose;
export const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);