import { useDispatch, useSelector } from "react-redux";
import CommonAuditAccount from "./CommonAuditAccount";
import { useCallback, useEffect, useState } from "react";
import { getAlreadyAuditHdm } from "../../../api/HDMAM/audit/saga";
import {
  BACKEND_STATUS,
  accountManagerIdsHDM,
  componentWithIndexHDMAm,
  isPlatformSelectedHDM,
} from "../../../utils/accountManagerUtil";
import { goToSpecificCompoHDMAM } from "../../../api/HDMAM/componentTracking/action";
import { onNextHDM } from "../GoToNextCompoButton";
import { generatePath, useNavigate, useParams } from "react-router";
import hdmAmRoutePaths from "../../../pages/hdm-am/constants/hdmAmRoutePaths";
import { updateWholeFormDataHdmAm } from "../../../api/HDMAM/form/action";

export default function HDMAuditAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();

  const [spends, setSpends] = useState(0);
  const [CMR, setCMR] = useState(0);

  const form = useSelector((state) => state.hdmFormAm);

  useEffect(() => {
    dispatch(updateWholeFormDataHdmAm());
  }, []);

  useEffect(() => {
    if (form?.auditInfo?.accountAuditInfo?.spend) {
      setSpends(form?.auditInfo?.accountAuditInfo?.spend);
    } else {
      setSpends(
        (
          (form?.onboardingInfo?.clientAccess?.platforms || []).find(
            (platform) => platform.platform === "facebook-ads"
          ) || {}
        ).spends || 0
      );
    }

    if (form?.auditInfo?.accountAuditInfo?.currentMonthlyRevenue) {
      setCMR(form?.auditInfo?.accountAuditInfo?.currentMonthlyRevenue);
    } else {
      setCMR(form?.onboardingInfo?.aboutBusiness?.monthlyRevenue || 0);
    }
  }, [form]);

  const onFBAccountIdExists = useCallback(() => {
    return getAlreadyAuditHdm("HDM").then((res) => {
      if (res.length !== 0) {
        const accounts = res.filter(
          (account) => account.accountId === form?.fbAdsAccountId
        );
        dispatch({
          type: "GET_AUDIT_DETAILS_HDM",
          payload: accounts.length
            ? accounts[accounts.length - 1].performanceData
            : res[res.length - 1].performanceData,
        });
      }
    });
  }, [dispatch, form?.fbAdsAccountId]);

  const onBack = () => {
    if (isPlatformSelectedHDM(form, "google-ads") !== -1) {
      dispatch(
        goToSpecificCompoHDMAM(
          componentWithIndexHDMAm[accountManagerIdsHDM.AUDIT_GOOGLE_ADS]
        )
      );
    } else {
      dispatch(
        goToSpecificCompoHDMAM(
          componentWithIndexHDMAm[
            accountManagerIdsHDM.HDM_ONBOARDING_COMMUNICATION
          ]
        )
      );
    }
  };

  const navigation = () => {
    navigate(
      generatePath(
        `${hdmAmRoutePaths.auditForFacebook.fullbase}/${hdmAmRoutePaths.auditForFacebook.sub.campaign}`,
        { formId }
      )
    );
  };

  const onAuditAccountChange = useCallback(() => {
    navigate(
      generatePath(
        `${hdmAmRoutePaths.auditForFacebook.fullbase}/${hdmAmRoutePaths.auditForFacebook.sub.again}`,
        { formId, again: true }
      )
    );
  }, [dispatch]);

  return (
    <CommonAuditAccount
      form={form}
      onFBAccountIdExists={onFBAccountIdExists}
      onBack={onBack}
      spends={spends}
      setSpends={setSpends}
      cmr={CMR}
      setCMR={setCMR}
      onAuditAccountChange={onAuditAccountChange}
      /** onNextHDM, is a curried function, no need to wrap the call in a callback */
      onNext={() =>
        onNextHDM(
          dispatch,
          navigation
        )({
          auditInfo: {
            accountAuditInfo: {
              currentMonthlyRevenue: CMR,
              spend: spends,
              status: BACKEND_STATUS.COMPLETED,
            },
          },
        })
      }
    />
  );
}
