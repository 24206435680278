import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {button_bg_corner as corner }from '../../../assets/icons/common/common';
import {Edit as EDITICON }from "../../../assets/icons/common/common";
import {Edit_white  as editWhiteICON} from "../../../assets/icons/common/common";

const PrimaryButton = (props) => {
  const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '14px',
    padding: props.icon == 'edit' ? '11px 25px 11px 45px' : props.type == 'corner' ? '11px 25px' : '10px 24px',
    border: props.type == 'corner' ? '0px solid' : '1px solid',
    lineHeight: '18px',
    background:
      props.type == 'bordered'
        ? '#ffffff'
        : props.type == 'corner'
        ? '#0869FB url(' + corner + ') no-repeat 0 0'
        
        :'#0869FB',
    borderColor: props.type == 'bordered' ? '#0869FB' : '#0869FB',
    borderRadius: '8px',
    color: props.type == 'bordered' ? '#0869FB' : '#FFFFFF',
    fontFamily: 'Inter',
    fontWeight: 600,
    width: props.width ? props.width : '',
    '&:hover': {
      color: props.type == 'bordered' ? '#FFFFFF' : '#FFFFFF',
      '&::before' : {
        background:props.icon == 'edit'? 'url(' + editWhiteICON + ') no-repeat center center': `''`,
      }
    },
    '&:before' : {
      content: `""`,
      position: 'absolute',
      left: '15px',
      top: '50%', 
      transform: 'translate(0,-50%)',
      background:props.icon == 'edit'? 'url(' + EDITICON + ') no-repeat center center': `''`,
      width: "20px",
      height: "20px",
      '&:hover': {
        
      },
    },
  });

  return (
    <CustomButton variant='contained' {...props}>
      {props.title}
    </CustomButton>
  );
};

export default PrimaryButton;
