import { leftPanelNameInUrlPath } from "../../../utils/HDMUtils/client/utils";


const BASE = "/hdm-client";

const hdmClientRoutePaths = /** @type {const} */ ({
  root: BASE,
  homePage: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.HOMEPAGE}`,
    root: `${leftPanelNameInUrlPath.HOMEPAGE}`, // should matches the end of fullbase
    sub: {},
  },
  onboardingForm: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ONBOARDING_FORM}`,
    root: `${leftPanelNameInUrlPath.ONBOARDING_FORM}`, // should matches the end of fullbase
    sub: {
      review: "",
      clientAndAccess: "client-and-access",
      clientAndAccessWithQuestionNumber: "client-and-access/:questionNumber",
      brandOrMessaging: "brand-or-messaging",
      brandOrMessagingWithQuestionNumber: "brand-or-messaging/:questionNumber",
      facebookTargeting: "facebook-targeting",
      facebookTargetingWithQuestionNumber: "facebook-targeting/:questionNumber",
      googleTargeting: "google-targeting",
      googleTargetingWithQuestionNumber: "google-targeting/:questionNumber",
      aboutYourBusiness: "about-your-business",
      aboutYourBusinessWithQuestionNumber: "about-your-business/:questionNumber",
      creatives: "creatives",
      creativesWithQuestionNumber: "creatives/:questionNumber"
    },
  },
  accessSharing: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ACCESS_SHARING}`,
    root: `${leftPanelNameInUrlPath.ACCESS_SHARING}`, // should matches the end of fullbase
    sub: {},
  },
  onboardingDoc: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ONBOARDING_DOC}`,
    root: `${leftPanelNameInUrlPath.ONBOARDING_DOC}`, // should matches the end of fullbase
    sub: {
      onboarding: "",
      reviewBriefingDoc: "review-briefing-doc",
      expectations: "expectations",
      success: "success"
    },
  },
  strategyDeck: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.STRATEGY_DECK}`,
    root: `${leftPanelNameInUrlPath.STRATEGY_DECK}`, // should matches the end of fullbase
    sub: {
      goal: '',
      analysis: "analysis",
      google: 'google',
      facebook: 'facebook',
      ourPlan: "our-plan",
      bestPractices: "best-practices",
      suggestChanges: "suggest-changes",
    },
  },
  campaignPreview: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CAMPAIGN_PREVIEW}`,
    root: `${leftPanelNameInUrlPath.CAMPAIGN_PREVIEW}`, // should matches the end of fullbase
    sub: {},
  },
  analyticsDashboard: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ANALYTICS_DASHBOARD}`,
    root: `${leftPanelNameInUrlPath.ANALYTICS_DASHBOARD}`, // should matches the end of fullbase
    sub: {},
  },

});

export default hdmClientRoutePaths;
