import { useDispatch, useSelector } from 'react-redux';
import {
  BACKEND_STATUS,
} from "../../../utils/accountManagerUtil";
import CommonReviewPoints from './CommonReviewPoints';
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import hdmAmRoutePaths from '../../../pages/hdm-am/constants/hdmAmRoutePaths';
import { useEffect } from 'react';
import { getInsightsForFormIdReducer } from '../../../api/accountManager/googleAdsCustomer/slice';

export default function HDMReviewPoints() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  
  const form = useSelector((store) => store.hdmFormAm);

  useEffect(() => {
    dispatch(getInsightsForFormIdReducer());
  }, []);

  const onClick = () => {
    if (
      (form?.onboardingInfo?.clientAccess?.platforms || []).find(
        (pf) => pf.platform === "facebook-ads"
      )
    ) {
      if ([BACKEND_STATUS.COMPLETED, BACKEND_STATUS.ONGOING].includes(form?.auditInfo?.status)) {
        navigate(
          generatePath(
            `${hdmAmRoutePaths.auditForFacebook.fullbase}/${hdmAmRoutePaths.auditForFacebook.sub.account}`,
            { formId }
          )
        );
      } else {
        navigate(
          generatePath(
            `${hdmAmRoutePaths.auditForFacebook.fullbase}/${hdmAmRoutePaths.auditForFacebook.sub.selectAccount}`,
            { formId }
          )
        );
      }
    } else {
      navigate(
        generatePath(`${hdmAmRoutePaths.accountAnalysis.fullbase}`, { formId })
      );
    }
  };

  const onChangeAccountClick = () => {
    navigate(
      generatePath(
        `${hdmAmRoutePaths.auditForGoogle.fullbase}/${hdmAmRoutePaths.auditForGoogle.sub.again}`,
        { formId, again: true }
      )
    );
  };

  
  return (
    <CommonReviewPoints
      form={form}
      onClick={onClick}
      onChangeAccountClick={onChangeAccountClick}
      displayAdditionalInfo={false}
    />
  );
}
