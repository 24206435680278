import React, { useState } from "react";
import { menuIcon, trashIcon } from "../../../assets/icons/admin/admin";
import {
  StyledAccountDetails,
  StyledAccountId,
  StyledAccountName,
  StyledAlreadyConnectedText,
  StyledCardBody,
  StyledImageContainer,
  StyledMenuWithStatusBody,
  StyledStatusLabel,
} from "./styled";
import {
  StyledRemoveText,
  StyledRemoveWrapper,
} from "../../AccessSharing/ConnectedAdAccounts/styled";
import { ClickAwayListener, Popover } from "@mui/material";
import { PERMISSIONS } from "../../../utils/commonEnums";

const SelectedAccountCard = ({
  adAccountId,
  adAccountName,
  permissions,
  brand,
  deleteAdAccountCallback,
  payloadFromGoogleAuth,
  setRemoveAdAccount,
}) => {
  const [remove, setRemove] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  return (
    <ClickAwayListener onClickAway={() => setRemove(false)}>
      <StyledCardBody>
        <StyledAccountDetails>
          <StyledAccountName>{adAccountName}</StyledAccountName>
          <StyledAccountId>{adAccountId}</StyledAccountId>
        </StyledAccountDetails>
        <StyledMenuWithStatusBody>
          {permissions === PERMISSIONS.READ ? (
            <StyledStatusLabel bgColor="#FEF5EB" textColor="#F99F35">
              Read
            </StyledStatusLabel>
          ) : permissions === PERMISSIONS.WRITE ? (
            <StyledStatusLabel bgColor="#E8F9F6" textColor="#1ABFA1">
              Write
            </StyledStatusLabel>
          ) : (
            <StyledAlreadyConnectedText>
              Not Enough Permissions
            </StyledAlreadyConnectedText>
          )}
          <StyledImageContainer
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setRemove(!remove);
            }}
          >
            <img src={menuIcon} alt="menu-icon" />
          </StyledImageContainer>
        </StyledMenuWithStatusBody>

        <Popover
          open={remove}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setRemove(false);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <StyledRemoveWrapper
            onClick={() => {
              setRemoveAdAccount({
                brandId: brand.brandId
                  ? brand.brandId
                  : payloadFromGoogleAuth.brandId,
                adAccountId: adAccountId,
              });
              deleteAdAccountCallback();
            }}
          >
            <StyledRemoveText>
              <img src={trashIcon} alt="trash" />
              Remove
            </StyledRemoveText>
          </StyledRemoveWrapper>
        </Popover>
      </StyledCardBody>
    </ClickAwayListener>
  );
};

export default SelectedAccountCard;
