import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../api";
// export const getClientFormQueryKey = ({ formId }) => [
//   "client-forms/get",
//   formId,
// ];
export const useGetAdAccounts = (brandId, options = {}) => {
  return useQuery(
    ["adAccounts/get"],
    async () => {
      const response = await api.get(`/ad-accounts/${brandId}`);
      return response.data;
    },
    options
  );
};

export const usePutAdAccountforAccountsList = () => {
  return useMutation({
    mutationFn: async ({ brandId, platform, fields }) => {
      const response = await api.put(
        `/ad-accounts/${brandId}/${platform}`,
        fields
      );
      return response.data;
    },
  });
};

export const usePutAdAccountforSendingAccounts = (
  { tokenUid = "", brandId, formId = "" },
  options = {}
) => {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ fields }) => {
      await api.put(`/ad-accounts/${tokenUid}/brand/${brandId}`, fields);
    },
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["userlist/get"] });
      queryClient.invalidateQueries({ queryKey: ["brand-list/get"] });
      queryClient.invalidateQueries({ queryKey: ["adAccounts/get"] });
      // queryClient.invalidateQueries({
      //   queryKey: getClientFormQueryKey({ formId: formId }),
      // });
      onSuccess?.(...args);
    },
    ...restOptions,
  });
};

export const useDeleteAdAccount = (options = {}) => {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ brandId, accountId }) => {
      console.log(brandId, accountId);
      await api.delete(`/ad-accounts/${brandId}/${accountId}`);
    },
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["userlist/get"] });
      queryClient.invalidateQueries({ queryKey: ["adAccounts/get"] });
      queryClient.invalidateQueries({ queryKey: ["brand-list/get"] });
      onSuccess?.(...args);
    },
    ...restOptions,
  });
};
