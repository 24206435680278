import { makeStyles } from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Languages } from "../../../../utils/Languages";
import "../campaign-language-select.scss";

const MyChip = (props) => {
  return (
    <div className="custom_chips">
      <span>{props.label}</span>
      <span
        className="close_tag"
        style={{ display: props.disabled ? "none" : undefined }}
        onClick={props.onDelete}
      />
    </div>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles({
  popper: { maxWidth: "356px !important" }, // a style rule
});
const CampainLanguageSelect = ({
  languages,
  setLanguages,
  disabled,
  placeholder = "Select",
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      id="checkboxes-tags"
      options={Languages}
      disabled={disabled}
      value={languages}
      onChange={(event, values) => {
        setLanguages(values);
      }}
      disableCloseOnSelect
      classes={{
        popper: classes.popper,
      }}
      renderTags={(tagValue, getTagProps) => {
        return (
          <div style={{ display: "flex", overflow: "auto", flexWrap: "wrap" }}>
            {tagValue.map((option, index) => (
              <MyChip {...getTagProps({ index })} label={option.language} disabled={disabled} />
            ))}
          </div>
        );
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option.language, inputValue, {
          insideWords: true,
        });
        const parts = parse(option.language, matches);
        return (
          <li {...props} className="search_location_list">
            <div className="location_list_content">
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? "#0869FB" : "#333333",
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </div>
          </li>
        );
      }}
      getOptionLabel={(option) => option.language}
      renderInput={(params) => (
        <div className="location_question_header">
          <span className="search_icon"></span>
          <TextField
            style={{ padding: 0 }}
            {...params}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder={placeholder}
            variant="standard"
          />
        </div>
      )}
      disablePortal={true}
      className="auto_location_search"
    />
  );
};

export default CampainLanguageSelect;
