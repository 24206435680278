import { ListSubheader, MenuItem, Radio, Select } from '@mui/material';
import { forwardRef, useMemo } from 'react';
import { filterDropdown } from './utils';

const AudienceDropdown = forwardRef(function ({
  value,
  onChange,
  audiences = [],
  ...props
}, ref) {
  const audienceDropdown = useMemo(() => ({
    WEBSITE: filterDropdown('WEBSITE', 'WEBSITE', audiences),
    // CUSTOM: filterDropdown("CUSTOM", "CUSTOM", audiences),
    // "FB EVENT": filterDropdown("FB EVENT", "FB_EVENT", audiences),
  }), [audiences]);

  const idToNameMap = useMemo(() => audiences.reduce((acc, audience) => ({...acc, [audience.id]: audience.name}), {}), [audiences]);

  // render abstractions
  const DropDownRender = () => {
    var ListArray = [];
    for (const key in audienceDropdown) {
      if (Object.hasOwnProperty.call(audienceDropdown, key)) {
        const element = audienceDropdown[key];
        ListArray.push(
          <ListSubheader key={key} className='drop_custom_list_head'>
            {key}
          </ListSubheader>
        );
        element.forEach((ele, index) => {
          ListArray.push(
            <MenuItem
              key={index}
              value={ele.id}
              className='drop_custom_list_row'
              style={{
                width: '400px',
              }}
            >
              <div className='drop_custom_list'>
                <div>
                  <Radio checked={value?.id === ele.id}/>
                  <p style={{ overflow: 'revert' }}>{ele.name}</p>
                </div>
                <span>{ele.reach}</span>
              </div>
            </MenuItem>
          );
        });
      }
    }
    return ListArray;
  };

  return (
    <Select
      variant='standard'
      placeholder='Audience Name'
      className='audience_droplist_hdm'
      value={value?.id}
      onChange={(e) => {
        const audienceId = e.target.value;
        onChange(audiences?.find(el => el.id === audienceId));
      }}
      renderValue={(selectedId) => idToNameMap[selectedId]}
      {...props}
      ref={ref}
    >
      <ListSubheader className='drop_custom_list_tophead'>
        <span>Audience</span> <span>Reach</span>
      </ListSubheader>
      {DropDownRender()}
    </Select>
  )
})

export default AudienceDropdown;