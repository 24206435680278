import InputTextField from '../InputTextField/TextField';
// import { questionForm } from "../Questions/FormQuestions";
import { withStyles } from '@material-ui/core/styles';
import { Close } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Slide,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeHDMClientView } from '../../../api/HDMAM/componentTracking/action';
import LocationAutoComplete from './Helpers/LocationAutocomplete';
import {
  updateHDMClientDetails,
  updateHDMClientDetailsReducer,
} from '../../../api/HDMClient/action';
import {
  goToReviewHDMQuestionier,
  goToSpecificCompoHDMClient,
  updateHDMCompoReduxClient,
} from '../../../api/HDMClient/componentTracking/action';
import { mappedReduxType } from '../../../api/HDMClient/enum';
import { closeClientView } from '../../../api/accountManager/componentTracking/action';
import { updateCompoReduxClient } from '../../../api/componentTracking/action';
import { getImageUrl } from '../../../api/fileHandling/saga';
import backgroundImage2 from '../../../assets/icons/background-2.png';
import {
  search_icon as MAG,
  preview as PreviewIcon,
  down_arrow as arrowDown,
  top_arrow as arrowUp,
  menu as menuIcon,
  trash as trashIcon,
  right_arrow as rightArrow,
  left_arrow as leftArrow,
} from '../../../assets/icons/common/common';
import facebookIcon from '../../../assets/icons/social/facebook.svg';
import {
  google_icon as googleIcon,
  tiktok_icon_square as tiktokIcon,
} from '../../../assets/icons/social/social';
import {
  clientDashboardIds as LDClientDashboardIds,
  LDcomponentWithIndexClient,
} from '../../../utils/clientDashboardIdsUtil';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import BpRadio from '../../ClientDashboard/CustomRadio/Radio';
import { ageValid } from '../../ClientDashboard/Questions/Validators/ageValid';
import { brandDescValidator } from '../../ClientDashboard/Questions/Validators/brandDescValidator';
import { urlValidator } from '../../ClientDashboard/Questions/Validators/urlValidator';
import { mappedFbTargetingQuestion } from '../../ClientDashboard/Questions/mappedQuestion';
import HDMCheckBoxOption from './HDMCheckBoxOption';
import StyledDrop from './HDMFileInputBox';
import HDMInterestAutoComplete from './HDMInterestAutoComplete';
import HDMLocationAutoComplete from './HDMLocationAutoComplete';
import { mappedQuestion, sourcesOfLeads } from './HDMMappedQuestion';
import HDMMenu from './HDMMenu';
import AgeRangeCheckboxes from './Helpers/AgeRangeCheckBoxes';
import CustomerEmploymentCheckbox from './Helpers/CustomerEmploymentCheckbox';
import LocationAutoCompleteField from './Helpers/LocationAutoCompleteField';
import ProductServiceInform from './Helpers/ProductServiceInform';
import TargetCustomerIncome from './Helpers/TargetCustomerIncome';

import {
  clientDashboardIds,
  componentWithIndexHDMClient,
} from '../../../utils/HDMClientDashboardIdsUtil';
import roles from '../../../utils/roles';
import { emailValidator } from '../../ClientDashboard/Questions/Validators/emailValidator';
import { serviceDescValidator } from '../../ClientDashboard/Questions/Validators/serviceDescValidator';
import { specialCharValidator } from '../../ClientDashboard/Questions/Validators/specialCharValidator';

const HDMQuestionaireContainer = (props) => {
  const reduxStates = useSelector((state) => state);
  const [widthMoved, setWidthMoved] = useState(0);
  const currentCompoClient = useSelector((state) =>
    props?.isLD
      ? state.clientCurrentComp?.currentComp
      : state.hdmClientCurrentComp?.currentComp
  );
  const visitedLastTime = useSelector((state) =>
    props?.isLD
      ? state.clientCurrentComp?.visitedLastTime
      : state.hdmClientCurrentComp?.visitedLastTime
  );
  const currentHeaderTab = useSelector(
    (state) => state.hdmClientCurrentComp?.currentHeaderTab
  );
  const countriesCheckBox = useSelector(
    (state) => state.hdmClient?.onboardingInfo?.aboutBusiness?.countryTargeted
  );
  const advLocation = useSelector(
    (state) =>
      state.hdmClient?.onboardingInfo?.facebookTargeting?.advertisingLocations
  );
  const activity = useSelector(
    (state) =>
      state.hdmClient?.onboardingInfo?.facebookTargeting?.activitiesInterest
  );
  const platforms = useSelector(
    (state) => state?.hdmClient?.onboardingInfo?.clientAccess?.platforms
  );
  const customerBaseCountries = useSelector(
    (state) =>
      state?.hdmClient?.onboardingInfo?.brandMessaging?.customerBaseCountries
  );
  const [countryTarget, setCountryTarget] = useState('');
  const [selectedCountryTags, setSelectedCountryTags] = useState(
    customerBaseCountries
  );
  const runMontageAds = useSelector(
    (state) => state.hdmClient?.onboardingInfo?.aboutBusiness?.runMontageAds
  );
  console.log('Customer Base countries : ', customerBaseCountries);
  const [error, setError] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  // const [bgAnimate, setBgAnimate] = useState(false);
  const dispatch = useDispatch();
  // const [slideIn, setSlideIn] = useState(true);
  const [objValue, setObjectValue] = useState([]);
  const [file, setFile] = useState([]);
  useEffect(() => {
    if (props.objKey === 'runMontageAds') {
      if (runMontageAds === undefined)
        dispatch(
          updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
            infoType: props.infoType,
            key: props.objKey,
            value: true,
          })
        );
    }
  }, [props.objKey]);

  useEffect(() => {
    setWidthMoved((props.quesNumber * 100) / props.totalQuestions);
  }, [props.quesNumber, props.totalQuestions]);
  const role = localStorage.getItem('role');

  console.log('current compo:', currentCompoClient);

  useEffect(() => {
    if (props.objKey === 'runMontageAds') {
      if (runMontageAds === undefined)
        dispatch(
          updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
            infoType: props.infoType,
            key: props.objKey,
            value: true,
          })
        );
    }
  }, [props.objKey]);

  const skipCompoNext = () => {
    let isSkip = false;
    if (
      platforms?.findIndex(
        (platform) => platform.platform === 'facebook-ads'
      ) === -1 &&
      props.objKey === 'logoLink'
    ) {
      dispatch(
        goToSpecificCompoHDMClient(
          componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING1]
        )
      );
      isSkip = true;
    } else if (
      platforms?.findIndex((platform) => platform.platform === 'google-ads') ===
        -1 &&
      props.objKey === 'otherCharacteristics'
    ) {
      dispatch(
        goToSpecificCompoHDMClient(
          componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS1]
        )
      );
      isSkip = true;
    } else if (runMontageAds === false && props.objKey === 'runMontageAds') {
      console.log('running..');
      dispatch(
        goToSpecificCompoHDMClient(
          componentWithIndexHDMClient[
            clientDashboardIds.REVIEW_HDMQUESTIONIER_RESPONSES
          ]
        )
      );
      isSkip = true;
    }
    console.log('isSkip:', isSkip);
    return isSkip;
  };

  const skipCompoBack = () => {
    let isSkip = false;
    if (
      platforms?.findIndex(
        (platform) => platform.platform === 'facebook-ads'
      ) === -1 &&
      props.objKey === 'ageRange'
    ) {
      dispatch(
        goToSpecificCompoHDMClient(
          componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING12]
        )
      );
      isSkip = true;
    } else if (
      platforms?.findIndex((platform) => platform.platform === 'google-ads') ===
        -1 &&
      props.objKey === 'productandservice'
    ) {
      dispatch(
        goToSpecificCompoHDMClient(
          componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE7]
        )
      );
      isSkip = true;
    }
    return isSkip;
  };

  const onClickHandler = () => {
    if (
      [
        'businessName',
        'preferredPhoneNumber',
        'primaryContactPerson',
        'fbPageUrl',
        'websiteUrl',
        'brandDescription',
        'monthlyRevenue',
        'monthlyRevenueGoal3Months',
        'monthlyRevenueGoal6Months',
        'monthlyRevenueGoal1Year',
        'averageOrderValue',
        'cogsPerAov',
        'shippingCostPerAov',
        'breakEvenPoint',
        'gender',
      ].includes(props.objKey) &&
      props.value === ''
    ) {
      setError(true);
    } else if (['platforms', 'advertisingLocations', 'activitiesInterestHDM'].includes(props.objKey) && !props.value.length) {
      setError(true);
    } else if (
      props.objKey === 'productandservice' &&
      !props.value?.every(
        (value) =>
          !serviceDescValidator(value?.description) &&
          !specialCharValidator(value?.name)
      )
    ) {
      setError(true);
    } else if (
      props.objKey === 'productandservice' &&
      !props.value?.every(
        (value) => value?.description.length && value?.name.length
      )
    ) {
      setError(true);
    } else if (
      props.objKey === 'AgeRange' &&
      (props.value.min === 0 ||
        props.value.min === '' ||
        props.value.max === 0 ||
        props.value.max === '')
    ) {
      setError(true);
    } else if (
      props.objKey === 'AgeRange' &&
      (props.value.min === 0 || props.value.min === '') &&
      (props.value.max === 0 || props.value.max === '')
    ) {
      setError(true);
    } else if (
      props.objKey === 'AgeRange' &&
      !ageValid(props.value.min, props.value.max)
    ) {
      setError(true);
    } else if (
      ['keyCompetitors', 'geoTargets'].includes(props.objKey) &&
      !props.value[props.value.length - 1]?.name
    ) {
      setError(true);
    } else if (
      ['brandsInterests'].includes(props.objKey) &&
      props?.value?.some((value) => value === '')
    ) {
      setError(true);
    } else if (
      props.value !== '' &&
      props.objKey === 'brandDescription' &&
      !brandDescValidator(props.value)
    ) {
      setError(false);
    } else if (
      ['fbPageUrl', 'websiteUrl'].includes(props.objKey) &&
      !urlValidator(props.value)
    ) {
      setError(true);
    } else if (props.objKey === 'preferredEmailAddress' && props.value === '') {
      setError(true);
    } else if (
      'preferredEmailAddress' === props.objKey &&
      props.value !== '' &&
      !emailValidator(props.value)
    ) {
      setError(true);
    } else if (
      props.objKey === 'keyCompetitors' &&
      props.value[props.value.length - 1]?.name &&
      props.value?.some((value) => value?.website.length === 0)
    ) {
      setError(true);
    } else if (
      props.objKey === 'keyCompetitors' &&
      props.value[props.value.length - 1]?.name &&
      props.value?.some((value) => !urlValidator(value?.website))
    ) {
      setError(true);
    } else if (
      props.objKey === 'keyCompetitors' &&
      props.value?.every((value) => !urlValidator(value?.website))
    ) {
      setError(false);
    } else if (
      props.objKey === 'customerBaseCountries' &&
      selectedCountryTags.length === 0
    ) {
      setError(true);
    } else {
      dispatch(updateHDMClientDetails());
      if (
        props.isLD &&
        currentCompoClient ===
          LDcomponentWithIndexClient[
            LDClientDashboardIds.QUEST_FB_TARGETING5
          ] &&
        role === roles.ACCOUNT_MANAGER
      ) {
        dispatch(closeClientView());
      } else if (
        currentCompoClient ===
          componentWithIndexHDMClient[
            clientDashboardIds.CREATIVEANDMONTAGEAD12
          ] &&
        role === roles.ACCOUNT_MANAGER
      ) {
        dispatch(closeHDMClientView());
      } else {
        if (props?.isLD) {
          dispatch(updateCompoReduxClient());
        } else {
          !skipCompoNext() && dispatch(updateHDMCompoReduxClient());
        }
      }
      props?.onNextClickHandler();
      // props?.onNextClickHandler();
    }
    return;
  };
  const StyledCheckbox = withStyles((theme) => ({
    root: {
      color: '#CCCCCC',
      '& .MuiIconButton-label': {
        position: 'relative',
        zIndex: 0,
        background: '#FFFFFF',
        border: '1px solid #CCCCCC',
      },
      '&:not($checked) .MuiIconButton-label:after': {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: -1,
        borderRadius: 12,
      },
    },
  }))(Checkbox);

  const getFileURL = (url) => {
    setFile((prev) => {
      return [...prev, url];
    });
  };

  useEffect(() => {
    if (file.length)
      dispatch(
        updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
          infoType: props.infoType,
          key: props.objKey,
          value: file,
        })
      );
  }, [file]);

  const getFile = (file) => {
    setObjectValue((prev) => {
      return [...prev, file];
    });
  };

  const hitEnter = (e) => {
    if (e.key === 'Enter') {
      onClickHandler();
    }
    return false;
  };

  const platformsObj = {
    'google-ads': {
      imgIcon: googleIcon,
      platform: 'google-ads',
      isSelected: false,
      iconName: 'Google',
    },
    'facebook-ads': {
      imgIcon: facebookIcon,
      platform: 'facebook-ads',
      isSelected: false,
      iconName: 'Facebook',
    },
    'tiktok-ads': {
      imgIcon: tiktokIcon,
      platform: 'tiktok-ads',
      isSelected: false,
      iconName: 'Tiktok',
    },
  };

  const inputComponent = (objType, type, value) => {
    switch (objType) {
      case 'businessName':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            onKeyPress={hitEnter}
          />
        );
      case 'primaryContactPerson':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            onKeyPress={hitEnter}
          />
        );
      case 'fbPageUrl':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            errorText={'Please enter a valid URL'}
            onKeyPress={hitEnter}
          />
        );
      case 'websiteUrl':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            onKeyPress={hitEnter}
          />
        );
      case 'shopifyStoryAvailable':
        return (
          <>
            <FormControl>
              <RadioGroup
                defaultValue={value}
                aria-labelledby='demo-customized-radios'
                name='customized-radios'
                style={{
                  marginLeft: '0.8rem',
                  flexDirection: 'row',
                  gap: '1rem',
                }}
                onChange={(e) => {
                  dispatch(
                    updateHDMClientDetailsReducer(
                      mappedReduxType[props.infoType],
                      {
                        infoType: props.infoType,
                        key: props.objKey,
                        value: JSON.parse(e.target.value.toLowerCase()),
                      }
                    )
                  );
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label='Yes'
                  style={{
                    width: 100,
                    height: 42,
                    background: '#FFFFFF',
                    border: '1px solid #DBDBDB',
                    boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                    borderRadius: 10,
                    paddingLeft: '0.5rem',
                    // marginBottom: '1rem',
                    color: '#7F7D87',
                  }}
                />
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label='No'
                  style={{
                    width: 100,
                    height: 42,
                    background: '#FFFFFF',
                    border: '1px solid #DBDBDB',
                    boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                    borderRadius: 10,
                    paddingLeft: '0.5rem',
                    color: '#7F7D87',
                  }}
                />
              </RadioGroup>
            </FormControl>
          </>
        );

      case 'platforms':
        return (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2rem',
                pointerEvents:
                  localStorage.getItem('role') === roles.ACCOUNT_MANAGER
                    ? 'none'
                    : 'all',
              }}
            >
              {Object.entries(platformsObj).map(([key, platform], index) => (
                <HDMCheckBoxOption
                  imgIcon={platform.imgIcon}
                  iconName={platform.iconName}
                  objKey={objType + ' ' + 'platform'}
                  infoType={type}
                  platforms={platforms}
                  platform={key}
                  isActive={
                    platforms.find((platform) => platform.platform === key)
                      ? true
                      : false
                  }
                  disabled={
                    localStorage.getItem('role') === roles.ACCOUNT_MANAGER
                      ? true
                      : false
                  }
                />
              ))}
            </div>
            {error && (
              <p
                style={{
                  color: 'red',
                }}
              >
                Please select the platform
              </p>
            )}
          </>
        );
      case 'preferredEmailAddress':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            errorText='Please enter a valid email address'
            onKeyPress={hitEnter}
          />
        );

      case 'preferredPhoneNumber':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            onKeyPress={hitEnter}
          />
        );

      case 'streetAddress':
        return (
          <>
            <InputTextField
              width='300px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              multiline={true}
              height='8px'
              paddingBottom='0px'
              onKeyPress={hitEnter}
            />
          </>
        );

      case 'city':
        return (
          <>
            <InputTextField
              width='300px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              multiline={true}
              height='8px'
              paddingBottom='0px'
              onKeyPress={hitEnter}
            />
          </>
        );

      case 'provinceOrState':
        return (
          <>
            <InputTextField
              width='300px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              multiline={true}
              height='8px'
              paddingBottom='0px'
              onKeyPress={hitEnter}
            />
          </>
        );

      case 'postalCode':
        return (
          <>
            <InputTextField
              width='300px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              multiline={true}
              type='number'
              height='8px'
              paddingBottom='0px'
              // number={true}
              onKeyPress={hitEnter}
            />
          </>
        );

      case 'country':
        return (
          <>
            <InputTextField
              width='300px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              multiline={true}
              height='8px'
              paddingBottom='0px'
              onKeyPress={hitEnter}
            />
          </>
        );

      case 'ageRange':
        return (
          <AgeRangeCheckboxes
            value={value}
            objKey='ageRange'
            maxObjKey='maxAgeRange'
            minObjKey='minAgeRange'
            intakeForm={true}
            minAgeValue={
              reduxStates['hdmClient']['onboardingInfo']['googleTargeting'][
                'minAgeRange'
              ]
            }
            maxAgeValue={
              reduxStates['hdmClient']['onboardingInfo']['googleTargeting'][
                'maxAgeRange'
              ]
            }
            infoType={type}
            error={error}
            errorText={'Please select the valid option'}
            outerKey={props.outerKey}
          />
        );

      case 'geoTargets':
        return (
          <LocationAutoCompleteField
            value={value}
            type={type}
            objType={objType}
            placeholder={'Enter Location ...'}
            error={error ? true : false}
          />
        );

      // case "maritalStatus":
      //   return (
      //     <MaritalStatusCheckbox
      //       value={value}
      //       objKey={objType}
      //       infoType={type}
      //       outerKey={props.outerKey}
      //     />
      //   );

      case 'activitiesInterest':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            onKeyPress={hitEnter}
          />
        );
      case 'excludeAudiences':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            onKeyPress={hitEnter}
          />
        );

      case 'customerIncomeRange':
        return (
          <TargetCustomerIncome
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={props.outerKey}
            error={error}
            errorText={'Please select the valid options'}
          />
        );

      // case "customerEducation":
      //   return (
      //     <HighestDegreeCheckBox
      //       value={value}
      //       objKey={objType}
      //       infoType={type}
      //       outerKey={props.outerKey}
      //       error={error}
      //       errorText={"Please select the valid options"}
      //     />
      //   );
      case 'customerEmployment':
        return (
          <CustomerEmploymentCheckbox
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={props.outerKey}
            error={error}
            errorText={'Please select the valid options'}
          />
        );
      case 'esthetic':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            multiline={true}
            error={error ? true : false}
            onKeyPress={hitEnter}
          />
        );
      case 'tagline':
        return (
          <InputTextField
            width='600px'
            objKey={objType}
            infoType={type}
            value={value}
            multiline={true}
            error={error ? true : false}
            onKeyPress={hitEnter}
          />
        );
      case 'emotionalResponse':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              multiline={true}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'feelWithBrand':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'engageWithBrand':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'uniqueSellingPoint':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'whatSetsYouApart':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'benefitsOfProducts':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'painPoints':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'storyToPromote':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'brandMessagingExampels':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'brandFont':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'customerBaseCountries':
        return (
          <>
            <LocationAutoComplete
              selectedTags={selectedCountryTags}
              setSelectedTags={setSelectedCountryTags}
              placeholder={'Enter Location ...'}
            />
            {error && (
              <p
                style={{
                  color: 'red',
                }}
              >
                Please select a country
              </p>
            )}
          </>
        );
      case 'brandColor':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'logoLink':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'AgeRange':
        return (
          <>
            <InputTextField
              width='200px'
              objKey={objType}
              infoType={type}
              type='number'
              value={value.min}
              placeholder='MIN Age'
              input={'min'}
              error={!ageValid(value.min, value.max, 'minAge')}
            />
            <span
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '20px',
                lineHeight: '24px',
                color: '#000000',
                margin: '25px',
              }}
            >
              to
            </span>
            <InputTextField
              width='200px'
              objKey={objType}
              infoType={type}
              value={value.max}
              placeholder='MAX Age'
              input={'max'}
              error={!ageValid(value.min, value.max, 'maxAge')}
              onKeyPress={hitEnter}
            />
            {error && (
              <p
                style={{
                  color: 'red',
                }}
              >
                Please enter the valid age range (accepted range - 13 years to
                65 years)
              </p>
            )}
          </>
        );
      case 'gender':
        return (
          // <div style={{ display: 'flex' }}>
          //   <div
          //     style={{
          //       fontFamily: 'Lato',
          //       fontStyle: 'normal',
          //       fontWeight: 400,
          //       fontSize: 16,
          //       color: '#7F7D87',
          //       background: '#FFFFFF',
          //       border: '1px solid #DBDBDB',
          //       boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
          //       borderRadius: 8,
          //       width: 150,
          //       alignItems: 'center',
          //       padding: '0.25rem 0.25rem',
          //     }}
          //   >
          //     <StyledCheckbox
          //       value='Male'
          //       checked={props.value === 'Male'}
          //       onClick={(e) => {
          //         dispatch(
          //           updateHDMClientDetailsReducer(
          //             mappedReduxType[props.infoType],
          //             {
          //               infoType: props.infoType,
          //               key: props.objKey,
          //               value: e.target.value,
          //             }
          //           )
          //         );
          //       }}
          //       size='small'
          //     />{' '}
          //     <Typography
          //       style={{
          //         fontFamily: 'Lato',
          //         fontStyle: 'normal',
          //         fontWeight: 400,
          //         fontSize: 16,
          //         display: 'inline',
          //         color: '#7F7D87',
          //       }}
          //     >
          //       Male
          //     </Typography>
          //   </div>
          //   <div
          //     style={{
          //       fontFamily: 'Lato',
          //       fontStyle: 'normal',
          //       fontWeight: 400,
          //       fontSize: 16,
          //       color: '#7F7D87',
          //       background: '#FFFFFF',
          //       border: '1px solid #DBDBDB',
          //       boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
          //       borderRadius: 8,
          //       width: 150,
          //       alignItems: 'center',
          //       padding: '0.25rem 0.25rem',
          //       marginLeft: '20px',
          //     }}
          //   >
          //     <StyledCheckbox
          //       value='Female'
          //       checked={props.value === 'Female'}
          //       onClick={(e) => {
          //         dispatch(
          //           updateHDMClientDetailsReducer(
          //             mappedReduxType[props.infoType],
          //             {
          //               infoType: props.infoType,
          //               key: props.objKey,
          //               value: e.target.value,
          //             }
          //           )
          //         );
          //       }}
          //       size='small'
          //     />{' '}
          //     <Typography
          //       style={{
          //         fontFamily: 'Lato',
          //         fontStyle: 'normal',
          //         fontWeight: 400,
          //         fontSize: 16,
          //         display: 'inline',
          //         color: '#7F7D87',
          //       }}
          //     >
          //       Female
          //     </Typography>
          //   </div>

          //   <div
          //     style={{
          //       fontFamily: 'Lato',
          //       fontStyle: 'normal',
          //       fontWeight: 400,
          //       fontSize: 16,
          //       color: '#7F7D87',
          //       background: '#FFFFFF',
          //       border: '1px solid #DBDBDB',
          //       boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
          //       borderRadius: 8,
          //       width: 150,
          //       alignItems: 'center',
          //       padding: '0.25rem 0.25rem',
          //       marginLeft: '20px',
          //     }}
          //   >
          //     <StyledCheckbox
          //       value='Both'
          //       checked={props.value === 'Both'}
          //       onClick={(e) => {
          //         dispatch(
          //           updateHDMClientDetailsReducer(
          //             mappedReduxType[props.infoType],
          //             {
          //               infoType: props.infoType,
          //               key: props.objKey,
          //               value: e.target.value,
          //             }
          //           )
          //         );
          //       }}
          //       size='small'
          //     />{' '}
          //     <Typography
          //       style={{
          //         fontFamily: 'Lato',
          //         fontStyle: 'normal',
          //         fontWeight: 400,
          //         fontSize: 16,
          //         display: 'inline',
          //         color: '#7F7D87',
          //       }}
          //     >
          //       Both
          //     </Typography>
          //   </div>
          // </div>
          <>
            <FormControl>
              <RadioGroup
                defaultValue={value}
                aria-labelledby='demo-customized-radios'
                name='customized-radios'
                style={{
                  marginLeft: '0.8rem',
                  flexDirection: 'row',
                  gap: '1rem',
                }}
                onChange={(e) => {
                  dispatch(
                    updateHDMClientDetailsReducer(
                      mappedReduxType[props.infoType],
                      {
                        infoType: props.infoType,
                        key: props.objKey,
                        value: e.target.value,
                      }
                    )
                  );
                }}
              >
                <FormControlLabel
                  value='Male'
                  control={<BpRadio />}
                  label='Male'
                  style={{
                    width: 100,
                    height: 42,
                    background: '#FFFFFF',
                    border: '1px solid #DBDBDB',
                    boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                    borderRadius: 10,
                    paddingLeft: '0.5rem',
                    // marginBottom: '1rem',
                    color: '#7F7D87',
                  }}
                />
                <FormControlLabel
                  value='Female'
                  control={<BpRadio />}
                  label='Female'
                  style={{
                    width: 120,
                    height: 42,
                    background: '#FFFFFF',
                    border: '1px solid #DBDBDB',
                    boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                    borderRadius: 10,
                    paddingLeft: '0.5rem',
                    color: '#7F7D87',
                  }}
                />
                <FormControlLabel
                  value='Both'
                  control={<BpRadio />}
                  label='Both'
                  style={{
                    width: 100,
                    height: 42,
                    background: '#FFFFFF',
                    border: '1px solid #DBDBDB',
                    boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                    borderRadius: 10,
                    paddingLeft: '0.5rem',
                    color: '#7F7D87',
                  }}
                />
              </RadioGroup>
            </FormControl>
            {error && (
              <p
                style={{
                  color: 'red',
                }}
              >
                Please enter the mandatory field
              </p>
            )}
          </>
        );
      case 'advertisingLocations':
        return (
          <HDMLocationAutoComplete
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            onKeyPress={hitEnter}
          />
        );
      case 'activitiesInterestHDM':
        return (
          <HDMInterestAutoComplete
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            onKeyPress={hitEnter}
          />
        );
      case 'excludes':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'formalCustomerPersonas':
        return (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
                width: '100%',
              }}
            >
              {objValue.map((element, index) => {
                return (
                  <div
                    style={{
                      width: '40%',
                      height: '150px',
                      position: 'relative',
                    }}
                    key={index}
                  >
                    <img
                      src={element}
                      style={{
                        width: 'inherit',
                        objectFit: 'contain',
                        height: '100%',
                      }}
                      alt=''
                    />
                    <div
                      style={{
                        background: 'white',
                        position: 'absolute',
                        left: '-0.5rem',
                        top: '-0.5rem',
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                      }}
                    >
                      <Close
                        color='warning'
                        fontSize='small'
                        onClick={() => {
                          setObjectValue((currentImg) =>
                            currentImg.filter((img, i) => i !== index)
                          );
                          setFile((currentImg) =>
                            currentImg.filter((img, i) => i !== index)
                          );
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <StyledDrop setObj={getFile} setFile={getFileURL} />
            </div>
          </>
        );
      case 'otherCharacteristics':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'productandservice':
        return (
          <ProductServiceInform
            value={value}
            type={type}
            objType={objType}
            error={error}
          />
        );
      case 'noPromotionProduct':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'offers':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'averageOrderValue':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'cogsPerAov': //work
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'shippingCostPerAov':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'breakEvenPoint':
        return (
          <>
            {' '}
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'fbCamaignBudget':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'googleCamaignBudget':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'tiktokCamaignBudget':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'countryTargeted':
        return (
          <>
            <div
              style={{
                display: 'flex',
                marginBottom: '1rem',
              }}
            >
              {countriesCheckBox.map((text, index) => {
                return (
                  <div className='custom_chips'>
                    <span>{text}</span>
                    <span
                      className='close_tag'
                      onClick={() => {
                        dispatch({
                          type: 'UPDATE_MULTIPLE_CHECKBOX_HDM',
                          payload: {
                            key: objType,
                            infoType: type,
                            value: text,
                            element: countriesCheckBox,
                          },
                        });
                      }}
                    ></span>
                  </div>
                );
              })}
            </div>

            <div
              style={{
                border: '0.5 solid #E5E5E5',

                boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.05)',
                borderRadius: 8,
                width: 600,
              }}
            >
              <div style={{ background: '#FFFFFF' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    background: '#FFFFFF',
                    paddingLeft: '24.75px',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    // marginBottom: '22px',
                  }}
                >
                  <img src={MAG} alt='' />
                  <TextField
                    variant='standard'
                    type='search'
                    placeholder='Search'
                    style={{
                      width: '100%',
                      border: 'transparent',
                      marginLeft: '15.25px',
                    }}
                    InputProps={{ disableUnderline: true }}
                    onChange={(e) => setCountryTarget(e.target.value)}
                  ></TextField>
                </div>
              </div>

              {countryTarget.length === 0
                ? []
                : sourcesOfLeads
                    .filter(
                      (text) =>
                        text
                          .toLowerCase()
                          .search(countryTarget.toLocaleLowerCase()) !== -1
                    )
                    .map((element) => {
                      return (
                        <>
                          <div
                            style={{
                              fontFamily: 'Lato',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 16,
                              color: '#7F7D87',
                              background: '#FFFFFF',
                              alignItems: 'center',
                              padding: '0.25rem 0.25rem',
                              paddingLeft: '24.75px',
                            }}
                          >
                            {' '}
                            <StyledCheckbox
                              value={element}
                              checked={
                                countriesCheckBox.find((q) => q === element)
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                dispatch({
                                  type: 'UPDATE_MULTIPLE_CHECKBOX_HDM',
                                  payload: {
                                    key: objType,
                                    infoType: type,
                                    value: e.target.value,
                                    element: countriesCheckBox,
                                  },
                                });
                              }}
                              size='small'
                            />
                            <Typography
                              style={{
                                fontFamily: 'Lato',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 16,
                                display: 'inline',
                                color: '#949494',
                              }}
                            >
                              {element}
                            </Typography>
                          </div>
                        </>
                      );
                    })}
            </div>
          </>
        );
      case 'brandDescription':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={!brandDescValidator(value)}
              errorText={'Min character limit : 90'}
              onKeyPress={hitEnter}
            />
            {error && value.length === 0 && (
              <p
                style={{
                  color: 'red',
                }}
              >
                Please enter empty field
              </p>
            )}
          </>
        );
      case 'keyCompetitors':
        return (
          <>
            {value.map((d, index) => (
              <div
                style={{
                  display: 'flex',
                  gap: '2rem',
                  marginBottom: '1.5rem',
                  position: 'relative',
                }}
                key={index}
              >
                <InputTextField
                  width='300px'
                  placeholder='Enter competitor name ...'
                  objKey={objType}
                  infoType={type}
                  value={d.name}
                  arrayIndex={index}
                  arrayValue={value}
                  field={'name'}
                />
                <InputTextField
                  width='300px'
                  placeholder='Enter website ...'
                  objKey={objType}
                  infoType={type}
                  value={d.website}
                  error={!urlValidator(d.website) && d.website.length !== 0}
                  arrayIndex={index}
                  arrayValue={value}
                  field={'website'}
                  errorText={'Please enter a valid website'}
                />
                {index > 0 && (
                  <img
                    src={trashIcon}
                    alt='delete'
                    onClick={() =>
                      dispatch({
                        type: 'DELETE_KEY_COMPETITTOR_FIELD',
                        payload: index,
                      })
                    }
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </div>
            ))}
            <div
              style={{
                background: '#E3EEFF',
                borderRadius: 6,
                padding: '3px 9px',
                display: 'inline-block',
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 11,
                  color:
                    value[value.length - 1].name &&
                    value[value.length - 1].website
                      ? '#0869FB'
                      : 'gray',
                  cursor:
                    value[value.length - 1].name &&
                    value[value.length - 1].website
                      ? 'pointer'
                      : 'not-allowed',
                }}
                onClick={() =>
                  value[value.length - 1].name &&
                  value[value.length - 1].website
                    ? dispatch({ type: 'ADD_NEW_FIELD_IN_KEY_COMPETITORS' })
                    : ''
                }
              >
                + ADD FIELD
              </Typography>
            </div>
            {error && value?.some((value) => value?.website.length === 0) && (
              <p
                style={{
                  color: 'red',
                }}
              >
                Please enter empty field
              </p>
            )}
          </>
        );
      case 'competitorsIntake':

      case 'brandsInterests': //brandsinterest
        return (
          <>
            {value.map((element, index) => {
              return (
                <>
                  <div
                    style={{
                      display: 'inline',
                      marginRight: '2rem',
                    }}
                    key={index}
                  >
                    <InputTextField
                      width='300px'
                      objKey={objType}
                      infoType={type}
                      value={element}
                      placeholder='Enter Name...'
                      arrayIndex={index}
                      arrayValue={value}
                      onKeyPress={hitEnter}
                    />
                    {index > 3 && (
                      <img
                        src={trashIcon}
                        alt='delete'
                        onClick={() =>
                          dispatch({
                            type: 'DELETE_NEW_FIELD_IN_BRAND_INTEREST',
                            payload: index,
                          })
                        }
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                          right: index % 2 === 0 ? '400px' : '50px',
                          float: 'right',
                        }}
                      />
                    )}
                  </div>
                </>
              );
            })}
            <div
              style={{
                background: '#E3EEFF',
                borderRadius: 6,
                padding: '3px 9px',
                width: 90,
                marginTop: 10,
              }}
            >
              <Typography
                style={{
                  fontFamily: 'Lato',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 12,
                  color: value[value.length - 1] ? '#0869FB' : 'gray',
                  cursor: value[value.length - 1] ? 'pointer' : 'not-allowed',
                }}
                onClick={() => {
                  if (value[value.length - 1])
                    dispatch({
                      type: 'ADD_NEW_FIELD_IN_BRAND_INTEREST',
                    });
                }}
              >
                + ADD FIELD
              </Typography>
            </div>
            {error && (
              <p
                style={{
                  color: 'red',
                }}
              >
                Please enter empty field
              </p>
            )}
          </>
        );
      case 'commonJargons':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'monthlyRevenue':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'monthlyRevenueGoal3Months':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'monthlyRevenueGoal6Months':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'monthlyRevenueGoal1Year':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              type={'number'}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'maximizeEnterpriseValueProfit':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'requirementsForUs':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'dobHomeAddress':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'runMontageAds':
        return (
          <>
            <FormControl>
              <RadioGroup
                defaultValue={true}
                value={value}
                aria-labelledby='demo-customized-radios'
                name='customized-radios'
                style={{
                  marginLeft: '0.8rem',
                  flexDirection: 'row',
                  gap: '1rem',
                }}
                onChange={(e) => {
                  dispatch(
                    updateHDMClientDetailsReducer(
                      mappedReduxType[props.infoType],
                      {
                        infoType: props.infoType,
                        key: props.objKey,
                        value: JSON.parse(e.target.value.toLowerCase()),
                      }
                    )
                  );
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label='Yes'
                  style={{
                    width: 100,
                    height: 42,
                    background: '#FFFFFF',
                    border: '1px solid #DBDBDB',
                    boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                    borderRadius: 10,
                    paddingLeft: '0.5rem',
                    // marginBottom: '1rem',
                    color: '#7F7D87',
                  }}
                />
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label='No'
                  style={{
                    width: 100,
                    height: 42,
                    background: '#FFFFFF',
                    border: '1px solid #DBDBDB',
                    boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                    borderRadius: 10,
                    paddingLeft: '0.5rem',
                    color: '#7F7D87',
                  }}
                />
              </RadioGroup>
            </FormControl>
          </>
        );
      case 'videoReviews':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'googleDriveEmail':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'googleDriveLink':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'interestedMontageAds':
        return (
          <>
            <FormControl>
              <RadioGroup
                defaultValue={value}
                aria-labelledby='demo-customized-radios'
                name='customized-radios'
                style={{
                  marginLeft: '0.8rem',
                  flexDirection: 'row',
                  gap: '1rem',
                }}
                onChange={(e) => {
                  dispatch(
                    updateHDMClientDetailsReducer(
                      mappedReduxType[props.infoType],
                      {
                        infoType: props.infoType,
                        key: props.objKey,
                        value: JSON.parse(e.target.value.toLowerCase()),
                      }
                    )
                  );
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label='Yes'
                  style={{
                    width: 100,
                    height: 42,
                    background: '#FFFFFF',
                    border: '1px solid #DBDBDB',
                    boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                    borderRadius: 10,
                    paddingLeft: '0.5rem',
                    // marginBottom: '1rem',
                    color: '#7F7D87',
                  }}
                />
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label='No'
                  style={{
                    width: 100,
                    height: 42,
                    background: '#FFFFFF',
                    border: '1px solid #DBDBDB',
                    boxShadow: '0px 15px 53px rgba(30, 20, 165, 0.03)',
                    borderRadius: 10,
                    paddingLeft: '0.5rem',
                    color: '#7F7D87',
                  }}
                />
              </RadioGroup>
            </FormControl>
          </>
        );
      case 'pocForProductDeliveries':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'products':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'creatorPersona':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'shootNotes':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              multiline={true}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'desiredCta':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'promoCode':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              multiline={true}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'uspOfProductsSent':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'visualReferences':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      case 'others':
        return (
          <>
            <InputTextField
              width='600px'
              objKey={objType}
              infoType={type}
              multiline={true}
              value={value}
              error={error ? true : false}
              onKeyPress={hitEnter}
            />
          </>
        );
      default:
        <></>;
    }
  };

  useEffect(() => {
    if (props.objKey === 'formalCustomerPersonas') {
      const images = props.images.map(async (image) => {
        return getImageUrl(image);
      });
      Promise.allSettled(images).then((res) => {
        res.map((ele) => {
          setObjectValue((prev) => {
            return [...prev, URL.createObjectURL(ele.value)];
          });
        });
      });
    }
  }, [props.objKey]);

  return (
    <div
      className={`slide_question ${props.bgAnimate ? 'animate' : ''} question-${
        mappedQuestion[props.infoType][props.objKey]['questNumber'] % 2 == 0
          ? 'even'
          : 'odd'
      }`}
      style={{
        width: '100%',
        borderRadius: '8px',
        position: 'relative',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: '#fff',
        backgroundImage: 'url(' + backgroundImage2 + ')',
        backgroundSize: 'cover',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: `${widthMoved}%`,
          height: 6,
          background: '#5498FF',
          borderRadius: 30,
          transition: '0.3s ease',
        }}
      ></div>
      <Slide
        direction='up'
        in={props.slideIn}
        // container={containerRef.current}
        easing={{
          enter: 'linear',
          exit: 'linear',
        }}
        timeout={{
          enter: 500,
          exit: 0,
        }}
        onEntering={(node, done) => {
          props.setBgAnimate(true);
        }}
        onEntered={(node, done) => {
          // setBgAnimate(false)
        }}
        onExited={(node) => {
          props.setBgAnimate(false);
        }}
      >
        <div
          style={{
            width: '100%',
            height: '80vh',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1',
            overflow: 'hidden',
          }}
        >
          {!props.multiQuestion && (
            <div
              className='question-container'
              style={{
                position: 'relative',
              }}
            >
              <div style={{ position: 'relative' }}>
                {mappedQuestion[props.infoType][props.objKey]['icon'] && (
                  <img
                    src={mappedQuestion[props.infoType][props.objKey]['icon']}
                    alt=''
                    style={{
                      position: 'absolute',
                      bottom: '-1.25rem',
                      left: '-0.5rem',
                    }}
                  />
                )}
              </div>
              <div className='question'>
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontstyle: 'normal',
                    fontWeight: 400,
                    fontSize: 12,
                    // line-height: 20px
                    color: '#797979',
                    marginBottom: '0.5rem',
                  }}
                >
                  QUESTION{' '}
                  {mappedQuestion[props.infoType][props.objKey]['questNumber']}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 26,
                    // lineHeight: 31,
                    color: '#000000',
                    marginBottom: '0.5rem',
                  }}
                >
                  {mappedQuestion[props.infoType][props.objKey]['questName']}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 18,
                    // lineHeight: 24,
                    color: '#000000',
                  }}
                >
                  {mappedQuestion[props.infoType][props.objKey]['question']}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 16,
                    color: '#6C6C6C',
                    marginBottom: '1.5rem',
                  }}
                >
                  {mappedQuestion[props.infoType][props.objKey]['questDescrip']}
                </Typography>
                {inputComponent(props.objKey, props.infoType, props.value)}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <PrimaryButton
                  // title='Next'
                  title={
                    props.isLD &&
                    currentCompoClient ===
                      LDcomponentWithIndexClient[
                        LDClientDashboardIds.QUEST_FB_TARGETING5
                      ]
                      ? 'Finish'
                      : 'Next'
                  }
                  type='corner'
                  onClick={onClickHandler}
                />
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 200,
                    fontSize: 12,
                    alignSelf: 'center',
                    // line-height: 20px;
                  }}
                >
                  Hit ENTER
                </Typography>
              </div>
            </div>
          )}
          {props.multiQuestion && (
            <div
              className='question-container'
              style={{
                position: 'relative',
              }}
            >
              <div className='question'>
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontstyle: 'normal',
                    fontWeight: 400,
                    fontSize: 12,
                    // line-height: 20px
                    color: '#797979',
                    marginBottom: '0.5rem',
                  }}
                >
                  QUESTION{' '}
                  {mappedQuestion[props.infoType][props.objKey]['questNumber']}
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 22,
                    color: '#000000',
                    marginBottom: '0.5rem',
                  }}
                >
                  {mappedQuestion[props.infoType][props.objKey]['questName']}
                </Typography>
                {props?.flex && (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '16px',
                    }}
                  >
                    {mappedQuestion[props.infoType][props.objKey][
                      'questions'
                    ].map((element) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: 'Lato',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 18,
                              color: '#000000',
                              marginTop: '1.5rem',
                            }}
                          >
                            {element.question}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Lato',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: 16,
                              color: '#6C6C6C',
                              marginBottom: '0.5rem',
                            }}
                          >
                            {element.questDescrip}
                          </Typography>
                          {inputComponent(
                            element.valueKey,
                            props.infoType,
                            reduxStates['hdmClient']['onboardingInfo'][
                              props.infoType
                            ][element.valueKey]
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
                {!props?.flex && (
                  <>
                    {mappedQuestion[props.infoType][props.objKey][
                      'questions'
                    ].map((element) => {
                      return (
                        <>
                          <Typography
                            style={{
                              fontFamily: 'Lato',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 18,
                              color: '#000000',
                              marginTop: '1.5rem',
                            }}
                          >
                            {element.question}
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Lato',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: 16,
                              color: '#6C6C6C',
                              marginBottom: '0.5rem',
                            }}
                          >
                            {element.questDescrip}
                          </Typography>
                          {inputComponent(
                            element.valueKey,
                            props.infoType,
                            reduxStates['hdmClient']['onboardingInfo'][
                              props.infoType
                            ][element.valueKey]
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <PrimaryButton
                  title='Next'
                  type='corner'
                  onClick={onClickHandler}
                />
                <Typography
                  style={{
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 200,
                    fontSize: 12,
                    alignSelf: 'center',
                    // line-height: 20px;
                  }}
                >
                  Hit ENTER
                </Typography>
              </div>
            </div>
          )}
        </div>
      </Slide>
      {!props.isLD ? (
        <>
          {menuVisible && (
            <HDMMenu
              questions={Object.values(
                props?.isLD
                  ? mappedFbTargetingQuestion[
                      props.infoType === 'aboutBusiness'
                        ? 'facebookTargeting'
                        : props.infoType
                    ]
                  : mappedQuestion[props.infoType]
              )}
              currentCompoClient={currentCompoClient}
              visitedLastTime={visitedLastTime}
              isLD={props?.isLD}
              isAM={props.isAM}
            />
          )}
          <div className='question-nav-container'>
            <div
              className='questionNavIconStyle'
              onClick={() => {
                props?.onPreviewScreenClickHandler();
                // if (props?.isLD) {
                // } else {
                //   role === roles.ACCOUNT_MANAGER
                //     ? dispatch(closeHDMClientView())
                //     : dispatch(
                //         goToReviewHDMQuestionier({
                //           lastActiveComp: currentCompoClient,
                //           currentComp:
                //             componentWithIndexHDMClient[
                //               clientDashboardIds.REVIEW_HDMQUESTIONIER_RESPONSES
                //             ],
                //           currentHeaderTab: null,
                //         })
                //       );
                // }
              }}
            >
              <img src={PreviewIcon} alt='' />
            </div>
            <div
              className='questionNavIconStyle'
              onClick={() => setMenuVisible(!menuVisible)}
            >
              <img src={menuIcon} alt='' />
            </div>
            <div
              // className='questionNavIconStyle'
              onClick={() => {
                if (
                  ['uniqueSellingPoint'].includes(props.objKey) &&
                  props.value === ''
                ) {
                  setError(true);
                } else if (props.objKey === 'gender' && props.value === '') {
                  setError(true);
                  return;
                } else if (
                  ['fbPageUrl', 'websiteUrl'].includes(props.objKey) &&
                  !urlValidator(props.value)
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'preferredEmailAddress' &&
                  props.value === ''
                ) {
                  setError(true);
                } else if (
                  'preferredEmailAddress' === props.objKey &&
                  props.value !== '' &&
                  !emailValidator(props.value)
                ) {
                  setError(true);
                } else if (
                  [
                    'businessName',
                    'preferredEmailAddress',
                    'preferredPhoneNumber',
                    'primaryContactPerson',
                    'averageOrderValue',
                    'cogsPerAov',
                    'shippingCostPerAov',
                    'breakEvenPoint',
                    'brandDescription',
                    'keyCompetitors',
                    'brandsInterests',
                    'monthlyRevenue',
                    'monthlyRevenueGoal3Months',
                    'monthlyRevenueGoal6Months',
                    'monthlyRevenueGoal1Year',
                  ].includes(props.objKey) &&
                  props.value === ''
                ) {
                  setError(true);
                } else if (
                 ['platforms', 'advertisingLocations', 'activitiesInterestHDM'].includes(props.objKey) &&
                  !props.value.length
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'productandservice' &&
                  !props.value?.every(
                    (value) =>
                      !serviceDescValidator(value?.description) &&
                      !specialCharValidator(value?.name)
                  )
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'productandservice' &&
                  !props.value?.every(
                    (value) => value?.description.length && value?.name.length
                  )
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'AgeRange' &&
                  (props.value.min === 0 ||
                    props.value.min === '' ||
                    props.value.max === 0 ||
                    props.value.max === '')
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'AgeRange' &&
                  (props.value.min === 0 || props.value.min === '') &&
                  (props.value.max === 0 || props.value.max === '')
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'AgeRange' &&
                  !ageValid(props.value.min, props.value.max)
                ) {
                  setError(true);
                } else if (
                  [
                    'activitiesInterest',
                    'keyCompetitors',
                    'geoTargets',
                  ].includes(props.objKey) &&
                  !props.value[props.value.length - 1]?.name
                ) {
                  setError(true);
                } else if (
                  ['brandsInterests'].includes(props.objKey) &&
                  props?.value?.some((value) => value === '')
                ) {
                  setError(true);
                } else if (
                  props.value !== '' &&
                  props.objKey === 'brandDescription' &&
                  !brandDescValidator(props.value)
                ) {
                  setError(false);
                  props?.onNextClickHandler();
                } else if (
                  props.objKey === 'keyCompetitors' &&
                  props.value?.every((value) => !urlValidator(value?.website))
                ) {
                  setError(false);
                  props?.onNextClickHandler();
                } else if (
                  props.objKey === 'keyCompetitors' &&
                  props.value[props.value.length - 1]?.name &&
                  props.value?.some((value) => value?.website.length === 0)
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'keyCompetitors' &&
                  props.value[props.value.length - 1]?.name &&
                  props.value?.some((value) => !urlValidator(value?.website))
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'keyCompetitors' &&
                  props.value?.every((value) => !urlValidator(value?.website))
                ) {
                  setError(false);
                  props?.onNextClickHandler();
                } else {
                  setError(false);
                  props?.onNextClickHandler();
                  if (
                    currentCompoClient === 59 &&
                    role === roles.ACCOUNT_MANAGER
                  ) {
                    dispatch(closeHDMClientView());
                  } else {
                    dispatch(
                      props.isLD
                        ? updateCompoReduxClient()
                        : !skipCompoNext() && updateHDMCompoReduxClient()
                    );
                  }
                }
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  color: '#0869FB',
                  width: '135px',
                  cursor: 'pointer',
                }}
              >
                Next Question
                <img src={rightArrow} alt='' />
              </div>
            </div>
          </div>
          <div
            style={{
              position: 'fixed',
              left: '20.3rem',
              bottom: '1.9rem',
              cursor: 'pointer',
              zIndex: '03',
              width: '160px',
            }}
            onClick={() => {
              setError(false);
              // if (props.isLD) {
              //   dispatch(goBackClientCompo());
              // } else if (
              //   !(
              //     role === roles.ACCOUNT_MANAGER &&
              //     currentCompoClient ===
              //       componentWithIndexHDMClient[
              //         clientDashboardIds.CLIENTANDACCESS1
              //       ]
              //   ) &&
              //   !skipCompoBack()
              // ) {
              //   dispatch(goBackHDMClientCompo());
              // }
              props?.onUpClickHandler();
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: '#0869FB',
              }}
            >
              <img src={leftArrow} alt='' />
              <span>Previous Question</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='question-nav-container'>
            <div
              className='questionNavIconStyle'
              onClick={() => {
                props?.onPreviewScreenClickHandler();
                // if (props?.isLD) {
                // } else {
                //   role === roles.ACCOUNT_MANAGER
                //     ? dispatch(closeHDMClientView())
                //     : dispatch(
                //         goToReviewHDMQuestionier({
                //           lastActiveComp: currentCompoClient,
                //           currentComp:
                //             componentWithIndexHDMClient[
                //               clientDashboardIds.REVIEW_HDMQUESTIONIER_RESPONSES
                //             ],
                //           currentHeaderTab: null,
                //         })
                //       );
                // }
              }}
            >
              <img src={PreviewIcon} alt='' />
            </div>
            <div
              className='questionNavIconStyle'
              onClick={() => setMenuVisible(!menuVisible)}
            >
              <img src={menuIcon} alt='' />
            </div>
            <div
              // className='questionNavIconStyle'
              onClick={() => {
                if (
                  ['uniqueSellingPoint'].includes(props.objKey) &&
                  props.value === ''
                ) {
                  setError(true);
                } else if (props.objKey === 'gender' && props.value === '') {
                  setError(true);
                  return;
                } else if (
                  ['fbPageUrl', 'websiteUrl'].includes(props.objKey) &&
                  !urlValidator(props.value)
                ) {
                  setError(true);
                } else if (
                  [
                    'businessName',
                    'primaryContactPerson',
                    'averageOrderValue',
                    'cogsPerAov',
                    'shippingCostPerAov',
                    'breakEvenPoint',
                    'brandDescription',
                    'keyCompetitors',
                    'brandsInterests',
                    'monthlyRevenue',
                    'monthlyRevenueGoal3Months',
                    'monthlyRevenueGoal6Months',
                    'monthlyRevenueGoal1Year',
                  ].includes(props.objKey) &&
                  props.value === ''
                ) {
                  setError(true);
                } else if (
                  ['platforms', 'advertisingLocations', 'activitiesInterestHDM'].includes(props.objKey) &&
                  !props.value.length
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'productandservice' &&
                  !props.value?.every(
                    (value) =>
                      !serviceDescValidator(value?.description) &&
                      !specialCharValidator(value?.name)
                  )
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'productandservice' &&
                  !props.value?.every(
                    (value) => value?.description.length && value?.name.length
                  )
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'AgeRange' &&
                  (props.value.min === 0 ||
                    props.value.min === '' ||
                    props.value.max === 0 ||
                    props.value.max === '')
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'AgeRange' &&
                  (props.value.min === 0 || props.value.min === '') &&
                  (props.value.max === 0 || props.value.max === '')
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'AgeRange' &&
                  !ageValid(props.value.min, props.value.max)
                ) {
                  setError(true);
                } else if (
                  [
                    'activitiesInterest',
                    'keyCompetitors',
                    'geoTargets',
                  ].includes(props.objKey) &&
                  !props.value[props.value.length - 1]?.name
                ) {
                  setError(true);
                } else if (
                  ['brandsInterests'].includes(props.objKey) &&
                  props?.value?.some((value) => value === '')
                ) {
                  setError(true);
                } else if (
                  props.value !== '' &&
                  props.objKey === 'brandDescription' &&
                  !brandDescValidator(props.value)
                ) {
                  setError(false);
                  props?.onNextClickHandler();
                } else if (
                  props.objKey === 'keyCompetitors' &&
                  props.value?.every((value) => !urlValidator(value?.website))
                ) {
                  setError(false);
                  props?.onNextClickHandler();
                } else if (
                  props.objKey === 'keyCompetitors' &&
                  props.value[props.value.length - 1]?.name &&
                  props.value?.some((value) => value?.website.length === 0)
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'keyCompetitors' &&
                  props.value[props.value.length - 1]?.name &&
                  props.value?.some((value) => !urlValidator(value?.website))
                ) {
                  setError(true);
                } else if (
                  props.objKey === 'keyCompetitors' &&
                  props.value?.every((value) => !urlValidator(value?.website))
                ) {
                  setError(false);
                  props?.onNextClickHandler();
                } else {
                  setError(false);
                  props?.onNextClickHandler();
                  if (
                    currentCompoClient === 59 &&
                    role === roles.ACCOUNT_MANAGER
                  ) {
                    dispatch(closeHDMClientView());
                  } else {
                    dispatch(
                      props.isLD
                        ? updateCompoReduxClient()
                        : !skipCompoNext() && updateHDMCompoReduxClient()
                    );
                  }
                }
              }}
            >
              <img src={arrowDown} alt='' />
            </div>
            <div
              className='questionNavIconStyle'
              onClick={() => {
                setError(false);
                // if (props.isLD) {
                //   dispatch(goBackClientCompo());
                // } else if (
                //   !(
                //     role === roles.ACCOUNT_MANAGER &&
                //     currentCompoClient ===
                //       componentWithIndexHDMClient[
                //         clientDashboardIds.CLIENTANDACCESS1
                //       ]
                //   ) &&
                //   !skipCompoBack()
                // ) {
                //   dispatch(goBackHDMClientCompo());
                // }
                props?.onUpClickHandler();
              }}
            >
              <img src={arrowUp} alt='' />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default HDMQuestionaireContainer;
