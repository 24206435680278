import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { goToSpecificCompoHDMClient } from "../../../api/HDMClient/componentTracking/action";
import {ready as readyGif} from "../../../assets/icons/proposal/proposal";
import PrimaryButton from "../../AccountManager/ButtonPrimary/Buttons";
import "./LastPageHDM.css";
import { generatePath, useNavigate } from "react-router";
import hdmClientRoutePaths from "../../../pages/hdm-client/constants/hdmClientRoutePaths";
import { Button } from "@mui/material";

const DeckSuccess = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          gap: "0.5rem",
        }}
      >
        <div>
          <img src={readyGif} alt="" width={150} height={150} />
        </div>
        <div>
          <Typography
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 24,
            }}
          >
            Please wait while we generate a strategy for you
          </Typography>
          <Typography
            style={{
              fontFamily: "Lato",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 16,
              color: "#6F6C90",
              textAlign: "center"
            }}
          >
            In the meantime, please book a call with our Account Manager.
          </Typography>
        </div>
        <div
          style={{
            margin: "2rem 0",
            display: "flex",
            gap: "1.5rem",
            justifyContent: "center",
          }}
        >
            <a
              href={`https://priyanka-hdm.youcanbook.me/service/jsps/cal.jsp?cal=20c4b594-6f50-4ff2-a613-6236c6e62735&ini=1688485613356&service=jsid9926805`}
              style={{ textDecoration: "none" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="contained"
                style={{
                  marginTop: "2rem",
                  width: 130,
                }}
              >
                Book a Call
              </Button>
            </a>
        </div>
      </div>
      {/* <div className="center">
        <img src={readyGif} width={150} height={150} />
        <div className="submit-title">
         
        </div>
        <div className="submit-desc">
          We will notify you as soon as the onboarding doc is ready for your{" "}
          <br />
          approval.
        </div>
        <Button
          variant="contained"
          onClick={() => dispatch(goToSpecificCompoHDMClient(0))}
        >
          Home
        </Button>
      </div> */}
    </>
  );
};
export default DeckSuccess;
