import { accountManagerIdsHDM } from "../../utils/accountManagerUtil";
import CommunicationHDMAM from "../HDMClientDashboard/DeckProposal/communication/communicationAM";
import OnboardHDMAM from "../HDMClientDashboard/DeckProposal/OnboardingAM";
import ReviewHDMAM from "../HDMClientDashboard/DeckProposal/ReviewAM";
import QuestionierPreviewAM from "../HDMClientDashboard/HDMQuestions/HDMPreviewAM";
import HDMAccessAM from "../HDMClientDashboard/HDMReadAccess/HDMAccessAM";
import SelectBrand from "./SelectBrand/SelectBrand";
// import FacebookAccounts from "./Audit/FacebookAccounts";
// import AuditAccount from "./Audit/AuditAccount";
// import AuditCampaign from "./Audit/AuditCampaign";
// import AuditAdset from "./Audit/AuditAdset";
// import AuditAd from "./Audit/AuditAd";
import HDMFacebookAccounts from '../FacebookAudit/Accounts/HDMFacebookAccounts';
import HDMAuditAccount from '../FacebookAudit/AuditAccount/HDMAuditAccount';
import HDMAuditCampaign from '../FacebookAudit/AuditCampaign/HDMAuditCampaign';
import HDMAuditAdset from '../FacebookAudit/AuditAdset/HDMAuditAdset';
import HDMAuditAd from '../FacebookAudit/AuditAd/HDMAuditAd';
import GOOGLEANALYSIS from "./Audit/AccountAnalysis/GoogleAnalysis/googleanalysis";
import FBANALYSIS from "./Audit/AccountAnalysis/FacebookAnalysis/fbanalysis";
import WebsiteAnalysis from "./Audit/AccountAnalysis/WebsiteAnalysis/websiteanalysis";
import CreativesAnalysis from "./Audit/AccountAnalysis/CreativesAnalysis/creativeanalysis";
import CompetitorAnalysis from "./Audit/AccountAnalysis/CompetitorAnalysis/competitoranalysis";
// import HDMLookALike from "../FacebookStrategyCreation/LookALike/HDMLookALike";
// import HDMTOFInterest from "../FacebookStrategyCreation/TOFInterest/HDMTOFInterest";
// import HDMMOF from "../FacebookStrategyCreation/MOF/HDMMOF";
// import HDMBOF from "../FacebookStrategyCreation/BOF/HDMBOF";
// import LookALike from "./StrategyCreation/LookaLike";
// import TofInterest from "./StrategyCreation/TOFInterest";
// import MOF from "./StrategyCreation/MOF";
// import BOF from "./StrategyCreation/BOF";
import GoToNextCompoButton from "./GoToNextCompoButton";
import GoalHDM from "./HDMAMStrategy/HDMAMGoals";
import AnaylsisHDM from "./HDMAMStrategy/HDMAMAnalysis";
import StrategyDeckGoogleHDM from "./HDMAMStrategy/HDMAMStrategyDeckGoogleHDM";
import StrategyDeckFacebookHDM from "./HDMAMStrategy/HDMAMStrategyDeckFacebookHDM";
import OurPlanHDM from "./HDMAMStrategy/HDMAMOurPlan";
import BestPraticeHDM from "./HDMAMStrategy/HDMAMBestPractice";
import SuggestChangesHDMDeck from "./HDMAMStrategy/HDMAMLastPageStrategy";
import BOFCampaign from "./BOFCampaignFB/BOFCampaign";
import HDMAdset from "./HDMCampaignCreation/HDMAdset";
import HDMCampaignCreation from "./HDMCampaignCreation/HDMCampaignCreation";
import InterestCampaign from "./InterestCampaignFB/InterestCampaign";
import LookAlikeCampaign from "./LookAlikeCampaignFB/LookAlikeCampaign";
import MOFCampaign from "./MOFCampaignFB/MOFCampaign";
// import CampaignPreview from "./HDMCampaignCreation/CampaignPreview";
// import GoogleAdsAccount from "../AccountManager/Questions/GoogleAdsAccount";
// import ReviewPoints from "../AccountManager/ReviewPoints/ReviewPoints";
import HDMGoogleAdsAccount from "../GoogleAudit/HDMGoogleAdsAccount";
import HDMReviewPoints from "../GoogleAudit/ReviewPoints/HDMReviewPoints";
import StrategyInput from "../AccountManager/StrategyDeck/StrategyInput";
import StrategyInputCompetitor from "../AccountManager/StrategyDeck/StrategyInputCompetitor";
import StrategyInputService from "../AccountManager/StrategyDeck/StrategyInputService";
import CampaignPreviewGoogle from "../AccountManager/CampaignApproval/CampaignPreview";
import CampaignCreation from "../AccountManager/CampaignApproval/CampaignCreationNew";
import StrategyDeckGoogle from "../AccountManager/StrategyDeck/StrategyDeckGoogle";
import StrategyGoogleCampaignSelector from "./StrategyGoogleCampaignSelector";
import PMaxStrategyCreation from "../HDMAccountManager/PMaxStrategyCreation";
import CampaignGoogleSelector from "./CampaignGoogleSelector";
import PMaxCampaignCreationHDM from "./PMaxCampaignCreationHDM";
import CampaignPreview from "../CampaignPreview/NewCampaignPreview";
import { PMaxStrategyListHDM } from "./PMaxStrategyListHDM";

const RenderCurrentComponent = ({ currentCompoent, hdAmForm }) => {
  console.log("CURRENT COMP:", currentCompoent);
  const currentCompenent = (currentCompoent) => {
    switch (currentCompoent) {
      case accountManagerIdsHDM.SELECT_BRAND:
        return <SelectBrand />;
      case accountManagerIdsHDM.FORM_PREVIEW:
        return <QuestionierPreviewAM />;
      case accountManagerIdsHDM.ACCESS_SHARING:
        return <HDMAccessAM type="COMPLETE_ACCESS" accessType="HDM" />;
      case accountManagerIdsHDM.HDM_ONBOARDING:
        return <OnboardHDMAM />;
      case accountManagerIdsHDM.HDM_ONBOARDING_REVIEW:
        return <ReviewHDMAM />;
      case accountManagerIdsHDM.HDM_ONBOARDING_COMMUNICATION:
        return <CommunicationHDMAM />;
      // case accountManagerIdsHDM.HDM_ONBOARDING_SUCCESS:
      //   return <DeckSuccessAM />;
      case accountManagerIdsHDM.AUDIT_GOOGLE_ADS:
        return <HDMGoogleAdsAccount />
        // return <GoogleAdsAccount agencyType="HDM" />;
      case accountManagerIdsHDM.AUDIT_REVIEW_POINTS:
        return <HDMReviewPoints />
        // return <ReviewPoints agencyType="HDM" />;
      case accountManagerIdsHDM.HDM_FACEBOOK_ACCOUNTS:
        return <HDMFacebookAccounts />;
        // return <FacebookAccounts />;
      case accountManagerIdsHDM.HDM_AUDIT_ACCOUNT:
        return <HDMAuditAccount />;
        // return <AuditAccount />;
      case accountManagerIdsHDM.HDM_AUDIT_CAMPAIGN:
        return <HDMAuditCampaign />
        // return <AuditCampaign />;
      case accountManagerIdsHDM.HDM_AUDIT_ADSET:
        return <HDMAuditAdset />;
        // return <AuditAdset />;
      case accountManagerIdsHDM.HDM_AUDIT_AD:
        return <HDMAuditAd />;
        // return <AuditAd />;
      case accountManagerIdsHDM.ACCOUNT_ANNALYSIS_GOOGLE:
        return (
          <GOOGLEANALYSIS googleVal={hdAmForm["accountAnalysis"]["google"]} />
        );
      case accountManagerIdsHDM.ACCOUNT_ANNALYSIS_FACEBOOK:
        return <FBANALYSIS fbVal={hdAmForm["accountAnalysis"]["tiktok"]} />;
      case accountManagerIdsHDM.ACCOUNT_ANNALYSIS_WEBSITE:
        return (
          <WebsiteAnalysis
            websiteVal={hdAmForm["accountAnalysis"]["website"]}
          />
        );
      case accountManagerIdsHDM.ACCOUNT_ANNALYSIS_CREATIVES:
        return (
          <CreativesAnalysis
            creativeVal={hdAmForm["accountAnalysis"]["creatives"]}
          />
        );
      case accountManagerIdsHDM.ACCOUNT_ANNALYSIS_COMPETITOR:
        return (
          <CompetitorAnalysis
            competVal={hdAmForm["accountAnalysis"]["competitor"]}
          />
        );
      // case accountManagerIdsHDM.ACCOUNT_ANNALYSIS_SUCCESS:
      //   return (
      //     <>
      //       <h2>SUCCESS</h2>
      //       <GoToNextCompoButton title="Next" />
      //     </>
      //   );
      case accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY:
        return <StrategyGoogleCampaignSelector />;
      case accountManagerIdsHDM.STRATEGY_GOOGLE_BRAND_CAMPAIGN:
        return <StrategyInput agencyType="HDM"/>;
      case accountManagerIdsHDM.STRATEGY_GOOGLE_COMPETITIER_CAMPAIGN:
        return <StrategyInputCompetitor agencyType="HDM"/>;
      case accountManagerIdsHDM.STRATEGY_GOOGLE_SERVICE_CAMPAIGN:
        return <StrategyInputService agencyType="HDM"/>;
      case accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_1:
        // return <PMaxStrategyCreation campName="campaign1" />;
        return <PMaxStrategyListHDM />
      case accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_2:
        return <PMaxStrategyCreation campName="campaign2" />;
      case accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_3:
        return <PMaxStrategyCreation campName="campaign3" />;
      // case accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE:
      //   return <HDMLookALike />
      // case accountManagerIdsHDM.STRATEGY_FACEBOOK_INTEREST:
      //   return <HDMTOFInterest />
      // case accountManagerIdsHDM.STRATEGY_FACEBOOK_MOF:
      //   return <HDMMOF />
      // case accountManagerIdsHDM.STRATEGY_FACEBOOK_BOF:
      //   return <HDMBOF />
      case accountManagerIdsHDM.STRATEGY_DECK_GOAL:
        return <GoalHDM />;
      case accountManagerIdsHDM.STRATEGY_DECK_ANALYSIS:
        return <AnaylsisHDM />;
      case accountManagerIdsHDM.STRATEGY_DECK_GOOGLE_STRATEGY:
        return <StrategyDeckGoogle agencyType="HDM" />;
      case accountManagerIdsHDM.STRATEGY_DECK_FB_STRATEGY:
        return <StrategyDeckFacebookHDM agencyType="HDM"/>;
      case accountManagerIdsHDM.STRATEGY_DECK_OUR_PLAN:
        return <OurPlanHDM />;
      case accountManagerIdsHDM.STRATEGY_DECK_BEST_PRACTICES:
        return <BestPraticeHDM />;
      case accountManagerIdsHDM.STRATEGY_DECK_CHANGES_SUGGESTED:
        return <SuggestChangesHDMDeck />;
      case accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE:
        return <CampaignGoogleSelector agencyType="HDM" />;
      case accountManagerIdsHDM.CAMPAIGN_CREATION_PAGE:
        return <CampaignCreation agencyType="HDM" />;
      case accountManagerIdsHDM.CAMPAIGN_CREATION_PAGE_PMAX:
        return <PMaxCampaignCreationHDM />;
      case accountManagerIdsHDM.CAMPAIGN_CREATION_FB:
        return <HDMCampaignCreation />;
      case accountManagerIdsHDM.ADSET_LEVEL_LOOKALIKE:
        return <LookAlikeCampaign />;
      case accountManagerIdsHDM.ADSET_LEVEL_INTEREST:
        return <InterestCampaign />;
      case accountManagerIdsHDM.ADSET_LEVEL_MOF:
        return <MOFCampaign />;
      case accountManagerIdsHDM.ADSET_LEVEL_BOF:
        return <BOFCampaign />;
      case accountManagerIdsHDM.HDM_ADSET_LEVEL:
        return <HDMAdset />;
      case accountManagerIdsHDM.CAMPAIGN_PREVIEW_PAGE:
        return <CampaignPreview />;
        // return <CampaignPreviewGoogle agencyType="HDM" />;
      // case accountManagerIdsHDM.CAMPAIGN_PREVIEW_FB:
      //   return <CampaignPreview />;
      default:
        return null;
        // return <HDMBOF />;
    }
  };
  return currentCompenent(currentCompoent, hdAmForm);
};
export default RenderCurrentComponent;
