import React from 'react';

const AddIcon = () => {
  return (
    <svg
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='26' height='26' rx='13' fill='#F8F9FD' />
      <path
        d='M13 8.33203V17.6654'
        stroke='#0869FB'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.33203 13H17.6654'
        stroke='#0869FB'
        stroke-width='1.2'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default AddIcon;
