export const DB_KEY_PRODUCT_INFO={
    "status": undefined,
    "leadEmailAddress": "On which email address should the leads be sent?",
    "descriptions":"Product/ Service Information",
    "brandDescription": "Brand Description",
    "appRelatedService": "Does your company have an app related to these services?",
    "profitMargin": "Profit Margin",
    "avgClosingPercent": "Average sales closing percentage",
    "sessionality": "Is there any seasonality to your products/services?",
    "targetCpa": "Target CPA",
    "brandServiceKeywords": "Keywords"
}
export const DB_KEY_PRODUCT_PREFERENCES={
    "status": undefined,
    "monthlyAdSpend": "What is your monthly ad spend budget?*",
    "leadPreferences": "What are your lead preferences?*",
    "trackingNumberDisplay": "Would you prefer your tracking phone number to show as your local area code or a toll-free number?*",
    "handleDepletedAccountBalance": "On rare occasions, your monthly budget may be depleted a few days before the end of the month. If this ever happens with your account how should we proceed?*",
    "competitors":"Your top competitors and their websites"
}

export const DB_KEY_AUDIENCE={
    "status": undefined,
    "ageRanges": "What is the age range of your customer base?*",
    "location":"What zip codes and/or cities do your best customers come from?*",
    "gender": "Which gender is your main customer base?*",
    "advertiseLocation": "Where do you advertise?*",
    "maritalStatus": "What is the marital status of your target customer?",
    "customerCollar": "Are your customers white-collar or blue-collar?*",
    "activitiesInterest": "What activities/interests do your best customers have?",
    "excludeAudiences": "Are there specific locations, markets, or groups of people we should exclude?",
    "customerIncomeRange": "What is your target customer's yearly household income?*",
    "customerEducation": "What is the highest level of school your target customer has completed or the highest degree they have received?",
    "customerEmployment": "Which of the following categories best describes your target customer's employment status?*",
    "formalCustomerPersona": undefined,
    "geoTargets":"Enter the locations where you'd like to run your ad"
}

export const DB_KEY_BRANDING={
    "status": undefined,
    "brandingStyleGuide": "Please upload your company's branding or style guide. Should include company colors, fonts, logos, and usage guidelines",
    "companyLogo": "Please upload your company's logo in vector or its largest format",
    "brandingMaterials": "Please upload your company branding material here",
    "companyClientLogo": "Please upload your most reputable client's logos. (B2B clients only)",
    "companyClientWebsite": "If you don't have the logos of your clients, please list their websites",
    "awards": "Please upload logos, awards, and any other credibility signals. For example, mentioned in Forbes, won Best of State, Fortune 500, etc.",
    "productImages": "Please upload any photos, product images, and graphical assets. Include product photos, location photos, custom icon kits, custom graphics, etc."
}

export const DB_KEY_SOCIAL_PROOF={
    "status": undefined,
    "testimonial1": "Customer Testimonial#1",
    "testimonial2": "Customer Testimonial#2",
    "testimonial3": "Customer Testimonial#3"
}