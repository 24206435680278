export const listOfIds = {
  accountOnboarding: "ACCOUNT_ONBOARDING",
  accountScopeOfWorkAndPricing: "ACCOUNT_SCOPE_OF_WORK_AND_PRICING",
  sharedAssets: "SHARED_ASSETS",
  audit: "AUDIT",
  auditFacebook: "AUDIT_FACEBOOK",
  proposal: "proposal",
  reviewIntakeForm: "REVIEW_INTAKE_FORM",
  sharedAssetsComplete: "SHARED_ASSETS_COMPLETE",
  strategyInput: "STRATEGY_INPUT",
  strategyCreationFacebook: "StrategyCreationFacebook",
  strategy: "strategy_deck_google",
  campaignForGoogle: "CAMPAIGN_FOR_GOOGLE",
  campaignCreationFB: "CampaignCreationFB",
  campaignPreview: "CAMPAIGN_PREVIEW",
  creativeApproval: "CREATIVE_APPROVAL",
  analyticDashboard: "AnalyticDashboard",
};

export const leftPanelLabelLDAm = {
  ONBOARDING_FORM: "Client Onboarding Inputs",
  PRICING: "Pricing",
  READ_SHARED_ASSETS: "Read Shared Assets",
  ACCESS_SHARING: "Access Sharing",
  AUDIT_FOR_GOOGLE: "Audit For Google",
  AUDIT_FOR_FACEBOOK: "Audit For Facebook",
  PROPOSAL: "Proposal",
  REVIEW_INTAKE_FORM: "Review Intake Form",
  COMPLETE_SHARED_ASSETS: "Complete Shared Assets",
  STRATEGY_GOOGLE: "Strategy Creation Google",
  STRATEGY_FACEBOOK: "Strategy Creation Facebook",
  STRATEGY_DECK: "Strategy Deck",
  CAMPAIGN_GOOGLE: "Campaign Creation Google",
  CAMPAIGN_FACEBOOK: "Campaign Creation Facebook",
  CAMPAIGN_PREVIEW: "Campaign Preview",
  CREATIVE_APPROVAL: "Creative Approval",
  ANALYTICS_DASHBOARD: "Analytics Dashboard",
};

export const leftPanelNameInUrlPath = /** @type {const} */ ({
  ONBOARDING_FORM: "onboarding-form",
  PRICING: "pricing",
  READ_SHARED_ASSETS: "read-shared-assets",
  ACCESS_SHARING: "access-sharing",
  AUDIT_FOR_GOOGLE: "audit-for-google",
  AUDIT_FOR_FACEBOOK: "audit-for-facebook",
  PROPOSAL: "proposal",
  REVIEW_INTAKE_FORM: "review-intake-form",
  COMPLETE_SHARED_ASSETS: "complete-shared-assets",
  STRATEGY_GOOGLE: "strategy-creation-google",
  STRATEGY_FACEBOOK: "strategy-creation-facebook",
  STRATEGY_DECK: "strategy-deck",
  CAMPAIGN_GOOGLE: "campaign-creation-google",
  CAMPAIGN_FACEBOOK: "campaign-creation-facebook",
  CAMPAIGN_PREVIEW: "campaign-preview",
  CREATIVE_APPROVAL: "creative-approval",
  ANALYTICS_DASHBOARD: "analytics-dashboard",
});

export const facebookOtherAccounts = [
  "Facebook Page",
  "Facebook Catalog",
  "Facebook Pixel",
];

export const googleOtherAccounts = [
  "Google Analytics",
  "Google Merchant",
  "Google Tags Manager",
];

export const googleAccountsMappingToKeys = {
  "Google Analytics": "googleAnalytics",
  "Google Merchant": "googleMerchant",
  "Google Tags Manager": "googleTagsManager",
};

export const facebookAccountsMappingToKeys = {
  "Facebook Page": "page",
  "Facebook Catalog": "catalogue",
  "Facebook Pixel": "pixel",
};

export const googleAccessAccounts = {
  googleAnalytics: [],
  googleMerchant: [],
  googleTagsManager: [],
};
export const fbAccessAccounts = {
  page: [],
  pixel: [],
  catalogue: [],
};
// export const leftPanelLabelWithUrlPathMapping = {
//   [leftPanelNameInUrlPath.ONBOARDING_FORM] : listOfIds.accountOnboarding,
//   [leftPanelNameInUrlPath.PRICING]: listOfIds.accountScopeOfWorkAndPricing,
//   [leftPanelNameInUrlPath.READ_SHARED_ASSETS]: listOfIds.sharedAssets,
//   [leftPanelNameInUrlPath.AUDIT_FOR_GOOGLE]: listOfIds.audit,
//   [leftPanelNameInUrlPath.REVIEW_INTAKE_FORM]: listOfIds.auditFacebook,
//   [leftPanelNameInUrlPath.PROPOSAL]: listOfIds.proposal,
//   [leftPanelNameInUrlPath.COMPLETE_SHARED_ASSETS]: listOfIds.sharedAssetsComplete,
//   [leftPanelNameInUrlPath.STRATEGY_GOOGLE]: listOfIds.strategyInput,
//   [leftPanelNameInUrlPath.STRATEGY_FACEBOOK]: listOfIds.strategyCreationFacebook,
//   [leftPanelNameInUrlPath.STRATEGY_DECK]: listOfIds.strategy,
//   [leftPanelNameInUrlPath.CAMPAIGN_GOOGLE]: listOfIds.campaignForGoogle,
//   [leftPanelNameInUrlPath.CAMPAIGN_FACEBOOK]: listOfIds.campaignCreationFB,
//   [leftPanelNameInUrlPath.CAMPAIGN_PREVIEW]: listOfIds.campaignPreview,
//   [leftPanelNameInUrlPath.CREATIVE_APPROVAL]: listOfIds.creativeApproval,
//   [leftPanelNameInUrlPath.ANALYTICS_DASHBOARD]: listOfIds.analyticDashboard,
// }
