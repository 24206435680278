import { combineReducers } from 'redux';
import authReducer from '../api/auth/reducer';
import clientReducer from '../api/client/reducer';
import formReducer from '../api/accountManager/forms/slice';
import pricingReducer from '../api/accountManager/pricing/slice';
import clientCurrentComp from '../api/componentTracking/reducer';
import amCurrentComp from '../api/accountManager/componentTracking/reducer';
import googleCustomerReducer from '../api/accountManager/googleAdsCustomer/slice';
import hdmClientRedcuer from '../api/HDMClient/reducer';
import hdmClientCurrentComp from '../api/HDMClient/componentTracking/reducer';
import hdmAMCurrentComp from '../api/HDMAM/componentTracking/reducer';
import sharedAssetsReducerAM from '../api/accountManager/sharedAssets/slice';
import creativeSliceReducer from '../api/accountManager/creativeAI/slice';
import targetSliceReducer from '../api/accountManager/targetAI/slice';
import sharedAssetsReducer from '../api/sharedAssets/reducer';
import hdmAudit from '../api/HDMAM/audit/reducer';
import campaignSliceReducer from '../api/accountManager/campaign/slice';
import globalSliceReducer from '../api/Global/slice';
import hdmFormAm from '../api/HDMAM/form/reducer';
import strategyCreationHdmAm from '../api/HDMAM/strategyCreation/reducer';
import hdmCampaignReducer from '../api/HDMAM/campaign/reducer';
import analyticsReducer from '../api/analytics/reducer';
import creativeApprovalReducer from '../api/creativeApproval/slice';
import pmaxStrategyReducer from '../api/pmaxStrategy/slice';
import channel from '../api/channels/reducer';
import pmaxCampaignReduer from '../api/pmaxCampaign/slice';
import bpClientReducer from '../api/clientforms/slice';

const rootReducer = combineReducers({
  auth: authReducer,
  client: clientReducer,
  clientCurrentComp: clientCurrentComp,
  form: formReducer,
  pricing: pricingReducer,
  amCurrentComp: amCurrentComp,
  googleCustomers: googleCustomerReducer,
  sharedAssetsAM: sharedAssetsReducerAM,
  creativeAI: creativeSliceReducer,
  targetAI: targetSliceReducer,
  hdmClient: hdmClientRedcuer,
  hdmClientCurrentComp: hdmClientCurrentComp,
  hdmAMCurrentComp: hdmAMCurrentComp,
  sharedAssets: sharedAssetsReducer,
  hdmAudit: hdmAudit,
  sharedAssets: sharedAssetsReducer,
  campain: campaignSliceReducer,
  global: globalSliceReducer,
  hdmFormAm: hdmFormAm,
  strategyCreationHdmAm: strategyCreationHdmAm,
  hdmCampaign: hdmCampaignReducer,
  dashboard: analyticsReducer,
  creativeApproval: creativeApprovalReducer,
  pmaxStrategy: pmaxStrategyReducer,
  pmaxCampaign: pmaxCampaignReduer,
  channel: channel,
  forms: bpClientReducer,
});

export default rootReducer;
