import { UPDATE_CURRENT_COMPONENT } from "../../api/componentTracking/enum";

export const updateCompoReduxClient = () => {
  return {
    type: UPDATE_CURRENT_COMPONENT,
  };
};

export const gotToHomeClient = () => {
  return {
    type: "GO_TO_HOME_CLIENT",
  };
};

export const goBackClientCompo = () => {
  return {
    type: "GO_BACK_CLIENT_COMPO",
  };
};

export const goToSpecificCompoClient = (payload) => {
  return {
    type: "GO_TO_SPECIFIC_COMPO_CLIENT",
    payload: payload,
  };
};

export const goToReviewQuestionier = (payload) => {
  return {
    type: "REVIEW_QUESTIONIER",
    payload: payload,
  };
};

export const goBackReviewQuestionier = (payload) => {
  return {
    type: "GO_BACK_REVIEW_QUESTIONIER",
    payload: payload,
  };
};

export const updateTab = (payload) => {
  return {
    type: "UPDATE_TAB_CLIENT",
    payload: payload,
  };
};

