import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  form: null,
  googleAdsInfo: {},
  facebookAdsInfo: {},
  croInfo: {},
  allForms: [],
  campaignConfirmModal: false,
};

export const formSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    getFormByFormIdReducer() {},
    updateCompanyInfoReducer() {},
    updateGoogleAdsInfoReducer() {},
    updateFacebookAdsInfoReducer() {},
    updateCROInfoReducer() {},
    publishProposalReducer() {},
    updateFormReducer() {},
    getAllFormsReducer() {},
    validateGoogleAdsAccessReducer() {},
    updateFormForCampaignReducer() {},
    updateData: (state, action) => {
      state.form = action.payload;
    },
    updateGoogleAdData: (state, action) => {
      state.googleAdsInfo = { ...state.googleAdsInfo, ...action.payload };
    },
    updateFacebookAdData: (state, action) => {
      state.facebookAdsInfo = { ...state.facebookAdsInfo, ...action.payload };
    },
    updateCROData: (state, action) => {
      state.croInfo = { ...state.croInfo, ...action.payload };
    },
    updateAllForms: (state, action) => {
      state.allForms = action.payload;
    },
    updateCampaignConfirmModal: (state, action) => {
      state.campaignConfirmModal = action.payload;
    },
    resetFormState: (state, action) => {
      state.form = initialState.form;
    },
    updatedSharedAssetsLdAm: (state, action) => {
      const tempState = { ...JSON.parse(JSON.stringify(state.form)) };
      const { infoType, key, key2, key3, value } = action.payload;
      tempState[infoType][key][key2][key3] = value;
      state.form = tempState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getFormByFormIdReducer,
  updateCompanyInfoReducer,
  updateGoogleAdsInfoReducer,
  updateFacebookAdsInfoReducer,
  updateCROInfoReducer,
  updateData,
  publishProposalReducer,
  updateGoogleAdData,
  updateFacebookAdData,
  updateCROData,
  updateFormReducer,
  validateGoogleAdsAccessReducer,
  updateAllForms,
  getAllFormsReducer,
  updateFormForCampaignReducer,
  updateCampaignConfirmModal,
  resetFormState,
  updatedSharedAssetsLdAm,
} = formSlice.actions;

export default formSlice.reducer;
