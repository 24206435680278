import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import {
  CITY_LOCATION_AUTOSUGGESTION_INPUT,
  LOCATION_ADDED_TO_FORM,
} from '../../../../api/client/enum';
import { useEffect, useState } from 'react';

const MyChip = (props) => {
  return (
    <div className='custom_chips'>
      <span>{props.label}</span>
      <span className='close_tag' onClick={props.onDelete}></span>
    </div>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const LocationAutoCompleteField = (props) => {
  const dispatch = useDispatch();
  let selectedValues = useSelector(
    (state) => state.hdmClient.onboardingInfo.googleTargeting.geoTargets
  );
  let locationOptions = useSelector(
    (state) => state.hdmClient.onboardingInfo.googleTargeting.locationOptions
  );
  const [selectedTags, setSelectedTags] = useState(selectedValues);
  useEffect(() => {
    dispatch({
      type: 'LOCATION_ADDED_TO_FORM_HDM',
      payload: selectedTags,
    });
  }, [selectedTags]);
  if (!locationOptions) {
    locationOptions = [];
  }
  if (selectedValues && !selectedValues.length) selectedValues = undefined;
  return (
    <Autocomplete
      multiple
      id='checkboxes-tags'
      options={locationOptions}
      defaultValue={selectedValues}
      onChange={(event, values) => {
        setSelectedTags(values);
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <MyChip {...getTagProps({ index })} label={option.name} />
        ));
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option.title, inputValue, { insideWords: true });
        const parts = parse(option.title, matches);
        return (
          <li {...props} className='search_location_list'>
            <div className='location_list_head'>
              {' '}
              <p>Location</p> <p>Reach</p>
            </div>
            <div className='location_list_content'>
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? '#0869FB' : '#333333',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
              <span>{option.reach}</span>
            </div>
          </li>
        );
      }}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <div className='location_question_header'>
          <span className='search_icon'></span>
          <TextField
            {...params}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder={props.placeholder}
            variant='standard'
            onChange={(e) =>
              dispatch({
                type: CITY_LOCATION_AUTOSUGGESTION_INPUT,
                payload: {
                  infoType: props.type,
                  value: e.target.value,
                },
              })
            }
          />
        </div>
      )}
      disablePortal={true}
      className={
        props.error
          ? 'error_bounce bounce-top auto_location_search'
          : 'auto_location_search'
      }
    />
  );
};

export default LocationAutoCompleteField;
