import React from 'react';
import { Typography } from '@mui/material';
import Image from '../../../../assets/images/blankimage.svg';

const Creative = ({ imageUrl,imageUrl2,ctr, cpm, cvr, cpa }) => {
  const ctrValueStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 15.54,
    lineHeight: '22px',
  };
  const ctrTextStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 8.47,
    lineHeight: '15px',
    color: '#797979',
  };

  return (
    <div className='creative-container' style={{ marginRight: 24 }}>
      <div className='creative-image-container'>
        <img
          src={imageUrl2.length !== 0 ? process.env.REACT_APP_BACKEND_BASE_URL+"/files/"+imageUrl2 : imageUrl.length !== 0 ? imageUrl : Image}
          alt=''
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
      <div className='creative-spends'>
        <div className='cpa-creative-container'>
          <Typography style={ctrValueStyling}>{cpa.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CPA</Typography>
          <div className='divider-creative'></div>
        </div>
        <div className='ctr-creative-container'>
          <Typography style={ctrValueStyling}>{ctr.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CTR</Typography>
          <div className='divider-creative'></div>
        </div>
        <div className='cpm-creative-container'>
          <Typography style={ctrValueStyling}>{cpm.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CPM</Typography>
          <div
            className='divider-creative'
            style={{
              position: 'absolute',
              left: '3.5rem',
            }}
          ></div>
        </div>
        <div className='cvr-creative-container'>
          <Typography style={ctrValueStyling}>{cvr.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CVR</Typography>
        </div>
      </div>
    </div>
  );
};

export default Creative;
