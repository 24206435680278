import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "../api";
import { queryClient } from "../utils/queryClient";
import { ADSET_CRON_STATUS } from "../components/FacebookStrategyCreation/utils";

export const getGoogleAdsStrategyQueryKey = (formId) => [
  "g-ads-strategy/:formId",
  formId,
];

const getSpecificAdsStrategyQueryKey = (formId, strategyId) => [
  "g-ads-strategy/:formId/:strategyId",
  formId,
  strategyId,
];

export const useGetListGoogleAdsStrategies = (
  formId,
  dataFormat,
  generationType = null,
  options = {}
) => {
  return useQuery(
    getGoogleAdsStrategyQueryKey(formId),
    async () => {
      let response;
      if (generationType === null)
        response = await api.get(
          `/client-forms/${formId}/g-ads-strategy?dataFormat=${dataFormat}`
        );
      else
        response = await api.get(
          `/client-forms/${formId}/g-ads-strategy?dataFormat=${dataFormat}&generationType=${generationType}`
        );
      return response.data;
    },
    options
  );
};

export const useGetGoogleAdsByStrategyId = (
  formId,
  strategyId,
  options = {}
) => {
  return useQuery(
    getSpecificAdsStrategyQueryKey(formId, strategyId),
    async () => {
      const response = await api.get(
        `/client-forms/${formId}/g-ads-strategy/${strategyId}`
      );
      return response.data;
    },
    options
  );
};

// create new strategy
export const usePostGoogleAdsStrategy = (formId, options = {}) => {
  const { onSuccess, ...otherOptions } = options;
  return useMutation({
    mutationFn: async (payload = {}) => {
      const body = {
        ...payload,
      };
      const response = await api.post(
        `/client-forms/${formId}/g-ads-strategy`,
        body
      );
      return response.data;
    },
    onSuccess: (...args) => {
      // Invalidate and refresh
      queryClient.invalidateQueries({
        queryKey: getGoogleAdsStrategyQueryKey(formId),
      });
      onSuccess?.(...args);
    },
    ...otherOptions,
  });
};

export const usePutGoogleAdsStrategy = (formId, strategyId, options = {}) => {
  const { onSuccess, ...otherOptions } = options;
  return useMutation({
    mutationFn: async (payload = {}) => {
      const incomingStrategyId = strategyId || payload.strategyId;
      if (payload.strategyId) {
        delete payload.strategyId;
      }
      const body = {
        ...payload,
      };
      const response = await api.put(
        `/client-forms/${formId}/g-ads-strategy/${incomingStrategyId}`,
        body
      );
      return response.data;
    },
    onSuccess: (...args) => {
      // Invalidate and refresh
      // queryClient.invalidateQueries({ queryKey: getSpecificAdsStrategyQueryKey(formId, strategyId) });
      // onSuccess?.(...args);
    },
    ...otherOptions,
  });
};

// execute update strategies parallely
export const usePutGoogleAdsStrategies = (formId, options = {}) => {
  const { onSuccess, ...otherOptions } = options;
  return useMutation({
    mutationFn: async (strategies) => {
      const response = await Promise.all(
        strategies.map((strategy) => {
          return api.put(
            `/client-forms/${formId}/g-ads-strategy/${strategy.id}`,
            strategy
          );
        })
      );
      return response;
    },
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getGoogleAdsStrategyQueryKey(formId),
      });
      // Invalidate and refresh
      // queryClient.invalidateQueries({ queryKey: getSpecificAdsStrategyQueryKey(formId, strategyId) });
      // onSuccess?.(...args);
    },
    ...otherOptions,
  });
};

// delete adset
export const useDeleteGoogleAdsStrategyAdset = (
  formId,
  strategyId,
  options = {}
) => {
  const { onSuccess, ...otherOptions } = options;
  return useMutation({
    mutationFn: async (adsetId = "") => {
      const response = await api.delete(
        `/client-forms/${formId}/g-ads-strategy/${strategyId}/assets?adset_ids=${adsetId}`
      );
      return response.data;
    },
    // onSuccess: (...args) => {
    //     // Invalidate and refresh
    //     queryClient.invalidateQueries({ queryKey: getSpecificAdsStrategyQueryKey(formId, strategyId) });
    //     onSuccess?.(...args);
    // },
    ...otherOptions,
  });
};

//delete strategy
export const useDeleteGoogleAdsStrategy = (formId, options = {}) => {
  const { onSuccess, ...otherOptions } = options;
  return useMutation({
    mutationFn: async (strategyId = "") => {
      const response = await api.delete(
        `/client-forms/${formId}/g-ads-strategy/${strategyId}/assets`
      );
      return response.data;
    },
    onSuccess: (...args) => {
      // Invalidate and refresh
      queryClient.invalidateQueries({
        queryKey: getGoogleAdsStrategyQueryKey(formId),
      });
      onSuccess?.(...args);
    },
    ...otherOptions,
  });
};

// create Adset
export const usePostGoogleAdset = (formId, strategyId, generationType, options = {}) => {
  const { onSuccess, ...otherOptions } = options;
  return useMutation({
    mutationFn: async (payload = {}) => {
      const { adsetId, ...field } = payload;
      const body = {
        ...field,
      };
      let response;
      if (adsetId) {
        response = await api.post(
          `/targeting-keywords/google-seach-ads/${formId}/${strategyId}?adset_id=${adsetId}&generationType=${generationType}`,
          body
        );
      } else {
        response = await api.post(
          `/targeting-keywords/google-seach-ads/${formId}/${strategyId}?generationType=${generationType}`,
          body
        );
      }
      return response.data;
    },
    ...otherOptions,
  });
};

// generate Resource

export const usePostGoogleAdsetResource = (
  formId,
  strategyId,
  options = {}
) => {
  const { onSuccess, ...otherOptions } = options;
  return useMutation({
    mutationFn: async (payload = {}) => {
      const body = {
        ...payload,
      };
      const response = await api.post(
        `/creative-resources/google-seach-ads/${formId}/${strategyId}`,
        body
      );
      return response.data;
    },
    ...otherOptions,
  });
};

// poll adsets

export const usePollAdsetGSearchAds = (
  { adsetId, formId, strategyId },
  options = {}
) => {
    return useQuery(
      [
        `clientform/${formId}/gads-search-strategy/${strategyId}/adset/${adsetId}/poll`,
      ],
      async () => {
        if(adsetId){
            const response = await api.get(
              `/client-forms/${formId}/g-ads-strategy/${strategyId}?adset_id=${adsetId}`
            );
            return response.data;
        }
        return null;
      },
      {
        ...options,
        refetchInterval(data) {
          if (
            data &&
            [ADSET_CRON_STATUS.RUNNING, ADSET_CRON_STATUS.PENDING].includes(
              data.cron_status
            )
          )
            return 30 * 1000;
          return false;
        },
      }
    );
};
