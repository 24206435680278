import { Box, Grid, Input } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFacebbokAccounts } from "../../../api/HDMAM/audit/action";
import {
  allAlreadyMetadataAccounts,
  isAccountValid,
  isMetaDataArrivedFacebook,
} from "../../../api/HDMAM/audit/saga";
import { saveHdmAmFormsData } from "../../../api/HDMAM/form/action";
import { getHDMClientDetailsByFormId } from "../../../api/HDMClient/action";
import { search as SEARCH } from "../../../assets/icons/icon";
import DateModal from "../../AccountManager/DateModal/DateModal";
import LoaderModal from "../../AccountManager/LoaderModal/LoaderModal";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";
import HoldTight from "../../HDMAccountManager/Audit/HoldTight";
import Option from "../../HDMAccountManager/Audit/Option";
import "../../GoogleAudit/googleaccount.scss";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";
import {
  BACKEND_STATUS,
  isPlatformSelectedBP,
} from "../../../utils/accountManagerUtil";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import { ReactComponent as IconNoData } from "../../../assets/icons/no-data-available.svg";
import { isPlatformSelectedLD } from "../../../utils/LDUtils/commonUtils";
const CommonFacebookAccounts = ({
  fbAccountId,
  onGenerateReport,
  form,
  onSkip = () => {},
}) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const hdmForm = useSelector((state) => state.hdmAudit);
  const [accountId, setAccount] = useState(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [selectedCustomerName, setSelectedCustomerName] = useState(null);
  const [facebookAccounts, setFacebookAccounts] = useState([
    ...hdmForm.facebookAccounts,
  ]);
  const [searchedValue, setSearchedValue] = useState("");
  // for loader and modal
  const [loader, setLoader] = useState(true);

  const isUserFacebookAccountValid = useSelector(
    (state) => state.hdmAudit.isUserFacebookAccountValid
  );
  const gotFacebookAccounts = useSelector(
    (store) => store.hdmAudit.gotFacebookAccounts
  );
  console.log("isUserFacebookAccountValid :: ", isUserFacebookAccountValid);
  const onGenerateReportHandler = () => {
    setShowDateModal(false);
    setShowLoaderModal(true);
    onGenerateReport?.({
      startDate,
      endDate,
      accountId: accountId,
      selectedCustomerName,
    }).finally(() => {
      setShowLoaderModal(false);
    });
  };
  const [mappedAlreadyMetaData, setAlreadyMappedData] = useState({});

  useEffect(() => {
    setFacebookAccounts([...hdmForm.facebookAccounts]);
  }, [hdmForm.facebookAccounts]);
  useEffect(() => {
    if (gotFacebookAccounts) {
      setLoader(false);
    }
  }, [gotFacebookAccounts]);

  useEffect(() => {
    dispatch(getFacebbokAccounts(form?.brandId));
    dispatch({
      type: "UPDATE_WHOLE_HDM_AM_DETAILS_SAGA",
    });
  }, []);

  useEffect(() => {
    if (
      facebookAccounts.length !== 0 &&
      mappedAlreadyMetaData[fbAccountId] &&
      mappedAlreadyMetaData[fbAccountId].status === "COMPLETE"
    ) {
      let obj = null;
      facebookAccounts.forEach((account, index) => {
        if (account.adAccountId === fbAccountId) {
          obj = account;
        }
      });
      if (obj) {
        setAccount(obj.adAccountId);
        setSelectedCustomerName(obj.adAccountName);
      } else {
        setAccount(facebookAccounts[0].adAccountId);
        setSelectedCustomerName(facebookAccounts[0].adAccountName);
      }
    } else if (facebookAccounts.length !== 0) {
      if (
        mappedAlreadyMetaData[fbAccountId] &&
        mappedAlreadyMetaData[fbAccountId].status === "ONGOING"
      ) {
        let account = facebookAccounts[0];
        facebookAccounts.forEach((accountT) => {
          if (accountT.adAccountId === fbAccountId) {
            account = accountT;
          }
        });
        setAccount(account.adAccountId);
        setSelectedCustomerName(account.adAccountName);
      } else {
        setAccount(facebookAccounts[0].adAccountId);
        setSelectedCustomerName(facebookAccounts[0].adAccountName);
      }
    }
  }, [facebookAccounts, mappedAlreadyMetaData, fbAccountId]);

  const checkAlreadyMetaDataAccount = () => {
    allAlreadyMetadataAccounts(accountId, form._id)
      .then((res) => {
        setAlreadyMappedData(res.data);
      })
      .catch((err) => {
        setAlreadyMappedData({});
      });
  };

  useEffect(() => {
    if (fbAccountId) {
      checkAlreadyMetaDataAccount();
    }
    dispatch(getHDMClientDetailsByFormId());
  }, []);

  //is user account valid
  useEffect(() => {
    if (
      mappedAlreadyMetaData[fbAccountId] &&
      mappedAlreadyMetaData[fbAccountId].status === "COMPLETE"
    ) {
      dispatch({
        type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
        payload: "LIST_OF_ACCOUNTS",
      });
      setShowDateModal(false);
    } else if (
      mappedAlreadyMetaData[fbAccountId] &&
      mappedAlreadyMetaData[fbAccountId].status === "ONGOING"
    ) {
      dispatch({
        type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
        payload: "HOLD_TIGHT",
      });
      setShowDateModal(false);
    } else {
      if (facebookAccounts?.length !== 0) {
        dispatch({
          type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
          payload: "LIST_OF_ACCOUNTS",
        });
      } else {
        dispatch({
          type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
          payload: "USER_ACCOUNT_INVALID",
        });
        setShowDateModal(false);
      }
    }
  }, [mappedAlreadyMetaData, fbAccountId]);

  //is meta data arrived
  useEffect(() => {
    setInterval(() => {
      if (
        mappedAlreadyMetaData[fbAccountId] &&
        mappedAlreadyMetaData[fbAccountId].status === "ONGOING"
      ) {
        isMetaDataArrivedFacebook(fbAccountId, form._id)
          .then((res) => {
            if (res[fbAccountId].status === "COMPLETE") {
              dispatch({
                type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
                payload: "LIST_OF_ACCOUNTS",
              });
              dispatch(getFacebbokAccounts(form?.brandId));
              checkAlreadyMetaDataAccount();
              setShowDateModal(true);
            }
          })
          .catch((err) => {
            dispatch({
              type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
              payload: "HOLD_TIGHT",
            });
          });
      }
    }, 60000);
  });

  const hitInitialiseApi = () => {
    if (
      mappedAlreadyMetaData[accountId] &&
      mappedAlreadyMetaData[accountId].status === "COMPLETE"
    ) {
      setShowDateModal(true);
      dispatch({
        type: "UPDATE_FACEBOOK_ACCOUNT_ID_HDM",
        payload: {
          accountId: accountId,
          accountName: selectedCustomerName,
        },
      });
      dispatch(saveHdmAmFormsData());
    } else if (
      mappedAlreadyMetaData[accountId] &&
      mappedAlreadyMetaData[accountId].status === "ONGOING"
    ) {
      dispatch({
        type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
        payload: "HOLD_TIGHT",
      });
    } else {
      isAccountValid(accountId, form._id)
        .then((res) => {
          dispatch({
            type: "UPDATE_FACEBOOK_ACCOUNT_ID_HDM",
            payload: {
              accountId: accountId,
              accountName: selectedCustomerName,
            },
          });
          dispatch(saveHdmAmFormsData());
          dispatch({
            type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
            payload: "HOLD_TIGHT",
          });
          checkAlreadyMetaDataAccount();
        })
        .catch((err) => {
          dispatch({
            type: "UPDATE_USER_FACEBOOK_ACCOUNT_STATUS",
            payload: "USER_ACCOUNT_INVALID",
          });
          dispatch({
            type: "UPDATE_FACEBOOK_ACCOUNT_ID_HDM",
            payload: {
              accountId: accountId,
              accountName: selectedCustomerName,
            },
          });
          dispatch(saveHdmAmFormsData());
        });
    }
  };

  const searchFacebookAccount = (value) => {
    setSearchedValue(value);
    const updatedFilterd = hdmForm.facebookAccounts.filter((account) => {
      if (
        account.name.toLowerCase().includes(value.toLowerCase()) ||
        account.accountId.toLowerCase().includes(value.toLowerCase())
      ) {
        return account;
      }
    });
    setFacebookAccounts(updatedFilterd);
  };

  const skipButtonHandler = () => {
    if (form?.facebookAuditStatus !== BACKEND_STATUS.COMPLETED) {
      dispatch(
        updateFormReducer({
          facebookAuditStatus: BACKEND_STATUS.COMPLETED,
          isSkippedAuditFacebook: true,
          currentStep: "Proposal",
        })
      );
    }
    onSkip?.();
  };
  /*COMMENTING THIS OUT CAN BE USED AS A REFERENCE FOR FUTURE PREFERENCES*/
  // console.log("mappedAlreadyMetaData", mappedAlreadyMetaData);
  // USER_ACCOUNT_INVALID -> that there are no ad accounts connected in Facebook
  // LIST_OF_ACCOUNTS -> ad accounts are there that is ad accounts are connected by the user from admin panel or access sharing
  // return !facebookAccounts.length ? (
  //   <>
  //     <div className="googlebox">
  //       <div
  //         style={{
  //           fontFamily: "sans-serif",
  //           fontWeight: "400",
  //           marginBottom: "0rem",
  //         }}
  //       >
  //         <h2>Ad Account</h2>
  //         <Box sx={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}>
  //           <img src={SEARCH} />
  //           <Input
  //             placeholder="Data Search"
  //             size="small"
  //             disableUnderline={true}
  //             value={searchedValue}
  //             onChange={(e) => searchFacebookAccount(e.target.value)}
  //           />
  //         </Box>
  //         <div className="line"></div>
  //       </div>
  //       <div className="select-ad-modal__error" style={{ marginTop: 110 }}>
  //         <IconNoData />
  //         <h2 className="select-ad-modal__error-title">No ad accounts found</h2>
  //         <p className="select-ad-modal__error-description">
  //           No accounts are connected in this brand. Please connect an <br />
  //           account through the “Access Sharing” step or admin panel
  //         </p>
  //       </div>
  //       <div className="preview_footer_row">
  //         <div className="footer_down">
  //           <>
  //             <PrimaryButton
  //               title="Skip"
  //               style={{
  //                 color: "#1976d2",
  //                 background: "white",
  //               }}
  //               onClick={skipButtonHandler}
  //             />

  //             <PrimaryButton
  //               title="Next"
  //               disabled={true}
  //               onClick={() => hitInitialiseApi()}
  //             />
  //           </>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // ) : isUserFacebookAccountValid === "LIST_OF_ACCOUNTS" ||
  //   facebookAccounts?.length ? (
  //   <>
  //     <div className="googlebox">
  //       <div
  //         style={{
  //           fontFamily: "sans-serif",
  //           fontWeight: "400",
  //           marginBottom: "0rem",
  //         }}
  //       >
  //         <h2>Ad Account</h2>
  //         <Box sx={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}>
  //           <img src={SEARCH} />
  //           <Input
  //             placeholder="Data Search"
  //             size="small"
  //             disableUnderline={true}
  //             value={searchedValue}
  //             onChange={(e) => searchFacebookAccount(e.target.value)}
  //           />
  //         </Box>
  //         <div className="line"></div>
  //       </div>

  //       <div className="assignee assignee_add_acccount">
  //         <span>Assigned</span>
  //         <div className="ft">
  //           <div>
  //             <BpRadio checked={true} />
  //           </div>
  //           <div className="ai">
  //             <span>{selectedCustomerName}</span>
  //             <p>Account Id: {accountId}</p>
  //           </div>
  //         </div>
  //       </div>

  //       <div className="line"></div>

  //       <div className="available">
  //         <span style={{ color: "#999999", fontFamily: "sans-serif" }}>
  //           Available
  //         </span>
  //         <div>
  //           <Grid>
  //             <div className="radio_add_acccount">
  //               {facebookAccounts.map((account, index) => (
  //                 <Option
  //                   name={account.adAccountName}
  //                   account={account.adAccountId}
  //                   checked={accountId === account.adAccountId ? true : false}
  //                   setAccount={setAccount}
  //                   setSelectedCustomerName={setSelectedCustomerName}
  //                 />
  //               ))}
  //             </div>
  //           </Grid>
  //         </div>
  //       </div>
  //       <div className="preview_footer_row">
  //         <div className="footer_down">
  //           <>
  //             <PrimaryButton
  //               title="Skip"
  //               style={{
  //                 color: "#1976d2",
  //                 background: "white",
  //               }}
  //               onClick={skipButtonHandler}
  //             />

  //             <PrimaryButton title="Next" onClick={() => hitInitialiseApi()} />
  //           </>
  //         </div>
  //       </div>
  //       {showDateModal && (
  //         <DateModal
  //           setShowDateModal={setShowDateModal}
  //           setStartDate={setStartDate}
  //           setEndDate={setEndDate}
  //           onClickHandler={onGenerateReportHandler}
  //         />
  //       )}
  //       {showLoaderModal && <LoaderModal />}
  //     </div>
  //   </>
  // ) : (
  //   <HoldTight />
  // );
  return isUserFacebookAccountValid === "HOLD_TIGHT" ? (
    <HoldTight />
  ) : facebookAccounts?.length ? (
    <>
      <div className="googlebox">
        <div
          style={{
            fontFamily: "sans-serif",
            fontWeight: "400",
            marginBottom: "0rem",
          }}
        >
          <h2>Ad Account</h2>
          <Box sx={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}>
            <img src={SEARCH} />
            <Input
              placeholder="Data Search"
              size="small"
              disableUnderline={true}
              value={searchedValue}
              onChange={(e) => searchFacebookAccount(e.target.value)}
            />
          </Box>
          <div className="line"></div>
        </div>

        <div className="assignee assignee_add_acccount">
          <span>Assigned</span>
          <div className="ft">
            <div>
              <BpRadio checked={true} />
            </div>
            <div className="ai">
              <span>{selectedCustomerName}</span>
              <p>Account Id: {accountId}</p>
            </div>
          </div>
        </div>

        <div className="line"></div>

        <div className="available">
          <span style={{ color: "#999999", fontFamily: "sans-serif" }}>
            Available
          </span>
          <div>
            <Grid>
              <div className="radio_add_acccount">
                {facebookAccounts.map((account, index) => (
                  <Option
                    name={account.adAccountName}
                    account={account.adAccountId}
                    checked={accountId === account.adAccountId ? true : false}
                    setAccount={setAccount}
                    setSelectedCustomerName={setSelectedCustomerName}
                  />
                ))}
              </div>
            </Grid>
          </div>
        </div>
        <div className="preview_footer_row">
          <div className="footer_down">
            <>
              <PrimaryButton
                title="Skip"
                style={{
                  color: "#1976d2",
                  background: "white",
                }}
                onClick={skipButtonHandler}
              />

              <PrimaryButton title="Next" onClick={() => hitInitialiseApi()} />
            </>
          </div>
        </div>
        {showDateModal && (
          <DateModal
            setShowDateModal={setShowDateModal}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            onClickHandler={onGenerateReportHandler}
          />
        )}
        {showLoaderModal && <LoaderModal />}
      </div>
    </>
  ) : (
    <>
      <div className="googlebox">
        <div
          style={{
            fontFamily: "sans-serif",
            fontWeight: "400",
            marginBottom: "0rem",
          }}
        >
          <h2>Ad Account</h2>
          <Box sx={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}>
            <img src={SEARCH} />
            <Input
              placeholder="Data Search"
              size="small"
              disableUnderline={true}
              value={searchedValue}
              onChange={(e) => searchFacebookAccount(e.target.value)}
            />
          </Box>
          <div className="line"></div>
        </div>
        <div className="select-ad-modal__error" style={{ marginTop: 110 }}>
          <IconNoData />
          <h2 className="select-ad-modal__error-title">No ad accounts found</h2>
          <p className="select-ad-modal__error-description">
            No accounts are connected in this brand. Please connect an <br />
            account through the “Access Sharing” step or admin panel
          </p>
        </div>
        <div className="preview_footer_row">
          <div className="footer_down">
            <>
              <PrimaryButton
                title="Skip"
                style={{
                  color: "#1976d2",
                  background: "white",
                }}
                onClick={skipButtonHandler}
              />

              <PrimaryButton
                title="Next"
                disabled={true}
                onClick={() => hitInitialiseApi()}
              />
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default CommonFacebookAccounts;
