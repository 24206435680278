import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "../../api";
import { queryClient } from "../../utils/queryClient";

const GET_GOOGLE_PMAX_THEMES = ["google-pmax-themes/get"];
const themesURI = formId => `/creative-resources/pmax-ads/${formId}/themes`
const caiCronEndpoint = formId => `/creative-resources/pmax-ads/${formId}/themes-resources`;

export const useGetPmaxThemes = (formId, options={}) => {
    return useQuery(GET_GOOGLE_PMAX_THEMES, async () => {
        const response = await api.get(themesURI(formId));
        return response.data;
    }, options);
}

export const usePostGenerateResources = (formId, options={}) => {
    const { onSuccess, ...otherOptions } = options;
    return useMutation({
        mutationFn: async () => {
            const response = await api.post(caiCronEndpoint(formId));
            return response.data;
        },
        onSuccess: (...args) => {
            onSuccess?.(...args);
        },
        ...otherOptions
    })
}

export const usePostPmaxThemes = (formId, options={}) => {
    const { onSuccess, ...otherOptions } = options;
    return useMutation({
        mutationFn: async (theme='') => {
            const data = { theme }
            const response = await api.post(themesURI(formId), data);
            return response.data;
        },
        onSuccess: (...args) => {
            // Invalid and refetch
            queryClient.invalidateQueries({ queryKey: GET_GOOGLE_PMAX_THEMES });
            onSuccess?.(...args);
        },
        ...otherOptions
    })
}

const GET_GOOGLE_PMAX_RESOURCES = ["google-pmax-resources/get"];
const resourcesURI = formId => `/creative-resources/pmax-ads/${formId}/resources`

export const useGetPmaxResources = (formId, options={}) => {
    return useQuery(GET_GOOGLE_PMAX_RESOURCES,
        async() => {
            const response = await api.get(resourcesURI(formId));
            return response.data;
        }, options)
}

export const usePostPmaxResources = (formId, options={}) => {
    const { onSuccess, ...otherOptions } = options;
    return useMutation({
        mutationFn: async (themes=[]) => {
            const data = {
                themes,
                resourceTypes: []
            }
            const response = await api.post(resourcesURI(formId), data);
            return response.data;
        },
        onSuccess: (...args) => {
            // Invalid & refetch
            queryClient.invalidateQueries({ queryKey: GET_GOOGLE_PMAX_RESOURCES });
            onSuccess?.(...args);
        },
        ...otherOptions
    });
}
