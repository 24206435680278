import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  googleAdsAccess: {},
}

export const sharedAssetsSlice = createSlice({
  name: 'sharedAssets',
  initialState,
  reducers: {
    getSharedAssetsReducer() {},
    updateGoogleAdsAccessData: (state, action) => {
      state.googleAdsAccess = action.payload;
    }
  },
})

// Action creators are generated for each case reducer function
export const { updateGoogleAdsAccessData, getSharedAssetsReducer } = sharedAssetsSlice.actions

export default sharedAssetsSlice.reducer;