import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ReactComponent as IconDelete } from "../../../assets/images/Analytics/Delete2.svg";
import "./SegmentCard.scss";
import { segmentFormSchema } from './schema';

const FORM_ID_PREFIX = "SEGMENT_CARD";

export default function SegmentCard({
  uid,
  title,
  onSearchTermClick,
  onDelete,
  onConfirm,
  tags = null,
  isComplete,
  disableConfirm = true,
  defaultValues = null
}) {
  const FORM_ID = `${FORM_ID_PREFIX}-${uid}`;

  const { handleSubmit, formState: { errors, isDirty }, register, reset } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: zodResolver(segmentFormSchema),
  });

  const onSubmit = (values) => {
      onConfirm?.(values);
      reset({}, { keepValues: true });
  }

  return (
    <article className="segment-card">
      <div
        className='segment-card__indicator'
        data-border-indicator={(isComplete && !isDirty) ? "complete": "editing"} // complete | editing
      />
      <section className='segment-card__content'>
        <header className='segment-card__header'>
          <div className='segment-card__header-left'>
            <h2 className='segment-card__header-title'>
              {title}
            </h2>
          </div>
          <div className='segment-card__header-right'>
            {tags}
            <button className='segment-card__delete-button' onClick={onDelete}>
              <IconDelete width="14" height="17" />
            </button>
            <button className='segment-card__adset-level-button' onClick={onSearchTermClick}>
              Search Terms
            </button>
          </div>
        </header>
        <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)} className="segment-card__form">
          <div className='segment-card__form-row'>
            <fieldset className='segment-card__field segment-card__field--width-full'>
              <label className='segment-card__field-label'>Name</label>
              <TextField
                placeholder='Enter Name'
                helperText={errors.name?.message}
                fullWidth
                error={!!errors.name}
                {...register("name")}
              />
            </fieldset>
          </div>
        </form>
        <footer className='segment-card__footer'>
          <button className='segment-card__submit-button' form={FORM_ID} disabled={disableConfirm} style={{ opacity: disableConfirm ? '0.4' : '1', pointerEvents: disableConfirm ? 'none' : 'all'}} >
            Confirm
          </button>
        </footer>
      </section>
    </article>
  )
}
