import React from 'react';

const ReadIcon = (props) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.6285 10.0442C12.6285 11.4992 11.4485 12.6783 9.99354 12.6783C8.53854 12.6783 7.35938 11.4992 7.35938 10.0442C7.35938 8.58835 8.53854 7.40918 9.99354 7.40918C11.4485 7.40918 12.6285 8.58835 12.6285 10.0442Z'
        stroke={props.color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.99573 16.129C13.1691 16.129 16.0716 13.8473 17.7057 10.044C16.0716 6.24065 13.1691 3.95898 9.99573 3.95898H9.99906C6.82573 3.95898 3.92323 6.24065 2.28906 10.044C3.92323 13.8473 6.82573 16.129 9.99906 16.129H9.99573Z'
        stroke={props.color}
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default ReadIcon;
