import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledImageContainer = styled("div")`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
export const StyledWarningHeaderText = styled("h3")`
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;
export const StyledWarningDescriptionText = styled("p")`
  color: #6f6c90;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px;
  margin-bottom: 32px;
`;
export const StyledWarningHeaderDescriptionWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 9px;
`;
export const StyledButtonsWrapper = styled("div")`
  display: flex;
  justify-content: center;
  gap: 12px;
`;
export const StyledGoBackButton = styled(Button)`
  border-radius: 8px;
  background: #0869fb;
  padding: 8px 36px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  &:hover {
    background: #0869fb;
  }
`;
export const StyledProceedButton = styled(Button)`
  color: #0869fb;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 8px;
  border: 1.5px solid #0869fb;
  padding: 8px 36px;
  text-transform: capitalize;
`;
