import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getGoogleCampaignStatus } from '../../../../utils/accountManagerUtil';

const DEFAULT_INFO = [];
export default function useGoogleCampaignStatus({
  agencyType,
  campaignType,
}) {
  const form = useSelector((state) =>
    agencyType === 'LD' ? state.form.form : state.hdmFormAm
  );
  const generatedCampaignsInfo = form.googleSearchAdsGeneratedCampaignsInfo || DEFAULT_INFO;
  return useMemo(() => {
    return getGoogleCampaignStatus(campaignType, generatedCampaignsInfo);
  }, [generatedCampaignsInfo, campaignType])
}