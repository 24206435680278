import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import ReadyIcon from '../../assets/icons/proposal/ready.gif';

const AllConfirmModal = ({
    handleClose,
    description="All Asset Groups Confirmed",
    buttonTitle="Go To Campaign View",
    handleNavigation=() => {},
    showButton=true
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 504,
    height: 371,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 3.5,
    padding: '3rem 3rem 0 3rem',
    // height: '380px',
    borderRadius: '14px',
    outline: 'none',
  };
   // const [deleteModal, setDeleteModal] = useState(true);
  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Close
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            cursor: 'pointer'
          }}
           onClick={() => handleClose()}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: showButton && 'center',
            height: '100%',
            gap: '0.5rem',
          }}
        >
          <div>
            <img src={ReadyIcon} alt='' width={170} height={170} />
          </div>
          <div>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 18,
                textAlign: 'center',
                width: '18rem',
                lineHeight: 1.25,
              }}
            >
               {description}
            </Typography>
          </div>
          { showButton && <Button
                style={{
                  textDecoration: 'none',
                  marginTop: '10px',
                  width: '172px',
                  height: '40px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textTransform: 'capitalize',
                  background: '#0869FB',
                }}
                onClick={() => handleNavigation()}
              > 
                 {buttonTitle}
              </Button>
          }
        </div>
      </Box>
    </Modal>
  );
};

export default AllConfirmModal;
