import { isAnyCampaignsPublished } from "../components/CampaignPreview/utils";
import { listOfIdsHDM, listOfIds } from "./navbarUtil";

//order of components to render
export const accountManagerIds = {
  CLIENT_DETAILS_AM: "CLIENT_DETAILS_AM",
  SCOPE_GOOGLE_ADS_SETUP: "SCOPE_GOOGLE_ADS_SETUP",
  SCOPE_FACEBOOK_ADS_SETUP: "SCOPE_FACEBOOK_ADS_SETUP",
  SCOPE_CRO_ADS_SETUP: "SCOPE_CRO_ADS_SETUP",
  SCOPE_PRICING_ADS_SETUP: "SCOPE_PRICING_ADS_SETUP",
  SHARED_ASSETS: "SHARED_ASSETS",
  // PROPOSAL_INPUTS_ONBOARDING_QUEST1: "PROPOSAL_INPUTS_ONBOARDING_QUEST1",
  // PROPOSAL_INPUTS_ONBOARDING_QUEST2: "PROPOSAL_INPUTS_ONBOARDING_QUEST2",
  // PROPOSAL_INPUTS_ONBOARDING_QUEST3: "PROPOSAL_INPUTS_ONBOARDING_QUEST3",
  // PROPOSAL_INPUTS_ONBOARDING_QUEST4: "PROPOSAL_INPUTS_ONBOARDING_QUEST4",
  // PROPOSAL_INPUTS_ONBOARDING_QUEST5: "PROPOSAL_INPUTS_ONBOARDING_QUEST5",
  // PROPOSAL_INPUTS_ONBOARDING_QUEST6: "PROPOSAL_INPUTS_ONBOARDING_QUEST6",
  // PROPOSAL_INPUTS_ONBOARDING_QUEST7: "PROPOSAL_INPUTS_ONBOARDING_QUEST7",
  // PROPOSAL_INPUTS_PROPOSER_DETAILS_QUEST1:
  //   "PROPOSAL_INPUTS_PROPOSER_DETAILS_QUEST1",
  // PROPOSAL_INPUTS_PROPOSER_DETAILS_QUEST2:
  //   "PROPOSAL_INPUTS_PROPOSER_DETAILS_QUEST2",
  // PROPOSAL_INPUTS_REQUIREMENT_QUEST1: "PROPOSAL_INPUTS_REQUIREMENT_QUEST1",
  AUDIT_GOOGLE_ADS: "AUDIT_GOOGLE_ADS",
  AUDIT_REVIEW_POINTS: "AUDIT_REVIEW_POINTS",

  //facebook audit
  HDM_FACEBOOK_ACCOUNTS: "HDM_FACEBOOK_ACCOUNTS",
  HDM_AUDIT_ACCOUNT: "HDM_AUDIT_ACCOUNT",
  HDM_AUDIT_CAMPAIGN: "HDM_AUDIT_CAMPAIGN",
  HDM_AUDIT_ADSET: "HDM_AUDIT_ADSET",
  HDM_AUDIT_AD: "HDM_AUDIT_AD",
  //LD proposal
  PROPOSAL_PUBLISH: "PROPOSAL_PUBLISH",
  SUGGEST_CHANGES: "SUGGEST_CHANGES",
  //intake form
  REVIEW_INTAKE_FORM: "REVIEW_INTAKE_FORM",
  SHARED_ASSETS_COMPLETE: "SHARED_ASSETS_COMPLETE",

  // campaign selector google
  CAMPAIGN_SELECTOR_GOOGLE_STRATEGY: "CAMPAIGN_SELECTOR_GOOGLE_STRATEGY",
  //strategy creation google
  BRAND_STRATEGY_INPUT: "BRAND_STRATEGY_INPUT",
  COMPETITOR_STRATEGY_INPUT: "COMPETITOR_STRATEGY_INPUT",
  SERVICE_STRATEGY_INPUT: "SERVICE_STRATEGY_INPUT",
  // pmax strategy
  PMAX_STRATEGY_CAMPAIGN_1: "PMAX_STRATEGY_CAMPAIGN_1",
  PMAX_STRATEGY_CAMPAIGN_2: "PMAX_STRATEGY_CAMPAIGN_2",
  PMAX_STRATEGY_CAMPAIGN_3: "PMAX_STRATEGY_CAMPAIGN_3",
  //strategy creation facebook
  STRATEGY_FACEBOOK_LOOKALIKE: "STRATEGY_FACEBOOK_LOOKALIKE",
  STRATEGY_FACEBOOK_INTEREST: "STRATEGY_FACEBOOK_INTEREST",
  STRATEGY_FACEBOOK_MOF: "STRATEGY_FACEBOOK_MOF",
  STRATEGY_FACEBOOK_BOF: "STRATEGY_FACEBOOK_BOF",
  //deck
  STRATEGY_DECK_INTRODUCTION: "STRATEGY_DECK_INTRODUCTION",
  STRATEGY_DECK: "STRATEGY_DECK",
  STRATEGY_DECK_FACEBOOK: "STRATEGY_DECK_FACEBOOK",
  STRATEGY_SUGGEST_CHANGES: "STRATEGY_SUGGEST_CHANGES",
  //campaign creation google
  CAMPAIGN_SELECTOR_GOOGLE: "CAMPAIGN_SELECTOR_GOOGLE",
  CAMPAIGN_CREATION_PAGE: "CAMPAIGN_CREATION_PAGE",
  CAMPAIGN_CREATION_PAGE_PMAX: "CAMPAIGN_CREATION_PAGE_PMAX",
  //campaign creation facebook
  CAMPAIGN_CREATION_FB: "CAMPAIGN_CREATION_FB",
  ADSET_LEVEL_LOOKALIKE: "ADSET_LEVEL_LOOKALIKE",
  ADSET_LEVEL_INTEREST: "ADSET_LEVEL_INTEREST",
  ADSET_LEVEL_MOF: "ADSET_LEVEL_MOF",
  ADSET_LEVEL_BOF: "ADSET_LEVEL_BOF",
  //campaign preview google
  CAMPAIGN_PREVIEW_PAGE: "CAMPAIGN_PREVIEW_PAGE",
  //campaign preview facebook
  // CAMPAIGN_PREVIEW_FB: "CAMPAIGN_PREVIEW_FB",
  //creatives
  AD_COPIES: "AD_COPIES",
  CREATIVES: "CREATIVES",
  LANDING_PAGES: "LANDING_PAGES",
  AB_TEST: "AB_TEST",

  REVIEW_QUESTIONIER_RESPONSES: "REVIEW_QUESTIONIER_RESPONSES",
};

export const mappedComponentWithIndex = new Map(
  Object.keys(accountManagerIds).map((key, index) => [index, key])
);

export const ldComponentWithIndexAm = Object.keys(accountManagerIds).reduce(
  (obj, value, index) => {
    return { ...obj, [value]: index };
  },
  {}
);
//hdm
export const accountManagerIdsHDM = {
  SELECT_BRAND: "SELECT_BRAND",
  FORM_PREVIEW: "FORM_PREVIEW",
  ACCESS_SHARING: "ACCESS_SHARING",
  HDM_ONBOARDING: "HDM_ONBOARDING",
  HDM_ONBOARDING_REVIEW: "HDM_ONBOARDING_REVIEW",
  HDM_ONBOARDING_COMMUNICATION: "HDM_ONBOARDING_COMMUNICATION",
  // HDM_ONBOARDING_SUCCESS: "HDM_ONBOARDING_SUCCESS",
  //audit google
  AUDIT_GOOGLE_ADS: "AUDIT_GOOGLE_ADS",
  AUDIT_REVIEW_POINTS: "AUDIT_REVIEW_POINTS",
  // audit facebook
  HDM_FACEBOOK_ACCOUNTS: "HDM_FACEBOOK_ACCOUNTS",
  HDM_AUDIT_ACCOUNT: "HDM_AUDIT_ACCOUNT",
  HDM_AUDIT_CAMPAIGN: "HDM_AUDIT_CAMPAIGN",
  HDM_AUDIT_ADSET: "HDM_AUDIT_ADSET",
  HDM_AUDIT_AD: "HDM_AUDIT_AD",
  ACCOUNT_ANNALYSIS_GOOGLE: "ACCOUNT_ANNALYSIS_GOOGLE",
  ACCOUNT_ANNALYSIS_FACEBOOK: "ACCOUNT_ANNALYSIS_FACEBOOK",
  ACCOUNT_ANNALYSIS_WEBSITE: "ACCOUNT_ANNALYSIS_WEBSITE",
  ACCOUNT_ANNALYSIS_CREATIVES: "ACCOUNT_ANNALYSIS_CREATIVES",
  ACCOUNT_ANNALYSIS_COMPETITOR: "ACCOUNT_ANNALYSIS_COMPETITOR",
  // ACCOUNT_ANNALYSIS_SUCCESS: "ACCOUNT_ANNALYSIS_SUCCESS",
  // campaign selector google
  CAMPAIGN_SELECTOR_GOOGLE_STRATEGY: "CAMPAIGN_SELECTOR_GOOGLE_STRATEGY",
  //strategy creation google
  STRATEGY_GOOGLE_BRAND_CAMPAIGN: "STRATEGY_GOOGLE_BRAND_CAMPAIGN",
  STRATEGY_GOOGLE_COMPETITIER_CAMPAIGN: "STRATEGY_GOOGLE_COMPETITIER_CAMPAIGN",
  STRATEGY_GOOGLE_SERVICE_CAMPAIGN: "STRATEGY_GOOGLE_SERVICE_CAMPAIGN",
  // pmax strategy
  PMAX_STRATEGY_CAMPAIGN_1: "PMAX_STRATEGY_CAMPAIGN_1",
  PMAX_STRATEGY_CAMPAIGN_2: "PMAX_STRATEGY_CAMPAIGN_2",
  PMAX_STRATEGY_CAMPAIGN_3: "PMAX_STRATEGY_CAMPAIGN_3",
  // strategy creation fb
  STRATEGY_FACEBOOK_LOOKALIKE: "STRATEGY_FACEBOOK_LOOKALIKE",
  STRATEGY_FACEBOOK_INTEREST: "STRATEGY_FACEBOOK_INTEREST",
  STRATEGY_FACEBOOK_MOF: "STRATEGY_FACEBOOK_MOF",
  STRATEGY_FACEBOOK_BOF: "STRATEGY_FACEBOOK_BOF",
  STRATEGY_DECK_GOAL: "STRATEGY_DECK_GOAL",
  STRATEGY_DECK_ANALYSIS: "STRATEGY_DECK_ANALYSIS",
  STRATEGY_DECK_GOOGLE_STRATEGY: "STRATEGY_DECK_GOOGLE_STRATEGY",
  STRATEGY_DECK_FB_STRATEGY: "STRATEGY_DECK_FB_STRATEGY",
  STRATEGY_DECK_OUR_PLAN: "STRATEGY_DECK_OUR_PLAN",
  STRATEGY_DECK_BEST_PRACTICES: "STRATEGY_DECK_BEST_PRACTICES",
  STRATEGY_DECK_CHANGES_SUGGESTED: "STRATEGY_DECK_CHANGES_SUGGESTED",
  // HDM_ONBOARDING_SUCCESS: "HDM_ONBOARDING_SUCCESS",
  //campaign creation google
  CAMPAIGN_SELECTOR_GOOGLE: "CAMPAIGN_SELECTOR_GOOGLE",
  CAMPAIGN_CREATION_PAGE: "CAMPAIGN_CREATION_PAGE",
  CAMPAIGN_CREATION_PAGE_PMAX: "CAMPAIGN_CREATION_PAGE_PMAX",
  //campaign creation facebook
  CAMPAIGN_CREATION_FB: "CAMPAIGN_CREATION_FB",
  ADSET_LEVEL_LOOKALIKE: "ADSET_LEVEL_LOOKALIKE",
  ADSET_LEVEL_INTEREST: "ADSET_LEVEL_INTEREST",
  ADSET_LEVEL_MOF: "ADSET_LEVEL_MOF",
  ADSET_LEVEL_BOF: "ADSET_LEVEL_BOF",
  HDM_ADSET_LEVEL: "HDM_ADSET_LEVEL",
  CAMPAIGN_PREVIEW_PAGE: "CAMPAIGN_PREVIEW_PAGE",
};

export const mappedComponentWithIndexHDM = new Map(
  Object.keys(accountManagerIdsHDM).map((key, index) => [index, key])
);

export const componentWithIndexHDMAm = Object.keys(accountManagerIdsHDM).reduce(
  (obj, value, index) => {
    return { ...obj, [value]: index };
  },
  {}
);

export const leftBarStatus = {
  toBeUnlocked: "To Be Unlocked",
  ongoing: "Ongoing",
  completed: "Completed",
};

export const accessTypeEnum = {
  VERIFYING: "VERIFYING",
  VERIFYING_COMPLETE_ACCESS: "VERIFYING_COMPLETE_ACCESS",
  READ_ACCESS: "READ_ACCESS",
  COMPLETE_ACCESS: "COMPLETE_ACCESS",
  ACCESS_REVOKED: "ACCESS_REVOKED",
  COMPLETED_ACCESS_NOT_SHARED: "COMPLETED_ACCESS_NOT_SHARED",
};

export const accessSharingType = {
  VERIFYING: "VERIFYING",
  READ: "READ",
  VERIFYING_COMPLETE: "VERIFYING_COMPLETE",
  COMPLETE: "COMPLETE",
};

export const hasAccessProvided = (
  platformsUsed = [],
  accessSharingInfo = {}
) => {
  let hasAccessProvidedFlag = true;
  const platforms = {
    googleAds: "google-ads",
    facbookAds: "facebook-ads",
  };
  const platformAccessMapping = {
    googleAds: "googleAccess",
    facbookAds: "fbAccess",
  };
  const adAccounts = {
    googleAds: "googleAdAccount",
    facbookAds: "adAccount",
  };
  const selectedPlatforms = [];
  platformsUsed.map((platformData) => {
    selectedPlatforms.push(platformData.platform);
  });

  Object.keys(platforms).map((platform) => {
    if (selectedPlatforms.includes(platforms[platform])) {
      if (
        !accessSharingInfo[platformAccessMapping[platform]][
          adAccounts[platform]
        ].accountId
      ) {
        hasAccessProvidedFlag = false;
      }
    }
  });

  return hasAccessProvidedFlag;
};
export const getAccessStatus = (
  accessType,
  platformsUsed = [],
  accessSharingInfo = {}
) => {
  let hasAccess = false;
  const platforms = {
    googleAds: "google-ads",
    facbookAds: "facebook-ads",
  };
  const platformAccessMapping = {
    googleAds: "googleAccess",
    facbookAds: "fbAccess",
  };
  const adAccounts = {
    googleAds: "googleAdAccount",
    facbookAds: "adAccount",
  };
  const selectedPlatforms = [];
  platformsUsed.map((platformData) => {
    selectedPlatforms.push(platformData.platform);
  });

  if (accessType === accessSharingType.VERIFYING) {
    let verifyingAccess = true;
    Object.keys(platforms).map((platform) => {
      if (selectedPlatforms.includes(platforms[platform])) {
        if (
          [
            accessTypeEnum.VERIFYING_COMPLETE_ACCESS,
            accessTypeEnum.READ_ACCESS,
            accessTypeEnum.COMPLETE_ACCESS,
            accessTypeEnum.COMPLETED_ACCESS_NOT_SHARED,
            accessTypeEnum.ACCESS_REVOKED,
          ].includes(
            accessSharingInfo[platformAccessMapping[platform]][
              adAccounts[platform]
            ].status
          ) ||
          !accessSharingInfo[platformAccessMapping[platform]][
            adAccounts[platform]
          ].accountId
        ) {
          verifyingAccess = false;
        }
      }
    });

    hasAccess = verifyingAccess;
  }

  if (accessType === accessSharingType.READ) {
    let readAccess = true;
    Object.keys(platforms).map((platform) => {
      if (selectedPlatforms.includes(platforms[platform])) {
        if (
          ![
            accessTypeEnum.VERIFYING_COMPLETE_ACCESS,
            accessTypeEnum.READ_ACCESS,
            accessTypeEnum.COMPLETE_ACCESS,
            accessTypeEnum.COMPLETED_ACCESS_NOT_SHARED,
          ].includes(
            accessSharingInfo[platformAccessMapping[platform]][
              adAccounts[platform]
            ].status
          )
        ) {
          readAccess = false;
        }
      }
    });

    hasAccess = readAccess;
  }
  if (accessType === accessSharingType.VERIFYING_COMPLETE) {
    let verifyingCompleteAccess = true;
    Object.keys(platforms).map((platform) => {
      if (selectedPlatforms.includes(platforms[platform])) {
        if (
          ![
            accessTypeEnum.VERIFYING_COMPLETE_ACCESS,
            accessTypeEnum.COMPLETED_ACCESS_NOT_SHARED,
          ].includes(
            accessSharingInfo[platformAccessMapping[platform]][
              adAccounts[platform]
            ].status
          )
        ) {
          verifyingCompleteAccess = false;
        }
      }
    });
    hasAccess = verifyingCompleteAccess;
  }
  if (accessType === accessSharingType.COMPLETE) {
    let completeAccess = true;
    Object.keys(platforms).map((platform) => {
      if (selectedPlatforms.includes(platforms[platform])) {
        if (
          ![accessTypeEnum.COMPLETE_ACCESS].includes(
            accessSharingInfo[platformAccessMapping[platform]][
              adAccounts[platform]
            ].status
          )
        ) {
          completeAccess = false;
        }
      }
    });
    hasAccess = completeAccess;
  }
  return hasAccess;
};
export const isPlatformSelected = (form, platformName) => {
  return form?.onboardingInfo?.platformInfo?.platforms?.findIndex(
    (platform) => platform.platform === platformName
  );
};

export const isPlatformSelectedBP = (form, platformName) => {
  const result = form?.salesQuestionnaire?.spends?.platforms?.findIndex(
    (platform) => platform.platform === platformName
  );
  if (result === -1) return false;
  return true;
};

const isStrategyStarted = (form) => {
  let isCompleted = false;
  if (
    isPlatformSelected(form, "google-ads") !== -1 &&
    isPlatformSelected(form, "facebook-ads") !== -1
  ) {
    if (
      form?.fbAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED &&
      form?.googleStrategyCreationStatus === BACKEND_STATUS.COMPLETED
    ) {
      isCompleted = true;
    }
  } else if (isPlatformSelected(form, "google-ads") !== -1) {
    if (form?.googleStrategyCreationStatus === BACKEND_STATUS.COMPLETED) {
      isCompleted = true;
    }
  } else if (isPlatformSelected(form, "facebook-ads") !== -1) {
    if (form?.fbAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED) {
      isCompleted = true;
    }
  }
  return isCompleted;
};

const isProposalStarted = (form) => {
  let isProposalOngoing = false;
  if (
    isPlatformSelected(form, "google-ads") !== -1 &&
    isPlatformSelected(form, "facebook-ads") !== -1
  ) {
    if (
      form?.googleAuditData?.status === BACKEND_STATUS.COMPLETED &&
      form?.facebookAuditStatus === BACKEND_STATUS.COMPLETED
    ) {
      isProposalOngoing = true;
    }
  } else if (
    isPlatformSelected(form, "google-ads") !== -1 &&
    form?.googleAuditData?.status === BACKEND_STATUS.COMPLETED
  ) {
    isProposalOngoing = true;
  } else if (
    isPlatformSelected(form, "facebook-ads") !== -1 &&
    form?.facebookAuditStatus === BACKEND_STATUS.COMPLETED
  ) {
    isProposalOngoing = true;
  }
  return isProposalOngoing;
};

const isProposalStartedBP = (form) => {
  let isProposalOngoing = false;
  if (
    isPlatformSelectedBP(form, "google-ads") &&
    isPlatformSelectedBP(form, "facebook-ads")
  ) {
    if (
      form?.googleAuditStatus === BACKEND_STATUS.COMPLETED &&
      form?.facebookAuditStatus === BACKEND_STATUS.COMPLETED
    ) {
      isProposalOngoing = true;
    }
  } else if (
    isPlatformSelected(form, "google-ads") !== -1 &&
    form?.googleAuditStatus === BACKEND_STATUS.COMPLETED
  ) {
    isProposalOngoing = true;
  } else if (
    isPlatformSelected(form, "facebook-ads") !== -1 &&
    form?.facebookAuditStatus === BACKEND_STATUS.COMPLETED
  ) {
    isProposalOngoing = true;
  }
  return isProposalOngoing;
};

export const getStatusForLeftBarBP = (form) => {
  const statusObj = {
    "Sales Questionnaire": leftBarStatus.ongoing,
    "Read Shared Assets": leftBarStatus.toBeUnlocked,
    "Audit For Google": leftBarStatus.toBeUnlocked,
    "Audit For Facebook": leftBarStatus.toBeUnlocked,
    Proposal: leftBarStatus.toBeUnlocked,
  };

  if (form?.salesQuestionnaire?.status === BACKEND_STATUS.COMPLETED) {
    statusObj["Sales Questionnaire"] = leftBarStatus.completed;
    statusObj["Read Shared Assets"] = leftBarStatus.ongoing;
  }

  if (form?.accessSharing?.status === BACKEND_STATUS.COMPLETED) {
    statusObj["Read Shared Assets"] = leftBarStatus.completed;
    if (isPlatformSelectedBP(form, "google-ads")) {
      statusObj["Audit For Google"] = leftBarStatus.ongoing;
    }
    if (isPlatformSelectedBP(form, "facebook-ads")) {
      statusObj["Audit For Facebook"] = leftBarStatus.ongoing;
    }
  }

  if (form?.googleAuditStatus === BACKEND_STATUS.COMPLETED) {
    statusObj["Audit For Google"] = leftBarStatus.completed;
  }

  if (form?.facebookAuditStatus?.status === BACKEND_STATUS.COMPLETED) {
    statusObj["Audit For Facebook"] = leftBarStatus.completed;
  }
  if (isProposalStartedBP(form)) {
    statusObj["Proposal"] = leftBarStatus.ongoing;
  }
  if (
    form?.proposalIterations &&
    (form.proposalIterations[form.proposalIterations.length - 1] || {})
      .state === PROPOSAL_STATE.APPROVED
  ) {
    statusObj["Proposal"] = leftBarStatus.completed;
  }

  return statusObj;
};

export const getStatusForLeftBarBPClient = (form) => {
  const statusObj = {
    "Client Onboarding Inputs": leftBarStatus.ongoing,
    "Read Shared Assets": leftBarStatus.toBeUnlocked,
    "Audit For Google": leftBarStatus.toBeUnlocked,
    "Audit For Facebook": leftBarStatus.toBeUnlocked,
    Proposal: leftBarStatus.toBeUnlocked,
  };

  if (form?.salesQuestionnaire?.status === BACKEND_STATUS.COMPLETED) {
    statusObj["Client Onboarding Inputs"] = leftBarStatus.completed;
    statusObj["Read Shared Assets"] = leftBarStatus.ongoing;
  }

  if (form?.accessSharing?.status === BACKEND_STATUS.COMPLETED) {
    statusObj["Read Shared Assets"] = leftBarStatus.completed;
    if (isPlatformSelectedBP(form, "google-ads")) {
      statusObj["Audit For Google"] = leftBarStatus.ongoing;
    }
    if (isPlatformSelectedBP(form, "facebook-ads")) {
      statusObj["Audit For Facebook"] = leftBarStatus.ongoing;
    }
  }

  if (form?.auditInfo?.status === BACKEND_STATUS.COMPLETED) {
    statusObj["Audit For Facebook"] = leftBarStatus.completed;
  }

  return statusObj;
};

export const getStatusForLeftBar = (form, currentComp = 0) => {
  const statusObj = {
    [listOfIds.accountOnboarding]: leftBarStatus.ongoing,
    [listOfIds.sharedAssets]: leftBarStatus.toBeUnlocked,
    [listOfIds.proposalInputs]: leftBarStatus.toBeUnlocked,
    [listOfIds.audit]: leftBarStatus.toBeUnlocked,
    [listOfIds.accountScopeOfWorkAndPricing]: leftBarStatus.toBeUnlocked,
    [listOfIds.proposal]: leftBarStatus.toBeUnlocked,
    [listOfIds.accessSharing]: leftBarStatus.toBeUnlocked,
    [listOfIds.reviewIntakeForm]: leftBarStatus.toBeUnlocked,
    [listOfIds.sharedAssetsComplete]: leftBarStatus.toBeUnlocked,
    [listOfIds.strategyInput]: leftBarStatus.toBeUnlocked,
    [listOfIdsHDM.AuditHdm]: leftBarStatus.toBeUnlocked,
    [listOfIds.questionnaire]: leftBarStatus.toBeUnlocked,
    [listOfIds.strategy]: leftBarStatus.toBeUnlocked,
    [listOfIdsHDM.StrategyCreationFacebook]: leftBarStatus.toBeUnlocked,
    [listOfIds.campaignForGoogle]: leftBarStatus.toBeUnlocked,
    [listOfIdsHDM.CampaignCreationFB]: leftBarStatus.toBeUnlocked,
    [listOfIds.CAMPAIGN_PREVIEW]: leftBarStatus.toBeUnlocked,
    [listOfIdsHDM.CampaignPreview]: leftBarStatus.toBeUnlocked,
    [listOfIds.AnalyticDashboard]: leftBarStatus.toBeUnlocked,
    [listOfIds.creativeApproval]: leftBarStatus.toBeUnlocked,
  };

  // const strategyIdStatus = updateLeftSideBarGoogleSelectorPage(form);
  const compForStatus = [
    "onboardingInfo",
    "companyInfo",
    "sowPricingInfo",
    "intakeInfo",
    "googleSearchAdsStrategyInfo",
    "auditInfo",
    "accessSharing",
    "googleAuditData",
  ];
  const compWithStatus = compForStatus.reduce((obj, comp) => {
    return {
      ...obj,
      [comp]: form[comp]?.status,
    };
  }, {});

  if (compWithStatus["onboardingInfo"] === BACKEND_STATUS.COMPLETED) {
    statusObj[listOfIds.accountOnboarding] = leftBarStatus.completed;
  }
  if (compWithStatus["sowPricingInfo"] === BACKEND_STATUS.ONGOING) {
    statusObj[listOfIds.accountScopeOfWorkAndPricing] = leftBarStatus.ongoing;
  }
  if (compWithStatus["sowPricingInfo"] === BACKEND_STATUS.COMPLETED) {
    statusObj[listOfIds.accountScopeOfWorkAndPricing] = leftBarStatus.completed;
    statusObj[listOfIds.sharedAssets] = leftBarStatus.ongoing;
  }
  if (compWithStatus["accessSharing"] === BACKEND_STATUS.COMPLETED) {
    statusObj[listOfIds.sharedAssets] = leftBarStatus.completed;
  }
  if (isPlatformSelected(form, "google-ads") !== -1) {
    if (compWithStatus["googleAuditData"] === BACKEND_STATUS.ONGOING) {
      statusObj[listOfIds.audit] = leftBarStatus.ongoing;
    }
    if (compWithStatus["googleAuditData"] === BACKEND_STATUS.COMPLETED) {
      statusObj[listOfIds.audit] = leftBarStatus.completed;
    }
  }
  if (isPlatformSelected(form, "facebook-ads") !== -1) {
    if (form?.facebookAuditStatus === BACKEND_STATUS.ONGOING) {
      statusObj[listOfIdsHDM.AuditHdm] = leftBarStatus.ongoing;
    }
    if (form?.facebookAuditStatus === BACKEND_STATUS.COMPLETED) {
      statusObj[listOfIdsHDM.AuditHdm] = leftBarStatus.completed;
    }
  }
  if (isProposalStarted(form)) {
    statusObj[listOfIds.proposal] = leftBarStatus.ongoing;
  }
  if (
    form?.proposalIterations &&
    (form.proposalIterations[form.proposalIterations.length - 1] || {})
      .state === PROPOSAL_STATE.APPROVED
  ) {
    statusObj[listOfIds.proposal] = leftBarStatus.completed;
  }

  if (compWithStatus["intakeInfo"] === BACKEND_STATUS.COMPLETED) {
    statusObj[listOfIds.reviewIntakeForm] = leftBarStatus.ongoing;
  }
  if (form?.reviewIntakeForm === BACKEND_STATUS.COMPLETED) {
    statusObj[listOfIds.reviewIntakeForm] = leftBarStatus.completed;
  }

  if (isPlatformSelected(form, "google-ads") !== -1) {
    if (form?.googleStrategyCreationStatus === BACKEND_STATUS.ONGOING) {
      statusObj[listOfIds.strategyInput] = leftBarStatus.ongoing;
    }
    if (form?.googleStrategyCreationStatus === BACKEND_STATUS.COMPLETED) {
      statusObj[listOfIds.strategyInput] = leftBarStatus.completed;
    }
  }
  if (isPlatformSelected(form, "facebook-ads") !== -1) {
    if (form?.fbAdsStrategyInfo?.status === BACKEND_STATUS.ONGOING) {
      statusObj[listOfIdsHDM.StrategyCreationFacebook] = leftBarStatus.ongoing;
    }
    if (form?.fbAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED) {
      statusObj[listOfIdsHDM.StrategyCreationFacebook] =
        leftBarStatus.completed;
    }
  }
  if (isStrategyStarted(form)) {
    statusObj[listOfIds.strategy] = leftBarStatus.ongoing;
  }
  if (
    form?.strategyIterations &&
    (form.strategyIterations[(form.strategyIterations || []).length - 1] || {})
      .state === PROPOSAL_STATE.APPROVED
  ) {
    statusObj[listOfIds.strategy] = leftBarStatus.completed;
    if (isPlatformSelected(form, "google-ads") !== -1) {
      statusObj[listOfIds.campaignForGoogle] = leftBarStatus.ongoing;
    }
    if (isPlatformSelected(form, "facebook-ads") !== -1) {
      statusObj[listOfIdsHDM.CampaignCreationFB] = leftBarStatus.ongoing;
    }
  }

  if (form?.anyGoogleCampaignPublished || form?.anyFbCampaignPublished) {
    if (
      isPlatformSelected(form, "google-ads") !== -1 &&
      form?.anyGoogleCampaignPublished
    ) {
      statusObj[listOfIds.campaignForGoogle] = leftBarStatus.completed;
    }
    if (
      isPlatformSelected(form, "facebook-ads") !== -1 &&
      form?.anyFbCampaignPublished
    ) {
      statusObj[listOfIdsHDM.CampaignCreationFB] = leftBarStatus.completed;
    }
    statusObj[listOfIdsHDM.CampaignPreview] = leftBarStatus.completed;
    statusObj[listOfIds.CAMPAIGN_PREVIEW] = leftBarStatus.completed;
    statusObj[listOfIds.AnalyticDashboard] = leftBarStatus.completed;
    statusObj[listOfIds.creativeApproval] = leftBarStatus.completed;
  }

  // if (form?.googleSearchAdsGeneratedCampaignsInfo) {
  //   const [campaignObj, isGenerating, hasError, isGenerated] =
  //     getGoogleCampaignObject(form?.googleSearchAdsGeneratedCampaignsInfo);
  //   if (isGenerated) {
  //     statusObj[listOfIds.campaignForGoogle] = leftBarStatus.completed;
  //     statusObj[listOfIds.CAMPAIGN_PREVIEW] = leftBarStatus.completed;
  //   }
  // }
  // if (form?.fbAdsGeneratedCampaignsInfo) {
  //   const [campaignObj, isGenerating] = getFBCampaignObject(
  //     form?.fbAdsGeneratedCampaignsInfo
  //   );
  //   if (
  //     campaignObj.lookalike &&
  //     campaignObj.interest &&
  //     campaignObj.mof &&
  //     campaignObj.bof
  //   ) {
  //     statusObj[listOfIdsHDM.CampaignCreationFB] = leftBarStatus.completed;
  //     statusObj[listOfIdsHDM.CampaignPreview] = leftBarStatus.completed;
  //   }
  // }

  // const canShowAnalyticsDashboard = () => {
  //   let canShow = false;
  //   if (
  //     isPlatformSelected(form, "google-ads") !== -1 &&
  //     isPlatformSelected(form, "facebook-ads") !== -1
  //   ) {
  //     if (
  //       statusObj[listOfIds.campaignForGoogle] === leftBarStatus.completed &&
  //       statusObj[listOfIdsHDM.CampaignCreationFB] === leftBarStatus.completed
  //     ) {
  //       canShow = true;
  //     }
  //   } else if (
  //     isPlatformSelected(form, "google-ads") !== -1 &&
  //     statusObj[listOfIds.campaignForGoogle] === leftBarStatus.completed
  //   ) {
  //     canShow = true;
  //   } else if (
  //     isPlatformSelected(form, "facebook-ads") !== -1 &&
  //     statusObj[listOfIdsHDM.CampaignCreationFB] === leftBarStatus.completed
  //   ) {
  //     canShow = true;
  //   }
  //   return canShow;
  // };

  // if (canShowAnalyticsDashboard()) {
  //   statusObj[listOfIds.AnalyticDashboard] = leftBarStatus.completed;
  //   statusObj[listOfIds.creativeApproval] = leftBarStatus.completed;
  // }

  return statusObj;
};

export const BACKEND_STATUS = {
  PENDING: "PENDING",
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};
export const BACKEND_STATUS_HDM = {
  PENDING: "PENDING",
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
};

export const PROPOSAL_STATE = {
  GENERATED: "GENERATED",
  CHANGES_SUGGESTED: "CHANGES_SUGGESTED",
  CHANGES_APPLIED: "CHANGES_APPLIED",
  APPROVED: "APPROVED",
};

export const CRON_STATUS_BACKEND = {
  RUNNING: "RUNNING",
  DONE: "DONE",
  FAILED: "FAILED",
  PENDING: "PENDING",
};
export const getChangesSuggestedObj = (proposalIteration = []) => {
  const changesSuggestions = proposalIteration.filter(
    (proposal) => proposal.state === PROPOSAL_STATE.CHANGES_SUGGESTED
  );
  const changesSuggestedObj = {};
  changesSuggestions.forEach((proposal) => {
    changesSuggestedObj[
      `${proposal.stateTs.slice(0, 10)} ${proposal.stateTs.slice(11, 19)}`
    ] = proposal.suggestedChanges[proposal.suggestedChanges.length - 1];
  });

  return changesSuggestedObj;
};

// return [campaignObj, isGeneratingCampaign]
export const getFBCampaignObject = (generatedCampaignsInfo = []) => {
  // {campaignType: campaignId}
  const campaignObj = {
    lookalike: null,
    interest: null,
    mof: null,
    bof: null,
  };
  let isGenerating = false;

  (generatedCampaignsInfo || []).forEach((info) => {
    if (!info.responseData) {
      isGenerating = true;
    }
    if (
      info.campaignType === "top_of_the_funnel_lookalike" &&
      info.responseData &&
      info.responseData.status === "SUCCESS"
    ) {
      campaignObj.lookalike = info.responseData.data.campaign_id;
    }
    if (
      info.campaignType === "top_of_the_funnel_interest" &&
      info.responseData &&
      info.responseData.status === "SUCCESS"
    ) {
      campaignObj.interest = info.responseData.data.campaign_id;
    }
    if (
      info.campaignType === "middle_of_the_funnel" &&
      info.responseData &&
      info.responseData.status === "SUCCESS"
    ) {
      campaignObj.mof = info.responseData.data.campaign_id;
    }
    if (
      info.campaignType === "bottom_of_the_funnel" &&
      info.responseData &&
      info.responseData.status === "SUCCESS"
    ) {
      campaignObj.bof = info.responseData.data.campaign_id;
    }
  });

  return [campaignObj, isGenerating];
};

export const getGoogleCampaignObject = (generatedCampaignsInfo = []) => {
  const campaignObj = {
    brandCampaign: null,
    competitorCampaign: null,
    serviceCampaign: null,
  };

  let isGenerating = false;
  let isGenerated = false;
  let hasError = true;

  generatedCampaignsInfo.forEach((info) => {
    if (!info.campaignId && !info.failureReason) {
      isGenerating = true;
    }
    if (info.campaignType === "BRAND" && info.campaignId) {
      campaignObj.brandCampaign = info.campaignId;
    }
    if (info.campaignType === "COMPETITOR" && info.campaignId) {
      campaignObj.competitorCampaign = info.campaignId;
    }
    if (info.campaignType === "SERVICE" && info.campaignId) {
      campaignObj.serviceCampaign = info.campaignId;
    }
  });

  if (!isGenerating) {
    let tempHasError = false;
    ["brandCampaign", "competitorCampaign", "serviceCampaign"].forEach(
      (campaign) => {
        if (!campaignObj[campaign]) {
          tempHasError = true;
        }
      }
    );
    if (!tempHasError) {
      hasError = false;
    }
  } else {
    hasError = false;
  }

  if (!hasError && !isGenerating) {
    isGenerated = true;
  }

  return [campaignObj, isGenerating, hasError, isGenerated];
};

export const getGooglePmaxCampaignObject = (
  generatedCampaignsInfo = [],
  campaignIdsWithCampaignNumber = {}
) => {
  const campaignObj = {
    "Campaign 1": null,
    "campaign 2": null,
    "campaign 3": null,
  };

  let isGenerating = false;
  generatedCampaignsInfo.forEach((info) => {
    if (!info.campaignId && !info.failureReason) {
      isGenerating = true;
    }
    if (
      info.internalCampaignId === campaignIdsWithCampaignNumber["Campaign 1"] &&
      info.campaignId
    ) {
      campaignObj["Campaign 1"] = info.campaignId;
    }
    if (
      info.internalCampaignId === campaignIdsWithCampaignNumber["Campaign 2"] &&
      info.campaignId
    ) {
      campaignObj["Campaign 2"] = info.campaignId;
    }
    if (
      info.internalCampaignId === campaignIdsWithCampaignNumber["Campaign 3"] &&
      info.campaignId
    ) {
      campaignObj["Campaign 3"] = info.campaignId;
    }
  });

  return [campaignObj, isGenerating];
};

/**
 *
 * @param {*} campaignType brandCampaign | competitorCampaign | serviceCampaign
 * @param {*} generatedCampaignsInfo
 * @returns
 */
export const getGoogleCampaignStatus = (
  campaignType,
  generatedCampaignsInfo = []
) => {
  const _campaignType = {
    brandCampaign: "BRAND",
    competitorCampaign: "COMPETITOR",
    serviceCampaign: "SERVICE",
  }[campaignType];
  const filteredCampaigns = generatedCampaignsInfo
    .filter((info) => info.campaignType === _campaignType)
    .sort((a, b) => new Date(b.requestTs) - new Date(a.requestTs));
  const [latest] = filteredCampaigns;

  const result = {
    campaignId: null,
    isPublishing: false,
    isCompleted: false,
    errors: [],
    isNotStarted: false,
  };

  // if no campaigns exists of the give campaignType >> return defaults;
  if (!latest) {
    result.isNotStarted = true;
    return result;
  }
  // // if failureReason exists >> errors = list of errors
  // if (latest.failureReason) {
  //   // obj.errors = [latest.failureReason];
  //   result.errors = filteredCampaigns.map(item => item.failureReason).filter(Boolean);
  //   return result;
  // }
  if (latest.failureLog) {
    const formatedError = (
      <span>
        <b>{latest.failureLog.errorCode} </b>
        {latest.failureLog.errorMessage}
      </span>
    );
    result.errors = [formatedError];
    return result;
  }
  // if no campaignId and no failureReason >> publishing state
  if (!latest.campaignId) {
    result.isPublishing = true;
    return result;
  }
  // if campaignId exists >> complete
  result.campaignId = latest.campaignId;
  result.isCompleted = true;

  return result;
};

/**
 *
 * @param {*} campaignType campaign 1 | campaign 2 | campaign 3
 * @param {*} generatedCampaignsInfo
 * @param {*} campaignIdsWithCampaignNumber
 * @returns
 */
export const getGooglePMaxCampaignStatus = (
  campaignType,
  generatedCampaignsInfo = [],
  campaignIdsWithCampaignNumber = {}
) => {
  const _campaignType = campaignIdsWithCampaignNumber[campaignType];
  const filteredCampaigns = generatedCampaignsInfo
    .filter((info) => info.internalCampaignId === _campaignType)
    .sort((a, b) => new Date(b.requestTs) - new Date(a.requestTs));
  const [latest] = filteredCampaigns;

  const result = {
    campaignId: null,
    isPublishing: false,
    isCompleted: false,
    errors: [],
    isNotStarted: false,
  };

  // if no campaigns exists of the give campaignType >> return defaults;
  if (!latest) {
    result.isNotStarted = true;
    return result;
  }
  // // if failureReason exists >> errors = list of errors
  // if (latest.failureReason) {
  //   // obj.errors = [latest.failureReason];
  //   result.errors = filteredCampaigns.map(item => item.failureReason).filter(Boolean);
  //   return result;
  // }
  if (latest.failureLog) {
    const formatedError = (
      <span>
        <b>{latest.failureLog.errorCode} </b>
        {latest.failureLog.errorMessage}
      </span>
    );
    result.errors = [formatedError];
    return result;
  }
  // if no campaignId and no failureReason >> publishing state
  if (!latest.campaignId) {
    result.isPublishing = true;
    return result;
  }
  // if campaignId exists >> complete
  result.campaignId = latest.campaignId;
  result.isCompleted = true;

  return result;
};

export const isPlatformSelectedHDM = (form, platformName) => {
  return form?.onboardingInfo?.clientAccess?.platforms?.findIndex(
    (platform) => platform.platform === platformName
  );
};

export const canPMaxConfirm = (assets = []) => {
  let canConfirm = true;
  assets?.map((asset) => {
    if (
      asset.status !== BACKEND_STATUS.COMPLETED ||
      (asset.errors && Object.keys(asset.errors).length)
    )
      canConfirm = false;
  });
  return canConfirm;
};

export const frontendInterestDemoToBackendInterestDemo = (
  interestDemo = []
) => {
  const backendInterestDemo = {};
  interestDemo.forEach((interDemo) => {
    if (backendInterestDemo[interDemo.type]) {
      backendInterestDemo[interDemo.type].push({
        criterionId: interDemo.criterionId,
        parentId: interDemo.parentId,
        category: interDemo.category,
      });
    } else {
      backendInterestDemo[interDemo.type] = [
        {
          criterionId: interDemo.criterionId,
          parentId: interDemo.parentId,
          category: interDemo.category,
        },
      ];
    }
  });

  return backendInterestDemo;
};

export const backendInterestDemoToFrontendInterestDemo = (
  interestDemo = {}
) => {
  const frontendInterestDemo = [];
  (Object.keys(interestDemo) || []).forEach((interDemoType) => {
    interestDemo[interDemoType].forEach((interDemo) => {
      let values = interDemo.category.split("/");
      frontendInterestDemo.push({
        ...interDemo,
        type: interDemoType,
        value: values[values.length - 1],
      });
    });
  });
  return frontendInterestDemo;
};

export const updateLeftSideBarGoogleSelectorPage = (form) => {
  // if (
  //   form?.googlePmaxStrategy?.status === BACKEND_STATUS.COMPLETED ||
  //   form?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED
  // )
  //   return BACKEND_STATUS.COMPLETED;
  if (form?.googleStrategyCreationStatus === BACKEND_STATUS.COMPLETED)
    return BACKEND_STATUS.COMPLETED;
  else return BACKEND_STATUS.ONGOING;
};
