import { all, put, takeLatest, select, delay } from "redux-saga/effects";
import { api } from "../../index";
import { getLocalStorage } from "../../localStorage/localStorage";
import {
  updateLookalike,
  updateInterest,
  updatemof,
  updatebof,
} from "../campaign/action";
import { checkingReadAccess, dbStatus } from "../../../components/ClientDashboard/HomePage/HomePage";
import { BACKEND_STATUS, isPlatformSelected, isPlatformSelectedHDM } from "../../../utils/accountManagerUtil";
import { updateData } from "../../../api/accountManager/forms/slice";
import agencyTypes from "../../../utils/agencyType";

function* updateWholeHdmAmForm() {
  try {
    const res = yield api.get(
      "/client-forms/" + (getLocalStorage("amSelectedFormId") ? getLocalStorage("amSelectedFormId") : getLocalStorage("formId"))
    );
    yield put({
      type: "UPDATE_WHOLE_HDM_AM_DETAILS",
      payload: res.data,
    });
    if (res?.data?.campaignInfo) {
      yield* [
        "topOfTheFunnelLookalike",
        "topOfTheFunnelInterest",
        "middleOfTheFunnel",
        "bottomOfTheFunnel",
      ].map(function* (strategyType) {
        switch (strategyType) {
          case "topOfTheFunnelLookalike":
            yield put(
              updateLookalike({
                ...(res?.data?.campaignInfo?.topOfTheFunnelLookalike || {}),
              })
            );
          case "topOfTheFunnelInterest":
            yield put(
              updateInterest({
                ...(res?.data?.campaignInfo?.topOfTheFunnelInterest || {}),
              })
            );
          case "middleOfTheFunnel":
            yield put(
              updatemof({
                ...(res?.data?.campaignInfo?.middleOfTheFunnel || {}),
              })
            );
          case "bottomOfTheFunnel":
            yield put(
              updatebof({
                ...(res?.data?.campaignInfo?.bottomOfTheFunnel || {}),
              })
            );
        }
      });
    }
  } catch (err) {
    console.log("updateWholeHdmAmForm error",err);
  }
}
function* saveHdmAdmFormData(action) {
    try{
      let client = yield select((state) => state.hdmFormAm);
      client=JSON.parse(JSON.stringify({...client}));
      const currentCompo = yield select(
        (state) => state.hdmAMCurrentComp.currentCompAm
      );
      const currentCompoLd = yield select(
        (state) => state.amCurrentComp.currentCompAm
      );
      const ldForm =  yield select(
        (state) => state.form.form)
      let hdmClient = yield select((state) => state.hdmClient);
      hdmClient=JSON.parse(JSON.stringify({...hdmClient}));
      if (hdmClient?.accessSharing && client?.accessSharing) {
        const accessSharing = hdmClient?.accessSharing;
        delete accessSharing.status;
        client["accessSharing"] = { ...client["accessSharing"], ...accessSharing };
      }
      const isFormIdExist = {};
      if (getLocalStorage("amSelectedFormId") || getLocalStorage("formId")) {
        isFormIdExist["_id"] = (getLocalStorage("amSelectedFormId") ?  getLocalStorage("amSelectedFormId") :  getLocalStorage("formId"));
      }
      if ((currentCompo === 12 || currentCompo === 13) && client["accountAnalysis"]["status"] !== "COMPLETED") {
        client["accountAnalysis"]["status"] = "ONGOING";
      }
      if (currentCompo === 17) {
        client["accountAnalysis"]["status"] = "COMPLETED";
      }
      if (
        currentCompo === 21 &&
        client["fbAdsStrategyInfo"]["status"] !== "COMPLETED"
      ) {
        client["fbAdsStrategyInfo"]["status"] = "ONGOING";
      }
      if (currentCompo === 24) {
        client["fbAdsStrategyInfo"]["status"] = "COMPLETED";
      }
      if (currentCompo === 21) {
        client["fbAdsStrategyInfo"]["topOfTheFunnelLookalike"]["status"] = "ONGOING";
      }
      if (currentCompo === 22) {
        client["fbAdsStrategyInfo"]["topOfTheFunnelInterest"]["status"] = "ONGOING";
      }
      if (currentCompo === 23) {
        client["fbAdsStrategyInfo"]["middleOfTheFunnel"]["status"] = "ONGOING";
      }
      if (currentCompo === 24) {
        client["fbAdsStrategyInfo"]["bottomOfTheFunnel"]["status"] = "ONGOING";
      } if(currentCompo >= 6){
        delete client["proposalStatus"];
      }
      //if(getLocalStorage("agencyId") === "1" && currentCompoLd >= 30 && currentCompoLd <= 33){
      if(getLocalStorage("agencyType") === agencyTypes.LINEAR && currentCompoLd >= 30 && currentCompoLd <= 33){
        if (client["fbAdsStrategyInfo"]["status"] !== "COMPLETED"
          ) {
            client["fbAdsStrategyInfo"]["status"] = "ONGOING";
            client["fbAdsStrategyInfo"]["topOfTheFunnelLookalike"]["status"] = "ONGOING";
        }
      }
      try {
        const res = yield api.post(
          "/client-forms",
          { ...(action.payload || client), ...isFormIdExist },
          false
        );
        yield put({
          type: "UPDATE_WHOLE_HDM_AM_DETAILS",
          payload: res.data,
        });
        yield put(updateData(res.data));
        if (res?.data?.campaignInfo) {
          yield* [
            "topOfTheFunnelLookalike",
            "topOfTheFunnelInterest",
            "middleOfTheFunnel",
            "bottomOfTheFunnel",
          ].map(function* (strategyType) {
            switch (strategyType) {
              case "topOfTheFunnelLookalike":
                yield put(
                  updateLookalike({
                    ...(res?.data?.campaignInfo?.topOfTheFunnelLookalike || {}),
                  })
                );
              case "topOfTheFunnelInterest":
                yield put(
                  updateInterest({
                    ...(res?.data?.campaignInfo?.topOfTheFunnelInterest || {}),
                  })
                );
              case "middleOfTheFunnel":
                yield put(
                  updatemof({
                    ...(res?.data?.campaignInfo?.middleOfTheFunnel || {}),
                  })
                );
              case "bottomOfTheFunnel":
                yield put(
                  updatebof({
                    ...(res?.data?.campaignInfo?.bottomOfTheFunnel || {}),
                  })
                );
            }
          });
        }
      } catch (err) {
        console.log("error ", err);
      }
    }catch(err){
      console.log("hjdshjdsjh",err)
    }
}

function* publishStrategy(action) {
  console.log("################ in publish strategy saga", action);
  let client = yield select((state) => state.hdmFormAm);
  console.log("aksdjfaksjdfkajsfkjak", client);
  client = {
    ...client,
    strategyIterations: [
      ...(client.strategyIterations || []),
      ...(action.payload.strategyIterations || []),
    ],
  };
  console.log("after log", client);
  try {
    console.log("before api call");
    const res = yield api.post("/client-forms", { ...client }, false);
    console.log("after api call");
    yield put({
      type: "UPDATE_WHOLE_HDM_AM_DETAILS",
      payload: res.data,
    });
    yield put({
      type: "UPDATE_WHOLE_HDM_AM_DETAILS",
      payload: res.data,
    });
  } catch (err) {
    console.log("error ", err);
  }
}

export const updateStatusProposalHdm = (
  client,
  status,
  generateDeck = false
) => {
  const formId = localStorage.getItem("formId");
  const brandId = localStorage.getItem("brandId");
  const body = {};
  if (generateDeck) {
    body["fbAdsStrategyInfo"]["status"] = "COMPLETED";
  } else {
    body["proposalStatus"] = status;
    if(isPlatformSelectedHDM(client, "google-ads") !== -1){
      body["googleAuditData"] = {status: BACKEND_STATUS.ONGOING};
    }
    if(isPlatformSelectedHDM(client, "facebook-ads") !== -1){
      body["facebookAuditStatus"] = BACKEND_STATUS.ONGOING;
    }

  }
  return new Promise(async (res, rej) => {
    try {
      const data = await api.post(
        "/client-forms",
        { ...body, _id: formId, brandId },
        false
      );
      res(data);
    } catch (err) {
      rej(err);
    }
  });
};

export const isDataSavedHdmAmForm = (client, hdmClient,type="HDM",objData=null) => {
  let newClient=JSON.parse(JSON.stringify({...client}));
  const isFormIdExist = {};
  if (getLocalStorage("amSelectedFormId")) {
    isFormIdExist["_id"] = getLocalStorage("amSelectedFormId");
  }
  if(type === "LD"){
    const jsonifyData=JSON.parse(JSON.stringify({...objData}));
    if(jsonifyData.accessSharing.fbAccess.adAccount.status === "READ_ACCESS"){
      jsonifyData.fbAdsAccountId=objData.accessSharing.fbAccess.adAccount.accountId;
    }
    if(checkingReadAccess(objData?.onboardingInfo?.platformInfo?.platforms, objData?.accessSharing) === dbStatus.ACCESS_REVOKED){
      jsonifyData.accessSharing.status = BACKEND_STATUS.PENDING;
    }
    if(checkingReadAccess(objData?.onboardingInfo?.platformInfo?.platforms, objData?.accessSharing) === dbStatus.READ_ACCESS ){
      jsonifyData.accessSharing.status = BACKEND_STATUS.COMPLETED;
      if(isPlatformSelected(jsonifyData, "google-ads") !== -1 && jsonifyData.googleAuditData?.status !== BACKEND_STATUS.COMPLETED){
        jsonifyData.googleAuditData.status = BACKEND_STATUS.ONGOING;
      }
      if(isPlatformSelected(jsonifyData, "facebook-ads") !== -1 && jsonifyData.facebookAuditStatus !== BACKEND_STATUS.COMPLETED){
        jsonifyData.facebookAuditStatus = BACKEND_STATUS.ONGOING;
      }
    }
    newClient=jsonifyData;
  }else if(type === "HDM") {
    const accessSharing = {...client?.accessSharing};
    if(accessSharing?.fbAccess?.adAccount?.status === "READ_ACCESS" || accessSharing?.googleAccess?.googleAdAccount?.status === "READ_ACCESS"){
      newClient["accessSharing"]["status"]="ONGOING";
    }else if(accessSharing?.fbAccess?.adAccount?.status === "COMPLETE_ACCESS" && accessSharing?.googleAccess?.googleAdAccount?.status === "COMPLETE_ACCESS"){
      newClient["accessSharing"]["status"]="COMPLETED";
    }else if(accessSharing?.fbAccess?.adAccount?.status === "ACCESS_REVOKED" || accessSharing?.googleAccess?.googleAdAccount?.status === "ACCESS_REVOKED"){
      newClient["accessSharing"]["status"]="PENDING";
    }
  }
  return new Promise(async (res, rej) => {
    try {
      const data = await api.post(
        "/client-forms",
        { ...newClient, ...isFormIdExist },
        false
      );
      res(data);
    } catch (err) {
      rej(err);
    }
  });
};

export const saveCompleteAccess = (forms,status,isClient=true,accessType) => {
  const jsonifyData=JSON.parse(JSON.stringify(forms));
  const platform=jsonifyData.onboardingInfo?.platformInfo?.platforms || jsonifyData.onboardingInfo?.clientAccess?.platforms;
  if(isClient){
    platform.forEach((platform) => {
      if(platform.platform === "google-ads" && jsonifyData.accessSharing.googleAccess.googleAdAccount.status !== "COMPLETE_ACCESS"){
        jsonifyData.accessSharing.googleAccess.googleAdAccount.status=status;
      }else if(platform.platform === "facebook-ads" && jsonifyData.accessSharing.fbAccess.adAccount.status !== "COMPLETE_ACCESS"){
        jsonifyData.accessSharing.fbAccess.adAccount.status=status;
      }
    });
    jsonifyData.completedSharedAssetStatus = BACKEND_STATUS.ONGOING;  
  }else if(status === "COMPLETE_ACCESS"){
    let acceseSharing=jsonifyData["accessSharing"];
    let isComplete=true;
    if(accessType === "HDM"){
      if(acceseSharing?.fbAccess?.adAccount?.status === "ACCESS_REVOKED" || acceseSharing?.googleAccess?.googleAdAccount?.status === "ACCESS_REVOKED" || acceseSharing?.tiktokAccess?.tiktokAdsManager?.status === "ACCESS_REVOKED"){
        isComplete=false;
      }
    }else{
      if(acceseSharing?.fbAccess?.adAccount?.status === "COMPLETED_ACCESS_NOT_SHARED" || acceseSharing?.googleAccess?.googleAdAccount?.status === "COMPLETED_ACCESS_NOT_SHARED"){
        isComplete=false;
      }
    }
    if(isComplete){
      jsonifyData["accessSharing"]["status"]="COMPLETED";
      if(accessType === 'LD'){
        if(acceseSharing?.googleAccess?.googleAdAccount?.status === "COMPLETE_ACCESS"){
          jsonifyData["googleStrategyCreationStatus"] = BACKEND_STATUS.ONGOING;
        }
        if(acceseSharing?.fbAccess?.adAccount?.status === "COMPLETE_ACCESS"){
          jsonifyData["fbAdsStrategyInfo"]["status"] = BACKEND_STATUS.ONGOING;
        }
        jsonifyData["completedSharedAssetStatus"] = BACKEND_STATUS.COMPLETED;
      }
    }else{
      jsonifyData["accessSharing"]["status"]="PENDING";
    }
    jsonifyData["completedSharedAssetStatus"]="COMPLETED";
  }
  return new Promise(async (res, rej) => {
    try {
      const data = await api.post(
        "/client-forms",
        { ...jsonifyData },
        false
      );
      res(data);
    } catch (err) {
      rej(err);
    }
  });
}

export default function* root() {
  yield all([
    takeLatest("UPDATE_WHOLE_HDM_AM_DETAILS_SAGA", updateWholeHdmAmForm),
    takeLatest("SAVE_WHOLE_HDM_AM_DETAILS_SAGA", saveHdmAdmFormData),
    takeLatest("PUBLISH_HDM_AM_STRATEGY", publishStrategy),
  ]);
}
