import moment from "moment";
import { defaultFBReport, defaultFBState, defaultGAReport, defaultGAState } from "../../components/models/defaultData";
import { CHANGE_DATE, SELECT_ACCOUNT, SWITCH_REDIRECT, UPDATE_CHART_LOCAL } from "./enum";
const formatDate = date => moment(date).format("YYYY-MM-DD");

const endDate = new Date();
endDate.setDate(endDate.getDate() - 1);
const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);

const INITIAL_STATE = {
    redirectBack: false,
    dateRange: {
        label: "Last 7 Days",
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
    },
    selectedAccounts: {},
    allReports: [
        {
            "id": "facebook-ads",
            "report_name": "FB Analytics",
            "report_type": null,
            "chart_count": 12,
            "report_icon": "/static/media/logos_facebook.91b71a2ce3d132c597af28daa19fff42.svg"
        },
        {
            "id": "google-ads",
            "report_name": "GA Analytics",
            "report_type": null,
            "chart_count": 12,
            "report_icon": "/static/media/logos_google-ads.8fae42304be5c592e8094e8abb988cef.svg"
        }
    ],
    reports: {
        "facebook-ads": defaultFBReport,
        "google-ads": defaultGAReport
    },
    "facebook-ads": defaultFBState,
    "google-ads": defaultGAState
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_DATE:
            return { ...state, dateRange: { ...action.payload, startDate: formatDate(action.payload.startDate), endDate: formatDate(action.payload.endDate) } };
        case UPDATE_CHART_LOCAL:
            var newCharts = Array.from(state.reports[action.payload.reportId].charts);
            action.payload.updates.forEach(update => {
                switch (update.update_type) {
                    case "update":
                        const index = newCharts.findIndex(chart => chart.id === update.chart_id);
                        newCharts[index] = { ...newCharts[index], ...update.params };
                        break;
                    case "delete":
                        newCharts = newCharts.filter(chart => chart.id !== update.chart_id);
                        break;
                    case "add":
                        newCharts.push(update.params);
                        break;
                }
            });
            return { ...state, reports: { ...state.reports, [action.payload.reportId]: { ...state.reports[action.payload.reportId], charts: newCharts, updated_at: new Date().getTime() } } };
        case SELECT_ACCOUNT:
            const newSelectedAccounts = { ...state.selectedAccounts };
            if (action.payload.account) {
                newSelectedAccounts[action.payload.channel] = action.payload.account;
            } else {
                delete newSelectedAccounts[action.payload.channel];
            }
            return { ...state, selectedAccounts: newSelectedAccounts };
        case SWITCH_REDIRECT:
            return { ...state, redirectBack: !state.redirectBack }
        default:
            return state;
    }
};

export default reducer;
