import { Typography, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import "./adlevel.css";
import AdLevelChild from "./AdLevelChild";
import AdLevelHeadlineInputChild from "./AdLevelHeadlineInputChild";
import AdLevelDescriptionInputChild from "./AdLevelDescriptionInputChild";
const RenderComponentOnNav = (props) => {
  switch (props.currentNavTab) {
    case "headline":
      return (
        <div className="ad-list-result-container">
          {(props.headlines || []).map((headline, idx) => {
            return (
              <AdLevelChild
                value={headline}
                totalCharacter={30}
                read={props.read}
                key={idx}
                deleteResource={props.deleteHead}
                campaignType={props.campaignType}
                adSetName={props.adSetName}
                editResource={props.editHeadline}
                isDisabled={props.isDisabled}
                index={idx}
              />
            );
          })}
          {props.headlineInput.map((headline) => (
            <AdLevelHeadlineInputChild
              id={headline.id}
              totalCharacter={30}
              deleteHeadline={props.deleteHeadline}
              addHeadline={props.addHeadline}
              campaignType={props.campaignType}
              adSetName={props.adSetName}
              isDisabled={props.isDisabled}
            />
          ))}
        </div>
      );
    case "description":
      return (
        <div className="ad-list-result-container">
          {(props.descriptions || []).map((description, idx) => {
            return (
              <AdLevelChild
                value={description}
                totalCharacter={90}
                read={props.read}
                key={idx}
                deleteResource={props.deleteDesc}
                campaignType={props.campaignType}
                adSetName={props.adSetName}
                editResource={props.editDescription}
                isDisabled={props.isDisabled}
                index={idx}
              />
            );
          })}
          {props.descriptionInput.map((description) => (
            <AdLevelDescriptionInputChild
              id={description.id}
              totalCharacter={90}
              deleteDescription={props.deleteDescription}
              addDescription={props.addDescription}
              campaignType={props.campaignType}
              adSetName={props.adSetName}
              isDisabled={props.isDisabled}
            />
          ))}
        </div>
      );
    case "preview":
      return (
        <div className="preview-ad-list-results">
          <div className="preview-ad-list-result">
            <Typography className="preview-ad-list-result__heading">
              {props.headlines
                ? props.headlines
                    .slice(0, 3)
                    .map((head) => head.value)
                    .join(" - ")
                : ""}
            </Typography>
            <Typography className="preview-ad-list-result__url">
              {props.website || ""}
            </Typography>
            <Typography className="preview-ad-list-result__description">
              {props.descriptions
                ? props.descriptions
                    .slice(0, 1)
                    .map((head) => head.value)
                    .join(" - ")
                : ""}
            </Typography>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

export default RenderComponentOnNav;
