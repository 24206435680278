import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { PLATFORM } from "../../../utils/commonEnums";
export const StyledAccountListText = styled(Typography)`
  color: #2e3044;
  text-align: center;
  font-family: Lato;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 8px;
  margin-top: 36px;
`;
export const StyledAccountParaText = styled(Typography)`
  color: #6d6e7c;
  text-align: center;
  font-family: Inter;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 0 12px;
  margin: 0 28px;
  margin-bottom: 32px;
`;
export const StyledFooterButton = styled("div")`
  box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.04);
  /* border: 1px solid red; */
  text-align: center;
  padding: 24px 0;
  cursor: pointer;
`;
export const StyledAccountList = styled("div")`
  min-height: 272px;
  max-height: 272px;
  overflow-y: scroll;
  margin: 0 28px;
  scroll-margin: 10px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: #eaeaec;
    margin-left: 6px;
  }
`;
export const StyledIconContainer = styled("div")(
  ({ icon }) => `
  width: 40px;
  height: 40px;
  position: absolute;
  top: ${icon === PLATFORM.GOOGLE.toLowerCase() ? "21px" : "6px"};
  left: ${icon === PLATFORM.GOOGLE.toLowerCase() ? "21px" : "14px"};
`
);
export const StyledFooterText = styled("div")`
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #0869fb;
`;
export const StyledPlusText = styled("span")`
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #0869fb;
  margin-right: 4px;
`;
