import React from 'react';
import { Typography, Tooltip } from '@mui/material';
const textStyle = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '19px',
  display: 'flex',
  alignItems: 'center',
  gap: 6,
};
const ExpandAssetNameGroupTooltip = ({
  assetGroupName,
  shortAssetGroupName,
}) => (
  <Tooltip
    PopperProps={{
      sx: {
        '& .MuiTooltip-arrow': {
          color: 'white',
        },
        '& .MuiTooltip-tooltip': {
          backgroundColor: 'white',
          color: 'black',
          marginBottom: '20px',
          filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
          //   padding: '10px 12px 10px 13px',
          //   width: 181,
          //   borderRadius: 2,
        },
      },
    }}
    title={
      <>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '20px',
            color: '#333333',
          }}
        >
          {assetGroupName}
        </Typography>
      </>
    }
    arrow
    placement='right'
  >
    <Typography style={textStyle}>{shortAssetGroupName}</Typography>
  </Tooltip>
);

export default ExpandAssetNameGroupTooltip;
