import React, { useEffect, useState } from "react";
import { back_arrow as backArrow } from "../../../assets/icons/icon";
import { Typography, Box, Button } from "@mui/material";
import "./look-alike-campaign-fb.css";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Conversion from "../Conversion";
import LookAlikeTargetting from "./LookAlikeTargetting";
import { useDispatch, useSelector } from "react-redux";
import { updateLookalike } from "../../../api/HDMAM/campaign/action";
import {
  goBackAmCompo,
  goToSpecificCompoHDMAM,
} from "../../../api/HDMAM/componentTracking/action";
import TabPanel from "@mui/lab/TabPanel";
import CheckedIcon from "../../AccountManager/CampaignApproval/CheckedIcon";
import HDMAdset from "../HDMCampaignCreation/HDMAdset";
import CampaignConfirmModal from "../HDMCampaignCreation/CampaignConfirmModal";
import {
  cta as ctaMapping,
  optimisationAdDelivery,
  optimizationGoalBackendMapping,
  CTABackendMapping,
  destinationTypeBackendMapping,
  objectiveFrontendMappiing,
  conversionLocation as conversionLocationMapping,
  conversionEvents,
  destinationTypeFrontendMapping,
  selectPixelPage,
} from "../HDMCampaignCreation/constants";
import {
  accountManagerIds,
  accountManagerIdsHDM,
  BACKEND_STATUS,
  componentWithIndexHDMAm,
  ldComponentWithIndexAm,
} from "../../../utils/accountManagerUtil";
import {
  goToSpecificCompoClient,
  goToSpecificCompoClientAm,
} from "../../../api/accountManager/componentTracking/action";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import AssetGroupTabListView from "../../PMaxStrategyCreation/AssetGroupTabListView";
import {
  useGetFbPages,
  useGetFbPixelEvents,
  useGetFbPixels,
} from "../../../services/fb-ads";
import { useGetClientForms } from "../../../services/clientForms";
import AddNewAdModal from "../../FacebookStrategyCreation/AdsetLevel/AddNewAdModal";
import CreateAdsetModal from "../../FacebookCampaignCreation/CreateAdsetModal";
import {
  useDeleteFbAdsCampaignCreationAdsets,
  useGetFBAdsCampaignCreaionById,
  usePutFbAdsCampaignCreation,
} from "../../../services/fb-campaign-creation";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";
import {
  ABO_ENUM,
  allowedConversionLocation,
} from "../../FacebookCampaignCreation/constants";
import AllConfirmModal from "../../PMaxStrategyCreation/AllConfirmModal";
import { getWebsiteUrl } from "../../../utils/getWebsiteWrapper";

const LookAlikeCampaign = ({
  agencyType = "HDM",
  setModalOpen = () => {},
  goToListScreen = () => {},
}) => {
  const dispatch = useDispatch();
  const { formId, campaignId } = useParams();
  const [showAd, setShowAd] = useState(false);
  const { data: clientForm } = useGetClientForms(formId);
  // const lookalikeData = useSelector((store) => store.hdmCampaign.lookalike);
  const updateAdset = (details) => {
    const updatedAdsets = [...adsets];
    updatedAdsets[currentAdsetIndex] = {
      ...updatedAdsets[currentAdsetIndex],
      ...details,
    };
    setCurrentAdset((prev) => ({ ...prev, ...details }));
    setAdsets(updatedAdsets);
  };
  const campaignConfirmModal = useSelector(
    (store) => store.hdmCampaign.campaignConfirmModal
  );

  const fbTargeting = useSelector(
    (store) => store?.form?.form?.onboardingInfo?.facebookTargeting || {}
  );
  const [pixelId, setPixelId] = useState(null);
  const [events, setEvents] = useState({});
  useGetFbPixelEvents(pixelId, {
    onSuccess: (data) => {
      setEvents((prev) => ({ ...prev, ...{ [pixelId]: data?.events } }));
    },
    enabled: Boolean(pixelId),
  });
  const [pixels, setPixels] = useState([]);
  const [pages, setPages] = useState([]);
  useGetFbPixels(clientForm?.clientCampaignFacebookAdsInfo?.accountId, {
    onSuccess: (data) => {
      if (data?.pixels) setPixels(data.pixels);
    },
  });
  useGetFbPages({
    onSuccess: (data) => {
      if (data?.pages) setPages(data.pages);
    },
  });

  const [isCreateAdsetModalOpen, setIsCreateAdsetModalOpen] = useState(false);
  const openCreateAdsetModal = () => setIsCreateAdsetModalOpen(true);
  const closeCreateAdsetModal = () => setIsCreateAdsetModalOpen(false);
  // console.log("Fb Pixel :: ", pixels);
  // console.log("Fb Pages :: ", pages);
  const [campaign, setCampaign] = useState({});
  const [adsets, setAdsets] = useState([]);
  const [currentAdset, setCurrentAdset] = useState({});
  const [showAllConfirmModal, setShowAllConfirmModal] = useState(false);
  const [adsetCount, setAdsetCount] = useState(1);
  const { isFetching: isFetchingGetCampaigns } = useGetFBAdsCampaignCreaionById(
    { formId, campaignId },
    {
      onSuccess: (data) => {
        setCampaign(data);
        if (!data?.adsets?.length) {
          const newAdset = [
            {
              adsetName: "Adset 1",
              status: BACKEND_STATUS.ONGOING,
              adsetNumber: 1,
            },
          ];
          setAdsets(newAdset);
          setCurrentAdset(newAdset[0]);
          setCurrentAdsetIndex(0);
          setAdsetCount(1);
          openCreateAdsetModal();
          return;
        }
        setAdsetCount(data.adsetCount);
        const adsets = data.adsets.map((adset) => ({
          ...adset,
          savedStatus: true,
        }));
        setAdsets(adsets);
        setCurrentAdset(adsets?.[0]);
      },
    }
  );
  const { mutate: mutatePutCampaign } = usePutFbAdsCampaignCreation(formId, {
    onSuccess: (data) => {
      const { adsets } = data;
      let updatedAdset = adsets?.find(
        (adset) => adset.adsetNumber === currentAdset.adsetNumber
      );
      updateAdset({ ...updatedAdset, savedStatus: true });

      // Check if all adsets are confirmed
      let tempCurrAdsets = JSON.parse(JSON.stringify(adsets));
      tempCurrAdsets[
        tempCurrAdsets.findIndex(
          (adset) => adset.adsetNumber === currentAdset.adsetNumber
        )
      ] = updatedAdset;
      if (
        tempCurrAdsets.every(
          (adset) => adset.status === BACKEND_STATUS.COMPLETED
        )
      ) {
        setShowAllConfirmModal(true);
      }
    },
  });
  const { mutateAsync: mutateDeleteAdset } =
    useDeleteFbAdsCampaignCreationAdsets({ formId, campaignId });
  const [objective, setObjective] = useState(campaign?.objective || "");
  const [bidStrategy, setBidStrategy] = useState(campaign?.bidStrategy || "");
  const [campaignGeneratedInfoStatus, setCampaignGeneratedInfoStatus] =
    useState(campaign?.campaignGeneratedInfoStatus || "PENDING");
  const [abo, setAbo] = useState(campaign?.abo || "ON");
  // const [budget, setBudget] = useState(campaign.budget);
  const [currentAdsetIndex, setCurrentAdsetIndex] = useState(0);
  const [value, setValue] = React.useState("0");
  const [id, setId] = useState(currentAdset?.id || null);
  const [status, setStatus] = useState(currentAdset?.status || "ONGOING");
  const [adsetName, setAdsetName] = useState(currentAdset?.adsetName || "");
  const [budget, setBudget] = useState(() => {
    if (abo === ABO_ENUM.OFF) return currentAdset?.budget || 0;
    else return null;
  });
  const [adsetNumber, setAdsetNumber] = useState(
    currentAdset?.adsetNumber || 0
  );
  const [conversionLocationObject, setConversionLocationObject] = useState(
    currentAdset?.conversionLocationObject?.frontend
      ? currentAdset?.conversionLocationObject
      : {
          backend:
            destinationTypeBackendMapping[
              allowedConversionLocation[
                objectiveFrontendMappiing?.[campaign.objective || ""]
              ]?.[0]
            ],
          frontend:
            allowedConversionLocation[
              objectiveFrontendMappiing?.[campaign.objective || ""]
            ]?.[0],
        }
  );
  const [advertisingLocations, setAdvertisingLocations] = useState(
    currentAdset?.advertisingLocations ||
      fbTargeting?.advertisingLocations ||
      []
  );
  const [minAge, setMinAge] = useState(currentAdset?.minAge || null);
  const [maxAge, setMaxAge] = useState(currentAdset?.maxAge || null);
  const [optimizationAdDelivery, setOptimizationAdDelivery] = useState(
    currentAdset?.optimizationAdDelivery || ""
  );
  const [cta, setCta] = useState(currentAdset?.cta || "");
  const [gender, setGender] = useState(
    currentAdset?.gender || fbTargeting?.gender || "BOTH"
  );
  const [keywords, setKeywords] = useState(currentAdset?.keywords || []);
  const [audience, setAudience] = useState(currentAdset?.audience || []);
  const [minRange, setMinRange] = useState(currentAdset?.minRange || "");
  const [maxRange, setMaxRange] = useState(currentAdset?.maxRange || "");
  const [facebookAds, setFacebookAds] = useState(() => {
    const fbs = currentAdset?.facebookAds?.map((fb) => {
      if (fb?.fbPage) {
        return fb;
      } else {
        fb.fbPage = pages[0];
        return fb;
      }
    });
    return fbs || [];
  });
  const [conversionEvent, setConversionEvent] = useState(
    currentAdset?.conversionEvent ||
      conversionEvents[objectiveFrontendMappiing[campaign?.objective]]?.[0] ||
      ""
  );
  const [fbPixel, setFbpixel] = useState(
    currentAdset?.fbPixel || { pixelId: "", name: "" }
  );
  const [fbPage, setFbpage] = useState(
    currentAdset?.fbPage || {
      id: "",
      name: "",
      businessId: "",
      businessName: "",
    }
  );
  const [taiReqId, setTaiReqId] = useState(currentAdset?.taiReqId || "");
  const [targetingType, setTartgetingType] = useState(
    currentAdset?.targetingType || "LOOKALIKE"
  );
  const [cronStatus, setCronStatus] = useState(currentAdset?.cronStatus || "");
  const [cronInput, setCronInput] = useState(currentAdset?.cronInput || {});
  const [cronFailureReason, setCronFailureReason] = useState(
    currentAdset?.cronFailureReason || ""
  );
  const [isModalConfirmed, setModalConfirmed] = useState(false);

  useEffect(() => {
    if (campaign) {
      setObjective(campaign?.objective);
      setBidStrategy(campaign?.bidStrategy);
      setAbo(campaign?.abo);
      setCampaignGeneratedInfoStatus(
        campaign?.campaignGeneratedInfoStatus || "PENDING"
      );
      // setAdsetCount(campaign?.adsetCount || 1);
    }
    if (adsets.length) {
      setStatus(currentAdset?.status || "ONGOING");
      setAdsetName(currentAdset?.adsetName || "");
      setAdsetNumber(currentAdset?.adsetNumber || 0);
      setBudget(() => {
        if (abo === ABO_ENUM.OFF) return currentAdset?.budget || 0;
        else return null;
      });
      setConversionLocationObject(
        currentAdset?.conversionLocationObject?.frontend
          ? currentAdset?.conversionLocationObject
          : {
              backend:
                destinationTypeBackendMapping[
                  allowedConversionLocation[
                    objectiveFrontendMappiing?.[campaign.objective || ""]
                  ]?.[0]
                ],
              frontend:
                allowedConversionLocation[
                  objectiveFrontendMappiing?.[campaign.objective || ""]
                ]?.[0],
            }
      );
      setAdvertisingLocations(
        currentAdset?.advertisingLocations ||
          fbTargeting?.advertisingLocations ||
          []
      );
      setMinAge(currentAdset?.minAge || null);
      setMaxAge(currentAdset?.maxAge || null);
      setOptimizationAdDelivery(
        currentAdset?.optimizationAdDelivery ||
          optimizationGoalBackendMapping[
            ((optimisationAdDelivery[objectiveFrontendMappiing[objective]] ||
              {})[conversionLocationObject?.frontend] || [])[0]
          ]
      );
      setCta(
        currentAdset?.cta ||
          CTABackendMapping[
            ((ctaMapping[objectiveFrontendMappiing[objective]] || {})[
              conversionLocationObject?.frontend
            ] || [])[0]
          ]
      );
      setGender(currentAdset?.gender || fbTargeting?.gender || "");
      setKeywords(currentAdset?.keywords || []);
      setAudience(currentAdset?.audience || []);
      setMinRange(currentAdset?.minRange || "");
      setMaxRange(currentAdset?.maxRange || "");
      setConversionEvent(
        currentAdset?.conversionEvent ||
          conversionEvents[
            objectiveFrontendMappiing[campaign?.objective]
          ]?.[0] ||
          ""
      );
      setFacebookAds(() => {
        const fbs = currentAdset?.facebookAds?.map((fb) => {
          if (fb?.fbPage) {
            return fb;
          } else {
            fb.fbPage = pages[0];
            return fb;
          }
        });
        return fbs || [];
      });
      setFbpixel(
        currentAdset?.fbPixel ||
          (selectPixelPage?.[objectiveFrontendMappiing?.[objective]]?.[
            conversionLocationObject?.frontend
          ] === "pixel" &&
            pixels?.[0]) || { pixelId: "", name: "" }
      );
      setFbpage(
        currentAdset?.fbPage ||
          (selectPixelPage?.[objectiveFrontendMappiing?.[objective]]?.[
            conversionLocationObject?.frontend
          ] === "page" &&
            pages?.[0]) || {
            id: "",
            name: "",
            businessId: "",
            businessName: "",
          }
      );
      setTaiReqId(currentAdset?.taiReqId || "");
      setTartgetingType(currentAdset?.targetingType || "LOOKALIKE");
      setCronStatus(currentAdset?.cronStatus || "");
      setCronInput(currentAdset?.cronInput || {});
      setCronFailureReason(currentAdset?.cronFailureReason || "");
    }
  }, [
    JSON.stringify(campaign),
    JSON.stringify(currentAdset),
    JSON.stringify(pages),
    JSON.stringify(pixels),
  ]);

  useEffect(() => {
    if (!adsets?.length) return;
    setCurrentAdset(adsets[currentAdsetIndex]);
  }, [currentAdsetIndex]);

  useEffect(() => {
    if (isCreateAdsetModalOpen) {
      const element = document.querySelector(".intro_strategy_main");
      if (element) {
        element.scrollTo(0, 0);
      }
    }
  }, [isCreateAdsetModalOpen]);

  useEffect(() => {
    setConversionLocationObject(
      currentAdset?.conversionLocationObject?.frontend
        ? currentAdset?.conversionLocationObject
        : {
            backend:
              destinationTypeBackendMapping[
                allowedConversionLocation[
                  objectiveFrontendMappiing?.[campaign.objective || ""]
                ]?.[0]
              ],
            frontend:
              allowedConversionLocation[
                objectiveFrontendMappiing?.[campaign.objective || ""]
              ]?.[0],
          }
    );
  }, [objective, currentAdsetIndex]);

  const getDetails = () => {
    return {
      status,
      adsetName,
      adsetNumber,
      budget,
      conversionLocationObject,
      advertisingLocations,
      minAge,
      maxAge,
      optimizationAdDelivery,
      cta,
      gender,
      keywords,
      audience,
      minRange,
      maxRange,
      conversionEvent,
      facebookAds,
      fbPixel,
      fbPage,
      taiReqId,
      targetingType,
      // cronStatus,
      cronInput,
      cronFailureReason,
    };
  };

  const handleAddNewAdset = () => {
    updateAdset({ ...getDetails(), cronInput: {} });
    setAdsetCount((prev) => prev + 1);
    const newAdset = {
      adsetName: `Adset ${adsetCount + 1}`,
      adsetNumber: adsetCount + 1,
    };
    setAdsets((prev) => [...prev, newAdset]);
    setCurrentAdsetIndex(adsets.length);
    openCreateAdsetModal(true);
    setModalOpen(true);
  };

  console.log("status:", status);
  console.log("Adsets:", adsets);
  console.log("current adset:", currentAdset);
  console.log("current adset index:", currentAdsetIndex);
  console.log("adset count:", adsetCount);

  const remove = (index) => {
    const newKeywords = keywords.slice();
    newKeywords?.splice(index, 1);
    setKeywords(newKeywords);
  };
  const replace = (keywords) => {
    setKeywords(keywords);
  };

  const updateDataForConversion = (data, field) => {
    // const lookalikeDataTemp = { ...lookalikeData };
    switch (field) {
      case "conversionLocation":
        setConversionLocationObject({
          backend: destinationTypeBackendMapping[data],
          frontend: data,
        });
        setOptimizationAdDelivery(
          optimizationGoalBackendMapping[
            ((optimisationAdDelivery[objectiveFrontendMappiing[objective]] ||
              {})[data] || [])[0]
          ] || ""
        );
        setCta(
          CTABackendMapping[
            ((ctaMapping[objectiveFrontendMappiing[objective]] || {})[data] ||
              [])[0]
          ] || ""
        );
        setConversionEvent("");
        setFbpixel({ pixelId: "", name: "" });
        setFbpage({ id: "", name: "", businessId: "", businessName: "" });
        break;
      case "fbPixel":
        setFbpixel(data);
        setConversionEvent(
          conversionEvents[objectiveFrontendMappiing[objective]]?.[0] || ""
        );
        setFbpage({ id: "", name: "", businessId: "", businessName: "" });
        break;
      case "adsetName":
        setAdsetName(data);
        break;
      case "budget":
        setBudget(data);
        break;
      case "conversionEvent":
        setConversionEvent(data);
        break;
      case "fbPage":
        setFbpage(data);
        setFbpixel({ pixelId: "", name: "" });
        break;
      case "optimizationAdDelivery":
        setOptimizationAdDelivery(data);
        break;
      case "cta":
        setCta(data);
        break;
      case "facebookAds":
        setFacebookAds(data);
        break;
      default:
        console.log(field, "not present");
    }
    // lookalikeDataTemp.adsets = adsets;
    // dispatch(updateLookalike(lookalikeDataTemp));
    // if (field !== "status") {
    //   setConversionData(lookalikeDataTemp.adsets[value]);
    //   setTargetingData(lookalikeDataTemp.adsets[value]);
    //   dispatch({
    //     type: "UPDATE_CHANGES_STATUS",
    //     payload: {
    //       campaignType: "lookalikeChanges",
    //       value: true,
    //     },
    //   });
    // }
    // if (isLastAdset) {
    //   dispatch({
    //     type: "SUBMIT_FORM_FOR_HDM_CAMPAIGN",
    //     payload: {
    //       campaignType: "topOfTheFunnelLookalike",
    //       // agencyType: agencyType || "HDM",
    //     },
    //   });
    // }
  };

  const updateDataForTargeting = (data, field) => {
    setStatus(BACKEND_STATUS.ONGOING);
    switch (field) {
      case "keywords":
        setKeywords(data);
        break;
      case "audience":
        setAudience(data);
        break;
      case "minAge":
        setMinAge(data);
        break;
      case "maxAge":
        setMaxAge(data);
        break;
      case "gender":
        setGender(data);
        break;
      case "minRange":
        setMinRange(data);
        break;
      case "maxRange":
        setMaxRange(data);
        break;
      case "advertisingLocations":
        setAdvertisingLocations(data);
        break;
      default:
        console.log(field, "not present");
    }
  };

  const handleConfirmAdset = () => {
    const adsetDetail = getDetails();
    if (currentAdset?.id) adsetDetail.id = currentAdset.id;

    const detail = {
      campaignId,
      adsets: [{ ...adsetDetail, status: BACKEND_STATUS.COMPLETED }],
      adsetCount,
    };
    mutatePutCampaign(detail);
  };

  const handleChangeAdset = (index) => {
    if (index !== currentAdsetIndex) {
      updateAdset(getDetails());
      setCurrentAdsetIndex(index);
    }
  };

  const handleRemoveAdset = async (index, id) => {
    try {
      if (index === currentAdsetIndex && isCreateAdsetModalOpen) {
        closeCreateAdsetModal();
        setShowAd(false);
      }
      if (id !== undefined) await mutateDeleteAdset({ adsetIds: [id] });
      const newAdsets = adsets.slice();
      newAdsets.splice(index, 1);
      if (index > 0 && adsets.length === 2) {
        if (currentAdsetIndex !== 0) setCurrentAdsetIndex((prev) => prev - 1);
        setAdsets(newAdsets);
      } else if (index > 0 && adsets.length > 2) {
        if (index === currentAdsetIndex) {
          setCurrentAdsetIndex((prev) => prev - 1);
          setAdsets(newAdsets);
        } else {
          if (currentAdsetIndex > index)
            setCurrentAdsetIndex((prev) => prev - 1);
          setAdsets(newAdsets);
        }
      } else if (index === 0 && adsets.length > 1) {
        const currIndex = currentAdsetIndex;
        setCurrentAdsetIndex(index + 1);
        setAdsets(newAdsets);
        if (currentAdsetIndex !== 0) {
          setCurrentAdsetIndex(currIndex - 1);
          setCurrentAdset(newAdsets[currIndex - 1]);
        } else {
          setCurrentAdsetIndex(0);
          setCurrentAdset(newAdsets[0]);
        }
        setCurrentAdsetIndex(index);
      } else if (index === 0 && adsets.length === 1) {
        setAdsets((prev) => prev.splice(0, 1, {}));
      }
    } catch (err) {
      console.error(err);
    }
  };

  // const closeConfirmModal = () => {
  //   dispatch({
  //     type: "UPDATE_HDM_CAMPAIGN_CONFIRM_MODAL",
  //   });
  // };

  // const goToCampaignViewHandler = () => {
  //   if (agencyType === "LD") {
  //     dispatch(
  //       goToSpecificCompoClient(
  //         ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_CREATION_FB]
  //       )
  //     );
  //   } else {
  //     dispatch(
  //       goToSpecificCompoHDMAM(
  //         componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_FB]
  //       )
  //     );
  //   }
  //   dispatch({
  //     type: "UPDATE_HDM_CAMPAIGN_CONFIRM_MODAL",
  //   });
  // };
  const getValue = () => {
    let toBeDisabled = false;
    [
      "adsetName",
      "conversionLocation",
      "minAge",
      "maxAge",
      "gender",
      "minRange",
      "maxRange",
      "optimizationAdDelivery",
      "cta",
    ].map((para) => {
      if (!para) {
        toBeDisabled = true;
      }
    });
    // if (!(facebookAds || []).length) {
    //   toBeDisabled = true;
    // }
    if (!(advertisingLocations || []).length) {
      toBeDisabled = true;
    }
    if (abo === "OFF" && !budget) {
      toBeDisabled = true;
    }

    return toBeDisabled;
  };
  console.log("optimzation:", optimizationAdDelivery, "cta:", cta);
  return showAd ? (
    <HDMAdset
      formId={formId}
      setShowAd={setShowAd}
      adsData={facebookAds || []}
      adsetNumber={adsetNumber}
      cta={cta || ""}
      index={parseInt(value) + 1}
      pages={pages}
      websiteUrl={getWebsiteUrl(clientForm)}
      updateReducer={updateDataForConversion}
    />
  ) : (
    <>
      <AssetGroupTabListView
        currCamp={adsets}
        currStatus={status}
        currAssetGroup={currentAdsetIndex}
        defaultName="Adset"
        assetNumberField="adsetNumber"
        addNewAssetString="Add New Adset"
        handleAddNewAsset={handleAddNewAdset}
        handleChangeAssetGroup={handleChangeAdset}
        handleRemoveAssetGroup={handleRemoveAdset}
        showAddNewTab={!isCreateAdsetModalOpen}
        setAdsetCount={setAdsetCount}
        isCampaignSuccess={campaignGeneratedInfoStatus === "SUCCESS"}
      />
      <div
        className="intro_strategy_main"
        style={{
          position: "relative",
          // overflowY: campaignFillModal ? 'hidden' : 'scroll',
          overflowY: "scroll",
          // height: campaignFillModal ? 'calc(100vh - 132px)' : '',
          height: "calc(100vh - 132px)",
          paddingBottom: "74px",
        }}
      >
        <div
          className="fblookalike-section-container"
          id={`fb-campaign-adset-${currentAdsetIndex}`}
        >
          <Conversion
            adsetName={adsetName}
            budget={budget}
            conversionLocationObject={conversionLocationObject}
            objective={objective}
            conversionEvent={conversionEvent}
            optimizationAdDelivery={optimizationAdDelivery}
            cta={cta}
            fbPixel={fbPixel}
            fbPage={fbPage}
            updateData={updateDataForConversion}
            pixels={pixels}
            pages={pages}
            events={events}
            abo={abo}
            currentIndex={currentAdsetIndex}
            isCampaignSuccess={campaignGeneratedInfoStatus === "SUCCESS"}
            setStatus={setStatus}
          />
          <LookAlikeTargetting
            audience={audience}
            advertisingLocations={advertisingLocations}
            minAge={minAge}
            maxAge={maxAge}
            minRange={minRange}
            maxRange={maxRange}
            gender={gender}
            keywords={keywords}
            targetingType={targetingType}
            updateData={updateDataForTargeting}
            index={value}
            replace={replace}
            remove={remove}
            isCampaignSuccess={campaignGeneratedInfoStatus === "SUCCESS"}
            setStatus={setStatus}
          />
        </div>
        <div className="preview_footer_row">
          <div
            className="footer_con"
            style={{ justifyContent: "end", gap: "2rem" }}
          >
            <Button
              onClick={() => setShowAd(true)}
              style={{
                background: "rgba(8, 105, 251, 0.07)",
                borderRadius: 8,
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 14,
                lineHeight: "20px",
                padding: "0px 20px",
                textTransform: "capitalize",
              }}
            >
              Go to Ad Level
            </Button>
            <PrimaryButton
              title="Confirm"
              onClick={handleConfirmAdset}
              disabled={campaignGeneratedInfoStatus === "SUCCESS" || getValue()}
            />
          </div>
        </div>
        {(!currentAdset?.id ||
          ["PENDING", "RUNNING", "FAILED"].includes(cronStatus) ||
          isModalConfirmed) && (
          <CreateAdsetModal
            shouldCreate={isCreateAdsetModalOpen}
            onClose={closeCreateAdsetModal}
            formId={formId}
            campaignId={campaignId}
            adset={currentAdset}
            updateAdset={(data) => {
              updateAdset(data);
              setModalOpen(false);
            }}
            mountNodeId={currentAdsetIndex}
            onAddNewAdset={updateAdset}
            adsetNumber={adsetNumber}
            setModalConfirmed={setModalConfirmed}
            adsetCount={adsetCount}
            setAdsetCount={setAdsetCount}
            websiteUrl={getWebsiteUrl(clientForm)}
          />
        )}
      </div>
      {isFetchingGetCampaigns && <PmaxLoader />}
      {/* campaignConfirmModal && (
        <CampaignConfirmModal
          campaignName="TOF- Lookalike"
          updateCampaignConfirmModal={closeConfirmModal}
          goToCampaignViewHandler={goToCampaignViewHandler}
        />
      ) */}
      {showAllConfirmModal && (
        <AllConfirmModal
          handleClose={() => setShowAllConfirmModal(false)}
          handleNavigation={goToListScreen}
        />
      )}
    </>
  );
};

export default LookAlikeCampaign;
