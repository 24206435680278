import React, { useEffect, useRef, useState } from 'react';
import { Typography, Button, TextField } from '@material-ui/core';
import { crossIcon } from '../../../assets/icons/creative/creative';
import {
    CREATIVE_OBJECT_STATUS_ENUM,
  DESCRIPTION_CHAR_LIMIT,
  PLATFORM_NAME,
} from '../../../api/creativeApproval/enums';
const Description = ({
  isRead,
  setShowError,
  setIsRead,
  isClient,
  status,
  platform,
  descriptionList,
  setDescriptionList,
}) => {
  //const [descriptionList, setDescriptionList] = useState([]);
  const [addNewTerm, setAddNewTerm] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
      if (addNewTerm) {
          inputRef.current.focus();
      }
  }, [addNewTerm]);

  useEffect(() => {
      if (isRead) {
          setAddNewTerm(false);
          if (descriptionList[descriptionList?.length - 1] === '')
              setDescriptionList(prev => {
                  const newArray = prev.slice();
                  newArray.pop();
                  return newArray;
              });
      }
  }, [isRead]);

  const changeDescription = (e, index) => {
    setShowError(false);
    const newDescriptionList = descriptionList.slice();
    newDescriptionList[index] = e.target.value;
    setDescriptionList(newDescriptionList);
  };

  const removeDescription = (index) => {
    setAddNewTerm(false);
    setShowError(false);
    const newDescriptionList = descriptionList.slice();
    newDescriptionList.splice(index, 1);
    setDescriptionList(newDescriptionList);
  };

  const maxCharLimitDescription =
    platform === PLATFORM_NAME.GOOGLE
      ? DESCRIPTION_CHAR_LIMIT.GOOGLE
      : DESCRIPTION_CHAR_LIMIT.FACEBOOK;
  return (
    <div
      style={{
        border: '2px solid #E6F6FF',
        borderRadius: '8px',
        width: '65%',
      }}
    >
      <div
        style={{
          padding: '10px 0 11px 20px',
          borderBottom: '1px solid #F0F0F0',
        }}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 16,
            lineHeight: '19px',
            color: '#333333',
          }}
        >
          Descriptions
        </Typography>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 10,
            lineHeight: '12px',
            color: '#827F7F',
            marginTop: 4,
          }}
        >
          Please add descriptions to be approved by the client.
        </Typography>
      </div>
      <div
        style={{
          maxHeight: 225,
          minHeight: 225,
          padding: '1rem',
          overflowY: 'scroll',
        }}
      >
        {descriptionList.map((description, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 15,
              width: '100%',
              paddingBottom: 12,
            }}
          >
            <div
              style={{
                background: '#FFFFFF',
                border: '1px solid #F0F0F0',
                boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.04)',
                borderRadius: '5px',
                width: '98%',
                paddingLeft: 15,
                paddingTop: 2,
                paddingBottom: 2,
                minHeight: 34,
              }}
            >
              {' '}
              {!addNewTerm && (
                  !isRead ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant='standard'
                    value={description}
                    multiline
                    error={
                      description.length === maxCharLimitDescription
                        ? true
                        : false
                    }
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: 1.5,
                        width: 500,
                        // paddingBottom: 6,
                        border: 0,
                      },
                      maxLength:
                        platform === PLATFORM_NAME.GOOGLE
                          ? DESCRIPTION_CHAR_LIMIT.GOOGLE
                          : DESCRIPTION_CHAR_LIMIT.FACEBOOK,
                    }}
                    onChange={(e) => changeDescription(e, index)}
                  />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 1.5,
                      paddingRight: 8,
                    }}
                  >
                    {platform === PLATFORM_NAME.GOOGLE
                      ? `${description.length}/${DESCRIPTION_CHAR_LIMIT.GOOGLE}`
                      : `${description.length}/${DESCRIPTION_CHAR_LIMIT.FACEBOOK}`}
                  </span>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '31px',
                    }}
                  >
                    {description}
                  </Typography>
                  {description.length !== 0 && (
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: 1.5,
                        paddingRight: 8,
                      }}
                    >
                      {platform === PLATFORM_NAME.GOOGLE
                        ? `${description.length}/${DESCRIPTION_CHAR_LIMIT.GOOGLE}`
                        : `${description.length}/${DESCRIPTION_CHAR_LIMIT.FACEBOOK}`}
                    </span>
                  )}
                </div>
              ))}
             { addNewTerm && ( isRead ? ((index === descriptionList.length - 1 ) ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant='standard'
                    value={description}
                    inputRef={inputRef}
                    multiline
                    error={
                      description.length === maxCharLimitDescription
                        ? true
                        : false
                    }
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: 1.5,
                        width: 500,
                        // paddingBottom: 6,
                        border: 0,
                      },
                      maxLength:
                        platform === PLATFORM_NAME.GOOGLE
                          ? DESCRIPTION_CHAR_LIMIT.GOOGLE
                          : DESCRIPTION_CHAR_LIMIT.FACEBOOK,
                    }}
                    onChange={(e) => changeDescription(e, index)}
                    onBlur={() => {
                        if (description === '')
                            removeDescription(index);
                        setAddNewTerm(false);
                    }}
                  />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 1.5,
                      paddingRight: 8,
                    }}
                  >
                    {platform === PLATFORM_NAME.GOOGLE
                      ? `${description.length}/${DESCRIPTION_CHAR_LIMIT.GOOGLE}`
                      : `${description.length}/${DESCRIPTION_CHAR_LIMIT.FACEBOOK}`}
                  </span>
                </div>
             ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: '31px',
                    }}
                  >
                    {description}
                  </Typography>
                  {description.length !== 0 && (
                    <span
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: 1.5,
                        paddingRight: 8,
                      }}
                    >
                      {platform === PLATFORM_NAME.GOOGLE
                        ? `${description.length}/${DESCRIPTION_CHAR_LIMIT.GOOGLE}`
                        : `${description.length}/${DESCRIPTION_CHAR_LIMIT.FACEBOOK}`}
                    </span>
                    )}
                    </div>
                  )) : (
                      ((index === descriptionList?.length - 1) ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant='standard'
                    value={description}
                    inputRef={inputRef}
                    multiline
                    error={
                      description.length === maxCharLimitDescription
                        ? true
                        : false
                    }
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: 1.5,
                        width: 500,
                        // paddingBottom: 6,
                        border: 0,
                      },
                      maxLength:
                        platform === PLATFORM_NAME.GOOGLE
                          ? DESCRIPTION_CHAR_LIMIT.GOOGLE
                          : DESCRIPTION_CHAR_LIMIT.FACEBOOK,
                    }}
                    onChange={(e) => changeDescription(e, index)}
                  />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 1.5,
                      paddingRight: 8,
                    }}
                  >
                    {platform === PLATFORM_NAME.GOOGLE
                      ? `${description.length}/${DESCRIPTION_CHAR_LIMIT.GOOGLE}`
                      : `${description.length}/${DESCRIPTION_CHAR_LIMIT.FACEBOOK}`}
                  </span>
                </div>
                ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    variant='standard'
                    value={description}
                    multiline
                    error={
                      description.length === maxCharLimitDescription
                        ? true
                        : false
                    }
                    inputProps={{
                      style: {
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: 14,
                        lineHeight: 1.5,
                        width: 500,
                        // paddingBottom: 6,
                        border: 0,
                      },
                      maxLength:
                        platform === PLATFORM_NAME.GOOGLE
                          ? DESCRIPTION_CHAR_LIMIT.GOOGLE
                          : DESCRIPTION_CHAR_LIMIT.FACEBOOK,
                    }}
                    onChange={(e) => changeDescription(e, index)}
                  />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: 1.5,
                      paddingRight: 8,
                    }}
                  >
                    {platform === PLATFORM_NAME.GOOGLE
                      ? `${description.length}/${DESCRIPTION_CHAR_LIMIT.GOOGLE}`
                      : `${description.length}/${DESCRIPTION_CHAR_LIMIT.FACEBOOK}`}
                  </span>
                </div>
                )
             )))}
            </div>
            {/* { !isRead &&
                <span>
                    { (platform === PLATFORM_NAME.GOOGLE)
                        ?
                        `${description.length}/${DESCRIPTION_CHAR_LIMIT.GOOGLE}`
                        :
                        `${description.length}/${DESCRIPTION_CHAR_LIMIT.FACEBOOK}`
                    }
                </span>
            } */}
      { isClient && status === CREATIVE_OBJECT_STATUS_ENUM.PENDING &&
            <img
              src={crossIcon}
              alt='cross-icon'
              style={{
                  cursor: "pointer"
              }}
              onMouseDown={() => removeDescription(index)}
            />
      }
      { ! isClient &&
            <img
              src={crossIcon}
              alt='cross-icon'
              style={{
                  cursor: "pointer"
              }}
              onMouseDown={() => removeDescription(index)}
            />
      }
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: '#FFFFFF',
          borderTop: '1px solid #F0F0F0',
          boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.03)',
          padding: '8px 0',
          borderRadius: '0 0 8px 8px',
        }}
      >
      { isClient && status === CREATIVE_OBJECT_STATUS_ENUM.PENDING &&
        <Button
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '20px',
            color: '#0869FB',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
          }}
          onClick={() => {
              if (descriptionList[descriptionList?.length - 1] === '') return;
              setAddNewTerm(true)
              setDescriptionList([...descriptionList, ''])
          }}
        >
          +Add Description
        </Button>
      }
      { ! isClient &&
        <Button
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '20px',
            color: '#0869FB',
            textTransform: 'capitalize',
            backgroundColor: 'transparent',
          }}
          onClick={() => {
              if (descriptionList[descriptionList?.length - 1] === '') return;
              setAddNewTerm(true)
              setDescriptionList([...descriptionList, ''])
              // setIsRead(false);
          }}
        >
          +Add Description
        </Button>
      }
      </div>
    </div>
  );
};

export default Description;
