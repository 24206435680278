import { all, put, takeLatest } from "redux-saga/effects";
import { api } from "../../api/index";
import { getLocalStorage } from "../../api/localStorage/localStorage";
import { UPDATE_CHART, UPDATE_CHART_LOCAL } from "./enum";

export const getWidgetData = (chart, apiConfigs, formId) => new Promise(async (resolve, reject) => {
    try {
        const response = await api.post(`/analytics/extract/${formId ?? getLocalStorage("formId")}`, {
            chart, apiConfigs
        });
        resolve(response.data.responses);
    } catch (error) {
        reject(error.response);
    }
});

function* updateChart(args) {
    if (args.payload.updates.length === 0) { return; }
    try {
        yield put({
            type: UPDATE_CHART_LOCAL,
            payload: args.payload
        });
        // yield api.post(`/api/report/${args.payload.reportId}/chart/update`, {
        //     updates: args.payload.updates
        // });
        // yield put({
        //     type: UPDATE_CHART_SUCCESS,
        //     payload: args.payload
        // });
    } catch (error) {
        console.log("🚀 ~ file: saga.js ~ line 139 ~ function*updateChart ~ error", error)
    }
}

export default function* root() {
    yield all([
        takeLatest(UPDATE_CHART, updateChart)
    ])
}
