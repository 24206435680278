import { useDispatch, useSelector } from "react-redux";
import CommonAuditCampaign from "./CommonAuditCampaign";
import { useCallback } from "react";
import { getAlreadyAuditHdm } from "../../../api/HDMAM/audit/saga";
import { goBackAmCompo } from "../../../api/accountManager/componentTracking/action";
import { onNextLD } from "../GoToNextCompoButton";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import { useGetClientForms } from "../../../services/clientForms";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";

export default function LDAuditCampaign() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();

  const { data: form, isLoading } = useGetClientForms(formId);

  const navigation = () => {
    navigate(
      generatePath(
        `${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.adset}`,
        { formId }
      )
    );
  };
  const onFBAccountIdExists = useCallback(() => {
    getAlreadyAuditHdm("LD").then((res) => {
      if (res.length !== 0) {
        const account = res.find(
          (account) => account.accountId === form.fAdsAccountId
        );
        dispatch({
          type: "GET_AUDIT_DETAILS_HDM",
          payload: account
            ? account.performanceData
            : res[res.length - 1].performanceData,
        });
      }
    });
  }, [dispatch, form.fAdsAccountId]);

  const onBack = () => {
    navigate(
      generatePath(
        `${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.account}`,
        { formId }
      )
    );
  };
  if (isLoading) {
    return <PmaxLoader />;
  }
  return (
    <CommonAuditCampaign
      form={form}
      onFBAccountIdExists={onFBAccountIdExists}
      onBack={onBack}
      /** onNextLD, is a curried function, no need to wrap the call in a callback */
      onNext={onNextLD(dispatch, navigation)}
    />
  );
}
