import { put, takeLatest } from "redux-saga/effects";
import { api } from "../../index";
import { updateLocations } from "./slice";

function* getCampaignLocations(data) {
  try {
    const res = yield api.get(
      "/g-ads/geo-targets?locationNames=" + data.payload.value
    );
    let options = res.data.geoTargets.map(function (element) {
      return {
        name: element.name,
        title: element.canonicalName,
        resourceName: element.resourceName,
        reach: element.reach,
      };
    });
    yield put(updateLocations(options));
  } catch (err) {
    console.log(err);
  }
}

export default function* creativeAISaga() {
  yield takeLatest("GET_CAMPAIGN_LOCATION", getCampaignLocations);
}
