import { isAnyCampaignsPublished } from "../../../components/CampaignPreview/utils";
import {
  BACKEND_STATUS,
  PROPOSAL_STATE,
  getFBCampaignObject,
  getGoogleCampaignObject,
  isPlatformSelectedHDM,
  leftBarStatus,
} from "../../accountManagerUtil";
import { accessSharingStatus, checkForAccess, isStrategyStarted, strategyStage } from "../commonUtils";

export const listOfIds = {
  onboardingInfo: "onboardingInfo",
  accessSharing: "accessSharing",
  onboardingDoc: "ONBOARDINGDOC",
  auditForGoogle: "AUDIT_FOR_GOOGLE",
  auditForFacebook: "AUDIT_FOR_FACEBOOK",
  accountAnalysis: "AccountAnalysis",
  strategyCreationGoogle: "strategyCreationGoogle",
  strategyCreationFacebook: "StrategyCreationFacebook",
  strategyDeck: "STRATEGY_DECK",
  campaignCreationGoogle: "CampaignCreationGoogle",
  campaignCreationFB: "CampaignCreationFB",
  campaignPreview: "CampaignPreview",
  analyticDashboard: "AnalyticDashboard",
};

export const leftPanelLabelHDMAm = {
  ONBOARDING_FORM: "Client Onboarding Inputs",
  ACCESS_SHARING: "Access sharing",
  ONBOARDING_DOC: "Onboarding Doc",
  AUDIT_FOR_GOOGLE: "Audit For Google",
  AUDIT_FOR_FACEBOOK: "Audit For Facebook",
  ACCOUNT_ANALYSIS: "Account Analysis",
  STRATEGY_GOOGLE: "Strategy Creation (Google)",
  STRATEGY_FACEBOOK: "Strategy Creation (Facebook)",
  STRATEGY_DECK: "Strategy Deck",
  CAMPAIGN_GOOGLE: "Campaign Creation Google",
  CAMPAIGN_FACEBOOK: "Campaign Creation Facebook",
  CAMPAIGN_PREVIEW: "Campaign Preview",
  ANALYTICS_DASHBOARD: "Analytics Dashboard",
};

export const leftPanelNameInUrlPath = /** @type {const} */ ({
  ONBOARDING_FORM: "onboarding-form",
  ACCESS_SHARING: "access-sharing",
  ONBOARDING_DOC: "onboarding-doc",
  AUDIT_FOR_GOOGLE: "audit-for-google",
  AUDIT_FOR_FACEBOOK: "audit-for-facebook",
  ACCOUNT_ANALYSIS: "account-analysis",
  STRATEGY_GOOGLE: "strategy-creation-google",
  STRATEGY_FACEBOOK: "strategy-creation-facebook",
  STRATEGY_DECK: "strategy-deck",
  CAMPAIGN_GOOGLE: "campaign-creation-google",
  CAMPAIGN_FACEBOOK: "campaign-creation-facebook",
  CAMPAIGN_PREVIEW: "campaign-preview",
  ANALYTICS_DASHBOARD: "analytics-dashboard",
});

export const getStatusForLeftBarHDMAm = (form) => {
  const statusObj = {
    [listOfIds.onboardingInfo]: leftBarStatus.ongoing,
    [listOfIds.accessSharing]: leftBarStatus.toBeUnlocked,
    [listOfIds.onboardingDoc]: leftBarStatus.toBeUnlocked,
    [listOfIds.auditForGoogle]: leftBarStatus.toBeUnlocked,
    [listOfIds.auditForFacebook]: leftBarStatus.toBeUnlocked,
    [listOfIds.accountAnalysis]: leftBarStatus.toBeUnlocked,
    [listOfIds.strategyCreationGoogle]: leftBarStatus.toBeUnlocked,
    [listOfIds.strategyCreationFacebook]: leftBarStatus.toBeUnlocked,
    [listOfIds.strategyDeck]: leftBarStatus.toBeUnlocked,
    [listOfIds.campaignCreationGoogle]: leftBarStatus.toBeUnlocked,
    [listOfIds.campaignCreationFB]: leftBarStatus.toBeUnlocked,
    [listOfIds.campaignPreview]: leftBarStatus.toBeUnlocked,
    [listOfIds.analyticDashboard]: leftBarStatus.toBeUnlocked,
  };

  // const facebookCampaignObj = null;
  // const googleCampaignObj = {};
  // if (form?.fbAdsGeneratedCampaignsInfo) {
  //   const result = getFBCampaignObject(form?.fbAdsGeneratedCampaignsInfo);
  //   facebookCampaignObj = result[0];
  // }
  // if (form?.googleSearchAdsGeneratedCampaignsInfo) {
  //   const result = getGoogleCampaignObject(
  //     form.googleSearchAdsGeneratedCampaignsInfo
  //   );
  //   googleCampaignObj = result[0];
  // }

  if (form?.onboardingInfo?.status === BACKEND_STATUS.COMPLETED) {
    statusObj[listOfIds.onboardingInfo] = leftBarStatus.completed;
    statusObj[listOfIds.accessSharing] = leftBarStatus.ongoing;
  }
  if (
    form?.accessSharing?.status === BACKEND_STATUS.COMPLETED
  ) {
    statusObj[listOfIds.accessSharing] = leftBarStatus.completed;
    statusObj[listOfIds.onboardingDoc] = leftBarStatus.ongoing;
  }
  if (
    [BACKEND_STATUS.COMPLETED, BACKEND_STATUS.ONGOING].includes(
      form?.proposalStatus
    )
  ) {
    statusObj[listOfIds.accessSharing] = leftBarStatus.completed;
    statusObj[listOfIds.onboardingDoc] = leftBarStatus.completed;
  }
  if (isPlatformSelectedHDM(form, "google-ads") !== -1) {
    if (form?.googleAuditData?.status === BACKEND_STATUS.ONGOING) {
      statusObj[listOfIds.auditForGoogle] = leftBarStatus.ongoing;
    }
    if (form?.googleAuditData?.status === BACKEND_STATUS.COMPLETED) {
      statusObj[listOfIds.auditForGoogle] = leftBarStatus.completed;
      statusObj[listOfIds.strategyCreationGoogle] = leftBarStatus.ongoing;
    }
    if (form?.googleStrategyCreationStatus === "COMPLETED") {
      statusObj[listOfIds.strategyCreationGoogle] = leftBarStatus.completed;
    }
  }

  if (isPlatformSelectedHDM(form, "facebook-ads") !== -1) {
    if (
      form?.facebookAuditStatus === BACKEND_STATUS.ONGOING
    ) {
      statusObj[listOfIds.auditForFacebook] = leftBarStatus.ongoing;
    }
    if (
      form?.facebookAuditStatus === BACKEND_STATUS.COMPLETED
    ) {
      statusObj[listOfIds.auditForFacebook] = leftBarStatus.completed;
      statusObj[listOfIds.strategyCreationFacebook] = leftBarStatus.ongoing;
    }
    if (form?.fbAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED) {
      statusObj[listOfIds.strategyCreationFacebook] = leftBarStatus.completed;
    }
  }
  if (form?.accountAnalysis?.status === BACKEND_STATUS.ONGOING) {
    statusObj[listOfIds.accountAnalysis] = leftBarStatus.ongoing;
  }

  if (form?.accountAnalysis?.status === BACKEND_STATUS.COMPLETED) {
    statusObj[listOfIds.accountAnalysis] = leftBarStatus.completed;
  }

  if(isStrategyStarted(form)){
    statusObj[listOfIds.strategyDeck] = leftBarStatus.ongoing;
  }
  if (
    strategyStage(form?.strategyIterations || []) === PROPOSAL_STATE.APPROVED
  ) {
    statusObj[listOfIds.strategyDeck] = leftBarStatus.completed;
    if (isPlatformSelectedHDM(form, "google-ads") !== -1) {
      statusObj[listOfIds.campaignCreationGoogle] = leftBarStatus.ongoing;
    }
    if (isPlatformSelectedHDM(form, "facebook-ads") !== -1) {
      statusObj[listOfIds.campaignCreationFB] = leftBarStatus.ongoing;
    }
  }

  // if (
  //   googleCampaignObj.brandCampaign &&
  //   googleCampaignObj.competitorCampaign &&
  //   googleCampaignObj.serviceCampaign
  // ) {
  //   statusObj[listOfIds.campaignCreationGoogle] = leftBarStatus.completed;
  // }

  // if (
  //   facebookCampaignObj &&
  //   facebookCampaignObj.lookalike &&
  //   facebookCampaignObj.interest &&
  //   facebookCampaignObj.mof &&
  //   facebookCampaignObj.bof
  // ) {
  //   statusObj[listOfIds.campaignCreationFB] = leftBarStatus.completed;
  // }

  if(form?.anyGoogleCampaignPublished || form?.anyFbCampaignPublished) {
    if (isPlatformSelectedHDM(form, "google-ads") !== -1 && form?.anyGoogleCampaignPublished) {
      statusObj[listOfIds.campaignCreationGoogle] = leftBarStatus.completed;
    }
    if (isPlatformSelectedHDM(form, "facebook-ads") !== -1 && form?.anyFbCampaignPublished) {
      statusObj[listOfIds.campaignCreationFB] = leftBarStatus.completed;
    }
    statusObj[listOfIds.campaignPreview] = leftBarStatus.completed;
    statusObj[listOfIds.analyticDashboard] = leftBarStatus.completed;
  }
  return statusObj;
};
