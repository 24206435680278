import { useMutation, useQuery } from '@tanstack/react-query'
import { api } from '../api';
import { BACKEND_STATUS } from '../utils/accountManagerUtil';

const getGeneratedKeywordsQueryKey = (formId) => ["/targeting-keywords/fb-ads/:formId", formId]
export const useGetGeneratedKeywords = (formId, options = {}) => {
  return useQuery(
    getGeneratedKeywordsQueryKey(formId),
    async () => {
      const response = await api.get("/targeting-keywords/fb-ads/" + formId);
      return response.data;
    },
    options,
  );
};

export const usePostFBGenerateKeywords = (formId, options = {}) => {
  return useMutation(
    async () => {
      const response = await api.post("/targeting-keywords/fb-ads/" + formId);
      return response.data;
    },
    options,
  );
};

export const usePostGenerateAdset = (formId, options = {}) => {
  const { mutate: updateStrategyStatus } = useMutation(
    async ({ strategyId }) => {
      const response = await api.put(`/client-forms/${formId}/fb-campaign-strategy/${strategyId}`, {
        status: BACKEND_STATUS.ONGOING,
      });
      return response.data;
    },
  );

  return useMutation(
    async ({ strategyId, adset }) => {
      const response = await api.post(`/targeting-keywords/fb-ads/${formId}/${strategyId}`, adset);
      return response.data;
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        const { strategyId } = variables;
        updateStrategyStatus({ strategyId });

        options.onSuccess?.(data, variables, ctx);
      }
    },
  );
}


export const usePostGenerateAdsetById = ({ formId, strategyId }, options = {}) => {
  return useMutation(
    async ({ adsetId, adset }) => {
      const response = await api.post(`/targeting-keywords/fb-ads/${formId}/${strategyId}?adset_id=${adsetId}`, adset);
      return response.data;
    },
    options,
  );
}

export const usePostGenerateCampaignAdset = (formId, options = {}) => {
  return useMutation(
    async ({ campaignId, adset }) => {
      const response = await api.post(`/targeting-keywords/fb-ads/campaign/${formId}/${campaignId}`, adset);
      return response.data;
    }, options,
  );
}

export const usePostGenerateCampaignAdsetById = ({ formId, campaignId }, options = {}) => {
  return useMutation(
    async ({ adsetId, adset }) => {
      const response = await api.post(`/targeting-keywords/fb-ads/campaign/${formId}/${campaignId}?adset_id=${adsetId}`, adset);
      return response.data;
    }, options,
  );
}
