import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_ABTEST } from "../../utils/creativeApprovalUtil";
import { AB_TEST_STATUS_ENUM, CREATIVE_OBJECT_STATUS_ENUM } from "./enums";

const initialState = {
  adCopies: [],
  creatives: [],
  landingPages: [],
  abTests: INITIAL_ABTEST,
  toBeSaved: false,
  isError: false,
};

const creativeApproval = createSlice({
  name: "creativeApproval",
  initialState,
  reducers: {
    fetchAdCopies() {},
    fetchCreatives() {},
    fetchLandingPages() {},
    fetchAbTests() {},
    putAdCopies() {},
    putCreatives() {},
    putLandingPages() {},
    putAbTests() {},
    addAdCopy(state, action) {
      state.adCopies = action.payload;
    },
    addCreative(state, action) {
        state.creatives = action.payload;
    },
    addLandingPage(state, action) {
        state.landingPages = action.payload;
    },
    addAbTests(state, action) {
        const { section, question } = action.payload;
        const foundIndex = state.abTests.findIndex((e) => e.section === section);
        state.abTests[foundIndex].questions.push({ question, status: AB_TEST_STATUS_ENUM.NOT_STARTED });
    },
    updateAdCopyContent(state, action) {
      const foundAdCopy = state.adCopies.find(
        (e) => e.id === action.payload.id
      );
      if (foundAdCopy) {
        foundAdCopy.status = action.payload.status;
        foundAdCopy.theme = action.payload.theme;
        foundAdCopy.campaignName = action.payload.campaignName;
        foundAdCopy.headlines = action.payload.headlines.slice();
        foundAdCopy.descriptions = action.payload.descriptions.slice();
        foundAdCopy.updatedOn = new Date().toISOString();
        if (action.payload.primaryText !== undefined)
          foundAdCopy.primaryText = action.payload.primaryText.slice();
      }
      state.toBeSaved = true;
    },
    updateCreativeContent(state, action) {
      console.log(action);
      const foundCreative = state.creatives.find(
        (e) => e.id === action.payload.id
      );
      if (foundCreative) {
        foundCreative.status = action.payload.status;
        foundCreative.theme = action.payload.theme;
        foundCreative.campaignName = action.payload.campaignName;
        foundCreative.creativeImagess = action.payload.creativeImagess.map(e => ({...e}));
        foundCreative.updatedOn = new Date().toISOString();
      }
      state.toBeSaved = true;
    },
    updateLandingPageContent(state, action) {
      const foundLandingPage = state.landingPages.find(
        (e) => e.id === action.payload.id
      );
      if (foundLandingPage) {
        foundLandingPage.status = action.payload.status;
        foundLandingPage.theme = action.payload.theme;
        foundLandingPage.campaignName = action.payload.campaignName;
        foundLandingPage.link = action.payload.link;
        foundLandingPage.updatedOn = new Date().toISOString();
      }
      state.toBeSaved = true;
    },
    updateAdCopyStatus(state, action) {
      const { id, status } = action.payload;
      const foundAdCopy = state.adCopies.find((e) => e.id === id);
      if (foundAdCopy) {
        foundAdCopy.status = status;
        foundAdCopy.updatedOn = new Date().toISOString();
      }
      state.toBeSaved = true;
    },
    updateCreativeStatus(state, action) {
      const { id, status } = action.payload;
      const foundCreative = state.creatives.find((e) => e.id === id);
      if (foundCreative) {
        foundCreative.status = status;
        foundCreative.updatedOn = new Date().toISOString();
      }
      state.toBeSaved = true;
    },
    updateLandingPageStatus(state, action) {
      const { id, status } = action.payload;
      console.log(id, status);
      const foundLandingPage = state.landingPages.find((e) => e.id === id);
      console.log(foundLandingPage);
      if (foundLandingPage) {
        foundLandingPage.status = status;
        foundLandingPage.updatedOn = new Date().toISOString();
      }
      state.toBeSaved = true;
    },
    updateAbTests(state, action) {
        const { section, id, question, status } = action.payload;
        const foundIndex = state.abTests.findIndex((e) => e.section === section);
        state.abTests[foundIndex].questions[id] = { question, status };
    },
    updateToBeSaved: (state) => {
        state.toBeSaved = !state.toBeSaved;
    },
    updateSliceForAbTests: (state, action) => {
        state.abTests = action.payload;
    },
    updateSliceState: (state, action) => {
        Object.keys(action.payload || {}).map(key => {
            state[key] = action.payload[key];
        })
    },
    updateErrorState: (state) => {
        state.isError = !state.isError;
    }
  },
});

export const {
  fetchAdCopies,
  fetchCreatives,
  fetchLandingPages,
  fetchAbTests,
  putAdCopies,
  putCreatives,
  putAbTests,
  putLandingPages,
  addAdCopy,
  addCreative,
  addAbTests,
  addLandingPage,
  updateAdCopyContent,
  updateCreativeContent,
  updateLandingPageContent,
  updateAdCopyStatus,
  updateCreativeStatus,
  updateLandingPageStatus,
  updateAbTests,
  updateToBeSaved,
  updateSliceState,
  updateSliceForAbTests,
  updateErrorState
} = creativeApproval.actions;

export default creativeApproval.reducer;
