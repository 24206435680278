import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateHDMCompoReduxClient } from "../../../api/HDMClient/componentTracking/action";
import ProfitIndexTable from "../../Common/ProfitIndexTable";
import "./Goal.css";
import EDIT from "./strategy-Icons/Edit.png";
import GOAL from "./strategy-Icons/goal.png";
import HISTORY from "./strategy-Icons/history.png";
import REVENUE from "./strategy-Icons/revenue.png";
import trackingOnboarding from "../../../assets/images/TrackingStrategy.svg";

import { getRoas } from "../../../utils/hdmUtil";
import { isPlatformSelectedHDM } from "../../../utils/accountManagerUtil";
import { getKeywords } from "../../../api/pmaxStrategy/slice";
import { fetchCriteria } from "../../../api/accountManager/googleAdsCustomer/slice";
import { updateData } from "../../../api/pmaxCampaign/slice";
import { generatePath, useNavigate } from "react-router";
import hdmClientRoutePaths from "../../../pages/hdm-client/constants/hdmClientRoutePaths";

const GoalHDM = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [convertedRoas, setConvertedRoas] = useState(0);
  const [convertedRoas3Months, setConvertedRoas3Months] = useState(0);
  const [convertedRoas6Months, setConvertedRoas6Months] = useState(0);
  const [convertedRoas12Months, setConvertedRoas12Months] = useState(0);
  const [googleSpends, setGoogleSpends] = useState(0);
  const [facebookSpends, setFacebookSpends] = useState(0);  
  const hdmclient = useSelector((state) => state.hdmClient);
  const roas = useSelector((state) => state.strategyCreationHdmAm.roas);
  const roas3Months = useSelector(
    (state) => state.strategyCreationHdmAm.roas3Months
  );
  const roas6Months = useSelector(
    (state) => state.strategyCreationHdmAm.roas6Months
  );
  const roas12Months = useSelector(
    (state) => state.strategyCreationHdmAm.roas12Months
  );

  const [
    affinityCategories,
    inmarketCategories,
    lifeEvents,
    detailedDemographics,
  ] = useSelector((store) => [
    store.googleCustomers.affinityCategories,
    store.googleCustomers.inmarketCategories,
    store.googleCustomers.lifeEvents,
    store.googleCustomers.detailedDemographics,
  ]);

  const directSegments = useSelector(
    (store) => store.pmaxStrategy.directSegments
  );

  useEffect(() => {
    dispatch({
      type: "GET_FACEBOOK_ROAS",
    });
    dispatch({
      type: "GET_FACEBOOK_ROAS_3MONTHS",
    });
    dispatch({
      type: "GET_FACEBOOK_ROAS_6MONTHS",
    });
    dispatch({
      type: "GET_FACEBOOK_ROAS_12MONTHS",
    });
  }, []);

  useEffect(() => {
    if (isPlatformSelectedHDM(hdmclient, "facebook-ads") !== -1) {
      setFacebookSpends(
        parseInt(
          (hdmclient?.onboardingInfo?.clientAccess?.platforms.find(
            (platform) => platform.platform === "facebook-ads"
          ) || {}).spends || 0
        )
      );
    }
    if (isPlatformSelectedHDM(hdmclient, "google-ads") !== -1) {
      setGoogleSpends(
        parseInt(
          hdmclient?.onboardingInfo?.clientAccess?.platforms.find(
            (platform) => platform.platform === "google-ads"
          ).spends
        )
      );
    }
  }, [hdmclient]);
  
  useEffect(() => {
    setConvertedRoas(
      facebookSpends
        ? getRoas(
            roas,
            parseInt(facebookSpends),
            parseInt(hdmclient.onboardingInfo.aboutBusiness.monthlyRevenue)
          )
        : 1
    );
    setConvertedRoas3Months(
      facebookSpends
        ? getRoas(
            roas3Months,
            parseInt(facebookSpends) * 3,
            parseInt(
              hdmclient.onboardingInfo.aboutBusiness.monthlyRevenueGoal3Months
            )
          )
        : 1
    );
    setConvertedRoas6Months(
      facebookSpends
        ? getRoas(
            roas6Months,
            parseInt(facebookSpends) * 6,
            parseInt(
              hdmclient.onboardingInfo.aboutBusiness.monthlyRevenueGoal6Months
            )
          )
        : 1
    );
    setConvertedRoas12Months(
      facebookSpends
        ? getRoas(
            roas12Months,
            parseInt(facebookSpends) * 12,
            parseInt(
              hdmclient.onboardingInfo.aboutBusiness.monthlyRevenueGoal1Year
            )
          )
        : 1
    );
  }, [roas, roas3Months, roas6Months, roas12Months, facebookSpends]);

  useEffect(() => {
    dispatch(getKeywords());
    dispatch(fetchCriteria());
  }, []);


  useEffect(() => {
    if (directSegments.length) {
      const tempInterestAndDemographies = directSegments.map((segment) => {
        if (segment.type === "In-Market Audiences") {
          const obj = inmarketCategories.find(
            (category) => category.criterionId === segment.userInterestId
          );
          if (obj) {
            let values = obj.category.split("/");
            return {
              ...obj,
              type: "inmarketCategories",
              value: values[values.length - 1],
            };
          } else {
            return null;
          }
        }
        if (segment.type === "Affinity Audiences") {
          const obj = affinityCategories.find(
            (category) => category.criterionId === segment.userInterestId
          );
          if (obj) {
            let values = obj.category.split("/");
            return {
              ...obj,
              type: "affinityCategories",
              value: values[values.length - 1],
            };
          } else {
            return null;
          }
        }
      });

      dispatch(
        updateData({
          interestAndDemographies: tempInterestAndDemographies.filter(Boolean),
        })
      );
    }
  }, [
    affinityCategories,
    inmarketCategories,
    lifeEvents,
    detailedDemographics,
    directSegments,
  ]);

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div style={{ width: "100%" }}>
      <div className="proposal_layout proposal_flex_top ">
        <div className="preview_head">
          <h4>PROPOSAL</h4>
          <h2>Horizons Digital Marketing </h2>
          {/* <img src="./test.svg" /> */}
        </div>
      </div>
      <div className="preview_footer_row">
        {/* <div className="back_button">
          {" "}
          <Button style={{ color: "#0869FB" }}>BACK </Button>
        </div> */}
        <div className="footer_content">
          <Button
            variant="contained"
            onClick={() => {
              // dispatch(updateHDMCompoReduxClient());
              navigate(generatePath(`${hdmClientRoutePaths.strategyDeck.fullbase}/${hdmClientRoutePaths.strategyDeck.sub.analysis}`));
            }}
          >
            PROCEED
          </Button>
        </div>
      </div>
      <div className="hero12"></div>
      <div>
        <Typography
          style={{
            fontFamily: "Lato",
            fontWeight: "700",
            color: "#000000",
            fontSize: "25px",
            lineHeight: "30px",
            marginLeft: "54px",
            marginTop: "44px",
          }}
        >
          Onboarding Phases
        </Typography>
        <img
          src={trackingOnboarding}
          style={{
            width: "100%",
            height: "100%",
          }}
          alt="onboarding"
        />
      </div>
      <div className="scroll_items">
        {/* <div className='phase'>
              <Typography style={{fontFamily:'Lato',fontWeight:'700', color:'#000000',fontSize:'30px',lineHeight:'36px',marginLeft:'52px'}}>Onboarding Phases</Typography>
              <img style={{height:"491.34px", width:'100%'}} src="../../../assets/images/phase.png" alt="" />
              
          </div> */}

        <div className="goal">
          <div className="goal_head">
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={GOAL}
                alt=""
              />{" "}
              Your Goals
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div className="pricing_card">
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                  marginBottom: "10px",
                  marginTop: "25px",
                }}
              >
                Current Monthly Revenue Goal
              </Typography>

              <Grid container xs={12}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "13px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      marginLeft: "32.95px",
                    }}
                  >
                    {month[new Date().getMonth()].toUpperCase()}
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "18px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                      marginTop: "24px",
                    }}
                  >
                    $ {hdmclient.onboardingInfo.aboutBusiness.monthlyRevenue}
                  </b>
                </Grid>
                {/* <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "13px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      marginLeft: "32.95px",
                    }}
                  >
                    3 Month Average
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "18px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                      marginTop: "24px",
                    }}
                  >
                    $1,065.44
                  </b>
                </Grid> */}
              </Grid>
              {/* </b> */}
            </div>

            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                  marginBottom: "10px",
                }}
              >
                3 month Revenue Goal
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "13px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                MONTHLY
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                $
                {
                  hdmclient.onboardingInfo.aboutBusiness
                    .monthlyRevenueGoal3Months
                }{" "}
                - ({" "}
                {(
                  parseInt(
                    hdmclient.onboardingInfo.aboutBusiness
                      .monthlyRevenueGoal3Months
                  ) /
                  parseInt(
                    hdmclient.onboardingInfo.aboutBusiness.monthlyRevenue
                  )
                ).toFixed(1)}{" "}
                X Increase)
              </b>
            </div>

            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                  marginBottom: "10px",
                }}
              >
                6 month Revenue Goal
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "13px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                MONTHLY
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                $
                {
                  hdmclient.onboardingInfo.aboutBusiness
                    .monthlyRevenueGoal6Months
                }{" "}
                - (
                {(
                  parseInt(
                    hdmclient.onboardingInfo.aboutBusiness
                      .monthlyRevenueGoal6Months
                  ) /
                  parseInt(
                    hdmclient.onboardingInfo.aboutBusiness.monthlyRevenue
                  )
                ).toFixed(1)}{" "}
                X Increase)
              </b>
            </div>
          </div>
          <div className="card3">
            <Typography
              style={{
                fontFamily: "Lato",
                fontSize: "18px",
                lineHeight: "22px",
                fontWeight: "400",
                color: " #5B596B",
                marginLeft: "32.95px",
                marginBottom: "10px",
              }}
            >
              12 month Revenue Goal
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato",
                fontSize: "13px",
                lineHeight: "22px",
                fontWeight: "400",
                color: " #5B596B",
                marginLeft: "32.95px",
              }}
            >
              MONTHLY
            </Typography>
            <b
              style={{
                fontFamily: "Inter",
                fontSize: "20px",
                lineHeight: "22px",
                fontWeight: "700",
                color: " #170F49",
                marginLeft: "32.95px",
              }}
            >
              ${hdmclient.onboardingInfo.aboutBusiness.monthlyRevenueGoal1Year}{" "}
              - (
              {(
                parseInt(
                  hdmclient.onboardingInfo.aboutBusiness.monthlyRevenueGoal1Year
                ) /
                parseInt(hdmclient.onboardingInfo.aboutBusiness.monthlyRevenue)
              ).toFixed(1)}{" "}
              X Increase)
            </b>
          </div>
        </div>
        <div className="history">
          <div className="history-head">
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={HISTORY}
                alt=""
              />{" "}
              Your Historical Data
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div className="pricing_card">
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                  marginBottom: "10px",
                }}
              >
                Current Monthly Revenue
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "13px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                {month[new Date().getMonth()].toUpperCase()}
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                -{/* $1,065.44 */}
              </b>
            </div>
            $
            {roas < 0.2
              ? parseInt(hdmclient.onboardingInfo.aboutBusiness.monthlyRevenue)
              : (
                  roas *
                  parseInt(
                    facebookSpends
                  )
                ).toFixed(2)}
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                  marginBottom: "10px",
                  marginTop: 25,
                }}
              >
                Spend
              </Typography>
              <Grid container xs={12}>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "13px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      marginLeft: "32.95px",
                    }}
                  >
                    GOOGLE
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "18px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                      marginTop: "24px",
                    }}
                  >
                    $
                    {googleSpends}
                  </b>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "13px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      marginLeft: "32.95px",
                    }}
                  >
                    FACEBOOK
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "18px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                      marginTop: "24px",
                    }}
                  >
                    ${facebookSpends}
                  </b>
                </Grid>
              </Grid>
            </div>
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                  marginBottom: "10px",
                }}
              >
                Historical Average Platform ROAS
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "13px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                AVERAGE
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                {convertedRoas.toFixed(2)}
              </b>
            </div>
          </div>
          <div className="card3">
            <Typography
              style={{
                fontFamily: "Lato",
                fontSize: "18px",
                lineHeight: "22px",
                fontWeight: "400",
                color: " #5B596B",
                marginLeft: "32.95px",
                marginBottom: "10px",
              }}
            >
              Historical Average Blended ROAS
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato",
                fontSize: "13px",
                lineHeight: "22px",
                fontWeight: "400",
                color: " #5B596B",
                marginLeft: "32.95px",
              }}
            >
              AVERAGE
            </Typography>
            <b
              style={{
                fontFamily: "Inter",
                fontSize: "20px",
                lineHeight: "22px",
                fontWeight: "700",
                color: " #170F49",
                marginLeft: "32.95px",
              }}
            >
              -
            </b>
          </div>
        </div>
        <div className="keyNumber">
          <div className="keyNumber-head">
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={HISTORY}
                alt=""
              />{" "}
              Your Key Numbers
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div className="pricing_card">
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                  marginBottom: "10px",
                }}
              >
                AOV
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "13px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Average order value
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                ${hdmclient.onboardingInfo.aboutBusiness.averageOrderValue}
              </b>
            </div>
            {/* <div style={{ display: "block" }}>
        {" "}
        Higher purchase behaviour than Amazon AOV
      </div> */}
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                  marginBottom: "10px",
                }}
              >
                COGS per AOV
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "13px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                Total COGS/ #ORDERS
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                ${hdmclient.onboardingInfo.aboutBusiness.cogsPerAov}
              </b>
            </div>
            <div className="card2">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "18px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                  marginBottom: "10px",
                }}
              >
                Shipping per AOV
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "13px",
                  lineHeight: "22px",
                  fontWeight: "400",
                  color: " #5B596B",
                  marginLeft: "32.95px",
                }}
              >
                TOTAL SHIPPING COSTS/ #ORDERS
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                ${hdmclient.onboardingInfo.aboutBusiness.shippingCostPerAov}
              </b>
            </div>
          </div>
          <div className="card3">
            <Typography
              style={{
                fontFamily: "Lato",
                fontSize: "18px",
                lineHeight: "22px",
                fontWeight: "400",
                color: " #5B596B",
                marginLeft: "32.95px",
                marginBottom: "10px",
              }}
            >
              Break- even Point
            </Typography>
            <Typography
              style={{
                fontFamily: "Lato",
                fontSize: "13px",
                lineHeight: "22px",
                fontWeight: "400",
                color: " #5B596B",
                marginLeft: "32.95px",
              }}
            >
              (AOV - COGS PER AOV- SHIPPING PER AOV)
            </Typography>
            <b
              style={{
                fontFamily: "Inter",
                fontSize: "20px",
                lineHeight: "22px",
                fontWeight: "700",
                color: " #170F49",
                marginLeft: "32.95px",
              }}
            >
              {parseInt(
                hdmclient.onboardingInfo.aboutBusiness.averageOrderValue
              ) -
                parseInt(hdmclient.onboardingInfo.aboutBusiness.cogsPerAov) -
                parseInt(
                  hdmclient.onboardingInfo.aboutBusiness.shippingCostPerAov
                )}
            </b>
          </div>
          <div
            style={{
              border: "1.12399px solid #DFDFDF",
              marginBottom: "15px",
              height: 1,
            }}
          ></div>
          <div style={{ marginLeft: 10, marginBottom: 30 }}>
            <div
              style={{
                marginBottom: "10px",
                fontWeight: 700,
                fontSize: "24.7278px",
                color: "#333333",
                marginTop: "40px",
                marginBottom: "40px",
              }}
            >
              Profitability Index
            </div>
            <Box>
              <ProfitIndexTable roas={convertedRoas} />
            </Box>
          </div>
        </div>

        <div className="hitGoal">
          <div className="hitGoal-head">
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "Lato",
                fontSize: "35.5237px",
                fontWeight: "700",
                lineHeight: "46px",
                margin: "0px",
              }}
            >
              <img
                style={{ position: "relative", top: "15px" }}
                src={GOAL}
                alt=""
              />{" "}
              What you will need to hit your goals
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div className="pricing_card">
            <div
              className="card2"
              style={{ height: "auto", paddingBottom: 24 }}
            >
              <div
                style={{ display: "flex", padding: 20, alignItems: "center" }}
              >
                {" "}
                <img src={REVENUE} />
                <div style={{ marginLeft: "20px" }}>
                  {" "}
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "15px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      display: "block",
                    }}
                  >
                    Monthly Revenue
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "15px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                    }}
                  >
                    3 Month Goal
                  </b>
                </div>
              </div>
              <Grid container xs={12}>
                <Grid item xs={4}>
                  {" "}
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "11.2399px",
                      lineHeight: "17px",
                      fontWeight: "600",
                      color: " #6F6C90",
                      marginLeft: "32.95px",
                      marginBottom: "2.5px",
                    }}
                  >
                    YOUR GOAL
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                    }}
                  >
                    $
                    {
                      hdmclient.onboardingInfo.aboutBusiness
                        .monthlyRevenueGoal3Months
                    }
                  </b>
                </Grid>
                <Grid item xs={8}>
                  {" "}
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "11.2399px",
                      lineHeight: "17px",
                      fontWeight: "600",
                      color: " #6F6C90",
                      marginLeft: "32.95px",
                      marginBottom: "2.5px",
                    }}
                  >
                    ESTIMATED ADSPEND
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                    }}
                  >
                    {convertedRoas3Months
                      ? `$${parseInt(
                          parseInt(
                            hdmclient.onboardingInfo.aboutBusiness
                              .monthlyRevenueGoal3Months
                          ) / convertedRoas3Months
                        )}`
                      : 0}
                  </b>
                </Grid>
              </Grid>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "11.2399px",
                  lineHeight: "17px",
                  fontWeight: "600",
                  color: " #6F6C90",
                  marginLeft: "32.95px",
                  marginBottom: "2.5px",
                  marginTop: "18px",
                }}
              >
                HISTORICAL AVERAGE ROAS (3 MONTHS)
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                {convertedRoas3Months.toFixed(2)}
              </b>
            </div>
            {/* <div style={{ display: "block" }}>
        {" "}
        Higher purchase behaviour than Amazon AOV
      </div> */}
            <div
              className="card2"
              style={{ height: "auto", paddingBottom: 24 }}
            >
              <div
                style={{ display: "flex", padding: 20, alignItems: "center" }}
              >
                {" "}
                <img src={REVENUE} />
                <div style={{ marginLeft: "20px" }}>
                  {" "}
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "15px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      display: "block",
                    }}
                  >
                    Monthly Revenue
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "15px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                    }}
                  >
                    6 Month Goal
                  </b>
                </div>
              </div>
              <Grid container xs={12}>
                <Grid item xs={4}>
                  {" "}
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "11.2399px",
                      lineHeight: "17px",
                      fontWeight: "600",
                      color: " #6F6C90",
                      marginLeft: "32.95px",
                      marginBottom: "2.5px",
                    }}
                  >
                    YOUR GOAL
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                    }}
                  >
                    $
                    {
                      hdmclient.onboardingInfo.aboutBusiness
                        .monthlyRevenueGoal6Months
                    }
                  </b>
                </Grid>
                <Grid item xs={8}>
                  {" "}
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "11.2399px",
                      lineHeight: "17px",
                      fontWeight: "600",
                      color: " #6F6C90",
                      marginLeft: "32.95px",
                      marginBottom: "2.5px",
                    }}
                  >
                    ESTIMATED ADSPEND
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                    }}
                  >
                    {convertedRoas6Months
                      ? `$${parseInt(
                          parseInt(
                            hdmclient.onboardingInfo.aboutBusiness
                              .monthlyRevenueGoal6Months
                          ) / convertedRoas6Months
                        )}`
                      : 0}
                  </b>
                </Grid>
              </Grid>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "11.2399px",
                  lineHeight: "17px",
                  fontWeight: "600",
                  color: " #6F6C90",
                  marginLeft: "32.95px",
                  marginBottom: "2.5px",
                }}
              >
                HISTORICAL AVERAGE ROAS (6 MONTHS)
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                {convertedRoas6Months.toFixed(2)}
              </b>
            </div>
            <div
              className="card2"
              style={{ height: "auto", paddingBottom: 24 }}
            >
              <div
                style={{ display: "flex", padding: 20, alignItems: "center" }}
              >
                {" "}
                <img src={REVENUE} />
                <div style={{ marginLeft: "20px" }}>
                  {" "}
                  <Typography
                    style={{
                      fontFamily: "Lato",
                      fontSize: "15px",
                      lineHeight: "22px",
                      fontWeight: "400",
                      color: " #5B596B",
                      display: "block",
                    }}
                  >
                    Monthly Revenue
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "15px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                    }}
                  >
                    12 Month Goal
                  </b>
                </div>
              </div>
              <Grid container xs={12}>
                <Grid item xs={4}>
                  {" "}
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "11.2399px",
                      lineHeight: "17px",
                      fontWeight: "600",
                      color: " #6F6C90",
                      marginLeft: "32.95px",
                      marginBottom: "2.5px",
                    }}
                  >
                    YOUR GOAL
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                    }}
                  >
                    $
                    {
                      hdmclient.onboardingInfo.aboutBusiness
                        .monthlyRevenueGoal1Year
                    }
                  </b>
                </Grid>
                <Grid item xs={8}>
                  {" "}
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "11.2399px",
                      lineHeight: "17px",
                      fontWeight: "600",
                      color: " #6F6C90",
                      marginLeft: "32.95px",
                      marginBottom: "2.5px",
                    }}
                  >
                    ESTIMATED ADSPEND
                  </Typography>
                  <b
                    style={{
                      fontFamily: "Inter",
                      fontSize: "16px",
                      lineHeight: "22px",
                      fontWeight: "700",
                      color: " #170F49",
                      marginLeft: "32.95px",
                    }}
                  >
                    {convertedRoas12Months
                      ? `$${parseInt(
                          parseInt(
                            hdmclient.onboardingInfo.aboutBusiness
                              .monthlyRevenueGoal1Year
                          ) / convertedRoas12Months
                        )}`
                      : 0}
                  </b>
                </Grid>
              </Grid>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "11.2399px",
                  lineHeight: "17px",
                  fontWeight: "600",
                  color: " #6F6C90",
                  marginLeft: "32.95px",
                  marginBottom: "2.5px",
                }}
              >
                HISTORICAL AVERAGE ROAS (12 MONTHS)
              </Typography>
              <b
                style={{
                  fontFamily: "Inter",
                  fontSize: "16px",
                  lineHeight: "22px",
                  fontWeight: "700",
                  color: " #170F49",
                  marginLeft: "32.95px",
                }}
              >
                {convertedRoas12Months.toFixed(2)}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GoalHDM;
