import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Box, Grid, Input } from "@mui/material";
import "./googleaccount.scss";
import { search as SEARCH } from "../../assets/icons/icon";
import BpRadio from "../ClientDashboard/CustomRadio/Radio";
import Option from "../AccountManager/Option";
import PrimaryButton from "../ButtonPrimary/Buttons";
import DateModal from "../AccountManager/DateModal/DateModal";
import {
  getGoogleCustomersReducers,
  createGoogleAdsAuditReducer,
  updateSelectedCustomerId,
  updateSelectedLoginCustomerId,
  updateSelectedLoginCustomerName,
  updateSelectedCustomerName,
  updateGotInsights,
  getInsightsForFormIdReducer,
  updateData,
} from "../../api/accountManager/googleAdsCustomer/slice";
import { updateFormReducer } from "../../api/accountManager/forms/slice";
import LoaderModal from "../AccountManager/LoaderModal/LoaderModal";
import AuditLoader from "../AccountManager/Audit/AuditLoader";
import {
  BACKEND_STATUS,
  isPlatformSelectedBP,
} from "../../utils/accountManagerUtil";
import { useGoogleAccountMetaData } from "./service";
import {
  updateFieldInFormReducer,
  updateFormData,
} from "../../api/clientforms/slice";
import { Button } from "@mui/material";
import { useGetGoogleInsight } from "../../services/g-ads";
import PmaxLoader from "../PMaxStrategyCreation/PmaxLoader";
import { useGetAdAccounts } from "../../services/adAccount";
import { ReactComponent as IconNoData } from "../../assets/icons/no-data-available.svg";
import { isPlatformSelectedLD } from "../../utils/LDUtils/commonUtils";
import { PLATFORM, PLATFORM_ADS } from "../../utils/commonEnums";
import { useParams } from "react-router-dom";
const CommonGoogleAdsAccount = ({
  form,
  onGotInsights,
  onHaveInsights,
  performanceAuditBoolean = false,
  redirectUrl = "#",
  generateAudit = () => {},
  onNext = () => {},
  onSkip = () => {},
  again = false,
}) => {
  // debugger;
  const dispatch = useDispatch();
  const googleAdsCustomer = useSelector(
    (store) => store.googleCustomers.googleAdCustomers
  );

  const gotCustomers = useSelector(
    (store) => store.googleCustomers.gotCustomers
  );

  // for loader and modal
  // const [loader, setLoader] = useState(true);

  // const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  // const [selectedLoginCustomerId, setSelectedLoginCustomerId] = useState(null);
  // const [selectedCustomerName, setSeletectedCustomerName] = useState(null);
  // const [selectedLoginCustomerName, setSlectedLoginCustomerName] = useState(null);
  // const [gotInsights, setGotInsights] = useState(false);
  // const [haveInsights, setHaveInsights] = useState(false);
  const { formId } = useParams();
  const selectedCustomerId =
    useSelector((store) => store.googleCustomers.selectedCustomerId) || "";
  const selectedLoginCustomerId =
    useSelector((store) => store.googleCustomers.selectedLoginCustomerId) || "";
  const selectedCustomerName =
    useSelector((store) => store.googleCustomers.selectedCustomerName) || "";
  const selectedLoginCustomerName =
    useSelector((store) => store.googleCustomers.selectedLoginCustomerName) ||
    "";
  const gotInsights = useSelector((store) => store.googleCustomers.gotInsights);
  const insights = useSelector((store) => store.googleCustomers.insights);
  // const { data: insights } = useGetGoogleInsight(form?._id);

  const haveInsights = useSelector(
    (store) => store.googleCustomers.haveInsights
  );

  const { data: googleAccMetaData } = useGoogleAccountMetaData(
    {
      customerId: selectedCustomerId,
      loginCustomerId: selectedLoginCustomerId,
      formId: formId,
    },
    { enabled: !!selectedCustomerId && !!selectedLoginCustomerId }
  );
  const {
    data: connectedAdAccounts,
    isLoading,
    isFetching,
  } = useGetAdAccounts(form?.brandId);
  // console.log("Connected Ad Accounts :: ", connectedAdAccounts);
  const [startDate, setStartDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(Date.now()).format("YYYY-MM-DD")
  );
  const [showDateModal, setShowDateModal] = useState(false);
  const [showLoaderModal, setShowLoaderModal] = useState(false);
  const [showAuditLoader, setShowAuditLoader] = useState(false);
  const [generationCompleted, setGenerationCompleted] = useState(false);
  const [showAccountList, setShowAccountList] = useState(true);
  const [googleCustomers, setGoogleCustomers] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");

  useEffect(() => {
    dispatch(
      updateData(
        connectedAdAccounts?.data?.filter(
          ({ platform }) => platform === PLATFORM.GOOGLE
        )
      )
    );
  }, [connectedAdAccounts?.data, dispatch]);

  // useEffect(() => {
  //   if (gotInsights) {
  //     setShowLoaderModal(false);
  //     dispatch(
  //       updateFormReducer({
  //         gAdsCustomerId: selectedCustomerId,
  //         clientGoogleAdsInfo: {
  //           customerId: selectedCustomerId,
  //           loginCustomerId: selectedLoginCustomerId,
  //           customerName: selectedCustomerName,
  //           loginCustomerName: selectedLoginCustomerName,
  //         },
  //       })
  //     );
  //     onGotInsights?.();
  //     dispatch(updateGotInsights(false));
  //   }
  // }, [gotInsights]);

  // useEffect(() => {
  //   if (!haveInsights || again) {
  //     dispatch(getGoogleCustomersReducers());
  //   } else {
  //     if (!showAuditLoader) onHaveInsights?.();
  //   }
  // }, [haveInsights, showAuditLoader]);

  // useEffect(() => {
  //   if (gotCustomers) {
  //     setLoader(false);
  //   }
  // }, [gotCustomers]);
  useEffect(() => {
    if (
      performanceAuditBoolean &&
      [BACKEND_STATUS.ONGOING, BACKEND_STATUS.COMPLETED].includes(
        form?.performanceAuditGoogleAds
      )
    ) {
      setShowAccountList(false);
    }
  }, [form]);

  useEffect(() => {
    // debugger;

    // dispatch(
    //   updateSelectedLoginCustomerName(googleCustomer.loginCustomerName)
    // );
    if (
      googleAdsCustomer &&
      googleAdsCustomer.findIndex(
        (googleAdCustomer) =>
          googleAdCustomer.adAccountId === selectedCustomerId
      ) >= 0
    ) {
      const googleCustomer = googleAdsCustomer?.find(
        (customer) => customer.adAccountId === selectedCustomerId
        // && customer.loginCustomerId === selectedLoginCustomerId
      );
      if (googleCustomer) {
        dispatch(updateSelectedCustomerName(googleCustomer?.adAccountName));
        dispatch(
          updateSelectedLoginCustomerId(googleCustomer?.loginCustomerId)
        );
      }
    } else {
      if (form) {
        // if (form?.accessSharing?.googleAccess?.googleAdAccount?.accountId) {
        //   const presentCustomer = googleAdsCustomer.find(
        //     (acc) =>
        //       acc.customerId ===
        //       form?.accessSharing?.googleAccess?.googleAdAccount?.accountId
        //   );
        //   if (presentCustomer) {
        //     dispatch(updateSelectedCustomerId(presentCustomer.customerId));
        //     dispatch(updateSelectedCustomerName(presentCustomer.customerName));
        //     dispatch(
        //       updateSelectedLoginCustomerId(presentCustomer.loginCustomerId)
        //     );
        //     dispatch(
        //       updateSelectedLoginCustomerName(presentCustomer.loginCustomerName)
        //     );
        //   }
        // } else {}
        if (googleAdsCustomer) {
          dispatch(updateSelectedCustomerId(googleAdsCustomer[0]?.adAccountId));
          dispatch(
            updateSelectedLoginCustomerId(googleAdsCustomer[0]?.loginCustomerId)
          );
          dispatch(
            updateSelectedCustomerName(googleAdsCustomer[0]?.adAccountName)
          );
        }

        // dispatch(
        //   updateSelectedLoginCustomerName(
        //     googleAdsCustomer[0]?.loginCustomerName
        //   )
        // );
      }
    }
  }, [selectedCustomerId, selectedLoginCustomerId, googleAdsCustomer, form]);
  useEffect(() => {}, [googleAdsCustomer, setGoogleCustomers]);
  useEffect(() => {
    if (!searchedValue) {
      setGoogleCustomers(
        connectedAdAccounts?.data?.filter(
          ({ platform }) => platform === PLATFORM.GOOGLE
        )
      );
    } else {
      setGoogleCustomers(
        googleAdsCustomer.filter(
          (customer) =>
            (customer.adAccountName || "")
              .toLowerCase()
              .includes(searchedValue.toLowerCase()) &&
            customer.platform === PLATFORM.GOOGLE
        )
      );
    }
  }, [googleAdsCustomer, searchedValue]);

  const onGenerateReportHandler = () => {
    if (!performanceAuditBoolean) {
      dispatch(
        createGoogleAdsAuditReducer({
          startDate: startDate,
          endDate: endDate,
          nextStep:
            isPlatformSelectedLD(form, PLATFORM_ADS.FACEBOOK_ADS) ||
            isPlatformSelectedBP(form, PLATFORM_ADS.FACEBOOK_ADS)
              ? "Audit"
              : "Proposal",
        })
      );
      setShowLoaderModal(true);
    }
    // hit post audit report api
    if (performanceAuditBoolean) {
      // dispatch(
      //   updateFormData({
      //     gAdsCustomerId: selectedCustomerId,
      //     clientGoogleAdsInfo: {
      //       customerId: selectedCustomerId,
      //       loginCustomerId: selectedLoginCustomerId,
      //       customerName: selectedCustomerName,
      //       loginCustomerName: selectedLoginCustomerName,
      //     },
      //       performanceAuditGoogleAds: BACKEND_STATUS.ONGOING
      //   })
      // );
      // dispatch(
      //   updateFieldInFormReducer({
      //     gAdsCustomerId: selectedCustomerId,
      //     clientGoogleAdsInfo: {
      //       customerId: selectedCustomerId,
      //       loginCustomerId: selectedLoginCustomerId,
      //       customerName: selectedCustomerName,
      //       loginCustomerName: selectedLoginCustomerName,
      //     },
      //   })
      // );
      setShowAccountList(false);
      setShowAuditLoader(true);
      setGenerationCompleted(false);
      generateAudit({
        gAdsCustomerId: selectedCustomerId,
        clientGoogleAdsInfo: {
          customerId: selectedCustomerId,
          loginCustomerId: selectedLoginCustomerId,
          customerName: selectedCustomerName,
          loginCustomerName: selectedLoginCustomerName || "",
        },
        startDate,
        endDate,
      })
        .then(() => {
          setShowAccountList(false);
          setShowAuditLoader(true);
          setGenerationCompleted(false);
        })
        .catch((err) => {
          console.log(err);
          setShowAccountList(true);
          setShowAuditLoader(false);
        });
    }
    setShowDateModal(false);
  };

  useEffect(() => {
    if (performanceAuditBoolean) {
      if (form?.performanceAuditGoogleAds === BACKEND_STATUS.COMPLETED) {
        setGenerationCompleted(true);
        setShowAuditLoader(false);
      }
    }
  }, [form?.performanceAuditGoogleAds]);

  const displayStatus = () => {
    if (
      form?.performanceAuditGoogleAds === BACKEND_STATUS.COMPLETED &&
      generationCompleted
    )
      return (
        <AuditLoader
          isReady={true}
          disabled={false}
          redirectUrl={redirectUrl}
          setShowAccountList={setShowAccountList}
          title="The audit report has been successfully generated."
          subTitle="You can view it in the Audit Manager. To generate more reports, please click on the “Generate Report Again” button below:"
          onNext={onNext}
        />
      );
    if (
      form?.performanceAuditGoogleAds === BACKEND_STATUS.ONGOING ||
      showAuditLoader
    )
      return <AuditLoader redirectUrl={redirectUrl} disabled={true} />;
  };

  const skipButtonHandler = () => {
    if (
      form?.googleAuditData?.status !== BACKEND_STATUS.COMPLETED ||
      form?.googleAuditStatus !== BACKEND_STATUS.COMPLETED
    ) {
      dispatch(
        updateFormReducer({
          googleAuditStatus: BACKEND_STATUS.COMPLETED,
          googleAuditData: {
            status: BACKEND_STATUS.COMPLETED,
            isSkipped: true,
          },
          facebookAuditStatus: BACKEND_STATUS.ONGOING,
          currentStep:
            isPlatformSelectedLD(form, "facebook-ads") ||
            isPlatformSelectedBP(form, "facebook-ads")
              ? "Audit"
              : "Proposal",
        })
      );
    }
    onSkip?.();
  };
  console.log("googlePerformanceAudit:", form?.performanceAuditGoogleAds);
  if (isLoading) {
    return <PmaxLoader />;
  }
  return (
    <div
      className="googlebox"
      style={{
        background: "white",
      }}
    >
      {performanceAuditBoolean ? (
        performanceAuditBoolean && !showAccountList ? (
          displayStatus()
        ) : (
          <>
            <div
              style={{
                fontFamily: "sans-serif",
                fontWeight: "400",
                marginBottom: "0rem",
                position: "relative",
              }}
            >
              <h2
                style={{
                  margin: "24px 0",
                }}
              >
                Ad Account
              </h2>
              <Box
                sx={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}
              >
                <img src={SEARCH} />
                <Input
                  placeholder="Data Search"
                  size="small"
                  disableUnderline={true}
                  value={searchedValue}
                  onChange={(e) => setSearchedValue(e.target.value)}
                  disabled={!connectedAdAccounts?.data?.length}
                />
              </Box>
              <div className="line"></div>
            </div>
            {(form?.googleAuditStatus === BACKEND_STATUS.COMPLETED ||
              form?.performanceAuditGoogleAds === BACKEND_STATUS.COMPLETED) && (
              <a
                href={redirectUrl}
                style={{
                  textDecoration: "none",
                  position: "absolute",
                  right: "16px",
                  top: "16px",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  //disabled={disabled}
                  style={{
                    textDecoration: "none",
                    width: "172px",
                    height: "40px",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#0869FB",
                    textTransform: "capitalize",
                    background: "white",
                    border: "2px solid #0869FB",
                  }}
                >
                  Audit Manager
                </Button>
              </a>
            )}
            <div></div>

            {!!connectedAdAccounts?.data?.length && (
              <>
                <div className="assignee assignee_add_acccount">
                  <span>Assigned</span>
                  <div className="ft">
                    <div>
                      <BpRadio checked={true} />
                    </div>
                    <div className="ai">
                      <span>{selectedCustomerName}</span>
                      <p>
                        Account Id: {selectedCustomerId} (
                        {selectedLoginCustomerName})
                      </p>
                    </div>
                  </div>
                </div>
                <div className="line"></div>
              </>
            )}

            {googleCustomers?.length ? (
              <div className="available">
                <span>Available</span>
                <div>
                  <Grid>
                    <div className="radio_add_acccount">
                      {googleCustomers.map((connectedAdAccount, idx) => (
                        <Option
                          key={idx}
                          accountId={connectedAdAccount.adAccountId}
                          customerName={connectedAdAccount.adAccountName}
                          // loginAccountId={customer.loginCustomerId}
                          // loginCustomerName={customer.loginCustomerName}
                          checked={
                            // connectedAdAccount.customerId === selectedCustomerId &&
                            // connectedAdAccount.loginCustomerId === selectedLoginCustomerId
                            connectedAdAccount.adAccountId ===
                            selectedCustomerId
                          }
                        />
                      ))}
                    </div>
                  </Grid>
                </div>
              </div>
            ) : (
              <div className="select-ad-modal__error">
                <IconNoData />
                <h2 className="select-ad-modal__error-title">
                  No ad accounts found
                </h2>
                <p className="select-ad-modal__error-description">
                  No accounts are connected in this brand. Please connect an{" "}
                  <br />
                  account through the “Access Sharing” step or admin panel
                </p>
              </div>
            )}
          </>
        )
      ) : (
        <>
          <div
            style={{
              fontFamily: "sans-serif",
              fontWeight: "400",
              marginBottom: "0rem",
            }}
          >
            <h2>Ad Account</h2>
            <Box sx={{ display: "flex", alignItems: "flex-end", gap: "1rem" }}>
              <img src={SEARCH} />
              <Input
                placeholder="Data Search"
                size="small"
                disableUnderline={true}
                value={searchedValue}
                onChange={(e) => setSearchedValue(e.target.value)}
              />
            </Box>
            <div className="line"></div>
          </div>

          <div className="assignee assignee_add_acccount">
            <span>Assigned</span>
            <div className="ft">
              <div>
                <BpRadio checked={true} />
              </div>
              <div className="ai">
                <span>{selectedCustomerName}</span>
                <p>
                  Account Id: {selectedCustomerId} ({selectedLoginCustomerName})
                </p>
              </div>
            </div>
          </div>

          <div className="line"></div>

          <div className="available">
            <span>Available</span>
            <div>
              <Grid>
                <div className="radio_add_acccount">
                  {googleCustomers.map((customer, idx) => (
                    <Option
                      key={idx}
                      accountId={customer.customerId}
                      customerName={customer.customerName}
                      loginAccountId={customer.loginCustomerId}
                      loginCustomerName={customer.loginCustomerName}
                      checked={
                        customer.customerId === selectedCustomerId &&
                        customer.loginCustomerId === selectedLoginCustomerId
                      }
                    />
                  ))}
                </div>
              </Grid>
            </div>
          </div>
        </>
      )}
      <div className="preview_footer_row">
        <div className="footer_down">
          {performanceAuditBoolean ||
          [BACKEND_STATUS.COMPLETED, BACKEND_STATUS.ONGOING].includes(
            form?.performanceAuditGoogleAds
          ) ? (
            <>
              {performanceAuditBoolean && showAccountList ? (
                <PrimaryButton
                  title="Skip"
                  style={{
                    marginRight: "10px",
                    color: "#1976d2",
                    background: "white",
                  }}
                  onClick={skipButtonHandler}
                />
              ) : (
                ""
              )}

              <PrimaryButton
                title="Next"
                disabled={
                  googleCustomers?.length
                    ? form?.performanceAuditGoogleAds ===
                        BACKEND_STATUS.ONGOING ||
                      showAuditLoader ||
                      !connectedAdAccounts?.data?.length
                      ? true
                      : false
                    : true
                }
                onClick={() => {
                  if (
                    [BACKEND_STATUS.COMPLETED, BACKEND_STATUS.ONGOING].includes(
                      form?.performanceAuditGoogleAds
                    ) &&
                    !showAccountList
                  ) {
                    // setShowAuditLoader(false);
                    onNext();
                  } else {
                    setShowDateModal(true);
                  }
                }}
              />
            </>
          ) : (
            <div>
              <PrimaryButton
                title="Skip"
                style={{
                  marginRight: "10px",
                  color: "#1976d2",
                  background: "white",
                }}
                onClick={skipButtonHandler}
              />
              <PrimaryButton
                title="Next"
                onClick={() => {
                  setShowDateModal(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
      {showDateModal && (
        <DateModal
          setShowDateModal={setShowDateModal}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          onClickHandler={onGenerateReportHandler}
          timeZone={googleAccMetaData?.timeZone}
        />
      )}
      {showLoaderModal && <LoaderModal />}
    </div>
  );
};
export default CommonGoogleAdsAccount;
