import { Languages } from '../../../../utils/Languages';


const OBJECTIVES = [
  "Leads",
  "Sales",
  "Website Traffic",
  "Product and brand consideration",
  "Brand Awareness and reach",
  "App promotion",
  "Local store visits and promotions",
  "Create a campaign without a goal's guidance",
];

export const OBJECTIVE_OPTIONS = OBJECTIVES.map((value, index) => ({
  label: value,
  value,
  disabled: index !== 7,
}));

// ---------------------------------------------------------------

const BIDDING_LIST = [
  {
    value: "Clicks",
    field: {
      label: "Max CPC bid limit",
      placeholder: "Enter Max CPC bid limit",
      key: "maxCpcBidLimit",
    },
  },
  {
    value: "Conversions",
    field: {
      label: "Target CPA (optional)",
      placeholder: "Enter Target CPA (optional)",
      key: "targetCpa",
    },
  },
  {
    value: "Conversion value",
    field: {
      label: "Target ROAS (optional)",
      placeholder: "Enter Target ROAS (optional)",
      key: "selectTargetRoas",
    },
  },
  {
    value: "Impression Share",
    field: null,
    disabled: true,
  },
];

export const BIDDING_OPTIONS = BIDDING_LIST.map((item) => ({
  label: item.value,
  value: item.value,
  disabled: item.disabled,
}));

export function getBiddingExtraField (biddingOptionValue) {
  return BIDDING_LIST.find((item) => item.value === biddingOptionValue)?.field;
}

// ---------------------------------------------------------------

const NETWORKS = ["Display Network", "Search Network"];

export const NETWORK_OPTIONS = NETWORKS.map((value) => ({
  label: value,
  value,
}));

// ---------------------------------------------------------------

export const CONVERSION_GOALS = [
  "Submit lead forms",
  "Purchases",
  "Phone call leads",
];

export const CONVERSION_GOAL_OPTIONS = CONVERSION_GOALS.map((value) => ({
  label: value,
  value,
}));

// ---------------------------------------------------------------


const ENGLISH_LANG_OPTION = Languages.find(lang => lang.language === "English");
const DEFAULT_LANGUAGES =  ENGLISH_LANG_OPTION ? [ENGLISH_LANG_OPTION]: [];

export const CAMPAIGN_DEFAULTS = {
  conversionGoals: CONVERSION_GOALS, // all selected
  objective: OBJECTIVES[7],
  networks: [],
  bidding: BIDDING_LIST[0].value,
  languages: DEFAULT_LANGUAGES,
  locations: [],
}