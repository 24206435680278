import React from 'react';
import campaignBudgetInfoIcon from '../PMaxStrategyCreation/pmaxIcons/campaign-warning-icon.svg';
import { Typography, Tooltip } from '@mui/material';
const InfoTip = (props) => (
  <Tooltip
    PopperProps={{
      sx: {
        '& .MuiTooltip-arrow': {
          color: 'white',
        },
        '& .MuiTooltip-tooltip': {
          backgroundColor: 'white',
          color: 'black',
          marginBottom: '20px',
          filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
          padding: '10px 12px 10px 13px',
          width: 181,
          borderRadius: 2,
        },
      },
    }}
    title={
      <>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '20px',
            color: '#333333',
          }}
        >
          {props.infotext}
        </Typography>
      </>
    }
    arrow
    placement='bottom'
  >
    <img src={campaignBudgetInfoIcon} alt='campaign-budget' />
  </Tooltip>
);

export default InfoTip;
