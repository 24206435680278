import { TextField } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { editicon as IconEdit } from '../../../../assets/icons/icon';
import { checked_icon as IconCheck } from '../../../../assets/icons/strategyIcons/strategyIcons';
import AudienceDropdown from '../AudienceDropdown';
import DisplayAdsetName from './DisplayAdsetName';

export default function AudienceAdset({
  index,
  activeAdsetIndex,
  onChangeActiveAdsetIndex,
  openAdsetDeleteModalCurried,
  audiences,
}) {
  const { register, control, formState: { errors } } = useFormContext();

  const [isAdsetNameEditable, setIsAdsetNameEditable] = useState(false);
  const toggleIsAdsetNameEditable = () => setIsAdsetNameEditable(prev => !prev);

  return (
    <div
      className={
        index === activeAdsetIndex
          ? 'adset_block_hdm selected'
          : 'adset_block_hdm'
      }
      onClick={onChangeActiveAdsetIndex(index)}
    >
      <input type="hidden" value="COMPLETED" {...register(`adsets.${index}.status`)} />
      <input type="hidden" {...register(`adsets.${index}.id`)} />
      <input type="hidden" {...register(`adsets.${index}.adsetNumber`)} />
      <div className='adset_head_hdm flex-center-space'>
        <p
          style={{
            display: 'flex',
            alignItems: 'baseline',
            gap: '6px',
          }}
        >
          <span>Adset Name:</span>
          {isAdsetNameEditable &&
            index === activeAdsetIndex ? (
            <TextField
              variant='standard'
              style={{
                width: 80,
              }}
              autoFocus
              inputProps={{
                style: {
                  fontFamily: 'Inter',
                  fontWeight: '600',
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: '#333333',
                  paddingBottom: 0,
                },
              }}
              {...register(`adsets.${index}.adsetName`)}
            />
          ) : (
            <DisplayAdsetName control={control} index={index} />
          )}
          <button
            style={{
              background: '#F8F8F8',
              borderRadius: '6px',
              border: 'transparent',
              width: '0',
              height: '0',
            }}
            onClick={toggleIsAdsetNameEditable}
            type="button"
          >
            <img
              src={
                isAdsetNameEditable &&
                  index === activeAdsetIndex
                  ? IconCheck
                  : IconEdit
              }
              alt=''
            />
          </button>
        </p>
        <i
          className='delete_adset'
          onClick={openAdsetDeleteModalCurried(index)}
        ></i>
      </div>
      <div className='adset_list_hdm flex-center-space'>
        <div className='adset_dropdown_hdm'>
          <label className='adset_label_hdm'>Audience</label>
          <Controller
            name={`adsets.${index}.audience`}
            control={control}
            render={({ field }) => (
              <AudienceDropdown
                audiences={audiences}
                error={!!errors.adsets?.[index]?.audience}
                {...field}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}