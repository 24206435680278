export const updateLookalike = (data) => {
  return {
    type: "updateLookalike",
    payload: data,
  };
};

export const updateInterest = (data) => {
  return {
    type: "updateInterest",
    payload: data,
  };
};
export const updatemof = (data) => {
  return {
    type: "updatemof",
    payload: data,
  };
};
export const updatebof = (data) => {
  return {
    type: "updatebof",
    payload: data,
  };
};
export const addLocation = (data) => {
  return {
    type: "addLocationTerget",
    payload: data,
  };
};

export const getPages = () => {
  return {
    type: "GET_FB_PAGES",
  };
};
export const updatePages = (data) => {
  return {
    type: "UPDATE_FB_PAGES",
    payload: data,
  };
}

export const getAdPixels = () => {
  return {
    type: "GET_FB_AD_PIXELS",
  };
}

export const updatePixels = (data) => {
  return {
    type: "UPDATE_FB_PIXELS",
    payload: data,
  };
}
export const getPixelEvents = (data) => {
  return {
    type: "GET_FB_PIXEL_EVENTS",
    payload: data
  };
}

export const updatePixelEvents = (data) => {
  return {
    type: "UPDATE_FB_PIXEL_EVENTS",
    payload: data,
  };
}

export const updateCampaignBackClicked = (data) => {
  return {
    type: "UPDATE_CAMPAIGN_BACK_CLICKED"
  }
}







