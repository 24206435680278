import { Select, MenuItem, MenuList } from '@material-ui/core';
import React, { useState } from 'react';

const ABTestListStatus = ({ onChangeHandler, status }) => {
  const handleChange = (event) => {
    onChangeHandler({ status: event.target.value });
  };
  return (
    <>
      <Select
        value={status}
        onChange={handleChange}
        displayEmpty
        variant='outlined'
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        style={{
          width: 130,
          height: 27,
          background: '#FFFFFF',
          border: '1px solid #F3F3F3',
          borderRadius: 6,
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: 12,
          outline: 'none',
          boxShadow: 'none',
          margin: '8px 0 18px 0',
          color:
            status === 'Not Started'
              ? '#F8862D'
              : status === 'Ongoing'
              ? '#3482F7'
              : '#1ABFA1',
        }}
      >
        <MenuItem
          value='Not Started'
          style={{
            fontSize: 12,
            color: '#F8862D',
          }}
        >
          Not Started
        </MenuItem>
        <MenuItem
          value='Ongoing'
          style={{
            fontSize: 12,
            color: '#3482F7',
          }}
        >
          Ongoing
        </MenuItem>
        <MenuItem
          value='Completed'
          style={{
            fontSize: 12,
            color: '#1ABFA1',
          }}
        >
          Completed
        </MenuItem>
      </Select>
    </>
  );
};

export default ABTestListStatus;
