import { withStyles } from "@material-ui/core/styles";
import { Close } from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  Slide,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeClientView } from "../../../api/accountManager/componentTracking/action";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import {
  updateClientDetails,
  updateClientDetailsReducer,
} from "../../../api/client/action";
import {
  ADD_NEW_FIELD_IN_PLATFORM_COMPETITORS,
  GET_PERSONAL_INFO_CLIENT,
} from "../../../api/client/enum";
import {
  goToSpecificCompoClient,
  updateCompoReduxClient,
} from "../../../api/componentTracking/action";
import { getImageUrl } from "../../../api/fileHandling/saga";
import backgroundImage2 from "../../../assets/icons/background-2.png";
import {
  preview as PreviewIcon,
  down_arrow as arrowDown,
  top_arrow as arrowUp,
  menu as menuIcon,
  trash as trashIcon,
} from "../../../assets/icons/common/common";
import facebookIcon from "../../../assets/icons/social/facebook.svg";
import { google_icon as googleIcon } from "../../../assets/icons/social/social";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";
import {
  LDcomponentWithIndexClient,
  clientDashboardIds,
} from "../../../utils/clientDashboardIdsUtil";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";
import StyledDrop from "../Browse";
import CompetitorTextField from "../InputTextField/CompetitorWebsiteTextField";
import InputTextField from "../InputTextField/TextField";
import TextField2 from "../InputTextField/TextField2";
import CheckBoxOption from "./CheckBoxOption";
import AddKeywords from "./Helpers/AddKeywords";
import AdvertiseLocation from "./Helpers/AdvertiseLocation";
import AgeRangeCheckboxes from "./Helpers/AgeRangeCheckBoxes";
import AppRelatedServices from "./Helpers/AppRelatedServices";
import AverageClosingPercent from "./Helpers/AverageClosingPercent";
import CustomerCollar from "./Helpers/CustomerCollar";
import CustomerEmploymentCheckbox from "./Helpers/CustomerEmploymentCheckbox";
import GenderCustomerBase from "./Helpers/GenderCustomerBase";
import HandleDepletedAccount from "./Helpers/HandleDepletedAccount";
import HighestDegreeCheckBox from "./Helpers/HighestDegreeCheckBox";
import LeadPreferences from "./Helpers/LeadPreferences";
import LocationAutocomplete from "./Helpers/LocationAutocomplete";
import MaritalStatusCheckbox from "./Helpers/MaritalStatusCheckbox";
import MultipleFiles from "./Helpers/MultipleFiles";
import ProductServiceInform from "./Helpers/ProductServiceInform";
import SeasonabilityProductService from "./Helpers/SeasonabilityProductService";
import TargetCustomerIncome from "./Helpers/TargetCustomerIncome";
import TrackingNumberDisplay from "./Helpers/TrackingNumberDisplay";
import LocationAutoCompleteField from "./LocationAutoCompleteField";
import Menu from "./Menu";
import { brandDescValidator } from "./Validators/brandDescValidator";
import { emailValidator } from "./Validators/emailValidator";
import { serviceDescValidator } from "./Validators/serviceDescValidator";
import { specialCharValidator } from "./Validators/specialCharValidator";
import { urlValidator } from "./Validators/urlValidator";
import { crmQuestions, mappedQuestion, sourcesOfLeads } from "./mappedQuestion";
import { permissionAccess, permissions } from "../../../utils/roles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const completedFlag = {
  [10]: "personalInfo",
  [16]: "platformInfo",
  [20]: "growthGoal",
};

const QuestionaireContainer = (props) => {
  console.log("################# in question container");
  const StyledCheckbox = withStyles((theme) => ({
    root: {
      color: "#CCCCCC",
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        background: "#FFFFFF",
        border: "1px solid #CCCCCC",
      },
      "&:not($checked) .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "white",
        zIndex: -1,
        borderRadius: 12,
      },
    },
  }))(Checkbox);

  const [loader, setLoader] = useState(false);
  const crmChekedBox = useSelector(
    (state) => state.client.onboardingInfo.platformInfo.crm
  );
  const platforms = useSelector(
    (state) => state.client.onboardingInfo.platformInfo.platforms
  );
  const clientView = useSelector((state) => state.amCurrentComp.clientView);
  const leadsCheckedBox = useSelector(
    (state) => state.client.onboardingInfo.platformInfo.leadSource
  );
  const competitors = useSelector(
    (state) => state.client.onboardingInfo.platformInfo.competitors
  );
  const companyInfo = useSelector((state) => state.client.companyInfo);
  let mapNames = {
    Google: "googleAdsManaged",
    Facebook: "fbAdsManaged",
    CRO: "implementCro",
  };

  const currentCompoClient = useSelector(
    (state) => state.clientCurrentComp.currentComp
  );
  const clientCurrentCompoToRender = useSelector(
    (state) => state.clientCurrentComp.currentComp
  );
  const visitedLastTime = useSelector(
    (state) => state.clientCurrentComp.visitedLastTime
  );
  const currentLeftSidebar = useSelector(
    (state) => state.clientCurrentComp.currentLeftSidebar
  );
  const currentHeaderTab = useSelector(
    (state) => state.clientCurrentComp.currentHeaderTab
  );
  const permissionsForAccess = useSelector((state) => state.auth.permissions);
  const form = useSelector((store) => store.client);
  // const widthMoved = (questionIndex * 100) / totalNumberOfQuestions;
  const [leads, setLeads] = useState({});
  const [error, setError] = useState();
  const [objValue, setObjectValue] = useState("");
  const [file, setFile] = useState("");
  const [menuVisible, setMenuVisible] = useState(false);
  const [widthMoved, setWidthMoved] = useState(0);
  const [selectedCountryTags, setSelectedCountryTags] = useState(
    form?.intakeInfo?.preferences?.customerBaseCountries
  );
  // const [bgAnimate, setBgAnimate] = useState(false);
  // const [slideIn, setSlideIn] = useState(true);
  const dispatch = useDispatch();
  const [otherOptionEnabledLeads, setOtherOptionEnabledLeads] = useState(null);
  const [otherOptionEnabledcrm, setOtherOptionEnabledcrm] = useState(null);
  const infoTypeMapping = {
    [10]: "personalInfo",
    [17]: "platformInfo",
    [18]: "growthGoal",
  };
  // useEffect(() => {
  //   if (props.value) {
  //     setError(!error);
  //   }
  // }, []);
  const platformSelected = useSelector(
    (state) => state?.client?.onboardingInfo?.platformInfo?.platforms
  );
  const skipComponentsNext = () => {
    let isSkip = false;
    if (
      props.objKey === "handleDepletedAccountBalance" &&
      platformSelected?.findIndex(
        (platform) => platform.platform === "google-ads"
      ) === -1
    ) {
      //skip google targeting components
      dispatch(
        goToSpecificCompoClient(
          LDcomponentWithIndexClient[
            clientDashboardIds.QUEST_INTAKE_FORM_BRANDING1
          ]
        )
      );
      isSkip = true;
    } else if (
      props.objKey === "testimonial3" &&
      platformSelected?.findIndex(
        (platform) => platform.platform === "facebook-ads"
      ) === -1
    ) {
      //skip facebook targeting components
      dispatch(
        goToSpecificCompoClient(
          LDcomponentWithIndexClient[
            clientDashboardIds.EDIT_RESPONSES_INTAKE_FORM
          ]
        )
      );
      isSkip = true;
    }
    return isSkip;
  };

  const skipComponentsBack = () => {
    let isSkip = false;
    if (
      props.objKey === "brandingStyleGuide" &&
      platformSelected?.findIndex(
        (platform) => platform.platform === "google-ads"
      ) === -1
    ) {
      //skip google targeting components
      dispatch(
        goToSpecificCompoClient(
          LDcomponentWithIndexClient[
            clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES1
          ]
        )
      );
      isSkip = true;
    }
    return isSkip;
  };
  const saveToDb = () => {
    // debugger;
    // setLoader(true);
    if (props?.infoType === "growthGoal") {
      setLoader(true);
      dispatch(
        updateFormReducer({
          onboardingInfo: {
            growthGoal: {
              status: BACKEND_STATUS.COMPLETED,
              goals: props.value,
              notes: "",
            },
            status: BACKEND_STATUS.ONGOING,
          },
          callback(error, _data) {
            if (error) return;
            setLoader(false);
            props?.onNextClickHandler();
          },
        })
      );
      // props?.onNextClickHandler();
      return;
    } else {
      dispatch(updateClientDetails());
      props?.onNextClickHandler();
      return;
    }
  };
  useEffect(() => {
    setWidthMoved((props.quesNumber * 100) / props.totalQuestions);
  }, [props.quesNumber, props.totalQuestions]);
  useEffect(() => {
    ["companyLogo", "companyClientLogo"].includes(props.objKey) &&
      dispatch({
        type: "GET_SINGLE_FILE_VALUE",
        payload: {
          outerKey: props.outerKey,
          infoType: props.infoType,
          key: props.objKey,
          value: file,
        },
      });
    [
      "formalCustomerPersona",
      "brandingStyleGuide",
      "brandingMaterials",
      "productImages",
      "testimonial1",
      "testimonial2",
      "testimonial3",
      "awards",
    ].includes(props.objKey) &&
      dispatch({
        type: "GET_MULTIPLE_FILE_VALUE",
        payload: {
          outerKey: props.outerKey,
          infoType: props.infoType,
          key: props.objKey,
          value: file,
        },
      });
  }, [file]);

  useEffect(() => {
    setObjectValue("");
    const isFile = ["companyLogo", "companyClientLogo"].includes(props.objKey);
    const { value } = props;
    if (isFile) {
      if (Array.isArray(value)) {
        value.length !== 0 &&
          value[0].length !== 0 &&
          getImageUrl(value[0]).then((res) => {
            setObjectValue(URL.createObjectURL(res));
          });
      } else if (typeof value === "string") {
        value.length !== 0 &&
          getImageUrl(value).then((res) => {
            setObjectValue(URL.createObjectURL(res));
          });
      }
    }
    if (props.objKey === "competitorsIntake") {
      if (!props.value[0].name) {
        dispatch({
          type: "UPDATE_INTAKE_COMPETITORS",
          payload: {
            value: competitors,
          },
        });
      }
    }
  }, [props.objKey]);

  useEffect(() => {
    const isMultipleFile = [
      "formalCustomerPersona",
      "brandingStyleGuide",
      "brandingMaterials",
      "productImages",
      "testimonial1",
      "testimonial2",
      "testimonial3",
      "awards",
    ].includes(props.objKey);
    if (isMultipleFile) {
      const { value } = props;
      (async () => {
        const responses = await Promise.allSettled(
          value.map((val) => getImageUrl(val))
        );
        const blobs = [];
        responses.forEach((response) => {
          if (response.status === "fulfilled") {
            blobs.push(URL.createObjectURL(response.value));
          }
        });
        setObjectValue(blobs);
      })();
    }
  }, [props.value]);

  const getCheckBoxValue = (valueTemp, question) => {
    if (!valueTemp.includes("Other")) {
      return "";
    }
    let otherValIndex = null;
    const mappedObj = {};
    valueTemp.forEach((key, index) => {
      mappedObj[key] = {
        flag: false,
        ["index"]: index,
      };
    });
    Object.entries(mappedObj).map(([key, value], index) => {
      if (!question.includes(key)) {
        otherValIndex = value.index;
      }
    });
    return valueTemp[otherValIndex];
  };

  const onClickHandler = () => {
    // debugger;
    if (
      ![
        "companyWebsite",
        "primaryContactName",
        "primaryContactRole",
        "primaryContactNumber",
        "avgClosingPercent",
        "sessionality",
        "appRelatedService",
        "monthlyAdSpend",
        "leadPreferences",
        "trackingNumberDisplay",
        "handleDepletedAccountBalance",
        "ageRange",
        "gender",
        "advertiseLocation",
        "customerCollar",
        "customerIncomeRange",
        "customerEducation",
        "customerEmployment",
        "descriptions",
        "brandDescription",
        "primaryContactEmail",
        "company",
        "customerBaseCountries",
        "crm",
      ].includes(props.objKey)
    ) {
      if (
        ["companyEmail", "leadEmailAddress"].includes(props.objKey) &&
        props.value !== "" &&
        !emailValidator(props.value)
      ) {
        setError(true);
      } else if (
        ["competitors", "competitorsIntake"].includes(props.objKey) &&
        props.value?.some(
          (value) => !urlValidator(value?.website) && value?.website.length >= 0
        )
      ) {
        setError(true);
        return;
      } else if (
        ["competitors", "competitorsIntake"].includes(props.objKey) &&
        !props.value?.every((value) => value?.name)
      ) {
        setError(true);
        return;
      } else if (props.objKey === "platforms" && !props.value.length) {
        setError(true);
        return;
      } else {
        setError(false);
        saveToDb();
        // props?.onNextClickHandler();
      }
    } else {
      // debugger;
      if (props.objKey === "ageRange") {
        if (props.minAgeValue && props.maxAgeValue) {
          saveToDb();
          setError(false);
          // props?.onNextClickHandler();
        } else {
          setError(true);
        }
      } else if (
        props.value === "" ||
        props.value === "0" ||
        typeof props.value === "object" ||
        !urlValidator(props?.value)
      ) {
        if (
          props.value[props.value?.length - 1]?.name &&
          props.value?.every(
            (value) =>
              !serviceDescValidator(value?.description) &&
              !specialCharValidator(value?.name) &&
              value?.description?.length > 0
          )
        ) {
          saveToDb();
          setError(false);
          // props?.onNextClickHandler();
        } else if (
          props.objKey === "primaryContactEmail" &&
          props.value === ""
        ) {
          setError(true);
          return;
        } else if (
          props.objKey === "primaryContactEmail" &&
          props.value !== "" &&
          !emailValidator(props.value)
        ) {
          setError(true);
          return;
        } else if (
          props.objKey === "customerBaseCountries" &&
          selectedCountryTags.length === 0
        ) {
          setError(true);
          return;
        } else if (props.objKey === "company" && props.value?.length <= 2) {
          setError(true);
          return;
        } else if (
          props.value[props.value?.length - 1]?.name &&
          props.value?.some((value) => value?.description?.length === 0) &&
          props.objKey === "descriptions"
        ) {
          setError(true);
          return;
        } else if (
          props.value[props.value?.length - 1]?.name &&
          props.value?.every((value) => urlValidator(value?.website))
        ) {
          saveToDb();
          setError(false);
          // props?.onNextClickHandler();
        } else if (
          props.value?.length > 1 &&
          [
            "customerIncomeRange",
            "customerEducation",
            "customerEmployment",
            "crm",
          ].includes(props.objKey)
        ) {
          saveToDb();
          setError(false);
          // props?.onNextClickHandler();
        } else if (
          typeof props.value === "object" &&
          props.value?.length > 1 &&
          !["competitors", "competitorsIntake", "descriptions"].includes(
            props.objKey
          )
        ) {
          saveToDb();
          setError(false);
          // props?.onNextClickHandler();
        } else if (
          props.objKey === "companyWebsite" &&
          !urlValidator(props.value)
        ) {
          setError(true);
        } else if (
          props.value !== "" &&
          ![
            "competitors",
            "competitorsIntake",
            "descriptions",
            "brandDescription",
            "customerIncomeRange",
            "customerEducation",
            "customerEmployment",
            "crm",
          ].includes(props.objKey)
        ) {
          saveToDb();
          setError(false);
          // props?.onNextClickHandler();
        } else if (
          props.value !== "" &&
          props.objKey === "brandDescription" &&
          brandDescValidator(props.value)
        ) {
          saveToDb();
          setError(false);
          // props?.onNextClickHandler();
        } else {
          setError(true);
        }
      } else {
        saveToDb();
        setError(false);
        // props?.onNextClickHandler();
      }
    }
  };
  const hitEnter = (e) => {
    if (e.key === "Enter") {
      onClickHandler();
    }
  };
  const platformsObj = {
    "google-ads": {
      imgIcon: googleIcon,
      platform: "google-ads",
      isSelected: false,
      iconName: "Google",
    },
    "facebook-ads": {
      imgIcon: facebookIcon,
      platform: "facebook-ads",
      isSelected: false,
      iconName: "Facebook",
    },
  };
  useEffect(() => {
    if (props.objKey === "Google-Facebook-CRO") {
      dispatch({
        type: "UPDATE_ACCOUNTS_SELECTED",
        payload: {
          key: mapNames["Google"],
          infoType: "companyInfo",
          value:
            platforms?.findIndex(
              (platform) => platform.platform === "google-ads"
            ) === -1
              ? false
              : true,
        },
      });
      dispatch({
        type: "UPDATE_ACCOUNTS_SELECTED",
        payload: {
          key: mapNames["Facebook"],
          infoType: "companyInfo",
          value:
            platforms?.findIndex(
              (platform) => platform.platform === "facebook-ads"
            ) === -1
              ? false
              : true,
        },
      });
    }
  }, [props.objKey]);
  const inputComponent = (objType, type, value, outerKey) => {
    switch (objType) {
      case "companyWebsite":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            error={error}
            errorText={"Please enter a valid website"}
            onKeyPress={hitEnter}
          />
        );
      case "primaryContactName":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            error={error}
            errorText={"Please enter a valid text"}
            onKeyPress={hitEnter}
          />
        );
      case "primaryContactRole":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            errorText={"Please enter a valid text"}
            onKeyPress={hitEnter}
          />
        );
      case "primaryContactNumber":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            type="number"
            error={error ? true : false}
            errorText={"Please enter a valid phone number"}
            onKeyPress={hitEnter}
          />
        );
      case "company":
        return (
          <>
            <InputTextField
              width="600px"
              objKey={objType}
              infoType={type}
              value={value}
              onKeyPress={hitEnter}
            />
            {error && (
              <p
                style={{
                  color: "red",
                }}
              >
                Company name should have more than 2 characters
              </p>
            )}
          </>
        );
      case "profitMargin":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            onKeyPress={hitEnter}
          />
        );
      case "companyEmail":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            error={error}
            errorText={"Please enter a valid email"}
            onKeyPress={hitEnter}
          />
        );
      case "companyAddress":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            onKeyPress={hitEnter}
          />
        );
      case "companyMailingAddress":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            onKeyPress={hitEnter}
          />
        );
      case "companyPhone":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            type="number"
            onKeyPress={hitEnter}
          />
        );
      case "primaryContactEmail":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            error={error}
            errorText={"Please enter a valid email"}
            onKeyPress={hitEnter}
          />
        );
      case "targetCpa":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            type="number"
            onKeyPress={hitEnter}
          />
        );
      case "brandDescription":
        return (
          <>
            <InputTextField
              width="600px"
              objKey={objType}
              infoType={type}
              value={value}
              multiline={true}
              error={!brandDescValidator(value) && error && value !== ""}
              errorText={"Min character limit : 90"}
              onKeyPress={hitEnter}
            />
            {value === "" && error && (
              <p
                style={{
                  color: "red",
                }}
              >
                Please enter the empty field
              </p>
            )}
          </>
        );
      case "leadEmailAddress":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            errorText={"Please enter valid email"}
            onKeyPress={hitEnter}
          />
        );
      case "monthlyAdSpend":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            errorText={"Please enter a valid value"}
            type="number"
            onKeyPress={hitEnter}
          />
        );
      case "activitiesInterest":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            onKeyPress={hitEnter}
          />
        );
      case "excludeAudiences":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            onKeyPress={hitEnter}
          />
        );
      case "averageTicketPrice":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            type="number"
            errorText={"Please enter a valid value"}
            onKeyPress={hitEnter}
          />
        );
      case "companyClientWebsite":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value}
            error={error ? true : false}
            errorText={"Please enter valid url"}
            onKeyPress={hitEnter}
          />
        );
      case "cltv":
        return (
          <InputTextField
            width="600px"
            objKey={objType}
            infoType={type}
            value={value || ""}
            error={error ? true : false}
            type="number"
            errorText={"Please enter valid value"}
            onKeyPress={hitEnter}
          />
        );
      case "industry":
      case "callTracking":
        return (
          <FormControl variant="standard">
            <Select
              inputProps={{ "aria-label": "Without label" }}
              style={{ width: 600 }}
              placeholder="Choose your answer here"
              value={value}
              onChange={(e) => {
                dispatch(
                  updateClientDetailsReducer(GET_PERSONAL_INFO_CLIENT, {
                    key: objType,
                    infoType: type,
                    value: e.target.value,
                  })
                );
              }}
            >
              <MenuItem disabled value="Choose your answer here">
                <em>Choose your answer here</em>
              </MenuItem>
              <MenuItem value="Healthcare">Healthcare</MenuItem>
              <MenuItem value="Apparel">Apparel</MenuItem>
              <MenuItem value="Fitness">Fitness</MenuItem>
              <MenuItem value="Consumer Service">Consumer Service</MenuItem>
              <MenuItem value="Retail">Retail</MenuItem>
              <MenuItem value="Technology">Technology</MenuItem>
              <MenuItem value="Home Improvement">Home Improvement</MenuItem>
            </Select>
          </FormControl>
        );

      case "platforms":
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "2rem",
                pointerEvents: "all",
              }}
            >
              {Object.entries(platformsObj).map(([key, platform], index) => (
                <CheckBoxOption
                  imgIcon={platform.imgIcon}
                  iconName={platform.iconName}
                  objKey={objType + " " + "platform"}
                  infoType={type}
                  platforms={platforms}
                  platform={key}
                  isActive={
                    platforms.find((platform) => platform.platform === key)
                      ? true
                      : false
                  }
                />
              ))}
            </div>
            {error && (
              <p
                style={{
                  color: "red",
                }}
              >
                Please select the platform
              </p>
            )}
          </>
        );
      case "goals":
        return (
          <InputTextField
            width="600px"
            multiline={true}
            objKey={objType}
            infoType={type}
            value={value}
            onKeyPress={hitEnter}
          />
        );
      case "competitorsIntake":
        return (
          <>
            {console.log(value)}
            {value?.map((d, index) => (
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  marginBottom: "1.5rem",
                  position: "relative",
                }}
                key={index}
              >
                <CompetitorTextField
                  width="300px"
                  placeholder="Enter competitor name ..."
                  objKey={"competitors" + " " + index + " name"}
                  infoType={"preferences"}
                  value={d.name}
                />
                <CompetitorTextField
                  width="300px"
                  placeholder="Enter website ..."
                  objKey={"competitors" + " " + index + " website"}
                  infoType={"preferences"}
                  value={d.website}
                  error={!urlValidator(d.website) && d.website.length !== 0}
                  errorText={"Please enter a valid website"}
                  quesType="competitor"
                />
                {index > 0 && (
                  <img
                    src={trashIcon}
                    alt="delete"
                    onClick={() =>
                      dispatch({
                        type: "DELETE_INTAKE_COMPETITOR_FIELD",
                        payload: index,
                      })
                    }
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            ))}
            <div
              style={{
                background: "#E3EEFF",
                borderRadius: 6,
                padding: "3px 9px",
                display: "inline-block",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 11,
                  color:
                    value[value.length - 1].name &&
                    urlValidator(value[value.length - 1].website)
                      ? "#0869FB"
                      : "gray",
                  cursor:
                    value[value.length - 1].name &&
                    urlValidator(value[value.length - 1].website)
                      ? "pointer"
                      : "not-allowed",
                }}
                onClick={() =>
                  value[value.length - 1].name &&
                  urlValidator(value[value.length - 1].website)
                    ? dispatch({ type: "ADD_NEW_FIELD_IN_INTAKE_COMPETITORS" })
                    : ""
                }
              >
                + ADD FIELD
              </Typography>
            </div>
            {error && value?.some((value) => value?.website.length === 0) && (
              <p
                style={{
                  color: "red",
                }}
              >
                Please enter the mandatory field
              </p>
            )}
            {error && !value?.every((value) => value?.name) && (
              <p
                style={{
                  color: "red",
                }}
              >
                Please enter the mandatory field
              </p>
            )}
          </>
        );
      case "competitors":
        return (
          <>
            {value.map((d, index) => (
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  marginBottom: "1.5rem",
                  position: "relative",
                }}
                key={index}
              >
                <CompetitorTextField
                  width="300px"
                  placeholder="Enter competitor name ..."
                  objKey={"competitors" + " " + index + " name"}
                  infoType={"platformInfo"}
                  value={d.name}
                />
                <CompetitorTextField
                  width="300px"
                  placeholder="Enter website ..."
                  objKey={"competitors" + " " + index + " website"}
                  infoType={"platformInfo"}
                  value={d.website}
                  error={!urlValidator(d.website) && d.website.length !== 0}
                  errorText={"Please enter a valid website"}
                  quesType="competitor"
                  onKeyPress={hitEnter}
                />
                {index > 0 && (
                  <img
                    src={trashIcon}
                    alt="delete"
                    onClick={() =>
                      dispatch({
                        type: "DELETE_COMPETITTOR_FIELD",
                        payload: index,
                      })
                    }
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
            ))}
            <div
              style={{
                background: "#E3EEFF",
                borderRadius: 6,
                padding: "3px 9px",
                display: "inline-block",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 11,
                  color:
                    value[value.length - 1].name &&
                    urlValidator(value[value.length - 1].website)
                      ? "#0869FB"
                      : "gray",
                  cursor:
                    value[value.length - 1].name &&
                    urlValidator(value[value.length - 1].website)
                      ? "pointer"
                      : "not-allowed",
                }}
                onClick={() =>
                  value[value.length - 1].name &&
                  urlValidator(value[value.length - 1].website)
                    ? dispatch({ type: ADD_NEW_FIELD_IN_PLATFORM_COMPETITORS })
                    : ""
                }
              >
                + ADD FIELD
              </Typography>
            </div>
            {error && value?.some((value) => value?.website.length === 0) && (
              <p
                style={{
                  color: "red",
                }}
              >
                Please enter the mandatory field
              </p>
            )}
            {error && !value?.every((value) => value?.name) && (
              <p
                style={{
                  color: "red",
                }}
              >
                Please enter the mandatory field
              </p>
            )}
          </>
        );
      case "Google-Facebook-CRO":
        return (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "1rem",
              marginTop: "1rem",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {objType.split("-").map((str, index, questArr) => (
              <div
                style={{
                  background: "#FFFFFF",
                  border: "1px solid #DBDBDB",
                  boxShadow: "0px 15px 53px rgba(30, 20, 165, 0.03)",
                  borderRadius: 12,
                  display: "flex",
                  alignItems: "center",
                  width: 220,
                  flexWrap: "wrap",
                }}
                key={index}
              >
                <StyledCheckbox
                  value={str}
                  checked={companyInfo[mapNames[str]]}
                  disabled={true}
                  onClick={(e) => {
                    dispatch({
                      type: "UPDATE_ACCOUNTS_SELECTED",
                      payload: {
                        key: mapNames[str],
                        infoType: "companyInfo",
                        value: e.target.checked,
                      },
                    });
                  }}
                  size="small"
                />{" "}
                <Typography
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: 16,
                    display: "inline",
                    color: "#7F7D87",
                  }}
                >
                  {str}
                </Typography>
              </div>
            ))}
          </div>
        );
      case "leadSource":
        return (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "1rem",
              marginTop: "1rem",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {sourcesOfLeads.map((quest, index) => (
              <div
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 16,
                  color: "#7F7D87",
                  background: "#FFFFFF",
                  border: "1px solid #DBDBDB",
                  boxShadow: "0px 15px 53px rgba(30, 20, 165, 0.03)",
                  borderRadius: 8,
                  width: 300,
                  display: "flex",
                  alignItems: "center",
                  padding: "0.25rem 0.25rem",
                }}
                key={index}
              >
                <StyledCheckbox
                  value={quest}
                  checked={
                    leadsCheckedBox.find((q) => q === quest) ? true : false
                  }
                  onClick={(e) => {
                    dispatch({
                      type: "UPDATE_MULTIPLE_CHECKBOX",
                      payload: {
                        key: objType,
                        infoType: type,
                        value: e.target.value,
                        quest: sourcesOfLeads,
                      },
                    });
                  }}
                />{" "}
                <Typography
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: 16,
                    display: "inline",
                    color: "#7F7D87",
                  }}
                >
                  {quest}
                </Typography>
              </div>
            ))}
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField2
                width="410px"
                objType={objType}
                value={getCheckBoxValue(value, sourcesOfLeads)}
                infoType={type}
                reduxType="UPDATE_MULTIPLE_CHECKBOX"
                isDisabled={value.includes("Other") ? false : true}
                quest={sourcesOfLeads}
              />
            </div>
          </div>
        );
      case "crm":
        return (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              marginBottom: "1rem",
              marginTop: "1rem",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {crmQuestions.map((quest, index) => (
              <div
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 16,
                  color: "#7F7D87",
                  background: "#FFFFFF",
                  border: "1px solid #DBDBDB",
                  boxShadow: "0px 15px 53px rgba(30, 20, 165, 0.03)",
                  borderRadius: 8,
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                  padding: "0.25rem 0.25rem",
                }}
                key={index}
              >
                <StyledCheckbox
                  value={quest}
                  checked={crmChekedBox.find((q) => q === quest) ? true : false}
                  onClick={(e) => {
                    dispatch({
                      type: "UPDATE_MULTIPLE_CHECKBOX",
                      payload: {
                        key: objType,
                        infoType: type,
                        value: e.target.value,
                        quest: crmQuestions,
                      },
                    });
                  }}
                  size="small"
                />{" "}
                <Typography
                  style={{
                    fontFamily: "Lato",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: 16,
                    display: "inline",
                    color: "#7F7D87",
                  }}
                >
                  {quest}
                </Typography>
              </div>
            ))}
            <div display={{ display: "flex" }}>
              <TextField2
                width="410px"
                objType={objType}
                value={getCheckBoxValue(value, crmQuestions)}
                infoType={type}
                reduxType="UPDATE_MULTIPLE_CHECKBOX"
                isDisabled={value.includes("Other") ? false : true}
                quest={crmQuestions}
              />
            </div>
            {error && <p style={{ color: "red" }}>Please enter the fields</p>}
          </div>
        );
      case "geoTargets":
        return (
          <LocationAutoCompleteField
            value={value}
            type={type}
            objType={objType}
            placeholder={"Enter Location ..."}
          />
        );
      case "customerBaseCountries":
        return (
          <>
            <LocationAutocomplete
              selectedTags={selectedCountryTags}
              setSelectedTags={setSelectedCountryTags}
              placeholder={"Enter Location ..."}
            />
            {error && (
              <p
                style={{
                  color: "red",
                }}
              >
                Please select the country
              </p>
            )}
          </>
        );
      case "city":
      case "avgClosingPercent":
        return (
          <AverageClosingPercent
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid option"}
          />
        );
      case "sessionality":
        return (
          <SeasonabilityProductService
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid option"}
          />
        );
      case "appRelatedService":
        return (
          <AppRelatedServices
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid option"}
          />
        );
      case "leadPreferences":
        return (
          <LeadPreferences
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid option"}
          />
        );
      case "trackingNumberDisplay":
        return (
          <TrackingNumberDisplay
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid option"}
          />
        );
      case "handleDepletedAccountBalance":
        return (
          <HandleDepletedAccount
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid option"}
          />
        );
      case "gender":
        return (
          <GenderCustomerBase
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid option"}
          />
        );
      case "advertiseLocation":
        return (
          <AdvertiseLocation
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid option"}
          />
        );
      case "customerCollar":
        return (
          <CustomerCollar
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid option"}
          />
        );
      case "ageRange":
        return (
          <AgeRangeCheckboxes
            value={value}
            objKey={"ageRanges"}
            infoType={type}
            error={error}
            errorText={"Please select the valid age range"}
            outerKey={outerKey}
          />
        );
      case "maritalStatus":
        return (
          <MaritalStatusCheckbox
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
          />
        );
      case "descriptions":
        return (
          <ProductServiceInform
            value={value}
            type={type}
            objType={objType}
            error={error}
          />
        );
      case "customerIncomeRange":
        return (
          <TargetCustomerIncome
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid options"}
          />
        );
      case "customerEducation":
        return (
          <HighestDegreeCheckBox
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid options"}
          />
        );
      case "customerEmployment":
        return (
          <CustomerEmploymentCheckbox
            value={value}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            error={error}
            errorText={"Please select the valid options"}
          />
        );
      case "brandServiceKeywords":
        return (
          <AddKeywords
            value={value}
            type={type}
            objType={objType}
            hitEnter={hitEnter}
          />
        );
      case "companyLogo":
      case "companyClientLogo":
        return objValue ? (
          <>
            <div
              style={{ width: "40%", height: "150px", position: "relative" }}
            >
              <img
                src={objValue}
                style={{
                  width: "inherit",
                  objectFit: "contain",
                }}
                alt=""
              />
              <div
                style={{
                  background: "white",
                  position: "absolute",
                  left: "-0.5rem",
                  top: "-0.5rem",
                  borderRadius: "50%",
                  width: 20,
                  height: 20,
                }}
              >
                <Close
                  color="warning"
                  fontSize="small"
                  onClick={() => setObjectValue("")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <StyledDrop setObj={setObjectValue} setFile={setFile} />
          </div>
        );
      case "brandingStyleGuide":
      case "productImages":
      case "brandingMaterials":
      case "formalCustomerPersona":
      case "testimonial1":
      case "awards":
      case "testimonial2":
      case "testimonial3":
        return (
          <MultipleFiles
            objVal={objValue}
            setObj={setObjectValue}
            setFile={setFile}
            objKey={objType}
            infoType={type}
            outerKey={outerKey}
            value={value}
          />
        );
      default:
        <></>;
    }
  };
  return (
    <div
      className={`slide_question ${props.bgAnimate ? "animate" : ""} question-${
        mappedQuestion[props.infoType][props.objKey]["questNumber"] % 2 == 0
          ? "even"
          : "odd"
      }`}
      style={{
        width: "100%",
        borderRadius: "8px",
        position: "relative",
        overflow: "hidden",
        backgroundColor: "#fff",
        backgroundImage: "url(" + backgroundImage2 + ")",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: 0,
          left: 0,
          width: `${widthMoved}%`,
          height: 6,
          background: "#5498FF",
          borderRadius: 30,
          transition: "0.3s ease",
        }}
      ></div>
      <Slide
        direction="up"
        in={props.slideIn}
        // container={containerRef.current}
        easing={{
          enter: "linear",
          exit: "linear",
        }}
        timeout={{
          enter: 500,
          exit: 0,
        }}
        onEntering={(node, done) => {
          props.setBgAnimate(true);
        }}
        onEntered={(node, done) => {
          // setBgAnimate(false)
        }}
        onExited={(node) => {
          props.setBgAnimate(false);
        }}
      >
        <div
          style={{
            width: "100%",
            height: clientView ? "86vh" : "80vh",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1",
            overflow: "hidden",
          }}
        >
          <div className="question-container">
            <div style={{ position: "relative" }}>
              {mappedQuestion[props.infoType][props.objKey]["icon"] && (
                <img
                  src={mappedQuestion[props.infoType][props.objKey]["icon"]}
                  alt=""
                  style={{
                    position: "absolute",
                    bottom: "-1.25rem",
                    left: "-0.5rem",
                  }}
                />
              )}
            </div>
            <div className="question">
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontstyle: "normal",
                  fontWeight: 400,
                  fontSize: 12,
                  color: "#797979",
                  marginBottom: "0.5rem",
                }}
              >
                QUESTION{" "}
                {mappedQuestion[props.infoType][props.objKey]["questNumber"]}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 22,
                  marginBottom: "0.5rem",
                }}
              >
                {mappedQuestion[props.infoType][props.objKey]["questName"]}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#6C6C6C",
                  marginBottom: "1.5rem",
                }}
              >
                {mappedQuestion[props.infoType][props.objKey]["questDescrip"]}
              </Typography>
              {inputComponent(
                props.objKey,
                props.infoType,
                props.value,
                props.outerKey
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <PrimaryButton
                title={props.infoType === "growthGoal" ? "Finish" : "Next"}
                type="corner"
                onClick={onClickHandler}
              />
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: 200,
                  fontSize: 12,
                  alignSelf: "center",
                }}
                onClick={() => onClickHandler()}
              >
                Hit ENTER
              </Typography>
            </div>
          </div>
        </div>
      </Slide>

      {menuVisible && (
        <Menu
          questions={Object.values(mappedQuestion[props.infoType])}
          currentCompoClient={currentCompoClient}
          visitedLastTime={visitedLastTime}
          isAM={props.isAM}
        />
      )}
      <div className="question-nav-container">
        <div
          className="questionNavIconStyle"
          onClick={() => {
            props?.onPreviewScreenClickHandler();
          }}
        >
          <img src={PreviewIcon} alt="" />
        </div>
        <div
          className="questionNavIconStyle"
          onClick={() => setMenuVisible(!menuVisible)}
        >
          <img src={menuIcon} alt="" />
        </div>
        <div
          className="questionNavIconStyle"
          onClick={() => {
            const isMandatoryField = [
              "companyWebsite",
              "primaryContactName",
              "primaryContactRole",
              "primaryContactNumber",
              "primaryContactName",
              "primaryContactRole",
              "primaryContactNumber",
              "avgClosingPercent",
              "sessionality",
              "appRelatedService",
              "monthlyAdSpend",
              "leadPreferences",
              "trackingNumberDisplay",
              "handleDepletedAccountBalance",
              "ageRange",
              "gender",
              "advertiseLocation",
              "customerCollar",
              "customerIncomeRange",
              "customerEducation",
              "customerEmployment",
              "descriptions",
              "brandDescription",
              "primaryContactEmail",
              "company",
            ].includes(props.objKey);
            if (!isMandatoryField) {
              if (
                ["companyEmail", "leadEmailAddress"].includes(props.objKey) &&
                props.value !== "" &&
                !emailValidator(props.value)
              ) {
                setError(true);
                return;
              } else if (
                ["competitors", "competitorsIntake"].includes(props.objKey) &&
                props.value?.some(
                  (value) =>
                    !urlValidator(value?.website) && value?.website.length === 0
                )
              ) {
                setError(true);
                return;
              } else if (
                ["competitors", "competitorsIntake"].includes(props.objKey) &&
                !props.value?.every((value) => value?.name)
              ) {
                setError(true);
                return;
              } else if (props.objKey === "platforms" && !props.value.length) {
                setError(true);
                return;
              } else {
                setError(false);
                props?.onNextClickHandler(true);
                dispatch(updateClientDetails());
                if (
                  currentCompoClient ===
                    LDcomponentWithIndexClient[
                      clientDashboardIds.GROWTH_GOALS_QUEST1
                    ] &&
                  permissionsForAccess.includes(
                    permissions.REVIEW_INTAKE_FORM.concat(
                      permissionAccess.VIEW
                    ) ||
                      permissions.REVIEW_INTAKE_FORM.concat(
                        permissionAccess.EXECUTE
                      )
                  )
                ) {
                  dispatch(closeClientView());
                } else {
                  !skipComponentsNext() && dispatch(updateCompoReduxClient());
                }
                return;
              }
            } else {
              if (
                props.value === "" ||
                props.value === "0" ||
                typeof props.value === "object" ||
                !urlValidator(props?.value)
              ) {
                if (props.objKey === "ageRange") {
                  if (props.minAgeValue && props.maxAgeValue) {
                    saveToDb();
                    setError(false);
                    props?.onNextClickHandler(true);
                  } else {
                    setError(true);
                  }
                } else if (
                  props.value[props.value?.length - 1]?.name &&
                  props.value?.every(
                    (value) =>
                      !serviceDescValidator(value?.description) &&
                      !specialCharValidator(value?.name) &&
                      value?.description?.length > 0
                  )
                ) {
                  saveToDb();
                  setError(false);
                  props?.onNextClickHandler(true);
                } else if (
                  props.objKey === "primaryContactEmail" &&
                  props.value === ""
                ) {
                  setError(true);
                  return;
                } else if (
                  props.objKey === "primaryContactEmail" &&
                  props.value !== "" &&
                  !emailValidator(props.value)
                ) {
                  setError(true);
                  return;
                } else if (
                  props.objKey === "company" &&
                  props.value?.length <= 2
                ) {
                  setError(true);
                  return;
                } else if (
                  props.value[props.value?.length - 1]?.name &&
                  props.value?.some(
                    (value) => value?.description?.length === 0
                  ) &&
                  props.objKey === "descriptions"
                ) {
                  setError(true);
                  return;
                } else if (
                  props.objKey !== "descriptions" &&
                  props.value[props.value?.length - 1]?.name &&
                  props.value?.every((value) => urlValidator(value?.website))
                ) {
                  saveToDb();

                  setError(false);
                  props?.onNextClickHandler(true);
                } else if (
                  props.value?.length > 1 &&
                  [
                    "customerIncomeRange",
                    "customerEducation",
                    "customerEmployment",
                    "crm",
                  ].includes(props.objKey)
                ) {
                  saveToDb();

                  setError(false);
                  props?.onNextClickHandler(true);
                } else if (
                  typeof props.value === "object" &&
                  props.value?.length > 1 &&
                  ![
                    "competitors",
                    "competitorsIntake",
                    "descriptions",
                  ].includes(props.objKey)
                ) {
                  saveToDb();

                  setError(false);
                  props?.onNextClickHandler(true);
                } else if (
                  props.objKey === "companyWebsite" &&
                  !urlValidator(props.value)
                ) {
                  setError(true);
                } else if (
                  props.value !== "" &&
                  ![
                    "competitors",
                    "competitorsIntake",
                    "descriptions",
                    "brandDescription",
                    "customerIncomeRange",
                    "customerEducation",
                    "customerEmployment",
                  ].includes(props.objKey)
                ) {
                  saveToDb();

                  setError(false);
                  props?.onNextClickHandler(true);
                } else if (
                  props.value !== "" &&
                  props.objKey === "brandDescription" &&
                  brandDescValidator(props.value)
                ) {
                  saveToDb();

                  setError(false);
                  props?.onNextClickHandler(true);
                } else if (
                  props.value === "" &&
                  props.objKey === "brandDescription"
                ) {
                  setError(true);
                  return;
                } else {
                  setError(true);
                }
              } else {
                saveToDb();

                setError(false);
                props?.onNextClickHandler(true);
              }
            }
          }}
        >
          <img src={arrowDown} alt="" />
        </div>
        <div
          className="questionNavIconStyle"
          onClick={() => {
            setError(false);
            props?.onUpClickHandler();
          }}
        >
          <img src={arrowUp} alt="" />
        </div>
      </div>
      {loader && <PmaxLoader />}
    </div>
  );
};
export default QuestionaireContainer;
