export const proposalStage = (proposalIterations) =>
    proposalIterations?.length !== 0
      ? proposalIterations?.[
          proposalIterations?.length - 1
        ]['state']
      : null;

// returns current strategy state
// if not exist returns a null
export const strategyStage = (strategyIterations) =>
strategyIterations?.length !== 0
  ? strategyIterations?.[
      strategyIterations?.length - 1
    ]['state']
  : null;


// returns a boolean
export const isPlatformSelectedLD = (form, platformName) => {
  const result =  form?.onboardingInfo?.platformInfo?.platforms?.findIndex(
    (platform) => platform.platform === platformName
  );

  if (result === -1) return false;
  return true
};
