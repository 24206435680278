import z, { ZodIssueCode, coerce, object, string } from 'zod';
import { BUDGET_TYPE } from './constants';

export const campaignFormSchema = object({
  name: string().min(1),
  startDate: coerce.date(),
  endDate: string().max(0).or(coerce.date().optional().nullable()), // empty string or valid date
  budgetType: z.enum(Object.values(BUDGET_TYPE)),
  lifetimeBudget: coerce.number().optional(),
  dailyBudget: coerce.number().optional(),
}).superRefine((data, ctx) => {
  if (data.budgetType === BUDGET_TYPE.DAILY && data.dailyBudget <= 0) ctx.addIssue({
    code: ZodIssueCode.custom,
    path: ["dailyBudget"],
    message: "Daily Budget should be greater than 0",
  });

  if (data.budgetType === BUDGET_TYPE.LIFETIME && data.lifetimeBudget <= 0) ctx.addIssue({
    code: ZodIssueCode.custom,
    path: ["lifetimeBudget"],
    message: "Lifetime Budget should be greater than 0",
  });

  if (data.budgetType === BUDGET_TYPE.LIFETIME && !data.endDate) ctx.addIssue({
    code: ZodIssueCode.custom,
    path: ["endDate"],
    message: "End Date is required",
  });
});

const NULL_FORM = {};
// mapping functions are kept with schema as they are tied together. these mapping functions are meant to transform api response data in and out of form
export function mapResponseToFormValue(campaignStrategy) {
  if (!campaignStrategy) return NULL_FORM;
  return {
    name: campaignStrategy.name,
    startDate: campaignStrategy.startDate,
    endDate: campaignStrategy.endDate || null,
    budgetType: campaignStrategy.budget?.type,
    lifetimeBudget: campaignStrategy.budget?.type === BUDGET_TYPE.LIFETIME ? campaignStrategy.budget.value : 0,
    dailyBudget: campaignStrategy.budget?.type === BUDGET_TYPE.DAILY ? campaignStrategy.budget.value : campaignStrategy?.dailyBudget || 0 
  };
}

// outgoing values tranformation when form is submitted
export function mapValuesToResponse(values) {
  return {
    name: values.name,
    startDate: values.startDate,
    endDate: values.endDate || "",
    budget: {
      type: values.budgetType,
      value: {
        [BUDGET_TYPE.DAILY]: values.dailyBudget,
        [BUDGET_TYPE.LIFETIME]: values.lifetimeBudget,
      }[values.budgetType],
    },
  };
}
