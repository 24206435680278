import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AGENCY_TYPES, PLATFORM_ADS } from "../../utils/commonEnums";

const GoogleAuthRedirect = ({ agencyType }) => {
  const navigate = useNavigate();
  useEffect(() => {
    const querySearchParams = new URLSearchParams(
      window.location.href.split("?")[1]
    );
    if (querySearchParams) {
      const payload = JSON.parse(
        decodeURI(querySearchParams.get("state")).replace("%26", "&")
      );
      // console.log("payload : ", decodeURI(querySearchParams.get("state")));
      const error = querySearchParams.get("error");
      if (payload && !error) {
        if (agencyType === AGENCY_TYPES.BP) {
          if (payload?.platform === PLATFORM_ADS.GOOGLE_ADS) {
            navigate(
              `/bp-am/${localStorage.getItem("formId")}/access-sharing`,
              {
                state: { ...payload, authCode: querySearchParams.get("code") },
              }
            );
          } else {
            navigate(
              `/bp-am/${localStorage.getItem(
                "formId"
              )}/access-sharing/facebook-access`,
              {
                state: { ...payload, authCode: querySearchParams.get("code") },
              }
            );
          }
        } else {
          if (payload?.platform === PLATFORM_ADS.GOOGLE_ADS) {
            navigate(
              `/ld-am/${localStorage.getItem("formId")}/access-sharing`,
              {
                state: { ...payload, authCode: querySearchParams.get("code") },
              }
            );
          } else {
            navigate(
              `/ld-am/${localStorage.getItem(
                "formId"
              )}/access-sharing/facebook-access`,
              {
                state: { ...payload, authCode: querySearchParams.get("code") },
              }
            );
          }
        }
      } else {
        if (agencyType === AGENCY_TYPES.BP) {
          if (payload?.platform === PLATFORM_ADS.GOOGLE_ADS) {
            navigate(`/bp-am/${localStorage.getItem("formId")}/access-sharing`);
          } else {
            navigate(
              `/bp-am/${localStorage.getItem(
                "formId"
              )}/access-sharing/facebook-access`
            );
          }
        } else {
          if (payload?.platform === PLATFORM_ADS.GOOGLE_ADS) {
            navigate(`/ld-am/${localStorage.getItem("formId")}/access-sharing`);
          } else {
            navigate(
              `/ld-am/${localStorage.getItem(
                "formId"
              )}/access-sharing/facebook-access`
            );
          }
        }
      }
    }
  }, []);
  return null;
};

export default GoogleAuthRedirect;
