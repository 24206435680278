import { put, takeLatest, call, select, all } from 'redux-saga/effects';
import { api } from '..';
import { getLocalStorage } from '../localStorage/localStorage';
import {
  addAssetLibrary,
  addCustomSegments,
  addNewAsset,
  addResources,
  addStrategyId,
  addThemes,
  callGetKeywords,
  callGetResources,
  callGetThemes,
  clearValidationError,
  deleteAssetGroup,
  generateAdCopies,
  generateExtendedThemes,
  getAdAssets,
  getKeywords,
  getPmaxStrategies,
  getResources,
  getSegments,
  postAssetGroup,
  postKeywords,
  postThemes,
  putAssetGroup,
  removeAssetGroup,
  saveChanges,
  updateACamapign,
  updateAllCampaigns,
  updateAssetGroup,
  updateCampaignBudget,
  updateCommonThemeAndThemes,
  updateCustomSegments,
  updateKeywords,
  updateStartLoader,
  updateToBeSaved,
  updateValidationError,
  startCronPMaxStrategy,
  updateDirectSegments,
} from './slice';
import { updateFormReducer } from '../accountManager/forms/slice';
import {
  BACKEND_STATUS,
  CRON_STATUS_BACKEND,
} from '../../utils/accountManagerUtil';

function* getStrategies() {
  const formId = getLocalStorage('formId')
  ? getLocalStorage('formId')
  : getLocalStorage('amSelectedFormId');

  const getStrategiesURI = `/client-forms/${formId}/pmax-strategy`;

  const getPlatformsArray = yield select((state) => {
    if (getLocalStorage('formId'))
      return state.form?.form?.onboardingInfo?.platformInfo?.platforms;
    else return state.hdmFormAm?.onboardingInfo?.clientAccess?.platforms;
  });
  const getGooglePlatform = (getPlatformsArray || []).find(
    (platform) => platform.platform === 'google-ads'
  );
  const budget40Percent = getGooglePlatform ? Math.round((getGooglePlatform.spends * 0.4) / 30.4): 0;

  try {
    const response = yield call(api.get, getStrategiesURI);
    if (response) {
      const { strategies } = response.data;
      const campaign1 = [];
      const campaign2 = [];
      const campaign3 = [];
      let strategyId1 = 0;
      let strategyId2 = 0;
      let strategyId3 = 0;
      let assetCount1 = 0;
      let assetCount2 = 0;
      let assetCount3 = 0;
      let budget1 = 0;
      let budget2 = 0;
      let budget3 = 0;
      let theme = '';
      let themes = [];

      strategies.forEach((strategy) => {
        if (strategy.name === 'campaign3') {
          strategyId3 = strategy.id;
          budget3 = strategy?.budget || 0;
          assetCount3 = strategy?.assetCount || 0;
          strategy.assets.forEach((asset) => {
            campaign3.push({ ...asset, savedStatus: true });
          });
        } else if (strategy.name === 'campaign2') {
          strategyId2 = strategy.id;
          budget2 = strategy?.budget || 0;
          assetCount2 = strategy?.assetCount || 0;
          strategy.assets.forEach((asset) => {
            campaign2.push({ ...asset, savedStatus: true });
          });
        } else if (
          strategy.name === 'Campaign1' ||
          strategy.name === 'campaign1'
        ) {
          strategyId1 = strategy.id;
          assetCount1 = strategy?.assetCount || 0;
          budget1 = strategy?.budget || budget40Percent;
          assetCount3 = strategy?.assetCount || 0;
          strategy.assets.forEach((asset, index) => {
            if (index === 0) {
              theme = asset.theme;
              themes = asset.themes;
              if (!asset.name && !asset?.segment?.name)
                campaign1.push({
                  ...asset,
                  name: 'Asset Group 1',
                  segment: { name: 'Segment 1', type: 'NEW' },
                  savedStatus: true,
                });
              else campaign1.push({ ...asset, savedStatus: true });
            } else {
              campaign1.push({ ...asset, savedStatus: true });
            }
          });
        }
      });

      if (!campaign1.length && strategyId1)
        campaign1.push({
          value: 'Theme',
          status: 'ONGOING',
          name: `Asset Group 1`,
          segment: { type: 'NEW', name: 'Segment 1' },
          savedStatus: false,
        });
      if (!campaign2.length && strategyId2)
        campaign2.push({
          value: 'Theme',
          status: 'ONGOING',
          name: `Asset Group 1`,
          segment: { type: 'NEW', name: 'Segment 1' },
          savedStatus: false,
        });
      if (!campaign3.length && strategyId3)
        campaign3.push({
          value: 'Theme',
          status: 'ONGOING',
          name: `Asset Group 1`,
          segment: { type: 'NEW', name: 'Segment 1' },
          savedStatus: false,
        });

      yield put(updateCommonThemeAndThemes({ theme, themes }));
      yield put(
        updateAllCampaigns({
          campaign1,
          campaign2,
          campaign3,
          strategyId1,
          strategyId2,
          strategyId3,
          budget1,
          budget2,
          budget3,
          assetCount1,
          assetCount2,
          assetCount3,
        })
      );
      yield put(updateStartLoader(false));
    }
  } catch (err) {
    console.log('ERROR:', err);
    yield put(updateStartLoader(false));
  }
}

function* fetchSegements() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const segmentsURI = `/g-ads/custom-segments?formId=${formId}`;

  try {
    const response = yield call(api.get, segmentsURI);
    if (response) {
      const { customSegments } = response.data;
      yield put(addCustomSegments(customSegments));
    }
  } catch (err) {
    console.log('Error:', err);
  }
}

function* fetchAdAssets() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const adAssetURI = `/g-ads/ad-assets?formId=${formId}`;

  try {
    const response = yield call(api.get, adAssetURI);
    if (response) {
      const { assets } = response.data;
      const imageLibrary = [];
      const logoLibrary = [];
      const videoLibrary = [];
      assets.forEach((asset) => {
        if (asset.type === 'YOUTUBE_VIDEO') videoLibrary.push(asset);
        else if (asset.type === 'IMAGE') imageLibrary.push(asset);
        else logoLibrary.push(asset);
      });
      yield put(addAssetLibrary({ imageLibrary, logoLibrary, videoLibrary }));
    }
  } catch (err) {
    console.log('Error:', err);
  }
}

function* getThemes() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const getThemesURI = `/creative-resources/pmax-ads/${formId}/themes`;
  try {
    const response = yield call(api.get, getThemesURI);
    console.log(response);
    if (response) {
      const { themes } = response.data;
      if (themes?.length) {
        yield put(callGetThemes(false));
        const themesObject = themes.map((theme) => ({
          name: theme,
          status: false,
        }));
        yield put(addThemes(themesObject));
        yield put(updateStartLoader(false));
      }
    }
  } catch (err) {
    console.log('Error:', err);
    yield put(callGetThemes(false));
    yield put(updateStartLoader(false));
  }
}

function* fetchResources() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const getResources = `/creative-resources/pmax-ads/${formId}/resources`;
  try {
    const response = yield call(api.get, getResources);
    if (response) {
      const { resources } = response.data;
      if (resources.length) {
        let headlines = [];
        let longHeadlines = [];
        let descriptions = [];
        yield put(callGetResources(false));
        const isResourcesEmpty = resources.some(
          (resource) => resource.resources === null
        );

        if (!isResourcesEmpty) {
          resources.forEach((resource) => {
            if (resource.resourceType === 'description')
              descriptions = resource?.resources || [];
            else if (resource.resourceType === 'headline')
              headlines = resource?.resources || [];
            else longHeadlines = resource?.resources || [];
          });
          yield put(
            addResources({
              headlines:
                headlines.length > 10 ? headlines.slice(0, 10) : headlines,
              longHeadlines:
                longHeadlines.length > 10
                  ? longHeadlines.slice(0, 10)
                  : longHeadlines,
              descriptions:
                descriptions.length > 10
                  ? descriptions.slice(0, 10)
                  : descriptions,
            })
          );
          yield put(updateStartLoader(false));
        } else yield put(callGetResources(true));
      }
    }
  } catch (err) {
    console.log('Error:', err);
    yield put(callGetResources(false));
    yield put(updateStartLoader(false));
  }
}

function* postResources() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const postResources = `/creative-resources/pmax-ads/${formId}/resources`;
  const currentCampaign = yield select(
    (state) => state.pmaxStrategy.currentCampaign
  );
  const currentAssetGroup = yield select(
    (state) => state.pmaxStrategy.currentIndex[currentCampaign]
  );
  const getCurrentAsset = yield select(
    (state) => state.pmaxStrategy[currentCampaign][currentAssetGroup]
  );
  const { themes } = getCurrentAsset;
  const sendThemes = [];
  themes.forEach((theme) => {
    if (theme.status) sendThemes.push(theme.name);
  });
  const data = {
    themes: sendThemes.slice(),
    resourceTypes: [],
  };

  try {
    const response = yield call(api.post, postResources, data);
    if (response) yield put(callGetResources(true));
  } catch (err) {
    console.log('Error:', err);
  }
}

function* postStrategy() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const currentCampaign = yield select(
    (state) => state.pmaxStrategy.currentCampaign
  );
  const theme = yield select(state => state.pmaxStrategy.commonTheme);
  const themes = yield select(state => state.pmaxStrategy.commonThemes);
  const keywords = yield select(state => state.pmaxStrategy.keywords);
  const segment = {
      type: "NEW",
      name: "Segment 1",
      targetingType: "People who searched for any of these terms on Google",
      keywords
  };
  const firstAsset = {
      status: BACKEND_STATUS.ONGOING,
      name: "Asset Group 1",
      theme,
      themes,
      assetNumber: 1,
      segment,
  };
  const postStrategyURI = `/client-forms/${formId}/pmax-strategy`;
  const data = {
    name: currentCampaign,
    status: 'ONGOING',
    assetCount: 1,
    budget: 0,
    assets: [firstAsset],
  };

  try {
    const response = yield call(api.post, postStrategyURI, data);
    if (response.data) {
      const { name, status, assets, id, budget } = response.data;
      const asset = assets[0];
      yield put(addStrategyId(id));
      yield put(
        updateAssetGroup({ ...asset, budget, savedStatus: true })
      );
    }
  } catch (err) {
    console.log('ERROR:', err);
  }
}

function* putStrategy() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const currentCampaign = yield select(
    (state) => state.pmaxStrategy.currentCampaign
  );
  const currentAssetGroup = yield select(
    (state) => state.pmaxStrategy.currentIndex[currentCampaign]
  );
  const currentAssetCounts = yield select(
    (state) => state.pmaxStrategy.assetCounts[currentCampaign]
  );
  const campArray = yield select(
    (state) => state.pmaxStrategy[currentCampaign]
  );
  const { value, errors, savedStatus, ...getCurrentAsset } =
    campArray[currentAssetGroup];
  const strategyId = yield select(
    (state) => state.pmaxStrategy.strategyId[currentCampaign]
  );
  const getBudget = yield select(
    (state) => state.pmaxStrategy.budget[currentCampaign]
  );
  const data = {
    status: 'ONGOING',
    budget: Number(getBudget),
    name: currentCampaign,
    assetCount: currentAssetCounts,
    assets: [{ ...getCurrentAsset, status: 'COMPLETED' }],
  };

  const putStrategyURI = `/client-forms/${formId}/pmax-strategy/${strategyId}`;

  try {
    const response = yield call(api.put, putStrategyURI, data);
    if (response.data) {
      console.log(response.data);
      const { name, status, assets, id, budget, assetCount } = response.data;
      // yield put(addStrategyId(id));
      let updatedAsset = assets.find(
        (asset) => asset.assetNumber === getCurrentAsset.assetNumber
      );
      // if (getCurrentAsset?.id)
      //   updatedAsset = assets.find(asset => asset.id === getCurrentAsset.id );
      const { errors, ...responseAssets } = updatedAsset;
      yield put(clearValidationError());
      yield put(
        updateAssetGroup({ ...responseAssets, budget, savedStatus: true })
      );
      yield put(updateValidationError(errors));
      yield put(updateStartLoader(false));
    }
  } catch (err) {
    const detail = err.response.data.detail;
    yield put(clearValidationError());
    yield put(
      updateAssetGroup({
        id: detail?.asset_id[0],
        budget: getBudget,
        savedStatus: true,
        status: 'ONGOING',
      })
    );
    yield put(updateValidationError(detail));
    yield put(updateStartLoader(false));
  }
}

function* deleteStrategy() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const currentCampaign = yield select(
    (state) => state.pmaxStrategy.currentCampaign
  );
  const deleteIndex = yield select(
    (state) => state.pmaxStrategy.deleteAssetGroupIndex
  );
  const getDeleteAsset = yield select(
    (state) => state.pmaxStrategy[currentCampaign][deleteIndex]
  );
  const strategyId = yield select(
    (state) => state.pmaxStrategy.strategyId[currentCampaign]
  );
  const deleteStrategyURI = `/client-forms/${formId}/pmax-strategy/${strategyId}/assets?asset_ids=${getDeleteAsset?.id}`;
  try {
    if (getDeleteAsset?.id) {
      const response = yield call(api.delete, deleteStrategyURI);
      if (response) {
        yield put(removeAssetGroup(deleteIndex));
      }
    } else yield put(removeAssetGroup(deleteIndex));
  } catch (err) {
    console.log('Error:', err);
  }
}

function* getKeywordsTAI() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const getKeywordsURI = `/targeting-keywords/pmax-ads/${formId}`;

  try {
    const response = yield call(api.get, getKeywordsURI);
    if (response?.data?.keywords) {
      yield put(callGetKeywords(false));
      const { positiveKw, keywordIdeas, directSegments } =
        response.data.keywords;
      const allKeywords = positiveKw.concat(keywordIdeas);
      yield put(updateKeywords(allKeywords));
      yield put(updateDirectSegments(directSegments));
    }
  } catch (err) {
    console.log('Error:', err);
    yield put(callGetKeywords(false));
  }
}

function* postKeyowrdsTAI() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const postKeywordsURI = `/targeting-keywords/pmax-ads/${formId}`;
  try {
    const response = yield call(api.post, postKeywordsURI);
    if (response) yield put(callGetKeywords(true));
  } catch (err) {}
  try {
    const response = yield call(api.post, postKeywordsURI);
    if (response) yield put(callGetKeywords(true));
  } catch (err) {}
}

function* putAllCampaings() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const camp1 = yield select((state) => state.pmaxStrategy.campaign1);
  const camp2 = yield select((state) => state.pmaxStrategy.campaign2);
  const camp3 = yield select((state) => state.pmaxStrategy.campaign3);
  const publishFlag = yield select((state) => state.pmaxStrategy.publishFlag);
  const strategyIds = yield select((state) => state.pmaxStrategy.strategyId);
  const assetCounts = yield select((state) => state.pmaxStrategy.assetCounts);
  const strategyId1 = strategyIds.campaign1;
  const strategyId2 = strategyIds.campaign2;
  const strategyId3 = strategyIds.campaign3;
  const assetCount1 = assetCounts.campaign1;
  const assetCount2 = assetCounts.campaign2;
  const assetCount3 = assetCounts.campaign3;

  const putStrategy1URI = `/client-forms/${formId}/pmax-strategy/${strategyId1}`;
  const putStrategy2URI = `/client-forms/${formId}/pmax-strategy/${strategyId2}`;
  const putStrategy3URI = `/client-forms/${formId}/pmax-strategy/${strategyId3}`;

  let data1 = {};
  let data2 = {};
  let data3 = {};

  if (publishFlag) {
    data1 = { status: 'COMPLETED' };
    data2 = { status: 'COMPLETED' };
    data3 = { status: 'COMPLETED' };
  } else {
    data1 = {
      status: 'ONGOING',
      assetCount: assetCount1,
      assets: camp1,
    };
    data2 = {
      status: 'ONGOING',
      assetCount: assetCount2,
      assets: camp2,
    };
    data3 = {
      status: 'ONGOING',
      assetCount: assetCount3,
      assets: camp3,
    };
  }

  const promiseArray = [api.put(putStrategy1URI, data1)];

  if (camp2.length) promiseArray.push(api.put(putStrategy2URI, data2));
  if (camp3.length) promiseArray.push(api.put(putStrategy3URI, data3));

  try {
    const response = yield Promise.all(promiseArray);
    yield put(updateStartLoader(false));
  } catch (err) {
    console.log('ERROR:', err);
    yield put(updateStartLoader(false));
  }
}

function* postExtendedThemes() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const currentCampaign = yield select(
    (state) => state.pmaxStrategy.currentCampaign
  );
  const currentAssetGroup = yield select(
    (state) => state.pmaxStrategy.currentIndex[currentCampaign]
  );
  const getCurrentAsset = yield select(
    (state) => state.pmaxStrategy[currentCampaign][currentAssetGroup]
  );
  const theme = getCurrentAsset?.theme;
  const postThemesURI = `/creative-resources/pmax-ads/${formId}/themes`;
  console.log('post extended themes', theme);
  const data = { theme };
  try {
    const response = yield call(api.post, postThemesURI, data);
    if (response) yield put(callGetThemes(true));
  } catch (err) {
    console.log('Error:', err);
  }
}

function* postStartCronPMaxStrategy() {
  const formId = getLocalStorage('formId')
    ? getLocalStorage('formId')
    : getLocalStorage('amSelectedFormId');
  const form = getLocalStorage('formId')
    ? yield select((store) => store.form.form)
    : yield select((store) => store.hdmFormAm);
  const caiCronEndpoint = `/creative-resources/pmax-ads/${formId}/themes-resources`;
  const taiCronEndpoint = `/targeting-keywords/pmax-ads/${formId}`;
  const data = {
    googlePmaxStrategy: { status: BACKEND_STATUS.ONGOING },
  };

  try {
    if (
      !form?.pmaxCAICronStatus ||
      form?.pmaxCAICronStatus === CRON_STATUS_BACKEND.FAILED
    )
      yield call(api.post, caiCronEndpoint);
    if (
      !form?.pmaxTAICronStatus ||
      form?.pmaxTAICronStatus === CRON_STATUS_BACKEND.FAILED
    )
      yield call(api.post, taiCronEndpoint);
    yield put(updateFormReducer(data));
    yield put(updateStartLoader(false));
  } catch (error) {
    console.log('ERROR:', error.message);
    yield put(updateStartLoader(false));
  }
}

export default function* watchPmaxStrategy() {
  yield all([
    yield takeLatest(getPmaxStrategies.type, getStrategies),
    yield takeLatest(getSegments.type, fetchSegements),
    yield takeLatest(getAdAssets.type, fetchAdAssets),
    yield takeLatest(getKeywords.type, getKeywordsTAI),
    yield takeLatest(postKeywords.type, postKeyowrdsTAI),
    yield takeLatest(postAssetGroup.type, postStrategy),
    yield takeLatest(putAssetGroup.type, putStrategy),
    yield takeLatest(deleteAssetGroup.type, deleteStrategy),
    yield takeLatest(postThemes.type, postExtendedThemes),
    yield takeLatest(generateExtendedThemes.type, getThemes),
    yield takeLatest(generateAdCopies.type, postResources),
    yield takeLatest(getResources.type, fetchResources),
    yield takeLatest(generateAdCopies.type, postResources),
    yield takeLatest(saveChanges.type, putAllCampaings),
    yield takeLatest(startCronPMaxStrategy.type, postStartCronPMaxStrategy),

  ])
}
