import { getLocalStorage } from "../api/localStorage/localStorage"

export const getWebsiteUrl = clientForm => {
    const agencyType = getLocalStorage("agencyType");
    switch (agencyType) {
        case "ld":
            return clientForm?.onboardingInfo?.personalInfo?.companyWebsite;
        case "hdm":
            return clientForm?.onboardingInfo?.clientAccess?.websiteUrl;
        case "bp":
        default:
            return "";
    }
}
