import { useDispatch } from 'react-redux';
import { goBackAmCompo } from '../../../api/accountManager/componentTracking/action';
import { onNextLD } from '../GoToNextCompoButton';
import CommonAuditAdset from './CommonAuditAdset';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import ldAmRoutePaths from '../../../pages/ld-am/constants/ldAmRoutePaths';

export default function LDAuditAdset() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {formId} = useParams();


  const navigation = () => {
    navigate(
      generatePath(
        `${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.ad}`,
        { formId }
      )
    );
  }

  const onBack = () => {
    navigate(generatePath(`${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.campaign}`, {formId}));
  };
  
  return (
    <CommonAuditAdset
      onBack={onBack}
      /** onNextLD, is a curried function, no need to wrap the call in a callback */
      onNext={onNextLD(dispatch, navigation)}
    />
  );
}