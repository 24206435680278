import { useDispatch, useSelector } from "react-redux";
import CommonFacebookAccounts from "./CommonFacebookAccounts";
import { useCallback } from "react";
import {
  getAuditFacebook,
  hitTargetingKeywordsApi,
} from "../../../api/HDMAM/audit/saga";
import { updateCompoReduxAM } from "../../../api/accountManager/componentTracking/action";
import { saveHdmAmFormsData } from "../../../api/HDMAM/form/action";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";
import { useGetClientForms } from "../../../services/clientForms";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";

export default function LDFacebookAccounts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  // const form = useSelector((store) => store.form.form);
  const { data: form, isLoading } = useGetClientForms(formId);
  const fbAccountId = useSelector((state) => state?.form?.form?.fbAdsAccountId);

  // return a promise
  const onGenerateReport = useCallback(
    (options) => {
      return getAuditFacebook(options, "LD").then(async (res) => {
        // const apiHit = await hitTargetingKeywordsApi();
        dispatch({ type: "GET_AUDIT_DETAILS_HDM", payload: res });
        dispatch(
          updateFormReducer({
            fbAdsAccountId: options.accountId,
            fbAdsAccountName: options.selectedCustomerName,
            auditInfo: {
              status: BACKEND_STATUS.ONGOING,
            },
          })
        );
        navigate(
          generatePath(
            `${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.account}`,
            { formId }
          )
        );
      });
    },
    [dispatch]
  );

  const onSkip = () => {
    navigate(generatePath(`${ldAmRoutePaths.proposol.fullbase}`, { formId }));
  };
  if (isLoading) {
    return <PmaxLoader />;
  }
  return (
    <CommonFacebookAccounts
      fbAccountId={fbAccountId}
      onGenerateReport={onGenerateReport}
      form={form}
      onSkip={onSkip}
    />
  );
}
