import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLocalStorage } from "../../../api/localStorage/localStorage";
import { bookicon as BOOK } from "../../../assets/icons/common/common";
import { campaign as CAMPAIGN } from "../../../assets/icons/links/links";
import { deck as DECK } from "../../../assets/icons/links/links";
import { proposal as PROPOSAL } from "../../../assets/icons/links/links";
import { fb_icon as FB } from "../../../assets/icons/social/social";
import { google2 as GOOGLE } from "../../../assets/icons/social/social";
import CRE from "../../../assets/images/creativeai.png";
import GOV from "../../../assets/images/governai.png";
import TAR from "../../../assets/images/targetai.png";
import GoToNextCompoButton from "../../ButtonPrimary/GoToNextCompoButton";
import GoToSpecificCompoButton from "../../ButtonPrimary/GoToSpecificCompoButton";
import { blue_platform_icon as bluePlatformIcon } from "../../../assets/icons/icon";
import { green_preview_eye as previewEyeIcon } from "../../../assets/icons/icon";
import { checkForAccess } from "../../../utils/hdmUtil";
import { goToSpecificCompoHDMClient } from "../../../api/HDMClient/componentTracking/action";
import { useDispatch } from "react-redux";
import "./HDMHomePage.css";
import {
  BACKEND_STATUS,
  isPlatformSelectedHDM,
} from "../../../utils/accountManagerUtil";
import { campaignPreviewFacebookComplete } from "../HDMNavigationSteps/index";
import {
  componentWithIndexHDMClient,
  clientDashboardIds,
} from "../../../utils/HDMClientDashboardIdsUtil";
import {
  facebookCampaignPreview,
  googleCampaignPreview,
  showCampaignPreviewClient,
} from "../../../utils/clientDashboardIdsUtil";
import { generatePath, useNavigate } from "react-router";
import hdmClientRoutePaths from "../../../pages/hdm-client/constants/hdmClientRoutePaths";

const StatusWithRightBtn = ({
  currentStep,
  nextStep,
  btnTitle,
  belowTitle,
  navigationPath,
  disabled = false,
  showTwoBtn = false,
}) => {
  return (
    <div className="status_bubble_inner">
      <div className="status_bar_row">
        <div className="bubble_current_status">
          <span className="icon">
            <img src={previewEyeIcon} />
          </span>
          <p>{currentStep}</p>
        </div>
        <div className="separator_status"></div>
        <div className="bubble_next_status">
          <div className="bubble_next_action ">
            <span className="icon">
              <img src={bluePlatformIcon} />
            </span>
            <GoToSpecificCompoButton
              className="started_button"
              title={btnTitle}
              type="hdm-client"
              navigationPath={navigationPath}
              disabled={disabled}
            />
          </div>
          <p>{nextStep}</p>
        </div>
      </div>
      <p className="bubble_status_info">{belowTitle}</p>
      {showTwoBtn && (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <GoToSpecificCompoButton
            className="started_button"
            title="Facebook"
            type="hdm-client"
            navigationPath={navigationPath}
            disabled={disabled}
          />
          <GoToSpecificCompoButton
            className="started_button"
            title="Google"
            type="hdm-client"
            navigationPath={navigationPath}
          />
        </div>
      )}
    </div>
  );
};

const StatusWithSingleTitle = ({
  title,
  showTwoBtn = false,
  googlePreviewDisabled,
  facebookPreviewDisabled,
  navigationPath,
  compoTitle
}) => {
  return (
    <>
      <img src={BOOK} alt="" />
      <span>{title}</span>
      {!showTwoBtn && !navigationPath && (
        <GoToNextCompoButton
          className="started_button"
          title="Get Started"
          type="hdmclient"
        />
      )}
      {navigationPath && (
       <GoToSpecificCompoButton
          className="started_button"
          disabled={googlePreviewDisabled}
          title={compoTitle}
          type="hdm-client"
          navigationPath={navigationPath}
        /> 
      )}
      {showTwoBtn && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <GoToSpecificCompoButton
            className="started_button"
            disabled={googlePreviewDisabled}
            title="Google"
            type="hdm-client"
            navigationPath={navigationPath}
          />
          <GoToSpecificCompoButton
            className="started_button"
            disabled={facebookPreviewDisabled}
            title="Facebook"
            type="hdm-client"
            navigationPath={navigationPath}
          />
        </div>
      )}
    </>
  );
};
const statuses = {
  PENDING: "PENDING",
  COMPLETED: "COMPLETED",
};
const accessList = {
  "READ_ACCESS": "READ_ACCESS",
  "VERIFYING": "VERIFYING",
  "COMPLETE_ACCESS": "COMPLETE_ACCESS",
  "ACCESS_REVOKED": "ACCESS_REVOKED",
  "PENDING": "PENDING",
};
const HDMHomePage = () => {
  const navigate = useNavigate();
  const clientState = useSelector((state) => state.hdmClient);
  const onboardingStatus = clientState.onboardingInfo.status;
  const [accessType, setAccessType] = useState(accessList.PENDING);
  const isProposalPublished =
    clientState?.proposalStatus !== BACKEND_STATUS.PENDING ? true : false;
  const strategyIterations = useSelector(
    (state) => state.hdmClient.strategyIterations
  );
  const [isCampaignApproved, setIsCampaignApproved] = useState(false);
  const isDeckPublishApproved =
    strategyIterations &&
    strategyIterations.length !== 0 &&
    ["GENERATED", "APPROVED"].includes(
      strategyIterations[strategyIterations.length - 1].state
    )
      ? false
      : true;
  const generatedCampaignsInfo = clientState?.generatedCampaignsInfo;
  const dispatch = useDispatch();

  useEffect(() => {
    if (clientState?.anyGoogleCampaignPublished || clientState?.anyFbCampaignPublished) {
      setIsCampaignApproved(true);
    }
  }, [clientState]);
  useEffect(() => {
    if (checkForAccess(clientState) === accessList.PENDING) {
      setAccessType(accessList.PENDING);
    } else if (checkForAccess(clientState) === accessList.VERIFYING) {
      setAccessType(accessList.VERIFYING);
    } else if (checkForAccess(clientState) === accessList.ACCESS_REVOKED) {
      setAccessType(accessList.ACCESS_REVOKED);
    } else if (checkForAccess(clientState) === accessList.READ_ACCESS) {
      setAccessType(accessList.READ_ACCESS);
    } else if (checkForAccess(clientState) === accessList.COMPLETE_ACCESS) {
      setAccessType(accessList.COMPLETE_ACCESS);
    }
  }, [clientState]);
  return (
    <div className="main_hero">
      <div className="onboard">
        <div className="hero_banner">
          <div className="banner_left">
            <span>Welcome</span>
            <h2>{getLocalStorage("brandName")}</h2>
            <p>
              Meet the most powerful, fastest, and smartest product to infuse
              AI-led decision-making in every aspect of your marketing.
            </p>
          </div>
          <div className="bubble_banner">
            {onboardingStatus === statuses.PENDING ? (
              <StatusWithSingleTitle title="Lets get started" compoTitle={"Get Started"} navigationPath={generatePath(`${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}`)}/>
            ) : onboardingStatus !== statuses.COMPLETED ? (
              <StatusWithSingleTitle title="please complete your onboarding form" compoTitle={"Get Started"} navigationPath={generatePath(`${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}`)}/>
            ) : accessType === accessList.PENDING ? (
              <StatusWithRightBtn
                currentStep="Onboarding form complete"
                nextStep="Provide complete access"
                btnTitle="View Complete Access"
                compoTitle={"Get Started"} 
                navigationPath={generatePath(`${hdmClientRoutePaths.accessSharing.fullbase}`)}
                belowTitle="Please provide complete access"
              />
            ) : accessType === accessList.VERIFYING ? (
              <StatusWithRightBtn
                currentStep="Onboarding form complete"
                nextStep="Checking Complete Access"
                btnTitle="Checking Complete Access"
                disabled={true}
                belowTitle="Verifying Complete Access"
              />
            ) : accessType === accessList.ACCESS_REVOKED ? (
              <StatusWithRightBtn
                currentStep="Onboarding form complete"
                nextStep="Complete access not received"
                btnTitle="Complete access not received"
                navigationPath={generatePath(`${hdmClientRoutePaths.accessSharing.fullbase}`)}
                belowTitle=""
              />
            ) : (accessType === accessList.READ_ACCESS ||
                accessType === accessList.COMPLETE_ACCESS) &&
              clientState.proposalStatus !== "COMPLETED" &&
              clientState.proposalStatus !== "ONGOING" ? (
              <StatusWithRightBtn
                currentStep={
                  accessList.READ_ACCESS === accessType
                    ? "Read Access Granted"
                    : "Complete Access Granted"
                }
                nextStep="Generating Onboarding Doc"
                btnTitle="View Onboarding Doc"
                belowTitle="You will be notified as soon as the Onboarding Doc is ready for review"
                disabled={true}
              />
            ) : clientState.proposalStatus === "ONGOING" ? (
              <StatusWithRightBtn
                currentStep="Onboarding Doc Generated"
                nextStep="Onboarding Doc"
                btnTitle="View Onboarding Doc"
                navigationPath={generatePath(`${hdmClientRoutePaths.onboardingDoc.fullbase}`)}
                belowTitle="Your Onboarding Doc has been generated"
              />
            ) : clientState.proposalStatus === "COMPLETED" &&
              strategyIterations.length === 0 ? (
              <StatusWithRightBtn
                currentStep="Onboarding Doc complete"
                nextStep="Generating Strategy Deck"
                btnTitle="Strategy Deck"
                disabled={true}
              />
            ) : strategyIterations.length !== 0 &&
              strategyIterations[strategyIterations.length - 1].state ===
                "GENERATED" ? (
              <StatusWithRightBtn
                currentStep="Onboarding Doc complete"
                nextStep="Strategy Deck Generated"
                btnTitle="View Strategy Deck"
                navigationPath={generatePath(`${hdmClientRoutePaths.strategyDeck.fullbase}`)}
              />
            ) : strategyIterations.length !== 0 &&
              strategyIterations[strategyIterations.length - 1].state ===
                "CHANGES_SUGGESTED" ? (
              <StatusWithRightBtn
                currentStep="Changes In Strategy Deck"
                nextStep="Generating New Strategy Deck"
                btnTitle="View Strategy Deck"
                disabled={true}
              />
            ) : strategyIterations.length !== 0 &&
              strategyIterations[strategyIterations.length - 1].state ===
                "CHANGES_APPLIED" ? (
              <StatusWithRightBtn
                currentStep="Changes In Strategy Deck"
                nextStep="New strategy deck generated"
                btnTitle="View Strategy Deck"
                navigationPath={generatePath(`${hdmClientRoutePaths.strategyDeck.fullbase}`)}
              />
            ) : strategyIterations.length !== 0 &&
              strategyIterations[strategyIterations.length - 1].state ===
                "APPROVED" &&
              !isCampaignApproved ? (
              <StatusWithRightBtn
                currentStep="Strategy Deck Approved"
                nextStep="Generating campaigns"
                btnTitle="Generating campaigns"
                disabled={true}
              />
            ) : isCampaignApproved ? (
              <StatusWithSingleTitle
                title="Campaigns Generated"
                navigationPath={generatePath(`${hdmClientRoutePaths.campaignPreview.fullbase}`)}
                compoTitle="Preview Campaigns"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="grid_home_page">
        <div className="grid_colum">
          <div className="grid_account_column">
            <p className="home_title">Accounts</p>

            <div className="social_media">
              <div className="social_home">
                <div className="social_list_home">
                  <img src={FB} alt="" />
                  <p className="social_name_title">
                    Facebook Ads{" "}
                    {clientState.accessSharing.fbAccess.adAccount.accountId ? (
                      <>
                        <span>|</span> Account ID :{" "}
                        {clientState.accessSharing.fbAccess.adAccount.accountId}
                      </>
                    ) : (
                      <></>
                    )}{" "}
                  </p>
                </div>
                <div
                  className={
                    clientState.accessSharing.fbAccess.adAccount.status ===
                      "READ_ACCESS" ||
                    clientState.accessSharing.fbAccess.adAccount.status ===
                      "COMPLETE_ACCESS"
                      ? "social_status_tag success"
                      : "social_status_tag warning"
                  }
                >
                  <p>
                    {clientState.accessSharing.fbAccess.adAccount.status ===
                      "VERIFYING" ||
                    clientState.accessSharing.fbAccess.adAccount.status ===
                      "ACCESS_REVOKED"
                      ? clientState.accessSharing.fbAccess.adAccount.accountId
                          .length === 0
                        ? "NOT CONNECTED"
                        : clientState.accessSharing.fbAccess.adAccount.status
                      : "CONNECTED"}
                  </p>
                </div>
              </div>
              <div className="social_home">
                <div className="social_list_home">
                  <img src={GOOGLE} alt="" />
                  <p className="social_name_title">
                    Google Ads{" "}
                    {clientState.accessSharing.googleAccess.googleAdAccount
                      .accountId ? (
                      <>
                        <span>|</span> Account ID :{" "}
                        {
                          clientState.accessSharing.googleAccess.googleAdAccount
                            .accountId
                        }
                      </>
                    ) : (
                      <></>
                    )}{" "}
                  </p>
                </div>
                <div
                  className={
                    clientState.accessSharing.googleAccess.googleAdAccount
                      .status === "READ_ACCESS" ||
                    clientState.accessSharing.googleAccess.googleAdAccount
                      .status === "COMPLETE_ACCESS"
                      ? "social_status_tag success"
                      : "social_status_tag warning"
                  }
                >
                  <p>
                    {clientState.accessSharing.googleAccess.googleAdAccount
                      .status === "VERIFYING" ||
                    clientState.accessSharing.googleAccess.googleAdAccount
                      .status === "ACCESS_REVOKED"
                      ? clientState.accessSharing.googleAccess.googleAdAccount
                          .accountId.length === 0
                        ? "NOT CONNECTED"
                        : clientState.accessSharing.googleAccess.googleAdAccount
                            .status
                      : "CONNECTED"}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid_quick_column">
            <p className="home_title">Quick Links</p>
            <div className="home_cards">
              <div className="card_block_home">
                <i>
                  <img src={PROPOSAL} alt="" />
                </i>
                <p>Onboarding Doc</p>
                <span
                  style={{
                    backgroundColor: !isProposalPublished ? "#eeee" : "",
                    opacity: !isProposalPublished ? "0.5" : 1,
                    pointerEvents: !isProposalPublished ? "none" : "all",
                    cursor: !isProposalPublished ? "" : "pointer"
                  }}
                  onClick={() =>
                    navigate(generatePath(`${hdmClientRoutePaths.onboardingDoc.fullbase}`))
                  }
                >
                  View Onboarding Doc
                </span>
              </div>
              <div className="card_block_home">
                <i>
                  <img src={DECK} alt="" />
                </i>
                <p>Strategy Deck</p>
                <span
                  style={{
                    backgroundColor: isDeckPublishApproved ? "#eeee" : "",
                    opacity: isDeckPublishApproved ? "0.5" : 1,
                    pointerEvents: isDeckPublishApproved ? "none" : "all",
                    cursor: isDeckPublishApproved ? "pointer" :"", 
                  }}
                  onClick={() =>
                    navigate(generatePath(`${hdmClientRoutePaths.strategyDeck.fullbase}`))
                  }
                >
                  Veiw Strategy Deck
                </span>
              </div>
              <div className="card_block_home">
                <i>
                  <img src={CAMPAIGN} alt="" />
                </i>
                <p>Campaign Setup</p>
                <span
                  style={{
                    backgroundColor: isCampaignApproved ? "" : "#eeee",
                    opacity: isCampaignApproved ? 1 : "0.5",
                    pointerEvents: !isCampaignApproved ? "none" : "all",
                    cursor: !isCampaignApproved ? "" : "pointer",
                  }}
                  onClick={() =>
                    navigate(generatePath(`${hdmClientRoutePaths.campaignPreview.fullbase}`))
                  }
                >
                  View Campaign Setup
                </span>
              </div>
              <div className="card_block_home">
                <i>
                  <img src={PROPOSAL} alt="" />
                </i>
                <p>Analytics</p>
                <span
                  style={{
                    backgroundColor: !isCampaignApproved ? "#eeee" : "",
                    opacity: !isCampaignApproved ? "0.5" : 1,
                    pointerEvents: !isCampaignApproved ? "none" : "all",
                    cursor: !isCampaignApproved ? "" : "pointer",
                  }}
                  onClick={() => {
                    if(isCampaignApproved){
                      window.location.href = "/analytic-dashboard";
                    }
                  }}
                >
                  View Dashboard
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid_colum">
          <div className="grid_learning_column">
            <div className="learning_centre">
              <p className="home_title">Pixis Learning Centre</p>
              <div className="learning_home_row">
                <div className="learning_home_img">
                  <img src={GOV} alt="" />
                </div>
                <div className="learning_item_info">
                  <h4>Performance AI</h4>
                  <p>
                    Maximize your marketing ROI by leveraging hyper-contextual
                    proprietary models. An all-in-one AI-led performance engine
                    to manage all aspects of your marketing.
                  </p>
                </div>
                <a
                  href="https://pixis.ai/performance-ai/"
                  target="_blank"
                  className="export_icon"
                ></a>
              </div>
              <div className="learning_home_row">
                <div className="learning_home_img">
                  <img src={TAR} alt="" />
                </div>
                <div className="learning_item_info">
                  <h4>Targeting AI</h4>
                  <p>
                    Fuel your targeting strategies with AI-powered customer
                    behavior & engagement feedbacks. Trained on billions of data
                    points, Targeting AI creates the most relevant cohorts for
                    your brand.
                  </p>
                </div>
                <a
                  href="https://pixis.ai/targeting-ai/"
                  target="_blank"
                  className="export_icon"
                ></a>
              </div>
              <div className="learning_home_row">
                <div className="learning_home_img">
                  <img src={CRE} alt="" />
                </div>
                <div className="learning_item_info">
                  <h4>Creative AI</h4>
                  <p>
                    Generate contextual text and creative assets effortlessly
                    for each of your cohorts. Get cohort-specific asset
                    recommendations, engagement-based feedback, and more with
                    industry-leading Transformer-based models.
                  </p>
                </div>
                <a
                  href="https://pixis.ai/creative-ai/"
                  target="_blank"
                  className="export_icon"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HDMHomePage;
