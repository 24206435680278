import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Item, TextField, Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {back_arrow as backArrow} from '../../../../assets/icons/icon';
import AdGroup from '../AdGroup';
import AdLevel from '../AdLevel';
import ReadIcon from '../ReadIcon';
import EditIcon from '../EditIcon';
import '../campaigncreation.css';
import {tracking_template_icon as trackingTemplateIcon} from '../../../../assets/icons/campaign-approval/campaign_approval';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import TrackingTemplateModal from '../TrackingTemplateModal';
import CampaignConfirmModal from '../CampaignConfirmModal';
import { updateCampaignConfirmModal } from '../../../../api/accountManager/forms/slice';

import CheckedIcon from '../CheckedIcon';
import { openSnackbar } from '../../../../api/Global/slice';

const CompetitorCampaignCreation = ({
  adsets,
  confirmHandler,
  finalUrl,
  setFinalUrl,
  websiteUtmUrl,
  setWebsiteUtmUrl,
  utmSource,
  utmMedium,
  utmCampaign,
  setUtmSource,
  setUtmMedium,
  setUtmCampaign,
  website,
  setShowViewDetails,
  addKeyWords,
  addHeadline,
  addDescription,
  deleteKeyWord,
  deleteHeadline,
  deleteDescription,
  editKeyWord,
  editHeadline,
  editDescription,
  isDisabled,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [read, setRead] = useState(true);
  const [modal, setModal] = React.useState(false);
  const [currentAdsetIndex, setCurrectAdsetIndex] = useState(0);
  const [adset, setAdset] = useState({});
  const [confirmedIndex, setConfirmedIndex] = useState([]);
  const campaignConfirmModal = useSelector(
    (state) => state.form.campaignConfirmModal
  );

  useEffect(() => {
    if (currentAdsetIndex < (adsets || []).length) {
      setAdset(adsets[currentAdsetIndex]);
    }
  }, [currentAdsetIndex, adsets]);

  const handleChange = (event, newValue) => {
    setCurrectAdsetIndex(newValue);
    setValue(newValue);
  };

  const confirmCampaignHandler = () => {
    if ((adsets[currentAdsetIndex].descriptions || []).length < 2) {
      dispatch(
        openSnackbar({
          message: "descriptions should be more than 1",
          severity: "error",
        })
      );
    } else if ((adsets[currentAdsetIndex].headlines || []).length < 3) {
      dispatch(
        openSnackbar({
          message: "headlines should be more than 2",
          severity: "error",
        })
      );
    } else {
      if (currentAdsetIndex < adsets.length - 1) {
        setCurrectAdsetIndex(currentAdsetIndex + 1);
        setValue(currentAdsetIndex + 1);
        setConfirmedIndex([...confirmedIndex, currentAdsetIndex]);
      } else {
        setConfirmedIndex([...confirmedIndex, currentAdsetIndex]);
        confirmHandler(true, "competitorCampaign");
      }
    }
  };

  const goToCampaignViewHandler = () => {
    setShowViewDetails(false);
    dispatch(updateCampaignConfirmModal(false));
  };
  const closeConfirmModal = () => {
    dispatch(updateCampaignConfirmModal(false));
  };
  return (
    <div className="campaign-container competitor-campaign-container">
      {!isDisabled && (
        <div
          style={{
            position: "absolute",
            right: "1.5rem",
            top: "1rem",
            display: "flex",
          }}
        >
          <div
            className="read-icon-container"
            style={{
              background: read ? "rgba(8, 105, 251, 0.07)" : "#FFFFFF",
              // border: read ? '1px solid #cccccc' : '1px solid #0869FB',
            }}
            onClick={() => setRead(true)}
          >
            <ReadIcon color={read ? "#0869FB" : "#999999"} />
          </div>
          <div
            className="write-icon-container"
            onClick={() => setRead(false)}
            style={{
              background: !read ? "rgba(8, 105, 251, 0.07)" : "#FFFFFF",
              // border: !read ? '1px solid #0869FB' : '1px solid #cccccc',
            }}
          >
            <EditIcon color={!read ? "#0869FB" : "#999999"} />
          </div>
        </div>
      )}
      <div className="campaign-header">
        <div className="campaign-header-container">
          <div
            className="back-button-container"
            onClick={() => setShowViewDetails(false)}
          >
            <img src={backArrow} alt="" />
          </div>
          <div className="campaign-description">
            <Typography className="campaign-title">
              Competitor Campaign Creation
            </Typography>
          </div>
        </div>
      </div>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {adsets.map((ad, idx) => {
                return (
                  <Tab
                    label={ad.name}
                    value={idx}
                    icon={confirmedIndex.includes(idx) ? <CheckedIcon /> : ""}
                    key={idx}
                    style={{
                      width: "calc(100%/3)",
                      textTransform: "capitalize",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 14,
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.25rem",
                    }}
                  />
                );
              })}
            </TabList>
          </Box>
          {/* <TabPanel value='1'>Item One</TabPanel>
          <TabPanel value='2'>Item Two</TabPanel>
          <TabPanel value='3'>Item Three</TabPanel> */}
        </TabContext>
      </Box>
      <div className="ad-section-container">
        <div className="ad-group-section">
          <AdGroup
            read={read}
            keywords={adset.keywords || []}
            adSetName={adset.name}
            addKeyWords={addKeyWords}
            campaignType="competitorCampaign"
            deleteKeyWord={deleteKeyWord}
            editKeyWord={editKeyWord}
            isDisabled={isDisabled}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginTop: "0.75rem",
            }}
          >
            <div>
              <TextField
                id="outlined-basic"
                label="Final URL"
                variant="outlined"
                size="small"
                placeholder="Enter Final URL"
                value={finalUrl}
                onChange={(e) => setFinalUrl(e.target.value)}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                style={{
                  width: "100%",
                  background: "#FFFFFF",
                  border: "1px solid #DDDDDD",
                  borderRadius: 8,
                }}
                disabled={isDisabled}
              />
            </div>
            <div style={{ display: "flex", gap: 12 }}>
              <TextField
                id="outlined-basic"
                label="Tracking Template"
                variant="outlined"
                size="small"
                placeholder="Enter Tracking Template"
                value={`${websiteUtmUrl}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                style={{
                  width: "90%",
                  background: "#FFFFFF",
                  border: "1px solid #DDDDDD",
                  borderRadius: 8,
                }}
                disabled={isDisabled}
              />
              <div
                style={{
                  width: 40,
                  height: 42,
                  background: "#F2F2F2",
                  border: "1px solid #DDDDDD",
                  borderRadius: 8,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => setModal(true)}
              >
                <img src={trackingTemplateIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="ad-level-section">
          <AdLevel
            key={currentAdsetIndex}
            read={read}
            headlines={adset.headlines}
            descriptions={adset.descriptions}
            website={website}
            addHeadline={addHeadline}
            addDescription={addDescription}
            adSetName={adset.name}
            campaignType="competitorCampaign"
            deleteHeadline={deleteHeadline}
            deleteDescription={deleteDescription}
            editHeadline={editHeadline}
            editDescription={editDescription}
            isDisabled={isDisabled}
          />
          <div style={{width: "100%", height: "58px"}} />
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="footer_con" style={{ justifyContent: "end" }}>
          <PrimaryButton disabled={isDisabled} title="Confirm" onClick={confirmCampaignHandler} />
        </div>
      </div>
      {modal && (
        <TrackingTemplateModal
          setModal={setModal}
          websiteUtmUrl={websiteUtmUrl}
          setWebsiteUtmUrl={setWebsiteUtmUrl}
          utmSource={utmSource}
          utmMedium={utmMedium}
          utmCampaign={utmCampaign}
          setUtmSource={setUtmSource}
          setUtmMedium={setUtmMedium}
          setUtmCampaign={setUtmCampaign}
          isDisabled={isDisabled}
        />
      )}
      {campaignConfirmModal && (
        <CampaignConfirmModal
          campaignName="Competitor"
          updateCampaignConfirmModal={closeConfirmModal}
          goToCampaignViewHandler={goToCampaignViewHandler}
        />
      )}
    </div>
  );
};

export default CompetitorCampaignCreation;
