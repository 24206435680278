import React, { forwardRef, useImperativeHandle } from "react";
import IconButton from "@mui/material/IconButton";
import { Menu, MenuItem } from "@mui/material";
import Alert from "@mui/material/Alert"
import { logos_sixdots as  SixDots } from "../../../assets/images/Analytics/analytics";
import { logos_graphs as GraphLogo } from "../../../assets/images/Analytics/analytics";
import { logos_threedots as ThreeDots } from "../../../assets/images/Analytics/analytics";
import { logos_table as TableLogo } from "../../../assets/images/Analytics/analytics";
import { logos_add_chart as AddChartLogo } from "../../../assets/images/Analytics/analytics";
import { Delete2 as DeleteIcon } from "../../../assets/images/Analytics/analytics";
import CircularProgress from "@mui/material/CircularProgress";
import SimpleLineChart from "./SimpleLineChart";
import TableChart from "./TableChart";
import BarChartContainer from "./BarChart";
import KPIChart from "./KPIChart";
import FunnelChart from "./FunnelChart";
import LineBarContainer from "./LineBarChart";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Chart, Filter } from "../../models/chart";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import { getApiObject, GetChannel } from "../../models/channel";
import { useParams } from "react-router";
import { DateDisplayFormatter, dataBlendSource, ChartFilterSupport } from "./ChartUtils";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import * as htmlToImage from 'html-to-image';
import { getFilterDetails, getFilterObject } from "../Filteration/Utils";
import { Errorhandler } from "../ErrorHanlder/ErrorHandler";
import { defaultFBState } from "../../models/defaultData";
import { getWidgetData } from "../../../api/analytics/saga";
import { updateChart } from "../../../api/analytics/action";

const ChartWrapper = forwardRef((props, ref) => {
  const { chart: initialChart, handleToggleEditDrawer, removeChart, report, reportId } = props;
  const dispatch = useDispatch()
  const _updateChart = (_) => dispatch(updateChart(_))
  const { tab: tabPath } = useParams();
  const [chart, setChart] = React.useState(initialChart);
  const [channel, setChannel] = React.useState(GetChannel(chart.channelType));
  const [updateDataCount, setUpdateDataCount] = React.useState(0);
  const selectedAccounts = useSelector(state => state.dashboard.selectedAccounts);
  const dateRange = useSelector(state => state.dashboard.dateRange);
  const state = useSelector(state => channel.getState(state)) ?? defaultFBState;
  const formId = useSelector(state => state.hdmClient._id)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [title, setTitle] = React.useState(chart.title);
  const [chartStyle, setChartStyle] = React.useState(chart.chartStyle)
  const [isTable, setIsTable] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [data, setData] = React.useState({
    dimension: "",
    metrics: [],
    data: [],
  });
  const [comparedData, setComparedData] = React.useState({
    dimension: [] || "",
    leftMetrics: [],
    rightMetrics: [],
    data: [],
  });
  const [currentChartType, setCurrentChartType] = React.useState(chart.type)
  const [isAddChart, setIsAddChart] = React.useState(chart.type === "ADD");
  const [exportData, setExportData] = React.useState(false)

  React.useEffect(() => {
    if (!chart.isEqual(initialChart)) {
      setChart(initialChart);
      setChannel(GetChannel(initialChart.channelType));
      setTitle(initialChart.title);
      setChartStyle(initialChart.chartStyle);
      // updateChartFilters();
    }
  }, [initialChart]);

  React.useEffect(() => {
    updateData();
  }, [state.metricsUpdated, dateRange, chart, updateDataCount]);

  React.useEffect(() => {
    // updateChartFilters();
  }, [updateDataCount]);

  const forceUpdate = () => {
    setIsAddChart(false);
    setUpdateDataCount((prev) => prev + 1);
  }

  const insertInNamingMap = (namingMap, metric) => {
    if (!metric) { return; }
    const key = metric.dataSource ?? chart.channelType;
    if (!namingMap.get(key)) {
      namingMap.set(key, new Map());
    }
    namingMap.get(key).set(metric.id, metric.name);
  }

  const updateFilter = ([filter]) => {
    handleChartChange("filter", (!filter || filter.error) ? Filter.new({ channelType: chart.channelType }) : filter);
  }

  const updateMetricFilter = (filters) => {
    let updateChart = false;
    const leftMetrics = chart.leftMetrics.map(metric => {
      const filter = metric.filter.id && filters.find(filter => filter.id === metric.filter.id);
      if (filter) {
        metric.filter = filter;
      } else {
        if (!updateChart && metric.filter.id) {
          updateChart = true;
        }
        metric.filter = Filter.new({ channelType: chart.channelType })
      }
      return metric;
    })

    const rightMetrics = chart.rightMetrics?.map(metric => {
      const filter = metric.filter.id && filters.find(filter => filter.id === metric.filter.id);
      if (filter && filter.id && !filter.error) {
        metric.filter = filter;
      } else {
        if (!updateChart && metric.filter.id) {
          updateChart = true;
        }
        metric.filter = Filter.new({ channelType: chart.channelType })
      }
      return metric;
    })
    chart.leftMetrics = leftMetrics.filter(metric => metric && metric.metric);
    chart.rightMetrics = rightMetrics?.filter(metric => metric && metric.metric);

    const chartObj = chart.toJSON();
    setChart(Chart.fromJSON(chartObj));
    if (updateChart) {
      const paramKeys = ["leftMetrics", "rightMetrics"];
      const chartUpdateObj = chart.toJSONfiltered();
      _updateChart({
        reportId: reportId,
        updates: [{ chart_id: chart.id, update_type: "update", params: paramKeys.map(param => ({ [param]: chartUpdateObj[param] ?? null })).reduce((obj, curr) => ({ ...obj, ...curr }), {}) }],
      });
    }
  }

  const updateChartFilters = () => {
    if (!channel.isBlend) {
      if (ChartFilterSupport(currentChartType)) {
        if (chart?.filter?.id) {
          getFilterDetails([chart.filter.id], channel.type, updateFilter)
        } else {
          updateData();
        }
      } else {
        const combinedMetrics = [
          ...chart.leftMetrics,
          ...(chart.rightMetrics ?? []),
        ];

        if (combinedMetrics.length === 0) return;
        const allFilters = combinedMetrics.map(metric => metric.filter.id).filter(id => id);
        if (allFilters.length) {
          getFilterDetails(allFilters, channel.type, (filters) => updateMetricFilter(filters))
        } else {
          updateData();
        }
      }
    } else {
      updateData();
    }
  }

  const updateData = () => {
    setTitle(chart.title);
    setIsLoading(true);

    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ];

    if (!(channel.type in selectedAccounts)) {
      setData({ error: "Please select view id under this account" });
      setIsLoading(false);
      return;
    }


    if (combinedMetrics.length === 0) {
      setData({ error: "Invalid query" });
      setIsLoading(false);
      return;
    }

    if (!state.metricsUpdated) { return; }
    channel.updateGridFields(chart, state.tables, state.metrics, state.dimensions);
    setIsAddChart(false);
    handleClose();

    if (chart.type === "ADD") {
      setData({ error: "Chart Type not selected" });
      setIsLoading(false);
      return;
    }

    const unitKey = channel.properties?.unitKey ?? "dataType";

    const namingMap = new Map();

    for (const metric of combinedMetrics) {
      if (!metric?.metric) {
        if (combinedMetrics.length === 1) {
          setData({ error: "No Field Selected. Please check the mapping once within data blend source." })
        } else {
          setData({ error: "Some selected Metric fields doesn't exist. Please check the mapping once within data blend source." });
        }
        setIsLoading(false);
        return;
      };
      insertInNamingMap(namingMap, metric?.metric)
    }

    for (const [index, dimension] of (chart.dimensions ?? []).entries()) {
      if (!["PIE", "DONUT", "FUNNEL", "KPI"].includes(currentChartType) && !dimension?.id) {
        setData({ error: "Some selected Dimension fields doesn't exist. Please check the mapping once within data blend source." });
        setIsLoading(false);
        return;
      }
      insertInNamingMap(namingMap, dimension)
    }
    chart.dimensions?.forEach((dimension) =>
      namingMap.set(dimension?.id, dimension?.name)
    );

    if (ChartFilterSupport(currentChartType)) {
      var promise;
      promise = getWidgetData(`${report || reportId}|${tabPath}|${chart.title || chart.id}`, [
        {
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          ...getApiObject(
            channel.type,
            combinedMetrics.map(metric => metric.metric.id),
            chart.dimensions.map(dimension => dimension.id),
            chart.table
          ),
          ...getFilterObject(chart.filter),
          dataSource: channel.type
        },
      ], formId);
      promise.then((data) => {
        const segmentIndex = data[0].columns.findIndex(
          (c) => c === "ga:segment"
        );
        const dataDimensions = chart.dimensions.map((d) => d.name);
        const mappedData = data[0].values.map((value) => {
          const entry = new Map();
          data[0].columns.forEach((column, index) => {
            const displayName = namingMap.get(data[0].dataSources?.[index] ?? chart.channelType).get(column);
            if (displayName === "Name") {
              const columnName = column
                .replaceAll("_", " ")
                .replaceAll(".", " ")
                .split(" ")
                .map(name => name[0].toUpperCase() + name.slice(1))
                .join(" ");
              dataDimensions[chart.dimensions.findIndex(d => d.id === column)] = columnName;
              entry.set(columnName, value[index]);
            } else if (index !== segmentIndex) {
              entry.set(displayName, value[index]);
            }
          });
          chart.dimensions.forEach(d => {
            if (!data[0].columns.includes(d.id)) {
              let index = data[0].columns.findIndex(c => c === d.miscInfo?.base_dimension?.id);
              if (index !== -1) entry.set(d.name, value[index])
            }
          })
          return Object.fromEntries(entry.entries());
        });
        const dataObject = {
          dimensions: dataDimensions,
          leftMetrics: combinedMetrics.map((metric) => ({
            id: metric.metric.id, name: metric.metric.name, unit: metric.metric[unitKey],
            // currency: state.currency ? state.currency : dataBlendSource(metric.metric.name) ? wholeState[dataBlendSource(metric.metric.name)]?.currency : undefined 
          })),
          data: mappedData,
        };
        channel.transformer?.transformData?.(dataObject);
        setData(dataObject);
        setIsLoading(false);
      }).catch((err) => {
        if (err.status === 400 || err.status === 422) {
          setData({ error: "Invalid query" });
        } else if (err.status === 500 && err.data === "Invalid Blend ID") {
          setData({ error: "This source is not supported anymore" });
        } else if (err.status === 500 && err.data?.Error?.includes?.("No mapping found for Dimension")) {
          setData({ error: "Mapping Does not exists for current accounts for added Custom Dimensions in this source. Please Refresh Your Mapping!" });
        } else if (err.status === 500 && err.data?.Error?.includes?.("No Custom Dimension found")) {
          setData({ error: "Some of the Custom Dimensions added in this source are deleted. Please update source dimensions and keys!" });
        } else {
          console.log("~ Error", chart, err);
          setData({ error: "Something went wrong", canReload: true });
        }
        setIsLoading(false);
      });
    } else {
      updateChartData(dateRange.startDate, dateRange.endDate, setData);
      updateCompareWithDate(initialChart.compareWith);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemoveChart = () => {
    handleChartChange("type", "ADD");
    handleClose();
  };

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement('a');
    elem.href = blob
    elem.download = fileName;
    elem.style = 'display:none;';
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === 'function') {
      elem.click();
    } else {
      elem.target = '_blank';
      elem.dispatchEvent(new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      }));
    }
    URL.revokeObjectURL(elem.href);
    elem.remove()
  }

  const padding = 15;
  let top = 15;
  const elHeight = 80;
  const elWidth = 150;
  const maxColCount = 5;

  const createPdfData = async (doc, top) => {
    if (isTable || chart.type === "TABLE") {
      const headers = [...(data.dimensions ?? []), ...data.leftMetrics.map(m => m.name), ...(data.rightMetrics?.map(m => m.name) ?? [])];
      for (let count = 0; count < headers.length; count = count + maxColCount) {
        if (headers.length > maxColCount) {
          doc.text(["Table:: Part " + (count / maxColCount + 1)], padding, top);
          top += 10;
        }
        const filteredHeaders = headers.filter((h, index) => (index >= count && index < (count + maxColCount)))
        var rows = data.data.map((row, rowIndex) => {
          let obj = [rowIndex + 1];
          for (let header of filteredHeaders) {
            obj.push(row[header]);
          }
          return obj;
        })
        doc.autoTable({
          startY: top,
          head: [["Sr. No.", ...filteredHeaders]],
          body: rows,
          columnStyles: {
            0: {
              columnWidth: 20,
              fontStyle: 'bold',
              halign: 'center',
            }
          }
        })
        top = doc.previousAutoTable.finalY + 10;
      }
    } else {
      const imgData = await htmlToImage.toPng(document.getElementById("Chart" + chart.id), { quality: 1.0, pixelRatio: 1 })
      doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${chart.id}`);
      top += elHeight + padding;
    }
    return top;
  }

  useImperativeHandle(ref, () => ({
    forceUpdateChart() {
      forceUpdate()
    },
    handleAllExport() {
      if (data && data?.data?.length > 0) {
        var headers;
        var rows;
        var title = ["Title:", chart.title];
        if (currentChartType === "PIE" || currentChartType === "DONUT" || currentChartType === "FUNNEL") {
          headers = Object.keys(data.data[0]);
          rows = [Object.values(data.data[0])];
        } else {
          headers = [...(data.dimensions ?? []), ...data.leftMetrics.map(m => m.name), ...(data.rightMetrics?.map(m => m.name) ?? [])];
          rows = data.data.map((row) => headers.map((h) => row[h]));
        }
        return [title, [], headers, ...rows];
      }
    },
    async handleAllPdfExport(doc, top) {
      if (data && data?.data?.length > 0) {
        if (top > 200) {
          top = 15
          doc.addPage()
        }
        var title = `Title:  ${chart.title}`;
        doc.text([title], padding, top);
        top += 10;
        top = await createPdfData(doc, top);
      }
      return top;
    },
    handleAllPngExport() {
      if (!data || !data.data || data.data.length === 0) return;
      onCapturePNG()
    },
    handleAllJpgExport() {
      if (!data || !data.data || data.data.length === 0) return;
      onCaptureJPG()
    }
  }));

  const onCapturePNG = () => {
    const name = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    htmlToImage.toPng(document.getElementById("Chart" + chart.id), { quality: 1.0, pixelRatio: 1 })
      .then(function (dataUrl) {
        saveAs(dataUrl, `${name}.png`);
      });
    handleClose();
  }

  const onCaptureJPG = () => {
    const name = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    htmlToImage.toJpeg(document.getElementById("Chart" + chart.id), { backgroundColor: "white", quality: 1.0, pixelRatio: 1 })
      .then(function (dataUrl) {
        saveAs(dataUrl, `${name}.jpeg`);
      });
    handleClose();
  }

  const handleExport = () => {
    var headers;
    var rows;
    var title = ["Title:", chart.title];
    var date = ["Date Range: ", DateDisplayFormatter(dateRange.startDate) + " - " + DateDisplayFormatter(dateRange.endDate)];
    if (currentChartType === "PIE" || currentChartType === "DONUT" || currentChartType === "FUNNEL") {
      headers = Object.keys(data.data[0]);
      rows = [Object.values(data.data[0])];
    } else {
      headers = [...(data.dimensions ?? []), ...data.leftMetrics.map(m => m.name), ...(data.rightMetrics?.map(m => m.name) ?? [])];
      rows = data.data.map((row) => headers.map((h) => row[h]));
    }

    const blob = new Blob([[title, date, [], headers, ...rows].join("\n")], {
      type: "text/csv",
    });
    const a = document.createElement("a");
    a.download = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    a.href = window.URL.createObjectURL(blob);
    a.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
    a.remove();

    handleClose();
  };

  const handlePdfExport = async () => {
    const name = chart.title.replace(/[^a-zA-Z0-9 ]/g, "").replaceAll(" ", "-").toLowerCase();
    const doc = new jsPDF({});
    var title = `Title:  ${chart.title}`;
    var date = `Date Range:  ${DateDisplayFormatter(dateRange.startDate)} - ${DateDisplayFormatter(dateRange.endDate)}`;
    doc.text([title, date], padding, top);
    top += 20;
    top = await createPdfData(doc, top);
    doc.save(`${name}.pdf`);
    handleClose();
  };

  const updateChartData = (startDate, endDate, callback) => {
    setIsLoading(true);
    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ];

    if (combinedMetrics.length === 0) return;


    const unitKey = channel.properties?.unitKey ?? "dataType";
    const namingMap = new Map();
    for (const metric of combinedMetrics) {
      insertInNamingMap(namingMap, metric?.metric)
    }
    chart.dimensions?.forEach((dimension) =>
      insertInNamingMap(namingMap, dimension)
    );

    var promise = getWidgetData(`${report || reportId}|${tabPath}|${chart.title || chart.id}`,
      combinedMetrics.map((metric) => ({
        startDate: startDate,
        endDate: endDate,
        ...getApiObject(
          channel.type,
          [metric.metric?.id],
          chart.dimensions?.filter(d => d).map(dimension => dimension?.id) ?? [],
          chart.table
        ),
        ...getFilterObject(metric.filter),
        dataSource: channel.type
      })), formId
    );
    promise.then((chartData) => {
      const rightMetricsIndex = chart.leftMetrics.length;
      const totalMetrics = combinedMetrics.length;
      const dimensionIndex = chart.dimensions && chart.dimensions.length > 0
        ? chartData[0].columns.findIndex((c) => (c === chart.dimensions[0]?.id) || (c === chart.dimensions[0]?.miscInfo?.base_dimension?.id))
        : -1;
      const totalRows = chartData.reduce(
        (prevValue, currentValue) =>
          Math.min(prevValue, currentValue.rowCount),
        chartData[0].rowCount
      );
      const totalMaxRows = chartData.reduce(
        (prevValue, currentValue) =>
          Math.max(prevValue, currentValue.rowCount),
        chartData[0].rowCount
      );
      const dataLeftMetrics = chart.leftMetrics.map(metric => ({
        id: metric.metric.id, name: metric.metric.name, unit: metric.metric[unitKey],
        // currency: state.currency ? state.currency : dataBlendSource(metric.metric.name) ? wholeState[dataBlendSource(metric.metric.name)]?.currency : undefined 
      }));
      const dataRightMetrics = chart.rightMetrics?.map(metric => ({
        id: metric.metric.id, name: metric.metric.name, unit: metric.metric[unitKey],
        // currency: state.currency ? state.currency : dataBlendSource(metric.metric.name) ? wholeState[dataBlendSource(metric.metric.name)]?.currency : undefined 
      }));

      let mappedData = [];
      let metricIndex = [];
      for (let i = 0; i < totalMetrics; i++) {
        metricIndex.push(
          chartData[channel.isBlend ? 0 : i].columns.findIndex((c, ci) => c === combinedMetrics[i].metric.id && chartData[channel.isBlend ? 0 : i].dataSources?.[ci] === combinedMetrics[i].metric.dataSource)
        );
      }

      let dimWiseData = {};
      if (dimensionIndex >= 0 && totalRows !== totalMaxRows) {
        for (let [mIndex, metric] of chartData.entries()) {
          let column = metric.columns[1];
          if (!combinedMetrics[mIndex].filter.isEmpty()) {
            const filterName = combinedMetrics[mIndex].filter.name;
            //Updating name in chart metrics data only once
            if (mIndex >= rightMetricsIndex) {
              dataRightMetrics[mIndex - rightMetricsIndex].name += " (" + filterName + ")";
            } else {
              dataLeftMetrics[mIndex].name += " (" + filterName + ")";
            }
            column = column + " (" + filterName + ")"
          }

          for (let value of metric.values) {
            if (dimWiseData[value[dimensionIndex]]) {
              dimWiseData[value[dimensionIndex]][column] = value[1]
            } else {
              dimWiseData[value[dimensionIndex]] = { [column]: value[1] }
            }
          }
        }

        let dimName = namingMap.get(chartData[0].dataSources?.[dimensionIndex] ?? chart.channelType)
          .get(chartData[0].columns[dimensionIndex]) ?? chart.dimensions[0].name
        for (let row of Object.keys(dimWiseData)) {
          let entry = new Map();
          entry.set(dimName, row);

          for (let mIndex = 0; mIndex < totalMetrics; mIndex++) {
            const index = metricIndex[mIndex];
            let metricData = chartData[channel.isBlend ? 0 : mIndex]
            let column = metricData.columns[index]
            let metricName = namingMap.get(chartData[channel.isBlend ? 0 : mIndex].dataSources?.[index] ?? chart.channelType).get(chartData[channel.isBlend ? 0 : mIndex].columns[index]);
            if (!combinedMetrics[mIndex].filter.isEmpty()) {
              const filterName = combinedMetrics[mIndex].filter.name;
              column += " (" + filterName + ")"
              metricName += " (" + filterName + ")"
            }
            let value = dimWiseData[row][column] ?? "-";
            entry.set(metricName, value ?? "-");
          }
          mappedData.push(Object.fromEntries(entry));
        }
      } else {
        for (let row = 0; row < totalRows; row++) {
          let entry = new Map();

          if (dimensionIndex >= 0) {
            const value = chartData[0].values[row][dimensionIndex];
            let dimName = namingMap.get(chartData[0].dataSources?.[dimensionIndex] ?? chart.channelType)
              .get(chartData[0].columns[dimensionIndex]) ?? chart.dimensions[0].name
            entry.set(dimName, value);
          }

          for (let mIndex = 0; mIndex < totalMetrics; mIndex++) {
            const index = metricIndex[mIndex];
            const value = chartData[channel.isBlend ? 0 : mIndex].values[row][index];
            const metricName = namingMap.get(chartData[channel.isBlend ? 0 : mIndex].dataSources?.[index] ?? chart.channelType).get(chartData[channel.isBlend ? 0 : mIndex].columns[index]);

            // if metric has filter, insert using filter name
            if (!combinedMetrics[mIndex].filter.isEmpty()) {
              const filterName = combinedMetrics[mIndex].filter.name;
              //Upddating name only once
              if (row === 0) {
                if (mIndex >= rightMetricsIndex) {
                  dataRightMetrics[mIndex - rightMetricsIndex].name += " (" + filterName + ")";
                } else {
                  dataLeftMetrics[mIndex].name += " (" + filterName + ")";
                }
              }
              entry.set(metricName + " (" + filterName + ")", value);
            } else {
              entry.set(metricName, value);
            }
          }
          mappedData.push(Object.fromEntries(entry));
        }
      }
      const dataObject = {
        dimensions: chart.dimensions?.map(d => d?.name || ""),
        leftMetrics: dataLeftMetrics,
        rightMetrics: dataRightMetrics,
        data: mappedData,
      };
      channel.transformer?.transformData?.(dataObject);

      callback(dataObject);
      setIsLoading(false);
    }).catch((err) => {
      if ([400, 422].includes(err.status)) {
        callback({ error: "Invalid query" });
      } else if (err.status === 500 && err.data === "Invalid Blend ID") {
        callback({ error: "This source is not supported anymore" });
      } else if (err.status === 500 && err.data?.Error?.includes?.("No mapping found for Dimension")) {
        setData({ error: "Mapping Does not exists for current accounts for added Custom Dimensions in this source. Please Refresh Your Mapping!" });
      } else if (err.status === 500 && err.data?.Error?.includes?.("No Custom Dimension found")) {
        setData({ error: "Some of the Custom Dimensions added in this source are deleted. Please update source dimensions and keys!" });
      } else {
        console.log("~ Error", chart, err);
        callback({ error: "Something went wrong", canReload: true });
      }
      setIsLoading(false);
    });
  }

  const updateCompareWithDate = (value) => {

    if (!value) {
      setComparedData({
        data: [],
        leftMetrics: [],
        rightMetrics: [],
        dimensions: []
      })
    } else {
      const startDate = moment(dateRange.startDate, "YYYY-MM-DD").subtract(1, value).format("YYYY-MM-DD");
      const endDate = moment(dateRange.endDate, "YYYY-MM-DD").subtract(1, value).format("YYYY-MM-DD");

      updateChartData(startDate, endDate, setComparedData);
    }

  }

  const handleChartChange = (property, value, addChart) => {
    var updated = false;
    var paramKeys = [];
    switch (property) {
      case "compareWith":
        if (chart.compareWith !== value) {
          chart.compareWith = value;
          paramKeys = ["compareWith"];
          updateCompareWithDate(value);
        }
        break;
      case "title":
        if (chart.title !== value) {
          chart.title = value;
          setTitle(value);
          paramKeys = ["title"];
        }
        break;
      case "dimensions":
        if (
          chart.dimensions.length === value.length &&
          chart.dimensions.every(
            (dimension, index) => Chart.isDimensionEqual(dimension, value[index])
          )
        ) {
        } else {
          chart.dimensions = value;
          paramKeys = ["dimensions"];
          updated = true;
        }
        break;
      case "leftMetrics":
        if (
          chart.leftMetrics.length === value.length &&
          chart.leftMetrics.every((metric, index) =>
            metric.isEqual(value[index])
          )
        ) {
        } else {
          chart.leftMetrics = value;
          paramKeys = ["leftMetrics"];
          updated = true;
        }
        break;
      case "rightMetrics":
        if (!chart.rightMetrics) {
          break;
        }
        if (
          chart.rightMetrics.length === value.length &&
          chart.rightMetrics.every((metric, index) =>
            metric.isEqual(value[index])
          )
        ) {
        } else {
          chart.rightMetrics = value;
          paramKeys = ["rightMetrics"];
          updated = true;
        }
        break;
      case "filter":
        if (!chart.filter.isEqualAll(value)) {
          paramKeys = ["filter"];
          chart.filter = value;
          updated = true;
        }
        break;
      case "table":
        if (chart.table?.id !== value?.id) {
          chart.copyValues(Chart.new(chart.channelType, chart.title, chart.type, value, chart.gridPosition));
          const chartObj = chart.toJSON();
          delete chartObj.id;
          delete chartObj.channel;
          delete chartObj.title;
          delete chartObj.type;
          delete chartObj.gridPosition;
          paramKeys = Object.keys(chartObj);
          updated = chart.leftMetrics.length !== 0;
        }
        break;
      case "type":
        if (chart.type !== value) {
          setCurrentChartType(value);
          if (value === "ADD" || isAddChart || addChart) {
            setIsAddChart(true);
            chart.copyValues(Chart.new(chart.channelType, "", value, null, chart.gridPosition));
            const chartObj = chart.toJSON();
            delete chartObj.id;
            delete chartObj.channel;
            delete chartObj.gridPosition;
            paramKeys = Object.keys(chartObj);
            updated = true;
          } else {
            chart.type = value;
            paramKeys = ["type"];
          }
        }
        break;
      case "channel":
        if (chart.channelType !== value.type && value.type !== chart.channelType.id) {
          setChannel(value);
          chart.copyValues(Chart.new(value.isBlend ? { id: value.type, name: value.title } : value.type, "", chart.type, null, chart.gridPosition));
          const chartObj = chart.toJSON();
          delete chartObj.id;
          delete chartObj.type;
          delete chartObj.gridPosition;
          paramKeys = Object.keys(chartObj);
          updated = true;
        }
        break;
      case "chartStyle":
        if (chart.chartStyle !== value) {
          chart.chartStyle = { ...chart.chartStyle, ...value };
          setChartStyle(chart.chartStyle)
          paramKeys = ["chartStyle"];
        }
        break;
      default:
        break;
    }
    chart.validate();
    if (updated) {
      setChart(Chart.fromJSON(chart.toJSON()));
      updateCompareWithDate(chart.compareWith);
    }
    if (paramKeys.length > 0) {
      const chartObj = chart.toJSON();
      _updateChart({
        reportId: reportId,
        updates: [{ chart_id: chart.id, update_type: "update", params: paramKeys.map(param => ({ [param]: chartObj[param] ?? null })).reduce((obj, curr) => ({ ...obj, ...curr }), {}) }],
      });
    }
  };


  const getChartObject = (type, data) => {
    try {
      if (!data.data) {
        return <div style={{ margin: "auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ErrorRoundedIcon style={{ color: "#CC0000" }} />
            <h3 className="lato bold" style={{ margin: "10px" }}>{data.error}</h3>
          </div>
          {data.canReload && <ReplayRoundedIcon style={{ cursor: "pointer", color: "#496DFF" }} onClick={forceUpdate} />}
        </div>;
      } else if (currentChartType === "GEO" && chart.dimensions?.[0] && !(chart.dimensions[0].name.toLowerCase().includes("country"))) {
        return <div style={{ margin: "auto", display: "flex", alignItems: "center" }}>
          <ErrorRoundedIcon style={{ color: "#CC0000" }} />
          <h3 className="lato bold" style={{ margin: "10px" }}>First Dimension must be country field for GEO chart.</h3>
        </div>;
      }

      if (data.data.length === 0) {
        return <div style={{ margin: "auto", display: "flex", alignItems: "center" }}>
          <ReportProblemRoundedIcon style={{ color: "#FFCC00" }} />
          <h3 className="lato bold" style={{ margin: "10px" }}>Nothing to be displayed on this view id</h3>
        </div>;
      }

      switch (type) {
        case "LINE":
        default:
          return <SimpleLineChart data={data} comparedData={comparedData} chartStyle={chartStyle} type={channel.type} />;
        case "BAR":
          return <BarChartContainer data={data} comparedData={comparedData} chartStyle={chartStyle} type={channel.type} />;
        case "LINEBAR":
          return <LineBarContainer data={data} comparedData={comparedData} chartStyle={chartStyle} type={channel.type} />;
        case "KPI":
          return <KPIChart data={data} chartStyle={chartStyle} type={channel.type} />;
        case "FUNNEL":
          return <FunnelChart data={data} chartStyle={chartStyle} type={channel.type} />;
        case "TABLE":
          return <TableChart data={data} chartStyle={chartStyle} type={channel.type} />;
        case "ADD":
          return <div />;
      }
    }
    catch (e) {
      return <Alert severity="error">Error loading chart</Alert>;
    }
  };
  return (
    <>
      {!isAddChart ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "0",
              height: "40px"
            }}
          >
            {currentChartType !== "KPI" && (
              <p
                style={{
                  fontSize: chart.chartStyle.titleStyle.fontSize,
                  fontFamily: chart.chartStyle.titleStyle.font,
                  fontWeight: chart.chartStyle.titleStyle.fontFormat.includes("bold") && "bold",
                  fontStyle: chart.chartStyle.titleStyle.fontFormat.includes('italic') && "italic",
                  textDecorationLine: chart.chartStyle.titleStyle.fontFormat.includes('underline') && "underline",
                  color: chart.chartStyle.titleStyle.color
                }}
                className="inter"
              >
                {title}
              </p>
            )}
            {currentChartType === "KPI" && <div style={{ width: "100px" }} />}
            <div className="drag-handle">
              <img src={SixDots} alt="dots" />
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ width: "68px" }}>
                {currentChartType !== "TABLE" && currentChartType !== "KPI" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: 8,
                      border: "1px solid #0869FB",
                      padding: "0px 4px",
                      borderRadius: 4,
                      cursor: "pointer",
                    }}
                    aria-label="settings"
                  >
                    <div
                      onClick={() => setIsTable(false)}
                      style={{
                        marginRight: 4,
                        borderRight: "1px solid lightgray",
                        paddingRight: 4,
                      }}
                    >
                      <img
                        src={GraphLogo}
                        alt="graph"
                        className={
                          isTable ? "not-selected-svg" : "selected-svg"
                        }
                      />
                    </div>
                    <div onClick={() => setIsTable(true)}>
                      <img
                        src={TableLogo}
                        alt="table"
                        className={
                          isTable ? "selected-svg" : "not-selected-svg"
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div data-tut="edit_chart">
                <IconButton
                  style={{ padding: 0 }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <img src={ThreeDots} alt="dots" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  className="smooth-shadow"
                  anchorEl={anchorEl}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "center" }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {!exportData ?
                    <>
                      <MenuItem
                        data-tut="edit_chart_options"
                        onClick={() => {
                          handleToggleEditDrawer(chart, handleChartChange);
                          handleClose();
                        }}
                      >
                        Edit Widget
                      </MenuItem>
                      <MenuItem onClick={handleRemoveChart}>Remove Chart</MenuItem>
                      <MenuItem
                        onClick={() => setExportData(true)}
                        disabled={!data || !data.data || data.data.length === 0}
                      >
                        Export
                      </MenuItem>
                    </> :
                    <>
                      <MenuItem style={{ paddingLeft: "10px", color: "#00000099", fontSize: "14px" }} onClick={() => setExportData(false)}><ArrowBackIcon style={{ width: "16px" }} /><p style={{ margin: "auto 5px", fontWeight: "bold" }}> Export</p> </MenuItem>
                      <MenuItem style={{ paddingLeft: "30px" }} onClick={handlePdfExport}>PDF Format</MenuItem>
                      <MenuItem style={{ paddingLeft: "30px" }} onClick={onCapturePNG}>PNG Format</MenuItem>
                      <MenuItem style={{ paddingLeft: "30px" }} onClick={onCaptureJPG}>JPG Format</MenuItem>
                      <MenuItem style={{ paddingLeft: "30px" }} onClick={handleExport}>CSV Format</MenuItem>
                    </>
                  }
                </Menu>
              </div>
            </div>
          </div>
          {isLoading ? (
            <CircularProgress disableShrink style={{ margin: "auto" }} />
          ) : (
            <div id={"Chart" + chart.id} style={{ height: "calc(100% - 60px)", width: "auto", display: "flex", overflow: "hidden" }}>
              <Errorhandler errorMessage="Error Loading Chart">
                {getChartObject(isTable ? "TABLE" : currentChartType, data)}
              </Errorhandler>
            </div>
          )}
          <img src={channel.icon} height="20" width="20" />
        </>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={removeChart} style={{ position: "absolute", right: "1.5vw", top: "2vh", }} >
            <DeleteIcon />
          </IconButton>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleChartChange("type", "ADD");
              handleToggleEditDrawer(chart, handleChartChange);
            }}
          >
            <img src={AddChartLogo} alt="Add Chart" />
            <br />
            <h4 style={{ marginTop: "10px" }} className="inter bold">
              Add Chart
            </h4>
          </div>
        </div>
      )}
    </>
  );
})

export default ChartWrapper;
