import { put, takeLatest, select, call, all, takeEvery } from "redux-saga/effects";
import { api } from "../index";

import {
  getPmaxCampaigns,
  createPmaxCampaign,
  updatePmaxCampaign,
  deletePmaxCamapaignAsset,
  updateData,
  publishSpecificPMaxCampaign
} from "./slice";
import { getLocalStorage } from "../localStorage/localStorage";
import { PMaxCampaignName } from "./enum";
import { getFormByFormIdReducer } from "../accountManager/forms/slice";
import { updateStartLoader } from "../pmaxStrategy/slice";

function* fetchPmaxCampaigns() {
  let formId = "";
  if (getLocalStorage("formId") && getLocalStorage("formId") !== "undefined") {
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  try {
    const campaignResponse = yield api.get("/pmax-ads-campaigns/" + formId);
    if (campaignResponse && campaignResponse?.data?.campaigns?.length) {
      const campaigns = campaignResponse.data.campaigns;
      let campaign1data = campaigns.find(
        (campaign) => campaign.campaignNumber === PMaxCampaignName.campaign1
      );
      let campaign1AssetGroups = [];
      if (campaign1data) {
        campaign1AssetGroups = (campaign1data.assets || []).map(asset => {
          return {
            ...asset,
            // errors: null
          }
        });
        delete campaign1data.assets;
      }
      let campaign2data = campaigns.find(
        (campaign) => campaign.campaignNumber === PMaxCampaignName.campaign2
      );
      let campaign2AssetGroups = [];
      if (campaign2data) {
        campaign2AssetGroups = (campaign2data.assets || []).map(asset => {
          return {
            ...asset,
            // errors: null
          }
        });;
        delete campaign2data.assets;
      }
      let campaign3data = campaigns.find(
        (campaign) => campaign.campaignNumber === PMaxCampaignName.campaign3
      );
      let campaign3AssetGroups = [];
      if (campaign3data) {
        campaign3AssetGroups = (campaign3data.assets || []).map(asset => {
          return {
            ...asset,
            // errors: null
          }
        });;
        delete campaign3data.assets;
      }
      yield put(
        updateData({
          campaign1: campaign1data,
          campaign2: campaign2data,
          campaign3: campaign3data,
          campaign1AssetGroups: campaign1AssetGroups,
          campaign2AssetGroups: campaign2AssetGroups,
          campaign3AssetGroups: campaign3AssetGroups,
        })
      );
    }
  } catch (error) {
    console.log("error while fetching Pmax campaign info", error.message);
  }
  yield put(
    updateData({
      isPmaxCampaignFetched: true,
    })
  );
}

function* postPMaxCampaign(action) {
  let formId = "";
  if (getLocalStorage("formId") && getLocalStorage("formId") !== "undefined") {
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const url = `/pmax-ads-campaigns/${formId}`;
  try {
    const pMaxCampaignResponse = yield api.post(url, action.payload.data);
    console.log("in saga for pMaxCampaign", pMaxCampaignResponse);
    action.payload.callback?.(null, pMaxCampaignResponse.data);
    yield put(updateStartLoader(false));
  } catch (error) {
    action.payload.callback?.(true, null);
    console.error("Error while updating form for campaign, " + error.message);
    yield put(updateStartLoader(false));
  }
}

function* putPMaxCampaign(action) {
  let formId = "";
  if (getLocalStorage("formId") && getLocalStorage("formId") !== "undefined") {
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const { campaignId, body } = action.payload;
  const url = `/pmax-ads-campaigns/${formId}/${campaignId}`;
  try {
    const pMaxCampaignResponse = yield api.put(url, body);
    console.log("in saga for pMaxCampaign", pMaxCampaignResponse);
    action.payload.callback?.(null, pMaxCampaignResponse.data);
    yield put(updateStartLoader(false));
  } catch (error) {
    action.payload.callback?.(error?.response?.data?.detail, null);
    console.error("Error while updating form for campaign, " + error.message);
    yield put(updateStartLoader(false));
  }
}

function* removePMaxCampaignAsset(action) {
  const { campaignId, assetId, callback } = action.payload;
  let formId = "";
  if (getLocalStorage("formId") && getLocalStorage("formId") !== "undefined") {
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const deleteUrl = `/pmax-ads-campaigns/${formId}/${campaignId}/assets?asset_ids=${assetId}`;
  try {
    if (assetId) {
      const response = yield api.delete(deleteUrl);
      callback?.()
      yield put(updateStartLoader(false));
    }
  } catch (err) {
    console.log("Error:", err);
    yield put(updateStartLoader(false));
  }
}

function* publishSpecificPMaxCampaignType(action) {
  const { campaignId, callback } = action.payload;

  // const store = yield select();
  // const form = ["1", "12"].includes(localStorage.getItem("agencyId")) ? store.form.form : store.hdmFormAm;
  // if(form?.generatedCampaignsInfo) { // ??
  //   const result = getGoogleCampaignObject(form.generatedCampaignsInfo);
  //   campaignObj = result[0];
  // }
  let formId = "";
  if (getLocalStorage("formId") && getLocalStorage("formId") !== "undefined") {
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const url = `/pmax-ads-campaigns/${formId}/${campaignId}:generate`;
  try {
    const response = yield api.post(url);
    callback?.(null, response.data);
  } catch (error) {
    callback?.(error, null);
    console.log(`error is`, error.message);
  }
  yield put(getFormByFormIdReducer());
}
export default function* pmaxCampaignSaga() {
  yield all([
    takeLatest(getPmaxCampaigns.type, fetchPmaxCampaigns),
    takeEvery(createPmaxCampaign.type, postPMaxCampaign),
    takeLatest(updatePmaxCampaign.type, putPMaxCampaign),
    takeLatest(deletePmaxCamapaignAsset.type, removePMaxCampaignAsset),
    takeLatest(publishSpecificPMaxCampaign.type, publishSpecificPMaxCampaignType),
  ]);
}
