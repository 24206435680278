import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { api } from "../../index";
import {
  generateKeywordsReducer,
  getKeywordsReducer,
  updateCampaignsData,
  updateKeywordStatusReducer,
  updateKeywordsStatusReducer,
  editKeywordStatusReducer,
  updateHasCalledTAI,
} from "./slice";
import { getLocalStorage } from "../../localStorage/localStorage";
import { getFormByFormIdReducer } from "../forms/slice";
import { updateStartLoader } from "../../pmaxStrategy/slice";

function* generateKeywords(action) {
  let formId = "";
  try{
    if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
      formId = getLocalStorage("formId");
    } else {
      formId = getLocalStorage("amSelectedFormId");
    }
    const url = `/targeting-keywords/google-seach-ads/${formId}`;
    const targetResponse = yield api.post(url);
    yield put(getFormByFormIdReducer());
    console.log("targetResponse while generating keywords", targetResponse);
    yield put(updateStartLoader(false));
  } catch(error){
    console.log("error while  generating google TAI keywords", error.message);
    yield put(updateStartLoader(false));
  }
}

function* getKeywords(action) {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  try{
    const targetResponse = yield api.get(`/targeting-keywords/google-seach-ads/${formId}`);
    yield put(updateCampaignsData(targetResponse.data.campaigns));
    yield put(updateHasCalledTAI(true));
  }catch(error){
    console.log("error while fetching google TAI keywords", error.message);
  }
}

function* updateKeywordStatus(action) {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const { campaignName, clusterName, keyword, status } = action.payload;
  const url = `/targeting-keywords/${formId}/campaigns/${campaignName}/adgroup_clusters/${clusterName}/keywords/${keyword}:${status}`;
  const targetResponse = yield api.put(url);
  if (targetResponse.data.success) {
    yield put(getKeywordsReducer());
  }
}

function* editKeywordStatus(action) {
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const { items } =
    action.payload;
  let url = `/targeting-keywords/google-seach-ads/${formId}`;
  yield api.put(url, {items});
}

function* updateKeywordsStatus(action) {
  const { keywords, campaignName, clusterName, status } = action.payload;
  let formId = "";
  if(getLocalStorage("formId") && getLocalStorage("formId") !== "undefined"){
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  yield* keywords.map(function* (keyword) {
    const url = `/targeting-keywords/${formId}/campaigns/${campaignName}/adgroup_clusters/${clusterName}/keywords/${keyword}:${status}`;
    yield api.put(url);
  });
  yield put(getKeywordsReducer());
}
export default function* targetAISaga() {
  yield takeLatest(generateKeywordsReducer.type, generateKeywords);
  yield takeLatest(getKeywordsReducer.type, getKeywords);
  yield takeEvery(updateKeywordStatusReducer.type, updateKeywordStatus);
  yield takeLatest(updateKeywordsStatusReducer.type, updateKeywordsStatus);
  yield takeLatest(editKeywordStatusReducer.type, editKeywordStatus);
}
