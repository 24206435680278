import React, { useState, useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  Font,
  Svg,
} from "@react-pdf/renderer";
import mLightFont from "../../assets/fonts/Montserrat-Light.ttf";
import mRegularFont from "../../assets/fonts/Montserrat-Regular.ttf";
import mMediumItalic from "../../assets/fonts/Montserrat-MediumItalic.ttf";
import mMediumFont from "../../assets/fonts/Montserrat-Medium.ttf";
import mSemiBoldFont from "../../assets/fonts/Montserrat-SemiBold.ttf";
import mBoldFont from "../../assets/fonts/Montserrat-Bold.ttf";
import mExtraBoldFont from "../../assets/fonts/Montserrat-Black.ttf";
import StarLeft from "../../assets/images/LDProposal_images/star_left.png";
import StarRight from "../../assets/images/LDProposal_images/star_right.png";
import Penji from "../../assets/images/LDProposal_images/penji.jpeg";
import BrightBox from "../../assets/images/LDProposal_images/brightbox.jpeg";
import PenjiTag from "../../assets/images/LDProposal_images/penji_tag.png";
import BrightTag from "../../assets/images/LDProposal_images/brightbox_tag.png";
import OrderMark from "../../assets/images/LDProposal_images/ordermark.jpeg";
import OrderTag from "../../assets/images/LDProposal_images/ordermark_tag.png";
import GAds from "../../assets/images/LDProposal_images/google_ads.png";
import Asana from "../../assets/images/LDProposal_images/asana.png";
import People from "../../assets/images/LDProposal_images/expectation.png";
import Speaker from "../../assets/images/LDProposal_images/speaker.png";
import PPC from "../../assets/images/LDProposal_images/ppc_tag.png";
import Network from "../../assets/images/LDProposal_images/networkppc_tag.png";
import Contract from "../../assets/images/LDProposal_images/contractppc_tag.png";
import SSL from "../../assets/images/LDProposal_images/spaceshipstar_left.png";
import SSR from "../../assets/images/LDProposal_images/spaceshipstar_right.png";
import UFO from "../../assets/images/LDProposal_images/spaceship.png";
import ThankYou from "../../assets/images/LDProposal_images/thankyou_star.png";
import Swydo from "../../assets/images/LDProposal_images/swydo.png";
import Strategy from "../../assets/images/LDProposal_images/strategy.png";
import Bing from "../../assets/images/LDProposal_images/paidsearch.png";
import Social from "../../assets/images/LDProposal_images/paidsocial.png";
import ArrowDown from "../../assets/images/LDProposal_images/arrow_down.png";
import { Green_Checked_Icon as checkedIcon } from "../../assets/icons/common/common";
import ArrowCurve from "../../assets/images/LDProposal_images/arrowcurve.png";
import checkedTimeframeIcon from "../../assets/images/LDProposal_images/Ic/Close.png";
import crossTimeframeIcon from "../../assets/images/LDProposal_images/Ic/Ic/Close.png";
import { socialMediaDesignMonthsCount } from "../../utils/LDProposalUtils";
import oppOverImage from "../opp-overview.png";
const styles = StyleSheet.create({
  introView: {
    backgroundColor: "#171743",
    position: "relative",
  },
  projectScopeView: {
    paddingTop: 32,
    paddingBottom: 15,
    paddingLeft: 74,
    paddingRight: 74,
  },
  ppcPlanView: {
    paddingTop: 32,
    paddingBottom: 15,
    paddingLeft: 74,
    paddingRight: 74,
  },
  pageNumber: {
    fontSize: 12,
    color: "grey",
  },
  bullet: {
    height: "100%",
    marginRight: 8,
  },
  leftRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: 13,
    fontFamily: "OpenSans",
    color: "#272727",
    letterSpacing: -0.13,
    lineHeight: 1.4,
    marginBottom: 6,
  },
  croLeftRow: {
    display: "flex",
    flexDirection: "row",
    fontSize: 13,
    fontFamily: "OpenSans",
    color: "#272727",
    letterSpacing: -0.13,
    lineHeight: 1.4,
    marginBottom: 16,
    width: 210,
  },
  timeFrameView: {
    paddingTop: 32,
    paddingHorizontal: 28,
  },
  timeFrameHeadlineContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1.5px solid #333333",
    paddingBottom: 3,
  },
  timeFrameTextHeading: {
    color: "#333333",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 22,
  },
  timeFrameDataIndication: {
    fontSize: 8,
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#333333",
  },
});

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: mLightFont,
      format: "truetype",
      fontWeight: 300,
    },
    {
      src: mRegularFont,
      format: "truetype",
      fontWeight: 400,
    },
    {
      src: mMediumItalic,
      format: "truetype",
      fontWeight: 500,
      fontStyle: "italic",
    },
    {
      src: mMediumFont,
      format: "truetype",
      fontWeight: 500,
    },
    {
      src: mSemiBoldFont,
      format: "truetype",
      fontWeight: 600,
    },
    {
      src: mBoldFont,
      format: "truetype",
      fontWeight: 700,
    },
    {
      src: mExtraBoldFont,
      format: "truetype",
      fontWeight: 800,
    },
  ],
});

Font.register({
  family: "OpenSans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: "bold",
    },
  ],
});

const LDGeneratePdf = ({
  proposal,
  dateOfProposal,
  mappedIndustry,
  selectedPricing,
  baseService,
  mappedAddons,
  totalAddonPrice,
  timeframeData = {},
  reviewPoints,
  form,
}) => {
  // console.log(mappedIndustry);
  return (
    <Document>
      {/* Intro Page  */}
      <Page style={styles.introView} size="A4" orientation="landscape">
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            paddingTop: 142,
          }}
        >
          <View
            style={{
              borderRight: "2px solid white",
              paddingRight: 64,
              marginRight: 64,
            }}
          >
            <Text
              style={{
                fontSize: 48,
                color: "white",
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              linear
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: "white",
                marginTop: -4,
                fontFamily: "Montserrat",
                fontWeight: 300,
              }}
            >
              an Aiquire Inc. ("Pixis") brand
            </Text>
          </View>
          <View>
            <View>
              <Text
                style={{
                  fontSize: 12,
                  color: "rgb(241, 73, 76)",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                STATEMENT OF WORK (“SOW”) FOR
              </Text>
              <Text
                style={{
                  fontSize: 24,
                  color: "white",
                  marginTop: 12,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                marginTop: 80,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "rgb(241, 73, 76)",
                  marginBottom: 4,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                PREPARED FOR
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontStyle: "normal",
                }}
              >
                {proposal?.onboardingInfo?.personalInfo?.primaryContactName}
              </Text>
            </View>
            <View
              style={{
                marginTop: 42,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  color: "rgb(241, 73, 76)",
                  marginBottom: 4,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                PREPARED BY
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "white",
                  marginBottom: 4,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontStyle: "normal",
                }}
              >
                Luke Heinecke
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontStyle: "italic",
                }}
              >
                Mid Market Manager
              </Text>
            </View>
            <View
              style={{
                marginTop: 32,
                backgroundColor: "rgb(241, 73, 76)",
                color: "white",
                fontSize: 10,
                padding: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontStyle: "normal",
              }}
            >
              <Text>{dateOfProposal}</Text>
            </View>
          </View>
        </View>

        <Image
          src={StarLeft}
          style={{
            width: 253,
            height: 401,
            position: "absolute",
            left: -48,
            bottom: -32,
            transform: "rotate(-16deg)",
          }}
        />
        <Image
          src={StarRight}
          style={{
            width: 344,
            height: 205,
            position: "absolute",
            right: -92,
            top: 0,
            transform: "rotate(-125deg)",
          }}
        />
      </Page>
      {/* Case Studies  */}
      <Page size="A4" orientation="landscape">
        <View
          style={{
            color: "rgb(241, 73, 76)",
            paddingLeft: 48,
            paddingBottom: 32,
            paddingTop: 48,
            fontSize: 12,
            backgroundColor: "rgb(23, 23, 67)",
          }}
        >
          <Text
            style={{
              marginBottom: 8,
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            CASE STUDIES
          </Text>
          <Text
            style={{
              fontSize: 28,
              fontFamily: "Montserrat",
              fontWeight: 700,
              color: "white",
              width: "50%",
            }}
          >
            We’ve Done Amazing Work For Companies Like Yours
          </Text>
        </View>
        <Text
          style={{
            fontSize: 10,
            marginLeft: 48,
            marginTop: 28,
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
        >
          Here are a few examples for you to check out. You can visit our{" "}
          <Link
            src="https://lineardesign.com/case-studies/"
            style={{
              textDecoration: "none",
            }}
          >
            <Text
              style={{
                color: "rgb(89, 186, 250)",
              }}
            >
              case studies
            </Text>
          </Link>{" "}
          page to see more.
        </Text>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            padding: 48,
            paddingVertical: 38,
          }}
        >
          {mappedIndustry.has(
            proposal?.onboardingInfo?.personalInfo?.industry?.trim()
          ) &&
            mappedIndustry
              .get(proposal?.onboardingInfo?.personalInfo?.industry.trim())
              .links.map((link, index) => {
                return (
                  <View
                    style={{
                      width: "33.33%",
                      marginRight: 24,
                      fontSize: 8,
                      position: "relative",
                    }}
                  >
                    <View
                      style={{
                        position: "relative",
                      }}
                    >
                      {/* <View
                        style={{
                          width: 120,
                          height: 36,
                          backgroundColor: 'rgb(243, 243, 245)',
                          paddingVertical: 4,
                          position: 'absolute',
                          top: -18,
                          left: 6,
                        }}
                      >
                        <Svg
                          src={
                            mappedIndustry.get(
                              proposal?.onboardingInfo?.personalInfo?.industry.trim()
                            ).logo[index]
                          }
                          width={'100%'}
                          height={'100%'}
                          style={{
                            objectFit: 'contain',
                            zIndex: 99,
                          }}
                        />
                      </View> */}
                      <Image
                        style={{
                          zIndex: 1,
                        }}
                        src={
                          mappedIndustry.get(
                            proposal?.onboardingInfo?.personalInfo?.industry.trim()
                          ).imgs[index]
                        }
                      />
                      <View
                        style={{
                          fontSize: 10,
                          backgroundColor: "rgb(241, 73, 76)",
                          color: "white",
                          width: 80,
                          paddingVertical: 6,
                          paddingHorizontal: 10,
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                        }}
                      >
                        <Link
                          src={
                            mappedIndustry.get(
                              proposal?.onboardingInfo?.personalInfo?.industry.trim()
                            ).links[index]
                          }
                          style={{
                            textDecoration: "none",
                          }}
                        >
                          <Text
                            style={{
                              fontFamily: "Montserrat",
                              fontWeight: 800,
                              textAlign: "center",
                              color: "white",
                            }}
                          >
                            VIEW &gt;
                          </Text>
                        </Link>
                      </View>
                    </View>
                    <Text
                      style={{
                        marginTop: 24,
                        marginBottom: 12,
                        lineHeight: 1.4,
                        paddingRight: 10,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      “
                      {
                        mappedIndustry.get(
                          proposal?.onboardingInfo?.personalInfo?.industry.trim()
                        ).testimony[index]
                      }
                      ”
                    </Text>
                    <Text
                      style={{
                        marginBottom: 3.5,
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                      }}
                    >
                      {
                        mappedIndustry.get(
                          proposal?.onboardingInfo?.personalInfo?.industry.trim()
                        ).head[index]
                      }
                    </Text>
                    <Text
                      style={{
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      {
                        mappedIndustry.get(
                          proposal?.onboardingInfo?.personalInfo?.industry.trim()
                        ).subTitle[index]
                      }
                    </Text>
                  </View>
                );
              })}
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Oppurtunities Overview  */}
      {(form?.googleAuditData?.showAuditPointer ||
        form?.googleAuditData?.showAddVideo) && (
        <Page size="A4" orientation="landscape">
          <View
            style={{
              color: "#F1484C",
              paddingLeft: 48,
              paddingBottom: 48,
              paddingTop: 16,
              fontSize: 12,
              backgroundColor: "rgb(240, 249, 255)",
            }}
          >
            <Text
              style={{
                marginBottom: 8,
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              Opportunites Overview
            </Text>
          </View>
          <View>
            {form?.googleAuditData?.showAddVideo && (
              <Link
                // src='https://www.loom.com/share/f6a79b149325449fa76b5e37b5426e7c'
                src={form?.googleAuditData?.videoLink}
                style={{
                  textDecoration: "none",
                }}
              >
                <Image
                  src={oppOverImage}
                  style={{
                    width: 600,
                    height: 290,
                    margin: "auto",
                    marginTop: -42,
                    marginBottom: 28,
                    // position: 'absolute',
                    // left: '50%',
                    // top: 20,
                    // transform: 'translateX(-50%)',
                  }}
                />
              </Link>
            )}
            {form?.googleAuditData?.showAuditPointer && (
              <View
                style={{
                  width: "60%",
                  margin: "auto",
                  marginTop: !form?.googleAuditData?.showAddVideo ? 128 : "",
                }}
              >
                {reviewPoints?.map((reviewPoint) => (
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      {reviewPoint}
                    </Text>
                  </View>
                ))}
              </View>
            )}
          </View>
          <View
            style={{
              position: "absolute",
              bottom: 0,
              backgroundColor: "rgb(243, 243, 245)",
              width: "100%",
              paddingVertical: 8,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 48,
                }}
              >
                <Text
                  style={{
                    marginRight: 22,
                    fontSize: 24,
                    paddingTop: -3,
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                  }}
                >
                  linear
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                >
                  SOW for {proposal?.onboardingInfo?.personalInfo?.company}
                </Text>
              </View>
              <View
                style={{
                  paddingRight: 64,
                }}
              >
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber }) => `${pageNumber}`}
                  fixed
                />
              </View>
            </View>
          </View>
        </Page>
      )}
      {/* PPC Plan  */}
      <Page size="A4" orientation="landscape">
        <View style={styles.ppcPlanView}>
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 13,
              paddingBottom: 17,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            Pay Per Plan(PPC) PLAN
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View
              style={{
                width: "50%",
                backgroundColor: "rgb(240, 249, 255)",
                marginRight: 20,
                paddingLeft: 34,
                paddingTop: 34,
                paddingBottom: 17,
                paddingRight: 32,
                borderRadius: 5,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  marginBottom: 12,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                Paid Search: Google Ads
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginBottom: 8,
                  lineHeight: 1.45,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                We'll structure your account with the perfect amount of
                granularity using a technique called Specific Intent Campaigns.
                Then we'll link your Google Analytics account and import
                audiences for RLSA (retargeting lists for search ads). We'll
                also add the most relevant interests in observation mode to make
                bid adjustments on the most/least valuable segments. Finally,
                we'll send traffic from these campaigns to landing pages that
                are optimized to convert.
              </Text>
              <View
                style={{
                  width: 130,
                  textAlign: "center",
                  backgroundColor: "rgb(241, 73, 76)",
                  paddingVertical: 3,
                  paddingHorizontal: 6,
                  fontSize: 9,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                <Text>Campaigns we'll create:</Text>
              </View>
              <View
                style={{
                  marginTop: 15,
                }}
              >
                <View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Branded campaign:
                      </Text>{" "}
                      bidding on your branded terms to prevent competitors from
                      taking your spot and capture searches generated from brand
                      awareness campaigns.
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Competitor campaign:
                      </Text>{" "}
                      bidding on your competitor's brand terms to create
                      awareness and steal market share.
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Services campaign (US):
                      </Text>{" "}
                      bidding on high intent keywords
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Broad experimental campaigns:
                      </Text>{" "}
                      Testing RLSA + Broad Match, and DSA campaigns to find new
                      search terms.
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Retargeting Display campaign:
                      </Text>{" "}
                      Follow people around with banner ads who view key pages or
                      perform micro conversions, but don't convert on the
                      primary offer.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "50%",
                backgroundColor: "rgb(219, 241, 255)",
                marginRight: 20,
                paddingLeft: 34,
                paddingTop: 58,
                paddingBottom: 17,
                paddingRight: 32,
                borderRadius: 5,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  marginBottom: 16,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                Paid Social
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginBottom: 28,
                  lineHeight: 1.45,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                We'll create Facebook Ad campaigns, new audiences, write copy,
                and design creative.
              </Text>
              <View
                style={{
                  width: 220,
                  textAlign: "center",
                  backgroundColor: "rgb(241, 73, 76)",
                  paddingVertical: 3,
                  paddingHorizontal: 6,
                  fontSize: 9,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                <Text>Additional Strategies & Tactics we'll use:</Text>
              </View>
              <View
                style={{
                  marginTop: 18,
                }}
              >
                <View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Write new ad copy and design new creative
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Test different ad formats (carousel, single image, video,
                      etc.)
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Test Lifetime budgets v. Daily budgets
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Fix limited custom audiences and create new saved
                      audiences to test against LALs
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Create a campaign to drive warmer traffic to collect email
                      addresses for retargeting campaigns
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Implement successful retargeting campaigns
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Creative Selection Strategy  */}
      <Page size="A4" orientation="landscape">
        <View
          style={{
            height: "100%",
            width: 280,
            backgroundColor: "rgb(240, 249, 255)",
            position: "absolute",
            right: 0,
          }}
        ></View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingLeft: 72,
            paddingTop: 84,
          }}
        >
          <View
            style={{
              width: "30%",
              marginRight: 50,
              marginTop: 76,
            }}
          >
            <Text
              style={{
                fontSize: 11,
                color: "rgb(241, 73, 76)",
                marginBottom: 8,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              CREATIVE SELECTION & STRATEGY
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              We'll Design Your Ads For Every Platform
            </Text>
            <Text
              style={{
                fontSize: 8,
                lineHeight: 1.5,
                marginTop: 18,
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              Our team of designers will design new ads for all your paid social
              and display campaigns. You'll be involved with access to comment
              and approve the designs. Check out our{" "}
              <Link src="https://dribbble.com/LinearTeam">
                <Text
                  style={{
                    color: "rgb(89, 186, 250)",
                  }}
                >
                  Dribbble
                </Text>
              </Link>{" "}
              to see some of the epic ad creative we’ve designed for other
              clients.
            </Text>
          </View>
          <View
            style={{
              width: 377,
              height: 377,
            }}
          >
            <Image
              src={GAds}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* CRO Plan  */}
      <Page size="A4" orientation="landscape">
        <View style={styles.ppcPlanView}>
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 13,
              paddingBottom: 17,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            Conversion Ration Optimization(CRO) PLAN
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <View
              style={{
                width: "50%",
                backgroundColor: "rgb(240, 249, 255)",
                marginRight: 20,
                paddingLeft: 34,
                paddingTop: 32,
                paddingBottom: 17,
                paddingRight: 48,
                borderRadius: 5,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  marginBottom: 16,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                Landing Page Design + Optimization
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginBottom: 18,
                  lineHeight: 1.45,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                We'll create landing pages to match the design and messaging of
                your ads then we'll use A/B testing, multivariate testing, and
                smart traffic to improve conversions exponentially.
              </Text>
              <View
                style={{
                  width: 220,
                  textAlign: "center",
                  backgroundColor: "rgb(241, 73, 76)",
                  paddingVertical: 3,
                  paddingHorizontal: 6,
                  fontSize: 9,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                <Text>Landing Page strategies & tactics we'll use:</Text>
              </View>
              <View
                style={{
                  marginTop: 15,
                }}
              >
                <View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Multi-step forms that break up the ﬁelds you’re using and
                      improve conversion rates
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      High tempo testing to always focus on having a variant
                      running alongside all landing page tests.
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Test two-step pages and drive direct conversions after
                      content is downloaded (The Wedge Method)
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Review heatmaps, user recordings, and user surveys to
                      constantly improve conversion rates
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Dynamic text replacement for personalization and improved
                      message match
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View
              style={{
                width: "50%",
                backgroundColor: "rgb(219, 241, 255)",
                marginRight: 20,
                paddingLeft: 34,
                paddingTop: 32,
                paddingBottom: 17,
                paddingRight: 48,
                borderRadius: 5,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  marginBottom: 8,
                  width: 40,
                  lineHeight: 1.25,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                Smart Pop-Ups
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginBottom: 18,
                  lineHeight: 1.5,
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                Pop-ups can improve site-wide conversions even from non-paid
                traffic sources. We'll design, write, and constantly test time
                and activity-based smart pop-ups to lower bounce rates and boost
                conversions.
              </Text>
              <View
                style={{
                  fontSize: 9,
                  width: 130,
                  textAlign: "center",
                  backgroundColor: "rgb(241, 73, 76)",
                  paddingVertical: 3,
                  paddingHorizontal: 3,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                <Text>How we'll use pop-ups:</Text>
              </View>
              <View
                style={{
                  marginTop: 18,
                }}
              >
                <View>
                  <View style={styles.croLeftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Scroll depth:
                      </Text>{" "}
                      when a visitor scrolls a certain percentage on a page
                    </Text>
                  </View>
                  <View style={styles.croLeftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Time delay:
                      </Text>{" "}
                      when a visitor visits a page for a specific amount of time
                    </Text>
                  </View>
                  <View style={styles.croLeftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <>
                      <Text
                        style={{
                          fontSize: 8,
                          fontFamily: "Montserrat",
                          fontWeight: 600,
                        }}
                      >
                        <Text
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          Retargeting:
                        </Text>{" "}
                        when a visitor has been to the page multiple times
                      </Text>
                    </>
                  </View>
                  <View style={styles.croLeftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        Exit Intent:
                      </Text>{" "}
                      before the visitor exits the page
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Project Scope Core Services  */}
      <Page size="A4" orientation="landscape">
        <View style={styles.projectScopeView}>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(241, 73, 76)",
              marginBottom: 8,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            PROJECT SCOPE
          </Text>
          <Text
            style={{
              fontSize: 12,
              color: "rgb(241, 73, 76)",
              marginBottom: 8,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            <Text
              style={{
                color: "rgb(241, 73, 76)",
                textDecoration: "underline",
              }}
            >
              TERM:
            </Text>{" "}
            <Text
              style={{
                color: "rgb(0, 120, 212)",
              }}
            >
              The SOW and the Terms and Conditions shall be effective upon
              acceptance of this SoW and the Terms and Conditions and shall
              continue to be effective for 6 months.
            </Text>
          </Text>
          <Text
            style={{
              fontSize: 28,
              marginBottom: 16,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            Core Services
          </Text>
          <View
            style={{
              fontSize: 8,
              width: "100%",
              backgroundColor: "rgb(89, 186, 250)",
              display: "flex",
              flexDirection: "row",
              color: "white",
              paddingVertical: 3,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            <View
              style={{
                width: "31%",
              }}
            >
              <Text></Text>
            </View>
            {["Starter", "Growth", "Premier"]?.map((addOn, index) => (
              <View
                style={{
                  width: "23%",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <Text>{addOn}</Text>
                {selectedPricing(
                  proposal?.sowPricingInfo?.clientPricing?.adSpendRange
                ) === addOn && (
                  <Image
                    style={{
                      position: "absolute",
                      width: 10,
                      height: 10,
                      top: -0.65,
                      right: 50,
                    }}
                    src="https://res.cloudinary.com/dl13567vi/image/upload/v1676358267/GreenCheckedIcon_nestqc.png"
                  />
                )}
              </View>
            ))}
          </View>
          <View
            style={{
              fontSize: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Text
              style={{
                width: "31%",
                textAlign: "left",
                paddingLeft: 4,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Ad Spend Range
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              5k - 25k
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              25k - 75k
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              75k - 150k
            </Text>
          </View>
          <View
            style={{
              fontSize: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Text
              style={{
                width: "31%",
                textAlign: "left",
                paddingLeft: 4,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Landing Pages
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Starter"
                ? baseService(
                    "Landing Pages",
                    proposal?.sowPricingInfo?.clientPricing?.baseServices
                  )
                : "1-2"}
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Growth"
                ? baseService(
                    "Landing Pages",
                    proposal?.sowPricingInfo?.clientPricing?.baseServices
                  )
                : "1-3"}
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Premier"
                ? baseService(
                    "Landing Pages",
                    proposal?.sowPricingInfo?.clientPricing?.baseServices
                  )
                : "1-5"}
            </Text>
          </View>
          <View
            style={{
              fontSize: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Text
              style={{
                width: "31%",
                textAlign: "left",
                paddingLeft: 4,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Pop-Ups
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Starter"
                ? baseService(
                    "Pop-up",
                    proposal?.sowPricingInfo?.clientPricing?.baseServices
                  )
                : "1"}
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Growth"
                ? baseService(
                    "Pop-up",
                    proposal?.sowPricingInfo?.clientPricing?.baseServices
                  )
                : "1"}
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Premier"
                ? baseService(
                    "Pop-up",
                    proposal?.sowPricingInfo?.clientPricing?.baseServices
                  )
                : "1-2"}
            </Text>
          </View>
          <View
            style={{
              fontSize: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Text
              style={{
                width: "31%",
                textAlign: "left",
                paddingLeft: 4,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Sticky Bars
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Starter"
                ? baseService(
                    "Sticky Bar",
                    proposal?.sowPricingInfo?.clientPricing?.baseServices
                  )
                : "1"}
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Growth"
                ? baseService(
                    "Sticky Bar",
                    proposal?.sowPricingInfo?.clientPricing?.baseServices
                  )
                : "1"}
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Premier"
                ? baseService(
                    "Sticky Bar",
                    proposal?.sowPricingInfo?.clientPricing?.baseServices
                  )
                : "1-2"}
            </Text>
          </View>
          <View
            style={{
              fontSize: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              paddingTop: 4,
              paddingBottom: 4,
            }}
          >
            <Text
              style={{
                width: "31%",
                textAlign: "left",
                paddingLeft: 4,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Monthly Fee
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
                paddingHorizontal: 24,
              }}
            >
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Starter"
                ? "$" +
                  (parseInt(
                    proposal?.sowPricingInfo?.clientPricing?.basePricePerMonth
                  ) -
                    parseInt(
                      totalAddonPrice(
                        proposal?.sowPricingInfo?.clientPricing?.addonServices
                      )
                    ))
                : "$4,000"}
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
                paddingHorizontal: 24,
              }}
            >
              <Text
                style={{
                  color: "rgb(0, 0, 0)",
                  fontWeight: 500,
                }}
              >
                Higher of
              </Text>{" "}
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Growth"
                ? "$" +
                  (parseInt(
                    proposal?.sowPricingInfo?.clientPricing?.basePricePerMonth
                  ) -
                    parseInt(
                      totalAddonPrice(
                        proposal?.sowPricingInfo?.clientPricing?.addonServices
                      )
                    ))
                : "$5250"}{" "}
              <Text
                style={{
                  color: "rgb(0, 0, 0)",
                  fontWeight: 500,
                }}
              >
                or{" "}
                <Text
                  style={{
                    fontWeight: 600,
                  }}
                >
                  10%
                </Text>{" "}
                of Media Spend
              </Text>
            </Text>
            <Text
              style={{
                width: "23%",
                textAlign: "center",
                fontFamily: "Montserrat",
                fontWeight: 600,
                paddingHorizontal: 24,
              }}
            >
              <Text
                style={{
                  color: "rgb(0, 0, 0)",
                  fontWeight: 500,
                }}
              >
                Higher of
              </Text>{" "}
              {selectedPricing(
                proposal?.sowPricingInfo?.clientPricing?.adSpendRange
              ) === "Premier"
                ? "$" +
                  (parseInt(
                    proposal?.sowPricingInfo?.clientPricing?.basePricePerMonth
                  ) -
                    parseInt(
                      totalAddonPrice(
                        proposal?.sowPricingInfo?.clientPricing?.addonServices
                      )
                    ))
                : "$12,500"}{" "}
              <Text
                style={{
                  color: "rgb(0, 0, 0)",
                  fontWeight: 500,
                }}
              >
                or{" "}
                <Text
                  style={{
                    fontWeight: 600,
                  }}
                >
                  10%
                </Text>{" "}
                of Media Spend
              </Text>
            </Text>
          </View>
          <Text
            style={{
              color: "rgb(89, 186, 250)",
              fontSize: 8,
              marginTop: 22,
              marginBottom: 12,
              borderBottom: "2px solid rgb(215, 223, 224)",
              paddingLeft: 6,
              paddingBottom: 5,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            Optional Add-Ons
          </Text>
          {proposal?.sowPricingInfo?.clientPricing?.addonServices?.map(
            (addOn, index) => (
              <View
                style={{
                  fontSize: 8,
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 14,
                }}
              >
                <Text
                  style={{
                    width: "31%",
                    textAlign: "left",
                    paddingLeft: 8,
                    color: "rgb(34, 34, 34)",
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                  }}
                >
                  {mappedAddons[addOn.service]}
                </Text>
                <Text
                  style={{
                    width: "23%",
                    textAlign: "center",
                    color: "rgb(34, 34, 34)",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                >
                  {"$" + addOn.price + "/month"}
                </Text>
                <Text
                  style={{
                    width: "23%",
                    textAlign: "center",
                    color: "rgb(34, 34, 34)",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                >
                  {["Call Rail", "Hotjar", "Grow forms"].some(
                    (elem) => elem === mappedAddons[addOn.service]
                  )
                    ? addOn.selected
                      ? 1
                      : 0
                    : addOn.quantity}
                </Text>
                <Text
                  style={{
                    width: "23%",
                    textAlign: "center",
                    color: "rgb(34, 34, 34)",
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                >
                  {"$" +
                    parseInt(
                      ["Call Rail", "Hotjar", "Grow forms"].some(
                        (elem) => elem === mappedAddons[addOn.service]
                      )
                        ? addOn.selected
                          ? "1"
                          : "0"
                        : addOn.quantity
                    ) *
                      addOn.price +
                    "/month"}
                </Text>
              </View>
            )
          )}

          <View
            style={{
              fontSize: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              marginBottom: 8,
              marginTop: 60,
            }}
          >
            <Text
              style={{
                width: "50%",
                textAlign: "left",
                paddingLeft: 4,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Optional Add-Ons
            </Text>
            <Text
              style={{
                width: "50%",
                textAlign: "right",
                paddingRight: 4,
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              {"$" +
                "" +
                totalAddonPrice(
                  proposal?.sowPricingInfo?.clientPricing?.addonServices
                )}
            </Text>
          </View>

          <View
            style={{
              fontSize: 8,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              backgroundColor: "rgb(241, 73, 76)",
              marginBottom: 14,
              color: "white",
              paddingVertical: 4,
            }}
          >
            <Text
              style={{
                width: "50%",
                textAlign: "left",
                paddingLeft: 8,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              TOTAL PER MONTH (INCLUDING OPTIONAL ADD-ONS)
            </Text>
            <Text
              style={{
                width: "50%",
                textAlign: "right",
                paddingRight: 8,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              ${proposal?.sowPricingInfo?.clientPricing?.basePricePerMonth}
            </Text>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Project Timeline  */}
      <Page size="A4" orientation="landscape">
        <View
          style={{
            height: "100%",
            width: 280,
            backgroundColor: "rgb(240, 249, 255)",
            position: "absolute",
            right: 0,
          }}
        ></View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            paddingLeft: 72,
            paddingTop: 84,
          }}
        >
          <View
            style={{
              width: "30%",
              marginRight: 50,
              marginTop: 76,
            }}
          >
            <Text
              style={{
                fontSize: 11,
                color: "rgb(241, 73, 76)",
                marginBottom: 8,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              PROJECT TIMELINE
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Always Know What Our Team Is Working On
            </Text>
            <Text
              style={{
                fontSize: 8,
                lineHeight: 1.4,
                marginTop: 18,
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              Below you’ll find an outline of what to expect during the first
              few months of working with us. After we kick-off, everything will
              be built into an Asana project so you can see each and every task.
              You’ll know what we’re working on and when it will be completed at
              all times.
            </Text>
          </View>
          <View
            style={{
              width: 377,
              height: 377,
            }}
          >
            <Image
              src={Asana}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/*TimeFrame*/}
      <Page size="A4" orientation="landscape">
        <View style={styles.timeFrameView}>
          <View style={styles.timeFrameHeadlineContainer}>
            <Text style={styles.timeFrameTextHeading}>Timeframe</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                paddingRight: 42,
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 28,
                }}
              >
                <View
                  style={{
                    width: 18,
                    height: 18,
                    backgroundColor: "rgb(89, 186, 250)",
                    marginRight: 6,
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>Paid Social</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 28,
                }}
              >
                <View
                  style={{
                    width: 18,
                    height: 18,
                    backgroundColor: "rgb(250, 210, 90)",
                    marginRight: 6,
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>Paid Search</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <View
                  style={{
                    width: 18,
                    height: 18,
                    backgroundColor: "rgb(241, 73, 76)",
                    marginRight: 6,
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>CRO</Text>
              </View>
            </View>
          </View>
          {/* Headings  */}
          <View
            style={{
              width: "100%",
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 8,
              backgroundColor: "#e0e0e0",
              color: "#333333",
              display: "flex",
              flexDirection: "row",
              padding: 5,
              marginTop: 12,
            }}
          >
            <Text
              style={{
                width: "15%",
              }}
            ></Text>
            <Text
              style={{
                width: "55%",
              }}
            >
              Action Items
            </Text>
            <Text
              style={{
                width: "30%",
                textAlign: "center",
              }}
            >
              {/* This is to be set as dynamic  */}
              {timeframeData.name}
            </Text>
          </View>
          {/* Month 1  */}
          <View
            style={{
              width: "100%",
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 8,
              color: "#333333",
              display: "flex",
              flexDirection: "row",
              padding: 5,
            }}
          >
            <Text
              style={{
                width: "15%",
              }}
            >
              Month 1
            </Text>
            <View
              style={{
                width: "55%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Kick-off Call & Strategy Call + Establish Goal
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(250, 210, 90)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Campaigns Build Out
                </Text>
              </View>
              {timeframeData["1"] && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 8,
                  }}
                >
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 10,
                      backgroundColor: "rgb(241, 73, 76)",
                    }}
                  ></View>
                  <Text style={styles.timeFrameDataIndication}>
                    Conversion Rate Optimisation Designs
                  </Text>
                </View>
              )}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginBottom: 3,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(89, 186, 250)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Social Ad Designs
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "30%",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 8,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              {timeframeData["1"] && (
                <Text
                  style={{
                    textAlign: "center",
                    color: "#333333",
                    marginBottom: 8,
                  }}
                >
                  {timeframeData["1"]}
                </Text>
              )}
              <Text
                style={{
                  textAlign: "center",
                  color: "#333333",
                  // marginBottom: 3,
                }}
              >
                Up to {socialMediaDesignMonthsCount[timeframeData.name]}
              </Text>
            </View>
          </View>
          {/* Month 2  */}
          <View
            style={{
              width: "100%",
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 8,
              color: "#333333",
              display: "flex",
              flexDirection: "row",
              padding: 5,

              backgroundColor: "#f8f8f8",
            }}
          >
            <Text
              style={{
                width: "15%",
              }}
            >
              Month 2
            </Text>
            <View
              style={{
                width: "55%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(89, 186, 250)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Campaigns Build Out
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(250, 210, 90)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Weekly Paid Search Optimizations
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(241, 73, 76)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Conversion Rate Optimisation Reviews (new tests are
                  data-dependent)
                </Text>
              </View>
              {timeframeData["2"] && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // marginBottom: 3,
                  }}
                >
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 10,
                      backgroundColor: "rgb(241, 73, 76)",
                    }}
                  ></View>
                  <Text style={styles.timeFrameDataIndication}>
                    Conversion Rate Optimisation Designs
                  </Text>
                </View>
              )}
            </View>
            <View
              style={{
                width: "30%",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 8,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              {timeframeData["2"] && (
                <Text
                  style={{
                    textAlign: "center",
                    color: "#333333",
                    // marginBottom: 3,
                  }}
                >
                  {timeframeData["2"]}
                </Text>
              )}
            </View>
          </View>
          {/* Month 3 */}
          <View
            style={{
              width: "100%",
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 8,
              color: "#333333",
              display: "flex",
              flexDirection: "row",
              padding: 5,
            }}
          >
            <Text
              style={{
                width: "15%",
              }}
            >
              Month 3
            </Text>
            <View
              style={{
                width: "55%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(89, 186, 250)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Weekly Paid Social Optimizations
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(250, 210, 90)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Weekly Paid Search Optimizations
                </Text>
              </View>
              {timeframeData["3"] && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 8,
                  }}
                >
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 10,
                      backgroundColor: "rgb(241, 73, 76)",
                    }}
                  ></View>
                  <Text style={styles.timeFrameDataIndication}>
                    Conversion Rate Optimisation Designs
                  </Text>
                </View>
              )}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(241, 73, 76)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Conversion Rate Optimisation Reviews (new tests are
                  data-dependent)
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginBottom: 3,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Quarterly Business Review (Review progress & set new goal)
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "30%",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 8,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              {timeframeData["3"] && (
                <Text
                  style={{
                    textAlign: "center",
                    color: "#333333",
                    marginBottom: 8,
                  }}
                >
                  {timeframeData["3"]}
                  {/* <Image
                  src={crossTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                /> */}
                </Text>
              )}
              <Text
                style={{
                  textAlign: "center",
                  color: "#333333",
                  marginBottom: 8,
                }}
              >
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#333333",
                  // marginBottom: 3,
                }}
              >
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
            </View>
          </View>
          {/* Month 4  */}
          <View
            style={{
              width: "100%",
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 8,
              color: "#333333",
              display: "flex",
              flexDirection: "row",
              padding: 5,
              backgroundColor: "#f8f8f8",
            }}
          >
            <Text
              style={{
                width: "15%",
              }}
            >
              Month 4
            </Text>
            <View
              style={{
                width: "55%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(89, 186, 250)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Weekly Paid Social Optimizations
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(250, 210, 90)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Weekly Paid Search Optimizations
                </Text>
              </View>

              {timeframeData["4"] && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 8,
                  }}
                >
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 10,
                      backgroundColor: "rgb(241, 73, 76)",
                    }}
                  ></View>
                  <Text style={styles.timeFrameDataIndication}>
                    Conversion Rate Optimisation Designs
                  </Text>
                </View>
              )}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(241, 73, 76)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Conversion Rate Optimisation Reviews (new tests are
                  data-dependent)
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginBottom: 3,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(89, 186, 250)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Social Ad Designs
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "30%",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 8,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              {timeframeData["4"] && (
                <Text
                  style={{
                    textAlign: "center",
                    color: "#333333",
                    marginBottom: 8,
                  }}
                >
                  {timeframeData["4"]}
                  {/* <Image
                  src={crossTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                /> */}
                </Text>
              )}
              <Text
                style={{
                  textAlign: "center",
                  color: "#333333",
                  marginBottom: 8,
                }}
              >
                {/* In case of started use this image else a number  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#333333",
                  // marginBottom: 3,
                }}
              >
                Up to {socialMediaDesignMonthsCount[timeframeData.name]}
              </Text>
            </View>
          </View>
          {/* Month 5 */}
          <View
            style={{
              width: "100%",
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 8,
              color: "#333333",
              display: "flex",
              flexDirection: "row",
              padding: 5,
            }}
          >
            <Text
              style={{
                width: "15%",
              }}
            >
              Month 5
            </Text>
            <View
              style={{
                width: "55%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(89, 186, 250)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Weekly Paid Social Optimizations
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(250, 210, 90)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Weekly Paid Search Optimizations
                </Text>
              </View>

              {timeframeData["5"] && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 8,
                  }}
                >
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 10,
                      backgroundColor: "rgb(241, 73, 76)",
                    }}
                  ></View>
                  <Text style={styles.timeFrameDataIndication}>
                    Conversion Rate Optimisation Designs
                  </Text>
                </View>
              )}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginBottom: 3,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(241, 73, 76)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Conversion Rate Optimisation Reviews (new tests are
                  data-dependent)
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "30%",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 8,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              {timeframeData["5"] && (
                <Text
                  style={{
                    textAlign: "center",
                    color: "#333333",
                    marginBottom: 8,
                  }}
                >
                  {timeframeData["5"]}
                  {/* <Image
                  src={crossTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                /> */}
                </Text>
              )}
              <Text
                style={{
                  textAlign: "center",
                  color: "#333333",
                  // marginBottom: 3,
                }}
              >
                {/* In case of started use this image else a number  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
            </View>
          </View>
          {/* Month 6 */}
          <View
            style={{
              width: "100%",
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: 8,
              color: "#333333",
              display: "flex",
              flexDirection: "row",
              padding: 5,
              backgroundColor: "#f8f8f8",
            }}
          >
            <Text
              style={{
                width: "15%",
              }}
            >
              Month 6
            </Text>
            <View
              style={{
                width: "55%",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(89, 186, 250)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Weekly Paid Social Optimizations
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(250, 210, 90)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Weekly Paid Search Optimizations
                </Text>
              </View>
              {timeframeData["6"] && (
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 8,
                  }}
                >
                  <View
                    style={{
                      width: 8,
                      height: 8,
                      marginRight: 10,
                      backgroundColor: "rgb(241, 73, 76)",
                    }}
                  ></View>
                  <Text style={styles.timeFrameDataIndication}>
                    Conversion Rate Optimisation Designs
                  </Text>
                </View>
              )}
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                    backgroundColor: "rgb(241, 73, 76)",
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Conversion Rate Optimisation Reviews (new tests are
                  data-dependent)
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginBottom: 3,
                }}
              >
                <View
                  style={{
                    width: 8,
                    height: 8,
                    marginRight: 10,
                  }}
                ></View>
                <Text style={styles.timeFrameDataIndication}>
                  Quarterly Business Review + Renewal Options
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "30%",
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 8,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#5eb86b",
                  marginBottom: 8,
                }}
              >
                {/* This is to be set as dynamic  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              {timeframeData["6"] && (
                <Text
                  style={{
                    textAlign: "center",
                    color: "#333333",
                    marginBottom: 8,
                  }}
                >
                  {timeframeData["6"]}
                  {/* <Image
                  src={crossTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                /> */}
                </Text>
              )}
              <Text
                style={{
                  textAlign: "center",
                  color: "#333333",
                  marginBottom: 8,
                }}
              >
                {/* In case of started use this image else a number  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  color: "#333333",
                  // marginBottom: 9,
                }}
              >
                {/* In case of started use this image else a number  */}
                <Image
                  src={checkedTimeframeIcon}
                  style={{
                    width: 8,
                    height: 8,
                  }}
                />
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Client Expectations  */}
      <Page size="A4" orientation="landscape">
        <View
          style={{
            height: "100%",
            width: 280,
            backgroundColor: "rgb(240, 249, 255)",
            position: "absolute",
            right: 0,
          }}
        >
          <Image
            src={People}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </View>
        <View
          style={{
            width: "66.75%",
            paddingLeft: 52,
            backgroundColor: "rgb(240, 249, 255)",
            paddingTop: 40,
            paddingBottom: 28,
          }}
        >
          <View
            style={{
              width: "74%",
            }}
          >
            <Text
              style={{
                fontSize: 11,
                color: "rgb(241, 73, 76)",
                marginBottom: 8,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              CLIENT EXPECTATIONS
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              What we expect from you
            </Text>
            <Text
              style={{
                fontSize: 8,
                lineHeight: 1.4,
                marginTop: 18,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              We're super excited to kick some major butt together! We've worked
              with tons of amazing companies like yours, and learned that to get
              the most out of our relationship, a few expectations and
              understandings need to be aligned. We hope that the points below
              make as much sense to you as they do to us - because we're
              promising everything back to you as well.
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 52,
            marginTop: 26,
          }}
        >
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 22,
              paddingRight: 28,
              paddingBottom: 18,
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            1
          </Text>
          <Text
            style={{
              color: "#222222",
              fontSize: 8,
              width: "54%",
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            You agree that it's your responsibility to be brutally honest with
            us (don't be silent). We don't know how we're doing unless we know
            the good and the bad, be it reporting or results.
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 52,
            marginTop: 12,
          }}
        >
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 22,
              paddingRight: 28,
              paddingBottom: 18,
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            2
          </Text>
          <Text
            style={{
              color: "#222222",
              fontSize: 8,
              width: "54%",
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            You agree to respond to our emails in a timely manner. We’re working
            to grow your business as fast as we can, but we’ll need you to be
            responsive.
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 52,
            marginTop: 12,
          }}
        >
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 22,
              paddingRight: 28,
              paddingBottom: 18,
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            3
          </Text>
          <Text
            style={{
              color: "#222222",
              fontSize: 8,
              width: "54%",
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            All goals should be reasonable and realistic. Once we agree on a
            goal you won’t change it until our quarterly business review.
            Achieving results in paid search and conversion rate optimization
            takes time.
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 52,
            marginTop: 12,
          }}
        >
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 22,
              paddingRight: 28,
              paddingBottom: 18,
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            4
          </Text>
          <Text
            style={{
              color: "#222222",
              fontSize: 8,
              width: "54%",
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            You’ll do your best to keep email communications concise and to the
            point. Longer conversations should be scheduled as meetings instead
            of novels.
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            paddingLeft: 52,
            marginTop: 12,
          }}
        >
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 22,
              paddingRight: 28,
              paddingBottom: 18,
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            5
          </Text>
          <Text
            style={{
              color: "#222222",
              fontSize: 8,
              width: "54%",
              borderBottom: "1px solid rgb(215, 223, 224)",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            You agree to complete our weekly and monthly client satisfaction
            surveys providing honest and direct feedback so that our team can
            improve.
          </Text>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Referal Program  */}
      <Page size="A4" orientation="landscape">
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100%",
            width: "100%",
          }}
        >
          <View
            style={{
              height: "100%",
              width: "38%",
              backgroundColor: "rgb(240, 249, 255)",
              position: "absolute",
              left: 0,
              paddingTop: 56,
              paddingLeft: 48,
              paddingRight: 48,
            }}
          >
            <Text
              style={{
                fontSize: 12,
                color: "rgb(241, 73, 76)",
                marginBottom: 14,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              CUSTOMER REFERRAL PROGRAM
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Refer a
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              colleague and earn 10% of their monthly
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              contract as a
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              discount off
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              your monthly
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              contract
            </Text>
            <View
              style={{
                border: "2px solid black",
                fontSize: 8,
                width: 208,
                height: 80,
                paddingLeft: 60,
                paddingRight: 22,
                paddingTop: 18,
                lineHeight: 1.4,
                position: "relative",
                zIndex: 1,
                marginTop: 42,
                marginLeft: 14,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              <View
                style={{
                  backgroundColor: "rgb(240, 249, 255)",
                  position: "absolute",
                  width: 83,
                  height: 57,
                  top: 10,
                  left: -42,
                  zIndex: 99,
                }}
              >
                <Image
                  src={Speaker}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </View>

              <Text>There's no limit.</Text>
              <Text>Refer enough people & we'll start paying you!</Text>
            </View>
          </View>
          <View
            style={{
              width: "62%",
              height: "100%",
              position: "absolute",
              right: 0,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                marginBottom: 8,
                paddingTop: 42,
                paddingLeft: 92,
                paddingRight: 54,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              How It Works
            </Text>
            <View
              style={{
                border: "2px solid black",
                fontSize: 8,
                width: "60%",
                height: 80,
                paddingLeft: 60,
                paddingRight: 80,
                paddingTop: 20,
                lineHeight: 1.5,
                marginLeft: 92,
                marginRight: 54,
                position: "relative",
                zIndex: 1,
                marginTop: 24,
                fontFamily: "Montserrat",
                fontWeight: 600,
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  width: 52,
                  height: 44,
                  top: 10,
                  left: -24,
                  zIndex: 99,
                }}
              >
                <Image
                  src={PPC}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <View
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    width: 60,
                    height: 52,
                    top: -6,
                    left: 0,
                    zIndex: 120,
                  }}
                ></View>
              </View>

              <Text>
                You have an active PPC campaign with Linear that costs $5,000
                per month
              </Text>
              <Image
                src={ArrowDown}
                style={{
                  width: 18,
                  height: 18,
                  position: "absolute",
                  bottom: -23,
                  left: "80%",
                }}
              />
            </View>
            <View
              style={{
                border: "2px solid black",
                fontSize: 8,
                width: "60%",
                height: 80,
                paddingLeft: 60,
                paddingRight: 80,
                paddingTop: 20,
                lineHeight: 1.5,
                position: "relative",
                zIndex: 1,
                marginTop: 24,
                marginLeft: 92,
                marginRight: 54,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  width: 36,
                  height: 36,
                  top: 13.5,
                  left: -21.5,
                  zIndex: 99,
                }}
              >
                <Image
                  src={Network}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <View
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    width: 60,
                    height: 50,
                    top: -8,
                    left: -10,
                    zIndex: 120,
                  }}
                ></View>
              </View>

              <Text>
                Someone in your network needs PPC services and you refer them to
                Linear
              </Text>
              <Image
                src={ArrowDown}
                style={{
                  width: 18,
                  height: 18,
                  position: "absolute",
                  bottom: -23,
                  left: "80%",
                }}
              />
            </View>
            <View
              style={{
                border: "2px solid black",
                fontSize: 8,
                width: "60%",
                height: 80,
                paddingLeft: 60,
                paddingRight: 80,
                paddingTop: 20,
                lineHeight: 1.5,
                position: "relative",
                zIndex: 1,
                marginTop: 24,
                marginLeft: 92,
                marginRight: 54,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  width: 55,
                  height: 45,
                  top: 5.5,
                  left: -30,
                  zIndex: 99,
                }}
              >
                <Image
                  src={Contract}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
                <View
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    width: 60,
                    height: 46,
                    top: 4,
                    left: 0,
                    zIndex: 120,
                  }}
                ></View>
              </View>
              <Text>
                Your referral signs a new contract for PPC Services at $5,000
                per month
              </Text>
            </View>
            <View
              style={{
                backgroundColor: "rgb(23, 23, 67)",
                width: "100%",
                height: 280,
                marginTop: 48,
                color: "white",
                position: "relative",
                zIndex: 1,
              }}
            >
              <Image
                style={{
                  width: 33,
                  height: 40,
                  position: "absolute",
                  right: 24,
                  top: 28,
                  zIndex: 1,
                }}
                src={SSR}
              />
              <Image
                src={SSL}
                style={{
                  width: 150,
                  height: 110,
                  position: "absolute",
                  top: 4.5,
                  left: 4.5,
                  zIndex: 1,
                }}
              />
              <Image
                src={UFO}
                style={{
                  width: 178,
                  height: 154,
                  position: "absolute",
                  bottom: 42,
                  right: -10,
                  zIndex: 1,
                }}
              />
              <Image
                src={ArrowCurve}
                style={{
                  width: 90,
                  height: 100,
                  position: "absolute",
                  top: -52,
                  left: "35%",
                  transform: "rotate(45deg)",
                }}
              />
              <Text
                style={{
                  fontSize: 14,
                  color: "white",
                  width: 220,
                  position: "absolute",
                  bottom: 102,
                  left: 54,
                  lineHeight: 1.4,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  zIndex: 1,
                }}
              >
                You get a Referral Credit and your PPC is now only $4,500 per
                month!
              </Text>
            </View>
          </View>
          <View
            style={{
              position: "absolute",
              bottom: 0,
              backgroundColor: "rgb(243, 243, 245)",
              width: "100%",
              paddingVertical: 8,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 48,
                }}
              >
                <Text
                  style={{
                    marginRight: 22,
                    fontSize: 24,
                    paddingTop: -3,
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                  }}
                >
                  linear
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    fontFamily: "Montserrat",
                    fontWeight: 500,
                  }}
                >
                  SOW for {proposal?.onboardingInfo?.personalInfo?.company}
                </Text>
              </View>
              <View
                style={{
                  paddingRight: 64,
                }}
              >
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber }) => `${pageNumber}`}
                  fixed
                />
              </View>
            </View>
          </View>
        </View>
      </Page>
      {/* Thank You  */}
      <Page style={styles.introView} size="A4" orientation="landscape">
        <View
          style={{
            paddingTop: 72,
            paddingLeft: 68,
            color: "white",
          }}
        >
          <View>
            <Text
              style={{
                fontSize: 52,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              linear
            </Text>
            <Text
              style={{
                fontSize: 14,
                marginTop: -4,
                fontFamily: "Montserrat",
                fontWeight: 500,
              }}
            >
              an Aiquire Inc. ("Pixis") brand
            </Text>
          </View>
          <View
            style={{
              fontSize: 18,
              marginTop: 172,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            <Text
              style={{
                width: 180,
                lineHeight: 1.3,
              }}
            >
              We look forward to working with you.
            </Text>
          </View>
          <View
            style={{
              marginTop: 36,
            }}
          >
            <Text
              style={{
                fontSize: 10,
                color: "white",
                marginBottom: 4,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Luke Heinecke
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: "white",
                marginBottom: 4,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Mid Market Manager
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: "white",
                marginBottom: 4,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              luke@lineardesign.com
            </Text>
            <Text
              style={{
                fontSize: 10,
                color: "white",
                marginTop: 12,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              625 W Adams St Chicago, IL 60661 USA
            </Text>
            <View
              style={{
                width: 120,
                marginTop: 36,
                backgroundColor: "rgb(241, 73, 76)",
                color: "white",
                fontSize: 10,
                padding: 5,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                }}
              >
                lineardesign.com
              </Text>
            </View>
          </View>
        </View>
        <Image
          src={ThankYou}
          style={{
            width: 344,
            height: 205,
            position: "absolute",
            right: -92,
            top: 0,
            transform: "rotate(-125deg)",
          }}
        />
      </Page>
      {/* Customised Reporting  */}
      <Page size="A4" orientation="landscape">
        <View
          style={{
            height: "100%",
            width: 280,
            backgroundColor: "rgb(240, 249, 255)",
            position: "absolute",
            left: 0,
          }}
        ></View>
        <View
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            width: "100%",
            paddingRight: 72,
            paddingTop: 84,
          }}
        >
          <View
            style={{
              width: "42%",
              marginTop: 116,
              marginLeft: 32,
            }}
          >
            <Text
              style={{
                fontSize: 11,
                color: "rgb(241, 73, 76)",
                marginBottom: 8,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              CUSTOMIZED REPORTING
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontFamily: "Montserrat",
                fontWeight: 700,
              }}
            >
              Custom Reporting, Transparent Results
            </Text>
            <Text
              style={{
                fontSize: 8,
                lineHeight: 1.5,
                marginTop: 18,
                fontFamily: "Montserrat",
                fontWeight: 600,
                paddingRight: 54,
              }}
            >
              You'll stay up-to-date on the progress we make towards your goal
              with weekly updates, bi-weekly calls, and an in-depth report
              delivered every month. We'll also create and share a dashboard
              showing you the most important performance metrics, on the fly.
            </Text>
          </View>
          <View
            style={{
              width: 377,
              height: 377,
              marginLeft: 72,
            }}
          >
            <Image
              src={Swydo}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.name}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Paid CRO Plan - 1  */}
      <Page size="A4" orientation="landscape">
        <View style={styles.ppcPlanView}>
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 13,
              paddingBottom: 17,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            PAID SEARCH PLAN
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Image
              src={Strategy}
              style={{
                width: 383,
                height: 348,
                marginTop: 38,
                zIndex: 1,
              }}
            />
            <View
              style={{
                width: "60%",
                height: 440,
                backgroundColor: "rgb(240, 249, 255)",
                marginRight: 20,
                paddingLeft: 144,
                paddingTop: 32,
                paddingRight: 42,
                borderRadius: 5,
                position: "absolute",
                right: -8,
                zIndex: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  marginBottom: 16,
                  lineHeight: 1.3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                Landing Page Design + Optimization
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginBottom: 18,
                  lineHeight: 1.45,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                We'll create landing pages to match the design and messaging of
                your ads then we'll use A/B testing, multivariate testing, and
                smart traffic to improve conversions exponentially.
              </Text>
              <View
                style={{
                  width: 220,
                  textAlign: "center",
                  backgroundColor: "rgb(241, 73, 76)",
                  paddingVertical: 3,
                  paddingHorizontal: 6,
                  fontSize: 9,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                <Text>Landing Page strategies & tactics we'll use:</Text>
              </View>
              <View
                style={{
                  marginTop: 15,
                }}
              >
                <View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 200,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Multi-step forms that break up the ﬁelds you’re using and
                      improve conversion rates
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 200,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      High tempo testing to always focus on having a variant
                      running alongside all landing page tests.
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 200,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Test two-step pages and drive direct conversions after
                      content is downloaded (The Wedge Method)
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 200,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Review heatmaps, user recordings, and user surveys to
                      constantly improve conversion rates
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 220,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Dynamic text replacement for personalization and improved
                      message match
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Paid CRO Plan - 2  */}
      <Page size="A4" orientation="landscape">
        <View style={styles.ppcPlanView}>
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 13,
              paddingBottom: 17,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            PAID SEARCH PLAN
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Image
              src={GAds}
              style={{
                width: 383,
                height: 348,
                marginTop: 38,
                zIndex: 1,
              }}
            />
            <View
              style={{
                width: "60%",
                height: 440,
                backgroundColor: "rgb(240, 249, 255)",
                marginRight: 20,
                paddingLeft: 144,
                paddingTop: 32,
                paddingRight: 48,
                borderRadius: 5,
                position: "absolute",
                right: -16,
                zIndex: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  marginBottom: 12,
                  width: 40,
                  lineHeight: 1.25,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                Smart Pop-Ups
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  lineHeight: 1.45,
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  paddingRight: 28,
                }}
              >
                Pop-ups can improve site-wide conversions even from non-paid
                traffic sources. We'll
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  lineHeight: 1.45,
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  paddingRight: 28,
                }}
              >
                design, write, and constantly test time and
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  lineHeight: 1.45,
                  textAlign: "left",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  marginBottom: 18,
                  paddingRight: 28,
                }}
              >
                activity-based smart pop-ups to lower bounce rates and boost
                conversions.
              </Text>
              <View
                style={{
                  fontSize: 9,
                  width: 130,
                  textAlign: "center",
                  backgroundColor: "rgb(241, 73, 76)",
                  paddingVertical: 3,
                  paddingHorizontal: 3,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                <Text>How we'll use pop-ups:</Text>
              </View>
              <View
                style={{
                  marginTop: 18,
                }}
              >
                <View>
                  <View style={styles.croLeftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Scroll depth:
                      </Text>{" "}
                      when a visitor scrolls a certain percentage on a page
                    </Text>
                  </View>
                  <View style={styles.croLeftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Time delay:
                      </Text>{" "}
                      when a visitor visits a page for a specific amount of time
                    </Text>
                  </View>
                  <View style={styles.croLeftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <>
                        <Text
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: 700,
                          }}
                        >
                          Retargeting:
                        </Text>{" "}
                        when a visitor has been to the page multiple times
                      </>
                    </Text>
                  </View>
                  <View style={styles.croLeftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Exit Intent:
                      </Text>{" "}
                      before the visitor exits the page
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Paid CRO Plan - 3  */}
      <Page size="A4" orientation="landscape">
        <View style={styles.ppcPlanView}>
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 13,
              paddingBottom: 17,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            PAID SEARCH PLAN
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Image
              src={Bing}
              style={{
                width: 378,
                height: 378,
                marginTop: 24,
                zIndex: 1,
              }}
            />
            <View
              style={{
                width: "65%",
                height: 460,
                backgroundColor: "rgb(240, 249, 255)",
                marginRight: 20,
                paddingLeft: 142,
                paddingTop: 42,
                paddingRight: 42,
                borderRadius: 5,
                position: "absolute",
                right: -40,
                zIndex: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  marginBottom: 12,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                Paid Search: Google Ads
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  lineHeight: 1.45,
                  width: 280,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  paddingRight: 32,
                }}
              >
                We'll structure your account with the perfect amount of
                granularity using a technique called Specific Intent
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  lineHeight: 1.45,
                  width: 280,
                  marginBottom: 8,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  paddingRight: 32,
                }}
              >
                Campaigns. Then we'll link your Google Analytics account and
                import audiences for RLSA(retargeting lists for search ads).
                We'll also add the most relevant interests in observation mode
                to make bid adjustments on the most/least valuable segments.
                Finally, we'll send traffic from these campaigns to landing
                pages that are optimized to convert.
              </Text>
              <View
                style={{
                  width: 130,
                  textAlign: "center",
                  backgroundColor: "rgb(241, 73, 76)",
                  paddingVertical: 3,
                  paddingHorizontal: 6,
                  fontSize: 8,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                <Text>Campaigns we'll create:</Text>
              </View>
              <View
                style={{
                  marginTop: 15,
                }}
              >
                <View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 250,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Branded campaign:
                      </Text>{" "}
                      bidding on your branded terms to prevent competitors from
                      taking your spot and capture searches generated from brand
                      awareness campaigns.
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 250,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Competitor campaign:
                      </Text>{" "}
                      bidding on your competitor's brand terms to create
                      awareness and steal market share.
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 210,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Services campaign (US):
                      </Text>{" "}
                      bidding on high intent keywords
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 250,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Broad experimental campaigns:
                      </Text>{" "}
                      Testing RLSA + Broad Match, and DSA campaigns to find new
                      search terms.
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 250,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      <Text
                        style={{
                          fontFamily: "Montserrat",
                          fontWeight: 700,
                        }}
                      >
                        Retargeting Display campaign:
                      </Text>{" "}
                      Follow people around with banner ads who view key pages or
                      perform micro conversions, but don't convert on the
                      primary offer.
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Paid CRO Plan - 4  */}
      <Page size="A4" orientation="landscape">
        <View style={styles.ppcPlanView}>
          <Text
            style={{
              color: "rgb(241, 73, 76)",
              fontSize: 13,
              paddingBottom: 17,
              fontFamily: "Montserrat",
              fontWeight: 700,
            }}
          >
            PAID SEARCH PLAN
          </Text>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Image
              src={Social}
              style={{
                width: 378,
                height: 378,
                marginTop: 24,
                zIndex: 1,
              }}
            />
            <View
              style={{
                width: "60%",
                height: 440,
                backgroundColor: "rgb(240, 249, 255)",
                marginRight: 20,
                paddingLeft: 144,
                paddingTop: 42,
                paddingRight: 42,
                borderRadius: 5,
                position: "absolute",
                right: -8,
                zIndex: 2,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  marginBottom: 20,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                Paid Social
              </Text>
              <Text
                style={{
                  fontSize: 8,
                  marginBottom: 28,
                  lineHeight: 1.5,
                  width: 220,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                We'll create Facebook Ad campaigns, new audiences, write copy,
                and design creative.
              </Text>
              <View
                style={{
                  width: 220,
                  textAlign: "center",
                  backgroundColor: "rgb(241, 73, 76)",
                  paddingVertical: 3,
                  paddingHorizontal: 6,
                  fontSize: 8,
                  color: "white",
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                }}
              >
                <Text>Additional Strategies & Tactics we'll use:</Text>
              </View>
              <View
                style={{
                  marginTop: 18,
                }}
              >
                <View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 180,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Write new ad copy and design new creative
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 202,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Test different ad formats (carousel, single image, video,
                      etc.)
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Test Lifetime budgets v. Daily budgets
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 190,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Fix limited custom audiences and create new saved
                      audiences to test against LALs
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 10,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 202,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Create a campaign to drive warmer traffic to collect email
                      addresses for retargeting campaigns
                    </Text>
                  </View>
                  <View style={styles.leftRow}>
                    <View style={styles.bullet}>
                      <Text
                        style={{
                          fontSize: 11,
                          color: "rgb(241, 73, 76)",
                        }}
                      >
                        {"\u2022" + " "}
                      </Text>
                    </View>
                    <Text
                      style={{
                        fontSize: 8,
                        width: 182,
                        fontFamily: "Montserrat",
                        fontWeight: 600,
                      }}
                    >
                      Implement successful retargeting campaigns
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
      {/* Terms and Conditions  */}
      <Page size="A4" orientation="landscape">
        <View
          style={{
            height: 102,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "#F2F9FE",
          }}
        >
          {/* <Text
            style={{
              paddingLeft: 72,
              fontFamily: 'Montserrat',
              fontWeight: 700,
              fontSize: 18,
              // letterSpacing: 0.02,
              color: '#DF5653',
            }}
          >
            TERMS AND CONDITIONS
          </Text> */}
        </View>
        <View
          style={{
            marginTop: 28,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingHorizontal: 72,
              paddingRight: 92,
              // alignItems: 'baseline',
            }}
          >
            <Text
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 30,
                // letterSpacing: 0.02,
                color: "#DF5653",
                marginRight: 36,
              }}
            >
              1
            </Text>
            <Text
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 12.5,
                lineHeight: 1.4,
                color: "#000000",
                paddingTop: 4,
              }}
            >
              Please make sure you’ve read and understood the SOW. It’s really
              important to us that everything is transparent and understood from
              the beginning so that we lay a solid foundation for a great
              working relationship. The services set out in this SOW are being
              provided by Aiquire Inc, which inter alia operates the brand name
              Linear Design.
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 28,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingHorizontal: 72,
              paddingRight: 92,
              // alignItems: 'baseline',
            }}
          >
            <Text
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 30,
                // letterSpacing: 0.02,
                color: "#DF5653",
                marginRight: 33,
              }}
            >
              2
            </Text>
            <Text
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 12.5,
                lineHeight: 1.4,
                color: "#000000",
                paddingTop: 4,
              }}
            >
              This SOW is subject to and governed by the Terms and Conditions
              (“T&C’s”). Any capitalized term which is not defined under this
              SOW shall have the meaning assigned to it in the T&C’s, which is
              linked below. This SOW and the T&C’s together constitute a binding
              non- exclusive agreement (“Agreement”). Upon clicking on “I Agree”
              below, you indicate that you find the services and the proposed
              commercials set out herein acceptable and agree to be bound by
              this SOW, the Terms and Conditions and any other documents that
              this Agreement incorporates by reference or attachment.
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 28,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingHorizontal: 72,
              paddingRight: 92,
              // alignItems: 'baseline',
            }}
          >
            <Text
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 30,
                // letterSpacing: 0.02,
                color: "#DF5653",
                marginRight: 33,
              }}
            >
              3
            </Text>
            <Text
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 12.5,
                lineHeight: 1.4,
                color: "#000000",
                paddingTop: 4,
              }}
            >
              If you have any questions at all, please let us know. We’re happy
              to clarify any points and there may be some items that we can sort
              out together. We’re committed to finding the best way to work
              together.
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 28,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              paddingHorizontal: 72,
              paddingRight: 92,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 30,
                // letterSpacing: 0.02,
                color: "#DF5653",
                marginRight: 33,
              }}
            >
              4
            </Text>
            <Text
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: 12.5,
                lineHeight: 1.4,
                color: "#000000",
              }}
            >
              We’ll email you a separate copy of the signed contract for your
              records.
            </Text>
          </View>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: 0,
            backgroundColor: "rgb(243, 243, 245)",
            width: "100%",
            paddingVertical: 8,
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 48,
              }}
            >
              <Text
                style={{
                  marginRight: 22,
                  fontSize: 24,
                  paddingTop: -3,
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                }}
              >
                linear
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                }}
              >
                SOW for {proposal?.onboardingInfo?.personalInfo?.company}
              </Text>
            </View>
            <View
              style={{
                paddingRight: 64,
              }}
            >
              <Text
                style={styles.pageNumber}
                render={({ pageNumber }) => `${pageNumber}`}
                fixed
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default LDGeneratePdf;
