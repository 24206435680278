import { useQuery } from "@tanstack/react-query";
import { api } from "../api";

export const useUserList = (options = {}) => {
  return useQuery(
    ["userlist/get"],
    async () => {
      const response = await api.get("/userslist");
      return response.data;
    },
    options
  );
};
