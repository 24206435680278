import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const budgetDistribution = {
  searchPercentage: 60,
  pmaxPercentage: 40,
  brandCampaignPercentage: 15,
  competatorCampaignBudget: 15,
  serviceCampaignPercentage: 70,
};

// const budgetDistribution = {
//   "LD": {},
//   "HDM": {},
// };

export default function useGoogleCampaignBudget({ spends }) {
  // const spends = useSelector((state) => {
  //   if (agencyType === 'LD')
  //     return state.form.form.onboardingInfo?.platformInfo?.platforms?.find(
  //       (el) => el.platform === 'google-ads'
  //     )?.spends;
  //   return state.hdmFormAm.onboardingInfo?.clientAccess?.platforms?.find(
  //     (el) => el.platform === 'google-ads'
  //   )?.spends;
  // });

  const searchBudget = useMemo(() => {
    if (!spends) return 0;
    return (spends / 100) * budgetDistribution.searchPercentage;
  }, [spends]);

  const pmaxBudget = useMemo(() => {
    if (!spends) return 0;
    return Math.round((spends / 100) * budgetDistribution.pmaxPercentage / 30.4);
  }, [spends]);

  const brandCampaignBudget = useMemo(
    () =>
      Math.round(
        (searchBudget / 100) * budgetDistribution.brandCampaignPercentage
      ) / 30.4,
    [searchBudget]
  );
  const competatorCampaignBudget = useMemo(
    () =>
      Math.round(
        (searchBudget / 100) * budgetDistribution.competatorCampaignBudget
      ) / 30.4,
    [searchBudget]
  );
  const serviceCampaignBudget = useMemo(
    () =>
      Math.round(
        (searchBudget / 100) * budgetDistribution.serviceCampaignPercentage
      ) / 30.4,
    [searchBudget]
  );

  return {
    searchBudget,
    pmaxBudget,
    brandCampaignBudget,
    competatorCampaignBudget,
    serviceCampaignBudget,
    totalSpends: spends,
  };
}
