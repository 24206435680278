import { Typography } from '@mui/material';
import './newcampaignpreview.css'

const CampaignCard = (props) => {
    return(
        <div style={{display:'flex'}}>
        <div className="leftline"></div> 
        <div className='brand_container'>
            <Typography className='new_brand_heading'>{props.title}</Typography>
            <div style={{display:'flex'}}>
                <Typography className='new_brand_title'>Campaign ID : </Typography>
                <Typography className='new_brand_subtitle'>{props.campainID}</Typography>
            </div>
            <div style={{display:'flex'}}>
                <Typography className='new_brand_title'>Name : </Typography>
                <Typography className='new_brand_subtitle'>{props.name}</Typography>
            </div>
            </div>   
        </div>
    )
}
export default CampaignCard;