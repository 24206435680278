import { useEffect, useState } from "react";
import { updateCompoReduxClient } from "../../../api/componentTracking/action";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import GoToSpecificCompoButton from "../../ButtonPrimary/GoToSpecificCompoButton";
import GoToNextCompoButton from "../../ButtonPrimary/GoToNextCompoButton";
import { getLocalStorage } from "../../../api/localStorage/localStorage";
import { getClientDetailsByFormId } from "../../../api/client/action";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";
import moment from "moment";
import {
  clientDashboardIds,
  LDcomponentWithIndexClient,
} from "../../../utils/clientDashboardIdsUtil";
import { getPmaxStrategies } from "../../../api/pmaxStrategy/slice";
import { getKeywords } from "../../../api/pmaxStrategy/slice";
import { fetchCriteria } from "../../../api/accountManager/googleAdsCustomer/slice";
import { updateData } from "../../../api/pmaxCampaign/slice";
import { generatePath, useNavigate } from "react-router-dom";
import ldClientRoutePaths from "../../../pages/ld-client/constants/ldClientRoutePaths";
import { useGetListGoogleAdsStrategies } from "../../../services/googleAdsStrategy";
import { DATA_FORMAT_TO_GET_STRATEGIES } from "../../GoogleSearchStrategyCreation/utils";
import { useGetListPmaxStrategies } from "../../../services/googlePmaxStrategy";
import { useGetListFBAdsCampaignStrategies } from "../../../services/fb-campaign-strategy";
import { getFBStrategyGenerationFilterType } from "../../FacebookStrategyCreation/utils";

const totalBudget = (searchStrategies = [], pmaxStrategies = [], facebookStrategies = []) => {
  let totalBudget = 0;
  searchStrategies.forEach(strategy => {
    totalBudget += parseInt(strategy?.budget?.value);
  });
  pmaxStrategies.forEach(strategy => {
    totalBudget += parseInt(strategy?.budget);
  });
  facebookStrategies.forEach(strategy => {
    totalBudget += parseInt(strategy?.budget?.value);
  });

  return totalBudget;
};
const findCostPerConversion = (clientState, platform) => {
  if (!platform) return 0;
  return (
    clientState?.onboardingInfo?.platformInfo?.platforms?.find(
      (plat) => plat.platform === platform
    )?.cpa || 0
  );
};

const StrategyDeck = () => {
  const navigate = useNavigate();
  const formId = localStorage.getItem('formId');
  const clientState = useSelector((state) => state.client);
  const strategyIterations = clientState?.strategyIterations;
  const platformSelected = useSelector(
    (state) => state?.client?.onboardingInfo?.platformInfo?.platforms
  );

  const { data: googleSearchStragies } = useGetListGoogleAdsStrategies(
    formId,
    DATA_FORMAT_TO_GET_STRATEGIES.FULLDATA,
    clientState?.googleStrategyFlow || "GUIDED",
    {}
  );
  const { data: googlePmaxStrategies } = useGetListPmaxStrategies(
    formId,
    clientState?.googleStrategyFlow || "GUIDED",
    {}
  );
  const { data: facebookStrategies } = useGetListFBAdsCampaignStrategies(
    {
      formId,
      generationTypeFilter: getFBStrategyGenerationFilterType(
        clientState?.fbStrategyFlow
      ),
    },
    {}
  );

  let currentCompo = useSelector(
    (state) => state.clientCurrentComp.currentComp
  );
  const pmaxBudget = useSelector((store) => store.pmaxStrategy.budget);

  const publishedDate = (date) => moment(date).format("YYYY-MM-DD");
  return (
    <div className="intro_strategy_main">
      <div
        className="intro_strategy_layout"
        style={{
          paddingTop: 0,
          paddingBottom: 0,
        }}
      >
        {strategyIterations.length !== 0 &&
          strategyIterations[strategyIterations.length - 1].state ===
            "CHANGES_SUGGESTED" && (
            <div
              className="intro_strategy_warning"
              style={{
                marginTop: 14,
              }}
            >
              <span className="icon orange_check">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.75"
                    width="20"
                    height="20"
                    rx="10"
                    fill="#F99F35"
                  />
                  <path
                    d="M6 10.7832L8.86226 13.65L14.7592 7.75"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <p>
                You are viewing The Strategy copy generated on{" "}
                {publishedDate(
                  strategyIterations[strategyIterations.length - 1].stateTs
                )}
                . Your suggested changes have been registered and you will be
                notified soon
              </p>
            </div>
          )}
        <div
          className="intro_strategy_banner"
          style={{
            marginTop: 12,
          }}
        >
          <div className="intro_banner_info">
            <h2>{clientState?.onboardingInfo?.personalInfo?.company}</h2>
            <p>2023 Strategy Deck</p>
          </div>
          <div className="intro_banner_footer">
            <div>
              <p>
                {clientState?.onboardingInfo?.personalInfo?.company} |{" "}
                {clientState.strategyIterations.length !== 0 &&
                  publishedDate(
                    clientState.strategyIterations[
                      clientState.strategyIterations.length - 1
                    ].stateTs
                  )}
              </p>
              <p>
                Proposal prepared for:{" "}
                {clientState?.onboardingInfo?.personalInfo?.primaryContactName}
              </p>
            </div>
            <div>
              <p>625 W Adams St Chicago,IL 60661,USA</p>
              <p>
                <a href="mailto:luke@lineardesign.com">luke@lineardesign.com</a>
              </p>
            </div>
          </div>
        </div>
        <div
          className="intro_statatics_row"
          style={{
            marginTop: 14,
            marginBottom: 14,
          }}
        >
          <div className="intro_statatics_column">
            <h3>${totalBudget(
                googleSearchStragies?.strategies,
                googlePmaxStrategies?.strategies,
                facebookStrategies?.strategies
              )}</h3>
            <p>Total Budget</p>
          </div>
          {platformSelected.findIndex(
            (platform) => platform.platform === "google-ads"
          ) !== -1 && (
            <div className="intro_statatics_column">
              <h3>${findCostPerConversion(clientState, "google-ads")}</h3>
              <p>
                Cost/Conversion
                <br />
                <span>(For Google)</span>
              </p>
            </div>
          )}
          {platformSelected.findIndex(
            (platform) => platform.platform === "facebook-ads"
          ) !== -1 && (
            <div className="intro_statatics_column">
              <h3>${findCostPerConversion(clientState, "facebook-ads")}</h3>
              <p>
                Cost/Conversion
                <br />
                <span>(For Facebook)</span>
              </p>
            </div>
          )}
        </div>
      </div>
      <div className="preview_footer_row">
        <div
          className="footer_content"
          style={{ justifyContent: "space-between" }}
        >
          <button
            className="backbutton"
            onClick={() => {
              navigate(generatePath(ldClientRoutePaths.homePage.fullbase));
            }}
          >
            BACK
          </button>
          <PrimaryButton
            title="Next"
            type="client"
            onClick={() => {
              platformSelected.findIndex(
                (platform) => platform.platform === "google-ads"
              ) === -1
                ? navigate(generatePath(`${ldClientRoutePaths.strategyDeck.fullbase}/${ldClientRoutePaths.strategyDeck.sub.facebook}`))
                : navigate(generatePath(`${ldClientRoutePaths.strategyDeck.fullbase}/${ldClientRoutePaths.strategyDeck.sub.google}`));
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default StrategyDeck;
