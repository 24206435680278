import React from 'react';
import { TextField } from '@material-ui/core';
const DisplayTests = ({ text, read, onChangeHandler }) => {
  return (
    <>
      {!read ? (
        <TextField
          variant='standard'
          value={text}
          style={{
            width: '100%',
            height: 16,
          }}
          inputProps={{
            style: {
              color: '#333333',
              height: 0,
              background: '#FFFFFF',
              borderRadius: 12,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 12,
              outline: 'none',
              paddingLeft: 4,
            },
          }}
          onChange={e => onChangeHandler({ question: e.target.value })}
        />
      ) : (
        `${text}`
      )}
    </>
  );
};

export default DisplayTests;
