import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { updateTab } from "../../../api/accountManager/componentTracking/action";
import {
  headerNavbarAMWithCurrentCompIds,
  headerNavbarAM,
  listOfIds,
  headerNavbarHDMAM,
  listOfIdsHDM,
} from "../../../utils/navbarUtil";
import {
  accountManagerIdsHDM,
  componentWithIndexHDMAm,
  isPlatformSelectedHDM,
  PROPOSAL_STATE,
} from "../../../utils/accountManagerUtil";
import { updateTabHDMAM } from "../../../api/HDMAM/componentTracking/action";
import { updateTabHDM } from "../../../api/HDMClient/componentTracking/action";
import { isAnyAdsThereInAdsetHdm } from "../../../utils/strategyCreationFacebook";
import { useEffect, useState } from "react";

const HeaderNavSteps = ({ headerNavList={}, active, clientView }) => {
  const dispatch = useDispatch();
  const form = useSelector((store) => store.hdmFormAm);
  const interestTof = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo?.topOfTheFunnelInterest?.adsets
  );
  const lookalikeTof = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo?.topOfTheFunnelLookalike?.adsets
  );
  const mofTof = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo?.middleOfTheFunnel?.adsets
  );
  const bofTof = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo?.bottomOfTheFunnel?.adsets
  );
  const onClickHandler = (tab, id) => {
    if (active !== tab) {
      if (clientView) {
        dispatch(updateTabHDM(id));
      // } else if (
      //     tab === headerNavbarHDMAM[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_1]
      // ) {
      //     dispatch(updateTabHDMAM(headerNavbarAMWithCurrentCompIds[tab]));
      // } else if (
      //     tab === headerNavbarHDMAM[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_2]
      // ) {
      //    console.log('TAB:', tab);
      //    dispatch(updateTabHDMAM(headerNavbarAMWithCurrentCompIds[tab]));
      // } else if (
      //     tab === headerNavbarHDMAM[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_3]
      // ) {
      //    dispatch(updateTabHDMAM(headerNavbarAMWithCurrentCompIds[tab]));
      } else {
        dispatch(updateTabHDMAM(id));
      }
    }
  };

  console.log('HEADER NAV LIST:', headerNavList);

  const platformToBeDisable = (id,name) => {
    if(name === "GOOGLE TARGETING" && isPlatformSelectedHDM(form, "google-ads") === -1){
      return true;
    } 
    if(name === "FACEBOOK TARGETING" && isPlatformSelectedHDM(form, "facebook-ads") === -1){
      return true;
    } 
    if(id === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_FB_STRATEGY]){
      if(isPlatformSelectedHDM(form, "facebook-ads") === -1){
        return true
      }
    }
    if(id === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_GOOGLE_STRATEGY]){
      if(isPlatformSelectedHDM(form, "google-ads") === -1){
        return true
      }
    }
     if(name === "CREATIVES" && !form?.onboardingInfo?.aboutBusiness?.runMontageAds)
       return true
    return false;
  }

  const checkStrategyCreationFBTab = (id) => {
    const lookalike = isAnyAdsThereInAdsetHdm(lookalikeTof);
    const interest = isAnyAdsThereInAdsetHdm(interestTof);
    const mof = isAnyAdsThereInAdsetHdm(mofTof);
    const bof = isAnyAdsThereInAdsetHdm(bofTof);
    if (
      (lookalike || interest || mof || bof) &&
      id >=
        componentWithIndexHDMAm[
          accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE
        ] &&
      id <= componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_BOF]
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
      {Object.values(headerNavList).map(
        ({ name, isActive, id, icon }, index) => {
          console.log(
            "in header am",
            name,
            id,
            (
              form?.strategyIterations[form?.strategyIterations.length - 1] ||
              {}
            ).state
          );
          return (
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background:
                  name === active
                    ? "#F1F7FF"
                    : (name === "CHANGES SUGGESTED" &&
                        !(
                          (
                            form?.strategyIterations[
                              form?.strategyIterations.length - 1
                            ] || {}
                          ).state === PROPOSAL_STATE.CHANGES_SUGGESTED
                        )) ||
                      id === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_CHANGES_SUGGESTED] ||
                      checkStrategyCreationFBTab(id) || platformToBeDisable(id,name)
                    ? "rgb(235 235 228 / 90%)"
                    : "",
                color: name === active ? "#0869FB" : "",
                cursor: "pointer",
                width: `calc(100%/${Object.values(headerNavList).length})`,
              }}
              key={index}
              disabled={
                (name === "CHANGES SUGGESTED" &&
                  !(
                    (
                      form?.strategyIterations[
                        form?.strategyIterations.length - 1
                      ] || {}
                    ).state === PROPOSAL_STATE.CHANGES_SUGGESTED
                  )) ||
                  id === componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_CHANGES_SUGGESTED] ||
                checkStrategyCreationFBTab(id) || platformToBeDisable(id,name)
              }
              onClick={() => onClickHandler(name, id)}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: name === active ? "#0869FB" : "#505050",
                  display: "flex",
                  alignItems: "center",
                  gap: ["PRICING", "CRO SETUP"].includes(name) ? 8 : 0,
                }}
              >
                {icon && <img src={icon} alt="" />}
                {name}
              </Typography>
            </Button>
          );
        }
      )}
    </>
  );
};

export default HeaderNavSteps;
