import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../api";
import concat from 'lodash/concat';
import { ADSET_CRON_STATUS } from "../components/FacebookStrategyCreation/utils";

const getListGAdsCampaignQueryKey = formId => [`gads-campaigns/list/:formId`, formId];
export const useGetListGAdsCampaigns = (formId, options = {}) => {
  return useQuery(
    getListGAdsCampaignQueryKey(formId),
    async () => {
      const response = await api.get(`/g-ads-campaign/${formId}`);
      return response.data;
    },
    options
  );
};

export const getGAdsCampaignByCampaignIdQueryKey = (formId, campaignId) => [`gads-campaigns/list/:formId/:campaignId`, formId, campaignId];
export const useGetGAdsCampaignById = (formId, campaignId, options = {}) => {
  return useQuery(
    getGAdsCampaignByCampaignIdQueryKey(formId, campaignId),
    async () => {
      const response = await api.get(`/g-ads-campaign/${formId}/${campaignId}`);
      return response.data;
    },
    options
  );
};

export const useDeleteGAdsCampaignById = (formId, options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ campaignId }) => {
      const response = await api.delete(`/g-ads-campaign/${formId}/${campaignId}/adsets/assets`);
      return response.data;
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        const { campaignId } = variables;
        queryClient.setQueryData(
          getListGAdsCampaignQueryKey(formId),
          (prev) => ({
            ...prev,
            campaigns: prev.campaigns.filter(campaign => campaign.id !== campaignId),
          }),
        );
        options.onSuccess?.(data, variables, ctx);
      },
    }
  );
};

export const usePostGAdsCampaign = (formId, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (campaign={}) => {
     const body = {
         ...campaign
      }
      const response = await api.post(`/g-ads-campaign/${formId}`, body);
      return response.data;
    }, {
      ...options,
      onSuccess(data, variables, ctx) {
        queryClient.setQueryData(
          getListGAdsCampaignQueryKey(formId),
          (prev) => ({
            ...prev,
            campaigns: concat(prev.campaigns, data),
          }),
        );
        options.onSuccess?.(data, variables, ctx);
      }
    },
  );
}

export const usePutGoogleAdsCampaign = (formId, campaignId, options={}) => {
    const { onSuccess, ...otherOptions } = options;
    return useMutation({
        mutationFn: async (payload={}) => {
            const incomingCampaignId = campaignId || payload.campaignId;
            if(payload.campaignId){
                delete payload.campaignId;
            }
            const body = {
                ...payload,
            };
            const response = await api.put(`/g-ads-campaign/${formId}/${incomingCampaignId}`, body);
            return response.data;
        },
        onSuccess: (...args) => {
            // Invalidate and refresh
            // queryClient.invalidateQueries({ queryKey: getSpecificAdsStrategyQueryKey(formId, strategyId) });
            // onSuccess?.(...args);
        },
        ...otherOptions
    })
}

export const usePutGAdsCampaign = (formId, options = {}) => {
  return useMutation({
      mutationFn: async (payload={}) => {
      const { campaignId, ...fields } = payload;
      const data = {
          ...fields
      };
      const response = await api.put(`/g-ads-campaign/${formId}/${campaignId}`, data);
      return response.data;
    },
    ...options
  });
}

export const useDeleteGAdsCampaignAdsets = ( formId, campaignId , options = {}) => {
  return useMutation(
    async ({ adsetIds = [] }) => {
      // guard clause to avoid deleting the strategy when adsets are empty,
      // as when adsets are empty backend deletes the strategy (common endpoint)
      if (!adsetIds.length) return Promise.reject({ detail: "no adsets to delete as adsetIds is empty" });

      const searchParams = new URLSearchParams();
      for (let id of adsetIds) {
        searchParams.append("adset_ids", id);
      }
      const response = await api.delete(`/g-ads-campaign/${formId}/${campaignId}/adsets/assets?${searchParams.toString()}`);
      return response.data;
    },
  );
};

// create Adset 
export const usePostGoogleCampaignAdset = (formId, campaignId, options={}) => {
    const { onSuccess, ...otherOptions } = options;
    return useMutation({
        mutationFn: async (payload={}) => {
            const {adsetId, ...field} = payload;
            const body = {
                ...field,
            }
            let response;
            if(adsetId){
                response = await api.post(`/targeting-keywords/google-seach-ads/campaign/${formId}/${campaignId}?adset_id=${adsetId}`, body);
            }else{
                response = await api.post(`/targeting-keywords/google-seach-ads/campaign/${formId}/${campaignId}`, body);
            }
            return response.data;
        },
        ...otherOptions
    })
}

// poll adsets

export const usePollAdsetGSearchAdsCampaign = (
  { adsetId, formId, campaignId },
  options = {}
) => {
    return useQuery(
      [
        `/g-ads-campaign/${formId}/${campaignId}/adset/${adsetId}/poll`,
      ],
      async () => {
        if(adsetId){
            const response = await api.get(
              `/g-ads-campaign/${formId}/${campaignId}/adset/${adsetId}`
            );
            return response.data;
        }
        return null;
      },
      {
        ...options,
        refetchInterval(data) {
          if (
            data &&
            [ADSET_CRON_STATUS.RUNNING, ADSET_CRON_STATUS.PENDING].includes(
              data.cronStatus
            )
          )
            return 30 * 1000;
          return false;
        },
      }
    );
};