import { leftPanelNameInUrlPath } from "../../../utils/LDUtils/accountManager/utils";

const BASE = "/ld-am/:formId";

const ldAmRoutePaths = /** @type {const} */ ({
  root: BASE,
  onboardingForm: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ONBOARDING_FORM}`,
    root: `${leftPanelNameInUrlPath.ONBOARDING_FORM}`, // should matches the end of fullbase
    sub: {
      review: "",
      basicInformation: "basic-information",
      basicInformationWithQuestionNumber: "basic-information/:questionNumber",
      platformInformation: "platform-information",
      platformInformationWithQuestionNumber:
        "platform-information/:questionNumber",
      growthGoals: "growth-goals",
      growthGoalsWithQuestionNumber: "growth-goals/:questionNumber",
    },
  },
  pricing: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.PRICING}`,
    root: `${leftPanelNameInUrlPath.PRICING}`, // should matches the end of fullbase
    sub: {},
  },
  accessSharing: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ACCESS_SHARING}`,
    root: `${leftPanelNameInUrlPath.ACCESS_SHARING}`,
    sub: {
      google: "google-access",
      facebook: "facebook-access",
    },
  },
  readSharedAssets: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.READ_SHARED_ASSETS}`,
    root: `${leftPanelNameInUrlPath.READ_SHARED_ASSETS}`, // should matches the end of fullbase
    sub: {},
  },
  auditForGoogle: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.AUDIT_FOR_GOOGLE}`,
    root: `${leftPanelNameInUrlPath.AUDIT_FOR_GOOGLE}`, // should matches the end of fullbase
    sub: {
      selectAccount: "",
      reviewPoints: "review-points",
      again: ":again",
    },
  },
  auditForFacebook: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.AUDIT_FOR_FACEBOOK}`,
    root: `${leftPanelNameInUrlPath.AUDIT_FOR_FACEBOOK}`, // should matches the end of fullbase
    sub: {
      selectAccount: "",
      account: "account",
      campaign: "campaign",
      adset: "adset",
      ad: "ad",
      again: ":again",
    },
  },
  proposol: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.PROPOSAL}`,
    root: `${leftPanelNameInUrlPath.PROPOSAL}`, // should matches the end of fullbase
    sub: {
      proposalView: "",
      suggestChanges: "suggest-changes",
    },
  },
  reviewIntakeForm: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.REVIEW_INTAKE_FORM}`,
    root: `${leftPanelNameInUrlPath.REVIEW_INTAKE_FORM}`, // should matches the end of fullbase
    sub: {
      review: "",
      productInfo: "product-info",
      productInfoWithQuestionNumber: "product-info/:questionNumber",
      preferences: "preferences",
      preferencesWithQuestionNumber: "preferences/:questionNumber",
      googleTargeting: "google-targeting",
      googleTargetingWithQuestionNumber: "google-targeting/:questionNumber",
      branding: "branding",
      brandingWithQuestionNumber: "branding/:questionNumber",
      socialProof: "social-proof",
      socialProofWithQuestionNumber: "social-proof/:questionNumber",
      facebookTargeting: "facebook-targeting",
      facebookTargetingWithQuestionNumber: "facebook-targeting/:questionNumber",
    },
  },
  completeSharedAssets: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.COMPLETE_SHARED_ASSETS}`,
    root: `${leftPanelNameInUrlPath.COMPLETE_SHARED_ASSETS}`, // should matches the end of fullbase
    sub: {},
  },
  strategyGoogle: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.STRATEGY_GOOGLE}`,
    root: `${leftPanelNameInUrlPath.STRATEGY_GOOGLE}`, // should matches the end of fullbase
    sub: {
      generationType: {
        selectFlow: "",
        again: ":again",
      },
      guidedFlow: {
        selector: "guided",
        googleSearch: {
          strategiesList: "guided/google-search/strategies",
          adsetGroupLevel: "guided/google-search/:strategyId",
        },
        googlePmax: {
          strategiesList: "guided/strategies",
          adgroupLevel: "guided/:strategyId",
          segmentLevel: "guided/segment/:segmentId",
        },
      },
      automatedFlow: {
        strategiesList: "automated/strategies",
        adgroupLevel: "automated/search/:strategyId",
        assetgroupLevel: "automated/pmax/:strategyId",
        segmentLevel: "automated/segment/:segmentId",
      },
    },
  },
  strategyFacebook: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.STRATEGY_FACEBOOK}`,
    root: `${leftPanelNameInUrlPath.STRATEGY_FACEBOOK}`, // should matches the end of fullbase
    sub: {
      generationType: "",
      strategiesList: "strategies/:strategyFlow",
      adsetLevel: "strategies/:strategyFlow/:strategyId",
    },
  },
  strategyDeck: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.STRATEGY_DECK}`,
    root: `${leftPanelNameInUrlPath.STRATEGY_DECK}`, // should matches the end of fullbase
    sub: {
      introduction: "",
      google: "google",
      facebook: "facebook",
      suggestChanges: "suggest-changes",
    },
  },
  campaignGoogle: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CAMPAIGN_GOOGLE}`,
    root: `${leftPanelNameInUrlPath.CAMPAIGN_GOOGLE}`, // should matches the end of fullbase
    sub: {
      pmax: "pmax",
      adGroupLevelSearchCampaign: "search/:campaignId",
      adGroupLevelPmaxCampaign: "pmax/:campaignId",
      adGroupLevelSegment: "segment/:segmentId",
    },
  },
  campaignFacebook: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CAMPAIGN_FACEBOOK}`,
    root: `${leftPanelNameInUrlPath.CAMPAIGN_FACEBOOK}`, // should matches the end of fullbase
    sub: {
      base: "",
      fbCampaign: ":campaignId",
    },
  },
  campaignPreview: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CAMPAIGN_PREVIEW}`,
    root: `${leftPanelNameInUrlPath.CAMPAIGN_PREVIEW}`, // should matches the end of fullbase
    sub: {},
  },
  creativeApproval: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CREATIVE_APPROVAL}`,
    root: `${leftPanelNameInUrlPath.CREATIVE_APPROVAL}`, // should matches the end of fullbase
    sub: {
      adCopies: "",
      creatives: "creatives",
      landingPages: "landing-pages",
      abTest: "ab-test",
    },
  },
  analyticsDashboard: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ANALYTICS_DASHBOARD}`,
    root: `${leftPanelNameInUrlPath.ANALYTICS_DASHBOARD}`, // should matches the end of fullbase
    sub: {},
  },
});

export default ldAmRoutePaths;
