import React, {useState} from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import loaderGif from '../../../assets/images/loader.gif';
import { Close } from "@mui/icons-material";

const GenerateModal = () => {
  const [confirmModal, setConfirmModal] = React.useState(true);
  const handleConfirmClose = () => setConfirmModal(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 584,
    boxShadow: 24,
    minHeight: '200px',
    background: '#FFFFFF',
    borderRadius: '20px',
    padding: '1.5rem',
    outline: 'none',
    height: '275px'
  };
  return (
    <Modal
      open={confirmModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClose={handleConfirmClose}
    >
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", position: "absolute", right: '18px', top: '15px' }}>
          <IconButton>
            <Close />
          </IconButton>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={loaderGif} alt='' width={110} height={110} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '0.25rem',
              //   width: '50%',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Lato',
                fontWeight: 700,
                fontSize: 24,
                color: '#333333',
                marginBottom: '9px'
              }}
            >
              Generating Ads
            </Typography>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                color: '#727272',

                lineHeight: '25.5px',
              }}
            >
              Kindly publish if the strategy is ready to be sent for client approval
            </Typography>
          </div>
        </div>
      </Box>
    </Modal>
  )
};
export default GenerateModal;
