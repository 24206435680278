import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import HDMCampaignType from "./HDMCampaignType";
import "./hdmcampaign.css";
import { useDispatch, useSelector } from "react-redux";
import {
  updatebof,
  updateInterest,
  updateLookalike,
  updatemof,
  getPages,
  getAdPixels,
  updateCampaignBackClicked,
} from "../../../api/HDMAM/campaign/action";
import { getFacebookAdsStrategy } from "../../../api/HDMAM/strategyCreation/action";
import {
  conversionLocation,
  bidStrategy,
  allowedConversionLocation,
  optimisationAdDelivery,
  cta,
  bidStrategyBackendMapping,
  objectiveBackendMapping,
  destinationTypeBackendMapping,
  optimizationGoalBackendMapping,
  CTABackendMapping,
  conversionEvents,
} from "./constants";
import { BACKEND_STATUS, accountManagerIds, accountManagerIdsHDM, componentWithIndexHDMAm, ldComponentWithIndexAm } from "../../../utils/accountManagerUtil";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import PublishCampaignModal from "./PublishCampaignModal";
import PublishingCampaignError from "./PublishingCampaignError";
import PublishingCampaignLoader from "./PublishingCampaignLoader";
import { getFBCampaignObject } from "../../../utils/accountManagerUtil";
import PublishedCampaignPage from "./PublishedCampaignPage";
import { goToSpecificCompoHDMAM } from "../../../api/HDMAM/componentTracking/action";
import { goToSpecificCompoClient } from "../../../api/accountManager/componentTracking/action";
import { useLocation } from "react-router-dom";

const getAge = (age) => {
  if (age < 13) {
    return "13";
  } else if (age > 65) {
    return "65";
  } else {
    return `${age}`;
  }
};


const mockData = [
    {
        title: 'TOF- lookalike campaign',
        name: 'Tiotio',
        objective: '',
        abo: 'on',
        budget: '1000',
        adsets: [],
        bidStrategy: '',
        startDate: null,
        endDate: null
    },
    {
        title: 'BOF Campiagn',
        name: 'Tiotio',
        objective: '',
        abo: 'on',
        budget: '1000',
        adsets: [],
        bidStrategy: '',
        startDate: null,
        endDate: null
    },
    {
        title: 'MOF Campaign',
        name: 'Tiotio',
        objective: '',
        abo: 'on',
        budget: '1000',
        adsets: [],
        bidStrategy: '',
        startDate: null,
        endDate: null
    },
]
const HDMCampaignCreation = ({ agencyType }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  
  useEffect(() => {
      return () => {
          console.log('running the cleanup function...');
      }
  }, []);

  const [isPublishCampaign, setIsPublishCampaign] = useState(false);
  const [isCampPubHit, setIsCampPubHit] = useState(false);

  const hasLookalikeChanges = useSelector(
    (state) => state.strategyCreationHdmAm.lookalikeChanges
  );
  const hasInterestChanges = useSelector(
    (state) => state.strategyCreationHdmAm.interestChange
  );
  const hasMOFChanges = useSelector(
    (state) => state.strategyCreationHdmAm.mofChanges
  );
  const hasBOFChanges = useSelector(
    (state) => state.strategyCreationHdmAm.bofChanges
  );
  const isBackClicked = useSelector((state) => state.hdmCampaign.isBackClicked);
  const [hasCampaignError, setHasCampaignError] = useState(false);
  const [isCampaignGenerating, setIsCampaignGenerating] = useState(false);
  const [isCampaignGenerated, setIsCampaignGenerated] = useState(false);
  const [campaignIds, setCampaignIds] = useState({});

  const form = useSelector((state) =>
    agencyType === "LD" ? state.form.form : state.hdmFormAm
  );
  const lookalikeData = useSelector((store) => store.hdmCampaign.lookalike);
  const interestData = useSelector((store) => store.hdmCampaign.interest);
  const mofData = useSelector((store) => store.hdmCampaign.MOF);
  const bofData = useSelector((store) => store.hdmCampaign.BOF);
  const pages = useSelector((store) => store.hdmCampaign.pages);
  const pixels = useSelector((store) => store.hdmCampaign.pixels);
  // console.log("lookalikeData :>> ", lookalikeData);
  // console.log("interestData :>> ", interestData);
  // console.log("mofData :>> ", mofData);
  // console.log("bofData :>> ", bofData);
  console.log('location:', location.pathname);

  useEffect(() => {
    dispatch({
      type: "UPDATE_FACEBOOK_ADS_AUDIENCES_SAGA",
    });
    dispatch(getPages());
    dispatch(getAdPixels());
    dispatch(getFacebookAdsStrategy());
  }, [form]);
  useEffect(() => {
    if (form?.fbAdsGeneratedCampaignsInfo) {
      const [campaignObj, isGenerating] = getFBCampaignObject(
        form.fbAdsGeneratedCampaignsInfo
      );

      console.log("@@@@@@@@@@@@@@@", campaignObj, isGenerating, campaignObj.lookalike &&
      campaignObj.interest &&
      campaignObj.mof &&
      campaignObj.bof);
      setCampaignIds(campaignObj);
      if (isGenerating) {
        setIsCampaignGenerating(true);
      }
      if (
        !isGenerating &&
        !(
          campaignObj.lookalike &&
          campaignObj.interest &&
          campaignObj.mof &&
          campaignObj.bof
        )
      ) {
        setHasCampaignError(true);
      }
      setIsCampaignGenerated(
        campaignObj.lookalike &&
          campaignObj.interest &&
          campaignObj.mof &&
          campaignObj.bof
      );
    }
    dispatch(
      updateLookalike(
        Object.keys(lookalikeData || {}).length && hasLookalikeChanges
          ? lookalikeData
          : form?.fbAdsCampaignInfo?.topOfTheFunnelLookalike || {
              name: "HDM || TOF || LLA || CA",
              budget: form?.fbAdsStrategyInfo?.topOfTheFunnelLookalike?.budget,
              abo: "ON",
              bidStrategy:
                bidStrategyBackendMapping[
                  bidStrategy[Object.keys(conversionLocation)[0]][0]
                ],
              objective:
                objectiveBackendMapping[Object.keys(conversionLocation)[0]],
              adsets: (
                form?.fbAdsStrategyInfo?.topOfTheFunnelLookalike?.adsets || []
              ).map((adset) => {
                return {
                  ...adset,
                  audience: adset.audeince || adset.audience || {},
                  minAge: getAge(
                    form?.onboardingInfo?.facebookTargeting?.minAgeRange || 0
                  ),
                  maxAge: getAge(
                    form?.onboardingInfo?.facebookTargeting?.maxAgeRange || 0
                  ),
                  gender: form?.onboardingInfo?.facebookTargeting?.gender || "",
                  advertisingLocations:
                    form?.onboardingInfo?.facebookTargeting
                      ?.advertisingLocations || [],
                  conversionLocation:
                    destinationTypeBackendMapping[
                      allowedConversionLocation[
                        Object.keys(conversionLocation)[0]
                      ][0]
                    ] || "",
                  fbPixel: pixels?.length ? pixels[0] : {},
                  conversionEvent: conversionEvents[Object.keys(conversionLocation)[0]][0],
                  optimizationAdDelivery:
                    optimizationGoalBackendMapping[
                      ((optimisationAdDelivery[
                        Object.keys(conversionLocation)[0]
                      ] || {})[
                        allowedConversionLocation[
                          Object.keys(conversionLocation)[0]
                        ][0]
                      ] || [])[0]
                    ] || "",
                  cta:
                    CTABackendMapping[
                      ((cta[Object.keys(conversionLocation)[0]] || {})[
                        allowedConversionLocation[
                          Object.keys(conversionLocation)[0]
                        ][0]
                      ] || [])[0]
                    ] || "",
                  facebookAds: (adset.facebookAds || []).map((ad) => {
                    return {
                      ...ad,
                      displayUrl:
                        ad.displayUrl ||
                        form?.onboardingInfo?.clientAccess?.websiteUrl ||
                        form?.onboardingInfo?.personalInfo?.companyWebsite ||
                        "",
                      websiteUrl:
                        ad.websiteUrl ||
                        form?.onboardingInfo?.clientAccess?.websiteUrl ||
                        form?.onboardingInfo?.personalInfo?.companyWebsite ||
                        "",
                      fbPage: ad.fbPage || pages[0] || {},
                    };
                  }),
                };
              }),
            }
      )
    );
    dispatch(
      updateInterest(
        Object.keys(interestData || {}).length && hasInterestChanges
          ? interestData
          : form?.fbAdsCampaignInfo?.topOfTheFunnelInterest || {
              name: "HDM || TOF || IB || CA",
              budget: form?.fbAdsStrategyInfo?.topOfTheFunnelInterest?.budget,
              abo: "ON",
              bidStrategy:
                bidStrategyBackendMapping[
                  bidStrategy[Object.keys(conversionLocation)[0]][0]
                ],
              objective:
                objectiveBackendMapping[Object.keys(conversionLocation)[0]],
              adsets: (
                form?.fbAdsStrategyInfo?.topOfTheFunnelInterest?.adsets || []
              ).map((adset) => {
                return {
                  ...adset,
                  minAge: getAge(
                    form?.onboardingInfo?.facebookTargeting?.minAgeRange || 0
                  ),
                  maxAge: getAge(
                    form?.onboardingInfo?.facebookTargeting?.maxAgeRange || 0
                  ),
                  gender: form?.onboardingInfo?.facebookTargeting?.gender || "",
                  advertisingLocations:
                    form?.onboardingInfo?.facebookTargeting
                      ?.advertisingLocations || [],
                  conversionLocation:
                    destinationTypeBackendMapping[
                      allowedConversionLocation[
                        Object.keys(conversionLocation)[0]
                      ][0]
                    ] || "",
                  fbPixel: pixels?.length ? pixels[0] : {},
                  conversionEvent: conversionEvents[Object.keys(conversionLocation)[0]][0],
                  optimizationAdDelivery:
                    optimizationGoalBackendMapping[
                      ((optimisationAdDelivery[
                        Object.keys(conversionLocation)[0]
                      ] || {})[
                        allowedConversionLocation[
                          Object.keys(conversionLocation)[0]
                        ][0]
                      ] || [])[0]
                    ] || "",
                  cta:
                    CTABackendMapping[
                      ((cta[Object.keys(conversionLocation)[0]] || {})[
                        allowedConversionLocation[
                          Object.keys(conversionLocation)[0]
                        ][0]
                      ] || [])[0]
                    ] || "",
                  facebookAds: (adset.facebookAds || []).map((ad) => {
                    return {
                      ...ad,
                      displayUrl:
                        ad.displayUrl ||
                        form?.onboardingInfo?.clientAccess?.websiteUrl ||
                        form?.onboardingInfo?.personalInfo?.companyWebsite ||
                        "",
                      websiteUrl:
                        ad.websiteUrl ||
                        form?.onboardingInfo?.clientAccess?.websiteUrl ||
                        form?.onboardingInfo?.personalInfo?.companyWebsite ||
                        "",
                      fbPage: ad.fbPage || pages[0] || {},
                    };
                  }),
                };
              }),
            }
      )
    );
    dispatch(
      updatemof(
        Object.keys(mofData || {}).length && hasMOFChanges
          ? mofData
          : form?.fbAdsCampaignInfo?.middleOfTheFunnel || {
              name: "HDM || MOF || CA",
              budget: form?.fbAdsStrategyInfo?.middleOfTheFunnel?.budget,
              abo: "ON",
              bidStrategy:
                bidStrategyBackendMapping[
                  bidStrategy[Object.keys(conversionLocation)[0]][0]
                ],
              objective:
                objectiveBackendMapping[Object.keys(conversionLocation)[0]],
              adsets: (
                form?.fbAdsStrategyInfo?.middleOfTheFunnel?.adsets || []
              ).map((adset) => {
                return {
                  ...adset,
                  audience: adset.audeince || adset.audience || {},
                  minAge: getAge(
                    form?.onboardingInfo?.facebookTargeting?.minAgeRange || 0
                  ),
                  maxAge: getAge(
                    form?.onboardingInfo?.facebookTargeting?.maxAgeRange || 0
                  ),
                  gender: form?.onboardingInfo?.facebookTargeting?.gender || "",
                  advertisingLocations:
                    form?.onboardingInfo?.facebookTargeting
                      ?.advertisingLocations || [],
                  conversionLocation:
                    destinationTypeBackendMapping[
                      allowedConversionLocation[
                        Object.keys(conversionLocation)[0]
                      ][0]
                    ] || "",
                  fbPixel: pixels?.length ? pixels[0] : {},
                  conversionEvent: conversionEvents[Object.keys(conversionLocation)[0]][0],
                  optimizationAdDelivery:
                    optimizationGoalBackendMapping[
                      ((optimisationAdDelivery[
                        Object.keys(conversionLocation)[0]
                      ] || {})[
                        allowedConversionLocation[
                          Object.keys(conversionLocation)[0]
                        ][0]
                      ] || [])[0]
                    ] || "",
                  cta:
                    CTABackendMapping[
                      ((cta[Object.keys(conversionLocation)[0]] || {})[
                        allowedConversionLocation[
                          Object.keys(conversionLocation)[0]
                        ][0]
                      ] || [])[0]
                    ] || "",
                  facebookAds: (adset.facebookAds || []).map((ad) => {
                    return {
                      ...ad,
                      displayUrl:
                        ad.displayUrl ||
                        form?.onboardingInfo?.clientAccess?.websiteUrl ||
                        form?.onboardingInfo?.personalInfo?.companyWebsite ||
                        "",
                      websiteUrl:
                        ad.websiteUrl ||
                        form?.onboardingInfo?.clientAccess?.websiteUrl ||
                        form?.onboardingInfo?.personalInfo?.companyWebsite ||
                        "",
                      fbPage: ad.fbPage || pages[0] || {},
                    };
                  }),
                };
              }),
            }
      )
    );
    dispatch(
      updatebof(
        Object.keys(bofData || {}).length && hasBOFChanges
          ? bofData
          : form?.fbAdsCampaignInfo?.bottomOfTheFunnel || {
              name: "HDM || BOF || CA",
              budget: form?.fbAdsStrategyInfo?.bottomOfTheFunnel?.budget,
              abo: "ON",
              bidStrategy:
                bidStrategyBackendMapping[
                  bidStrategy[Object.keys(conversionLocation)[0]][0]
                ],
              objective:
                objectiveBackendMapping[Object.keys(conversionLocation)[0]],
              adsets: (
                form?.fbAdsStrategyInfo?.bottomOfTheFunnel?.adsets || []
              ).map((adset) => {
                return {
                  ...adset,
                  audience: adset.audeince || adset.audience || {},
                  minAge: getAge(
                    form?.onboardingInfo?.facebookTargeting?.minAgeRange || 0
                  ),
                  maxAge: getAge(
                    form?.onboardingInfo?.facebookTargeting?.maxAgeRange || 0
                  ),
                  gender: form?.onboardingInfo?.facebookTargeting?.gender || "",
                  advertisingLocations:
                    form?.onboardingInfo?.facebookTargeting
                      ?.advertisingLocations || [],
                  conversionLocation:
                    destinationTypeBackendMapping[
                      allowedConversionLocation[
                        Object.keys(conversionLocation)[0]
                      ][0]
                    ] || "",
                  fbPixel: pixels?.length ? pixels[0] : {},
                  conversionEvent: conversionEvents[Object.keys(conversionLocation)[0]][0],
                  optimizationAdDelivery:
                    optimizationGoalBackendMapping[
                      ((optimisationAdDelivery[
                        Object.keys(conversionLocation)[0]
                      ] || {})[
                        allowedConversionLocation[
                          Object.keys(conversionLocation)[0]
                        ][0]
                      ] || [])[0]
                    ] || "",
                  cta:
                    CTABackendMapping[
                      ((cta[Object.keys(conversionLocation)[0]] || {})[
                        allowedConversionLocation[
                          Object.keys(conversionLocation)[0]
                        ][0]
                      ] || [])[0]
                    ] || "",
                  facebookAds: (adset.facebookAds || []).map((ad) => {
                    return {
                      ...ad,
                      displayUrl:
                        ad.displayUrl ||
                        form?.onboardingInfo?.clientAccess?.websiteUrl ||
                        form?.onboardingInfo?.personalInfo?.companyWebsite ||
                        "",
                      websiteUrl:
                        ad.websiteUrl ||
                        form?.onboardingInfo?.clientAccess?.websiteUrl ||
                        form?.onboardingInfo?.personalInfo?.companyWebsite ||
                        "",
                      fbPage: ad.fbPage || pages[0] || {},
                    };
                  }),
                };
              }),
            }
      )
    );
    setIsCampPubHit(false);
  }, [form]);
  useEffect(() => {
    if (pages && pages.length) {
      if (
        (lookalikeData.adsets || []).length &&
        (lookalikeData.adsets[0].facebookAds || []).length &&
        !lookalikeData.adsets[0].facebookAds[0].fbPage.id
      ) {
        dispatch(
          updateLookalike({
            ...lookalikeData,
            adsets: lookalikeData.adsets.map((adset) => {
              return {
                ...adset,
                facebookAds: adset.facebookAds.map((ad) => {
                  return {
                    ...ad,
                    fbPage: pages[0],
                  };
                }),
              };
            }),
          })
        );
      }

      if (
        (interestData.adsets || []).length &&
        (interestData.adsets[0].facebookAds || []).length &&
        !interestData.adsets[0].facebookAds[0].fbPage.id
      ) {
        dispatch(
          updateInterest({
            ...interestData,
            adsets: interestData.adsets.map((adset) => {
              return {
                ...adset,
                facebookAds: adset.facebookAds.map((ad) => {
                  return {
                    ...ad,
                    fbPage: pages[0],
                  };
                }),
              };
            }),
          })
        );
      }
      if (
        (mofData.adsets || []).length &&
        (mofData.adsets[0].facebookAds || []).length &&
        !mofData.adsets[0].facebookAds[0].fbPage.id
      ) {
        dispatch(
          updatemof({
            ...mofData,
            adsets: mofData.adsets.map((adset) => {
              return {
                ...adset,
                facebookAds: adset.facebookAds.map((ad) => {
                  return {
                    ...ad,
                    fbPage: pages[0],
                  };
                }),
              };
            }),
          })
        );
      }
      if (
        (bofData.adsets || []).length &&
        (bofData.adsets[0].facebookAds || []).length &&
        !bofData.adsets[0].facebookAds[0].fbPage.id
      ) {
        dispatch(
          updatebof({
            ...bofData,
            adsets: bofData.adsets.map((adset) => {
              return {
                ...adset,
                facebookAds: adset.facebookAds.map((ad) => {
                  return {
                    ...ad,
                    fbPage: pages[0],
                  };
                }),
              };
            }),
          })
        );
      }
    }
  }, [pages]);

  useEffect(() => {
    if (isBackClicked) {
      setHasCampaignError(false);
      setIsCampaignGenerated(false);
      setIsCampaignGenerating(false);
      dispatch(updateCampaignBackClicked());
    }
  }, [isBackClicked]);
  const updateLookalikeReducer = (data) => {
    dispatch(updateLookalike(data));
    dispatch({
      type: "UPDATE_CHANGES_STATUS",
      payload: {
        campaignType: "lookalikeChanges",
        value: true,
      },
    });
  };
  const updateInterestReducer = (data) => {
    dispatch(updateInterest(data));
    dispatch({
      type: "UPDATE_CHANGES_STATUS",
      payload: {
        campaignType: "interestChange",
        value: true,
      },
    });
  };
  const updateMOFReducer = (data) => {
    dispatch(updatemof(data));
    dispatch({
      type: "UPDATE_CHANGES_STATUS",
      payload: {
        campaignType: "mofChanges",
        value: true,
      },
    });
  };
  const updateBOFReducer = (data) => {
    dispatch(updatebof(data));
    dispatch({
      type: "UPDATE_CHANGES_STATUS",
      payload: {
        campaignType: "bofChanges",
        value: true,
      },
    });
  };

  const backToCampaignClickHandler = () => {
    dispatch(updateCampaignBackClicked());
  };

  const publishedPageClickHandler = () => {
    if (agencyType === "LD") {
      dispatch(goToSpecificCompoClient(ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_PREVIEW_PAGE]));
    } else {
      dispatch(goToSpecificCompoHDMAM(componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_PREVIEW_PAGE]));
    }
  };
  const publishHandler = () => {
    if (!isCampPubHit) {
      setIsCampPubHit(true);
      dispatch(updateCampaignBackClicked());
      dispatch({
        type: "PUBLISH_FB_CAMPAIGN",
      });
    }
  };
  console.log(
    "in hdm campaign creation",
    hasCampaignError,
    isCampaignGenerating,
    isCampaignGenerated,
    isBackClicked
  );
  return isCampaignGenerated ? 
    (<PublishedCampaignPage onClickHandler={publishedPageClickHandler} />) : 
   isCampaignGenerating ? (
    <PublishingCampaignLoader />
  ) : hasCampaignError ? (
    <PublishingCampaignError onClickHandler={backToCampaignClickHandler} />
  ) : (
    <div className="hdm-campaign-creation-container">
      <div className="hdm-campaign-creation-header">
        <Typography className="hdm-campaign-creation-header__title">
          Campaign Creation
        </Typography>
        <Typography className="hdm-campaign-creation-header__description">
          Create and customize your campaigns here, before publishing them.
        </Typography>
      </div>
      <HDMCampaignType
        title={"TOF- Lookalike Campaign"}
        data={lookalikeData}
        updateReducer={updateLookalikeReducer}
        indicator={
          form?.fbAdsCampaignInfo?.topOfTheFunnelLookalike?.status ===
            BACKEND_STATUS.COMPLETED && !hasLookalikeChanges
        }
        isCampaignComplete={!!campaignIds["lookalike"]}
        agencyType={agencyType}
      />
      <HDMCampaignType
        title={"TOF- Interest Campaign"}
        data={interestData}
        updateReducer={updateInterestReducer}
        indicator={
          form?.fbAdsCampaignInfo?.topOfTheFunnelInterest?.status ===
            BACKEND_STATUS.COMPLETED && !hasInterestChanges
        }
        isCampaignComplete={!!campaignIds["interest"]}
        agencyType={agencyType}
      />
      <HDMCampaignType
        title={"MOF Campaign"}
        data={mofData}
        updateReducer={updateMOFReducer}
        indicator={
          form?.fbAdsCampaignInfo?.middleOfTheFunnel?.status ===
            BACKEND_STATUS.COMPLETED && !hasMOFChanges
        }
        isCampaignComplete={!!campaignIds["mof"]}
        agencyType={agencyType}
      />
      <HDMCampaignType
        title={"BOF Campaign"}
        data={bofData}
        updateReducer={updateBOFReducer}
        indicator={
          form?.fbAdsCampaignInfo?.bottomOfTheFunnel?.status ===
            BACKEND_STATUS.COMPLETED && !hasBOFChanges
        }
        isCampaignComplete={!!campaignIds["bof"]}
        agencyType={agencyType}
      />
      <div className="preview_footer_row" style={{height: "74px"}}>
        <div className="footer_con">
          {form?.fbAdsCampaignInfo?.topOfTheFunnelLookalike?.status ===
            BACKEND_STATUS.COMPLETED &&
            !hasLookalikeChanges &&
            form?.fbAdsCampaignInfo?.topOfTheFunnelInterest?.status ===
              BACKEND_STATUS.COMPLETED &&
            !hasInterestChanges &&
            form?.fbAdsCampaignInfo?.middleOfTheFunnel?.status ===
              BACKEND_STATUS.COMPLETED &&
            !hasMOFChanges &&
            form?.fbAdsCampaignInfo?.bottomOfTheFunnel?.status ===
              BACKEND_STATUS.COMPLETED &&
            !hasBOFChanges && (
              <>
                <button
                  className="back"
                  // onClick={() => dispatch(goBackAmCompo())}
                >
                  BACK
                </button>
                <PrimaryButton
                  title="Publish Campaigns"
                  onClick={() => setIsPublishCampaign(true)}
                />
              </>
            )}
        </div>
      </div>
      {isPublishCampaign && (
        <PublishCampaignModal
          formSubm={isPublishCampaign}
          setFormSubm={setIsPublishCampaign}
          publishHandler={publishHandler}
        />
      )}{" "}
    </div>
  );
};
export default HDMCampaignCreation;
