import { Route, Routes, useParams } from "react-router-dom";
import { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from "react-redux";
import hdmAmRoutePaths from "./constants/hdmAmRoutePaths";
import { updateWholeFormDataHdmAm } from "../../api/HDMAM/form/action";
import { GET_HDM_CLIENT_DETAILS_BY_FORM_ID } from "../../api/HDMClient/enum";

// pages
const AccessSharing = lazy(() => import("./AccessSharing/index"));
const CampaignPreview = lazy(() => import("./CampaignPreview"));
const AnalyticDashboard = lazy(() => import("./AnalyticDashboard"));


// nested routers
const ClientOnboardingInputsRouter = lazy(() => import("./ClientOnboardingInputs/Router"));
const OnboardingDocRouter = lazy(() => import("./OnboardingDoc/Router"));
const AuditForGoogleRouter = lazy(() => import("./AuditForGoogle/Router"));
const AuditForFacebookRouter = lazy(() => import("./AuditForFacebook/Router"));
const AccountAnalysisRouter = lazy(() => import("./AccountAnalysis/Router"));
const GoogleStrategyRouter = lazy(() => import('./StrategyGoogle/Router'));
const FacebookStrategyRouter = lazy(() => import('./StrategyFacebook/Router'));
const StrategyDeckRouter = lazy(() => import('./StrategyDeck/Router'));
const GoogleCampaignCreationRouter = lazy(() => import("./GoogleCampaignCreation/Router"));
const FacebookCampaignCreationRouter = lazy(() => import("./FacebookCampaignCreation/Router"));


// layouts
const SideNavigationStepsLayout = lazy(() => import("./layouts/SideNavigationStepsLayout"));

export default function HDMAMRootRouter() {
  return (
    <Suspense fallback={<>loading...</>}>
      <Routes>
        <Route element={<SideNavigationStepsLayout />}>
          <Route path={`${hdmAmRoutePaths.onboardingForm.root}/*`} element={<ClientOnboardingInputsRouter />} />
          <Route path={hdmAmRoutePaths.accessSharing.root} element={<AccessSharing />} />
          <Route path={`${hdmAmRoutePaths.onboardingDoc.root}/*`} element={<OnboardingDocRouter />} />
          <Route path={`${hdmAmRoutePaths.auditForGoogle.root}/*`} element={<AuditForGoogleRouter />} />
          <Route path={`${hdmAmRoutePaths.auditForFacebook.root}/*`} element={<AuditForFacebookRouter />} />
          <Route path={`${hdmAmRoutePaths.accountAnalysis.root}/*`} element={<AccountAnalysisRouter />} />
          <Route path={`${hdmAmRoutePaths.strategyGoogle.root}/*`} element={<GoogleStrategyRouter />} />
          <Route path={`${hdmAmRoutePaths.strategyFacebook.root}/*`} element={<FacebookStrategyRouter />} />
          <Route path={`${hdmAmRoutePaths.strategyDeck.root}/*`} element={<StrategyDeckRouter />} />
          <Route path={`${hdmAmRoutePaths.campaignGoogle.root}/*`} element={<GoogleCampaignCreationRouter />} />
          <Route path={`${hdmAmRoutePaths.campaignFacebook.root}/*`} element={<FacebookCampaignCreationRouter />} />
          <Route path={`${hdmAmRoutePaths.campaignPreview.root}`} element={<CampaignPreview />} />
          <Route path={`${hdmAmRoutePaths.analyticsDashboard.root}`} element={<AnalyticDashboard />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
