import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import './conversion.css';
import {
  applicationStore,
  conversionLocation as conversionLocationMapping,
  allowedConversionLocation,
  cta as ctaMapping,
  hardcode,
  optimisationAdDelivery,
  selectPixelPage,
  CTAFrontendMapping,
  optimizationGoalFrontendMapping,
  destinationTypeFrontendMapping,
  objectiveBackendMapping,
  objectiveFrontendMappiing,
  optimizationGoalBackendMapping,
  CTABackendMapping,
  destinationTypeBackendMapping,
  conversionEvents,
} from './HDMCampaignCreation/constants';
import { getPixelEvents } from '../../api/HDMAM/campaign/action';
import { BACKEND_STATUS } from '../../utils/accountManagerUtil';

const disabledConversionLocation = objective => {
    const allowedArray = allowedConversionLocation[objectiveFrontendMappiing[objective]];
    const allConArray = conversionLocationMapping[objectiveFrontendMappiing[objective]];
    const diff = allConArray?.filter(x => !allowedArray.includes(x));
    return diff;
}

const Conversion = ({
    abo,
    adsetName,
    budget,
    conversionLocationObject={},
    objective,
    conversionEvent,
    optimizationAdDelivery,
    cta,
    fbPixel={},
    fbPage={},
    pixels,
    pages, 
    events,
    pixelapp=undefined,
    appStore=undefined,
    setPixelId=()=>{},
    updateData=()=>{},
    currentIndex=0,
    isCampaignSuccess=false,
    setStatus=()=>{},
}) => {

  const dispatch = useDispatch();
  const [pixelPageAppData, setPixelPageAppData] = useState([]);
  const [selectedPixelPageApp, setSelectedPixelPageApp] = useState(null);
  const [conversionLocationValue, setConversionLocationValue] = useState('');
  const [defaultOptDelivery, setDefaultOptDelivery] = useState('');
  const [defaultCta, setDefaultCta] = useState('');

  console.log('pixelPageAppData:', pixelPageAppData);
  console.log('conversionLocation:', conversionLocationObject);
  console.log('selectedPixelPageApp:', selectedPixelPageApp);
  console.log('conversion location value:', conversionLocationValue);
  console.log('optimization ad delivery:', optimizationAdDelivery);
  console.log('fbPixel:', fbPixel, 'fbPage:', fbPage);
  useEffect(() => {
    let isPixelPageSelected = true;
    if (conversionLocationObject?.frontend) {
        // may need to change to an array
      setConversionLocationValue(
        conversionLocationObject?.frontend,
      );
      const conValue = conversionLocationObject?.frontend;
      if (
        selectPixelPage[objectiveFrontendMappiing[objective]][
          conValue
        ] === 'pixel'
      ) {
       setSelectedPixelPageApp(fbPixel?.pixelId ? fbPixel : pixels?.[0]);
       updateData(fbPixel?.pixelId ? fbPixel : pixels?.[0], 'fbPixel');
       // setSelectedPixelPageApp([]);
      } else if (
        selectPixelPage[objectiveFrontendMappiing[objective]][
          conValue
        ] === 'page'
      ) {
       setSelectedPixelPageApp(fbPage?.id ? fbPage : pages?.[0]);
       updateData(fbPage?.id ? fbPage : pages?.[0], 'fbPage');
       // setSelectedPixelPageApp([]);
       isPixelPageSelected = false;
      } else {
       setSelectedPixelPageApp([]);
       updateData({ pixelId: "", name: "" }, 'fbPixel');
       updateData({ id: "", name: "", businessId: "", businessName: "" }, 'fbPage');
       isPixelPageSelected = false;
      }
      if  ((selectPixelPage[
             objectiveFrontendMappiing?.[objective]
           ][conValue] === 'pixel') ||
         (isPixelPageSelected)) {
        const convEvent = conversionEvents[objectiveFrontendMappiing?.[objective]][0];
        updateData(conversionEvent || convEvent, 'conversionEvent');
      }
      else {
        updateData(null, 'conversionEvent');
      }
      const optimization =  
             optimizationGoalBackendMapping[
               ((optimisationAdDelivery[
                 objectiveFrontendMappiing?.[objective]
               ] || {})[conValue] || [])[0]]
      updateData(optimizationAdDelivery || optimization, 'optimizationAdDelivery');
      const defaultcta = 
             CTABackendMapping[
               ((ctaMapping[objectiveFrontendMappiing?.[objective]] ||
                 {})[conValue] || [])[0]]
      updateData(cta || defaultcta, 'cta');
    } 
  }, [JSON.stringify(conversionLocationObject), currentIndex, JSON.stringify(fbPixel), JSON.stringify(fbPage), optimizationAdDelivery, pages, pixels, objective, cta, conversionEvent]);

  useEffect(() => {
    if (
      selectPixelPage[objectiveFrontendMappiing?.[objective]]?.[
        conversionLocationValue
      ] === 'pixel'
    ) {
      setPixelPageAppData(pixels);
    } else if (
      selectPixelPage[objectiveFrontendMappiing?.[objective]]?.[
        conversionLocationValue
      ] === 'page'
    ) {
      setPixelPageAppData(pages);
    } else {
      setPixelPageAppData([]);
    }

    setDefaultOptDelivery(
      (optimisationAdDelivery[
        objectiveFrontendMappiing?.[objective]
      ]?.[conversionLocationValue] || [])?.[0]
    );
    setDefaultCta(
      CTAFrontendMapping[
        (ctaMapping[objectiveFrontendMappiing?.[objective]]?.[
          conversionLocationValue
        ] || [])?.[0]
      ]
    );
  }, [conversionLocationValue, pixels, pages, currentIndex]);

  const handleChange = (e, field) => {
    if (field === 'conversionLocation') {
      if (
        allowedConversionLocation[
          objectiveFrontendMappiing[objective]
        ].includes(e.target.value)
      ) {
        setConversionLocationValue(e.target.value);
        updateData(e.target.value, 'conversionLocation');
        // setConversionLocation(e.target.value);
      }
    } else if (field == 'pixelPageApp') {
      if (
        selectPixelPage[objectiveFrontendMappiing[objective]][
          conversionLocationValue
        ] === 'pixel'
      ) {
        //  setFbpixel(e.target.value)
        updateData(e.target.value, 'fbPixel');
        // dispatch(getPixelEvents(e.target.value.pixelId));
        setPixelId(e.target.value.pixelId);
        setSelectedPixelPageApp(e.target.value);
      } else if (
        selectPixelPage[objectiveFrontendMappiing[objective]][
          conversionLocationValue
        ] === 'page'
      ) {
        // setFbpage(e.target.value);
        updateData(e.target.value, 'fbPage');
        setSelectedPixelPageApp(e.target.value);
      }
    } else if (field === 'optimizationAdDelivery') {
      console.log(
        '$$$$$$$$$$$$$$ in optimizationAdDelivery',
        optimizationGoalBackendMapping[e.target.value],
        e.target.value
      );
        // setOptimizationAdDelivery(e.target.value);
      updateData(
        optimizationGoalBackendMapping[e.target.value],
        field
      );
    } else if (field === 'cta') {
        // setCta(CTABackendMapping[e.target.value]);
      updateData(CTABackendMapping[e.target.value], field);
    } else {
      // field = adsetName || budget || conversionEvent
      updateData(e.target.value, field);
    }
  };

  return (
    <div className='conversion-container'>
      <div className='header-conversion-container'>
        <Typography className='conversion-header__title'>
          Conversions
        </Typography>
      </div>

      <div className='conversion-form'>
        <div className='conversion-form-element'>
          <Typography className='conversion-form-element__title'>
            Adset Name
          </Typography>
          <TextField
            placeholder='Enter Text'
            variant='outlined'
            sx={{
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}
            value={adsetName}
            disabled={isCampaignSuccess}
            onChange={(e) => { 
                updateData(e.target.value, 'adsetName');
                setStatus(BACKEND_STATUS.ONGOING);
            }}
            inputProps={{
              style: {
                padding: '11px 16px',
                background: '#FFFFFF',
                border: '1px solid #DDDDDD',
                borderRadius: 8,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12,
                lineHeight: '20px',
              },
            }}
          />
        </div>
        <div
          className='conversion-form-element'
          style={{ opacity: abo === 'ON' ? '0.3' : '' }}
        >
          <Typography className='conversion-form-element__title'>
            Daily Budget (in $)
          </Typography>
          <TextField
            placeholder='Enter Text'
            variant='outlined'
            type='number'
            value={budget || ''}
            onChange={(e) => { 
                updateData(e.target.value, 'budget')
                setStatus(BACKEND_STATUS.ONGOING);
            }}
            disabled={abo === 'ON' || isCampaignSuccess}
            sx={{
              'input::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              'input::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
              },
              'input[type=number]': {
                '-moz-appearance': 'textfield',
              },
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': { border: 0 },
            }}
            inputProps={{
              style: {
                padding: '11px 16px',
                background: '#FFFFFF',
                border: '1px solid #DDDDDD',
                borderRadius: 8,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12,
                lineHeight: '20px',
              },
            }}
          />
        </div>
      </div>
      <div style={{ marginBottom: 16 }}>
        <div className='conversion-flow-steps'>
          <div className='flow-line'></div>
          <div className='conversion-flow-step'>
            <div
              className={`${
                  conversionLocationObject?.frontend
                  ? 'complete-status'
                  : 'current-status'
              }`}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '7px',
                width: '100%',
                paddingTop: 3,
              }}
            >
              <Typography className='conversion-form-element__title'>
                Conversion Location
              </Typography>
              <div>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    style={{
                      height: 35,
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      background: '#FFFFFF',
                      border: '1px solid #DDDDDD',
                      borderRadius: 8,
                      color: '#000',
                    }}
                    placeholder={'Select'}
                    onChange={(e) => {
                      handleChange(e, 'conversionLocation');
                      setStatus(BACKEND_STATUS.ONGOING);
                    }}
                    value={
                        (conversionLocationValue)
                    }
                  >
                    {(
                      conversionLocationMapping[
                        objectiveFrontendMappiing[
                          objective || ''
                        ] || ''
                      ] || []
                    ).map((name, index) => {
                      return (
                        <MenuItem
                          disabled={isCampaignSuccess || disabledConversionLocation(objective)?.includes(name)}
                          key={index}
                          style={{
                            // background: [
                            //   "App",
                            //   "Instant forms & Messenger",
                            //   "Calls",
                            // ].includes(name)
                            //   ? "#d3d3d3"
                            //   : "",
                            cursor: [
                              'App',
                              'Instant forms & Messenger',
                              'Calls',
                            ].includes(name)
                              ? 'revert'
                              : 'pointer',
                          }}
                          value={name}
                        >
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='conversion-flow-step'>
            <div
              className={`${
                !conversionLocationObject?.frontend
                  ? 'pending-status'
                  : selectedPixelPageApp
                  ? 'complete-status'
                  : 'current-status'
              }`}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '7px',
                width: '100%',
                paddingTop: 3,
                opacity: !conversionLocationObject?.frontend ? '0.3' : '',
              }}
            >
              <Typography className='conversion-form-element__title'>
                Pixel/Page/App/Engagement Type
              </Typography>
              <div>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    disabled={!conversionLocationObject?.frontend}
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    style={{
                      height: 35,
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      background: '#FFFFFF',
                      border: '1px solid #DDDDDD',
                      borderRadius: 8,
                      color: '#000',
                    }}
                    value={(selectedPixelPageApp || {})?.name || ''}
                    renderValue={(selected) => selected}
                    onChange={(e) => {
                      handleChange(e, 'pixelPageApp');
                      setStatus(BACKEND_STATUS.ONGOING);
                    }}
                  >
                    {
                      pixelPageAppData?.length ? 
                      pixelPageAppData?.map((data) => {
                      return <MenuItem value={data} disabled={isCampaignSuccess}>{data.name}</MenuItem>;
                    })
                        :
                        <MenuItem disabled={true}> 
                          No data found in the ad account. Please attach <br/> 
                          pixels/pages in the ad account to view them here.
                        </MenuItem>
                    }
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='conversion-flow-step'>
            <div
              className={`${
                !pixelapp && !appStore
                  ? 'pending-status'
                  : appStore
                  ? 'complete-status'
                  : 'current-status'
              }`}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '7px',
                width: '100%',
                paddingTop: 3,
                opacity:
                  !conversionLocationObject?.frontend || !pixelapp
                    ? '0.3'
                    : '',
              }}
            >
              <Typography className='conversion-form-element__title'>
                App Store/ Mobile Store
              </Typography>
              <div>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    disabled={
                      !conversionLocationObject?.frontend || !pixelapp
                    }
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    style={{
                      height: 35,
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      background: '#FFFFFF',
                      border: '1px solid #DDDDDD',
                      borderRadius: 8,
                      color: '#999999',
                    }}
                    value={appStore || ""}
                    onChange={(e) => {
                      handleChange(e, 'appStore');
                      setStatus(BACKEND_STATUS.ONGOING);
                    }}
                  >
                    {objective &&
                      conversionLocationObject?.frontend &&
                      (
                        applicationStore[
                          objectiveFrontendMappiing[objective]
                        ][conversionLocationObject?.frontend] || []
                      )?.map((name) => {
                        return <MenuItem value={name} disabled={isCampaignSuccess}>{name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='conversion-flow-step'>
            <div
              className={`${
                (objective &&
                  selectPixelPage[
                    objectiveFrontendMappiing[objective]
                  ][conversionLocationValue] !== 'pixel') ||
                !selectedPixelPageApp
                  ? // !events[(selectedPixelPageApp || {}).pixelId]
                    'pending-status'
                  : conversionEvent
                  ? 'complete-status'
                  : 'current-status'
              }`}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '7px',
                width: '100%',
                paddingTop: 3,
                opacity:
                  (objective &&
                    selectPixelPage[
                      objectiveFrontendMappiing[objective]
                    ][conversionLocationValue] !== 'pixel') ||
                  !selectedPixelPageApp
                    ? // !events[(selectedPixelPageApp || {}).pixelId]
                      '0.3'
                    : '',
              }}
            >
              <Typography className='conversion-form-element__title'>
                Conversion Event
              </Typography>
              <div>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    disabled={
                      (objective &&
                        selectPixelPage[
                          objectiveFrontendMappiing[objective]
                        ][conversionLocationValue] !== 'pixel') ||
                      !selectedPixelPageApp
                      // !events[(selectedPixelPageApp || {}).pixelId]
                    }
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    style={{
                      height: 35,
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      background: '#FFFFFF',
                      border: '1px solid #DDDDDD',
                      borderRadius: 8,
                      color: '#000',
                    }}
                    value={conversionEvent || ''}
                    onChange={(e) => {
                      handleChange(e, 'conversionEvent');
                      setStatus(BACKEND_STATUS.ONGOING);
                    }}
                  >
                    {/* {(events[(selectedPixelPageApp || {}).pixelId] || []).map( */}
                    {(
                      (objective &&
                        conversionEvents[
                          objectiveFrontendMappiing[objective]
                        ]) ||
                      []
                    )?.map((name) => {
                      return <MenuItem value={name} disabled={isCampaignSuccess}>{name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='conversion-flow-step'>
            <div
              className={`${
                !conversionLocationObject?.frontend &&
                !optimizationAdDelivery
                  ? 'pending-status'
                  : optimizationAdDelivery
                  ? 'complete-status'
                  : 'current-status'
              }`}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '7px',
                width: '100%',
                paddingTop: 3,
                opacity: !conversionLocationObject?.frontend ? '0.3' : '',
              }}
            >
              <Typography className='conversion-form-element__title'>
                Optimisation for Ad Delivery
              </Typography>
              <div>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    disabled={!conversionLocationObject?.frontend}
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    style={{
                      height: 35,
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      background: '#FFFFFF',
                      border: '1px solid #DDDDDD',
                      borderRadius: 8,
                      color: '#000',
                    }}
                    value={
                      optimizationGoalFrontendMapping[
                        optimizationAdDelivery
                      ] || defaultOptDelivery
                    }
                    defaultValue={
                      optimizationGoalFrontendMapping[
                        optimizationAdDelivery
                      ]
                    }
                    renderValue={(selected) => selected}
                    onChange={(e) => {
                      handleChange(e, 'optimizationAdDelivery');
                      setStatus(BACKEND_STATUS.ONGOING);
                    }}
                  >
                    {objective &&
                      optimisationAdDelivery[
                        objectiveFrontendMappiing[objective]
                      ][conversionLocationValue]?.map((name) => {
                        return (
                          <MenuItem
                            disabled={isCampaignSuccess || ['Conversion Leads', 'Daily Unique Reach'].includes(name)}
                            value={name}
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className='conversion-flow-step'>
            <div
              className={`${
                !conversionLocationObject?.frontend && !cta
                  ? 'pending-status'
                  : cta
                  ? 'complete-status'
                  : 'current-status'
              }`}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '7px',
                width: '100%',
                paddingTop: 3,
                opacity: !conversionLocationObject?.frontend ? '0.3' : '',
              }}
            >
              <Typography className='conversion-form-element__title'>
                CTA
              </Typography>
              <div>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    disabled={!conversionLocationObject?.frontend}
                    // value={props.data.cta}
                    value={CTAFrontendMapping[cta] || defaultCta}
                    renderValue={(selected) => selected}
                    sx={{
                      boxShadow: 'none',
                      '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    }}
                    style={{
                      height: 35,
                      fontFamily: 'Inter',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: 12,
                      background: '#FFFFFF',
                      border: '1px solid #DDDDDD',
                      borderRadius: 8,
                      color: '#000',
                    }}
                    onChange={(e) => {
                      handleChange(e, 'cta');
                      setStatus(BACKEND_STATUS.ONGOING);
                    }}
                  >
                    {objective &&
                      ctaMapping?.[objectiveFrontendMappiing?.[objective]]?.[
                          conversionLocationObject?.frontend
                      ]?.map((name) => {
                        return <MenuItem value={name} disabled={isCampaignSuccess || name === 'See Menu'}>{name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversion;
