import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import z, { ZodIssueCode, coerce, number, object, string } from 'zod';
import { useGetClientForms } from '../../../services/clientForms';
import { useGetAudiences } from '../../../services/facebook-ads';
import {
  useDeleteFbAdsCampaignStartegyAdsets,
  useGetFBAdsCampaignStrategyById,
  usePutFbAdsCampaignStrategy
} from '../../../services/fb-campaign-strategy';
import { BACKEND_STATUS } from '../../../utils/accountManagerUtil';
import { getWebsiteUrl } from "../../../utils/getWebsiteWrapper";
import PrimaryButton from '../../ButtonPrimary/Buttons';
import GoToNextCompoButton from '../../ButtonPrimary/GoToNextCompoButton';
import PleaseWaitLoaderModal from '../../modals/PleaseWaitLoaderModal';
import CreateAdsetModal from '../CreateAdsetModal';
import { ADSET_STATUS, TARGETING_TYPE } from '../utils';
import AddNewAdModal from './AddNewAdModal';
import AdsList from './AdsList';
import AdsetList from "./AdsetList";
import ConfirmAdsetDeleteModal from './ConfirmAdsetDeleteModal';
import ConfirmAdsetLevelModal from './ConfirmAdsetLevelModal';
import EditAdModal from './EditAdModal';

// constants
const NULL_FORM = {};
export const NEW_AD_KEY_NAME = "NEW_AD";
export const CAMPAIGN_TYPE = {
  TOF_LOOKALIKE: "top_of_the_funnel_lookalike",
  TOF_INTEREST: "top_of_the_funnel_interest",
  MOF: "middle_of_the_funnel",
  BOF: "bottom_of_the_funnel",
};

function getUniqueItems(array) {
  if (!Array.isArray(array)) return [];
  return [...new Set(array.filter(Boolean))];
}

// https://github.com/colinhacks/zod/issues/1394#issuecomment-1346013265
const schema = object({
  adsetCount: coerce.number().default(0),
  adsets: object({
    adsetName: string().min(1, "adsetName is required"),
    adsetNumber: number(),
    status: z.enum(Object.values(ADSET_STATUS)),
    targetingType: z.enum(Object.values(TARGETING_TYPE)).default(TARGETING_TYPE.LOOKALIKE),
    audience: object({
      id: string(),
      maxReach: coerce.number(),
      minReach: coerce.number(),
      name: string(),
      subtype: string().optional(),
    }).optional().nullable(),
    minRange: coerce.number().optional(),
    maxRange: coerce.number().optional(),
    id: string().optional(),
    facebookAds: object({
      imageUrl: string().optional(),
      headline: string().optional(),
      description: string().optional(),
      primaryText: string().optional(),
      adName: string().min(1, "adName is required"),
    }).array().default([]),
    keywords: object({
      id: string(),
      name: string(),
    }).array().default([]),
  }).superRefine(
    (data, ctx) => {
      if (TARGETING_TYPE.LOOKALIKE === data.targetingType) {
        if (!data.maxRange) ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ["minRange"],
          message: "Min Range is required",
        });

        if (!data.minRange) ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ["maxRange"],
          message: "Max Range is required",
        });
      }

      if ([TARGETING_TYPE.AUDIENCE, TARGETING_TYPE.LOOKALIKE].includes(data.targetingType)) {
        if (!data.audience) ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ["audience"],
          message: "audience is required",
        });
      }
    },
  ).array().min(1),
});

export const SLIDDER_SETTINGS = {
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  variableWidth: true,
  dots: false,
};

const FORM_ID = `campaign-strategy-adset-level`;

export default function CampaignStrategyAdsetLevel({
  onGoToCampaignView
}) {
  const { strategyId, formId, strategyFlow } = useParams();

  const { data: form = NULL_FORM } = useGetClientForms(formId);
  const { clientStrategyFacebookAdsInfo } = form;

  const [activeAdsetIndex, setActiveAdsetIndex] = useState(0);

  const [isAddNewAdModalOpen, setIsAddNewAdModalOpen] = useState(false);
  const openAddNewAdModal = () => setIsAddNewAdModalOpen(true);
  const closeAddNewAdModal = () => setIsAddNewAdModalOpen(false);

  const [isCreateAdsetModalOpen, setIsCreateAdsetModalOpen] = useState(false);
  const openCreateAdsetModal = () => setIsCreateAdsetModalOpen(true);
  const closeCreateAdsetModal = () => setIsCreateAdsetModalOpen(false);

  const [isAdsetsConfirmedModalOpen, setIsAdsetsConfirmedModalOpen] = useState(false);
  const openAdsetsConfirmedModal = () => setIsAdsetsConfirmedModalOpen(true);
  const closeAdsetsConfirmedModal = () => setIsAdsetsConfirmedModalOpen(false);

  const [adsetIndexToDelete, setAdsetIndexToDelete] = useState(null);
  const openAdsetDeleteModalCurried = (index) => () => setAdsetIndexToDelete(index);
  const closeAdsetDeleteModal = () => setAdsetIndexToDelete(null);

  // used to track the deleted ids on frontend level, as a septerate request to delete is not made for each adset,
  // but instead collective single api hit is made on proceed
  const [adsetIdsToDelete, setAdsetIdsToDelete] = useState([]);

  // presence of this field opens the edit modal
  const [editAdModalFieldKey, setAdModalFieldKey] = useState("");
  const closeEditAdModal = () => setAdModalFieldKey("");

  const formMethods = useForm({
    defaultValues: NULL_FORM,
    resolver: zodResolver(schema),
    mode: "all",
  });

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    register,
    getValues,
    formState: { isValid },
    watch,
  } = formMethods;

  const { fields: adsets, remove: removeAdsetByIndex, append: appendNewAdset, replace: replaceAdsets } = useFieldArray({
    name: "adsets",
    control,
    keyName: "key",
  });

  const { remove: removeAdByIndex, append: appendAd } = useFieldArray({
    name: `adsets.${activeAdsetIndex}.facebookAds`,
    control,
  });
  // fields key on fieldarray does not stay in sync with name changes
  const ads = watch(`adsets.${activeAdsetIndex}.facebookAds`);

  // api requests
  const { isInitialLoading: isLoadingStrategy } = useGetFBAdsCampaignStrategyById({ formId, strategyId }, {
    onSuccess(data) {
      reset(data);
    },
  });

  const { data: audiences } = useGetAudiences(clientStrategyFacebookAdsInfo?.accountId, {
    enabled: !!clientStrategyFacebookAdsInfo?.accountId,
    select: (responseData) => responseData.audiences,
  });

  const { mutateAsync: updateStrategyAsync } = usePutFbAdsCampaignStrategy({ formId }, {
    onSuccess: () => openAdsetsConfirmedModal(true),
  });

  const { mutateAsync: deleteAdsetsAsync } = useDeleteFbAdsCampaignStartegyAdsets({ formId, strategyId })

  // form callbacks
  const onClearAllAdsets = () => setValue(`adsets.${activeAdsetIndex}.facebookAds`, []);
  const onChangeActiveAdsetIndex = (index) => () => setActiveAdsetIndex(index);
  const onDeleteAdsetConfirm = () => {
    const adsetIdToDelete = adsets[adsetIndexToDelete]?.id;
    removeAdsetByIndex(adsetIndexToDelete);
    setTimeout(() => {
      const nextIndex = adsetIndexToDelete !== 0 ? adsetIndexToDelete - 1 : adsetIndexToDelete;
      setAdsetIdsToDelete(prev => [...prev, adsetIdToDelete]);
      setActiveAdsetIndex(nextIndex);
      closeAdsetDeleteModal();
    }, 0);
  };

  const getNextAdsetNumber = () => (getValues().adsetCount ?? 0) + 1
  const onAddNewAdset = (adset) => {
    setActiveAdsetIndex(adsets.length);
    appendNewAdset(adset);
    setTimeout(() => {
      const nextAdsetCount = getNextAdsetNumber();
      setValue("adsetCount", nextAdsetCount);
    }, 0);
  };

  const onOpenCreateNewAdModal = () => setAdModalFieldKey(NEW_AD_KEY_NAME);

  const onOpenEditModal = (facebookAdIndex) => () => setAdModalFieldKey(`adsets.${activeAdsetIndex}.facebookAds.${facebookAdIndex}`);
  const onDeleteAd = (facebookAdIndex) => () => removeAdByIndex(facebookAdIndex);

  const onAddNewAd = ({ addToThisAdsetList, addToAllAdsetList }) => {
    const adsets = getValues().adsets;
    replaceAdsets(adsets.map(
      (adset, index) => index === activeAdsetIndex
        ? { ...adset, facebookAds: [...adset.facebookAds, ...addToThisAdsetList, ...addToAllAdsetList] }
        : { ...adset, facebookAds: [...adset.facebookAds, ...addToAllAdsetList] }
    ));
  };
  const onUpdateAd = ({ name, value }) => {
    // for new ad append facebookAds
    if (name === NEW_AD_KEY_NAME) appendAd({ ...value, adName: `AD ${ads.length + 1}` });
    else setValue(name, value);

    // cleanup
    closeAddNewAdModal();
  };

  const onSubmit = async (values) => {
    const _adsetIdsToDelete = getUniqueItems(adsetIdsToDelete);
    console.log({ strategy: values, _adsetIdsToDelete });
    if (!!_adsetIdsToDelete.length) {
      await deleteAdsetsAsync({ adsetIds: getUniqueItems(adsetIdsToDelete) });
    }
    await updateStrategyAsync({
      strategy: {
        ...values,
        status: BACKEND_STATUS.ONGOING,
      },
      strategyId
    });
  };
  const onErrors = (errors) => console.log({ formErrors: errors, values: getValues() });

  return (
    <FormProvider {...formMethods}>
      <form
        className='hd_strategy_layout'
        onSubmit={handleSubmit(onSubmit, onErrors)}
        id={FORM_ID}
        style={{ position: "relative", flexGrow: 1 }}
      >
        <input type="hidden" {...register("adsetCount")} />
        <div className='hd_strategy_head flex-center-space'>
          <div className='hd_strategy_drop_head flex-center-space' />
          <div className='hd_strategy_button_row flex-center-space'>
            <PrimaryButton
              type='errorLight'
              className='edit_response_button'
              title='Clear All Ads'
              icon='clear'
              onClick={onClearAllAdsets}
              buttonType="button"
            />
            <PrimaryButton
              type='light'
              className='edit_response_button'
              title='+ Add New Ad'
              buttonType="button"
              onClick={openAddNewAdModal}
            />
          </div>
        </div>

        {/*adsets*/}
        <div className='hd_strategy_sections flex-top-space'>
          <div className='adset_hdm_row'>
            <div className='adset_hdm_row_inner'>
              <AdsetList
                activeAdsetIndex={activeAdsetIndex}
                adsets={adsets}
                audiences={audiences}
                onChangeActiveAdsetIndex={onChangeActiveAdsetIndex}
                openAdsetDeleteModalCurried={openAdsetDeleteModalCurried}
              />
            </div>
            <div className='addnew_adset_row'>
              <button
                className='addnew_adset_button'
                onClick={openCreateAdsetModal}
                type="button"
              >
                + ADD NEW ADSET
              </button>
            </div>
          </div>

          {/*slide*/}
          <div className='hd_strategy_column'>
            <div className='adset_slider'>
              <AdsList
                onOpenEditModal={onOpenEditModal}
                onDeleteAd={onDeleteAd}
                activeAdsetIndex={activeAdsetIndex}
                websiteUrl={getWebsiteUrl(form)}
                openAddNewAdModal={openAddNewAdModal}
                ads={ads}
              />
            </div>
          </div>
        </div>
      </form >

      <div className='preview_footer_row'>
        <div className='footer_con'>
          <button typp="button" className='back' onClick={onGoToCampaignView}>BACK</button>
          <GoToNextCompoButton
            title='Confirm'
            disabled={!isValid}
            type="submit"
            form={FORM_ID}
          />
        </div>
      </div>

      {/*creative*/}
      <AddNewAdModal
        open={isAddNewAdModalOpen}
        onClose={closeAddNewAdModal}
        websiteUrl={getWebsiteUrl(form)}
        onAddNewAd={onAddNewAd}
        onCreateNewAdd={onOpenCreateNewAdModal}
        formId={formId}
        strategyFlow={strategyFlow}
        strategyId={strategyId}
      />

      {/*edit ad*/}
      {
        !!editAdModalFieldKey && (
          <EditAdModal
            open={true}
            control={control}
            editAdModalFieldKey={editAdModalFieldKey}
            onClose={closeEditAdModal}
            websiteUrl={getWebsiteUrl(form)}
            onUpdateAd={onUpdateAd}
          />
        )
      }

      <PleaseWaitLoaderModal open={isLoadingStrategy} />

      <CreateAdsetModal
        shouldCreate={isCreateAdsetModalOpen}
        onAddNewAdset={onAddNewAdset}
        formId={formId}
        strategyId={strategyId}
        mountNodeId={FORM_ID}
        onClose={closeCreateAdsetModal}
        getNextAdsetNumber={getNextAdsetNumber}
        replaceAdsets={replaceAdsets}
        strategyFlow={strategyFlow}
        control={control}
      />

      <ConfirmAdsetLevelModal
        open={isAdsetsConfirmedModalOpen}
        onClose={closeAdsetsConfirmedModal}
        onGoToCampaignView={onGoToCampaignView}
      />

      <ConfirmAdsetDeleteModal
        onConfirm={onDeleteAdsetConfirm}
        onCancel={closeAdsetDeleteModal}
        open={adsetIndexToDelete !== null}
      />
    </FormProvider>
  )
}
