import { all, put, select, takeLatest } from "redux-saga/effects";
import { api } from "../../api/index";
import {
  getLocalStorage,
  setLocalStorage,
} from "../../api/localStorage/localStorage";
import { clientDashboardIds, componentWithIndexHDMClient } from "../../utils/HDMClientDashboardIdsUtil";
import {
  APPROVE_STRATEGY_DECK_WITH_DETAILS,
  GET_HDM_CLIENT_DETAILS_BY_FORM_ID,
  HDM_CLIENT_STATUS_SAVE_DB,
  SAGA_UPDATE_HDM_CLIENT_DETAILS,
} from "./enum";

function* updateHDMClientDetails(_, isPublished = false) {
  let hdmClient = yield select((state) => state.hdmClient);
  hdmClient=JSON.parse(JSON.stringify({...hdmClient}));
  // const currentComp = yield select(
  //   (state) => state.hdmClientCurrentComp.currentComp
  // );
  // hdmClient["onboardingInfo"]["status"] =
  //   hdmClient["onboardingInfo"]["status"] || "ONGOING";
  // hdmClient["accessSharing"]["status"] =
  //   hdmClient["accessSharing"]["status"] ||
  //   "PENDING";
  // hdmClient["onboardingInfo"]["targetLuanchDate"] =
  //   hdmClient["onboardingInfo"]["targetLuanchDate"] ||
  //   new Date(new Date().setDate(new Date().getDay() + 10)).toDateString();

  // if (HDM_CLIENT_STATUS_SAVE_DB[currentComp] !== undefined) {
  //   const { parentKey, childKey } = HDM_CLIENT_STATUS_SAVE_DB[currentComp];
  //   hdmClient[parentKey][childKey]["status"] = "COMPLETED";
  // } else if (
  //   currentComp === componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS1] &&
  //   hdmClient.onboardingInfo.clientAccess.status !== "COMPLETED"
  // ) {
  //   hdmClient["onboardingInfo"]["clientAccess"]["status"] = "ONGOING";
  //   hdmClient["onboardingInfo"]["status"] = "ONGOING";
  // } else if (
  //   currentComp === componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING1] &&
  //   hdmClient.onboardingInfo.brandMessaging.status !== "COMPLETED"
  // ) {
  //   hdmClient["onboardingInfo"]["brandMessaging"]["status"] = "ONGOING";
  //   hdmClient["onboardingInfo"]["status"] = "ONGOING";
  // } else if (
  //   currentComp === componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE1]&&
  //   hdmClient.onboardingInfo.facebookTargeting.status !== "COMPLETED"
  // ) {
  //   hdmClient["onboardingInfo"]["facebookTargeting"]["status"] = "ONGOING";
  //   hdmClient["onboardingInfo"]["status"] = "ONGOING";
  // }else if (
  //   currentComp === componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING1] &&
  //   hdmClient.onboardingInfo.googleTargeting.status !== "COMPLETED"
  // ) {
  //   hdmClient["onboardingInfo"]["googleTargeting"]["status"] = "ONGOING";
  //   hdmClient["onboardingInfo"]["status"] = "ONGOING";
  // } else if (
  //   currentComp === componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS1] &&
  //   hdmClient.onboardingInfo.aboutBusiness.status !== "COMPLETED"
  // ) {
  //   hdmClient["onboardingInfo"]["aboutBusiness"]["status"] = "ONGOING";
  //   hdmClient["onboardingInfo"]["status"] = "ONGOING";
  // } else if (
  //   currentComp === componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD1] &&
  //   hdmClient.onboardingInfo.creativeAndMontageAds.status !== "COMPLETED"
  // ) {
  //   hdmClient["onboardingInfo"]["creativeAndMontageAds"]["status"] = "ONGOING";
  //   hdmClient["onboardingInfo"]["status"] = "ONGOING";
  // }

  // if (
  //   currentComp === componentWithIndexHDMClient[clientDashboardIds.REVIEW_HDMQUESTIONIER_RESPONSES] &&
  //   hdmClient.onboardingInfo.creativeAndMontageAds.status === "COMPLETED"
  // ) {
  //   hdmClient["published"] = true;
  //   hdmClient["onboardingInfo"]["status"] = "COMPLETED";
  // }

  // if(currentComp === componentWithIndexHDMClient[clientDashboardIds.HDM_ACCESS]){
  //   hdmClient["accessSharing"]["status"] = "ONGOING";
  // }

  const isFormIdExist = {};
  if (getLocalStorage("formId")) {
    isFormIdExist["_id"] = getLocalStorage("formId");
  }
  if (getLocalStorage("amSelectedFormId")) {
    isFormIdExist["_id"] = getLocalStorage("amSelectedFormId");
  }
  if (getLocalStorage("amSelectedBrandId")) {
    isFormIdExist["brandId"] = getLocalStorage("amSelectedBrandId");
  }
  if (getLocalStorage("brandId") && getLocalStorage("brandId") !== "undefined") {
    isFormIdExist["brandId"] = getLocalStorage("brandId");
  }

  const desiredObj = { ...hdmClient, published: isPublished, ...isFormIdExist };
  try {
    const res = yield api.post("/client-forms", desiredObj, false);
    yield put({
      type: "UPDATE_WHOLE_HDM_CLIENT_FORMID",
      payload: res.data,
    });
    yield put({
      type: "UPDATE_WHOLE_CLIENT_FORMID",
      payload: res.data,
    });
    setLocalStorage("formId", res.data._id);
  } catch (err) {
    console.log(err);
  }
}


function* approveStrategyDeckWithDetails(action) {
  let hdmClient = yield select((state) => state.hdmClient);
  hdmClient=JSON.parse(JSON.stringify({...hdmClient}));

  const fbAdsStrategyInfo = hdmClient?.fbAdsStrategyInfo;

  const isFormIdExist = {};
  if (getLocalStorage("formId")) {
    isFormIdExist["_id"] = getLocalStorage("formId");
  }
  if (getLocalStorage("amSelectedFormId")) {
    isFormIdExist["_id"] = getLocalStorage("amSelectedFormId");
  }
  if (getLocalStorage("amSelectedBrandId")) {
    isFormIdExist["brandId"] = getLocalStorage("amSelectedBrandId");
  }
  if (getLocalStorage("brandId") && getLocalStorage("brandId") !== "undefined") {
    isFormIdExist["brandId"] = getLocalStorage("brandId");
  }

  const strategyIterations = [...(hdmClient?.strategyIterations || []), {state:"APPROVED",suggestedChanges:[]}]

  const desiredObj = { fbAdsStrategyInfo ,strategyIterations, ...isFormIdExist };
  try {
    const res = yield api.post("/client-forms", desiredObj, false);
    yield put({
      type: "UPDATE_WHOLE_HDM_CLIENT_FORMID",
      payload: res.data,
    });
    yield put({
      type: "UPDATE_WHOLE_CLIENT_FORMID",
      payload: res.data,
    });
    action.payload.callback?.();
    setLocalStorage("formId", res.data._id);
  } catch (err) {
    console.log(err);
  }
}

function* getHDMClientDetailsByFormId() {
  try {
    let formId = getLocalStorage("formId") || getLocalStorage("amSelectedFormId");
    const res = yield api.get("/client-forms/" + formId);
    yield put({
      type: "UPDATE_WHOLE_HDM_CLIENT_FORMID",
      payload: res.data,
    });
    yield put({
      type: "UPDATE_WHOLE_CLIENT_FORMID",
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
}

function* getLocations(data) {
  try {
    const res = yield api.get(
      `/facebook-ads/locations?location_q=${data.payload.value}`
    );
    yield put({
      type: "UPDATE_LOCATION_OPTIONS",
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
}

function* getInterestKeyword(data) {
  try {
    const res = yield api.get(
      `/facebook-ads/interests-keywords?interest_keyword=${data.payload.value}`
    );
    yield put({
      type: "UPDATE_INTEREST_OPTIONS",
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
}

export const getHDMAMClientDetailsByFormId = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/client-forms/" + id);
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getHDMForms = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/client-forms");
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const suggestChangesApi = (value) => {
  const isFormIdExist = {};
  if (getLocalStorage("formId")) {
    isFormIdExist["_id"] = getLocalStorage("formId");
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        "/client-forms",
        {
          proposalStage: "PROPOSED_CHANGES",
          proposalSuggestedChanges: value,
          ...isFormIdExist,
        },
        false
      );
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export const onboardingFormSubmApi = (value) => {
  const isFormIdExist = {};
  if (getLocalStorage("formId")) {
    isFormIdExist["_id"] = getLocalStorage("formId");
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        "/client-forms",
        { onboardingFormCompleted: true, ...isFormIdExist },
        false
      );
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export const proposalApprovedApi = () => {
  const isFormIdExist = {};
  if (getLocalStorage("formId")) {
    isFormIdExist["_id"] = getLocalStorage("formId");
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        "/client-forms",
        { proposalStage: "APPROVED", ...isFormIdExist },
        false
      );
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export const approveStrategyDeckApi = (array) => {
  const isFormIdExist={};
  if(getLocalStorage("formId")){
      isFormIdExist["_id"]=getLocalStorage("formId");
  }
  return new Promise(async (resolve,reject)=>{
      try{
          const newArray=[...(array || [])];
          newArray.push({state:"APPROVED",suggestedChanges:[]});
          const res=await api.post("/client-forms",{strategyIterations:newArray,...isFormIdExist},false);
          resolve(res);
      }catch(err){
          reject(err);
      }
  });
}

export const suggestChangesApiStrategyDeck = (value, array) => {
  const isFormIdExist = {};
  if (getLocalStorage("formId")) {
    isFormIdExist["_id"] = getLocalStorage("formId");
  }
  return new Promise(async (resolve, reject) => {
    try {
      const newArray = array;
      newArray.push({ state: "CHANGES_SUGGESTED", suggestedChanges: [value] });
      const res = await api.post(
        "/client-forms",
        { strategyIterations: newArray, ...isFormIdExist },
        false
      );
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export const onboardingFormSuccess = (hdmClient) => {
  hdmClient["onboardingInfo"]["status"] = "COMPLETED";
  const isFormIdExist = {};
  if (getLocalStorage("formId")) {
    isFormIdExist["_id"] = getLocalStorage("formId");
  }
  return new Promise(async (resolve,reject) => {
    try{
      const res = await api.post("/client-forms", hdmClient, false);
      resolve("SUCCESS");
    }catch(err){
      reject("FAILED");
    }
  });
}

export const updatedStatusHdm = (status,key,data) => {
  if(data && data[key]){
    const newObj=JSON.parse(JSON.stringify(data));
    newObj[key]["status"]=status;
    return new Promise(async (resolve,reject) => {
      try{
        const res = await api.post("/client-forms",newObj,false);
        resolve(res.data);
      }catch(err){
        reject(err);
      }
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(SAGA_UPDATE_HDM_CLIENT_DETAILS, updateHDMClientDetails),
    takeLatest(GET_HDM_CLIENT_DETAILS_BY_FORM_ID, getHDMClientDetailsByFormId),
    takeLatest("GET_LOCATIONS", getLocations),
    takeLatest("GET_INTEREST_KEYWORS", getInterestKeyword),
    takeLatest(APPROVE_STRATEGY_DECK_WITH_DETAILS, approveStrategyDeckWithDetails),
  ]);
}
