import { put,all, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from '../../index';
import { getSharedAssetsReducer, updateGoogleAdsAccessData } from './slice';


function* getSharedAssets(action) {
  const formIds = action.payload.formIds;
  const googleAdsAccess = {};
  if(formIds){
    yield all(formIds.map(function* (formId) {
      try{
        const sharedAssetsResponse=yield api.get("/shared-assets/"+ formId);
        googleAdsAccess[formId] = !!(sharedAssetsResponse.data.gadsAssets.filter(asset => ["READ_ACCESS", "COMPLETE_ACCESS"].includes(asset.status)).length);
      }catch(error) {
        console.log("error while fetching google-ads-access for formId:" + formId + "- " + error.message)
      }
  
    }));
  }else {
    const sharedAssetsResponse=yield api.get("/shared-assets");
    sharedAssetsResponse.data.gadsAssets.forEach((asset) => {
        googleAdsAccess[asset.formId] = !!(["READ_ACCESS", "COMPLETE_ACCESS"].includes(asset.status));
    })
  }

  yield put(updateGoogleAdsAccessData(googleAdsAccess))  
}



export default function* sharedAssetsSaga() {
  yield takeEvery(getSharedAssetsReducer.type, getSharedAssets);
  
}