import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getInsightsForFormIdReducer } from "../../../api/accountManager/googleAdsCustomer/slice";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";
import CommonReviewPoints from "./CommonReviewPoints";

export default function LDReviewPoints() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  console.log("in review points ld", formId);

  const form = useSelector((store) => store.form.form);

  useEffect(() => {
    dispatch(getInsightsForFormIdReducer());
  }, []);

  const onClick = () => {
    if (
      (form?.onboardingInfo?.platformInfo?.platforms || []).find(
        (pf) => pf.platform === "facebook-ads"
      )
    ) {
      if ([BACKEND_STATUS.COMPLETED, BACKEND_STATUS.ONGOING].includes(form?.auditInfo?.status)) {
        navigate(
          generatePath(
            `${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.account}`,
            { formId }
          )
        );
      } else {
        navigate(
          generatePath(ldAmRoutePaths.auditForFacebook.fullbase, { formId })
        );
      }
    } else {
      navigate(generatePath(ldAmRoutePaths.proposol.fullbase, { formId }));
    }
  };

  const onChangeAccountClick = () => {
    navigate(
      generatePath(
        `${ldAmRoutePaths.auditForGoogle.fullbase}/${ldAmRoutePaths.auditForGoogle.sub.again}`,
        { formId, again: true }
      )
    );
  };

  return (
    <CommonReviewPoints
      form={form}
      onClick={onClick}
      onChangeAccountClick={onChangeAccountClick}
      displayAdditionalInfo={true}
    />
  );
}
