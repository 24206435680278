import React from 'react';
import { Button, Typography } from '@mui/material';
import { generatingDataFailedInCampaign as failedIcon } from '../../../assets/icons/icon';

const FailedGeneratingDataView = ({
  onTryAgain
}) => {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: 478,
        }}
      >
        <div
          className='processtime'
          style={{
            marginTop: 32,
          }}
        >
          <img
            src={failedIcon}
            // style={{ width: '60px', height: '60px' }}
            alt=''
          />
        </div>
        <div
          style={{
            marginTop: '40px',
            marginBottom: '72px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontFamily: 'Lato',
              fontSize: '23px',
              lineHeight: '27px',
              fontWeight: '700',
              textAlign: 'center',
              marginBottom: '9px',
            }}
          >
            Generating Data Failed
          </Typography>
          <Typography
            style={{
              fontFamily: 'Lato',
              fontSize: '15px',
              lineHeight: '26px',
              fontWeight: '400',
              color: '#6F6C90',
              textAlign: 'center',
              width: '86%',
            }}
          >
            Oops! The data generation failed. Please click on the button below
            to try generating the data again or contact the Pixis support team
            if the issue persists
          </Typography>
          <Button
            style={{
              width: 107,
              height: 32,
              backgroundColor: '#0869FB',
              color: '#FFFFFF',
              textTransform: 'capitalize',
              marginTop: 12,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 14,
              lineHeight: '16px',
              borderRadius: 6,
            }}
            onClick={onTryAgain}
          >
            Try Again
          </Button>
        </div>
      </div>
      {/* <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 10,
          lineHeight: '20px',
          color: '#9798A1',
          position: 'absolute',
          bottom: 23,
          left: 17,
        }}
      >
        Explore other ad groups and campaigns without impacting this data
        generation
      </Typography> */}
    </div>
  );
};

export default FailedGeneratingDataView;
