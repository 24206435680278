import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Typography } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientDetailsByFormId,
  updateClientDetails,
} from "../../../api/client/action";
import { intakeFormSubmittedApi } from "../../../api/client/saga";
import {
  goBackClientCompo,
  goBackReviewQuestionier,
  goToSpecificCompoClient,
} from "../../../api/componentTracking/action";
import {
  DB_KEY_AUDIENCE,
  DB_KEY_BRANDING,
  DB_KEY_PRODUCT_INFO,
  DB_KEY_PRODUCT_PREFERENCES,
  DB_KEY_SOCIAL_PROOF,
} from "../../../utils/dbKeyMappingIntakeForm";
import {
  PRODUCT_INFORMATION_LD,
  PREFERENCES_LD,
  AUDIENCE_LD,
} from "../../../utils/questionsOrderToPreview";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import IntakeAuditFormSubmitted from "../../modals/IntakeAuditFormSubmitted";
import IntakeFormSure from "../../modals/IntakeFormSure";
import { clientDashboardIds } from "../../../utils/clientDashboardIdsUtil";
import "./AccountPreview.css";
import { hitTargetingKeywordsApi } from "../../../api/HDMAM/audit/saga";
import { fbTargeting } from "../Questions/mappedQuestion";
import { goToSpecificCompoClientAm } from "../../../api/accountManager/componentTracking/action";
import roles, { permissionAccess, permissions } from "../../../utils/roles";
import { generatePath, useNavigate } from "react-router";
import ldClientRoutePaths from "../../../pages/ld-client/constants/ldClientRoutePaths";
import {
  getClientFormQueryKey,
  useGetClientForms,
} from "../../../services/clientForms";
import { useParams } from "react-router-dom";
import { queryClient } from "../../../utils/queryClient";
const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
const EditResponseIntakeForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  const permissionsForAccess = useSelector((state) => state.auth.permissions);
  const { data: clientForm } = useGetClientForms(formId);
  const [productInformationQuestions, setProductInformationQuestions] =
    useState({});
  const [preferenceQuestions, setPreferenceQuestions] = useState({});
  const [audienceQuestions, setAudienceQuestions] = useState({});
  const [showGeneratingStrategyPage, setShowGeneratingStrategyPage] =
    useState(false);

  const productInfo = clientForm?.intakeInfo?.productServiceInfo;
  const productPreferences = clientForm?.intakeInfo?.preferences;
  const audiences = clientForm?.intakeInfo?.audiences;
  const branding = clientForm?.intakeInfo?.brandAssets;
  const socialProof = clientForm?.intakeInfo?.customerTestimonial;
  const completedSharedAssetStatus = useSelector(
    (store) => store.form?.form?.completedSharedAssetStatus
  );
  const reviewIntakeFormStatus = useSelector(
    (store) => store.form?.form?.reviewIntakeForm
  );

  const [fbTargetingQuestion, setFbTargetingQuestion] = useState({});
  const platforms = clientForm?.onboardingInfo?.platformInfo?.platforms;

  const [modal, setModal] = useState(false);
  const [formSubm, setFormSubm] = useState(false);

  useEffect(() => {
    if (productInfo) {
      const informations = {};
      PRODUCT_INFORMATION_LD.forEach((question) => {
        informations[question] = productInfo[question];
      });
      setProductInformationQuestions(informations);
    }
  }, [productInfo]);

  useEffect(() => {
    if (productPreferences) {
      const informations = {};
      PREFERENCES_LD.forEach((question) => {
        informations[question] = productPreferences[question];
      });
      setPreferenceQuestions(informations);
    }
  }, [productPreferences]);

  useEffect(() => {
    if (audiences) {
      const informations = {};
      AUDIENCE_LD.forEach((question) => {
        informations[question] = audiences[question];
      });
      setAudienceQuestions(informations);
    }
  }, [audiences]);

  useEffect(() => {
    let targetingKeyword = clientForm?.intakeInfo?.facebookTargeting;
    const obj = {};
    obj["ageRange"] =
      "(Min=>" +
      targetingKeyword?.minAgeRange +
      ",Max=>" +
      targetingKeyword?.maxAgeRange +
      ")";
    obj["gender"] = targetingKeyword?.gender;
    obj["advertisingLocations"] = targetingKeyword?.advertisingLocations;
    obj["interestKeywords"] = targetingKeyword?.interestKeywords;
    obj["excludes"] = targetingKeyword?.excludes;
    setFbTargetingQuestion(obj);
  }, [clientForm?.intakeInfo?.facebookTargeting]);

  const mappedKey = {
    descriptions: "name",
    location: "city",
    competitors: "name",
    platforms: "platform",
  };
  const desiredData = (key, value) => {
    if (key === "crm" || key === "leadSource") {
      let str = "";
      value.forEach((val) => {
        if (val && val !== "Other") {
          str += val + ",";
        }
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : "";
    }
    if (key === "competitors") {
      let compNameValue = "";
      value.map((key, index) => {
        if (key.name.length !== 0 || key.website.length !== 0) {
          compNameValue += "(" + key.name + " => " + key.website + ")" + ",";
        }
      });
      return compNameValue.slice(0, compNameValue.length - 1);
    }
    const tempArr = value.map((infoType) => infoType[mappedKey[key]]);
    return tempArr.join(",").slice(1);
  };
  const breakFromUnderscore = (str, key) => {
    if (typeof str === "string") {
      const data = str.split("_");
      if (data.length > 1) {
        const temp = data.join(",");
        return temp.slice(0, temp.length - 1);
      } else if (key === "monthlyAdSpend") {
        return `$${data[0]}`;
      } else {
        return data[0];
      }
    } else {
      return str;
    }
  };
  const desiredData2 = (key, value) => {
    if (key === "advertisingLocations") {
      let str = "";
      value.forEach((val) => {
        str = str + val.name + ",";
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : "";
    }
    if (key === "geoTargets") {
      let str = "";
      value.forEach((v) => {
        str = str + `(name=>${v.title}),`;
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : "";
    }
    if (key === "descriptions") {
      let str = "";
      value.forEach(({ name, description }, index) => {
        if (name.length !== 0 || description.length !== 0) {
          str += "(name=>" + name + ",description=>" + description + "),";
        }
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : "";
    }
    if (key === "competitors") {
      let compNameValue = "";
      value.map((key, index) => {
        compNameValue += "(" + key.name + " => " + key.website + ")" + ",";
      });
      return compNameValue.slice(0, compNameValue.length - 1);
    }
    return value.filter((val) => val).join(",");
  };
  const desiredDataBool = (key, value) => {
    if (value) {
      return "Yes";
    } else {
      return "No";
    }
  };
  const intakeFormSubmitted = () => {
    setModal(!modal);
  };
  const submitIntakeFormFinal = () => {
    intakeFormSubmittedApi(clientForm).then((res) => {
      queryClient.invalidateQueries({
        queryKey: getClientFormQueryKey({ formId }),
      });
      dispatch(getClientDetailsByFormId());
      setModal(!modal);
      setFormSubm(!formSubm);
    });
  };
  // useEffect(() => {
  //   dispatch(updateClientDetails());
  // }, []);
  const truncateLink = (urlLink) => {
    return urlLink.substring(0, 40) + "...";
  };
  return (
    <>
      <div
        style={{
          height: "100%",
          overflow: "auto",
        }}
      >
        <div className="top_head">
          <div className="resp">
            <h3 className="preview_heading">PREVIEW</h3>
            <h1 className="preview_header2">VIEW AND EDIT YOUR RESPONSES</h1>
            <p style={{ color: "#6F6C90", textAlign: "center", margin: 0 }}>
              Kindly review your responses carefully before publishing, as you
              will not{" "}
            </p>
            <p style={{ color: "#6F6C90", textAlign: "center", margin: 0 }}>
              be able to edit this section once it is published.
            </p>

            <button
              className="btn back_button"
              onClick={() => navigate(-1)}
            ></button>

            <PrimaryButton
              type="bordered"
              className="edit_response_button"
              title="Edit Responses"
              icon="edit"
              onClick={() => {
                navigate(
                  generatePath(
                    `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}`
                  )
                );
              }}
            ></PrimaryButton>
          </div>
        </div>
        <div className="accordian">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight={600}>Product Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(productInformationQuestions)?.map(
                  ([key, value], index) =>
                    DB_KEY_PRODUCT_INFO[key] !== undefined && (
                      <div className="tag">
                        <div className="content">
                          <span>{DB_KEY_PRODUCT_INFO[key]} : </span>
                          <span
                            style={{ color: "#619BF3", fontWeight: "bolder" }}
                          >
                            {Array.isArray(value)
                              ? desiredData2(key, value)
                              : (value || "").length === 0
                              ? ""
                              : typeof value === "boolean"
                              ? desiredDataBool(key, value)
                              : ["targetCpa"].includes(key)
                              ? `$${value}`
                              : value}
                          </span>{" "}
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Preferences</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(preferenceQuestions)?.map(
                  ([key, value], index) =>
                    DB_KEY_PRODUCT_PREFERENCES[key] !== undefined && (
                      <div className="tag">
                        <div className="content">
                          <span>{DB_KEY_PRODUCT_PREFERENCES[key]} : </span>
                          <span
                            style={{ color: "#619BF3", fontWeight: "bolder" }}
                          >
                            {Array.isArray(value)
                              ? desiredData(key, value)
                              : (value || "").length === 0
                              ? ""
                              : breakFromUnderscore(value, key)}
                          </span>{" "}
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {platforms?.findIndex(
          (platform) => platform.platform === "google-ads"
        ) !== -1 && (
          <div className="accordian">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={600}>Google Targeting</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="wrap">
                  {Object.entries(audienceQuestions)?.map(
                    ([key, value], index) =>
                      DB_KEY_AUDIENCE[key] !== undefined && (
                        <div className="tag">
                          <div className="content">
                            <span>{DB_KEY_AUDIENCE[key]} : </span>
                            <span
                              style={{ color: "#619BF3", fontWeight: "bolder" }}
                            >
                              {key === "ageRanges"
                                ? `${audiences.minAgeRange}-${audiences.maxAgeRange}`
                                : Array.isArray(value)
                                ? desiredData2(key, value)
                                : (value || "").length === 0
                                ? ""
                                : breakFromUnderscore(value)}
                            </span>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}

        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Branding</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(branding ? branding : {})?.map(
                  ([key, value], index) =>
                    DB_KEY_BRANDING[key] !== undefined && (
                      <div className="tag">
                        <div className="content">
                          <span>{DB_KEY_BRANDING[key]} : </span>
                          <span
                            style={{ color: "#619BF3", fontWeight: "bolder" }}
                          >
                            {value.length !== 0 && Array.isArray(value) ? (
                              <>
                                {value
                                  ?.filter((imgId) => imgId !== "")
                                  ?.map((imgId, index) => {
                                    return (
                                      <>
                                        <a
                                          href={`${imageBaseUrl}/${imgId}`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          {truncateLink(
                                            `${imageBaseUrl}/${imgId}`
                                          )}
                                        </a>{" "}
                                        ,
                                      </>
                                    );
                                  })}
                              </>
                            ) : value.length !== 0 ? (
                              ["companyLogo", "companyClientLogo"].includes(
                                key
                              ) ? (
                                <>
                                  <a
                                    href={`${imageBaseUrl}/${value}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {truncateLink(`${imageBaseUrl}/${value}`)}
                                  </a>{" "}
                                  ,
                                </>
                              ) : (
                                value
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Social Proof</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(socialProof ? socialProof : {})?.map(
                  ([key, value], index) =>
                    DB_KEY_SOCIAL_PROOF[key] !== undefined && (
                      <div className="tag">
                        <div className="content">
                          <span>{DB_KEY_SOCIAL_PROOF[key]} : </span>
                          <span
                            style={{ color: "#619BF3", fontWeight: "bolder" }}
                          >
                            <>
                              {value
                                ?.filter((imgId) => imgId !== "")
                                ?.map((imgId, index) => {
                                  return (
                                    <>
                                      <a
                                        href={`${imageBaseUrl}/${imgId}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {truncateLink(
                                          `${imageBaseUrl}/${imgId}`
                                        )}
                                      </a>{" "}
                                      ,
                                    </>
                                  );
                                })}
                            </>
                          </span>
                        </div>
                      </div>
                    )
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        {platforms?.findIndex(
          (platform) => platform.platform === "facebook-ads"
        ) !== -1 && (
          <div className="accordian">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={600}>FB Targeting</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="wrap">
                  {Object.entries(fbTargetingQuestion)?.map(
                    ([key, value], index) =>
                      [key] !== undefined && (
                        <div className="tag">
                          <div className="content">
                            <span>{fbTargeting[key].questName} : </span>
                            <span
                              style={{ color: "#619BF3", fontWeight: "bolder" }}
                            >
                              {Array.isArray(value)
                                ? desiredData2(key, value)
                                : value}
                            </span>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>

      <div className="preview_footer_row">
        <div className="footer_con">
          <button
            className="back"
            // onClick={() => dispatch(goBackClientCompo())}
            onClick={() => {
              if (
                permissionsForAccess.includes(
                  permissions.REVIEW_INTAKE_FORM.concat(
                    permissionAccess.VIEW
                  ) ||
                    permissions.REVIEW_INTAKE_FORM.concat(
                      permissionAccess.EXECUTE
                    )
                )
              ) {
              } else {
                navigate(-1);
              }
            }}
          >
            BACK
          </button>
          <PrimaryButton
            title={
              permissionsForAccess.includes(
                permissions.INTAKE_FORM.concat(permissionAccess.VIEW) ||
                  permissions.INTAKE_FORM.concat(permissionAccess.EXECUTE)
              )
                ? "Submit"
                : "PROCEED"
            }
            onClick={() =>
              permissionsForAccess.includes(
                permissions.INTAKE_FORM.concat(permissionAccess.VIEW) ||
                  permissions.INTAKE_FORM.concat(permissionAccess.EXECUTE)
              )
                ? intakeFormSubmitted()
                : dispatch(goToSpecificCompoClientAm(26))
            }
          ></PrimaryButton>
        </div>
      </div>
      {permissionsForAccess.includes(
        permissions.INTAKE_FORM.concat(permissionAccess.VIEW) ||
          permissions.INTAKE_FORM.concat(permissionAccess.EXECUTE)
      ) && (
        <IntakeFormSure
          modal={modal}
          setModal={setModal}
          submitIntakeFormFinal={submitIntakeFormFinal}
        />
      )}
      <IntakeAuditFormSubmitted
        formSubm={formSubm}
        setFormSubm={setFormSubm}
        onClickHandler={() => {
          navigate(generatePath(`${ldClientRoutePaths.homePage.fullbase}`));
        }}
      />
    </>
  );
};
export default EditResponseIntakeForm;
