import { api } from "../index";
import axios from "axios";

export const isUserAuthenticated = () => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios.post("/api/authenticate-token", {
        accessToken: localStorage.getItem("accessToken"),
      });
      resolve("authenticated");
    } catch (err) {
      reject("un-authenticated");
    }
  });
};

export const loginApi = async (email, password) => {
  const formData = new FormData();
  formData.append("username", email);
  formData.append("password", password);
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post("/token", formData, false);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const userDetailsApi = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get("/usersdetails", false);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getPermissionsAPI = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.get("/user-permissions", false);
      resolve(response.data.permissions);
    } catch (err) {
      reject(err);
    }
  });
};

export const generatePasswordApi = async (obj) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await api.post("/users", obj, false);
      resolve(response.data);
    } catch (err) {
      reject(err);
    }
  });
};

export const getClientIdForPlatforms = async (platform) => {
  try {
    const clientIdDetails = await api.get(`/ad-accounts/${platform}/clientId`);
    return clientIdDetails.data.clientId;
  } catch (err) {
    return err;
  }
};
