import { Button, ButtonBase, FormControl, FormControlLabel, RadioGroup, TextField, Typography } from "@mui/material";
import BpRadio from "../ClientDashboard/CustomRadio/Radio";
import './googleSegment.scss';
import crossIcon from './../PMaxStrategyCreation/pmaxIcons/cross-icon.svg'
import { useEffect, useRef, useState } from "react";
import PrimaryButton from "../ButtonPrimary/Buttons";
import { useGetGoogleSegment, usePutGoogleSegment } from "../../services/googleSegments";
import { getLocalStorage } from "../../api/localStorage/localStorage";
import PmaxLoader from "../PMaxStrategyCreation/PmaxLoader";
import { BACKEND_STATUS } from "../../utils/accountManagerUtil";
import AllConfirmModal from "../PMaxStrategyCreation/AllConfirmModal";
import { useNavigate } from "react-router-dom";

const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;

  const textStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '19.29px',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    color: "#333333"
  };

export default function GoogleStrategySegment ({
    segmentId,
    handleBack,
    formId
}) {
    const [keywords, setKeywords] = useState([]);
    const [edit, setEdit] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [targetingType, setTargetingType] = useState("People who searched for any of these terms on Google");
     const { data: getSegment, isFetching: isFetchingSegment } = useGetGoogleSegment(formId, segmentId, {
         onSuccess: (data) => {
             const { keywords, targetingType } = data;
             setKeywords(keywords || []);
             if (targetingType)
               setTargetingType(targetingType);
         }
     });

    useEffect(() => {
        if (edit) {
            inputRef.current.focus();
            inputRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [edit]);

    const { mutate: mutatePutSegment } = usePutGoogleSegment(formId, {
        onSuccess: () => {
            setShowModal(true);
        }
    });

    const handleDeleteKeyword = index => {
        setKeywords(prev => {
            const updatedKeywords = [...prev];
            updatedKeywords.splice(index, 1);
            return updatedKeywords;
        });
        setEdit(false);
    }

    const handleAddKeyword = () => {
        setKeywords(prev => [...prev, '']);
        setEdit(true);
    }

    const changeKeyword = (value, index) => {
        setKeywords(prev => {
            const updatedKeyword = [...prev];
            updatedKeyword[index] = value;
            return updatedKeyword;
        });
    }

    const handleConfirm = () => {
        setEdit(false);
        mutatePutSegment({
            segmentId,
            keywords,
            targetingType,
            status: BACKEND_STATUS.ONGOING
        });
    }

    console.log('Keywords:', keywords);

    return (
        <div>
        <div style={{
            display: "flex",
        }}>
         <div style={{
             flexBasis: "55%",
             paddingTop: 40,
             paddingLeft: 40,
             paddingRight: 55
          }}>
          <FormControl>
           <RadioGroup>
             <FormControlLabel
               value='interests or purchase intentions'
               control={
                <BpRadio
                   width={14}
                   height={14}
                   checked={
                     targetingType ===
                       'People with any of these interests or purchase intentions' &&
                     true
                   }
                />
               }
               label={<Typography style={textStyle}>People with any of these interests or purchase intentions</Typography>}
               onClick={() => {
                 setTargetingType(
                   'People with any of these interests or purchase intentions'
                 );
               }}
             />
             <FormControlLabel
               value='searched for any of these terms on Google'
               control={
                <BpRadio
                   width={14}
                   height={14}
                   checked={
                     targetingType ===
                       'People who searched for any of these terms on Google' &&
                     true
                   }
                />
               }
               label={<Typography style={textStyle}>People who searched for any of these terms on Google</Typography>}
               onClick={() => {
                setTargetingType(
                  'People who searched for any of these terms on Google'
                );
               }}
             />
           </RadioGroup>
          </FormControl>
          <div style={{
              border: "1px solid #F0F0F0",
              backgroundColor: "#F8F9FD",
              borderRadius: "8px",
              marginTop: "24px",
              minHeight: "423px",
              maxHeight: "423px"
          }}>
            <div style={{
                height: 417,
                overflowY: 'scroll'
            }}>
             { !edit && keywords.map((keyword, index) => (
              <div 
                 key={index}
                 style={{
                  height: 31,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  margin: "16px 40px 16px 16px",
                  padding: "8px 15px 8px 15px",
                  fontSize: 12,
                  lineHeight: "18px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontSize: 12,
                  lineHeight: "18px",
                  position: 'relative'
              }}>
                 {keyword}
               <img src={crossIcon} style={{ cursor: 'pointer', height: "10px", width: "10px", position: 'absolute', right: '15px' }} onClick={() => handleDeleteKeyword(index)}/>
              </div>
             ))}
             { edit && keywords.map((keyword, index) => (
              <div 
                 key={index}
                 style={{
                  height: 31,
                  backgroundColor: "#FFFFFF",
                  borderRadius: 8,
                  margin: "16px 40px 16px 16px",
                  padding: "8px 15px 8px 15px",
                  fontSize: 12,
                  lineHeight: "18px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontSize: 12,
                  lineHeight: "18px",
                  position: 'relative'
              }}
                   
                   onBlur={() => { 
                       setEdit(false);
                       if (keyword === '')
                           handleDeleteKeyword(index);
                   }}
                   onKeyDown={(e) => {
                       if (e.key === 'Enter') {
                           setEdit(false);
                           if (keyword === '')
                               handleDeleteKeyword(index);
                       }
                   }}
                 >
                { index !== (keywords.length - 1) ? 
                    (<>
                      {keyword}
                  <img src={crossIcon} style={{ cursor: 'pointer', height: "10px", width: "10px", position: 'absolute', right: '15px' }} onClick={() => handleDeleteKeyword(index)}/>
                   </>) : (
                       <>
                        <TextField 
                          variant='standard'
                          value={keyword}
                          inputRef={inputRef}
                          inputProps={{
                            style: {
                             padding: 0,
                             // padding: "8px 15px 8px 15px",
                             fontFamily: 'Inter',
                             fontStyle: 'normal',
                             fontSize: 12,
                             lineHeight: "0px",
                             border: 0,
                             width: '300px',
                           }
                          }}
                          onChange={(e) => changeKeyword(e.target.value, index)}
                         />
                        <img src={crossIcon} style={{ cursor: 'pointer', height: "10px", width: "10px", position: 'absolute', right: '15px' }} onMouseDown={() => handleDeleteKeyword(index) }/>
                       </>
                )
                }
              </div>
             ))}
            </div>
            <div class="add-search-term-container">
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#0869FB',
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                }}
                onClick={() => {
                  handleAddKeyword();
                }}
              >
                + Add Google Search Terms
              </Button>
            </div>
          </div>
         </div>
         <div style={{
             flexBasis: "45%",
             display: "flex",
             flexDirection: "column",
             alignItems: "center",
             justifyContent: "space-evenly",
             overflow: "hidden",
             height: "100vh",
             backgroundColor: "#F5F9FF"
         }}>
          <img 
            style={{
                width: "361px",
                height: "297px",
            }}
            src={`${imageBaseUrl}/assets/71a01f4b-27c3-4b5b-abaf-2597911fc119`}
          />
          <div>
            <h3 style={{
                fontSize: "24px",
                lineHeight: "32px"
            }}>Segments</h3>
            <div style={{
                width: "394px",
                fontSize: "14px",
                lineHeight: "22px",
                color: "#5C6674"
            }}>
            Google Ads custom segments let you leverage Google’s proprietary data about its users in ways that are unique to your business. You can create custom segments using the interests and products/services people are searching for.
            </div>
          </div>
         </div>
        { isFetchingSegment && <PmaxLoader /> }
        { showModal && <AllConfirmModal description="Segment Updated" handleClose={() => setShowModal(false) } handleNavigation={() => handleBack()} /> }
        </div>
        <div className="preview_footer_row">
        { /* <div className="footer_down"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
             <button className='back' onClick={() => handleBack()}>
               BACK
             </button>
          */ }
            <div
              className='footer_con'
              style={{ justifyContent: 'end', gap: 16, position: 'relative' }}
            >
            <Button
                onClick={() => handleConfirm() }
                disabled={ keywords.length && keywords[0] !== '' ? false : true }
                style={{
                  width: '122px',
                  height: '40px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                  background: '#0869FB',
                  border: '1.5px solid #0869FB',
                  color: '#FFF',
                  opacity: keywords.length && keywords[0] !== '' ? 1: 0.4,
                }}
            >
                Confirm
            </Button>
            </div>
        { /* </div> */ }
        </div>
        </div>
    )
}
