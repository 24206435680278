import { getLocalStorage } from "../api/localStorage/localStorage"
import { isPlatformSelectedBP } from "./BPUtils/common";
import { isPlatformSelectedLD } from "./LDUtils/commonUtils";

// returns a boolean
export const isPlatformSelected = (clientForm, platform) => {
    const agencyType = getLocalStorage("agencyType");
    switch (agencyType) {
        case "ld":
            return isPlatformSelectedLD(clientForm, platform);
        case "hdm":
            return isPlatformSelectedHDM(clientForm, platform);
        case "bp":
            return isPlatformSelectedBP(clientForm, platform);
    }
}



// returns a boolean
 const isPlatformSelectedHDM = (form, platformName) => {
  const result =  form?.onboardingInfo?.clientAccess?.platforms?.findIndex(
    (platform) => platform.platform === platformName
  );

  if (result === -1) return false;
  return true
};
