import { ChannelType } from "../../components/Admin/channel.js";
import {
  all,
  put,
  takeLatest,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import { api } from "../../api/index";
import {
  CONNECT_ACCOUNT,
  AUTHENTICATED_CHANNEL,
  SEND_AUTH_CODE,
  removeAuthAccountMappedApi,
  authChecMappedApi,
  GET_ACCOUNTS_CHANNEL,
  channelAccountsList,
  REVOKE_CHANNEL,
} from "./enum";
import { getClientIdForPlatforms } from "../auth/saga.js";
import { PLATFORM, PLATFORM_ADS } from "../../utils/commonEnums.js";

function* connectAccountGoogle(args) {
  const clientId = yield getClientIdForPlatforms(PLATFORM_ADS.GOOGLE_ADS);
  yield (window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${process.env.REACT_APP_BASE_URL}/select-accounts&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`);
}

function* connectAccountFacebook(args) {
  try {
    yield window.fbLogin((response) => {
      const { accessToken } = response.authResponse;
      (async () => {
        const dbResponse = await api.post(
          "/facebook-auth/tokens",
          { accessToken: accessToken },
          false
        );
        window.location.href =
          "/select-accounts" + `?channel=${ChannelType.FacebookAds}&success`;
      })();
    });
  } catch (err) {
    console.log(err);
  }
}

function* sendAuthCode(code) {
  try {
    const res = yield api.post(
      "/google-auth/tokens",
      {
        authCode: code.authCode,
        redirectUri: process.env.REACT_APP_BASE_URL + "/select-accounts",
      },
      false
    );
    yield put({
      type: AUTHENTICATED_CHANNEL,
      payload: {
        type: ChannelType.GoogleAds,
      },
    });
    localStorage.setItem(ChannelType.GoogleAds, true);
    window.location.href =
      "/select-accounts" + `?channel=${ChannelType.GoogleAds}&success`;
  } catch (error) {
    localStorage.setItem(ChannelType.GoogleAds, false);
    window.location.href =
      "/select-accounts" + `?channel=${ChannelType.GoogleAds}&failure`;
  }
}

export const googleAdsCustomers = async () => {
  try {
    const res = await api.get("/g-ads/customers");
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const removeAccountApi = (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.delete(removeAuthAccountMappedApi[type]);
      resolve("success");
    } catch (err) {
      reject("failed");
    }
  });
};

export const authenticatedAccountApi = (type) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(authChecMappedApi[type]);
      return true;
    } catch (err) {
      reject("error");
    }
  });
};

export const facebookAdsCustomers = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get("/facebook-ads/ad-accounts");
      resolve(res.data);
    } catch (err) {
      reject(err);
    }
  });
};

function* getAccountsChannel(payload) {
  const type = payload.payload;
  try {
    const res = yield api.get(channelAccountsList[type]);
    if (type === ChannelType.FacebookAds) {
      const arr = [];
      res?.data?.accounts?.forEach((account) => {
        arr.push({ customerId: account.accountId, customerName: account.name });
      });
      yield put({
        type: "SET_CHANNEL_ACCOUNTS_LIST",
        payload: { data: arr, channelName: type },
      });
    } else {
      yield put({
        type: "SET_CHANNEL_ACCOUNTS_LIST",
        payload: { data: res.data.customers, channelName: type },
      });
    }
  } catch (err) {
    yield put({
      type: "CHANNEL_AUTH_FAILED",
      payload: { channelName: type },
    });
    return { data: {} };
  }
}

function* revokeAccount(payload) {
  const type = payload.payload;
  try {
    const res = yield api.delete(removeAuthAccountMappedApi[type]);
    yield put({
      type: "CHANNEL_AUTH_FAILED",
      payload: { channelName: type },
    });
  } catch (err) {
    console.log("err revoking", err);
    return { data: {} };
  }
}

export default function* root() {
  yield takeLatest(
    CONNECT_ACCOUNT + "_" + ChannelType.GoogleAds,
    connectAccountGoogle
  );
  yield takeLatest(SEND_AUTH_CODE + "_" + ChannelType.GoogleAds, sendAuthCode);
  yield takeLatest(
    CONNECT_ACCOUNT + "_" + ChannelType.FacebookAds,
    connectAccountFacebook
  );
  yield takeEvery(GET_ACCOUNTS_CHANNEL, getAccountsChannel);
  yield takeLeading(REVOKE_CHANNEL, revokeAccount);
}
