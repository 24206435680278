import { useDispatch } from "react-redux";
import BpRadio from "../ClientDashboard/CustomRadio/Radio";
import "./Option.css";
import {
  updateSelectedCustomerId,
  updateSelectedLoginCustomerId,
} from "../../api/accountManager/googleAdsCustomer/slice";

const Option = (props) => {
  const dispatch = useDispatch();

  const handleChange = () => {
    dispatch(updateSelectedCustomerId(props.accountId));
    dispatch(updateSelectedLoginCustomerId(props.loginAccountId));
  };
  return (
    <div className="radio">
      <div className="st">
        <div>
          <BpRadio checked={props.checked} onChange={handleChange} />
        </div>
        <div className="mp">
          {/*<span>{props.customerName}</span>*/}
          {/*<p>Account Id: {props.accountId} ({props.loginCustomerName})</p>*/}
          <span>{props.customerName}</span>
          <p>Account Id: {props.accountId}</p>
        </div>
      </div>
    </div>
  );
};
export default Option;
