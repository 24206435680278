import {api} from "../index";
import { all, put, takeLatest,select } from "redux-saga/effects";
import {setLocalStorage,getLocalStorage} from "../../api/localStorage/localStorage";

export const sharedAssetsPostApi = (customerId,status) => {
    const dataObj={formId:localStorage.getItem("formId"),customerId:customerId,status:status};
    return new Promise(async (resolve,reject)=>{
       try{
          const res=await api.post("/shared-assets",dataObj);
          resolve(res.data);
       }catch(err){
          reject(err);
       }
    });
}

function* sharedAssetsApiGet(){
    try{
        const res=yield api.get("/shared-assets/"+getLocalStorage("formId"));
        yield put({
            type:"GET_SHARED_ASSETS_ACCOUNTS",
            payload:res.data.gadsAssets
        })
    }catch(err){
        console.log(err);
    }
}

export const sharedAssetsPutApi = (assetId,assetStatus) => {
    return new Promise(async (resolve,reject)=>{
        try{
           const res=await api.put("/shared-assets/"+assetId+"?assetStatus="+assetStatus);
           resolve(res.data);
        }catch(err){
           reject(err);
        }
    });
}


export const sharedAssetsGetApi = () => {
    return new Promise(async (resolve,reject)=>{
        try{
            const res=await api.get("/shared-assets/"+getLocalStorage("formId"));
            resolve(res.data);
        }catch(err){
            reject(err);
        }
    });
}

export const sharedAssetsDeleteApi = (assetId) => {
    return new Promise(async (resolve,reject)=>{
        try{
           const res=await api.delete("/shared-assets/"+assetId);
           resolve(res.data);
        }catch(err){
           reject(err);
        }
    });
}

export default function* root() {
    yield all([
        takeLatest("GET_SHARED_ASSETS_API",sharedAssetsApiGet)
    ])
}