import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_CAMPAIGN_SELECTOR_STAGES, PMaxCampaignName } from "../../constants/LDConstants";
import GoogleCampaignSelector from "../GoogleCampaignSelector/GoogleCampaignSelector";
import {
  BACKEND_STATUS,
  CRON_STATUS_BACKEND,
  accountManagerIds,
  ldComponentWithIndexAm,
  componentWithIndexHDMAm,
  accountManagerIdsHDM,
  getGooglePmaxCampaignObject,
} from "../../utils/accountManagerUtil";
import { goToSpecificCompoClientAm } from "../../api/accountManager/componentTracking/action";
import { getPmaxStrategies, startCronPMaxStrategy } from "../../api/pmaxStrategy/slice";
import { goToSpecificCompoHDMAM } from "../../api/HDMAM/componentTracking/action";
import { getAdPixels } from "../../api/HDMAM/campaign/action";

const isAllSearchCampaignsPublished = (clientForm) => {
  const generatedCampaignsObj =
    clientForm.googleSearchAdsGeneratedCampaignsInfo
      ?.filter((el) => el.campaignId)
      .reduce((acc, el) => {
        return {
          ...acc,
          [el.campaignType]: {
            id: el.campaignId,
            name: el.requestPayload?.campaign?.name,
          },
        };
      }, {}) || {};
  return (
    ["BRAND", "COMPETITOR", "SERVICE"]
      .map((campaignType) => generatedCampaignsObj[campaignType])
      .filter(Boolean).length >= 3
  );
};

const isAllPmaxPublished = (
  form,
  pmaxStrategy1 = [],
  pmaxStrategy2 = [],
  pmaxStrategy3 = [],
  pmaxCampaign1 = {},
  pmaxCampaign2 = {},
  pmaxCampaign3 = {},
) => {
  if (form?.pmaxAdsGeneratedCampaignsInfo) {
    let isPublished = true;
    const [campaignIds, isGenerating] = getGooglePmaxCampaignObject(
      form?.pmaxAdsGeneratedCampaignsInfo,
      {
        [PMaxCampaignName.campaign1]: pmaxCampaign1?.id,
        [PMaxCampaignName.campaign2]: pmaxCampaign2?.id,
        [PMaxCampaignName.campaign3]: pmaxCampaign3?.id,
      }
    );
    if (pmaxStrategy1.length && !campaignIds[PMaxCampaignName.campaign1]) {
      isPublished = false;
    }
    if (pmaxStrategy2.length && !campaignIds[PMaxCampaignName.campaign2]) {
      isPublished = false;
    }
    if (pmaxStrategy3.length && !campaignIds[PMaxCampaignName.campaign3]) {
      isPublished = false;
    }
    return isPublished;
  } else {
    return false;
  }
};
const CampaignGoogleSelector = ({
    agencyType="LD"
}) => {
  const dispatch = useDispatch();

  const [clientForm, setClientForm] = useState();
  const [pmaxGeneratingButton, setPmaxGeneratingButton] = useState(false);
  const [pmaxOngoingButton, setPmaxOngoingButton] = useState(false);
  const [pmaxCompletedButton, setPmaxCompletedButton] = useState(false);

  const [searchGeneratingButton, setSearchGeneratingButton] = useState(false);
  const [searchOngoingButton, setSearchOngoingButton] = useState(false);
  const [searchCompletedButton, setSearchCompletedButton] = useState(false);

  const [
    campaign1Strategies,
    campaign2Strategies,
    campaign3Strategies,
    campaign1PMaxCampaign,
    campaign2PMaxCampaign,
    campaign3PMaxCampaign,
    form,
  ] = useSelector((store) => [
    store.pmaxStrategy.campaign1,
    store.pmaxStrategy.campaign2,
    store.pmaxStrategy.campaign3,
    store.pmaxCampaign.campaign1,
    store.pmaxCampaign.campaign2,
    store.pmaxCampaign.campaign3,
    store.hdmFormAm,
  ]);

  useEffect(() => {
    dispatch(getPmaxStrategies());
    dispatch(getAdPixels());
  }, [])

  useEffect(() => {
    if (form) {
      setClientForm(form);
      if (
        isAllPmaxPublished(
          form,
          campaign1Strategies,
          campaign2Strategies,
          campaign3Strategies,
          campaign1PMaxCampaign,
          campaign2PMaxCampaign,
          campaign3PMaxCampaign,
        )
      ) {
        // dispatch(getPmaxStrategies());
        setPmaxCompletedButton(true);
      } else if (
        [BACKEND_STATUS.COMPLETED].includes(
          form?.googlePmaxStrategy?.status
        )
        ) {
        setPmaxOngoingButton(true);
      }

      if (isAllSearchCampaignsPublished(form)) {
        setSearchCompletedButton(true);
      } else if(form?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED){
        setSearchOngoingButton(true);
      }
    }
  }, [form,campaign1Strategies,
    campaign2Strategies,
    campaign3Strategies,
    campaign1PMaxCampaign,
    campaign2PMaxCampaign,
    campaign3PMaxCampaign]);

  const onClickHandlerForSearchStrategy = () => {
    if (clientForm?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED) {
      switch (agencyType) {
          case 'LD':
            dispatch(
              goToSpecificCompoClientAm(
                ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_CREATION_PAGE]
              )
            );
            break;
          case 'HDM':
            dispatch(
              goToSpecificCompoHDMAM(
                componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_PAGE]
              )
            );
            break;
          default:
            console.log('no agencyType matched...');
      }
    }
  };
  const onClickHandlerForPMaxStrategySelect = () => {
    if (
      [BACKEND_STATUS.COMPLETED].includes(
        clientForm?.googlePmaxStrategy?.status
      )
    ) {
      switch (agencyType) {
          case 'LD':
            dispatch(
              goToSpecificCompoClientAm(
                ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_CREATION_PAGE_PMAX]
              )
            );
              break;
            case 'HDM':
            dispatch(
              goToSpecificCompoHDMAM(
                componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_PAGE_PMAX]
              )
            );
              break;
            default:
            console.log('no agencyType matched...');
      }
    }
  };

  return (
    <GoogleCampaignSelector
      stage={GOOGLE_CAMPAIGN_SELECTOR_STAGES.Campaign}
      pmaxOngoingButton={pmaxOngoingButton}
      pmaxGeneratingButton={pmaxGeneratingButton}
      pmaxCompletedButton={pmaxCompletedButton}
      pmaxClickHandler={onClickHandlerForPMaxStrategySelect}
      searchOngoingButton={searchOngoingButton}
      searchGeneratingButton={searchGeneratingButton}
      searchCompletedButton={searchCompletedButton}
      searchClickHandler={onClickHandlerForSearchStrategy}
    />
  );
};

export default CampaignGoogleSelector;
