import { leftPanelNameInUrlPath } from "../../../utils/HDMUtils/accountManager/utils";


const BASE = "/hdm-am/:formId";

const hdmAmRoutePaths = /** @type {const} */ ({
  root: BASE,
  onboardingForm: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ONBOARDING_FORM}`,
    root: `${leftPanelNameInUrlPath.ONBOARDING_FORM}`, // should matches the end of fullbase
    sub: {
      review: "",
      clientAndAccess: "client-and-access",
      clientAndAccessWithQuestionNumber: "client-and-access/:questionNumber",
      brandOrMessaging: "brand-or-messaging",
      brandOrMessagingWithQuestionNumber: "brand-or-messaging/:questionNumber",
      facebookTargeting: "facebook-targeting",
      facebookTargetingWithQuestionNumber: "facebook-targeting/:questionNumber",
      googleTargeting: "google-targeting",
      googleTargetingWithQuestionNumber: "google-targeting/:questionNumber",
      aboutYourBusiness: "about-your-business",
      aboutYourBusinessWithQuestionNumber: "about-your-business/:questionNumber",
      creatives: "creatives",
      creativesWithQuestionNumber: "creatives/:questionNumber"
    },
  },
  accessSharing: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ACCESS_SHARING}`,
    root: `${leftPanelNameInUrlPath.ACCESS_SHARING}`, // should matches the end of fullbase
    sub: {},
  },
  onboardingDoc: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ONBOARDING_DOC}`,
    root: `${leftPanelNameInUrlPath.ONBOARDING_DOC}`, // should matches the end of fullbase
    sub: {
      onboarding: "",
      reviewBriefingDoc: "review-briefing-doc",
      expectations: "expectations",
    },
  },
  auditForGoogle: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.AUDIT_FOR_GOOGLE}`,
    root: `${leftPanelNameInUrlPath.AUDIT_FOR_GOOGLE}`, // should matches the end of fullbase
    sub: {
      selectAccount: "",
      reviewPoints: "review-points",
      again: ":again"
    },
  },
  auditForFacebook: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.AUDIT_FOR_FACEBOOK}`,
    root: `${leftPanelNameInUrlPath.AUDIT_FOR_FACEBOOK}`, // should matches the end of fullbase
    sub: {
      selectAccount: "",
      account: "account",
      campaign: "campaign",
      adset: "adset",
      ad: "ad",
      again: ":again"
    },
  },
  accountAnalysis: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ACCOUNT_ANALYSIS}`,
    root: `${leftPanelNameInUrlPath.ACCOUNT_ANALYSIS}`, // should matches the end of fullbase
    sub: {
      google: "",
      facebook: "facebook",
      website: "website",
      creatives: "creatives",
      competitor: "competitor",
    },
  },
  strategyGoogle: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.STRATEGY_GOOGLE}`,
    root: `${leftPanelNameInUrlPath.STRATEGY_GOOGLE}`, // should matches the end of fullbase
    sub: {
        generationType: {
            selector: "",
            again: ":again"
        },
        guidedFlow: {
          selector: "guided",
          googleSearch: {
            strategiesList: "guided/google-search/strategies",
            adsetGroupLevel: "guided/google-search/:strategyId"
          },
          googlePmax: {
            strategiesList: "guided/strategies",
            adgroupLevel: "guided/:strategyId",
            segmentLevel: "guided/segment/:segmentId"
          }
        },
        automatedFlow: {
            strategiesList: "automated/strategies",
            adgroupLevel: "automated/search/:strategyId",
            assetgroupLevel: "automated/pmax/:strategyId",
            segmentLevel: "automated/segment/:segmentId"
        }
    },
  },
  strategyFacebook: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.STRATEGY_FACEBOOK}`,
    root: `${leftPanelNameInUrlPath.STRATEGY_FACEBOOK}`, // should matches the end of fullbase
    sub: {
      generationType: "",
      strategiesList: "strategies/:strategyFlow",
      adsetLevel: "strategies/:strategyFlow/:strategyId" 
    },
  },
  strategyDeck: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.STRATEGY_DECK}`,
    root: `${leftPanelNameInUrlPath.STRATEGY_DECK}`, // should matches the end of fullbase
    sub: {
      goal: '',
      analysis: "analysis",
      google: 'google',
      facebook: 'facebook',
      ourPlan: "our-plan",
      bestPractices: "best-practices",
      suggestChanges: "suggest-changes",
    },
  },
  campaignGoogle: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CAMPAIGN_GOOGLE}`,
    root: `${leftPanelNameInUrlPath.CAMPAIGN_GOOGLE}`, // should matches the end of fullbase
    sub: {
      pmax: 'pmax',
      adGroupLevelSearchCampaign: 'search/:campaignId',
      adGroupLevelPmaxCampaign: 'pmax/:campaignId',
      adGroupLevelSegment: 'segment/:segmentId',
    },
  },
  campaignFacebook: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CAMPAIGN_FACEBOOK}`,
    root: `${leftPanelNameInUrlPath.CAMPAIGN_FACEBOOK}`, // should matches the end of fullbase
    sub: {
      base: "",
      fbCampaign: ":campaignId",
    },
  },
  campaignPreview: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CAMPAIGN_PREVIEW}`,
    root: `${leftPanelNameInUrlPath.CAMPAIGN_PREVIEW}`, // should matches the end of fullbase
    sub: {},
  },
  analyticsDashboard: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ANALYTICS_DASHBOARD}`,
    root: `${leftPanelNameInUrlPath.ANALYTICS_DASHBOARD}`, // should matches the end of fullbase
    sub: {},
  },

});

export default hdmAmRoutePaths;
