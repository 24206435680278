import { BACKEND_STATUS } from "../accountManagerUtil";

// return a boolean, checks wether a platfrom is selected or not
export const isPlatformSelectedBP = (form, platformName) => {
  // debugger;
  const result = form?.salesQuestionnaire?.spends?.platforms?.findIndex(
    (platform) => platform.platform === platformName
  );

  if (result === -1) {
    return false;
  }
  return true;
};

// returns current proposal state
// if not exist returns a null
export const proposalStage = (proposalIterations) =>
  proposalIterations?.length !== 0
    ? proposalIterations?.[proposalIterations?.length - 1]["state"]
    : null;

// returns current strategy state
// if not exist returns a null
export const strategyStage = (strategyIterations) =>
  strategyIterations?.length !== 0
    ? strategyIterations?.[strategyIterations?.length - 1]["state"]
    : null;
// return a boolean
export const isProposalStartedBP = (form) => {
  let isProposalOngoing = false;
  if (
    isPlatformSelectedBP(form, "google-ads") &&
    isPlatformSelectedBP(form, "facebook-ads")
  ) {
    if (
      form?.googleAuditStatus === BACKEND_STATUS.COMPLETED &&
      form?.facebookAuditStatus === BACKEND_STATUS.COMPLETED
    ) {
      isProposalOngoing = true;
    }
  } else if (
    isPlatformSelectedBP(form, "google-ads") &&
    form?.googleAuditStatus === BACKEND_STATUS.COMPLETED
  ) {
    isProposalOngoing = true;
  } else if (
    isPlatformSelectedBP(form, "facebook-ads") &&
    form?.facebookAuditStatus === BACKEND_STATUS.COMPLETED
  ) {
    isProposalOngoing = true;
  }
  return isProposalOngoing;
};
