import React from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    IconButton,
    TextField,
} from "@mui/material";
import { Clear, ExpandMore } from "@mui/icons-material";
import MetricList from "./MetricList";
import SingleMetricSettings from "./SingleMetricSettings";
import { Metric } from "../../models/chart";
import { useSelector } from "react-redux";
import { defaultFBState } from "../../models/defaultData";

export default function EditChartType2({ handleAllChartsForceReload, editDrawerState, currentMetricState, setCurrentMetricState, channel }) {

    const { chart, handleChartChange } = editDrawerState;
    const [leftMetrics, setLeftMetrics] = React.useState(chart.leftMetrics.concat([null]));
    const [rightMetrics, setRightMetrics] = React.useState(chart.rightMetrics?.concat([null]));
    const [table, setTable] = React.useState(chart.table);

    const state = useSelector(state => channel.getState(state)) ?? defaultFBState;
    const tables = state.tables;
    const metrics = Array.isArray(state.metrics) ? state.metrics : state.metrics?.[table?.id] ?? [];

    if (!currentMetricState.metric.channelType) {
        currentMetricState.metric.channelType = channel.type;
        currentMetricState.metric.validate();
    }

    React.useEffect(() => {
        if (table !== chart.table) {
            handleChartChange("table", table);
            setLeftMetrics([null]);
            setRightMetrics(chart.rightMetrics ? [null] : null);
        }
    }, [table]);

    React.useEffect(() => {
        handleChartChange("leftMetrics", leftMetrics.filter(metric => metric && metric.metric));
    }, [leftMetrics]);

    React.useEffect(() => {
        handleChartChange("rightMetrics", rightMetrics?.filter(metric => metric && metric.metric));
    }, [rightMetrics]);

    const handleSingleMetricSettings = (metric, axis, index) => setCurrentMetricState({
        isOpen: true,
        metric: metric,
        axis: axis,
        index: index,
    });

    const updateFilter = (filter) => {
        if (currentMetricState.axis === "left") {
            const newMetrics = Array.from(leftMetrics);
            const metric = Metric.copy(newMetrics[currentMetricState.index]);
            metric.filter = filter;
            newMetrics[currentMetricState.index] = metric;
            setLeftMetrics(newMetrics);
            setCurrentMetricState({ ...currentMetricState, metric: metric });
        } else {
            const newMetrics = Array.from(rightMetrics);
            const metric = Metric.copy(newMetrics[currentMetricState.index]);
            metric.filter = filter;
            newMetrics[currentMetricState.index] = metric;
            setRightMetrics(newMetrics);
            setCurrentMetricState({ ...currentMetricState, metric: metric });
        }
    }

    const getDisplayFilters = () => {
        return leftMetrics.filter(metric => metric && metric.metric).map((metric, index) => {
            const filterString = metric.getFilterString();
            if (filterString) {
                return {
                    index: index,
                    axis: "left",
                    string: filterString,
                };
            }
            return null;
        }).concat(
            rightMetrics?.filter(metric => metric && metric.metric).map((metric, index) => {
                const filterString = metric.getFilterString();
                if (filterString) {
                    return {
                        index: index,
                        axis: "right",
                        string: filterString,
                    };
                }
                return null;
            })
        ).filter(filter => filter);
    }

    const displayFilters = getDisplayFilters();

    return (
        <div>

            {currentMetricState.isOpen ? (
                <SingleMetricSettings
                    chart={chart}
                    channel={channel}
                    currentMetric={currentMetricState.metric}
                    isKPI={chart.type === "KPI"}
                    handleChartChange={handleChartChange}
                    dimension={chart.dimensions ? chart.dimensions[0]?.name : null}
                    onChange={metric => {
                        if (currentMetricState.axis === "left") {
                            const newMetrics = Array.from(leftMetrics);
                            newMetrics[currentMetricState.index] = metric;
                            if (metric && currentMetricState.index === newMetrics.length - 1) {
                                newMetrics.push(null);
                            }
                            setLeftMetrics(newMetrics);
                        } else {
                            const newMetrics = Array.from(rightMetrics);
                            newMetrics[currentMetricState.index] = metric;
                            if (metric && currentMetricState.index === newMetrics.length - 1) {
                                newMetrics.push(null);
                            }
                            setRightMetrics(newMetrics);
                        }
                        setCurrentMetricState({ ...currentMetricState, metric: metric });
                    }}
                    updateFilter={updateFilter}
                    handleAllChartsForceReload={handleAllChartsForceReload}
                    onClose={() => setCurrentMetricState({ ...currentMetricState, isOpen: false })}
                />
            ) : (
                <div>
                    <Accordion defaultExpanded={chart.title === ""} sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                            sx={{ maxHeight: "30px" }}
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <h4 className="inter bold" >
                                Edit Title
                            </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                label="Title"
                                placeholder="Enter Title"
                                style={{ width: "100%" }}
                                id="outlined-basic"
                                defaultValue={chart.title}
                                onChange={e => handleChartChange("title", e.target.value)}
                                variant="outlined"
                                size="small" />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                            sx={{ maxHeight: "30px" }}
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
                            <h4 className="inter bold">
                                Edit Widget Data
                            </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* report type */}
                            {tables && <div>
                                <h4 className="inter bold">
                                    Report type
                                </h4>
                                <MetricList
                                    channel={channel}
                                    metric={table}
                                    metrics={tables}
                                    isEditable={true}
                                    onClick={setTable}
                                    defaultTitle="Add Report Type"
                                />
                                <br />
                            </div>}

                            {/* left metrics */}
                            <h4 className="inter bold">
                                {rightMetrics ? "LEFT AXIS" : "Metrics"}
                            </h4>
                            {leftMetrics.map((metric, index) =>
                                <MetricList
                                    key={leftMetrics.length * (index + 1)}
                                    channel={channel}
                                    metric={metric}
                                    metrics={metrics}
                                    onClick={metric => {
                                        if (index === leftMetrics.length - 1) {
                                            const newMetrics = Array.from(leftMetrics);
                                            newMetrics[index] = Metric.new(chart, metric);
                                            newMetrics.push(null);
                                            setLeftMetrics(newMetrics);
                                        } else {
                                            handleSingleMetricSettings(metric, "left", index);
                                        }
                                    }} onDelete={() => {
                                        const newMetrics = Array.from(leftMetrics);
                                        setLeftMetrics(newMetrics.slice(0, index).concat(newMetrics.slice(index + 1)));
                                    }}
                                />
                            )}

                            {/* right metrics */}
                            {rightMetrics &&
                                <h4 className="inter bold" style={{ marginTop: "25px" }}>
                                    RIGHT AXIS
                                </h4>
                            }
                            {rightMetrics?.map((metric, index) =>
                                <MetricList
                                    key={rightMetrics.length * (index + 1)}
                                    channel={channel}
                                    metric={metric}
                                    metrics={metrics}
                                    onClick={metric => {
                                        if (index === rightMetrics.length - 1) {
                                            const newMetrics = Array.from(rightMetrics);
                                            newMetrics[index] = Metric.new(chart, metric);
                                            newMetrics.push(null);
                                            setRightMetrics(newMetrics);
                                        } else {
                                            handleSingleMetricSettings(metric, "right", index);
                                        }
                                    }} onDelete={() => {
                                        const newMetrics = Array.from(rightMetrics);
                                        setRightMetrics(newMetrics.slice(0, index).concat(newMetrics.slice(index + 1)));
                                    }}
                                />
                            )}

                            {/* filter display */}
                            {displayFilters.length > 0 &&
                                <h4 className="inter bold" style={{ marginTop: "25px" }}>
                                    FILTERS
                                </h4>
                            }
                            {displayFilters.map((filter, index) =>
                                <div
                                    key={index}
                                    style={{
                                        marginTop: "15px",
                                        marginBottom: "15px",
                                        display: "flex",
                                        flexDirection: "row",
                                        width: "400px"
                                    }}>
                                    <div className="filter-list">
                                        {filter.string}
                                    </div>
                                    <IconButton
                                        onClick={() => {
                                            if (filter.axis === "left") {
                                                const newMetrics = Array.from(leftMetrics);
                                                newMetrics[filter.index] = Metric.new(chart, newMetrics[filter.index].metric);
                                                setLeftMetrics(newMetrics);
                                            } else {
                                                const newMetrics = Array.from(rightMetrics);
                                                newMetrics[filter.index] = Metric.new(chart, newMetrics[filter.index].metric);
                                                setRightMetrics(newMetrics);
                                            }
                                        }}
                                        disableRipple
                                        disableFocusRipple
                                        aria-label="delete">
                                        <Clear />
                                    </IconButton>
                                </div>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}
        </div>
    );
}
