export const updateHDMCompoReduxClient = () => {
  return {
    type: "UPDATE_CURRENT_COMPONENT_HDM",
  };
};

export const goBackHDMClientCompo = () => {
  return {
    type: "GO_BACK_HDMCLIENT_COMPO",
  };
};

export const goToSpecificCompoHDMClient = (payload) => {
  return {
    type: "GO_TO_SPECIFIC_COMPO_HDMCLIENT",
    payload: payload,
  };
};

export const goToReviewHDMQuestionier = (payload) => {
  return {
    type: "REVIEW_HDMQUESTIONIER",
    payload: payload,
  };
};

export const goBackReviewHDMQuestionier = (payload) => {
  return {
    type: "GO_BACK_REVIEW_HDMQUESTIONIER",
    payload: payload,
  };
};

export const updateTabHDM = (payload) => {
  return {
    type: "UPDATE_TAB_HDMCLIENT",
    payload: payload,
  };
};
