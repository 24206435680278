import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import infoIcon from '../../assets/icons/campaign-approval/campaign-publish-modal-icon.svg';
import PrimaryButton from '../ButtonPrimary/Buttons';

const GoBackConfirmationModal = ({ 
    clickHandler,
    discardHandler=() => {},
    saveHandler=() => {},
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 444,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 3.5,
    padding: '3rem 3rem 0 3rem',
    // height: '380px',
    borderRadius: '15px',
    outline: 'none',
  };

  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Close
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
          }}
          onClick={() => clickHandler(false)}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            gap: '0.5rem',
          }}
        >
          <div>
            <img src={infoIcon} alt='' width={64} height={64} />
          </div>
          <div style={{ marginTop: '1.5rem' }}>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 20,
                textAlign: 'center',
              }}
            >
              Are you sure you want to go back?
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                color: '#6F6C90',
                textAlign: 'center',
              }}
            >
              The changes haven't been saved yet.
            </Typography>
          </div>
          <div
            style={{
              margin: '2rem 0rem',
              display: 'flex',
              gap: '1.5rem',
              justifyContent: 'center',
              paddingBottom: '1rem',
            }}
          >
            {/* <PrimaryButton title='Go Back' type='bordered' width={145} /> */}
            {/* <PrimaryButton title='PUBLISH' width={145} /> */}
            <Button
              style={{
                width: 145,
                height: 40,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '20px',
                color: '#0869FB',
                border: '1px solid #0869FB',
                borderRadius: 8,
                textTransform: 'capitalize',
              }}
              onClick={() => discardHandler()}
            >
              Discard
            </Button>
            <Button
              style={{
                width: 145,
                height: 40,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '20px',
                color: 'white',
                background: '#0869FB',
                borderRadius: 8,
                textTransform: 'capitalize',
              }}
               onClick={() => saveHandler()}
            >
              Save
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default GoBackConfirmationModal;
