import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { BACKEND_STATUS } from "../utils/accountManagerUtil";
import { api } from "../api";
import { queryClient } from "../utils/queryClient";
import { concat } from 'lodash';

const getGoogleSegmentQueryKey = formId =>
    ["google-segment/:formId", formId]

const getSpecificSegmentQueryKey = (formId, segmentId) =>
    ["google-segment/:formId/segment/:segmentId", formId, segmentId]

const getCustomSegmentQueryKey = formId => ["google-custom-segment/:formId", formId]

export const useGetListGoogleSegments = (formId, generationType=null, options={}) => {
    return useQuery(
        getGoogleSegmentQueryKey(formId),
        async () => {
            let response;
            if (generationType === null)
                response = await api.get(`/client-forms/${formId}/pmax-strategy/segment`);
            else
                response = await api.get(`/client-forms/${formId}/pmax-strategy/segment?generationType=${generationType}`);
            return response.data;
        },
        options
    )
}

export const useGetGoogleSegment = (formId, segmentId, options={}) => {
    return useQuery(
        getSpecificSegmentQueryKey(formId, segmentId),
        async () => {
            const response = await api.get(`/client-forms/${formId}/pmax-strategy/segment/${segmentId}`);
            return response.data;
        },
        options,
    )
}

export const usePostGoogleSegment = (formId, options={}) => {
    const { onSuccess, ...otherOptions } = options;
    const queryClient = useQueryClient();
    
    return useMutation({
        mutationFn: async (payload={}) => {
            const body = {
                ...payload,
                status: BACKEND_STATUS.ONGOING
            }
            const response = await api.post(`/client-forms/${formId}/pmax-strategy/segment`, body);
            return response.data;
        },
        onSuccess: (data, variables, ctx) => {
            // Invalidate & refetch
            // queryClient.invalidateQueries({ queryKey: getGoogleSegmentQueryKey });

            queryClient.setQueryData(
                getGoogleSegmentQueryKey(formId),
                (prev) => ({
                    ...prev,
                    segments: concat(prev?.segments, data),
                    totalResultsCount: (prev?.segments?.length || 0) + 1,
                })
            );
            
            onSuccess?.(data, variables, ctx);
        },
        ...otherOptions
    })
}

export const usePutGoogleSegment = (formId, options={}) => {
    return useMutation({
        mutationFn: async (payload={}) => {
            const { segmentId, ...fields } = payload;
            const data = {
                ...fields
            };
            const response = await api.put(`/client-forms/${formId}/pmax-strategy/segment/${segmentId}`, data);
            return response.data;
        },
        ...options,
    })
}

// update multiple segments parallely

export const usePutGoogleSegments = (formId, options={}) => {
    return useMutation({
        mutationFn: async (segments = []) => {
            const response = await Promise.all(segments.map(segment => {
                return api.put(`/client-forms/${formId}/pmax-strategy/segment/${segment.id}`, segment);
            }));
            return response;
        },
        onSuccess: (...args) => {
            // Invalidate & refetch
            queryClient.invalidateQueries({ queryKey: getGoogleSegmentQueryKey(formId) });
        },
        ...options,
    })
}
export const useDeleteGoogleSegment = (formId, options={}) => {
    return useMutation({
        mutationFn: async (segmentId="") => {
            const response = await api.delete(`/client-forms/${formId}/pmax-strategy/segment/${segmentId}`);
            return response.data;
        },
        ...options,
        onSuccess(data, variables, ctx) {
            queryClient.setQueryData(
                getGoogleSegmentQueryKey(formId),
                (prev) => ({
                    ...prev,
                    segments: prev?.segments?.filter(segment => segment.id !== variables),
                    totalResultsCount: (prev?.segments?.length || 0) + 1,
                })
            );
            options.onSuccess?.(data, variables, ctx);
        },
    })
}


export const useGetCustomSegments = (formId, options={}) => {
    return useQuery(
        getCustomSegmentQueryKey(formId),
        async () => {
          const response = await api.get(`/g-ads/custom-segments?formId=${formId}`);
          return response.data;
        },
        options
    );
}
