import clientSaga from '../api/client/saga';
import hdmClientSaga from '../api/HDMClient/saga';
import { call, all, spawn, fork } from 'redux-saga/effects';
import formSaga from '../api/accountManager/forms/saga';
import pricingSaga from '../api/accountManager/pricing/saga';
import channel from '../api/channels/saga';
import googleAdCustomerSaga from '../api/accountManager/googleAdsCustomer/saga';
import sharedAssetsSagaAM from '../api/accountManager/sharedAssets/saga';
import targetAISaga from '../api/accountManager/targetAI/saga';
import creativeAISaga from '../api/accountManager/creativeAI/saga';
import sharedAssetsSaga from '../api/sharedAssets/saga';
import hdmAudit from '../api/HDMAM/audit/saga';
import campaignSaga from '../api/accountManager/campaign/saga';
import hdmAmFormSaga from '../api/HDMAM/form/saga';
import strategyCreationHdmAm from '../api/HDMAM/strategyCreation/saga';
import HDMFBcampaignSaga from '../api/HDMAM/campaign/saga';
import analyticsSaga from '../api/analytics/saga';
import creativeApprovalSaga from '../api/creativeApproval/saga';
import pmaxStrategySaga from '../api/pmaxStrategy/saga';
import pmaxCampaignSaga from '../api/pmaxCampaign/saga';
import newFormSaga from '../api/clientforms/saga';

function* rootSaga() {
  const sagas = [
    formSaga,
    pricingSaga,
    clientSaga,
    channel,
    googleAdCustomerSaga,
    sharedAssetsSagaAM,
    targetAISaga,
    creativeAISaga,
    hdmClientSaga,
    sharedAssetsSaga,
    hdmAudit,
    campaignSaga,
    hdmAmFormSaga,
    strategyCreationHdmAm,
    HDMFBcampaignSaga,
    analyticsSaga,
    creativeApprovalSaga,
    pmaxStrategySaga,
    pmaxCampaignSaga,
    newFormSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {}
        }
      })
    )
  );
}

export default rootSaga;
