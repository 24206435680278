import { useState, useEffect, useRef } from "react";
import PMaxCampaignCreation from "../PMaxCampaign/PMaxCampaignCreation/PMaxCampaignCreation";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdAssets,
  getKeywords,
  getPmaxStrategies,
  getSegments,
} from "../../api/pmaxStrategy/slice";
import {
  createPmaxCampaign,
  getPmaxCampaigns,
  publishSpecificPMaxCampaign,
  updateData,
  updatePmaxCampaign,
} from "../../api/pmaxCampaign/slice";
import { Languages } from "../../utils/Languages";
import PMaxCampaignAssetGroup from "../PMaxCampaign/PMaxCampaignAssetGroup/PMaxCampaignAssetGroup";
import { PMaxCampaignName } from "../../constants/LDConstants";
import {
  fetchAudience,
  fetchCriteria,
  fetchUserList,
} from "../../api/accountManager/googleAdsCustomer/slice";

import { AxiosError } from "axios";
import { accountManagerIds,  ldComponentWithIndexAm, BACKEND_STATUS, getGooglePMaxCampaignStatus, getGooglePmaxCampaignObject } from "../../utils/accountManagerUtil";
import { goToSpecificCompoClient } from "../../api/accountManager/componentTracking/action";
import { getFormByFormIdReducer } from "../../api/accountManager/forms/slice";

const ENGLISH_LANG_OPTION = Languages.find(
  (lang) => lang.language === "English"
);
const DEFAULT_LANGUAGES = ENGLISH_LANG_OPTION ? [ENGLISH_LANG_OPTION] : [];
const POLLING_INTERVAL = 1 * 60 * 1000; // 1 min

const PMaxCampaignCreationLD = () => {
  const dispatch = useDispatch();
  // for campaign1
  const [campaign1CampName, setCampaign1CampName] = useState("");
  const [campaign1Objective, setCampaign1Objective] = useState("");
  const [campaign1Locations, setCampaign1Locations] = useState([]);
  const [campaign1ConversionGoals, setCampaign1ConversionGoals] = useState([]);
  const [campaign1Budget, setCampaign1Budget] = useState(0);
  const [campaign1Languages, setCampaign1Languages] = useState([]);
  const [campaign1Bidding, setCampaign1Bidding] = useState("Conversions");
  const [campaign1TargetRoas, setCampaign1TargetRoas] = useState("");
  const [campaign1TargetCpa, setCampaign1TargetCpa] = useState("");
  const [isCampaign1StrategyCreated, setIsCampaign1StrategyCreated] =
    useState(false);
    const [hasCampaign1CampLevelChange, setHasCampaign1CampLevelChange] = useState(false);
    const [campaign1PublishError, setCampaign1PublishError] = useState([]);
    const [isCampaign1Publishing, setIsCampaign1Publishing] = useState(false);
    const [isCampaign1Published, setIsCampaign1Published] = useState(false);

  // for campaign2
  const [campaign2CampName, setCampaign2CampName] = useState("");
  const [campaign2Objective, setCampaign2Objective] = useState("");
  const [campaign2Locations, setCampaign2Locations] = useState([]);
  const [campaign2ConversionGoals, setCampaign2ConversionGoals] = useState([]);
  const [campaign2Budget, setCampaign2Budget] = useState(0);
  const [campaign2Languages, setCampaign2Languages] = useState([]);
  const [campaign2Bidding, setCampaign2Bidding] = useState("Conversions");
  const [campaign2TargetRoas, setCampaign2TargetRoas] = useState("");
  const [campaign2TargetCpa, setCampaign2TargetCpa] = useState("");
  const [isCampaign2StrategyCreated, setIsCampaign2StrategyCreated] =
    useState(false);
    const [hasCampaign2CampLevelChange, setHasCampaign2CampLevelChange] = useState(false);
  const [campaign2PublishError, setCampaign2PublishError] = useState([]);
  const [isCampaign2Publishing, setIsCampaign2Publishing] = useState(false);
  const [isCampaign2Published, setIsCampaign2Published] = useState(false);

  // for campaign3
  const [campaign3CampName, setCampaign3CampName] = useState("");
  const [campaign3Objective, setCampaign3Objective] = useState("");
  const [campaign3Locations, setCampaign3Locations] = useState([]);
  const [campaign3ConversionGoals, setCampaign3ConversionGoals] = useState([]);
  const [campaign3Budget, setCampaign3Budget] = useState(0);
  const [campaign3Languages, setCampaign3Languages] = useState([]);
  const [campaign3Bidding, setCampaign3Bidding] = useState("Conversions");
  const [campaign3TargetRoas, setCampaign3TargetRoas] = useState("");
  const [campaign3TargetCpa, setCampaign3TargetCpa] = useState("");
  const [isCampaign3StrategyCreated, setIsCampaign3StrategyCreated] =
    useState(false);
    const [hasCampaign3CampLevelChange, setHasCampaign3CampLevelChange] = useState(false);
  const [campaign3PublishError, setCampaign3PublishError] = useState([]);
  const [isCampaign3Publishing, setIsCampaign3Publishing] = useState(false);
  const [isCampaign3Published, setIsCampaign3Published] = useState(false);

  const [campaignObj, setCampaignObj] = useState({})
  const [isCampaignGenerating, setIsCampaignGenerating] = useState(false);
  const [viewAssetGroup, setViewAssetGroup] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState({});

  const [publishApiErrors, setPublishApiErrors] = useState({
    [PMaxCampaignName.campaign1]: "",
    [PMaxCampaignName.campaign2]: "",
    [PMaxCampaignName.campaign3]: "",
  });

  const [campaignIdsWithCampaignNumber, setCampaignIdsWithCampaignNumber] = useState({});

  const [
    campaign1Strategies,
    campaign2Strategies,
    campaign3Strategies,
    campaignAssetsCount,
    campaign1PMaxCampaign,
    campaign2PMaxCampaign,
    campaign3PMaxCampaign,
    pmaxStrategyBudget,
    form,
  ] = useSelector((store) => [
    store.pmaxStrategy.campaign1,
    store.pmaxStrategy.campaign2,
    store.pmaxStrategy.campaign3,
    store.pmaxStrategy.assetCounts,
    store.pmaxCampaign.campaign1,
    store.pmaxCampaign.campaign2,
    store.pmaxCampaign.campaign3,
    store.pmaxStrategy.budget,
    store.form.form,
  ]);

  const [
    affinityCategories,
    inmarketCategories,
    lifeEvents,
    detailedDemographics,
  ] = useSelector((store) => [
    store.googleCustomers.affinityCategories,
    store.googleCustomers.inmarketCategories,
    store.googleCustomers.lifeEvents,
    store.googleCustomers.detailedDemographics,
  ]);
  const directSegments = useSelector(
    (store) => store.pmaxStrategy.directSegments
  );

  const isPmaxCampaignFetched = useSelector(
    (store) => store.pmaxCampaign.isPmaxCampaignFetched
  );
  const [campaign1AssetGroups, campaign2AssetGroups, campaign3AssetGroups] =
    useSelector((store) => [
      store.pmaxCampaign.campaign1AssetGroups,
      store.pmaxCampaign.campaign2AssetGroups,
      store.pmaxCampaign.campaign3AssetGroups,
    ]);

    const intervelRef = useRef();

  
    useEffect(() => {
      // not initialized yet if campaignIds has no keys
      if(!Object.keys(campaignObj).length) return;
      if(!isCampaignGenerating) return;
      const callback = () => {
        dispatch(getFormByFormIdReducer({
          callback(error) {
            if(error) return;
            // setIsPublishingForced(DEFAULT_PUBLISHING_FORCED);
          }
        }));
      };
      intervelRef.current = setInterval(callback, POLLING_INTERVAL);
      return () => {
        clearInterval(intervelRef.current);
      };
    }, [dispatch, campaignObj, isCampaignGenerating]);

  useEffect(() => {
    dispatch(getPmaxCampaigns());
    dispatch(getPmaxStrategies());
    dispatch(getSegments());
    dispatch(getAdAssets());
    dispatch(getKeywords());
    dispatch(fetchAudience());
    dispatch(fetchCriteria());
    dispatch(fetchUserList());
  }, []);


  useEffect(() => {
    if(publishApiErrors[PMaxCampaignName.campaign1]){
      setCampaign1PublishError([ publishApiErrors[PMaxCampaignName.campaign1], ...campaign1PublishError])
    }
    if(publishApiErrors[PMaxCampaignName.campaign2]){
      setCampaign2PublishError([ publishApiErrors[PMaxCampaignName.campaign2], ...campaign2PublishError])
    }
    if(publishApiErrors[PMaxCampaignName.campaign3]){
      setCampaign3PublishError([ publishApiErrors[PMaxCampaignName.campaign3], ...campaign3PublishError])
    }
  }, [publishApiErrors])
  useEffect(() => {
    if (directSegments.length) {
      const tempInterestAndDemographies = directSegments.map((segment) => {
        if (segment.type === "In-Market Audiences") {
          const obj = inmarketCategories.find(
            (category) => category.criterionId === segment.userInterestId
          );
          if (obj) {
            let values = obj.category.split("/");
            return {
              ...obj,
              type: "inmarketCategories",
              value: values[values.length - 1],
            };
          } else {
            return null;
          }
        }
        if (segment.type === "Affinity Audiences") {
          const obj = affinityCategories.find(
            (category) => category.criterionId === segment.userInterestId
          );
          if (obj) {
            let values = obj.category.split("/");
            return {
              ...obj,
              type: "affinityCategories",
              value: values[values.length - 1],
            };
          } else {
            return null;
          }
        }
      });

      dispatch(
        updateData({
          interestAndDemographies: tempInterestAndDemographies.filter(Boolean),
        })
      );
    }
  }, [
    affinityCategories,
    inmarketCategories,
    lifeEvents,
    detailedDemographics,
    directSegments,
  ]);
  useEffect(() => {
    if (campaign1Strategies.length) {
      setIsCampaign1StrategyCreated(true);
      setCampaign1CampName(campaign1PMaxCampaign?.name || "");
      setCampaign1Objective(campaign1PMaxCampaign?.objective || 'Leads');
      setCampaign1Locations(
        campaign1PMaxCampaign?.locations ||
          form?.intakeInfo?.audiences?.geoTargets ||
          []
      );
      setCampaign1ConversionGoals(campaign1PMaxCampaign?.conversionGoals || ['Book Appointments']);
      setCampaign1Budget(
        campaign1PMaxCampaign?.budget || pmaxStrategyBudget?.campaign1 || 0
      );
      setCampaign1Languages(
        campaign1PMaxCampaign?.languages || DEFAULT_LANGUAGES
      );
      setCampaign1Bidding(campaign1PMaxCampaign?.bidding || "Conversions");
      setCampaign1TargetRoas(campaign1PMaxCampaign?.targetRoas);
      setCampaign1TargetCpa(campaign1PMaxCampaign?.targetCpa);
      if(campaign1PMaxCampaign?.id){
        setCampaignIdsWithCampaignNumber(prev => {
          return {
            ...prev,
            [PMaxCampaignName.campaign1]: campaign1PMaxCampaign?.id
          }
        })
      }
    }
    if (campaign2Strategies.length) {
      setIsCampaign2StrategyCreated(true);
      setCampaign2CampName(campaign2PMaxCampaign?.name || "");
      setCampaign2Objective(campaign2PMaxCampaign?.objective || 'Leads');
      setCampaign2Locations(
        campaign2PMaxCampaign?.locations ||
          form?.intakeInfo?.audiences?.geoTargets ||
          []
      );
      setCampaign2ConversionGoals(campaign2PMaxCampaign?.conversionGoals || ['Book Appointments']);
      setCampaign2Budget(
        campaign2PMaxCampaign?.budget || pmaxStrategyBudget?.campaign2 || 0
      );
      setCampaign2Languages(
        campaign2PMaxCampaign?.languages || DEFAULT_LANGUAGES
      );
      setCampaign2Bidding(campaign2PMaxCampaign?.bidding || "Conversions");
      setCampaign2TargetRoas(campaign2PMaxCampaign?.targetRoas);
      setCampaign2TargetCpa(campaign2PMaxCampaign?.targetCpa);
      if(campaign2PMaxCampaign?.id){
        setCampaignIdsWithCampaignNumber(prev => {
          return {
            ...prev,
            [PMaxCampaignName.campaign2]: campaign2PMaxCampaign?.id
          }
        })
      }
    }
    if (campaign3Strategies.length) {
      setIsCampaign3StrategyCreated(true);
      setCampaign3CampName(campaign3PMaxCampaign?.name || "");
      setCampaign3Objective(campaign3PMaxCampaign?.objective || 'Leads');
      setCampaign3Locations(
        campaign3PMaxCampaign?.locations ||
          form?.intakeInfo?.audiences?.geoTargets ||
          []
      );
      setCampaign3ConversionGoals(campaign3PMaxCampaign?.conversionGoals || ['Book Appointments']);
      setCampaign3Budget(
        campaign3PMaxCampaign?.budget || pmaxStrategyBudget?.campaign3 || 0
      );
      setCampaign3Languages(
        campaign3PMaxCampaign?.languages || DEFAULT_LANGUAGES
      );
      setCampaign3Bidding(campaign3PMaxCampaign?.bidding || "Conversions");
      setCampaign3TargetRoas(campaign3PMaxCampaign?.targetRoas);
      setCampaign3TargetCpa(campaign3PMaxCampaign?.targetCpa);
      if(campaign3PMaxCampaign?.id){
        setCampaignIdsWithCampaignNumber(prev => {
          return {
            ...prev,
            [PMaxCampaignName.campaign3]: campaign3PMaxCampaign?.id
          }
        })
      }
    }
  }, [
    campaign1Strategies,
    campaign2Strategies,
    campaign3Strategies,
    campaign1PMaxCampaign,
    campaign2PMaxCampaign,
    campaign3PMaxCampaign,
    pmaxStrategyBudget,
    form,
  ]);

  useEffect(() => {
    if(form?.pmaxAdsGeneratedCampaignsInfo){
      const [campaignIds, isGenerating] = getGooglePmaxCampaignObject(form?.pmaxAdsGeneratedCampaignsInfo, campaignIdsWithCampaignNumber);
      setCampaignObj(campaignIds);
      setIsCampaignGenerating(isGenerating);
      let {errors, isCompleted, isPublishing} = getGooglePMaxCampaignStatus(PMaxCampaignName.campaign1, form?.pmaxAdsGeneratedCampaignsInfo, campaignIdsWithCampaignNumber);
      if(errors.length){
        setCampaign1PublishError(errors);
        setIsCampaign1Publishing(false);
        setIsCampaign1Published(false);
      }
      if(isPublishing){
        setCampaign1PublishError([]);
        setIsCampaign1Publishing(true);
        setIsCampaign1Published(false);
      }
      if(isCompleted){
        setCampaign1PublishError([]);
        setIsCampaign1Publishing(false);
        setIsCampaign1Published(true);
      }


      if(isCampaign2StrategyCreated){
        let {errors, isCompleted, isPublishing} = getGooglePMaxCampaignStatus(PMaxCampaignName.campaign2, form?.pmaxAdsGeneratedCampaignsInfo, campaignIdsWithCampaignNumber);
        if(errors.length){
          setCampaign2PublishError(errors);
          setIsCampaign2Publishing(false);
          setIsCampaign2Published(false);
        }

        if(isPublishing){
          setCampaign2PublishError([]);
          setIsCampaign2Publishing(true);
          setIsCampaign2Published(false);
        }

        if(isCompleted){
          setCampaign2PublishError([]);
          setIsCampaign2Publishing(false);
          setIsCampaign2Published(true);
        }

      }
      if(isCampaign3StrategyCreated){
        let {errors, isCompleted, isPublishing} = getGooglePMaxCampaignStatus(PMaxCampaignName.campaign3, form?.pmaxAdsGeneratedCampaignsInfo, campaignIdsWithCampaignNumber);
        if(errors.length){
          setCampaign3PublishError(errors);
          setIsCampaign3Publishing(false);
          setIsCampaign3Published(false);
        }
        if(isPublishing){
          setCampaign3PublishError([]);
          setIsCampaign3Publishing(true);
          setIsCampaign3Published(false);
        }
        if(isCompleted){
          setCampaign3PublishError([]);
          setIsCampaign3Publishing(false);
          setIsCampaign3Published(true);
        }
  

      }
    }
  }, [form, isCampaign1StrategyCreated, isCampaign2StrategyCreated, isCampaign3StrategyCreated, campaignIdsWithCampaignNumber])
  // useEffect(() => {
  //   if (isPmaxCampaignFetched) {
  //     let camp1assetsData = [...campaign1AssetGroups];
  //     let camp2assetsData = [...campaign2AssetGroups];
  //     let camp3assetsData = [...campaign3AssetGroups];
  //     let camp1 = {};
  //     let camp2 = {};
  //     let camp3 = {};

  //     if (!campaign1AssetGroups.length && campaign1Strategies.length) {
  //       camp1.assetCount = campaignAssetsCount.campaign1;
  //       camp1assetsData = campaign1Strategies.map((asset) => {
  //         return {
  //           name: asset.name,
  //           headlines: asset.headlines,
  //           descriptions: asset.descriptions,
  //           longHeadlines: asset.longHeadlines,
  //           logoUrls: asset.logoUrls,
  //           imageUrls: asset.imageUrls,
  //           ytVideosUrls: asset.ytVideosMetadata,
  //           finalUrl: form?.onboardingInfo?.personalInfo?.companyWebsite,
  //           assetNumber: asset?.assetNumber
  //         };
  //       });
  //     }
  //     if (!campaign2AssetGroups.length && campaign2Strategies.length) {
  //       camp2.assetCount = campaignAssetsCount.campaign2;
  //       camp2assetsData = campaign2Strategies.map((asset) => {
  //         return {
  //           name: asset.name,
  //           headlines: asset.headlines,
  //           descriptions: asset.descriptions,
  //           longHeadlines: asset.longHeadlines,
  //           logoUrls: asset.logoUrls,
  //           imageUrls: asset.imageUrls,
  //           ytVideosUrls: asset.ytVideosMetadata,
  //           finalUrl: form?.onboardingInfo?.personalInfo?.companyWebsite,
  //           assetNumber: asset?.assetNumber
  //         };
  //       });
  //     }
  //     if (!campaign3AssetGroups.length && campaign3Strategies.length) {
  //       camp3.assetCount = campaignAssetsCount.campaign3;
  //       camp3assetsData = campaign3Strategies.map((asset) => {
  //         return {
  //           name: asset.name,
  //           headlines: asset.headlines,
  //           descriptions: asset.descriptions,
  //           longHeadlines: asset.longHeadlines,
  //           logoUrls: asset.logoUrls,
  //           imageUrls: asset.imageUrls,
  //           ytVideosUrls: asset.ytVideosMetadata,
  //           finalUrl: form?.onboardingInfo?.personalInfo?.companyWebsite,
  //           assetNumber: asset?.assetNumber
  //         };
  //       });
  //     }
  //     dispatch(
  //       updateData({
  //         campaign1: camp1,
  //         campaign2: camp2,
  //         campaign3: camp3,
  //         campaign1AssetGroups: camp1assetsData,
  //         campaign2AssetGroups: camp2assetsData,
  //         campaign3AssetGroups: camp3assetsData,
  //       })
  //     );
  //   }
  // }, [
  //   isPmaxCampaignFetched,
  //   campaign1Strategies,
  //   campaign2Strategies,
  //   campaign3Strategies,
  // ]);

  const setLocationsHandler = (campType, locations) => {
    switch (campType) {
      case PMaxCampaignName.campaign1:
        setCampaign1Locations(locations);
        break;
      case PMaxCampaignName.campaign2:
        setCampaign2Locations(locations);
        break;
      case PMaxCampaignName.campaign3:
        setCampaign3Locations(locations);
        break;
      default:
        break;
    }
  };

  const setLanguageHandler = (campType, locations) => {
    switch (campType) {
      case PMaxCampaignName.campaign1:
        setCampaign1Languages(locations);
        break;
      case PMaxCampaignName.campaign2:
        setCampaign2Languages(locations);
        break;
      case PMaxCampaignName.campaign3:
        setCampaign3Languages(locations);
        break;
      default:
        break;
    }
  };

  const setBiddingHandler = (campType) => (value) => {
    if (value === "Conversions") {
      if (campType === PMaxCampaignName.campaign1) {
        setCampaign1TargetRoas("");
        setCampaign1Bidding(value);
      } else if (campType === PMaxCampaignName.campaign2) {
        setCampaign2TargetRoas("");
        setCampaign2Bidding(value);
      } else {
        setCampaign3TargetRoas("");
        setCampaign3Bidding(value);
      }
    }
    if (value === "Conversion value") {
      if (campType === PMaxCampaignName.campaign1) {
        setCampaign1TargetCpa("");
        setCampaign1Bidding(value);
      } else if (campType === PMaxCampaignName.campaign2) {
        setCampaign2TargetCpa("");
        setCampaign2Bidding(value);
      } else {
        setCampaign3TargetCpa("");
        setCampaign3Bidding(value);
      }
    }
  };
  const showViewAssetGroupHandler = (campType) => {
    const tempCampData = {};
    switch (campType) {
      case PMaxCampaignName.campaign1:
        tempCampData.name = campaign1CampName;
        tempCampData.objective = campaign1Objective;
        tempCampData.locations = campaign1Locations;
        tempCampData.languages = campaign1Languages;
        tempCampData.bidding = campaign1Bidding;
        tempCampData.targetRoas = campaign1TargetRoas;
        tempCampData.targetCpa = campaign1TargetCpa;
        tempCampData.budget = campaign1Budget;
        tempCampData.conversionGoals = campaign1ConversionGoals;
        tempCampData.campaignNumber = campType;
        tempCampData.id = campaign1PMaxCampaign?.id;
        tempCampData.assetCount = campaign1PMaxCampaign?.assetCount;
        break;
      case PMaxCampaignName.campaign2:
        tempCampData.name = campaign2CampName;
        tempCampData.objective = campaign2Objective;
        tempCampData.locations = campaign2Locations;
        tempCampData.languages = campaign2Languages;
        tempCampData.bidding = campaign2Bidding;
        tempCampData.targetRoas = campaign2TargetRoas;
        tempCampData.targetCpa = campaign2TargetCpa;
        tempCampData.budget = campaign2Budget;
        tempCampData.conversionGoals = campaign2ConversionGoals;
        tempCampData.campaignNumber = campType;
        tempCampData.id = campaign2PMaxCampaign.id;
        tempCampData.assetCount = campaign2PMaxCampaign?.assetCount;
        break;
      case PMaxCampaignName.campaign3:
        tempCampData.name = campaign3CampName;
        tempCampData.objective = campaign3Objective;
        tempCampData.locations = campaign3Locations;
        tempCampData.languages = campaign3Languages;
        tempCampData.bidding = campaign3Bidding;
        tempCampData.targetRoas = campaign3TargetRoas;
        tempCampData.targetCpa = campaign3TargetCpa;
        tempCampData.budget = campaign3Budget;
        tempCampData.conversionGoals = campaign3ConversionGoals;
        tempCampData.campaignNumber = campType;
        tempCampData.id = campaign3PMaxCampaign.id;
        tempCampData.assetCount = campaign3PMaxCampaign?.assetCount;
        break;
      default:
        break;
    }
    setCampaignDetails(tempCampData);
    setViewAssetGroup(true);
  };

  const confirmAndPublish = (campaignType) => {
    let data = {};
    let campId = "";
    switch (campaignType) {
      case PMaxCampaignName.campaign1: {
        campId = campaign1PMaxCampaign?.id;
        data = {
          ...campaign1PMaxCampaign,
          name: campaign1CampName,
          campaignNumber: PMaxCampaignName.campaign1,
          objective: campaign1Objective,
          locations: campaign1Locations,
          languages: campaign1Languages,
          bidding: campaign1Bidding,
          budget: campaign1Budget,
          conversionGoals: campaign1ConversionGoals,
          targetRoas: campaign1TargetRoas,
          targetCpa: campaign1TargetCpa,
          assets: campaign1AssetGroups,
        };
        break;
      }
      case PMaxCampaignName.campaign2: {
        campId = campaign2PMaxCampaign?.id;
        data = {
          ...campaign2PMaxCampaign,
          name: campaign2CampName,
          campaignNumber: PMaxCampaignName.campaign2,
          objective: campaign2Objective,
          locations: campaign2Locations,
          languages: campaign2Languages,
          bidding: campaign2Bidding,
          budget: campaign2Budget,
          conversionGoals: campaign2ConversionGoals,
          targetRoas: campaign2TargetRoas,
          targetCpa: campaign2TargetCpa,
          assets: campaign2AssetGroups,
        };
        break;
      }
      case PMaxCampaignName.campaign3: {
        campId = campaign3PMaxCampaign?.id;
        data = {
          ...campaign3PMaxCampaign,
          name: campaign3CampName,
          campaignNumber: PMaxCampaignName.campaign3,
          objective: campaign3Objective,
          locations: campaign3Locations,
          languages: campaign3Languages,
          bidding: campaign3Bidding,
          budget: campaign3Budget,
          conversionGoals: campaign3ConversionGoals,
          targetRoas: campaign3TargetRoas,
          targetCpa: campaign3TargetCpa,
          assets: campaign3AssetGroups,
        };
        break;
      }
      default:
        break;
    }

    const callbackFunc = (error, _data) => {
      if (error) return;
      const assetGroupData = _data.assets;
      delete _data.assets;
      if (campaignType === PMaxCampaignName.campaign1) {
        dispatch(
          updateData({
            campaign1: _data,
            campaign1AssetGroups: assetGroupData,
          })
        );
      } else if (campaignType === PMaxCampaignName.campaign1) {
        dispatch(
          updateData({
            campaign2: _data,
            campaign2AssetGroups: assetGroupData,
          })
        );
      } else {
        dispatch(
          updateData({
            campaign3: _data,
            campaign3AssetGroups: assetGroupData,
          })
        );
      }
      dispatch(
        publishSpecificPMaxCampaign({
          campaignId: _data.id,
          callback(error, _data) {
            if (error) {
              if (error instanceof AxiosError) {
                let apiErrorDetail = error.response.data?.detail;
                if (typeof apiErrorDetail === "string") {
                  try {
                    apiErrorDetail = JSON.parse(apiErrorDetail);
                    if(apiErrorDetail?.data?.status === "INVALID_ARGUMENT"){
                      setPublishApiErrors((prev) => ({
                        ...prev,
                        [campaignType]: (
                          <span>
                            Invalid Argument:
                            <br />
                            {apiErrorDetail?.data?.details?.[0]?.errors?.[0]?.message}
                          </span>
                        ),
                      }));
                    }
                  } catch (error) {
                    console.error("Error while parcing API Error", error);
                  }
                }
                if (apiErrorDetail?.data?.status === "PERMISSION_DENIED") {
                  const {
                    customerId,
                    customerName,
                    loginCustomerId,
                    loginCustomerName,
                  } = form.clientGoogleAdsInfo || {};
                  console.log("permission denied", form.clientGoogleAdsInfo);
                  setPublishApiErrors((prev) => ({
                    ...prev,
                    [campaignType]: (
                      <span>
                        It seems you do not have permission to publish campaigns
                        on this account.
                        <br />
                        <b>Account ID</b>: {customerId}
                        <br />
                        <b>Account name</b>: {customerName}
                        <br />
                        <b>MCC ID</b>: {loginCustomerId}
                        <br />
                        <b>MCC name</b>: {loginCustomerName}
                        <br />
                        Please verify access of this account on your Google Ads
                        MCC and try again. If this issue persists, please
                        contact the Pixis Internal Team
                      </span>
                    ),
                  }));
                }
              }
              return;
            }
            setPublishApiErrors((prev) => ({ ...prev, [campaignType]: "" }));
          },
        })
      );
    };

    if (!campId) {
      dispatch(createPmaxCampaign({ data, callback: callbackFunc }));
    } else {
      dispatch(
        updatePmaxCampaign({
          campaignId: campId,
          body: data,
          callback: callbackFunc,
        })
      );
    }
  };

  const onBackClickHandler = () => {
    dispatch(
      goToSpecificCompoClient(
        ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE]
      )
    );
  };

  const isDisabledCampaign = (campaignType) => {
    if(campaignType === PMaxCampaignName.campaign1){
      return isCampaign1Published || isCampaign1Publishing;
    }
    if(campaignType === PMaxCampaignName.campaign2){
      return isCampaign2Published || isCampaign2Publishing;
    }
    if(campaignType === PMaxCampaignName.campaign3){
      return isCampaign3Published || isCampaign3Publishing;
    }
  }

  const hasChangedCampaign = (campType) => (isChange) => {
    if(campType === PMaxCampaignName.campaign1){
      setHasCampaign1CampLevelChange(isChange);
    }
    if(campType === PMaxCampaignName.campaign2){
      setHasCampaign2CampLevelChange(isChange);
    }
    if(campType === PMaxCampaignName.campaign3){
      setHasCampaign3CampLevelChange(isChange);
    }
  }

  return viewAssetGroup ? (
    <PMaxCampaignAssetGroup
      campaignDetails={campaignDetails}
      campaignNumber={campaignDetails.campaignNumber}
      setViewAssetGroup={setViewAssetGroup}
      isDisabled={isDisabledCampaign(campaignDetails.campaignNumber)}
      hasChangedCampaign={hasChangedCampaign(campaignDetails.campaignNumber)}
    />
  ) : (
    <PMaxCampaignCreation
      //campaign 1
      campaign1CampName={campaign1CampName}
      setCampaign1CampName={setCampaign1CampName}
      campaign1Objective={campaign1Objective}
      setCampaign1Objective={setCampaign1Objective}
      campaign1Locations={campaign1Locations}
      setCampaign1Locations={setLocationsHandler}
      campaign1ConversionGoals={campaign1ConversionGoals}
      setCampaign1ConversionGoals={setCampaign1ConversionGoals}
      campaign1Budget={campaign1Budget}
      setCampaign1Budget={setCampaign1Budget}
      campaign1Languages={campaign1Languages}
      setCampaign1Languages={setLanguageHandler}
      campaign1Bidding={campaign1Bidding}
      campaign1TargetRoas={campaign1TargetRoas}
      setCampaign1TargetRoas={setCampaign1TargetRoas}
      campaign1TargetCpa={campaign1TargetCpa}
      setCampaign1TargetCpa={setCampaign1TargetCpa}
      isCampaign1StrategyCreated={isCampaign1StrategyCreated}
      setHasCampaign1CampLevelChange={setHasCampaign1CampLevelChange}
      campaign1Indicator={campaign1PMaxCampaign?.status === BACKEND_STATUS.COMPLETED && !hasCampaign1CampLevelChange}
      canCampaign1Publish={campaign1PMaxCampaign?.status === BACKEND_STATUS.COMPLETED}
      isCampaign1Published={isCampaign1Published}
      isCampaign1Publishing={isCampaign1Publishing}
      campaign1PublishError={campaign1PublishError}
      campaign1campaignId={campaignObj[PMaxCampaignName.campaign1]}
      //campaign 2
      campaign2CampName={campaign2CampName}
      setCampaign2CampName={setCampaign2CampName}
      campaign2Objective={campaign2Objective}
      setCampaign2Objective={setCampaign2Objective}
      campaign2Locations={campaign2Locations}
      setCampaign2Locations={setLocationsHandler}
      campaign2ConversionGoals={campaign2ConversionGoals}
      setCampaign2ConversionGoals={setCampaign2ConversionGoals}
      campaign2Budget={campaign2Budget}
      setCampaign2Budget={setCampaign2Budget}
      campaign2Languages={campaign2Languages}
      setCampaign2Languages={setLanguageHandler}
      campaign2Bidding={campaign2Bidding}
      campaign2TargetRoas={campaign2TargetRoas}
      setCampaign2TargetRoas={setCampaign2TargetRoas}
      campaign2TargetCpa={campaign2TargetCpa}
      setCampaign2TargetCpa={setCampaign2TargetCpa}
      isCampaign2StrategyCreated={isCampaign2StrategyCreated}
      setHasCampaign2CampLevelChange={setHasCampaign2CampLevelChange}
      campaign2Indicator={campaign2PMaxCampaign?.status === BACKEND_STATUS.COMPLETED && !hasCampaign2CampLevelChange}
      canCampaign2Publish={campaign2PMaxCampaign?.status === BACKEND_STATUS.COMPLETED}
      isCampaign2Published={isCampaign2Published}
      isCampaign2Publishing={isCampaign2Publishing}
      campaign2PublishError={campaign2PublishError}
      campaign2campaignId={campaignObj[PMaxCampaignName.campaign2]}
      //campaign 3
      campaign3CampName={campaign3CampName}
      setCampaign3CampName={setCampaign3CampName}
      campaign3Objective={campaign3Objective}
      setCampaign3Objective={setCampaign3Objective}
      campaign3Locations={campaign3Locations}
      setCampaign3Locations={setLocationsHandler}
      campaign3ConversionGoals={campaign3ConversionGoals}
      setCampaign3ConversionGoals={setCampaign3ConversionGoals}
      campaign3Budget={campaign3Budget}
      setCampaign3Budget={setCampaign3Budget}
      campaign3Languages={campaign3Languages}
      setCampaign3Languages={setLanguageHandler}
      campaign3Bidding={campaign3Bidding}
      campaign3TargetRoas={campaign3TargetRoas}
      setCampaign3TargetRoas={setCampaign3TargetRoas}
      campaign3TargetCpa={campaign3TargetCpa}
      setCampaign3TargetCpa={setCampaign3TargetCpa}
      isCampaign3StrategyCreated={isCampaign3StrategyCreated}
      setHasCampaign3CampLevelChange={setHasCampaign3CampLevelChange}
      campaign3Indicator={campaign3PMaxCampaign?.status === BACKEND_STATUS.COMPLETED && !hasCampaign3CampLevelChange}
      canCampaign3Publish={campaign3PMaxCampaign?.status === BACKEND_STATUS.COMPLETED}
      isCampaign3Published={isCampaign3Published}
      isCampaign3Publishing={isCampaign3Publishing}
      campaign3PublishError={campaign3PublishError}
      campaign3campaignId={campaignObj[PMaxCampaignName.campaign3]}

      showViewAssetGroupHandler={showViewAssetGroupHandler}
      setBiddingHandler={setBiddingHandler}
      confirmAndPublish={confirmAndPublish}

      onBackClickHandler={onBackClickHandler}
    />
  );
};

export default PMaxCampaignCreationLD;
