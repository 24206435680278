import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};
const MenuCheckList = (props) => {
  const [selectedOption, setSelectedOption] = useState([]);
  useEffect(() => {
    setSelectedOption(props.selectedOption);
  }, [props]);

  const handleChange = (event) => {
    if(props.setHasChange){
      props.setHasChange(true);
    }
    setSelectedOption(event.target.value);
    props.setSelectedOption(event.target.value);
  };

  return (
    <>
      <Select
        // disabled
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        value={selectedOption}
        renderValue={(selected) =>
          selected?.length ? `${selected.length} Selected` : "Select"
        }
        MenuProps={MenuProps}
        variant="outlined"
        multiple
        onChange={handleChange}
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
        // IconComponent={() => (
        //   <ExpandMore
        //     fontSize="small"
        //     sx={{ position: "absolute", right: 7 }}
        //   />
        // )}
        style={{
          width: 220,
          height: 27,
          background: "#FFFFFF",
          border: "1px solid #CCECFE",
          borderRadius: 4,
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 12,
          outline: "none",
          color: "#0869FB",
        }}
      >
        {props.list.map((name) => (
          <MenuItem key={name} value={name}>
            <FormControlLabel
            disabled={props.disabled}
              value={name}
              control={<Checkbox checked={selectedOption.includes(name)} />}
              label={name}
              checked={name === selectedOption}
            />
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default MenuCheckList;
