import { useQuery } from "@tanstack/react-query";
import { api } from "../api";
import { getLocalStorage } from "../api/localStorage/localStorage";

const locationQueryKey = (locationData, formId) => [
  "location/get",
  formId,
  locationData,
];
export const useGetLocationInForm = (formId, locationData, options = {}) => {
  return useQuery(
    locationQueryKey(locationData, formId),
    async () => {
      const _formId =
        formId ||
        (getLocalStorage("amSelectedFormId")
          ? getLocalStorage("amSelectedFormId")
          : getLocalStorage("formId"));
      const response = await api.get(
        `/g-ads/geo-targets/${_formId}?locationNames=${locationData}`
      );
      return response.data;
    },
    options
  );
};
