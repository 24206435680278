import { Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { useState } from 'react';
import './adlevel.css';
import RenderComponentOnNav from './RenderComponentOnNav';
const AdLevel = (props) => {
  console.log("adlevel", props)
  const [currentNavTab, setCurrentNavTab] = useState('headline');
  const [headlineInput, setheadlineInput] = useState([]);
  const [descriptionInput, setDescriptionInput] = useState([]);
  const onClickDescriptionHandler = (id) => {
    if(descriptionInput.some(el => !el.value)) return;
    setDescriptionInput([
      ...descriptionInput,
      {
        id: id,
        value: '',
      },
    ]);
  };
  const onClickHeadlineHandler = (id) => {
    if(headlineInput.some(el => !el.value)) return;
    setheadlineInput([
      ...headlineInput,
      {
        id: id,
        value: '',
      },
    ]);
  };
  const onDeleteHeadlineHandler = (id) => {
    setheadlineInput([
      ...headlineInput.filter((value) => {
        return value.id !== id;
      }),
    ]);
  };
  const onDeleteDescriptionHandler = (id) => {
    setDescriptionInput([
      ...descriptionInput.filter((value) => {
        return value.id !== id;
      }),
    ]);
  };
  const adLevelTextStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#333333',
  };
  const adLevelTextDescStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 10,
    color: '#827F7F',
  };
  const adLevelNavigationTextStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    padding: '8px 16px',
    background: '#FFFFFF',
    borderBottom: '1px solid #F0F0F0',
    display: 'flex',
    flexDirection: 'column',
    justifycontent: 'center',
    width: '100%',
    textAlign: 'center',
  };
  const setHighlightTextStyline = (navHeading) => {
    return {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 14,
      padding: '8px 16px',
      background: '#FFFFFF',
      borderBottom: `1px solid ${navHeading === currentNavTab ? '#0869FB' : '#F0F0F0'
        }`,
      color: navHeading === currentNavTab ? 'rgba(7,60,202,1)' : 'rgb(0,0,0)',
      display: 'flex',
      flexDirection: 'column',
      justifycontent: 'center',
      width: '100%',
      textAlign: 'center',
      transition: '0.3s ease-in',
    };
  };
  const handleNavigateChange = (navHeading) => {
    setCurrentNavTab(navHeading);
  };
  return (
    <div className='ad-level-container'>
      <div className='ad-level-header'>
        <Typography style={adLevelTextStyling}>Ad level</Typography>
        <Typography style={adLevelTextDescStyling}>
          Select the best headlines for the client strategy, hit cross to remove
          it altogether
        </Typography>
      </div>
      <div className='ad-group-navigation-and-results'>
        <div className='ad-level-navigation'>
          <div
            style={{ width: 'calc(100% / 3)', cursor: 'pointer' }}
            onClick={() => handleNavigateChange('headline')}
          >
            <Typography style={setHighlightTextStyline('headline')}>
              Headline
            </Typography>
          </div>
          <div
            style={{ width: 'calc(100% / 3)', cursor: 'pointer' }}
            onClick={() => handleNavigateChange('description')}
          >
            <Typography style={setHighlightTextStyline('description')}>
              Description
            </Typography>
          </div>
          <div
            style={{ width: 'calc(100% / 3)', cursor: 'pointer' }}
            onClick={() => handleNavigateChange('preview')}
          >
            <Typography style={setHighlightTextStyline('preview')}>
              Preview
            </Typography>
          </div>
        </div>
        <RenderComponentOnNav
          currentNavTab={currentNavTab}
          read={props.read}
          headlines={props.headlines}
          descriptions={props.descriptions}
          headlineInput={headlineInput}
          descriptionInput={descriptionInput}
          website={props.website}
          deleteHeadline={onDeleteHeadlineHandler}
          deleteDescription={onDeleteDescriptionHandler}
          addHeadline={props.addHeadline}
          addDescription={props.addDescription}
          campaignType={props.campaignType}
          adSetName={props.adSetName}
          deleteHead={props.deleteHeadline}
          deleteDesc={props.deleteDescription}
          editHeadline={props.editHeadline}
          editDescription={props.editDescription}
          isDisabled={props.isDisabled}
        />
      </div>
      {['headline', 'description'].includes(currentNavTab) && (
        <div
          className='add-headline-container'
          style={{
            ...(props.isDisabled ? {opacity: 0.6, pointerEvents: "none", userSelect: "none"}: {})
          }}
          onClick={() => {
            currentNavTab === 'headline'
              ? onClickHeadlineHandler(headlineInput.length)
              : onClickDescriptionHandler(descriptionInput.length)
          }}
        >
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 12,
              color: '#0869FB',
            }}
          >
            + Add {currentNavTab === 'headline' ? 'Headline' : 'Description'}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default AdLevel;
