import { useDispatch, useSelector } from "react-redux";
import CommonAuditAccount from "./CommonAuditAccount";
import { useCallback, useEffect, useState } from "react";
import { getAlreadyAuditHdm } from "../../../api/HDMAM/audit/saga";
import {
  BACKEND_STATUS,
  isPlatformSelected,
} from "../../../utils/accountManagerUtil";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";
import { onNextLD } from "../GoToNextCompoButton";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import { useGetClientForms } from "../../../services/clientForms";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";

export default function LDAuditAccount() {
  // debugger;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  const [spends, setSpends] = useState(0);
  const haveInsights = useSelector(
    (store) => store.googleCustomers.haveInsights
  );

  const [CMR, setCMR] = useState(0);
  const { data: form, isLoading } = useGetClientForms(formId);

  useEffect(() => {
    if (form?.auditInfo?.accountAuditInfo?.spend) {
      setSpends(form?.auditInfo?.accountAuditInfo?.spend);
    } else {
      setSpends(
        (
          (form?.onboardingInfo?.platformInfo?.platforms || []).find(
            (platform) => platform.platform === "facebook-ads"
          ) || {}
        ).spends || 0
      );
    }

    if (form?.auditInfo?.accountAuditInfo?.currentMonthlyRevenue) {
      setCMR(form?.auditInfo?.accountAuditInfo?.currentMonthlyRevenue);
    }
  }, [form]);

  const navigation = () => {
    navigate(
      generatePath(
        `${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.campaign}`,
        { formId }
      )
    );
  };
  const onFBAccountIdExists = useCallback(() => {
    return getAlreadyAuditHdm("LD").then((res) => {
      if (res.length !== 0) {
        const account = res.find(
          (account) => account.accountId === form?.fbAdsAccountId
        );
        dispatch({
          type: "GET_AUDIT_DETAILS_HDM",
          payload: account
            ? account.performanceData
            : res[res.length - 1].performanceData,
        });
      }
    });
  }, [dispatch, form?.fbAdsAccountId]);

  const onBack = () => {
    if (isPlatformSelected(form, "google-ads") !== -1) {
      if (haveInsights) {
        navigate(
          generatePath(
            `${ldAmRoutePaths.auditForGoogle.fullbase}/${ldAmRoutePaths.auditForGoogle.sub.reviewPoints}`,
            { formId }
          )
        );
      } else {
        navigate(
          generatePath(`${ldAmRoutePaths.auditForGoogle.fullbase}`, { formId })
        );
      }
    } else {
      navigate(
        generatePath(`${ldAmRoutePaths.readSharedAssets.fullbase}`, { formId })
      );
    }
  };

  const onAuditAccountChange = useCallback(() => {
    navigate(
      generatePath(`${ldAmRoutePaths.auditForFacebook.fullbase}`, { formId })
    );
  }, [navigate]);

  // useEffect(() => {
  //   setSpends(
  //     (
  //       (form?.onboardingInfo?.platformInfo?.platforms || []).find(
  //         (platform) => platform.platform === "facebook-ads"
  //       ) || {}
  //     ).spends || 0
  //   );
  // }, [form]);
  if (isLoading) {
    return <PmaxLoader />;
  }
  return (
    <CommonAuditAccount
      form={form}
      onFBAccountIdExists={onFBAccountIdExists}
      onBack={onBack}
      spends={spends}
      setSpends={setSpends}
      cmr={CMR}
      setCMR={setCMR}
      onAuditAccountChange={onAuditAccountChange}
      /** onNextLD, is a curried function, no need to wrap the call in a callback */
      onNext={() =>
        onNextLD(
          dispatch,
          navigation
        )({
          auditInfo: {
            status: BACKEND_STATUS.COMPLETED,
            accountAuditInfo: {
              currentMonthlyRevenue: CMR,
              spend: spends,
              status: BACKEND_STATUS.COMPLETED,
            },
            currentStep: form?.currentStep ? form?.currentStep : "Proposal",
          },
        })
      }
    />
  );
}
