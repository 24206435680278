import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../api";
import { ADSET_CRON_STATUS } from "../components/FacebookStrategyCreation/utils";
import { BACKEND_STATUS } from "../utils/accountManagerUtil";
import { queryClient } from "../utils/queryClient";

const getListFBAdsCampaignCreationsQueryKey = formId => [`fb-campaign-creation/list/:formId`, formId];
export const useGetListFBAdsCampaignCreations = (formId, options = {}) => {
  return useQuery(
    getListFBAdsCampaignCreationsQueryKey(formId),
    async () => {
      const response = await api.get(`/facebook-ads-campaign/${formId}`);
      return response.data;
    },
    options
  );
};

export const getFBAdsCampaignCreationByIdQueryKey = (formId, campaignId) => [`fb-campaign-creation/list/:formId/:campaignId`, formId, campaignId];
export const useGetFBAdsCampaignCreaionById = ({ formId, campaignId }, options = {}) => {
  return useQuery(
    getFBAdsCampaignCreationByIdQueryKey(formId, campaignId),
    async () => {
      const response = await api.get(`/facebook-ads-campaign/${formId}/${campaignId}`);
      return response.data;
    },
    options
  );
};

export const useDeleteFbAdsCampaignCreationById = (formId, options = {}) => {
  return useMutation(
    async ({ campaignId }) => {
      const response = await api.delete(`/facebook-ads-campaign/${formId}/${campaignId}/adsets`);
      return response.data;
    },
  );
};

export const usePostFbAdsCampaignCreation = (formId, options = {}) => {
  return useMutation(
    async (campaign={}) => {
     const body = {
         ...campaign,
         status: BACKEND_STATUS.ONGOING,
         adsetCount: 0,
         objective: 'OUTCOME_LEADS',
         bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
         abo: 'ON',
         startDate: new Date().toISOString(),
         // adsets: [{
         //     adsetName: "Adset 1",
         //     adsetNumber: 1,
         //     status: BACKEND_STATUS.ONGOING,
         // }]
      }
      const response = await api.post(`/facebook-ads-campaign/${formId}`, body);
      return response.data;
    }, options,
  );
}

export const usePutFbAdsCampaignCreation = (formId, options = {}) => {
  return useMutation({
      mutationFn: async (payload={}) => {
      const { campaignId, status, ...fields } = payload;
      const data = {
          ...fields,
          status: status ? status : BACKEND_STATUS.ONGOING,
      };
      const response = await api.put(`/facebook-ads-campaign/${formId}/${campaignId}`, data);
      return response.data;
    },
    ...options
  })
}

export const useDeleteFbAdsCampaignCreationAdsets = ({ formId, campaignId }, options = {}) => {
  return useMutation({
      mutationFn: async ({ adsetIds = [] }) => {
      // guard clause to avoid deleting the strategy when adsets are empty,
      // as when adsets are empty backend deletes the strategy (common endpoint)
      if (!adsetIds.length) return Promise.reject({ detail: "no adsets to delete as adsetIds is empty" });

      const searchParams = new URLSearchParams();
      for (let id of adsetIds) {
        searchParams.append("adset_ids", id);
      }
      const response = await api.delete(`/facebook-ads-campaign/${formId}/${campaignId}/adsets?${searchParams.toString()}`);
      return response.data;
    },
      ...options
  });
};

export const usePollAdset = ({ adsetId, formId, campaignId }, options = {}) => {
  return useQuery(
    [`facebook-ads-campaign/${formId}/${campaignId}/adset/${adsetId}/poll`],
    async () => {
      const response = await api.get(`/facebook-ads-campaign/${formId}/${campaignId}/adset/${adsetId}`);
      return response.data;
    },
    {
      ...options,
      refetchInterval(data) {
        if (data && [ADSET_CRON_STATUS.RUNNING, ADSET_CRON_STATUS.PENDING].includes(data.cronStatus)) return 30 * 1000;
        return false;
      },
    },
  );
};


// publish campaign fb
export const usePublishFbCampaign = (formId, options={}) => {
    return useMutation(
        async (campaignId) => {
            const response = await api.post(`/facebook-ads-campaigns/${formId}/${campaignId}:generate`);
            return response.data;
        }
    )
}
