import { TARGETING_TYPE } from '../../utils';
import AudienceAdset from './AudienceAdset';
import KeywordAdset from './KeywordAdset';
import LookalikeAdset from './LookalikeAdset';

export default function AdsetList({
  adsets,
  activeAdsetIndex,
  onChangeActiveAdsetIndex,
  openAdsetDeleteModalCurried,
  audiences,
}) {
  const renderAdset = (adset, index) => {
    const props = {
      activeAdsetIndex,
      onChangeActiveAdsetIndex,
      openAdsetDeleteModalCurried,
      audiences,
      index,
      adset,
    };
    switch (adset.targetingType) {
      case TARGETING_TYPE.AUDIENCE:
        return <AudienceAdset key={adset.key} {...props} />;
      case TARGETING_TYPE.INTERESTKEYWORDS:
        return <KeywordAdset key={adset.key} {...props} />;
      case TARGETING_TYPE.LOOKALIKE:
      default: return <LookalikeAdset key={adset.key} {...props} />;
    };
  };

  return <>{adsets.map(renderAdset)}</>
}
