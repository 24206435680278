import { Typography } from "@mui/material";
import "./pmaxcampaigncreation.css";
import { back_arrow as backArrow } from "../../../assets/icons/icon";
import PMaxCampaignType from "./PMaxCampaignType";
import { PMaxCampaignName } from "../../../api/pmaxCampaign/enum";
import { useEffect, useState } from "react";

const shouldDisable = (
  campName,
  objective,
  locations,
  conversionGoals,
  budget,
  languages
) => {
  let disable = false;
  if (
    !campName ||
    !objective ||
    !locations?.length ||
    !conversionGoals?.length ||
    !budget ||
    !languages?.length
  ) {
    disable = true;
  }
  return disable;
};

const PMaxCampaignCreation = ({
  //campaign 1
  campaign1CampName,
  setCampaign1CampName,
  campaign1Objective,
  setCampaign1Objective,
  campaign1Locations,
  setCampaign1Locations,
  campaign1ConversionGoals,
  setCampaign1ConversionGoals,
  campaign1Budget,
  setCampaign1Budget,
  campaign1Languages,
  setCampaign1Languages,
  campaign1Bidding,
  campaign1TargetRoas,
  setCampaign1TargetRoas,
  campaign1TargetCpa,
  setCampaign1TargetCpa,
  isCampaign1StrategyCreated,
  setHasCampaign1CampLevelChange,
  campaign1Indicator,
  canCampaign1Publish,
  isCampaign1Published,
  isCampaign1Publishing,
  campaign1PublishError,
  campaign1campaignId,

  // campaign 2
  campaign2CampName,
  setCampaign2CampName,
  campaign2Objective,
  setCampaign2Objective,
  campaign2Locations,
  setCampaign2Locations,
  campaign2ConversionGoals,
  setCampaign2ConversionGoals,
  campaign2Budget,
  setCampaign2Budget,
  campaign2Languages,
  setCampaign2Languages,
  campaign2Bidding,
  campaign2TargetRoas,
  setCampaign2TargetRoas,
  campaign2TargetCpa,
  setCampaign2TargetCpa,
  isCampaign2StrategyCreated,
  setHasCampaign2CampLevelChange,
  campaign2Indicator,
  canCampaign2Publish,
  isCampaign2Published,
  isCampaign2Publishing,
  campaign2PublishError,
  campaign2campaignId,


  //campaign 3
  campaign3CampName,
  setCampaign3CampName,
  campaign3Objective,
  setCampaign3Objective,
  campaign3Locations,
  setCampaign3Locations,
  campaign3ConversionGoals,
  setCampaign3ConversionGoals,
  campaign3Budget,
  setCampaign3Budget,
  campaign3Languages,
  setCampaign3Languages,
  campaign3Bidding,
  campaign3TargetRoas,
  setCampaign3TargetRoas,
  campaign3TargetCpa,
  setCampaign3TargetCpa,
  isCampaign3StrategyCreated,
  setHasCampaign3CampLevelChange,
  campaign3Indicator,
  canCampaign3Publish,
  isCampaign3Published,
  isCampaign3Publishing,
  campaign3PublishError,
  campaign3campaignId,

  showViewAssetGroupHandler,
  setBiddingHandler,
  confirmAndPublish,
  onBackClickHandler,
}) => {
  const [disableCampaign1ASLevel, setDisableCampaign1ASLevel] = useState(true);
  const [disableCampaign2ASLevel, setDisableCampaign2ASLevel] = useState(true);
  const [disableCampaign3ASLevel, setDisableCampaign3ASLevel] = useState(true);

  useEffect(() => {
    setDisableCampaign1ASLevel(
      shouldDisable(
        campaign1CampName,
        campaign1Objective,
        campaign1Locations,
        campaign1ConversionGoals,
        campaign1Budget,
        campaign1Languages
      )
    );
  }, [
    campaign1CampName,
    campaign1Objective,
    campaign1Locations,
    campaign1ConversionGoals,
    campaign1Budget,
    campaign1Languages,
  ]);
  useEffect(() => {
    setDisableCampaign2ASLevel(
      shouldDisable(
        campaign2CampName,
        campaign2Objective,
        campaign2Locations,
        campaign2ConversionGoals,
        campaign2Budget,
        campaign2Languages
      )
    );
  }, [
    campaign2CampName,
    campaign2Objective,
    campaign2Locations,
    campaign2ConversionGoals,
    campaign2Budget,
    campaign2Languages,
  ]);
  useEffect(() => {
    setDisableCampaign3ASLevel(
      shouldDisable(
        campaign3CampName,
        campaign3Objective,
        campaign3Locations,
        campaign3ConversionGoals,
        campaign3Budget,
        campaign3Languages
      )
    );
  }, [
    campaign3CampName,
    campaign3Objective,
    campaign3Locations,
    campaign3ConversionGoals,
    campaign3Budget,
    campaign3Languages,
  ]);

  return (
    <div className="pmax-campaign-creation-container">
      <div
        style={{
          position: "relative",
          left: 40,
          top: 32,
          cursor: "pointer",
        }}
        onClick={onBackClickHandler}
      >
        {/* <Typography
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 14,
              lineHeight: "16px",
              color: "#0869FB",
            }}
          >
            BACK
          </Typography> */}
        <div className="back-button-container">
          <img src={backArrow} alt="" />
        </div>
      </div>
      <div className="pmax-campaign-creation-header">
        <Typography className="pmax-campaign-creation-header__title">
          Performance MAX campaign CREATION
        </Typography>
        <Typography className="pmax-campaign-creation-header__description">
          Create and customize your campaigns here, before publishing them.
        </Typography>
      </div>
      <PMaxCampaignType
        title="Campaign 1"
        name={campaign1CampName}
        setName={setCampaign1CampName}
        budget={campaign1Budget}
        setBudget={setCampaign1Budget}
        locations={campaign1Locations}
        setLocations={setCampaign1Locations}
        objective={campaign1Objective}
        setObjective={setCampaign1Objective}
        conversionGoals={campaign1ConversionGoals}
        setConversionGoals={setCampaign1ConversionGoals}
        languages={campaign1Languages}
        setLanguages={setCampaign1Languages}
        bidding={campaign1Bidding}
        setBidding={setBiddingHandler(PMaxCampaignName.campaign1)}
        targetRoas={campaign1TargetRoas}
        setTargetRoas={setCampaign1TargetRoas}
        targetCpa={campaign1TargetCpa}
        setTargetCpa={setCampaign1TargetCpa}
        indicator={campaign1Indicator}
        isCampaignComplete={false}
        viewAssetsGroup={showViewAssetGroupHandler}
        isStrategyCreated={isCampaign1StrategyCreated}
        confirmAndPublish={confirmAndPublish}
        disableCampaignASLevel={disableCampaign1ASLevel}
        setHasChange={setHasCampaign1CampLevelChange}
        canPublish={canCampaign1Publish}
        publishedErrors={campaign1PublishError}
        isPublishing={isCampaign1Publishing}
        isPublished={isCampaign1Published}
        campaignId={campaign1campaignId || ""}
      />

      {isCampaign2StrategyCreated && (
        <PMaxCampaignType
          title="Campaign 2"
          name={campaign2CampName}
          setName={setCampaign2CampName}
          budget={campaign2Budget}
          setBudget={setCampaign2Budget}
          locations={campaign2Locations}
          setLocations={setCampaign2Locations}
          objective={campaign2Objective}
          setObjective={setCampaign2Objective}
          conversionGoals={campaign2ConversionGoals}
          setConversionGoals={setCampaign2ConversionGoals}
          languages={campaign2Languages}
          setLanguages={setCampaign2Languages}
          bidding={campaign2Bidding}
          setBidding={setBiddingHandler(PMaxCampaignName.campaign2)}
          targetRoas={campaign2TargetRoas}
          setTargetRoas={setCampaign2TargetRoas}
          targetCpa={campaign2TargetCpa}
          setTargetCpa={setCampaign2TargetCpa}
          indicator={campaign2Indicator}
          isCampaignComplete={false}
          viewAssetsGroup={showViewAssetGroupHandler}
          isStrategyCreated={isCampaign2StrategyCreated}
          confirmAndPublish={confirmAndPublish}
          disableCampaignASLevel={disableCampaign2ASLevel}
          setHasChange={setHasCampaign2CampLevelChange}
          canPublish={canCampaign2Publish}
          publishedErrors={campaign2PublishError}
          isPublishing={isCampaign2Publishing}
          isPublished={isCampaign2Published}
          campaignId={campaign2campaignId || ""}
        />
      )}

      {isCampaign3StrategyCreated && (
        <PMaxCampaignType
          title="Campaign 3"
          name={campaign3CampName}
          setName={setCampaign3CampName}
          budget={campaign3Budget}
          setBudget={setCampaign3Budget}
          locations={campaign3Locations}
          setLocations={setCampaign3Locations}
          objective={campaign3Objective}
          setObjective={setCampaign3Objective}
          conversionGoals={campaign3ConversionGoals}
          setConversionGoals={setCampaign3ConversionGoals}
          languages={campaign3Languages}
          setLanguages={setCampaign3Languages}
          bidding={campaign3Bidding}
          setBidding={setBiddingHandler(PMaxCampaignName.campaign3)}
          targetRoas={campaign3TargetRoas}
          setTargetRoas={setCampaign3TargetRoas}
          targetCpa={campaign3TargetCpa}
          setTargetCpa={setCampaign3TargetCpa}
          indicator={campaign3Indicator}
          isCampaignComplete={false}
          viewAssetsGroup={showViewAssetGroupHandler}
          isStrategyCreated={isCampaign3StrategyCreated}
          confirmAndPublish={confirmAndPublish}
          disableCampaignASLevel={disableCampaign3ASLevel}
          setHasChange={setHasCampaign3CampLevelChange}
          canPublish={canCampaign3Publish}
          publishedErrors={campaign3PublishError}
          isPublishing={isCampaign3Publishing}
          isPublished={isCampaign3Published}
          campaignId={campaign3campaignId || ""}
        />
      )}
    </div>
  );
};
export default PMaxCampaignCreation;
