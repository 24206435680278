import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { ReactComponent as IconDelete } from "../../assets/images/Analytics/Delete2.svg";
import "./CampaignCard.scss";
import DatePickerField, { addDays } from "./DatePickerField";
import { BUDGET_TYPE } from "./constants";
import {
  campaignFormSchema,
  mapResponseToFormValue,
  mapValuesToResponse,
} from "./schema";

const FORM_ID_PREFIX = "CAMPAIGN_CARD";

export default function CampaignCard({
  uid,
  title,
  countsLabel,
  onAdsetClick,
  onDelete,
  onConfirm,
  leftHeaderTags = null,
  tags = null,
  isComplete,
  defaultValues = null,
  displayLifetimeBudget = false,
  groupLevelName = "Ad Group",
  disableConfirm = true,
  onErrors,
}) {
  console.log("in campaing card", defaultValues);
  const FORM_ID = `${FORM_ID_PREFIX}-${uid}`;
  const [todayDate] = useState(() => new Date());

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: mapResponseToFormValue(defaultValues),
    mode: "onSubmit",
    resolver: zodResolver(campaignFormSchema),
  });

  const onSubmit = (values) => {
    onConfirm?.(mapValuesToResponse(values));
    reset({}, { keepValues: true });
  };

  const errorMessage =
    disableConfirm &&
    `Please confirm all ${countsLabel.split(" ").slice(1).join(" ")} first`;
  const confirmButton = (
    <Button
      style={{
        border: "none",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        padding: "0.875em 1.5em",
        borderRadius: "0.375em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        textTransform: "capitalize",
        background: "#0869FB",
      }}
      sx={{
        "&:disabled": {
          opacity: 0.5,
        },
      }}
      className="campaign-card__submit-button"
      form={FORM_ID}
      disabled={disableConfirm}
      type="submit"
    >
      Confirm
    </Button>
  );

  return (
    <article className="campaign-card">
      <div
        className="campaign-card__indicator"
        data-border-indicator={isComplete && !isDirty ? "complete" : "editing"} // complete | editing
      />
      <section className="campaign-card__content">
        <header className="campaign-card__header">
          <div className="campaign-card__header-left">
            <h2 className="campaign-card__header-title">
              {title}
              {countsLabel && (
                <span className="campaign-card__header-adsets-count">
                  {" "}
                  ({countsLabel})
                </span>
              )}
            </h2>
            {leftHeaderTags}
          </div>
          <div className="campaign-card__header-right">
            {tags}
            <button className="campaign-card__delete-button" onClick={onDelete}>
              <IconDelete width="14" height="17" />
            </button>
            <button
              className="campaign-card__adset-level-button"
              onClick={onAdsetClick}
            >
              {groupLevelName} Level
            </button>
          </div>
        </header>
        <form
          id={FORM_ID}
          onSubmit={handleSubmit(onSubmit, onErrors)}
          className="campaign-card__form"
        >
          <div className="campaign-card__form-row">
            <fieldset className="campaign-card__field campaign-card__field--width-50">
              <label className="campaign-card__field-label">Name</label>
              <TextField
                placeholder="Enter Name"
                fullWidth
                error={!!errors.name}
                {...register("name")}
              />
            </fieldset>
            <fieldset className="campaign-card__field campaign-card__field--width-25">
              <label className="campaign-card__field-label">Start Date</label>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePickerField
                    error={!!errors.startDate}
                    {...field}
                    disableDatesBefore={todayDate}
                    onChange={(dateString) => {
                      field.onChange(dateString);
                      setValue("endDate", null, { shouldValidate: false });
                    }}
                  />
                )}
              />
            </fieldset>
            <fieldset className="campaign-card__field campaign-card__field--width-25">
              <label className="campaign-card__field-label">End Date</label>
              <EndDateControllerField control={control} errors={errors} />
            </fieldset>
          </div>
          {!displayLifetimeBudget && (
            <div className="campaign-card__form-row campaign-card__budget-container">
              <input
                type="hidden"
                {...register("budgetType")}
                value={BUDGET_TYPE.DAILY}
              ></input>
              <fieldset className="campaign-card__field campaign-card__field--width-45">
                <label className="campaign-card__field-label">
                  Daily Budget (in $)
                </label>
                <TextField
                  placeholder="Enter Daily Budget (in $)"
                  type="number"
                  error={!!errors.dailyBudget}
                  {...register("dailyBudget")}
                />
              </fieldset>
            </div>
          )}
          {displayLifetimeBudget && (
            <Controller
              name="budgetType"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  className="campaign-card__form-row campaign-card__budget-container"
                  row
                  {...field}
                >
                  <FormControlLabel
                    className="campaign-card__field campaign-card__field--width-45"
                    classes={{ label: "campaign-card__field-radio-label" }}
                    value={BUDGET_TYPE.DAILY}
                    control={<Radio />}
                    label={
                      <fieldset className="campaign-card__field campaign-card__field--width-45">
                        <label className="campaign-card__field-label">
                          Daily Budget (in $)
                        </label>
                        <TextField
                          placeholder="Enter Daily Budget (in $)"
                          type="number"
                          error={!!errors.dailyBudget}
                          fullWidth
                          disabled={field.value !== BUDGET_TYPE.DAILY}
                          {...register("dailyBudget")}
                        />
                      </fieldset>
                    }
                  />
                  <FormControlLabel
                    className="campaign-card__field campaign-card__field--width-45"
                    classes={{ label: "campaign-card__field-radio-label" }}
                    value={BUDGET_TYPE.LIFETIME}
                    control={<Radio />}
                    label={
                      <fieldset className="campaign-card__field campaign-card__field--width-45">
                        <label className="campaign-card__field-label">
                          Lifetime Budget (in $)
                        </label>
                        <TextField
                          placeholder="Enter Lifetime Budget (in $)"
                          type="number"
                          fullWidth
                          disabled={field.value !== BUDGET_TYPE.LIFETIME}
                          error={!!errors.lifetimeBudget}
                          {...register("lifetimeBudget")}
                        />
                      </fieldset>
                    }
                  />
                </RadioGroup>
              )}
            />
          )}
        </form>
        <footer className="campaign-card__footer">
          {errorMessage ? (
            <Tooltip
              PopperProps={{
                sx: {
                  "& .MuiTooltip-arrow": {
                    color: "white",
                  },
                  "& .MuiTooltip-tooltip": {
                    backgroundColor: "white",
                    color: "black",
                    marginBottom: "20px",
                    filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                    padding: "10px 8px 8px 12px",
                  },
                },
              }}
              title={errorMessage}
              arrow
              placement="top-start"
            >
              <span>{confirmButton}</span>
            </Tooltip>
          ) : (
            confirmButton
          )}
        </footer>
      </section>
    </article>
  );
}

/** abstracted field to contol rerenders due to startdate watcher  */
function EndDateControllerField({ control, errors }) {
  const startDate = useWatch({ name: "startDate", control });

  const budgetType = useWatch({ name: "budgetType", control });

  return (
    <Controller
      name="endDate"
      control={control}
      render={({ field }) => (
        <DatePickerField
          error={!!errors.endDate}
          disableDatesBefore={addDays(startDate, 1)}
          disabled={!startDate}
          enabledClearField={budgetType === BUDGET_TYPE.DAILY}
          {...field}
        />
      )}
    />
  );
}
