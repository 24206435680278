import { put, takeLatest, select } from "redux-saga/effects";
import { api } from "../../index";
import {
  createProposalReducer,
  updateProposalData,
  updateSelectedPriceCard,
} from "./slice";
import { updateData as formUpdate } from "../forms/slice";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";

function* createProject(action) {
  const url = `/client-forms`;
  const store = yield select();
  const form = store.form.form;
  const body = { _id: form._id, brandId: form.brandId };
  console.log("in client pricing saga", action.payload);
  const pricing = store.pricing.pricing;
  const sowPricingInfo = { ...form.sowPricingInfo };
  sowPricingInfo["clientPricing"] = {
    status: BACKEND_STATUS.COMPLETED,
    ...pricing[action.payload],
  };
  sowPricingInfo.status = BACKEND_STATUS.COMPLETED;
  // body.template = store.templates.selectedTemplate;
  // body.template = "80445585-f354-49f4-bcab-521df065a6ea";
  body.sowPricingInfo = sowPricingInfo;
  const formResponse = yield api.post(url, body);
  yield put(updateProposalData(true));
  yield put(formUpdate(formResponse.data));
  yield put(updateSelectedPriceCard(action.payload));
}

export default function* pricingSaga() {
  yield takeLatest(createProposalReducer.type, createProject);
}
