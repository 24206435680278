//order of components to render
export const clientDashboardIds = {
  RENDER_HOME_PAGE: "RENDER_HOME_PAGE",
  CLIENTANDACCESS1: "CLIENTANDACCESS1",
  CLIENTANDACCESS2: "CLIENTANDACCESS2",
  CLIENTANDACCESS3: "CLIENTANDACCESS3",
  CLIENTANDACCESS4: "CLIENTANDACCESS4",
  CLIENTANDACCESS5: "CLIENTANDACCESS5",
  CLIENTANDACCESS6: "CLIENTANDACCESS6",
  CLIENTANDACCESS7: "CLIENTANDACCESS7",
  CLIENTANDACCESS8: "CLIENTANDACCESS8",
  CLIENTANDACCESS9: "CLIENTANDACCESS9",
  BRANDORMESSAGING1: "BRANDORMESSAGING1",
  BRANDORMESSAGING2: "BRANDORMESSAGING2",
  BRANDORMESSAGING3: "BRANDORMESSAGING3",
  BRANDORMESSAGING4: "BRANDORMESSAGING4",
  BRANDORMESSAGING5: "BRANDORMESSAGING5",
  BRANDORMESSAGING6: "BRANDORMESSAGING6",
  BRANDORMESSAGING7: "BRANDORMESSAGING7",
  BRANDORMESSAGING8: "BRANDORMESSAGING8",
  BRANDORMESSAGING9: "BRANDORMESSAGING9",
  BRANDORMESSAGING10: "BRANDORMESSAGING10",
  BRANDORMESSAGING11: "BRANDORMESSAGING11",
  BRANDORMESSAGING12: "BRANDORMESSAGING12",
  BRANDORMESSAGING13: "BRANDORMESSAGING13",
  TARGETAUDIENCE1: "TARGETAUDIENCE1",
  TARGETAUDIENCE2: "TARGETAUDIENCE2",
  TARGETAUDIENCE3: "TARGETAUDIENCE3",
  TARGETAUDIENCE4: "TARGETAUDIENCE4",
  TARGETAUDIENCE5: "TARGETAUDIENCE5",
  TARGETAUDIENCE6: "TARGETAUDIENCE6",
  TARGETAUDIENCE7: "TARGETAUDIENCE7",
  GOOGLETARGETING1: "GOOGLETARGETING1",
  GOOGLETARGETING2: "GOOGLETARGETING2",
  GOOGLETARGETING3: "GOOGLETARGETING3",
  GOOGLETARGETING4: "GOOGLETARGETING4",
  GOOGLETARGETING5: "GOOGLETARGETING5",
  GOOGLETARGETING6: "GOOGLETARGETING6",
  GOOGLETARGETING7: "GOOGLETARGETING7",
  ABOUTYOURBUSSINESS1: "ABOUTYOURBUSSINESS1",
  ABOUTYOURBUSSINESS2: "ABOUTYOURBUSSINESS2",
  ABOUTYOURBUSSINESS3: "ABOUTYOURBUSSINESS3",
  ABOUTYOURBUSSINESS4: "ABOUTYOURBUSSINESS4",
  ABOUTYOURBUSSINESS5: "ABOUTYOURBUSSINESS5",
  ABOUTYOURBUSSINESS6: "ABOUTYOURBUSSINESS6",
  ABOUTYOURBUSSINESS7: "ABOUTYOURBUSSINESS7",
  // ABOUTYOURBUSSINESS8: "ABOUTYOURBUSSINESS8",
  // ABOUTYOURBUSSINESS9: "ABOUTYOURBUSSINESS9",
  // ABOUTYOURBUSSINESS10: "ABOUTYOURBUSSINESS10",
  ABOUTYOURBUSSINESS11: "ABOUTYOURBUSSINESS11",
  ABOUTYOURBUSSINESS12: "ABOUTYOURBUSSINESS12",
  ABOUTYOURBUSSINESS13: "ABOUTYOURBUSSINESS13",
  ABOUTYOURBUSSINESS14: "ABOUTYOURBUSSINESS14",
  ABOUTYOURBUSSINESS15: "ABOUTYOURBUSSINESS15",
  ABOUTYOURBUSSINESS16: "ABOUTYOURBUSSINESS16",
  ABOUTYOURBUSSINESS17: "ABOUTYOURBUSSINESS17",
  ABOUTYOURBUSSINESS18: "ABOUTYOURBUSSINESS18",
  ABOUTYOURBUSSINESS19: "ABOUTYOURBUSSINESS19",
  ABOUTYOURBUSSINESS20: "ABOUTYOURBUSSINESS20",
  ABOUTYOURBUSSINESS21: "ABOUTYOURBUSSINESS21",
  ABOUTYOURBUSSINESS22: "ABOUTYOURBUSSINESS22",
  ABOUTYOURBUSSINESS23: "ABOUTYOURBUSSINESS23",
  CREATIVEANDMONTAGEAD1: "CREATIVEANDMONTAGEAD1",
  CREATIVEANDMONTAGEAD2: "CREATIVEANDMONTAGEAD2",
  CREATIVEANDMONTAGEAD3: "CREATIVEANDMONTAGEAD3",
  CREATIVEANDMONTAGEAD4: "CREATIVEANDMONTAGEAD4",
  CREATIVEANDMONTAGEAD5: "CREATIVEANDMONTAGEAD5",
  CREATIVEANDMONTAGEAD6: "CREATIVEANDMONTAGEAD6",
  CREATIVEANDMONTAGEAD7: "CREATIVEANDMONTAGEAD7",
  CREATIVEANDMONTAGEAD8: "CREATIVEANDMONTAGEAD8",
  CREATIVEANDMONTAGEAD9: "CREATIVEANDMONTAGEAD9",
  CREATIVEANDMONTAGEAD10: "CREATIVEANDMONTAGEAD10",
  CREATIVEANDMONTAGEAD11: "CREATIVEANDMONTAGEAD11",
  CREATIVEANDMONTAGEAD12: "CREATIVEANDMONTAGEAD12",
  REVIEW_HDMQUESTIONIER_RESPONSES: "REVIEW_HDMQUESTIONIER_RESPONSES",
  HDM_ACCESS: "HDM_ACCESS",
  HDM_ONBOARDING: "HDM_ONBOARDING",
  HDM_ONBOARDING_REVIEW: "HDM_ONBOARDING_REVIEW",
  HDM_ONBOARDING_COMMUNICATION: "HDM_ONBOARDING_COMMUNICATION",
  HDM_ONBOARDING_SUCCESS: "HDM_ONBOARDING_SUCCESS",
  HDM_STRATEGY_DECK_PAGE: "HDM_STRATEGY_DECK_PAGE",
  HDM_ANAYLSIS_DECK_PAGE: "HDM_ANAYLSIS_DECK_PAGE",
  HDM_STRATEGY_DECK_GOOGLE_PAGE: "HDM_STRATEGY_DECK_GOOGLE_PAGE",
  HDM_STRATEGY_DECK_FACEBOOK_PAGE: "HDM_STRATEGY_DECK_FACEBOOK_PAGE",
  HDM_STRATEGY_DECK_OURPLAN_PAGE: "HDM_STRATEGY_DECK_OURPLAN_PAGE",
  HDM_STRATEGY_DECK_BESTPRACTICE_PAGE: "HDM_STRATEGY_DECK_BESTPRACTICE_PAGE",
  HDM_STRATEGY_DECK_SUGGESTCHANGES_PAGE:
    "HDM_STRATEGY_DECK_SUGGESTCHANGES_PAGE",
  HDM_CAMPAIGN_PREVIEW: "HDM_CAMPAIGN_PREVIEW",
};

export const mappedComponentWithIndex = new Map(
  Object.keys(clientDashboardIds).map((key, index) => [index, key])
);

export const componentWithIndexHDMClient = Object.keys(
  clientDashboardIds
).reduce((obj, value, index) => {
  return { ...obj, [value]: index };
}, {});

console.log('++++', componentWithIndexHDMClient);
