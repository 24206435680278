import { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import adminRoutePaths from "./constants/adminRoutePaths";
import {
  AMColumns,
  clientColumns,
  currentStepsSortingConstantsForLD,
} from "../../utils/AdminUtils/utils";
import { useUserList } from "../../services/userlist";
import PmaxLoader from "../../components/PMaxStrategyCreation/PmaxLoader";
// pages
const ClientList = lazy(() => import("./ClientList"));
const AMList = lazy(() => import("./AMList"));

// layouts
const HeaderLayout = lazy(() => import("./layouts/HeaderLayout"));
//This component is used for redirection to client list
const RedirectToClient = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const querySearchParams = new URLSearchParams(
      window.location.href.split("?")[1]
    );
    if (querySearchParams.get("code")) {
      console.log("Code from router : ", querySearchParams.get("code"));
    }
  }, []);
  useEffect(() => {
    navigate(`${adminRoutePaths.root}/${adminRoutePaths.sub.client}`);
  }, []);

  return null;
};
export default function AdminPageRouter() {
  const { data: userData, isLoading } = useUserList();
  const [newClientColumns, setClientColumns] = useState(clientColumns);
  const [newAMColumns, setAMColumns] = useState(AMColumns);
  // To optimise the call for users list
  const clientRowsData = useMemo(
    () =>
      userData?.users
        .filter((user) => user.role === "brand-viewer")
        .map(
          ({
            brandId,
            sub,
            brandName,
            email,
            createdAt,
            connectedAccounts,
            currentStep,
            lastUpdateDate,
          }) => ({
            brandId,
            sub,
            name: brandName,
            email,
            createdAt,
            lastUpdateDate: lastUpdateDate ? lastUpdateDate : "-",
            currentStep: currentStep ? currentStep : "-",
            googleAccounts: !connectedAccounts.length
              ? []
              : connectedAccounts.filter(
                  ({ platform }) => platform === "GOOGLE"
                ),
            fbAccounts: !connectedAccounts.length
              ? []
              : connectedAccounts.filter(
                  ({ platform }) => platform === "FACEBOOK"
                ),
          })
        ),
    [userData]
  );
  const amRowsData = useMemo(
    () =>
      userData?.users
        .filter((user) => user.role === "agency-viewer")
        .map(({ name, email, createdAt }) => ({
          name: name,
          email: email,
          createdAt: createdAt,
        })),
    [userData]
  );
  if (isLoading) {
    return <PmaxLoader />;
  }
  return (
    <Suspense fallback={<>loading...</>}>
      <Routes>
        {/* header layout will be common for all routes nested within, it wraps suspence within to enable nested lazy loading */}
        <Route element={<HeaderLayout />}>
          <Route index element={<RedirectToClient />} />
          <Route
            path={`${adminRoutePaths.sub.client}`}
            // path={`${adminRoutePaths.root}/${adminRoutePaths.sub.client}`}
            element={
              <ClientList
                rows={clientRowsData || []}
                newClientColumns={newClientColumns}
                setClientColumns={setClientColumns}
              />
            }
          />
          <Route
            path={`${adminRoutePaths.sub.am}`}
            element={
              <AMList
                rows={amRowsData || []}
                newAMColumns={newAMColumns}
                setAMColumns={setAMColumns}
              />
            }
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
