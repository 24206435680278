import { useDispatch, useSelector } from 'react-redux';
import CommonAuditCampaign from './CommonAuditCampaign';
import { useCallback } from 'react';
import { getAlreadyAuditHdm } from '../../../api/HDMAM/audit/saga';
import { goBackHDMAmCompo } from '../../../api/HDMAM/componentTracking/action';
import { onNextHDM } from '../GoToNextCompoButton';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import hdmAmRoutePaths from '../../../pages/hdm-am/constants/hdmAmRoutePaths';

export default function HDMAuditCampaign() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {formId} = useParams();

  const form = useSelector((state) => state.hdmFormAm);

  const navigation = () => {
    navigate(
      generatePath(
        `${hdmAmRoutePaths.auditForFacebook.fullbase}/${hdmAmRoutePaths.auditForFacebook.sub.adset}`,
        { formId }
      )
    );
  }
  const onFBAccountIdExists = useCallback(() => {
    getAlreadyAuditHdm('HDM').then((res) => {
      if (res.length !== 0) {
        const account = res.find(
          (account) => account.accountId === form.fAdsAccountId
        );
        dispatch({
          type: 'GET_AUDIT_DETAILS_HDM',
          payload: account
            ? account.performanceData
            : res[res.length - 1].performanceData,
        });
      }
    });
  }, [dispatch, form.fAdsAccountId]);

  const onBack = () => {
    dispatch(goBackHDMAmCompo());
  };

  return (
    <CommonAuditCampaign
      form={form}
      onFBAccountIdExists={onFBAccountIdExists}
      onBack={onBack}
      /** onNextHDM, is a curried function, no need to wrap the call in a callback */
      onNext={onNextHDM(dispatch, navigation)}
    />
  );
}
