import Button from "@mui/material/Button";
import { useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import{fileUpload as  FUpload} from "../../../assets/icons/icon";
import { uploadFile } from "../../../api/fileHandling/saga";

const StyledDrop = (props) => {
  const onDrop = useCallback((acceptedFiles) => {}, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      <Button
        component="label"
        style={{
          border: "1px solid #eee",
          padding: "10px",
          backgroundColor: "#F8FBFF",
          width: "90%",
          height: "150px",
        }}
      >
        <Dropzone
          onDrop={(acceptedFiles) => {
            acceptedFiles.map((file, index) => {
              let formData = new FormData();
              formData.append("file", file);
              uploadFile(formData).then((res) => {
                props.setFile(res.fileId);
              });
              const reader = new FileReader();
              reader.onload = (e) => {
                props.setObj(e.target.result);
              };
              reader.readAsDataURL(file);
              return file;
            });
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section
              style={{
                border: "2px dashed #E2E6EA",
                borderRadius: 24,
                height: 100,
                width: 550,
                textAlign: "center",
                paddingTop: 33,
              }}
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: 14,
                    textAlign: "center",
                    color: "#242634",
                    opacity: 0.5,
                  }}
                >
                  Click to Browse or drag and drop your file
                </span>
              </div>
            </section>
          )}
        </Dropzone>
      </Button>
    </>
  );
};
export default StyledDrop;
