import { useEffect, useMemo, useState } from 'react';
import { useGetClientForms } from '../../services/clientForms';
import { getFacebookCampaigns, getGooglePmaxCampaigns, getGoogleSearchCampaigns, getPmaxSegments } from './utils';
import { useGetListGAdsCampaigns } from '../../services/google-ads-campaign-creation';
import { useParams } from 'react-router-dom';
import { useGetListFBAdsCampaignCreations } from '../../services/fb-campaign-creation';
import { useGetListPmaxCampaignSegments } from '../../services/pmax-ads-campaigns';
import { getLocalStorage } from '../../api/localStorage/localStorage';

export default function useGeneratedCampaigns () {
  const { formId } = useParams();
  const { data: clientForm = {} } = useGetClientForms(formId);
  const [gSearchCampaigns, setGSearchCampaigns] = useState([]);
  const [gPmaxCampaigns, setGPmaxCampaigns] = useState([]);
  // const [segments, setSegments] = useState([]);
  const { data: googleCampaigns = {}, isLoading: isGoogleLoading } = useGetListGAdsCampaigns(formId || getLocalStorage("formId"));
  const { data: fbCampaigns, isLoading: isFbLoading } = useGetListFBAdsCampaignCreations(formId || getLocalStorage("formId"));
  // const { data: pmaxSegments,  isLoading: isLoadingSegments } = useGetListPmaxCampaignSegments(
  //     formId,
  //     googleStrategyFlow,
  // );


  useEffect(() => {
          if (googleCampaigns?.campaigns?.length) {
              const gSearch = [];
              const gPmax = [];
              googleCampaigns?.campaigns?.forEach(camp => {
                  if (camp?.campaignType === 'PMAX')
                     gPmax.push(camp);
                  else
                      gSearch.push(camp);
              })
              setGSearchCampaigns(gSearch);
              setGPmaxCampaigns(gPmax);
          }
  }, [JSON.stringify(googleCampaigns)]);

  // useEffect(() => {
  //     if (isLoadingSegments) {
  //         if (pmaxSegments?.segments?.length) {
  //            setSegments(
  //              pmaxSegments?.segments?.sort((a, b) => {
  //                const titleA = a.title.toUpperCase();
  //                const titleB = b.title.toUpperCase();
  //                if (titleA < titleB) return -1;
  //                if (titleA > titleB) return 1;
  //                return 0;
  //              })
  //            );
  //         }
  //     }
  // }, [isLoadingSegments]);
  console.log('google camps:', googleCampaigns);

  const googleSearchCampaigns = useMemo(() => {
    return getGoogleSearchCampaigns(gSearchCampaigns);
  }, [gSearchCampaigns]);

  const googlePmaxCampaigns = useMemo(() => {
    return getGooglePmaxCampaigns(gPmaxCampaigns);
  }, [gPmaxCampaigns]);

  const facebookCampaigns = useMemo(() => {
    return getFacebookCampaigns(fbCampaigns?.campaigns);
  }, [fbCampaigns?.campaigns]);

  // const googlePmaxSegments = useMemo(() => {
  //   return getPmaxSegments(segments);
  // }, [pmaxSegments?.segments]);

  return {
    googleSearchCampaigns,
    googlePmaxCampaigns,
    facebookCampaigns,
    // googlePmaxSegments,
    clientForm,
    isGoogleLoading,
    isFbLoading
  };
}
