import React from "react";
import { UnitDataFormatter, GetColor } from "./ChartUtils";
import { ReactComponent as Group } from "../../../assets/images/Analytics/Group.svg"
import { Grid } from "@mui/material";
import {displayCurrency,dataBlendDisplayCurrency} from "./ChartUtils";

export default function KPIChart({ data, chartStyle,type }) {
  const dataTypeToShowCurrency=displayCurrency(type);
  return (
    <Grid container style={{
      display: "flex",
      width: "100%",
      height: '100%',
      justifyContent: "center",
    }}>
      {data.leftMetrics.map((metric, index) => {
        return <div
          key={index}
          style={{
            paddingTop: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: chartStyle.legendStyle.fontStyle,
            fontSize: chartStyle.legendStyle.fontSize
          }}
        >
           <Group width='50%' height="100%" fill={GetColor(0, chartStyle.palette)}/>
          <div
            style={{
              borderLeft: "1px solid #EFEFEF",
              marginLeft: "20px",
              paddingLeft: "20px",
              width: "100%",
              textAlign: "left",
            }}
          >
            <h3 style={{
              padding: "0",
              margin: "0",
            }} className="bold">{metric.name} {metric.currency && dataTypeToShowCurrency === metric.unit.toUpperCase() ? `(${metric.currency})` : dataBlendDisplayCurrency(metric.name,metric.unit,metric.currency)}</h3>
            <h1 style={{
              padding: "0",
              margin: "0",

            }} className="bold">{UnitDataFormatter(data.data[0][metric.name], metric.unit, metric.currency)}</h1>
          </div>
        </div>
      })}
    </Grid>
  );
}
