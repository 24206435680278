import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useGetClientForms } from '../../services/clientForms';
import hdmAmRoutePaths from '../../pages/hdm-am/constants/hdmAmRoutePaths';
import ldAmRoutePaths from '../../pages/ld-am/constants/ldAmRoutePaths';
import bpAmRoutePaths from '../../pages/(bp-am)/constants/bpAmRoutePaths';

const NULL_CLIENT_FORMS = {};

export default function useGoToFacebookStrategy({
  formId,
  flowSelectionFullpath,
  strategiesListFullpath,
}) {
  const navigate = useNavigate();
  const { data: clientForms = NULL_CLIENT_FORMS } = useGetClientForms(formId);
  const { fbStrategyFlow } = clientForms;

  const fbStrategyPath = useMemo(() => {
    if (!fbStrategyFlow) return generatePath(flowSelectionFullpath, { formId });
    return generatePath(strategiesListFullpath, {
      formId,
      strategyFlow: fbStrategyFlow,
    });
  }, [fbStrategyFlow, flowSelectionFullpath, formId, strategiesListFullpath]);

  const goToFBStrategy = useCallback(() => navigate(fbStrategyPath), [fbStrategyPath, navigate])

  return { goToFBStrategy, fbStrategyPath };
}

export function useHDMGoToFacebookStrategy({ formId }) {
  return useGoToFacebookStrategy({
    formId,
    flowSelectionFullpath: hdmAmRoutePaths.strategyFacebook.fullbase,
    strategiesListFullpath: `${hdmAmRoutePaths.strategyFacebook.fullbase}/${hdmAmRoutePaths.strategyFacebook.sub.strategiesList}`
  });
}

export function useLDGoToFacebookStrategy({ formId }) {
  return useGoToFacebookStrategy({
    formId,
    flowSelectionFullpath: ldAmRoutePaths.strategyFacebook.fullbase,
    strategiesListFullpath: `${ldAmRoutePaths.strategyFacebook.fullbase}/${ldAmRoutePaths.strategyFacebook.sub.strategiesList}`
  });
}

export function useBPGoToFacebookStrategy({ formId }) {
  return useGoToFacebookStrategy({
    formId,
    flowSelectionFullpath: bpAmRoutePaths.strategyFacebook.fullbase,
    strategiesListFullpath: `${bpAmRoutePaths.strategyFacebook.fullbase}/${bpAmRoutePaths.strategyFacebook.sub.strategiesList}`
  });
}