import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import usimage from "../../../assets/images/usimage.jpeg";
import ciscoimage from "../../../assets/images/ciscoimage.jpeg";
import wirelessimage from "../../../assets/images/wirelessimage.jpeg";
import award1 from "../../../assets/images/award1.png";
import award2 from "../../../assets/images/award2.png";
import award3 from "../../../assets/images/award3.png";
import award4 from "../../../assets/images/award4.png";
import award5 from "../../../assets/images/award5.png";
import award6 from "../../../assets/images/award6.png";
import croTimeline from "../../../assets/images/cro-timeline.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import croScopeImage from "../../../assets/images/cro-scope.png";
import adDesignImage from "../../../assets/images/ad-design.png";
import customiseReportImage from "../../../assets/images/customise-report.png";
import { getImageUrl } from "../../../api/fileHandling/saga";
import googleScope from "../../../assets/images/google-scope.png";
import facebookScopeImage from "../../../assets/images/facebook-scope.png";
import mappedIndustry from "./mappedIndutrsy";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ready as readyGif } from "../../../assets/icons/proposal/proposal";
import { useSelector, useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { proposalApprovedApi } from "../../../api/client/saga";
import Modal from "@mui/material/Modal";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";
import { PDFDownloadLink } from "@react-pdf/renderer";
import GeneratePdf from "../../Common/GeneratePdf";
import GoToSpecificCompoButton from "../../ButtonPrimary/GoToSpecificCompoButton";
import { getInsights } from "../../../api/client/saga";
import moment from "moment";
import LDGeneratePdf from "../../Common/LDGeneratePdf";
import NewOverview from "./NewOverview";
import { getTimeframeData } from "../../../utils/LDProposalUtils";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldClientRoutePaths from "../../../pages/ld-client/constants/ldClientRoutePaths";
import { queryClient } from "../../../utils/queryClient";
import {
  getClientFormQueryKey,
  useGetClientForms,
} from "../../../services/clientForms";
import { getClientDetailsByFormId } from "../../../api/client/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 584,
  bgcolor: "background.paper",
  borderRadius: "20px",
  padding: "46px 47px 42px",
  boxShadow: 24,
};
const Overview = () => {
  const navigate = useNavigate();
  const {formId} = useParams();
  const [links, setLinks] = useState({
    companyLogo: null,
    signature: null,
  });
  const { data: reduxState } = useGetClientForms();
  const [clientIntroData, setClientIntroData] = useState({});
  const [modal, setModal] = useState(false);
  const [iAggreeSelected, setAggreSelected] = useState(false);
  const [insights, setInsights] = useState(null);
  const [listofAdsSelected, setListsOfAdsSelected] = useState("");
  const [timeframeData, setTimeFrameData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    setClientIntroData(reduxState);
  }, [reduxState]);
  useEffect(() => {
    if (
      clientIntroData?.companyInfo?.companyLogo ||
      clientIntroData?.companyInfo?.signature
    ) {
      Promise.allSettled([
        getImageUrl(clientIntroData.companyInfo.companyLogo),
        getImageUrl(clientIntroData.companyInfo.signature),
      ]).then((res) => {
        setLinks({
          companyLogo:
            res[0].status === "fulfilled" && URL.createObjectURL(res[0].value),
          signature:
            res[1].status === "fulfilled" && URL.createObjectURL(res[1]?.value),
        });
      });
    }
  }, [clientIntroData]);
  useEffect(() => {
    setTimeFrameData(
      getTimeframeData(reduxState?.sowPricingInfo?.clientPricing)
    );
    setClientIntroData(reduxState);
  }, [reduxState]);
  const approvedProposal = () => {
    proposalApprovedApi(reduxState?.proposalIterations).then((res) => {
      queryClient.invalidateQueries({ queryKey: getClientFormQueryKey({formId}) });
      dispatch(getClientDetailsByFormId());
      setModal(!modal);
    });
  };
  useEffect(() => {
    getInsights().then((res) => {
      setInsights(res);
    });
  }, []);
  useEffect(() => {
    let str = "";
    if (reduxState?.companyInfo?.googleAdsManaged) {
      str += "Google Ads + ";
    }
    if (reduxState?.companyInfo?.fbAdsManaged) {
      str += "Facebook Ads + ";
    }
    if (reduxState?.companyInfo?.implementCro) {
      str += "CRO";
    }
    setListsOfAdsSelected(str);
  }, []);
  const publishedDate = (date) => moment(date).format("YYYY-MM-DD");
  const proposalState =
    reduxState?.proposalIterations && reduxState.proposalIterations.length !== 0
      ? reduxState["proposalIterations"][
          reduxState.proposalIterations.length - 1
        ]["state"]
      : null;
  return (
    <>
      <div>
        <div>
          <Typography
            component="div"
            variant="p"
            style={{
              fontSize: "12px",
              textAlign: "center",
              color: "#F99F35",
              marginTop: "10px",
            }}
          >
            {proposalState === "CHANGES_SUGGESTED" &&
              `You are viewing the Proposal copy generated on ${publishedDate(
                reduxState.createdOn
              )}. Your suggested  changes have been registered and you will be notified soon`}
          </Typography>
        </div>
        <NewOverview proposal={clientIntroData} timeframeData={timeframeData} />
        <div className="preview_footer_row">
          <div
            className="footer_content"
            style={{ justifyContent: "space-between" }}
          >
            <FormControlLabel
              value="accept"
              control={
                <Checkbox onClick={() => setAggreSelected(!iAggreeSelected)} />
              }
              label="I agree with the terms and conditions"
              labelPlacement="right"
              style={{
                visibility:
                  proposalState === "CHANGES_SUGGESTED" ||
                  proposalState === "APPROVED"
                    ? "hidden"
                    : "visible",
              }}
            />
            {proposalState === "CHANGES_SUGGESTED" ||
            proposalState === "APPROVED" ? (
              <PrimaryButton
                title="Home"
                onClick={() => {
                  navigate(
                    generatePath(`${ldClientRoutePaths.homePage.fullbase}`)
                  );
                }}
              ></PrimaryButton>
            ) : (
              <PrimaryButton
                disabled={iAggreeSelected ? false : true}
                title="I Accept"
                onClick={() => approvedProposal()}
              ></PrimaryButton>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={modal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="status_popup_layout">
            <div className="modal_icon">
              <img src={readyGif} />
            </div>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Proposal Accepted!
            </Typography>
            <div className="action_popup">
              <PrimaryButton
                title="PROCEED"
                onClick={() => {
                  navigate(
                    generatePath(`${ldClientRoutePaths.homePage.fullbase}`)
                  );
                }}
              />
            </div>
            <a
              className="close_button"
              onClick={() => setModal(!modal)}
              style={{ cursor: "pointer" }}
            >
              <span></span>
            </a>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default Overview;
