import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  CITY_LOCATION_AUTOSUGGESTION_INPUT,
  LOCATION_ADDED_TO_FORM,
} from "../../../api/client/enum";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetLocationInForm } from "../../../services/getLocation";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
const MyChip = (props) => {
  return (
    <div className="custom_chips">
      <span>{props.label}</span>
      <span className="close_tag" onClick={props.onDelete}></span>
    </div>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const LocationAutoCompleteField = (props) => {
  // debugger;
  // const dispatch = useDispatch();
  const [searchLocation, setSearchLocation] = useState("");
  const { formId } = useParams();
  const inputBox = useRef(null);
  let { data: locationOptions } = useGetLocationInForm(formId, searchLocation);
  // console.log(locationOptions?.geoTargets);
  // let selectedValues = useSelector(
  //   (state) => state.client.intakeInfo.audiences.geoTargets
  // );
  const [selectedValues, setSelectedValues] = useState(props.value);
  // let locationOptions = useSelector( (state) => state.client.intakeInfo.audiences.locationOptions);
  const [selectedTags, setSelectedTags] = useState(selectedValues);
  useEffect(() => {
    setSelectedValues(selectedTags);
    props.setGoogleTargetting((prevState) => {
      // debugger;
      return {
        ...prevState,
        [props.objType]: (selectedTags || []).map(({ resourceName, name }) => ({
          name: name,
          resourceName: resourceName,
          title: name,
        })),
      };
    });
  }, [selectedTags]);
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const handleChange = (value) => {
    setSearchLocation(value);
  };
  const optimizedFn = useCallback(debounce(handleChange), []);
  return (
    <Autocomplete
      multiple
      // freeSolo={true}
      id="checkboxes-tags"
      options={locationOptions ? locationOptions?.geoTargets : []}
      // defaultValue={[]}
      onChange={(event, values) => {
        setSelectedTags(values);
      }}
      value={selectedValues}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <MyChip {...getTagProps({ index })} label={option.name} />
        ));
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option.name, inputValue, {
          insideWords: true,
        });
        const parts = parse(option.name, matches);
        return (
          <li {...props} className="search_location_list">
            <div className="location_list_head">
              {" "}
              <p>Location</p> <p>Reach</p>
            </div>
            <div className="location_list_content">
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {parts?.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? "#0869FB" : "#333333",
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
              <span>{option.reach}</span>
            </div>
          </li>
        );
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <div className="location_question_header">
          <span className="search_icon"></span>
          <TextField
            {...params}
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            autoFocus
            placeholder={props.placeholder}
            variant="standard"
            // onChange={(e) => dispatch({
            //   type: CITY_LOCATION_AUTOSUGGESTION_INPUT,
            //   payload: {
            //       infoType: props.type,
            //       value: e.target.value,
            //   },
            // })
            // }
            onChange={(e) => optimizedFn(e.target.value)}
            onFocus={() => setSearchLocation("")}
          />
        </div>
      )}
      disablePortal={true}
      className="auto_location_search"
    />
  );
};

export default LocationAutoCompleteField;
