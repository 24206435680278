import { Route, Routes, generatePath, useParams } from "react-router-dom";
import { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from "react-redux";
import ldClientRoutePaths from "./constants/ldClientRoutePaths";
import { getClientDetailsByFormId } from "../../api/client/action";

// pages
const HomePage = lazy(() => import("./HomePage"));
const ReadAccess = lazy(() => import("./ReadSharedAssets"));
const CompleteSharedAssets = lazy(() => import("./CompleteSharedAssets"));
const CampaignPreview = lazy(() => import("./CampaignPreview"));
const AnalyticDashboard = lazy(() => import("./AnalyticDashboard"));



// nested routers
const ProposalRouter = lazy(() => import("./Proposal/Router"));
const StrategyDeckRouter = lazy(() => import('./StrategyDeck/Router')); 
const CreativeApproval = lazy(() => import('./CreativeApproval/Router'));
const IntakeFormRouter = lazy(() => import('./IntakeForm/Router'));



// layouts
const SideNavigationStepsLayout = lazy(() => import("./layouts/SideNavigationStepsLayout"));

export default function LDClientRootRouter() {
  // const { formId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientDetailsByFormId())
  }, [])

  return (
    <Suspense fallback={<>loading...</>}>
      <Routes>
        <Route element={<SideNavigationStepsLayout />}>
          {/* <Route path={`${ldAmRoutePaths.onboardingForm.root}/*`} element={<ClientOnboardingInputsRouter />} /> */}
          <Route index element={<HomePage />} />
          <Route path={ldClientRoutePaths.homePage.root} element={<HomePage />} />
          <Route path={ldClientRoutePaths.readSharedAssets.root} element={<ReadAccess />} />
          <Route path={`${ldClientRoutePaths.proposol.root}/*`} element={<ProposalRouter />} />
          <Route path={`${ldClientRoutePaths.intakeForm.root}/*`} element={<IntakeFormRouter />} />
          <Route path={ldClientRoutePaths.completeSharedAssets.root} element={<CompleteSharedAssets />} />
          <Route path={`${ldClientRoutePaths.strategyDeck.root}/*`} element={<StrategyDeckRouter />} />
          <Route path={ldClientRoutePaths.campaignPreview.root} element={<CampaignPreview />} />
          <Route path={`${ldClientRoutePaths.creativeApproval.root}/*`} element={<CreativeApproval />} />
          <Route path={ldClientRoutePaths.analyticsDashboard.root} element={<AnalyticDashboard />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
