import { Typography, Tooltip } from '@mui/material';
import React from 'react';
import Image from '../../../../assets/images/blankimage.svg';

const Ads = ({
  imageUrl,
  imageUrl2,
  ctr,
  cpm,
  cvr,
  cpa,
  primaryText,
  headline,
  description,
  ctaUrl,
  websiteUrl,
}) => {
  const ctrValueStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 15.54,
    lineHeight: '22px',
  };
  const ctrTextStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 8.47,
    lineHeight: '15px',
    color: '#797979',
  };
  const truncateString = (str) => {
    return str?.length > 30 ? str?.substring(0, 29) + '...' : str;
  };
  const truncateString2 = (str) => {
    return str?.length > 80 ? str?.substring(0, 80) + '...' : str;
  };
  return (
    <div className='creative-container' style={{ marginRight: '24px' }}>
      <div
        style={{
          padding: '6px 8px 8px 6px',
          width: '302px',
          borderBottom: '1px solid #F0F0F0',
        }}
      >
        <Tooltip title={primaryText} arrow>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 11,
              lineHeight: '14px',
            }}
          >
            {truncateString2(primaryText)}
          </Typography>
        </Tooltip>
      </div>
      <div className='ads-image-container'>
        <img
          src={imageUrl2.length !== 0 ? process.env.REACT_APP_BACKEND_BASE_URL+"/files/"+imageUrl2 : imageUrl.length !== 0 ? imageUrl : Image}
          alt=''
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>
      <div className='ads-description-container'>
        <div className='ads-description-header'>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 10,
              color: '#65676B',
            }}
          >
            {websiteUrl}
          </Typography>
          <div>
            <Tooltip title={headline} arrow>
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: 12,
                }}
              >
                {truncateString(headline)}
              </Typography>
            </Tooltip>
            <Tooltip title={description} arrow>
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#65676B',
                }}
              >
                {truncateString(description)}
              </Typography>
            </Tooltip>
          </div>
        </div>
        <div className='ads-description-button'>
          <Typography
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 12,
            }}
          >
            Book Now
          </Typography>
        </div>
      </div>
      <div className='creative-spends'>
        <div className='cpa-creative-container'>
          <Typography style={ctrValueStyling}>{cpa.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CPA</Typography>
          <div className='divider'></div>
        </div>
        <div className='ctr-creative-container'>
          <Typography style={ctrValueStyling}>{ctr.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CTR</Typography>
          <div className='divider'></div>
        </div>
        <div className='cpm-creative-container'>
          <Typography style={ctrValueStyling}>{cpm.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CPM</Typography>
          <div
            className='divider'
            style={{
              position: 'absolute',
              left: '3.75rem',
            }}
          ></div>
        </div>
        <div className='cvr-creative-container'>
          <Typography style={ctrValueStyling}>{cvr.toFixed(2)}</Typography>
          <Typography style={ctrTextStyling}>CVR</Typography>
        </div>
      </div>
    </div>
  );
};

export default Ads;
