export const serviceDescValidator = (description) => {
  if (typeof description === 'undefined') {
    return true;
  } else if (description.length === 0) {
    return false;
  } else if (description?.length < 30) {
    return true;
  } else {
    return false;
  }
};
