import { Typography } from "@material-ui/core";
import { styled } from "@mui/material/styles";
export const StyledOtherAccountHeaderText = styled("h3")`
  color: #2e3044;
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 4px;
`;
export const StyledOtherAccountParaText = styled("p")`
  color: #585969;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-right: 94px;
  margin-bottom: 16px;
`;
export const StyledOtherAccountsWrapper = styled("div")`
  margin: 16px 0 0 16px;
`;

export const StyledOtherAccountText = styled("h3")`
  color: #2e3044;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 8px;
`;
export const StyledOtherAccountWrapper = styled("div")`
  border-radius: 8px;
  background: #f9f9f9;
  padding: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
`;
export const StyledAccountTags = styled("div")`
  border-radius: 6px;
  border: 1px solid #eaeaec;
  background: #fff;
  padding: 4px 8px;
  color: #2e3044;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const StyledAccountTagsWrapper = styled("div")`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const StyledAddContainer = styled("div")`
  border-radius: 6px;
  border: 1px solid #ebf3ff;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const StyledCardBody = styled("div")`
  border-radius: 8px;
  background: #f9f9f9;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-right: 16px;
  position: relative;
`;
export const StyledAccountDetails = styled("div")`
  display: flex;
  flex-direction: column;
`;
export const StyledAccountName = styled("h3")`
  color: #2e3044;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
export const StyledAccountId = styled("p")`
  color: #6d6e7c;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
export const StyledMenuWithStatusBody = styled("div")`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 0;
`;
/* 
      Read Access BgColor - #FEF5EB
      Write Access BgColor - #E8F9F6

      Read Access textColor - #F99F35
      Write Access textColor - #1ABFA1
  */

export const StyledStatusLabel = styled("div")(
  ({ bgColor, textColor }) => `
     border-radius: 6px;
     padding: 4px 8px;
     background: ${bgColor};
     color: ${textColor};
     font-family: Inter;
     font-size: 11.5px;
     font-style: normal;
     font-weight: 400;
     line-height: 20px;
  `
);
export const StyledImageContainer = styled("div")`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const StyledConnectedAccountsWrapper = styled("div")`
  min-height: 286px;
  max-height: 286px;
  overflow-y: scroll;
`;
export const StyledFooterContainer = styled("div")`
  box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.04);
  padding: 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const StyledFooterText = styled("p")`
  color: #0869fb;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const StyledRemoveWrapper = styled("div")`
  border-radius: 8px;
  border: 0.5px solid #e5e5e5;
  width: 244px;
  padding: 18px;
  background: #fff;
  box-shadow: 0px 10px 24px 0px rgba(35, 40, 60, 0.05);
  /* position: absolute;
  top: 52px;
  right: 0;
  z-index: 99; */
  cursor: pointer;
`;

export const StyledRemoveText = styled("p")`
  display: flex;
  align-items: center;
  gap: 12px;
  color: #2e3044;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
