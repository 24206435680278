import React from 'react';
import { Typography } from '@mui/material';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import {ready as readyGif} from "../../../assets/icons/proposal/proposal";
import './publishcampaign.css';
const PublishedCampaignPage = (props) => {
  const headingStyle = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    color: '#000000',
    textAlign: 'center',
    lineHeight: '27px',
  };
  const paraStyle = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#6F6C90',
    textAlign: 'center',
    lineHeight: '25.5px',
  };
  return (
    <div className='publish-campaign-container'>
      <div className='loader-image-container'>
        <img src={readyGif} alt='' width={72} height={72} />
      </div>
      <div className='publish-campaign-description-container'>
        <Typography style={headingStyle}>Campaigns Published!!</Typography>
        <Typography style={paraStyle}>
        All your campaigns have been successfully published. Please go to the campaign preview screen to get the details of these campaigns.
        </Typography>
        <PrimaryButton title='Campaign Preview' onClick={() => {props.onClickHandler()}}/>
      </div>
    </div>
  );
};

export default PublishedCampaignPage;
