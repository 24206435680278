import { string } from 'zod';

export const emailValidator = (email) => {
  return string().email().safeParse(email).success;

  // let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  // if (emailRegex.test(email)) {
  // return true;
  // } else {
  // return false;
  // }
};
