import React, { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
  RadioGroup,
} from "@mui/material";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./age-range.scss";
const AgeRangeCheckboxes = ({
  value,
  objKey,
  infoType,
  error,
  errorText,
  outerKey,
}) => {
  const values = [
    "Under 12 years old",
    "12-17 years old",
    "18-24 years old",
    "25-34 years old",
    "35-44 years old",
    "45-54 years old",
    "55-64 years old",
    "65-74 years old",
    "75 and up",
  ];
  console.log(value);
  const dispatch = useDispatch();
  const fetchMinAge = useSelector(
    (state) => state.hdmClient.onboardingInfo.googleTargeting.minAgeRange
  );
  const fetchMaxAge = useSelector(
    (state) => state.hdmClient.onboardingInfo.googleTargeting.maxAgeRange
  );
  return (
    <>
      <div className="pmax-campaign-age-range">
        <FormControl variant="standard">
          <Select
            placeholder="Choose"
            variant="outlined"
            style={{
              width: 104,
              height: 41,
              background: "#FFFFFF",
              border: "0.964432px solid #DDDDDD",
              borderRadius: 7.71,
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 11.5,
              outline: "none",
              boxShadow: "none",
            }}
            value={fetchMinAge}
            onChange={(e) => {
              // setMinAge(e.target.value);
              dispatch({
                type: "GET_VALUE_FROM_AGE_RANGE_MIN_MAX_HDM",
                payload: {
                  infoType: infoType,
                  minKey: "minAgeRange",
                  maxKey: "maxAgeRange",
                  minAge: e.target.value,
                  maxAge: fetchMaxAge,
                  outerKey: outerKey,
                },
              });
            }}
          >
            <MenuItem value="18">18</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="35">35</MenuItem>
            <MenuItem value="45">45</MenuItem>
            <MenuItem value="55">55</MenuItem>
            <MenuItem value="65+">65+</MenuItem>
          </Select>
        </FormControl>
        <div className="separator"></div>
        <FormControl variant="standard">
          <Select
            placeholder="Choose"
            variant="outlined"
            style={{
              width: 104,
              height: 41,
              background: "#FFFFFF",
              border: "0.964432px solid #DDDDDD",
              borderRadius: 7.71,
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 11.5,
              outline: "none",
              boxShadow: "none",
            }}
            value={
              fetchMinAge === "65+"
                ? "65+"
                : fetchMinAge < fetchMaxAge
                ? fetchMaxAge
                : ""
            }
            disabled={fetchMinAge === "65+"}
            onChange={(e) => {
              dispatch({
                type: "GET_VALUE_FROM_AGE_RANGE_MIN_MAX_HDM",
                payload: {
                  infoType: infoType,
                  minKey: "minAgeRange",
                  maxKey: "maxAgeRange",
                  minAge: fetchMinAge,
                  maxAge: e.target.value,
                  outerKey: outerKey,
                },
              });
            }}
          >
            <MenuItem value="24" disabled={fetchMinAge > 24}>
              24
            </MenuItem>
            <MenuItem value="34" disabled={fetchMinAge > 34}>
              34
            </MenuItem>
            <MenuItem value="44" disabled={fetchMinAge > 44}>
              44
            </MenuItem>
            <MenuItem value="54" disabled={fetchMinAge > 54}>
              54
            </MenuItem>
            <MenuItem value="64" disabled={fetchMinAge > 64}>
              64
            </MenuItem>
            <MenuItem value="65+" disabled={fetchMinAge > 65}>
              65+
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {error && <p style={{ color: "red" }}>{errorText}</p>}
    </>
  );
};

export default AgeRangeCheckboxes;
