import { Button, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';
import ReadyIcon from '../../../assets/icons/proposal/ready.gif';
import IconClose from "@material-ui/icons/Close"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 504,
  height: 371,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '3rem 3rem 0 3rem',
  borderRadius: '14px',
  outline: 'none',
};

export default function ConfirmAdsetLevelModal({
  open,
  onClose,
  onGoToCampaignView,
}) {
  const _onGoToCampaignView = () => {
    onGoToCampaignView();
    onClose?.();
  };

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      onClose={onClose}
    >
      <Box sx={style}>
        <IconButton
          onClick={onClose}
          style={{ position: "absolute", top: 10, right: 10 }}
        >
          <IconClose />
        </IconButton>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            gap: '0.5rem',
          }}
        >
          <div>
            <img src={ReadyIcon} alt='' width={170} height={170} />
          </div>
          <div>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 18,
                textAlign: 'center',
                width: '18rem',
                lineHeight: 1.25,
              }}
            >
              All adsets are saved.
            </Typography>
          </div>
          <Button
            style={{
              textDecoration: 'none',
              marginTop: '10px',
              width: '172px',
              height: '40px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              textTransform: 'capitalize',
              background: '#0869FB',
            }}
            onClick={_onGoToCampaignView}
          >
            Go to Campaign View
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

