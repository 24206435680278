import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Slide,
  Typography,
  RadioGroup,
} from "@mui/material";
import { Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./age-range.scss";
const AgeRangeCheckboxes = ({
  value,
  objKey,
  infoType,
  error,
  errorText,
  outerKey,
  setGoogleTargetting,
}) => {
  console.log("Age range :: ", value);
  return (
    <>
      <div className="pmax-campaign-age-range">
        <FormControl variant="standard">
          <Select
            placeholder="Choose"
            variant="outlined"
            style={{
              width: 104,
              height: 41,
              background: "#FFFFFF",
              border: "0.964432px solid #DDDDDD",
              borderRadius: 7.71,
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 11.5,
              outline: "none",
              boxShadow: "none",
            }}
            value={value.minAgeValue ? value.minAgeValue : ""}
            onChange={(e) => {
              setGoogleTargetting((prevState) => {
                return {
                  ...prevState,
                  minAgeRange: e.target.value,
                  maxAgeRange: value.maxAgeValue,
                };
              });
            }}
          >
            <MenuItem value="18">18</MenuItem>
            <MenuItem value="25">25</MenuItem>
            <MenuItem value="35">35</MenuItem>
            <MenuItem value="45">45</MenuItem>
            <MenuItem value="55">55</MenuItem>
            <MenuItem value="65+">65+</MenuItem>
          </Select>
        </FormControl>
        <div className="separator"></div>
        <FormControl variant="standard">
          <Select
            placeholder="Choose"
            variant="outlined"
            style={{
              width: 104,
              height: 41,
              background: "#FFFFFF",
              border: "0.964432px solid #DDDDDD",
              borderRadius: 7.71,
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 11.5,
              outline: "none",
              boxShadow: "none",
            }}
            value={
              value.minAgeValue === "65+"
                ? "65+"
                : value.minAgeValue < value.maxAgeValue
                ? value.maxAgeValue
                : ""
            }
            disabled={value.minAgeValue === "65+"}
            onChange={(e) => {
              setGoogleTargetting((prevState) => {
                return {
                  ...prevState,
                  minAgeRange: value.minAgeValue,
                  maxAgeRange: e.target.value,
                };
              });
            }}
          >
            <MenuItem value="24" disabled={value.minAgeValue > 24}>
              24
            </MenuItem>
            <MenuItem value="34" disabled={value.minAgeValue > 34}>
              34
            </MenuItem>
            <MenuItem value="44" disabled={value.minAgeValue > 44}>
              44
            </MenuItem>
            <MenuItem value="54" disabled={value.minAgeValue > 54}>
              54
            </MenuItem>
            <MenuItem value="64" disabled={value.minAgeValue > 64}>
              64
            </MenuItem>
            <MenuItem value="65+" disabled={value.minAgeValue > 65}>
              65+
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {error && <p style={{ color: "red" }}>{errorText}</p>}
    </>
  );
};

export default AgeRangeCheckboxes;
