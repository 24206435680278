import { Typography } from '@mui/material';
import FbCampaign from './FbCampaign';
import GPCampaign from './GPCampaign';
import GSearchCampaign from './GSearchCampaign';
import './newcampaignpreview.css'
import useGeneratedCampaigns from './useGeneratedCampaigns';
import GPSegments from './GPSegments';
import { useGetClientForms } from '../../services/clientForms';
import { useParams } from 'react-router-dom';
import { getLocalStorage } from '../../api/localStorage/localStorage';
import { isPlatformSelected } from '../../utils/isPlatformSelectedWrapper';
import PmaxLoader from '../PMaxStrategyCreation/PmaxLoader';
const NewCampaignPreview = () => {
    const {facebookCampaigns, googlePmaxCampaigns, googleSearchCampaigns, isGoogleLoading, isFbLoading } = useGeneratedCampaigns();
    const { formId } = useParams();
    const { data: form = {} } = useGetClientForms(formId || getLocalStorage("formId"));

    return(
        <div className='newcampaignpreview-container'>
            <div className='newcampaignpreview-header'>
            <Typography className='newcampaignpreview-header-title'>Campaign Preview</Typography>
            <Typography className='newcampaignpreview-header-description'>Create campaigns here and publish them to be run</Typography>
            </div>
            <div className='campaign-accordian'>
                { isPlatformSelected(form, 'facebook-ads') && <FbCampaign campaigns={facebookCampaigns} /> }
                { isPlatformSelected(form, 'google-ads') && 
                    <>
                       <GSearchCampaign campaigns={googleSearchCampaigns} />
                       <GPCampaign campaigns={googlePmaxCampaigns} />
                    </>
                }
                { /* <GPSegments campaigns={googlePmaxSegments} /> */ }
                <div style={{width: "100%", height: "16px"}} />
            </div>
           { (isGoogleLoading || isFbLoading) && <PmaxLoader/> }
        </div>
    )
}
export default NewCampaignPreview;
