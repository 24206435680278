import { useDispatch, useSelector } from "react-redux";
import CommonAuditAd from "./CommonAuditAd";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import {
  BACKEND_STATUS,
  isPlatformSelectedHDM,
} from "../../../utils/accountManagerUtil";
import { onNextHDM } from "../GoToNextCompoButton";
import { goBackHDMAmCompo } from "../../../api/HDMAM/componentTracking/action";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import hdmAmRoutePaths from "../../../pages/hdm-am/constants/hdmAmRoutePaths";

export default function HDMAuditAd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  const form = useSelector((state) => state.hdmFormAm);
  const onboardingInfo = useSelector((state) => state.hdmClient.onboardingInfo);

  const onClick = () => {
    const data = {};
    if(form?.accountAnalysis?.status !== BACKEND_STATUS.COMPLETED){
      data["accountAnalysis"] = {status : BACKEND_STATUS.ONGOING};
    }
    if(form?.facebookAuditStatus !== BACKEND_STATUS.COMPLETED){
      data["facebookAuditStatus"] = BACKEND_STATUS.COMPLETED
    }
    dispatch(
      updateFormReducer(data)
    );
  };
  const navigation = () => {
    navigate(
      generatePath(`${hdmAmRoutePaths.accountAnalysis.fullbase}`, { formId })
    );
  };

  const nextDisable = () => {
    return (
      isPlatformSelectedHDM(form, "google-ads") !== -1 &&
      form.googleAuditData.status !== BACKEND_STATUS.COMPLETED
    );
  };

  const onBack = () => {
    dispatch(goBackHDMAmCompo());
  };

  return (
    <CommonAuditAd
      onboardingInfo={onboardingInfo}
      onClick={onClick}
      nextDisable={nextDisable}
      /** onNextHDM, is a curried function, no need to wrap the call in a callback */
      onNext={onNextHDM(
      dispatch,
      navigation
      )}
      onBack={onBack}
    />
  );
}
