import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../api";
import { getLocalStorage } from "../api/localStorage/localStorage";

export const getClientFormQueryKey = ({ formId }) => ["client-forms/get", formId];
export const useGetClientForms = (formId, options = {}) => {
  return useQuery(
    getClientFormQueryKey(({ formId })),
    async () => {
      const _formId =
        formId ||
        (getLocalStorage("amSelectedFormId")
          ? getLocalStorage("amSelectedFormId")
          : getLocalStorage("formId"));
      const response = await api.get("/client-forms/" + _formId);
      return response.data;
    },
    options
  );
};

// post client forms
export const usePostClientForms = ({ formId, brandId } = {}, options = {}) => {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  const getFormID = () => {
    return formId ||
      (getLocalStorage("amSelectedFormId")
        ? getLocalStorage("amSelectedFormId")
        : getLocalStorage("formId"));
  };
  return useMutation({
    mutationFn: async (fields = {}) => {
      const _formId = getFormID();

      const { brandId: cachedBrandId } = queryClient.getQueryData(getClientFormQueryKey({ formId: _formId })) || {};
      const _brandId =
        brandId || cachedBrandId ||
        (getLocalStorage("amSelectedBrandId")
          ? getLocalStorage("amSelectedBrandId")
          : getLocalStorage("brandId"));
      const body = {
        ...fields,
        _id: _formId,
        brandId: _brandId,
      };
      const response = await api.post("/client-forms", body);
      return response.data;
    },
    onSuccess: (...args) => {
      const _formId = getFormID();
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: getClientFormQueryKey({ formId: _formId }) });
      onSuccess?.(...args);
    },
    ...restOptions,
  });
};
