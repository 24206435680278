import HDMAccess from '../../HDMClientDashboard/HDMReadAccess/HDMAccess';
import './index.css';
import '../../Common/sharedAssets.css';

const SharedAssets = () => {
  return (
    <HDMAccess
      agencyType="LD"
    />
  );
};
export default SharedAssets;
