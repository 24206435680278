import { Box, Modal, Tooltip } from '@mui/material';
import { truncateString } from './utils';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import { useEffect, useRef, useState } from 'react';
import { uploadFile } from '../../../api/fileHandling/saga';
import { useWatch } from 'react-hook-form';
import { NEW_AD_KEY_NAME } from '.';

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1004,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  padding: '46px 47px 42px',
  boxShadow: 24,
};

export default function EditAdModal({
  open,
  onClose,
  websiteUrl = "",
  editAdModalFieldKey,
  control,
  onUpdateAd,
}) {
  const ad = useWatch({ name: editAdModalFieldKey, control });

  const [editAdsDetails, setEditAdsDetails] = useState(null);
  const hiddenFileInput = useRef(null);

  useEffect(() => {
    if (!!open) {
      setEditAdsDetails(ad);
    }
  }, [ad, open]);

  const handleUploadFile = () => hiddenFileInput.current.click();

  const editSpecificAds = () => {
    onUpdateAd({ name: editAdModalFieldKey, value: editAdsDetails });
    setEditAdsDetails(null);
    onClose?.();
  };

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='large_addad_popup'
      onClose={onClose}
    >
      <Box sx={MODAL_STYLE}>
        <div className='addad_popup_layout'>
          <div className='addad_modal_header'>
            <p>{editAdModalFieldKey === NEW_AD_KEY_NAME ? "Create New Ad" : "Edit Ad"}</p>
            <i
              className='popup_close_button'
              onClick={onClose}
            >
              <span></span>
            </i>
          </div>
          <div className='addad_modal_layout'>
            <div className='addad_modal_sections'>
              <div className='addad_modal_preview'>
                <div className='ad_preview_title'>
                  <p>Ad 1</p>
                </div>
                <div className='adset_slide_head_skip'>
                  <Tooltip title={editAdsDetails?.primaryText} arrow>
                    <p>
                      {truncateString(editAdsDetails?.primaryText)}
                    </p>
                  </Tooltip>
                </div>
                <div className='ad_preview_image'>
                  <img
                    src={
                      process.env.REACT_APP_BACKEND_BASE_URL +
                      '/files/' +
                      editAdsDetails?.imageUrl
                    }
                    alt="ad_preview_image"
                  />
                  <div className='preview_ad_upload'>
                    <label htmlFor='select-image'>
                      <input
                        type='file'
                        ref={hiddenFileInput}
                        id='select-image'
                        style={{ display: 'none' }}
                        accept='image/*'
                        onChange={(e) => {
                          const tempObj = { ...editAdsDetails };
                          const form = new FormData();
                          form.append('file', e.target.files[0]);
                          // setSelectedImage(e.target.files[0]);
                          uploadFile(form).then((res) => {
                            tempObj['imageUrl'] = res.fileId;
                            setEditAdsDetails(tempObj);
                          });
                        }}
                      />
                      <PrimaryButton
                        className='edit_response_button'
                        onClick={handleUploadFile}
                        title='Upload New Image'
                        icon='camera'
                      ></PrimaryButton>
                    </label>
                  </div>
                </div>
                <div className='adset_slide_footer flex-center-space'>
                  <div className='adset_slide_footer_info'>
                    <h2>{websiteUrl}</h2>
                    <p>{editAdsDetails?.headline}</p>
                    <span>{editAdsDetails?.description}</span>
                  </div>
                </div>
              </div>
              <div className='addad_modal_edit_options'>
                <form>
                  <div className='form_row_edit'>
                    <label>Primary Text</label>
                    <textarea
                      className='large_textarea'
                      value={editAdsDetails?.primaryText}
                      onChange={(e) => {
                        const tempObj = { ...editAdsDetails };
                        tempObj['primaryText'] = e.target.value;
                        setEditAdsDetails(tempObj);
                      }}
                    >
                      {editAdsDetails?.primaryText}
                    </textarea>
                  </div>
                  <div className='form_row_edit'>
                    <label>Headline</label>
                    <input
                      type='text'
                      defaultValue={editAdsDetails?.headline}
                      value={editAdsDetails?.headline}
                      onChange={(e) => {
                        const tempObj = { ...editAdsDetails };
                        tempObj['headline'] = e.target.value;
                        setEditAdsDetails(tempObj);
                      }}
                    />
                  </div>
                  <div className='form_row_edit'>
                    <label>Description</label>
                    <textarea
                      onChange={(e) => {
                        const tempObj = { ...editAdsDetails };
                        tempObj['description'] = e.target.value;
                        setEditAdsDetails(tempObj);
                      }}
                      value={editAdsDetails?.description}
                    >
                      {editAdsDetails?.description}
                    </textarea>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='addad_modal_footer'>
            <div className='addad_row_footer'>
              <button
                className='cancle_button_modal'
                onClick={onClose}
                type="button"
              >
                Cancel
              </button>
              <PrimaryButton
                className='edit_response_button'
                title='Save Changes'
                type="button"
                onClick={() => editSpecificAds()}
              ></PrimaryButton>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
