import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { Cookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { logout } from '../api/auth/actions';

const cookies = new Cookies();
export default function usePostLogout() {
  const { logout: logoutAuth0 } = useAuth0();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  
  const logoutFromUser = () => {
    logoutAuth0({ logoutParams: { returnTo: process.env.REACT_APP_BASE_URL } })
      .then((res) => {
        console.log('success', res);
        sessionStorage.clear();
        Object.keys(cookies.getAll()).forEach((cookieName) => {
          cookies.remove(cookieName);
        });
        dispatch(logout());
        queryClient.clear();
      })
      .catch((err) => {
        console.log('error while logout', err.message);
      });
  }

  return {
    logoutFromUser,
  };
}