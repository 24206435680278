import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography
} from "@mui/material";
import { google_icon as Google } from "../../assets/icons/campaignpreview/campaignpreview";
import CampaignCard from "./CampaignCard";
import ExpandIcon from "./ExpandIcon";
import "./newcampaignpreview.css";
import { getChunks } from "./utils";

const GPSegments = ({ campaigns }) => {
  return (
    <div className="google_accordian">
      <Accordion>
        <AccordionSummary
          sx={{
            height: "80px",
            borderBottom: "1px solid #F4F4F4",
            borderRadius: "12px",
          }}
          expandIcon={<ExpandIcon />}
        >
          <img src={Google} alt="" />
          <Typography className="campaign_head">
            Google Performance Segments
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingBottom: "20px" }}>
          {getChunks(campaigns, 2).map((chunks, index) => (
            <div className="brand_comp" key={index}>
              {chunks.map((campaign) => (
                <CampaignCard
                  key={campaign.id}
                  title={campaign.label}
                  campainID={campaign.id}
                  name={campaign.name}
                />
              ))}
            </div>
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default GPSegments;
