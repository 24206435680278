import React, { useEffect, useRef, useState } from 'react';
import PMaxDetailedView from '../PMaxStrategyCreation/PMaxDetailedView';
import PMaxSearchTerms from '../PMaxStrategyCreation/PMaxSearchTerms';
import '../PMaxStrategyCreation/pmax.scss';
import { Button, Typography, TextField, Tooltip, Select, MenuItem } from '@mui/material';
import infoIcon from '../PMaxStrategyCreation/pmaxIcons/info-icon.svg';
import readIcon from '../PMaxStrategyCreation/pmaxIcons/read-icon.svg';
import selectedReadIcon from '../PMaxStrategyCreation/pmaxIcons/selected-read-icon.svg';
import writeIcon from '../PMaxStrategyCreation/pmaxIcons/write-icon.svg';
import selectedWriteIcon from '../PMaxStrategyCreation/pmaxIcons/selected-write-icon.svg';
import CampaignConfirmationModal from '../PMaxStrategyCreation/CampaignConfirmationModal';

import AssetGroupTabListView from '../PMaxStrategyCreation/AssetGroupTabListView';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSegments,
  updateStartLoader,
} from '../../api/pmaxStrategy/slice';
import PmaxLoader from '../PMaxStrategyCreation/PmaxLoader';
import ExpandAssetNameGroupTooltip from '../TooltipIndicators/ExpandAssetNameGroupTooltip';
import ErrorTooltip from '../TooltipIndicators/ErrorTooltip';
import InfoTooltipForBudget from '../TooltipIndicators/InfoTooltipForBudget';
import { api } from '../../api';
import {
  BACKEND_STATUS,
} from '../../utils/accountManagerUtil';
import GoBackConfirmationModal from '../PMaxStrategyCreation/GoBackConfirmationModal';
import {
  getFormByFormIdReducer,
  updateFormReducer,
} from '../../api/accountManager/forms/slice';
import PMaxThemes from '../PMaxStrategyCreation/PMaxThemes';
import BpRadio from '../ClientDashboard/CustomRadio/Radio';
import { useDeletePmaxAssetGroup, useGetPmaxStrategyByStrategyId, usePutPmaxStrategy } from '../../services/googlePmaxStrategy';
import { useGetPmaxResources, useGetPmaxThemes, usePostPmaxResources, usePostPmaxThemes } from '../../services/CAIGoogle/googlePmax';
import { usePostClientForms } from '../../services/clientForms';
import { useGetAdAssets } from '../../services/g-ads';
import { useGetCustomSegments, useGetListGoogleSegments } from '../../services/googleSegments';
import AllConfirmModal from '../PMaxStrategyCreation/AllConfirmModal';
import { useNavigate } from 'react-router-dom';

const PMaxStrategyCreation = ({ 
    strategyId, 
    handleBack, 
    formId,
    isDirty,
    setIsDirty,
    generationType=null
}) => {
  const [showAllConfirmModal, setShowAllConfirmModal] = useState(false);
  const [assets, setAssets] = useState([]);
  const updateAssetGroup = details => {
      const updatedAssets = [...assets];
      updatedAssets[currentAssetGroupIndex] = { ...updatedAssets[currentAssetGroupIndex], ...details };
      setCurrentAssetGroup(prev => ({ ...prev, ...details}));
      setAssets(updatedAssets);
  }
  const [getResourcesFlag, setGetResourcesFlag] = useState(false);
  const [getThemesFlag, setGetThemesFlag] = useState(false);
  const [gotThemes, setGotThemes] = useState(false);
  const [gotResources, setGotResources] = useState(false);
  let { data: segments } = useGetListGoogleSegments(formId, generationType, {
      onSuccess: (data) => {
        data.segments.sort((a, b) => { 
          const titleA = a.title.toUpperCase();
          const titleB = b.title.toUpperCase();
          if (titleA < titleB)
              return -1;
          if (titleA > titleB)
              return 1;
        })
        segments = data;
      }
  });
  const { mutate: mutatePostClientForms, mutateAsync: mutatePostClientFormsAsync } = usePostClientForms();
  const {data: campaign, isFetching: isGetCampaignFetching } = useGetPmaxStrategyByStrategyId(formId, strategyId, {
      onSuccess: (data) => {
          const assets = data.assets.map(asset => ({ ...asset, savedStatus: true }));
          setAssets(assets);
          setCurrentAssetGroup(assets[0]);
          setCurrentAssetGroupIndex(0);
          setAssetCount(data?.assetCount);
      }
  });
  const { data: getThemes, isSuccess: isGetThemesSuccess, isRefetching: isThemesPolling } = useGetPmaxThemes(formId, {
      enabled: getThemesFlag,
      refetchInterval: (data) => {
          if (!data || data.themes === null)
              return 5000;
          else
              return false;
      },
      onSuccess: (data) => {
          if (data.themes !== null) {
              setGetThemesFlag(false);
              setGotThemes(true)
          }
      }
  });
  const { mutate: mutateThemes } = usePostPmaxThemes(formId, {
      onSuccess: () => setGetThemesFlag(true) 
  });
  const { data: resources, isSuccess: isGetResourcesSuccess } = useGetPmaxResources(formId, {
      enabled: getResourcesFlag,
      refetchInterval: (data) => {
        const isResourcesEmpty = data?.resources?.some(
          (resource) => resource.resources === null
        );
        if (!data || isResourcesEmpty)
          return 5000;
        else
          return false;
      },
      onSuccess: (data) => {
        const isResourcesEmpty = data?.resources?.some(
          (resource) => resource.resources === null
        );
        if (!isResourcesEmpty) {
            setGetResourcesFlag(false);
            setGotResources(true);
        }
      }
  });
  const { mutate: mutateResources } = usePostPmaxResources(formId, {
      onSuccess: () => setGetResourcesFlag(true)
  });
  const { mutate: mutatePutStrategy } = usePutPmaxStrategy(formId, {
      onSuccess: (data) => {
          const { assets: receivedAssets } = data;
          let updatedAsset = receivedAssets.find(
            (asset) => asset.assetNumber === currentAssetGroup.assetNumber
          );
          const { errors, ...responseAssets } = updatedAsset;
          updateAssetGroup({ ...responseAssets, errors, savedStatus: true });

          // check if all assets are confirmed
          let tempCurrentAssets = JSON.parse(JSON.stringify(assets));
          tempCurrentAssets[tempCurrentAssets.findIndex(asset =>  asset.assetNumber === currentAssetGroup.assetNumber)] = updatedAsset;
          // const updatedAssets = [...assets];
          // updatedAssets[currentAssetGroupIndex] = { ...updatedAssets[currentAssetGroupIndex], ...responseAssets };
          if (tempCurrentAssets.every(asset => asset.status === BACKEND_STATUS.COMPLETED)) {
              setShowAllConfirmModal(true);
          }
      },
      onError: (data) => {
          const detail = data.response.data.detail;
          updateAssetGroup({ 
              id: detail?.asset_id[0],
              savedStatus: true,
              status: BACKEND_STATUS.ONGOING,
              errors: detail
          });
      }
  });
  const { mutateAsync: mutateDeleteAssetGroupAsync } = useDeletePmaxAssetGroup(formId, strategyId, {});
  const { data: assetLibrary } = useGetAdAssets(formId, {
      onSuccess: (data) => {
          const { assets } = data;
          const imageLibrary = [];
          const logoLibrary = [];
          const videoLibrary = [];
          assets.forEach((asset) => {
            if (asset.type === 'YOUTUBE_VIDEO') videoLibrary.push(asset);
            else if (asset.type === 'IMAGE') imageLibrary.push(asset);
            else logoLibrary.push(asset);
          });
          setImageAssetLibrary(imageLibrary);
          setLogoAssetLibrary(logoLibrary);
          setVideoAssetLibrary(videoLibrary);
      }
  });


  const [loader, setLoader] = useState(false);
  const [currentAssetGroupIndex, setCurrentAssetGroupIndex] = useState(0);
  const [currentAssetGroup, setCurrentAssetGroup] = useState({});
  const [assetCount, setAssetCount] = useState(1);
  const value = currentAssetGroup?.value || 'Headline';
  const [ errors, setErrors ] = useState(currentAssetGroup?.errors || {});
  const [savedStatus, setSavedStatus] = useState(
    currentAssetGroup?.savedStatus || false
  );
  const [status, setStatus] = useState(currentAssetGroup?.status || 'ONGOING');
  const [read, setIsRead] = useState(true);
  const [name, setName] = useState(currentAssetGroup?.name || '');
  const [segmentName, setSegmentName] = useState(currentAssetGroup?.segment?.name || segments?.segments[0]?.title);
  const [segmentType, setSegmentType] = useState(currentAssetGroup?.segment?.type || 'NEW');
  const [segmentId, setSegmentId] = useState(currentAssetGroup?.segment?.id || segments?.segments[0]?.id);
  const [theme, setTheme] = useState(currentAssetGroup?.theme || '');
  const [themes, setThemes] = useState(currentAssetGroup?.themes || []);
  const [headlines, setHeadlines] = useState(currentAssetGroup?.headlines || []);
  const [longHeadlines, setLongHeadlines] = useState(
    currentAssetGroup?.longHeadlines || []
  );
  const [assetNumber, setAssetNumber] = useState(currentAssetGroup?.assetNumber || assetCount);
  const [descriptions, setDescriptions] = useState(
    currentAssetGroup?.descriptions || []
  );
  const [imageUrls, setimageUrls] = useState(currentAssetGroup?.imageUrls || []);
  const [logoUrls, setlogoUrls] = useState(currentAssetGroup?.logoUrls || []);
  const [ytVideosMetadata, setytVideosMetadata] = useState(
    currentAssetGroup?.ytVideosMetadata || []
  );
  const { data: customSegments } = useGetCustomSegments(formId);
  const [imageAssetLibrary, setImageAssetLibrary] = useState([]);
  const [logoAssetLibrary, setLogoAssetLibrary] = useState([]);
  const [videoAssetLibrary, setVideoAssetLibrary] = useState([]);
  const [addedNewTerm, setAddedNewTerm] = useState(false);
  const dispatch = useDispatch();
  let isAssetNameEmpty = Boolean(!name);
  const navigate = useNavigate();
  const [backModal, setBackModal] = useState(false);
  const [goBackFlag, setGoBackFlag] = useState(false);
  const inputRef = useRef(null);
  const [focusValue, setFocusValue] = useState(false);

  const textStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  };
  const segmentHeadingStyle = {
    ...textStyle,
    fontSize: 10,
    fontWeignt: 400,
    height: 29,
    borderTop: '1px solid #F1F1F1',
    borderBottom: '1px solid #F1F1F1',
    paddingLeft: '11.25px',
    paddingTop: 3,
    paddingBottom: 4
  }
  const segmentListStyle = {
      ...textStyle,
      fontSize: 10,
      height: 26,
      paddingLeft: 4,
      paddingTop: 9,
      paddingBottom: 9,
  }


  const goToListScreen = () => {
    handleBack();
  };

   useEffect(() => {
       if (gotThemes) {
           const themeObject = getThemes?.themes?.map(theme => ({
               name: theme,
               status: false
           }));
            updateAssetGroup({
                themes: themeObject
            });
           setGotThemes(false);
       }

   }, [gotThemes]);

  useEffect(() => {
      if (gotResources) {
        let headlines = [];
        let longHeadlines = [];
        let descriptions = [];
        resources?.resources?.forEach((resource) => {
          if (resource.resourceType === 'description')
            descriptions = resource?.resources || [];
          else if (resource.resourceType === 'headline')
            headlines = resource?.resources || [];
          else longHeadlines = resource?.resources || [];
        });
        updateAssetGroup({ 
          headlines:
            headlines.length > 10 ? headlines.slice(0, 10) : headlines,
          longHeadlines:
            longHeadlines.length > 10
              ? longHeadlines.slice(0, 10)
              : longHeadlines,
          descriptions:
            descriptions.length > 10
              ? descriptions.slice(0, 10)
              : descriptions,
        });
        setGotResources(false);
      }
  }, [gotResources]);

  useEffect(() => {
    if (goBackFlag) {
      const isAllAssetsNotSaved = assets.some(
        (asset) => asset?.savedStatus === false
      );

      if (isAllAssetsNotSaved)
        setBackModal(true);
      else {
        goToListScreen();
      }

      setGoBackFlag(false);
      
    }
  }, [goBackFlag]);

  useEffect(() => {
     dispatch(getSegments());
  }, []);

  // console.log('segment ids:', segments.segments.find(({id}) => id === currentAssetGroup?.segment?.id));
  console.log('custom segments:', customSegments);

  useEffect(() => {
    if (assets?.length) {
      setStatus(currentAssetGroup?.status || 'ONGOING');
      setName(currentAssetGroup?.name || '');
      setAssetNumber(currentAssetGroup?.assetNumber || assetCount);
      setErrors(currentAssetGroup?.errors || {});
      setSavedStatus(currentAssetGroup?.savedStatus || false);
      setSegmentId(
          currentAssetGroup?.segment?.id 
          // first segment from 'NEW' segments
          || segments?.segments[0]?.id 
          // first segment from 'EXISTING' segments
          || customSegments?.customSegments?.[0]?.resourceName
      );
      setSegmentName(
          // if 'NEW' segment id already saved in currentAssetGroup
          segments?.segments?.find(({ id }) => id === currentAssetGroup?.segment?.id)?.name || 
          // if 'PREDEFINED' segment id is already saved in currentAssetGroup
          customSegments?.customSegments.find(segment => segment.resourceName === currentAssetGroup?.segment?.id)?.name || 
          // Choose the first segment from the 'NEW' segment created
          segments?.segments[0]?.name || 
          // Choose the first segment from the 'EXISTING' segment created
          customSegments?.customSegments?.[0]?.name
      );
      setSegmentType(
          currentAssetGroup?.segment?.type ||
          segments?.segments[0]?.name  && 'NEW' || 
          customSegments?.customSegments?.[0]?.name && 'PREDEFINED'
      );
      setTheme(currentAssetGroup?.theme || '');
      // setBudget(currCampBudget);
      setThemes(currentAssetGroup?.themes || []);
      setHeadlines(currentAssetGroup?.headlines || []);
      setLongHeadlines(currentAssetGroup?.longHeadlines || []);
      setDescriptions(currentAssetGroup?.descriptions || []);
      setimageUrls(currentAssetGroup?.imageUrls || []);
      setlogoUrls(currentAssetGroup?.logoUrls || []);
      setytVideosMetadata(currentAssetGroup?.ytVideosMetadata || []);
    }
  }, [JSON.stringify(currentAssetGroup)]);

  const changeStatus = () => {
    if (status !== 'ONGOING') {
      setStatus('ONGOING');
    }
  };

  console.log('curr segment name:', currentAssetGroup?.segment?.name);

  const handleSelectTheme = (index) => {
    const newThemes = themes.slice();
    newThemes[index] = {
      ...newThemes[index],
      status: !newThemes[index].status,
    };
    setThemes(newThemes);
    changeStatus();
    setSavedStatus(false);
  };

  const change = (e, index) => {
    switch (value) {
      case 'Headline':
        const newHeadlineList = headlines.slice();
        newHeadlineList[index] = e.target.value;
        setHeadlines(newHeadlineList);
        break;
      case 'Long Headline':
        const newLongHeadlineList = longHeadlines.slice();
        newLongHeadlineList[index] = e.target.value;
        setLongHeadlines(newLongHeadlineList);
        break;
      case 'Description':
        const newDescriptionList = descriptions.slice();
        newDescriptionList[index] = e.target.value;
        setDescriptions(newDescriptionList);
        break;
      case 'Image':
        const newimageUrls = imageUrls.slice();
        newimageUrls[index] = e.target.value;
        setimageUrls(newimageUrls);
        break;
      case 'Logo':
        const newlogoUrls = logoUrls.slice();
        newlogoUrls[index] = e.target.value;
        setlogoUrls(newlogoUrls);
        break;
      default:
        console.log('Wrong value');
    }
    changeStatus();
    setSavedStatus(false);
  };

  const handleEditTheme = e => {
      setTheme(e.target.value);
      changeStatus();
      setSavedStatus(false);
  }

  const remove = (index) => {
    switch (value) {
      case 'Headline':
        const newHeadlineList = headlines.slice();
        newHeadlineList.splice(index, 1);
        setHeadlines(newHeadlineList);
        break;
      case 'Long Headline':
        const newLongHeadlineList = longHeadlines.slice();
        newLongHeadlineList.splice(index, 1);
        setLongHeadlines(newLongHeadlineList);
        break;
      case 'Description':
        const newDescriptionList = descriptions.slice();
        newDescriptionList.splice(index, 1);
        setDescriptions(newDescriptionList);
        break;
      case 'Image':
        const newimageUrls = imageUrls.slice();
        newimageUrls.splice(index, 1);
        setimageUrls(newimageUrls);
        break;
      case 'Logo':
        const newlogoUrls = logoUrls.slice();
        newlogoUrls.splice(index, 1);
        setlogoUrls(newlogoUrls);
        break;
      case 'Videos':
        const newVideoUrls = ytVideosMetadata.slice();
        newVideoUrls.splice(index, 1);
        setytVideosMetadata(newVideoUrls);
        break;
      default:
        console.log('Wrong value');
    }
    changeStatus();
    setSavedStatus(false);
    setAddedNewTerm(false);
    setFocusValue(false);
  };

  console.log('assets:', assets);
  console.log('current asset group index:', currentAssetGroupIndex);
  console.log('current asset group:', currentAssetGroup);

  useEffect(() => {
      if (!assets?.length) return;
      setCurrentAssetGroup(assets[currentAssetGroupIndex]);
  }, [currentAssetGroupIndex]);

  useEffect(() => {
        changeStatus();
        setSavedStatus(false);
      if (focusValue) {
        switch (value) {
          case 'Headline':
            if (headlines[headlines.length - 1] || !headlines.length)
             setHeadlines([...headlines, '']);
            break;
          case 'Long Headline':
            if (longHeadlines[longHeadlines.length - 1] || !longHeadlines.length)
              setLongHeadlines([...longHeadlines, '']);
            break;
          case 'Description':
            if (descriptions[descriptions.length - 1] || !descriptions.length)
              setDescriptions([...descriptions, '']);
            break;
          default:
            console.log('Wrong value');
        }

        setAddedNewTerm(true);
        setFocusValue(false);
      }
  }, [focusValue]);

  const add = () => {
    setAddedNewTerm(false);
    setFocusValue(true);
  };

  useEffect(() => {
      if (addedNewTerm) {
       inputRef.current.focus();
       inputRef.current.scrollIntoView({ behavior: 'smooth' });
      }
  }, [addedNewTerm]);

  const handleAssetGroupName = (e) => {
    changeStatus();
    setSavedStatus(false);
    setName(e.target.value);
  };

  const getDetails = () => {
    setAddedNewTerm(false);
    return {
      status,
      savedStatus,
      assetNumber,
      name,
      value,
      theme,
      themes,
      headlines: headlines.filter(headline => headline !== ""),
      longHeadlines: longHeadlines.filter(longHeadline => longHeadline !== ""),
      descriptions: descriptions.filter(description => description !== ""),
      imageUrls,
      logoUrls,
      ytVideosMetadata,
      segment: { name: segmentName, type: segmentType, id: segmentId },
      errors: { ...errors },
    };
  };

  const handleSetValue = (value) => {
    updateAssetGroup({ ...getDetails(), value });
  };

  console.log('name:', name);
  console.log('isAssetName:', isAssetNameEmpty);
  console.log('errors:', errors.name);

  const handleConfirmAssetGroup = () => {

    if (isAssetNameEmpty) {
        console.log('isAssetEmpty');
        setErrors(prev => ({ ...prev, name: 'Asset name cannot be empty' }));
        return;
    }

      let countRepeat = 0;

      for (const asset of assets)
        if (asset.name && asset.name === name) countRepeat += 1;

      if (countRepeat > 1) {
          setErrors(prev => ({ ...prev, name: 'Duplicate asset names are not allowed' }));
      return;
    }

    const assetDetail = getDetails();
    updateAssetGroup({ ...assetDetail, errors: {} });

    if (currentAssetGroup?.id !== undefined)
      assetDetail.id = currentAssetGroup.id;

    const detail = { 
        strategyId, 
        assets: [{ ...assetDetail, errors: {}, status: BACKEND_STATUS.COMPLETED }], 
        assetCount,
    };
    try{
      mutatePutStrategy(detail);
    } catch(error){

    }
    mutatePostClientForms({
        googlePmaxStrategy: { status: BACKEND_STATUS.ONGOING }
    });

    setIsRead(true);
  };

  const addNewAsset = index => {
      setAssetCount(prev => prev + 1);
      setAssets(prev => [...prev, { value: 'Headline', savedStatus: false, status: 'ONGOING', assetNumber: assetCount + 1, name: `Asset Group ${assetCount + 1}`, segment: {}}]);
      setCurrentAssetGroupIndex(index);
  }

  const handleAddNewAsset = (index) => {
    updateAssetGroup({ ...getDetails() });
    setIsRead(true);
    addNewAsset(index);
  };

  const handleChangeAssetGroup = (index) => {
    if (index !== currentAssetGroupIndex) {
      updateAssetGroup(getDetails());
      setCurrentAssetGroupIndex(index);
    }
  };

  const handleRemoveAssetGroup = async (index, id) => {
    try {
      console.log('assets:', assets);
      console.log('ID:', id, 'index:', index);
      if (id !== undefined)
        await mutateDeleteAssetGroupAsync(id);
      const newAssets = assets.slice();
      newAssets.splice(index, 1);
      if (index > 0 && assets.length === 2) {
          if (currentAssetGroupIndex !== 0)
            setCurrentAssetGroupIndex(prev => prev - 1);
          setAssets(newAssets);
      } else if ( index > 0 && assets.length > 2) {
          if (index === currentAssetGroupIndex) {
             setCurrentAssetGroupIndex(prev => prev - 1);
             setAssets(newAssets);
          } else {
              if (currentAssetGroupIndex > index)
                  setCurrentAssetGroupIndex(prev => prev - 1);
              setAssets(newAssets);
          }
      } else if (index === 0 && assets.length > 1) {
          const currIndex = currentAssetGroupIndex;
          setCurrentAssetGroupIndex(index + 1);
          setAssets(newAssets);
          if (currentAssetGroupIndex !== 0) {
            setCurrentAssetGroupIndex(currIndex - 1);
            setCurrentAssetGroup(newAssets[currIndex - 1]);
          }
          else {
            setCurrentAssetGroupIndex(0);
            setCurrentAssetGroup(newAssets[0]);
          }
      } else if (index === 0 && assets.length === 1) {
          setAssets(prev => prev.splice(0, 1, {}));
      }
    } catch (err) {
        console.error(err);
    }
  };

  console.log();
  const handleGenerateThemes = () => {
    if (!theme) return;
    updateAssetGroup({
      ...getDetails(),
      status: 'ONGOING',
      savedStatus: false,
      errors: {}
    });
    mutateThemes(theme);
  };

  const handleGenerateAdCopies = () => {
    const selectedThemes = [];
    themes.forEach((theme) => {
      if (theme.status === true) selectedThemes.push(theme.name);
    });
    if (!selectedThemes.length || selectedThemes.length > 5) return;
    updateAssetGroup({
      ...getDetails(),
      status: 'ONGOING',
      savedStatus: false,
      errors: {}
    });
    mutateResources(selectedThemes);
  };

  useEffect(() => {
      if (isDirty) {
        updateAssetGroup({ ...getDetails() });
        setGoBackFlag(true);
        setIsDirty(false);
      }
  }, [isDirty]);

  // const handleBackButton = () => {
  //   updateAssetGroup({ ...getDetails() });
  //   setGoBackFlag(true);
  // };

  const handleDiscardButton = () => {
    setBackModal(false);
    goToListScreen();
  };

  const handleSaveButton = async () => {
    setLoader(true);
    
    mutatePutStrategy({ strategyId, assets,  assetCount });
    const data = {
      googlePmaxStrategy: { status: BACKEND_STATUS.ONGOING },
    };
    mutatePostClientFormsAsync(data).then(() => setLoader(false)).finally(() => goToListScreen());
  };

  const isExistingChecked = segment => {
       const resourceArray = segment.resourceName.split("/");
       const id = resourceArray[resourceArray.length - 1];
       if (segmentId === id && segmentType !== 'NEW')
           return true
       else return false;
    }



  return (
    <>
      <AssetGroupTabListView
        handleChangeAssetGroup={handleChangeAssetGroup}
        currCamp={assets}
        assetCount={assetCount}
        currStatus={status}
        currAssetGroup={currentAssetGroupIndex}
        handleAddNewAsset={handleAddNewAsset}
        handleRemoveAssetGroup={handleRemoveAssetGroup}
      />
      <div className='pmax-strategy-creation-container'>
        <div className='pmax-strategy-detail'>
          <Typography style={textStyle}>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 3.5,
              }}
            >
              Asset Group Name{' '}
              {errors?.name && <ErrorTooltip error={errors?.name} />}
            </span>{' '}
            :{' '}
            {read ? (
              <span className='pmax-field-value'>
                {name.length === 0 ? (
                  name
                ) : name.length > 14 ? (
                  <ExpandAssetNameGroupTooltip
                    assetGroupName={name}
                    shortAssetGroupName={name.substring(0, 15) + '...'}
                  />
                ) : (
                  name
                )}
              </span>
            ) : (
              <TextField
                variant='standard'
                value={name}
                placeholder='Asset Group name goes here'
                inputProps={{
                  style: {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,

                    lineHeight: '19px',
                    width: 232,
                    paddingBottom: 2,
                    border: 0,
                  },
                }}
                onChange={(e) => handleAssetGroupName(e)}
              />
            )}
          </Typography>
         <div>
          Segment 
          <Select
              variant='outlined'
              style={{
                width: 356,
                height: 40,
                background: '#FFFFFF',
                border: '1px solid #EAEAEC',
                borderRadius: 6,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 10,
                opacity: 1,
                marginLeft: 8,
                // outline: 'none',
                // boxShadow: 'none',
              }}
              defaultValue=""
              value={segmentName || ""}
              renderValue={(value) => (value !== '' ? value : '')}
              onChange={e => setSegmentName(e.target.value)}
          >
           <div style={segmentHeadingStyle}>New</div>
           {segments?.segments?.map((segment, index) => (
             <MenuItem key={index} style={segmentListStyle}
               value={segment.name}
               sx={{
                 '&.Mui-selected': {
                   backgroundColor: 'transparent !important',
                 },
               }}
               onClick={() => { 
                   setSegmentType('NEW');
                   setSegmentId(segment.id);
                   setSavedStatus(false);
                   changeStatus();
               } }
               >
              <BpRadio 
               height="14px"
               width="14px"
               checked={segmentId === segment.id && segmentType === 'NEW'}
              />
               {segment.name}
             </MenuItem>
           ))}
           <div style={segmentHeadingStyle}>Existing</div>
           {customSegments?.customSegments?.map((segment, index) => (
             <MenuItem key={index} style={segmentListStyle}
               value={segment.name}
               sx={{
                 '&.Mui-selected': {
                   backgroundColor: 'transparent !important',
                 },
               }}
                 onClick={() => { 
                     setSegmentType('PREDEFINED');
                     setSegmentId(segment.resourceName);
                     setSavedStatus(false);
                     changeStatus();
                 } }
               >
              <BpRadio 
               height="14px"
               width="14px"
               checked={segmentId === segment.resourceName && segmentType === 'PREDEFINED'}
              />
               {segment.name}
             </MenuItem>
           ))}
          </Select>
          </div>
          <>
            <div
              className='read-write-icon'
              style={{
                display: 'flex',
                flexDirection: 'row',
                cursor: 'pointer',
                // marginTop: -4,
                // paddingBottom: 12,
              }}
            >
              <div
                className='read-icon'
                style={{
                  width: 40,
                  height: 40,
                  border: read ? '1px solid #0869FB' : '1px solid #CCCCCC',
                  borderRadius: '8px 0px 0px 8px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: read ? 'rgba(8, 105, 251, 0.07)' : '',
                }}
                onClick={() => { setIsRead(true); setAddedNewTerm(false); } }
              >
                <img src={read ? selectedReadIcon : readIcon} alt='' />
              </div>
              <div
                className='write-icon'
                style={{
                  width: 40,
                  height: 40,
                  border: !read ? '1px solid #0869FB' : '1px solid #CCCCCC',
                  borderRadius: '0px 8px 8px 0px',
                  // transform: 'matrix(-1, 0, 0, 1, 0, 0)',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: !read ? 'rgba(8, 105, 251, 0.07)' : '',
                }}
                onClick={() => setIsRead(false)}
              >
                <img src={!read ? selectedWriteIcon : writeIcon} alt='' />
              </div>
            </div>
          </>
        </div>
        <div className='pmax-search-term-and-detailed-view'>
          <PMaxThemes
            theme={theme}
            themes={themes}
            change={handleEditTheme}
            handleSelectTheme={handleSelectTheme}
            handleGenerateThemes={handleGenerateThemes}
            handleGenerateAdCopies={handleGenerateAdCopies}
          />
          <PMaxDetailedView
            read={read}
            // setIsRead={setIsRead}
            addedNewTerm={addedNewTerm}
            inputRef={inputRef}
            value={value}
            handleSetValue={handleSetValue}
            theme={theme}
            themes={themes}
            handleSelectTheme={handleSelectTheme}
            headlines={headlines}
            longHeadlines={longHeadlines}
            descriptions={descriptions}
            setSavedStatus={setSavedStatus}
            changeStatus={changeStatus}
            imageUrls={imageUrls}
            setimageUrls={setimageUrls}
            logoUrls={logoUrls}
            setlogoUrls={setlogoUrls}
            ytVideosMetadata={ytVideosMetadata}
            setytVideosMetadata={setytVideosMetadata}
            change={change}
            add={add}
            remove={remove}
            imageAssetLibrary={imageAssetLibrary}
            logoAssetLibrary={logoAssetLibrary}
            videoAssetLibrary={videoAssetLibrary}
            errors={errors}
            handleGenerateThemes={handleGenerateThemes}
            handleGenerateAdCopies={handleGenerateAdCopies}
          />
        </div>
        <div className='preview_footer_row'>
      { /* <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
       */ }
      { /* <button className='back' onClick={() => handleBackButton()}>
              BACK
            </button>
       */ }

            <div
              className='footer_con'
              style={{ justifyContent: 'end', gap: 16, position: 'relative' }}
            >
              <Button
                disabled={status === 'COMPLETED'}
                style={{
                  width: '172px',
                  height: '40px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                  background: '#FFFFFF',
                  border: '1.5px solid #0869FB',
                  color: '#0869FB',
                  opacity: status === 'COMPLETED' ? 0.4 : 1,
                }}
                onClick={() => handleConfirmAssetGroup()}
              >
                Confirm Asset Group
              </Button>
            </div>
          </div>
        </div>
        {getThemesFlag && <PmaxLoader description='We are generating themes.' />}
        {getResourcesFlag && <PmaxLoader description='We are generating content based on themes selected by you.' /> }
        {(isGetCampaignFetching || loader) && <PmaxLoader /> }
        {showAllConfirmModal && <AllConfirmModal handleClose={() => setShowAllConfirmModal(false)} handleNavigation={goToListScreen}/> }
        {backModal && (
          <GoBackConfirmationModal
            clickHandler={setBackModal}
            discardHandler={handleDiscardButton}
            saveHandler={handleSaveButton}
          />
        )}
      { /* </div> */}
    </>
  );
};

export default PMaxStrategyCreation;
