import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { forwardRef } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

const DEFAULT_VALUE = [];
const DEFAULT_CHANGE_HANDLER = () => {};

const toggleItem = (array, item) => {
  if (array.includes(item)) {
    return array.filter((el) => el !== item);
  }
  return [...new Set([...array, item])];
};

const MenuCheckList = forwardRef(
  (
    {
      value = DEFAULT_VALUE,
      onChange,
      /** @type {Array<{label: string, value: any}>} */
      options,
      disabled,
      placeholder = "Select",
      isEditable=true,
      ...props
    },
    ref
  ) => {
    return (
      <Select
        value={value}
        renderValue={(selected) => {
          return selected.length > 0 ? `${selected.length} Selected` : "Select"
        }}
        placeholder={placeholder}
        MenuProps={MenuProps}
        variant="outlined"
        multiple
        onChange={DEFAULT_CHANGE_HANDLER}
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "& .MuiSelect-select:focus": {
            backgroundColor: "transparent !important",
          },
          "& .Mui-disabled": {
            color: "#0869FB !important",
            "-webkit-text-fill-color": "#0869FB !important",
          }
        }}
        style={{
          width: 130,
          height: 27,
          background: "#FFFFFF",
          border: "1px solid #CCECFE",
          borderRadius: 4,
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 12,
          outline: "none",
          color: "#0869FB",
        }}
        ref={ref}
        classes={disabled ? {select: "Mui-disabled", root: "Mui-disabled"}: {}}
        {...props}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <FormControlLabel
              value={option.value}
              control={<Checkbox checked={value?.includes(option.value)} />}
              label={option.label}
              checked={value?.includes(option.value)}
              onClick={() =>
                (isEditable && !disabled) && onChange(toggleItem(value, option.value))
              }
              name={props.name}
              disabled={option.disabled}
            />
          </MenuItem>
        ))}
      </Select>
    );
  }
);

export default MenuCheckList;
