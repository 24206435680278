import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { Tabs, useTabState, Panel } from "../../CustomTab/customTab";

import NavigationSteps from "../NavigationSteps";
import { facebook_icon as facebookIcon } from "../../../assets/icons/social/social";
import googleIcon from "../../../assets/icons/social/google_icon.png";

import Avatar from "@mui/material/Avatar";
import {
  BACKEND_STATUS,
  isPlatformSelectedBP,
  ldComponentWithIndexAm,
} from "../../../utils/accountManagerUtil";
import { showAnalyticsDashboard } from "../../../utils/clientDashboardIdsUtil";
import { haveAccess, platformAccessKey } from "../../../utils/notificationUtil";
import { getAllFormsReducer } from "../../../api/accountManager/forms/slice";
import { getSharedAssetsReducer } from "../../../api/accountManager/sharedAssets/slice";
import { NavLink } from "react-router-dom";
import { dbStatus } from "../../ClientDashboard/HomePage/HomePage";
import PleaseWaitLoaderModal from "../../modals/PleaseWaitLoaderModal";
import { useGetBrandList } from "../../../services/brandList";
import useGoogleAuthConnection from "../../../hooks/useGoogleAuthConnection";
import {
  StyledButtonAddAccount,
  StyledLinkAccount,
} from "../../Admin/UserListTable/styled";
import {
  redirectToFacebookAccountConnect,
  redirectToGoogleAccountConnectFromBrandList,
  redirectToGoogleAccountConnectFromBrandListBP,
} from "../../../utils/AdminUtils/utils";
import SelectAccountFromList from "../../Admin/AccountSelectionFromList/AccountSelection/SelectAccountFromList";
import WarningModal from "../../Admin/AccountSelectionFromList/WarningModal/WarningModal";
import SelectedAccountListModal from "../../Admin/SelectedAccounts/SelectedAccountListModal";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";
import { useDeleteAdAccount } from "../../../services/adAccount";

const isPlatformSelected = (platforms, platformName) => {
  let result = false;
  const foundIndex = platforms?.findIndex(
    (platform) => platform.platform === platformName
  );

  if (foundIndex !== -1) result = true;

  return result;
};

const getStatusTitle = (form, allProposals) => {
  const salesQuestionnaireStatus = form?.salesQuestionnaire?.status;
  const fbAdAccountStatus = form?.accessSharing?.fbAccess?.adAccount?.status;
  const googleAdAccountStatus =
    form?.accessSharing?.googleAccess?.googleAdAccount?.status;
  const readSharedAssetStatus = form?.accessSharing?.status;
  let title = "";
  let classes = "";
  title = "Sales Questionnaire";
  classes = "progress";
  if (form?.salesQuestionnaire?.status === "ONGOING") {
    title = "Sales Questionnaire";
    classes = "progress";
    // if (allProposals[form._id]) {
    //   title = "Shared Assets: Read";
    //   classes = "progress";
    // }
  }

  if (
    form?.salesQuestionnaire?.status === BACKEND_STATUS.COMPLETED &&
    form?.accessSharing?.status === BACKEND_STATUS.ONGOING
  ) {
    title = "Access Sharing";
    classes = "progress";
  }
  // if (readSharedAssetStatus === BACKEND_STATUS.COMPLETED) {
  //   if (fbAdAccountStatus === dbStatus.READ_ACCESS && fbAdAccountStatus === dbStatus.READ_ACCESS) {
  //     title = "Shared Assets: Read",
  //     classes = "progress"
  //   }
  // }
  if (form?.accessSharing?.status === BACKEND_STATUS.COMPLETED) {
    title = "Audit";
    classes = "progress";
  }
  if (
    form?.googleAuditData?.status === "COMPLETED" &&
    isPlatformSelectedBP(form, "facebook-ads")
  ) {
    title = "Audit";
    classes = "progress";
  }
  if (
    form?.googleAuditData?.status === "COMPLETED" &&
    !isPlatformSelectedBP(form, "facebook-ads")
  ) {
    title = "Proposal";
    classes = "progress";
  }
  if (form?.facebookAuditStatus === "COMPLETED") {
    title = "Proposal";
    classes = "progress";
  }
  if (form?.proposalIterations && form?.proposalIterations.length) {
    title = "Proposal";
    classes = "progress";
    if (
      (form?.proposalIterations[form?.proposalIterations.length - 1] || {})
        .state === "APPROVED"
    ) {
      title = "Proposal";
      classes = "progress";
      // if (allProposals[form._id]) {
      //   title = "Shared Assets: Complete";
      //   classes = "progress";
      // }
    }
  }
  if (
    form?.proposalIterations?.length &&
    (form?.proposalIterations[form?.proposalIterations.length - 1] || {})
      .state === "CHANGES_SUGGESTED"
  ) {
    title = "Proposal";
    classes = "progress";
  }
  if (
    form?.intakeInfo?.status === "ONGOING" ||
    form?.reviewIntakeForm === "ONGOING"
  ) {
    title = "Review Intake Form";
    classes = "progress";
  }
  if (form?.reviewIntakeForm === "COMPLETED") {
    title = "Strategy Creation";
    classes = "progress";
  }
  if ((form?.strategyIterations || []).length) {
    if (
      ["GENERATED", "CHANGES_APPLIED"].includes(
        (form?.strategyIterations[form?.strategyIterations.length - 1] || {})
          .state
      )
    ) {
      title = "Strategy Creation";
      classes = "progress";
    }
    if (
      (form?.strategyIterations[form?.strategyIterations.length - 1] || {})
        .state === "CHANGES_SUGGESTED"
    ) {
      title = "Strategy Creation";
      classes = "progress";
    }
    if (
      (form?.strategyIterations[form?.strategyIterations.length - 1] || {})
        .state === "APPROVED"
    ) {
      title = "Strategy Deck";
      classes = "progress";
    }
  }
  if (showAnalyticsDashboard(form)) {
    title = "Campaign Generation: Complete";
    classes = "progress";
  }

  return [title, classes];
};

const CommonBrandPage = ({ formId = null, setFormId = () => {}, url = "" }) => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [removeAlert, setRemoveAlert] = useState(false);
  const [removeAdAccount, setRemoveAdAccount] = useState({
    brandId: "",
    adAccountId: "",
  });
  const googleAdsAccess = useSelector(
    (store) => store.sharedAssetsAM.googleAdsAccess
  );
  const { data: brandList, isLoading, isFetching } = useGetBrandList();
  const allProposals = brandList?.values;

  // const [querySearchParams, setQuerySearchParams] = useState(null);
  // const open = true;
  // const [isLoading, setIsLoading] = useState(true);
  // const [payloadFromGoogleAuth, setPayloadFromaGoogleAuth] = useState(null);
  const {
    payloadFromGoogleAuth,
    setPayloadFromaGoogleAuth,
    accountListModal,
    openAccountListModal,
    selectAccounts,
    openSelectAccounts,
    accountSelected,
    setAccountSelected,
    warningModal,
    setWarningModal,
    enableAllSelectedAccounts,
    setEnableAllSelectedAccounts,
    adAccountLocallyList,
    setAdAccountLocallyList,
    onProceedCallback,
    onGoBackCallback,
    adAccountList,
    isLoadingAdAccounts,
    brandSelected,
    setBrandSelected,
  } = useGoogleAuthConnection("bp-am/brands");
  const { mutate: mutateDeleteAdAccount } = useDeleteAdAccount();
  // useEffect(() => {
  //   dispatch(
  //     getAllFormsReducer({
  //       callback: () => {
  //         setIsLoading(false);
  //       },
  //     })
  //   );
  // }, []);
  // useEffect(() => {
  //   if (allProposals?.length) {
  //     dispatch(
  //       getSharedAssetsReducer({
  //         // formIds: allProposals.map(proposal => proposal._id)
  //       })
  //     );
  //   }
  // }, [allProposals]);
  if (isLoadingAdAccounts || isFetching) {
    return <PmaxLoader />;
  }

  return (
    <main class={`main_layout accountManger`}>
      {/*<PleaseWaitLoaderModal open={isLoading} />*/}
      <section className="nav_section">
        <NavigationSteps />
      </section>
      <section className={`right_layout`}>
        <div className={`right_layout_inner`}>
          <div className={`right_main_section`}>
            <div className="brand_selection_layout">
              <div className="brand_selection_header">
                <h4>Hello</h4>
                {/* <h2>Brand Selection</h2> */}
                <p>Please Select the brand that you want to work with</p>
              </div>
              <div className="brand_selection_body">
                <div className="selection_inner_layout success">
                  {(allProposals || []).map((proposal, idx) => {
                    const [title, classes] = getStatusTitle(
                      proposal,
                      googleAdsAccess
                    );
                    const googleConnectedAccounts =
                      proposal.connectedAdAccounts.filter(
                        (account) => account.platform === "GOOGLE"
                      );
                    const facebookConnectedAccounts =
                      proposal.connectedAdAccounts.filter(
                        (account) => account.platform === "FACEBOOK"
                      );
                    return (
                      <div
                        className={`selection_brand_block ${
                          currentIndex === idx ? `selected ${classes}` : classes
                        }`}
                        key={idx}
                        onClick={() => {
                          setCurrentIndex(idx);
                          setFormId(proposal._id);
                        }}
                      >
                        <div className="brand_blocks_head">
                          <div className="block_brand_logo">
                            <Avatar
                              className="brand_avatar"
                              alt={proposal.brandName || ""}
                              src={"avatar.jpg"}
                            />
                          </div>
                          <div className="brand_selection_status">{title}</div>
                        </div>
                        <div className="brand_blocks_details">
                          <h3>{proposal.brandName}</h3>
                          <div className="brand_campaing_row">
                            <div className="brand_name_selection">
                              <div className="campaign_connect_logo">
                                <img src={facebookIcon} />
                              </div>
                              <p className="campaign_connect_title">
                                Facebook Ads
                              </p>
                            </div>
                            {facebookConnectedAccounts?.length ? (
                              <StyledLinkAccount
                                onClick={() => {
                                  setAccountSelected("facebook");
                                  openAccountListModal(true);
                                  setBrandSelected({
                                    brandId: proposal.brandId,
                                    sub: proposal.sub,
                                    brandName: proposal.brandName,
                                  });
                                }}
                              >
                                {facebookConnectedAccounts.length}{" "}
                                {facebookConnectedAccounts.length > 1
                                  ? "Accounts"
                                  : "Account"}
                              </StyledLinkAccount>
                            ) : (
                              <StyledButtonAddAccount
                                onClick={() =>
                                  redirectToFacebookAccountConnect(
                                    proposal.brandName,
                                    proposal.brandId,
                                    proposal.sub,
                                    "facebook-ads",
                                    false,
                                    "bp-am/brands"
                                  )
                                }
                              >
                                Add Account
                              </StyledButtonAddAccount>
                            )}
                          </div>
                          <div className="brand_campaing_row">
                            <div className="brand_name_selection">
                              <div className="campaign_connect_logo connected">
                                <img src={googleIcon} />
                              </div>
                              <p className="campaign_connect_title">
                                Google Ads
                              </p>
                            </div>
                            {googleConnectedAccounts?.length ? (
                              <StyledLinkAccount
                                onClick={() => {
                                  setAccountSelected("google");
                                  openAccountListModal(true);
                                  setBrandSelected({
                                    brandId: proposal.brandId,
                                    sub: proposal.sub,
                                    brandName: proposal.brandName,
                                  });
                                }}
                              >
                                {googleConnectedAccounts.length}{" "}
                                {googleConnectedAccounts.length > 1
                                  ? "Accounts"
                                  : "Account"}
                              </StyledLinkAccount>
                            ) : (
                              <StyledButtonAddAccount
                                onClick={() =>
                                  redirectToGoogleAccountConnectFromBrandListBP(
                                    proposal.brandName,
                                    proposal.brandId,
                                    proposal.sub,
                                    "g-ads",
                                    false,
                                    "bp-am/brands"
                                  )
                                }
                              >
                                Add Account
                              </StyledButtonAddAccount>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="preview_footer_row">
                <NavLink
                  disabled={currentIndex === null}
                  to={url}
                  className="footer_content"
                  style={{ justifyContent: "flex-end", textDecoration: "none" }}
                >
                  <PrimaryButton
                    title="Next"
                    style={{
                      backgroundColor:
                        currentIndex !== null ? "#0869FB" : "#B9CCE9",
                      color: "#ffff",
                      textTransform: "none",
                      fontSize: 13,
                    }}
                    disabled={currentIndex === null}
                    onClick={() => {
                      localStorage.setItem(
                        "formId",
                        allProposals[currentIndex]._id
                      );
                      localStorage.setItem(
                        "brandId",
                        allProposals[currentIndex].brandId
                      );
                      localStorage.setItem("isNextClicked", true);
                    }}
                  ></PrimaryButton>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectAccounts && (
        <SelectAccountFromList
          openSelectAccounts={openSelectAccounts}
          selectAccounts={selectAccounts}
          accountSelected={accountSelected}
          adAccountList={adAccountList}
          payloadFromGoogleAuth={payloadFromGoogleAuth}
          warningModal={warningModal}
          setWarningModal={setWarningModal}
          enableAllSelectedAccounts={enableAllSelectedAccounts}
          setEnableAllSelectedAccounts={setEnableAllSelectedAccounts}
          adAccountLocallyList={adAccountLocallyList}
          setAdAccountLocallyList={setAdAccountLocallyList}
          accountListModelView={false}
          openAccountListModal={openAccountListModal}
        />
      )}
      {warningModal && (
        <WarningModal
          warningModal={warningModal}
          setWarningModal={setWarningModal}
          onProceedCallback={onProceedCallback}
          onGoBackCallback={onGoBackCallback}
          message={`This will let you select accounts which are already connected to a
          brand. Using this option is not recommended since this would
          disrupt the future flow of this dashboard and some functionalities
          will stop working properly.Please contact the Pixis Team first if
          you are facing issues with connecting ad accounts.`}
        />
      )}
      {removeAlert && (
        <WarningModal
          warningModal={removeAlert}
          setWarningModal={setRemoveAlert}
          onProceedCallback={() => {
            mutateDeleteAdAccount(
              {
                brandId: removeAdAccount.brandId,
                accountId: removeAdAccount.adAccountId,
              },
              {
                onSuccess: () => {
                  setRemoveAlert(false);
                  openAccountListModal(true);
                },
              }
            );
          }}
          onGoBackCallback={() => {
            setRemoveAlert(false);
            openAccountListModal(true);
          }}
          message={`This will delink the account from this brand. To add it again, you can use the "+ Add More Accounts" button`}
        />
      )}
      {accountListModal && (
        <SelectedAccountListModal
          accountSelected={accountSelected}
          accountListModal={accountListModal}
          openAccountListModal={openAccountListModal}
          brand={brandSelected}
          pageFromWhichCalled="brands-list"
          accountListModelView={true}
          payloadFromGoogleAuth={payloadFromGoogleAuth}
          removeAlert={removeAlert}
          setRemoveAlert={setRemoveAlert}
          setRemoveAdAccount={setRemoveAdAccount}
          removeAdAccount={setRemoveAdAccount}
        />
      )}
    </main>
  );
};
export default CommonBrandPage;
