import {
  LOGOUT,
  RESET_ERROR,
  ALREADY_AUTHENTICATED,
  LOGIN_SUCCESS,
} from "./enum";

export const alreadyAuthenticated = (payload) => {
  return {
    type: ALREADY_AUTHENTICATED,
    payload: payload,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const loginSuccess = (payload) => {
  return {
    type: LOGIN_SUCCESS,
    payload: payload,
  };
};

export const resetError = () => {
  return {
    type: RESET_ERROR,
  };
};
