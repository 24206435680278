import { Typography } from '@mui/material';
import React from 'react';
import ProcessTime from '../../../assets/icons/Audit/processing-time.gif';
import './loader-view.css';

const LoaderView = () => {
  return (
    <div
      style={{
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          height: 478,
        }}
      >
        <div className='processtime'>
          <img
            src={ProcessTime}
            style={{ width: '180px', height: '180px', objectFit: 'cover' }}
            alt=''
          />
        </div>
        <div
          style={{
            marginTop: '40px',
            marginBottom: '111px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontFamily: 'Lato',
              fontSize: '24px',
              lineHeight: '27px',
              fontWeight: '700',
              textAlign: 'center',
              marginBottom: '9px',
            }}
          >
            Generating Data
          </Typography>
          <Typography
            style={{
              fontFamily: 'Lato',
              fontSize: '16px',
              lineHeight: '26px',
              fontWeight: '400',
              color: '#6F6C90',
              textAlign: 'center',
              width: '62%',
            }}
          >
            Please wait while we generate data for you. This usually takes 20-30
            minutes.
          </Typography>
        </div>
      </div>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 10,
          lineHeight: '20px',
          color: '#9798A1',
          position: 'absolute',
          bottom: 23,
          left: 17,
        }}
      >
        Explore other campaigns without impacting this data
        generation
      </Typography>
    </div>
  );
};

export default LoaderView;
