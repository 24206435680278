export const inefficientCampaigns=(type,campaigns)=>{
    if(type === "CPA"){
        const sortedCampaigns=campaigns.sort((a,b) => b[6] - a[6]);
        return sortedCampaigns.length > 5 ? sortedCampaigns.slice(0,5) : sortedCampaigns;
    }else if(type === "CTR"){
        const sortedCampaigns=campaigns.sort((a,b) => a[7] - b[7]);
        const excludeZeroCtr=sortedCampaigns.filter(campaign => campaign[7] >= 0.01);
        return excludeZeroCtr.length > 5 ? excludeZeroCtr.slice(0,5) : excludeZeroCtr;
    }else if(type === "CPM"){
        const sortedCampaigns=campaigns.sort((a,b) => b[8] - a[8]);
        return sortedCampaigns.length > 5 ? sortedCampaigns.slice(0,5) : sortedCampaigns;
    }else if(type === "CVR"){
        const sortedCampaigns=campaigns.sort((a,b) => a[9] - b[9]);
        const excludeZeroCvr=sortedCampaigns.filter(campaign => campaign[9] >= 0.01);
        return excludeZeroCvr.length > 5 ? excludeZeroCvr.slice(0,5) : excludeZeroCvr;
    }
}

export const bestPerformingCampaigns = (type,campaigns) => {
    if(type === "CPA"){
        const sortedCampaigns=campaigns.sort((a,b) => a[6] - b[6]);
        const excludeZeroCpa=sortedCampaigns.filter(campaign => campaign[6] >= 0.01);
        return excludeZeroCpa.length > 5 ? excludeZeroCpa.slice(0,5) : excludeZeroCpa;
    }else if(type === "CTR"){
        const sortedCampaigns=campaigns.sort((a,b) => b[7] - a[7]);
        return sortedCampaigns.length > 5 ? sortedCampaigns.slice(0,5) : sortedCampaigns;
    }else if(type === "CPM"){
        const sortedCampaigns=campaigns.sort((a,b) => a[8] - b[8]);
        const excludeZeroCpm=sortedCampaigns.filter(campaign => campaign[8] >= 0.01);
        return excludeZeroCpm.length > 5 ? excludeZeroCpm.slice(0,5) : excludeZeroCpm;
    }else if(type === "CVR"){
        const sortedCampaigns=campaigns.sort((a,b) => b[9] - a[9]);
        return sortedCampaigns.length > 5 ? sortedCampaigns.slice(0,5) : sortedCampaigns;
    }
}

const inefficientCampaignsColdWarmAudience = (type,campaigns) => {
    if(type === "CPA"){
        const sortedCampaigns=campaigns.sort((a,b) => b[8] - a[8]);
        return sortedCampaigns.length > 5 ? sortedCampaigns.slice(0,5) : sortedCampaigns;
    }else if(type === "CTR"){
        const sortedCampaigns=campaigns.sort((a,b) => a[9] - b[9]);
        const excludeZeroCtr=sortedCampaigns.filter(campaign => campaign[9] >= 0.01);
        return excludeZeroCtr.length > 5 ? excludeZeroCtr.slice(0,5) : excludeZeroCtr;
    }else if(type === "CPM"){
        const sortedCampaigns=campaigns.sort((a,b) => b[10] - a[10]);
        return sortedCampaigns.length > 5 ? sortedCampaigns.slice(0,5) : sortedCampaigns;
    }else if(type === "CVR"){
        const sortedCampaigns=campaigns.sort((a,b) => a[11] - b[11]);
        const excludeZeroCvr=sortedCampaigns.filter(campaign => campaign[11] >= 0.01);
        return excludeZeroCvr.length > 5 ? excludeZeroCvr.slice(0,5) : excludeZeroCvr;
    }
}

const bestCampaignsColdWarmAudience = (type,campaigns) => {
    if(type === "CPA"){
        const sortedCampaigns=campaigns.sort((a,b) => a[8] - b[8]);
        const excludeZeroCpa=sortedCampaigns.filter(campaign => campaign[8] >= 0.01);
        return excludeZeroCpa.length > 5 ? excludeZeroCpa.slice(0,5) : excludeZeroCpa;
    }else if(type === "CTR"){
        const sortedCampaigns=campaigns.sort((a,b) => b[9] - a[9]);
        return sortedCampaigns.length > 5 ? sortedCampaigns.slice(0,5) : sortedCampaigns;
    }else if(type === "CPM"){
        const sortedCampaigns=campaigns.sort((a,b) => a[10] - b[10]);
        const excludeZeroCpm=sortedCampaigns.filter(campaign => campaign[10] >= 0.01);
        return excludeZeroCpm.length > 5 ? excludeZeroCpm.slice(0,5) : excludeZeroCpm;
    }else if(type === "CVR"){
        const sortedCampaigns=campaigns.sort((a,b) => b[11] - a[11]);
        return sortedCampaigns.length > 5 ? sortedCampaigns.slice(0,5) : sortedCampaigns;
    }
}

export const coldAudience = (worstBest,type,campaigns) => {
    const coldAudienceArr=campaigns.filter(campaign => campaign[12] === "COLD");
    if(worstBest === "best"){
        return bestCampaignsColdWarmAudience(type,coldAudienceArr);
    }else if(worstBest === "worst"){
        return inefficientCampaignsColdWarmAudience(type,coldAudienceArr);
    }
}

export const warmAudience = (worstBest,type,campaigns) => {
    const warmAudienceArr=campaigns.filter(campaign => campaign[12] === "WARM");
    if(worstBest === "best"){
        return bestCampaignsColdWarmAudience(type,warmAudienceArr);
    }else if(worstBest === "worst"){
        return inefficientCampaignsColdWarmAudience(type,warmAudienceArr);
    }
}

export const adTabAds = (worstBest,type,campaigns) => {
    if(worstBest === "best"){
        return bestPerformingCampaigns(type,campaigns);
    }else if(worstBest === "worst"){
        return inefficientCampaigns(type,campaigns);
    }
}

export const adTabCreatives = (worstBest,type,campaigns) => {
    if(worstBest === "best"){
        return bestPerformingCampaigns(type,campaigns);
    }else if(worstBest === "worst"){
        return inefficientCampaigns(type,campaigns);
    }
}

export const adTabCopies = (worstBest,type,campaigns) => {
    if(worstBest === "best"){
        return bestPerformingCampaigns(type,campaigns);
    }else if(worstBest === "worst"){
        return inefficientCampaigns(type,campaigns);
    }
}