import { TextField } from "@mui/material";

export default function TextFieldElement({ channel, label = channel.title, ...params }) {
  if (params.InputProps) {
    params.InputProps.disableUnderline = true;
  }
  return (
  <div className="metric-list-form">
    <img height="20px" style={{ marginLeft: "10px" }} src={channel.icon} alt={channel.title} />
    <TextField
      id="Analytics"
      variant="filled"
      label={label}
      size="small"
      InputProps={{
        disableUnderline: true,
        readOnly: true,
      }}
      {...params}
    />
  </div>
  );
}
