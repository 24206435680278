import React, { useEffect, useState } from "react";
import{back_arrow as  backArrow} from "../../../assets/icons/icon";
import { Typography, Box, Button } from "@mui/material";
import "./interest-campaign-fb.css";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Conversion from "../Conversion";
import InterestTargetting from "./InterestTargetting";
import { useDispatch, useSelector } from "react-redux";
import { updateInterest } from "../../../api/HDMAM/campaign/action";
import {
  goBackAmCompo,
  goToSpecificCompoHDMAM,
} from "../../../api/HDMAM/componentTracking/action";
import TabPanel from "@mui/lab/TabPanel";
import CheckedIcon from "../../AccountManager/CampaignApproval/CheckedIcon";
import HDMAdset from "../HDMCampaignCreation/HDMAdset";
import CampaignConfirmModal from "../HDMCampaignCreation/CampaignConfirmModal";
import {
  cta,
  optimisationAdDelivery,
  optimizationGoalBackendMapping,
  CTABackendMapping,
  destinationTypeBackendMapping,
  objectiveFrontendMappiing,
  conversionEvents,
} from "../HDMCampaignCreation/constants";
import { accountManagerIds, accountManagerIdsHDM, BACKEND_STATUS, componentWithIndexHDMAm, ldComponentWithIndexAm } from "../../../utils/accountManagerUtil";
import { goToSpecificCompoClient, goToSpecificCompoClientAm } from "../../../api/accountManager/componentTracking/action";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";

const InterestCampaign = ({agencyType = "HDM"}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {formId} = useParams();
  const [showAd, setShowAd] = useState(false);
  const interestData = useSelector((store) => store.hdmCampaign.interest);
  const campaignConfirmModal = useSelector(
    (store) => store.hdmCampaign.campaignConfirmModal
  );
  const adsets = (interestData.adsets || []).length
    ? interestData.adsets
    : ["adset1", "adset2", "adset3"];
  const [value, setValue] = useState("0");
  const [conversionData, setConversionData] = useState(
    (interestData.adsets || [])[value] || {}
  );
  const [targetingData, setTargetingData] = useState(
    (interestData.adsets || [])[value] || {}
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value) {
      setConversionData({ ...(interestData.adsets || [])[value] } || {});
      setTargetingData({ ...(interestData.adsets || [])[value] } || {});
    }
  }, [value]);

  const updateReducer = (data, field, isLastAdset) => {
    console.log("in update reducer", data, field, value);
    const interestDataTemp = { ...interestData };
    const adsets = interestDataTemp.adsets.map((adset, idx) => {
      if (idx == value) {
        if (field === "conversionLocation") {
          return {
            ...adset,
            status: BACKEND_STATUS.ONGOING,
            [field]: destinationTypeBackendMapping[data],
            optimizationAdDelivery:
              optimizationGoalBackendMapping[
                ((optimisationAdDelivery[
                  objectiveFrontendMappiing[interestData.objective]
                ] || {})[data] || [])[0]
              ] || "",
            cta:
              CTABackendMapping[
                ((cta[objectiveFrontendMappiing[interestData.objective]] || {})[
                  data
                ] || [])[0]
              ] || "",
            conversionEvent: "",
            fbPixel: null,
            fbPage: null,
          };
        }
        if (field === "fbPixel") {
          return {
            ...adset,
            status: BACKEND_STATUS.ONGOING,
            [field]: data,
            conversionEvent:
              conversionEvents[
                objectiveFrontendMappiing[interestData.objective]
              ][0] || '',
            fbPage: null,
          };
        }
        return {
          ...adset,
          status: BACKEND_STATUS.ONGOING,
          [field]: data,
        };
      } else {
        return { ...adset };
      }
    });
    interestDataTemp.adsets = adsets;
    dispatch(updateInterest(interestDataTemp));
    if (field !== "status") {
      setConversionData(interestDataTemp.adsets[parseInt(value)]);
      setTargetingData(interestDataTemp.adsets[parseInt(value)]);
      dispatch({
        type: "UPDATE_CHANGES_STATUS",
        payload: {
          campaignType: "interestChange",
          value: true,
        },
      });
    }

    if (isLastAdset) {
      dispatch({
        type: "SUBMIT_FORM_FOR_HDM_CAMPAIGN",
        payload: {
          campaignType: "topOfTheFunnelInterest",
          agencyType: agencyType || 'HDM'
        },
      });
    }
  };

  const updateReducerTargeting = (data, field) => {
    const interestDataTemp = { ...interestData };
    const adsets = interestDataTemp.adsets.map((adset, idx) => {
      if (idx == value) {
        return {
          ...adset,
          status: BACKEND_STATUS.ONGOING,
          [field]: data,
        };
      } else {
        return { ...adset };
      }
    });
    interestDataTemp.adsets = adsets;
    setTargetingData(interestDataTemp.adsets[value]);
    setConversionData(interestDataTemp.adsets[value]);
    dispatch(updateInterest(interestDataTemp));
    dispatch({
      type: "UPDATE_CHANGES_STATUS",
      payload: {
        campaignType: "interestChange",
        value: true,
      },
    });
  };

  const closeConfirmModal = () => {
    dispatch({
      type: "UPDATE_HDM_CAMPAIGN_CONFIRM_MODAL",
    });
  };

  const goToCampaignViewHandler = () => {
    if(agencyType === 'LD'){
      dispatch(goToSpecificCompoClient(ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_CREATION_FB]));
    } else {
      dispatch(goToSpecificCompoHDMAM(componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_FB]));
    }
    dispatch({
      type: "UPDATE_HDM_CAMPAIGN_CONFIRM_MODAL",
    });
  };

  const getValue = (data) => {
    let toBeDisabled = false;
    console.log("!!!!!!!!!!", data);
    [
      "adsetName",
      "conversionLocation",
      "minAge",
      "maxAge",
      "gender",
      "optimizationAdDelivery",
      "cta",
    ].map((para) => {
      if (!data[para]) {
        toBeDisabled = true;
      }
    });
    if (!(data.facebookAds || []).length) {
      toBeDisabled = true;
    }
    if (!(data.advertisingLocations || []).length) {
      toBeDisabled = true;
    }
    if (interestData.abo === "OFF" && !data.budget) {
      toBeDisabled = true;
    }

    return toBeDisabled;
  };

  console.log("#$%^&*&^%$#%^&^%$", conversionData);
  return showAd ? (
    <HDMAdset
      setShowAd={setShowAd}
      adsData={(interestData?.adsets[value] || {}).facebookAds || []}
      cta={(interestData?.adsets[value] || {}).cta || ""}
      index={parseInt(value) + 1}
      updateReducer={updateReducer}
    />
  ) : (
    <div className="campaign-container">
      <div className="campaign-header">
        <div className="campaign-header-container">
          <div
            className="back-button-container"
            onClick={() => {
              if(agencyType === "HDM"){
                dispatch(goToSpecificCompoHDMAM(componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_FB]));
              } else {
                navigate(generatePath(`${ldAmRoutePaths.campaignFacebook.fullbase}`, {formId}));
              }
            }}
          >
            <img src={backArrow} alt="" />
          </div>
          <div className="campaign-description">
            <Typography className="campaign-title">
              TOF-Interest Campaign
            </Typography>
          </div>
        </div>
      </div>
      <Box sx={{ width: "100%", typography: "body1", marginTop: "0.5rem" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {adsets.map((ad, idx) => {
                return (
                  <Tab
                    label={`Adset ${idx + 1}`}
                    value={idx.toString()}
                    icon={
                      ad.status === BACKEND_STATUS.COMPLETED ? (
                        <CheckedIcon />
                      ) : (
                        ""
                      )
                    }
                    iconPosition="start"
                    key={idx}
                    style={{
                      width: `calc(100%/${adsets.length})`,
                      textTransform: "capitalize",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSize: 14,
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.25rem",
                    }}
                  />
                );
              })}
            </TabList>
          </Box>
          {adsets.map((e, idx) => {
            return (
              <TabPanel value={idx.toString()} index={idx}>
                <div className="fblookalike-section-container">
                  <Conversion
                    data={conversionData}
                    updateReducer={updateReducer}
                    loadData={interestData}
                  />
                  <InterestTargetting
                    data={targetingData}
                    updateReducer={updateReducerTargeting}
                    index={value}
                  />
                </div>
                <div className="preview_footer_row">
                  <div
                    className="footer_con"
                    style={{ justifyContent: "end", gap: "2rem" }}
                  >
                    <Button
                      onClick={() => setShowAd(true)}
                      style={{
                        background: "rgba(8, 105, 251, 0.07)",
                        borderRadius: 8,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: 14,
                        lineHeight: "20px",
                        padding: "0px 20px",
                        textTransform: "capitalize",
                      }}
                    >
                      Go to Ad Level
                    </Button>
                    <PrimaryButton
                      title="Confirm"
                      onClick={(e) => {
                        if (idx != adsets.length - 1) {
                          handleChange(e, (parseInt(value) + 1).toString());
                          updateReducer(BACKEND_STATUS.COMPLETED, "status");
                        } else {
                          updateReducer(
                            BACKEND_STATUS.COMPLETED,
                            "status",
                            true
                          );
                        }
                      }}
                      disabled={getValue(conversionData)}
                    />
                  </div>
                </div>
              </TabPanel>
            );
          })}
        </TabContext>
      </Box>
      {campaignConfirmModal && (
        <CampaignConfirmModal
          campaignName="TOF- Interest"
          updateCampaignConfirmModal={closeConfirmModal}
          goToCampaignViewHandler={goToCampaignViewHandler}
        />
      )}
    </div>
  );
};

export default InterestCampaign;
