import { useDispatch } from 'react-redux';
import { updateHDMClientDetailsReducer } from '../../../api/HDMClient/action';
import { mappedReduxType } from '../../../api/HDMClient/enum';
import TRASH from '../HDMReadAccess/icons/trash-icon.png';

const InputForDeleteAccountId = (props) => {
  const dispatch = useDispatch();
  return (
    <div className='row' style={{ display: 'flex', alignItems: 'center' }}>
      {props.value ? (
        <>
          <div
            style={{
              height: 32,
              width: 155,
              display: 'flex',
              alignItems: 'center',
              fontSize: 12,
            }}
          >
            {props.value}
          </div>
          {/* <img
            src={TRASH}
            style={{ width: 25, marginTop: 2 }}
            onClick={() => {
              dispatch(
                updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
                  infoType: props.infoType,
                  key: props.objKey,
                  key2: props.objKey2,
                  key3: props.objKey3,
                  value: "",
                })
              );
            }}
          /> */}
        </>
      ) : (
        '-'
      )}
    </div>
  );
};
export default InputForDeleteAccountId;
