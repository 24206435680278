import { put, takeLatest, select, call, all } from 'redux-saga/effects';
import { api } from '../../api/index';
import {
  updateFieldInFormReducer,
  getFormDetailsReducer,
  setClientForm,
  setSpendsQuestionForm,
  setPerformanceQuestionForm,
  updateFetchStatus,
  updateAuditReport,
  getAuditReport,
  getPerformanceAuditData,
  updatePerformanceAuditData,
} from './slice';
import { getLocalStorage } from '../localStorage/localStorage';
import { yearPickerClasses } from '@mui/lab';

function* getForm(action) {
  let formId = '';
  if (getLocalStorage('formId') && getLocalStorage('formId') !== 'undefined') {
    formId = getLocalStorage('formId');
  } else {
    formId = getLocalStorage('amSelectedFormId');
  }
  console.log('form id is ' + formId);
  try {
  let formResponse;
  // if (action.payload) {
  //   formResponse = yield api.get('/client-forms/' + action.payload);
  // } else {
  //   formResponse = yield api.get('/client-forms/' + formId);
  // }
  formResponse = yield api.get('/client-forms/' + formId)
  console.log(formResponse.data);
  yield put(setClientForm(formResponse.data));
  } catch (error) {
     console.error(error);
  }
}

function* updateFormField(action) {
  let formId = '';
  if (getLocalStorage('formId') && getLocalStorage('formId') !== 'undefined') {
    formId = getLocalStorage('formId');
  } else {
    formId = getLocalStorage('amSelectedFormId');
  }
  let brandId = '';
  if (
    getLocalStorage('brandId') &&
    getLocalStorage('brandId') !== 'undefined'
  ) {
    brandId = getLocalStorage('brandId');
  } else {
    brandId = getLocalStorage('amSelectedBrandId');
  }
  const url = `/client-forms`;
  const body = {
    ...action.payload,
    _id: formId,
    brandId,
  };
 try {
  const formResponse = yield api.post(url, body);
  console.log('!!!!!', formResponse.data);
  yield put(setClientForm(formResponse.data));
 } catch (error) {
    console.error(error);
 }
}

function* getAuditReportSaga() {
  const ads = [];
  const keywords = [];
  const adGroups = [];
  const assetGroups = [];
  const campaigns = [];
  const merchentCentre = [];
  const conversions = [];
  const account = [];
  const landingPage = [];
  let filteredAudits = {};
  let score = {};
  let total = {};
  const goals = [];
  const finalTakeaways = [];
  const initialFindings = [];
  let yourAuditScore = 0;
  const url = `/performance-audit/g-ads/${getLocalStorage("formId")}`;
  // const form = yeild select(store => store.forms.form.performaceGoogleAdsData);

  try {
    const response = yield api.get(url);
    const eventData = response.data.event_data;
    eventData.forEach(({ area_of_focus, recommendation, takeaway_label, takeaway_type}) => {
      switch(area_of_focus) {
        case 'Ads':
          ads.push({ recommendation, finding: takeaway_label, takeawayType: takeaway_type});
          break;
        case 'Keywords':
          keywords.push({ recommendation, finding: takeaway_label, takeawayType: takeaway_type});
          break;
        case 'Adgroups':
          adGroups.push({ recommendation, finding: takeaway_label, takeawayType: takeaway_type});
          break;
        case 'Asset Groups':
          assetGroups.push({ recommendation, finding: takeaway_label, takeawayType: takeaway_type});
          break;
        case 'Campaigns':
          campaigns.push({ recommendation, finding: takeaway_label, takeawayType: takeaway_type});
          break;
        case 'MerchentCentre':
          merchentCentre.push({ recommendation, finding: takeaway_label, takeawayType: takeaway_type});
          break;
        case 'Conversions':
          conversions.push({ recommendation, finding: takeaway_label, takeawayType: takeaway_type});
          break;
        case 'Account':
          account.push({ recommendation, finding: takeaway_label, takeawayType: takeaway_type});
          break;
        case 'Landing Page':
          landingPage.push({ recommendation, finding: takeaway_label, takeawayType: takeaway_type});
          break;
        default:
          throw Error('Error:', area_of_focus);
      }})

      score = {
        'Ads': ads.filter(e => e.takeawayType === "POSITIVE").length,
        'Keywords': keywords.filter(e => e.takeawayType === "POSITIVE").length,
        'Ad Groups': adGroups.filter(e => e.takeawayType === "POSITIVE").length,
        'Asset Groups': assetGroups.filter(e => e.takeawayType === "POSITIVE").length,
        'Campaigns': campaigns.filter(e => e.takeawayType === "POSITIVE").length,
        'Merchent Centre': merchentCentre.filter(e => e.takeawayType === "POSITIVE").length,
        'Conversions': conversions.filter(e => e.takeawayType === "POSITIVE").length,
        'Account': account.filter(e => e.takeawayType === "POSITIVE").length,
        'Landing Page': landingPage.filter(e => e.takeawayType === "POSITIVE").length,
      }

      total = {
        'Ads': ads.length,
        'Keywords': keywords.length,
        'Ad Groups': adGroups.length,
        'Asset Groups': assetGroups.length,
        'Campaigns': campaigns.length,
        'Merchent Centre': merchentCentre.length,
        'Conversions': conversions.length,
        'Account': account.length,
        'Landing Page': landingPage.length,
      }

      const scoreAdd = ads.filter(e => e.takeawayType === "POSITIVE").length + keywords.filter(e => e.takeawayType === "POSITIVE").length + adGroups.filter(e => e.takeawayType === "POSITIVE").length + assetGroups.filter(e => e.takeawayType === "POSITIVE").length + campaigns.filter(e => e.takeawayType === "POSITIVE").length + merchentCentre.filter(e => e.takeawayType === "POSITIVE").length + ads.filter(e => e.takeawayType === "POSITIVE").length + conversions.filter(e => e.takeawayType === "POSITIVE").length + account.filter(e => e.takeawayType === "POSITIVE").length + landingPage.filter(e => e.takeawayType === "POSITIVE").length
      const totalAdd = ads.length + keywords.length + adGroups.length + assetGroups.length + campaigns.length + merchentCentre.length + conversions.length + account.length + landingPage.length;
      yourAuditScore = Math.round((scoreAdd/totalAdd)*100)

      filteredAudits = {
        'Ads': ads.filter(e => e.takeawayType === "NEGATIVE"),
        'Keywords': keywords.filter(e => e.takeawayType === "NEGATIVE"),
        'Ad Groups': adGroups.filter(e => e.takeawayType === "NEGATIVE"),
        'Asset Groups': assetGroups.filter(e => e.takeawayType === "NEGATIVE"),
        'Campaigns': campaigns.filter(e => e.takeawayType === "NEGATIVE"),
        'Merchent Centre': merchentCentre.filter(e => e.takeawayType === "NEGATIVE"),
        'Conversion': conversions.filter(e => e.takeawayType === "NEGATIVE"),
        'Account': account.filter(e => e.takeawayType === "NEGATIVE"),
        'Landing Page': landingPage.filter(e => e.takeawayType === "NEGATIVE"),
      }

      console.log('~~~~');
      console.log('Score:', score)
      yield put(updateAuditReport({
        score,
        goals,
        finalTakeaways,
        initialFindings,
        total,
        filteredAudits,
        yourAuditScore,
      }))
      yield put(updatePerformanceAuditData());

  } catch (err) {
    console.error(err);
  }
}

function* getPerformanceAuditDataSaga() {
  const formId = getLocalStorage("formId");
  const url = `/performance-audit/get_client_form_performance_audit_data/${formId}`

  try {
    const response = yield api.get(url);
    if (response && response?.data) {
      if (response?.data?.success === false)
        yield put(getAuditReport());
      else
        yield put(updateAuditReport(response.data));
    }
  } catch(err) {
    console.error(err);
  }
}

function* updatePerformanceAuditDataSaga() {
  const formId = getLocalStorage("formId");
  const url = `/performance-audit/update_client_form_performance_audit_data/${formId}`
  const auditReport = yield select(store => store.forms.auditReport);
  console.log(auditReport);
  const data = {
    ...auditReport,
    success: true,
  }

  try {
    const response = yield api.put(url, data);
    if (response && response?.data) {
      yield put(updateAuditReport(response.data));
    }
  } catch(err) {
    console.error(err);
  }
}

export default function* newFormSaga() {
  yield takeLatest(getFormDetailsReducer.type, getForm);
  yield takeLatest(updateFieldInFormReducer.type, updateFormField);
  yield takeLatest(getAuditReport.type, getAuditReportSaga);
  yield takeLatest(getPerformanceAuditData.type, getPerformanceAuditDataSaga);
  yield takeLatest(updatePerformanceAuditData.type, updatePerformanceAuditDataSaga);
}
