import { ready as readyGif } from "../../assets/icons/proposal/proposal";
import ButtonPrimary from "../ButtonPrimary/Buttons";
import { useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { goToSpecificCompoClient } from "../../api/componentTracking/action";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "380px",
  borderRadius: "15px",
};

const IntakeAuditFormSubmitted = ({ formSubm, setFormSubm, onClickHandler = () => {} }) => {
  const dispatch = useDispatch();
  return (
    <Modal
      open={formSubm}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Close
          onClick={() => setFormSubm(!formSubm)}
          style={{
            position: "absolute",
            top: "0.8rem",
            right: "0.8rem",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "0.5rem",
          }}
        >
          <div>
            <img src={readyGif} alt="" width={150} height={150} />
          </div>
          <div>
            <Typography
              style={{
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              Client Intake Form Submitted!
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 18,
                color: "#6C6C6C",
              }}
            >
              Let's move on to the next step
            </Typography>
          </div>
          <div
            style={{
              marginTop: "1.5rem",
              display: "flex",
              gap: "1.5rem",
              justifyContent: "center",
            }}
          >
            <ButtonPrimary
              title="PROCEED"
              type="client"
              onClick={() => onClickHandler() }
            />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default IntakeAuditFormSubmitted;
