const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const advertise_icon  = `${imageBaseUrl}/assets/aec76d20-d440-498c-9845-f37977e3ac5c`
export const app_related_services_icon  = `${imageBaseUrl}/assets/fcaad265-cdcd-4d18-af53-805eecc3f939`
export const average_sales_icon  = `${imageBaseUrl}/assets/6e96dfb1-796e-4bdc-9348-416b3fdd8c68`
export const award_icon  = `${imageBaseUrl}/assets/bb3f01f6-0d92-4c8a-9a91-98e32cc8f3a0`
export const branding_icon  = `${imageBaseUrl}/assets/7619f527-1782-4774-bf3d-5bc3e1021338`
export const company_upload_logo_icon  = `${imageBaseUrl}/assets/25dd3c68-3657-44fa-b7ab-3927e8dfbbeb`
export const customer_household_income_icon = `${imageBaseUrl}/assets/50eaf1f9-2488-41f3-b3da-4e7fbb0dea10`
export const customer_interest_icon = `${imageBaseUrl}/assets/da54a243-f7cc-4be4-b1e8-8aff1b48df63`
export const customer_w_b_icon = `${imageBaseUrl}/assets/052d673b-4e2d-4001-b961-47c42fb06739`
export const education_icon = `${imageBaseUrl}/assets/69494c3a-ed02-4e77-9024-e29832c2a81c`
export const employment_status_icon = `${imageBaseUrl}/assets/5e9b9305-df0d-4776-987d-4870286fb244`
export const gender_icon = `${imageBaseUrl}/assets/70b96dd1-86db-4976-96d2-d2eeb502b4d7`
export const group_icon = `${imageBaseUrl}/assets/2b9e6d1c-aef1-45d8-9707-bfb120b8e14a`
export const lead_preferences_icon = `${imageBaseUrl}/assets/e3ecd1f2-4581-4661-bfd1-339ce3b177e9`
export const location_icon = `${imageBaseUrl}/assets/bcc9d90f-ba08-4364-b5f8-8447b1c036a5`
export const maritial_status_icon = `${imageBaseUrl}/assets/9cd8e5d8-3c7c-408c-b521-d2e5af012bcc`
export const monthly_ad_spend_icon = `${imageBaseUrl}/assets/fc856d9c-00f0-45f6-a618-206905bec418`
export const monthly_budget_icon = `${imageBaseUrl}/assets/1e196399-0122-4883-8a1c-23339d219a91`
export const product_information_icon = `${imageBaseUrl}/assets/740b023c-edd6-424f-aad6-3a4d2043e8e1`
export const profit_margin_icon = `${imageBaseUrl}/assets/a3931d1e-a286-4504-9edd-2bf6e2216fcb`
export const seasonability_icon = `${imageBaseUrl}/assets/93b121f3-8f09-4214-93ee-bf6cb6d6795b`
export const target_cpa_icon = `${imageBaseUrl}/assets/e5d19f3f-007a-49bb-938b-b8ab783926f3`
export const top_competitors_icon = `${imageBaseUrl}/assets/e65cdf7c-0fd0-44f5-a6a0-290f2827c1db`