// returns current strategy state

export const BACKEND_STATUS = {
  PENDING: "PENDING",
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};


// if not exist returns a null
export const strategyStage = (strategyIterations) =>
strategyIterations?.length !== 0
  ? strategyIterations?.[
      strategyIterations?.length - 1
    ]['state']
  : null;


  export const accessSharingStatus = {
    PENDING: "PENDING",
    CONNECTED: "CONNECTED",
    NOT_CONNECTED: "NOT_CONNECTED",
    VERIFYING: "VERIFYING",
    READ_ACCESS: "READ_ACCESS",
    ACCESS_REVOKED: "ACCESS_REVOKED",
    COMPLETE_ACCESS: "COMPLETE_ACCESS",
    VERIFYING_COMPLETE_ACCESS: "VERIFYING_COMPLETE_ACCESS",
    COMPLETED_ACCESS_NOT_SHARED: "COMPLETED_ACCESS_NOT_SHARED",
  };


  export const checkForAccess = (clientState) => {
    const platforms=clientState?.onboardingInfo?.clientAccess?.platforms;
    const accessSharing = {...clientState?.accessSharing};
    if(!platforms || !(platforms || []).length){
      return accessSharingStatus.PENDING;
    }
    //pending
    let isPending=false;
    platforms?.forEach((platform) => {
      if(platform.platform === "facebook-ads"){
        if(accessSharing?.fbAccess?.adAccount?.status ===  accessSharingStatus.VERIFYING && accessSharing?.fbAccess?.adAccount?.accountId?.length === 0){
          isPending=true;
        }
      }else if(platform.platform === "google-ads"){
        if(accessSharing?.googleAccess?.googleAdAccount?.status === accessSharingStatus.VERIFYING && accessSharing?.googleAccess?.googleAdAccount?.accountId?.length === 0){
          isPending=true;
        }
      } else if(platform.platform === "tiktok-ads"){
        if(accessSharing?.tiktokAccess?.tiktokAdsManager?.status === accessSharingStatus.VERIFYING && accessSharing?.tiktokAccess?.tiktokAdsManager?.accountId?.length === 0){
          isPending=true;
        }
      } 
    });
    if(isPending){
      return accessSharingStatus.PENDING;
    }
    //verifying
    let isVerifying=false;
    platforms.forEach((platform) => {
      if(platform.platform === "facebook-ads"){
        if(accessSharing?.fbAccess?.adAccount?.status ===  accessSharingStatus.VERIFYING && accessSharing?.fbAccess?.adAccount?.accountId?.length !== 0){
          isVerifying=true;
        }
      }else if(platform.platform === "google-ads"){
        if(accessSharing?.googleAccess?.googleAdAccount?.status === accessSharingStatus.VERIFYING && accessSharing?.googleAccess?.googleAdAccount?.accountId?.length !== 0){
          isVerifying=true;
        }
      }
      else if(platform.platform === "tiktok-ads"){
        if(accessSharing?.tiktokAccess?.tiktokAdsManager?.status === accessSharingStatus.VERIFYING && accessSharing?.tiktokAccess?.tiktokAdsManager?.accountId?.length !== 0){
          isVerifying=true;
        }
      } 
    });
    if(isVerifying){
      return accessSharingStatus.VERIFYING;
    }
    //access revoked
    let isAccessRevoked=false;
    platforms.forEach((platform) => {
      if(platform.platform === "facebook-ads"){
        if(accessSharing?.fbAccess?.adAccount?.status ===  accessSharingStatus.ACCESS_REVOKED){
          isAccessRevoked=true;
        }
      }else if(platform.platform === "google-ads"){
        if(accessSharing?.googleAccess?.googleAdAccount?.status === accessSharingStatus.ACCESS_REVOKED){
          isAccessRevoked=true;
        }
      }
      else if(platform.platform === "tiktok-ads"){
        if(accessSharing?.tiktokAccess?.tiktokAdsManager?.status === accessSharingStatus.ACCESS_REVOKED ){
          isAccessRevoked=true;
        }
      }
    })
    if(isAccessRevoked){
      return accessSharingStatus.ACCESS_REVOKED;
    }
    //Read Access
    let isRead=false;
    platforms.forEach((platform) => {
      if(platform.platform === "facebook-ads"){
        if(accessSharing?.fbAccess?.adAccount?.status ===  accessSharingStatus.READ_ACCESS){
          isRead=true;
        }
      }else if(platform.platform === "google-ads"){
        if(accessSharing?.googleAccess?.googleAdAccount?.status === accessSharingStatus.READ_ACCESS){
          isRead=true;
        }
      } else if(platform.platform === "tiktok-ads"){
        if(accessSharing?.tiktokAccess?.tiktokAdsManager?.status === accessSharingStatus.READ_ACCESS ){
          isRead=true;
        }
      }
    });
    if(isRead){
      return accessSharingStatus.READ_ACCESS;
    }
    //complete access
    let countComplete=0;
    platforms.forEach((platform) => {
      if(platform.platform === "facebook-ads"){
        if(accessSharing?.fbAccess?.adAccount?.status ===  accessSharingStatus.COMPLETE_ACCESS){
          countComplete++;
        }
      }else if(platform.platform === "google-ads"){
        if(accessSharing?.googleAccess?.googleAdAccount?.status === accessSharingStatus.COMPLETE_ACCESS){
          countComplete++;
        }
      } else if(platform.platform === "tiktok-ads"){
        if(accessSharing?.tiktokAccess?.tiktokAdsManager?.status === accessSharingStatus.COMPLETE_ACCESS ){
          countComplete++;
        }
      }
    });
    if(countComplete === platforms?.length){
      return accessSharingStatus.COMPLETE_ACCESS;
    }
  };


  // to check whether platform is selected or not
  export const isPlatformSelectedHDM = (form, platformName) => {
    return form?.onboardingInfo?.clientAccess?.platforms?.findIndex(
      (platform) => platform.platform === platformName
    );
  };

  // to check whether strategy deck has been started or not
  export const isStrategyStarted = (form) => {
    let isCompleted = false;
    if (
      isPlatformSelectedHDM(form, "google-ads") !== -1 &&
      isPlatformSelectedHDM(form, "facebook-ads") !== -1
    ) {
      if (
        form?.fbAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED &&
        form?.googleStrategyCreationStatus === BACKEND_STATUS.COMPLETED
      ) {
        isCompleted = true;
      }
    } else if (isPlatformSelectedHDM(form, "google-ads") !== -1) {
      if (
        form?.googleStrategyCreationStatus === BACKEND_STATUS.COMPLETED
      ) {
        isCompleted = true;
      }
    } else if (isPlatformSelectedHDM(form, "facebook-ads") !== -1) {
      if (form?.fbAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED) {
        isCompleted = true;
      }
    }
    return isCompleted;
  };