import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { campaign_publish_modal_icon as infoIcon } from '../../assets/icons/campaign-approval/campaign_approval';
const AssetGroupDeleteModal = ({
    // deleteModal,
    // setDeleteModal,
    handleRemove,
    handleClose,
    description,
    subDescription,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 371,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 3.5,
    padding: '3rem 3rem 0 3rem',
    // height: '380px',
    borderRadius: '14px',
    outline: 'none',
  };
   // const [deleteModal, setDeleteModal] = useState(true);
  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Close
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            cursor: 'pointer'
          }}
           onClick={() => handleClose(null)}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            gap: '0.5rem',
          }}
        >
          <div>
            <img src={infoIcon} alt='' width={64} height={64} />
          </div>
          <div style={{ marginTop: '1.5rem' }}>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 22,
                textAlign: 'center',
                width: '18rem',
                lineHeight: 1.25,
              }}
            >
              { description || 'Are you sure you want to delete this asset group?' }
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                color: '#6F6C90',
                textAlign: 'center',
              }}
            >
              { subDescription || 'This action cannot be undone.' }
            </Typography>
          </div>
          <div
            style={{
              margin: '2rem 0rem',
              display: 'flex',
              gap: '12px',
              justifyContent: 'center',
              paddingBottom: '1rem',
            }}
          >
            {/* <PrimaryButton title='Go Back' type='bordered' width={145} /> */}
            {/* <PrimaryButton title='PUBLISH' width={145} /> */}
            <Button
              style={{
                width: 145,
                height: 40,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '20px',
                color: '#0869FB',
                border: '1px solid #0869FB',
                borderRadius: 8,
                textTransform: 'capitalize',
              }}
              onClick={() => handleClose(null)}
            >
              Go Back
            </Button>
            <Button
              style={{
                width: 145,
                height: 40,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 14,
                lineHeight: '20px',
                color: 'white',
                background: '#0869FB',
                borderRadius: 8,
                textTransform: 'capitalize',
              }}
              onClick={() => { handleRemove(); }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AssetGroupDeleteModal;
