import { useWatch } from 'react-hook-form';

export default function DisplayAdsetName({ index, control }) {
  const watchAdsetName = useWatch({
    name: `adsets.${index}.adsetName`,
    control,
  });

  return (
    <>{watchAdsetName}</>
  );
}