import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import React from "react";
import SelectedAccountCard from "../AccountDetailCard/SelectedAccountCard";
import { google_icon_big } from "../../../assets/icons/icon";
import { facebook_pricing_icon } from "../../../assets/icons/icon";
import {
  StyledAccountList,
  StyledAccountListText,
  StyledAccountParaText,
  StyledFooterButton,
  StyledFooterText,
  StyledIconContainer,
  StyledPlusText,
} from "./styled";
import {
  useDeleteAdAccount,
  useGetAdAccounts,
} from "../../../services/adAccount";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";
import {
  redirectToFacebookAccountConnect,
  redirectToGoogleAccountConnect,
  redirectToGoogleAccountConnectFromBrandList,
} from "../../../utils/AdminUtils/utils";
import { getLocalStorage } from "../../../api/localStorage/localStorage";
import { PLATFORM, PLATFORM_ADS } from "../../../utils/commonEnums";
const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 538,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "0",
  paddingBottom: 0,
  height: "auto",
  borderRadius: "8px",
  outline: "none",
};
const SelectedAccountListModal = ({
  accountSelected,
  accountListModal,
  openAccountListModal,
  brand,
  pageFromWhichCalled = "",
  payloadFromGoogleAuth,
  isAdmin = false,
  removeAlert,
  setRemoveAlert,
  setRemoveAdAccount,
  removeAdAccount,
}) => {
  const { data, isLoading, isFetching } = useGetAdAccounts(
    brand.brandId ? brand.brandId : payloadFromGoogleAuth.brandId
  );
  const deleteAdAccountCallback = () => {
    // console.log("Remove is clicked");
    openAccountListModal(false);
    setRemoveAlert(true);
    // mutateDeleteAdAccount({
    //   brandId,
    //   accountId,
    // });
  };
  const redirectionBasedOnAccountSelected = () => {
    if (accountSelected === PLATFORM.GOOGLE.toLowerCase()) {
      redirectToGoogleAccountConnect(
        brand.brandName,
        brand.brandId,
        brand.sub,
        "g-ads",
        true
      );
    } else {
      redirectToFacebookAccountConnect(
        brand.brandName,
        brand.brandId,
        brand.sub,
        "facebook-ads",
        true,
        "admin/client"
      );
    }
  };
  const redirectionBasedOnPayloadFromAuth = () => {
    if (payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS) {
      redirectToGoogleAccountConnect(
        brand.brandName,
        brand.brandId,
        brand.sub,
        "g-ads",
        true
      );
    } else {
      redirectToFacebookAccountConnect(
        brand.brandName,
        brand.brandId,
        brand.sub,
        "facebook-ads",
        true,
        "admin/client"
      );
    }
  };
  const onAccountDisplayCallback = () => {
    // debugger;
    if (isAdmin) {
      if (accountSelected) {
        redirectionBasedOnAccountSelected();
      } else {
        redirectionBasedOnPayloadFromAuth();
      }
    } else {
      if (pageFromWhichCalled) {
        if (accountSelected) {
          if (accountSelected === PLATFORM.GOOGLE.toLowerCase()) {
            redirectToGoogleAccountConnectFromBrandList(
              brand.brandName,
              brand.brandId,
              brand.sub,
              PLATFORM_ADS.GOOGLE_ADS,
              true,
              getLocalStorage("agencyType") === "ld"
                ? "ld-am/brands"
                : "bp-am/brands"
            );
          } else {
            redirectToFacebookAccountConnect(
              brand.brandName,
              brand.brandId,
              brand.sub,
              PLATFORM_ADS.FACEBOOK_ADS,
              true,
              getLocalStorage("agencyType") === "ld"
                ? "ld-am/brands"
                : "bp-am/brands"
            );
          }
        } else {
          if (payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS) {
            redirectToGoogleAccountConnectFromBrandList(
              brand.brandName,
              brand.brandId,
              brand.sub,
              PLATFORM_ADS.GOOGLE_ADS,
              true,
              getLocalStorage("agencyType") === "ld"
                ? "ld-am/brands"
                : "bp-am/brands"
            );
          } else {
            redirectToFacebookAccountConnect(
              brand.brandName,
              brand.brandId,
              brand.sub,
              PLATFORM_ADS.FACEBOOK_ADS,
              true,
              getLocalStorage("agencyType") === "ld"
                ? "ld-am/brands"
                : "bp-am/brands"
            );
          }
        }
      } else {
        if (accountSelected) {
          if (accountSelected === PLATFORM.GOOGLE.toLowerCase()) {
            redirectToGoogleAccountConnectFromBrandList(
              brand.brandName,
              brand.brandId,
              brand.sub,
              PLATFORM_ADS.GOOGLE_ADS
            );
          } else {
            redirectToFacebookAccountConnect(
              brand.brandName,
              brand.brandId,
              brand.sub,
              PLATFORM_ADS.FACEBOOK_ADS,
              false,
              getLocalStorage("agencyType") === "ld"
                ? "ld-am/brands"
                : "bp-am/brands"
            );
          }
        } else {
          if (payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS) {
            redirectToGoogleAccountConnectFromBrandList(
              brand.brandName,
              brand.brandId,
              brand.sub,
              PLATFORM_ADS.GOOGLE_ADS
            );
          } else {
            redirectToFacebookAccountConnect(
              brand.brandName,
              brand.brandId,
              brand.sub,
              PLATFORM_ADS.FACEBOOK_ADS,
              false,
              getLocalStorage("agencyType") === "ld"
                ? "ld-am/brands"
                : "bp-am/brands"
            );
          }
        }
      }
    }
  };
  const filterAccounts = (adAccount) => {
    // debugger;
    if (!accountSelected) {
      if (payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS) {
        return adAccount.platform === PLATFORM.GOOGLE;
      } else {
        return adAccount.platform === PLATFORM.FACEBOOK;
      }
    } else {
      if (accountSelected === PLATFORM.GOOGLE.toLowerCase()) {
        return adAccount.platform === PLATFORM.GOOGLE;
      } else {
        return adAccount.platform === PLATFORM.FACEBOOK;
      }
    }

    // return accountSelected === "google" ||
    //   payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS
    //   ? adAccount.platform === PLATFORM.GOOGLE
    //   : adAccount.platform === PLATFORM.FACEBOOK;
  };
  return isLoading || isFetching ? (
    <>
      <PmaxLoader />
    </>
  ) : (
    <Modal
      open={accountListModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <Box sx={style}>
          <Close
            onClick={() => {
              openAccountListModal(false);
            }}
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              cursor: "pointer",
            }}
          />
          <StyledIconContainer
            icon={
              accountSelected
                ? accountSelected
                : payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS
                ? PLATFORM.GOOGLE.toLowerCase()
                : PLATFORM.FACEBOOK.toLowerCase()
            }
          >
            <img
              src={
                accountSelected
                  ? accountSelected === PLATFORM.GOOGLE.toLowerCase()
                    ? google_icon_big
                    : facebook_pricing_icon
                  : payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS
                  ? google_icon_big
                  : facebook_pricing_icon
              }
              alt="google"
              width={
                accountSelected
                  ? accountSelected === PLATFORM.GOOGLE.toLowerCase()
                    ? 30
                    : 52
                  : payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS
                  ? 30
                  : 52
              }
              height={
                accountSelected
                  ? accountSelected === PLATFORM.GOOGLE.toLowerCase()
                    ? 30
                    : 52
                  : payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS
                  ? 30
                  : 52
              }
            />
          </StyledIconContainer>
          <div>
            <StyledAccountListText>
              {brand.brandName
                ? brand.brandName
                : payloadFromGoogleAuth.brandName}
            </StyledAccountListText>
            <StyledAccountParaText>
              Please add the respective brand ad accounts here. You can also
              remove the ad accounts which are already connected.
            </StyledAccountParaText>
            <StyledAccountList>
              {data.data
                .filter(filterAccounts)
                .map(({ adAccountId, adAccountName, permissions }) => (
                  <SelectedAccountCard
                    adAccountId={adAccountId}
                    adAccountName={adAccountName}
                    permissions={permissions}
                    brand={brand}
                    deleteAdAccountCallback={deleteAdAccountCallback}
                    payloadFromGoogleAuth={payloadFromGoogleAuth}
                    key={adAccountId}
                    setRemoveAdAccount={setRemoveAdAccount}
                  />
                ))}
            </StyledAccountList>
            <StyledFooterButton onClick={onAccountDisplayCallback}>
              <StyledFooterText>
                <StyledPlusText>+</StyledPlusText> Add More Accounts
              </StyledFooterText>
            </StyledFooterButton>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default SelectedAccountListModal;
