import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, IconButton, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { campaign_publish_modal_icon as infoIcon } from '../../assets/icons/campaign-approval/campaign_approval';

const SegmentDeleteModal = ({
    handleClose,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 504,
    height: 371,
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 3.5,
    padding: '3rem 3rem 0 3rem',
    // height: '380px',
    borderRadius: '14px',
    outline: 'none',
  };
   // const [deleteModal, setDeleteModal] = useState(true);
  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Close
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1rem',
            cursor: 'pointer'
          }}
           onClick={() => handleClose()}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            gap: '0.5rem',
          }}
        >
          <div>
            <img src={infoIcon} alt='' width={64} height={64} />
          </div>
          <div style={{ marginTop: '1.5rem' }}>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 22,
                textAlign: 'center',
                width: '18rem',
                lineHeight: 1.25,
              }}
            >
              This segment cannot be deleted 
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                color: '#6F6C90',
                textAlign: 'center',
              }}
            >
              This segment is being used in several asset groups. Please unlink it from
               those asset groups before proceeding with deletion of the segment.
            </Typography>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default SegmentDeleteModal;
