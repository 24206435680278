import React, { useEffect, useRef, useState } from 'react';
import PMaxDetailedView from '../PMaxStrategyCreation/PMaxDetailedView';
import PMaxSearchTerms from '../PMaxStrategyCreation/PMaxSearchTerms';
import '../PMaxStrategyCreation/pmax.scss';
import { Button, Typography, TextField, Tooltip } from '@mui/material';
import infoIcon from '../PMaxStrategyCreation/pmaxIcons/info-icon.svg';
import readIcon from '../PMaxStrategyCreation/pmaxIcons/read-icon.svg';
import selectedReadIcon from '../PMaxStrategyCreation/pmaxIcons/selected-read-icon.svg';
import writeIcon from '../PMaxStrategyCreation/pmaxIcons/write-icon.svg';
import selectedWriteIcon from '../PMaxStrategyCreation/pmaxIcons/selected-write-icon.svg';
import CampaignConfirmationModal from '../PMaxStrategyCreation/CampaignConfirmationModal';

import AssetGroupTabListView from '../PMaxStrategyCreation/AssetGroupTabListView';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewAsset,
  changeAssetGroup,
  changeAssetGroupValue,
  changeCampaign,
  clearAllIndex,
  clearValidationError,
  confirmAssetGroup,
  deleteAssetGroup,
  generateAdCopies,
  generateExtendedThemes,
  getAdAssets,
  getKeywords,
  getPmaxStrategies,
  getResources,
  getSegments,
  maintainAsset,
  postAssetGroup,
  postKeywords,
  postThemes,
  putAssetGroup,
  removeAssetGroup,
  saveChanges,
  selectCampaign,
  setDeleteIndex,
  updateAssetGroup,
  updateAssetStatusFlag,
  updateCampFlag,
  updateGetThemes,
  updateGoBackFlag,
  updatePublishFlag,
  updateStartLoader,
  updateToBeSaved,
  updateToBeStored,
  updateValidationError,
  updateValidationErrorLocal,
} from '../../api/pmaxStrategy/slice';
import useInterval from '../../hooks/useInterval';
import OpenCampaignView from '../PMaxStrategyCreation/OpenCampaignView';
import PmaxLoader from '../PMaxStrategyCreation/PmaxLoader';
import {
  goToSpecificCompoClientAm,
  updateTab,
} from '../../api/accountManager/componentTracking/action';
import ExpandAssetNameGroupTooltip from '../TooltipIndicators/ExpandAssetNameGroupTooltip';
import ErrorTooltip from '../TooltipIndicators/ErrorTooltip';
import InfoTooltipForBudget from '../TooltipIndicators/InfoTooltipForBudget';
import { api } from '../../api';
import {
  accountManagerIds,
  accountManagerIdsHDM,
  BACKEND_STATUS,
  componentWithIndexHDMAm,
  ldComponentWithIndexAm,
} from '../../utils/accountManagerUtil';
import GoBackConfirmationModal from '../PMaxStrategyCreation/GoBackConfirmationModal';
import {
  getFormByFormIdReducer,
  updateFormReducer,
} from '../../api/accountManager/forms/slice';
import { goToSpecificCompoHDMAM } from '../../api/HDMAM/componentTracking/action';
import useGoogleCampaignBudget from '../AccountManager/useGoogleCampaignBudget';
import hdmAmRoutePaths from '../../pages/hdm-am/constants/hdmAmRoutePaths';
import { useNavigate, useParams, generatePath } from 'react-router-dom';
import PMaxThemes from '../PMaxStrategyCreation/PMaxThemes';

const PMaxStrategyCreation = ({ campName = 'campaign1' }) => {
  const navigate = useNavigate();
  const {formId} = useParams();
  const [confirmModal, setConfirmModal] = useState(false);
  const publishFlag = useSelector((state) => state.pmaxStrategy.publishFlag);
  const clientForm = useSelector((state) => state.hdmFormAm);
  const currentCamp = useSelector((state) => state.pmaxStrategy[campName]);
  const previousCamp = useSelector(selectCampaign);
  const campaignId = useSelector(
    (state) => state.pmaxStrategy.strategyId[campName]
  );
  const campaign1Array = useSelector((state) => state.pmaxStrategy.campaign1);
  const campaign2Array = useSelector((state) => state.pmaxStrategy.campaign2);
  const campaign3Array = useSelector((state) => state.pmaxStrategy.campaign3);
  const currentAssetGroup = useSelector(
    (state) => state.pmaxStrategy.currentIndex[campName]
  );
  const currCampBudget = useSelector(
    (state) => state.pmaxStrategy.budget[campName]
  );
  const currAssetCounts = useSelector(
    (state) => state.pmaxStrategy.assetCounts[campName]
  );
  const currentAsset = currentCamp[currentAssetGroup];
  const value = currentAsset?.value || 'Theme';
  const [errors, setErrors] = useState(currentAsset?.errors || {});
  const keywordsFromStore = useSelector((state) => state.pmaxStrategy.keywords);
  const commonTheme = useSelector((state) => state.pmaxStrategy.commonTheme);
  const commonThemes = useSelector((state) => state.pmaxStrategy.commonThemes);
  const [savedStatus, setSavedStatus] = useState(
    currentAsset?.savedStatus || false
  );
  const [status, setStatus] = useState(currentAsset?.status || 'ONGOING');
  const [read, setIsRead] = useState(true);
  const [name, setName] = useState(currentAsset?.name || '');
  const { pmaxBudget } = useGoogleCampaignBudget({ agencyType: 'HDM' });
  const [budget, setBudget] = useState(currCampBudget);
  const [segmentNamePredefined, setSegmentNamePredefined] = useState(
    currentAsset?.segment?.type === 'PREDEFINED' &&
      (currentAsset?.segment?.name || '')
  );
  const [segmentNameNew, setSegmentNameNew] = useState(
    currentAsset?.segment?.type === 'NEW' && (currentAsset?.segment?.name || '')
  );
  const [targetingType, setTargetingType] = useState(
    currentAsset?.segment?.targetingType ||
      'People who searched for any of these terms on Google'
  );
  const [keywords, setKeywords] = useState(
    currentAsset?.segment?.keywords || keywordsFromStore
  );
  const [type, setType] = useState(currentAsset?.segment?.type || 'NEW');
  const [theme, setTheme] = useState(currentAsset?.theme || commonTheme);
  const [assetNumber, setAssetNumber] = useState(
    currentAsset?.assetNumber || currAssetCounts
  );
  const [themes, setThemes] = useState(currentAsset?.themes || commonThemes);
  const [headlines, setHeadlines] = useState(currentAsset?.headlines || []);
  const [longHeadlines, setLongHeadlines] = useState(
    currentAsset?.longHeadlines || []
  );
  const [descriptions, setDescriptions] = useState(
    currentAsset?.descriptions || []
  );
  const [imageUrls, setimageUrls] = useState(currentAsset?.imageUrls || []);
  const [logoUrls, setlogoUrls] = useState(currentAsset?.logoUrls || []);
  const [ytVideosMetadata, setytVideosMetadata] = useState(
    currentAsset?.ytVideosMetadata || []
  );
  const startLoader = useSelector((state) => state.pmaxStrategy.startLoader);
  const [loaderMessage, setLoaderMessage] = useState('');
  const customSegments = useSelector(
    (state) => state.pmaxStrategy.customSegments
  );
  const imageAssetLibrary = useSelector(
    (state) => state.pmaxStrategy.imageAssetLibrary
  );
  const logoAssetLibrary = useSelector(
    (state) => state.pmaxStrategy.logoAssetLibrary
  );
  const videoAssetLibrary = useSelector(
    (state) => state.pmaxStrategy.videoAssetLibrary
  );
  const getResourcesFlag = useSelector(
    (state) => state.pmaxStrategy.getResourcesFlag
  );
  const getThemesFlag = useSelector(
    (state) => state.pmaxStrategy.getThemesFlag
  );
  const getKeywordsFlag = useSelector(
    (state) => state.pmaxStrategy.getKeywordsFlag
  );
  const toBeSaved = useSelector((state) => state.pmaxStrategy.toBeSaved);
  const [addedNewTerm, setAddedNewTerm] = useState(false);
  const [addedNewKeyword, setAddedNewKeyword] = useState(false);
  const dispatch = useDispatch();
  let isAssetNameEmpty = Boolean(!name);
  let isBudgetZero = Boolean(!budget);
  let isBudgetLessThanZero = Boolean(parseInt(budget) < 0);
  let isSegmentNamePredefinedEmpty = Boolean(!segmentNamePredefined);
  let isSegmentNameNewEmpty = Boolean(!segmentNameNew);
  let isKeywordsEmpty = Boolean(!keywords.length);
  const [backModal, setBackModal] = useState(false);
  const goBackFlag = useSelector((state) => state.pmaxStrategy.goBackFlag);
  const inputRef = useRef(null);
  const keywordRef = useRef(null);
  const [focusValue, setFocusValue] = useState(false);
  const [focusKeyword, setFocusKeyword] = useState(false);

  useEffect(() => {
    if (!currCampBudget) {
      if (campName === 'campaign1') return setBudget(pmaxBudget);
      return setBudget(0);
    }
    setBudget(currCampBudget);
  }, [currCampBudget, pmaxBudget, campName]);

  const textStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  };
  const budgetTextStyle = {
    ...textStyle,
    marginLeft: -212,
  };

  const goToSelectorScreen = () => {
    dispatch(
      goToSpecificCompoHDMAM(
        componentWithIndexHDMAm[
          accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY
        ]
      )
    );
  };

  useEffect(() => {
    if (publishFlag) {
      dispatch(updateStartLoader(true));
      const data = {
        googlePmaxStrategy: { status: 'COMPLETED' },
      };
      dispatch(saveChanges());
      dispatch(updateFormReducer(data));
      dispatch(updatePublishFlag(false));
      setConfirmModal(false);
      goToSelectorScreen();
    } else {
      dispatch(updateStartLoader(false));
    }
  }, [publishFlag]);

  useEffect(() => {
    if (previousCamp.length) {
      dispatch(updateAssetGroup({ budget, ...getDetails() }));
    }
    setIsRead(true);
    dispatch(changeCampaign(campName));
  }, [campName]);

  useEffect(() => {
    if (!startLoader) setLoaderMessage('');
  }, [startLoader]);

  useEffect(() => {
    if (goBackFlag) {
      const isCampaign1NotSaved = campaign1Array.some(
        (asset) => asset?.savedStatus === false
      );
      const isCampaign2NotSaved = campaign2Array.some(
        (asset) => asset?.savedStatus === false
      );
      const isCampaign3NotSaved = campaign3Array.some(
        (asset) => asset?.savedStatus === false
      );

      if (isCampaign1NotSaved || isCampaign3NotSaved || isCampaign2NotSaved)
        setBackModal(true);
      else goToSelectorScreen();

      dispatch(updateGoBackFlag(false));
    }
  }, [goBackFlag]);

  useInterval(
    () => {
      dispatch(generateExtendedThemes());
    },
    !getThemesFlag ? null : 5 * 1000
  );

  useInterval(
    () => {
      dispatch(getResources());
    },
    !getResourcesFlag ? null : 5 * 1000
  );

  // useInterval(
  //     () => { dispatch(getKeywords()) },
  //     !getKeywordsFlag ? null : 5 * 1000
  // );

  useEffect(() => {
    dispatch(updateStartLoader(true));
    dispatch(updateAssetGroup({ value: 'Theme' }));
    dispatch(clearAllIndex());
    dispatch(getPmaxStrategies());
    dispatch(getAdAssets());
    dispatch(getSegments());
    dispatch(getKeywords());
  }, []);

  useEffect(() => {
    if (toBeSaved) {
      dispatch(updateStartLoader(true));
      const data = {
        googlePmaxStrategy: { status: BACKEND_STATUS.ONGOING },
      };
      dispatch(updateFormReducer(data));
      dispatch(putAssetGroup());
      dispatch(updateToBeSaved());
    }
  }, [toBeSaved]);

  // useEffect(() => {
  //     return () => { console.log('unmounted...'); dispatch(updateAssetGroup(getDetails())); }
  // }, []);

  // useEffect(() => {
  //     return () => { console.log('unmounted...'); dispatch(updateAssetGroup(getDetails())); }
  // }, []);

  useEffect(() => {
    if (currentCamp.length) {
      setStatus(currentAsset?.status || 'ONGOING');
      setName(currentAsset?.name || '');
      setAssetNumber(currentAsset?.assetNumber || currAssetCounts);
      if (currentAsset?.segment?.type === 'NEW') {
        setSegmentNameNew(currentAsset?.segment?.name || '');
        customSegments.length &&
          setSegmentNamePredefined(customSegments[0].name);
      } else setSegmentNamePredefined(currentAsset?.segment?.name);
      setTargetingType(
        currentAsset?.segment?.targetingType ||
          'People who searched for any of these terms on Google'
      );
      setErrors(currentAsset?.errors || {});
      setSavedStatus(currentAsset?.savedStatus || false);
      setKeywords(currentAsset?.segment?.keywords || keywordsFromStore);
      setType(currentAsset?.segment?.type || 'NEW');
      setTheme(currentAsset?.theme || commonTheme);
      setBudget(currCampBudget);
      setThemes(currentAsset?.themes || commonThemes);
      setHeadlines(currentAsset?.headlines || []);
      setLongHeadlines(currentAsset?.longHeadlines || []);
      setDescriptions(currentAsset?.descriptions || []);
      setimageUrls(currentAsset?.imageUrls || []);
      setlogoUrls(currentAsset?.logoUrls || []);
      setytVideosMetadata(currentAsset?.ytVideosMetadata || []);
    }
  }, [JSON.stringify(currentAsset)]);

  const changeStatus = () => {
    if (status !== 'ONGOING') {
      setStatus('ONGOING');
    }
  };

  const changeKeywords = (e, index) => {
    const newKeywords = keywords.slice();
    newKeywords[index] = e.target.value;
    setKeywords(newKeywords);
    changeStatus();
    setSavedStatus(false);
  };

  useEffect(() => {
    if (focusKeyword) {
      if (keywords[keywords.length - 1] || !keywords.length) setKeywords([...keywords, '']);
      changeStatus();
      setSavedStatus(false);
      setAddedNewKeyword(true);
      setFocusKeyword(false);
    }
  }, [focusKeyword]);

  const addKeywords = () => {
    setAddedNewKeyword(false);
    setFocusKeyword(true);
  };

  const removeKeywords = (index) => {
    const newKeywords = keywords.slice();
    newKeywords.splice(index, 1);
    setKeywords(newKeywords);
    changeStatus();
    setSavedStatus(false);
    setAddedNewKeyword(false);
  };

  const handleSelectTheme = (index) => {
    const newThemes = themes.slice();
    newThemes[index] = {
      ...newThemes[index],
      status: !newThemes[index].status,
    };
    setThemes(newThemes);
    changeStatus();
    setSavedStatus(false);
  };

  const change = (e, index) => {
    switch (value) {
      case 'Theme':
        setTheme(e.target.value);
        break;
      case 'Headline':
        const newHeadlineList = headlines.slice();
        newHeadlineList[index] = e.target.value;
        setHeadlines(newHeadlineList);
        break;
      case 'Long Headline':
        const newLongHeadlineList = longHeadlines.slice();
        newLongHeadlineList[index] = e.target.value;
        setLongHeadlines(newLongHeadlineList);
        break;
      case 'Description':
        const newDescriptionList = descriptions.slice();
        newDescriptionList[index] = e.target.value;
        setDescriptions(newDescriptionList);
        break;
      case 'Image':
        const newimageUrls = imageUrls.slice();
        newimageUrls[index] = e.target.value;
        setimageUrls(newimageUrls);
        break;
      case 'Logo':
        const newlogoUrls = logoUrls.slice();
        newlogoUrls[index] = e.target.value;
        setlogoUrls(newlogoUrls);
        break;
      default:
        console.log('Wrong value');
    }
    changeStatus();
    setSavedStatus(false);
  };

  const remove = (index) => {
    switch (value) {
      case 'Headline':
        const newHeadlineList = headlines.slice();
        newHeadlineList.splice(index, 1);
        setHeadlines(newHeadlineList);
        break;
      case 'Long Headline':
        const newLongHeadlineList = longHeadlines.slice();
        newLongHeadlineList.splice(index, 1);
        setLongHeadlines(newLongHeadlineList);
        break;
      case 'Description':
        const newDescriptionList = descriptions.slice();
        newDescriptionList.splice(index, 1);
        setDescriptions(newDescriptionList);
        break;
      case 'Image':
        const newimageUrls = imageUrls.slice();
        newimageUrls.splice(index, 1);
        setimageUrls(newimageUrls);
        break;
      case 'Logo':
        const newlogoUrls = logoUrls.slice();
        newlogoUrls.splice(index, 1);
        setlogoUrls(newlogoUrls);
        break;
      case 'Videos':
        const newVideoUrls = ytVideosMetadata.slice();
        newVideoUrls.splice(index, 1);
        setytVideosMetadata(newVideoUrls);
        break;
      default:
        console.log('Wrong value');
    }
    changeStatus();
    setAddedNewTerm(false);
    setSavedStatus(false);
  };

  useEffect(() => {
    if (focusValue) {
      switch (value) {
        case 'Headline':
          if (headlines[headlines.length - 1] || !headlines.length) setHeadlines([...headlines, '']);
          break;
        case 'Long Headline':
          if (longHeadlines[longHeadlines.length - 1] || !longHeadlines.length)
            setLongHeadlines([...longHeadlines, '']);
          break;
        case 'Description':
          if (descriptions[descriptions.length - 1] || !descriptions.length)
            setDescriptions([...descriptions, '']);
          break;
        default:
          console.log('Wrong value');
      }

      changeStatus();
      setSavedStatus(false);
      setAddedNewTerm(true);
      setFocusValue(false);
    }
  }, [focusValue]);

  const add = () => {
    setAddedNewTerm(false);
    setFocusValue(true);
  };

  useEffect(() => {
    if (addedNewTerm) {
      inputRef.current.focus();
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [addedNewTerm]);

  useEffect(() => {
    if (addedNewKeyword) {
      keywordRef.current.focus();
      keywordRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [addedNewKeyword]);

  const handleAssetGroupName = (e) => {
    changeStatus();
    setSavedStatus(false);
    setName(e.target.value);
  };

  const getDetails = () => {
    let segment = {};
    setAddedNewTerm(false);
    if (type === 'PREDEFINED')
      segment = {
        type,
        name: segmentNamePredefined,
      };
    else
      segment = {
        type,
        name: segmentNameNew,
        targetingType,
        keywords,
      };
    return {
      status,
      savedStatus,
      name,
      budget,
      value,
      theme,
      themes,
      headlines: headlines.filter((headline) => headline !== ''),
      longHeadlines: longHeadlines.filter(
        (longHeadline) => longHeadline !== ''
      ),
      descriptions: descriptions.filter((description) => description !== ''),
      imageUrls,
      logoUrls,
      ytVideosMetadata,
      segment: { ...segment },
      errors: { ...errors },
    };
  };

  const handleSetValue = (value) => {
    dispatch(updateAssetGroup({ ...getDetails(), value }));
  };

  const handleConfirmAssetGroup = () => {
    const assetDetail = getDetails();
    if (assetDetail.segment?.keywords)
      dispatch(
        updateAssetGroup({
          ...assetDetail,
          errors: {},
          segment: {
            ...assetDetail.segment,
            keywords: assetDetail.segment.keywords.filter(
              (keyword) => keyword !== ''
            ),
          },
        })
      );
    else dispatch(updateAssetGroup({ ...assetDetail, errors: {} }));
    if (
      isAssetNameEmpty ||
      (isSegmentNameNewEmpty && type === 'NEW') ||
      isBudgetZero ||
      isBudgetLessThanZero
    ) {
      if (isAssetNameEmpty)
        dispatch(
          updateValidationError({
            name: 'Asset Name cannot be empty',
          })
        );
      if (isSegmentNameNewEmpty && type === 'NEW')
        dispatch(
          updateValidationError({
            segmentName: 'Segment Name cannot be empty',
          })
        );
      if (isBudgetZero) {
        dispatch(
          updateValidationError({
            budget: 'Budget cannot be zero',
          })
        );
      } else if (isBudgetLessThanZero) {
        dispatch(
          updateValidationError({
            budget: 'Budget should be greater than zero',
          })
        );
      }

      let countRepeat = 0;

      for (const asset of currentCamp)
        if (asset.name && asset.name === name) countRepeat += 1;

      if (countRepeat > 1) {
        dispatch(
          updateValidationError({
            name: 'Duplicate Asset Group names are not allowed',
          })
        );
      }

      return;
    }

    dispatch(confirmAssetGroup());
    setAddedNewKeyword(false);
    setIsRead(true);
  };

  const handleAddNewAsset = (index) => {
    dispatch(updateAssetGroup({ ...getDetails() }));
    setAddedNewKeyword(false);
    setIsRead(true);
    dispatch(addNewAsset(index));
  };

  const handleChangeAssetGroup = (index) => {
    if (index !== currentAssetGroup) {
      dispatch(updateAssetGroup(getDetails()));
      setAddedNewKeyword(false);
      dispatch(changeAssetGroup({ [campName]: index }));
    }
  };

  const handleRemoveAssetGroup = (index) => {
    dispatch(setDeleteIndex(index));
    dispatch(deleteAssetGroup());
  };

  const handleGenerateThemes = () => {
    if (!theme) return;
    dispatch(clearValidationError());
    setLoaderMessage('We are generating themes');
    dispatch(updateStartLoader(true));
    dispatch(
      updateAssetGroup({
        ...getDetails(),
        status: 'ONGOING',
        savedStatus: false,
      })
    );
    dispatch(maintainAsset({ campaign: campName, asset: currentAssetGroup }));
    dispatch(postThemes());
  };

  const handleGenerateAdCopies = () => {
    const selectedThemes = [];
    themes.forEach((theme) => {
      if (theme.status === true) selectedThemes.push(theme);
    });
    if (!selectedThemes.length || selectedThemes.length > 5) return;
    dispatch(clearValidationError());
    setLoaderMessage(
      'We are generating content based on the themes selected by you'
    );
    dispatch(updateStartLoader(true));
    dispatch(
      updateAssetGroup({
        ...getDetails(),
        status: 'ONGOING',
        savedStatus: false,
      })
    );
    dispatch(maintainAsset({ campaign: campName, asset: currentAssetGroup }));
    dispatch(generateAdCopies());
  };

  const handleConfirmCampaign = () => {
    const isCampaign1Ongoing = campaign1Array.findIndex(
      (asset) => asset?.status === 'ONGOING'
    );
    const isCampaign2Ongoing = campaign2Array.findIndex(
      (asset) => asset?.status === 'ONGOING'
    );
    const isCampaign3Ongoing = campaign3Array.findIndex(
      (asset) => asset?.status === 'ONGOING'
    );

    if (isCampaign1Ongoing !== -1) {
      dispatch(changeAssetGroup(isCampaign1Ongoing));
      navigate(generatePath(`${hdmAmRoutePaths.strategyGoogle.fullbase}/${hdmAmRoutePaths.strategyGoogle.sub.campaign1Pmax}`, {formId}));
    } else if (isCampaign2Ongoing !== -1 && campaign2Array.length) {
      dispatch(changeAssetGroup({ campaign2: isCampaign2Ongoing }));
      navigate(generatePath(`${hdmAmRoutePaths.strategyGoogle.fullbase}/${hdmAmRoutePaths.strategyGoogle.sub.campaign2Pmax}`, {formId}));
    } else if (isCampaign3Ongoing !== -1 && campaign3Array.length) {
      dispatch(changeAssetGroup({ campaign3: isCampaign3Ongoing }));
      navigate(generatePath(`${hdmAmRoutePaths.strategyGoogle.fullbase}/${hdmAmRoutePaths.strategyGoogle.sub.campaign3Pmax}`, {formId}));
    } else {
      dispatch(updatePublishFlag(true));
    }
  };

  const handleBackButton = () => {
    dispatch(updateAssetGroup({ budget, ...getDetails() }));
    dispatch(updateGoBackFlag(true));
  };

  const handleDiscardButton = () => {
    setBackModal(false);
    goToSelectorScreen();
  };

  const handleSaveButton = () => {
    dispatch(updateStartLoader(true));
    dispatch(saveChanges());
    const data = {
      googlePmaxStrategy: { status: BACKEND_STATUS.ONGOING },
    };
    dispatch(updateFormReducer(data));
    goToSelectorScreen();
  };

  if (!campaignId && campName !== 'campaign1') return <OpenCampaignView />;

  return (
    <>
      <AssetGroupTabListView
        handleChangeAssetGroup={handleChangeAssetGroup}
        currCamp={currentCamp}
        assetNumber={assetNumber}
        currAssetCounts={currAssetCounts}
        currStatus={status}
        currAssetGroup={currentAssetGroup}
        handleAddNewAsset={handleAddNewAsset}
        handleRemoveAssetGroup={handleRemoveAssetGroup}
      />
      <div className='pmax-strategy-creation-container'>
        <div className='pmax-strategy-detail'>
          <Typography style={textStyle}>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 3.5,
              }}
            >
              Asset Group Name{' '}
              {errors?.name && <ErrorTooltip error={errors?.name} />}
            </span>{' '}
            :{' '}
            {read ? (
              <span className='pmax-field-value'>
                {name.length === 0 ? (
                  name
                ) : name.length > 14 ? (
                  <ExpandAssetNameGroupTooltip
                    assetGroupName={name}
                    shortAssetGroupName={name.substring(0, 15) + '...'}
                  />
                ) : (
                  name
                )}
              </span>
            ) : (
              <TextField
                variant='standard'
                value={name}
                placeholder='Asset Group name goes here'
                inputProps={{
                  style: {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,

                    lineHeight: '19px',
                    width: 232,
                    paddingBottom: 2,
                    border: 0,
                  },
                }}
                onChange={(e) => handleAssetGroupName(e)}
              />
            )}
          </Typography>
          <Typography style={budgetTextStyle}>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 3,
              }}
            >
              Daily Budget(in $)
              {!errors?.budget ? (
                <InfoTooltipForBudget />
              ) : (
                <ErrorTooltip error={errors.budget} />
              )}
            </span>
            :{' '}
            {read ? (
              <span className='pmax-field-value'>{budget}</span>
            ) : (
              <TextField
                variant='standard'
                value={budget}
                type='number'
                inputProps={{
                  style: {
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '19px',
                    width: 120,
                    paddingBottom: 2,
                    border: 0,
                  },
                }}
                onChange={(e) => {
                  setBudget(
                    Number(e.target.value) !== 0 && Number(e.target.value)
                  );
                  changeStatus();
                  setSavedStatus(false);
                }}
              />
            )}
          </Typography>
          <>
            <div
              className='read-write-icon'
              style={{
                display: 'flex',
                flexDirection: 'row',
                // marginTop: -4,
                // paddingBottom: 12,
                cursor: 'pointer',
              }}
            >
              <div
                className='read-icon'
                style={{
                  width: 40,
                  height: 40,
                  border: read ? '1px solid #0869FB' : '1px solid #CCCCCC',
                  borderRadius: '8px 0px 0px 8px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: read ? 'rgba(8, 105, 251, 0.07)' : '',
                }}
                onClick={() => {
                  setIsRead(true);
                  setAddedNewTerm(false);
                  setAddedNewKeyword(false);
                }}
              >
                <img src={read ? selectedReadIcon : readIcon} alt='' />
              </div>
              <div
                className='write-icon'
                style={{
                  width: 40,
                  height: 40,
                  border: !read ? '1px solid #0869FB' : '1px solid #CCCCCC',
                  borderRadius: '0px 8px 8px 0px',
                  // transform: 'matrix(-1, 0, 0, 1, 0, 0)',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: !read ? 'rgba(8, 105, 251, 0.07)' : '',
                }}
                onClick={() => setIsRead(false)}
              >
                <img src={!read ? selectedWriteIcon : writeIcon} alt='' />
              </div>
            </div>
          </>
        </div>
        <div className='pmax-search-term-and-detailed-view'>
          <PMaxSearchTerms
            read={read}
            setIsRead={setIsRead}
            addedNewKeyword={addedNewKeyword}
            keywordRef={keywordRef}
            segmentNamePredefined={segmentNamePredefined}
            setSegmentNamePredefined={setSegmentNamePredefined}
            segmentNameNew={segmentNameNew}
            setSegmentNameNew={setSegmentNameNew}
            type={type}
            setType={setType}
            targetingType={targetingType}
            setTargetingType={setTargetingType}
            customSegments={customSegments}
            changeStatus={changeStatus}
            setSavedStatus={setSavedStatus}
            errors={errors}
            keywords={keywords}
            addKeywords={addKeywords}
            changeKeywords={changeKeywords}
            removeKeywords={removeKeywords}
          />
          <PMaxDetailedView
            read={read}
            // setIsRead={setIsRead}
            addedNewTerm={addedNewTerm}
            inputRef={inputRef}
            value={value}
            handleSetValue={handleSetValue}
            theme={theme}
            themes={themes}
            handleSelectTheme={handleSelectTheme}
            headlines={headlines}
            longHeadlines={longHeadlines}
            descriptions={descriptions}
            setSavedStatus={setSavedStatus}
            changeStatus={changeStatus}
            imageUrls={imageUrls}
            setimageUrls={setimageUrls}
            logoUrls={logoUrls}
            setlogoUrls={setlogoUrls}
            ytVideosMetadata={ytVideosMetadata}
            setytVideosMetadata={setytVideosMetadata}
            change={change}
            add={add}
            remove={remove}
            imageAssetLibrary={imageAssetLibrary}
            logoAssetLibrary={logoAssetLibrary}
            videoAssetLibrary={videoAssetLibrary}
            errors={errors}
            handleGenerateThemes={handleGenerateThemes}
            handleGenerateAdCopies={handleGenerateAdCopies}
          />
        </div>
        <div className='preview_footer_row'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <button className='back' onClick={() => handleBackButton()}>
              BACK
            </button>

            <div
              className='footer_con'
              style={{ justifyContent: 'end', gap: 16, position: 'relative' }}
            >
              <div
                // className='footer_con'
                style={{
                  // position: 'absolute',
                  gap: 12,
                  // top: '1.5rem',
                  // left: 0,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img src={infoIcon} alt='info' width={16} height={16} />
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 12.5,
                    lineHeight: '17px',
                    color: '#7B7B7B',
                  }}
                >
                  New segments can only be added at this level
                </Typography>
              </div>
              <Button
                disabled={status === 'COMPLETED'}
                style={{
                  width: '172px',
                  height: '40px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textTransform: 'capitalize',
                  background: '#FFFFFF',
                  border: '1.5px solid #0869FB',
                  color: '#0869FB',
                  opacity: status === 'COMPLETED' ? 0.4 : 1,
                }}
                onClick={() => handleConfirmAssetGroup()}
              >
                Confirm Asset Group
              </Button>
              <Button
                disabled={
                  status === 'ONGOING' ||
                  currentCamp.some((asset) => asset?.status === 'ONGOING')
                }
                style={{
                  width: '172px',
                  height: '40px',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  textTransform: 'capitalize',
                  background: '#0869FB',
                  opacity:
                    status === 'ONGOING' ||
                    currentCamp.some((asset) => asset?.status === 'ONGOING')
                      ? 0.4
                      : 1,
                }}
                onClick={() => handleConfirmCampaign()}
              >
                Confirm Campaign
              </Button>
            </div>
          </div>
        </div>
        {startLoader && <PmaxLoader description={loaderMessage} />}
        {backModal && (
          <GoBackConfirmationModal
            clickHandler={setBackModal}
            discardHandler={handleDiscardButton}
            saveHandler={handleSaveButton}
          />
        )}
      </div>
    </>
  );
};

export default PMaxStrategyCreation;
