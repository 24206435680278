const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const account = `${imageBaseUrl}/assets/60646fbf-f20a-4e52-aaca-9f007c094661`;
export const credentials = `${imageBaseUrl}/assets/092833dd-fba2-41ea-a707-d4e3174ee853`;
export const logOutIcon = `${imageBaseUrl}/assets/bbbdc42f-74a6-4d97-ad93-2dc6e32f4c52`;
export const copyIcon = `${imageBaseUrl}/assets/f838bbe7-1f48-4cb0-b162-53d7f56d7857`;
export const menuIcon = `${imageBaseUrl}/assets/e9e06643-baac-4e8e-8507-bbe4e0c29c98`;
export const searchIcon = `${imageBaseUrl}/assets/0a609528-6dab-4e0f-969f-97b74823faf0`;
export const infoIcon = `${imageBaseUrl}/assets/8a858e81-17ad-4793-912d-336f27d82436`;
export const uncheckedIcon = `${imageBaseUrl}/assets/ffffae3e-1a49-4739-b0a6-2bfb06f021ce`;
export const checkedIcon = `${imageBaseUrl}/assets/a3155ec4-66ce-41fb-ae4d-e1f733db10b4`;
export const addAccountImage = `${imageBaseUrl}/assets/cd8219cb-fdd5-444e-bbfc-20d393030bf3`;
export const crossIcon = `${imageBaseUrl}/assets/6ffff381-b45f-429c-a0a1-f3c55699aa17`;
export const addIcon = `${imageBaseUrl}/assets/4b115cf1-1443-4800-8d3b-277bb63fdb40`;
export const trashIcon = `${imageBaseUrl}/assets/b1048692-0c20-4cb1-9cf2-3a289edc498d`;
export const warningInfoIcon = `${imageBaseUrl}/assets/81512b6a-3e8e-4c83-bf9e-2e8a705d3591`;
export const infoIconNew = `${imageBaseUrl}/assets/589d90a1-9903-4d13-8bd2-61298da678b0`;
