import { v4 as uuid } from 'uuid';
import { CREATIVE_OBJECT_STATUS_ENUM, PLATFORM_NAME, AB_TEST_STATUS_ENUM, AB_TEST_SECTION_ENUM } from '../api/creativeApproval/enums';

export const prepareAdCopy = (marketingPlatform) => {
    if (marketingPlatform === PLATFORM_NAME.FACEBOOK)
        return {
            id: uuid(),
            status: CREATIVE_OBJECT_STATUS_ENUM.PENDING,
            marketingPlatform: PLATFORM_NAME.FACEBOOK,
            theme: '',
            campaignName: '',
            updatedOn: new Date().toISOString(),
            headlines: [],
            descriptions: [],
            primaryText: [],
            type: 'AdCopy'
        }
    else
        return {
            id: uuid(),
            status: CREATIVE_OBJECT_STATUS_ENUM.PENDING,
            marketingPlatform: PLATFORM_NAME.GOOGLE,
            theme: '',
            campaignName: '',
            updatedOn: new Date().toISOString(),
            headlines: [],
            descriptions: [],
            type: 'AdCopy'
        }
}

export const prepareNewCreative = (marketingPlatform) => {
    return {
        id: uuid(),
        status: CREATIVE_OBJECT_STATUS_ENUM.PENDING,
        marketingPlatform: marketingPlatform === PLATFORM_NAME.FACEBOOK ? PLATFORM_NAME.FACEBOOK : PLATFORM_NAME.GOOGLE,
        theme: '',
        campaignName: '',
        updatedOn: new Date().toISOString(),
        creativeImagess: [],
        type: 'Creative'
    }
}

export const prepareNewLandingPage = (marketingPlatform) => {
    return {
        id: uuid(),
        status: CREATIVE_OBJECT_STATUS_ENUM.PENDING,
        marketingPlatform: marketingPlatform === PLATFORM_NAME.FACEBOOK ? PLATFORM_NAME.FACEBOOK : PLATFORM_NAME.GOOGLE,
        theme: '',
        campaignName: '',
        updatedOn: new Date().toISOString(),
        link: '',
        type: 'LandingPage'
    }
}
export const INITIAL_ABTEST = [
        {
            section: AB_TEST_SECTION_ENUM.MESSAGING_TESTS,
            questions: [
                {
                    question: 'Headlines text based on target audience',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Headline tests in final CTA section',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Headline tests in form section',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Value prop/selling point testing',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Button CTA testing',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Long copy vs short copy testing',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Section handling testing',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
            ],
        },
        {
            section: AB_TEST_SECTION_ENUM.FORM_TESTS,
            questions: [
                {
                    question: 'Multi-step vs single step forms',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Form placement tests (hero vs footer)',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'CTA tests for forms',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Number of fields in form',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Foot-in-the-door form vs traditional form',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Expectation management tests',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Trust badge tests',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
            ],
        },
        {
            section: AB_TEST_SECTION_ENUM.ADDITIONAL_TESTS,
            questions: [
                {
                    question: 'Testing Different imagery/video in Hero',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Customer review placement testing',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Testing messaging on pop ups/ stickybars',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Offer testing (10% off vs $200 off)',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Testing different designs (iconography vs photography)',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
                {
                    question: 'Wedge method testing',
                    status: AB_TEST_STATUS_ENUM.NOT_STARTED
                },
            ],
        },
    ];

export const selectAllAdCopies = (state) => state.creativeApproval.adCopies;
export const selectSingleAdCopy = (state, id) => state.creativeApproval.adCopies.find(e => e.id === id);
export const selectAbTestSection = (state, section) => state.creativeApproval.abTests.find(e => e.section === section);
export const selectIsError = (state) => state.creativeApproval.isError;
