import { useMutation, useQuery } from "@tanstack/react-query"
import { api } from "../api"
import { queryClient } from "../utils/queryClient"
import { BACKEND_STATUS } from "../utils/accountManagerUtil"

const getGooglePmaxStrategyQueryKey = formId => 
    ["pmax-strategy/:formId", formId]

const getSpecificPmaxStrategyQueryKey = (formId, strategyId) => 
    ["pmax-strategy/:formId/:strategyId", formId, strategyId]

export const useGetListPmaxStrategies = (formId, generationType=null, options={}) => {
    return useQuery(
        getGooglePmaxStrategyQueryKey(formId),
        async () => {
            let response;
            console.log('Generation type:', generationType);
            if (generationType === null)
               response = await api.get(`/client-forms/${formId}/pmax-strategy`);
            else
               response = await api.get(`/client-forms/${formId}/pmax-strategy?generationType=${generationType}`);
            return response.data;
        },
        options
    )
}

export const useGetPmaxStrategyByStrategyId = (formId, strategyId, options={}) => {
    return useQuery(
        getSpecificPmaxStrategyQueryKey(formId, strategyId),
        async () => {
            const response = await api.get(`/client-forms/${formId}/pmax-strategy/${strategyId}`);
            return response.data;
        },
        options,
    )
}

// create new strategy
export const usePostPmaxStrategy = (formId, options={}) => {
    const { onSuccess, ...otherOptions } = options;
    return useMutation({
        mutationFn: async (payload={}) => {
            const body = {
                ...payload,
                status: BACKEND_STATUS.ONGOING,
                assetCount: 1,
                strategyCreationType: 'CUSTOM',
                startDate: new Date().toISOString(),
                assets: [{
                    "name": "Asset Group 1",
                    assetNumber: 1,
                    status: BACKEND_STATUS.ONGOING,
                    ...payload
                }]
            }
            const response = await api.post(`/client-forms/${formId}/pmax-strategy`, body);
            return response.data;
        },
        onSuccess: (...args) => {
            // Invalidate and refresh
            // queryClient.invalidateQueries({ queryKey: getGooglePmaxStrategyQueryKey(formId) });
            onSuccess?.(...args);
        },
        ...otherOptions
    })
}

export const usePutPmaxStrategy = (formId, options={}) => {
    return useMutation({
        mutationFn: async (payload={}) => {
            const { strategyId, status, ...fields } = payload;
            const data = {
                ...fields,
                status: status ? status : BACKEND_STATUS.ONGOING,
            };
            const response = await api.put(`/client-forms/${formId}/pmax-strategy/${strategyId}`, data);
            return response.data;
        },
        ...options,
    })
}

export const usePutPmaxStrategiesParallel = (formId, options={}) => {
    return useMutation({
        mutationFn: async (strategies = []) => {
            const response = await Promise.all(strategies.map(strategy => {
                return api.put(`/client-forms/${formId}/pmax-strategy/${strategy.id}`, strategy);
            }));
            return response;
        },
        onSuccess: (...args) => {
            queryClient.invalidateQueries({ queryKey: getGooglePmaxStrategyQueryKey(formId) });
        },
        ...options,
    })
}

export const useDeletePmaxAssetGroup = (formId, strategyId, options={}) => {
    return useMutation({
        mutationFn: async (adsetId="") => {
            const response = await api.delete(`/client-forms/${formId}/pmax-strategy/${strategyId}/assets?asset_ids=${adsetId}`);
            return response.data;
        },
        ...options
    })

}

export const useDeletePmaxCampaign = (formId, options={}) => {
    return useMutation({
        mutationFn: async (strategyId="") => {
            const response = await api.delete(`/client-forms/${formId}/pmax-strategy/${strategyId}/assets`);
            return response.data;
        },
        ...options
    });
}
