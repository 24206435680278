import { TextField, Typography } from "@mui/material";
import { website_icon as WEBSITE } from "../../../../../assets/icons/socialmedia/socialmedia";
import GoToNextCompoButton from "../../../GoToNextCompoButton";
import { goBackHDMAmCompo } from "../../../../../api/HDMAM/componentTracking/action";
import { useDispatch, useSelector } from "react-redux";
import "./website.css";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../../../ButtonPrimary/Buttons";
import hdmAmRoutePaths from "../../../../../pages/hdm-am/constants/hdmAmRoutePaths";

const WebsiteAnalysis = ({ websiteVal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  const form = useSelector((store) => store.hdmFormAm);
  return (
    <div style={{ width: "100%" }}>
      <div className="website_bk">
        <div
          style={{
            width: "56.84px",
            height: "56.84px",
            background: "#FFFFFF",
            boxShadow: "0px 13.3214px 17.7619px rgba(27, 33, 40, 0.08)",
            borderRadius: "10.6571px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={WEBSITE} alt="" />
        </div>

        <Typography
          style={{
            fontFamily: "Lato",
            fontSize: "35.52px",
            fontWeight: "700",
            lineHeight: "46.18px",
            color: "#000000",
            marginLeft: "14.21px",
          }}
        >
          {" "}
          Analysis Webiste
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        {/* <TextField placeholder='Please add text here...' id="outlined-multiline-static" InputProps={{style:{
                        height:'186px',
                        width:'1047px',
                        padding:'0px'
                        
                    }}}/> */}
        <TextField
          className="text-field"
          multiline
          rows={5}
          placeholder="Please add text here..."
          InputProps={{
            style: {
              height: "186px",
              width: "1047px",
              border: "1px solid #F0F0F0",
              background: "#F0F0F0",
            },
          }}
          value={form?.accountAnalysis?.website}
          onChange={(e) =>
            dispatch({
              type: "UPDATE_VALUE_ACCOUNT_ANALYSIS_HDM_AM",
              payload: { key: "website", value: e.target.value },
            })
          }
        />
      </div>
      <div className="preview_footer_row">
        <div className="footer_con">
          <button
            className="back"
            onClick={() =>
              navigate(
                generatePath(
                  `${hdmAmRoutePaths.accountAnalysis.fullbase}/${hdmAmRoutePaths.accountAnalysis.sub.facebook}`,
                  { formId }
                )
              )
            }
          >
            BACK
          </button>
          <PrimaryButton
            title="Next"
            onClick={() =>
              navigate(
                generatePath(
                  `${hdmAmRoutePaths.accountAnalysis.fullbase}/${hdmAmRoutePaths.accountAnalysis.sub.creatives}`,
                  { formId }
                )
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
export default WebsiteAnalysis;
