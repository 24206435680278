
import { useMutation } from "@tanstack/react-query"
import { api } from "../api"

export const usePostStrategyCampaignCreation = (formId) => {
    return useMutation({
        mutationFn: async () => {
            const response = await api.post(`/dynamic-campaign/${formId}/create-campaigns`);
            return response.data;
        },
    })
}