import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  form: null,
  fetchStatus: false,
  auditReport: {
    success: false,
  }
};

export const clientFormsSlice = createSlice({
  name: 'client-forms',
  initialState,
  reducers: {
    updateFieldInFormReducer() {},
    getFormDetailsReducer() {},
    getPerformanceAuditData() {},
    updatePerformanceAuditData() {},
    getAuditReport() {},
    setClientForm: (state, action) => {
      state.form = action.payload;
    },
    setSpendsQuestionForm: (state, action) => {
      state.form.salesQuestionnaire.spends = {
        ...state,
        [action.payload.value]: action.payload.value,
      };
    },
    setPerformanceQuestionForm: (state, action) => {
      state.salesQuestionnaire.performance = {
        ...state,
        [action.payload.value]: action.payload.value,
      };
    },
    updateFormData: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state.form[key] = action.payload[key];
      })
    },
    updateSharedAsset: (state, action) => {
      const tempState = { ...JSON.parse(JSON.stringify(state.form)) };
      const { infoType, key, key2, key3, value } = action.payload;
      tempState[infoType][key][key2][key3] = value;
      state.form = tempState;
    },
    updateSharedAssetToReadAccess: (state, action) => {
      const tempState = { ...JSON.parse(JSON.stringify(state.form)) };
      const { infoType, key, key2, key3, value } = action.payload;
      tempState[infoType][key][key2][key3] = value;
      tempState[infoType][key][key2]["status"] = "READ_ACCESS";
      state.form = tempState;
    },
    updateFetchStatus: (state, action) => {
      state.fetchStatus = action.payload;
    },
    updateAuditReport: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state.auditReport[key] = action.payload[key];
      })
    },
    updateFilterAudits: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state.auditReport.filteredAudits[key] = action.payload[key]
      })
    }
  },
});

export const {
  updateFieldInFormReducer,
  getFormDetailsReducer,
  setClientForm,
  setSpendsQuestionForm,
  setPerformanceQuestionForm,
  updateFormData,
  updateSharedAsset,
  updateSharedAssetToReadAccess,
  updateFetchStatus,
  updateAuditReport,
  updateFilterAudits,
  getAuditReport,
  getPerformanceAuditData,
  updatePerformanceAuditData,
} = clientFormsSlice.actions;

export default clientFormsSlice.reducer;
