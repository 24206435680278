// google campaign selector stages
export const GOOGLE_CAMPAIGN_SELECTOR_STAGES = {
  Strategy: "strategy",
  Campaign: "campaign",
};

export const PMaxCampaignName = {
  campaign1: "Campaign 1",
  campaign2: "Campaign 2",
  campaign3: "Campaign 3"
}