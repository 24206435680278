export const ageValid =(minAgeRange,maxAgeRange,ageStatus)=>{
    
    if(minAgeRange && (minAgeRange<13 || minAgeRange >64) ){
        return false
    }else if(maxAgeRange && maxAgeRange >65){
        return false
    }else if (minAgeRange && maxAgeRange && minAgeRange > maxAgeRange){
        return false
    }else if (minAgeRange && maxAgeRange && maxAgeRange < minAgeRange){
        return false
    }else {
        return true
    }
}