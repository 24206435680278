import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: 'ONGOING',
    campaign1: [],
    campaign2: [],
    campaign3: [],
    customSegments: [],
    imageAssetLibrary: [],
    logoAssetLibrary: [],
    videoAssetLibrary: [],
    keywords: [],
    toBeSaved: false,
    currentCampaign: 'campaign1',
    maintainCampaign: "campaign1",
    maintainAssetGroup: 0,
    deleteAssetGroupIndex: 0,
    commonTheme: '',
    commonThemes: [],
    directSegments: [],
    goBackFlag: false,
    getThemesFlag: false,
    getResourcesFlag: false,
    getKeywordsFlag: false,
    publishFlag: false,
    addAsset: false,
    assetStatusFlag: false,
    startLoader: false,
    currentIndex: {
        "campaign1": 0,
        "campaign2": 0,
        "campaign3": 0,
    },
    strategyId: {
        "campaign1": 0,
        "campaign2": 0,
        "campaign3": 0,
    },
    budget: {
        "campaign1": 0,
        "campaign2": 0,
        "campaign3": 0,
    },
    assetCounts: {
        "campaign1": 0,
        "campaign2": 0,
        "campaign3": 0
    }
};

const pmaxStrategy = createSlice({
    name: "pmaxStrategy",
    initialState,
    reducers: {
      getPmaxStrategies() {},
      getSegments() {},
      getKeywords() {},
      getThemes() {},
      getResources() {},
      getAdAssets() {},
      postAssetGroup() {},
      putAssetGroup() {},
      deleteAssetGroup() {},
      postKeywords() {},
      postThemes() {},
      generateAdCopies() {},
      generateExtendedThemes() {},
      saveChanges() {},
      startCronPMaxStrategy() {},
      callGetThemes(state, action) {
          state.getThemesFlag = action.payload;
      },
      callGetResources(state, action) {
          state.getResourcesFlag = action.payload;
      },
      callGetKeywords(state, action) {
          state.getKeywordsFlag = action.payload;
      },
      addCustomSegments(state, action) {
          state.customSegments = action.payload;
      },
      updateAllCampaigns(state, action) {
          const { 
              campaign1, 
              campaign2, 
              campaign3,
              strategyId1,
              strategyId2,
              strategyId3,
              budget1,
              budget2,
              budget3,
              assetCount1,
              assetCount2,
              assetCount3,
          } = action.payload;
          state.campaign1 = campaign1.slice();
          state.campaign2 = campaign2.slice();
          state.campaign3 = campaign3.slice();
          state.strategyId = { 
              ...state.strategyId,
              campaign1: strategyId1,
              campaign2: strategyId2,
              campaign3: strategyId3,
          };
          state.budget = {
              ...state.budget,
              campaign1: budget1,
              campaign2: budget2,
              campaign3: budget3
          };
          state.assetCounts = {
              ...state.assetCounts,
              campaign1: assetCount1,
              campaign2: assetCount2,
              campaign3: assetCount3,
          }
      },
      updateCampaignBudget(state, action) {
          state.budget[state.currentCampaign] = action.payload;  
      },
      updateValidationError(state, action) {
          const campAsset = state[state.currentCampaign];
          campAsset[state.currentIndex[state.currentCampaign]].errors = { ...(campAsset[state.currentIndex[state.currentCampaign]]?.errors || {}), ...action.payload };
      },
      clearValidationError(state) {
          const campAsset = state[state.currentCampaign];
          campAsset[state.currentIndex[state.currentCampaign]].errors = { };
      },
      addAssetLibrary(state, action) {
          const { imageLibrary, logoLibrary, videoLibrary } = action.payload;
          state.imageAssetLibrary = imageLibrary.slice();
          state.logoAssetLibrary = logoLibrary.slice();
          state.videoAssetLibrary = videoLibrary.slice();
      },
      addThemes(state, action) {
          const campAsset = state[state.maintainCampaign];
          campAsset[state.maintainAssetGroup].themes = action.payload;
          console.log(action.payload);
      },
      addResources(state, action) {
          const { headlines, longHeadlines, descriptions } = action.payload;
          const campAsset = state[state.maintainCampaign];
          campAsset[state.maintainAssetGroup].headlines = headlines.slice();
          campAsset[state.maintainAssetGroup].longHeadlines = longHeadlines.slice();
          campAsset[state.maintainAssetGroup].descriptions = descriptions.slice();
      },
      changeAssetGroup(state, action) {
          state.currentIndex = { ...state.currentIndex, ...action.payload };
      },
      changeAssetGroupValue(state, action) {
          const value = action.payload;
          const campAsset = state[state.currentCampaign];
          campAsset[state.currentIndex[state.currentCampaign]].value = value;
          console.log('value action ran...');
      },
      updateAssetGroup(state, action) {
          const { budget, ...assetDetails } = action.payload;
          const campAsset = state[state.currentCampaign];

          state.budget[state.currentCampaign] = budget;  
          campAsset[state.currentIndex[state.currentCampaign]] = {
              ...campAsset[state.currentIndex[state.currentCampaign]],
              ...(assetDetails || {}),
          };
      },
      updateCommonThemeAndThemes(state, action) {
          const { theme, themes } = action.payload;
          state.commonTheme = theme;
          state.commonThemes = themes.slice();
      },
      confirmAssetGroup(state) {
          state.toBeSaved = true;
      },
      removeAssetGroup(state, action) {
          const index = action.payload;
          const campAsset = state[state.currentCampaign];
          if (index > 0 && campAsset.length === 2) {
              state.currentIndex[state.currentCampaign] = index - 1;
              campAsset.splice(index, 1);
          } else if (index > 0 && campAsset.length > 2) {
               if (index === state.currentIndex[state.currentCampaign]) {
                  state.currentIndex[state.currentCampaign] = state.currentIndex[state.currentCampaign] - 1;
                  campAsset.splice(index, 1);
               } else {
                   if (state.currentIndex[state.currentCampaign] > index)
                     state.currentIndex[state.currentCampaign] = state.currentIndex[state.currentCampaign] - 1;
                  campAsset.splice(index, 1);
               }
              // state.currentIndex[state.currentCampaign] = index;
          } else if (index === 0 && campAsset.length > 1) {
              state.currentIndex[state.currentCampaign] = index + 1;
              campAsset.splice(index, 1);
              state.currentIndex[state.currentCampaign] = index;
          } else if (index === 0 && campAsset.length === 1) {
              campAsset.splice(0, 1, {});
          } 
      },
      addStrategyId(state, action) {
          const id = action.payload;
          state.strategyId[state.currentCampaign] = id;
      },
      addNewAsset(state, action) {
          const index = action.payload;
          const count = state.assetCounts[state.currentCampaign];
          state[state.currentCampaign].push({ value: 'Theme', savedStatus: false, status: 'ONGOING', assetNumber: count + 1, name: `Asset Group ${count + 1}`, segment: { type: 'NEW', name: `Segment ${count + 1}` } });
          state.currentIndex[state.currentCampaign] = index;
          state.assetCounts[state.currentCampaign] = count + 1;
      },
      updateToBeSaved(state) {
          state.toBeSaved = !state.toBeSaved;
      },
      setDeleteIndex(state, action) {
          state.deleteAssetGroupIndex = action.payload;
      },
      maintainAsset(state, action) {
          state.maintainCampaign = action.payload.campaign;
          state.maintainAssetGroup = action.payload.asset;
      },
      changeCampaign(state, action) {
          state.currentCampaign = action.payload;
      },
      updateAssetStatusFlag(state, action) {
          state.assetStatusFlag = action.payload;
      },
      updateStartLoader(state, action) {
          state.startLoader = action.payload;
      },
      updateKeywords(state, action) {
          state.keywords = action.payload;
      },
      clearAllIndex(state) {
          state.currentIndex = {
              ...state.currentIndex,
              campaign1: 0,
              campaign2: 0,
              campaign3: 0,
          }
      },
      updateGoBackFlag(state, action) {
          state.goBackFlag = action.payload;
      },
      updatePublishFlag(state,action) {
          state.publishFlag = action.payload;
      },
      updateDirectSegments(state,action) {
        state.directSegments = action.payload;
    }
    },
});

export const {
    getPmaxStrategies,
    getSegments,
    getKeywords,
    getThemes,
    getResources,
    getAdAssets,
    postAssetGroup,
    putAssetGroup,
    deleteAssetGroup,
    generateAdCopies,
    generateExtendedThemes,
    postThemes,
    postKeywords,
    addThemes,
    addResources,
    updateAllCampaigns,
    updateCampaignBudget,
    updateValidationError,
    clearValidationError,
    addCustomSegments,
    addAssetLibrary,
    callGetResources,
    callGetThemes,
    callGetKeywords,
    changeAssetGroup,
    updateAssetGroup,  // only stores in redux
    confirmAssetGroup,
    addStrategyId,
    updateCommonThemeAndThemes,
    addNewAsset,
    removeAssetGroup,
    changeAssetGroupValue,
    updateGetThemes,
    updateToBeSaved,
    setDeleteIndex,
    updateToBeDeleted,
    maintainAsset,
    changeCampaign,
    updateAssetStatusFlag,
    updateStartLoader,
    updateKeywords,
    clearAllIndex,
    saveChanges,
    updateGoBackFlag,
    updatePublishFlag,
    startCronPMaxStrategy,
    updateDirectSegments
} = pmaxStrategy.actions;

export const selectCampaign = state => 
    state.pmaxStrategy[state.pmaxStrategy.currentCampaign]

export default pmaxStrategy.reducer;

