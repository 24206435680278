import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

const ProfitIndexTable = (props) => {
    const hdmclient = useSelector((state) => state.hdmClient);
    const hdm = 2500;
    let roas = props.roas;
    let { averageOrderValue: aov, cogsPerAov: cogs } = hdmclient.onboardingInfo.aboutBusiness || {};
    const platformSelected = hdmclient?.onboardingInfo?.clientAccess?.platforms;
    let fbSpends = 0;
    if(platformSelected?.length){
        fbSpends = (platformSelected.find(platform => platform.platform === "facebook-ads") || {}).spends || 0;
    }
    let calRevenue = Number(fbSpends) + hdm;
    console.log("########## roas", roas);
    return (
        <TableContainer>
            <Table>
                <TableHead style={{ background: ' #F5F9FF' }}>
                    <TableRow>
                        <TableCell> <b>ROAS</b> </TableCell>
                        <TableCell> <b>Spends($)</b> </TableCell>
                        <TableCell> <b>Revenue($)</b> </TableCell>
                        <TableCell> <b>AOV($)</b> </TableCell>
                        <TableCell> <b>Sales</b> </TableCell>
                        <TableCell> <b>COGS (Per Unit)($)</b> </TableCell>
                        <TableCell> <b>Total COGS($)</b> </TableCell>
                        <TableCell> <b>HDM Payment($)</b> </TableCell>
                        <TableCell> <b>Profit($)</b> </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array(7).fill("").map((item, index) => {
                        if (index > 0) {
                            fbSpends = Number(index > 1 ? fbSpends * (150 / 100) : fbSpends).toFixed(1);
                            roas = ((roas < 1)
                                ? (index === 1 ? (calRevenue / fbSpends) : (index > 2 ? roas * (105 / 100) : roas * (120 / 100)))
                                : (index > 1 ? roas * (105 / 100) : roas * (140 / 100))
                            ).toFixed(1)
                        }
                        const revenue = ((roas < 1 && index === 1) ? (Number(fbSpends) + hdm) : (roas * fbSpends)).toFixed(0);
                        const sales = (revenue / aov).toFixed(0);
                        const totalCogs = (sales * cogs).toFixed(0);
                        const profit = (revenue - (Number(fbSpends) + hdm)).toFixed(0);
                        return (
                            <TableRow>
                                <TableCell>{roas}</TableCell>
                                <TableCell>${fbSpends}</TableCell>
                                <TableCell>${revenue}</TableCell>
                                <TableCell>${aov}</TableCell>
                                <TableCell>{sales}</TableCell>
                                <TableCell>${cogs}</TableCell>
                                <TableCell>${totalCogs}</TableCell>
                                <TableCell>${hdm}</TableCell>
                                <TableCell>{profit}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default ProfitIndexTable;