import { generatePath } from 'react-router-dom';
import { company_icon as companyIcon } from '../../../assets/icons/questionnare/questionnare';

import { contact_icon as contactIcon } from '../../../assets/icons/questionnare/questionnare';
import { email_icon as emailIcon } from '../../../assets/icons/questionnare/questionnare';
import hdmAmRoutePaths from '../../../pages/hdm-am/constants/hdmAmRoutePaths';
import { edit_icon as editIcon } from '../../../assets/icons/questionnare/questionnare';
import {
  componentWithIndexHDMClient,
  clientDashboardIds,
} from '../../../utils/HDMClientDashboardIdsUtil';
import agencyTypes from '../../../utils/agencyType';
import hdmClientRoutePaths from '../../../pages/hdm-client/constants/hdmClientRoutePaths';

const clientAndAccess = {
  businessName: {
    icon: companyIcon,
    questNumber: 1,
    questName: 'Business Name*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS1], //question number based on order of component rendering (clientDashboardUtil)
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.clientAndAccess}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}/1`
    ),
  },
  primaryContactPerson: {
    icon: contactIcon,
    questNumber: 2,
    questName: 'Primary Contact - Name*',
    questDescrip: '',
    checkbox: { text: 'Facebook Partner Request Sent:' },
    id: componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS2],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.clientAndAccess}/2`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}/2`
    ),
  },
  fbPageUrl: {
    icon: emailIcon,
    questNumber: 3,
    questName: 'Facebook Page URL*',
    questDescrip: '',
    checkbox: { text: 'Facebook Partner Request Sent:' },
    id: componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS3],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.clientAndAccess}/3`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}/3`
    ),
  },
  websiteUrl: {
    icon: emailIcon,
    questNumber: 4,
    questName: 'Website URL*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS4],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.clientAndAccess}/4`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}/4`
    ),
  },
  shopifyStoryAvailable: {
    questNumber: 5,
    questName: 'Shopify Store Available?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS5],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.clientAndAccess}/5`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}/5`
    ),
  },
  platforms: {
    questNumber: 6,
    questName: 'Please choose the platforms that you want us to work on*',
    questDescrip:
      'After choosing platforms, kindly mention their Spends and CPA to proceed.',
    id: componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS6],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.clientAndAccess}/6`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}/6`
    ),
  },
  preferredEmailAddress: {
    icon: emailIcon,
    questNumber: 7,
    questName: 'Preferred Email Address*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS7],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.clientAndAccess}/7`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}/7`
    ),
  },
  preferredPhoneNumber: {
    icon: emailIcon,
    questNumber: 8,
    questName: 'Preferred Phone Number*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS8],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.clientAndAccess}/8`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}/8`
    ),
  },
  businessAddress: {
      questNumber: 9,
      questName: 'Business Address',
      questions: [
        {
          question: 'Street Address',
          questDescrip: '',
          valueKey: 'streetAddress',
        },
        {
          question: 'City',
          questDescrip: '',
          valueKey: 'city',
        },
        {
          question: 'Province or state',
          questDescrip: '',
          valueKey: 'provinceOrState',
        },
        {
          question: 'Postal Code or Zip Code',
          questDescrip: '',
          valueKey: 'postalCode',
        },
        {
          question: 'Country',
          questDescrip: '',
          valueKey: 'country',
        },
      ],
      id: componentWithIndexHDMClient[clientDashboardIds.CLIENTANDACCESS9],
      amNavigationPath: generatePath(
        `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.clientAndAccess}/9`,
        { formId: localStorage.getItem("formId") || "" }
      ),
      clientNavigationPath: generatePath(
        `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.clientAndAccess}/9`
      ),
  }
};

const brandMessaging = {
  customerBaseCountries: {
    questNumber: 1,
    questName: '',
    question:
      'In which country does your customer base primarily lie? Please select multiple if required.*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING1],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/1`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/1`
    ),
  },
  esthetic: {
    questNumber: 2,
    questName: 'Esthetic',
    question: 'What esthetic appeal to your market?',
    questDescrip:
      '(Examples: Pared down. Simple. Clean. Elegant, Forest. Handmade. Pure. Clean.)',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING1],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/2`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/2`
    ),
  },
  tagline: {
    questNumber: 3,
    questName: 'Tagline',
    question:
      'What tagline(s) tells the entire story of your business in one sentence?',
    questDescrip: `Examples: “It's about women, Its about beauty, It’s about time”, “Nature comes home”, “Know your factories, know your costs, always ask why”`,
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING2],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/3`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/3`
    ),
  },
  emotionalResponse: {
    questNumber: 4,
    questName: 'Emotional Response',
    questions: [
      {
        question:
          'What emotional response do you want to elicit in your customers?',
        questDescrip: '(examples: revolution, mindfulness)',
        valueKey: 'emotionalResponse',
      },
      {
        question:
          'What do you want people to feel when they hear about your brand?',
        questDescrip: '',
        valueKey: 'feelWithBrand',
      },
      {
        question: 'What do you want people to think when they engage with you?',
        questDescrip: '',
        valueKey: 'engageWithBrand',
      },
    ],
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING3],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/4`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/4`
    ),
  },
  uniqueSellingPoint: {
    questNumber: 5,
    questName: 'Unique Selling Point',
    question: 'In 1-2 sentences describe your business?',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING4],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/5`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/5`
    ),
  },
  whatSetsYouApart: {
    questNumber: 6,
    questName:
      'Why Should Someone Buy from YOU vs. another brand selling a similar product?',
    question: 'What really sets you apart? What are your USPs?',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING5],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/6`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/6`
    ),
  },
  benefitsOfProducts: {
    questNumber: 7,
    questName: 'What are the top 3 benefits of your products?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING7],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/7`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/7`
    ),
  },

  painPoints: {
    questNumber: 8,
    questName: 'What are the top 3 pain points your products solve?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING8],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/8`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/8`
    ),
  },

  storyToPromote: {
    questNumber: 9,
    questName: 'What story do you want to promote: ',
    questDescrip:
      '(Summarized examples: the person behind the brand, nature to home, radical transparency)',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING9],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/9`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/9`
    ),
  },

  brandMessagingExampels: {
    questNumber: 10,
    questName: 'What are some brands with messaging that you really like?',
    questDescrip: '(List 5)',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING10],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/10`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/10`
    ),
  },

  brandFont: {
    questNumber: 11,
    questName: 'What are your brand fonts?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING11],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/11`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/11`
    ),
  },

  brandColor: {
    questNumber: 12,
    questName: 'What are your brand colours?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING12],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/12`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/12`
    ),
  },

  logoLink: {
    questNumber: 13,
    questName: 'Please add a link to your logo:',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.BRANDORMESSAGING13],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.brandOrMessaging}/13`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.brandOrMessaging}/13`
    ),
  },
};

const facebookTargeting = {
  AgeRange: {
    questNumber: 1,
    questName: 'What is the age range of your target customer base?*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE1],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.facebookTargeting}/1`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.facebookTargeting}/1`
    ),
  },
  gender: {
    questNumber: 2,
    questName: 'What is the gender of your target customer base?*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE2],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.facebookTargeting}/2`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.facebookTargeting}/2`
    ),
  },
  advertisingLocations: {
    questNumber: 3,
    questName: 'What locations do you advertise in?*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE3],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.facebookTargeting}/3`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.facebookTargeting}/3`
    ),
  },
  activitiesInterestHDM: {
    questNumber: 4,
    questName: 'What activities/interests do your best customers have?*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE4],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.facebookTargeting}/4`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.facebookTargeting}/4`
    ),
  },
  excludes: {
    questNumber: 5,
    questName:
      'Are there specific locations, markets, or groups of people we should exclude? Enter them here.',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE5],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.facebookTargeting}/5`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.facebookTargeting}/5`
    ),
  },
  formalCustomerPersonas: {
    questNumber: 6,
    questName: 'If you have formal customer personas please upload them here.',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE6],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.facebookTargeting}/6`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.facebookTargeting}/6`
    ),
  },
  otherCharacteristics: {
    questNumber: 7,
    questName:
      'Any other characteristic that describe your target audience not covered above? Enter them here.',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.TARGETAUDIENCE7],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.facebookTargeting}/7`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.facebookTargeting}/7`
    ),
  },
};

const googleTargeting = {
  ageRange: {
    questNumber: 1,
    questName: 'What is the age range of your customer base?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING1],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.googleTargeting}/1`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.googleTargeting}/1`
    ),
  },
  geoTargets: {
    questNumber: 2,
    questName: "Enter the locations where you'd like to run your ad*",
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING2],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.googleTargeting}/2`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.googleTargeting}/2`
    ),
  },
  gender: {
    questNumber: 3,
    questName: 'Which gender is your main customer base?*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING3],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.googleTargeting}/3`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.googleTargeting}/3`
    ),
  },
  activitiesInterest: {
    questNumber: 4,
    questName: 'What activities/interests do your best customers have?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING4],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.googleTargeting}/4`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.googleTargeting}/4`
    ),
  },
  excludeAudiences: {
    questNumber: 5,
    questName:
      'Are there specific locations, markets, or groups of people we should exclude?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING5],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.googleTargeting}/5`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.googleTargeting}/5`
    ),
  },
  customerIncomeRange: {
    questNumber: 6,
    questName: "What is your target customer's yearly household income?",
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING6],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.googleTargeting}/6`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.googleTargeting}/6`
    ),
  },
  customerEmployment: {
    questNumber: 7,
    questName:
      "Which of the following categories best describes your target customer's employment status?",
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.GOOGLETARGETING7],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.googleTargeting}/7`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.googleTargeting}/7`
    ),
  },
};

export const aboutBusiness = {
  productandservice: {
    questNumber: 1,
    questName: 'Please enter the products you want to work on*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS1],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/1`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/1`
    ),
  },
  noPromotionProduct: {
    questNumber: 2,
    questName: 'Any Products You Do NOT Want to Promote',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS2],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/2`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/2`
    ),
  },
  offers: {
    questNumber: 3,
    questName: 'Offers (discounts, bundles, special offers)',
    questDescrip:
      'Please list out any offers (specials/discounts) that can be used in campaigns',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS3],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/3`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/3`
    ),
  },
  averageOrderValue: {
    questNumber: 4,
    questName: 'Average Order Value (in $)*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS4],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/4`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/4`
    ),
  },
  cogsPerAov: {
    questNumber: 5,
    questName: 'COGS per AOV (in $)*',
    questDescrip: '(Cost of Goods Sold per AOV)',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS5],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/5`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/5`
    ),
  },
  shippingCostPerAov: {
    questNumber: 6,
    questName: 'Shipping Cost per AOV (in $)*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS6],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/6`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/6`
    ),
  },
  breakEvenPoint: {
    questNumber: 7,
    questName:
      'Your breakeven point that is, the MAXIMUM cost you can pay to acquire a customer (given your Average Order Value you mentioned above)? (in $)*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS7],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/7`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/7`
    ),
  },

  // fbCamaignBudget: {
  //   questNumber: 8,
  //   questName: "Campaign Budget (Facebook, in $)",
  //   questDescrip: "",
  //   id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS8],
  // },

  // googleCamaignBudget: {
  //   questNumber: 9,
  //   questName: "Campaign Budget (Google, in $)",
  //   questDescrip: "",
  //   id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS9],
  // },

  // tiktokCamaignBudget: {
  //   questNumber: 10,
  //   questName: "Campaign Budget (TikTok, in $)",
  //   questDescrip: "",
  //   id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS10],
  // },

  countryTargeted: {
    questNumber: 8,
    questName: 'Countries Targeted',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS11],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/8`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/8`
    ),
  },

  brandDescription: {
    questNumber: 9,
    questName: 'Describe your brand*',
    questDescrip:
      'Please start the description with your brand name and describe what the brand does/ sells. It would be great if you can mention some claims made by your brand or your USP, along with the type of audience you usually target. ',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS12],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/9`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/9`
    ),
  },

  keyCompetitors: {
    questNumber: 10,
    questName: 'Key Competitors*',
    questDescrip: '(local and multinational, list 5)',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS13],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/10`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/10`
    ),
  },

  brandsInterests: {
    //questNumber: localStorage.getItem('agencyId') === '1' ? 4 : 11,
    questNumber:
      localStorage.getItem('agencyType') === agencyTypes.LINEAR ? 4 : 11,
    questName:
      'Please mention a few interest keywords that you want us to target.*',
    questDescrip:
      'Try to keep the interest keywords generic (related to your brand or service, as it will help us in finding better target groups for you.',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS14],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/11`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/11`
    ),
  },

  commonJargons: {
    questNumber: 12,
    questName: 'Any common industry jargon we should be aware of?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS15],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/12`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/12`
    ),
  },

  monthlyRevenue: {
    questNumber: 13,
    questName: 'What is your monthly revenue goal? (in $)*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS16],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/13`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/13`
    ),
  },
  monthlyRevenueGoal3Months: {
    questNumber: 14,
    questName:
      'What is your monthly revenue goal for 3 months from now? (in $)*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS17],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/14`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/14`
    ),
  },
  monthlyRevenueGoal6Months: {
    questNumber: 15,
    questName:
      'What is your monthly revenue goal for 6 months from now? (in $)*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS18],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/15`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/15`
    ),
  },
  monthlyRevenueGoal1Year: {
    questNumber: 16,
    questName: 'What is your revenue goal for this year? (in $)*',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS19],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/16`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/16`
    ),
  },
  maximizeEnterpriseValueProfit: {
    questNumber: 17,
    questName:
      'Do you want to maximize enterprise value OR do you want to maximize profit?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS20],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/17`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/17`
    ),
  },
  requirementsForUs: {
    questNumber: 18,
    questName:
      "What would we have to do in the next 3-6 months to make sure you're happy with this relationship? What do you need from us?",
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS21],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/18`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/18`
    ),
  },
  dobHomeAddress: {
    questNumber: 19,
    questName: 'What is your DOB + Home Address?',
    questDescrip: '(we may have a special surprise around your birthday)',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS22],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/19`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/19`
    ),
  },
  runMontageAds: {
    icon: editIcon,
    questNumber: 20,
    questName: 'Do you want to run Montage Ads?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.ABOUTYOURBUSSINESS23],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.aboutYourBusiness}/20`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.aboutYourBusiness}/20`
    ),
  }
};

export const creativeAndMontageAds = {
  videoReviews: {
    questNumber: 1,
    questName: 'Video Reviews',
    questDescrip: '(Links or Google Drive folders please)',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD1],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/1`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/1`
    ),
  },

  googleDriveEmail: {
    questNumber: 2,
    questName: 'Do you have a Google Drive specific email?',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD2],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/2`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/2`
    ),
  },

  googleDriveLink: {
    questNumber: 3,
    questName: 'Your Google Drive Link is:',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD3],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/3`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/3`
    ),
  },
  pocForProductDeliveries: {
    questNumber: 4,
    questName: 'Key Point of Contact for Product Delivery to Creators',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD4],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/4`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/4`
    ),
  },

  products: {
    questNumber: 5,
    questName: 'List of Products:',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD5],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/5`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/5`
    ),
  },

  creatorPersona: {
    questNumber: 6,
    questName:
      'Creator Persona (Age, Gender, Distribtuion, Country, Interests, desired aesthetic) - The More You Add Here The Better.',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD6],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/6`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/6`
    ),
  },

  shootNotes: {
    questNumber: 7,
    questName: 'Shoot Notes:',
    questDescrip:
      '(Which products are most important to highlight? Any products that should be paired? Any specific notes for shooting your product? How would you like your product displayed?)',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD7],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/7`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/7`
    ),
  },

  desiredCta: {
    questNumber: 8,
    questName: 'Desired Call to Action',
    questDescrip: '(what do you want people to do)',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD8],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/8`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/8`
    ),
  },

  promoCode: {
    questNumber: 9,
    questName: 'Promo Code + Terms of Promo',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD9],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/9`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/9`
    ),
  },
  uspOfProductsSent: {
    questNumber: 10,
    questName:
      "Unique Selling Points of Products You Sent (3-5 Recommended) - If Same As Above you can just write 'Same'",
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD10],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/10`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/10`
    ),
  },

  visualReferences: {
    questNumber: 11,
    questName: 'Links to Visual References:',
    questDescrip:
      '(Please provide any video/photo examples outlining your brand’s desired aesthetic)',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD11],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/11`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/11`
    ),
  },

  others: {
    questNumber: 12,
    questName: 'Anything else to Add:',
    questDescrip: '',
    id: componentWithIndexHDMClient[clientDashboardIds.CREATIVEANDMONTAGEAD12],
    amNavigationPath: generatePath(
      `${hdmAmRoutePaths.onboardingForm.fullbase}/${hdmAmRoutePaths.onboardingForm.sub.creatives}/12`,
      { formId: localStorage.getItem('formId') || '' }
    ),
    clientNavigationPath: generatePath(
      `${hdmClientRoutePaths.onboardingForm.fullbase}/${hdmClientRoutePaths.onboardingForm.sub.creatives}/12`
    ),
  },
};

export const sourcesOfLeads = [
  'United States of America',
  'Spain',
  'Portugal',
  'France',
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Aruba',
  'Åland Islands',
  'Zimbabwe',
  'Zambia',
  'Yemen',
  'Western Sahara',
  'Wallis and Futuna',
  'Virgin Islands (U.S.)',
  'Viet Nam',
  'Venezuela (Bolivarian Republic of)',
  'Vanuatu',
  'Uzbekistan',
  'Uruguay',
  'United States Minor Outlying Islands (the)',
  'United Kingdom of Great Britain and Northern Ireland (the)',
  'United Arab Emirates (the)',
  'Ukraine',
  'Uganda',
  'Tuvalu',
  'Turks and Caicos Islands (the)',
  'Turkmenistan',
  'Turkey',
  'Tunisia',
  'Trinidad and Tobago',
  'Tonga',
  'Tokelau',
  'Togo',
  'Timor-Leste',
  'Thailand',
  'Tanzania, United Republic of',
  'Tajikistan',
  'Taiwan (Province of China)',
  'Syrian Arab Republic',
  'Switzerland',
  'Sweden',
  'Svalbard and Jan Mayen',
  'Sweden',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Sri Lanka',
  'South Sudan',
  'South Georgia and the South Sandwich Islands',
  'South Africa',
  'Somalia',
  'Solomon Islands',
  'Slovenia',
  'Slovakia',
  'Sint Maarten (Dutch part)',
  'Singapore',
  'Sierra Leone',
  'Seychelles',
  'Serbia',
  'Senegal',
  'Saudi Arabia',
  'Sao Tome and Principe',
  'San Marino',
  'Samoa',
  'Saint Vincent and the Grenadines',
  'Saint Pierre and Miquelon',
  'Saint Martin (French part)',
  'Saint Lucia',
  'Saint Kitts and Nevis',
  'Saint Helena, Ascension and Tristan da Cunha',
  'Saint Barthélemy',
  'Réunion',
  'Rwanda',
  'Russia',
  'Romania',
  'Republic of North Macedonia',
  'Qatar',
  'Puerto Rico',
  'Poland',
  'Pitcairn',
  'Philippines (the)',
  'Peru',
  'Paraguay',
  'Papua New Guinea',
  'Panama',
  'Palestine, State of',
  'Palau',
  'Pakistan',
  'Oman',
  'Norway',
  'Northern Mariana Islands (the)',
  'Norfolk Island',
  'Niue',
  'Nigeria',
  'Niger (the)',
  'Nicaragua',
  'New Zealand',
  'New Caledonia',
  'Netherlands (the)',
  'Nepal',
  'Nauru',
  'Namibia',
  'Myanmar',
  'Mozambique',
  'Morocco',
  'Montserrat',
  'Montenegro',
  'Mongolia',
  'Monaco',
  'Moldova (the Republic of)',
  'Micronesia (Federated States of)',
  'Mexico',
  'Mayotte',
  'Mauritius',
  'Mauritania',
  'Martinique',
  'Marshall Islands (the)',
  'Malta',
  'Mali',
  'Maldives',
  'Malaysia',
  'Malawi',
  'Madagascar',
  'Macao',
  'Luxembourg',
  'Lithuania',
  'Liechtenstein',
  'Libya',
  'Liberia',
  'Lesotho',
  'Lebanon',
  'Latvia',
  "Lao People's Democratic Republic (the)",
  'Kyrgyzstan',
  'Kuwait',
  'Korea (the Republic of)',
  "Korea (the Democratic People's Republic of)",
  'Kiribati',
  'Kenya',
  'Kazakhstan',
  'Jordan',
  'Jersey',
  'Japan',
  'Jamaica',
  'Italy',
  'Israel',
  'Ireland',
  'Iraq',
  'Iran (Islamic Republic of)',
  'Indonesia',
  'India',
  'Iceland',
  'Hungary',
  'Hong Kong',
  'Honduras',
  'Holy See (the)',
  'Heard Island and McDonald Islands',
  'Haiti',
  'Guyana',
  'Guinea-Bissau',
  'Guinea',
  'Guernsey',
  'Guatemala',
  'Guam',
  'Guadeloupe',
  'Grenada',
  'Greenland',
  'Greece',
  'Gibraltar',
  'Ghana',
  'Germany',
  'Georgia',
  'Gambia (the)',
  'Gabon',
  'French Southern Territories (the)',
  'French Polynesia',
  'French Guiana',
  'Finland',
  'Fiji',
  'Faroe Islands (the)',
  'Falkland Islands (the) [Malvinas]',
  'Ethiopia',
  'Eswatini',
  'Estonia',
  'Eritrea',
  'Equatorial Guinea',
  'El Salvador',
  'Egypt',
  'Ecuador',
  'Dominican Republic (the)',
  'Dominica',
  'Djibouti',
  'Denmark',
  "Côte d'Ivoire",
  'Czechia',
  'Cyprus',
  'Curaçao',
  'Cuba',
  'Croatia',
  'Costa Rica',
  'Cook Islands (the)',
  'Congo (the)',
  'Congo (the Democratic Republic of the)',
  'Comoros (the)',
  'Colombia',
  'Cocos (Keeling) Islands (the)',
  'Christmas Island',
  'China',
  'Chile',
  'Chad',
  'Central African Republic (the)',
  'Cayman Islands (the)',
  'Canada',
  'Cameroon',
  'Cambodia',
  'Cabo Verde',
  'Burundi',
  'Burkina Faso',
  'Bulgaria',
  'Brunei Darussalam',
  'British Indian Ocean Territory (the)',
  'Brazil',
  'Bouvet Island',
  'Botswana',
  'Bosnia and Herzegovina',
  'Bonaire, Sint Eustatius and Saba',
  'Bolivia (Plurinational State of)',
  'Bhutan',
  'Bermuda',
  'Benin',
  'Belize',
  'Belgium',
  'Belarus',
  'Barbados',
  'Bangladesh',
  'Bahrain',
  'Bahamas (the)',
  'Azerbaijan',
  'Austria',
  'Australia',
];
export const advtLoctaion = [
  'Kolhapur',
  'Mumbai',
  'Pune',
  'Aurangabad',
  'Raigadh',
];

export const mappedQuestion = {
  clientAccess: clientAndAccess,
  brandMessaging: brandMessaging,
  facebookTargeting: facebookTargeting,
  googleTargeting: googleTargeting,
  aboutBusiness: aboutBusiness,
  creativeAndMontageAds: creativeAndMontageAds,
};
export const Leads = {
  country_regions: [
    {
      name: 'United States of America (the)',
      alpha_2_code: 'US',
      alpha_3_code: 'USA',
      numeric_code: '840',
    },
    {
      name: 'Afghanistan',
      alpha_2_code: 'AF',
      alpha_3_code: 'AFG',
      numeric_code: '4',
    },
    {
      name: 'Albania',
      alpha_2_code: 'AL',
      alpha_3_code: 'ALB',
      numeric_code: '8',
    },
    {
      name: 'Algeria',
      alpha_2_code: 'DZ',
      alpha_3_code: 'DZA',
      numeric_code: '12',
    },
    {
      name: 'American Samoa',
      alpha_2_code: 'AS',
      alpha_3_code: 'ASM',
      numeric_code: '16',
    },
    {
      name: 'Andorra',
      alpha_2_code: 'AD',
      alpha_3_code: 'AND',
      numeric_code: '20',
    },
    {
      name: 'Angola',
      alpha_2_code: 'AO',
      alpha_3_code: 'AGO',
      numeric_code: '24',
    },
    {
      name: 'Anguilla',
      alpha_2_code: 'AI',
      alpha_3_code: 'AIA',
      numeric_code: '660',
    },
    {
      name: 'Antarctica',
      alpha_2_code: 'AQ',
      alpha_3_code: 'ATA',
      numeric_code: '10',
    },
    {
      name: 'Antigua and Barbuda',
      alpha_2_code: 'AG',
      alpha_3_code: 'ATG',
      numeric_code: '28',
    },
    {
      name: 'Argentina',
      alpha_2_code: 'AR',
      alpha_3_code: 'ARG',
      numeric_code: '32',
    },
    {
      name: 'Armenia',
      alpha_2_code: 'AM',
      alpha_3_code: 'ARM',
      numeric_code: '51',
    },
    {
      name: 'Aruba',
      alpha_2_code: 'AW',
      alpha_3_code: 'ABW',
      numeric_code: '533',
    },
    {
      name: 'Australia',
      alpha_2_code: 'AU',
      alpha_3_code: 'AUS',
      numeric_code: '36',
    },
    {
      name: 'Austria',
      alpha_2_code: 'AT',
      alpha_3_code: 'AUT',
      numeric_code: '40',
    },
    {
      name: 'Azerbaijan',
      alpha_2_code: 'AZ',
      alpha_3_code: 'AZE',
      numeric_code: '31',
    },
    {
      name: 'Bahamas (the)',
      alpha_2_code: 'BS',
      alpha_3_code: 'BHS',
      numeric_code: '44',
    },
    {
      name: 'Bahrain',
      alpha_2_code: 'BH',
      alpha_3_code: 'BHR',
      numeric_code: '48',
    },
    {
      name: 'Bangladesh',
      alpha_2_code: 'BD',
      alpha_3_code: 'BGD',
      numeric_code: '50',
    },
    {
      name: 'Barbados',
      alpha_2_code: 'BB',
      alpha_3_code: 'BRB',
      numeric_code: '52',
    },
    {
      name: 'Belarus',
      alpha_2_code: 'BY',
      alpha_3_code: 'BLR',
      numeric_code: '112',
    },
    {
      name: 'Belgium',
      alpha_2_code: 'BE',
      alpha_3_code: 'BEL',
      numeric_code: '56',
    },
    {
      name: 'Belize',
      alpha_2_code: 'BZ',
      alpha_3_code: 'BLZ',
      numeric_code: '84',
    },
    {
      name: 'Benin',
      alpha_2_code: 'BJ',
      alpha_3_code: 'BEN',
      numeric_code: '204',
    },
    {
      name: 'Bermuda',
      alpha_2_code: 'BM',
      alpha_3_code: 'BMU',
      numeric_code: '60',
    },
    {
      name: 'Bhutan',
      alpha_2_code: 'BT',
      alpha_3_code: 'BTN',
      numeric_code: '64',
    },
    {
      name: 'Bolivia (Plurinational State of)',
      alpha_2_code: 'BO',
      alpha_3_code: 'BOL',
      numeric_code: '68',
    },
    {
      name: 'Bonaire, Sint Eustatius and Saba',
      alpha_2_code: 'BQ',
      alpha_3_code: 'BES',
      numeric_code: '535',
    },
    {
      name: 'Bosnia and Herzegovina',
      alpha_2_code: 'BA',
      alpha_3_code: 'BIH',
      numeric_code: '70',
    },
    {
      name: 'Botswana',
      alpha_2_code: 'BW',
      alpha_3_code: 'BWA',
      numeric_code: '72',
    },
    {
      name: 'Bouvet Island',
      alpha_2_code: 'BV',
      alpha_3_code: 'BVT',
      numeric_code: '74',
    },
    {
      name: 'Brazil',
      alpha_2_code: 'BR',
      alpha_3_code: 'BRA',
      numeric_code: '76',
    },
    {
      name: 'British Indian Ocean Territory (the)',
      alpha_2_code: 'IO',
      alpha_3_code: 'IOT',
      numeric_code: '86',
    },
    {
      name: 'Brunei Darussalam',
      alpha_2_code: 'BN',
      alpha_3_code: 'BRN',
      numeric_code: '96',
    },
    {
      name: 'Bulgaria',
      alpha_2_code: 'BG',
      alpha_3_code: 'BGR',
      numeric_code: '100',
    },
    {
      name: 'Burkina Faso',
      alpha_2_code: 'BF',
      alpha_3_code: 'BFA',
      numeric_code: '854',
    },
    {
      name: 'Burundi',
      alpha_2_code: 'BI',
      alpha_3_code: 'BDI',
      numeric_code: '108',
    },
    {
      name: 'Cabo Verde',
      alpha_2_code: 'CV',
      alpha_3_code: 'CPV',
      numeric_code: '132',
    },
    {
      name: 'Cambodia',
      alpha_2_code: 'KH',
      alpha_3_code: 'KHM',
      numeric_code: '116',
    },
    {
      name: 'Cameroon',
      alpha_2_code: 'CM',
      alpha_3_code: 'CMR',
      numeric_code: '120',
    },
    {
      name: 'Canada',
      alpha_2_code: 'CA',
      alpha_3_code: 'CAN',
      numeric_code: '124',
    },
    {
      name: 'Cayman Islands (the)',
      alpha_2_code: 'KY',
      alpha_3_code: 'CYM',
      numeric_code: '136',
    },
    {
      name: 'Central African Republic (the)',
      alpha_2_code: 'CF',
      alpha_3_code: 'CAF',
      numeric_code: '140',
    },
    {
      name: 'Chad',
      alpha_2_code: 'TD',
      alpha_3_code: 'TCD',
      numeric_code: '148',
    },
    {
      name: 'Chile',
      alpha_2_code: 'CL',
      alpha_3_code: 'CHL',
      numeric_code: '152',
    },
    {
      name: 'China',
      alpha_2_code: 'CN',
      alpha_3_code: 'CHN',
      numeric_code: '156',
    },
    {
      name: 'Christmas Island',
      alpha_2_code: 'CX',
      alpha_3_code: 'CXR',
      numeric_code: '162',
    },
    {
      name: 'Cocos (Keeling) Islands (the)',
      alpha_2_code: 'CC',
      alpha_3_code: 'CCK',
      numeric_code: '166',
    },
    {
      name: 'Colombia',
      alpha_2_code: 'CO',
      alpha_3_code: 'COL',
      numeric_code: '170',
    },
    {
      name: 'Comoros (the)',
      alpha_2_code: 'KM',
      alpha_3_code: 'COM',
      numeric_code: '174',
    },
    {
      name: 'Congo (the Democratic Republic of the)',
      alpha_2_code: 'CD',
      alpha_3_code: 'COD',
      numeric_code: '180',
    },
    {
      name: 'Congo (the)',
      alpha_2_code: 'CG',
      alpha_3_code: 'COG',
      numeric_code: '178',
    },
    {
      name: 'Cook Islands (the)',
      alpha_2_code: 'CK',
      alpha_3_code: 'COK',
      numeric_code: '184',
    },
    {
      name: 'Costa Rica',
      alpha_2_code: 'CR',
      alpha_3_code: 'CRI',
      numeric_code: '188',
    },
    {
      name: 'Croatia',
      alpha_2_code: 'HR',
      alpha_3_code: 'HRV',
      numeric_code: '191',
    },
    {
      name: 'Cuba',
      alpha_2_code: 'CU',
      alpha_3_code: 'CUB',
      numeric_code: '192',
    },
    {
      name: 'Curaçao',
      alpha_2_code: 'CW',
      alpha_3_code: 'CUW',
      numeric_code: '531',
    },
    {
      name: 'Cyprus',
      alpha_2_code: 'CY',
      alpha_3_code: 'CYP',
      numeric_code: '196',
    },
    {
      name: 'Czechia',
      alpha_2_code: 'CZ',
      alpha_3_code: 'CZE',
      numeric_code: '203',
    },
    {
      name: "Côte d'Ivoire",
      alpha_2_code: 'CI',
      alpha_3_code: 'CIV',
      numeric_code: '384',
    },
    {
      name: 'Denmark',
      alpha_2_code: 'DK',
      alpha_3_code: 'DNK',
      numeric_code: '208',
    },
    {
      name: 'Djibouti',
      alpha_2_code: 'DJ',
      alpha_3_code: 'DJI',
      numeric_code: '262',
    },
    {
      name: 'Dominica',
      alpha_2_code: 'DM',
      alpha_3_code: 'DMA',
      numeric_code: '212',
    },
    {
      name: 'Dominican Republic (the)',
      alpha_2_code: 'DO',
      alpha_3_code: 'DOM',
      numeric_code: '214',
    },
    {
      name: 'Ecuador',
      alpha_2_code: 'EC',
      alpha_3_code: 'ECU',
      numeric_code: '218',
    },
    {
      name: 'Egypt',
      alpha_2_code: 'EG',
      alpha_3_code: 'EGY',
      numeric_code: '818',
    },
    {
      name: 'El Salvador',
      alpha_2_code: 'SV',
      alpha_3_code: 'SLV',
      numeric_code: '222',
    },
    {
      name: 'Equatorial Guinea',
      alpha_2_code: 'GQ',
      alpha_3_code: 'GNQ',
      numeric_code: '226',
    },
    {
      name: 'Eritrea',
      alpha_2_code: 'ER',
      alpha_3_code: 'ERI',
      numeric_code: '232',
    },
    {
      name: 'Estonia',
      alpha_2_code: 'EE',
      alpha_3_code: 'EST',
      numeric_code: '233',
    },
    {
      name: 'Eswatini',
      alpha_2_code: 'SZ',
      alpha_3_code: 'SWZ',
      numeric_code: '748',
    },
    {
      name: 'Ethiopia',
      alpha_2_code: 'ET',
      alpha_3_code: 'ETH',
      numeric_code: '231',
    },
    {
      name: 'Falkland Islands (the) [Malvinas]',
      alpha_2_code: 'FK',
      alpha_3_code: 'FLK',
      numeric_code: '238',
    },
    {
      name: 'Faroe Islands (the)',
      alpha_2_code: 'FO',
      alpha_3_code: 'FRO',
      numeric_code: '234',
    },
    {
      name: 'Fiji',
      alpha_2_code: 'FJ',
      alpha_3_code: 'FJI',
      numeric_code: '242',
    },
    {
      name: 'Finland',
      alpha_2_code: 'FI',
      alpha_3_code: 'FIN',
      numeric_code: '246',
    },
    {
      name: 'France',
      alpha_2_code: 'FR',
      alpha_3_code: 'FRA',
      numeric_code: '250',
    },
    {
      name: 'French Guiana',
      alpha_2_code: 'GF',
      alpha_3_code: 'GUF',
      numeric_code: '254',
    },
    {
      name: 'French Polynesia',
      alpha_2_code: 'PF',
      alpha_3_code: 'PYF',
      numeric_code: '258',
    },
    {
      name: 'French Southern Territories (the)',
      alpha_2_code: 'TF',
      alpha_3_code: 'ATF',
      numeric_code: '260',
    },
    {
      name: 'Gabon',
      alpha_2_code: 'GA',
      alpha_3_code: 'GAB',
      numeric_code: '266',
    },
    {
      name: 'Gambia (the)',
      alpha_2_code: 'GM',
      alpha_3_code: 'GMB',
      numeric_code: '270',
    },
    {
      name: 'Georgia',
      alpha_2_code: 'GE',
      alpha_3_code: 'GEO',
      numeric_code: '268',
    },
    {
      name: 'Germany',
      alpha_2_code: 'DE',
      alpha_3_code: 'DEU',
      numeric_code: '276',
    },
    {
      name: 'Ghana',
      alpha_2_code: 'GH',
      alpha_3_code: 'GHA',
      numeric_code: '288',
    },
    {
      name: 'Gibraltar',
      alpha_2_code: 'GI',
      alpha_3_code: 'GIB',
      numeric_code: '292',
    },
    {
      name: 'Greece',
      alpha_2_code: 'GR',
      alpha_3_code: 'GRC',
      numeric_code: '300',
    },
    {
      name: 'Greenland',
      alpha_2_code: 'GL',
      alpha_3_code: 'GRL',
      numeric_code: '304',
    },
    {
      name: 'Grenada',
      alpha_2_code: 'GD',
      alpha_3_code: 'GRD',
      numeric_code: '308',
    },
    {
      name: 'Guadeloupe',
      alpha_2_code: 'GP',
      alpha_3_code: 'GLP',
      numeric_code: '312',
    },
    {
      name: 'Guam',
      alpha_2_code: 'GU',
      alpha_3_code: 'GUM',
      numeric_code: '316',
    },
    {
      name: 'Guatemala',
      alpha_2_code: 'GT',
      alpha_3_code: 'GTM',
      numeric_code: '320',
    },
    {
      name: 'Guernsey',
      alpha_2_code: 'GG',
      alpha_3_code: 'GGY',
      numeric_code: '831',
    },
    {
      name: 'Guinea',
      alpha_2_code: 'GN',
      alpha_3_code: 'GIN',
      numeric_code: '324',
    },
    {
      name: 'Guinea-Bissau',
      alpha_2_code: 'GW',
      alpha_3_code: 'GNB',
      numeric_code: '624',
    },
    {
      name: 'Guyana',
      alpha_2_code: 'GY',
      alpha_3_code: 'GUY',
      numeric_code: '328',
    },
    {
      name: 'Haiti',
      alpha_2_code: 'HT',
      alpha_3_code: 'HTI',
      numeric_code: '332',
    },
    {
      name: 'Heard Island and McDonald Islands',
      alpha_2_code: 'HM',
      alpha_3_code: 'HMD',
      numeric_code: '334',
    },
    {
      name: 'Holy See (the)',
      alpha_2_code: 'VA',
      alpha_3_code: 'VAT',
      numeric_code: '336',
    },
    {
      name: 'Honduras',
      alpha_2_code: 'HN',
      alpha_3_code: 'HND',
      numeric_code: '340',
    },
    {
      name: 'Hong Kong',
      alpha_2_code: 'HK',
      alpha_3_code: 'HKG',
      numeric_code: '344',
    },
    {
      name: 'Hungary',
      alpha_2_code: 'HU',
      alpha_3_code: 'HUN',
      numeric_code: '348',
    },
    {
      name: 'Iceland',
      alpha_2_code: 'IS',
      alpha_3_code: 'ISL',
      numeric_code: '352',
    },
    {
      name: 'India',
      alpha_2_code: 'IN',
      alpha_3_code: 'IND',
      numeric_code: '356',
    },
    {
      name: 'Indonesia',
      alpha_2_code: 'ID',
      alpha_3_code: 'IDN',
      numeric_code: '360',
    },
    {
      name: 'Iran (Islamic Republic of)',
      alpha_2_code: 'IR',
      alpha_3_code: 'IRN',
      numeric_code: '364',
    },
    {
      name: 'Iraq',
      alpha_2_code: 'IQ',
      alpha_3_code: 'IRQ',
      numeric_code: '368',
    },
    {
      name: 'Ireland',
      alpha_2_code: 'IE',
      alpha_3_code: 'IRL',
      numeric_code: '372',
    },
    {
      name: 'Isle of Man',
      alpha_2_code: 'IM',
      alpha_3_code: 'IMN',
      numeric_code: '833',
    },
    {
      name: 'Israel',
      alpha_2_code: 'IL',
      alpha_3_code: 'ISR',
      numeric_code: '376',
    },
    {
      name: 'Italy',
      alpha_2_code: 'IT',
      alpha_3_code: 'ITA',
      numeric_code: '380',
    },
    {
      name: 'Jamaica',
      alpha_2_code: 'JM',
      alpha_3_code: 'JAM',
      numeric_code: '388',
    },
    {
      name: 'Japan',
      alpha_2_code: 'JP',
      alpha_3_code: 'JPN',
      numeric_code: '392',
    },
    {
      name: 'Jersey',
      alpha_2_code: 'JE',
      alpha_3_code: 'JEY',
      numeric_code: '832',
    },
    {
      name: 'Jordan',
      alpha_2_code: 'JO',
      alpha_3_code: 'JOR',
      numeric_code: '400',
    },
    {
      name: 'Kazakhstan',
      alpha_2_code: 'KZ',
      alpha_3_code: 'KAZ',
      numeric_code: '398',
    },
    {
      name: 'Kenya',
      alpha_2_code: 'KE',
      alpha_3_code: 'KEN',
      numeric_code: '404',
    },
    {
      name: 'Kiribati',
      alpha_2_code: 'KI',
      alpha_3_code: 'KIR',
      numeric_code: '296',
    },
    {
      name: "Korea (the Democratic People's Republic of)",
      alpha_2_code: 'KP',
      alpha_3_code: 'PRK',
      numeric_code: '408',
    },
    {
      name: 'Korea (the Republic of)',
      alpha_2_code: 'KR',
      alpha_3_code: 'KOR',
      numeric_code: '410',
    },
    {
      name: 'Kuwait',
      alpha_2_code: 'KW',
      alpha_3_code: 'KWT',
      numeric_code: '414',
    },
    {
      name: 'Kyrgyzstan',
      alpha_2_code: 'KG',
      alpha_3_code: 'KGZ',
      numeric_code: '417',
    },
    {
      name: "Lao People's Democratic Republic (the)",
      alpha_2_code: 'LA',
      alpha_3_code: 'LAO',
      numeric_code: '418',
    },
    {
      name: 'Latvia',
      alpha_2_code: 'LV',
      alpha_3_code: 'LVA',
      numeric_code: '428',
    },
    {
      name: 'Lebanon',
      alpha_2_code: 'LB',
      alpha_3_code: 'LBN',
      numeric_code: '422',
    },
    {
      name: 'Lesotho',
      alpha_2_code: 'LS',
      alpha_3_code: 'LSO',
      numeric_code: '426',
    },
    {
      name: 'Liberia',
      alpha_2_code: 'LR',
      alpha_3_code: 'LBR',
      numeric_code: '430',
    },
    {
      name: 'Libya',
      alpha_2_code: 'LY',
      alpha_3_code: 'LBY',
      numeric_code: '434',
    },
    {
      name: 'Liechtenstein',
      alpha_2_code: 'LI',
      alpha_3_code: 'LIE',
      numeric_code: '438',
    },
    {
      name: 'Lithuania',
      alpha_2_code: 'LT',
      alpha_3_code: 'LTU',
      numeric_code: '440',
    },
    {
      name: 'Luxembourg',
      alpha_2_code: 'LU',
      alpha_3_code: 'LUX',
      numeric_code: '442',
    },
    {
      name: 'Macao',
      alpha_2_code: 'MO',
      alpha_3_code: 'MAC',
      numeric_code: '446',
    },
    {
      name: 'Madagascar',
      alpha_2_code: 'MG',
      alpha_3_code: 'MDG',
      numeric_code: '450',
    },
    {
      name: 'Malawi',
      alpha_2_code: 'MW',
      alpha_3_code: 'MWI',
      numeric_code: '454',
    },
    {
      name: 'Malaysia',
      alpha_2_code: 'MY',
      alpha_3_code: 'MYS',
      numeric_code: '458',
    },
    {
      name: 'Maldives',
      alpha_2_code: 'MV',
      alpha_3_code: 'MDV',
      numeric_code: '462',
    },
    {
      name: 'Mali',
      alpha_2_code: 'ML',
      alpha_3_code: 'MLI',
      numeric_code: '466',
    },
    {
      name: 'Malta',
      alpha_2_code: 'MT',
      alpha_3_code: 'MLT',
      numeric_code: '470',
    },
    {
      name: 'Marshall Islands (the)',
      alpha_2_code: 'MH',
      alpha_3_code: 'MHL',
      numeric_code: '584',
    },
    {
      name: 'Martinique',
      alpha_2_code: 'MQ',
      alpha_3_code: 'MTQ',
      numeric_code: '474',
    },
    {
      name: 'Mauritania',
      alpha_2_code: 'MR',
      alpha_3_code: 'MRT',
      numeric_code: '478',
    },
    {
      name: 'Mauritius',
      alpha_2_code: 'MU',
      alpha_3_code: 'MUS',
      numeric_code: '480',
    },
    {
      name: 'Mayotte',
      alpha_2_code: 'YT',
      alpha_3_code: 'MYT',
      numeric_code: '175',
    },
    {
      name: 'Mexico',
      alpha_2_code: 'MX',
      alpha_3_code: 'MEX',
      numeric_code: '484',
    },
    {
      name: 'Micronesia (Federated States of)',
      alpha_2_code: 'FM',
      alpha_3_code: 'FSM',
      numeric_code: '583',
    },
    {
      name: 'Moldova (the Republic of)',
      alpha_2_code: 'MD',
      alpha_3_code: 'MDA',
      numeric_code: '498',
    },
    {
      name: 'Monaco',
      alpha_2_code: 'MC',
      alpha_3_code: 'MCO',
      numeric_code: '492',
    },
    {
      name: 'Mongolia',
      alpha_2_code: 'MN',
      alpha_3_code: 'MNG',
      numeric_code: '496',
    },
    {
      name: 'Montenegro',
      alpha_2_code: 'ME',
      alpha_3_code: 'MNE',
      numeric_code: '499',
    },
    {
      name: 'Montserrat',
      alpha_2_code: 'MS',
      alpha_3_code: 'MSR',
      numeric_code: '500',
    },
    {
      name: 'Morocco',
      alpha_2_code: 'MA',
      alpha_3_code: 'MAR',
      numeric_code: '504',
    },
    {
      name: 'Mozambique',
      alpha_2_code: 'MZ',
      alpha_3_code: 'MOZ',
      numeric_code: '508',
    },
    {
      name: 'Myanmar',
      alpha_2_code: 'MM',
      alpha_3_code: 'MMR',
      numeric_code: '104',
    },
    {
      name: 'Namibia',
      alpha_2_code: 'NA',
      alpha_3_code: 'NAM',
      numeric_code: '516',
    },
    {
      name: 'Nauru',
      alpha_2_code: 'NR',
      alpha_3_code: 'NRU',
      numeric_code: '520',
    },
    {
      name: 'Nepal',
      alpha_2_code: 'NP',
      alpha_3_code: 'NPL',
      numeric_code: '524',
    },
    {
      name: 'Netherlands (the)',
      alpha_2_code: 'NL',
      alpha_3_code: 'NLD',
      numeric_code: '528',
    },
    {
      name: 'New Caledonia',
      alpha_2_code: 'NC',
      alpha_3_code: 'NCL',
      numeric_code: '540',
    },
    {
      name: 'New Zealand',
      alpha_2_code: 'NZ',
      alpha_3_code: 'NZL',
      numeric_code: '554',
    },
    {
      name: 'Nicaragua',
      alpha_2_code: 'NI',
      alpha_3_code: 'NIC',
      numeric_code: '558',
    },
    {
      name: 'Niger (the)',
      alpha_2_code: 'NE',
      alpha_3_code: 'NER',
      numeric_code: '562',
    },
    {
      name: 'Nigeria',
      alpha_2_code: 'NG',
      alpha_3_code: 'NGA',
      numeric_code: '566',
    },
    {
      name: 'Niue',
      alpha_2_code: 'NU',
      alpha_3_code: 'NIU',
      numeric_code: '570',
    },
    {
      name: 'Norfolk Island',
      alpha_2_code: 'NF',
      alpha_3_code: 'NFK',
      numeric_code: '574',
    },
    {
      name: 'Northern Mariana Islands (the)',
      alpha_2_code: 'MP',
      alpha_3_code: 'MNP',
      numeric_code: '580',
    },
    {
      name: 'Norway',
      alpha_2_code: 'NO',
      alpha_3_code: 'NOR',
      numeric_code: '578',
    },
    {
      name: 'Oman',
      alpha_2_code: 'OM',
      alpha_3_code: 'OMN',
      numeric_code: '512',
    },
    {
      name: 'Pakistan',
      alpha_2_code: 'PK',
      alpha_3_code: 'PAK',
      numeric_code: '586',
    },
    {
      name: 'Palau',
      alpha_2_code: 'PW',
      alpha_3_code: 'PLW',
      numeric_code: '585',
    },
    {
      name: 'Palestine, State of',
      alpha_2_code: 'PS',
      alpha_3_code: 'PSE',
      numeric_code: '275',
    },
    {
      name: 'Panama',
      alpha_2_code: 'PA',
      alpha_3_code: 'PAN',
      numeric_code: '591',
    },
    {
      name: 'Papua New Guinea',
      alpha_2_code: 'PG',
      alpha_3_code: 'PNG',
      numeric_code: '598',
    },
    {
      name: 'Paraguay',
      alpha_2_code: 'PY',
      alpha_3_code: 'PRY',
      numeric_code: '600',
    },
    {
      name: 'Peru',
      alpha_2_code: 'PE',
      alpha_3_code: 'PER',
      numeric_code: '604',
    },
    {
      name: 'Philippines (the)',
      alpha_2_code: 'PH',
      alpha_3_code: 'PHL',
      numeric_code: '608',
    },
    {
      name: 'Pitcairn',
      alpha_2_code: 'PN',
      alpha_3_code: 'PCN',
      numeric_code: '612',
    },
    {
      name: 'Poland',
      alpha_2_code: 'PL',
      alpha_3_code: 'POL',
      numeric_code: '616',
    },
    {
      name: 'Portugal',
      alpha_2_code: 'PT',
      alpha_3_code: 'PRT',
      numeric_code: '620',
    },
    {
      name: 'Puerto Rico',
      alpha_2_code: 'PR',
      alpha_3_code: 'PRI',
      numeric_code: '630',
    },
    {
      name: 'Qatar',
      alpha_2_code: 'QA',
      alpha_3_code: 'QAT',
      numeric_code: '634',
    },
    {
      name: 'Republic of North Macedonia',
      alpha_2_code: 'MK',
      alpha_3_code: 'MKD',
      numeric_code: '807',
    },
    {
      name: 'Romania',
      alpha_2_code: 'RO',
      alpha_3_code: 'ROU',
      numeric_code: '642',
    },
    {
      name: 'Russia',
      alpha_2_code: 'RU',
      alpha_3_code: 'RUS',
      numeric_code: '643',
    },
    {
      name: 'Rwanda',
      alpha_2_code: 'RW',
      alpha_3_code: 'RWA',
      numeric_code: '646',
    },
    {
      name: 'Réunion',
      alpha_2_code: 'RE',
      alpha_3_code: 'REU',
      numeric_code: '638',
    },
    {
      name: 'Saint Barthélemy',
      alpha_2_code: 'BL',
      alpha_3_code: 'BLM',
      numeric_code: '652',
    },
    {
      name: 'Saint Helena, Ascension and Tristan da Cunha',
      alpha_2_code: 'SH',
      alpha_3_code: 'SHN',
      numeric_code: '654',
    },
    {
      name: 'Saint Kitts and Nevis',
      alpha_2_code: 'KN',
      alpha_3_code: 'KNA',
      numeric_code: '659',
    },
    {
      name: 'Saint Lucia',
      alpha_2_code: 'LC',
      alpha_3_code: 'LCA',
      numeric_code: '662',
    },
    {
      name: 'Saint Martin (French part)',
      alpha_2_code: 'MF',
      alpha_3_code: 'MAF',
      numeric_code: '663',
    },
    {
      name: 'Saint Pierre and Miquelon',
      alpha_2_code: 'PM',
      alpha_3_code: 'SPM',
      numeric_code: '666',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      alpha_2_code: 'VC',
      alpha_3_code: 'VCT',
      numeric_code: '670',
    },
    {
      name: 'Samoa',
      alpha_2_code: 'WS',
      alpha_3_code: 'WSM',
      numeric_code: '882',
    },
    {
      name: 'San Marino',
      alpha_2_code: 'SM',
      alpha_3_code: 'SMR',
      numeric_code: '674',
    },
    {
      name: 'Sao Tome and Principe',
      alpha_2_code: 'ST',
      alpha_3_code: 'STP',
      numeric_code: '678',
    },
    {
      name: 'Saudi Arabia',
      alpha_2_code: 'SA',
      alpha_3_code: 'SAU',
      numeric_code: '682',
    },
    {
      name: 'Senegal',
      alpha_2_code: 'SN',
      alpha_3_code: 'SEN',
      numeric_code: '686',
    },
    {
      name: 'Serbia',
      alpha_2_code: 'RS',
      alpha_3_code: 'SRB',
      numeric_code: '688',
    },
    {
      name: 'Seychelles',
      alpha_2_code: 'SC',
      alpha_3_code: 'SYC',
      numeric_code: '690',
    },
    {
      name: 'Sierra Leone',
      alpha_2_code: 'SL',
      alpha_3_code: 'SLE',
      numeric_code: '694',
    },
    {
      name: 'Singapore',
      alpha_2_code: 'SG',
      alpha_3_code: 'SGP',
      numeric_code: '702',
    },
    {
      name: 'Sint Maarten (Dutch part)',
      alpha_2_code: 'SX',
      alpha_3_code: 'SXM',
      numeric_code: '534',
    },
    {
      name: 'Slovakia',
      alpha_2_code: 'SK',
      alpha_3_code: 'SVK',
      numeric_code: '703',
    },
    {
      name: 'Slovenia',
      alpha_2_code: 'SI',
      alpha_3_code: 'SVN',
      numeric_code: '705',
    },
    {
      name: 'Solomon Islands',
      alpha_2_code: 'SB',
      alpha_3_code: 'SLB',
      numeric_code: '90',
    },
    {
      name: 'Somalia',
      alpha_2_code: 'SO',
      alpha_3_code: 'SOM',
      numeric_code: '706',
    },
    {
      name: 'South Africa',
      alpha_2_code: 'ZA',
      alpha_3_code: 'ZAF',
      numeric_code: '710',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      alpha_2_code: 'GS',
      alpha_3_code: 'SGS',
      numeric_code: '239',
    },
    {
      name: 'South Sudan',
      alpha_2_code: 'SS',
      alpha_3_code: 'SSD',
      numeric_code: '728',
    },
    {
      name: 'Spain',
      alpha_2_code: 'ES',
      alpha_3_code: 'ESP',
      numeric_code: '724',
    },
    {
      name: 'Sri Lanka',
      alpha_2_code: 'LK',
      alpha_3_code: 'LKA',
      numeric_code: '144',
    },
    {
      name: 'Sudan (the)',
      alpha_2_code: 'SD',
      alpha_3_code: 'SDN',
      numeric_code: '729',
    },
    {
      name: 'Suriname',
      alpha_2_code: 'SR',
      alpha_3_code: 'SUR',
      numeric_code: '740',
    },
    {
      name: 'Svalbard and Jan Mayen',
      alpha_2_code: 'SJ',
      alpha_3_code: 'SJM',
      numeric_code: '744',
    },
    {
      name: 'Sweden',
      alpha_2_code: 'SE',
      alpha_3_code: 'SWE',
      numeric_code: '752',
    },
    {
      name: 'Switzerland',
      alpha_2_code: 'CH',
      alpha_3_code: 'CHE',
      numeric_code: '756',
    },
    {
      name: 'Syrian Arab Republic',
      alpha_2_code: 'SY',
      alpha_3_code: 'SYR',
      numeric_code: '760',
    },
    {
      name: 'Taiwan (Province of China)',
      alpha_2_code: 'TW',
      alpha_3_code: 'TWN',
      numeric_code: '158',
    },
    {
      name: 'Tajikistan',
      alpha_2_code: 'TJ',
      alpha_3_code: 'TJK',
      numeric_code: '762',
    },
    {
      name: 'Tanzania, United Republic of',
      alpha_2_code: 'TZ',
      alpha_3_code: 'TZA',
      numeric_code: '834',
    },
    {
      name: 'Thailand',
      alpha_2_code: 'TH',
      alpha_3_code: 'THA',
      numeric_code: '764',
    },
    {
      name: 'Timor-Leste',
      alpha_2_code: 'TL',
      alpha_3_code: 'TLS',
      numeric_code: '626',
    },
    {
      name: 'Togo',
      alpha_2_code: 'TG',
      alpha_3_code: 'TGO',
      numeric_code: '768',
    },
    {
      name: 'Tokelau',
      alpha_2_code: 'TK',
      alpha_3_code: 'TKL',
      numeric_code: '772',
    },
    {
      name: 'Tonga',
      alpha_2_code: 'TO',
      alpha_3_code: 'TON',
      numeric_code: '776',
    },
    {
      name: 'Trinidad and Tobago',
      alpha_2_code: 'TT',
      alpha_3_code: 'TTO',
      numeric_code: '780',
    },
    {
      name: 'Tunisia',
      alpha_2_code: 'TN',
      alpha_3_code: 'TUN',
      numeric_code: '788',
    },
    {
      name: 'Turkey',
      alpha_2_code: 'TR',
      alpha_3_code: 'TUR',
      numeric_code: '792',
    },
    {
      name: 'Turkmenistan',
      alpha_2_code: 'TM',
      alpha_3_code: 'TKM',
      numeric_code: '795',
    },
    {
      name: 'Turks and Caicos Islands (the)',
      alpha_2_code: 'TC',
      alpha_3_code: 'TCA',
      numeric_code: '796',
    },
    {
      name: 'Tuvalu',
      alpha_2_code: 'TV',
      alpha_3_code: 'TUV',
      numeric_code: '798',
    },
    {
      name: 'Uganda',
      alpha_2_code: 'UG',
      alpha_3_code: 'UGA',
      numeric_code: '800',
    },
    {
      name: 'Ukraine',
      alpha_2_code: 'UA',
      alpha_3_code: 'UKR',
      numeric_code: '804',
    },
    {
      name: 'United Arab Emirates (the)',
      alpha_2_code: 'AE',
      alpha_3_code: 'ARE',
      numeric_code: '784',
    },
    {
      name: 'United Kingdom of Great Britain and Northern Ireland (the)',
      alpha_2_code: 'GB',
      alpha_3_code: 'GBR',
      numeric_code: '826',
    },
    {
      name: 'United States Minor Outlying Islands (the)',
      alpha_2_code: 'UM',
      alpha_3_code: 'UMI',
      numeric_code: '581',
    },
    {
      name: 'Uruguay',
      alpha_2_code: 'UY',
      alpha_3_code: 'URY',
      numeric_code: '858',
    },
    {
      name: 'Uzbekistan',
      alpha_2_code: 'UZ',
      alpha_3_code: 'UZB',
      numeric_code: '860',
    },
    {
      name: 'Vanuatu',
      alpha_2_code: 'VU',
      alpha_3_code: 'VUT',
      numeric_code: '548',
    },
    {
      name: 'Venezuela (Bolivarian Republic of)',
      alpha_2_code: 'VE',
      alpha_3_code: 'VEN',
      numeric_code: '862',
    },
    {
      name: 'Viet Nam',
      alpha_2_code: 'VN',
      alpha_3_code: 'VNM',
      numeric_code: '704',
    },
    {
      name: 'Virgin Islands (British)',
      alpha_2_code: 'VG',
      alpha_3_code: 'VGB',
      numeric_code: '92',
    },
    {
      name: 'Virgin Islands (U.S.)',
      alpha_2_code: 'VI',
      alpha_3_code: 'VIR',
      numeric_code: '850',
    },
    {
      name: 'Wallis and Futuna',
      alpha_2_code: 'WF',
      alpha_3_code: 'WLF',
      numeric_code: '876',
    },
    {
      name: 'Western Sahara',
      alpha_2_code: 'EH',
      alpha_3_code: 'ESH',
      numeric_code: '732',
    },
    {
      name: 'Yemen',
      alpha_2_code: 'YE',
      alpha_3_code: 'YEM',
      numeric_code: '887',
    },
    {
      name: 'Zambia',
      alpha_2_code: 'ZM',
      alpha_3_code: 'ZMB',
      numeric_code: '894',
    },
    {
      name: 'Zimbabwe',
      alpha_2_code: 'ZW',
      alpha_3_code: 'ZWE',
      numeric_code: '716',
    },
    {
      name: 'Åland Islands',
      alpha_2_code: 'AX',
      alpha_3_code: 'ALA',
      numeric_code: '248',
    },
  ],
};
