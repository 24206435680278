import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Checkbox } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLocation } from "../../../api/HDMAM/campaign/action";
import { useGetCampaignLocation } from "../../../services/fb-ads";

const MyChip = (props) => {
  return (
    <div className="custom_chips">
      <span>{props.label}</span>
      { !props?.isCampaignSuccess && <span className="close_tag" onClick={props.onDelete}></span> }
    </div>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const LookalikeLocationField = (props) => {
  const dispatch = useDispatch();

  // let locationOptions = useSelector((state) => state.hdmCampaign.locationsList);
  const [locString, setLocString] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);
  useGetCampaignLocation(locString, {
      onSuccess: (data) => {
          const options = data?.locations.map(element => {
             return {
               name:
                 element.type === "country"
                   ? element.name
                   : element.type === "region"
                   ? `${element.name}, ${element.countryName || element.country_name}`
                   : element.type === "city"
                   ? `${element.name}, ${element.region}, ${
                       element.countryName || element.country_name
                     }`
                   : element.type === "zip"
                   ? `${element.name}, ${element.primary_city}, ${element.region} ${
                       element.countryName || element.country_name
                     }`
                   : element.type === "geo_market"
                   ? `${element.name}, ${
                       element.countryName || element.country_name
                     }`
                   : element.type === "electoral_district"
                   ? `${element.name}, ${element.region} ${
                       element.countryName || element.country_name
                     }`
                   : `${element.name}`,
               type: element.type,
               key: element.key,
               countryName: element.countryName || element.country_name,
               region: element.region,
               supportsRegion: element.supports_region,
               supportsCity: element.supports_city,
             };
          });
          if (options)
              setLocationOptions(options);
      },
      enabled: Boolean(locString)
  });


  if (!locationOptions) {
    locationOptions = [];
  }
  console.log('~~~LocationOptions:', locationOptions);
  console.log("location check");
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags"
      options={locationOptions}
      value={props.advertisingLocations}
      onChange={(event, values) => {
        props.updateReducer(values, 'advertisingLocations');
      }}
      style={{ display: "flex", flexWrap: "wrap" }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <MyChip {...getTagProps({ index })} label={option.name} isCampaignSuccess={props?.isCampaignSuccess}/>
        ));
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option, inputValue, { insideWords: true });
        const parts = parse(option, matches);
        console.log(parts)
        return (
          <li {...props} className="search_location_list">
            <div className="location_list_head">
              {" "}
              <p>Location</p> <p>Type</p>
            </div>
            <div className="location_list_content">
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? "#0869FB" : "#333333",
                    }}
                  >
                    {part.text.name}
                  </span>
                ))}
              </div>
              <span>{option.type}</span>
            </div>
          </li>
        );
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => 
        (<div className="location_question_header">
          <span className="search_icon"></span>
          <TextField
            {...params}
            ref={params.InputProps.ref}
            autoFocus
            hidden={true}
            style={{
              width: props.width,
              height: props.height ? props.height : "",
              animation: props.bounce && `${props.bounceAnimation} 0.9s both`,
            }}
            placeholder={props.placeholder ? props.placeholder : "Search"}
            variant="standard"
            onChange={(e) => {
                setLocString(e.target.value);
            }}
          />
        </div>)
      }
      disablePortal={true}
      className="auto_location_search"
    />
  );
};

export default LookalikeLocationField;
