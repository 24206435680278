import { BACKEND_STATUS } from '../../../utils/accountManagerUtil';
import { TARGETING_TYPE } from '../utils';

export const truncateString = (str) => {
  return str?.length > 120 ? str?.substring(0, 120) + '...' : str;
};

export const filterDropdown = (group, type, audiences) => {
  const arrTemp = [];
  audiences &&
    audiences.forEach((value, index) => {
      if (value.subtype === type) {
        arrTemp.push({
          group: group,
          name: value.name,
          reach: value.maxReach,
          id: value.id,
        });
      }
    });
  return arrTemp;
};

export const randomisedFacebookCreativeAds = (ads) => {
  if(!ads?.length) return [];
  const desiredArray = [];
  let id = 1;
  ads.forEach((ad) => {
    desiredArray.push({ headline: ad.headline, imageUrl: ad.imageUrl, primaryText: ad.primaryText, description: ad.description, requestId: ad?.requestId });
    ad.response.forEach((response) => {
      desiredArray.push({ ...response, ...{ imageUrl: response.adImageUrl || ad.imageUrl, requestId: ad?.requestId, id: id } });
      id++;
    });
  });
  const outputArray = [];
  const numberOfRadmise = 2;
  for (let i = 0; i < numberOfRadmise; i++) {
    const randomNumber = Math.floor(Math.random() * desiredArray.length);
    outputArray.push({ ...desiredArray[randomNumber], ...{ adName: "Ad " + (randomNumber + 1) } });
  }
  return outputArray;
};

export const randomisedFacebookGaiAds = (adCopySets) => {
  const allAds = adCopySets.flatMap(set => set.ads);
  const outputArray = [];
  if(!allAds.length) return outputArray;
  const numberOfRadmise = 2;
  for (let i = 0; i < numberOfRadmise; i++) {
    const randomNumber = Math.floor(Math.random() * allAds.length),
      ad = allAds[randomNumber];
    outputArray.push({ ...ad, ...{ adName: ad.name } });
  }
  return outputArray;
}

export const findUrl = (facebookAds, id) => {
  let idt = '';
  facebookAds.forEach((ad) => {
    if (ad?.requestId === id) {
      idt = ad.imageUrl;
    }
  });
  return idt;
};

const NULL_ADSET = {
  "audience": {
    "id": "",
    "name": "",
    "minReach": "",
    "maxReach": "",
    "subType": ""
  },
  "minRange": "",
  "maxRange": "",
  "adsetName": "",
  "keywords": [],
  "facebookAds": [
    {
      "iamgeUrl": "",
      "headline": "",
      "description": "",
      "primaryText": "",
      "adName": ""
    },
    {
      "iamgeUrl": "",
      "headline": "",
      "description": "",
      "primaryText": "",
      "adName": ""
    }
  ]
};

/** has audience, min range, and max range */
export function createNewLookalikeAdsetObject({ facebookAds, audience, ...rest }) {
  return {
    ...NULL_ADSET,
    ...rest,
    audience,
    facebookAds,
    maxRange: 3,
    minRange: 1,
    targetingType: TARGETING_TYPE.LOOKALIKE,
    status: BACKEND_STATUS.ONGOING
  };
}

/** has audience, no min range and max range */
export function createNewAudienceAdsetObject({ facebookAds, audience, ...rest }) {
  return {
    ...NULL_ADSET,
    ...rest,
    audience,
    facebookAds,
    maxRange: undefined,
    minRange: undefined,
    targetingType: TARGETING_TYPE.AUDIENCE,
    status: BACKEND_STATUS.ONGOING
  };
}
