import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  MenuList,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import successIcon from './pmaxIcons/success-icon.svg';
import failedIcon from './pmaxIcons/failed-icon.svg';
import './pmaxImageUploadStatus.scss';
import image from './pmaxIcons/image20.png';
const ImageUploadStatusModal = ({ 
    // setModal,
    verifiedFiles,
    errorFiles,
    handleClose,
    handleProceed,
}) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 524,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2.5,
    borderRadius: '8px',
    outline: 'none',
  };
  const textFieldStyle = {
    background: '#FFFFFF',
    border: '1px solid #DDDDDD',
    borderRadius: 8,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#262626',
  };
  // const [testName, setTestName] = useState('');
  // const [testStatus, setTestStatus] = useState('Not Started');
  // const handleChange = (event) => {
  //   setTestStatus(event.target.value);
  // };
  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='abtest-add-modal'
    >
      <Box sx={{ ...style }}>
        <Close
          onClick={() => handleClose()}
          style={{
            position: 'absolute',
            top: '1rem',
            right: '1.5rem',
            cursor: 'pointer',
            background: '#F2F2F2',
            borderRadius: 4,
            padding: '2px 4px',
            width: 34,
            height: 34,
          }}
        />
        <div
          //   style={{
          //     display: 'flex',
          //     flexDirection: 'column',
          //     alignItems: 'center',
          //     justifyContent: 'center',
          //     height: '100%',
          //     gap: '0.5rem',
          //   }}
          className='pmax-image-upload-message-status-container'
        >
          <div
            style={{
              padding: '0 0 1rem 0',
              borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
              width: '100%',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 20,
                color: '#1C1E21',
                paddingLeft: 24,
              }}
            >
              Image Upload
            </Typography>
          </div>
          <div
            className='image-upload-message-status-container'
            style={{
              minHeight: 530,
              maxHeight: 530,
              overflowY: 'scroll',
            }}
          >
            {/* Failed Image Container */}
            { errorFiles?.length > 0 && 
            <div className='image-uploaded-container'>
                <div className='image-upload-failed'>
                <img src={failedIcon} alt='success' />
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: '19px',
                    color: '#333333',
                  }}
                >
                  The following images could not be uploaded:
                </Typography>
              </div>
                <div className='image-failed-uploaded-list'>
                {/* Image failed */}
                { errorFiles.map((image, index) => 
                  (
                    <>
                      <div className='image-upload'>
                      <div className='image'>
                        <img src={image.id} />
                      </div>
                      <div className='image-details'>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: 14,
                            lineHeight: '17px',
                            color: '#333333',
                          }}
                        >
                          Image {index + 1}: {image.name}
                        </Typography>
                        <div className='image-res-details'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                            }}
                          >
                            Aspect Ratio:{' '}
                            <span
                              style={{
                                color: '#9D9D9D',
                              }}
                            >
                             {image.aspectRatio}
                            </span>
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                            }}
                          >
                            Resolution:{' '}
                            <span
                              style={{
                                color: '#9D9D9D',
                              }}
                            >
                              {image.resolution}
                            </span>
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                            }}
                          >
                            Size:{' '}
                            <span
                              style={{
                                color: '#9D9D9D',
                              }}
                            >
                              {image.size}
                            </span>
                          </Typography>
                        </div>
                      </div>
                    </div>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        fontSize: 12,
                        lineHeight: '15px',
                        color: '#EF5854',
                        marginTop: -12,
                        marginBottom: 20,
                      }}
                    >
                      {image.errorMessage}
                    </Typography>
                   </>
                  ))}
              </div>
            </div>
            }
            {/* Success Image container  */}
            { verifiedFiles?.length > 0 && 
            <div className='image-uploaded-container'>
              <div
                className='image-upload-success'
                style={{
                  marginTop: 36,
                }}
              >
                <img src={successIcon} alt='success' />
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: '19px',
                    color: '#333333',
                  }}
                >
                  We can successfully upload the following images:
                </Typography>
              </div>
              <div className='image-success-uploaded-list'>
                {/* Image upload component   */}
                { verifiedFiles.map((image, index) => 
                  (
                    <div className='image-upload' key={index}>
                      <div className='image'>
                        <img src={image.id} />
                      </div>
                      <div className='image-details'>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            fontSize: 14,
                            lineHeight: '17px',
                            color: '#333333',
                          }}
                        >
                          Image {index + 1}: {image.name}
                        </Typography>
                        <div className='image-res-details'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                            }}
                          >
                            Aspect Ratio:{' '}
                            <span
                              style={{
                                color: '#9D9D9D',
                              }}
                            >
                             {image.aspectRatio}
                            </span>
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                            }}
                          >
                            Resolution:{' '}
                            <span
                              style={{
                                color: '#9D9D9D',
                              }}
                            >
                             {image.resolution}
                            </span>
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              lineHeight: '20px',
                              color: '#333333',
                            }}
                          >
                            Size:{' '}
                            <span
                              style={{
                                color: '#9D9D9D',
                              }}
                            >
                             {image.size}
                            </span>
                          </Typography>
                        </div>
                      </div>
                   </div>
                  ))}
              </div>
            </div>
            }
          </div>
          <div className='pmax-proceed-button-container'>
            <Button className='proceed-button' onClick={() => handleProceed()}>PROCEED</Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default ImageUploadStatusModal;
