import React, { useState } from 'react';
import closeIcon from '../PMaxStrategyCreation/pmaxIcons/cross-icon.svg';
import selectedCloseIcon from '../PMaxStrategyCreation/pmaxIcons/selected-cross-icon.svg';
import AssetGroupDeleteModal from './AssetGroupDeleteModal';
import { ADSET_CRON_STATUS } from '../FacebookStrategyCreation/utils';
import { BACKEND_STATUS } from '../../utils/accountManagerUtil';

const getTabColor = adset => {
    if ([ADSET_CRON_STATUS.PENDING, ADSET_CRON_STATUS.RUNNING].includes(adset?.cronStatus))
        return '1.45px solid #F8862D';
    else if (adset.status === BACKEND_STATUS.COMPLETED)
        return  '1.45px solid #1ABFA1';
    else
        return '1.45px solid #0869FB'
}

const getNameColor = adset => {
    if ([ADSET_CRON_STATUS.PENDING, ADSET_CRON_STATUS.RUNNING].includes(adset?.cronStatus))
        return '#F8862D';
    else if (adset?.cronStatus === ADSET_CRON_STATUS.FAILED)
        return '#FDEEEE';
    else
        return '#0869FB' ;
}

const AssetGroupTabListView = ({
  handleChangeAssetGroup,
  handleAddNewAsset,
  handleRemoveAssetGroup,
  currAssetCounts=1,
  currStatus,
  currCamp,
  currAssetGroup,
  assetNumberField="assetNumber",
  addNewAssetString="Add New Asset",
  defaultName="Asset Group",
  showAddNewTab=true,
  isCampaignSuccess=false,
  disableDelete=false
}) => {
  const [deleteModal, setDeleteModal] = useState(null);
  console.log('ASSETS:', currCamp);
  const assetsTabs = currCamp?.map((asset, i) => {
      if (currAssetGroup === i)
          return (
              <div
                key={i}
                className='asset-group-tab'
                style={{
                  borderLeft: getTabColor(asset),
                  borderRight: getTabColor(asset),
                  borderTop: getTabColor(asset),
                  borderBottom: 0,
                  color: getNameColor(asset),
                  background: currStatus === "COMPLETED" ? '#F5FFFD' : 'white'
                }}
                onClick={() => onHandleClick(i)}
              >
              {defaultName} { asset?.[assetNumberField] || currAssetCounts}
              { (!isCampaignSuccess && currCamp.length > 1 ) && <img src={selectedCloseIcon} aria-disabled={disableDelete} alt='close' 
                  // onClick={(e) => handleRemove(e, i)}
                  onClick={(e) => {
                    if(disableDelete) return;
                    // if(forCampaign){
                    //   // e.stopPropagation();
                    //   handleRemoveAssetGroup(i)
                    // } else {
                      setDeleteModal(<AssetGroupDeleteModal handleClose={setDeleteModal} handleRemove={() => handleRemove(e, i, asset.id)} />)
                      e.stopPropagation();
                    // }
                  }}
                /> 
              }
              </div>
          );
      else 
          return (
              <div className='asset-group-tab' 
                key={i}
                style={{
                  color: getNameColor(asset),
                  background: asset?.status === "COMPLETED" ? '#F5FFFD' : 'white',
                }}
                onClick={() => onHandleClick(i)}
              >
              {defaultName} { asset?.[assetNumberField] || currAssetCounts}
              { (!isCampaignSuccess && currCamp.length > 1) && <img src={closeIcon} alt='close' 
                  // onClick={(e) => handleRemove(e, i)}
                  onClick={(e) => {
                  // if(forCampaign){
                  //   // e.stopPropagation();
                  //   handleRemoveAssetGroup(i);
                  // } else {
                    setDeleteModal(<AssetGroupDeleteModal handleClose={setDeleteModal} handleRemove={() => handleRemove(e, i, asset.id)} />);
                    e.stopPropagation();
                  // }
                }}
                />
              }
              </div>
          );
      });

  const handleRemove = (event, index, id) => {
      event.stopPropagation();
      handleRemoveAssetGroup(index, id);
      setDeleteModal(null);
  }

  const onHandleClick = (index) => {
      // setActiveIndex(index);
     handleChangeAssetGroup(index);
  }

  const onHandleAdd = index => {
     handleAddNewAsset(index);
  }

  return (
    <div className='asset-group-tab-list'>
      {/* Map the ad list here till the max asset group reaches */}

      {assetsTabs}
      {/* This below add new set should be removed after 7 asset */}
      { ( !isCampaignSuccess && (showAddNewTab && currCamp?.length < 7) )
        && <div className='asset-group-tab'
              onClick={() => onHandleAdd(currCamp.length)}
            >
             + {addNewAssetString}
           </div>
      }
      { deleteModal }
    </div>
  );
};

export default AssetGroupTabListView;
