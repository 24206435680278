import { useEffect, useState } from "react";
import { ReactComponent as IconAdd } from "../../../assets/icons/add-icon-circle.svg";
import CampaignCard from "../../CampaignCard/CampaignCard";
import { GooglePmaxTag } from "../../CampaignCard/CampaignTypeTags";
import CreateCampaignOrSegementSelectionModal, {
  ALL_OPTIONS,
  PMAX_OPTIONS,
} from "../../CampaignCard/CreateCampaignOrSegementSelectionModal";
import SegmentCard from "../../CampaignCard/SegmentCard/SegmentCard";
import "./PmaxCampaignsListPage.scss";
import {
  useDeletePmaxCampaign,
  useGetListPmaxStrategies,
  usePostPmaxStrategy,
  usePutPmaxStrategy,
} from "../../../services/googlePmaxStrategy";
import { getLocalStorage } from "../../../api/localStorage/localStorage";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";
import {
  useDeleteGoogleSegment,
  useGetListGoogleSegments,
  usePostGoogleSegment,
  usePutGoogleSegment,
} from "../../../services/googleSegments";
import { PMAX_OPTION } from "../../CampaignCard/CreateCampaignOrSegementSelectionModal/utils";
import PMaxStrategyCreation from "../../AccountManager/PMaxStrategyCreation";
import GoogleStrategySegment from "../../GoogleStrategySegment";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { useDispatch } from "react-redux";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import SegmentDeleteModal from "../SegmentDeleteModal";
import { BUDGET_TYPE } from "../../CampaignCard/constants";
import PmaxLoader from "../PmaxLoader";
import AssetGroupDeleteModal from "../AssetGroupDeleteModal";
import { usePostClientForms } from "../../../services/clientForms";

const setSegment = new Set();

export default function PmaxCampaignsListPage({
  backButton = () => {},
  formId,
  handleAssetGroup = () => {},
  handleSegmentLevel = () => {},
}) {
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  // const formId = getLocalStorage("formId");
  const { mutateAsync: mutatePostClientFormAsync } = usePostClientForms();
  const [segmentDeleteModal, setSegmentDeleteModal] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [segments, setSegments] = useState([]);
  const [segmentId, setSegmentId] = useState("");
  const [showSegment, setShowSegment] = useState(false);
  const [strategyId, setStrategyId] = useState("");
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showDeleteStrategyModal, setShowDeleteStrategyModal] = useState(false);
  const [showDeleteSegmentModal, setShowDeleteSegmentModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [index, setIndex] = useState(null);
  const [addButton, setAddButton] = useState(false);
  const [showStrategy, setShowStrategy] = useState(false);
  const [showPmaxCampaign, setShowPmaxCampaign] = useState(false);
  const { data: getCampaigns, isFetching: isFetchingPmaxStrategies } =
    useGetListPmaxStrategies(formId, "GUIDED", {
      onSuccess: (data) => {
        setSegment.clear();
        const strategies = data.strategies.map(
          (
            {
              id,
              title,
              formId,
              name,
              status,
              assets,
              budget,
              startDate,
              endDate,
            },
            index
          ) => ({
            title,
            id,
            formId,
            name: name || "",
            status,
            assets: assets.map((asset) => {
              if (asset.segment?.id) {
                setSegment.add(asset.segment.id);
              } else {
                asset.segment = {};
                asset.segment.name = "Segment 1";
                setSegment.add(asset.segment.name);
              }
              return { status: BACKEND_STATUS.ONGOING, ...asset };
            }),
            budget: budget || undefined,
            startDate: startDate ? startDate : new Date().toISOString(),
            endDate: endDate || "",
          })
        );
        setCampaigns(strategies);
      },
    });
  const { mutateAsync: mutatePutStrategy } = usePutPmaxStrategy(formId);
  const { mutateAsync: mutateDeleteStrategy } = useDeletePmaxCampaign(formId);
  const { mutateAsync: mutatePostStrategy } = usePostPmaxStrategy(formId);
  const { data: getSegments, isFetching: isFetchingGoogleSegments } =
    useGetListGoogleSegments(formId, "GUIDED", {
      onSuccess: (data) => {
        const segments = data.segments.map(
          ({ id, formId, name, status, title, keywords, targetingType }) => ({
            id,
            formId,
            name,
            status,
            title,
            keywords,
            targetingType,
          })
        );
        setSegments(
          segments.sort((a, b) => {
            const titleA = a.title.toUpperCase();
            const titleB = b.title.toUpperCase();
            if (titleA < titleB) return -1;
            if (titleA > titleB) return 1;
          })
        );
      },
    });
  const { mutateAsync: mutatePutSegment } = usePutGoogleSegment(formId);
  const { mutateAsync: mutatePostSegment } = usePostGoogleSegment(formId);
  const { mutateAsync: mutateDeleteSegment } = useDeleteGoogleSegment(formId);

  const handleConfirmCampaign = async (values, index, strategyId) => {
    console.log("confirming..");
    const { budget, ...fields } = values;
    try {
      const response = await mutatePutStrategy({
        strategyId,
        budget: budget?.type === BUDGET_TYPE.DAILY ? budget.value : 0,
        ...fields,
        status: BACKEND_STATUS.COMPLETED,
      });
      setCampaigns((prev) => {
        prev[index] = { ...response };
        return prev;
      });
    } catch (err) {
      console.error(err);
    }
  };
  const handleConfirmSegment = async (values, segmentId, index) => {
    try {
      const response = await mutatePutSegment({
        segmentId,
        status: BACKEND_STATUS.COMPLETED,
        ...values,
      });
      setSegments((prev) => {
        prev[index] = { ...response };
        return prev;
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteCampaign = async (campaignId, index) => {
    mutateDeleteStrategy(campaignId)
      .then(() => {
        setCampaigns((prev) => {
          const newCamapaigns = [...prev];
          newCamapaigns.splice(index, 1);

          // clear the set
          setSegment.clear();
          newCamapaigns.forEach(({ assets }) => {
            assets?.forEach((asset) => {
              setSegment.add(asset.segment.id || "Segment 1");
            });
          });
          return newCamapaigns;
        });
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setDeleteId("");
        setIndex(null);
        setShowDeleteStrategyModal(false);
      });
  };

  console.log("set of segments:", setSegment);
  console.log("campaigns:", campaigns);
  const handleDeleteSegment = async (segmentId, index, segmentTitle) => {
    if (setSegment.has(segmentId)) {
      setSegmentDeleteModal(true);
      return;
    }
    if (setSegment.has(segmentTitle)) {
      setSegmentDeleteModal(true);
      return;
    }
    setShowDeleteSegmentModal(true);
    setDeleteId(segmentId);
    setIndex(index);
  };

  const deleteSegment = async (segmentId, index) => {
    try {
      await mutateDeleteSegment(segmentId);
      setSegments((prev) => {
        const newSegments = [...prev];
        newSegments.splice(index, 1);
        return newSegments;
      });
    } catch (err) {
      console.error(err);
    } finally {
      setDeleteId("");
      setIndex(null);
      setShowDeleteSegmentModal(false);
    }
  };

  const onCreate = async (option) => {
    try {
      setAddButton(true);
      if (option === PMAX_OPTION.label) {
        const response = await mutatePostStrategy({ generationType: "GUIDED" });
        setCampaigns((prev) => [...prev, response]);
      } else {
        const response = await mutatePostSegment({ generationType: "GUIDED" });
        setSegments((prev) => [...prev, response]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setCreateModalOpen(false);
      setAddButton(false);
    }
  };

  const handlePublish = async () => {
    const data = {
      googlePmaxStrategy: {
        status: BACKEND_STATUS.COMPLETED,
      },
    };
    try {
      await mutatePostClientFormAsync(data);
      backButton();
    } catch (err) {
      console.log(err);
    }
  };

  const handleAssetPage = (strategyId) => {
    handleAssetGroup(strategyId);
    // setStrategyId(strategyId);
    // setShowPmaxCampaign(true);
  };
  const handleSegmentPage = (segmentId) => {
    handleSegmentLevel(segmentId);
    // setSegmentId(segmentId);
    // setShowSegment(true);
    // return <GoogleStrategySegment segmentId={segmentId} />
  };

  useEffect(() => {
    if (isFetchingPmaxStrategies || isFetchingPmaxStrategies) {
      setShowLoader(true);
    } else setShowLoader(false);
  }, [isFetchingPmaxStrategies, isFetchingGoogleSegments]);

  console.log("showLoader:", showLoader);

  const onCreateModalClose = () => setCreateModalOpen(false);
  const onCreateModalOpen = () => setCreateModalOpen(true);

  return (
    <section
      className="pmax-g-campaigns-list hdm-campaign-creation-container"
      style={{ paddingBottom: 74 }}
    >
      <header className="pmax-g-campaigns-list__header">
        <h2 className="pmax-g-campaigns-list__title">campaigns/ Segment</h2>
        <p className="pmax-g-campaigns-list__description">
          Multiple campaigns/ segments have been generated to add to a Client’s{" "}
          <br /> strategy deck. Please delete the ones not required to be
          shared.
        </p>
      </header>
      <div className="pmax-g-campaigns-list__content">
        {campaigns?.map((campaign, index) => (
          <CampaignCard
            countsLabel={`${campaign?.assets?.length ?? 0} Asset ${
              campaign?.assets?.length > 1 ? "groups" : "group"
            }`}
            isComplete={
              campaign.status === BACKEND_STATUS.COMPLETED ? true : false
            }
            onAdsetClick={() => handleAssetPage(campaign.id)}
            onConfirm={(values) =>
              handleConfirmCampaign(values, index, campaign.id)
            }
            disableConfirm={
              campaign?.assets?.find(
                (adset) =>
                  adset.status === BACKEND_STATUS.ONGOING || !adset?.status
              )?.status
                ? true
                : false
            }
            onDelete={() => {
              setShowDeleteStrategyModal(true);
              setDeleteId(campaign.id);
              setIndex(index);
            }}
            onErrors={(errors) => console.log(errors)}
            title={campaign.title}
            uid={campaign.id}
            key={campaign.id}
            formId={campaign.formId}
            defaultValues={{
              name: campaign.name,
              startDate: campaign?.startDate || null,
              endDate: campaign?.endDate || "",
              budget: { type: BUDGET_TYPE.DAILY, value: campaign.budget },
            }}
            displayLifetimeBudget={false}
            groupLevelName="Asset Group"
            tags={<GooglePmaxTag />}
          />
        ))}

        {segments.map((segment, index) => (
          <SegmentCard
            isComplete={
              segment.status === BACKEND_STATUS.COMPLETED ? true : false
            }
            onSearchTermClick={() => handleSegmentPage(segment.id)}
            onConfirm={(values) =>
              handleConfirmSegment(values, segment.id, index)
            }
            disableConfirm={
              segment?.keywords?.length && segment?.targetingType ? false : true
            }
            onDelete={() =>
              handleDeleteSegment(segment.id, index, segment.title)
            }
            title={segment.title}
            uid={segment.id}
            key={segment.id}
            defaultValues={{ name: segment?.name || "" }}
            tags={<GooglePmaxTag />}
          />
        ))}
      </div>
      <footer className="pmax-g-campaigns-list__footer">
        <button
          className="pmax-g-campaigns-list__button--width-full"
          onClick={onCreateModalOpen}
        >
          <IconAdd />
          <span>New Campaign/ Segment</span>
        </button>
      </footer>
      <div className="preview_footer_row">
        <div
          className="footer_down"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <button className="back" onClick={() => backButton()}>
            BACK
          </button>
          <PrimaryButton
            title="PROCEED"
            onClick={() => handlePublish()}
            disabled={
              (campaigns.some(
                (campaign) =>
                  !campaign.status ||
                  campaign?.status === BACKEND_STATUS.ONGOING
              ) ||
                segments.some(
                  (segment) =>
                    !segment.status ||
                    segment?.status === BACKEND_STATUS.ONGOING
                )) &&
              true
            }
          />
        </div>
      </div>

      {isCreateModalOpen && (
        <CreateCampaignOrSegementSelectionModal
          open={true}
          onClose={onCreateModalClose}
          onConfirm={onCreate}
          disabled={addButton}
          options={PMAX_OPTIONS}
        />
      )}
      {segmentDeleteModal && (
        <SegmentDeleteModal handleClose={() => setSegmentDeleteModal(false)} />
      )}
      {showLoader && <PmaxLoader />}
      {showDeleteStrategyModal && (
        <AssetGroupDeleteModal
          description="Are you sure you want to delete this?"
          subDescription="You won't be able to fetch this campaign and it's data again."
          handleRemove={() => handleDeleteCampaign(deleteId, index)}
          handleClose={() => {
            setShowDeleteStrategyModal(false);
            setDeleteId("");
            setIndex(null);
          }}
        />
      )}
      {showDeleteSegmentModal && (
        <AssetGroupDeleteModal
          description="Are you sure you want to delete this?"
          subDescription="You won't be able to fetch this segment and it's data again."
          handleRemove={() => deleteSegment(deleteId, index)}
          handleClose={() => {
            setShowDeleteSegmentModal(false);
            setDeleteId("");
            setIndex(null);
          }}
        />
      )}
    </section>
  );
}
