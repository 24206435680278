import PrimaryButton from '../ClientDashboard/ButtonPrimary/Buttons';
// import arrowDownIcon from '../../assets/icons/creative/arrow-down-icon.svg';
import { warningIcon } from '../../assets/icons/creative/creative';
import { Button, Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import FacebookLandingPage from '../CreativeResources/LandingPages/FacebookLandingPage';
import GoogleLandingPage from '../CreativeResources/LandingPages/GoogleLandingPage';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLandingPages, putLandingPages, updateLandingPageContent, updateLandingPageStatus, updateToBeSaved } from '../../api/creativeApproval/slice';
import { CREATIVE_OBJECT_STATUS_ENUM, PLATFORM_NAME } from '../../api/creativeApproval/enums';
import { updateCompoReduxClient } from '../../api/componentTracking/action';
const LandingPages = ({ isClient, onProceed }) => {
  const dispatch = useDispatch();
  const landingPages = useSelector((state) => state.creativeApproval.landingPages);
  const toBeSaved = useSelector((state) => state.creativeApproval.toBeSaved);
  const [newLandingPageUuid, setNewLandingPageUuid] = useState(null);
  const [addNewLandingPage, setAddNewLandingPage] = useState(false);
  const [openPending, setOpenPending] = useState(true);
  const [openApproved, setOpenApproved] = useState(false);
  const [openDisapproved, setOpenDisapproved] = useState(false);

  // cache landingPages
  const sortedLandingPages = useMemo(
      () => landingPages
              .slice()
              .sort((a, b) => b.updatedOn.localeCompare(a.updatedOn))
    , 
   [JSON.stringify(landingPages)]);

  useEffect(() => {
    dispatch(fetchLandingPages());
  }, []);

  useEffect(() => {
    if (toBeSaved) {
      // call api with put request
      dispatch(putLandingPages());
      dispatch(updateToBeSaved());
    }
  }, [toBeSaved]);

  const onSaveHandler = ({
    id,
    theme,
    campaignName,
    link
  }) => {
    dispatch(
      updateLandingPageContent({
        id,
        status: CREATIVE_OBJECT_STATUS_ENUM.PENDING,
        theme,
        campaignName,
        link
      })
    );
    if(newLandingPageUuid === id){
      setNewLandingPageUuid(null);
    }
  };

  const onApproveHandler = ({
    id,
    theme,
    campaignName,
    link
  }) => {
    dispatch(
      updateLandingPageContent({
        id,
        status: CREATIVE_OBJECT_STATUS_ENUM.APPROVED,
        theme,
        campaignName,
        link
      })
    );
    if(newLandingPageUuid === id){
      setNewLandingPageUuid(null);
    }
    setOpenApproved(true);
    setOpenPending(false);
    setOpenDisapproved(false);
  };

  const onDisapproveHandler = ({
    id,
    theme,
    campaignName,
    link
  }) => {
    dispatch(
      updateLandingPageContent({
        id,
        status: CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED,
        theme,
        campaignName,
        link
      })
    );
    if(newLandingPageUuid === id){
      setNewLandingPageUuid(null);
    }
    setOpenDisapproved(true);
    setOpenPending(false);
    setOpenApproved(false);
  };

  // this function wont be called from
  // client side
  const onRemoveHandler = (id) => {
    dispatch(updateLandingPageStatus({
        id, status: CREATIVE_OBJECT_STATUS_ENUM.REMOVED
    }));
  };

  return (
    <div>
      {sortedLandingPages.length ? (
        <div>
          <div>
            <Accordion
              expanded={openPending}
              style={{
                marginBottom: 16,
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                style={{
                  background: '#F7FAFF',
                  height: '64px',
                  cursor: 'pointer',
                }}
                expandIcon={<ExpandCircleDownOutlined />}
              IconButtonProps={{
                onClick: () => {
                  setOpenPending(prev => !prev);
                },
              }}
              >
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  Pending
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {sortedLandingPages.some((creative) => {
                    return creative.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING;
                }) ? (
                  sortedLandingPages
                    .filter((creative) => {
                        return creative.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING
                    })
                    .map((ad, index) => {
                      if (ad.marketingPlatform === PLATFORM_NAME.FACEBOOK) {
                        return (
                       <FacebookLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={CREATIVE_OBJECT_STATUS_ENUM.PENDING}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={isClient}
                         link={ad.link}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDisapproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                      if (ad.marketingPlatform === PLATFORM_NAME.GOOGLE) {
                        return (
                       <GoogleLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={CREATIVE_OBJECT_STATUS_ENUM.PENDING}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={isClient}
                         link={ad.link}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDisapproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                    })
                ) : (
                  <h1
                    style={{
                      fontFamily: 'Inter',
                      textAlign: 'center',
                      color: 'rgba(51,51,51,0.8)',
                      fontWeight: '500',
                      fontSize: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 6,
                    }}
                  >
                    <img src={warningIcon} alt='' width={20} height={20} />
                    No pending Landing Pages
                  </h1>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={openApproved}
              style={{
                marginBottom: 16,
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                style={{
                  background: 'rgba(26, 191, 161, 0.05)',
                  height: '64px',
                  cursor: 'pointer',
                }}
                expandIcon={<ExpandCircleDownOutlined />}
              IconButtonProps={{
                onClick: () => {
                  setOpenApproved(prev => !prev);
                },
              }}
              >
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  Approved
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {sortedLandingPages.some((creative) => {
                    return creative.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED;
                }) ? (
                  sortedLandingPages
                    .filter((creative) => {
                        return creative.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED
                    })
                    .map((ad, index) => {
                      if (ad.marketingPlatform === PLATFORM_NAME.FACEBOOK) {
                        return (
                       <FacebookLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={ad.status}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={isClient}
                         link={ad.link}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDisapproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                      if (ad.marketingPlatform === PLATFORM_NAME.GOOGLE) {
                        return (
                       <GoogleLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={ad.status}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={isClient}
                         link={ad.link}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDisapproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                    })
                ) : (
                  <h1
                    style={{
                      fontFamily: 'Inter',
                      textAlign: 'center',
                      color: 'rgba(51,51,51,0.8)',
                      fontWeight: '500',
                      fontSize: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 6,
                    }}
                  >
                    <img src={warningIcon} alt='' width={20} height={20} />
                    No approved Landing Pages
                  </h1>
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={openDisapproved}
              style={{
                marginBottom: 16,
                boxShadow: 'none',
              }}
            >
              <AccordionSummary
                aria-controls='panel1d-content'
                id='panel1d-header'
                style={{
                  background: 'rgba(232, 75, 71, 0.05)',
                  height: '64px',
                  cursor: 'pointer',
                }}
                expandIcon={<ExpandCircleDownOutlined />}
              IconButtonProps={{
                onClick: () => {
                    setOpenDisapproved(prev => !prev);
                },
              }}
              >
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: 500,
                    fontSize: 18,
                  }}
                >
                  Disapproved
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {sortedLandingPages.some((creative) => {
                    return creative.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED;
                }) ? (
                  sortedLandingPages
                    .filter((creative) => {
                        return creative.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED
                    })
                    .map((ad, index) => {
                      if (ad.marketingPlatform === PLATFORM_NAME.FACEBOOK) {
                        return (
                       <FacebookLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={ad.status}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={isClient}
                         link={ad.link}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDisapproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                      if (ad.marketingPlatform === PLATFORM_NAME.GOOGLE) {
                        return (
                       <GoogleLandingPage 
                         key={index}
                         expand={newLandingPageUuid === ad.id ? true : false}
                         read={newLandingPageUuid === ad.id ? false : true}
                         status={ad.status}
                         id={ad.id}
                         theme={ad.theme}
                         campaignName={ad.campaignName}
                         isClient={isClient}
                         link={ad.link}
                         onSaveHandler={onSaveHandler}
                         onApproveHandler={onApproveHandler}
                         onDisapproveHandler={onDisapproveHandler}
                         onRemoveHandler={onRemoveHandler}
                       />
                        );
                      }
                    })
                ) : (
                  <h1
                    style={{
                      fontFamily: 'Inter',
                      textAlign: 'center',
                      color: 'rgba(51,51,51,0.8)',
                      fontWeight: '500',
                      fontSize: 20,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 6,
                    }}
                  >
                    <img src={warningIcon} alt='' width={20} height={20} />
                    No disapproved Landing Pages
                  </h1>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 35,
            marginTop: '8rem',
          }}
        >
          <div
            style={{
              width: 200,
              height: 200,
              background: '#FFFFFF',
              border: '0.5px solid #E9E9E9',
              boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.04)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '0 40px 0 40px',
            }}
          >
            <img src={warningIcon} alt='' />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20,
            }}
          >
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 24,
                lineHeight: '27px',
              }}
            >
              No Landing Pages to show!
            </Typography>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                lineHeight: '26px',
                color: '#6F6C90',
              }}
            >
              Please wait while we create some landing pages for your approval.
            </Typography>
          </div>
        </div>
      )}
      <div
        className='preview_footer_row'
        style={{
          borderTop: '1px solid #dbdee2',
          padding: '17px 25px 16px',
          position: 'fixed',
          bottom: 0,
          left: '307px',
          right: '17px',
          background: '#fff',
          zIndex: 99,
        }}
      >
        <div className='footer_con' style={{ justifyContent: 'end' }}>
          <Button
            style={{
              width: isClient ? '96px' : '172px',
              height: '40px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              textTransform: 'capitalize',
              background: '#0869FB',
              cursor: 'pointer',
            }}
            onClick={() => onProceed()}
          >
            PROCEED
          </Button>
        </div>
      </div>
    </div>
  );
};

export default LandingPages;
