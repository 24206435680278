import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import useKeywordsSearch from './useKeywordsSearch';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

// render nothing as listing is done outside
const MyChip = (props) => { };

export default function KeywordsSearchInput({
  keywords,
  replace,
}) {
  const { keywordOptions, setSearchText, searchText } = useKeywordsSearch();

  return (
    <Autocomplete
      multiple
      value={keywords}
      inputValue={searchText}
      renderTags={(tagValue) => {
        return tagValue.map((option, index) => (
          <MyChip
            label={option.name}
            key={option.id}
            index={index}
          />
        ));
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option.title, inputValue, {
          insideWords: true,
        });
        const parts = parse(option.title, matches);
        return (
          <li {...props} className='search_location_list'>
            <div className='location_list_head'>
              {' '}
              <p>Interest Keywords</p>
            </div>
            <div className='location_list_content'>
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight
                        ? '#0869FB'
                        : '#333333',
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </div>
          </li>
        );
      }}
      options={keywordOptions}
      getOptionLabel={(option) => option.title}
      renderInput={(params) => (
        <div className='location_question_header'>
          <span className='search_icon'></span>
          <TextField
            {...params}
            ref={params.InputProps.ref}
            autoFocus
            placeholder='Search'
            variant='standard'
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
      )}
      onChange={(_e, val) => replace(val)}
      disablePortal={true}
      className='auto_location_search'
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  )
}