import Button from "@mui/material/Button";
import { useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { fileUpload as FUpload } from "../../assets/icons/icon";
import { uploadFile } from "../../api/fileHandling/saga";

const StyledDrop = (props) => {
  const onDrop = useCallback((acceptedFiles) => {}, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      <Button
        component="label"
        style={{
          border: "1px solid #eee",
          padding: "10px",
          backgroundColor: "#F8FBFF",
          width: "90%",
          height: "150px",
        }}
      >
        <Dropzone
          onDrop={(acceptedFiles) => {
            acceptedFiles.map((file, index) => {
              let formData = new FormData();
              formData.append("file", file);
              uploadFile(formData).then((res) => {
                props.setFile(res.fileId);
              });
              return file;
            });
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <img src={FUpload} />
              </div>
            </section>
          )}
        </Dropzone>
      </Button>
    </>
  );
};
export default StyledDrop;
