import {
  Box,
  Button,
} from "@mui/material";
import React, { useEffect } from "react";
import { NEW_FILTER } from "../../../models/chart";
import FilterList from "./FilterList";

export default function FilterBox({ title, channel, dimensions, metrics, filterListRef }) {
  const filters = dimensions.concat(metrics);
  const [filterNumbers, setFilterNumbers] = React.useState(filterListRef.current.length);

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          sx={{
            display: "flex",
            justifyContent: "right",
          }}
          onClick={() => {
            filterListRef.current = [NEW_FILTER];
            setFilterNumbers(filterNumbers <= 0 ? filterNumbers - 1 : 0);
          }}
        >
          Clear Filters
        </Button>
      </Box>
      <Box
        sx={{
          padding: "10px",
          paddingTop: "5px",
          border: "1px solid #DDDDDD",
          borderRadius: "4px",
        }}>
        <p>{title}</p>
        <Box>
          {Array.from(Array(Math.max(filterNumbers, 1))).map((_, index) =>
            <FilterList key={filterNumbers * (index + 1)} channel={channel} filters={filters} currentFilter={filterListRef.current[index]}
              onChange={filter => {
                const newFilters = Array.from(filterListRef.current);
                const earlierOperator = filterListRef.current[index].boolOperator;
                newFilters[index] = filter;
                if (!earlierOperator && filter.boolOperator) {
                  newFilters.push(NEW_FILTER);
                  setFilterNumbers(newFilters.length);
                }
                filterListRef.current = newFilters;
              }}
              onDelete={index > 0 ? () => {
                const newFilters = Array.from(filterListRef.current);
                if (index === newFilters.length - 1) { newFilters[index - 1].boolOperator = null; }
                filterListRef.current = newFilters.slice(0, index).concat(newFilters.slice(index + 1));
                setFilterNumbers(filterListRef.current.length);
              } : null}
            />
          )}
        </Box>
      </Box>
    </div>
  );
}
