import { address2_icon as addressIcon } from "../../../assets/icons/questionnare/questionnare";
import { company_icon as companyIcon } from "../../../assets/icons/questionnare/questionnare";
import { contact_icon as contactIcon } from "../../../assets/icons/questionnare/questionnare";
import crmSettingIcon from "../../../assets/icons/questionnare/crm_setting_icon.png";
import { diamond_icon as diamondIcon } from "../../../assets/icons/questionnare/questionnare";
import { email_icon as emailIcon } from "../../../assets/icons/questionnare/questionnare";
import { competitor_icon as growthIcon } from "../../../assets/icons/questionnare/questionnare";
import { industry_icon as industryIcon } from "../../../assets/icons/questionnare/questionnare";
import { primary_contact_name_icon as primaryContactIcon } from "../../../assets/icons/questionnare/questionnare";
import { growth_goal_icon as growthGoalIcon } from "../../../assets/icons/questionnare/questionnare";
import primaryEmailIcon from "../../../assets/icons/questionnare/email_icon.svg";
import { ticket_icon as ticketIcon } from "../../../assets/icons/questionnare/questionnare";
import { website_icon as websiteIcon } from "../../../assets/icons/questionnare/questionnare";
import { target_cpa_icon as targetCpaIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { profit_margin_icon as profitMarginIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { average_sales_icon as averageSalesIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { seasonability_icon as seasonabilityIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { app_related_services_icon as companyServicesIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { product_information_icon as productInfoIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { top_competitors_icon as topCompetitorIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { monthly_ad_spend_icon as monthlyAdSpendIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { lead_preferences_icon as leadPreferencesIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { monthly_budget_icon as monthlyBudgetIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { location_icon as locationIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { gender_icon as genderIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { advertise_icon as advertiseIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { maritial_status_icon as maritalStatusIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { customer_w_b_icon as customerWBIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { customer_interest_icon as interestIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { group_icon as excludeGroupIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { customer_household_income_icon as incomeIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { education_icon as educationIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { employment_status_icon as employmentIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { branding_icon as brandingIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { company_upload_logo_icon as companyUploadLogoIcon } from "../../../assets/icons/intakeForm/intakeForm";
import { award_icon as awardIcon } from "../../../assets/icons/intakeForm/intakeForm";
import customerTestimonialIcon from "../../../assets/icons/intakeForm/customer-testimonial-icon.svg";
import {
  clientDashboardIds,
  LDcomponentWithIndexClient,
} from "../../../utils/clientDashboardIdsUtil";
import { generatePath } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import ldClientRoutePaths from "../../../pages/ld-client/constants/ldClientRoutePaths";

const questionBasicInformation = {
  company: {
    icon: companyIcon,
    questNumber: 1,
    questName: "Company Name*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST1], //question number based on order of component rendering (clientDashboardUtil)
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  industry: {
    icon: industryIcon,
    questNumber: 2,
    questName: "Industry",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST2],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/2`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  companyWebsite: {
    icon: websiteIcon,
    questNumber: 3,
    questName: "Company Website*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST3],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/3`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  companyEmail: {
    icon: emailIcon,
    questNumber: 4,
    questName: "Company Email Address",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST4],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/4`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  companyPhone: {
    icon: contactIcon,
    questNumber: 5,
    questName: "Company Phone Number",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST5],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/5`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  companyAddress: {
    icon: addressIcon,
    questNumber: 6,
    questName: "Company Office Address",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST6],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/6`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  primaryContactName: {
    icon: primaryContactIcon,
    questNumber: 7,
    questName: "Primary Contact - Full Name*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST7],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/7`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  primaryContactRole: {
    icon: primaryContactIcon,
    questNumber: 8,
    questName: "Primary Contact - Role/Title*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST8],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/8`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  primaryContactEmail: {
    icon: emailIcon,
    questNumber: 9,
    questName: "Primary Contact - Email Address*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST9],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/9`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  primaryContactNumber: {
    icon: contactIcon,
    questNumber: 10,
    questName: "Primary Contact - Phone Number*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST10],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}/10`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
};
const platformInformation = {
  platforms: {
    questNumber: 1,
    questName: "Please choose the platforms that you want us to work on*",
    questDescrip:
      "After choosing platforms, kindly mention their Spends and CPA to proceed.",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST1
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.platformInformation}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  averageTicketPrice: {
    icon: ticketIcon,
    questNumber: 2,
    questName: "What is your Average Ticket Price(in $)?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST2
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.platformInformation}/2`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  cltv: {
    icon: diamondIcon,
    questNumber: 3,
    questName: "What is your Customer Lifetime Value(in $)?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST3
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.platformInformation}/3`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  crm: {
    icon: crmSettingIcon,
    questNumber: 4,
    questName: "Which CRM do you use?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST4
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.platformInformation}/4`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  leadSource: {
    questNumber: 5,
    questName: "What are your sources of leads?",
    questDescrip: "Select as many as you use",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST5
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.platformInformation}/5`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  competitors: {
    icon: growthIcon,
    questNumber: 6,
    questName: "Enter your top competitors and their websites*",
    questDescrip: "Enter as many competitors as you want",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.PLATFORM_INFORMATION_QUEST6
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.platformInformation}/6`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
  // 'Google-Facebook-CRO': {
  //   icon: growthGoalIcon,
  //   questNumber: 7,
  //   questName:
  //     'Please select the platforms for which you require our services.',
  //   questDescrip: '',
  //   id: LDcomponentWithIndexClient[clientDashboardIds.PLATFORM_INFORMATION_QUEST7],
  // },
  // brandDescription: {
  //   icon: growthIcon,
  //   questNumber: 8,
  //   questName: 'Describe your brand*',
  //   questDescrip:
  //     'Please start the description with your brand name and describe what the brand does/ sells. It would be great if you can mention some claims made by your brand or your USP, along with the type of audience you usually target.',
  //   id: LDcomponentWithIndexClient[clientDashboardIds.PLATFORM_INFORMATION_QUEST8],
  // },
};
const growthGoals = {
  goals: {
    icon: growthGoalIcon,
    questNumber: 1,
    questName: "What are your growth goals?",
    questDescrip: "Please elaborate your expectations and goals here",
    id: LDcomponentWithIndexClient[clientDashboardIds.GROWTH_GOALS_QUEST1],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.growthGoals}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: "",
  },
};

const intakeFormProductInfo = {
  targetCpa: {
    icon: targetCpaIcon,
    questNumber: 1,
    questName: "What is your Target CPA (in $)?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM1],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.productInfo}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}/1`
    ),
  },
  profitMargin: {
    icon: profitMarginIcon,
    questNumber: 2,
    questName: "What is your Profit Margin?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM2],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.productInfo}/2`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}/2`
    ),
  },
  avgClosingPercent: {
    icon: averageSalesIcon,
    questNumber: 3,
    questName: "What is your average sales closing percentage?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM3],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.productInfo}/3`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}/3`
    ),
  },
  sessionality: {
    icon: seasonabilityIcon,
    questNumber: 4,
    questName: "Is there any seasonality to your products/services?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM4],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.productInfo}/4`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}/4`
    ),
  },
  appRelatedService: {
    icon: companyServicesIcon,
    questNumber: 5,
    questName: "Does your company have an app related to these services?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM5],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.productInfo}/5`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}/5`
    ),
  },
  descriptions: {
    icon: productInfoIcon,
    questNumber: 6,
    questName: "Product/ Service Information*",
    questDescrip:
      "Please provide information about the products you want to advertise. NOTE: Please write only about the products/services that you wish to advertise. ",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM6],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.productInfo}/6`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}/6`
    ),
  },
  brandDescription: {
    icon: productInfoIcon,
    questNumber: 7,
    questName: "Describe your brand*",
    questDescrip:
      "Please start the description with your brand name and describe what the brand does/ sells. It would be great if you can mention some claims made by your brand or your USP, along with the type of audience you usually target.",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM7],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.productInfo}/7`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}/7`
    ),
  },
  leadEmailAddress: {
    icon: emailIcon,
    questNumber: 8,
    questName: "On which email address should the leads be sent?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM8],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.productInfo}/8`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}/8`
    ),
  },
  brandServiceKeywords: {
    icon: productInfoIcon,
    questNumber: 9,
    questName: "Please add keywords relevant to your brand and services.",
    questDescrip:
      "Please provide information about the products you want to advertise.",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM9],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.productInfo}/9`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}/9`
    ),
  },
};

const intakeFormPreferences = {
  competitors: {
    icon: topCompetitorIcon,
    questNumber: 1,
    questName: "Enter your top competitors and their websites*",
    questDescrip: "You can enter many inputs",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES1
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.preferences}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.preferences}/1`
    ),
  },
  monthlyAdSpend: {
    icon: monthlyAdSpendIcon,
    questNumber: 2,
    questName:
      "What is your total monthly ad spend budget (across all platforms, in $)?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES2
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.preferences}/2`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.preferences}/2`
    ),
  },
  leadPreferences: {
    icon: leadPreferencesIcon,
    questNumber: 3,
    questName: "What are your lead preferences?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES3
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.preferences}/3`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.preferences}/3`
    ),
  },
  trackingNumberDisplay: {
    icon: contactIcon,
    questNumber: 4,
    questName:
      "Would you prefer your tracking phone number to show as your local area code or a toll-free number?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES4
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.preferences}/4`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.preferences}/4`
    ),
  },
  handleDepletedAccountBalance: {
    icon: monthlyBudgetIcon,
    questNumber: 5,
    questName:
      "On rare occasions, your monthly budget may be depleted a few days before the end of the month. If this ever happens with your account how should we proceed?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES5
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.preferences}/5`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.preferences}/5`
    ),
  },
  customerBaseCountries: {
    icon: locationIcon,
    questNumber: 6,
    questName:
      "In which country does your customer base primarily lie? Please select multiple if required.*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES6
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.preferences}/6`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.preferences}/6`
    ),
  },
};

const intakeFormAudience = {
  ageRange: {
    icon: monthlyAdSpendIcon,
    questNumber: 1,
    questName: "What is the age range of your customer base?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE1
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/1`
    ),
  },
  geoTargets: {
    icon: locationIcon,
    questNumber: 2,
    questName: "Enter the locations where you'd like to run your ad",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE2
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/2`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/2`
    ),
  },
  gender: {
    icon: genderIcon,
    questNumber: 3,
    questName: "Which gender is your main customer base?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE3
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/3`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/3`
    ),
  },
  advertiseLocation: {
    icon: advertiseIcon,
    questNumber: 4,
    questName: "Where do you advertise?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE4
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/4`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/4`
    ),
  },
  maritalStatus: {
    icon: maritalStatusIcon,
    questNumber: 5,
    questName: "What is the marital status of your target customer?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE5
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/5`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/5`
    ),
  },
  customerCollar: {
    icon: customerWBIcon,
    questNumber: 6,
    questName: "Are your customers white-collar or blue-collar?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE6
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/6`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/6`
    ),
  },
  activitiesInterest: {
    icon: interestIcon,
    questNumber: 7,
    questName: "What activities/interests do your best customers have?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE7
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/7`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/7`
    ),
  },
  excludeAudiences: {
    icon: excludeGroupIcon,
    questNumber: 8,
    questName:
      "Are there specific locations, markets, or groups of people we should exclude?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE8
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/8`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/8`
    ),
  },
  customerIncomeRange: {
    icon: incomeIcon,
    questNumber: 9,
    questName: "What is your target customer's yearly household income?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE9
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/9`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/9`
    ),
  },
  customerEducation: {
    icon: educationIcon,
    questNumber: 10,
    questName:
      "What is the highest level of school your target customer has completed or the highest degree they have received? (Check all that apply)*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE10
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/10`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/10`
    ),
  },
  customerEmployment: {
    icon: employmentIcon,
    questNumber: 11,
    questName:
      "Which of the following categories best describes your target customer's employment status?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE11
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/11`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/11`
    ),
  },
  formalCustomerPersona: {
    icon: primaryContactIcon,
    questNumber: 12,
    questName: "If you have formal customer personas please upload them here.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE12
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.googleTargeting}/12`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.googleTargeting}/12`
    ),
  },
};
const intakeBrandingForm = {
  brandingStyleGuide: {
    icon: companyIcon,
    questNumber: 1,
    questName:
      "Please upload your company's branding or style guide. Should include company colors, fonts, logos, and usage guidelines.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING1
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.branding}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.branding}/1`
    ),
  },
  companyLogo: {
    icon: companyIcon,
    questNumber: 2,
    questName:
      "Please upload your company's logo in vector or its largest format.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING2
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.branding}/2`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.branding}/2`
    ),
  },

  brandingMaterials: {
    icon: companyIcon,
    questNumber: 3,
    questName: "Please upload your company branding material here.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING3
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.branding}/3`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.branding}/3`
    ),
  },
  companyClientLogo: {
    icon: companyIcon,
    questNumber: 4,
    questName:
      "Please upload your most reputable client's logos. (B2B clients only) ",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING4
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.branding}/4`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.branding}/4`
    ),
  },
  companyClientWebsite: {
    icon: companyIcon,
    questNumber: 5,
    questName:
      "If you don't have the logos of your clients, please list their websites. We will do our best to gather their logos.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING5
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.branding}/5`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.branding}/5`
    ),
  },
  awards: {
    icon: companyIcon,
    questNumber: 6,
    questName:
      "Please upload logos, awards, and any other credibility signals. For example, mentioned in Forbes, won Best of State, Fortune 500, etc.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING6
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.branding}/6`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.branding}/6`
    ),
  },
  productImages: {
    icon: companyIcon,
    questNumber: 7,
    questName:
      "Please upload any photos, product images, and graphical assets. Include product photos, location photos, custom icon kits, custom graphics, etc.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_BRANDING7
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.branding}/7`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.branding}/7`
    ),
  },
};
const intakeSocialProofForm = {
  testimonial1: {
    icon: companyIcon,
    questNumber: 1,
    questName:
      "Customer Testimonial #1 Include the customer's name, age, gender, and job title when relevant.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF1
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.socialProof}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.socialProof}/1`
    ),
  },
  testimonial2: {
    icon: companyIcon,
    questNumber: 2,
    questName:
      "Customer Testimonial #2 Include the customer's name, age, gender, and job title when relevant.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF2
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.socialProof}/2`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.socialProof}/2`
    ),
  },
  testimonial3: {
    icon: companyIcon,
    questNumber: 3,
    questName:
      "Customer Testimonial #3 Include the customer's name, age, gender, and job title when relevant.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[
      clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF3
    ],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.socialProof}/3`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.socialProof}/3`
    ),
  },
};
export const fbTargeting = {
  ageRange: {
    questNumber: 1,
    questName: "What is the age range of your target customer base?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING1],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.facebookTargeting}/1`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.facebookTargeting}/1`
    ),
  },
  gender: {
    questNumber: 2,
    questName: "What is the gender of your target customer base?*",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING2],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.facebookTargeting}/2`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.facebookTargeting}/2`
    ),
  },
  advertisingLocations: {
    questNumber: 3,
    questName: "What locations do you advertise in?",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING3],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.facebookTargeting}/3`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.facebookTargeting}/3`
    ),
  },
  interestKeywords: {
    questNumber: 4,
    questName:
      "Please mention a few interest keywords that you want us to target.*",
    questDescrip:
      "Try to keep the interest keywords generic (related to your brand or service, as it will help us in finding better target groups for you.",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING4],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.facebookTargeting}/4`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.facebookTargeting}/4`
    ),
  },
  excludes: {
    questNumber: 5,
    questName:
      "Are there specific locations, markets, or groups of people we should exclude? Enter them here.",
    questDescrip: "",
    id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING5],
    amNavigationPath: generatePath(
      `${ldAmRoutePaths.reviewIntakeForm.fullbase}/${ldAmRoutePaths.reviewIntakeForm.sub.facebookTargeting}/5`,
      { formId: localStorage.getItem("formId") || "" }
    ),
    clientNavigationPath: generatePath(
      `${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.facebookTargeting}/5`
    ),
  },
};
export const crmQuestions = [
  "Mail Chimp",
  "Constant Contact",
  "Speak2Leads",
  "Campaign Monitor",
  "HubSpot",
  "Salesforce",
  "AWeber",
  "Infusionsoft",
  "Don't use any",
  "Other",
];

export const sourcesOfLeads = [
  "Pay-per-click (PPC) advertising",
  "Listing websites",
  "Social Media",
  "Email newsletters",
  "Referrals",
  "Search engines",
  "Other",
];

export const mappedQuestion = {
  personalInfo: questionBasicInformation,
  platformInfo: platformInformation,
  growthGoal: growthGoals,
  productServiceInfo: intakeFormProductInfo,
  preferences: intakeFormPreferences,
  audiences: intakeFormAudience,
  brandAssets: intakeBrandingForm,
  customerTestimonial: intakeSocialProofForm,
  facebookTargeting: fbTargeting,
};

export const mappedFbTargetingQuestion = {
  facebookTargeting: fbTargeting,
};

export const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
