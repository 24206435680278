export const getAudiencesHdmAm = () => {
    return {
        type:"UPDATE_FACEBOOK_ADS_AUDIENCES_SAGA"
    }
}
export const getAlreadyAuditDetailsHdm = () => {
    return {
        type:"UPDATE_FACEBOOK_ADS_SAGA"
    }
}

export const getFacebookKeywords = () => {
    return {
        type:"UPDATE_KEYWORDS_ADS_SAGA_HDM"
    }
}

export const getFacebookAdsStrategy = () => {
    return {
        type:"UPDATE_FACEBOOK_ADS_SAGA_STARTEGY"
    }
}