import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProposalPublishedModal from "../../modals/ProposalPublishedModal";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import {
  publishProposalReducer,
  getFormByFormIdReducer,
} from "../../../api/accountManager/forms/slice";
import { goBackAmCompo } from "../../../api/accountManager/componentTracking/action";

import { getImageUrl } from "../../../api/fileHandling/saga";
import NewOverview from "./NewOverview";
import { getTimeframeData } from "../../../utils/LDProposalUtils";
import { PROPOSAL_STATE } from "../../../utils/accountManagerUtil";
import {
  accountManagerIds,
  ldComponentWithIndexAm,
} from "../../../utils/accountManagerUtil";
import { goToSpecificCompoClientAm } from "../../../api/accountManager/componentTracking/action";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import { useGetClientForms } from "../../../services/clientForms";

const Overview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  // const form = useSelector((state) => state.form.form);
  const { data: form = {} } = useGetClientForms(formId);

  const [links, setLinks] = useState({
    companyLogo: null,
    signature: null,
  });
  const [formSubm, setFormSubm] = useState(false);
  const [clientIntroData, setClientIntroData] = useState({});
  useEffect(() => {
    setClientIntroData(form);
  }, [form]);
  const insights = useSelector((state) => state.googleCustomers);
  const [listofAdsSelected, setListsOfAdsSelected] = useState("");
  const [timeframeData, setTimeFrameData] = useState({});
  const sowPricingInfo = form?.sowPricingInfo?.clientPricing?.addonServices;

  const platforms = form?.onboardingInfo?.platformInfo?.platforms?.map(
    (platform) => platform.platform
  );
  console.log(form);
  useEffect(() => {
    dispatch(getFormByFormIdReducer());
  }, []);

  useEffect(() => {
    if (form) {
      setTimeFrameData(getTimeframeData(form?.sowPricingInfo?.clientPricing));
      setClientIntroData(form);
      if (form?.companyInfo?.companyLogo && form?.companyInfo?.signature) {
        Promise.allSettled([
          getImageUrl(form.companyInfo.companyLogo),
          getImageUrl(form.companyInfo.signature),
        ]).then((res) => {
          setLinks({
            companyLogo:
              res[0].status === "fulfilled" &&
              URL.createObjectURL(res[0].value),
            signature:
              res[1].status === "fulfilled" &&
              URL.createObjectURL(res[1]?.value),
          });
        });
      }
    }
  }, [form]);

  useEffect(() => {
    setClientIntroData({ ...clientIntroData, ...links });
  }, [links]);
  useEffect(() => {
    let str = "";
    if (clientIntroData?.companyInfo?.googleAdsManaged) {
      str += "Google Ads + ";
    }
    if (clientIntroData?.companyInfo?.fbAdsManaged) {
      str += "Facebook Ads + ";
    }
    if (clientIntroData?.companyInfo?.implementCro) {
      str += "CRO";
    }
    setListsOfAdsSelected(str);
  }, []);
  return (
    <div id="preview_proposal" className="proposal_layout proposal_flex_top ">
      <NewOverview proposal={form} timeframeData={timeframeData} />
      <div className="preview_footer_row">
        <div className="footer_con" style={{ justifyContent: "space-between" }}>
          {/* To be dispatched based on condition of platform  */}
          <button
            className="back"
            onClick={() =>
              platforms?.find((platform) => platform === "facebook-ads")
                ? navigate(
                    generatePath(
                      `${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.ad}`,
                      { formId }
                    )
                  )
                : navigate(
                    generatePath(
                      `${ldAmRoutePaths.auditForGoogle.fullbase}/${ldAmRoutePaths.auditForGoogle.sub.reviewPoints}`,
                      { formId }
                    )
                  )
            }
          >
            BACK
          </button>
          <PrimaryButton
            title="PUBLISH"
            disabled={
              form?.proposalIterations &&
              [
                PROPOSAL_STATE.APPROVED,
                PROPOSAL_STATE.CHANGES_APPLIED,
                PROPOSAL_STATE.GENERATED,
              ].includes(
                form?.proposalIterations[form?.proposalIterations?.length - 1]
                  ?.state
              )
            }
            onClick={() => {
              dispatch(publishProposalReducer());
              setFormSubm(true);
            }}
          />
        </div>

        {formSubm && (
          <div>
            <ProposalPublishedModal
              formSubm={clientIntroData}
              setFormSubm={setFormSubm}
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default Overview;
