import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaigns: [],
  selectedBrandKeywords: [],
  unselectedBrandKeywords: [],
  removedBrandKeywords: [],
  selectedCompetitorKeywords: {},
  unselectedCompetitorKeywords: {},
  removedCompetitorKeywords: {},
  selectedServiceKeywords: {},
  unselectedServiceKeywords: {},
  removedServiceKeywords: {},
  selectedBrandHeadlines: {},
  unselectedBrandHeadlines: {},
  removedBrandHeadlines: {},
  selectedBrandDescription: {},
  unselectedBrandDescription: {},
  removedBrandDescription: {},
  selectedCompetitorHeadlines: {},
  unselectedCompetitorHeadlines: {},
  removedCompetitorHeadlines: {},
  selectedCompetitorDescription: {},
  unselectedCompetitorDescription: {},
  removedCompetitorDescription: {},
  selectedServiceHeadlines: {},
  unselectedServiceHeadlines: {},
  removedServiceHeadlines: {},
  selectedServiceDescription: {},
  unselectedServiceDescription: {},
  removedServiceDescription: {},
  BrandBudget: 0,
  CompetitorBudget: 0,
  ServiceBudget: 0,
  hasCalledTAI: false,

  affectedBrandKeywords: {},
  affectedCompetitorKeywords: {},
  affectedServiceKeywords: {},
};

export const targetSlice = createSlice({
  name: "targetAI",
  initialState,
  reducers: {
    generateKeywordsReducer() {},
    getKeywordsReducer() {},
    updateKeywordStatusReducer() {},
    updateKeywordsStatusReducer() {},
    editKeywordStatusReducer() {},
    updateSelectedHeadlines() {},
    updateSelectedDescriptions() {},
    updateCampaignsData: (state, action) => {
      state.campaigns = action.payload;
    },
    updateSelectedCompetitorKeywords: (state, action) => {
      state.selectedCompetitorKeywords = action.payload;
    },
    updateUnselectedCompetitorKeywords: (state, action) => {
      state.unselectedCompetitorKeywords = action.payload;
    },
    updateRemovedCompetitorKeywords: (state, action) => {
      state.removedCompetitorKeywords = action.payload;
    },
    updateSelectedBrandKeywords: (state, action) => {
      state.selectedBrandKeywords = action.payload;
    },
    updateUnselectedBrandKeywords: (state, action) => {
      state.unselectedBrandKeywords = action.payload;
    },
    updateRemovedBrandKeywords: (state, action) => {
      state.removedBrandKeywords = action.payload;
    },
    updateSelectedServiceKeywords: (state, action) => {
      state.selectedServiceKeywords = action.payload;
    },
    updateUnselectedServiceKeywords: (state, action) => {
      state.unselectedServiceKeywords = action.payload;
    },
    updateRemovedServiceKeywords: (state, action) => {
      state.removedServiceKeywords = action.payload;
    },
    updateSelectedBrandHeadlines: (state, action) => {
      state.selectedBrandHeadlines = action.payload;
    },
    updateUnselectedBrandHeadlines: (state, action) => {
      state.unselectedBrandHeadlines = action.payload;
    },
    updateRemovedBrandHeadlines: (state, action) => {
      state.removedBrandHeadlines = action.payload;
    },
    updateSelectedBrandDescriptions: (state, action) => {
      state.selectedBrandDescription = action.payload;
    },
    updateUnselectedBrandDescriptions: (state, action) => {
      state.unselectedBrandDescription = action.payload;
    },
    updateRemovedBrandDescriptions: (state, action) => {
      state.removedBrandDescription = action.payload;
    },
    updateSelectedCompetitorHeadlines: (state, action) => {
      state.selectedCompetitorHeadlines = action.payload;
    },
    updateUnselectedCompetitorHeadlines: (state, action) => {
      state.unselectedCompetitorHeadlines = action.payload;
    },
    updateRemovedCompetitorHeadlines: (state, action) => {
      state.removedCompetitorHeadlines = action.payload;
    },
    updateSelectedCompetitorDescriptions: (state, action) => {
      state.selectedCompetitorDescription = action.payload;
    },
    updateUnselectedCompetitorDescriptions: (state, action) => {
      state.unselectedCompetitorDescription = action.payload;
    },
    updateRemovedCompetitorDescriptions: (state, action) => {
      state.removedCompetitorDescription = action.payload;
    },
    updateSelectedServiceHeadlines: (state, action) => {
      state.selectedServiceHeadlines = action.payload;
    },
    updateUnselectedServiceHeadlines: (state, action) => {
      state.unselectedServiceHeadlines = action.payload;
    },
    updateRemovedServiceHeadlines: (state, action) => {
      state.removedServiceHeadlines = action.payload;
    },
    updateSelectedServiceDescriptions: (state, action) => {
      state.selectedServiceDescription = action.payload;
    },
    updateUnselectedServiceDescriptions: (state, action) => {
      state.unselectedServiceDescription = action.payload;
    },
    updateRemovedServiceDescriptions: (state, action) => {
      state.removedServiceDescription = action.payload;
    },
    updateBudgets: (state, action) => {
      Object.keys(action.payload).map((key) => {
        state[key] = parseInt(action.payload[key] || "0");
      });
    },
    updateHasCalledTAI: (state, action) => {
      state.hasCalledTAI = action.payload;
    },

    updateAffectedBrandKeywords: (state, action) => {
      state.affectedBrandKeywords = action.payload;
    },
    updateAffectedCompetitorKeywords: (state, action) => {
      state.affectedCompetitorKeywords = action.payload;
    },
    updateAffectedServiceKeywords: (state, action) => {
      state.affectedServiceKeywords = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  generateKeywordsReducer,
  getKeywordsReducer,
  updateCampaignsData,
  updateKeywordsStatusReducer,
  updateKeywordStatusReducer,
  updateSelectedCompetitorKeywords,
  updateSelectedBrandKeywords,
  updateUnselectedBrandKeywords,
  updateRemovedBrandKeywords,
  updateSelectedServiceKeywords,
  updateUnselectedCompetitorKeywords,
  updateRemovedCompetitorKeywords,
  updateUnselectedServiceKeywords,
  updateRemovedServiceKeywords,
  updateBudgets,
  updateSelectedHeadlines,
  updateSelectedDescriptions,
  updateSelectedBrandHeadlines,
  updateUnselectedBrandHeadlines,
  updateRemovedBrandHeadlines,
  updateSelectedBrandDescriptions,
  updateUnselectedBrandDescriptions,
  updateRemovedBrandDescriptions,
  updateSelectedCompetitorHeadlines,
  updateUnselectedCompetitorHeadlines,
  updateRemovedCompetitorHeadlines,
  updateSelectedCompetitorDescriptions,
  updateUnselectedCompetitorDescriptions,
  updateRemovedCompetitorDescriptions,
  updateSelectedServiceHeadlines,
  updateUnselectedServiceHeadlines,
  updateRemovedServiceHeadlines,
  updateSelectedServiceDescriptions,
  updateUnselectedServiceDescriptions,
  updateRemovedServiceDescriptions,
  editKeywordStatusReducer,
  updateHasCalledTAI,
  updateAffectedBrandKeywords,
  updateAffectedCompetitorKeywords,
  updateAffectedServiceKeywords,
} = targetSlice.actions;

export default targetSlice.reducer;
