import {ready as readyGif} from "../../assets/icons/proposal/proposal";
import {timeprocess as timeclock} from '../../assets/icons/icon'
import Box from "@mui/material/Box";
import PrimaryButton from "../ButtonPrimary/Buttons";

const style = {
  position: "absolute",
  // top: "50%",
  // left: "10%",
  // transform: "translate(-50%, -50%)",
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  // height: "450px",
  height: "110%",

  // borderRadius: "40px",
};
const GeneratingStrategyPage = ({ onClickHandler }) => {
  return (
    <Box style={style}>
      {/* <Close style={{ color: "black", position: "absolute", right: "24px", top: '24px', cursor: "pointer" }} onClick={() => setFormSubm(!formSubm)} /> */}
      <div
        id="working_proposal"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          paddingRight: "0px",
          width: "100%",
        }}
      >
        <div
          className="proposal_status"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "#ffffff",
            paddingRight: "0px",
            width: "100%",
            height: "100%",
            borderRadius: "8px",
          }}
        >
          <div className="proposal_status_layout">
            <span className="setIcon">
              <img src={timeclock} />
            </span>
            <h2 className="proposal_status_title">
              Generating Keywords and Ads
            </h2>
            <p
              style={{
                fontFamily: "Lato",
                fontSize: "16px",
                lineHeight: "25.5px",
                fontWeight: "400",
                color: "#6F6C90",
              }}
            >
              Please sit back while we work on generating content for your adsets and campaigns. This usually takes about 1 hour. 
            </p>
            <PrimaryButton
              title="Go To Brand Page"
              onClick={() => onClickHandler()}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};
export default GeneratingStrategyPage;
