import { useDispatch } from "react-redux";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";
import "../../AccountManager/Option.css";

const Option = (props) => {
  const dispatch = useDispatch();
  return (
    <div className="radio">
      <div className="st">
        <div>
          <BpRadio checked={props.checked} onClick={()=>{props.setAccount(props.account);props.setSelectedCustomerName(props.name)}}/>
        </div>
        <div className="mp">
          <span>{props.name}</span>
          <p>Account Id: {props.account}</p>
        </div>
      </div>
    </div>
  );
};
export default Option;
