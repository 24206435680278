import { styled } from "@mui/material/styles";
import { Button, TextField, Typography } from "@mui/material";
export const StyledAccountListText = styled(Typography)`
  color: #2e3044;
  text-align: center;
  font-family: Lato;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 8px;
  margin-top: 32px;
`;
export const StyledAccountParaText = styled(Typography)`
  color: #6d6e7c;
  text-align: center;
  font-family: Inter;
  font-size: 13.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  padding: 0 12px;
  margin: 0px 24px;
  margin-bottom: 28px;
  /* margin-left: 24px; */
`;
export const StyledChooseAccountText = styled(Typography)`
  color: #585969;
  font-family: Inter;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 8px;
`;
export const StyledFooterButton = styled("div")`
  box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.04);
  /* border: 1px solid red; */
  text-align: right;
  padding: 12px 0;
  margin-right: 24px;
`;
export const StyledAccountList = styled("div")`
  min-height: 372px;
  max-height: 372px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* margin: 0 28px; */
  margin: 0 24px;
  margin-top: 16px;
  scroll-margin: 10px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background: #eaeaec;
    margin-left: 6px;
  }
`;
export const StyledIconContainer = styled("div")(
  ({ icon }) => `
  width: 40px;
  height: 40px;
  position: absolute;
  top: ${icon === "google" ? "21px" : "6px"};
  left: ${icon === "google" ? "21px" : "14px"};
`
);
export const StyledFooterText = styled("div")`
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #0869fb;
`;
export const StyledPlusText = styled("span")`
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #0869fb;
  margin-right: 4px;
`;
export const StyledAlreadyConnectedAccountText = styled(Typography)`
  color: #0869fb;
  /* text-align: center; */
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 24px;
  margin-left: 18px;
  cursor: pointer;
`;
export const StyledTextField = styled(TextField)`
  /* padding: 12px 16px; */
  & .MuiOutlinedInput-input {
    padding: 12px 16px;
    color: #585969;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-radius: 6px;
    border: 1px solid #eaeaec !important;
    /* background: #fff; */
  }
`;
export const StyledAccountSearchFieldWrapper = styled("div")`
  margin: 0 24px;
`;
export const StyledButton = styled(Button)`
  border-radius: 6px;
  background: #0869fb;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  padding: 10px 24px;
  &:hover {
    background: #0869fb;
    color: #fff;
  }
  &.Mui-disabled {
    color: white;
    opacity: 0.5;
  }
`;
export const StyledTooltipText = styled("span")`
  color: #333;
  /* Typography/Body/4/Regular */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledTooltipLinkText = styled("span")`
  color: #0869fb;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
`;
