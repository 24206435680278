import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_CAMPAIGN_SELECTOR_STAGES } from "../../constants/LDConstants";
import GoogleCampaignSelector from "../GoogleCampaignSelector/GoogleCampaignSelector";
import {
  BACKEND_STATUS,
  CRON_STATUS_BACKEND,
  accountManagerIds,
  ldComponentWithIndexAm,
  componentWithIndexHDMAm,
  accountManagerIdsHDM,
} from "../../utils/accountManagerUtil";
import { goToSpecificCompoClientAm } from "../../api/accountManager/componentTracking/action";
import {
  startCronPMaxStrategy,
  updateStartLoader,
} from "../../api/pmaxStrategy/slice";
import { generateKeywordsReducer } from "../../api/accountManager/targetAI/slice";
import { goToSpecificCompoHDMAM } from "../../api/HDMAM/componentTracking/action";
import { updateNavAM } from "../../api/HDMAM/componentTracking/reducer";
import PmaxLoader from "../PMaxStrategyCreation/PmaxLoader";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import hdmAmRoutePaths from "../../pages/hdm-am/constants/hdmAmRoutePaths";
import { useGetClientForms, usePostClientForms } from "../../services/clientForms";
import { usePostGenerateResources } from "../../services/CAIGoogle/googlePmax";
import { usePostGenerateSegments } from "../../services/TAIGoogle/googlePmax";
import { updateWholeFormDataHdmAm } from "../../api/HDMAM/form/action";
import { useHDMGoToFacebookStrategy } from '../FacebookStrategyCreation/useGoToFacebookStrategy';
import { usePostKeywords } from "../../services/TAIGoogle/googleSearchAds";

const StrategyGoogleCampaignSelector = ({
    handleBack=()=>{}
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();

  const [clientForm, setClientForm] = useState({
    pmaxCAICronStatus: "NOTSTARTED",
    pmaxTAICronStatus: "NOTSTARTED",
    googleAdsCronStatus: "NOTSTARTED",
  });
  const [pmaxUnlockButton, setPmaxUnlockButton] = useState(false);
  const [pmaxGeneratingButton, setPmaxGeneratingButton] = useState(false);
  const [pmaxOngoingButton, setPmaxOngoingButton] = useState(false);
  const [pmaxCompletedButton, setPmaxCompletedButton] = useState(false);

  const startLoader = useSelector((store) => store.pmaxStrategy.startLoader);
  const [searchUnlockButton, setSearchUnlockButton] = useState(false);
  const [searchGeneratingButton, setSearchGeneratingButton] = useState(false);
  const [searchOngoingButton, setSearchOngoingButton] = useState(false);
  const [searchCompletedButton, setSearchCompletedButton] = useState(false);
  const { data: form } = useGetClientForms(formId, {
      refetchInterval: (data) => {
          if (
              [CRON_STATUS_BACKEND.RUNNING, CRON_STATUS_BACKEND.PENDING].includes(data?.pmaxCAICronStatus) ||
              [CRON_STATUS_BACKEND.RUNNING, CRON_STATUS_BACKEND.PENDING].includes(data?.pmaxTAICronStatus) ||
              [CRON_STATUS_BACKEND.RUNNING, CRON_STATUS_BACKEND.PENDING].includes(data?.googleAdsCronStatus)
          )
              return 30*1000
          else return false
      }
  });
  const { mutateAsync: mutatePostClientForms } = usePostClientForms();
  const { mutateAsync: mutatePostGenerateKeywords } = usePostKeywords();
  const { mutateAsync: mutatePostGenerateResources } = usePostGenerateResources(formId);
  const { mutateAsync: mutatePostGenerateSegments } = usePostGenerateSegments(formId);

  console.log('clientForm:', clientForm);
  console.log('Form:', form);
  useEffect(() => {
    if (form) {
      setClientForm(form);
      if (
        [BACKEND_STATUS.ONGOING].includes(form?.googlePmaxStrategy?.status) &&
        ( [CRON_STATUS_BACKEND.RUNNING, CRON_STATUS_BACKEND.PENDING].includes(form?.pmaxCAICronStatus) ||
          [CRON_STATUS_BACKEND.RUNNING, CRON_STATUS_BACKEND.PENDING].includes(form?.pmaxTAICronStatus) )
      ) {
        setPmaxUnlockButton(false);
        setPmaxGeneratingButton(true);
      } else if (
        [BACKEND_STATUS.ONGOING].includes(form?.googlePmaxStrategy?.status) &&
        form?.pmaxCAICronStatus === CRON_STATUS_BACKEND.DONE &&
        form?.pmaxTAICronStatus === CRON_STATUS_BACKEND.DONE
      ) {
        // dispatch(getPmaxStrategies());
        setPmaxGeneratingButton(false);
        setPmaxOngoingButton(true);
      } else if (
        [BACKEND_STATUS.COMPLETED].includes(form?.googlePmaxStrategy?.status)
      ) {
        setPmaxOngoingButton(false);
        setPmaxCompletedButton(true);
      } else {
        setPmaxGeneratingButton(false);
        setPmaxUnlockButton(true);
      }

      if (form?.googleAdsCronStatus === CRON_STATUS_BACKEND.FAILED) {
        setSearchGeneratingButton(false);
        setSearchUnlockButton(true);
      }
      else if ([CRON_STATUS_BACKEND.RUNNING, CRON_STATUS_BACKEND.PENDING].includes(form?.googleAdsCronStatus)) {
        setSearchUnlockButton(false);
        setSearchGeneratingButton(true);
      } else if (form?.googleAdsCronStatus === CRON_STATUS_BACKEND.DONE) {
        if (
          form?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED
        ) {
          setSearchOngoingButton(false);
          setSearchCompletedButton(true);
        } else {
          setSearchGeneratingButton(false);
          setSearchOngoingButton(true);
        }
      } else {
        setSearchUnlockButton(true);
      }
    }
  }, [JSON.stringify(form)]);

  const onClickHandlerForSearchStrategy = async () => {
    if (
      !clientForm.googleAdsCronStatus ||
      clientForm.googleAdsCronStatus === CRON_STATUS_BACKEND.FAILED
    ) {
      dispatch(updateStartLoader(true));
      await mutatePostGenerateKeywords();
      await mutatePostClientForms({ googleSearchAdsStrategyInfo: { status: BACKEND_STATUS.ONGOING } })
      dispatch(updateStartLoader(false));
    }
    if (clientForm.googleAdsCronStatus === CRON_STATUS_BACKEND.DONE) {
      navigate(
        generatePath(
          `${hdmAmRoutePaths.strategyGoogle.fullbase}/${hdmAmRoutePaths.strategyGoogle.sub.guidedFlow.googleSearch.strategiesList}`,
          { formId }
        )
      );
    }
  };
  const onClickHandlerForPMaxStrategySelect = async () => {
    try {
    if (
        (!clientForm?.pmaxCAICronStatus ||
        !clientForm?.pmaxTAICronStatus) ||
      [clientForm?.pmaxCAICronStatus, clientForm?.pmaxTAICronStatus].includes(
        CRON_STATUS_BACKEND.FAILED
      )
    ) {
      console.log('something');
      dispatch(updateStartLoader(true));
       if (
         !clientForm?.pmaxCAICronStatus ||
         clientForm?.pmaxCAICronStatus === CRON_STATUS_BACKEND.FAILED
       )
        await mutatePostGenerateResources(formId);
       if (
         !clientForm?.pmaxTAICronStatus ||
         clientForm?.pmaxTAICronStatus === CRON_STATUS_BACKEND.FAILED
       )
        await mutatePostGenerateSegments(formId);
        await mutatePostClientForms({ googlePmaxStrategy: { status: BACKEND_STATUS.ONGOING } })
        dispatch(updateStartLoader(false))
    }
    if (
      [BACKEND_STATUS.ONGOING, BACKEND_STATUS.COMPLETED].includes(
        clientForm?.googlePmaxStrategy?.status
      ) &&
      clientForm.pmaxCAICronStatus === CRON_STATUS_BACKEND.DONE &&
      clientForm.pmaxTAICronStatus === CRON_STATUS_BACKEND.DONE
    ) {
      navigate(
        generatePath(
          `${hdmAmRoutePaths.strategyGoogle.fullbase}/${hdmAmRoutePaths.strategyGoogle.sub.guidedFlow.googlePmax.strategiesList}`,
          { formId }
        )
      );
    }
    } catch(err) {
        dispatch(updateStartLoader(false))
        console.log(err);
    }
  };

  const disableConfirm = () => {
    if (
      form?.googlePmaxStrategy?.status !== BACKEND_STATUS.COMPLETED &&
      form?.googleSearchAdsStrategyInfo?.status !== BACKEND_STATUS.COMPLETED
    ) {
      return true;
    } else if (
      [CRON_STATUS_BACKEND.PENDING, CRON_STATUS_BACKEND.RUNNING].includes(
        form?.googleAdsCronStatus
      ) ||
      [CRON_STATUS_BACKEND.PENDING, CRON_STATUS_BACKEND.RUNNING].includes(
        form?.pmaxCAICronStatus
      ) ||
      [CRON_STATUS_BACKEND.PENDING, CRON_STATUS_BACKEND.RUNNING].includes(
        form?.pmaxTAICronStatus
      )
    ) {
      return true
    } else if(form?.googleAdsCronStatus === CRON_STATUS_BACKEND.DONE && form?.googleSearchAdsStrategyInfo?.status !== BACKEND_STATUS.COMPLETED){
      return true;
    } else if(form?.pmaxCAICronStatus === CRON_STATUS_BACKEND.DONE && form?.pmaxTAICronStatus === CRON_STATUS_BACKEND.DONE && form?.googlePmaxStrategy?.status !== BACKEND_STATUS.COMPLETED  ){
      return true;
    }

    return false;
  };

  const { goToFBStrategy } = useHDMGoToFacebookStrategy({ formId });
  const handleConfirm = async () => {
    try {
       if ((clientForm?.googleSearchAdsStrategyInfo?.status === BACKEND_STATUS.COMPLETED || 
           clientForm?.googlePmaxStrategy?.status === BACKEND_STATUS.COMPLETED) &&
           clientForm?.googleStrategyCreationStatus !== BACKEND_STATUS.COMPLETED
       ) {
          await mutatePostClientForms({ googleStrategyCreationStatus: BACKEND_STATUS.COMPLETED })
          dispatch(updateWholeFormDataHdmAm());
       }
       if (
         [BACKEND_STATUS.ONGOING, BACKEND_STATUS.COMPLETED].includes(
           form?.fbAdsStrategyInfo?.status
         )
       ) {
        goToFBStrategy(); 
       } else {
         navigate(
           generatePath(`${hdmAmRoutePaths.strategyDeck.fullbase}`, { formId })
         );
       }
    } catch(err) {
        console.log(err);
    }
  };

  return (
    <>
      <GoogleCampaignSelector
        stage={GOOGLE_CAMPAIGN_SELECTOR_STAGES.Strategy}
        pmaxOngoingButton={pmaxOngoingButton}
        pmaxGeneratingButton={pmaxGeneratingButton}
        pmaxCompletedButton={pmaxCompletedButton}
        pmaxClickHandler={onClickHandlerForPMaxStrategySelect}
        searchOngoingButton={searchOngoingButton}
        searchGeneratingButton={searchGeneratingButton}
        searchCompletedButton={searchCompletedButton}
        searchClickHandler={onClickHandlerForSearchStrategy}
        searchUnlockButton={searchUnlockButton}
        pmaxUnlockButton={pmaxUnlockButton}
        disableConfirm={disableConfirm}
        handleConfirm={handleConfirm}
        handleBack={handleBack}
        hideBackButton={clientForm?.googleStrategyCreationStatus === BACKEND_STATUS.COMPLETED ? true : false}
      />
      {startLoader && <PmaxLoader />}
    </>
  );
};

export default StrategyGoogleCampaignSelector;
