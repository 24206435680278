import { Button, Grid, Typography } from '@mui/material';
import ROAS from './strategy-Icons/blended.png';
import SCALING from './strategy-Icons/scaling.png';

import { useDispatch, useSelector } from 'react-redux';
import {
  goBackHDMAmCompo,
  goToSpecificCompoHDMAM,
  updateCompoReduxHDMAM,
} from '../../../api/HDMAM/componentTracking/action';
import './HDMAMOurPlan.css';
import TEST from './strategy-Icons/testing.png';
import { accountManagerIdsHDM, componentWithIndexHDMAm, isPlatformSelectedHDM } from '../../../utils/accountManagerUtil';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import hdmAmRoutePaths from '../../../pages/hdm-am/constants/hdmAmRoutePaths';
const OurPlanHDM = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {formId} = useParams();
  const form = useSelector(store => store.hdmFormAm);
  return (
    <div style={{ width: '100%' }}>
      <div className='proposal_layout proposal_flex_top '>
        <div className='preview_head'>
          <h4>PROPOSAL</h4>
          <h2>Horizons Digital Marketing </h2>
          {/* <img src="./test.svg" /> */}
        </div>
      </div>
      <div className='preview_footer_row'>
        <div className='back_button'>
          {' '}
          <Button
            style={{ color: '#0869FB', cursor: "pointer" }}
            onClick={() => {
              if(isPlatformSelectedHDM(form, 'facebook-ads') !== -1){
                navigate(generatePath(`${hdmAmRoutePaths.strategyDeck.fullbase}/${hdmAmRoutePaths.strategyDeck.sub.facebook}`, {formId}));
              } else {
                navigate(generatePath(`${hdmAmRoutePaths.strategyDeck.fullbase}/${hdmAmRoutePaths.strategyDeck.sub.google}`, {formId}));
              }
            }}
          >
            BACK{' '}
          </Button>
        </div>
        <div className='footer_content'>
          <Button
            variant='contained'
            onClick={() => {
              // dispatch(updateHDMCompoReduxClient());
              navigate(generatePath(`${hdmAmRoutePaths.strategyDeck.fullbase}/${hdmAmRoutePaths.strategyDeck.sub.bestPractices}`, {formId}));
            }}
          >
            PROCEED
          </Button>
        </div>
      </div>
      <div className='ourplan'></div>
      <div className='scroll_items'>
        {/* <div className='phase'>
        <Typography style={{fontFamily:'Lato',fontWeight:'700', color:'#000000',fontSize:'30px',lineHeight:'36px',marginLeft:'52px'}}>Onboarding Phases</Typography>
        <img style={{height:"491.34px", width:'100%'}} src="../../../assets/images/phase.png" alt="" />
        
    </div> */}

        <div className='Testing'>
          <div className='Testing_head'>
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato',
                fontSize: '25px',
                fontWeight: '700',
                lineHeight: '51.91px',
                margin: '0px',
              }}
            >
              <img
                style={{
                  position: 'relative',
                  top: '15px',
                  height: 100,
                  width: 100,
                }}
                src={TEST}
                alt=''
              />{' '}
              Testing
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    01
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Gathering Data. What works best for YOUR brand. Products,
                    audiences, ads.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    02
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Build The Foundation. Analyse, strategize and implement
                    based on what we’ve learned in month 1.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    03
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Growth. We’ve hit our strides and this is where we will
                    typically talk scaling.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    04
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Scaling
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    05
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    1,000s of tests we can do. We focus on the ones that will
                    move the needle the MOST
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className='Scaling'>
          <div className='Scaling_head'>
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato',
                fontSize: '25px',
                fontWeight: '700',
                lineHeight: '51.91px',
                margin: '0px',
              }}
            >
              <img
                style={{ position: 'relative', top: '15px' }}
                src={SCALING}
                alt=''
              />{' '}
              Scaling
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    01
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Scaling horizontally (different platforms)
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    02
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Scaling vertically (same platform)
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    03
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Make sure we don’t have a leaky bucket (optimizing the
                    current spend)
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className='Scale'>
          <div className='Scale_head'>
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato',
                fontSize: '25px',
                fontWeight: '700',
                lineHeight: '51.91px',
                margin: '0px',
              }}
            >
              <img
                style={{ position: 'relative', top: '15px' }}
                src={SCALING}
                alt=''
              />{' '}
              When to scale?
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    01
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Are we above your breakeven point?
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    02
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Successful brands are scaling when they are above breakeven.
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    03
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    1.5, 2, 3, 5X ROAS… These can all be successfuls
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    04
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    The overall REVENUE goal can be a moving target (Ie.
                    constantly increasing) BUT your ROAS needs to be consistent.
                    In fact, B/E ROAS should drop with scale.
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <div className='ROAS'>
          <div className='ROAS_head'>
            {/* <Typography style={{display:'flex', alignItems:'center',fontFamily:'Lato',fontSize:'35.5237px',fontWeight:'700',lineHeight:"46px"}}><img src={AGENDA} alt="" /> Onboarding Agenda</Typography> */}
            <h3
              style={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Lato',
                fontSize: '25px',
                fontWeight: '700',
                lineHeight: '51.91px',
                margin: '0px',
              }}
            >
              <img
                style={{ position: 'relative', top: '15px' }}
                src={ROAS}
                alt=''
              />{' '}
              Blended ROAS
            </h3>
            {/* <p
              style={{
                fontFamily: "Inter",
                fontWeight: "400",
                fontSize: "12.4333px",
                lineHeight: "20px",
                color: "#000000",
                margin: "0px",
                marginLeft: "1.5rem",
              }}
            >
              It is a long established fact that a reader will be distracted by
              the readable content of a page <br /> when looking at its layout.
              The point of using Lorem Ipsum is that it has a more-or-less.
            </p> */}
          </div>
          <div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    01
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Because of all of the iOS updates… Tracking on any
                    individual platform is not always accurate
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    02
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    We look at overall revenue & overall spend
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    03
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    Now, obviously you might be running other campaigns on other
                    platforms, email marketing, PR, influencers, organic etc…
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginLeft: 10, marginBottom: 40 }}>
              <Grid container xs={12}>
                <Grid item xs={1}>
                  <b
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '600',
                      color: '#333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    04
                  </b>
                </Grid>
                <Grid item xs={11}>
                  <Typography
                    style={{
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: '400',
                      color: ' #333333',
                      marginLeft: '32.95px',
                    }}
                  >
                    BUT… This is the most effective way to track success because
                    tracking on platforms is next to useless
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurPlanHDM;
