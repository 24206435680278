import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { AddCircleOutline, Clear, ExpandMore } from "@mui/icons-material";
import React from "react";
import FilterPopup from "../Filteration/filterpopup/FilterPopup";
import MetricList from "./MetricList";
import { Filter, Metric } from "../../models/chart";
import { useSelector } from "react-redux";
// import { ReactComponent as FilterIcon } from "../../Assets/Icons/openFilter.svg";
import { useDispatch } from "react-redux";
// import { updateFilterChartMap } from "../../api/dashboard/action";
import { DimensionsLength, MetricsLength } from "../Charts/ChartUtils";
import { defaultFBState } from "../../models/defaultData";

export default function EditChartType3({ handleAllChartsForceReload, editDrawerState, currentChartType, setSwitchChartDimensions, setSwitchChartMetrics, channel }) {

    const { chart, handleChartChange } = editDrawerState;
    const [chartDimensions, setChartDimensions] = React.useState((!DimensionsLength[currentChartType] || chart.dimensions.length < DimensionsLength[currentChartType]) ? chart.dimensions?.concat([null]) : chart.dimensions);
    const [chartMetrics, setChartMetrics] = React.useState((!MetricsLength[currentChartType] || chart.leftMetrics.length < MetricsLength[currentChartType]) ? chart.leftMetrics.concat([null]) : chart.leftMetrics);

    const [filterListOpen, setFilterListOpen] = React.useState(false);
    const [isFilterOpen, setIsFilterOpen] = React.useState(false);
    const [filterString, setFilterString] = React.useState(chart?.filter?.toString());
    const [chartFilter, setChartFilter] = React.useState(chart.filter)

    const [table, setTable] = React.useState(chart.table);
    const state = useSelector(state => channel.getState(state)) ?? defaultFBState;
    const tables = state.tables;
    const dimensions = Array.isArray(state.dimensions) ? state.dimensions : state.dimensions?.[table?.id] ?? [];
    const metrics = Array.isArray(state.metrics) ? state.metrics : state.metrics?.[table?.id] ?? [];

    const dispatch = useDispatch();
    // const _updateFilterChartMap = (_) => dispatch(updateFilterChartMap(_));
    // const filterChartMap = useSelector(state => state.dashboard.filterChartMap);

    const updateFilter = (filter) => {
        setFilterString(filter.toString());
        handleChartChange("filter", filter);
        setChartFilter(filter);
        if (!filter.id) return;
        // let allCharts = filterChartMap[filter.id] ?? []
        // if (!allCharts.includes(chart.id)) {
        //     // _updateFilterChartMap({ ...filterChartMap, [filter.id]: (filterChartMap[filter.id] ?? []).concat(chart.id) })
        // }
    }

    React.useEffect(() => {
        if (table !== chart.table) {
            handleChartChange("table", table);
            setChartMetrics([null]);
            setChartDimensions([null]);
        }
    }, [table]);

    React.useEffect(() => {
        handleChartChange("dimensions", chartDimensions.filter(dimension => dimension && dimension.id));
        setSwitchChartDimensions(chartDimensions);
    }, [chartDimensions]);

    React.useEffect(() => {
        if (MetricsLength[currentChartType] && chartMetrics.length < MetricsLength[currentChartType]) {
            setChartMetrics(chartMetrics.concat([null]));
        } else {
            handleChartChange("leftMetrics", chartMetrics.filter(metric => metric && metric.metric));
            setSwitchChartMetrics(chartMetrics);
        }
    }, [chartMetrics]);

    const mappedCoordinatesBubble = new Map([[0, "X axis"], [1, "Y axis"], [2, "Size"]]);

    return (
        <div>
            <Accordion defaultExpanded={chart.title === ""} sx={{ boxShadow: "none" }}>
                <AccordionSummary
                    sx={{ maxHeight: "30px" }}
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <h4 className="inter bold" >
                        Edit Title
                    </h4>
                </AccordionSummary>
                <AccordionDetails>
                    <TextField
                        label="Title"
                        placeholder="Enter Title"
                        style={{ width: "100%" }}
                        id="outlined-basic"
                        defaultValue={chart.title}
                        onChange={e => handleChartChange("title", e.target.value)}
                        variant="outlined"
                        size="small" />
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
                <AccordionSummary
                    sx={{ maxHeight: "30px" }}
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <h4 className="inter bold">
                        Edit Widget Data
                    </h4>
                </AccordionSummary>
                <AccordionDetails>
                    {/* report type */}
                    {tables && <div>
                        <h4 className="inter bold">
                            Report type
                        </h4>
                        <MetricList
                            channel={channel}
                            metric={table}
                            metrics={tables}
                            isEditable={true}
                            onClick={setTable}
                            defaultTitle="Add Report Type"
                        />
                        <br />
                    </div>}

                    {/* dimensions */}

                    <h4 className="inter bold">
                        Dimensions
                    </h4>
                    {chartDimensions.map((dimension, index) => (!DimensionsLength[currentChartType] || index < DimensionsLength[currentChartType]) &&
                        <MetricList
                            key={chartDimensions.length * (index + 1)}
                            channel={channel}
                            metric={dimension}
                            isEditable={true}
                            defaultTitle="Add New Dimension"
                            metrics={(currentChartType === "GEO" && index == 0) ? dimensions.filter(dim => dim.name.toLowerCase().includes("country")) : dimensions}
                            onClick={dimension => {
                                const newDimensions = Array.from(chartDimensions);
                                newDimensions[index] = dimension;
                                if (
                                    dimension && index === newDimensions.length - 1 &&
                                    (!DimensionsLength[currentChartType] || chartDimensions.length < DimensionsLength[currentChartType])
                                ) {
                                    newDimensions.push(null);
                                }
                                setChartDimensions(newDimensions);
                            }}
                            onDelete={chartDimensions.length <= 1 ? null : () => {
                                const newDimensions = Array.from(chartDimensions);
                                if (["TREEMAP", "SCATTER", "GEO"].includes(currentChartType) && chartDimensions.filter(d => d).length === DimensionsLength[currentChartType]) {
                                    newDimensions.push(null);
                                }
                                setChartDimensions(newDimensions.slice(0, index).concat(newDimensions.slice(index + 1)));
                            }}
                        />)}

                    {/* metrics */}
                    <h4 className="inter bold" style={{ marginTop: "15px" }}>
                        Metrics
                    </h4>
                    {chartMetrics.map((metric, index) => {
                        return <>
                            {currentChartType === "BUBBLE" && <h4 className="inter bold" style={{ marginTop: "15px" }}>{mappedCoordinatesBubble.get(index)}</h4>}
                            <MetricList
                                key={chartMetrics.length * (index + 1)}
                                channel={channel}
                                metric={metric?.metric}
                                isEditable={true}
                                metrics={metrics.filter(metric => (chartMetrics.findIndex(m => m?.metric?.name === metric.name) === -1))}
                                onClick={metric => {
                                    const newMetrics = Array.from(chartMetrics);
                                    newMetrics[index] = Metric.new(chart, metric);
                                    if (
                                        metric && index === newMetrics.length - 1 &&
                                        (!MetricsLength[currentChartType] || chartMetrics.length < MetricsLength[currentChartType])
                                    ) {
                                        newMetrics.push(null);
                                    }
                                    setChartMetrics(newMetrics);
                                }}
                                onDelete={chartMetrics.length <= 1 ? null : () => {
                                    const newMetrics = Array.from(chartMetrics);
                                    setChartMetrics(newMetrics.slice(0, index).concat(newMetrics.slice(index + 1)));
                                }}
                            />
                        </>
                    }
                    )}

                    {!channel.isBlend && <>
                        {/* filters */}
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "15px", width: "338px" }}>
                            <h4 className="inter bold" style={{ margin: "auto 0px" }}>
                                Filters
                            </h4>
                            <Typography
                                style={{ color: "#0869FB", margin: "auto 0px" }}
                                onClick={() => { setFilterListOpen(true) }}
                            >
                                {/* <FilterIcon /> See All */}
                            </Typography>
                        </div>

                        {filterString &&
                            <div
                                style={{
                                    marginTop: "15px",
                                    marginBottom: "5px",
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "400px"
                                }}>
                                <div className="filter-list" >
                                    {filterString}
                                </div>
                                <IconButton
                                    onClick={() => {
                                        updateFilter(Filter.new(chart))
                                    }}
                                    disableRipple
                                    disableFocusRipple
                                    aria-label="delete">
                                    <Clear />
                                </IconButton>
                            </div>
                        }
                        <Button
                            sx={{
                                justifyContent: "flex-start",
                                color: "#333333",
                                borderColor: "#DDDDDD",
                                borderRadius: "8px",
                                height: "52px",
                                width: "340px",
                                padding: "12px",
                                marginTop: "12px",
                            }}
                            fullWidth
                            variant="outlined"
                            size="small"
                            startIcon={<AddCircleOutline />}
                            onClick={() => setIsFilterOpen(true)}>
                            Add a filter
                        </Button>
                    </>}
                </AccordionDetails>
            </Accordion>

            {isFilterOpen &&
                <FilterPopup
                    chart={chart}
                    onClose={() => setIsFilterOpen(false)}
                    currentFilter={chartFilter}
                    onChange={filter => {
                        updateFilter(filter);
                        handleAllChartsForceReload(filter.id);
                    }}
                />}

        </div>
    );
}
