import { Button, Modal,Box,Typography } from '@mui/material';
import {timeprocess as timeclock} from '../../assets/icons/icon'

import * as React from 'react';
import PrimaryButton from '../ButtonPrimary/Buttons';
import { Close } from '@mui/icons-material';
const BudgetModal = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 584,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 5,
        paddingBottom: '3.8rem',
        height: '364px',
        borderRadius: '15px',
      };

    const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
    return(
        <div>
      
      <Modal
        // open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Close
          style={{
              height:'31px',
              width:'30px',
            color: "#333333",
            position: "absolute",
            right: "13.37px",
            top: "9.37px",
            cursor: "pointer",
          }}
           onClick={handleClose}
        />
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
            {/* <div style={{height:'108px',width:'108px',background:'#FFFFFF',border:'1px solid #E9E9E9',borderTopRightRadius:'2rem',borderBottomLeftRadius:'2rem',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <img src={timeclock} style={{height:'97.2px',width:'97.2px'}} alt="" />

            </div> */}
            <Typography style={{fontFamily:'Lato',fontSize:'24px',fontWeight:'700',lineHeight:'27px',textAlign:'center',marginBottom:'9px',marginTop:'24.5px'}}>The budget has been divided into Search and Performance Max Campaigns. We have allocated 60% budget to Search Campaigns and 40% budget to Performance Max Campaigns.</Typography>
            {/* <Typography style={{fontFamily:'Lato',fontSize:'16px',fontWeight:'400',lineHeight:'25.5px',textAlign:'center',color:'#6F6C90',marginBottom:'24.5px'}}>We have asked the client to provide access again for the accounts that are not yet shared.</Typography> */}

            <PrimaryButton title='Proceed'/>
            </div>
           
        </Box>
      </Modal>
    </div>
    )
}
export default BudgetModal;