export const PRICING_PLANS_LD = {
  STARTER: 'Starter',
  GROWTH: "Growth",
  PREMIER: 'Premier'
}

export const socialMediaDesignMonthsCount = {
  [PRICING_PLANS_LD.STARTER]: 6,
  [PRICING_PLANS_LD.GROWTH]: 9,
  [PRICING_PLANS_LD.PREMIER]: 12
}

export const selectedPlan = (amountRange) => {
  if (amountRange.minSpend >= 5000 && amountRange.maxSpend <= 25000) {
    return PRICING_PLANS_LD.STARTER;
  } else if (amountRange.minSpend >= 25000 && amountRange.maxSpend <= 75000) {
    return PRICING_PLANS_LD.GROWTH;
  } else if (amountRange.minSpend >= 75000 && amountRange.maxSpend <= 150000) {
    return PRICING_PLANS_LD.PREMIER;
  }
};

const getMaxQuantity = (quantityLiterals = '') => {
  if(quantityLiterals.includes('-')){
    return quantityLiterals.split('-')[1];
  } else {
    return quantityLiterals;
  }
}

const getTotalLandingPages = (baseServices = [], addOnServices = []) => {
  const baseObj = baseServices.find(serviceObj => serviceObj.service === "Landing Pages");
  const addOnObj = addOnServices.find(serviceObj => serviceObj.service === "Additional Landing Pages");
  let totalQuantity = 0;

  if(baseObj){
    totalQuantity += parseInt(getMaxQuantity(baseObj.quantity));
  }
  if(addOnObj){
    totalQuantity += parseInt(addOnObj.quantity)
  }
  return totalQuantity;
}

const getTotalPopUps = (baseServices = [], addOnServices = []) => {
  const baseObj = baseServices.find(serviceObj => serviceObj.service === "Pop-up");
  const addOnObj = addOnServices.find(serviceObj => serviceObj.service === "Additional Pop Up");
  let totalQuantity = 0;
  if(baseObj){
    totalQuantity += parseInt(getMaxQuantity(baseObj.quantity));
  }
  if(addOnObj){
    totalQuantity += parseInt(addOnObj.quantity)
  }
  return totalQuantity;
}

const getTotalStickyBars = (baseServices = [], addOnServices = []) => {
  const baseObj = baseServices.find(serviceObj => serviceObj.service === "Sticky Bar");
  const addOnObj = addOnServices.find(serviceObj => serviceObj.service === "Additional Sticky Bar");
  let totalQuantity = 0;
  if(baseObj){
    totalQuantity += parseInt(getMaxQuantity(baseObj.quantity));
  }
  if(addOnObj){
    totalQuantity += parseInt(addOnObj.quantity)
  }
  return totalQuantity;
}

// return monthWise CRODesignData e.g .- {'1' : 'L P S', '2' : 'L P S', '3' : 'L'}
const getCRODesign = (totalLandingPages, totalPopUps, totalStickyBar, planSelected) => {
  let totalServices = parseInt(totalLandingPages) + parseInt(totalPopUps) + parseInt(totalStickyBar);
  let currentLandingPages = parseInt(totalLandingPages);
  let currentPopUps = parseInt(totalPopUps);
  let currentStickyBars = parseInt(totalStickyBar);
  let monthCount = 1;
  const CRODesign = {};
  while(totalServices > 0){
    if(planSelected === PRICING_PLANS_LD.STARTER){
      let tempDesignData = "";
      if(currentLandingPages > 0){
        tempDesignData += 'L ';
        currentLandingPages -= 1;
        totalServices -= 1;
      }
      if(currentPopUps > 0){
        tempDesignData += 'P ';
        currentPopUps -= 1;
        totalServices -= 1;
      }
      if(currentStickyBars > 0){
        tempDesignData += 'S ';
        currentStickyBars -= 1;
        totalServices -= 1;
      }
      CRODesign[monthCount] = tempDesignData;
    }
    if(planSelected === PRICING_PLANS_LD.GROWTH){
      let tempDesignData = "";
      if(currentLandingPages > 0){
        if(currentLandingPages >= 2){
          tempDesignData += '2L ';
          currentLandingPages -= 2;
          totalServices -= 2;
        } else {
          tempDesignData += 'L ';
          currentLandingPages -= 1;
          totalServices -= 1;
        }
      }
      if(currentPopUps > 0){
        if(currentPopUps >= 2){
          tempDesignData += '2P ';
          currentPopUps -= 2;
          totalServices -= 2;
        } else {
          tempDesignData += 'P ';
          currentPopUps -= 1;
          totalServices -= 1;
        }
      }
      if(currentStickyBars > 0){
        if(currentStickyBars >= 2){
          tempDesignData += '2S ';
          currentStickyBars -= 2;
          totalServices -= 2;
        } else {
          tempDesignData += 'S ';
          currentStickyBars -= 1;
          totalServices -= 1;
        }
      }
      CRODesign[monthCount] = tempDesignData;
    }
    if(planSelected === PRICING_PLANS_LD.PREMIER){
      let tempDesignData = "";
      if(currentLandingPages > 0){
        if(currentLandingPages >= 3){
          tempDesignData += '3L ';
          currentLandingPages -= 3;
          totalServices -= 3;
        } else if(currentLandingPages >= 2){
          tempDesignData += '2L ';
          currentLandingPages -= 2;
          totalServices -= 2;
        } else {
          tempDesignData += 'L ';
          currentLandingPages -= 1;
          totalServices -= 1;
        }
      }
      if(currentPopUps > 0){
        if(currentPopUps >= 3){
          tempDesignData += '3P ';
          currentPopUps -= 3;
          totalServices -= 3;
        } else if(currentPopUps >= 2){
          tempDesignData += '2P ';
          currentPopUps -= 2;
          totalServices -= 2;
        } else {
          tempDesignData += 'P ';
          currentPopUps -= 1;
          totalServices -= 1;
        }
      }
      if(currentStickyBars > 0){
        if(currentStickyBars >= 3){
          tempDesignData += '3S ';
          currentStickyBars -= 3;
          totalServices -= 3;
        } else if(currentStickyBars >= 2){
          tempDesignData += '2S ';
          currentStickyBars -= 2;
          totalServices -= 2;
        } else {
          tempDesignData += 'S ';
          currentStickyBars -= 1;
          totalServices -= 1;
        }
      }
      CRODesign[monthCount] = tempDesignData;
    }
    monthCount += 1
  }

  return CRODesign;
}

export const getTimeframeData = (pricing = {}) => {
  let timeFrameData = {};
  let plan = null;
  if(pricing?.adSpendRange){
    plan = selectedPlan(pricing?.adSpendRange);
  } else {
    return timeFrameData;
  }
  const totalLandingPages = getTotalLandingPages(pricing?.baseServices, pricing?.addonServices);
  const totalPopUps = getTotalPopUps(pricing?.baseServices, pricing?.addonServices);
  const totalStickyBar = getTotalStickyBars(pricing?.baseServices, pricing?.addonServices);

  switch(plan){
    case PRICING_PLANS_LD.STARTER:
      timeFrameData = {name : PRICING_PLANS_LD.STARTER, ...(getCRODesign(totalLandingPages, totalPopUps, totalStickyBar, plan) || {})};
      break;
    case PRICING_PLANS_LD.GROWTH:
      timeFrameData = {name : PRICING_PLANS_LD.GROWTH, ...(getCRODesign(totalLandingPages, totalPopUps, totalStickyBar, plan) || {})};
      break;
    case PRICING_PLANS_LD.PREMIER:
      timeFrameData = {name : PRICING_PLANS_LD.PREMIER, ...(getCRODesign(totalLandingPages, totalPopUps, totalStickyBar, plan) || {})};
  }

  console.log("###### in time frmae", timeFrameData, totalLandingPages, totalPopUps, totalStickyBar, plan);
  return timeFrameData;
}