export const PLATFORM = {
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
};
export const PLATFORM_ADS = {
  GOOGLE_ADS: "g-ads",
  FACEBOOK_ADS: "facebook-ads",
};
export const PERMISSIONS = {
  READ: "READ",
  WRITE: "WRITE",
};
export const AGENCY_TYPES = {
  BP: "bp",
  LD: "ld",
  HDM: "hdm",
};
//ENUM for pricing card
export const STARTER_PRICING_CARD_RANGE = {
  minPrice: 5000.0,
  maxPrice: 25000.0,
};
export const GROWTH_PRICING_CARD_RANGE = {
  minPrice: 25000.0,
  maxPrice: 75000.0,
};
export const PREMIER_PRICING_CARD_RANGE = {
  minPrice: 75000.0,
  maxPrice: 150000.0,
};
