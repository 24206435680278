import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  campaign1: null,
  campaign2: null,
  campaign3: null,
  campaign1AssetGroups: [],
  campaign2AssetGroups: [],
  campaign3AssetGroups: [],
  currentAssetGroup: 0,
  assetCount: 0,
  isPmaxCampaignFetched: false,
  interestAndDemographies: [],
};

const pmaxCampaigns = createSlice({
  name: "pmaxCampaigns",
  initialState,
  reducers: {
    getPmaxCampaigns() {},
    createPmaxCampaign() {},
    updatePmaxCampaign() {},
    deletePmaxCamapaignAsset() {},
    publishSpecificPMaxCampaign() {},
    removeAssetGroup: (state, payload) => {
      // 
    },
    updateData: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

export const {
  getPmaxCampaigns,
  createPmaxCampaign,
  updatePmaxCampaign,
  deletePmaxCamapaignAsset,
  removeAssetGroup,
  updateData,
  publishSpecificPMaxCampaign
} = pmaxCampaigns.actions;

export default pmaxCampaigns.reducer;
