import { Button, Card, CardContent, CardHeader, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import Carousel from "react-grid-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch } from "react-redux";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import {
  PROPOSAL_STATE
} from "../../../utils/accountManagerUtil";
import StrategyDeckSureModal from "../../AccountManager/StrategyDeck/StrategyDeckSureModal";
import { useTabState } from "../../CustomTab/customTab";
import { TARGETING_TYPE } from "../../FacebookStrategyCreation/utils";
import ProposalPublishedModal from "../../modals/ProposalPublishedModal";
import "./HDMAMStrategyDeckGoogleHDM.css";
import USER from "./strategy-Icons/Vector.png";
import FACEBOOK from "./strategy-Icons/facebook.png";
import USERGROUP from "./strategy-Icons/userGroup.png";

const cn = (...args) => args.filter(Boolean).join(" ");

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button className={cn("tab", isActive && "active")} onClick={onClick}>
      {children}
    </button>
  );
};

const StrategyDeckFacebookHDM = ({
  facebookStrategies = [],
  buttonTitle,
  onBackClickHandler,
  onClickHandler,
  disableButton,
  proceedToPublish,
  setProceedToPublish,
  form,
}) => {
  const dispatch = useDispatch();

  const [totalBudget, setTotalBudget] = useState(0);
  const [sureModal, setSureModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  // console.log(tofInterest.adsets);

  useEffect(() => {
    if (proceedToPublish) {
      setSureModal(true);
    }
  }, [proceedToPublish]);

  useEffect(() => {
    let tempGoogleBudget = 0;
    facebookStrategies.forEach((strategy) => {
      tempGoogleBudget += parseInt(strategy?.budget?.value);
    });
    setTotalBudget(tempGoogleBudget);
  }, [facebookStrategies]);
  const truncateString = (str) => {
    return str?.length > 30 ? str?.substring(0, 29) + "..." : str;
  };
  const truncateString2 = (str) => {
    return str?.length > 80 ? str?.substring(0, 80) + "..." : str;
  };
  const publishDeck = () => {
    const strategyIterations = [...(form?.strategyIterations || [])];
    if (!strategyIterations.length) {
      strategyIterations.push({
        state: PROPOSAL_STATE.GENERATED,
      });
    } else {
      strategyIterations.push({
        state: PROPOSAL_STATE.CHANGES_APPLIED,
      });
    }
    dispatch(
      updateFormReducer({
        strategyIterations: strategyIterations,
      })
    );
    setSureModal(false);
    setProceedToPublish?.(false);
    setPublishModal(true);
  };

  const onClosingSureModal = (value) => {
    setSureModal(value);
    setProceedToPublish?.(false);
  }
  return (
    <div className="intro_strategy_main">
      {/* <div className="intro_strategy_layout">
        <div className="intro_strategy_warning">
          <span className="icon orange_check">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.75" width="20" height="20" rx="10" fill="#F99F35" />
              <path
                d="M6 10.7832L8.86226 13.65L14.7592 7.75"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <p>
            You are viewing the Strategy copy generated on 13 Dec 2022 - 09:00.
            Your suggested changes have been registered and you will be notified
            soon
          </p>
        </div>
      </div> */}
      <div className="google_strategy_layout">
        <div className="facebook_strategy_banner">
          <div className="google_banner_part">
            <div className="google_strategy_row">
              <div className="google_strategy_logo">
                <h6
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Lato",
                    fontSize: "25.5237px",
                    fontWeight: "700",
                    lineHeight: "46px",
                    margin: "0px",
                  }}
                >
                  <img
                    style={{
                      position: "relative",
                      top: "15px",
                      height: 100,
                      width: 100,
                    }}
                    src={FACEBOOK}
                    alt=""
                  />{" "}
                  Facebook
                </h6>
              </div>
              <div className="google_strategy_budget">
                <p>Total Daily Budget : ${totalBudget}</p>
              </div>
            </div>
            <p
              className="google_strategy_info"
              style={{
                marginLeft: 10,
                fontWeight: 400,
                fontSize: "13.9749px",

                color: "#000000",
              }}
            >
              Please review the strategy for running your campaigns on facebook
              for most efficient delivery and performance.
            </p>
          </div>
        </div>
      </div>
      {facebookStrategies.map((strategy, index) => (
        <div className="strategy_row_layout">
          <div className="strategy_google_row_main">
            <div className="google_strategy_header" style={{ display: "flex" }}>
              <div>
                <div className="google_strategy_row">
                  <div className="google_strategy_title">
                    <p>{strategy.name}</p>
                  </div>
                  <div className="google_title_budget">
                    <p>Daily Budget : ${strategy?.budget?.value}</p>
                  </div>
                </div>
              </div>
            </div>

            {(strategy.adsets || []).map((adset, index) => {
              return (
                <Accordion className="accordion_strategy_body" key={index}>
                  <AccordionSummary className="accordion_strategy_head">
                    <div className="strategy_accordion_title">
                      <h4>{adset.adsetName}</h4>
                      <span></span>
                    </div>
                  </AccordionSummary>
                  {[TARGETING_TYPE.AUDIENCE, TARGETING_TYPE.LOOKALIKE].includes(
                    adset.targetingType
                  ) ? (
                    <AccordionDetails className="accordion_strategy_content">
                      <p
                        style={{
                          fontWeight: 400,
                          fontSize: " 15.7359px",
                          color: " #333333",
                          display: "inline",
                          marginBottom: 10,
                        }}
                      >
                        Audience : {adset?.audience?.name}
                        {adset.targetingType === TARGETING_TYPE.LOOKALIKE ? (
                          <p style={{ marginLeft: 10, display: "inline" }}>
                            {" "}
                            Range : {adset.minRange}% - {adset.maxRange}%
                          </p>
                        ) : (
                          ""
                        )}
                      </p>
                      <div
                        className="strategy_accordion_layout"
                        style={{ marginTop: 20 }}
                      >
                        <div className="strategy_row_item">
                          <Carousel cols={3} rows={1} gap={0} loop>
                            {(adset.facebookAds || []).map((adItem, idx) => {
                              return (
                                <Carousel.Item key={idx - idx}>
                                  <div
                                    className="strategy_column_item"
                                    style={{ margin: "0px 10px" }}
                                  >
                                    <Card sx={{ maxWidth: 345 }}>
                                      <CardHeader
                                        style={{
                                          background: " #EEF4FF",
                                          border: " 0.868595px solid #F0F0F0",
                                          borderRadius:
                                            "6.94876px 6.94876px 0px 0px;",
                                          textAlign: "center",
                                          height: "40px",
                                        }}
                                        subheader={adItem.adname}
                                      />
                                      <CardContent style={{ padding: 0 }}>
                                        <div>
                                          <Tooltip
                                            title={adItem.primaryText}
                                            arrow
                                          >
                                            <div
                                              style={{
                                                fontSize: 10,
                                                color: "#000000",
                                                fontWidth: "500",
                                                padding: 8,
                                              }}
                                            >
                                              {truncateString2(
                                                adItem.primaryText
                                              )}
                                            </div>
                                          </Tooltip>

                                          <div
                                            style={{
                                              border: "1px solid #F0F0F0",
                                              height: 1,

                                              display: "block",
                                            }}
                                          ></div>
                                          <div
                                            style={{
                                              display: "block",
                                              justifyContent: "center",
                                              width: "100%",
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: 311,
                                                height: 302,
                                              }}
                                            >
                                              <img
                                                src={
                                                  process.env
                                                    .REACT_APP_BACKEND_BASE_URL +
                                                  "/files/" +
                                                  adItem.imageUrl
                                                }
                                                style={{
                                                  width: "100%",
                                                  height: "100%",
                                                  objectFit: "cover",
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              background: "#EEF4FF",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              padding: 8,
                                              paddingTop: 16,
                                            }}
                                          >
                                            <div style={{ display: "block" }}>
                                              <div
                                                style={{
                                                  color: "#5E5E5E",
                                                  fontSize: 10,
                                                }}
                                              >
                                                something.com
                                              </div>
                                              <div style={{ fontSize: 12 }}>
                                                <Tooltip
                                                  title={adItem.headline}
                                                >
                                                  <b>
                                                    {truncateString(
                                                      adItem.headline
                                                    )}
                                                  </b>
                                                </Tooltip>
                                              </div>
                                              <div
                                                style={{
                                                  color: "#5E5E5E",
                                                  fontSize: 10,
                                                }}
                                              >
                                                {adItem.description}
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                fontFamily: "Roboto",
                                                padding: 9,
                                                color: "#000000",
                                                fontSize: 12,
                                                background: "#E4E6EA",
                                                borderRadius: "2px",
                                                fontWeight: "700",
                                                height: 32,
                                              }}
                                            >
                                              Book Now
                                            </div>
                                          </div>
                                        </div>
                                      </CardContent>
                                    </Card>
                                    <div className="headline_column_actions">
                                      {/* <button className="actions_headline_strategy accept_icon"></button> */}
                                      <button
                                        className="actions_headline_strategy reject_icon"
                                        disabled
                                      ></button>
                                    </div>
                                  </div>{" "}
                                </Carousel.Item>
                              );
                            })}
                          </Carousel>
                        </div>
                      </div>
                    </AccordionDetails>
                  ) : (
                    <AccordionDetails className="accordion_strategy_content">
                      <div className="strategy_accordion_layout">
                        <div
                          className="strategy_row_item"
                          style={{
                            width: "100%",
                          }}
                        >
                          <div className="strategy_column_item search_row_strategy">
                            <Autocomplete
                              open
                              multiple
                              disableCloseOnSelect
                              noOptionsText="No labels"
                              renderOption={(props, option, { selected }) => (
                                <li {...props} className="autosearch_list">
                                  <div className="strategy_list_row">
                                    <div className="strategy_list_column">
                                      <span>
                                        <img src={USER} />
                                      </span>
                                      <p>{option.name}</p>
                                    </div>
                                    <div>
                                      <div className="headline_column_actions">
                                        {/* <button
                                    className={`
                             
                                 
                                list_search_actions accept_icon`}
                                  ></button> */}
                                        <button
                                          className={`
                             
                                 list_search_actions reject_icon`}
                                          disabled
                                        ></button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                              options={[...(adset.keywords || [])]}
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <div className="search_strategy_header">
                                  <span>
                                    {" "}
                                    <img src={USERGROUP} />
                                  </span>
                                  <TextField
                                    {...params}
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder="Search..."
                                    variant="standard"
                                  />
                                </div>
                              )}
                              disablePortal={true}
                              className="auto_complete_search"
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: -22,
                            }}
                          >
                            <div
                              style={{
                                width: 700,
                              }}
                            >
                              <Carousel cols={2} rows={1} gap={18} loop>
                                {(adset.facebookAds || []).map(
                                  (adItem, idx) => {
                                    return (
                                      <Carousel.Item key={idx - idx}>
                                        <div
                                          className="strategy_column_item"
                                          // style={{ margin: '0px 10px' }}
                                        >
                                          <Card sx={{ maxWidth: 345 }}>
                                            <CardHeader
                                              style={{
                                                background: " #EEF4FF",
                                                border:
                                                  " 0.868595px solid #F0F0F0",
                                                borderRadius:
                                                  "6.94876px 6.94876px 0px 0px;",
                                                textAlign: "center",
                                                height: "40px",
                                              }}
                                              subheader={adItem.adname}
                                            />
                                            <CardContent style={{ padding: 0 }}>
                                              <div>
                                                <Tooltip
                                                  title={adItem.primaryText}
                                                  arrow
                                                >
                                                  <div
                                                    style={{
                                                      fontSize: 10,
                                                      color: "#000000",
                                                      fontWidth: "500",
                                                      padding: 8,
                                                    }}
                                                  >
                                                    {truncateString2(
                                                      adItem.primaryText
                                                    )}
                                                  </div>
                                                </Tooltip>

                                                <div
                                                  style={{
                                                    border: "1px solid #F0F0F0",
                                                    height: 1,

                                                    display: "block",
                                                  }}
                                                ></div>
                                                <>
                                                  <div
                                                    style={{
                                                      width: 311,
                                                      height: 302,
                                                    }}
                                                  >
                                                    <img
                                                      src={
                                                        process.env
                                                          .REACT_APP_BACKEND_BASE_URL +
                                                        "/files/" +
                                                        adItem.imageUrl
                                                      }
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                      }}
                                                    />
                                                  </div>
                                                </>
                                                <div
                                                  style={{
                                                    background: "#EEF4FF",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    alignItems: "center",
                                                    padding: 8,
                                                    paddingTop: 8,
                                                  }}
                                                >
                                                  <div
                                                    style={{ display: "block" }}
                                                  >
                                                    <div
                                                      style={{
                                                        color: "#5E5E5E",
                                                        fontSize: 10,
                                                      }}
                                                    >
                                                      something.com
                                                    </div>
                                                    <div
                                                      style={{ fontSize: 12 }}
                                                    >
                                                      <Tooltip
                                                        title={adItem.headline}
                                                        arrow
                                                      >
                                                        <b>
                                                          {truncateString(
                                                            adItem.headline
                                                          )}
                                                        </b>
                                                      </Tooltip>
                                                    </div>
                                                    <div
                                                      style={{
                                                        color: "#5E5E5E",
                                                        fontSize: 10,
                                                      }}
                                                    >
                                                      {adItem.description}
                                                    </div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontFamily: "Roboto",
                                                      padding: 9,
                                                      color: "#000000",
                                                      fontSize: 12,
                                                      background: "#E4E6EA",
                                                      borderRadius: "2px",
                                                      fontWeight: "700",
                                                      height: 32,
                                                    }}
                                                  >
                                                    Book Now
                                                  </div>
                                                </div>
                                              </div>
                                            </CardContent>
                                          </Card>
                                          <div className="headline_column_actions">
                                            {/* <button className="actions_headline_strategy accept_icon"></button> */}
                                            <button
                                              className="actions_headline_strategy reject_icon"
                                              disabled
                                            ></button>
                                          </div>
                                        </div>{" "}
                                      </Carousel.Item>
                                    );
                                  }
                                )}
                              </Carousel>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  )}
                </Accordion>
              );
            })}
          </div>
        </div>
      ))}

      <div className="preview_footer_row">
        <div className="back_button">
          {" "}
          <Button
            style={{ color: "#0869FB", cursor: "pointer" }}
            onClick={onBackClickHandler}
          >
            BACK{" "}
          </Button>
        </div>
        <div className="footer_content">
          <Button
            variant="contained"
            disabled={disableButton}
            onClick={onClickHandler}
          >
            {buttonTitle}
          </Button>
        </div>
      </div>
      {sureModal && (
        <StrategyDeckSureModal
          modal={sureModal}
          setModal={onClosingSureModal}
          publishDeck={publishDeck}
        />
      )}
      {publishModal && (
        <ProposalPublishedModal
          formSubm={publishModal}
          setFormSubm={setPublishModal}
          title="Strategy Deck Published!"
          description="You will be notified as soon as the client has reviewed / approved the strategy deck."
          buttonTitle="Home"
        />
      )}
    </div>
  );
};
export default StrategyDeckFacebookHDM;
