import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider, ListItemButton, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useDispatch } from 'react-redux';
import { logout } from '../../api/auth/actions';
import BpRadio from '../ClientDashboard/CustomRadio/Radio';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {connectAccount} from "../../api/channels/action";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 450,
  bgcolor: 'background.paper',
  boxShadow: 24,
  minHeight: '200px',
  padding: '10px 10px',
  background: '#FFFFFF',
  borderRadius: 2,
  padding: '1.25rem',
};

const CreateNewProposal = ({ modal, setModal,adsChannel }) => {
  const dispatch = useDispatch();
  
  return (
    <Modal
      open={modal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography
            style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 16,
              color: '#000000',
              marginLeft: '0.5rem',
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              cursor:"pointer"
            }}
          >
            <ArrowBackIcon/> Connect Accounts
          </Typography>
          <IconButton>
            <Close
              onClick={() => {
                setModal(!modal);
              }}
            />
          </IconButton>
        </div>
        <Divider
          style={{
            border: '0.6px solid #F0F0F0',
            width: '100%',
          }}
        />
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            color: 'rgba(0, 0, 0, 0.5)',
            marginLeft: '0.5rem',
            marginTop: '1.5rem',
            marginBottom: '0.75rem',
          }}
        >
          Or , Make Changes to Previous Proposals:
        </Typography>
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <FormGroup>
              {adsChannel?.map((ads, index) => {
                return (
                  <>
                    <ListItemButton
                      style={{
                        width: '100%',
                        padding: '10px 15px',
                        position: 'relative',
                      }}
                    >
                      <Typography
                        component='h5'
                        variant='h5'
                        style={{
                          fontSize: '12px',
                          color: '#000000',
                          textAlign: 'left',
                        }}
                        mt={1}
                        mb={1}
                      >
                           {ads.name} <br />
                        <Typography
                          component='span'
                          variant='span'
                          style={{
                            fontSize: '12px',
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                          mt={2}
                          mb={2}
                        >
                          {`Bonbology > https://bonbology.com/`}
                        </Typography>
                      </Typography>
                      <Button
                        disabled={ads.hasArrived ? false : true}
                        style={{
                          backgroundColor:!ads.hasArrived ? "#eee" : "#E6F6FF",
                          color:"#00A0FB",
                          textTransform: 'none',
                          position: 'absolute',
                          right: 28,
                          fontSize: 12,
                          borderRadius: 8,
                          width: 80,
                        }}
                        onClick={()=>dispatch(connectAccount(ads.type))}
                      >
                         Connect
                      </Button>
                    </ListItemButton>
                    <Divider />
                  </>
                );
              })}
          </FormGroup>
        </div>
        <Divider
          style={{
            border: '0.6px solid #F0F0F0',
            width: '100%',
          }}
        />
      </Box>
    </Modal>
  );
};
export default CreateNewProposal;
