import { useEffect, useState } from "react";
import Tabs from "react-simply-tabs";
import { up_arrow_button_icon as upArrowButtonIcon } from "../../../assets/icons/icon";
import { link_arrow as linkArrow } from "../../../assets/icons/icon";
import { google_icon as googleIcon } from "../../../assets/icons/icon";
import { delete_icon as deleteIcon } from "../../../assets/icons/icon";
import { facebook as facebookIcon } from "../../../assets/icons/icon";
import { TextField } from "@mui/material";
import PrimaryButton from "../ButtonPrimary/Buttons";
import Button from "@mui/material/Button";
import "../../Common/sharedAssets.css";
import {
  sharedAssetsPostApi,
  sharedAssetsPutApi,
} from "../../../api/sharedAssets/saga";
import { useSelector, useDispatch } from "react-redux";
import { getSharedAssetsGoogel } from "../../../api/sharedAssets/action";
import moment from "moment";
import Box from "@mui/material/Box";
import { ready as readyGif } from "../../../assets/icons/proposal/proposal";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";
import Radio from "@mui/material/Radio";
import { dbStatus, mappedStatusDb } from "../HomePage/HomePage";
import HDMCompleteAccess from "../../HDMClientDashboard/HDMReadAccess/HDMCompleteAccess";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 584,
  bgcolor: "background.paper",
  borderRadius: "20px",
  padding: "46px 47px 42px",
  boxShadow: 24,
};
const statusMapping = {
  VERIFYING_COMPLETE_ACCESS: "Verifying",
  COMPLETED_ACCESS_NOT_SHARED: "Read Access",
};
const SharedAssets = () => {
  const [handleSuccess, setHandleSuccess] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(1);
  const [activeTabIndexFB, setActiveTabIndexFB] = useState(0);
  const [accountIdGoogle, setAccountIdGoogle] = useState("");
  const sharedAssetsAccountsGoogle = useSelector(
    (state) => state.sharedAssets.googleSharedAssetsAccounts
  );
  const platformSelected = useSelector(
    (state) => state?.client?.onboardingInfo?.platformInfo?.platforms
  );
  const accountid = useSelector((state) => state?.client?.accessSharing);
  const dispatch = useDispatch();
  const sharedAssets = () => {
    sharedAssetsPostApi(accountIdGoogle, "VERIFYING_COMPLETE_ACCESS").then(
      (res) => {
        setActiveTabIndex(1);
        setAccountIdGoogle("");
      }
    );
  };
  const submitCompleteAccess = () => {
    setHandleSuccess(!handleSuccess);
  };
  useEffect(() => {
    dispatch(getSharedAssetsGoogel());
  }, [activeTabIndex]);
  const desiredDate = (date) => moment(date).format("YYYY-MM-DD");
  return (
    <>
      <HDMCompleteAccess agencyType="LD" />
    </>
  );
};
export default SharedAssets;
