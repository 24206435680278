import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Modal,
  Button,
  Box,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import Radio from '@mui/material/Radio';
import './targeting-look-alike.css';
import BpRadio from '../../ClientDashboard/CustomRadio/Radio';
import LookalikeLocationField from './LookalikeLocationField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Close } from "@material-ui/icons";
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import AddEditKeywordModal from '../../FacebookStrategyCreation/AdsetLevel/AdsetList/AddEditKeywordModal';
import { BACKEND_STATUS } from '../../../utils/accountManagerUtil';

const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 475,
  bgcolor: "background.paper",
  borderRadius: "20px",
  padding: "46px 47px 42px",
  boxShadow: 24,
};

const filterDropdown = (group, type, audiences) => {
  const arrTemp = [];
  audiences &&
    audiences.forEach((value, index) => {
      if (value.subtype === type) {
        arrTemp.push({
          group: group,
          name: value.name,
          reach: value.maxReach,
          id: value.id,
        });
      }
    });
  return arrTemp;
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const LookAlikeTargetting = ({
    audience=[],
    advertisingLocations=[],
    minAge=null,
    maxAge=null,
    minRange=null,
    maxRange=null,
    gender='',
    keywords=[],
    index,
    targetingType='',
    updateData=()=>{},
    replace=()=>{},
    remove=()=>{},
    isCampaignSuccess=false,
    setStatus=()=>{}
}) => {
  const [modal, showModal] = useState(false);
  const [selectedKeyWord, setSelectedKeyWord] = useState({});
  const audiences = useSelector(
    (state) => state.strategyCreationHdmAm.audiences
  );

  const audienceDropdown = {
    WEBSITE: filterDropdown('WEBSITE', 'WEBSITE', audiences),
    // CUSTOM: filterDropdown('CUSTOM', 'CUSTOM', audiences),
    // 'FB EVENT': filterDropdown('FB EVENT', 'FB_EVENT', audiences),
  };

  console.log('minRange:', minRange, 'maxRange:', maxRange);
  const clearKeyword = (keyword) => {
    updateData((keywords || []).filter(keywd => keywd !== keyword), 'keywords')
  };

  const DropDownRender = (isCampaignSuccess) => {
    var ListArray = [];
    for (const key in audienceDropdown) {
      if (Object.hasOwnProperty.call(audienceDropdown, key)) {
        const element = audienceDropdown[key];
        ListArray.push(
          <ListSubheader key={key} className='drop_custom_list_head'>
            {key}
          </ListSubheader>
        );
        {
          element.forEach((ele, index) => {
            ListArray.push(
              <MenuItem
                key={index}
                value={ele.name}
                disabled={isCampaignSuccess}
                className='drop_custom_list_row'
              >
                <div className='drop_custom_list'>
                  <div>
                    <Radio checked={audience.name === ele.name} />
                    <p style={{ overflow: 'revert' }}>{ele.name}</p>
                  </div>
                  <span>{ele.reach}</span>
                </div>
              </MenuItem>
            );
          });
        }
      }
    }
    return ListArray;
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const MyChip = (props) => {};

  const handleChange = (e, field) => {
    if (field === 'audience') {
      updateData(
        (audiences || []).find((aud) => aud.name === e.target.value),
        field
      );
    } else {
      // field = minAge || maxAge || gender || minRange || maxRange
      updateData(e.target.value, field);
    }
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 300);
    };
  };

  const optimizedFn = useCallback(debounce(handleChange), []);
  return (
    <div className='targeting-container'>
      <div className='header-targeting-container'>
        <Typography className='targeting-header__title'>Targeting</Typography>
      </div>
      <div className='targeting-form'>
        <div className='targeting-form-element'>
          <Typography className='targeting-form-element__title'>
            Location
          </Typography>

         {isCampaignSuccess ?
             <div style={{ display: "flex", flexWrap: "wrap" }}>
               {advertisingLocations?.map((ad, index) => 
                   <div className="custom_chips" key={index} >
                      <span>{ad?.name}</span>
                   </div>
                )
               }
             </div>
             :
          <LookalikeLocationField
            advertisingLocations={advertisingLocations}
            updateReducer={updateData}
            isCampaignSuccess={isCampaignSuccess}
          />
         }
          {/* <TextField
            placeholder="Select"
            variant="outlined"
            sx={{
              boxShadow: "none",
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
            }}
            value={props.data.location}
            onChange={(e) => optimizedFn(e, "location")}
            // onChange={(e) => {
            //   handleChange(e, "location");
            // }}
            inputProps={{
              style: {
                padding: "11px 16px",
                background: "#FFFFFF",
                border: "1px solid #DDDDDD",
                borderRadius: 8,
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 12,
                lineHeight: "20px",
              },
            }}
          /> */}
        </div>
        <div className='targeting-form-element age-form-element'>
          <Typography className='targeting-form-element__title'>Age</Typography>
          <div style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
            <TextField
              placeholder='From'
              variant='outlined'
              type='number'
              disabled={isCampaignSuccess}
              sx={{
                'input::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                'input::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                'input[type=number]': {
                  '-moz-appearance': 'textfield',
                },
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              }}
              value={minAge || ''}
              onChange={(e) => { 
                  handleChange(e, 'minAge')
                  setStatus(BACKEND_STATUS.ONGOING);
              }}
              inputProps={{
                style: {
                  padding: '11px 16px',
                  background: '#FFFFFF',
                  border: '1px solid #DDDDDD',
                  borderRadius: 8,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '20px',
                },
              }}
            />

            <div className='divider-age-range'></div>
            <TextField
              placeholder='To'
              variant='outlined'
              type='number'
              disabled={isCampaignSuccess}
              sx={{
                'input::-webkit-outer-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                'input::-webkit-inner-spin-button': {
                  '-webkit-appearance': 'none',
                  margin: 0,
                },
                'input[type=number]': {
                  '-moz-appearance': 'textfield',
                },
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              }}
              value={maxAge || ''}
              onChange={(e) => { 
                  handleChange(e, 'maxAge')
                  setStatus(BACKEND_STATUS.ONGOING);
              }}
              inputProps={{
                style: {
                  padding: '11px 16px',
                  background: '#FFFFFF',
                  border: '1px solid #DDDDDD',
                  borderRadius: 8,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '20px',
                },
              }}
            />
          </div>
        </div>
        <div className='targeting-form-element gender-form-element'>
          <Typography className='targeting-form-element__title'>
            Gender
          </Typography>
          <>
            <RadioGroup
              row
              aria-labelledby='demo-row-radio-buttons-group-label'
              name='row-radio-buttons-group'
              style={{
                gap: '21px',
              }}
              value={gender}
              onChange={(e) => {
                handleChange(e, 'gender');
                setStatus(BACKEND_STATUS.ONGOING);
              }}
            >
              <FormControlLabel
                value='Both'
                control={<BpRadio />}
                label='Both'
                disabled={isCampaignSuccess}
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px !important',
                  lineHeight: '20px',
                  color: '#999999',
                }}
              />
              <FormControlLabel
                value='Male'
                control={<BpRadio />}
                label='Male'
                disabled={isCampaignSuccess}
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px !important',
                  lineHeight: '20px',
                  color: '#999999',
                }}
              />
              <FormControlLabel
                value='Female'
                control={<BpRadio />}
                label='Female'
                disabled={isCampaignSuccess}
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '12px !important',
                  lineHeight: '20px',
                  color: '#999999',
                }}
              />
            </RadioGroup>
          </>
        </div>
      { targetingType !== 'INTERESTKEYWORDS' &&
          <div className='targeting-form-element'>
          <Typography className='targeting-form-element__title'>
            Custom Audience
          </Typography>
          <div>
            <FormControl sx={{ width: '100%' }}>
              <Select
                sx={{
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                style={{
                  height: 42,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  background: '#FFFFFF',
                  border: '1px solid #DDDDDD',
                  borderRadius: 8,
                  color: '#000000',
                }}
                value={audience?.name || ''}
                onChange={(e) => {
                  handleChange(e, 'audience');
                  setStatus(BACKEND_STATUS.ONGOING);
                }}
                renderValue={(selected) => selected}
              >
                <ListSubheader className='drop_custom_list_tophead'>
                  <span>Audience</span> <span>Reach</span>
                </ListSubheader>
                {DropDownRender(isCampaignSuccess)}
              </Select>
            </FormControl>
          </div>
        </div>
      }
      { targetingType === 'LOOKALIKE' &&
        <div className='targeting-form-element lookAlikeformelement'>
          <Typography className='targeting-form-element__title'>
            Lookalike
          </Typography>
          <div style={{ display: 'flex', gap: '13px', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <TextField
                disabled={isCampaignSuccess}
                placeholder='From'
                variant='outlined'
                type='number'
                sx={{
                  'input::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  'input::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  'input[type=number]': {
                    '-moz-appearance': 'textfield',
                  },
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                value={minRange || ''}
                onChange={(e) => { 
                    handleChange(e, 'minRange')
                    setStatus(BACKEND_STATUS.ONGOING);
                }}
                // onChange={(e) => {
                //   handleChange(e, "lookalikeFrom");
                // }}
                inputProps={{
                  style: {
                    padding: '11px 16px',
                    background: '#FFFFFF',
                    border: '1px solid #DDDDDD',
                    borderRadius: 8,
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '20px',
                  },
                }}
              />
              <span
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#999999',
                }}
              >
                %
              </span>
            </div>

            <div className='divider-age-range'></div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <TextField
                placeholder='To'
                disabled={isCampaignSuccess}
                variant='outlined'
                type='number'
                sx={{
                  'input::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  'input::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0,
                  },
                  'input[type=number]': {
                    '-moz-appearance': 'textfield',
                  },
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': { border: 0 },
                }}
                value={maxRange || ''}
                onChange={(e) => { 
                    handleChange(e, 'maxRange')
                    setStatus(BACKEND_STATUS.ONGOING);
                }}
                // onChange={(e) => {
                //       handleChange(e, "lookalikeTo");
                //     }}
                inputProps={{
                  style: {
                    padding: '11px 16px',
                    background: '#FFFFFF',
                    border: '1px solid #DDDDDD',
                    borderRadius: 8,
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: '20px',
                  },
                }}
              />
              <span
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#999999',
                }}
              >
                %
              </span>
            </div>
          </div>
        </div>
      }
      { targetingType === 'INTERESTKEYWORDS' &&
        <>
        <div
          className="lookAlikeformelement"
          style={{
            width: "100%",
          }}
        >
          <Typography className="targeting-form-element__title">
            Keywords
          </Typography>
          <div className="add-view-interests-targetting">
            {(keywords || []).map((keyword, idx) => {
              if (idx < 12) {
                return (
                  <div
                    style={{
                      padding: "3.27481px 5.45802px",
                      background: "#FFFFFF",
                      borderRadius: "3.27481px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 500,
                        fontSize: 12,
                        lineHeight: "15px",
                      }}
                    >
                      {keyword.name}
                    </Typography>
                  </div>
                );
              }
            })}
          </div>
              { !isCampaignSuccess && <Button
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 10,
              lineHeight: "12px",
              color: "#0869FB",
              textTransform: "capitalize",
              justifyContent: "flex-start",
            }}
            onClick={() => showModal(!modal)}
          >
            Add / View More
          </Button>
              }
        </div>
        </>
      }
      </div>
      <AddEditKeywordModal keywords={keywords} open={modal} replace={replace} remove={remove} onClose={() => showModal(!modal)}/>
    </div>
  );
};

export default LookAlikeTargetting;
