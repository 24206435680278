import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  showFlashmessage: false,
  flashMessage: { message: 'Test Message', severity: 'info' },
};

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // component actions with saga
    // fetchScroll() {},
    // internal actions without saga and triggered by saga
    toggleSnackbar(state) {
      state.showFlashmessage = !state.showFlashmessage;
    },
    openSnackbar(state, action) {
      const { payload } = action;
      state.flashMessage = payload;
      state.showFlashmessage = !state.showFlashmessage;
    },
  },
});

export const {
  toggleSnackbar,
  openSnackbar,
} = globalSlice.actions;

export default globalSlice.reducer;
