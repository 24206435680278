import { isAnyCampaignsPublished } from "../../../components/CampaignPreview/utils";
import { BACKEND_STATUS, PROPOSAL_STATE, leftBarStatus } from "../../accountManagerUtil";
import { showAnalyticsDashboard } from "../../clientDashboardIdsUtil";
import { accessSharingStatus, checkForAccess, strategyStage } from "../commonUtils";

export const listOfIds = {
  onboardingForm: "ONBOARDING_FORM",
  accessSharing: "ACCESS_SHARING",
  onboardingDoc: "ONBOARDING_DOC",
  strategy: "STRATEGY_DECK",
  campaignPreview: "CAMPAIGN_PREVIEW",
  analyticDashboard: "AnalyticDashboard",
};

export const leftPanelLabelHDMClient = {
  ONBOARDING_FORM: "Onboarding Form",
  ACCESS_SHARING: "Access Sharing",
  ONBOARDING_DOC: "Onboarding Doc",
  STRATEGY_DECK: "Strategy Deck",
  CAMPAIGN_PREVIEW: "Campaign Preview",
  ANALYTICS_DASHBOARD: "Analytics Dashboard",
};

export const leftPanelNameInUrlPath = {
  HOMEPAGE: "homepage",
  ONBOARDING_FORM: "onboarding-form",
  ACCESS_SHARING: "access-sharing",
  ONBOARDING_DOC: "onboarding-doc",
  STRATEGY_DECK: "strategy-deck",
  CAMPAIGN_PREVIEW: "campaign-preview",
  ANALYTICS_DASHBOARD: "analytics-dashboard",
};

export const getStatusForLeftBarHDMClient = (form) => {
  const statusObj = {
    [listOfIds.onboardingForm]: leftBarStatus.ongoing,
    [listOfIds.accessSharing]: leftBarStatus.toBeUnlocked,
    [listOfIds.onboardingDoc]: leftBarStatus.toBeUnlocked,
    [listOfIds.strategy]: leftBarStatus.toBeUnlocked,
    [listOfIds.campaignPreview]: leftBarStatus.toBeUnlocked,
    [listOfIds.analyticDashboard]: leftBarStatus.toBeUnlocked,
  };

  if(form?.onboardingInfo?.status === BACKEND_STATUS.COMPLETED){
    statusObj[listOfIds.onboardingForm] = leftBarStatus.completed;
    statusObj[listOfIds.accessSharing] = leftBarStatus.ongoing;
  }

  if(form?.accessSharing?.status === BACKEND_STATUS.COMPLETED) {
    statusObj[listOfIds.accessSharing] = leftBarStatus.completed;
    statusObj[listOfIds.onboardingDoc] = leftBarStatus.ongoing;
  }

  if(form?.proposalStatus === BACKEND_STATUS.COMPLETED){
    statusObj[listOfIds.onboardingDoc] = leftBarStatus.completed;
    statusObj[listOfIds.strategy] = leftBarStatus.ongoing;
  }
  if(strategyStage(form?.strategyIterations || []) === PROPOSAL_STATE.APPROVED){
    statusObj[listOfIds.strategy] = leftBarStatus.completed;
  }

  if(form?.anyGoogleCampaignPublished || form?.anyFbCampaignPublished){
    statusObj[listOfIds.campaignPreview] = leftBarStatus.completed;
    statusObj[listOfIds.analyticDashboard] = leftBarStatus.completed;
  }

  return statusObj;

}
