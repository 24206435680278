import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  Box,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Popper,
  TextField,
} from "@mui/material";
import moment from "moment";
import { forwardRef, useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import CloseIcon from "@mui/icons-material/Clear";

const WEEK_DAYS = ["S", "M", "T", "W", "T", "F", "S"];
const PLACEHOLDER_DEFAULT = "DD-MM-YYYY";

const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDate = (date) =>
  moment.tz(date, timeZone).format("YYYY-MM-DD");
const displayFormatedDate = (date) =>
  moment.tz(date, timeZone).format("DD/MM/YYYY");
export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const DatePickerField = forwardRef(function (
  {
    value,
    onChange,
    placeholder = PLACEHOLDER_DEFAULT,
    disableDatesBefore,
    disabled,
    enabledClearField = false,
    ...props
  },
  ref
) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  const [_value, setValue] = useState(() =>
    !!value ? new DateObject(value) : undefined
  );

  const handleChange = (date) => {
    setValue(date);
  };

  const onSave = () => {
    onChange(formatDate(_value?.toDate()));
    handleClose();
  };
  const onCancel = () => {
    const date = !!value ? new DateObject(value) : undefined;
    setValue(date);
    handleClose();
  };

  const onClear = (e) => {
    e.stopPropagation();
    setValue(null);
    onChange(null);
    handleClose();
  };

  useEffect(() => {
    const date = !!value ? new DateObject(value) : undefined;
    setValue(date);
  }, [value]);

  console.log(
    "value:",
    value,
    "_value:",
    displayFormatedDate(_value?.format())
  );

  return (
    <ClickAwayListener onClickAway={onCancel}>
      <Box>
        <TextField
          placeholder={placeholder}
          value={!!_value ? displayFormatedDate(_value?.format()) : ""}
          InputProps={{
            endAdornment: (
              <>
                {enabledClearField && !!_value && (
                  <IconButton
                    style={{
                      width: "0.5rem",
                      height: "0.5rem",
                      position: "absolute",
                      right: "40px",
                    }}
                    onClick={onClear}
                  >
                    <CloseIcon style={{ fontSize: "0.8rem" }} />
                  </IconButton>
                )}
                <InputAdornment position="end">
                  <CalendarTodayIcon
                    style={{
                      color: "#333333",
                      opacity: "0.8",
                      cursor: "pointer",
                    }}
                  />
                </InputAdornment>
              </>
            ),
            readOnly: true,
          }}
          sx={{
            "& input": { border: "none" },
          }}
          onClick={handleClick}
          ref={ref}
          disabled={disabled}
          {...props}
        />
        <Popper
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          placement="bottom-end"
          container={this?.anchorEl.parentNode}
          modifiers={[
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                tether: true,
                rootBoundary: "document",
                // padding: 8,
              },
            },
          ]}
          style={{
            padding: "10px",
            background: "#FFFFFF",
            border: "0.5px solid #E5E5E5",
            boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
            borderRadius: "8px",
            zIndex: 100,
          }}
          disablePortal={true}
        >
          <Calendar
            value={_value}
            onChange={handleChange}
            showOtherDays={true}
            weekDays={WEEK_DAYS}
            highlightToday={true}
            minDate={disableDatesBefore}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.75rem",
            }}
          >
            <button
              className="date-field__button"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="date-field__button date-field__button--primary"
              type="button"
              onClick={onSave}
            >
              Save
            </button>
          </Box>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
});

export default DatePickerField;
