import { useEffect, useMemo, useState } from "react";
import { updateCompoReduxClient } from "../../../api/componentTracking/action";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import "./HomePage.css";
import { bookicon as BOOK } from "../../../assets/icons/common/common";
import { fb_icon as FB } from "../../../assets/icons/social/social";
import { google2 as GOOGLE } from "../../../assets/icons/social/social";
import { proposal as PROPOSAL } from "../../../assets/icons/links/links";
import { deck as DECK } from "../../../assets/icons/links/links";
import { creative as CREATIVE } from "../../../assets/icons/links/links";
import { campaign as CAMPAIGN } from "../../../assets/icons/links/links";
import GOV from "../../../assets/images/governai.png";
import TAR from "../../../assets/images/targetai.png";
import CRE from "../../../assets/images/creativeai.png";

import { blue_platform_icon as bluePlatformIcon } from "../../../assets/icons/icon";
import { green_preview_eye as previewEyeIcon } from "../../../assets/icons/icon";

import { updateLeftSidebarStatusClient } from "../../../api/componentTracking/action";
import GoToSpecificCompoButton from "../../ButtonPrimary/GoToSpecificCompoButton";
import GoToNextCompoButton from "../../ButtonPrimary/GoToNextCompoButton";
import { getLocalStorage } from "../../../api/localStorage/localStorage";
import { getClientDetailsByFormId } from "../../../api/client/action";
import { goToSpecificCompoClient } from "../../../api/componentTracking/action";
import { sharedAssetsGetApi } from "../../../api/sharedAssets/saga";
import { getSharedAssetsGoogel } from "../../../api/sharedAssets/action";
import {
  clientDashboardIds,
  facebookCampaignPreview,
  googleCampaignPreview,
  LDcomponentWithIndexClient,
  showAnalyticsDashboard,
  // showCampaignPreviewClient,
} from "../../../utils/clientDashboardIdsUtil";
import { Typography, Tooltip } from "@mui/material";
import CustomToolkitForInfo from "./CustomToolkitForInfo";
import { isAnyCampaignsPublished } from "../../CampaignPreview/utils";
import { generatePath, useNavigate } from "react-router-dom";
import ldClientRoutePaths from "../../../pages/ld-client/constants/ldClientRoutePaths";
import { useGetClientForms } from "../../../services/clientForms";
import { useGetAdAccounts } from "../../../services/adAccount";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";
const StatusWithRightBtn = ({
  currentStep,
  nextStep,
  btnTitle,
  belowTitle,
  navigationPath,
  disabled = false,
  showTwoBtn = false,
}) => {
  return (
    <div className="status_bubble_inner">
      <div className="status_bar_row">
        <div className="bubble_current_status">
          <span className="icon">
            <img src={previewEyeIcon} />
          </span>
          <p>{currentStep}</p>
        </div>
        <div className="separator_status"></div>
        <div className="bubble_next_status">
          <div
            className={`${
              disabled
                ? "bubble_next_action disable_action"
                : "bubble_next_action"
            }`}
          >
            <span className="icon">
              <img src={bluePlatformIcon} />
            </span>
            <GoToSpecificCompoButton
              className="started_button"
              title={btnTitle}
              type="client"
              navigationPath={navigationPath}
              disabled={disabled}
            />
          </div>
          <p>{nextStep}</p>
        </div>
      </div>
      <p className="bubble_status_info">{belowTitle}</p>
      {showTwoBtn && (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <GoToSpecificCompoButton
            className="started_button"
            title="Google"
            type="client"
            navigationPath={navigationPath}
            disabled={disabled}
          />
          <GoToSpecificCompoButton
            className="started_button"
            title="Facebook"
            type="client"
            disabled={disabled}
            navigationPath={navigationPath}
          />
        </div>
      )}
    </div>
  );
};

const StatusWithSingleTitle = ({
  title,
  showTwoBtn = false,
  googlePreviewDisabled,
  facebookPreviewDisabled,
  getStartedDisabled,
  navigationPath,
  compoTitle = "Get Started",
}) => {
  return (
    <>
      <img src={BOOK} alt="" />
      <span className="single_title_start">{title}</span>
      {!showTwoBtn && (
        // <GoToNextCompoButton
        //   className="started_button"
        //   title="Get Started"
        //   type="client"
        // />
        <GoToSpecificCompoButton
          className="started_button"
          title={compoTitle}
          type="client"
          disabled={getStartedDisabled}
          navigationPath={navigationPath}
        />
      )}
      {showTwoBtn && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50%",
          }}
        >
          <GoToSpecificCompoButton
            className="started_button"
            disabled={googlePreviewDisabled}
            title="Google"
            type="client"
            navigationPath={navigationPath}
          />
          <GoToSpecificCompoButton
            className="started_button"
            disabled={facebookPreviewDisabled}
            title="Facebook"
            type="client"
            navigationPath={navigationPath}
          />
        </div>
      )}
    </>
  );
};
export const dbStatus = {
  PENDING: "PENDING",
  CONNECTED: "CONNECTED",
  NOT_CONNECTED: "NOT_CONNECTED",
  VERIFYING: "VERIFYING",
  READ_ACCESS: "READ_ACCESS",
  ACCESS_REVOKED: "ACCESS_REVOKED",
  COMPLETE_ACCESS: "COMPLETE_ACCESS",
  VERIFYING_COMPLETE_ACCESS: "VERIFYING_COMPLETE_ACCESS",
  COMPLETED_ACCESS_NOT_SHARED: "COMPLETED_ACCESS_NOT_SHARED",
};
export const mappedStatusDb = {
  [dbStatus.VERIFYING]: "Verifying",
  [dbStatus.READ_ACCESS]: "Read Access",
  [dbStatus.ACCESS_REVOKED]: "Not Shared",
  [dbStatus.VERIFYING_COMPLETE_ACCESS]: "Verifying",
  [dbStatus.COMPLETED_ACCESS_NOT_SHARED]: "Not Shared",
  [dbStatus.COMPLETE_ACCESS]: "Complete Access",
};
export const checkingReadAccess = (platformSelected, accountList) => {
  if (platformSelected.length === 2) {
    if (
      (accountList.fbAccess.adAccount.status === dbStatus.VERIFYING &&
        accountList.fbAccess.adAccount.accountId.length === 0) ||
      (accountList.googleAccess.googleAdAccount.status === dbStatus.VERIFYING &&
        accountList.googleAccess.googleAdAccount.accountId.length === 0)
    ) {
      return dbStatus.PENDING;
    } else if (
      (accountList.fbAccess.adAccount.status === dbStatus.VERIFYING &&
        accountList.fbAccess.adAccount.accountId.length !== 0) ||
      (accountList.googleAccess.googleAdAccount.status === dbStatus.VERIFYING &&
        accountList.googleAccess.googleAdAccount.accountId.length !== 0)
    ) {
      return dbStatus.VERIFYING;
    } else if (
      accountList.fbAccess.adAccount.status === dbStatus.ACCESS_REVOKED ||
      accountList.googleAccess.googleAdAccount.status ===
        dbStatus.ACCESS_REVOKED
    ) {
      return dbStatus.ACCESS_REVOKED;
    } else if (
      accountList.fbAccess.adAccount.status === dbStatus.READ_ACCESS &&
      accountList.googleAccess.googleAdAccount.status === dbStatus.READ_ACCESS
    ) {
      return dbStatus.READ_ACCESS;
    }
  } else if (
    platformSelected.length === 1 &&
    platformSelected[0].platform === "google-ads"
  ) {
    if (
      accountList.googleAccess.googleAdAccount.status === dbStatus.VERIFYING &&
      accountList.googleAccess.googleAdAccount.accountId.length === 0
    ) {
      return dbStatus.PENDING;
    } else if (
      accountList.googleAccess.googleAdAccount.status === dbStatus.VERIFYING &&
      accountList.googleAccess.googleAdAccount.accountId.length !== 0
    ) {
      return dbStatus.VERIFYING;
    } else if (
      accountList.googleAccess.googleAdAccount.status ===
      dbStatus.ACCESS_REVOKED
    ) {
      return dbStatus.ACCESS_REVOKED;
    } else if (
      accountList.googleAccess.googleAdAccount.status === dbStatus.READ_ACCESS
    ) {
      return dbStatus.READ_ACCESS;
    }
  } else if (
    platformSelected.length === 1 &&
    platformSelected[0].platform === "facebook-ads"
  ) {
    if (
      accountList.fbAccess.adAccount.status === dbStatus.VERIFYING &&
      accountList.fbAccess.adAccount.accountId.length === 0
    ) {
      return dbStatus.PENDING;
    } else if (
      accountList.fbAccess.adAccount.status === dbStatus.VERIFYING &&
      accountList.fbAccess.adAccount.accountId.length !== 0
    ) {
      return dbStatus.VERIFYING;
    } else if (
      accountList.fbAccess.adAccount.status === dbStatus.ACCESS_REVOKED
    ) {
      return dbStatus.ACCESS_REVOKED;
    } else if (accountList.fbAccess.adAccount.status === dbStatus.READ_ACCESS) {
      return dbStatus.READ_ACCESS;
    }
  }
  return dbStatus.PENDING;
};
export const checkingForCompleteAccess = (platformSelected, accountList) => {
  for (let i = 0; i < platformSelected.length; i++) {
    if (platformSelected[i].platform === "facebook-ads") {
      if (
        accountList.fbAccess.adAccount.status ===
        dbStatus.COMPLETED_ACCESS_NOT_SHARED
      ) {
        return dbStatus.COMPLETED_ACCESS_NOT_SHARED;
      }
    } else if (platformSelected[i].platform === "google-ads") {
      if (
        accountList.googleAccess.googleAdAccount.status ===
        dbStatus.COMPLETED_ACCESS_NOT_SHARED
      ) {
        return dbStatus.COMPLETED_ACCESS_NOT_SHARED;
      }
    }
  }
  for (let i = 0; i < platformSelected.length; i++) {
    if (platformSelected[i].platform === "facebook-ads") {
      if (
        accountList.fbAccess.adAccount.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
      ) {
        return dbStatus.VERIFYING_COMPLETE_ACCESS;
      }
    } else if (platformSelected[i].platform === "google-ads") {
      if (
        accountList.googleAccess.googleAdAccount.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
      ) {
        return dbStatus.VERIFYING_COMPLETE_ACCESS;
      }
    }
  }
  let complete = true;
  for (let i = 0; i < platformSelected.length; i++) {
    if (platformSelected[i].platform === "facebook-ads") {
      if (accountList.fbAccess.adAccount.status !== dbStatus.COMPLETE_ACCESS) {
        complete = false;
        break;
      }
    } else if (platformSelected[i].platform === "google-ads") {
      if (
        accountList.googleAccess.googleAdAccount.status !==
        dbStatus.COMPLETE_ACCESS
      ) {
        complete = false;
        break;
      }
    }
  }
  if (
    checkingReadAccess(platformSelected, accountList) === dbStatus.PENDING &&
    !complete
  ) {
    return dbStatus.PENDING;
  }
  if (
    dbStatus.VERIFYING === checkingReadAccess(platformSelected, accountList) &&
    !complete
  ) {
    return dbStatus.VERIFYING;
  }
  if (
    dbStatus.ACCESS_REVOKED ===
      checkingReadAccess(platformSelected, accountList) &&
    !complete
  ) {
    return dbStatus.ACCESS_REVOKED;
  }
  if (
    checkingReadAccess(platformSelected, accountList) ===
      dbStatus.READ_ACCESS &&
    !complete
  ) {
    return dbStatus.READ_ACCESS;
  }
  return complete
    ? dbStatus.COMPLETE_ACCESS
    : dbStatus.VERIFYING_COMPLETE_ACCESS;
};
const isAccountConnected = (platformName, accountList) => {
  if (platformName === "facebook-ads") {
    if (
      accountList?.fbAccess?.adAccount?.status === dbStatus.READ_ACCESS ||
      accountList?.fbAccess?.adAccount?.status === dbStatus.COMPLETE_ACCESS ||
      accountList?.fbAccess?.adAccount?.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
    ) {
      return dbStatus.CONNECTED;
    } else if (
      accountList?.fbAccess?.adAccount?.status === dbStatus.VERIFYING &&
      accountList.fbAccess.adAccount.accountId.length !== 0
    ) {
      return dbStatus.VERIFYING;
    }
  } else if (platformName === "google-ads") {
    if (
      accountList?.googleAccess?.googleAdAccount?.status ===
        dbStatus.READ_ACCESS ||
      accountList?.googleAccess?.googleAdAccount?.status ===
        dbStatus.COMPLETE_ACCESS ||
      accountList?.googleAccess?.googleAdAccount?.status ===
        dbStatus.VERIFYING_COMPLETE_ACCESS
    ) {
      return dbStatus.CONNECTED;
    } else if (
      accountList?.googleAccess?.googleAdAccount?.status ===
        dbStatus.VERIFYING &&
      accountList?.googleAccess?.googleAdAccount?.accountId?.length !== 0
    ) {
      return dbStatus.VERIFYING;
    }
  }
  return dbStatus.NOT_CONNECTED;
};
const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formId = getLocalStorage("formId");
  const [isCampaignApproved, setIsCampaignApproved] = useState(false);

  const { data: clientState = {} } = useGetClientForms(formId);
  const { data: adConnectedAccounts, isLoading } = useGetAdAccounts(
    getLocalStorage("brandId")
  );
  const proposalStage = clientState.proposalIterations?.length
    ? clientState["proposalIterations"][
        clientState.proposalIterations.length - 1
      ]["state"]
    : null;
  const intakeForm = clientState?.intakeInfo;
  const strategyIterations = clientState.strategyIterations;
  const platformSelected = clientState?.onboardingInfo?.platformInfo?.platforms;
  const accountid = clientState?.accessSharing;
  const connectedGoogleAccounts = useMemo(() => {
    return adConnectedAccounts?.data?.filter((connectedAdAccount) => {
      return connectedAdAccount.platform === "GOOGLE";
    });
  }, [adConnectedAccounts]);
  const connectedFacebookAccounts = useMemo(() => {
    return adConnectedAccounts?.data?.filter((connectedAdAccount) => {
      return connectedAdAccount.platform === "FACEBOOK";
    });
  }, [adConnectedAccounts]);
  useEffect(() => {
    if (
      clientState?.anyGoogleCampaignPublished ||
      clientState?.anyFbCampaignPublished
    ) {
      setIsCampaignApproved(true);
    }
  }, [clientState]);
  if (isLoading) {
    return <PmaxLoader />;
  }
  return (
    <div className="main_hero">
      <div className="onboard">
        <div className="hero_banner">
          <div className="banner_left">
            <span>Welcome</span>
            <h2>{getLocalStorage("brandName")}</h2>
            <p>
              {clientState?.onboardingInfo?.status !== "COMPLETED"
                ? "Please wait while we connect with you on a call. Post that, you’ll be able to use this dashboard to review your proposal."
                : `Meet the most powerful product to infuse AI-led decision-making in
              every aspect of your marketing.`}
            </p>
          </div>
          <div className="bubble_banner">
            {!proposalStage ? (
              <StatusWithSingleTitle
                title={"Onboarding Call Pending"}
                getStartedDisabled={true}
              />
            ) : proposalStage === "GENERATED" ? (
              <StatusWithRightBtn
                currentStep="Read Access Granted"
                nextStep="Proposal Generated"
                btnTitle="Review Proposal"
                navigationPath={generatePath(
                  ldClientRoutePaths.proposol.fullbase
                )}
                belowTitle="Your proposal has been generated and is ready for review"
              />
            ) : proposalStage === "CHANGES_SUGGESTED" ? (
              <StatusWithRightBtn
                currentStep="Changes In Proposal"
                nextStep="Generating New Proposal"
                btnTitle="View Proposal"
                compoNumber={null}
                belowTitle="Your revised proposal is now being generated."
                disabled={true}
              />
            ) : proposalStage === "CHANGES_APPLIED" ? (
              <StatusWithRightBtn
                currentStep="Changes In Proposal"
                nextStep="New Proposal Generated"
                btnTitle="View Proposal"
                navigationPath={generatePath(
                  ldClientRoutePaths.proposol.fullbase
                )}
                belowTitle="You can now view your revised proposal."
              />
            ) : proposalStage === "APPROVED" &&
              intakeForm?.status !== "COMPLETED" ? (
              <StatusWithRightBtn
                currentStep="Proposal Accepted"
                nextStep="Fill Intake Form"
                btnTitle="View Intake Form"
                navigationPath={`${ldClientRoutePaths.intakeForm.fullbase}/${ldClientRoutePaths.intakeForm.sub.productInfo}`}
              />
            ) : intakeForm?.status === "COMPLETED" &&
              strategyIterations.length === 0 ? (
              <StatusWithRightBtn
                currentStep="Intake Form Complete"
                nextStep="Generating Strategy Deck"
                btnTitle="View Strategy Deck"
                compoNumber={null}
                disabled={true}
              />
            ) : strategyIterations.length !== 0 &&
              strategyIterations[strategyIterations.length - 1].state ===
                "GENERATED" ? (
              <StatusWithRightBtn
                currentStep="Complete Access Received"
                nextStep="Strategy Deck Generated"
                btnTitle="View Strategy Deck"
                navigationPath={generatePath(
                  ldClientRoutePaths.strategyDeck.fullbase
                )}
              />
            ) : strategyIterations.length !== 0 &&
              strategyIterations[strategyIterations.length - 1].state ===
                "CHANGES_SUGGESTED" ? (
              <StatusWithRightBtn
                currentStep="Changes In Strategy Deck"
                nextStep="Generating New Strategy Deck"
                btnTitle="View Strategy Deck"
                compoNumber={null}
                disabled={true}
              />
            ) : strategyIterations.length !== 0 &&
              strategyIterations[strategyIterations.length - 1].state ===
                "CHANGES_APPLIED" ? (
              <StatusWithRightBtn
                currentStep="Changes In Strategy Deck"
                nextStep="New strategy deck generated"
                btnTitle="View Strategy Deck"
                navigationPath={generatePath(
                  ldClientRoutePaths.strategyDeck.fullbase
                )}
              />
            ) : strategyIterations.length !== 0 &&
              strategyIterations[strategyIterations.length - 1].state ===
                "APPROVED" &&
              !isCampaignApproved ? (
              <StatusWithRightBtn
                currentStep="Strategy Deck Approved"
                nextStep="Generating campaigns"
                btnTitle="Generating campaigns"
                compoNumber={null}
                disabled={true}
              />
            ) : isCampaignApproved ? (
              <StatusWithSingleTitle
                title="Campaigns Generated"
                navigationPath={generatePath(
                  ldClientRoutePaths.campaignPreview.fullbase
                )}
                compoTitle="Preview Campaigns"
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div className="grid_home_page">
        <div className="grid_colum">
          <div className="grid_account_column">
            <p className="home_title">Accounts</p>

            <div className="social_media">
              {connectedFacebookAccounts?.length > 0 ? (
                <div className="social_home">
                  <div className="social_list_home">
                    <img src={FB} alt="" />
                    <p className="social_name_title">Facebook Ads</p>
                  </div>
                  <div className={"social_status_tag success"}>
                    <p>Connected</p>
                  </div>
                </div>
              ) : (
                <div className="social_home">
                  <div className="social_list_home">
                    <img src={FB} alt="" />
                    <p className="social_name_title">Facebook Ads</p>
                  </div>
                  <div className="social_status_tag warning">
                    <p>Not Connected</p>
                  </div>
                </div>
              )}

              {connectedGoogleAccounts?.length > 0 ? (
                <div className="social_home">
                  <div className="social_list_home">
                    <img src={GOOGLE} alt="" />
                    <p className="social_name_title">Google Ads</p>
                  </div>
                  <div className={"social_status_tag success"}>
                    <p>Connected</p>
                  </div>
                </div>
              ) : (
                <div className="social_home">
                  <div className="social_list_home">
                    <img src={GOOGLE} alt="" />
                    <p className="social_name_title">Google Ads</p>
                  </div>
                  <div className="social_status_tag warning">
                    <p>Not Connected</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="grid_quick_column">
            <p className="home_title">Quick Links</p>
            <div className="home_cards">
              <div className="card_block_home">
                <i>
                  <img src={PROPOSAL} alt="" />
                </i>
                <p>Proposal</p>
                <span
                  style={{
                    backgroundColor:
                      proposalStage === "GENERATED" ||
                      proposalStage === "APPROVED" ||
                      proposalStage === "CHANGES_APPLIED"
                        ? ""
                        : "#eeee",
                    opacity:
                      proposalStage === "GENERATED" ||
                      proposalStage === "APPROVED" ||
                      proposalStage === "CHANGES_APPLIED"
                        ? 1
                        : 0.5,
                    cursor: proposalStage !== "APPROVED" ? "" : "pointer",
                  }}
                  onClick={() => {
                    (proposalStage === "GENERATED" ||
                      proposalStage === "APPROVED" ||
                      proposalStage === "CHANGES_APPLIED") &&
                      navigate(
                        generatePath(ldClientRoutePaths.proposol.fullbase)
                      );
                  }}
                >
                  View Proposal
                </span>
              </div>
              <div className="card_block_home">
                <i>
                  <img src={DECK} alt="" />
                </i>
                <p>Strategy Deck</p>
                <span
                  style={{
                    backgroundColor:
                      strategyIterations?.length &&
                      (strategyIterations[strategyIterations.length - 1]
                        .state === "GENERATED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "CHANGES_APPROVED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "APPROVED")
                        ? ""
                        : "#eeee",
                    opacity:
                      strategyIterations?.length &&
                      (strategyIterations[strategyIterations.length - 1]
                        .state === "GENERATED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "CHANGES_APPROVED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "APPROVED")
                        ? 1
                        : 0.5,
                    cursor:
                      strategyIterations?.length &&
                      (strategyIterations[strategyIterations.length - 1]
                        .state === "GENERATED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "CHANGES_APPROVED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "APPROVED")
                        ? "pointer"
                        : "",
                  }}
                  onClick={() => {
                    strategyIterations?.length &&
                      (strategyIterations[strategyIterations.length - 1]
                        .state === "GENERATED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "CHANGES_APPROVED" ||
                        strategyIterations[strategyIterations.length - 1]
                          .state === "APPROVED") &&
                      navigate(
                        generatePath(ldClientRoutePaths.strategyDeck.fullbase)
                      );
                  }}
                >
                  View Strategy
                </span>
              </div>
              <div
                className="card_block_home"
                onClick={() => {
                  if (showAnalyticsDashboard(clientState)) {
                    dispatch(
                      goToSpecificCompoClient(
                        LDcomponentWithIndexClient[
                          clientDashboardIds.CREATIVE_APPROVAL_ADCOPIES
                        ]
                      )
                    );
                  }
                }}
              >
                <i>
                  <img src={CREATIVE} alt="" />
                </i>
                <p>Creatives</p>
                <span
                  style={{
                    backgroundColor: isCampaignApproved ? "" : "#eeee",
                    opacity: isCampaignApproved ? 1 : 0.5,
                    cursor: isCampaignApproved ? "pointer" : "",
                  }}
                  onClick={() => {
                    navigate(
                      generatePath(ldClientRoutePaths.creativeApproval.fullbase)
                    );
                  }}
                >
                  View Creatives
                </span>
              </div>
              <div className="card_block_home">
                <i>
                  <img src={CAMPAIGN} alt="" />
                </i>
                <p>Campaign Setup</p>
                <span
                  style={{
                    backgroundColor: isCampaignApproved ? "" : "#eeee",
                    opacity: isCampaignApproved ? 1 : 0.5,
                    cursor: isCampaignApproved ? "pointer" : "",
                  }}
                  onClick={() => {
                    navigate(
                      generatePath(ldClientRoutePaths.campaignPreview.fullbase)
                    );
                  }}
                >
                  View Campaign
                </span>
              </div>
              <div className="card_block_home">
                <i>
                  <img src={PROPOSAL} alt="" />
                </i>
                <p>Analytics</p>
                <span
                  style={{
                    backgroundColor: isCampaignApproved ? "" : "#eeee",
                    opacity: isCampaignApproved ? 1 : 0.5,
                    cursor: isCampaignApproved ? "pointer" : "",
                  }}
                  onClick={() => {
                    if (isCampaignApproved) {
                      window.location.href = "/analytic-dashboard";
                    }
                    // if (Object.values(campaignsObj)?.every(val => val !== null)) {
                    //   window.location.href = "/analytic-dashboard";
                    // }
                  }}
                >
                  View Dashboard
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="grid_colum">
          <div className="grid_learning_column">
            <div className="learning_centre">
              <p className="home_title">Pixis Learning Centre</p>
              <div className="learning_home_row">
                <div className="learning_home_img">
                  <img src={GOV} alt="" />
                </div>
                <div className="learning_item_info">
                  <h4>Performance AI</h4>
                  <p>
                    Maximize your marketing ROI by leveraging hyper-contextual
                    proprietary models. An all-in-one AI-led performance engine
                    to manage all aspects of your marketing.
                  </p>
                </div>
                <a
                  href="https://pixis.ai/performance-ai/"
                  target="_blank"
                  className="export_icon"
                ></a>
              </div>
              <div className="learning_home_row">
                <div className="learning_home_img">
                  <img src={TAR} alt="" />
                </div>
                <div className="learning_item_info">
                  <h4>Targeting AI</h4>
                  <p>
                    Fuel your targeting strategies with AI-powered customer
                    behavior & engagement feedbacks. Trained on billions of data
                    points, Targeting AI creates the most relevant cohorts for
                    your brand.
                  </p>
                </div>
                <a
                  href="https://pixis.ai/targeting-ai/"
                  target="_blank"
                  className="export_icon"
                ></a>
              </div>
              <div className="learning_home_row">
                <div className="learning_home_img">
                  <img src={CRE} alt="" />
                </div>
                <div className="learning_item_info">
                  <h4>Creative AI</h4>
                  <p>
                    Generate contextual text and creative assets effortlessly
                    for each of your cohorts. Get cohort-specific asset
                    recommendations, engagement-based feedback, and more with
                    industry-leading Transformer-based models.
                  </p>
                </div>
                <a
                  href="https://pixis.ai/creative-ai/"
                  target="_blank"
                  className="export_icon"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomePage;
