import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../api';
import { ADSET_CRON_STATUS } from '../components/FacebookStrategyCreation/utils';
import { queryClient } from '../utils/queryClient';
import { BACKEND_STATUS } from '../utils/accountManagerUtil';

const getListFBAdsCampaignStrategiesQueryKey = (formId, generationTypeFilter) => [`fb-campaign-strategy/list/:formId?generationTypeFilter`, formId, ...generationTypeFilter];
export const useGetListFBAdsCampaignStrategies = ({ formId, generationTypeFilter = [] }, options = {}) => {
  return useQuery(
    getListFBAdsCampaignStrategiesQueryKey(formId, generationTypeFilter),
    async () => {
      const searchParams = new URLSearchParams();
      for (let filter of generationTypeFilter) {
        searchParams.append("generationType", filter);
      }
      const response = await api.get(`/client-forms/${formId}/fb-campaign-strategy?${searchParams.toString()}`);
      return response.data;
    },
    options
  );
};

const getFBAdsCampaignStrategyByIdQueryKey = (formId, strategyId) => [`fb-campaign-strategy/list/:formId/:stategyId`, formId, strategyId];
export const useGetFBAdsCampaignStrategyById = ({ formId, strategyId }, options = {}) => {
  return useQuery(
    getFBAdsCampaignStrategyByIdQueryKey(formId, strategyId),
    async () => {
      const response = await api.get(`/client-forms/${formId}/fb-campaign-strategy/${strategyId}`);
      return response.data;
    },
    options
  );
};

export const useDeleteFbAdsCampaignStartegyById = ({ formId, generationTypeFilter = [] }, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ strategyId }) => {
      const response = await api.delete(`/client-forms/${formId}/fb-campaign-strategy/${strategyId}/assets`);
      return response.data;
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        const { strategyId } = variables;
        queryClient.setQueryData(
          getListFBAdsCampaignStrategiesQueryKey(formId, generationTypeFilter),
          (prev) => ({
            ...prev,
            strategies: prev?.strategies?.filter(strategy => strategy.id !== strategyId),
            totalResultsCount: prev?.totalResultsCount - 1,
          }),
        );

        options.onSuccess?.(data, variables, ctx);
      }
    }
  );
};

export const usePostFbAdsCampaignStrategy = ({ formId, generationTypeFilter = [] }, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ strategy }) => {
      const response = await api.post(`/client-forms/${formId}/fb-campaign-strategy`, strategy);
      return response.data;
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        queryClient.setQueryData(
          getListFBAdsCampaignStrategiesQueryKey(formId, generationTypeFilter),
          (prev) => ({
            ...prev,
            strategies: [...prev?.strategies, data],
            totalResultsCount: prev?.totalResultsCount + 1,
          }),
        );

        options.onSuccess?.(data, variables, ctx);
      }
    }
  );
}

export const usePutFbAdsCampaignStrategy = ({ formId, generationTypeFilter = [] }, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ strategy, strategyId }) => {
      // TODO: find a bettwr way to remove adsets.<id> which are empty string
      // remove adset ids which are to be created (adsets which r to be create has empty string for id, which is not accepeted by backend)
      const _strategy = {
        ...strategy,
        ...(strategy.adsets ? {
          adsets: strategy.adsets.map(({ id, ...adset }) => ({
            ...adset,
            status: BACKEND_STATUS.COMPLETED,
            ...(!!id ? { id } : {}) // remove id: ""
          }))
        } : {}),
      };
      const response = await api.put(`/client-forms/${formId}/fb-campaign-strategy/${strategyId}`, _strategy);
      return response.data;
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        const { strategyId } = variables
        queryClient.invalidateQueries(getFBAdsCampaignStrategyByIdQueryKey(formId, strategyId));
        queryClient.setQueryData(
          getListFBAdsCampaignStrategiesQueryKey(formId, generationTypeFilter),
          (prev) => ({
            ...prev,
            strategies: prev?.strategies?.map(strategy => strategy.id === strategyId ? Object.assign({}, strategy, data) : strategy),
            totalResultsCount: prev?.totalResultsCount - 1,
          }),
        );

        options.onSuccess?.(data, variables, ctx);
      },
    },
  );
}

// execute update strategies parallely
export const usePutFacebookAdsStrategies = (formId, generationTypeFilter = [], options={}) => {
  const { onSuccess, ...otherOptions } = options;
  return useMutation({
      mutationFn: async (strategies) => {
          const response = await Promise.all(strategies.map(strategy => {
              return api.put(`/client-forms/${formId}/fb-campaign-strategy/${strategy.id}`, strategy);
          }));
          return response;
      },
      onSuccess: (...args) => {
          queryClient.invalidateQueries({ queryKey: getListFBAdsCampaignStrategiesQueryKey(formId, generationTypeFilter) });
      },
      ...otherOptions
  })
}

export const useDeleteFbAdsCampaignStartegyAdsets = ({ formId, strategyId }, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ adsetIds = [] }) => {
      // guard clause to avoid deleting the strategy when adsets are empty,
      // as when adsets are empty backend deletes the strategy (common endpoint)
      if (!adsetIds.length) return Promise.reject({ detail: "no adsets to delete as adsetIds is empty" });

      const searchParams = new URLSearchParams();
      for (let id of adsetIds) {
        searchParams.append("adset_ids", id);
      }
      const response = await api.delete(`/client-forms/${formId}/fb-campaign-strategy/${strategyId}/assets?${searchParams.toString()}`);
      return response.data;
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        const { adsetIds } = variables;
        queryClient.setQueryData(
          getFBAdsCampaignStrategyByIdQueryKey(formId, strategyId),
          (prev) => ({
            ...prev,
            adsets: prev.adsets.filter(adset => !adsetIds.includes(adset.id)),
          }),
        );

        options.onSuccess?.(data, variables, ctx);
      }
    }
  );
};

export const usePollAdset = ({ adsetId, formId, strategyId }, options = {}) => {
  return useQuery(
    [`clientform/${formId}/strategy/${strategyId}/adset/${adsetId}/poll`],
    async () => {
      const response = await api.get(`/client-forms/${formId}/fb-campaign-strategy/${strategyId}?adset_id=${adsetId}`);
      return response.data;
    },
    {
      ...options,
      refetchInterval(data) {
        if (data && [ADSET_CRON_STATUS.RUNNING, ADSET_CRON_STATUS.PENDING].includes(data.cronStatus)) return 30 * 1000;
        return false;
      },
    },
  );
};
