const INITIAL_STATE = {
  locationsList: [],
  pixels: [],
  events: {},
  pages: [],
  lookalike: {},
  interest: {},
  MOF: {},
  BOF: {},
  campaignConfirmModal: false,
  isBackClicked: false,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "updateLookalike":
      return {
        ...state,
        lookalike: { ...state.lookalike, ...action.payload },
      };
    case "updateInterest":
      return {
        ...state,
        interest: { ...state.interest, ...action.payload },
      };
    case "updatemof":
      return {
        ...state,
        MOF: { ...state.MOF, ...action.payload },
      };
    case "updatebof":
      return {
        ...state,
        BOF: { ...state.BOF, ...action.payload },
      };
    case "updateLocationCampaign":
      const stateTemp = { ...state };
      console.log("in updateLocationCampaign", action.payload);
      let options = action.payload.locations.map(function (element) {
        return {
          name:
            element.type === "country"
              ? element.name
              : element.type === "region"
              ? `${element.name}, ${element.country_name}`
              : element.type === "city"
              ? `${element.name}, ${element.region}, ${
                  element.countryName || element.country_name
                }`
              : element.type === "zip"
              ? `${element.name}, ${element.primary_city}, ${element.region} ${
                  element.countryName || element.country_name
                }`
              : element.type === "geo_market"
              ? `${element.name}, ${
                  element.countryName || element.country_name
                }`
              : element.type === "electoral_district"
              ? `${element.name}, ${element.region} ${
                  element.countryName || element.country_name
                }`
              : `${element.name}`,
          type: element.type,
          key: element.key,
          countryName: element.countryName || element.country_name,
          region: element.region,
          supportsRegion: element.supports_region,
          supportsCity: element.supports_city,
        };
      });
      if (options) {
        stateTemp["locationsList"] = options;
      } else {
        stateTemp["locationsList"] = [];
      }
      return stateTemp;
    // case "addLocationTerget":
    //   const key = action.payload.key;
    //   const index = action.payload.index;
    //   const stateTemp1 = { ...state };
    //   if (!stateTemp1[key]["targeting"][index]) {
    //     stateTemp1[key]["targeting"][index] = {};
    //   }
    //   stateTemp1[key]["targeting"][index]["location"] = action.payload.data;
    //   return stateTemp1;
    case "UPDATE_FB_PAGES":
      return {
        ...state,
        pages: action.payload || [],
      };
    case "UPDATE_FB_PIXELS":
      return {
        ...state,
        pixels: action.payload || [],
      };

    case "UPDATE_FB_PIXEL_EVENTS":
      return {
        ...state,
        events: { ...state.events, ...action.payload },
      };
    case "UPDATE_HDM_CAMPAIGN_CONFIRM_MODAL":
      return {
        ...state,
        campaignConfirmModal: !state.campaignConfirmModal,
      };
    case "UPDATE_CAMPAIGN_BACK_CLICKED":
      return {
        ...state,
        isBackClicked: !state.isBackClicked,
      };
    default:
      return state;
  }
};

export default reducer;
