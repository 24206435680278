import React from 'react';
import { Typography } from '@mui/material';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import {campaign_publish_error_icon as Error} from '../../../assets/icons/campaign-approval/campaign_approval';
import './publishcampaign.css';
const PublishingCampaignError = (props) => {
  const headingStyle = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    color: '#000000',
    lineHeight: '27px',
    textAlign: 'center',
  };
  const paraStyle = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#6F6C90',
    lineHeight: '25.5px',
    textAlign: 'center',
  };
  return (
    <div className='publish-campaign-container'>
      <div className='loader-image-container'>
        <img src={Error} alt='' />
      </div>
      <div className='publish-campaign-description-container'>
        <Typography style={headingStyle}>
          Error Publishing Campaigns!
        </Typography>
        <Typography style={paraStyle}>
          There was an error while publishing these campaigns. Please check the
          inputs. If this issue persists, please contact the Pixis support team.
        </Typography>
        <PrimaryButton title='Go To Campaign Creation' onClick={() => {props.onClickHandler()}} />
      </div>
    </div>
  );
};

export default PublishingCampaignError;
