export const conversionLocation = {
  leads: [
    "Website",
    "App",
    "Instant forms",
    "Messenger",
    "Instant forms & Messenger",
    "Calls",
  ],
  awareness: ["FB Page"],
  traffic: ["Website", "App", "Messenger", "Whatsapp", "Calls"],
  engagement: ["Messaging apps", "On your ad", "Website", "App", "FB Page"],
  appPromotion: ["App"],
  sales: ["Website", "App", "Website & App", "Messenger", "Whatsapp", "Calls"],
};

export const allowedConversionLocation = {
  leads: ["Website", "Instant forms", "Messenger", "Instant forms & Messenger"],
  awareness: ["FB Page"],
  traffic: ["Website", "Messenger", "Whatsapp"],
  engagement: ["Website"],
  appPromotion: [],
  sales: ["Website", "Messenger"],
};

export const selectPixelPage = {
  leads: {
    Website: "pixel",
    "Instant forms": "page",
    Messenger: "page",
    "Instant forms & Messenger": "page",
  },
  awareness: {
    "FB Page": "page",
  },
  traffic: {
    Whatsapp: "page",
  },
  engagement: {
    Website: "pixel",
  },
  appPromotion: {},
  sales: {
    Website: "pixel",
    Messenger: "pixel",
  },
};

export const applicationStore = {
  leads: {
    Website: ["-"],
    App: ["Google play", "Games", "Custom"],
    "Instant forms": ["-"],
    Messenger: ["-"],
    "Instant forms & Messenger": ["-"],
    Calls: ["-"],
  },
  appPromotion: {
    App: [
      "Google play",
      "App store",
      "App store for iPad",
      "Windows Store",
      "Facebook Canvas",
      "Amazon Appstore",
      "Games",
      "Oculus App Store",
    ],
  },
  sales: {
    Website: ["-"],
    App: ["App Store"],
    "Website & App": ["-"],
    Messenger: ["-"],
    Whatsapp: ["-"],
    Calls: ["-"],
  },
  engagement: {
    messagingApps: ["FB Page"],
    "On your Ad": ["-"],
    Website: ["-"],
    App: ["App Store"],
    "FB Page": ["-"],
  },
  traffic: {
    Website: ["-"],
    App: ["App Store"],
    Messenger: ["-"],
    Whatsapp: ["-"],
    Calls: ["-"],
  },
  awareness: { "FB Page": ["-"] },
};

export const optimisationAdDelivery = {
  leads: {
    Website: [
      "Conversions",
      "Landing Page Views",
      "Link Clicks",
      "Daily Unique Reach",
      "Impressions",
    ],
    App: ["App events", "Daily Unique Reach", "Link Clicks"],
    "Instant forms": ["Leads", "Conversion Leads"],
    Messenger: ["Leads"],
    "Instant forms & Messenger": ["Leads"],
    Calls: ["Calls"],
  },
  appPromotion: {
    App: ["Value", "App events", "App installs", "Link Clicks"],
  },
  sales: {
    Website: [
      "Conversions",
      "Value",
      "Landing Page Views",
      "Link Clicks",
      "Daily Unique Reach",
      "Impressions",
    ],
    App: ["App events", "Daily Unique Reach", "Link Clicks"],
    "Website & App": ["Conversions"],
    Messenger: [
      "Conversions",
      "Conversations",
      "Link Clicks",
      "Daily Unique Reach",
      "Impressions",
    ],
    Whatsapp: [
      "Conversions",
      "Link Clicks",
      "Daily Unique Reach",
      "Impressions",
    ],
    Calls: ["Calls"],
  },
  engagement: {
    "Messaging apps": ["Conversions"],
    "On your ad": ["ThruPlay", "2-second continuous video views"],
    Website: [
      "Conversions",
      "Landing Page Views",
      "Link Clicks",
      "Daily Unique Reach",
      "Impressions",
    ],
    App: ["App clicks", "Daily Unique Reach", "Link Clicks"],
    "FB Page": ["Page likes"],
  },
  traffic: {
    Website: [
      "Landing Page Views",
      "Impressions",
      "Link Clicks",
      "Daily Unique Reach",
    ],
    App: ["Link Clicks", "Daily Unique Reach"],
    Messenger: ["Link Clicks", "Daily Unique Reach", "Impressions"],
    Whatsapp: ["Link Clicks", "Daily Unique Reach", "Impressions"],
    Calls: ["Link Clicks", "Calls"],
  },
  awareness: {
    "FB Page": [
      "Reach",
      "Impressions",
      "Ad recall lift",
      "Thruplay",
      "2 second continuous video views",
    ],
  },
};

export const cta = {
  leads: {
    Website: [
      "No button",
      "Listen Now",
      "Order Now",
      "Play Game",
      "Request Time",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
      "Apply Now",
      "Book Now",
      "Buy Tickets",
      "Contact Us",
      "Download",
      "Get Offer",
      "Get Quote",
      "Get Showtimes",
      "Learn more",
    ],
    App: [
      "Book Now",
      "Download",
      "Learn more",
      "Open Link",
      "Listen Now",
      "Start Order",
      "Order Now",
      "Use App",
      "Play Game",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
    "Instant forms": [
      "Apply Now",
      "Book Now",
      "Download",
      "Get Offer",
      "Get Quote",
      "Learn more",
      "Sign Up",
      "Subscribe",
    ],
    Messenger: [
      "Send Message",
      "Apply Now",
      "Book Now",
      "Contact Us",
      "Get Quote",
      "Learn more",
      "Order Now",
      "Shop now",
      "Sign Up",
      "Subscribe",
    ],
    "Instant forms & Messenger": [
      "Apply Now",
      "Book Now",
      "Download",
      "Get Offer",
      "Get Quote",
      "Learn more",
      "Sign Up",
      "Subscribe",
    ],
    Calls: ["Call now"],
  },
  appPromotion: {
    App: [
      "Book Now",
      "Download",
      "Learn more",
      "Listen Now",
      "Order Now",
      "Play Game",
      // "Install Now",
      "Use App",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
  },
  sales: {
    Website: [
      "No button",
      "Apply Now",
      "Book Now",
      "Buy Tickets",
      "Contact Us",
      "Download",
      "Get Offer",
      "Get Quote",
      "Get Showtimes",
      "Learn more",
      "Listen Now",
      "Order Now",
      "Play Game",
      "Request Time",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
    App: [
      "Book Now",
      "Download",
      "Learn more",
      "Open Link",
      "Listen Now",
      "Start Order",
      "Order Now",
      "Use App",
      "Play Game",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
    "Website & App": [
      "No button",
      "Send Message",
      "Send Message",
      "Apply Now",
      "Send WhatsApp Message",
      "Book Now",
      "Contact Us",
      "Download",
      "Get Offer",
      "Get Quote",
      "Get Showtimes",
      "Learn more",
      "Listen Now",
      "Order Now",
      "Play Game",
      "Request Time",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
    Messenger: [
      "Send Message",
      "Apply Now",
      "Book Now",
      "Contact Us",
      "Get Quote",
      "Learn more",
      "Order Now",
      "Play Game",
      "Shop now",
      "Sign Up",
      "Subscribe",
    ],
    Whatsapp: [
      "Apply Now",
      "Send WhatsApp Message",
      "Book Now",
      "Contact Us",
      "Get Quote",
      "Learn more",
      "Order Now",
      "Play Game",
      "Shop now",
      "Sign Up",
      "Subscribe",
    ],
    Calls: ["Call now"],
  },
  engagement: {
    "Messaging Apps": [
      "Send Message",
      "Apply Now",
      "Book Now",
      "Contact Us",
      "Get Quote",
      "Learn more",
      "Order Now",
      "Shop now",
      "Sign Up",
      "Subscribe",
    ],
    "On your Ad": [
      // "Call now",
      "Send Message",
      "Send WhatsApp Message",
      "Book Now",
      "Download",
      "Get Quote",
      "Get Showtimes",
      "Learn more",
      "Listen Now",
      "Order Now",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
    Website: [
      "No button",
      "Apply Now",
      "Book Now",
      "Buy Tickets",
      "Contact Us",
      "Download",
      "Get Offer",
      "Get Quote",
      "Get Showtimes",
      "Learn more",
      "Listen Now",
      "Order Now",
      "Play Game",
      "Request Time",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
    App: [
      "Book Now",
      "Download",
      "Learn more",
      "Open Link",
      "Listen Now",
      "Start Order",
      "Order Now",
      "Use App",
      "Play Game",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
    "FB Page": [],
  },
  traffic: {
    Website: [
      "No button",
      "Apply Now",
      "Book Now",
      "Contact Us",
      "Download",
      "Get Offer",
      "Get Quote",
      // "Get Access",
      "Get Showtimes",
      "Learn more",
      "Listen Now",
      "Order Now",
      "Request Time",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
    App: [
      "Book Now",
      "Download",
      "Learn more",
      "Open Link",
      "Listen Now",
      "Start Order",
      "Order Now",
      "Use App",
      "Play Game",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
    ],
    Messenger: [
      "Send Message",
      "Apply Now",
      "Book Now",
      "Contact Us",
      "Get Quote",
      // "Get Access",
      "Learn more",
      "Order Now",
      "Shop now",
      "Sign Up",
      "Subscribe",
    ],
    Whatsapp: [
      "Apply Now",
      "Send WhatsApp Message",
      "Book Now",
      "Contact Us",
      "Get Quote",
      // "Get Access",
      "Learn more",
      "Order Now",
      "Shop now",
      "Sign Up",
      "Subscribe",
    ],
    calls: ["Call now"],
  },
  awareness: {
    "FB Page": [
      // "Call now",
      // "Get Directions",
      // "Save",
      "Send Message",
      "Apply Now",
      "Send WhatsApp Message",
      "Book Now",
      "Contact Us",
      "Download",
      "Get Quote",
      "Get Showtimes",
      "Learn more",
      "Listen Now",
      "Order Now",
      "Request Time",
      // "See Menu",
      "Shop now",
      "Sign Up",
      "Subscribe",
      "Watch More",
      "No button",
    ],
  },
};

export const bidStrategy = {
  leads: ["Highest volume", "Cost per result", "Bid cap"],
  awareness: ["Highest volume", "Bid cap"],
  traffic: ["Highest volume", "Cost per result", "Bid cap"],
  engagement: ["Highest volume", "Cost per result", "Bid cap"],
  appPromotion: ["Highest volume", "Cost per result", "ROAS Goal", "Bid cap"],
  sales: ["Highest volume", "Cost per result", "ROAS Goal", "Bid cap"],
};

export const objectiveBackendMapping = {
  leads: "OUTCOME_LEADS",
  awareness: "OUTCOME_AWARENESS",
  traffic: "OUTCOME_TRAFFIC",
  engagement: "OUTCOME_ENGAGEMENT",
  appPromotion: "OUTCOME_APP_PROMOTION",
  sales: "OUTCOME_SALES",
};

export const objectiveFrontendMappiing = {
  OUTCOME_LEADS: "leads",
  OUTCOME_AWARENESS: "awareness",
  OUTCOME_TRAFFIC: "traffic",
  OUTCOME_ENGAGEMENT: "engagement",
  OUTCOME_APP_PROMOTION: "appPromotion",
  OUTCOME_SALES: "sales",
};

export const bidStrategyBackendMapping = {
  "Highest volume": "LOWEST_COST_WITHOUT_CAP",
  "Cost per result": "COST_CAP",
  "Bid cap": "LOWEST_COST_WITH_BID_CAP",
  "ROAS Goal": "LOWEST_COST_WITH_MIN_ROAS",
};

export const bidStrategyFrontendMapping = {
  LOWEST_COST_WITHOUT_CAP: "Highest volume",
  COST_CAP: "Cost per result",
  LOWEST_COST_WITH_BID_CAP: "Bid cap",
  LOWEST_COST_WITH_MIN_ROAS: "ROAS Goal",
};

export const optimizationGoalBackendMapping = {
  Conversions: "OFFSITE_CONVERSIONS",
  "Landing Page Views": "LANDING_PAGE_VIEWS",
  "Link Clicks": "LINK_CLICKS",
  // "Daily Unique Reach": "REACH",
  Impressions: "IMPRESSIONS",
  "App events": "DERIVED_EVENTS",
  Leads: "LEAD_GENERATION",
  "Conversion Leads": "QUALITY_LEAD",
  Calls: "QUALITY_CALL",
  Value: "VALUE",
  "App installs": "APP_INSTALLS",
  "Ad recall lift": "AD_RECALL_LIFT",
  Thruplay: "THRUPLAY",
  Reach: "REACH"
};
export const optimizationGoalFrontendMapping = {
  OFFSITE_CONVERSIONS: "Conversions",
  LANDING_PAGE_VIEWS: "Landing Page Views",
  LINK_CLICKS: "Link Clicks",
  // REACH: "Daily Unique Reach",
  IMPRESSIONS: "Impressions",
  DERIVED_EVENTS: "App events",
  LEAD_GENERATION: "Leads",
  QUALITY_LEAD: "Conversion Leads",
  QUALITY_CALL: "Calls",
  VALUE: "Value",
  APP_INSTALLS: "App installs",
  AD_RECALL_LIFT: "Ad recall lift",
  THRUPLAY: "Thruplay",
  REACH: "REACH"
};

export const destinationTypeBackendMapping = {
  Website: "WEBSITE",
  App: "APP",
  "Instant forms": "UNDEFINED",
  Messenger: "MESSENGER",
  "Instant forms & Messenger": "UNDEFINED",
  Calls: "UNDEFINED",
  "FB Page": "UNDEFINED",
};

export const destinationTypeFrontendMapping = {
  WEBSITE: "Website",
  APP: "App",
  UNDEFINED: "Instant forms",
  MESSENGER: "Messenger",
  // UNDEFINED: "Instant forms & Messenger",
  // UNDEFINED: "Calls",
  // FB_PAGE: "FB Page"
};

export const CTABackendMapping = {
  "Apply Now": "APPLY_NOW",
  "Book Now": "BOOK_TRAVEL",
  "Buy Tickets": "BUY_TICKETS",
  // "Call now": "BUY_TICKETS",
  "Contact Us": "CONTACT_US",
  Download: "DOWNLOAD",
  // "Get Access": "DOWNLOAD",
  // "Get Directions": "DOWNLOAD",
  "Get Offer": "GET_OFFER",
  "Get Quote": "GET_QUOTE",
  "Get Showtimes": "GET_SHOWTIMES",
  // "Install Now": "GET_SHOWTIMES",
  "Learn more": "LEARN_MORE",
  "Listen Now": "LISTEN_NOW",
  // "N/A on testing account": "LISTEN_NOW",
  "No button": "NO_BUTTON",
  "Open Link": "OPEN_LINK",
  "Order Now": "ORDER_NOW",
  "Play Game": "PLAY_GAME_ON_FACEBOOK",
  "Request Time": "REQUEST_TIME",
  // Save: "REQUEST_TIME",
  // "See Menu": "REQUEST_TIME",
  "Send Message": "MESSAGE_PAGE",
  "Send WhatsApp Message": "WHATSAPP_MESSAGE",
  "Shop now": "SHOP_NOW",
  "Sign Up": "SIGN_UP",
  "Start Order": "START_ORDER",
  Subscribe: "SUBSCRIBE",
  "Use App": "USE_APP",
  "Watch More": "WATCH_MORE",
};

export const CTAFrontendMapping = {
  APPLY_NOW: "Apply Now",
  BOOK_TRAVEL: "Book Now",
  BUY_TICKETS: "Buy Tickets",
  // BUY_TICKETS: "Call now",
  CONTACT_US: "Contact Us",
  DOWNLOAD: "Download",
  // DOWNLOAD: "Get Access",
  // DOWNLOAD: "Get Directions",
  GET_OFFER: "Get Offer",
  GET_QUOTE: "Get Quote",
  GET_SHOWTIMES: "Get Showtimes",
  // GET_SHOWTIMES: "Install Now",
  LEARN_MORE: "Learn more",
  LISTEN_NOW: "Listen Now",
  // LISTEN_NOW: "N/A on testing account",
  NO_BUTTON: "No button",
  OPEN_LINK: "Open Link",
  ORDER_NOW: "Order Now",
  PLAY_GAME_ON_FACEBOOK: "Play Game",
  REQUEST_TIME: "Request Time",
  // REQUEST_TIME: "Save",
  // REQUEST_TIME: "See Menu",
  MESSAGE_PAGE: "Send Message",
  WHATSAPP_MESSAGE: "Send WhatsApp Message",
  SHOP_NOW: "Shop now",
  SIGN_UP: "Sign Up",
  START_ORDER: "Start Order",
  SUBSCRIBE: "Subscribe",
  USE_APP: "Use App",
  WATCH_MORE: "Watch More",
};

// export const conversionEvents = [
//   "RATE",
//   "TUTORIAL_COMPLETION",
//   "CONTACT",
//   "CUSTOMIZE_PRODUCT",
//   "DONATE",
//   "FIND_LOCATION",
//   "SCHEDULE",
//   "START_TRIAL",
//   "SUBMIT_APPLICATION",
//   "SUBSCRIBE",
//   "ADD_TO_CART",
//   "ADD_TO_WISHLIST",
//   "INITIATED_CHECKOUT",
//   "ADD_PAYMENT_INFO",
//   "PURCHASE",
//   "LEAD",
//   "COMPLETE_REGISTRATION",
//   "CONTENT_VIEW",
//   "SEARCH",
//   "SERVICE_BOOKING_REQUEST",
//   "MESSAGING_CONVERSATION_STARTED_7D",
//   "LEVEL_ACHIEVED",
//   "ACHIEVEMENT_UNLOCKED",
//   "SPENT_CREDITS",
//   "LISTING_INTERACTION",
//   "D2_RETENTION",
//   "D7_RETENTION",
// ];

export const conversionEvents = {
  leads: [
    "SUBSCRIBE",
    "SUBMIT_APPLICATION",
    "START_TRIAL",
    "SEARCH",
    "SCHEDULE",
    "LEAD",
    "FIND_LOCATION",
    "CONTACT",
    "COMPLETE_REGISTRATION",
    "CONTENT_VIEW",
  ],
  sales: [
    "CONTENT_VIEW",
    "SUBSCRIBE",
    "START_TRIAL",
    "SEARCH",
    "PURCHASE",
    "INITIATED_CHECKOUT",
    "DONATE",
    "ADD_TO_WISHLIST",
    "ADD_TO_WISHLIST",
    "ADD_TO_CART",
    "ADD_PAYMENT_INFO",
    "COMPLETE_REGISTRATION",
  ],
  engagement: [
    "CONTENT_VIEW",
    "SUBSCRIBE",
    "SUBMIT_APPLICATION",
    "START_TRIAL",
    "SEARCH",
    "SCHEDULE",
    "FIND_LOCATION",
    "DONATE",
    "CUSTOMIZE_PRODUCT",
    "ADD_TO_WISHLIST",
    "CONTACT",
  ],
};
