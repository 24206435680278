import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  RESET_FAILURE,
  ALREADY_AUTHENTICATED,
} from "./enum";

const INITIAL_STATE = {
  isAuthenticated: false,
  loginFailure: false,
  loginFailureMessage: "",
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem("accessToken", action.payload);
      return { ...state };
    case ALREADY_AUTHENTICATED:
      return { ...state, isAuthenticated: true, permissions: action.payload };
    case LOGOUT:
      localStorage.clear();
      return { ...state, isAuthenticated: false };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        loginFailureMessage: action.payload,
      };
    case RESET_FAILURE:
      return { ...state, loginFailure: false };
    default:
      return state;
  }
};

export default reducer;
