import { Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import bpClientRoutePaths from "./constants/bpClientRoutePath";
import { useDispatch, useSelector } from "react-redux";
import { getFormDetailsReducer } from "../../api/clientforms/slice";

// pages
const HomePage = lazy(() => import("./HomePage/index"));

// nested routers
const ProposalRouter = lazy(() => import("./Proposal/Router"));
const IntakeFormRouter = lazy(() => import("./IntakeForm/Router"));
const StrategyDeckRouter = lazy(() => import("./StrategyDeck/Router"));
// layouts
const SideNavigationStepsLayout = lazy(() =>
  import("./layouts/SideNavigationStepsLayout")
);

export default function BPClientRootRouter() {
  const formId = useSelector((state) => state.forms?.form?.formId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFormDetailsReducer());
  }, [formId]);

  return (
    <Suspense fallback={<>loading...</>}>
      <Routes>
        <Route element={<SideNavigationStepsLayout />}>
          <Route
            path={bpClientRoutePaths.homePage.root}
            element={<HomePage />}
          />
          <Route
            path={`${bpClientRoutePaths.proposol.root}/*`}
            element={<ProposalRouter />}
          />
          <Route
            path={`${bpClientRoutePaths.intakeForm.root}/*`}
            element={<IntakeFormRouter />}
          />
          <Route
            path={`${bpClientRoutePaths.strategyDeck.root}/*`}
            element={<StrategyDeckRouter />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
