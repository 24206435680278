const BASE = "/bp-client";

const bpClientRoutePaths = /** @type {const} */ ({
  root: BASE,
  homePage: {
    fullbase: `${BASE}/homepage`,
    root: "homepage",
    sub: {},
  },
  readSharedAssets: {
    fullbase: `${BASE}/read-shared-assets`,
    root: "read-shared-assets", // should matches the end of fullbase
    sub: {},
  },
  proposol: {
    fullbase: `${BASE}/proposal`,
    root: "proposal", // should matches the end of fullbase
    sub: {
      proposalView: "",
      audit: "audit",
      suggestChanges: "suggest-changes",
    },
  },
  intakeForm: {
    fullbase: `${BASE}/intake-form`,
    root: "intake-form",
    sub: {
      review: "",
      basicInfo: "basicInformation",
      basicInfoWithQuestionNumber: "basicInformation/:questionNumber",
      ppc: "ppc",
      ppcWithQuestionNumber: "ppc/:questionNumber",
      fbTargetting: "fbTargetting",
      fbTargettingWithQuestionNumber: "fbTargetting/:questionNumber",
      googleTargetting: "googleTargetting",
      googleTargettingWithQuestionNumber: "googleTargetting/:questionNumber",
      aboutBusiness: "aboutBusiness",
      aboutBusinessWithQuestionNumber: "aboutBusiness/:questionNumber",
      brandInfo: "brandInfo",
      brandInfoWithQuestionNumber: "brandInfo/:questionNumber",
    },
  },
  strategyDeck: {
    fullbase: `${BASE}/strategy-deck`,
    root: `strategy-deck`, // should matches the end of fullbase
    sub: {
      introduction: "",
      google: "google",
      facebook: "facebook",
      suggestChanges: "suggest-changes",
    },
  },
});

export default bpClientRoutePaths;
