import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import GoToNextCompoButton from "../GoToNextCompoButton";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";
import "./Googleads.css";
import {
  updateCROInfoReducer,
  updateCROData,
} from "../../../api/accountManager/forms/slice";
import { goBackAmCompo } from "../../../api/accountManager/componentTracking/action";
import { updateCompoReduxAM } from "../../../api/accountManager/componentTracking/action";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";

const CroAdsSetup = () => {
  const dispatch = useDispatch();
  const form = useSelector((store) => store.form.form);
  const croInfo = useSelector((store) => store.form.croInfo);
  const [landingPageDesign, setLandingPageDesign] = useState("");
  const [thankYouPage, setThankYouPage] = useState("");
  const [wedgeMethodDesign, setWedgeMethodDesign] = useState("");
  const [popDesign, setPopDesign] = useState("");
  const [stickyBarDesign, setStickyBarDesign] = useState("");
  const [heatmapping, setHeatmapping] = useState(false);
  const [userRecordings, setUserRecordings] = useState(false);
  const [userSurveys, setUserSurveys] = useState(false);

  const croInfoRef = useRef({
    landingPageDesign: landingPageDesign,
    thankYouPage: thankYouPage,
    wedgeMethodDesign: wedgeMethodDesign,
    popDesign: popDesign,
    stickyBarDesign: stickyBarDesign,
    heatmapping: heatmapping,
    userRecordings: userRecordings,
    userSurveys: userSurveys,
  });
  const [landingPageDesignRadio, setLandingPageDesignRadio] = useState(false);
  const [thankYouPageRadio, setThankYouPageRadio] = useState(false);
  const [wedgeMethodDesignRadio, setWedgeMethodDesignRadio] = useState(false);
  const [popDesignRadio, setPopDesignRadio] = useState(false);
  const [stickyBarDesignRadio, setStickyBarDesignRadio] = useState(false);

  useEffect(() => {
    croInfoRef.current = {
      landingPageDesign: landingPageDesign,
      thankYouPage: thankYouPage,
      wedgeMethodDesign: wedgeMethodDesign,
      popDesign: popDesign,
      stickyBarDesign: stickyBarDesign,
      heatmapping: heatmapping,
      userRecordings: userRecordings,
      userSurveys: userSurveys,
    };
  }, [
    landingPageDesign,
    thankYouPage,
    wedgeMethodDesign,
    popDesign,
    stickyBarDesign,
    heatmapping,
    userRecordings,
    userSurveys,
  ]);

  useEffect(() => {
    dispatch(updateCompoReduxAM());
    if (form?.companyInfo?.implementCro === false) {
    }
  }, [form]);

  useEffect(() => {
    setLandingPageDesign(croInfo?.landingPageDesign || "");
    setThankYouPage(croInfo?.thankYouPage || "");
    setPopDesign(croInfo?.popDesign || "");
    setWedgeMethodDesign(croInfo?.wedgeMethodDesign || "");
    setStickyBarDesign(croInfo?.stickyBarDesign || "");
    setHeatmapping(croInfo?.heatmapping || false);
    setUserRecordings(croInfo?.userRecordings || false);
    setUserSurveys(croInfo?.userSurveys || false);

    if (croInfo?.landingPageDesign) {
      setLandingPageDesignRadio(true);
    }
    if (croInfo?.thankYouPage) {
      setThankYouPageRadio(true);
    }
    if (croInfo?.wedgeMethodDesign) {
      setWedgeMethodDesignRadio(true);
    }
    if (croInfo?.popDesign) {
      setPopDesignRadio(true);
    }
    if (croInfo?.stickyBarDesign) {
      setStickyBarDesignRadio(true);
    }

    croInfoRef.current = {
      landingPageDesign: croInfo?.landingPageDesign || "",
      thankYouPage: croInfo?.thankYouPage || "",
      wedgeMethodDesign: croInfo?.wedgeMethodDesign || "",
      popDesign: croInfo?.popDesign || "",
      stickyBarDesign: croInfo?.stickyBarDesign || "",
      heatmapping: croInfo?.heatmapping || false,
      userRecordings: croInfo?.userRecordings || false,
      userSurveys: croInfo?.userSurveys || false,
    };
  }, [croInfo]);
  useEffect(() => {
    return () => {
      dispatch(
        updateCROData({
          ...croInfo,
          ...croInfoRef.current,
        })
      );
    };
  }, []);
  const onClickHandler = () => {
    dispatch(
      updateCROInfoReducer({
        status: BACKEND_STATUS.COMPLETED,
        landingPageDesign: landingPageDesign,
        thankYouPage: thankYouPage,
        wedgeMethodDesign: wedgeMethodDesign,
        popDesign: popDesign,
        stickyBarDesign: stickyBarDesign,
        heatmapping: heatmapping,
        userRecordings: userRecordings,
        userSurveys: userSurveys,
      })
    );
  };
  return (
    <div className="cro_box">
      <div className="cro_setup_row">
        <div className="cro_setup_column">
          <div className="heading_setup">
            <span>Landing Page Design & Testing *</span>
            <div style={{ marginBottom: "3rem" }}>
              <RadioGroup
                defaultValue=""
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                value={landingPageDesignRadio}
                onChange={() =>
                  !landingPageDesign &&
                  setLandingPageDesignRadio(!landingPageDesignRadio)
                }
                style={{
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label="NO"
                />
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label="Other"
                />
                <TextField
                  variant="standard"
                  value={landingPageDesign}
                  disabled={!landingPageDesignRadio}
                  style={{
                    width: "300px",
                    fontFamily: "sans-serif",
                    color: "#7F7D87",
                  }}
                  onChange={(e) => setLandingPageDesign(e.target.value)}
                />
              </RadioGroup>
            </div>
          </div>

          <div className="heading_setup">
            <span>Confirmation/Thank you page *</span>
            <div style={{ marginBottom: "3rem" }}>
              <RadioGroup
                defaultValue=""
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                value={thankYouPageRadio}
                onChange={() =>
                  !thankYouPage && setThankYouPageRadio(!thankYouPageRadio)
                }
                style={{
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label="NO"
                />
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label="Other"
                />
                <TextField
                  variant="standard"
                  value={thankYouPage}
                  disabled={!thankYouPageRadio}
                  style={{
                    width: "300px",
                    fontFamily: "sans-serif",
                    color: "#7F7D87",
                  }}
                  onChange={(e) => setThankYouPage(e.target.value)}
                />
              </RadioGroup>
            </div>
          </div>

          <div className="heading_setup">
            <span>Wedge Method Design *</span>
            <div style={{ marginBottom: "3rem" }}>
              <RadioGroup
                defaultValue=""
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                value={wedgeMethodDesignRadio}
                onChange={() =>
                  !wedgeMethodDesign &&
                  setWedgeMethodDesignRadio(!wedgeMethodDesignRadio)
                }
                style={{
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label="NO"
                />
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label="Other"
                />
                <TextField
                  variant="standard"
                  value={wedgeMethodDesign}
                  disabled={!wedgeMethodDesignRadio}
                  style={{
                    width: "300px",
                    fontFamily: "sans-serif",
                    color: "#7F7D87",
                  }}
                  onChange={(e) => setWedgeMethodDesign(e.target.value)}
                />
              </RadioGroup>
            </div>
          </div>

          <div className="heading_setup">
            <span>Pop-up Design & Testing *</span>
            <div style={{ marginBottom: "3rem" }}>
              <RadioGroup
                defaultValue=""
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                value={popDesignRadio}
                onChange={() =>
                  !popDesign && setPopDesignRadio(!popDesignRadio)
                }
                style={{
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label="NO"
                />
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label="Other"
                />
                <TextField
                  variant="standard"
                  value={popDesign}
                  disabled={!popDesignRadio}
                  style={{
                    width: "300px",
                    fontFamily: "sans-serif",
                    color: "#7F7D87",
                  }}
                  onChange={(e) => setPopDesign(e.target.value)}
                />
              </RadioGroup>
            </div>
          </div>

          <div className="heading_setup">
            <span>Sticky Bar Design & Testing *</span>
            <div style={{ marginBottom: "3rem" }}>
              <RadioGroup
                defaultValue=""
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                value={stickyBarDesignRadio}
                onChange={() =>
                  !stickyBarDesign &&
                  setStickyBarDesignRadio(!stickyBarDesignRadio)
                }
                style={{
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label="NO"
                />
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label="Other"
                />
                <TextField
                  variant="standard"
                  value={stickyBarDesign}
                  disabled={!stickyBarDesignRadio}
                  style={{
                    width: "300px",
                    fontFamily: "sans-serif",
                    color: "#7F7D87",
                  }}
                  onChange={(e) => setStickyBarDesign(e.target.value)}
                />
              </RadioGroup>
            </div>
          </div>

          <div className="heading_setup">
            <span>Heatmapping (Hotjar) *</span>
            <div style={{ marginBottom: "3rem" }}>
              <RadioGroup
                value={heatmapping}
                onChange={(e) => setHeatmapping(eval(e.target.value))}
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                style={{
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label="YES"
                />
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label="NO"
                />
                {/* <TextField variant="standard" style={{width:'300px', fontFamily:'sans-serif',color:"#7F7D87"}}/> */}
              </RadioGroup>
            </div>
          </div>
        </div>

        <div className="vertical"></div>

        <div className="cro_setup_column">
          <div className="heading_setup">
            <span>User Surveys (Hotjar) *</span>
            <div style={{ marginBottom: "3rem" }}>
              <RadioGroup
                value={userSurveys}
                onChange={(e) => setUserSurveys(eval(e.target.value))}
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                style={{
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label="YES"
                />
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label="NO"
                />
                {/* <TextField variant="standard" style={{width:'300px', fontFamily:'sans-serif',color:"#7F7D87"}}/> */}
              </RadioGroup>
            </div>
          </div>

          <div className="heading_setup">
            <span>User Recordings (Hotjar) *</span>
            <div style={{ marginBottom: "3rem" }}>
              <RadioGroup
                value={userRecordings}
                onChange={(e) => setUserRecordings(eval(e.target.value))}
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                style={{
                  flexDirection: "row",
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  value={true}
                  control={<BpRadio />}
                  label="YES"
                />
                <FormControlLabel
                  value={false}
                  control={<BpRadio />}
                  label="NO"
                />
                {/* <TextField variant="standard" style={{width:'300px', fontFamily:'sans-serif',color:"#7F7D87"}}/> */}
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="footer_con">
          <button className="back" onClick={() => dispatch(goBackAmCompo())}>
            BACK
          </button>
          <GoToNextCompoButton title="Next" onClickHandler={onClickHandler} />
        </div>
      </div>
    </div>
  );
};
export default CroAdsSetup;
