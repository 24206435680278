import React, { useRef, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { mappedReduxType } from '../../../api/HDMClient/enum';
import './textfield.css';
import { updateHDMClientDetailsReducer } from '../../../api/HDMClient/action';
import { ControlPointSharp } from '@mui/icons-material';
// companyWebsite
const InputTextField = (props) => {
  console.log('#################', props);
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  useEffect(() => {
    if (
      !(
        (props.infoType === 'aboutBusiness' &&
          (props.objKey === 'topProducts' ||
            props.objKey === 'brandsInterests' ||
            props.objKey === 'keyCompetitors')) ||
        (props.infoType === 'facebookTargeting' &&
          props.objKey === 'AgeRange') ||
        ['emotionalResponse', 'feelWithBrand', 'engageWithBrand'].some(
          (elem) => elem === props.objKey
        ) ||
        ['productServiceName', 'productService'].some(
          (elem) => elem === props.quesType
        ) ||
        [
          'streetAddress',
          'city',
          'provinceOrState',
          'postalCode',
          'country',
        ].some((elem) => elem === props.objKey)
      )
    ) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  }, [props]);
  return (
    <>
      <TextField
        // id='standard-basic'
        inputRef={inputRef}
        type={props.type === 'number' ? 'number' : ''}
        error={props.error}
        placeholder={
          props.placeholder ? props.placeholder : 'Type your answer here...'
        }
        value={props.value}
        variant={props.variant ? props.variant : 'standard'}
        style={{
          marginBottom: '1rem',
          width: props.width,
          height: props.height ? props.height : '',
          animation: props.bounce && `${props.bounceAnimation} 0.9s both`,
          // '&::-webkit-input-placeholder': { color: 'blue' },
        }}
        multiline={props.multiline}
        className={props.error ? 'bounce-top' : ''}
        name='input'
        onKeyPress={(e) => props.onKeyPress(e)}
        size={props.size ? props.size : ''}
        onChange={(e) => {
          if (
            props.infoType === 'facebookTargeting' &&
            props.objKey === 'AgeRange'
          ) {
            if (props.input === 'min') {
              dispatch(
                updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
                  infoType: props.infoType,
                  key: 'min' + props.objKey,
                  value: e.target.value,
                })
              );
            } else {
              dispatch(
                updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
                  infoType: props.infoType,
                  key: 'max' + props.objKey,
                  value: e.target.value,
                })
              );
            }
          } else {
            let value = '';
            // props.setError(!props.error);
            if (
              props.infoType === 'aboutBusiness' &&
              (props.objKey === 'topProducts' ||
                props.objKey === 'brandsInterests')
            ) {
              props.arrayValue[props.arrayIndex] = e.target.value;
              value = props.arrayValue;
            } else if (
              props.infoType === 'aboutBusiness' &&
              props.objKey === 'keyCompetitors'
            ) {
              props.arrayValue[props.arrayIndex][props.field] = e.target.value;
              value = props.arrayValue;
            } else if (
              props.infoType === 'brandMessaging' &&
              [
                'benefitsOfProducts',
                'painPoints',
                'brandMessagingExampels',
              ].includes(props.objKey)
            ) {
              value = e.target.value.split(',');
            } else {
              value = e.target.value;
            }
            dispatch(
              updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
                infoType: props.infoType,
                key: props.objKey,
                value,
              })
            );
          }
        }}
        inputProps={{
          style: {
            paddingBottom: props.paddingBottom ? props.paddingBottom : 10,
            color: props.error ? '#800000' : '',
          },
        }}
      />
      {props.error && props.field === 'website' && (
        <p
          style={{
            position: 'absolute',
            right: 84,
            bottom: -24,
            color: 'red',
          }}
        >
          {props.errorText}
        </p>
      )}
      {props.error && props.quesType === 'productServiceName' && (
        <p
          style={{
            position: 'absolute',
            left: 28,
            bottom: -24,
            color: 'red',
          }}
        >
          {props.errorText}
        </p>
      )}
      {props.error && props.objKey === 'brandDescription' && (
        <p
          style={{
            position: 'absolute',
            left: 0,
            bottom: 36,
            color: 'red',
          }}
        >
          {props.errorText}
        </p>
      )}
      {props.error &&
        props.objKey === 'brandDescription' &&
        props.value.length === 0 && (
          <p
            style={{
              position: 'absolute',
              left: 0,
              bottom: 36,
              color: 'red',
            }}
          >
            Please enter the empty field
          </p>
        )}
      {props.error && props.quesType === 'productService' && (
        <p
          style={{
            position: 'absolute',
            right: 84,
            bottom: -24,
            color: 'red',
          }}
        >
          {props.errorText}
        </p>
      )}
      {/* {props.error &&
        props.objKey !== 'AgeRange' &&
        props.objKey !== 'brandsInterests' && (
          <p style={{ color: 'red' }}> {props.errorText} </p>
        )} */}
    </>
  );
};

export default InputTextField;
