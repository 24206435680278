import { Typography, Tooltip } from '@mui/material';
import React from 'react';
import CheckedIcon from './checked-icon.svg';

const Success = (props) => {
  return (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-arrow': {
            color: 'white',
          },
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'white',
            color: 'black',
            marginBottom: '20px',
            filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
            padding: '10px 10px 8px 12px',
          },
        },
      }}
      title={
        <>
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '20px',
              color: '#333333',
            }}
          >
            This campaign has been successfully published.
          </Typography>
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '20px',
              color: '#333333',
            }}
          >
            Campaign ID : <strong>{props.campaignId}</strong>
          </Typography>
        </>
      }
      arrow
      placement='right'
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4px 8px',
          gap: 6,
          width: 81,
          height: 24,
          background: '#E8F9F6',
          borderRadius: 6,
          cursor: 'auto',
        }}
      >
        <img
          src={CheckedIcon}
          alt=''
          style={{
            width: 12,
            height: 12,
          }}
        />
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '20px',
            color: '#1ABFA1',
          }}
        >
          Success
        </Typography>
      </div>
    </Tooltip>
  );
};

export default Success;