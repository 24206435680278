import { Route, Routes, useParams } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import ldAmRoutePaths from "./constants/ldAmRoutePaths";
import { setLocalStorage } from "../../api/localStorage/localStorage";

// pages
const ReadSharedAssets = lazy(() => import("./ReadSharedAssets/index"));
const Pricing = lazy(() => import("./Pricing/index"));
// const CompleteSharedAssets = lazy(() => import("./CompleteSharedAssets"));
const CampaignPreview = lazy(() => import("./CampaignPreview"));
const AnalyticDashboard = lazy(() => import("./AnalyticDashboard"));

// nested routers
const ClientOnboardingInputsRouter = lazy(() =>
  import("./ClientOnboardingInputs/Router")
);
const AuditForGoogleRouter = lazy(() => import("./AuditForGoogle/Router"));
const AuditForFacebookRouter = lazy(() => import("./AuditForFacebook/Router"));
const ProposalRouter = lazy(() => import("./Proposal/Router"));
const ReviewIntakeFormRouter = lazy(() => import("./ReviewIntakeForm/Router"));
const GoogleStrategyRouter = lazy(() => import("./StrategyGoogle/Router"));
const FacebookStrategyRouter = lazy(() => import("./StrategyFacebook/Router"));
const StrategyDeckRouter = lazy(() => import("./StrategyDeck/Router"));
const GoogleCampaignCreationRouter = lazy(() =>
  import("./GoogleCampaignCreation/Router")
);
const FacebookCampaignCreationRouter = lazy(() =>
  import("./FacebookCampaignCreation/Router")
);
const CreativeApprovalRouter = lazy(() => import("./CreativeApproval/Router"));
const AccessSharingRouter = lazy(() => import("./AccessSharing/Router"));

// layouts
const SideNavigationStepsLayout = lazy(() =>
  import("./layouts/SideNavigationStepsLayout")
);

export default function LDAMRootRouter() {
  const { formId } = useParams();
  useEffect(() => {
    if (formId) {
      setLocalStorage("formId", formId);
    }
  }, [formId]);
  return (
    <Suspense fallback={<>loading...</>}>
      <Routes>
        <Route element={<SideNavigationStepsLayout />}>
          <Route
            path={`${ldAmRoutePaths.onboardingForm.root}/*`}
            element={<ClientOnboardingInputsRouter />}
          />
          <Route path={ldAmRoutePaths.pricing.root} element={<Pricing />} />
          {/* <Route path={ldAmRoutePaths.readSharedAssets.root} element={<ReadSharedAssets />} /> */}
          <Route
            path={`${ldAmRoutePaths.accessSharing.root}/*`}
            element={<AccessSharingRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.auditForGoogle.root}/*`}
            element={<AuditForGoogleRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.auditForFacebook.root}/*`}
            element={<AuditForFacebookRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.proposol.root}/*`}
            element={<ProposalRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.reviewIntakeForm.root}/*`}
            element={<ReviewIntakeFormRouter />}
          />
          {/* <Route path={`${ldAmRoutePaths.completeSharedAssets.root}`} element={<CompleteSharedAssets />} /> */}
          <Route
            path={`${ldAmRoutePaths.strategyGoogle.root}/*`}
            element={<GoogleStrategyRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.strategyFacebook.root}/*`}
            element={<FacebookStrategyRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.strategyDeck.root}/*`}
            element={<StrategyDeckRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.campaignGoogle.root}/*`}
            element={<GoogleCampaignCreationRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.campaignFacebook.root}/*`}
            element={<FacebookCampaignCreationRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.campaignPreview.root}`}
            element={<CampaignPreview />}
          />
          <Route
            path={`${ldAmRoutePaths.creativeApproval.root}/*`}
            element={<CreativeApprovalRouter />}
          />
          <Route
            path={`${ldAmRoutePaths.analyticsDashboard.root}`}
            element={<AnalyticDashboard />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
