const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const facebook_icon = `${imageBaseUrl}/assets/f41f0717-c63e-43e0-897b-4378ef060381`;
export const google_icon = `${imageBaseUrl}/assets/0247b2ee-16f0-43f3-87f1-ccd8f0c9edd2`;
export const HDMAccountManager = `${imageBaseUrl}/assets/ec3d445d-ef4d-47e7-91e4-f02aef9aab94`;
export const information = `${imageBaseUrl}/assets/03aa993c-2b20-4ef4-9864-c993bc145107`;
export const network_icon = `${imageBaseUrl}/assets/6727ea47-f548-4055-a6b5-830e6f22c1c7`;
export const shopify_icon = `${imageBaseUrl}/assets/48f0c652-795e-4099-8893-c657cd13d9f6`;
export const verizon_logo = `${imageBaseUrl}/assets/48f0c652-795e-4099-8893-c657cd13d9f6`;

// HDM client and account manager nav icons
//HDM Client
export const campaignPreviewSuccess = `${imageBaseUrl}/assets/2a5d4a08-7ab0-4eb9-9401-98ce4a5f2cd7`;
export const campaignPreviewOngoing = `${imageBaseUrl}/assets/72b509e7-c00e-4e70-8f69-c0b9360c9a8c`;
export const campaignPreviewPending = `${imageBaseUrl}/assets/4b502a9c-f0d5-46cc-8c7b-66dbf45bdb49`;

//HDM AM
export const auditGoogleOngoing = `${imageBaseUrl}/assets/be5d1b2d-b4f5-4063-b469-e7c80b104b61`;
export const auditGooglePending = `${imageBaseUrl}/assets/fcb84588-3ac1-45aa-a20d-db8cc0322b5e`;
export const auditGoogleSuccess = `${imageBaseUrl}/assets/59cc7791-fe67-4982-9499-c26dd701e9f1`;

export const auditFacebookOngoing = `${imageBaseUrl}/assets/49062166-edbf-4f8f-b654-040842770abe`;
export const auditFacebookPending = `${imageBaseUrl}/assets/04e20bc3-bae8-4ee2-94b7-6bc6f2ba3797`;
export const auditFacebookSuccess = `${imageBaseUrl}/assets/fcfc6435-7e96-4e3b-a1d2-0baa5f36bb16`;

export const accountAnalysisOngoing = `${imageBaseUrl}/assets/7797e82f-fe0c-4366-9cfe-caad43690dd4`;
export const accountAnalysisPending = `${imageBaseUrl}/assets/9f980314-aafa-4647-84f7-5a74703ad3d6`;
export const accountAnalysisSuccess = `${imageBaseUrl}/assets/63479208-eabe-49c6-acde-0333e20b65f1`;

export const strategyCreationGoogleOngoing = `${imageBaseUrl}/assets/e35d5cdf-c3fa-4941-bafe-b573d90de006`;
export const strategyCreationGooglePending = `${imageBaseUrl}/assets/b842fa1d-8a0c-45f2-aa42-f6ba1d1491e7`;
export const strategyCreationGoogleSuccess = `${imageBaseUrl}/assets/bbaefc86-5e17-4198-b9bc-bbe570f8c988`;

export const strategyCreationFacebookOngoing = `${imageBaseUrl}/assets/b5a85122-9a94-40f9-9b70-19b862c90193`;
export const strategyCreationFacebookPending = `${imageBaseUrl}/assets/73dd1aa4-cd26-43dc-8154-9126d10adc39`;
export const strategyCreationFacebookSuccess = `${imageBaseUrl}/assets/a8d1a9a2-75fa-494f-840a-c582936d0056`;

export const campaignCreationGoogleOngoing = `${imageBaseUrl}/assets/93ceb73b-4e3a-4361-b43a-c0b8286eb855`;
export const campaignCreationGooglePending = `${imageBaseUrl}/assets/93425277-5048-4850-a827-13d6d5aa0f63`;
export const campaignCreationGoogleSuccess = `${imageBaseUrl}/assets/765cfbd8-d8cc-46b4-b16a-8d3ccfe1fc7c`;

export const campaignCreationFacebookOngoing = `${imageBaseUrl}/assets/ba979086-c1c2-4b9a-934c-a43bcf28133e`;
export const campaignCreationFacebookPending = `${imageBaseUrl}/assets/7eef0f6d-5ab5-455e-96c1-02d0751e3f0b`;
export const campaignCreationFacebookSuccess = `${imageBaseUrl}/assets/7da6dce6-eff1-4027-8fa9-d2f4f7a9fb75`;

export const campaignPreviewGoogleOngoing = `${imageBaseUrl}/assets/96ac4ec7-232a-455c-b1d7-4dff1abed8b1`;
export const campaignPreviewGooglePending = `${imageBaseUrl}/assets/c62f1dd6-a72a-4dc5-b57c-8fac9de0dd2e`;
export const campaignPreviewGoogleSuccess = `${imageBaseUrl}/assets/ad70400a-b615-4d87-ad86-06aea29a9c8b`;

export const campaignPreviewFacebookOngoing = `${imageBaseUrl}/assets/47642f0e-3b2b-4f06-920e-b2367e037fb3`;
export const campaignPreviewFacebookPending = `${imageBaseUrl}/assets/139b8417-efc7-4138-9c74-16f88245bcf2`;
export const campaignPreviewFacebookSuccess = `${imageBaseUrl}/assets/49c8b593-fd7d-4772-b844-8b5c37d7a958`;
//Common for client and AM
export const onboardingFormSuccess = `${imageBaseUrl}/assets/08e909eb-7821-4fbc-b793-edaef705d0c4`;
export const onboardingFormOngoing = `${imageBaseUrl}/assets/b5bf347d-0cea-4d0d-a963-2ddaff7dbdaf`;
export const onboardingFormPending = `${imageBaseUrl}/assets/757281e7-77eb-4179-93bb-7a5c328656b4`;

export const strategyDeckSuccess = `${imageBaseUrl}/assets/635e7fae-79ba-4091-82b4-d15efd7ea5f6`;
export const strategyDeckOngoing = `${imageBaseUrl}/assets/6fb369a5-f3d2-4f1d-b9c8-4463df886c1e`;
export const strategyDeckPending = `${imageBaseUrl}/assets/76d45c56-53ef-44df-aa55-0d720c9cbabb`;

export const analyticsDashboardSuccess = `${imageBaseUrl}/assets/f4d6294e-5a7f-48b8-9a93-d2ffca9e8ed9`;
export const analyticsDashboardOngoing = `${imageBaseUrl}/assets/11a78add-a453-4c6d-a085-c3434f5de18b`;
export const analyticsDashboardPending = `${imageBaseUrl}/assets/ae870e6f-85fd-455f-a533-2415ba565222`;

export const accessSharingSuccess = `${imageBaseUrl}/assets/8b78f6c9-939a-4e8f-990f-1b57cd062973`;
export const accessSharingOngoing = `${imageBaseUrl}/assets/a1628c0f-4821-4623-a6ba-47afdb1db71b`;
export const accessSharingPending = `${imageBaseUrl}/assets/77f34e97-955e-4529-8b22-f8b800e84ea1`;

export const onboardingDocSuccess = `${imageBaseUrl}/assets/a7120467-b969-4da7-bf2b-9a7f6f7e3b1c`;
export const onboardingDocOngoing = `${imageBaseUrl}/assets/72cf8dd4-658e-4f27-8f33-fc98417e8028`;
export const onboardingDocPending = `${imageBaseUrl}/assets/a8be7304-6b4e-4364-a9f8-05deec32ae96`;
