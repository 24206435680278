import InputTextField from "../../InputTextField/TextField";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { trash as trashIcon } from "../../../../assets/icons/common/common";
import { serviceDescValidator } from "../Validators/serviceDescValidator";
import { specialCharValidator } from "../Validators/specialCharValidator";
import { useState } from "react";
import CompetitorTextField from "../../InputTextField/CompetitorWebsiteTextField";
const ProductServiceInfo = ({
  value,
  objType,
  type,
  error,
  setProductInfo,
}) => {
  const dispatch = useDispatch();
  console.log("Descriptions : ", value);
  // const [error, setError] = useState(false);
  return (
    <>
      {value?.map((d, index) => {
        return (
          <div
            style={{
              display: "flex",
              gap: "2rem",
              marginBottom: "1.5rem",
              position: "relative",
            }}
            key={index}
          >
            <CompetitorTextField
              width="300px"
              placeholder="Product"
              objKey={objType + " " + index + " name"}
              infoType={type}
              value={d.name}
              error={specialCharValidator(d.name)}
              quesType="productServiceName"
              errorText={"Please enter valid characters"}
              setProductInfo={setProductInfo}
            />
            <CompetitorTextField
              width="300px"
              placeholder="Description"
              objKey={objType + " " + index + " description"}
              infoType={type}
              value={d.description}
              error={serviceDescValidator(d.description)}
              quesType="productService"
              errorText={"Min character limit : 30"}
              setProductInfo={setProductInfo}
            />
            {index > 0 && (
              <img
                src={trashIcon}
                alt="delete"
                onClick={
                  () => {
                    setProductInfo((prevState) => {
                      return {
                        ...prevState,
                        descriptions: prevState.descriptions.filter(
                          (_, nI) => index !== nI
                        ),
                      };
                    });
                  }
                  // dispatch({
                  //   type: "DELETE_SERVICE_INFORM_INTAKE_FORM_FIELD",
                  //   payload: index,
                  // })
                }
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        );
      })}
      <div
        style={{
          background: "#E3EEFF",
          borderRadius: 6,
          padding: "3px 9px",
          display: "inline-block",
        }}
      >
        <Typography
          style={{
            fontFamily: "Lato",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 11,
            color:
              value[value.length - 1]?.name &&
              !serviceDescValidator(value[value.length - 1]?.description)
                ? "#0869FB"
                : "gray",
            cursor:
              value[value.length - 1]?.name &&
              !serviceDescValidator(value[value.length - 1]?.description)
                ? "pointer"
                : "not-allowed",
          }}
          onClick={() =>
            value[value.length - 1].name &&
            !serviceDescValidator(value[value.length - 1]?.description)
              ? setProductInfo((prevState) => {
                  return {
                    ...prevState,
                    descriptions: [
                      ...prevState.descriptions,
                      {
                        name: "",
                        description: "",
                      },
                    ],
                  };
                })
              : ""
          }
        >
          + ADD FIELD
        </Typography>
      </div>
      {error && (
        <p
          style={{
            color: "red",
          }}
        >
          Please enter the empty field
        </p>
      )}
    </>
  );
};
export default ProductServiceInfo;
