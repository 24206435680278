import { useDispatch, useSelector } from 'react-redux';
import CommonGoogleAdsAccount from './CommonGoogleAdsAccount';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import hdmAmRoutePaths from '../../pages/hdm-am/constants/hdmAmRoutePaths';

export default function HDMGoogleAdsAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId, again } = useParams();

  const [toNavigate, setToNavigate] = useState(false);
  const form = useSelector((store) =>  store.hdmFormAm);

  useEffect(()=> {
    if(toNavigate && !again){
      navigate(
        generatePath(
          `${hdmAmRoutePaths.auditForGoogle.fullbase}/${hdmAmRoutePaths.auditForGoogle.sub.reviewPoints}`,
          { formId }
        )
      );
    }
  }, [toNavigate])

  const onHaveInsights = useCallback(() => {
    setToNavigate(true);
  }, [navigate]);
  
  const onGotInsights= () => {
    navigate(
      generatePath(
        `${hdmAmRoutePaths.auditForGoogle.fullbase}/${hdmAmRoutePaths.auditForGoogle.sub.reviewPoints}`,
        { formId }
      )
    );
  }
  return (
    <CommonGoogleAdsAccount
      form={form}
      onHaveInsights={onHaveInsights}
      onGotInsights={onGotInsights}
    />
  )
}