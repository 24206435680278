import { put, takeLatest, call, select } from "redux-saga/effects";
import { api } from "../index";
import { getLocalStorage } from "../localStorage/localStorage";
import { fetchAbTests, fetchAdCopies, fetchCreatives, fetchLandingPages, putAbTests, putAdCopies, putCreatives, putLandingPages, updateErrorState, updateSliceForAbTests, updateSliceState } from "./slice";

function* getAbTests() {
  const clientFormURI = `/client-forms/${getLocalStorage(
    "formId")}`;
  try {
    const response = yield call(api.get, clientFormURI);
    if (response) {
       const { creativeApproval } = response.data;
       console.log('data:', response.data);
       console.log('GET', creativeApproval);
       if (creativeApproval) yield put(updateSliceForAbTests(creativeApproval.abTests));
    }
  } catch (err) {
    console.log("Error:", err);
  }
}

function* putAbTest() {
  const clientFormURI = `/client-forms`;
  try {
    console.log('putabtest call');
    const client = yield select(state => state.client);
    const abTests = yield select((state) => state.creativeApproval.abTests);
      const _formId =
        (getLocalStorage("amSelectedFormId")
          ? getLocalStorage("amSelectedFormId")
          : getLocalStorage("formId"));
      const _brandId =
        (getLocalStorage("amSelectedBrandId")
          ? getLocalStorage("amSelectedBrandId")
          : getLocalStorage("brandId"));

    // add type field in Array objects before sending put request
    //  console.log(client._id, client.brandId, abTests);
    const creativeApproval = { abTests };
    const data = { creativeApproval, brandId: _brandId, _id: _formId };
    console.log('put ab-test:', data);

    const response = yield call(api.post, clientFormURI, data);
    if (response) {
        const { creativeApproval } = response.data
        yield put(updateSliceForAbTests(
            creativeApproval.abTests
        ));
    }
  } catch (err) {
    console.log(err);
  }
}

function* getCreativeObjects() {
  const creativeApprovalURI = `/client-forms/${getLocalStorage(
    "formId"
  )}/creative-approval`;
  try {
    const response = yield call(api.get, creativeApprovalURI);
    if (response) {
        const {adCopies, creatives, landingPages} = response.data;
      yield put(
        updateSliceState({
          adCopies: adCopies || [],
          creatives: creatives || [],
          landingPages: landingPages || [],
        })
      );
    }
  } catch (err) {
    console.log("Error:", err);
  }
}

function* putCreativeObjects() {
  const creativeApprovalURI = `/client-forms/${getLocalStorage(
    "formId"
  )}/creative-approval`;
  try {
    const creativeApproval = yield select((state) => state.creativeApproval);

    // add type field in Array objects before sending put request
    const adCopies = creativeApproval.adCopies.map(e => ({...e, type: 'AdCopy'}));
    const creatives = creativeApproval.creatives.map(e => ({...e, type: 'Creative'}));
    const landingPages = creativeApproval.landingPages.map(e => ({...e, type: 'LandingPage'}));
    console.log('creatives:', creatives);

    const data = {
      creativeObjects: [
        ...adCopies,
        ...creatives,
        ...landingPages,
      ],
    };
    const response = yield call(api.put, creativeApprovalURI, data);
    if (response) {
        const {adCopies, creatives, landingPages} = response.data;
      yield put(
        updateSliceState({
          adCopies: adCopies || [],
          creatives: creatives || [],
          landingPages: landingPages || [],
        })
      );
    }
  } catch (err) {
    console.log(err);
    yield put(updateErrorState());
  }
}

export default function* watchCreativeApproval() {
  yield takeLatest(fetchAdCopies.type, getCreativeObjects);
  yield takeLatest(putAdCopies.type, putCreativeObjects);
  yield takeLatest(fetchCreatives.type, getCreativeObjects);
  yield takeLatest(putCreatives.type, putCreativeObjects);
  yield takeLatest(fetchLandingPages.type, getCreativeObjects);
  yield takeLatest(putLandingPages.type, putCreativeObjects);
  yield takeLatest(fetchAbTests.type, getAbTests);
  yield takeLatest(putAbTests.type, putAbTest);
}
