import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button, Checkbox } from '@mui/material';
import { Close } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import image from './pmaxIcons/image20.png';
import playIcon from './pmaxIcons/play-icon.svg';
const VideoLibraryModal = ({ setModal, handleAdd, videoAssetLibrary }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 838,
    bgcolor: 'background.paper',
    boxShadow: 24,
    padding: '16px 0 0 0',
    // height: '591px',
    borderRadius: '8px',
    outline: 'none',
  };

  const [selectedVideo, setSelectedVideo] = useState(() =>
    videoAssetLibrary.map((asset) => ({ ...asset, checked: false }))
  );

  const handleClick = (index) => {
    const newVideoAsset = selectedVideo.slice();
    newVideoAsset[index] = {
      ...newVideoAsset[index],
      checked: !newVideoAsset[index].checked,
    };
    setSelectedVideo(newVideoAsset);
  };

  const onAdd = () => {
    const selected = selectedVideo.filter((video) => video.checked === true);

    const newSelected = selected.map((video) => ({
      id: video.ytVideoId,
      title: video.ytVideoTitle,
      thumbnailUrl: video.ytVideoThumbnailUrl,
      duration: video.ytVideoDuration,
    }));

    handleAdd(newSelected);
  };

  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='video-library-modal'
    >
      <Box sx={style}>
        <Close
          onClick={() => setModal(false)}
          style={{
            position: 'absolute',
            top: '1.30rem',
            right: '1.5rem',
            cursor: 'pointer',
            background: '#F2F2F2',
            borderRadius: 4,
          }}
        />
        <div
        //   style={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     height: '100%',
        //     gap: '0.5rem',
        //   }}
        >
          <div
            style={{
              padding: '0 0 1rem 1.5rem',
              borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
              width: '100%',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 20,
                color: '#1C1E21',
              }}
            >
              Library
            </Typography>
          </div>
          <div className='video-list-container'>
            <>
              {videoAssetLibrary.map((video, index) => (
                <>
                  <div className='video-list'>
                    <div className='video'>
                      <img src={video.ytVideoThumbnailUrl} alt='video' />
                      <img src={playIcon} alt='play' className='play-icon' />
                      <Checkbox
                        className='checkbox'
                        checked={video.checked}
                        onClick={() => handleClick(index)}
                      />
                    </div>
                    <div className='video-details'>
                      <div className='name'>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: 12,
                            color: '#7B7B7B',
                          }}
                        >
                          Name:
                        </Typography>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 12,
                            color: '#7B7B7B',
                          }}
                        >
                          {video.ytVideoTitle}
                        </Typography>
                      </div>
                      <div className='link'>
                        <div className='link-details'>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: 12,
                              color: '#7B7B7B',
                            }}
                          >
                            URL:
                          </Typography>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontSize: 12,
                              color: '#0869FB',
                              textDecoration: 'underline',
                            }}
                          >
                            {`https://www.youtube.com/watch?v=${video.ytVideoId}`}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                  {index === videoAssetLibrary.length - 1 &&
                    (videoAssetLibrary.length - 1) % 2 === 0 && (
                      <div
                        style={{
                          width: 349,
                          height: 199,
                        }}
                      ></div>
                    )}
                </>
              ))}
            </>
          </div>

          <div
            style={{
              //   margin: '2rem 0',
              //   paddingTop: '2rem',
              display: 'flex',
              justifyContent: 'end',
              gap: '1.5rem',
              alignItems: 'center',
              height: 71,
              background: '#FFFFFF',
              borderRadius: '0px 0px 8px 8px',
              boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.02)',
              marginRight: 23,
            }}
          >
            {/* onClick={() => setModal(false)} */}
            <Button
              style={{
                width: '81px',
                height: '40px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                textTransform: 'capitalize',
                background: '#0869FB',
              }}
              onClick={() => onAdd()}
            >
              Add
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default VideoLibraryModal;
