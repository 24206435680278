import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { useGetClientForms } from '../../../services/clientForms';
import { useGetFBCreativeResources } from '../../../services/creative-resources-fb-ads';
import { useGetFBGaiResources } from '../../../services/facebook-gai-resources';
import { usePollAdset, usePutFbAdsCampaignCreation } from '../../../services/fb-campaign-creation';
import { usePostGenerateCampaignAdset, usePostGenerateCampaignAdsetById } from '../../../services/targeting-keywords-fb-ads';
import BpRadio from '../../ClientDashboard/CustomRadio/Radio';
import { createNewAudienceAdsetObject, createNewLookalikeAdsetObject, randomisedFacebookCreativeAds, randomisedFacebookGaiAds } from '../../FacebookStrategyCreation/AdsetLevel/utils';
import { ADSET_CRON_STATUS, STRATEGY_FLOW_BACKEND } from '../../FacebookStrategyCreation/utils';
import errorIcon from '../../PMaxStrategyCreation/pmaxIcons/error-icon.svg';
import AudienceTargettingFields from './AudienceTargettingFields';
import FailedGeneratingDataView from './FailedGeneratingDataView';
import InterestKeywordsFields from './InterestKeywordsFields';
import LoaderView from './LoaderView';
import './campaign-fill-modal.scss';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 691,
  bgcolor: 'background.paper',
  boxShadow: '12px 12px 48px rgba(80, 80, 159, 0.04)',
  //   p: 3,
  //   height: '380px',
  borderRadius: '8px',
  outline: 'none',
};

const fontStyleRadioButtonLabel = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: 15,
  lineHeight: '19px',

  color: '#333333',
};

const ADSET_GENERATION_TYPE = {
  MANUAL: "MANUAL",
  AI: "AI",
};

const AI_ADSET_TYPE = {
  KEYWORDS: "KEYWORD",
  AUDIENCE: "AUDIENCE",
};
export const NULL_ADSETS = [];
const NULL_CREATIVE_ADS = [];
const NULL_GAI_ADS = [];
const NULL_CLIENT_FORMS = {};

const CreateAdsetModal = ({
  shouldCreate,
  formId,
  onAddNewAdset,
  onClose,
  mountNodeId,
  campaignId,
  updateAdset,
  adsetNumber,
  setModalConfirmed,
  adsetCount,
  adset,
  websiteUrl = "",
  objective = 'OUTCOME_LEADS',
  setAdsetCount = () => { },
}) => {
  // ------------------ start of create logic --------------------------

  const [selectedAdsetGenerationType, setselectedAdsetGenerationType] = useState(ADSET_GENERATION_TYPE.MANUAL);
  const [selectedAiAdsetType, setSelectedAiAdsetType] = useState(AI_ADSET_TYPE.KEYWORDS);
  const [error, setError] = useState('');
  const [interestKeywords, setInterestKeywords] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState('');

  const clearState = () => {
    setselectedAdsetGenerationType(ADSET_GENERATION_TYPE.MANUAL);
    setSelectedAiAdsetType(AI_ADSET_TYPE.KEYWORDS);
    setInterestKeywords([]);
    setSelectedAudience("");
  };

  // api hits
  const { data: clientForms = NULL_CLIENT_FORMS } = useGetClientForms(formId);
  const { fbStrategyFlow } = clientForms;

  const { data: fbCreativeAds = NULL_CREATIVE_ADS } = useGetFBCreativeResources(formId, {
    select: (responseData) => responseData.ads,
    enabled: fbStrategyFlow === STRATEGY_FLOW_BACKEND.GUIDED,
  });

  const { data: fbGaiAdCopySets = NULL_GAI_ADS } = useGetFBGaiResources({ formId, strategyId: campaignId }, {
    select: (responseData) => responseData.data,
    enabled: fbStrategyFlow === STRATEGY_FLOW_BACKEND.SMART,
  });

  const { mutate: triggerGenerateAdsetCron, isLoading: isGererateAdsetApiInProgress } = usePostGenerateCampaignAdset(formId, {
    onSuccess(data) {
      // queryClient.invalidateQueries({ queryKey: getFBAdsCampaignCreationByIdQueryKey(formId, campaignId)});
      onClose();
      clearState();
      onAddNewAdset(data);
      setAdsetCount(data.adsetNumber);
    },
  });

  const { mutateAsync: mutatePutCampaign } = usePutFbAdsCampaignCreation(formId, {
    onSuccess: (data) => {
      const { adsets } = data;
      let updatedAdset = adsets?.find(
        ad => ad.adsetNumber === adsetNumber
      );
      
      if(!updatedAdset) updatedAdset = adsets?.length ? adsets[adsets.length-1] : {};
      updateAdset({ ...updatedAdset, savedStatus: true });
    }
  });

  // callbacks
  const getRandomisedAds = ({
    [STRATEGY_FLOW_BACKEND.SMART]: () => randomisedFacebookGaiAds(fbGaiAdCopySets),
    [STRATEGY_FLOW_BACKEND.GUIDED]: () => randomisedFacebookCreativeAds(fbCreativeAds),
  })[fbStrategyFlow];

  // callbacks
  const onCreateLookalikeAdset = (partialAdset = {}) => onAddNewAdset(createNewLookalikeAdsetObject({
    facebookAds: getRandomisedAds?.() || [],
    ...partialAdset,
  }));
  const onCreateAdsetWithAudience = (audience, partialAdset = {}) => onAddNewAdset(createNewAudienceAdsetObject({
    facebookAds: getRandomisedAds?.() || [],
    audience,
    ...partialAdset,
  }));

  const onCreateConfirm = () => {
    // 1. manual
    if (selectedAdsetGenerationType === ADSET_GENERATION_TYPE.MANUAL) {
      const newAdset = onCreateLookalikeAdset(adset);
      mutatePutCampaign({
        campaignId,
        adsetCount: adsetCount + 1,
        adsets: [{ ...newAdset, facebookAds: newAdset?.facebookAds?.map(ad => ({ ...ad, websiteUrl, displayUrl: websiteUrl })) }]
      }).then(() => {
        onClose();
        clearState();
        return;
      }).finally(() => {
        return;
      })
      return;
    }

    // 2. ai and audience
    if (selectedAiAdsetType === AI_ADSET_TYPE.AUDIENCE) {
      if (!selectedAudience) {
        setError("Please select a audience to confirm");
        return;
      }
      setError("");
      const newAdset = onCreateAdsetWithAudience(selectedAudience, adset);
      mutatePutCampaign({
        campaignId,
        adsetCount: adsetCount + 1,
        adsets: [{ ...newAdset, facebookAds: newAdset?.facebookAds?.map(ad => ({ ...ad, websiteUrl, displayUrl: websiteUrl })) }]
      }).then(() => {
        onClose();
        clearState();
      })
      return;
    }

    // 3. ai and keywords
    if (interestKeywords.length < 4) {
      setError('Please add atleast 4 interest keywords.');
      return;
    }
    else if (interestKeywords.length >= 4) {
      triggerGenerateAdsetCron({
        campaignId,
        adset: {
          ...adset,
          keywords: interestKeywords
        },
      });
      setModalConfirmed(true);
      setError('');
    }
  };

  // --------------------- end of create logic -----------------

  // --------------------- start of edit/try-again logic ----------------- 

  const [isInTryAgainMode, setIsInTryAgainMode] = useState(false);

  const {
    mutate: retriggerGenerateKeywordsForAdsetCron,
    isLoading: isRetryGenerateKeywordsApiInProgress
  } = usePostGenerateCampaignAdsetById({ formId, campaignId }, {
    onSuccess(data) {
      if (!data) return;
      updateAdset(data);
      setIsInTryAgainMode(false);
      setModalConfirmed(false);
      clearState();
    },
  });

  const onTryAgainConfirm = () => {
    // reject other cases just to confirm
    if (!isInTryAgainMode ||
      ADSET_GENERATION_TYPE.MANUAL === selectedAdsetGenerationType ||
      AI_ADSET_TYPE.AUDIENCE === selectedAiAdsetType
    ) return;

    if (interestKeywords.length < 4) {
      setError('Please add atleast 4 interest keywords.');
      return;
    }
    retriggerGenerateKeywordsForAdsetCron({
      adsetId: failedAdset?.id,
      adset: {
        adsetName: failedAdset?.adsetName,
        keywords: interestKeywords
      },
    });
    setError('');
    setModalConfirmed(true);
  };

  // --------------------- end of edit/try-again logic ----------------- 

  // ---------------------- start of generating and failed logic -------------------

  // const adsets = useWatch({ control, name: "adsets", defaultValue: NULL_ADSETS });
  const runningAdset = useMemo(
    () => [ADSET_CRON_STATUS.PENDING, ADSET_CRON_STATUS.RUNNING].includes(adset?.cronStatus) ? adset : false,
    [adset],
  );
  const failedAdset = useMemo(
    () => (adset?.cronStatus === ADSET_CRON_STATUS.FAILED) ? adset : false,
    [adset],
  );

  usePollAdset(
    { adsetId: runningAdset?.id, formId, campaignId },
    {
      enabled: !!runningAdset,
      onSuccess(data) {
        // 1. if still running, then don nothing
        if (!data || [ADSET_CRON_STATUS.PENDING, ADSET_CRON_STATUS.RUNNING].includes(data.cronStatus)) return;

        // 2. if done or failed, update the form state making runningAdset and failedAdset recompute,
        // resulting the closing of popup or opening of failed UI; 
        updateAdset(data);
        setModalConfirmed(false);
      },
    },
  );

  const onTryAgain = () => {
    setIsInTryAgainMode(true);
    setselectedAdsetGenerationType(ADSET_GENERATION_TYPE.AI);
    setSelectedAiAdsetType(AI_ADSET_TYPE.KEYWORDS);
    setInterestKeywords(failedAdset.cronInputs?.keywords);
  };

  // ---------------------- end of generating and failed logic -------------------

  // flags
  const isGenerating = !!runningAdset;
  const isGeneratingFailed = !!failedAdset;

  const isCloseDisabled = isGenerating || isGeneratingFailed;
  const isModalOpen = shouldCreate || isGenerating || isGeneratingFailed;
  const isConfirmDisabled = isGererateAdsetApiInProgress || isRetryGenerateKeywordsApiInProgress;

  console.log('isGeneratingFailed:', isGeneratingFailed);
  console.log('isTryAgaingMode:', isInTryAgainMode);

  return (
    <Modal
      open={isModalOpen}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      id='campaign-fill-modal'
      container={() => document.getElementById(`fb-campaign-adset-${mountNodeId}`)}
      style={{
        position: 'absolute',
        // height: '112%',
        // overflow: 'hidden',
      }}
      slotProps={{
        backdrop: {
          style: {
            position: 'absolute',
            height: '100vh',
          },
        },
      }}
      onClose={isCloseDisabled ? undefined : onClose}
    >
      <Box sx={style}>
        {isGenerating
          ? <LoaderView /> :
          (isGeneratingFailed && !isInTryAgainMode)
            ? <FailedGeneratingDataView onTryAgain={onTryAgain} />
            : (
              <>
                <div className='manual-selection-container'>
                  <BpRadio
                    onChange={(e) => setselectedAdsetGenerationType(e.target.value)}
                    checked={selectedAdsetGenerationType === ADSET_GENERATION_TYPE.MANUAL}
                    value={ADSET_GENERATION_TYPE.MANUAL}
                    name='campaign-guideline-flow'
                    disabled={isInTryAgainMode}
                  />
                  <Typography style={fontStyleRadioButtonLabel}>
                    Enter all the ad copies of this adset yourself.
                  </Typography>
                </div>
                <div className='ai-selection-container'>
                  <div className='radio-selection'>
                    <BpRadio
                      onChange={(e) => setselectedAdsetGenerationType(e.target.value)}
                      checked={selectedAdsetGenerationType === ADSET_GENERATION_TYPE.AI}
                      value={ADSET_GENERATION_TYPE.AI}
                      name='campaign-guideline-flow'
                    />
                    <Typography style={fontStyleRadioButtonLabel}>
                      {' '}
                      Use our AI to generate multiple ad copies.
                    </Typography>
                  </div>
                  <div
                    className='ai-form-fields'
                    style={{
                      opacity: selectedAdsetGenerationType === ADSET_GENERATION_TYPE.MANUAL ? '0.4' : '1',
                    }}
                  >
                    <div
                      className='form-types'
                      style={{
                        justifyContent: 'center',
                        gap: 48,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <BpRadio
                          name='campaignTypes'
                          value='brand'
                          checked={selectedAiAdsetType === AI_ADSET_TYPE.KEYWORDS}
                          onChange={() => {
                            setSelectedAiAdsetType(AI_ADSET_TYPE.KEYWORDS);
                            setError("");
                          }}
                          disabled={selectedAdsetGenerationType === ADSET_GENERATION_TYPE.MANUAL}
                        />{' '}
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: '16px',
                            color:
                              selectedAiAdsetType === AI_ADSET_TYPE.KEYWORDS ? '#0869FB' : '#585969',
                          }}
                        >
                          Interest Keywords
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <BpRadio
                          name='campaignTypes'
                          value='brand'
                          checked={selectedAiAdsetType === AI_ADSET_TYPE.AUDIENCE}
                          onChange={() => {
                            setSelectedAiAdsetType(AI_ADSET_TYPE.AUDIENCE);
                            setError("");
                          }}
                          disabled={selectedAdsetGenerationType === ADSET_GENERATION_TYPE.MANUAL || isInTryAgainMode}
                        />{' '}
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: '16px',
                            color:
                              selectedAiAdsetType === AI_ADSET_TYPE.AUDIENCE ? '#0869FB' : '#585969',
                          }}
                        >
                          Audience Targetting
                        </Typography>
                      </div>
                    </div>
                    {selectedAiAdsetType === AI_ADSET_TYPE.KEYWORDS ? (
                      <InterestKeywordsFields
                        setError={setError}
                        disabled={selectedAdsetGenerationType === ADSET_GENERATION_TYPE.MANUAL}
                        interestKeywords={interestKeywords}
                        setInterestKeywords={setInterestKeywords}
                      />
                    ) : (
                      <AudienceTargettingFields
                        disabled={selectedAdsetGenerationType === ADSET_GENERATION_TYPE.MANUAL}
                        audience={selectedAudience}
                        setAudience={setSelectedAudience}
                        formId={formId}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: error ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {error && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 27,
                        gap: 4,
                        marginTop: -14,
                      }}
                    >
                      <img
                        src={errorIcon}
                        alt='error'
                        style={{
                          width: 16,
                          height: 16,
                        }}
                      />
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: 14,
                          lineHeight: '22px',
                          color: '#F27976',
                        }}
                      >
                        {error}
                      </Typography>
                    </div>
                  )}
                  <Button
                    style={{
                      width: '102px',
                      height: '40px',
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      textTransform: 'capitalize',
                      background: '#0869FB',
                      marginBottom: 15,
                      marginRight: 18,
                    }}
                    sx={{
                      "&:disabled": {
                        opacity: 0.5,
                      },
                    }}
                    onClick={isInTryAgainMode ? onTryAgainConfirm : onCreateConfirm}
                    disabled={isConfirmDisabled}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
      </Box>
    </Modal>
  );
};

export default CreateAdsetModal;
