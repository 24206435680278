import { Button } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateHDMClientDetailsReducer } from "../../../api/HDMClient/action";
import { mappedReduxType } from "../../../api/HDMClient/enum";
import "./HDMInputComponent.css";
import {Edit as edit} from "../../../assets/icons/common/common";

const InputComponent = (props) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState(props.value);
  return (
    <div className="row" style={{ display: "flex", alignItems: "center" }}>
      {props.value ? (
        <>
          <div
            style={{
              height: 32,
              width: 155,
              display: "flex",
              alignItems: "center",
            }}
          >
            {input}
          </div>
          <img
            src={edit}
            style={{ width: 15, marginTop: 2, cursor: "pointer" }}
            onClick={() => {
              if(props.agencyType === "LD"){
                dispatch({
                  type:"UPDATE_ACCESS_SHARING_LD_CLIENT",
                  payload:{
                    infoType: props.infoType,
                    key: props.objKey,
                    key2: props.objKey2,
                    key3: props.objKey3,
                    value: "",
                    isComplete:true
                  }
                })
              }else{
                dispatch(
                  updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
                    infoType: props.infoType,
                    key: props.objKey,
                    key2: props.objKey2,
                    key3: props.objKey3,
                    value: "",
                    isComplete:true
                  })
                );
              }
            }}
          />
        </>
      ) : (
        <>
          <input
            value={input}
            placeholder="Enter Account ID"
            className="input_field"
            onChange={(e) => {
              setInput(e.target.value);
            }}
          />
          <Button
            style={{
              minWidth: "29px",
              height: "20px",
              marginTop: 2,
              fontSize: "8px",
              background: "#EAF2FF",
              borderRadius: "4px",
            }}
            onClick={() => {
              if(props.agencyType === "LD"){
                dispatch({
                  type:"UPDATE_ACCESS_SHARING_LD_CLIENT",
                  payload:{
                    infoType: props.infoType,
                    key: props.objKey,
                    key2: props.objKey2,
                    key3: props.objKey3,
                    value: input,
                    isComplete:true
                  }
                })
              }else{
                dispatch(
                  updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
                    infoType: props.infoType,
                    key: props.objKey,
                    key2: props.objKey2,
                    key3: props.objKey3,
                    value: input,
                    isComplete:true
                  })
                );
              }
            }}
          >
            SAVE
          </Button>
        </>
      )}
    </div>
  );
};
export default InputComponent;
