import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
// import abIcon from '../../../assets/icons/creative/ab-test-icon.svg';
import './abtests.scss';
import MessagingText from './MessagingText';
import FormTests from './FormTests';
import AdditionalTests from './AdditionalTests';
// import readIcon from '../../../assets/icons/creative/read-icon.svg';
// import writeIcon from '../../../assets/icons/creative/write-icon.svg';
// import selectedReadIcon from '../../../assets/icons/creative/selected-read-icon.svg';
// import selectedWriteIcon from '../../../assets/icons/creative/selected-write-icon.svg';
import {
    readIcon,
    writeIcon,
    selectedReadIcon,
    selectedWriteIcon,
    abIcon
    
} from '../../../assets/icons/creative/creative';
import { useSelector } from 'react-redux';
import { AB_TEST_SECTION_ENUM } from '../../../api/creativeApproval/enums';
import { selectAbTestSection } from '../../../utils/creativeApprovalUtil';
const ABTests = ({ isClient, abTests, onUpdateHandler, read, isRead }) => {
  const messagingTests = useSelector((state) =>
    selectAbTestSection(state, AB_TEST_SECTION_ENUM.MESSAGING_TESTS)
  );
  const formTests = useSelector((state) =>
    selectAbTestSection(state, AB_TEST_SECTION_ENUM.FORM_TESTS)
  );
  const additionalTests = useSelector((state) =>
    selectAbTestSection(state, AB_TEST_SECTION_ENUM.ADDITIONAL_TESTS)
  );

  console.log('at', abTests);
  return (
      <div 
        className='intro_strategy_main'
        style={{
            position: 'relative',
            overflowY: 'scroll',
            height: 'calc(100vh - 72px)',
            paddingBottom: '44px'
        }}
      >
      <div className='abtests-header-container'>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 22,
          }}
        >
          <div className='adsets-logo-image-container'>
            <img src={abIcon} alt='abIcon' />
          </div>
          <Typography
            style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 20,
              lineHeight: '52px',
            }}
          >
            Types of Testing We Do
          </Typography>
        </div>
        {!isClient && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 16,
              paddingRight: 16,
            }}
          >
            <div
              className='read-write-icon'
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                className='read-icon'
                style={{
                  width: 40,
                  height: 40,
                  border: read ? '1px solid #0869FB' : '1px solid #CCCCCC',
                  borderRadius: '8px 0px 0px 8px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: read ? 'rgba(8, 105, 251, 0.07)' : '',
                }}
                onClick={() => isRead(true)}
              >
                <img src={read ? selectedReadIcon : readIcon} alt='' />
              </div>
              <div
                className='write-icon'
                style={{
                  width: 40,
                  height: 40,
                  border: !read ? '1px solid #0869FB' : '1px solid #CCCCCC',
                  borderRadius: '0px 8px 8px 0px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: !read ? 'rgba(8, 105, 251, 0.07)' : '',
                }}
                onClick={() => isRead(false)}
              >
                <img src={!read ? selectedWriteIcon : writeIcon} alt='' />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='abtests-tests-types'>
        <MessagingText
          read={read}
          isClient={isClient}
          questions={messagingTests.questions}
          onUpdateHandler={onUpdateHandler}
        />
        <FormTests
          read={read}
          isClient={isClient}
          questions={formTests.questions}
          onUpdateHandler={onUpdateHandler}
        />
        <AdditionalTests
          read={read}
          isClient={isClient}
          questions={additionalTests.questions}
          onUpdateHandler={onUpdateHandler}
        />
      </div>
    </div>
  );
};

export default ABTests;
