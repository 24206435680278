import { IconButton, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState } from 'react';
import AddIcon from './AddIcon';
import './common-fields-form.scss';
import crossIcon from './cross-icon.svg';

const InterestKeywordsFields = ({
  disabled,
  interestKeywords,
  setInterestKeywords,
}) => {
  const [keywordVal, setKeywordVal] = useState('');
  const Chip = ({ keyword, id }) => {
    return (
      <div
        style={{
          height: 24,
          background: '#F7F7F7',
          border: '1px solid #EAEAEC',
          borderRadius: 6,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 7,
          padding: "0 0.5rem",
        }}
      >
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: '20px',
            minWidth: "max-content",
            color: '#2E3044',
          }}
        >
          {keyword}
        </Typography>
        <img
          src={crossIcon}
          alt='close'
          style={{
            width: 10,
            height: 10,
          }}
          onClick={() =>
            setInterestKeywords(
              interestKeywords.filter((keyword, index) => index !== id)
            )
          }
        />
      </div>
    );
  };
  return (
    <div className='interest-keyword-form'>
      <div className='interest-keywords'>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '22px',
            color: '#585969',
          }}
        >
          Interest Keywords
        </Typography>
        <div className='selected-keyword-tag-container'>
          <div className='selected-keywords'>
            {interestKeywords.map((keyword, index) => (
              <Chip keyword={keyword} id={index} />
            ))}
          </div>
          <div className='input-keywords'>
            <TextField
              id='brand-website'
              variant='standard'
              placeholder='New Keyword here'
              value={keywordVal}
              disabled={disabled}
              onChange={(e) => setKeywordVal(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position='end'
                    style={{
                      position: 'absolute',
                      right: 4,
                    }}
                  >
                    <IconButton
                      sx={{
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                        },
                      }}
                      onClick={() => {
                        if(!keywordVal.trim()) return;
                        setInterestKeywords([...interestKeywords, keywordVal.trim()]);
                        setKeywordVal('');
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  // color: inviteEmail ? '#170F49' : '#ABACB4',
                  width: '100%',
                  height: 24,
                  background: '#FFFFFF',
                  padding: '7px 0px 7px 12px',
                  // border: `1px solid ${error ? 'red' : '#F3F3F3'}`,
                  // border: '1px solid red',
                  borderTop: '1px solid #EAEAEC',
                  borderRadius: 6,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  outline: 'none',
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestKeywordsFields;
