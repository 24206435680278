import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  TableBody,
  Typography,
  Button,
} from "@mui/material";
const commonStyleForFonts = {
  color: "#9798A1",
  fontFamily: "Inter",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "20px",
  borderBottom: "none",
};
export const StyledLinkAccount = styled(Typography)(() => ({
  color: "#0869FB",
  fontFamily: "Inter",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "20px",
  textDecoration: "underline",
  cursor: "pointer",
}));
export const StyledButtonAddAccount = styled(Button)(() => ({
  borderRadius: 6,
  border: "1.5px solid #0869FB",
  color: "#0869FB",
  textAlign: "center",
  fontFamily: "Inter",
  fontSize: 13,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "16px",
  textTransform: "capitalize",
  padding: "6px 16px",
}));
export const StyledTableHeaderCell = styled(TableCell)(({ columnLength }) => ({
  ...commonStyleForFonts,
  // textAlign: "center",
  width: `calc(100% / ${columnLength})`,
  paddingBottom: 0,
  // paddingTop: 0,
  paddingLeft: 0,
  paddingRight: 0,
  "&:first-child": {
    paddingLeft: 22,
    //   paddingRight: 0,
  },
}));

export const StyledInfoHeaderWrapper = styled("div")`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledSpanText = styled("span")(() => ({
  display: "flex",
  alignItems: "center",
  borderRadius: 4,
  padding: "2px 2px",
  "&:hover": {
    background: "#EAEAEC",
    cursor: "pointer",
    // width: "max-content",
    display: "inline-flex",
    alignItems: "center",
  },
}));
export const StyleTableCellForData = styled(TableCell)(({ columnLength }) => ({
  ...commonStyleForFonts,
  fontWeight: 500,
  // padding: 0,
  color: "#585969",
  // textAlign: "center",
  width:
    columnLength === 7
      ? `calc(100.5%/ ${columnLength})`
      : `calc(100% / ${columnLength})`,
  "&:first-child": {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    paddingLeft: 22,
    paddingRight: 0,
  },
  "&:last-child": {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 20,
  paddingBottom: 20,
  background: "#fff",
}));
export const StyledTableRow = styled(TableRow)(() => ({}));
