import { ReactComponent as IconGoogle } from "../../assets/icons/social/google-icon-smple.svg";

export function GoogleSearchTag () {
  return (
    <div className='campaign-card__tag'>
      <IconGoogle />
      <span>Google Search</span>
    </div>
  )
}

export function GooglePmaxTag () {
  return (
    <div className='campaign-card__tag'>
      <IconGoogle />
      <span>Google Performance Max</span>
    </div>
  )
}
