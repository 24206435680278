import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchInterestKeywords } from '../../../../services/facebook-ads';

const SEARCH_DEBOUND_DELAY_IN_MS = 700;
const NULL_KEYWORD_OPTIONS = [];

export default function useKeywordsSearch({
  debounceDelay = SEARCH_DEBOUND_DELAY_IN_MS,
} = {}) {
  const [searchText, setSearchText] = useState("");

  const timeoutId = useRef(null);

  const {
    data: keywordResponse,
    mutate: searchKeywords,
    reset
  } = useSearchInterestKeywords();

  const keywordOptions = useMemo(
    () => keywordResponse?.interests.map((value) => ({ name: value.name, title: value.name, id: value.id })) || NULL_KEYWORD_OPTIONS,
    [keywordResponse]
  );

  // debounced search
  useEffect(() => {
    timeoutId.current = setTimeout(() => {
      if (!searchText) return reset();
      searchKeywords({ searchString: searchText });
    }, debounceDelay)

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [searchText, reset, searchKeywords, debounceDelay]);

  return {
    setSearchText,
    keywordOptions,
    searchText, 
  };
}