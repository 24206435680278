import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../api';

const getFBGaiResoursesQueryKey = ({ formId, strategyId }) => ["facebook-gai-resources/get", formId, strategyId];
export function useGetFBGaiResources({ formId, strategyId }, options = {}) {
  return useQuery(
    getFBGaiResoursesQueryKey({ formId, strategyId }),
    async () => {
      const response = await api.get(`/facebook-gai-resources/${formId}/${strategyId}`);
      return response.data;
    },
    options,
  );
}

export function usePutUpdateFbGaiResources({ formId }, options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    // strategy id is required to update the UI with new image
    async ({ resourceId, adId, ad, strategyId: _ }) => {
      const response = await api.put(`/facebook-gai-resources/${resourceId}/${adId}`, ad);
      return response.data;
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        const { resourceId, adId, strategyId } = variables;
        queryClient.setQueryData(
          getFBGaiResoursesQueryKey({ formId, strategyId }),
          (prev) => ({
            ...prev,
            data: prev.data.map(adcopyset => adcopyset.id === resourceId ? {
              ...adcopyset,
              ads: adcopyset.ads.map(ad => ad.id === adId ? ({
                ...ad,
                ...variables.ad,
              }) : ad)
            } : adcopyset)
          })
        )
        options.onSuccess?.(data, variables, ctx);
      }
    },
  );
};
