import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  proposalAdded: false,
  pricing: {
    1: {
      adSpendRange: {
        minSpend: 5000,
        maxSpend: 25000,
      },
      currency: '$',
      basePricePerMonth: 4000,
      basePercentPerMonth: 0,
      baseServices: [
        {
          service: 'Landing Pages',
          quantity: '1-2',
        },
        {
          service: 'Pop-up',
          quantity: '1',
        },
        {
          service: 'Sticky Bar',
          quantity: '1',
        },
      ],
      addonServices: [
        {
          service: 'Additional Landing Pages',
          quantity: 0,
          price: 500,
        },
        {
          service: 'Additional Pop Up',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Additional Sticky Bar',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Call Rail',
          quantity: 0,
          price: 50,
          selected: false,
        },
        {
          service: 'Hotjar',
          quantity: 0,
          price: 50,
          selected: false,
        },
        {
          service: 'Grow forms',
          quantity: 0,
          price: 75,
          selected: false,
        },
      ],
    },
    2: {
      adSpendRange: {
        minSpend: 25000,
        maxSpend: 75000,
      },
      currency: '$',
      basePricePerMonth: 5250,
      basePercentPerMonth: 0,
      baseServices: [
        {
          service: 'Landing Pages',
          quantity: '1-3',
        },
        {
          service: 'Pop-up',
          quantity: '1',
        },
        {
          service: 'Sticky Bar',
          quantity: '1',
        },
      ],
      addonServices: [
        {
          service: 'Additional Landing Pages',
          quantity: 0,
          price: 500,
        },
        {
          service: 'Additional Pop Up',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Additional Sticky Bar',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Call Rail',
          quantity: 0,
          price: 50,
          selected: false,
        },
        {
          service: 'Hotjar',
          quantity: 0,
          price: 50,
          selected: false,
        },
        {
          service: 'Grow forms',
          quantity: 0,
          price: 75,
          selected: false,
        },
      ],
    },
    3: {
      adSpendRange: {
        minSpend: 75000,
        maxSpend: 150000,
      },
      currency: '$',
      basePricePerMonth: 12500,
      basePercentPerMonth: 0,
      baseServices: [
        {
          service: 'Landing Pages',
          quantity: '1-5',
        },
        {
          service: 'Pop-up',
          quantity: '1-2',
        },
        {
          service: 'Sticky Bar',
          quantity: '1-2',
        },
      ],
      addonServices: [
        {
          service: 'Additional Landing Pages',
          quantity: 0,
          price: 500,
        },
        {
          service: 'Additional Pop Up',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Additional Sticky Bar',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Call Rail',
          quantity: 0,
          price: 50,
          selected: false,
        },
        {
          service: 'Hotjar',
          quantity: 0,
          price: 50,
          selected: false,
        },
        {
          service: 'Grow forms',
          quantity: 0,
          price: 75,
          selected: false,
        },
      ],
    },
    4: {
      adSpendRange: {
        minSpend: 150000,
        maxSpend: 250000,
      },
      currency: '$',
      basePricePerMonth: 22500,
      basePercentPerMonth: 0,
      baseServices: [
        {
          service: 'Landing Pages',
          quantity: '1-10',
        },
        {
          service: 'Pop-up',
          quantity: '1-3',
        },
        {
          service: 'Sticky Bar',
          quantity: '1-3',
        },
      ],
      addonServices: [
        {
          service: 'Additional Landing Pages',
          quantity: 0,
          price: 500,
        },
        {
          service: 'Additional Pop Up',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Additional Sticky Bar',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Call Rail',
          quantity: 0,
          price: 50,
        },
        {
          service: 'Hotjar',
          quantity: 0,
          price: 50,
        },
      ],
    },
    5: {
      adSpendRange: {
        minSpend: 250000,
        maxSpend: 0,
      },
      currency: '$',
      basePricePerMonth: -1,
      basePercentPerMonth: 0,
      baseServices: [
        {
          service: 'Landing Pages',
          quantity: 'Custom',
        },
        {
          service: 'Pop-up',
          quantity: 'Custom',
        },
        {
          service: 'Sticky Bar',
          quantity: 'Custom',
        },
      ],
      addonServices: [
        {
          service: 'Additional Landing Pages',
          quantity: 0,
          price: 500,
        },
        {
          service: 'Additional Pop Up',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Additional Sticky Bar',
          quantity: 0,
          price: 100,
        },
        {
          service: 'Call Rail',
          quantity: 0,
          price: 50,
        },
        {
          service: 'Hotjar',
          quantity: 0,
          price: 50,
        },
      ],
    },
  },
  selectedPriceCard: 0,
};

export const pricingSlice = createSlice({
  name: 'pricing',
  initialState,
  reducers: {
    createProposalReducer() {},
    updateProposalData: (state, action) => {
      state.proposalAdded = action.payload;
    },
    updatePricing: (state, action) => {
      const priceIndex = action.payload.priceIndex;
      state.pricing = {
        ...state.pricing,
        [priceIndex]: action.payload.pricing,
      };
    },
    updateSelectedPriceCard: (state, action) => {
      state.selectedPriceCard = action.payload;
    },
    resetPricingState: (state, action) => {
      state.pricing = initialState.pricing;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  createProposalReducer,
  updateProposalData,
  updatePricing,
  updateSelectedPriceCard,
  resetPricingState,
} = pricingSlice.actions;

export default pricingSlice.reducer;
