import { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import AddEditKeywordModal from './AddEditKeywordModal';
import DisplayAdsetName from './DisplayAdsetName';
import KeywordsSearchInput from './KeywordsSearchInput';
import { ADSET_CRON_STATUS, ADSET_STATUS } from '../../utils';

function AdsetStatusField ({index, control}) {
  const { register } = useFormContext();
  const adsetCronStatus = useWatch({
    control,
    name: `adsets.${index}.cronStatus`,
  });
  
  const status = (!adsetCronStatus || adsetCronStatus === ADSET_CRON_STATUS.DONE) ? ADSET_STATUS.COMPLETED: ADSET_STATUS.ONGOING;
  return (
    <input type="hidden" value={status} {...register(`adsets.${index}.status`)} />
  );
}

export default function KeywordAdset({
  activeAdsetIndex,
  onChangeActiveAdsetIndex,
  openAdsetDeleteModalCurried,
  index,
}) {
  const { register, control } = useFormContext();

  const [isAddEditKeywordModalOpen, setIsAddEditKeywordModalOpen] = useState(false);
  const openAddEditKeywordsModal = () => setIsAddEditKeywordModalOpen(true);
  const closeAddEditKeywordsModal = () => setIsAddEditKeywordModalOpen(false);

  const { fields: keywords, replace, remove } = useFieldArray({
    control,
    name: `adsets.${index}.keywords`,
    keyName: "key"
  });

  const adsetName = <DisplayAdsetName control={control} index={index} />;

  return (
    <div
      className={
        `tof-interest_tab adset_block_hdm ${activeAdsetIndex === index ? "selected" : ""}`
      }
      onClick={onChangeActiveAdsetIndex(index)}
    >
      <AdsetStatusField index={index} control={control} />
      <input type="hidden" {...register(`adsets.${index}.id`)} />
      <input type="hidden" {...register(`adsets.${index}.adsetNumber`)} />
      <div className='adset_head_hdm flex-center-space'>
        <p>
          Ad Name: <span>{adsetName}</span>
        </p>
        <i
          className='delete_adset'
          onClick={openAdsetDeleteModalCurried(index)}
        ></i>
      </div>
      <div className='tag_selected_blocks'>
        <div className='tag_selected_chips'>
          {keywords.map((keyword, index) =>
            index <= 11 && <span key={keyword.key}>{keyword.name}</span>
          )}
        </div>
        <div className='edit_view_row'>
          <button
            className='edit_view_button'
            onClick={openAddEditKeywordsModal}
            type="button"
          >
            Edit / View More
          </button>
        </div>
      </div>
      <div className='adset_list_hdm'>
        <p className='addNew_keyword_title'>Add New Keyword</p>
        <KeywordsSearchInput keywords={keywords} replace={replace} />
      </div>

      {!!isAddEditKeywordModalOpen && (
        <AddEditKeywordModal
          open={true}
          onClose={closeAddEditKeywordsModal}
          keywords={keywords}
          remove={remove}
          replace={replace}
          adsetName={adsetName}
        />
      )}
    </div>
  )
}