import GoogleAdsLogo from "../../assets/images/Analytics/logos_google-ads.svg"
import FacebookLogo from "../../assets/images/Analytics/logos_facebook.svg"
import DataBlendIcon from "../../assets/images/Analytics/DataBlend.svg";

class Channel {
    constructor(type, title, icon, subtext, isSupported = false, isBlend = false) {
        this.type = type;
        this.title = title;
        this.icon = icon;
        this.subtext = subtext;
        this.isSupported = isSupported;
        this.isBlend = isBlend;

        if (this.isSupported) {
            // if (this.isBlend) {
            //     const transformerModule = require("../api/blends/transformer");
            //     this.properties = transformerModule.properties;
            //     this.transformer = Object.keys(transformerModule)
            //         .filter(t => typeof transformerModule[t] === 'function')
            //         .reduce((obj, t) => ({ ...obj, [t]: transformerModule[t] }), {});
            // } else {
            //     const transformerModule = require(`../api/${this.type}/transformer`);
            //     this.properties = transformerModule.properties;
            //     this.transformer = Object.keys(transformerModule)
            //         .filter(t => typeof transformerModule[t] === 'function')
            //         .reduce((obj, t) => ({ ...obj, [t]: transformerModule[t] }), {});
            //     this.actions = {
            //         connectAccount: { type: `${this.type}.CONNECT_ACCOUNT` },
            //         disconnectAccount: { type: `${this.type}.DISCONNECT_ACCOUNT` },
            //         removeAccountData: { type: `${this.type}.DISCONNECT_ACCOUNT_SUCCESS` },
            //         getAccountSummary: { type: `${this.type}.GET_ACCOUNT_SUMMARY` },
            //         getFields: { type: `${this.type}.GET_FIELDS` },
            //         getColumns: (params) => { return { type: `${this.type}.GET_COLUMNS`, payload: params } },
            //         getCurrency: (account) => ({ type: `${this.type}.GET_CURRENCY`, payload: account }),
            //     };
            // }
        }
    }

    loadGrid() {
        // if (this.isSupported && !this.isBlend) {
        //     this.grid = require(`../api/${this.type}/grid`).default;
        //     this.grid.forEach(tab => tab.grids.forEach(grid => {
        //         grid.tabId = tab.id;
        //         grid.charts.forEach(chart => chart.gridId = grid.id);
        //     }));
        // }
    }

    updateGridFields(chart, tables = [], metrics = [], dimensions = []) {
        if (!this.isSupported) { return; }

        function getId(column) {
            if (column.dataSource) {
                return `${column.id}/${column.dataSource}`
            } else {
                return column.id;
            }
        }

        const tableMap = new Map();
        tables?.forEach(table => tableMap.set(table.id, table));

        const metricMap = new Map();
        if (Array.isArray(metrics)) {
            metrics.forEach(metric => metricMap.set(getId(metric), metric));
        } else {
            Object.entries(metrics).forEach(e => {
                const innerMap = new Map();
                e[1].forEach(metric => innerMap.set(getId(metric), metric));
                metricMap.set(e[0], innerMap);
            });
        }

        const dimensionMap = new Map();
        if (Array.isArray(dimensions)) {
            dimensions.forEach(dimension => dimensionMap.set(getId(dimension), dimension));
        } else {
            Object.entries(dimensions).forEach(e => {
                const innerMap = new Map();
                e[1].forEach(dimension => innerMap.set(getId(dimension), dimension));
                dimensionMap.set(e[0], innerMap);
            });
        }

        chart.table = tableMap.get(chart.table?.id);

        [...chart.leftMetrics, ...(chart.rightMetrics ?? [])].forEach(metric => {
            if (metric.metric && Object.keys(metric.metric).length <= 2) {
                if (chart.table) {
                    metric.metric = metricMap.get(chart.table.id)?.get(getId(metric.metric));
                } else {
                    metric.metric = metricMap.get(getId(metric.metric));
                }
            }
        });
        chart.dimensions = chart.dimensions?.map(dimension => {
            if (dimension && Object.keys(dimension).length <= 2) {
                if (chart.table) {
                    return dimensionMap.get(chart.table.id)?.get(getId(dimension));
                } else {
                    return dimensionMap.get(getId(dimension));
                }
            }
            return dimension;
        });
    }

    getState(state) {
        return this.isBlend ? state.blends[this.type] ?? {} : state.dashboard[this.type];
    }
}

export const ChannelType = {
    Default: "default",
    GoogleAnalytics: "google-analytics",
    ShopifyAds: "shopify-ads",
    Adjust: "adjust",
    Branch: "branch",
    Looker: "looker",
    Appsflyer: "appsflyer",
    Salesforce: "salesforce",
    Metadata: "metadata",
    GoogleAds: "google-ads",
    FacebookAds: "facebook-ads",
    LinkedInAds: "linkedin-ads",
    Bing: "bing-ads",
};

export const AdsList = [
    new Channel(
        ChannelType.GoogleAds,
        "Google Ads",
        GoogleAdsLogo,
        "Connect and source data from Google Ads",
        true,
    ),
    new Channel(
        ChannelType.FacebookAds,
        "Facebook Ads",
        FacebookLogo,
        "Connect and source data from Facebook Ads",
        true,
    )
];

const allChannels = [...AdsList];


export const SupportedChannels = allChannels.filter(channel => channel.isSupported);

const channelMapping = new Map(allChannels.map(v => [v.type, v]));
export const GetChannel = (channelString) => {
    if (!channelString) {
        return null;
    } else if (channelString === ChannelType.Default) {
        return SupportedChannels[0];
    } else if (channelMapping.has(channelString)) {
        return channelMapping.get(channelString);
    } else if (typeof (channelString) === "object" && "id" in channelString && "name" in channelString) {
        return new Channel(channelString.id, channelString.name, DataBlendIcon, "Template for customized blended data sources", true, true);
    } else {
        return null;
    }
};

export const GetChannelSortOrder = (channelType) => allChannels.findIndex(channel => channel.type === channelType);

SupportedChannels.forEach(channel => channel.loadGrid());

export function getApiObject(type, metrics, dimensions, table) {
    return type === ChannelType.GoogleAds ? {
        selectFields: [...metrics, ...dimensions],
        from: table?.id
    } : {
        metrics: metrics,
        dimensions: dimensions,
    }
}
