import React from 'react';
import { Typography } from '@material-ui/core';
import ongoing from '../assets/ongoing-icon.svg';
const OngoingButton = () => {
  return (
    <div className='ongoing-button'>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 12,
          lineHeight: '20px',
          color: '#0869FB',
        }}
      >
        Ongoing
      </Typography>
      <div className='lock-icon-image-wrapper'>
        <img src={ongoing} alt='' />
      </div>
    </div>
  );
};

export default OngoingButton;
