import {
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material";
import moment from "moment";
import { ChannelType } from "../../models/channel";

function getDurationString(time) {
  // return time;
  var duration = parseFloat(time.toString().replaceAll(",", ""));

  const minuteDuration = 60;
  const hourDuration = 60 * minuteDuration;
  const dayDuration = 24 * hourDuration;

  var days = 0;
  var hours = 0;
  var minutes = 0;
  if (duration >= dayDuration) {
    days = Math.floor(duration / dayDuration);
    duration -= dayDuration * days;
  }
  if (duration >= hourDuration) {
    hours = Math.floor(duration / hourDuration);
    duration -= hourDuration * hours;
  }
  if (duration > minuteDuration) {
    minutes = Math.floor(duration / minuteDuration);
    duration -= minuteDuration * minutes;
  }

  if (!Number.isSafeInteger(duration)) {
    duration = parseInt(duration);
  }

  var string = `${duration}s`;
  if (minutes > 0) { string = `${minutes}m ` + string }
  if (hours > 0) { string = `${hours}h ` + string }
  if (days > 0) { string = `${days}d ` + string }

  return string;
}

const unitMapper = {
  "DATE": getDurationString,
  "TIME": getDurationString,
  "DATETIME": getDurationString,
  "PERCENT": val => `${val}%`,
  "PRICE": (val, currency) => `${val}`,
  "CURRENCY": (val, currency) => `${val}`,
}

const insertComma = valStr => {
  const digits = valStr.length;
  for (let i = 0; i < Math.floor((digits - 1) / 3); i++) {
    const endIndex = digits - (i + 1) * 3;
    valStr = valStr.slice(0, endIndex) + "," + valStr.slice(endIndex);
  }
  return valStr;
}

export const DisplayFormatter = value => {
  // for arrays, insert inside div
  if (Array.isArray(value)) {
    return value.map(v => <div>{v}</div>);
  }
  if (typeof value === "number") {
    const signString = Math.sign(value) === -1 ? "-" : "";
    const mag = Math.abs(value);
    // for big integers, insert commas
    if (Number.isSafeInteger(value)) {
      return signString + insertComma(mag.toString());
    }
    // for float, only 2 decimal places
    const valStr = mag.toFixed(2);
    return signString + insertComma(valStr.slice(0, valStr.length - 3)) + valStr.slice(valStr.length - 3);
  }
  return value;
}

export const UnitDataFormatter = (number, unit, currency) => {
  const mapper = (unit ? unitMapper[unit.toUpperCase()] : null) ?? (val => val);
  return mapper(DataFormatter(number), currency);
};

export const UnitDisplayFormatter = (number, unit, currency) => {
  const mapper = (unit ? unitMapper[unit.toUpperCase()] : null) ?? (val => val);
  return mapper(DisplayFormatter(number), currency);
};

export const DataFormatter = (number) => {
  if (!number) { number = 0; }
  const signString = Math.sign(number) === -1 ? "-" : "";
  const mag = Math.abs(number);
  if (mag > 1000000000) {
    return signString + (mag / 1000000000).toFixed(2) + "B";
  } else if (mag > 1000000) {
    return signString + (mag / 1000000).toFixed(2) + "M";
  } else if (mag > 1000) {
    return signString + (mag / 1000).toFixed(2) + "K";
  } else {
    return signString + mag.toFixed(2);
  }
};

export const DateFormatter = (date) => {
  if (!date) { return ""; }
  const formattedDate = moment(date, "YYYY-MM-DD").format("MMM DD");
  return formattedDate === "Invalid date" ? date : formattedDate;
};

export const DateDisplayFormatter = (date) => {
  if (!date) { return ""; }
  return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
};

export const isDate = (value) => {
  if (Number(value)) return false;
  else return !!Date.parse(value);
};

export const ChartTable = payload => (
  <Table style={{ width: "90%" }}>
    <TableBody>
      {payload.map(row => {
        return <TableRow>
          <TableCell style={{ borderRight: "1px solid #EFEFEF", borderTop: "1px solid #EFEFEF", padding: "10px" }}>
            <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: row.color, display: "inline-block", marginRight: "5px" }}></div>
            <span className="inter bold">{`${row.name} ${row.currency ? " (" + row.currency + ") " : ""}`}</span>
          </TableCell>
          <TableCell style={{ borderTop: "1px solid #EFEFEF", color: "gray", padding: "10px" }}>
            <span className="inter">{row.value}</span>
          </TableCell>
        </TableRow>
      })}
    </TableBody>
  </Table>
);

export const CustomizedLabel = (props) => {
  const { x, y, width, height, fill, value, unit } = props;
  const formattedValue = (value && value !== "-") && UnitDataFormatter(value, unit);
  const fireOffset = y > 30 || height < 20;
  return <text
    x={x}
    y={y}
    dy={(fireOffset || height < 0) ? -5 : (width / 5 || 20)}
    fontSize={(width / 4)}
    fontFamily='sans-serif'
    fill={((fireOffset && height > 0) || !height) ? fill : "#fff"}
  // textAnchor="middle"
  >
    {formattedValue}
  </text >
};

export const TooltipContent = (e, xField, useDateFormatter, currencyMapping) => (
  <div
    className="smooth-shadow"
    style={{
      backgroundColor: "white",
      borderRadius: "10px",
      minWidth: "100px",
      textAlign: "left",
      padding: "10px",
    }}
  >
    {e.payload?.some(p => p?.payload?.type === 'compare')
      ? <div>
        {useDateFormatter
          ? `${DateFormatter(e.payload[0]?.payload[xField])} - ${DateFormatter(e.payload[0].payload[`X-${xField}`])} (X)`
          : `${e.payload[0]?.payload[xField]} - ${e.payload[0].payload[`X-${xField}`]} (X)`
        }
      </div>
      : e.payload?.[0]?.payload?.[xField] && <div>
        <span className="inter" style={{ fontSize: "14px" }}>
          {useDateFormatter ? DateFormatter(e.payload[0]?.payload[xField]) : e.payload[0]?.payload[xField]}
        </span>
        <br />
      </div>
    }
    {e.payload?.map((row) => (
      <>
        <span className="inter" style={{ fontSize: "12px" }}>
          {`${row.name} ${currencyMapping?.get(row.name) ? ("(" + currencyMapping.get(row.name) + ")") : ""} : `}
        </span>
        <span className="inter bold" style={{ fontSize: "13px", color: row.color }}>
          {UnitDataFormatter(Number(row.payload[row.dataKey]), row.unit)}</span>
        <br />
      </>
    ))}
  </div>
);

export const TooltipContentType2 = (items, unit, currency) => (
  < div
    style={{
      backgroundColor: "white",
      borderRadius: "10px",
      minWidth: "100px",
      textAlign: "left",
      padding: "10px",
      margin: "0px -18px",
      display: "flex",
      flexDirection: 'column'
    }}
  >
    {
      items?.map((item) => {
        unit = unit ?? item?.data?.unit;
        currency = currency ?? item?.data?.currency;
        return (
          <div style={{ display: "flex", lineHeight: "18px", padding: "3px 0px" }}>
            <span className="inter" style={{ fontSize: "12px", color: "#000" }}>
              {`${item.name} ${currency ? " (" + currency + ") " : ""}: `}
            </span>
            <span className="inter bold" style={{ fontSize: "13px", color: item.color, marginLeft: "5px", whiteSpace: "pre-line", wordWrap: "break-word", maxWidth: "200px" }}>
              {(unit && isFinite(item.value)) ? UnitDataFormatter(Number(item.value), typeof (unit) === "string" ? unit : unit[item.name]) : item.value}
            </span>
            <br />
          </div>
        )
      })
    }
  </div >
);

export const TooltipContentType3 = (items, currencyMapping) => (
  < div
    style={{
      backgroundColor: "white",
      borderRadius: "10px",
      minWidth: "100px",
      textAlign: "left",
      padding: "10px",
      margin: "0px -18px",
      display: "flex",
      flexDirection: 'column'
    }}
  >
    {
      items?.map((item) => (
        <div style={{ display: "flex", lineHeight: "18px", padding: "3px 0px" }}>
          <span className="inter" style={{ fontSize: "12px", color: "#000" }}>
            {`${item.name} ${currencyMapping?.get(item.name) ? " (" + currencyMapping.get(item.name) + ") " : ""}: `}
          </span>
          <span className="inter bold" style={{ fontSize: "13px", color: item.color, marginLeft: "5px", whiteSpace: "pre-line", wordWrap: "break-word", maxWidth: "200px" }}>
            {isFinite(item.value) ? UnitDataFormatter(Number(item.value), typeof (item.data.unit) === "string" ? item.data.unit : item.data.unit[item.name]) : item.value}
          </span>
          <br />
        </div>
      ))
    }
  </div >
);

// const Colors = ["#496DFF", "#5F27BA", "#21ADFF", "#8CD6A0"];
export const Palette = [
  ["#496DFF", "#5F27BA", "#21ADFF", "#8CD6A0", "#69D2E7"],
  ["#69D2E7", "#A7DBD8", "#E0E4CC", "#F38630", "#FA6900"],
  ["#FE4365", "#FC9D9A", "#F9CDAD", "#C8C8A9", "#83AF9B"],
  ["#ECD078", "#D95B43", "#C02942", "#542437", "#53777A"],
  ["#556270", "#4ECDC4", "#C7F464", "#FF6B6B", "#C44D58"],
  ["#774F38", "#E08E79", "#F1D4AF", "#ECE5CE", "#C5E0DC"],
  ["#E8DDCB", "#CDB380", "#036564", "#033649", "#031634"],
];

export const TablePalette = [
  ["#335A75", "#ADD8E680", "#ADD8E630", "ADD8E680", "#ADD8E630",],
  ["#0869FB", "#FFFFFF", "#B2DFEE60", "#FFFFFF", "#B2DFEE60"],
  ["#FE4365", "#FC9D9A80", "#F9CDAD90", "#FC9D9A80", "#F9CDAD90"],
  ["#C02942", "#ECD07870", "#D95B4360", "#ECD07870", "#D95B4360"],
  ["#556270", "#4ECDC480", "#C7F46470", "#4ECDC480", "#C7F46470"],
  ["#774F38", "#E08E7970", "#F1D4AFAA", "#E08E7970", "#F1D4AFAA",],
  ["#033649", "#CDB380AA", "#E8DDCB", "#CDB380", "#E8DDCB"],
];

export const GetColor = (index, selectedPallete = 0) =>
  Palette[selectedPallete][index % Palette[selectedPallete].length];

export const LegendPosition = ["bottom", "left", "right", "top"];

export const TextStyle = {
  FontStyle: ['Monospace', 'Times New Roman', 'Inter'],
  FontSize: [8, 10, 12, 14, 16, 20, 24, 28, 32, 44, 56],
  FontColor: ["#21ADFF", "#21ADFF", "#8CD6A0", "#69D2E7", "#C7F464", "#FF6B6B", "#C44D58", "#FC9D9A", "#F9CDAD", "#C8C8A9", "#A7DBD8", "#E0E4CC"]
}
export const LegendStyle = {
  FontStyle: ['Monospace', 'Times New Roman', 'Inter'],
  FontSize: [8, 10, 12, 14, 16, 20, 24, 28, 32, 44, 56],
}

export const transFormComparisonData = (source, compared) => {

  if (compared?.data?.length > 0) {
    const sourceData = source.data.map((d, index) => {
      let obj = {};

      for (let key in d) {
        obj[key] = d?.[key]
        if (compared.dimensions?.includes(key) && compared.data?.[index]?.[key] === d?.[key]) {
          compared.data?.splice(index, 0, 0);
        } else {
          obj[`X-${key}`] = compared.data?.[index]?.[key]
        }
      }
      obj.type = 'compare';

      return obj;
    });

    const leftMetrics = compared.leftMetrics.map(m => ({
      ...m,
      id: `X-${m.id}`,
      name: `X-${m.name}`,
    }));

    const rightMetrics = compared.rightMetrics.map(m => ({
      ...m,
      id: `X-${m.id}`,
      name: `X-${m.name}`,
    }));

    return {
      sourceData,
      leftMetrics,
      rightMetrics
    }
  }

  return {
    sourceData: source.data,
    leftMetrics: [],
    rightMetrics: []
  }

}



export const Colors = ["#21ADFF", "#8CD6A0", "#69D2E7", "#C7F464", "#FF6B6B", "#C44D58", "#FC9D9A", "#F9CDAD", "#C8C8A9", "#A7DBD8", "#E0E4CC"]

export const displayCurrency = (type) => {
  return (type === ChannelType.FacebookAds || type === ChannelType.ShopifyAds) ? "PRICE" : "CURRENCY";
}

export const dataBlendSource = (type) => {
  const getType = type.match(/\(([^)]+)\)/);
  if (getType?.length === 2) {
    const updatedType = getType[1].toLowerCase().replace(" ", "-");
    return updatedType;
  } else {
    return null;
  }
}

export const dataBlendDisplayCurrency = (type, unit, currency) => {
  const getType = type.match(/\(([^)]+)\)/);
  if (getType?.length === 2 && currency) {
    const updatedType = getType[1].toLowerCase().replace(" ", "-");
    if ((updatedType === ChannelType.FacebookAds || updatedType === ChannelType.ShopifyAds) && unit?.toUpperCase() === "PRICE") {
      return " (" + currency + ")";
    } else if ((updatedType !== ChannelType.FacebookAds && updatedType !== ChannelType.ShopifyAds) && unit?.toUpperCase() === "CURRENCY") {
      return " (" + currency + ")";
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export const ChartFilterSupport = (chartType) => {
  return (
    chartType === "TABLE" || chartType === "COLUMN" || chartType === "BUBBLE" ||
    chartType === "SCATTER" || chartType === "WORDCLOUD" || chartType === "TREEMAP" ||
    chartType === "WATERFALL" || chartType === "GEO"
  )
}

export const DimensionsLength = {
  COLUMN: 1,
  BUBBLE: 1,
  SCATTER: 2,
  WORDCLOUD: 1,
  TREEMAP: 3,
  WATERFALL: 1,
  GEO: 2
}

export const MetricsLength = {
  BUBBLE: 3,
  SCATTER: 2,
  WORDCLOUD: 1,
  TREEMAP: 1,
  WATERFALL: 1,
  GEO: 1
}