import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  CITY_LOCATION_AUTOSUGGESTION_INPUT,
  LOCATION_ADDED_TO_FORM,
} from "../../../api/client/enum";
import { useEffect, useState } from "react";

const MyChip = (props) => {
  return (
    <div className="custom_chips">
      <span>{props.label}</span>
      <span className="close_tag" onClick={props.onDelete}></span>
    </div>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HDMLocationAutoComplete = (props) => {
  const dispatch = useDispatch();
  let selectedValues = useSelector(
    (state) =>
      state.hdmClient.onboardingInfo.facebookTargeting.advertisingLocations
  );
  let locationOptions = useSelector(
    (state) => state.hdmClient.onboardingInfo.facebookTargeting.locationOptions
  );
  const [selectedTags, setSelectedTags] = useState(props.value);
  useEffect(() => {
    dispatch({
      type: "UPDATE_MULTIPLE_CHECKBOX_HDM",
      payload: {
        key: props.objKey,
        infoType: props.infoType,
        element: selectedTags,
      },
    });
  }, [selectedTags]);
  if (!locationOptions) {
    locationOptions = [];
  }
  // if (selectedValues && !selectedValues.length) selectedValues = undefined;
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags"
      options={locationOptions}
      defaultValue={selectedValues}
      onChange={(event, values) => {
        setSelectedTags(values);
      }}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          return <MyChip {...getTagProps({ index })} label={option.name} />;
        });
      }}
      renderOption={(props, option, { inputValue, selected }) => {
        const matches = match(option, inputValue, { insideWords: true });
        const parts = parse(option, matches);
        console.log(parts)
        return (
          <li {...props} className="search_location_list">
            <div className="location_list_head">
              {" "}
              <p>Location</p> <p>Type</p>
            </div>
            <div className="location_list_content">
              <div>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      color: part.highlight ? "#0869FB" : "#333333",
                    }}
                  >
                    {part.text.name}
                  </span>
                ))}
              </div>
              <span>{option.type}</span>
            </div>
          </li>
        );
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <div className="location_question_header">
          <span className="search_icon"></span>
          <TextField
            {...params}
            ref={params.InputProps.ref}
            autoFocus
            style={{
              width: props.width,
              height: props.height ? props.height : "",
              animation: props.bounce && `${props.bounceAnimation} 0.9s both`,
            }}
            placeholder={props.placeholder ? props.placeholder : "Search"}
            variant="standard"
            onChange={(e) => {
              dispatch({
                type: "GET_LOCATIONS",
                payload: {
                  infoType: props.type,
                  value: e.target.value,
                },
              });
            }}
          />
        </div>
      )}
      disablePortal={true}
      className={
        props.error
          ? "error_bounce bounce-top auto_location_search"
          : "auto_location_search"
      }
    />
  );
};

export default HDMLocationAutoComplete;
