import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import {button_bg_corner as corner} from '../../assets/icons/common/common';
import { useDispatch } from "react-redux";
import { updateCompoReduxAM } from "../../api/accountManager/componentTracking/action";
import { updateCompoReduxHDMAM } from '../../api/HDMAM/componentTracking/action';

const GoToNextCompoButton = (props) => {
  const dispatch = useDispatch()
  const CustomButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '14px',
    padding: props.type == 'corner' ? '11px 25px' : '10px 24px',
    border: props.type == 'corner' ? '0px solid' : '1px solid',
    lineHeight: '18px',
    background:
      props.type == 'bordered'
        ? '#ffffff'
        : props.type == 'corner'
          ? '#0869FB url(' + corner + ') no-repeat 0 0'
          : '#0869FB',
    borderColor: props.type == 'bordered' ? '#0869FB' : '#0869FB',
    borderRadius: '8px',
    color: props.type == 'bordered' ? '#0869FB' : '#FFFFFF',
    fontFamily: 'Inter',
    fontWeight: 600,
    width: props.width ? props.width : '',
    '&:hover': {
      color: props.type == 'bordered' ? '#FFFFFF' : '#FFFFFF',
    },
  });
  const goToNextCompo = () => {
    if(props.agencyType === 'HDM'){
      dispatch(updateCompoReduxHDMAM());
    }else{
      dispatch(updateCompoReduxAM());
    }
  }
  return (
    <CustomButton variant='contained' {...props} onClick={() => {
      if(props.onClickHandler){
        props.onClickHandler();
      }
      goToNextCompo()
    }}>
      {props.title}
    </CustomButton>
  );
};

export default GoToNextCompoButton;
