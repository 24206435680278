import { Typography } from '@mui/material';
import React from 'react';
import emptyImg from './pmaxIcons/empty-content-img.svg';
const NoContentView = ({ tab, buttonName }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '75%',
        margin: 'auto',
        textAlign: 'center',
        gap: 22,
        height: !['Logo', 'Image', 'Videos'].includes(tab) && 400,
      }}
    >
      <img src={emptyImg} alt='' width={140} height={113.75} />
      {tab === 'Logo' && (
        <Typography
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '24px',
            // textAlign: 'center',
            color: '#7B6C90',
          }}
        >
          Please click on <strong>“Upload”</strong> button below to add an image
          from your PC, or use the <strong>“Library”</strong> button to use
          images from your Google Ads Library.
        </Typography>
      )}
      {tab === 'Image' && (
        <Typography
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '24px',
            // textAlign: 'center',
            color: '#7B6C90',
          }}
        >
          Please click on <strong>“Upload”</strong> button below to add an image
          from your PC, or use the <strong>“Library”</strong> button to use
          images from your Google Ads Library.
        </Typography>
      )}
      {tab === 'Videos' && (
        <Typography
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '24px',
            color: '#7B6C90',
          }}
        >
          Please use the <strong>“Library”</strong> button to use videos from
          your Google Ads Library, or you can add youtube videos using the text
          bar.
        </Typography>
      )}
      {tab === 'Theme' && (
        <Typography
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '24px',
            color: '#7B6C90',
          }}
        >
          Please generate and select a few themes and then click <strong>“Generate Ad Copies”</strong> 
          {" "} to generate some content.
        </Typography>
      )}
      {!['Logo', 'Image', 'Videos', 'Theme'].includes(tab) && (
        <Typography
          style={{
            fontFamily: 'Lato',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '24px',
            color: '#7B6C90',
          }}
        >
          Please click on{' '}
          <strong>{`"${buttonName}"`}</strong> to 
          add content here.
        </Typography>
      )}
    </div>
  );
};

export default NoContentView;
