import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardDoubleArrowUpRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowUpRounded";
import KeyboardDoubleArrowDownRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowDownRounded";
import ImportExportRoundedIcon from "@mui/icons-material/ImportExportRounded";
import { DisplayFormatter, TablePalette, UnitDisplayFormatter } from "./ChartUtils";
import moment from "moment";
import { FormControl, MenuItem, Pagination, Select, TableHead } from "@mui/material";
import { displayCurrency, dataBlendDisplayCurrency } from "./ChartUtils";

const SEPARATOR = "|";
const ARRAY = "array";


function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function TableChart({ data, chartStyle, type }) {
  const dimensions = data.dimensions ? (data.dimensions.length > 1 ? data.dimensions : data.dimensions[0]) : null;
  const chartMetrics = [...data.leftMetrics, ...(data.rightMetrics ?? [])];
  const metrics = dimensions ? [Array.isArray(dimensions) ? { name: dimensions.join(SEPARATOR), unit: ARRAY } : { name: dimensions }, ...chartMetrics] : chartMetrics;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [sortMetric, setSortMetric] = React.useState("");
  const [sortOrder, setSortOrder] = React.useState(1);
  const [sortedData, setSortedData] = React.useState(data.data);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = metric => {
    const newData = Array.from(sortedData);
    var order = sortOrder;

    if (sortMetric === metric.name) {
      order = order * -1;
    } else {
      order = 1;
      setSortMetric(metric.name);
    }

    newData.sort((a, b) => {
      if (metric.unit === ARRAY) {
        return metric.name.split(SEPARATOR).map(m => a[m]).join(SEPARATOR) > metric.name.split(SEPARATOR).map(m => b[m]).join(SEPARATOR) ? order : -order;
      } else {
        return a[metric.name] > b[metric.name] ? order : -order;
      }
    });

    setSortedData(newData);
    setSortOrder(order);
  };
  const dataTypeToShowCurrency = displayCurrency(type);
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: '0', height: "100%" }}>
      <TableContainer
        sx={data.data.length > 5 ? { maxHeight: 'calc(100% - 50px)', height: '100%' } : { height: '100%' }}
      >
        <Table sx={{ height: '100%' }} aria-label="custom pagination table" stickyHeader>
          <TableHead>

            <TableRow style={{ borderBottom: "1.5px solid #0869FB", height: chartStyle.tableStyle.tableHeader.fontSize + 30 }}>
              {metrics.map((metric, index) => (
                <TableCell
                  key={index}
                  component="th"
                  scope="row"
                  style={{ color: "white", background: TablePalette[chartStyle.palette][0], paddingBottom: "0", paddingTop: "0px", marginTop: "0px", opacity: "1", fontFamily: chartStyle.tableStyle.tableHeader.font, fontSize: chartStyle.tableStyle.tableHeader.fontSize, whiteSpace: "nowrap" }}
                  sx={index !== metrics.length - 1 ? { lineBreak: "anywhere" } : {}}
                  onClick={() => handleSort(metric)}
                >
                  <b>{DisplayFormatter(metric.unit === ARRAY ? metric.name.split(SEPARATOR) : metric.name
                    // + `${metric.currency && dataTypeToShowCurrency === metric.unit.toUpperCase() ? " (" + metric.currency + ")" : dataBlendDisplayCurrency(metric.name, metric?.unit, metric.currency)}`
                  )}</b>
                  {sortMetric === metric.name && sortOrder === 1 && (
                    <KeyboardDoubleArrowUpRoundedIcon
                      style={{ position: "relative", top: "5", width: "20" }}
                    />
                  )}
                  {sortMetric === metric.name && sortOrder === -1 && (
                    <KeyboardDoubleArrowDownRoundedIcon
                      style={{ position: "relative", top: "5", width: "20" }}
                    />
                  )}
                  {sortMetric !== metric.name && (
                    <ImportExportRoundedIcon
                      style={{ position: "relative", top: "5", width: "20" }}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody >
            {sortedData
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((d, index) =>
                <TableRow style={index % 2 ? { background: TablePalette[chartStyle.palette][2] } : { background: TablePalette[chartStyle.palette][1] }} key={index} sx={{ height: "30px" }}>
                  {metrics.map((metric, index) =>
                    <TableCell
                      key={index}
                      scope="row"
                      style={{ paddingBottom: "0px", paddingTop: "0px", marginTop: "0px", fontFamily: chartStyle.tableStyle.tableContent.font, fontSize: chartStyle.tableStyle.tableContent.fontSize }}
                      sx={index !== metrics.length - 1 ? { lineBreak: "anywhere" } : {}}
                    >
                      {UnitDisplayFormatter(index === 0 && metric.unit === ARRAY ? metric.name.split(SEPARATOR).map(dim => `${dim}: ${d[dim]}`) : d[metric.name], metric.unit)}
                    </TableCell>
                  )}
                </TableRow>
              )}
          </TableBody>

        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
        Rows per page:

        <FormControl sx={{ m: 1, minWidth: 60, justifyContent: 'center' }} size="small">
          <Select
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, height: '30px' }}
            value={rowsPerPage}
            variant="standard"
            disableUnderline
            onChange={(e) => handleChangeRowsPerPage(e)}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={data.data.length}>All</MenuItem>
          </Select>
        </FormControl>
        <Pagination page={page + 1} count={Math.ceil(data.data.length / rowsPerPage)} onChange={(e, page) => handleChangePage(page - 1)} sx={{
          '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
              background: TablePalette[chartStyle.palette][0],
              color: 'white',
            },
          },
          justifyContent: 'center',
          display: 'flex',
          height: '60px'
        }} />
      </div>
    </Paper>
  );
}
