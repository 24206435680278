const BASE = "/bp-am/:formId";

const bpAmRoutePaths = /** @type {const} */ ({
  root: BASE,
  salesQuestionnaire: {
    fullbase: `${BASE}/sales-questionnaire`,
    root: "sales-questionnaire", // should matches the end of fullbase
    sub: {
      review: "",
      spends: "spends",
      spendsWithQuestionNumber: "spends/:questionNumber",
      performance: "performance",
      performanceWithQuestionNumber: "performance/:questionNumber",
    },
  },
  accessSharing: {
    fullbase: `${BASE}/access-sharing`,
    root: `access-sharing`,
    sub: {
      google: "google-access",
      facebook: "facebook-access",
    },
  },
  readSharedAssets: {
    fullbase: `${BASE}/read-shared-assets`,
    root: "read-shared-assets", // should matches the end of fullbase
    sub: {},
  },
  auditForGoogle: {
    fullbase: `${BASE}/google-audit`,
    root: "google-audit", // should matches the end of fullbase
    sub: {},
  },
  auditForFacebook: {
    fullbase: `${BASE}/facebook-audit`,
    root: "facebook-audit", // should matches the end of fullbase
    sub: {
      selectAccount: "",
      account: "account",
      campaign: "campaign",
      adset: "adset",
      ad: "ad",
      again: ":again",
    },
  },
  proposol: {
    fullbase: `${BASE}/proposal`,
    root: "proposal", // should matches the end of fullbase
    sub: {
      proposalView: "",
      audit: "audit",
      suggestChanges: "suggest-changes",
    },
  },
  reviewIntakeForm: {
    fullbase: `${BASE}/review-intake-form`,
    root: "review-intake-form",
    sub: {
      review: "",
      basicInfo: "basicInformation",
      basicInfoWithQuestionNumber: "basicInformation/:questionNumber",
      ppc: "ppc",
      ppcWithQuestionNumber: "ppc/:questionNumber",
      fbTargetting: "fbTargetting",
      fbTargettingWithQuestionNumber: "fbTargetting/:questionNumber",
      googleTargetting: "googleTargetting",
      googleTargettingWithQuestionNumber: "googleTargetting/:questionNumber",
      aboutBusiness: "aboutBusiness",
      aboutBusinessWithQuestionNumber: "aboutBusiness/:questionNumber",
      brandInfo: "brandInfo",
      brandInfoWithQuestionNumber: "brandInfo/:questionNumber",
    },
  },
  strategyGoogle: {
    fullbase: `${BASE}/strategy-creation-google`,
    root: `strategy-creation-google`, // should matches the end of fullbase
    sub: {
      generationType: {
        selectFlow: "",
        again: ":again",
      },
      guidedFlow: {
        selector: "guided",
        googleSearch: {
          strategiesList: "guided/google-search/strategies",
          adsetGroupLevel: "guided/google-search/:strategyId",
        },
        googlePmax: {
          strategiesList: "guided/strategies",
          adgroupLevel: "guided/:strategyId",
          segmentLevel: "guided/segment/:segmentId",
        },
      },
      automatedFlow: {
        strategiesList: "automated/strategies",
        adgroupLevel: "automated/search/:strategyId",
        assetgroupLevel: "automated/pmax/:strategyId",
        segmentLevel: "automated/segment/:segmentId",
      },
    },
  },
  strategyFacebook: {
    fullbase: `${BASE}/strategy-creation-facebook`,
    root: `strategy-creation-facebook`, // should matches the end of fullbase
    sub: {
      generationType: "",
      strategiesList: "strategies/:strategyFlow",
      adsetLevel: "strategies/:strategyFlow/:strategyId",
    },
  },
  strategyDeck: {
    fullbase: `${BASE}/strategy-deck`,
    root: `strategy-deck`, // should matches the end of fullbase
    sub: {
      introduction: "",
      google: "google",
      facebook: "facebook",
      suggestChanges: "suggest-changes",
    },
  },
});

export default bpAmRoutePaths;
