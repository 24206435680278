import { v4 as uuid } from 'uuid';
import { ChartFilterSupport } from '../Analytics/Charts/ChartUtils';
import { ChannelType, GetChannel } from "./channel";

export class Filter {
  constructor(name = "", segment = null, dimensionFilters = [], metricFilters = [], filterId) {
    this.channelType = null;
    this.name = name;
    this.segment = segment;
    this.dimensionFilters = dimensionFilters;
    this.metricFilters = metricFilters;
    this.id = filterId;
  }

  static copy(filter) {
    if (!filter) { return null; }
    return Filter.new(
      filter,
      filter.name,
      filter.segment ? JSON.parse(JSON.stringify(filter.segment)) : null,
      filter.dimensionFilters.map(d => ({ ...d })),
      filter.metricFilters.map(m => ({ ...m })),
      filter.id
    );
  }

  isEqualAll(filter) {
    let segmentEquality = this.segment?.id === filter.segment?.id;
    if (Array.isArray(this.segment)) {
      segmentEquality = this.segment.every((v, i) => v?.id === filter.segment[i]?.id);
    }
    return (
      this.id === filter.id && this.name === filter.name &&
      segmentEquality &&
      this.dimensionFilters.length === filter.dimensionFilters.length &&
      this.dimensionFilters.every((v, i) => {
        const dimFilter = filter.dimensionFilters[i]
        return v.boolOperator === dimFilter.boolOperator &&
          v.filter?.id === dimFilter.filter?.id &&
          v.operator?.type === dimFilter.operator?.type &&
          v.value.length === dimFilter.value.length &&
          v.value.every((arr, i) => arr === dimFilter.value[i])
      }) &&
      this.metricFilters.length === filter.metricFilters.length &&
      this.metricFilters.every((v, i) => v === filter.metricFilters[i])
    );
  }

  isEqual(filter) {
    return (
      this.id === filter.id
    );
  }

  isEmpty() {
    let segmentEmpty = !this.segment;
    if (Array.isArray(this.segment)) {
      segmentEmpty = this.segment.filter(attr => attr).length === 0;
    }
    return segmentEmpty && this.dimensionFilters.length === 0 && this.metricFilters.length === 0;
  }

  toString() {
    if (this.isEmpty()) { return null; }
    return `Filter: ${this.name}`;
  }

  validate() {
    // GetChannel(this.channelType)?.transformer?.validateFilter?.(this);
    if (this.channelType === ChannelType.FacebookAds && !this.segment) {
      this.segment = [null, null, null];
    }
  }

  toJSON() {
    if (this.isEmpty()) { return null; }
    return {
      id: this.id,
      name: this.name,
      segment: this.segment,
      dimensionFilters: this.dimensionFilters.map(f => f),
      metricFilters: this.metricFilters.map(f => f),
    }
  }

  toJSONfiltered() {
    if (this.isEmpty()) { return null; }
    return {
      id: this.id
    }
  }

  static fromJSON(json) {
    if (!json) { return null; }
    return new Filter(
      json.name,
      json.segment,
      json.dimensionFilters,
      json.metricFilters,
      json.id
    );
  }

  static new(chart, ...params) {
    const filter = new Filter(...params);
    filter.channelType = chart.channelType;
    filter.validate();
    return filter;
  }

}

export const NEW_FILTER = {
  boolOperator: null,
  filter: null,
  operator: null,
  value: "",
};

export class Metric {
  constructor(metric, filter = new Filter()) {
    this.channelType = null;
    this.metric = metric;
    this.filter = filter;
  }

  static copy(metric) {
    const newMetric = new Metric({ ...metric.metric }, Filter.copy(metric.filter));
    newMetric.channelType = metric.channelType;
    return newMetric;
  }

  isEqual(metric) {
    return (
      this.metric?.id === metric.metric?.id &&
      this.filter.isEqualAll(metric.filter)
    );
  }

  getFilterString() {
    const filterString = this.filter.toString();
    if (filterString) {
      return `Metric: ${this.metric.name}, ${filterString}`
    }
    return null;
  }

  validate() {
    this.filter.channelType = this.channelType;
    this.filter.validate();
  }

  toJSON() {
    return {
      id: this.metric?.id,
      dataSource: this.metric?.dataSource,
      filter: this.filter?.toJSON(),
    }
  }

  toJSONfiltered() {
    return {
      id: this.metric?.id,
      dataSource: this.metric?.dataSource,
      filter: this.filter?.id ? this.filter?.toJSONfiltered() : this.filter?.toJSON(),
    }
  }

  static fromJSON(json) {
    const metric = new Metric(
      { id: json.id, dataSource: json.dataSource },
    );
    const filter = Filter.fromJSON(json.filter);
    if (filter) { metric.filter = filter; }
    return metric;
  }

  static new(chart, metric) {
    const newMetric = new Metric(metric);
    newMetric.channelType = chart.channelType;
    newMetric.validate();
    return newMetric;
  }

}

export const ABSOLUTE_METRICS = {
  "google-analytics": ["INTEGER", "CURRENCY", "FLOAT"],
  "shopify-ads": ["FLOAT", "INTEGER"],
  "google-ads": ["INTEGER", "FLOAT", "CURRENCY"],
  "facebook-ads": ["Price", "Number"],
  "linkedin-ads": ["CURRENCY", "INTEGER", "FLOAT"],
  "bing-ads": ["FLOAT", "INTEGER", "CURRENCY"],
  "default": ["INTEGER", "CURRENCY", "FLOAT", "Price", "Number"]
}

export const DATE_METRICS = ["Date", "Day", "Hour", "Week", "Month", "Quarter", "Year", "Hour of day", "Week of Year"]

export class Chart {
  constructor(
    channelType,
    title,
    type,
    table,
    gridPosition = { x: 0, y: 0, w: 0, h: 0, static: false },
    leftMetrics = [],
    rightMetrics = type === "LINE" || type === "BAR" || type === "STACKBAR" || type === "LINEBAR" || type === "AREA" ? [] : null,
    dimensions = (type === "KPI" || type === "PIE" || type === "DONUT" || type === "FUNNEL") ? null
      : ChartFilterSupport(type) ? [] : [GetChannel(channelType)?.properties?.dateDimension],
    filter = ChartFilterSupport(type) ? new Filter() : null,
    chartStyle = { legendPosition: 'bottom', legendStyle: { fontStyle: 'Inter', fontSize: 16 }, palette: 0, titleStyle: { font: 'Inter', fontSize: '16', alignment: 'left', color: '#000000', fontFormat: ['bold'] }, tableStyle: { tableHeader: { font: 'Inter', fontSize: 14 }, tableContent: { font: 'Inter', fontSize: 14 } } },
    gridId = "",
    compareWith = ""
  ) {
    this.channelType = channelType;
    this.title = title;
    this.type = type;
    this.table = table;
    this.gridPosition = gridPosition;
    this.leftMetrics = leftMetrics;
    this.rightMetrics = rightMetrics;
    this.dimensions = dimensions;
    this.filter = filter;
    this.chartStyle = chartStyle;
    this.id = uuid();
    this.gridId = gridId;
    this.compareWith = compareWith;
    this.validate();
  }

  copyValues(chart) {
    this.channelType = chart.channelType;
    this.title = chart.title;
    this.type = chart.type;
    this.table = chart.table;
    this.gridPosition = { ...chart.gridPosition };
    this.leftMetrics = chart.leftMetrics.map(m => Metric.copy(m));
    this.rightMetrics = chart.rightMetrics?.map(m => Metric.copy(m));
    this.dimensions = chart.dimensions?.map(d => ({ ...d }));
    this.filter = Filter.copy(chart.filter);
    this.chartStyle = chart.chartStyle;
    this.compareWith = chart.compareWith;
  }

  validate() {
    this.leftMetrics.forEach(metric => {
      metric.channelType = this.channelType;
      metric.validate();
    });
    this.rightMetrics?.forEach(metric => {
      metric.channelType = this.channelType;
      metric.validate();
    });
    if (this.filter) {
      this.filter.channelType = this.channelType;
      this.filter.validate();
    }
  }

  isEqual(chart) {
    if (!chart) { return false; }
    return (
      this.channelType === chart.channelType &&
      this.title === chart.title &&
      this.type === chart.type &&
      this.table?.id === chart.table?.id &&
      this.leftMetrics.length === chart.leftMetrics.length && this.leftMetrics.every((metric, index) => metric.isEqual(chart.leftMetrics[index])) &&
      (this.rightMetrics ? this.rightMetrics.length === chart.rightMetrics.length && this.rightMetrics.every((metric, index) => metric.isEqual(chart.rightMetrics[index])) : chart.rightMetrics === null) &&
      (this.dimensions ? this.dimensions.length === chart.dimensions.length && this.dimensions.every((dimension, index) => Chart.isDimensionEqual(dimension, chart.dimensions[index])) : chart.dimensions === null) &&
      (this.filter ? this.filter.isEqual(chart.filter) : chart.filter === null)
    );
  }

  toJSON() {
    return {
      id: this.id,
      gridId: this.gridId,
      channel: this.channelType,
      title: this.title,
      type: this.type,
      table: this.table?.id,
      gridPosition: { x: this.gridPosition.x, y: this.gridPosition.y, w: this.gridPosition.w, h: this.gridPosition.h },
      leftMetrics: this.leftMetrics.map(m => m.toJSON()),
      rightMetrics: this.rightMetrics?.map(m => m.toJSON()),
      dimensions: this.dimensions?.filter(d => d).map(d => ({ id: d.id, dataSource: d.dataSource })),
      filter: this.filter?.toJSON(),
      chartStyle: this.chartStyle,
      compareWith: this.compareWith || ""
    }
  }

  toJSONfiltered() {
    return {
      id: this.id,
      gridId: this.gridId,
      channel: this.channelType,
      title: this.title,
      type: this.type,
      table: this.table?.id,
      gridPosition: { x: this.gridPosition.x, y: this.gridPosition.y, w: this.gridPosition.w, h: this.gridPosition.h },
      leftMetrics: this.leftMetrics.map(m => m.toJSONfiltered()),
      rightMetrics: this.rightMetrics?.map(m => m.toJSONfiltered()),
      dimensions: this.dimensions?.filter(d => d).map(d => ({ id: d.id, dataSource: d.dataSource })),
      filter: this.filter?.toJSONfiltered(),
      chartStyle: this.chartStyle,
      compareWith: this.compareWith || ""
    }
  }

  static fromJSON(json) {
    const chart = new Chart(
      json.channel,
      json.title,
      json.type,
      { id: json.table },
      { ...json.gridPosition, static: false },
      json.leftMetrics.map(m => Metric.fromJSON(m)),
      json.rightMetrics?.map(m => Metric.fromJSON(m)),
      !json.dimensions ? null : typeof (json.dimensions[0]) === "object" ? json.dimensions : json.dimensions.map(d => ({ id: d })),
    );
    chart.gridId = json.gridId;
    if (!chart.table.id) { chart.table = null; }
    const filter = Filter.fromJSON(json.filter);
    if (filter) { chart.filter = filter; }
    chart.id = json.id;
    chart.chartStyle = json.chartStyle;
    chart.compareWith = json.compareWith;
    chart.validate();
    return chart;
  }

  static new(...params) {
    if (params.length > 0) {
      return new Chart(...params);
    }
    return new Chart(
      ChannelType.Default,
      "",
      "ADD",
      null,
      { x: 0, y: 0, w: 6, h: 2, static: false }
    );
  }

  static isDimensionEqual(baseDimension, changeDimension) {
    return baseDimension?.id === changeDimension?.id && baseDimension?.dataSource === changeDimension?.dataSource;
  }

}
