import { questionairre_icon as questionaireIcon } from "../assets/icons/icon";
import { readaccess_icon as readAccessIcon } from "../assets/icons/icon";
import { proposal_icon as proposalIcon } from "../assets/icons/icon";
import { complete_access_icon as completeAccessIcon } from "../assets/icons/icon";
import { form_icon as formIcon } from "../assets/icons/icon";
import { strategy_icon as strategyIcon } from "../assets/icons/icon";
import { approval_icon as approvalIcon } from "../assets/icons/icon";
import { onboarding as onboardingSvg } from "../assets/icons/common/common";
import { audit as auditSvg } from "../assets/icons/common/common";
import { account_proposal as accProposalIcon } from "../assets/icons/common/common";
import { google_strategy_icon as googleIcon } from "../assets/icons/common/common";
import { facebook_strategy as facebookIcon } from "../assets/icons/common/common";
import { proposalIcon as proposalInputIcon } from "../assets/icons/icon";

import { google_pricing_icon as googlePriceIcon } from "../assets/icons/icon";
import { facebook_pricing_icon as facebookPricingIcon } from "../assets/icons/icon";
import { cro_pricing_icon as croPricingIcon } from "../assets/icons/icon";
import { pricing_icon as pricingIcon } from "../assets/icons/icon";

import {
  accountManagerIds,
  accountManagerIdsHDM,
  componentWithIndexHDMAm,
  ldComponentWithIndexAm,
} from "./accountManagerUtil";
import {
  clientDashboardIds,
  LDcomponentWithIndexClient,
} from "./clientDashboardIdsUtil";
import {
  clientDashboardIds as HDMclientDashboardIds,
  componentWithIndexHDMClient,
} from "./HDMClientDashboardIdsUtil";

export const listOfIds = {
  questionnaire: "questionnaire",
  readAcess: "read-access",
  proposal: "proposal",
  completeAccess: "complete-access",
  form: "form",
  strategy: "strategy_deck_google",
  approval: "approval",
  questBasicInform: "QUEST_BASIC_INFORMATION",
  questPlatformInform: "QUEST_PLATFORM_INFORMATION",
  questGrowthGoals: "QUEST_GROWTH_GOALS",
  proposalProposal: "PROPOSAL_PROPOSAL",
  proposalChangesInProposal: "PROPOSAL_CHANGES_IN_PROPOSAL",
  accountClientDetails: "ACCOUNT_CLIENT_DETAILS",
  accountOnboarding: "ACCOUNT_ONBOARDING",
  accountScopeOfWorkAndPricing: "ACCOUNT_SCOPE_OF_WORK_AND_PRICING",
  pricing: "QUEST_PRICING",
  audit: "AUDIT",
  auditGoogle: "GOOGLE_AUDIT",
  proposalInputs: "PROPOSAL_INPUTS",
  changesSuggested: "CHANGES_SUGGESTED",
  strategyInput: "STRATEGY_INPUT",
  strategyGoogle: "STRATEGY_GOOGLE",
  strategyFacebook: "STRATEGY_FACEBOOK",
  strategyDeck: "STRATEGY_DECK",
  intakeFormProductInfo: "INTAKE_FORM_PRODUCT_INFO",
  intakeFormPreferences: "INTAKE_FORM_PREFERENCES",
  intakeFormAudience: "INTAKE_FORM_AUDIENCE",
  intakeFormBranding: "INTAKE_FORM_BRANDING",
  intakeFormSocialProof: "INTAKE_FORM_SOCIAL_PROOF",
  strategyDeckIntro: "STRATEGY_DECK_INTRO",
  strategyDeckGoogle: "STRATEGY_DECK_GOOGLE",
  strategyDeckSuggestChanges: "STRATEGY_DECK_SUGGEST_CHANGES",
  reviewIntakeForm: "REVIEW_INTAKE_FORM",
  brandCampaign: "BRAND_CAMPAIGN",
  competitorCampaign: "COMPETITOR_CAMPAIGN",
  serviceCampaign: "SERVICE_CAMPAIGN",
  STRATEGY_INPUT_PMAX: "STRATEGY_INPUT_PMAX",
  STRATEGY_INPUT_SEARCH: "STRATEGY_INPUT_SEARCH",
  PMAX_STRATEGY_CAMPAIGN_1: "PMAX_STRATEGY_CAMPAIGN_1",
  PMAX_STRATEGY_CAMPAIGN_2: "PMAX_STRATEGY_CAMPAIGN_2",
  PMAX_STRATEGY_CAMPAIGN_3: "PMAX_STRATEGY_CAMPAIGN_3",
  sharedAssets: "SHARED_ASSETS",
  accessSharing: "ACCESS_SHARING",
  sharedAssetsComplete: "SHARED_ASSETS_COMPLETE",
  campaignForGoogle: "CAMPAIGN_FOR_GOOGLE",
  CAMPAIGN_PREVIEW: "CAMPAIGN_PREVIEW",
  AnalyticDashboard: "AnalyticDashboard",
  facebook: "facebook",
  facebookTargeting: "FB_TARGETING",
  creativeApproval: "CREATIVE_APPROVAL",
  creativeApprovalAdcopies: "CREATIVE_APPROVAL_AD_COPIES",
  creativeApprovalCreatives: "CREATIVE_APPROVAL_CREATIVES",
  creativeApprovalLandingPages: "CREATIVE_APPROVAL_LANDING_PAGES",
  creativeAppovalAbTest: "CREATIVE_APPROVAL_AB_TEST",
  approval: "approval",
};
export const listOfIdsHDM = {
  CLIENTANDACCESS: "CLIENTANDACCESS",
  BRANDORMESSAGING: "BRANDORMESSAGING",
  FACEBOOKTARGETING: "FACEBOOKTARGETING",
  GOOGLETARGETING: "GOOGLETARGETING",
  ABOUTYOURBUSSINESS: "ABOUTYOURBUSSINESS",
  CREATIVEANDMONTAGEAD: "CREATIVEANDMNTAGEAD",
  accessSharing: "accessSharing",
  SELECTBRAND: "SELECT BRAND",
  ONBOARDINGOC: "ONBOARDINGDOC",
  ONBOARDING: "ONBOARDING",
  REVIEWBRIEINGDOC: "REVIEW BRIEFING DOC",
  EXPECTATIONS: "EXPECTATIONS",
  onboardingInfo: "onboardingInfo",
  AUDIT_FOR_GOOGLE: "AUDIT_FOR_GOOGLE",
  AUDIT: "AUDIT_FOR_FACEBOOK",
  AccountAnalysis: "AccountAnalysis",
  AnalyticDashboard: "AnalyticDashboard",
  strategyInput: "STRATEGY_INPUT",
  strategyGoogle: "STRATEGY_GOOGLE",
  strategyFacebook: "STRATEGY_FACEBOOK",
  strategyCreation: "strategyCreation",
  StrategyCreationGoogle: "StrategyCreationGoogle",
  StrategyCreationFacebook: "StrategyCreationFacebook",
  STRATEGY_DECK: "STRATEGY_DECK",
  Account: "AuditAccount",
  Campaign: "AuditCampaign",
  Adset: "AuditAdset",
  Ad: "AuditAd",
  proposalStatus: "proposalStatus",
  AccountAnalysisGoogle: "AccountAnalysisGoogle",
  AccountAnalysisFacebook: "AccountAnalysisFacebook",
  AccountAnalysisWebsite: "AccountAnalysisWebsite",
  AccountAnalysisCreatives: "AccountAnalysisCreatives",
  AccountAnalysisCompetitor: "AccountAnalysisCompetitor",
  STRATEGY_INPUT_PMAX: "STRATEGY_INPUT_PMAX",
  STRATEGY_INPUT_SEARCH: "STRATEGY_INPUT_SEARCH",
  PMAX_STRATEGY_CAMPAIGN_1: "PMAX_STRATEGY_CAMPAIGN_1",
  PMAX_STRATEGY_CAMPAIGN_2: "PMAX_STRATEGY_CAMPAIGN_2",
  PMAX_STRATEGY_CAMPAIGN_3: "PMAX_STRATEGY_CAMPAIGN_3",
  BRANDCAMPAIGN: "BRANDCAMPAIGN",
  COMPETITORCAMPAIGN: "COMPETITORCAMPAIGN",
  SERVICECAMPAIGN: "SERVICESCAMPAIGN",
  LOOKALIKE: "TOF-LOOKALIKE",
  INTEREST: "TOF-INTEREST",
  MOF: "MOF",
  BOF: "BOF",
  GOAL: "GOAL",
  ANALYSIS: "ANALYSIS",
  GOOGLESTRATEGY: "GOOGLESTRATEGY",
  FBSTRATEGY: "FBSTRATEGY",
  OURPLAN: "OURPLAN",
  BESTPRACTICES: "BESTPRACTICES",
  CHANGESSUGGESTED: "CHANGESSUGGESTED",
  StrategyDeck: "StrategyDeck",
  CampaignCreationFB: "CampaignCreationFB",
  CampaignCreationGoogle: "CampaignCreationGoogle",
  CampaignPreview: "CampaignPreview",
  AuditHdm: "AUDIT_FACEBOOK",
  CreativeApproval: "CreativeApproval",
};

//linear CLIENT
export const LeftSidebar = [
  // {
  //   imgIcon: questionaireIcon,
  //   name: 'Onboarding Form',
  //   status: 'Ongoing',
  //   isCurrentStep: true,
  //   id: listOfIds.questionnaire,
  // },
  // {
  //   imgIcon: proposalIcon,
  //   name: 'Pricing',
  //   status: 'To be unlocked',
  //   isCurrentStep: false,
  //   id: listOfIds.accountScopeOfWorkAndPricing,
  // },
  {
    imgIcon: readAccessIcon,
    name: "Read Access",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.readAcess,
  },
  {
    imgIcon: proposalIcon,
    name: "Proposal",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.proposal,
  },
  {
    imgIcon: formIcon,
    name: "Intake Form",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.form,
  },
  {
    imgIcon: completeAccessIcon,
    name: "Complete Access",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.completeAccess,
  },
  {
    imgIcon: strategyIcon,
    name: "Strategy Deck",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.strategy,
  },
  {
    imgIcon: googleIcon,
    name: "Campaign Preview",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.CAMPAIGN_PREVIEW,
  },
  {
    imgIcon: proposalIcon,
    name: "Creative Approval",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.creativeApproval,
  },
  {
    imgIcon: proposalIcon,
    name: "Analytics Dashboard",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.AnalyticDashboard,
  },
];

//hdm
export const HDMCLientLeftSidebar = [
  {
    imgIcon: questionaireIcon,
    name: "Onboarding Form",
    status: "Completed",
    isCurrentStep: true,
    id: listOfIdsHDM.CLIENTANDACCESS,
  },
  {
    imgIcon: readAccessIcon,
    name: "Access Sharing",
    status: "Ongoing",
    isCurrentStep: false,
    id: listOfIdsHDM.accessSharing,
  },
  {
    imgIcon: proposalIcon,
    name: "Onboarding Doc",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.ONBOARDING,
  },
  {
    imgIcon: strategyIcon,
    name: "Strategy Deck",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.STRATEGY_DECK,
  },
  {
    imgIcon: googleIcon,
    name: "Campaign Preview",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.CampaignPreview,
  },
  {
    imgIcon: proposalIcon,
    name: "Analytics Dashboard",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.AnalyticDashboard,
  },
];

export const headerTabs = {
  ["BASIC INFORMATION"]: "BASIC INFORMATION",
  ["PLATFORM INFORMATION"]: "PLATFORM INFORMATION",
  ["GROWTH GOALS"]: "GROWTH GOALS",
  ["GOOGLE ADS SETUP"]: "GOOGLE ADS SETUP",
  ["FACEBOOK ADS SETUP"]: "FACEBOOK ADS SETUP",
  ["CRO SETUP"]: "CRO SETUP",
  // ['PRICING']: 'PRICING',
  ["PROPOSAL"]: "PROPOSAL",
  ["SUGGEST CHANGES"]: "SUGGEST CHANGES",
  ["INTODUCTION"]: "INTRODUCTION",
  ["GOOGLE"]: "GOOGLE",
  ["SUGGEST CHANGES"]: "SUGGEST CHANGES",
  ["PRODUCT INFO"]: "PRODUCT INFO",
  ["PREFERENCES"]: "PREFERENCES",
  ["AUDIENCE"]: "GOOGLE TARGETING",
  ["BRANDING"]: "BRANDING",
  ["SOCIAL PROOF"]: "SOCIAL PROOF",
  ["FB TARGETING"]: "FB TARGETING",
  ["FACEBOOK"]: "FACEBOOK",
  ["AD COPIES"]: "AD COPIES",
  ["CREATIVES"]: "CREATIVES",
  ["LANDING PAGES"]: "LANDING PAGES",
  ["AB TEST"]: "AB TEST",
};

export const HDMHeaderTabs = {
  ["CLIENTANDACCESS"]: "CLIENT & ACCESS",
  ["BRANDORMESSAGING"]: "BRAND OR MESSAGING",
  ["FACEBOOKTARGETING"]: "FACEBOOK TARGETING",
  ["GOOGLETARGETING"]: "GOOGLE TARGETING",
  ["ABOUTYOURBUSSINESS"]: "ABOUT YOUR BUSINESS",
  ["CREATIVEANDMONTAGEAD"]: "CREATIVES",
  ["ONBOARDING"]: "ONBOARDING",
  ["REVIEWBRIEFINGDOCUMENT"]: "REVIEW BRIEFING DOCUMENT",
  ["EXPECTATIONS"]: "EXPECTATIONS",
  ["SUGGESTCHANGES"]: "SUGGEST CHANGES",
  ["GOOGLE_ADS_AUDIT"]: "GOOGLE ADS",
  ["ACCOUNT"]: "ACCOUNT",
  ["CAMPAIGN"]: "CAMPAIGN",
  ["ADSET"]: "ADSET",
  ["AD"]: "AD",
  [listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_1]: "CAMPAIGN 1",
  [listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_2]: "CAMPAIGN 2",
  [listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_3]: "CAMPAIGN 3",
  ["GOOGLE"]: "GOOGLE",
  ["FACEBOOK"]: "FACEBOOK",
  ["WEBSITE"]: "WEBSITE",
  ["CREATIVES"]: "CREATIVES",
  ["COMPETITOR"]: "COMPETITOR",
  ["COMPETITIOR"]: "COMPETITIOR",
  ["BRANDCAMPAIGN"]: "BRAND CAMPAIGN",
  ["COMPETITORCAMPAIGN"]: "COMPETITOR CAMPAIGN",
  ["SERVICECAMPAIGN"]: "SERVICES CAMPAIGN",
  ["LOOKALIKE"]: "TOF-LOOKALIKE",
  ["INTEREST"]: "TOF-INTEREST",
  ["MOF"]: "MOF",
  ["BOF"]: "BOF",
  ["GOAL"]: "GOAL",
  ["ANALYSIS"]: "ANALYSIS",
  ["GOOGLESTRATEGY"]: "GOOGLE STRATEGY",
  ["FBSTRATEGY"]: "FB STRATEGY",
  ["OURPLAN"]: "OUR PLAN",
  ["BESTPRACTICES"]: "BEST PRACTICES",
  ["CHANGESSUGGESTED"]: "CHANGES SUGGESTED",
};

//hdm client
export const HDMHeaderNavbar = {
  [listOfIdsHDM.CLIENTANDACCESS]: {
    [listOfIdsHDM.CLIENTANDACCESS]: {
      name: HDMHeaderTabs["CLIENTANDACCESS"],
      isActive: true,
      id: componentWithIndexHDMClient[HDMclientDashboardIds.CLIENTANDACCESS1],
    },
    [listOfIdsHDM.BRANDORMESSAGING]: {
      name: HDMHeaderTabs["BRANDORMESSAGING"],
      isActive: false,
      id: componentWithIndexHDMClient[HDMclientDashboardIds.BRANDORMESSAGING1],
    },
    [listOfIdsHDM.FACEBOOKTARGETING]: {
      name: HDMHeaderTabs["FACEBOOKTARGETING"],
      isActive: false,
      id: componentWithIndexHDMClient[HDMclientDashboardIds.TARGETAUDIENCE1],
    },
    [listOfIdsHDM.GOOGLETARGETING]: {
      name: HDMHeaderTabs["GOOGLETARGETING"],
      isActive: false,
      id: componentWithIndexHDMClient[HDMclientDashboardIds.GOOGLETARGETING1],
    },
    [listOfIdsHDM.ABOUTYOURBUSSINESS]: {
      name: HDMHeaderTabs["ABOUTYOURBUSSINESS"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.ABOUTYOURBUSSINESS1
      ],
    },
    [listOfIdsHDM.CREATIVEANDMONTAGEAD]: {
      name: HDMHeaderTabs["CREATIVEANDMONTAGEAD"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.CREATIVEANDMONTAGEAD1
      ],
    },
  },
  [listOfIdsHDM.accessSharing]: {},
  [listOfIdsHDM.ONBOARDING]: {
    [listOfIdsHDM.ONBOARDING]: {
      name: HDMHeaderTabs["ONBOARDING"],
      isActive: true,
      id: componentWithIndexHDMClient[HDMclientDashboardIds.HDM_ONBOARDING],
    },
    [listOfIdsHDM.REVIEWBRIEINGDOC]: {
      name: HDMHeaderTabs["REVIEWBRIEFINGDOCUMENT"],
      isActive: true,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.HDM_ONBOARDING_REVIEW
      ],
    },
    [listOfIdsHDM.EXPECTATIONS]: {
      name: HDMHeaderTabs["EXPECTATIONS"],
      isActive: true,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.HDM_ONBOARDING_COMMUNICATION
      ],
    },
  },
  [listOfIdsHDM.STRATEGY_DECK]: {
    [listOfIdsHDM.GOAL]: {
      name: HDMHeaderTabs["GOAL"],
      isActive: true,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.HDM_STRATEGY_DECK_PAGE
      ],
    },
    [listOfIdsHDM.ANALYSIS]: {
      name: HDMHeaderTabs["ANALYSIS"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.HDM_ANAYLSIS_DECK_PAGE
      ],
    },
    [listOfIdsHDM.GOOGLESTRATEGY]: {
      name: HDMHeaderTabs["GOOGLESTRATEGY"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.HDM_STRATEGY_DECK_GOOGLE_PAGE
      ],
    },
    [listOfIdsHDM.FBSTRATEGY]: {
      name: HDMHeaderTabs["FBSTRATEGY"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.HDM_STRATEGY_DECK_FACEBOOK_PAGE
      ],
    },
    [listOfIdsHDM.OURPLAN]: {
      name: HDMHeaderTabs["OURPLAN"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.HDM_STRATEGY_DECK_OURPLAN_PAGE
      ],
    },
    [listOfIdsHDM.BESTPRACTICES]: {
      name: HDMHeaderTabs["BESTPRACTICES"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.HDM_STRATEGY_DECK_BESTPRACTICE_PAGE
      ],
    },
    [listOfIdsHDM.CHANGESSUGGESTED]: {
      name: HDMHeaderTabs["CHANGESSUGGESTED"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.HDM_STRATEGY_DECK_SUGGESTCHANGES_PAGE
      ],
    },
  },
  [listOfIdsHDM.CampaignPreview]: {},
};

//linear client
export const headerNavbar = {
  [listOfIds.questionnaire]: {
    [listOfIds.questBasicInform]: {
      name: headerTabs["BASIC INFORMATION"],
      isActive: true,
      id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST1],
    },
    [listOfIds.questPlatformInform]: {
      name: headerTabs["PLATFORM INFORMATION"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.PLATFORM_INFORMATION_QUEST1
      ],
    },
    [listOfIds.questGrowthGoals]: {
      name: headerTabs["GROWTH GOALS"],
      isActive: false,
      id: LDcomponentWithIndexClient[clientDashboardIds.GROWTH_GOALS_QUEST1],
    },
  },
  [listOfIds.accountScopeOfWorkAndPricing]: {},
  [listOfIds.readAcess]: {},
  [listOfIds.proposal]: {
    [listOfIds.proposalProposal]: {
      name: headerTabs["PROPOSAL"],
      isActive: true,
      id: LDcomponentWithIndexClient[clientDashboardIds.PROPOSAL_TAB],
    },
    [listOfIds.proposalChangesInProposal]: {
      name: headerTabs["SUGGEST CHANGES"],
      isActive: false,
      id: LDcomponentWithIndexClient[clientDashboardIds.SUGGEST_CHANGES_TAB],
    },
  },
  [listOfIds.form]: {
    [listOfIds.intakeFormProductInfo]: {
      name: headerTabs["PRODUCT INFO"],
      isActive: true,
      id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM1],
    },
    [listOfIds.intakeFormPreferences]: {
      name: headerTabs["PREFERENCES"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES1
      ],
    },
    [listOfIds.intakeFormAudience]: {
      name: headerTabs["AUDIENCE"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE1
      ],
    },
    [listOfIds.intakeFormBranding]: {
      name: headerTabs["BRANDING"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_BRANDING1
      ],
    },
    [listOfIds.intakeFormSocialProof]: {
      name: headerTabs["SOCIAL PROOF"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF1
      ],
    },
    [listOfIds.facebookTargeting]: {
      name: headerTabs["FB TARGETING"],
      isActive: false,
      id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING1],
    },
  },
  [listOfIds.completeAccess]: {},
  [listOfIds.strategy]: {
    [listOfIds.strategyDeckIntro]: {
      name: headerTabs["INTODUCTION"],
      isActive: true,
      id: LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_INTRO],
    },
    [listOfIds.strategyDeckGoogle]: {
      name: headerTabs["GOOGLE"],
      isActive: false,
      id: LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_GOOGLE],
    },
    [listOfIds.facebook]: {
      name: headerTabs["FACEBOOK"],
      isActive: false,
      id: LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_FACEBOOK],
    },
    [listOfIds.strategyDeckSuggestChanges]: {
      name: headerTabs["SUGGEST CHANGES"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.STRATEGY_DECK_SUGGEST_CHANGES
      ],
    },
  },
  [listOfIds.approval]: {},
  [listOfIds.CAMPAIGN_PREVIEW]: {},
  [listOfIds.creativeApproval]: {
    [listOfIds.creativeApprovalAdcopies]: {
      name: headerTabs["AD COPIES"],
      isActive: true,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.CREATIVE_APPROVAL_ADCOPIES
      ],
    },
    [listOfIds.creativeApprovalCreatives]: {
      name: headerTabs["CREATIVES"],
      isActive: true,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.CREATIVE_APPROVAL_CREATIVES
      ],
    },
    [listOfIds.creativeApprovalLandingPages]: {
      name: headerTabs["LANDING PAGES"],
      isActive: true,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.CREATIVE_APPROVAL_LANDING_PAGES
      ],
    },
    [listOfIds.creativeAppovalAbTest]: {
      name: headerTabs["AB TEST"],
      isActive: true,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.CREATIVE_APPROVAL_AB_TEST
      ],
    },
  },
};

//hdm am
export const HDMAccountLeftSideBar = [
  {
    imgIcon: readAccessIcon,
    name: "Client Onboarding Inputs",
    status: "Done",
    isCurrentStep: false,
    id: listOfIdsHDM.onboardingInfo,
  },
  {
    imgIcon: proposalInputIcon,
    name: "Access Sharing",
    status: "Ongoing",
    isCurrentStep: true,
    id: listOfIdsHDM.accessSharing,
  },
  {
    imgIcon: proposalIcon,
    name: "Onboarding Doc",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.ONBOARDINGOC,
  },
  {
    imgIcon: auditSvg,
    name: "Audit For Google",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.AUDIT_FOR_GOOGLE,
  },
  {
    imgIcon: auditSvg,
    name: "Audit For Facebook",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.AUDIT,
  },
  {
    imgIcon: proposalIcon,
    name: "Account Analysis",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.AccountAnalysis,
  },
  {
    imgIcon: strategyIcon,
    name: "Strategy Creation Google",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.strategyInput,
  },
  {
    imgIcon: strategyIcon,
    name: "Strategy Creation Facebook",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.StrategyCreationFacebook,
  },
  {
    imgIcon: googleIcon,
    name: "Strategy Deck",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.STRATEGY_DECK,
  },
  {
    imgIcon: googleIcon,
    name: "Campaign Creation (Google)",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.CampaignCreationGoogle,
  },
  {
    imgIcon: googleIcon,
    name: "Campaign Creation (facebook)",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.CampaignCreationFB,
  },
  {
    imgIcon: googleIcon,
    name: "Campaign Preview",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.CampaignPreview,
  },
  {
    imgIcon: proposalIcon,
    name: "Analytics Dashboard",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.AnalyticDashboard,
  },
];

//linear am
export const AccountLeftSideBar = [
  {
    imgIcon: readAccessIcon,
    name: "Client Onboarding Inputs",
    status: "Done",
    isCurrentStep: false,
    id: listOfIds.accountOnboarding,
  },
  {
    imgIcon: proposalIcon,
    name: "Pricing",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.accountScopeOfWorkAndPricing,
  },
  {
    imgIcon: readAccessIcon,
    name: "Read Shared Assets",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.sharedAssets,
  },
  // {
  //   imgIcon: proposalInputIcon,
  //   name: 'Proposal Inputs',
  //   status: 'To be unlocked',
  //   isCurrentStep: false,
  //   id: listOfIds.proposalInputs,
  // },
  {
    imgIcon: auditSvg,
    name: "Audit For Google",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.audit,
  },
  {
    imgIcon: auditSvg,
    name: "Audit For Facebook",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.AuditHdm,
  },
  {
    imgIcon: accProposalIcon,
    name: "Proposal",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.proposal,
  },
  {
    imgIcon: accProposalIcon,
    name: "Review Intake Form",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.reviewIntakeForm,
  },
  {
    imgIcon: accProposalIcon,
    name: "Complete Shared Assets",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.sharedAssetsComplete,
  },
  {
    imgIcon: strategyIcon,
    name: "Strategy Creation Google",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.strategyInput,
  },
  {
    imgIcon: strategyIcon,
    name: "Strategy Creation Facebook",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.StrategyCreationFacebook,
  },
  {
    imgIcon: strategyIcon,
    name: "Strategy Deck",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.strategy,
  },
  {
    imgIcon: googleIcon,
    name: "Campaign Creation Google",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.campaignForGoogle,
  },
  {
    imgIcon: googleIcon,
    name: "Campaign Creation Facebook",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIdsHDM.CampaignCreationFB,
  },
  {
    imgIcon: proposalIcon,
    name: "Campaign Preview",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.CAMPAIGN_PREVIEW,
  },
  {
    imgIcon: proposalIcon,
    name: "Creative Approval",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.creativeApproval,
  },
  {
    imgIcon: proposalIcon,
    name: "Analytics Dashboard",
    status: "To be unlocked",
    isCurrentStep: false,
    id: listOfIds.AnalyticDashboard,
  },
];

export const headerTabsAccountManager = {
  ["ONBOARDING FORM"]: "ONBOARDING FORM",
  ["PROPOSER DETAILS"]: "PROPOSER DETAILS",
  ["REQUIREMENT DETAILS"]: "REQUIREMENT DETAILS",
  ["GOOGLE ADS SETUP"]: "GOOGLE ADS SETUP",
  ["FACEBOOK ADS SETUP"]: "FACEBOOK ADS SETUP",
  ["CRO SETUP"]: "CRO SETUP",
  ["PRICING"]: "PRICING",
  ["GOOGLE ADS AUDIT"]: "GOOGLE ADS",
  ["PROPOSAL"]: "PROPOSAL",
  ["CHANGES SUGGESTED"]: "CHANGES SUGGESTED",
  ["BRAND CAMPAIGN"]: "BRAND CAMPAIGN",
  ["COMPETITOR CAMPAIGN"]: "COMPETITOR CAMPAIGN",
  ["SERVICES CAMPAIGN"]: "SERVICES CAMPAIGN",
  [listOfIds.PMAX_STRATEGY_CAMPAIGN_1]: "CAMPAIGN 1",
  [listOfIds.PMAX_STRATEGY_CAMPAIGN_2]: "CAMPAIGN 2",
  [listOfIds.PMAX_STRATEGY_CAMPAIGN_3]: "CAMPAIGN 3",
  ["CAMPAIGN_2"]: "CAMPAIGN 2",
  ["CAMPAIGN 1"]: "CAMPAIGN 1",
  ["INTRODUCTION"]: "INTRODUCTION",
  ["GOOGLE"]: "GOOGLE",
  ["STRATEGY_SUGGEST_CHANGES"]: "SUGGESTED CHANGES",
  ["FACEBOOK"]: "FACEBOOK",
  ["LOOKALIKE"]: "TOF-LOOKALIKE",
  ["INTEREST"]: "TOF-INTEREST",
  ["MOF"]: "MOF",
  ["BOF"]: "BOF",
  ["AD COPIES"]: "AD COPIES",
  ["CREATIVES"]: "CREATIVES",
  ["LANDING PAGES"]: "LANDING PAGES",
  ["AB TEST"]: "AB TEST",
};

//header navbar hdm am
export const headerNavbarHDMAM = {
  [listOfIdsHDM.onboardingInfo]: {},
  [listOfIdsHDM.CLIENTANDACCESS]: {
    [listOfIdsHDM.CLIENTANDACCESS]: {
      name: HDMHeaderTabs["CLIENTANDACCESS"],
      isActive: true,
      id: componentWithIndexHDMClient[HDMclientDashboardIds.CLIENTANDACCESS1],
    },
    [listOfIdsHDM.BRANDORMESSAGING]: {
      name: HDMHeaderTabs["BRANDORMESSAGING"],
      isActive: false,
      id: componentWithIndexHDMClient[HDMclientDashboardIds.BRANDORMESSAGING1],
    },
    [listOfIdsHDM.FACEBOOKTARGETING]: {
      name: HDMHeaderTabs["FACEBOOKTARGETING"],
      isActive: false,
      id: componentWithIndexHDMClient[HDMclientDashboardIds.TARGETAUDIENCE1],
    },
    [listOfIdsHDM.GOOGLETARGETING]: {
      name: HDMHeaderTabs["GOOGLETARGETING"],
      isActive: false,
      id: componentWithIndexHDMClient[HDMclientDashboardIds.GOOGLETARGETING1],
    },
    [listOfIdsHDM.ABOUTYOURBUSSINESS]: {
      name: HDMHeaderTabs["ABOUTYOURBUSSINESS"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.ABOUTYOURBUSSINESS1
      ],
    },
    [listOfIdsHDM.CREATIVEANDMONTAGEAD]: {
      name: HDMHeaderTabs["CREATIVEANDMONTAGEAD"],
      isActive: false,
      id: componentWithIndexHDMClient[
        HDMclientDashboardIds.CREATIVEANDMONTAGEAD1
      ],
    },
  },
  [listOfIdsHDM.ONBOARDINGOC]: {
    [listOfIdsHDM.ONBOARDING]: {
      name: HDMHeaderTabs["ONBOARDING"],
      isActive: true,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.HDM_ONBOARDING],
    },
    [listOfIdsHDM.REVIEWBRIEINGDOC]: {
      name: HDMHeaderTabs["REVIEWBRIEFINGDOCUMENT"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.HDM_ONBOARDING_REVIEW],
    },
    [listOfIdsHDM.EXPECTATIONS]: {
      name: HDMHeaderTabs["EXPECTATIONS"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.HDM_ONBOARDING_COMMUNICATION],
    },
  },
  [listOfIdsHDM.AUDIT_FOR_GOOGLE]: {
    [listOfIds.auditGoogle]: {
      name: headerTabsAccountManager["GOOGLE ADS AUDIT"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.AUDIT_GOOGLE_ADS],
    },
  },
  [listOfIdsHDM.AUDIT]: {
    [listOfIdsHDM.Account]: {
      name: HDMHeaderTabs["ACCOUNT"],
      isActive: true,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_ACCOUNT],
    },
    [listOfIdsHDM.Campaign]: {
      name: HDMHeaderTabs["CAMPAIGN"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_CAMPAIGN],
    },
    [listOfIdsHDM.Adset]: {
      name: HDMHeaderTabs["ADSET"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_ADSET],
    },
    [listOfIdsHDM.Ad]: {
      name: HDMHeaderTabs["AD"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_AD],
    },
  },
  [listOfIdsHDM.AccountAnalysis]: {
    [listOfIdsHDM.AccountAnalysisGoogle]: {
      name: HDMHeaderTabs["GOOGLE"],
      isActive: true,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_GOOGLE],
    },
    [listOfIdsHDM.AccountAnalysisFacebook]: {
      name: HDMHeaderTabs["FACEBOOK"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_FACEBOOK],
    },
    [listOfIdsHDM.AccountAnalysisWebsite]: {
      name: HDMHeaderTabs["WEBSITE"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_WEBSITE],
    },
    [listOfIdsHDM.AccountAnalysisCreatives]: {
      name: HDMHeaderTabs["CREATIVES"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_CREATIVES],
    },
    [listOfIdsHDM.AccountAnalysisCompetitor]: {
      name: HDMHeaderTabs["COMPETITIOR"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_COMPETITOR],
    },
  },
  [listOfIdsHDM.strategyInput]: {
    [listOfIdsHDM.STRATEGY_INPUT_SEARCH]: {
      [listOfIdsHDM.BRANDCAMPAIGN]: {
        name: HDMHeaderTabs["BRANDCAMPAIGN"],
        isActive: false,
        // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_BRAND_CAMPAIGN],
      },
      [listOfIdsHDM.COMPETITORCAMPAIGN]: {
        name: HDMHeaderTabs["COMPETITORCAMPAIGN"],
        isActive: false,
        // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_COMPETITIER_CAMPAIGN],
      },
      [listOfIdsHDM.SERVICECAMPAIGN]: {
        name: HDMHeaderTabs["SERVICECAMPAIGN"],
        isActive: false,
        // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_SERVICE_CAMPAIGN],
      },
    },
    [listOfIdsHDM.STRATEGY_INPUT_PMAX]: {
      [listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_1]: {
        name: HDMHeaderTabs[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_1],
        isActive: false,
        // id: componentWithIndexHDMAm[accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_1],
      },
      [listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_2]: {
        name: HDMHeaderTabs[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_2],
        isActive: false,
        // id: componentWithIndexHDMAm[accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_2],
      },
      [listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_3]: {
        name: HDMHeaderTabs[listOfIdsHDM.PMAX_STRATEGY_CAMPAIGN_3],
        isActive: false,
        // id: componentWithIndexHDMAm[accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_3],
      },
    },
  },
  // [listOfIdsHDM.StrategyCreationGoogle]: {
  //   [listOfIdsHDM.BRANDCAMPAIGN]: {
  //     name: HDMHeaderTabs["BRANDCAMPAIGN"],
  //     isActive: true,
  // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_BRAND_CAMPAIGN],
  //   },
  //   [listOfIdsHDM.COMPETITORCAMPAIGN]: {
  //     name: HDMHeaderTabs["COMPETITORCAMPAIGN"],
  //     isActive: false,
  // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_COMPETITIER_CAMPAIGN],
  //   },
  //   [listOfIdsHDM.SERVICECAMPAIGN]: {
  //     name: HDMHeaderTabs["SERVICECAMPAIGN"],
  //     isActive: false,
  // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_GOOGLE_SERVICE_CAMPAIGN],
  //   },
  // },
  [listOfIdsHDM.StrategyCreationFacebook]: {
    [listOfIdsHDM.LOOKALIKE]: {
      name: HDMHeaderTabs["LOOKALIKE"],
      isActive: true,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE],
    },
    [listOfIdsHDM.INTEREST]: {
      name: HDMHeaderTabs["INTEREST"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_INTEREST],
    },
    [listOfIdsHDM.MOF]: {
      name: HDMHeaderTabs["MOF"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_MOF],
    },
    [listOfIdsHDM.BOF]: {
      name: HDMHeaderTabs["BOF"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_BOF],
    },
  },
  [listOfIdsHDM.STRATEGY_DECK]: {
    [listOfIdsHDM.GOAL]: {
      name: HDMHeaderTabs["GOAL"],
      isActive: true,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_GOAL],
    },
    [listOfIdsHDM.ANALYSIS]: {
      name: HDMHeaderTabs["ANALYSIS"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_ANALYSIS],
    },
    [listOfIdsHDM.GOOGLESTRATEGY]: {
      name: HDMHeaderTabs["GOOGLESTRATEGY"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_GOOGLE_STRATEGY],
    },
    [listOfIdsHDM.FBSTRATEGY]: {
      name: HDMHeaderTabs["FBSTRATEGY"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_FB_STRATEGY],
    },
    [listOfIdsHDM.OURPLAN]: {
      name: HDMHeaderTabs["OURPLAN"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_OUR_PLAN],
    },
    [listOfIdsHDM.BESTPRACTICES]: {
      name: HDMHeaderTabs["BESTPRACTICES"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_BEST_PRACTICES],
    },
    [listOfIdsHDM.CHANGESSUGGESTED]: {
      name: HDMHeaderTabs["CHANGESSUGGESTED"],
      isActive: false,
      // id: componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_CHANGES_SUGGESTED],
    },
  },
};

//linear am
export const headerNavbarAM = {
  [listOfIds.accountOnboarding]: {
    [listOfIds.questBasicInform]: {
      name: headerTabs["BASIC INFORMATION"],
      isActive: true,
      id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST1],
    },
    [listOfIds.questPlatformInform]: {
      name: headerTabs["PLATFORM INFORMATION"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.PLATFORM_INFORMATION_QUEST1
      ],
    },
    [listOfIds.questGrowthGoals]: {
      name: headerTabs["GROWTH GOALS"],
      isActive: false,
      id: LDcomponentWithIndexClient[clientDashboardIds.GROWTH_GOALS_QUEST1],
    },
  },
  [listOfIds.accountClientDetails]: {},
  [listOfIds.proposalInputs]: {
    [listOfIds.questBasicInform]: {
      name: headerTabsAccountManager["ONBOARDING FORM"],
      isActive: true,
    },
    [listOfIds.questPlatformInform]: {
      name: headerTabsAccountManager["PROPOSER DETAILS"],
      isActive: false,
    },
    [listOfIds.questGrowthGoals]: {
      name: headerTabsAccountManager["REQUIREMENT DETAILS"],
      isActive: false,
    },
  },
  [listOfIds.accountScopeOfWorkAndPricing]: {},
  [listOfIds.audit]: {
    [listOfIds.auditGoogle]: {
      name: headerTabsAccountManager["GOOGLE ADS AUDIT"],
      isActive: false,
    },
  },
  [listOfIdsHDM.AuditHdm]: {
    [listOfIdsHDM.Account]: {
      name: HDMHeaderTabs["ACCOUNT"],
      isActive: true,
    },
    [listOfIdsHDM.Campaign]: {
      name: HDMHeaderTabs["CAMPAIGN"],
      isActive: false,
    },
    [listOfIdsHDM.Adset]: {
      name: HDMHeaderTabs["ADSET"],
      isActive: false,
    },
    [listOfIdsHDM.Ad]: {
      name: HDMHeaderTabs["AD"],
      isActive: false,
    },
  },
  [listOfIds.proposal]: {
    [listOfIds.proposal]: {
      name: headerTabsAccountManager["PROPOSAL"],
      isActive: false,
    },
    [listOfIds.changesSuggested]: {
      name: headerTabsAccountManager["CHANGES SUGGESTED"],
      isActive: false,
    },
  },
  [listOfIds.reviewIntakeForm]: {
    [listOfIds.intakeFormProductInfo]: {
      name: headerTabs["PRODUCT INFO"],
      isActive: true,
      id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM1],
    },
    [listOfIds.intakeFormPreferences]: {
      name: headerTabs["PREFERENCES"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES1
      ],
    },
    [listOfIds.intakeFormAudience]: {
      name: headerTabs["AUDIENCE"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE1
      ],
    },
    [listOfIds.intakeFormBranding]: {
      name: headerTabs["BRANDING"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_BRANDING1
      ],
    },
    [listOfIds.intakeFormSocialProof]: {
      name: headerTabs["SOCIAL PROOF"],
      isActive: false,
      id: LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF1
      ],
    },
    [listOfIds.facebookTargeting]: {
      name: headerTabs["FB TARGETING"],
      isActive: false,
      id: LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING1],
    },
  },
  [listOfIds.strategyInput]: {
    [listOfIds.STRATEGY_INPUT_SEARCH]: {
      [listOfIds.brandCampaign]: {
        name: headerTabsAccountManager["BRAND CAMPAIGN"],
        isActive: false,
      },
      [listOfIds.competitorCampaign]: {
        name: headerTabsAccountManager["COMPETITOR CAMPAIGN"],
        isActive: false,
      },
      [listOfIds.serviceCampaign]: {
        name: headerTabsAccountManager["SERVICES CAMPAIGN"],
        isActive: false,
      },
    },
    [listOfIds.STRATEGY_INPUT_PMAX]: {
      [listOfIds.PMAX_STRATEGY_CAMPAIGN_1]: {
        name: headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_1],
        isActive: false,
      },
      [listOfIds.PMAX_STRATEGY_CAMPAIGN_2]: {
        name: headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_2],
        isActive: false,
      },
      [listOfIds.PMAX_STRATEGY_CAMPAIGN_3]: {
        name: headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_3],
        isActive: false,
      },
    },
  },
  [listOfIdsHDM.StrategyCreationFacebook]: {
    [listOfIdsHDM.LOOKALIKE]: {
      name: headerTabsAccountManager["LOOKALIKE"],
      isActive: true,
    },
    [listOfIdsHDM.INTEREST]: {
      name: headerTabsAccountManager["INTEREST"],
      isActive: false,
    },
    [listOfIdsHDM.MOF]: {
      name: headerTabsAccountManager["MOF"],
      isActive: false,
    },
    [listOfIdsHDM.BOF]: {
      name: headerTabsAccountManager["BOF"],
      isActive: false,
    },
  },
  [listOfIds.strategy]: {
    [listOfIds.strategyDeckIntro]: {
      name: headerTabsAccountManager["INTRODUCTION"],
      isActive: true,
    },
    [listOfIds.strategyDeckGoogle]: {
      name: headerTabsAccountManager["GOOGLE"],
      isActive: false,
    },
    [listOfIds.facebook]: {
      name: headerTabsAccountManager["FACEBOOK"],
      isActive: false,
    },
    [listOfIds.strategyDeckSuggestChanges]: {
      name: headerTabsAccountManager["STRATEGY_SUGGEST_CHANGES"],
      isActive: false,
    },
  },
  [listOfIds.creativeApproval]: {
    [listOfIds.creativeApprovalAdcopies]: {
      name: headerTabsAccountManager["AD COPIES"],
      isActive: true,
    },
    [listOfIds.creativeApprovalCreatives]: {
      name: headerTabsAccountManager["CREATIVES"],
      isActive: true,
    },
    [listOfIds.creativeApprovalLandingPages]: {
      name: headerTabsAccountManager["LANDING PAGES"],
      isActive: true,
    },
    [listOfIds.creativeAppovalAbTest]: {
      name: headerTabsAccountManager["AB TEST"],
      isActive: true,
    },
  },
};

export const headerNavbarAMWithCurrentCompIds = {
  // ["BASIC INFORMATION"]:
  //   LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST1],
  // ["PLATFORM INFORMATION"]:
  //   LDcomponentWithIndexClient[clientDashboardIds.PLATFORM_INFORMATION_QUEST1],
  // ["GROWTH GOALS"]:
  //   LDcomponentWithIndexClient[clientDashboardIds.GROWTH_GOALS_QUEST1],
  // ["GOOGLE ADS SETUP"]:
  //   ldComponentWithIndexAm[accountManagerIds.SCOPE_GOOGLE_ADS_SETUP],
  // ["FACEBOOK ADS SETUP"]:
  //   ldComponentWithIndexAm[accountManagerIds.SCOPE_FACEBOOK_ADS_SETUP],
  // ["CRO SETUP"]: ldComponentWithIndexAm[accountManagerIds.SCOPE_CRO_ADS_SETUP],
  // ["PRICING"]:
  //   ldComponentWithIndexAm[accountManagerIds.SCOPE_PRICING_ADS_SETUP],
  // ["ONBOARDING FORM"]:
  //   ldComponentWithIndexAm[accountManagerIds.PROPOSAL_INPUTS_ONBOARDING_QUEST1],
  // ["PROPOSER DETAILS"]:
  //   ldComponentWithIndexAm[
  //     accountManagerIds.PROPOSAL_INPUTS_PROPOSER_DETAILS_QUEST1
  //   ],
  // ["REQUIREMENT DETAILS"]:
  //   ldComponentWithIndexAm[
  //     accountManagerIds.PROPOSAL_INPUTS_REQUIREMENT_QUEST1
  //   ],
  // ["GOOGLE ADS AUDIT"]:
  //   ldComponentWithIndexAm[accountManagerIds.AUDIT_GOOGLE_ADS],
  // ["ACCOUNT"]: ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_ACCOUNT],
  // ["CAMPAIGN"]: ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_CAMPAIGN],
  // ["ADSET"]: ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_ADSET],
  // ["AD"]: ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_AD],
  // ["PROPOSAL"]: ldComponentWithIndexAm[accountManagerIds.PROPOSAL_PUBLISH],
  // ["CHANGES SUGGESTED"]:
  //   ldComponentWithIndexAm[accountManagerIds.SUGGEST_CHANGES],
  // ["BRAND CAMPAIGN"]:
  //   ldComponentWithIndexAm[accountManagerIds.BRAND_STRATEGY_INPUT],
  // ["COMPETITOR CAMPAIGN"]:
  //   ldComponentWithIndexAm[accountManagerIds.COMPETITOR_STRATEGY_INPUT],
  // ["SERVICES CAMPAIGN"]:
  //   ldComponentWithIndexAm[accountManagerIds.SERVICE_STRATEGY_INPUT],
  // [headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_1]]:
  //   ldComponentWithIndexAm[accountManagerIds.PMAX_STRATEGY_CAMPAIGN_1],
  // [headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_2]]:
  //   ldComponentWithIndexAm[accountManagerIds.PMAX_STRATEGY_CAMPAIGN_2],
  // [headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_3]]:
  //   ldComponentWithIndexAm[accountManagerIds.PMAX_STRATEGY_CAMPAIGN_3],
  // [headerTabsAccountManager["LOOKALIKE"]]:
  //   ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_LOOKALIKE],
  // [headerTabsAccountManager["INTEREST"]]:
  //   ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_INTEREST],
  // [headerTabsAccountManager["MOF"]]:
  //   ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_MOF],
  // [headerTabsAccountManager["BOF"]]:
  //   ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_BOF],
  // ["INTRODUCTION"]:
  //   ldComponentWithIndexAm[accountManagerIds.STRATEGY_DECK_INTRODUCTION],
  // ["GOOGLE"]: ldComponentWithIndexAm[accountManagerIds.STRATEGY_DECK],
  // ["FACEBOOK"]:
  //   ldComponentWithIndexAm[accountManagerIds.STRATEGY_DECK_FACEBOOK],
  // ["SUGGESTED CHANGES"]:
  //   ldComponentWithIndexAm[accountManagerIds.STRATEGY_SUGGEST_CHANGES],
  // ["PRODUCT INFO"]:
  //   LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM1],
  // ["PREFERENCES"]:
  //   LDcomponentWithIndexClient[
  //     clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES1
  //   ],
  // ["GOOGLE TARGETING"]:
  //   LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE1],
  // ["BRANDING"]:
  //   LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM_BRANDING1],
  // ["SOCIAL PROOF"]:
  //   LDcomponentWithIndexClient[
  //     clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF1
  //   ],
  // ["FB TARGETING"]:
  //   LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING1],
  // ["AD COPIES"]: ldComponentWithIndexAm[accountManagerIds.AD_COPIES],
  // ["CREATIVES"]: ldComponentWithIndexAm[accountManagerIds.CREATIVES],
  // ["LANDING PAGES"]: ldComponentWithIndexAm[accountManagerIds.LANDING_PAGES],
  // ["AB TEST"]: ldComponentWithIndexAm[accountManagerIds.AB_TEST],
};

export const leftSidebarAMWithCurrentCompIds = {
  // [listOfIds.accountOnboarding]:
  //   ldComponentWithIndexAm[accountManagerIds.CLIENT_DETAILS_AM],
  // [listOfIds.accountScopeOfWorkAndPricing]:
  //   ldComponentWithIndexAm[accountManagerIds.SCOPE_PRICING_ADS_SETUP],
  // [listOfIds.sharedAssets]:
  //   ldComponentWithIndexAm[accountManagerIds.SHARED_ASSETS],
  // // [listOfIds.proposalInputs]: ldComponentWithIndexAm[accountManagerIds.PROPOSAL_INPUTS_ONBOARDING_QUEST1],
  // [listOfIds.audit]: ldComponentWithIndexAm[accountManagerIds.AUDIT_GOOGLE_ADS],
  // [listOfIdsHDM.AuditHdm]:
  //   ldComponentWithIndexAm[accountManagerIds.HDM_FACEBOOK_ACCOUNTS],
  // [listOfIds.proposal]:
  //   ldComponentWithIndexAm[accountManagerIds.PROPOSAL_PUBLISH],
  // [listOfIds.reviewIntakeForm]:
  //   ldComponentWithIndexAm[accountManagerIds.REVIEW_INTAKE_FORM],
  // [listOfIds.sharedAssetsComplete]:
  //   ldComponentWithIndexAm[accountManagerIds.SHARED_ASSETS_COMPLETE],
  // [listOfIds.strategyInput]:
  //   ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY],
  // [listOfIds.STRATEGY_INPUT_PMAX]:
  //   ldComponentWithIndexAm[accountManagerIds.PMAX_STRATEGY_CAMPAIGN_1],
  // [listOfIdsHDM.StrategyCreationFacebook]:
  //   ldComponentWithIndexAm[accountManagerIds.STRATEGY_FACEBOOK_LOOKALIKE],
  // [listOfIds.strategy]:
  //   ldComponentWithIndexAm[accountManagerIds.STRATEGY_DECK_INTRODUCTION],
  // [listOfIds.campaignForGoogle]:
  //   ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE],
  // [listOfIdsHDM.CampaignCreationFB]:
  //   ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_CREATION_FB],
  // [listOfIds.CAMPAIGN_PREVIEW]:
  //   ldComponentWithIndexAm[accountManagerIds.CAMPAIGN_PREVIEW_PAGE],
  // [listOfIds.creativeApproval]:
  //   ldComponentWithIndexAm[accountManagerIds.AD_COPIES],
};

export const leftSidebarHDMAMWithCurrentCompIds = {
  // [listOfIdsHDM.onboardingInfo]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.FORM_PREVIEW],
  // [listOfIdsHDM.accessSharing]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.ACCESS_SHARING],
  // [listOfIdsHDM.ONBOARDINGOC]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.HDM_ONBOARDING],
  // [listOfIdsHDM.AUDIT_FOR_GOOGLE]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.AUDIT_GOOGLE_ADS],
  // [listOfIdsHDM.AUDIT]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.HDM_AUDIT_ACCOUNT],
  // [listOfIdsHDM.AccountAnalysis]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.ACCOUNT_ANNALYSIS_GOOGLE],
  // [listOfIdsHDM.strategyInput]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY],
  // [listOfIdsHDM.STRATEGY_INPUT_PMAX]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.PMAX_STRATEGY_CAMPAIGN_1],
  // [listOfIdsHDM.StrategyCreationFacebook]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_FACEBOOK_LOOKALIKE],
  // [listOfIdsHDM.STRATEGY_DECK]:
  //   componentWithIndexHDMAm[accountManagerIdsHDM.STRATEGY_DECK_GOAL],
  // [listOfIdsHDM.CampaignCreationGoogle]: componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE],
  // [listOfIdsHDM.CampaignCreationFB]: componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_CREATION_FB],
  // [listOfIdsHDM.CampaignPreview]: componentWithIndexHDMAm[accountManagerIdsHDM.CAMPAIGN_PREVIEW_PAGE],
};
