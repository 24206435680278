import { Typography, Tooltip } from '@mui/material';
import React from 'react';
import LoaderIcon from './loader-icon.svg';

const Publishing = () => {
  return (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-arrow': {
            color: 'white',
          },
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'white',
            color: 'black',
            marginBottom: '20px',
            filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
            padding: '10px 8px 8px 12px',
          },
        },
      }}
      title={
        <>
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 12,
              lineHeight: '20px',
              color: '#333333',
            }}
          >
            This campaign is being published. This usually takes less than 5
            minutes.
          </Typography>
        </>
      }
      arrow
      placement='right'
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '4px 8px',
          gap: 6,
          width: 91,
          height: 24,
          background: '#FEF5EB',
          borderRadius: 6,
          cursor: 'auto',
        }}
      >
        <img
          src={LoaderIcon}
          alt=''
          style={{
            width: 12,
            height: 12,
          }}
        />
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            lineHeight: '20px',
            color: '#F99F35',
          }}
        >
          Publishing
        </Typography>
      </div>
    </Tooltip>
  );
};

export default Publishing;