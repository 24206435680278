import { all, put, takeLatest, select, delay } from "redux-saga/effects";
import { api } from "../../index";
import { PLATFORM } from "../../../utils/commonEnums";
function* facebookAccounts(action) {
  // debugger;
  try {
    const res = yield api.get(`/ad-accounts/${action.payload}`);
    yield put({
      type: "GET_FACEBOOK_ACCOUNTS",
      payload: res.data.data.filter(
        (adAccount) => adAccount.platform === PLATFORM.FACEBOOK
      ),
    });
    yield put({
      type: "UPDATE_GOT_FACEBOOK_ACCOUNTS",
      payload: true,
    });
  } catch (err) {
    console.log(err);
  }
}

export const getAlreadyAuditHdm = (agencyType = "LD") => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.get(
        "/facebook-audit/" + localStorage.getItem("formId")
      );
      resolve(res.data.summaries);
    } catch (err) {
      reject(err);
    }
  });
};

export const getAuditFacebook = (obj, agencyType = "LD") => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        "/facebook-audit/" + localStorage.getItem("formId"),
        obj,
        false
      );
      resolve(res.data.response);
    } catch (err) {
      reject(err);
    }
  });
};

export const hitTargetingKeywordsApi = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        "/targeting-keywords/fb-ads/" +
          (localStorage.getItem("amSelectedFormId")
            ? localStorage.getItem("amSelectedFormId")
            : localStorage.getItem("formId")),
        {},
        false
      );
      resolve(res.data.response);
    } catch (err) {
      reject(err);
    }
  });
};

export const isAccountValid = (accountId, formId) => {
  // debugger;
  return new Promise(async (res, rej) => {
    try {
      const data = await api.post(
        `/facebook-audit/initialise/${formId}?account_id=${accountId}`
      );
      res(data);
    } catch (err) {
      rej(err);
    }
  });
};

export const isMetaDataArrivedFacebook = (accountId, formId) => {
  return new Promise(async (response, rej) => {
    try {
      const res = await api.get(
        `/facebook-audit/fetch-status/${accountId}/${formId}`
      );
      response(res.data);
    } catch (err) {
      rej(err);
    }
  });
};

export const allAlreadyMetadataAccounts = (accountId, formId) => {
  return new Promise(async (res, rej) => {
    try {
      const data = await api.get(
        `/facebook-audit/fetch-status/${accountId}/${formId}`
      );
      res(data);
    } catch (err) {
      rej(err);
    }
  });
};

export default function* root() {
  yield all([takeLatest("GET_FACEBOOK_ACCOUNTS_SAGA", facebookAccounts)]);
}
