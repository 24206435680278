export const IMAGE_ASPECT_RATIO_ENUM = {
    "1.91:1": 1.91,
    "1:1": 1,
    "4:5": 0.8,
}

export const LOGO_ASPECT_RATIO_ENUM = {
    "1:1": 1,
    "4:1": 4,
}

export const MAX_SIZE = 5120000;

export const MIN_RESOLUTION_IMAGE_ENUM = {
    "1.91:1": {
        WIDTH: 600,
        HEIGHT: 314,
    },
    "1:1": {
        WIDTH: 300,
        HEIGHT: 300,
    },
    "4:5": {
        WIDTH: 480,
        HEIGTH: 600,
    }
}

export const MIN_RESOLUTION_LOGO_ENUM = {
    "1:1": {
        WIDTH: 128,
        HEIGHT: 128,
    },
    "4:1": {
        WIDTH: 512,
        HEIGTH: 128,
    }
}
