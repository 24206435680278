import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { pricing_icon as pricingIcon } from "../../../assets/icons/common/common";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { checked_icon as checkedIcon } from "../../../assets/icons/common/common";
import { drop_down_arrow as dropdownArrow } from "../../../assets/icons/common/common";
import OptionalAdOns from "./OptionalAdOns";
import { pencil as pencilIcon } from "../../../assets/icons/common/common";
import TextField from "@material-ui/core/TextField";
import { checked_icon as checkedIconGreen } from "../../../assets/icons/icon";
import {
  updatePricing,
  createProposalReducer,
} from "../../../api/accountManager/pricing/slice";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";
const getBasePriceObj = (basePrice, basePercent) => {
  if (basePrice === -1) {
    return "Custom";
  }
  if (basePercent && !basePrice) {
    return `${basePercent}%`;
  }
  if (basePrice && !basePercent) {
    return `$${basePrice}`;
  }
  if (!basePrice && !basePercent) {
    return `$0`;
  }
  return `$${basePrice} or ${basePercent}%`;
};

const getPriceAndPercentage = (priceObj = "") => {
  if (priceObj.includes("%") && priceObj.includes("$")) {
    const [price, percent] = priceObj.split(" or ");
    return [parseInt(price.slice(1)), parseInt(percent.slice(0, -1))];
  }
  if (priceObj.includes("%")) {
    return [0, parseInt(priceObj.slice(0, -1))];
  }
  if (priceObj.includes("$")) {
    return [parseInt(priceObj.slice(1)), 0];
  }
  return [0, 0];
};
const PriceRate = (props) => {
  const dispatch = useDispatch();

  const [basePrice, setBasePrice] = useState(props.monthlyFee);
  const [landingPages, setLandingPages] = useState(props.landingPages);
  const [popUps, setPopUp] = useState(props.popUps);
  const [stickyBars, setStickyBars] = useState(props.stickyBars);
  const [basePriceEdit, setBasePriceEdit] = useState(false);
  const [landingPagesEdit, setLandingPagesEdit] = useState(false);
  const [popUpsEdit, setPopUpsEdit] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [stickyBarsEdit, setStickyBarsEdit] = useState(false);
  const [optionalAdons, setoptionalAdons] = useState(false);

  const [additionalLPQuantity, setAdditionalLPQuantity] = useState(0);
  const [additionalPUQuantity, setAdditionalPUQuantity] = useState(0);
  const [additionalSBQuantity, setAdditionalSBQuantity] = useState(0);
  const [callRailQuantity, setCallRailQuantity] = useState(0);
  const [hotjarQuantity, setHotjarQuantity] = useState(0);
  const [selectCallRail, isSelectCallRail] = useState(false);
  const [selectHotjar, isSelectHotjar] = useState(false);
  const [selectGrowforms, isSelectGrowforms] = useState(false);
  const [additionalLPPrice, setAdditionalLPPrice] = useState("500");
  const [additionalPUPrice, setAdditionalPUPrice] = useState("100");
  const [additionalSBPrice, setAdditionalSBPrice] = useState("100");
  const [callRailPrice, setCallRailPrice] = useState("50");
  const [hotjarPrice, setHotjarPrice] = useState("50");
  const [growFormsPrice, setGrowFormsPrice] = useState("75");
  const [priceCard, setPriceCard] = useState(false);
  const [toBeSave, setToBeSave] = useState(false);

  const pricing = useSelector((state) => state.pricing.pricing);

  useEffect(() => {
    console.log("Pricing : ", pricing);
  }, [pricing]);

  useEffect(() => {
    const pricingObj = pricing[props.priceCardNumber];
    const newPricing = {
      ...pricingObj,
      addonServices: [
        ...pricingObj.addonServices,
        {
          service: "Grow forms",
          quantity: "0",
          price: 75,
          selected: false,
        },
      ],
    };
    setToBeSave(false);
    setBasePrice(
      getBasePriceObj(
        pricingObj.basePricePerMonth,
        pricingObj.basePercentPerMonth
      )
    );
    setLandingPages(pricingObj.baseServices[0].quantity);
    setPopUp(pricingObj.baseServices[1].quantity);
    setStickyBars(pricingObj.baseServices[2].quantity);
    setAdditionalLPQuantity(pricingObj.addonServices[0].quantity);
    setAdditionalPUQuantity(pricingObj.addonServices[1].quantity);
    setAdditionalSBQuantity(pricingObj.addonServices[2].quantity);
    setCallRailQuantity(pricingObj.addonServices[3].quantity);
    isSelectCallRail(pricingObj?.addonServices[3]?.selected);
    setHotjarQuantity(pricingObj.addonServices[4].quantity);
    isSelectHotjar(pricingObj?.addonServices[4]?.selected);
    setAdditionalLPPrice(pricingObj.addonServices[0].price);
    setAdditionalPUPrice(pricingObj.addonServices[1].price);
    setAdditionalSBPrice(pricingObj.addonServices[2].price);
    setCallRailPrice(pricingObj.addonServices[3].price);
    setHotjarPrice(pricingObj.addonServices[4].price);
    setGrowFormsPrice(
      pricingObj?.addonServices[5]
        ? pricingObj.addonServices[5]?.price
        : newPricing.addonServices[5]?.price
    );
    isSelectGrowforms(
      pricingObj?.addonServices[5]
        ? pricingObj.addonServices[5]?.selected
        : newPricing.addonServices[5]?.selected
    );
  }, [pricing, props.priceCardNumber]);
  const selectPriceCardHandler = (id) => {
    props.onClickHandler(id);
  };
  useEffect(() => {
    console.table(
      additionalLPPrice,
      additionalPUPrice,
      additionalSBPrice,
      toBeSave
    );
    if (toBeSave) {
      const [price, percent] = getPriceAndPercentage(basePrice);
      dispatch(
        updatePricing({
          priceIndex: props.priceCardNumber,
          pricing: {
            adSpendRange: {
              minSpend: props.minAdSpend,
              maxSpend: props.maxAdSpend,
            },
            currency: "$",
            basePricePerMonth: price,
            basePercentPerMonth: percent || 0,
            baseServices: [
              {
                service: "Landing Pages",
                quantity: landingPages || "0",
              },
              {
                service: "Pop-up",
                quantity: popUps || "0",
              },
              {
                service: "Sticky Bar",
                quantity: stickyBars || "0",
              },
            ],
            addonServices: [
              {
                service: "Additional Landing Pages",
                quantity: additionalLPQuantity,
                price: parseInt(additionalLPPrice || "0"),
              },
              {
                service: "Additional Pop Up",
                quantity: additionalPUQuantity,
                price: parseInt(additionalPUPrice || "0"),
              },
              {
                service: "Additional Sticky Bar",
                quantity: additionalSBQuantity,
                price: parseInt(additionalSBPrice || "0"),
              },
              {
                service: "Call Rail",
                quantity: callRailQuantity,
                price: parseInt(callRailPrice || "0"),
                selected: selectCallRail,
              },
              {
                service: "Hotjar",
                quantity: hotjarQuantity,
                price: parseInt(hotjarPrice || "0"),
                selected: selectHotjar,
              },
              {
                service: "Grow forms",
                quantity: 0,
                price: parseInt(growFormsPrice || "0"),
                selected: selectGrowforms,
              },
            ],
          },
        })
      );
    }
  }, [
    additionalLPPrice,
    additionalLPQuantity,
    additionalPUPrice,
    additionalPUQuantity,
    additionalSBPrice,
    additionalSBQuantity,
    basePrice,
    callRailPrice,
    callRailQuantity,
    dispatch,
    growFormsPrice,
    hotjarPrice,
    hotjarQuantity,
    landingPages,
    popUps,
    props.maxAdSpend,
    props.minAdSpend,
    props.priceCardNumber,
    selectCallRail,
    selectGrowforms,
    selectHotjar,
    stickyBars,
    toBeSave,
  ]);

  const increaseLPQuantityHandler = () => {
    if (parseInt(additionalLPQuantity) < 4) {
      setToBeSave(true);
      setAdditionalLPQuantity(parseInt(additionalLPQuantity) + 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) + parseInt(additionalLPPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreaseLPQuantityHandler = () => {
    if (additionalLPQuantity != 0) {
      setToBeSave(true);
      setAdditionalLPQuantity(additionalLPQuantity - 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) - parseInt(additionalLPPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const increasePUQuantityHandler = () => {
    if (parseInt(additionalPUQuantity) < 4) {
      setToBeSave(true);
      setAdditionalPUQuantity(parseInt(additionalPUQuantity) + 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) + parseInt(additionalPUPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreasePUQuantityHandler = () => {
    if (additionalPUQuantity != 0) {
      setToBeSave(true);
      setAdditionalPUQuantity(additionalPUQuantity - 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) - parseInt(additionalPUPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const increaseSBQuantityHandler = () => {
    if (parseInt(additionalSBQuantity) < 4) {
      setToBeSave(true);
      setAdditionalSBQuantity(parseInt(additionalSBQuantity) + 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) + parseInt(additionalSBPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreaseSBQuantityHandler = () => {
    if (additionalSBQuantity != 0) {
      setToBeSave(true);
      setAdditionalSBQuantity(additionalSBQuantity - 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) - parseInt(additionalSBPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const increaseCallRailQuantityHandler = () => {
    // debugger;
    if (!selectCallRail) {
      setToBeSave(true);
      // setCallRailQuantity(parseInt(callRailQuantity) + 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) + parseInt(callRailPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreaseCallRailQuantityHandler = () => {
    // debugger;
    if (selectCallRail) {
      setToBeSave(true);
      // setCallRailQuantity(callRailQuantity - 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) - parseInt(callRailPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };
  const increaseHotjarQuantityHandler = () => {
    if (!selectHotjar) {
      setToBeSave(true);
      // setHotjarQuantity(parseInt(hotjarQuantity) + 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" + (parseInt(basePrices[0].slice(1)) + parseInt(hotjarPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreaseHotjarQuantityHandler = () => {
    if (selectHotjar) {
      setToBeSave(true);
      // setHotjarQuantity(hotjarQuantity - 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" + (parseInt(basePrices[0].slice(1)) - parseInt(hotjarPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const increaseGrowFormsQuantityHandler = () => {
    if (!selectGrowforms) {
      setToBeSave(true);
      // setHotjarQuantity(parseInt(hotjarQuantity) + 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) + parseInt(growFormsPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const decreaseGrowFormsQuantityHandler = () => {
    if (selectGrowforms) {
      setToBeSave(true);
      // setHotjarQuantity(hotjarQuantity - 1);
      const basePrices = basePrice.split(" or ");
      let updatedPrices =
        "$" +
        (parseInt(basePrices[0].slice(1)) - parseInt(growFormsPrice || "0"));
      if (basePrices[1]) {
        setBasePrice(updatedPrices + " or " + basePrices[1]);
      } else {
        setBasePrice(updatedPrices);
      }
      setHasChange(true);
    }
  };

  const boldNumStyling = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "22px",
    color: "#2E3044",
  };
  const featureWordStyle = {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "20px",
    color: "#585969",
  };
  return (
    <div
      className="price-card-container"
      style={{
        border: props.selected && `2px solid ${props.color}`,
      }}
    >
      <div className="price-card-header">
        <Typography
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 16,
            color: props.color,
            paddingBottom: 10,
            paddingTop: 10,
            textAlign: "center",
          }}
        >
          {props.title}
        </Typography>
        <div
          style={{
            position: "absolute",
            right: 6,
            top: 4.5,
          }}
        >
          <BpRadio
            name="pack-name"
            checked={props.selected}
            value={props.priceCardNumber}
            onChange={() => selectPriceCardHandler(props.priceCardNumber)}
            icon={
              <>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="10"
                    cy="10"
                    r="9.5"
                    fill="white"
                    stroke="#ABACB4"
                  />
                </svg>
              </>
            }
            checkedIcon={
              <>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.99999 0C15.5222 0 20 4.47676 20 9.99999C20 15.5222 15.5222 20 9.99999 20C4.47676 20 0 15.5222 0 9.99999C0 4.47676 4.47676 0 9.99999 0Z"
                    fill="#1ABFA1"
                  />
                  <path
                    d="M6.25 10.0063L8.615 12.375L13.4875 7.5"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </>
            }
          />
        </div>
      </div>
      <div className="price-card-body">
        <div className="pricing">
          <div>
            <img src={props.iconImg} alt="" />
          </div>
          <div>
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 13.8582,
                color: "#6F6C90",
              }}
            >
              Ad Spend Range
            </Typography>
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 18.4776,
                color: "#170F49",
              }}
            >
              {props.adSpendRange}
            </Typography>
          </div>
        </div>
        <div className="price">
          <>
            {basePriceEdit ? (
              <p
                style={{
                  display: "flex",
                  alignItems: "end",
                  gap: "0.3rem",
                  margin: 0,
                }}
              >
                <TextField
                  inputProps={{ style: { width: "120px" } }}
                  value={basePrice}
                  onChange={(e) => {
                    setToBeSave(true);
                    setBasePrice(e.target.value);
                    setHasChange(true);
                  }}
                />
                /monthly{" "}
                <span
                  onClick={() => {
                    if (basePriceEdit && basePrice === "") {
                      setToBeSave(true);
                      setBasePrice("$0");
                    }
                    setBasePriceEdit(!basePriceEdit);
                  }}
                >
                  <img src={pencilIcon} alt="" />
                </span>
              </p>
            ) : (
              <p
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  gap: "0.3rem",
                  margin: 0,
                }}
              >
                <span>{basePrice}</span>
                /monthly{" "}
                <span
                  onClick={() => {
                    setToBeSave(true);
                    setBasePriceEdit(!basePriceEdit);
                  }}
                >
                  <img src={pencilIcon} alt="" />
                </span>
              </p>
            )}
          </>
        </div>
        <div
          className="features"
          style={{
            backgroundColor: props.backgroundColor,
            borderRadius: 10,
            padding: 16,
            paddingTop: 8,
          }}
        >
          <p
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 14,
              color: "#170F49",
              marginBottom: 8,
            }}
          >
            What's included
          </p>
          <div className="feature-list">
            <div className="feature">
              <Typography style={boldNumStyling}>
                {props.landingPages}
              </Typography>
              <Typography style={featureWordStyle}>Landing Pages</Typography>
            </div>
            <div className="feature">
              <Typography style={boldNumStyling}>{props.popUps}</Typography>
              <Typography style={featureWordStyle}>Pop-up</Typography>
            </div>
            <div className="feature">
              <Typography style={boldNumStyling}>{props.stickyBars}</Typography>
              <Typography style={featureWordStyle}>Sticky Bar</Typography>
            </div>
          </div>
        </div>
        <div
          className="optional-add-ons"
          style={{
            cursor: "pointer",
          }}
        >
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 13.8582,
            }}
          >
            Optional Add-ons
          </p>
        </div>
        <div>
          <OptionalAdOns
            additionalLPQuantity={additionalLPQuantity}
            additionalLPPrice={additionalLPPrice}
            setAdditionalLPPrice={setAdditionalLPPrice}
            increaseLPQuantityHandler={increaseLPQuantityHandler}
            decreaseLPQuantityHandler={decreaseLPQuantityHandler}
            additionalPUQuantity={additionalPUQuantity}
            additionalPUPrice={additionalPUPrice}
            setAdditionalPUPrice={setAdditionalPUPrice}
            increasePUQuantityHandler={increasePUQuantityHandler}
            decreasePUQuantityHandler={decreasePUQuantityHandler}
            additionalSBQuantity={additionalSBQuantity}
            additionalSBPrice={additionalSBPrice}
            setAdditionalSBPrice={setAdditionalSBPrice}
            increaseSBQuantityHandler={increaseSBQuantityHandler}
            decreaseSBQuantityHandler={decreaseSBQuantityHandler}
            callRailQuantity={callRailQuantity}
            callRailPrice={callRailPrice}
            setCallRailPrice={setCallRailPrice}
            increaseCallRailQuantityHandler={increaseCallRailQuantityHandler}
            decreaseCallRailQuantityHandler={decreaseCallRailQuantityHandler}
            hotjarQuantity={hotjarQuantity}
            hotjarPrice={hotjarPrice}
            setHotjarPrice={setHotjarPrice}
            increaseHotjarQuantityHandler={increaseHotjarQuantityHandler}
            decreaseHotjarQuantityHandler={decreaseHotjarQuantityHandler}
            growFormsPrice={growFormsPrice}
            setGrowFormsPrice={setGrowFormsPrice}
            increaseGrowFormsQuantityHandler={increaseGrowFormsQuantityHandler}
            decreaseGrowFormsQuantityHandler={decreaseGrowFormsQuantityHandler}
            setHasChange={setHasChange}
            iconBullet={props.iconBullet}
            selectCallRail={selectCallRail}
            isSelectCallRail={isSelectCallRail}
            selectHotjar={selectHotjar}
            isSelectHotjar={isSelectHotjar}
            selectGrowforms={selectGrowforms}
            isSelectGrowforms={isSelectGrowforms}
            isRateCardSelected={props.selected}
            setToBeSave={setToBeSave}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceRate;
