const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const bing  = `${imageBaseUrl}/assets/36d1bc6c-4f23-4abe-9a90-b96665674877`
export const facebook_icon = `${imageBaseUrl}/assets/2af49c6a-bb6c-4237-a2f8-d610debe8da4`
export const facebook = `${imageBaseUrl}/assets/22a0cf02-5859-4a3b-8287-9b99a60a52a1`
export const fb_icon = `${imageBaseUrl}/assets/9fad25f0-b800-4810-983b-570aa4de8f7f`
export const google_icon = `${imageBaseUrl}/assets/bbf29fa8-86e5-4888-b311-ead413415bac`
export const google_analytics_icon = `${imageBaseUrl}/assets/f7b37bae-1d42-44b8-9495-05043534f69f`
export const google_manager_icon = `${imageBaseUrl}/assets/6833a7f3-57bd-437c-8a86-1bb4ecf5251a`
export const google = `${imageBaseUrl}/assets/7404cc4f-4595-4924-8af3-31f868575134`
export const google1 = `${imageBaseUrl}/assets/cd95d39d-e11d-4125-a515-e2fc958c3be6`
export const google2 = `${imageBaseUrl}/assets/c46f240f-496b-485c-a47d-0451fd28c3c3`
export const shopify_icon = `${imageBaseUrl}/assets/bc07ac0c-ab0b-4b34-beba-a10766b4aa30`
export const tiktok_icon = `${imageBaseUrl}/assets/15a02f9c-f023-4585-aabe-28e065241c4f`
export const tiktok_icon_square = `${imageBaseUrl}/assets/fd0ed606-4489-466e-ab19-80940472b374`;
