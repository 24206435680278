import { useEffect, useState } from "react";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { useTheme, styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputBase from "@mui/material/InputBase";
import { no_preview_icon as noIreviewIcon } from "../../../assets/icons/common/common";
import { Tabs, useTabState, Panel } from "../../CustomTab/customTab";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  goBackAmCompo,
  goToSpecificCompoClient,
} from "../../../api/accountManager/componentTracking/action";
import StrategyDeckSureModal from "./StrategyDeckSureModal";
import ProposalPublishedModal from "../../modals/ProposalPublishedModal";
import PEOPLE from "./Vector.svg";
import Person from "./person.svg";
import CAMERA from "../../../assets/icons/pmax_campaign.svg";
import PMaxStrategyDeck from "./PmaxStrategyDeck";
import { Typography } from "@mui/material";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";
import {
  BACKEND_STATUS,
  CRON_STATUS_BACKEND,
  PROPOSAL_STATE,
} from "../../../utils/accountManagerUtil";
import {
  goBackHDMAmCompo,
  goToSpecificCompoHDMAM,
  updateCompoReduxHDMAM,
} from "../../../api/HDMAM/componentTracking/action";
import { api } from "../../../api";
import { getLocalStorage } from "../../../api/localStorage/localStorage";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import hdmAmRoutePaths from "../../../pages/hdm-am/constants/hdmAmRoutePaths";
import { DATA_FORMAT_TO_GET_STRATEGIES } from "../../GoogleSearchStrategyCreation/utils";

const cn = (...args) => args.filter(Boolean).join(" ");

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button className={cn("tab", isActive && "active")} onClick={onClick}>
      {children}
    </button>
  );
};

const StrategyDeckGoogle = ({
  searchStrategies = [],
  pmaxStrategies = [],
  buttonTitle,
  onBackClickHandler,
  onClickHandler,
  segments = [],
  disableButton,
  proceedToPublish,
  form,
}) => {
  const dispatch = useDispatch();
  const [totalBudget, setTotalBudget] = useState(0);
  const [sureModal, setSureModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);

  useEffect(() => {
    let tempGoogleBudget = 0;
    searchStrategies.forEach((strategy) => {
      tempGoogleBudget += parseInt(strategy?.budget?.value);
    });
    pmaxStrategies.forEach((strategy) => {
      tempGoogleBudget += parseInt(strategy?.budget);
    });
    setTotalBudget(tempGoogleBudget);
  }, [searchStrategies, pmaxStrategies]);

  useEffect(() => {
    if (proceedToPublish) {
      setSureModal(true);
    }
  }, [proceedToPublish]);
  const publishDeck = () => {
    const strategyIterations = [...(form?.strategyIterations || [])];
    if (!strategyIterations.length) {
      strategyIterations.push({
        state: PROPOSAL_STATE.GENERATED,
      });
    } else {
      strategyIterations.push({
        state: PROPOSAL_STATE.CHANGES_APPLIED,
      });
    }
    dispatch(
      updateFormReducer({
        strategyIterations: strategyIterations,
      })
    );
    setSureModal(false);
    setPublishModal(true);
  };

  return (
    <div className="intro_strategy_main">
      <div className="google_strategy_layout">
        <div className="google_strategy_banner">
          <div className="google_banner_part">
            <div className="google_strategy_row">
              <div className="google_strategy_logo">
                <span></span>
                <p>Google</p>
              </div>
              <div className="google_strategy_budget">
                <p>Total Daily Budget : ${totalBudget}</p>
              </div>
            </div>
            <p className="google_strategy_info">
              Here, you can review the strategy for running your campaigns on
              google to drive efficient delivery and performance. We recommend
              that you go through each of the recommendations here and replace
              the ones you do not want to display in your campaign strategy
              deck. Removing the ones you do not find useful, will automatically
              generate new recommendations at the bottom of each of the lists.
            </p>
          </div>
        </div>
      </div>
      {searchStrategies.map((strategy, index) => (
        <div className="strategy_row_layout" key={`search=${index}`}>
          <div className="strategy_google_row_main">
            <div className="google_strategy_header">
              <div>
                <div className="google_strategy_row">
                  <div className="google_strategy_title">
                    <p>{strategy.name}</p>
                  </div>
                  <div className="google_title_budget">
                    <p>Daily Budget : ${strategy?.budget?.value || 0}</p>
                  </div>
                </div>
                {/* <p className="google_title_info">
                  Brand Campaigns essentially help you serve ads whenever a user
                  searches for anything related to your brand name. This is
                  generally to ensure no competitor is eating up your
                  prospective leads.
                </p> */}
              </div>
              {/* <div>
              <PrimaryButton title="Approve All" icon="approve" type="light" />
            </div> */}
            </div>

            {(strategy?.adsets || []).map((campaign, ids) => {
              return (
                <Accordion className="accordion_strategy_body" key={ids}>
                  <AccordionSummary className="accordion_strategy_head">
                    <div className="strategy_accordion_title">
                      <h4>{campaign.name}</h4>
                      <span></span>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="accordion_strategy_content">
                    <div className="strategy_accordion_layout">
                      <div className="strategy_row_item">
                        <div className="strategy_column_item search_row_strategy">
                          <Autocomplete
                            open
                            multiple
                            // disableCloseOnSelect
                            noOptionsText=""
                            renderOption={(props, option, { selected }) => (
                              <li
                                {...props}
                                className="autosearch_list"
                                onClick={() => {}}
                              >
                                <div className="strategy_list_row">
                                  <div className="strategy_list_column">
                                    <span className="search_icon"></span>
                                    <p>{option}</p>
                                  </div>
                                  <div>
                                    <div className="headline_column_actions">
                                      {/* <button
                                      className={`list_search_actions accept_icon`}
                                    ></button> */}
                                      <button
                                        className={`list_search_actions reject_icon`}
                                        disabled
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )}
                            options={[...campaign.keywords]}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <div className="search_strategy_header">
                                <span className="search_icon"></span>
                                <TextField
                                  {...params}
                                  ref={params.InputProps.ref}
                                  inputProps={params.inputProps}
                                  autoFocus
                                  placeholder="Search..."
                                  variant="standard"
                                />
                              </div>
                            )}
                            disablePortal={true}
                            className="auto_complete_search"
                          />
                        </div>
                        <div className="strategy_column_item">
                          <Tabs>
                            <div className="custom_tab_header">
                              <Tab>Headline</Tab>
                              <Tab>Description</Tab>
                              <Tab>Preview</Tab>
                            </div>
                            <Panel>
                              <div className={`tabpanel_strategy`}>
                                {(campaign?.headlines || []).map(
                                  (headline, idx) => {
                                    return (
                                      <div
                                        className="headerline_row_strategy"
                                        key={idx}
                                      >
                                        <div className="headline_column_strategy">
                                          <h4>{headline.value}</h4>
                                          <div className="headline_keyword_details">
                                            <p>{headline.category}</p>
                                            <span>
                                              {headline.value.length}/30
                                            </span>
                                          </div>
                                        </div>
                                        <div className="headline_column_actions">
                                          {/* <button className="actions_headline_strategy accept_icon " disabled></button> */}
                                          <button
                                            className="actions_headline_strategy reject_icon"
                                            disabled
                                          ></button>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </Panel>
                            <Panel>
                              <div className={`tabpanel_strategy`}>
                                {(campaign?.descriptions || []).map(
                                  (desc, idx) => {
                                    return (
                                      <div
                                        className="headerline_row_strategy"
                                        key={idx}
                                      >
                                        <div className="headline_column_strategy">
                                          <h4>{desc.value}</h4>
                                          <div className="headline_keyword_details">
                                            <p>{desc.category}</p>
                                            <span>{desc.value.length}/90</span>
                                          </div>
                                        </div>
                                        <div className="headline_column_actions">
                                          {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                          <button
                                            className="actions_headline_strategy reject_icon"
                                            disabled
                                            onClick={() => {
                                              // removeResource(desc, "brandCampaign", "descriptions", campaign.name)
                                            }}
                                          ></button>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </Panel>
                            <Panel>
                              <div className={`tabpanel_strategy`}>
                                <div className="strategy_campaign_preview">
                                  <p className="campaign_preview_title">
                                    {(campaign?.headlines || [])
                                      .slice(0, 3)
                                      .map((headline) => headline.value)
                                      .join(" - ")}
                                  </p>
                                  <p className="campaign_preview_link">
                                    {
                                      form?.onboardingInfo?.personalInfo
                                        ?.companyWebsite
                                    }
                                  </p>
                                  <p className="campaign_preview_desc">
                                    {(campaign?.descriptions || [])
                                      .slice(0, 1)
                                      .map((desc) => desc.value)
                                      .join(" - ")}
                                  </p>
                                </div>
                                <div className="strategy_campaign_preview">
                                  <p className="campaign_preview_title">
                                    {(campaign?.headlines || [])
                                      .slice(3, 6)
                                      .map((headline) => headline.value)
                                      .join(" - ")}
                                  </p>
                                  <p className="campaign_preview_link">
                                    {
                                      form?.onboardingInfo?.personalInfo
                                        ?.companyWebsite
                                    }
                                  </p>
                                  <p className="campaign_preview_desc">
                                    {(campaign?.descriptions || [])
                                      .slice(1, 2)
                                      .map((desc) => desc.value)
                                      .join(" - ")}
                                  </p>
                                </div>
                              </div>
                            </Panel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </div>
      ))}

      {pmaxStrategies.length && (
        <>
          <div className="pmax_campaign">
            <div
              style={{
                height: "108px",
                width: "1069px",
                backgroundColor: "#F0F3FF",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={CAMERA}
                style={{ width: "56px", height: "56px", objectFit: "cover" }}
                alt=""
              />
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "28px",
                  fontWeight: "400",
                  lineHeight: "34px",
                }}
              >
                {" "}
                Performance Max Campaign
              </Typography>
            </div>
          </div>
          {pmaxStrategies.map((strategy, index) => (
            <>
              <div className="strategy_row_layout" key={`pmax=${index}`}>
                <div className="strategy_google_row_main">
                  <div className="google_strategy_header">
                    <div>
                      <div className="google_strategy_row">
                        <div className="google_strategy_title">
                          <p>{strategy.name}</p>
                        </div>
                        <div className="google_title_budget">
                          <p>Daily Budget : ${strategy.budget}</p>
                        </div>
                      </div>
                      {/* <p className="google_title_info">
                        Brand Campaigns essentially help you serve ads
                        whenever a user searches for anything related to your
                        brand name. This is generally to ensure no competitor
                        is eating up your prospective leads.
                      </p> */}
                    </div>
                    {/* <div>
                    <PrimaryButton title="Approve All" icon="approve" type="light" />
                  </div> */}
                  </div>

                  {strategy?.assets?.map((asset, index) => (
                    <Accordion className="accordion_strategy_body" key={index}>
                      <AccordionSummary className="accordion_strategy_head">
                        <div className="strategy_accordion_title">
                          <h4
                            style={{
                              fontFamily: "Inter",
                              fontSize: "18px",
                              lineHeight: "21.78px",
                              fontWeight: "600",
                              paddingLeft: "14px",
                            }}
                          >
                            {asset?.name}
                          </h4>
                          <span></span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="accordion_strategy_content">
                        <div className="strategy_accordion_layout">
                          <div
                            className="strategy_row_item"
                            style={{ justifyContent: "center" }}
                          >
                            <div
                              className="strategy_column_item search_row_strategy"
                              style={{ flexBasis: "40%" }}
                            >
                              <div className="strategy_segment">
                                <span
                                  style={{
                                    fontFamily: "Inter",
                                    fontSize: "13.96px",
                                    fontWeight: "500",
                                    lineHeight: "16.9px",
                                    marginTop: "16.14px",
                                  }}
                                >
                                  {" "}
                                  <img
                                    src={PEOPLE}
                                    style={{
                                      marginRight: "13.44px",
                                      width: "22.30px",
                                      height: "11.96px",
                                    }}
                                    alt=""
                                  />
                                  {asset?.segment?.name}
                                </span>
                                <div
                                  style={{
                                    borderBottom: "1px solid #DBDEE2",
                                    marginTop: "10.74px",
                                  }}
                                ></div>
                                {segments
                                  ?.find(
                                    (segment) =>
                                      asset?.segment?.id === segment.id ||
                                      asset?.segment?.id === segment.resourceName
                                  )
                                  ?.keywords?.map((keyword, index) => (
                                    <div className="segment_body">
                                      <p key={index}>
                                        <img
                                          src={Person}
                                          style={{
                                            height: "11.94px",
                                            width: "9.53px",
                                            marginRight: "19.11px",
                                          }}
                                          alt=""
                                        />
                                        {keyword}
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            </div>
                            <PMaxStrategyDeck isClient={false} asset={asset} />
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </div>
            </>
          ))}
        </>
      )}

      <div className="preview_footer_row">
        <div
          className="footer_content"
          style={{ justifyContent: "space-between" }}
        >
          <button
            className="backbutton"
            onClick={onBackClickHandler}
            style={{
              cursor: "pointer",
            }}
          >
            BACK
          </button>
          <PrimaryButton
            title={buttonTitle}
            disabled={disableButton}
            onClick={onClickHandler}
          ></PrimaryButton>
        </div>
      </div>

      {sureModal && (
        <StrategyDeckSureModal
          modal={sureModal}
          setModal={setSureModal}
          publishDeck={publishDeck}
        />
      )}
      {publishModal && (
        <ProposalPublishedModal
          formSubm={publishModal}
          setFormSubm={setPublishModal}
          title="Strategy Deck Published!"
          description="You will be notified as soon as the client has reviewed / approved the strategy deck."
          buttonTitle="Home"
        />
      )}
    </div>
  );
};
export default StrategyDeckGoogle;
