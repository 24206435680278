import { useMutation, useQuery } from "@tanstack/react-query";
import { getLocalStorage } from "../../api/localStorage/localStorage";
import { api } from "../../api";
import { queryClient } from "../../utils/queryClient";

const URL = "/targeting-keywords/google-seach-ads/"

const GET_GOOGLE_SEARCH_KEYWORDS = ["google-search-keywords/get"];
export const useGetKeywords = (options={}) => {
    return useQuery(GET_GOOGLE_SEARCH_KEYWORDS, async () => {
    const formId = (getLocalStorage("amSelectedFormId") ? getLocalStorage("amSelectedFormId") : getLocalStorage("formId"));
    const targetResponse = await api.get(URL + formId);
    return targetResponse.data;
    }, {
        refetchInterval: (data) => !data || !data.keywordGenerated ? 5000 : false
    });
}


// post call for generating keywords
export const usePostKeywords =  (options={}) => {
    const { onSuccess, ...restOptions } = options;
    return useMutation({
        mutationFn: async (fields={}) => {
            const formId = (getLocalStorage("amSelectedFormId") ? getLocalStorage("amSelectedFormId") : getLocalStorage("formId"));
            const response = await api.post(URL + formId);
            return response.data;
        },
        onSuccess: (...args) => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: GET_GOOGLE_SEARCH_KEYWORDS });
            onSuccess?.(...args);
        },
        ...restOptions
    });
}

// update keywords for google search
export const usePutKeyword = (options={}) => {
    const { onSuccess, ...restOptions } = options;
    return useMutation({
        mutationFn: async (items={}) => {
            const formId = (getLocalStorage("amSelectedFormId") ? getLocalStorage("amSelectedFormId") : getLocalStorage("formId"));
            const response = await api.put(URL + formId, { items });
            return response.data;
        },
        onSuccess: (...args) => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: GET_GOOGLE_SEARCH_KEYWORDS });
            onSuccess?.(...args);
        },
        ...restOptions
    });
}
