import { useQuery } from "@tanstack/react-query";
import { api } from "../api";

export const useGetAdAssets = (formId, options = {}) => {
    return useQuery(["g-ads/ad-assets/get"], async () => {
        const response = await api.get(`/g-ads/ad-assets?formId=${formId}`)
        return response.data;
    }, options)
};

const getGoogleAdsAudiences = ({ formId }) => ["/g-ads/audience?formId={formId}", formId];
export const useGetGoogleAdsAudiences = ({ formId }, options = {}) => {
    return useQuery(
        getGoogleAdsAudiences({ formId }),
        async () => {
            const response = await api.get(`/g-ads/audience?formId=${formId}`);
            return response.data;
        },
        options,
    );
};

const getGoogleAdsUserListQueryKey = ({ formId }) => ["/g-ads/user-list?formId={formId}", formId];
export const useGetGoogleAdsUserList = ({ formId }, options = {}) => {
    return useQuery(
        getGoogleAdsUserListQueryKey({ formId }),
        async () => {
            const response = await api.get(`/g-ads/user-list?formId=${formId}`);
            return response.data;
        },
        options,
    );
};

export const useGetGoogleAdsCrietria = (options = {}) => {
    return useQuery(
        [`/g-ads/criteria`],
        async () => {
            const response = await api.get(`/g-ads/criteria`);
            return response.data;
        },
        options,
    );
};

export const segregrateGoogleAdsets = (assets = []) => {
    const imageAssetLibrary = [];
    const logoAssetLibrary = [];
    const videoAssetLibrary = [];
    assets.forEach((asset) => {
        if (asset.type === 'YOUTUBE_VIDEO') videoAssetLibrary.push(asset);
        else if (asset.type === 'IMAGE') imageAssetLibrary.push(asset);
        else logoAssetLibrary.push(asset);
    });
    return { imageAssetLibrary, logoAssetLibrary, videoAssetLibrary }
};
export const useGetGoogleAdsAssets = ({ formId }, options = {}) => {
    return useQuery(
        ["/g-ads/ad-assets?formId={formId}", formId],
        async () => {
            const response = await api.get(`/g-ads/ad-assets?formId=${formId}`);
            return response.data;
        },
        options
    );
};

export const useGetGoogleAdsGioTargets = ({searchTerm, formId}, options={}) => {
    return useQuery(
        ["/g-ads/geo-targets"],
        async () => {
            const response = await api.get(  `/g-ads/geo-targets/${formId}?locationNames=` + searchTerm  );
            return response.data;
        },
        options,
    );
}

export const useGetGoogleInsight = (formId, options={}) => {
    return useQuery(
        ["/g-ads/audit-summary"],
        async () => {
            const response = await api.get("/g-ads/audit-summary" + formId);
            return response.data;
        },
        options
    );
}

export const useGetGoogleCustomers = () => {
    return useQuery(
        ["/g-ads/customers"],
        async () => {
            const response = await api.get("/g-ads/customers");
            return response.data;
        }
    );
}

export const useGetGoogleConversionGoals = ({ formId }, options = {}) => {
    return useQuery(
        ["/g-ads/conversion-goals?formId={formId}", formId],
        async () => {
            const response = await api.get(`/g-ads/conversion-goals?formId=${formId}`);
            return response.data;
        },
        options
    );
};
