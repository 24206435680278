import { Close } from "@mui/icons-material";
import StyledDropMultipleFiles from "../../BrowseMultipleFiles";
import { useDispatch } from "react-redux";

const MultipleFiles = ({
  objVal,
  setFile,
  setObjectValue,
  objKey,
  infoType,
  outerKey,
  value,
  setSocialProof,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
        }}
      >
        {Array.isArray(objVal) &&
          objVal.map((val, index) => {
            return (
              <div
                style={{ width: "40%", height: "50px", position: "relative" }}
                key={index}
              >
                <img
                  src={val}
                  style={{
                    width: "inherit",
                    objectFit: "contain",
                    height: "100%",
                  }}
                  alt=""
                />
                <div
                  style={{
                    background: "white",
                    position: "absolute",
                    left: "-0.5rem",
                    top: "-0.5rem",
                    borderRadius: "50%",
                    width: 20,
                    height: 20,
                  }}
                >
                  <Close
                    color="warning"
                    fontSize="small"
                    style={{ cursor: "pointer" }}
                    onClick={
                      () =>
                        setSocialProof((prevState) => {
                          return {
                            ...prevState,
                            [objKey]: prevState[objKey].filter(
                              (file, nI) => nI !== index
                            ),
                          };
                        })
                      // dispatch({
                      //   type: "DELETE_MULTIPLE_FILE",
                      //   payload: {
                      //     key: objKey,
                      //     infoType: infoType,
                      //     outerKey: outerKey,
                      //     value: index,
                      //   },
                      // })
                    }
                  />
                </div>
              </div>
            );
          })}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StyledDropMultipleFiles setObj={setObjectValue} setFile={setFile} />
      </div>
    </>
  );
};
export default MultipleFiles;
