export const updateWholeFormDataHdmAm = () => {
  return {
    type: 'UPDATE_WHOLE_HDM_AM_DETAILS_SAGA',
  };
};

export const saveHdmAmFormsData = (payload) => {
  return {
    type: 'SAVE_WHOLE_HDM_AM_DETAILS_SAGA',
    payload,
  };
};

export const publishHdmAmFormStrategy = (payload) => {
  return {
    type: 'PUBLISH_HDM_AM_STRATEGY',
    payload: payload,
  };
};

export const assignValueFirstTimeStrategyCreation = (payload) => {
  return {
    type: 'ASSIGN_FIRST_TIME_STRATEGY_CREATION_VALUE',
    payload: payload,
  };
};

export const addNewAdsetAction = (payload) => {
  return {
    type: 'ADD_NEW_ADSET_HDM_AM',
    payload: payload,
  };
};

export const deleteAdsetAction = (payload) => {
  return {
    type: 'DELETE_ADSET_HDM_AM',
    payload: payload,
  };
};

export const updateValueAdsetDropdown = (payload) => {
  return {
    type: 'UPDATE_ADSET_DROPDOWN_VALUE',
    payload: payload,
  };
};

export const updateValueRangeAdset = (payload) => {
  return {
    type: 'UPDATE_ADSET_RANGE_VALUE',
    payload: payload,
  };
};

export const addToThisAdsetAction = (payload) => {
  return {
    type: 'ADD_TO_THIS_ADSET_HDM',
    payload: payload,
  };
};

export const addToAllAdsetAction = (payload) => {
  return {
    type: 'ADD_TO_ALL_ADSET_HDM',
    payload: payload,
  };
};

export const deleteSpecificAdsAction = (payload) => {
  return {
    type: 'DELETE_SPECIFIC_ADS_HDM',
    payload: payload,
  };
};

export const editSpecificAdsAction = (payload) => {
  return {
    type: 'EDIT_SPECIFIC_ADS_HDM',
    payload: payload,
  };
};

export const addInterestAdset = (payload) => {
  return {
    type: 'ADD_ADSET_INTEREST_HDM',
    payload: payload,
  };
};

export const clearAllAdsHdm = (payload) => {
  return {
    type: 'CLEAR_ALL_ADS_HDM',
    payload: payload,
  };
};

export const updateBudgetFunnelHdm = (payload) => {
  return {
    type: 'UPDATE_BUDGET_FUNNEL_HDM',
    payload: payload,
  };
};

export const updateStatusHdmAm = (payload) => {
  return {
    type: 'UPDATE_STATUS_HDM_AM',
    payload: payload,
  };
};
