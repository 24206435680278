import { isAnyCampaignsPublished } from "../../../components/CampaignPreview/utils";
import { BACKEND_STATUS, PROPOSAL_STATE, leftBarStatus } from "../../accountManagerUtil";
import { proposalStage, strategyStage } from "../commonUtils";

export const listOfIds = {
  proposal: "proposal",
  intakeForm: "form",
  strategy: "strategy_deck_google",
  campaignPreview: "CAMPAIGN_PREVIEW",
  creativeApproval: "CREATIVE_APPROVAL",
  analyticDashboard: "AnalyticDashboard",
};

export const leftPanelLabelLDClient = {
  READ_SHARED_ASSETS: "Read Access",
  PROPOSAL: "Proposal",
  INTAKE_FORM: "Intake Form",
  COMPLETE_SHARED_ASSETS: "Complete Access",
  STRATEGY_DECK: "Strategy Deck",
  CAMPAIGN_PREVIEW: "Campaign Preview",
  CREATIVE_APPROVAL: "Creative Approval",
  ANALYTICS_DASHBOARD: "Analytics Dashboard",
};

export const leftPanelNameInUrlPath = {
  HOMEPAGE: "homepage",
  READ_SHARED_ASSETS: "read-access",
  PROPOSAL: "proposal",
  INTAKE_FORM: "intake-form",
  COMPLETE_SHARED_ASSETS: "complete-access",
  STRATEGY_DECK: "strategy-deck",
  CAMPAIGN_PREVIEW: "campaign-preview",
  CREATIVE_APPROVAL: "creative-approval",
  ANALYTICS_DASHBOARD: "analytics-dashboard",
};



export const accessSharingStatus = {
  PENDING: "PENDING",
  CONNECTED: "CONNECTED",
  NOT_CONNECTED: "NOT_CONNECTED",
  VERIFYING: "VERIFYING",
  READ_ACCESS: "READ_ACCESS",
  ACCESS_REVOKED: "ACCESS_REVOKED",
  COMPLETE_ACCESS: "COMPLETE_ACCESS",
  VERIFYING_COMPLETE_ACCESS: "VERIFYING_COMPLETE_ACCESS",
  COMPLETED_ACCESS_NOT_SHARED: "COMPLETED_ACCESS_NOT_SHARED",
};

export const checkingReadAccess = (platformSelected, accountList) => {
  if (platformSelected.length === 2) {
    if (
      (accountList.fbAccess.adAccount.status === accessSharingStatus.VERIFYING &&
        accountList.fbAccess.adAccount.accountId.length === 0) ||
      (accountList.googleAccess.googleAdAccount.status === accessSharingStatus.VERIFYING &&
        accountList.googleAccess.googleAdAccount.accountId.length === 0)
    ) {
      return accessSharingStatus.PENDING;
    } else if (
      (accountList.fbAccess.adAccount.status === accessSharingStatus.VERIFYING &&
        accountList.fbAccess.adAccount.accountId.length !== 0) ||
      (accountList.googleAccess.googleAdAccount.status === accessSharingStatus.VERIFYING &&
        accountList.googleAccess.googleAdAccount.accountId.length !== 0)
    ) {
      return accessSharingStatus.VERIFYING;
    } else if (
      accountList.fbAccess.adAccount.status === accessSharingStatus.ACCESS_REVOKED ||
      accountList.googleAccess.googleAdAccount.status ===
        accessSharingStatus.ACCESS_REVOKED
    ) {
      return accessSharingStatus.ACCESS_REVOKED;
    } else if (
      accountList.fbAccess.adAccount.status === accessSharingStatus.READ_ACCESS &&
      accountList.googleAccess.googleAdAccount.status === accessSharingStatus.READ_ACCESS
    ) {
      return accessSharingStatus.READ_ACCESS;
    }
  } else if (
    platformSelected.length === 1 &&
    platformSelected[0].platform === "google-ads"
  ) {
    if (
      accountList.googleAccess.googleAdAccount.status === accessSharingStatus.VERIFYING &&
      accountList.googleAccess.googleAdAccount.accountId.length === 0
    ) {
      return accessSharingStatus.PENDING;
    } else if (
      accountList.googleAccess.googleAdAccount.status === accessSharingStatus.VERIFYING &&
      accountList.googleAccess.googleAdAccount.accountId.length !== 0
    ) {
      return accessSharingStatus.VERIFYING;
    } else if (
      accountList.googleAccess.googleAdAccount.status ===
      accessSharingStatus.ACCESS_REVOKED
    ) {
      return accessSharingStatus.ACCESS_REVOKED;
    } else if (
      accountList.googleAccess.googleAdAccount.status === accessSharingStatus.READ_ACCESS
    ) {
      return accessSharingStatus.READ_ACCESS;
    }
  } else if (
    platformSelected.length === 1 &&
    platformSelected[0].platform === "facebook-ads"
  ) {
    if (
      accountList.fbAccess.adAccount.status === accessSharingStatus.VERIFYING &&
      accountList.fbAccess.adAccount.accountId.length === 0
    ) {
      return accessSharingStatus.PENDING;
    } else if (
      accountList.fbAccess.adAccount.status === accessSharingStatus.VERIFYING &&
      accountList.fbAccess.adAccount.accountId.length !== 0
    ) {
      return accessSharingStatus.VERIFYING;
    } else if (
      accountList.fbAccess.adAccount.status === accessSharingStatus.ACCESS_REVOKED
    ) {
      return accessSharingStatus.ACCESS_REVOKED;
    } else if (accountList.fbAccess.adAccount.status === accessSharingStatus.READ_ACCESS) {
      return accessSharingStatus.READ_ACCESS;
    }
  }
  return accessSharingStatus.PENDING;
};
export const checkingForCompleteAccess = (platformSelected, accountList) => {
  for (let i = 0; i < platformSelected.length; i++) {
    if (platformSelected[i].platform === "facebook-ads") {
      if (
        accountList.fbAccess.adAccount.status ===
        accessSharingStatus.COMPLETED_ACCESS_NOT_SHARED
      ) {
        return accessSharingStatus.COMPLETED_ACCESS_NOT_SHARED;
      }
    } else if (platformSelected[i].platform === "google-ads") {
      if (
        accountList.googleAccess.googleAdAccount.status ===
        accessSharingStatus.COMPLETED_ACCESS_NOT_SHARED
      ) {
        return accessSharingStatus.COMPLETED_ACCESS_NOT_SHARED;
      }
    }
  }
  for (let i = 0; i < platformSelected.length; i++) {
    if (platformSelected[i].platform === "facebook-ads") {
      if (
        accountList.fbAccess.adAccount.status ===
        accessSharingStatus.VERIFYING_COMPLETE_ACCESS
      ) {
        return accessSharingStatus.VERIFYING_COMPLETE_ACCESS;
      }
    } else if (platformSelected[i].platform === "google-ads") {
      if (
        accountList.googleAccess.googleAdAccount.status ===
        accessSharingStatus.VERIFYING_COMPLETE_ACCESS
      ) {
        return accessSharingStatus.VERIFYING_COMPLETE_ACCESS;
      }
    }
  }
  let complete = true;
  for (let i = 0; i < platformSelected.length; i++) {
    if (platformSelected[i].platform === "facebook-ads") {
      if (accountList.fbAccess.adAccount.status !== accessSharingStatus.COMPLETE_ACCESS) {
        complete = false;
        break;
      }
    } else if (platformSelected[i].platform === "google-ads") {
      if (
        accountList.googleAccess.googleAdAccount.status !==
        accessSharingStatus.COMPLETE_ACCESS
      ) {
        complete = false;
        break;
      }
    }
  }
  if (
    checkingReadAccess(platformSelected, accountList) === accessSharingStatus.PENDING &&
    !complete
  ) {
    return accessSharingStatus.PENDING;
  }
  if (
    accessSharingStatus.VERIFYING === checkingReadAccess(platformSelected, accountList) &&
    !complete
  ) {
    return accessSharingStatus.VERIFYING;
  }
  if (
    accessSharingStatus.ACCESS_REVOKED ===
      checkingReadAccess(platformSelected, accountList) &&
    !complete
  ) {
    return accessSharingStatus.ACCESS_REVOKED;
  }
  if (
    checkingReadAccess(platformSelected, accountList) ===
      accessSharingStatus.READ_ACCESS &&
    !complete
  ) {
    return accessSharingStatus.READ_ACCESS;
  }
  return complete
    ? accessSharingStatus.COMPLETE_ACCESS
    : accessSharingStatus.VERIFYING_COMPLETE_ACCESS;
};

export const getStatusForLeftBarLDClient = (form) => {
  const statusObj = {
    [listOfIds.proposal]: leftBarStatus.toBeUnlocked,
    [listOfIds.intakeForm]: leftBarStatus.toBeUnlocked,
    [listOfIds.completeAccess]: leftBarStatus.toBeUnlocked,
    [listOfIds.strategy]: leftBarStatus.toBeUnlocked,
    [listOfIds.campaignPreview]: leftBarStatus.toBeUnlocked,
    [listOfIds.creativeApproval]: leftBarStatus.toBeUnlocked,
    [listOfIds.analyticDashboard]: leftBarStatus.toBeUnlocked,
  };


  if(form?.proposalIterations?.length){
    statusObj[listOfIds.proposal] = leftBarStatus.ongoing;
  }
  if (proposalStage(form?.proposalIterations || []) === PROPOSAL_STATE.APPROVED){
    statusObj[listOfIds.proposal] = leftBarStatus.completed;
    statusObj[listOfIds.intakeForm] = leftBarStatus.ongoing;
  }

  if (form?.intakeInfo?.status === BACKEND_STATUS.COMPLETED){
    statusObj[listOfIds.intakeForm] = leftBarStatus.completed;
  }
  if(form?.strategyIterations?.length){
    statusObj[listOfIds.strategy] = leftBarStatus.ongoing;
  }
  if(strategyStage(form?.strategyIterations || []) === PROPOSAL_STATE.APPROVED){
    statusObj[listOfIds.strategy] = leftBarStatus.completed;    
  }
  if(form?.anyGoogleCampaignPublished || form?.anyFbCampaignPublished){
    statusObj[listOfIds.campaignPreview] = leftBarStatus.completed;    
    statusObj[listOfIds.creativeApproval] = leftBarStatus.completed;    
    statusObj[listOfIds.analyticDashboard] = leftBarStatus.completed;    
  }

  return statusObj;
};
