export const DATA_FORMAT_TO_GET_STRATEGIES = {
  FULLDATA: "FULLDATA",
  METADATA: "METADATA",
}

export const getCampaignTitle = (campType, campaignTypeCount) => {
  if(campType === "branded_keyword"){
    return `Brand Campaign `;
  }
  if(campType === "service_keyword"){
    campaignTypeCount.serviceCampaign += 1;
    return `Service Campaign ${campaignTypeCount.serviceCampaign}`;
  }
  if(campType === "competitors_keyword"){
    campaignTypeCount.competitorCampaign += 1;
    return `Competitor Campaign ${campaignTypeCount.competitorCampaign}`;
  }
  if(campType === "custom"){
    campaignTypeCount.newCampaign += 1;
    return `New Campaign ${campaignTypeCount.newCampaign}`;
  }
}

export const getNewlyCreatedAdset = (adsets = [], result = []) => {
  return (result || []).filter(resultAdset => !adsets.find(adset => adset.id_ === resultAdset.id_))[0];
}

export const tabsTitleWithBackendMapper = {
  HEADLINE: "headlines",
  DESCRIPTION: "descriptions",
  PREVIEW: "preview"
}

export const sourceTypeForAdGroup = {
  GENERATE_RESOURCE: "GENERATE_RESOURCE",
  GENERATE_ADSET: "GENERATE_ADSET"
}