import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  LegendPosition,
  TextStyle,
  Palette,
  TablePalette,
  LegendStyle,
} from "../Charts/ChartUtils";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { Colors } from "../Charts/ChartUtils";

const ChartSettings = ({ editDrawerState }) => {
  const { chart, handleChartChange } = editDrawerState;
  const [legendPos, setLegendPos] = useState(chart.chartStyle.legendPosition);
  const [selectedPalette, setSelectedPalette] = useState(
    chart.chartStyle.palette
  );

  const [formats, setFormats] = React.useState(
    chart.chartStyle.titleStyle.fontFormat
  );

  const [legendStyle, setLegendStyle] = React.useState(
    chart.chartStyle.legendStyle
  );

  const [titleStyle, setTitleStyle] = useState(chart.chartStyle.titleStyle);

  const [tableStyle, setTableStyle] = useState(chart.chartStyle.tableStyle);

  const [seriesStyle, setSeriesStyle] = useState(chart.chartStyle.seriesStyle);

  // const [chartStyle, setChartStyle] = useState({
  //   FontStyle: "",
  //   BackColor: "",
  //   BorderColor: "",
  // });

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  useEffect(() => {
    handleTitleChange("fontFormat", formats);
  }, [formats]);

  useEffect(() => {
    handleChartChange("chartStyle", { titleStyle: titleStyle });
  }, [titleStyle]);

  useEffect(() => {
    handleChartChange("chartStyle", { legendStyle: legendStyle });
  }, [legendStyle]);

  useEffect(() => {
    handleChartChange("chartStyle", { tableStyle: tableStyle });
  }, [tableStyle]);

  useEffect(() => {
    handleChartChange("chartStyle", { seriesStyle: seriesStyle });
  }, [seriesStyle]);

  const handleTitleChange = (property, value) => {
    setTitleStyle({ ...titleStyle, [property]: value });
  };

  const handleLegendStyleChange = (property, value) => {
    setLegendStyle({ ...legendStyle, [property]: value });
  };

  const handleTableChange = (setting, property, value) => {
    setTableStyle({
      ...tableStyle,
      [setting]: { ...tableStyle[setting], [property]: value },
    });
  };

  const handleSeriesChange = (property, value) => {
    setSeriesStyle({ ...seriesStyle, [property]: value });
  };

  const toShowLegendStyle = (type) => {
    return type !== "DONUT" && type !== "FUNNEL" && type !== "TABLE";
  };
  const toShowTitleStyle = (type) => {
    return type !== "KPI";
  };
  const toShowSeriesStyle = (type) => {
    return type === "LINE" || type === "BAR" || type === "LINEBAR" || type === "STACKBAR" || type === "AREA";
  };

  return (
    <div style={{ padding: "24px" }}>
      {/* <Accordion disableGutters sx={{ boxShadow: "none" }}>
        <AccordionSummary
          sx={{ maxHeight: "30px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h4 className="inter bold">Chart Style</h4>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl
            sx={{ width: "100%", justifyContent: "center" }}
            size="small"
          >

            <Grid container>
              <Grid item xs={6}>
                <h4 className="inter bold" >
                  Background Color
                </h4>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  style={{
                    width: "50%",
                    backgroundColor: chartStyle.BackColor,
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setChartStyle({ ...chartStyle, BackColor: e.target.value });
                  }}
                >
                  {Colors.map((pos, index) => (
                    <MenuItem
                      key={index}
                      value={pos}
                      style={{ height: "30px", backgroundColor: pos }}
                    ></MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={6}>
                <h4 className="inter bold" >
                  Border Color
                </h4>
                <Select
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  style={{
                    width: "50%",
                    backgroundColor: chartStyle.BorderColor,
                  }}
                  variant="outlined"
                  onChange={(e) => {
                    setChartStyle({
                      ...chartStyle,
                      BorderColor: e.target.value,
                    });
                  }}
                >
                  {Colors.map((pos, index) => (
                    <MenuItem
                      key={index}
                      value={pos}
                      style={{ height: "30px", backgroundColor: pos }}
                    ></MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </FormControl>
        </AccordionDetails>
      </Accordion> */}

      {toShowTitleStyle(chart.type) && (
        <Accordion disableGutters sx={{ boxShadow: "none" }}>
          <AccordionSummary
            sx={{ maxHeight: "30px" }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4 className="inter bold">Chart Title</h4>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl
              sx={{ width: "100%", justifyContent: "center" }}
              size="small"
            >
              <Grid container>
                <Grid item xs={6}>
                  <h4 className="inter bold">Font Style</h4>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    style={{ width: "90%" }}
                    variant="outlined"
                    value={titleStyle.font}
                    onChange={(e) => {
                      handleTitleChange("font", e.target.value);
                    }}
                  >
                    {TextStyle.FontStyle.map((pos, index) => (
                      <MenuItem key={index} value={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <h4 className="inter bold">Font Size</h4>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    style={{ width: "90%" }}
                    variant="outlined"
                    value={titleStyle.fontSize}
                    onChange={(e) => {
                      handleTitleChange("fontSize", e.target.value);
                    }}
                  >
                    {TextStyle.FontSize.map((pos, index) => (
                      <MenuItem key={index} value={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>

              <Grid container style={{}}>
                <Grid item xs={6}>
                  <h4 className="inter bold" style={{ marginTop: "25px" }}>
                    Font Format
                  </h4>
                  <ToggleButtonGroup
                    value={formats}
                    onChange={handleFormat}
                    aria-label="text formatting"
                  >
                    <ToggleButton value="bold" aria-label="bold">
                      <FormatBoldIcon />
                    </ToggleButton>
                    <ToggleButton value="italic" aria-label="italic">
                      <FormatItalicIcon />
                    </ToggleButton>
                    <ToggleButton value="underline" aria-label="underlined">
                      <FormatUnderlinedIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6}>
                  <h4 className="inter bold" style={{ marginTop: "25px" }}>
                    Font Color
                  </h4>
                  <input
                    style={{ width: "90%", height: "45px" }}
                    type="color"
                    value={titleStyle.color}
                    onChange={(e) => {
                      handleTitleChange("color", e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}

      {toShowSeriesStyle(chart.type) && (
        <Accordion disableGutters sx={{ boxShadow: "none" }}>
          <AccordionSummary
            sx={{ maxHeight: "30px" }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4 className="inter bold">Chart Series</h4>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl
              sx={{ width: "100%", justifyContent: "center" }}
              size="small"
            >
              <FormControlLabel control={
                <Checkbox
                  checked={seriesStyle?.dataPoints}
                  onChange={(e) => { handleSeriesChange("dataPoints", e.target.checked) }}
                />}
                label="Enable Data Points"
              />
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}

      {toShowLegendStyle(chart.type) && (
        <Accordion disableGutters sx={{ boxShadow: "none" }}>
          <AccordionSummary
            sx={{ maxHeight: "30px" }}
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h4 className="inter bold">{chart.type === "WORDCLOUD" ? "Word Style" : "Legend Style"}</h4>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl
              sx={{ width: "100%", justifyContent: "center" }}
              size="small"
            >
              {(chart.type !== "KPI" && chart.type !== "WORDCLOUD" && chart.type !== "GEO") && (
                <>
                  <h4 className="inter bold">Position:</h4>
                  <Select
                    sx={{
                      width: "95%",
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      marginBottom: "25px",
                    }}
                    value={legendPos}
                    variant="outlined"
                    onChange={(e) => {
                      handleChartChange("chartStyle", {
                        legendPosition: e.target.value,
                      });
                      setLegendPos(e.target.value);
                    }}
                  >
                    {LegendPosition.map((pos, index) => (
                      <MenuItem key={index} value={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}

              <Grid container style={{}}>
                <Grid item xs={6}>
                  <h4 className="inter bold">Font Style</h4>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    style={{ width: "90%" }}
                    variant="outlined"
                    value={legendStyle.fontStyle}
                    onChange={(e) => {
                      handleLegendStyleChange("fontStyle", e.target.value);
                    }}
                  >
                    {LegendStyle.FontStyle.map((pos, index) => (
                      <MenuItem key={index} value={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <h4 className="inter bold">Font Size</h4>
                  <Select
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    style={{ width: "90%" }}
                    variant="outlined"
                    value={legendStyle.fontSize}
                    onChange={(e) => {
                      handleLegendStyleChange("fontSize", e.target.value);
                    }}
                  >
                    {LegendStyle.FontSize.map((pos, index) => (
                      <MenuItem key={index} value={pos}>
                        {pos}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}

      {/* table styles */}
      {chart.type === "TABLE" && (
        <>
          <Accordion disableGutters sx={{ boxShadow: "none" }}>
            <AccordionSummary
              sx={{ maxHeight: "30px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h4 className="inter bold">Table Header Style</h4>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl
                sx={{ width: "100%", justifyContent: "center" }}
                size="small"
              >
                <Grid container>
                  <Grid item xs={6}>
                    <h4 className="inter bold">Font Style:</h4>
                    <Select
                      style={{ width: "90%" }}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "25px",
                      }}
                      value={tableStyle.tableHeader.font}
                      variant="outlined"
                      onChange={(e) => {
                        handleTableChange(
                          "tableHeader",
                          "font",
                          e.target.value
                        );
                      }}
                    >
                      {TextStyle.FontStyle.map((pos, index) => (
                        <MenuItem key={index} value={pos}>
                          {pos}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={6}>
                    <h4 className="inter bold">Font Size:</h4>
                    <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      style={{ width: "90%" }}
                      variant="outlined"
                      value={tableStyle.tableHeader.fontSize}
                      onChange={(e) => {
                        handleTableChange(
                          "tableHeader",
                          "fontSize",
                          e.target.value
                        );
                      }}
                    >
                      {TextStyle.FontSize.map((pos, index) => (
                        <MenuItem key={index} value={pos}>
                          {pos}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </FormControl>
            </AccordionDetails>
          </Accordion>
          <Accordion disableGutters sx={{ boxShadow: "none" }}>
            <AccordionSummary
              sx={{ maxHeight: "30px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h4 className="inter bold">Table Content Style</h4>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl
                sx={{ width: "100%", justifyContent: "center" }}
                size="small"
              >
                <Grid container>
                  <Grid item xs={6}>
                    <h4 className="inter bold">Font Style:</h4>
                    <Select
                      style={{ width: "90%" }}
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginBottom: "25px",
                      }}
                      value={tableStyle.tableContent.font}
                      variant="outlined"
                      onChange={(e) => {
                        handleTableChange(
                          "tableContent",
                          "font",
                          e.target.value
                        );
                      }}
                    >
                      {TextStyle.FontStyle.map((pos, index) => (
                        <MenuItem key={index} value={pos}>
                          {pos}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  <Grid item xs={6}>
                    <h4 className="inter bold">Font Size:</h4>
                    <Select
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      style={{ width: "90%" }}
                      variant="outlined"
                      value={tableStyle.tableContent.fontSize}
                      onChange={(e) => {
                        handleTableChange(
                          "tableContent",
                          "fontSize",
                          e.target.value
                        );
                      }}
                    >
                      {TextStyle.FontSize.map((pos, index) => (
                        <MenuItem key={index} value={pos}>
                          {pos}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      <Accordion disableGutters sx={{ boxShadow: "none" }} defaultExpanded>
        <AccordionSummary
          sx={{ maxHeight: "30px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h4 className="inter bold">Chart Color Palette</h4>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {(chart.type === "TABLE" ? TablePalette : Palette).map((colors, index) =>
              index === 0 ? (
                <Grid item xs={12}>
                  <Grid item xs={12} style={{ display: "flex" }}>
                    <span
                      className="inter"
                      style={{
                        color: "#858585",
                        fontSize: 12,
                        float: "right",
                        paddingBottom: "5px",
                        paddingRight: "2px",
                      }}
                    >
                      Default Palette
                    </span>
                  </Grid>

                  <Grid item xs={6}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        borderRadius: "8px",
                        overflow: "hidden",
                        marginBottom: "8px",
                        cursor: "pointer",
                        border:
                          index === selectedPalette ? "1px solid blue" : "",
                        opacity: index === selectedPalette ? "1" : "0.6",
                      }}
                      onClick={() => {
                        handleChartChange("chartStyle", {
                          palette: index,
                        });
                        setSelectedPalette(index);
                      }}
                    >
                      {colors.map((color) => (
                        <div
                          style={{
                            height: "40px",
                            width: "20%",
                            backgroundColor: color,
                          }}
                        ></div>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      borderRadius: "8px",
                      overflow: "hidden",
                      marginBottom: "8px",
                      cursor: "pointer",
                      border:
                        index === selectedPalette ? "1px solid blue" : "",
                      opacity: index === selectedPalette ? "1" : "0.6",
                    }}
                    onClick={() => {
                      handleChartChange("chartStyle", {
                        palette: index,
                      });
                      setSelectedPalette(index);
                    }}
                  >
                    {colors.map((color) => (
                      <div
                        style={{
                          height: "40px",
                          width: "20%",
                          backgroundColor: color,
                        }}
                      ></div>
                    ))}
                  </div>
                </Grid>
              )
            )
            }
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ChartSettings;
