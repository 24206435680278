import React from 'react';
import { Typography } from '@material-ui/core';
import generatingData from '../assets/bulb-icon.svg';
const GeneratingButton = () => {
  return (
    <div className='generating-data-button'>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 12,
          lineHeight: '20px',
          color: '#F99F35',
        }}
      >
        Generating Data
      </Typography>
      <div className='lock-icon-image-wrapper'>
        <img src={generatingData} alt='' />
      </div>
    </div>
  );
};

export default GeneratingButton;
