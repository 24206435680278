import {ready as readyGif} from "../../../assets/icons/proposal/proposal";
import { useDispatch,useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { useEffect } from "react";
import {isAccountValid} from "../../../api/HDMAM/audit/saga";

const ScopeofWorkModal = () => {
  return (
    <div
      className="layout_submission"
      style={{
        width: "100%",
        margin: "auto",
      }}
    >
      <div className="center">
        <img
          src={readyGif}
          className="img-submit"
          style={{
            width: 180,
            height: 180,
          }}
        />
        <div className="submit-title">
           Hold Tight! Crawling through the account.
        </div>
        <div className="submit-desc">
           This usually takes 30 mins to 1 hour.
          <br />
          
        </div>
        <Button
          variant="contained"
          style={{
            marginTop: "2rem",
            width: 110,
          }}
        >
          Home
        </Button>
      </div>
    </div>
  );
};
export default ScopeofWorkModal;
