import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { approveDeckApi } from "../../../api/client/saga";
import CAMERA from "../../../assets/icons/pmax_campaign.svg";
import crossIcon from "../../../components/PMaxStrategyCreation/pmaxIcons/cross-icon.svg";
import PMaxStrategyDeck from "../../AccountManager/StrategyDeck/PmaxStrategyDeck";
import PEOPLE from "../../AccountManager/StrategyDeck/Vector.svg";
import Person from "../../AccountManager/StrategyDeck/person.svg";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { Panel, Tabs, useTabState } from "../../CustomTab/customTab";
import StrategyDeckApproval from "../../modals/StrategyDeckApproval";
import { getGoogleAdsStrategyQueryKey, usePutGoogleAdsStrategies, usePutGoogleAdsStrategy } from "../../../services/googleAdsStrategy";
import { queryClient } from "../../../utils/queryClient";
import { usePutPmaxStrategiesParallel } from "../../../services/googlePmaxStrategy";
import { usePutGoogleSegments } from "../../../services/googleSegments";

const cn = (...args) => args.filter(Boolean).join(" ");

const Tab = ({ children }) => {
  const { isActive, onClick } = useTabState();

  return (
    <button className={cn("tab", isActive && "active")} onClick={onClick}>
      {children}
    </button>
  );
};
const StrategyDeckGoogle = ({
  searchStrategies = [],
  pmaxStrategies = [],
  segments = [],
  buttonTitle,
  onBackClickHandler,
  onClickHandler,
  disableButton,
  proceedToPublish,
  form,
  onProceedHandler,
}) => {
  
  const [modalApprove, setModalApprove] = useState(false);
  const [totalBudget, setTotalBudget] = useState(0);
  const [googleSearchStrategies, setGoogleSearchStrategies] = useState([]);
  const [googlePmaxStrategies, setGooglePmaxStrategies] = useState([]);
  const [pmaxSegments, setPmaxSegments] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  // const publishedDate = (date) => moment(date).format("YYYY-MM-DD");

  const strategyIterations = useSelector(
    (state) => state.client.strategyIterations
  );

  const { mutateAsync: mutatePutGoogleStrategies } = usePutGoogleAdsStrategies(
    form?._id
  );
  const { mutateAsync: mutatePutPmaxStrategies } = usePutPmaxStrategiesParallel(form?._id);
  const { mutate: mutatePutSegments } = usePutGoogleSegments(form?._id);
  console.log("segments", segments);
  useEffect(() => {
    if (isDirty) {
      mutatePutGoogleStrategies(googleSearchStrategies.map(strategy => {
        return {...strategy, validateAssets:false};
      }));
      mutatePutPmaxStrategies(googlePmaxStrategies.map(strategy => {
        return {...strategy, validateAssets:false};
      }));
      mutatePutSegments(pmaxSegments.filter(segment => segment.id));
      onClickHandler?.();
    }
  }, [isDirty]);

  useEffect(() => {
    if (proceedToPublish) {
      approveDeckApi(strategyIterations).then((res) => {
        setModalApprove(true);
      });
    }
  }, [proceedToPublish]);

  useEffect(() => {
    if (searchStrategies?.length) {
      setGoogleSearchStrategies(searchStrategies);
    }
  }, [searchStrategies]);
  useEffect(() => {
    if (pmaxStrategies?.length) {
      setGooglePmaxStrategies(pmaxStrategies);
    }
  }, [pmaxStrategies]);
  useEffect(() => {
    if (segments?.length) {
      let segmentIds = [];
      pmaxStrategies.map((strategy) => {
        (strategy.assets || []).map(asset => {
          segmentIds.push(asset?.segment?.id);
        })
      });
      segmentIds = segmentIds.filter(id => !!id);
      const tempSegments = [];
      segments.map(segment => {
        if(segmentIds.includes(segment.id) || segmentIds.includes(segment.resourceName)){
          tempSegments.push(segment);
        }
      })
      setPmaxSegments(tempSegments);
    }
  }, [pmaxStrategies, segments]);

  useEffect(() => {
    let tempGoogleBudget = 0;
    searchStrategies.forEach((strategy) => {
      tempGoogleBudget += parseInt(strategy?.budget?.value);
    });
    pmaxStrategies.forEach((strategy) => {
      tempGoogleBudget += parseInt(strategy?.budget);
    });
    setTotalBudget(tempGoogleBudget);
  }, [searchStrategies, pmaxStrategies]);
  const removeKeyword = (strategyIndex, adsetIndex, keywordIndex) => {
    const tempStrategy = JSON.parse(JSON.stringify(googleSearchStrategies));
    (tempStrategy[strategyIndex]?.adsets[adsetIndex]?.keywords || []).splice(keywordIndex, 1);
    setGoogleSearchStrategies(tempStrategy);
  };

  const removeResource = ( resourceType, strategyIndex, adsetIndex, resourceIndex) => {
    const tempStrategy = JSON.parse(JSON.stringify(googleSearchStrategies));
    (tempStrategy[strategyIndex]?.adsets[adsetIndex][resourceType] || []).splice(resourceIndex, 1);
    setGoogleSearchStrategies(tempStrategy);
  };

  const handleEditAssetGroup = (strategyIndex, assetIndex) => (resourceType, resourceIndex) => {
    const tempStrategy = JSON.parse(JSON.stringify(googlePmaxStrategies));
    (tempStrategy[strategyIndex]?.assets[assetIndex][resourceType] || []).splice(resourceIndex, 1);
    setGooglePmaxStrategies(tempStrategy);
  };

  const removeSegmentKeyword = (segmentId, keywordIndex) => {
    const tempSegments = JSON.parse(JSON.stringify(pmaxSegments));
    const tempSegmentIndex = tempSegments.findIndex(segment => segment.id === segmentId || segment.resourceName === segmentId);
    (tempSegments[tempSegmentIndex].keywords || []).splice(keywordIndex, 1);
    setPmaxSegments(tempSegments);
  }

  const proceedClickHandler = () => {
    setIsDirty(true);
  }

  return (
    <div className="intro_strategy_main clientdashboard">
      {/* {strategyIterations.length !== 0 &&
        strategyIterations[strategyIterations.length - 1].state ===
          "CHANGES_SUGGESTED" && (
          <div className="intro_strategy_layout">
            <div className="intro_strategy_warning">
              <span className="icon orange_check">
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.75"
                    width="20"
                    height="20"
                    rx="10"
                    fill="#F99F35"
                  />
                  <path
                    d="M6 10.7832L8.86226 13.65L14.7592 7.75"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <p>
                You are viewing the Strategy copy generated on{" "}
                {publishedDate(
                  strategyIterations[strategyIterations.length - 1].stateTs
                )}
                . Your suggested changes have been registered and you will be
                notified soon
              </p>
            </div>
          </div>
        )} */}
      <div className="google_strategy_layout">
        <div className="google_strategy_banner">
          <div className="google_banner_part">
            <div className="google_strategy_row">
              <div className="google_strategy_logo">
                <span></span>
                <p>Google</p>
              </div>
              <div className="google_strategy_budget">
                <p>Total Budget : ${totalBudget}</p>
              </div>
            </div>
            <p className="google_strategy_info">
              Here, you can review the strategy for running your campaigns on
              google to drive efficient delivery and performance. We recommend
              that you go through each of the recommendations here and replace
              the ones you do not want to display in your campaign strategy
              deck. Removing the ones you do not find useful, will automatically
              generate new recommendations at the bottom of each of the lists.
            </p>
          </div>
        </div>
      </div>
      {googleSearchStrategies.map((strategy, index) => {
        return (
          <div className="strategy_row_layout" key={`searchStrategy-${index}`}>
            <div className="strategy_google_row_main">
              <div className="google_strategy_header">
                <div>
                  <div className="google_strategy_row">
                    <div className="google_strategy_title">
                      <p>{strategy?.name || ""}</p>
                    </div>
                    <div className="google_title_budget">
                      <p>Daily Budget : ${strategy?.budget?.value || 0}</p>
                    </div>
                  </div>
                  {/* <p className="google_title_info">
                    Brand Campaigns essentially help you serve ads whenever a
                    user searches for anything related to your brand name. This
                    is generally to ensure no competitor is eating up your
                    prospective leads.
                  </p> */}
                </div>
              </div>
              {(strategy?.adsets || []).map((campaign, ids) => {
                return (
                  <Accordion className="accordion_strategy_body" key={`search-adset-${ids}`}>
                    <AccordionSummary className="accordion_strategy_head">
                      <div className="strategy_accordion_title">
                        <h4>{campaign.name}</h4>
                        <span></span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="accordion_strategy_content">
                      <div className="strategy_accordion_layout">
                        <div className="strategy_row_item">
                          <div className="strategy_column_item search_row_strategy">
                            <Autocomplete
                              open
                              multiple
                              disableCloseOnSelect
                              noOptionsText=""
                              renderOption={(props, option, { selected }) => {
                                return (
                                <li className="autosearch_list">
                                  <div className="strategy_list_row">
                                    <div {...props} onClick={() => {}}>
                                      <div className="strategy_list_column">
                                        <span className="search_icon"></span>
                                        <p>{option}</p>
                                      </div>
                                    </div>
                                    <div>
                                      <div className="headline_column_actions">
                                        {/* <button
                                      className={`list_search_actions accept_icon`}
                                    ></button> */}
                                        <button
                                          className={`list_search_actions reject_icon`}
                                          onClick={() => {
                                            removeKeyword(
                                              index,
                                              ids,
                                              props["data-option-index"]
                                            );
                                          }}
                                        ></button>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}}
                              options={[...campaign.keywords]}
                              getOptionLabel={(option) => option}
                              renderInput={(params) => (
                                <div className="search_strategy_header">
                                  <span className="search_icon"></span>
                                  <TextField
                                    {...params}
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder="Search..."
                                    variant="standard"
                                  />
                                </div>
                              )}
                              disablePortal={true}
                              className="auto_complete_search"
                            />
                          </div>
                          <div className="strategy_column_item">
                            <Tabs>
                              <div className="custom_tab_header">
                                <Tab>Headline</Tab>
                                <Tab>Description</Tab>
                                <Tab>Preview</Tab>
                              </div>
                              <Panel>
                                <div className={`tabpanel_strategy`}>
                                  {(campaign?.headlines || []).map(
                                    (headline, idx) => {
                                      return (
                                        <div
                                          className="headerline_row_strategy"
                                          key={idx}
                                        >
                                          <div className="headline_column_strategy">
                                            <h4>{headline.value}</h4>
                                            <div className="headline_keyword_details">
                                              <p>{headline.category}</p>
                                              <span>
                                                {headline.value.length}/30
                                              </span>
                                            </div>
                                          </div>
                                          <div className="headline_column_actions">
                                            {/* <button className="actions_headline_strategy accept_icon active" onClick={() => removeResource(headline, "brandCampaing", "headline")}></button> */}
                                            <button
                                              className="actions_headline_strategy reject_icon"
                                              onClick={() => {
                                                removeResource(
                                                  "headlines",
                                                  index,
                                                  ids,
                                                  idx,
                                                );
                                              }}
                                            ></button>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </Panel>
                              <Panel>
                                <div className={`tabpanel_strategy`}>
                                  {(campaign?.descriptions || []).map(
                                    (desc, idx) => {
                                      return (
                                        <div
                                          className="headerline_row_strategy"
                                          key={idx}
                                        >
                                          <div className="headline_column_strategy">
                                            <h4>{desc.value}</h4>
                                            <div className="headline_keyword_details">
                                              <p>{desc.category}</p>
                                              <span>
                                                {desc.value.length}/90
                                              </span>
                                            </div>
                                          </div>
                                          <div className="headline_column_actions">
                                            {/* <button className="actions_headline_strategy accept_icon active" onClick={}></button> */}
                                            <button
                                              className="actions_headline_strategy reject_icon"
                                              onClick={() => {
                                                removeResource(
                                                  "descriptions",
                                                  index,
                                                  ids,
                                                  idx,
                                                );
                                              }}
                                            ></button>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </Panel>
                              <Panel>
                                <div className={`tabpanel_strategy`}>
                                  <div className="strategy_campaign_preview">
                                    <p className="campaign_preview_title">
                                      {(campaign?.headlines || [])
                                        .slice(0, 3)
                                        .map((desc) => desc.value)
                                        .join(" - ")}
                                    </p>
                                    <p className="campaign_preview_link">
                                      {
                                        form?.onboardingInfo?.personalInfo
                                          ?.companyWebsite
                                      }
                                    </p>
                                    <p className="campaign_preview_desc">
                                      {(campaign?.descriptions || [])
                                        .slice(0, 1)
                                        .map((desc) => desc.value)
                                        .join(" - ")}
                                    </p>
                                  </div>
                                  <div className="strategy_campaign_preview">
                                    <p className="campaign_preview_title">
                                      {(campaign?.headlines || [])
                                        .slice(3, 6)
                                        .map((desc) => desc.value)
                                        .join(" - ")}
                                    </p>
                                    <p className="campaign_preview_link">
                                      {
                                        form?.onboardingInfo?.personalInfo
                                          ?.companyWebsite
                                      }
                                    </p>
                                    <p className="campaign_preview_desc">
                                      {(campaign?.descriptions || [])
                                        .slice(1, 2)
                                        .map((desc) => desc.value)
                                        .join(" - ")}
                                    </p>
                                  </div>
                                </div>
                              </Panel>
                            </Tabs>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </div>
        );
      })}

      {googlePmaxStrategies.length && <>
        <div className="pmax_campaign">
              <div
                style={{
                  height: "108px",
                  width: "1069px",
                  backgroundColor: "#F0F3FF",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={CAMERA}
                  style={{ width: "56px", height: "56px", objectFit: "cover" }}
                  alt=""
                />
                <Typography
                  style={{
                    fontFamily: "Lato",
                    fontSize: "28px",
                    fontWeight: "400",
                    lineHeight: "34px",
                  }}
                >
                  {" "}
                  Performance Max Campaign
                </Typography>
              </div>
            </div>
            {googlePmaxStrategies.map((strategy, index) => {
              return (
                <div className="strategy_row_layout" key={`pmax-strategy-${index}`}>
                  <div className="strategy_google_row_main">
                    <div className="google_strategy_header">
                      <div>
                        <div className="google_strategy_row">
                          <div className="google_strategy_title">
                            <p>{strategy?.name || ""}</p>
                          </div>
                          <div className="google_title_budget">
                            <p>Daily Budget : ${strategy?.budget}</p>
                          </div>
                        </div>
                        {/* <p className="google_title_info">
                          Brand Campaigns essentially help you serve ads
                          whenever a user searches for anything related to your
                          brand name. This is generally to ensure no competitor
                          is eating up your prospective leads.
                        </p> */}
                      </div>
                      {/* <div>
                      <PrimaryButton title="Approve All" icon="approve" type="light" />
                    </div> */}
                    </div>

                    {strategy?.assets?.map((asset, idx) => (
                      <Accordion
                        className="accordion_strategy_body"
                        key={`pmax-asset-${idx}`}
                      >
                        <AccordionSummary className="accordion_strategy_head">
                          <div className="strategy_accordion_title">
                            <h4
                              style={{
                                fontFamily: "Inter",
                                fontSize: "18px",
                                lineHeight: "21.78px",
                                fontWeight: "600",
                                paddingLeft: "14px",
                              }}
                            >
                              {asset?.name}
                            </h4>
                            <span></span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="accordion_strategy_content">
                          <div className="strategy_accordion_layout">
                            <div
                              className="strategy_row_item"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                className="strategy_column_item search_row_strategy"
                                style={{ flexBasis: "40%" }}
                              >
                                <div className="strategy_segment">
                                  <span
                                    style={{
                                      fontFamily: "Inter",
                                      fontSize: "13.96px",
                                      fontWeight: "500",
                                      lineHeight: "16.9px",
                                      marginTop: "16.14px",
                                    }}
                                  >
                                    {" "}
                                    <img
                                      src={PEOPLE}
                                      style={{
                                        marginRight: "13.44px",
                                        width: "22.30px",
                                        height: "11.96px",
                                      }}
                                      alt=""
                                    />
                                    {asset?.segment?.name}
                                  </span>
                                  <div
                                    style={{
                                      borderBottom: "1px solid #DBDEE2",
                                      marginTop: "10.74px",
                                    }}
                                  ></div>
                                  {pmaxSegments
                                  ?.find(
                                    (segment) =>
                                      asset?.segment?.id === segment.id ||
                                      asset?.segment?.id === segment.resourceName
                                  )?.keywords?.map(
                                    (keyword, keyIdx) => (
                                      <div className="segment_body">
                                        <p key={keyIdx}>
                                          <img
                                            src={Person}
                                            style={{
                                              height: "11.94px",
                                              width: "9.53px",
                                              marginRight: "19.11px",
                                            }}
                                            alt=""
                                          />
                                          {keyword}
                                        </p>

                                        <img
                                          src={crossIcon}
                                          style={{ cursor: "pointer" }}
                                          alt="cross"
                                          onClick={() =>
                                            removeSegmentKeyword(asset.segment.id, keyIdx)
                                          }
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <PMaxStrategyDeck
                                isClient={true}
                                asset={asset}
                                onRemove={handleEditAssetGroup(index, idx)}
                                campName="campaign1"
                              />
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                </div>
              )
            })}
      </>}
      <div className="preview_footer_row">
        <div
          className="footer_content"
          style={{ justifyContent: "space-between" }}
        >
          <button
            className="backbutton"
            onClick={onBackClickHandler}
          >
            BACK
          </button>
          <PrimaryButton
            title={buttonTitle}
            disabled={disableButton}
            onClick={proceedClickHandler}
          ></PrimaryButton>
        </div>
      </div>
      <StrategyDeckApproval
        formSubm={modalApprove}
        setFormSubm={setModalApprove}
        title="Strategy Deck Approved"
        description="We will notify you once the campaigns are ready for your preview"
        buttonTitle="PROCEED"
        type="linear"
        onProceedHandler={onProceedHandler}
      />
    </div>
  );
};
export default StrategyDeckGoogle;
