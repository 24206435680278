import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
export const StyledCardBody = styled("div")`
  border-radius: 8px;
  background: #f9f9f9;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  position: relative;
`;
export const StyledAccountDetails = styled("div")`
  display: flex;
  flex-direction: column;
`;
export const StyledAccountName = styled(Typography)`
  color: #2e3044;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
export const StyledAccountId = styled(Typography)`
  color: #6d6e7c;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
export const StyledMenuWithStatusBody = styled("div")`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 0;
`;
/* 
      Read Access BgColor - #FEF5EB
      Write Access BgColor - #E8F9F6

      Read Access textColor - #F99F35
      Write Access textColor - #1ABFA1
  */

export const StyledStatusLabel = styled("div")(
  ({ bgColor, textColor }) => `
     border-radius: 6px;
     padding: 4px 8px;
     background: ${bgColor};
     color: ${textColor};
     font-family: Inter;
     font-size: 11.5px;
     font-style: normal;
     font-weight: 400;
     line-height: 20px;
  `
);
export const StyledImageContainer = styled("div")`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const StyledAlreadyConnectedText = styled(Typography)`
  color: #6d6e7c;
  /* text-align: right; */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
