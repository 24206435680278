import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { Box, InputAdornment, Tooltip } from "@mui/material";
import { google_icon_big } from "../../../../assets/icons/icon";
import { facebook_pricing_icon } from "../../../../assets/icons/icon";
import Modal from "@mui/material/Modal";
import AccountSelectCheckbox from "../AccountSelectCheckBox/AccountSelectCheckbox";
import { searchIcon } from "../../../../assets/icons/admin/admin";
import { infoIcon } from "../../../../assets/icons/admin/admin";
import {
  StyledAccountList,
  StyledAccountListText,
  StyledAccountParaText,
  StyledAccountSearchFieldWrapper,
  StyledAlreadyConnectedAccountText,
  StyledButton,
  StyledChooseAccountText,
  StyledFooterButton,
  StyledIconContainer,
  StyledTextField,
  StyledTooltipLinkText,
  StyledTooltipText,
} from "./styled";
import { usePutAdAccountforSendingAccounts } from "../../../../services/adAccount";
import PmaxLoader from "../../../PMaxStrategyCreation/PmaxLoader";
import { useNavigate, useParams } from "react-router-dom";
import WarningModal from "../WarningModal/WarningModal";
import { getLocalStorage } from "../../../../api/localStorage/localStorage";
import { AGENCY_TYPES, PLATFORM_ADS } from "../../../../utils/commonEnums";
const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 521,
  bgcolor: "background.paper",
  boxShadow: 24,
  // p: 3.5,
  height: "auto",
  borderRadius: "8px",
  outline: "none",
};
const DEBOUNCE_DELAY = 300;
const SelectAccountFromList = ({
  openSelectAccounts,
  accountSelected,
  selectAccounts,
  adAccountList,
  payloadFromGoogleAuth,
  warningModal,
  setWarningModal,
  enableAllSelectedAccounts,
  setEnableAllSelectedAccounts,
  adAccountLocallyList,
  setAdAccountLocallyList,
  accountListModelView,
  openAccountListModal,
  platform,
}) => {
  const { tokenUid, data } = adAccountList;
  // console.log("Ad accounts list : ", adAccountList);
  // const [adAccounts, setAdAccounts] = useState();
  console.log(payloadFromGoogleAuth);
  const [searchString, setSearchString] = useState("");
  const [debouncedSearchString, setDebouncedSearchString] = useState("");
  const [adAccountIdsSelected, setAdAccountIdsSelected] = useState([]);
  // const [adAccountLocally, setAdAccountLocally] = useState(false);
  const navigate = useNavigate();
  const { formId } = useParams();
  const { mutate: mutateAdAccounttoSendAccount, isLoading } =
    usePutAdAccountforSendingAccounts(
      {
        brandId: payloadFromGoogleAuth.brandId,
        tokenUid: tokenUid,
        formId: formId,
      },
      {
        onSuccess: () => {
          console.log(
            "isCalledFromConnectedListAdAccount :: ",
            payloadFromGoogleAuth.isCalledFromConnectedListAdAccount
          );
          openSelectAccounts(false);
          if (payloadFromGoogleAuth.isCalledFromConnectedListAdAccount) {
            openAccountListModal(true);
          } else {
            if (payloadFromGoogleAuth.componentFromWhichCalled) {
              if (getLocalStorage("agencyType") === AGENCY_TYPES.BP) {
                if (
                  payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS
                ) {
                  navigate(
                    `/bp-am/${localStorage.getItem("formId")}/access-sharing`,
                    {
                      state: {},
                      replace: true,
                    }
                  );
                } else {
                  navigate(
                    `/bp-am/${localStorage.getItem(
                      "formId"
                    )}/access-sharing/facebook-access`,
                    {
                      state: {},
                      replace: true,
                    }
                  );
                }
              } else {
                if (
                  payloadFromGoogleAuth?.platform === PLATFORM_ADS.GOOGLE_ADS
                ) {
                  navigate(
                    `/ld-am/${localStorage.getItem("formId")}/access-sharing`,
                    {
                      state: {},
                      replace: true,
                    }
                  );
                } else {
                  navigate(
                    `/ld-am/${localStorage.getItem(
                      "formId"
                    )}/access-sharing/facebook-access`,
                    {
                      state: {},
                      replace: true,
                    }
                  );
                }
              }
            } else {
              openAccountListModal(false);
            }
          }
        },
      }
    );
  // useEffect(() => {
  //   console.log(
  //     "Selected ad accounts : ",
  //     adAccountLocallyList.filter(
  //       (adAccount) =>
  //         adAccount.alreadyConnectedBySelf ||
  //         adAccount.alreadyConnectedtoAnotherBrand
  //     )
  //   );
  // }, [adAccountLocallyList, setAdAccountLocallyList]);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchString(searchString);
    }, DEBOUNCE_DELAY);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchString]);
  const filteredAdAccounts = useMemo(
    () =>
      data.adAccounts.filter(({ adAccountName }) =>
        adAccountName
          .toLowerCase()
          .includes(debouncedSearchString.toLowerCase())
      ),
    [data.adAccounts, debouncedSearchString]
  );
  const onAccountsSelectSubmit = () => {
    if (enableAllSelectedAccounts) {
      mutateAdAccounttoSendAccount({
        fields: {
          adAccountIds: adAccountLocallyList
            .filter(
              (adAccount) =>
                !(
                  adAccount.alreadyConnectedBySelf ||
                  adAccount.alreadyConnectedtoAnotherBrand
                )
            )
            .map((adAccount) => adAccount.adAccountId),
          platform:
            payloadFromGoogleAuth.platform === "g-ads"
              ? "g-ads"
              : "facebook-ads",
          parentPlatformId: data.parentPlatformId,
          adAccountLocally: adAccountLocallyList.filter(
            (adAccount) =>
              adAccount.alreadyConnectedBySelf ||
              adAccount.alreadyConnectedtoAnotherBrand
          ),
        },
      });
    } else {
      mutateAdAccounttoSendAccount({
        fields: {
          adAccountIds: adAccountIdsSelected,
          platform:
            payloadFromGoogleAuth.platform === "g-ads"
              ? "g-ads"
              : "facebook-ads",
          parentPlatformId: data.parentPlatformId,
          adAccountLocally: [],
        },
      });
    }
  };

  if (isLoading) {
    return <PmaxLoader />;
  }
  return (
    <Modal
      open={selectAccounts}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Close
          onClick={() => {
            openSelectAccounts(false);
          }}
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            cursor: "pointer",
          }}
        />
        <StyledIconContainer icon={payloadFromGoogleAuth?.platform}>
          <img
            src={
              payloadFromGoogleAuth?.platform === "g-ads"
                ? google_icon_big
                : facebook_pricing_icon
            }
            alt="google"
            width={payloadFromGoogleAuth?.platform === "g-ads" ? 30 : 50}
            height={payloadFromGoogleAuth?.platform === "g-ads" ? 30 : 50}
          />
        </StyledIconContainer>
        <div>
          <StyledAccountListText>
            {payloadFromGoogleAuth?.brandName}
          </StyledAccountListText>
          <StyledAccountParaText>
            Select your account(s) to continue
          </StyledAccountParaText>
          <StyledAccountSearchFieldWrapper>
            <StyledChooseAccountText>
              Choose Account
              <Tooltip
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-arrow": {
                      color: "white",
                    },
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "white",
                      color: "#333",
                      marginBottom: "20px",
                      filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                      padding: "10px 8px 8px 12px",
                    },
                  },
                }}
                title={
                  <StyledTooltipText>
                    These are the ad accounts for which{" "}
                    <StyledTooltipLinkText>{data?.email}</StyledTooltipLinkText>{" "}
                    has access
                  </StyledTooltipText>
                }
                arrow
                placement="right"
              >
                <img src={infoIcon} alt="info-icon" />
              </Tooltip>
            </StyledChooseAccountText>
            <StyledTextField
              fullWidth
              placeholder="Search..."
              value={searchString}
              autoComplete="off"
              onChange={(event) => {
                setSearchString(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <img src={searchIcon} alt="fb" />
                  </InputAdornment>
                ),
              }}
            />
          </StyledAccountSearchFieldWrapper>
          <StyledAccountList>
            {filteredAdAccounts.map((adAccount) => (
              <AccountSelectCheckbox
                adAccountName={adAccount.adAccountName}
                adAccountId={adAccount.adAccountId}
                permissions={adAccount.permissions}
                adAccountLinked={adAccount.alreadyConnectedtoAnotherBrand}
                alreadyConnectedBySelf={adAccount.alreadyConnectedBySelf}
                adAccountIdsSelected={adAccountIdsSelected}
                setAdAccountIdsSelected={setAdAccountIdsSelected}
                key={adAccount.adAccountId}
                enableAllSelectedAccounts={enableAllSelectedAccounts}
                adAccount={adAccount}
                adAccountLocallyList={adAccountLocallyList}
                setAdAccountLocallyList={setAdAccountLocallyList}
              />
            ))}
            {!debouncedSearchString && !enableAllSelectedAccounts && (
              <StyledAlreadyConnectedAccountText
                onClick={() => {
                  // setAdAccountLocally(true);
                  setWarningModal(true);
                  openSelectAccounts(false);
                }}
              >
                Already Connected Ad Accounts
              </StyledAlreadyConnectedAccountText>
            )}
          </StyledAccountList>
          <StyledFooterButton>
            <StyledButton
              disabled={!adAccountIdsSelected.length}
              onClick={onAccountsSelectSubmit}
            >
              Add
            </StyledButton>
          </StyledFooterButton>
        </div>
      </Box>
    </Modal>
  );
};

export default SelectAccountFromList;
