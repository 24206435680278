import React from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import BpRadio from "../../CustomRadio/Radio";
import { useDispatch } from "react-redux";

const CustomerCollar = ({
  value,
  objKey,
  infoType,
  outerKey,
  error,
  errorText,
  setGoogleTargetting,
}) => {
  const dispatch = useDispatch();
  return (
    <FormControl>
      <RadioGroup
        defaultValue=""
        aria-labelledby="demo-customized-radios"
        name="customized-radios"
        style={{
          marginLeft: "0.8rem",
          flexDirection: "row",
          gap: "1rem",
        }}
        value={value}
        onChange={(e) => {
          setGoogleTargetting((prevState) => {
            return {
              ...prevState,
              [objKey]: e.target.value,
            };
          });
          // dispatch({
          //   type: 'GET_RADIO_BUTTON_VALUE',
          //   payload: {
          //     infoType: infoType,
          //     value: e.target.value,
          //     key: objKey,
          //     outerKey: outerKey,
          //   },
          // });
        }}
      >
        <FormControlLabel
          value="White Collar"
          control={<BpRadio />}
          label="White Collar"
          style={{
            width: 160,
            height: 42,
            background: "#FFFFFF",
            border: "1px solid #DBDBDB",
            boxShadow: "0px 15px 53px rgba(30, 20, 165, 0.03)",
            borderRadius: 10,
            paddingLeft: "0.5rem",
            // marginBottom: '1rem',
            color: "#7F7D87",
          }}
        />
        <FormControlLabel
          value="Blue Collar"
          control={<BpRadio />}
          label="Blue Collar"
          style={{
            width: 160,
            height: 42,
            background: "#FFFFFF",
            border: "1px solid #DBDBDB",
            boxShadow: "0px 15px 53px rgba(30, 20, 165, 0.03)",
            borderRadius: 10,
            paddingLeft: "0.5rem",
            color: "#7F7D87",
          }}
        />
      </RadioGroup>
      {error && <p style={{ color: "red" }}>{errorText}</p>}
    </FormControl>
  );
};

export default CustomerCollar;
