import React, { createRef, useEffect } from "react";
import {
  Box,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Tab,
  Tabs,
  Button,
  Select,
  Typography,
  Tooltip,
  Menu,
  CircularProgress,
  Alert,
  Snackbar
} from "@mui/material";
import { Errorhandler } from "../ErrorHanlder/ErrorHandler";
import { DateRangePicker } from "materialui-daterange-picker";
import Sidebar from "../EditWidget/Sidebar";
import { styled } from "@mui/material/styles";
import CalendarLogo from "../../../assets/images/Analytics/logos_calendar.svg";
import ChartWrapper from "../Charts/ChartWrapper";
import { connect } from "react-redux";
// import { checkAuth, getAllCustomColumns } from "../../api/channel/action";
// import { changeDate, updateFilterChartMap } from "../../api/dashboard/action";
import { GetChannel } from "../../models/channel";
import { useHistory, useParams } from "react-router-dom";
// import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react"
// import { sendFingerprint } from "../../api/auth/saga"
// import { getUser } from "../../api/user/action";
import RGL, { WidthProvider } from "react-grid-layout";
import { ReactComponent as AddChartLogo } from "../../../assets/images/Analytics/logos_add_chart.svg";
import { ReactComponent as RemoveChartLogo } from "../../../assets/images/Analytics/logos_remove_chart.svg";
import { Chart } from "../../models/chart";
import { Report, Tab as ReportTab, Grid as ReportGrid } from "../../models/report";
import CustomReportIcon from "../../../assets/images/Analytics/custom_report.svg";
// import { createReport, updateReport, deleteReport, getAllReports, getReport, updateChart, updateGrid, updateTab } from "../../api/report/action";
import { ReactComponent as AddTab } from "../../../assets/images/Analytics/AddTab.svg";
import ThreeDots from "../../../assets/images/Analytics/logos_threedots.svg";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import EditNameModal from "../Modal/EditNameModal";
// import { DragDropContext, Droppable } from "react-beautiful-dnd";
// import DraggableTab from "./DraggableTab";
import { TabContext } from "@mui/lab";
// import { blendsList } from "../../api/blends/action";
import { ChartFilterSupport, DateDisplayFormatter } from "../Charts/ChartUtils";
import jsPDF from "jspdf";
import "jspdf-autotable"
import { useSelector } from "react-redux";
import { defaultFBReport } from "../../models/defaultData";
import { changeDate, updateChart } from "../../../api/analytics/action";

const ReactGridLayout = WidthProvider(RGL);

const drawerwidth = 450;
const SEPARATOR = ":";

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: "100%",
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: 0,
  }),
  ...(open && {
    width: `calc(100% - ${drawerwidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: 0,
    }),
    marginRight: drawerwidth,
  }),
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const endDate = new Date();
endDate.setDate(endDate.getDate() - 1);
const startDate = new Date();
startDate.setDate(startDate.getDate() - 7);

function Dashboard({
  brand = "facebook-ads",
  selectedAccounts = {}, allReports = [], reports = {}, reportDownloaded = true,
  dateRange, changeDate, filterChartMap = {}, updateFilterChartMap,
  checkAuth, getUser,
  createReport, updateReport, deleteReport, getAllReports, getReport,
  updateChart, updateGrid, updateTab,
  blendsList, getAllCustomColumns, blends = []
}) {

  const { channel: reportId, tab: tabPath } = useParams();

  const [report, setReport] = React.useState(Report.fromJSON(reports[Object.keys(selectedAccounts)[0] ?? brand]));
  if (!report) {
    setReport(Report.fromJSON(defaultFBReport));
  }
  const [updateNameModal, setUpdateNameModal] = React.useState({ show: false, update: false })
  const [isReportUpdated, setIsReportUpdated] = React.useState(false);
  const stateUpdateNumber = React.useRef(0);

  // const currentTabIndex = report.tabs.findIndex(t => t.path === tabPath);
  const currentTabIndex = 0;

  if (currentTabIndex < 0) {
    // window.location.href = `/dashboard/${reportId}/${report.tabs[0].path}`;
  }

  // const history = useHistory();

  const [editDrawerState, setEditDrawerState] = React.useState({
    isOpen: false,
    chart: null,
    grid: null,
    handleGridChange: (property, value, gridId) => {
      // updateGrid(
      //   {
      //     reportId: report.id,
      //     updates: [{ grid_id: gridId, update_type: "update", params: { [property]: value } }],
      //   }
      // )
    },
    handleChartChange: (property, value) => { },
  });
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);
  const [exportTabData, setExportTabData] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    setAnchorEl3(null);
  };

  const [open, setOpen] = React.useState(false);
  const openErrorMsg = () => {
    setOpen(true);
  }
  const closeErrorMsg = () => {
    setOpen(false)
  }

  // const { data: visitorData } = useVisitorData();

  const [refs, setRefs] = React.useState({});

  const handleAddNew = (title, type, icon) => {
    if (type === "Report") {
      // createReport(Report.new(title, icon));
    } else {
      const tab = ReportTab.new(title, report.tabs.length);
      const grid = tab.grids[0];
      const charts = grid.charts;
      // updateTab({
      //   reportId: report.id,
      //   updates: [{ tab_id: tab.id, update_type: "add", params: tab.toJSON() }],
      // });
      // updateGrid({
      //   reportId: report.id,
      //   updates: [{ grid_id: grid.id, update_type: "add", params: grid.toJSON() }],
      // });
      // updateChart({
      //   reportId: report.id,
      //   updates: charts.map(chart => {
      //     const ref = createRef();
      //     setRefs({ ...refs, [chart.id]: ref })
      //     return ({ chart_id: chart.id, update_type: "add", params: chart.toJSON() })
      //   }),
      // });
      // navigateTo(report.id, tab.path)
    }
    setUpdateNameModal({ show: false, update: false })
  }

  const updateName = (title, type, icon) => {
    if (type === "Report") {
      // updateReport({ reportId: report.id, params: { report_name: title, report_icon: icon } })
    } else {
      // updateTab({
      //   reportId: report.id,
      //   updates: [{ tab_id: report.tabs[currentTabIndex].id, update_type: "update", params: { name: title, path: Report.getTabPathFromTitle(title) } }],
      // });
      // navigateTo(report.id, Report.getTabPathFromTitle(title))
    }
    setUpdateNameModal({ show: false, update: false })
  }

  const handleAllChartsForceReload = (filterId) => {
    filterChartMap[filterId]?.forEach(chartId => {
      refs[chartId].current.forceUpdateChart();
    })
  }

  const handleTabCsvExport = (tabIndex) => {
    var date = ["Date Range: ", DateDisplayFormatter(dateRange.startDate) + " - " + DateDisplayFormatter(dateRange.endDate)];
    let allData = [date, [], []];
    Object.keys(refs).forEach(chartId => {
      const data = refs[chartId].current.handleAllExport();
      if (data) {
        allData = [...allData, ...data, [], []]
      }
    })
    const blob = new Blob([allData.join("\n")], {
      type: "text/csv",
    });
    const a = document.createElement("a");
    a.download = report.tabs[tabIndex].name
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    a.href = window.URL.createObjectURL(blob);
    a.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
    a.remove();
  }

  const padding = 15;
  let top = 15;

  const handleTabPdfExport = async (tabIndex) => {
    var date = `Date Range:  ${DateDisplayFormatter(dateRange.startDate)} - ${DateDisplayFormatter(dateRange.endDate)}`;
    const name = report.tabs[tabIndex].name.replace(/[^a-zA-Z0-9 ]/g, "").replaceAll(" ", "-").toLowerCase();
    const doc = new jsPDF({});
    doc.text([date], padding, top);
    top += padding
    for (let chartId of Object.keys(refs)) {
      top = await refs[chartId].current.handleAllPdfExport(doc, top);
    }
    doc.save(`${name}.pdf`);
  }

  const handleTabPngExport = () => {
    Object.keys(refs).forEach(chartId => {
      refs[chartId].current.handleAllPngExport();
    })
  }

  const handleTabJpgExport = () => {
    Object.keys(refs).forEach(chartId => {
      refs[chartId].current.handleAllJpgExport();
    })
  }

  const handleTabDelete = (tabIndex) => {
    if (report.tabs.length <= 1) { return; }
    // updateTab({
    //   reportId: report.id,
    //   updates: [{ tab_id: report.tabs[tabIndex].id, update_type: "delete" },
    //   ...report.tabs.filter(tab => tab.position > report.tabs[tabIndex].position).map(tab => ({ tab_id: tab.id, update_type: "update", params: { position: tab.position - 1 } }))
    //   ],
    // });
    // updateGrid({
    //   reportId: report.id,
    //   updates: report.tabs[tabIndex].grids.map(grid => ({ grid_id: grid.id, update_type: "delete" }))
    // });
    const newRefs = refs;
    // updateChart({
    //   reportId: report.id,
    //   updates: report.tabs[tabIndex].grids.reduce((prev, curr) => prev.concat(curr.charts), []).map(chart => {
    //     delete newRefs[chart.id]
    //     return ({ chart_id: chart.id, update_type: "delete" })
    //   })
    // })
    setRefs({ ...newRefs })
    const newIndex = tabIndex === 0 ? 1 : 0
    // navigateTo(report.id, report.tabs[newIndex].path)
  }

  const onDragEnd = result => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const source = result.source.index;
    const destination = result.destination.index;
    let updates;
    if (source > destination) {
      updates = report.tabs.filter(tab => tab.position >= destination && tab.position <= source).map(tab => (
        { tab_id: tab.id, update_type: "update", params: { position: tab.position === source ? destination : tab.position + 1 } }
      ))
    } else {
      updates = report.tabs.filter(tab => tab.position >= source && tab.position <= destination).map(tab => (
        { tab_id: tab.id, update_type: "update", params: { position: tab.position === source ? destination : tab.position - 1 } }
      ))
    }
    // updateTab({ reportId: report.id, updates });
  };

  const handleRemoveChart = (chartId) => {
    const newRefs = refs;
    delete newRefs[chartId];
    setRefs({ ...newRefs });
    updateChart({
      reportId: report.id,
      updates: [{ chart_id: chartId, update_type: "delete" }],
    });
  }
  const [updateCount, setUpdateCount] = React.useState(1)

  useEffect(() => {
    setExportTabData(false)
    let childRefs = {};
    let filter_chart_map = {};

    report.tabs[currentTabIndex].grids.forEach((grid, gridIndex) => {
      grid.charts.forEach((chart, chartIndex) => {
        childRefs[chart.id] = createRef();
        if (typeof (chart.channelType) === "object" && chart.channelType?.id) {
          const blendId = chart.channelType.id;
          const blendSource = blends.find(source => source.id === chart.channelType.id);
          blendSource?.dsConfigs.forEach(source => {
            const filterId = source.dsOptions?.filterGrouping?.filterId;
            filter_chart_map[filterId] = (filter_chart_map[filterId] ?? []).concat(chart.id)
          })
        } else {
          if (ChartFilterSupport(chart.type)) {
            const filterId = chart.filter.id;
            filter_chart_map[filterId] = (filter_chart_map[filterId] ?? []).concat(chart.id)
          } else {
            const combinedMetrics = chart.leftMetrics.concat(chart.rightMetrics ?? []);
            for (let metric of combinedMetrics) {
              const filterId = metric.filter.id;
              if (filterId && !((filter_chart_map[filterId] ?? []).includes(chart.id))) {
                filter_chart_map[filterId] = (filter_chart_map[filterId] ?? []).concat(chart.id)
              }
            }
          }
        }
      })
    })
    setRefs({ ...childRefs })
    // updateFilterChartMap(filter_chart_map)
  }, [updateCount])

  // const { showTutorial, run } = useSelector(state => state.user.tutorial) || {};

  useEffect(() => {
    stateUpdateNumber.current++;
    if (stateUpdateNumber.current >= 1 && reportDownloaded) { // first to get initial redux state, then state update when API call is made, then state update when API call completed
      setIsReportUpdated(true);
      // if (showTutorial && run === false) {
      //   setStartTour(true);
      // }
    }
  }, [reportDownloaded]);

  const updateGridPos = (grid, event) => {
    const updates = [];
    event.forEach(e => {
      const id = e.i.split(SEPARATOR)[0];
      const chart = grid?.charts.find(c => c.id === id);
      if (chart && (
        chart.gridPosition.x !== e.x ||
        chart.gridPosition.y !== e.y ||
        chart.gridPosition.w !== e.w ||
        chart.gridPosition.h !== e.h
      )) {
        if (isReportUpdated) {
          updates.push({ chart_id: chart.id, update_type: "update", params: { gridPosition: e } });
        } else {
          chart.gridPosition.x = e.x;
          chart.gridPosition.y = e.y;
          chart.gridPosition.w = e.w;
          chart.gridPosition.h = e.h;
        }
      }
    });
    if (updates.length > 0) {
      updateChart({
        reportId: report.id,
        updates: updates,
      });
    }
  }

  const addNewBlock = (grid) => {
    const newRefs = {}
    const height = grid.charts.reduce((max, chart) => Math.max(max, chart.gridPosition.y + chart.gridPosition.h), 0)
    const newCharts = Array.from(Array(4)).map((_, index) => {
      const chart = Chart.new();
      chart.gridPosition = { ...chart.gridPosition, x: index * 3, y: height, w: 3, h: 1 };
      chart.gridId = grid.id;
      return chart;
    });
    updateChart({
      reportId: report.id,
      updates: newCharts.map(chart => {
        newRefs[chart.id] = createRef();
        return ({ chart_id: chart.id, update_type: "add", params: chart.toJSON() })
      }),
    });
    setRefs({ ...refs, ...newRefs })
  }

  const removeAddBlock = (grid) => {
    const removedChartIds = []
    const chartsLength = grid.charts.length;
    for (let index = chartsLength - 1; index > (chartsLength > 4 ? chartsLength - 5 : 0); index--) {
      const chart = grid.charts[index];
      if (chart.type === "ADD") {
        removedChartIds.push(grid.charts[index].id)
      }
    }
    const newRefs = refs;
    updateChart({
      reportId: report.id,
      updates: removedChartIds.map(id => {
        delete newRefs[id];
        return ({ chart_id: id, update_type: "delete" })
      })
    });
    setRefs({ ...newRefs });
  }

  const checkGridRemove = (grid) => {
    const chartsLength = grid.charts.length;
    for (let index = chartsLength - 1; index > (chartsLength > 4 ? chartsLength - 5 : 0); index--) {
      if (grid.charts[index].type === "ADD") {
        return true;
      }
    }
    return false;
  }

  const handleHeaderAdd = (tabId) => {
    const grid = ReportGrid.new(tabId, "New Header", "Sub-Header");
    const charts = grid.charts;
    // updateGrid({
    //   reportId: report.id,
    //   updates: [{ grid_id: grid.id, update_type: "add", params: grid.toJSON() }],
    // });
    const newRefs = {}
    // updateChart({
    //   reportId: report.id,
    //   updates: charts.map(chart => {
    //     newRefs[chart.id] = createRef();
    //     return ({ chart_id: chart.id, update_type: "add", params: chart.toJSON() })
    //   }),
    // });
    setRefs({ ...refs, ...newRefs })
  }

  const handleHeaderDelete = (grid) => {
    if (report.tabs[currentTabIndex].grids.length < 2) {
      openErrorMsg();
      return;
    }
    // updateGrid({
    //   reportId: report.id,
    //   updates: [{ grid_id: grid.id, update_type: "delete" }]
    // });
    const newRefs = refs;
    // updateChart({
    //   reportId: report.id,
    //   updates: grid.charts.map(chart => {
    //     delete newRefs[chart.id];
    //     return ({ chart_id: chart.id, update_type: "delete" })
    //   })
    // })
    setRefs({ ...newRefs });
  }

  // useEffect(() => {
  //   if (visitorData) {
  //     sendFingerprint(visitorData, visitorData.visitorId)
  //   }
  // }, [visitorData]);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0)
  }, [editDrawerState.isOpen]);

  useEffect(() => {
    // checkAuth();
    // getUser();
    // getAllReports();
    // if (report.id) { getReport({ id: reportId }); }
    // blendsList();
    // getAllCustomColumns();
  }, []);

  const [datePickerRange, setDatePickerRange] = React.useState(dateRange);

  // const navigateTo = (reportId, tabPath) => {
  //   setUpdateCount(updateCount + 1)
  //   if (tabPath) {
  //     history.push(`/dashboard/${reportId}/${tabPath}`);
  //   } else {
  //     history.push(`/dashboard/${reportId}/`);
  //   }
  // }

  const getActiveChartClass = (chartId) => {
    if (editDrawerState.isOpen && editDrawerState.chart?.id === chartId) {
      return "highlight-shadow";
    } else { return ""; }
  }

  const applyDate = () => {
    changeDate(datePickerRange);
    setIsDatePickerOpen(false);
  };

  return (
    <>
      <Box sx={{ display: "flex", background: "#fff" }}>
        <CssBaseline />
        {!isReportUpdated ? <CircularProgress disableShrink style={{ margin: "auto" }} /> :
          <Main open={editDrawerState.isOpen}>
            <Grid
              container
              rowSpacing={0}
              spacing={0}
              justify="space-around"
              alignItems="stretch"
              style={{
                padding: "24px 30px",
                margin: "0 auto",
                maxWidth: "1700px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: 'center',
                  padding: '0px 20px 0px 10px'
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", alignItems: "start" }}>
                  <img
                    style={{
                      marginRight: "15px",
                      marginTop: "15px",
                      height: "50px",
                    }}
                    src={report.icon ?? report.report_icon ?? CustomReportIcon}
                    alt={report.report_name}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h1 className="lato bold" style={{ margin: 0, fontSize: "35px", color: "#0869FB" }}>
                      {report.report_name}
                      { }
                    </h1>

                    <Menu
                      id="menu-appbar"
                      className="smooth-shadow"
                      anchorEl={anchorEl2}
                      keepMounted
                      getContentAnchorEl={null}
                      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                      transformOrigin={{ vertical: "top", horizontal: "center" }}
                      open={Boolean(anchorEl2)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => { setUpdateNameModal({ show: "Report", update: true }); handleClose(); }}>Edit Report</MenuItem>
                      {/* <MenuItem onClick={() => { handleClose(); deleteReport({ id: report.id }); }}>Delete Report </MenuItem> */}
                    </Menu>

                    <div style={{ height: "15px" }}>
                      <Tooltip
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-arrow': {
                              color: 'white',
                            },
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "white",
                              color: 'black',
                              marginBottom: '20px',
                              filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                              padding: "10px 25px"
                            }
                          }
                        }}
                        title={<Box sx={{ borderRadius: "10px" }}> {Object.keys(selectedAccounts).map((channelType, index) => {
                          const channel = GetChannel(channelType);
                          return <div style={{ borderRadius: '4px', padding: '3px' }} key={channelType} value={channel.name}>
                            <img height={16} width={16} src={channel.icon} alt={channel.title} />
                            <span style={{ paddingLeft: '15px' }}>{selectedAccounts[channelType].name} - {selectedAccounts[channelType].id}</span>
                          </div>
                        })}</Box>}
                        arrow placement="top-end">
                        <h4 style={{ maxWidth: '400px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', margin: 0 }} className="lato bold">
                          {
                            Object.values(selectedAccounts).map(account => {
                              return account.name + " - " + account.id
                            }).join(", ")
                          }
                        </h4>
                      </Tooltip>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FormControl sx={{ minWidth: '220px' }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ flexGrow: 1 }}
                      style={{ lineHeight: "16px" }}
                    >
                      <span className="inter" style={{ color: "#858585", fontSize: 12, float: "right", paddingBottom: "5px", paddingRight: "2px" }}>
                        Change report type
                      </span>

                    </Typography>
                    <Select
                      data-tut="reports_list"
                      id="demo-simple-select"
                      value={report.id}
                      sx={{
                        '& legend': { display: 'none' },
                        '& fieldset': { top: 0 },
                        borderRadius: '8px'
                      }}
                      size='small'
                      onChange={(event) => {
                        if (event.target.value === "new") {
                          setUpdateNameModal({ show: "Report", update: false })
                        } else {
                          setReport(Report.fromJSON(reports[event.target.value]))
                        }
                      }}
                      inputProps={{ sx: { display: 'flex', alignItems: 'center', width: "220px" } }}
                      MenuProps={{ sx: { marginTop: '8px', borderRadius: '8px', maxHeight: "500px" }, MenuListProps: { sx: { padding: '8px', borderRadius: '8px' } } }}
                    >
                      {allReports.map(report =>
                        <MenuItem sx={{ borderRadius: '4px', marginTop: '4px' }} key={report.id} value={report.id} disabled={!selectedAccounts[report.id]}>
                          <img height={16} width={16} src={report.report_icon || CustomReportIcon} alt="Custom Reports" />
                          <div style={{ paddingLeft: '15px', maxWidth: "200px", whiteSpace: "pre-line", wordWrap: "break-word" }}>
                            {report.report_name}
                          </div>
                        </MenuItem>
                      )}
                      {/* <hr />
                      <MenuItem sx={{ borderRadius: '4px', marginTop: '4px' }} value="new">
                        <Button style={{ width: "100%", background: "#DDDDDDAA", borderRadius: "8px" }}>+ New Report</Button>
                      </MenuItem> */}
                    </Select>
                  </FormControl>
                </div>

              </div>
              <Modal
                style={{ width: "50%", height: "50%", margin: "auto auto" }}
                open={isDatePickerOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onBackdropClick={false}
              >
                <Box className="dateRangePicker">
                  <DateRangePicker
                    style={{ width: "100%" }}
                    open={isDatePickerOpen}
                    toggle={() => setIsDatePickerOpen(false)}
                    onChange={(range) => setDatePickerRange(range)}
                    wrapperClassName="datePicker"
                  />
                  <div
                    style={{
                      display: "flex",
                      padding: "15px 20px",
                      backgroundColor: "white",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      onClick={() => setIsDatePickerOpen(false)}
                      variant="text"
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        marginLeft: "10px",
                      }}
                      onClick={applyDate}
                      variant="contained"
                      color="primary"
                    >
                      Apply
                    </Button>
                  </div>
                </Box>
              </Modal>
              <Grid rowSpacing={0} item xs={12} align="center">
                <Box sx={{ width: "100%" }} className="dashboard-tabs">
                  <TabContext value={currentTabIndex}>
                    <Box sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center", padding: '10px 20px', paddingBottom: '0' }}>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", maxWidth: "60vw" }}>
                        {/* <DragDropContext onDragEnd={onDragEnd}> */}
                        {/* <Droppable droppableId="1" direction="horizontal"> */}
                        {/* {droppableProvided => ( */}
                        {/* // <div style={{ display: "flex", alignItems: "center" }} ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}> */}
                        <Tabs
                          value={currentTabIndex}
                          // onChange={(event, newValue) => navigateTo(report.id, report.tabs[newValue].path)}
                          variant="scrollable"
                          scrollButtons={false}
                          aria-label="scrollable auto tabs example"
                          style={{ maxWidth: "50vw" }}
                          selectionFollowsFocus

                        >
                          {report.tabs.map((tab, tabIndex) => {
                            // const child = 
                            return <Tab
                              className="dashboard-tab"
                              label={tab.name}
                              sx={{ whiteSpace: "nowrap", textTransform: "none", padding: "0px", margin: "0px", display: 'flex' }}
                              value={tabIndex}
                              icon={currentTabIndex === tabIndex ? <ArrowDropDownIcon style={{ color: "#000000", marginLeft: "20px" }} onClick={(e) => setAnchorEl(e.currentTarget)} /> : null}
                              iconPosition="end"
                            />;
                            // return <DraggableTab label={tab.name} value={tab.value} index={tabIndex} key={tabIndex} child={child} />;
                          })}
                          {/* {droppableProvided ? droppableProvided.placeholder : null} */}
                        </Tabs>

                        {/* </div>
                            )}
                          </Droppable>
                        </DragDropContext> */}
                        {/* <Tooltip
                          title={"Add New Tab"}
                          placement="bottom"
                        >
                          <Button data-tut="add_tab" style={{ marginLeft: "10px", fontSize: "20px" }} onClick={() => setUpdateNameModal({ show: "Tab", update: false })}>
                            <AddTab height={"20px"} />
                          </Button>
                        </Tooltip> */}
                        <Menu
                          id="menu-appbar"
                          className="smooth-shadow"
                          anchorEl={anchorEl}
                          keepMounted
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                          transformOrigin={{ vertical: "top", horizontal: "center" }}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          {!exportTabData ?
                            <>
                              {/* <MenuItem onClick={() => { setUpdateNameModal({ show: "Tab", update: true }); handleClose(); }}>Rename Tab</MenuItem> */}
                              <MenuItem onClick={() => setExportTabData(true)}>Export Tab </MenuItem>
                              {/* <MenuItem onClick={() => { setUpdateNameModal({ show: "Tab", update: false }); handleClose(); }}>Add New Tab </MenuItem> */}
                              {/* <MenuItem onClick={() => { handleClose(); handleTabDelete(currentTabIndex); }}>Delete Tab </MenuItem> */}
                            </> :
                            <>
                              <MenuItem style={{ paddingLeft: "10px", color: "#00000099", fontSize: "14px" }} onClick={() => setExportTabData(false)}><ArrowBackIcon style={{ width: "16px" }} /><p style={{ margin: "auto 5px", fontWeight: "bold" }}> Export</p> </MenuItem>
                              <MenuItem style={{ paddingLeft: "30px" }} onClick={() => { handleClose(); handleTabPdfExport(currentTabIndex); }}>PDF Format</MenuItem>
                              <MenuItem style={{ paddingLeft: "30px" }} onClick={() => { handleClose(); handleTabPngExport(currentTabIndex); }}>PNG Format</MenuItem>
                              <MenuItem style={{ paddingLeft: "30px" }} onClick={() => { handleClose(); handleTabJpgExport(currentTabIndex); }}>JPG Format</MenuItem>
                              <MenuItem style={{ paddingLeft: "30px" }} onClick={() => { handleClose(); handleTabCsvExport(currentTabIndex); }}>CSV Format</MenuItem>
                            </>
                          }
                        </Menu>
                      </div>

                      <div style={{ display: "flex" }}>
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{ flexGrow: 1 }}
                          style={{ lineHeight: "16px" }}
                        >
                          <span className="inter" style={{ color: "#858585", fontSize: 12, float: "right" }}>
                            {dateRange.label ?? "Custom date range"}
                          </span>
                          <br />
                          <span className="inter bold" style={{ fontSize: 16 }}>
                            {DateDisplayFormatter(dateRange.startDate)} -{" "}
                            {DateDisplayFormatter(dateRange.endDate)}
                          </span>
                        </Typography>
                        <IconButton
                          style={{ width: "40px", height: "40px", marginLeft: "15px" }}
                          className="smooth-shadow"
                          onClick={() => setIsDatePickerOpen(true)}
                          aria-label="delete"
                        >
                          <img style={{ width: "18px" }} src={CalendarLogo} alt="Calendar" />
                        </IconButton>
                      </div>

                    </Box>
                    {report.tabs.map((tab, tabIndex) =>
                      <TabPanel key={tab.id} value={currentTabIndex} index={tabIndex}>
                        <Grid
                          container
                          spacing={2}
                          rowSpacing={0}
                          justify="space-evenly"
                          alignItems="center"
                        >
                          {tab.grids.map(grid =>
                            <Grid key={grid.id} item xs={12}>
                              {grid.title &&
                                <div className="tab-section-header" style={{ position: "relative" }}>
                                  <h4 className="inter"
                                    style={{
                                      fontSize: grid.gridStyle?.titleStyle.fontSize,
                                      fontFamily: grid.gridStyle?.titleStyle.font,
                                      fontWeight: grid.gridStyle?.titleStyle.fontFormat.includes("bold") && "bold",
                                      fontStyle: grid.gridStyle?.titleStyle.fontFormat.includes('italic') && "italic",
                                      textDecorationLine: grid.gridStyle?.titleStyle.fontFormat.includes('underline') && "underline",
                                      color: grid.gridStyle?.titleStyle.color
                                    }}
                                  >
                                    {grid.title}
                                  </h4>
                                  <h5 className="inter"
                                    style={{
                                      fontSize: grid.gridStyle?.subTitleStyle.fontSize,
                                      fontFamily: grid.gridStyle?.subTitleStyle.font,
                                      fontWeight: grid.gridStyle?.subTitleStyle.fontFormat.includes("bold") && "bold",
                                      fontStyle: grid.gridStyle?.subTitleStyle.fontFormat.includes('italic') && "italic",
                                      textDecorationLine: grid.gridStyle?.subTitleStyle.fontFormat.includes('underline') && "underline",
                                      color: grid.gridStyle?.subTitleStyle.color,
                                      margin: 0
                                    }}
                                  >
                                    {grid.subtitle}
                                  </h5>
                                  {/* <IconButton
                                    style={{ position: "absolute", right: "10px", top: "20px", height: "5%" }}
                                    onClick={(e) => setAnchorEl3({ target: e.currentTarget, grid: grid })}
                                  >
                                    <img src={ThreeDots} alt="dots" />
                                  </IconButton> */}
                                  <Menu
                                    id="menu-appbar"
                                    className="smooth-shadow"
                                    anchorEl={anchorEl3?.target}
                                    keepMounted
                                    getContentAnchorEl={null}
                                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                                    open={Boolean(anchorEl3?.target)}
                                    onClose={handleClose}
                                  >
                                    <MenuItem onClick={() => {
                                      setEditDrawerState({
                                        ...editDrawerState,
                                        isOpen: true,
                                        grid: anchorEl3.grid,
                                        chart: null
                                      });
                                      handleClose();
                                    }}>
                                      Edit Header
                                    </MenuItem>
                                    <MenuItem onClick={() => setExportTabData(true)}>Export Header </MenuItem>
                                    <MenuItem onClick={() => { handleHeaderAdd(tab.id); handleClose(); }}>
                                      Add New Header
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleHeaderDelete(anchorEl3.grid); handleClose(); }}>
                                      Delete Header
                                    </MenuItem>
                                  </Menu>
                                </div>
                              }
                              <Grid item xs={12}>
                                <ReactGridLayout
                                  isBounded={true}
                                  rowHeight={200}
                                  cols={12}
                                  padding={0}
                                  resizeHandles={["ne", "nw", "se", "sw", "e", "n", "s", "w"]}
                                  draggableHandle=".drag-handle"
                                  onLayoutChange={event => {
                                    updateGridPos(grid, event)
                                  }}
                                >
                                  {grid.charts.map(chart =>
                                    <div
                                      className={`flexible-box same-padding ${getActiveChartClass(chart.id)}`}
                                      key={chart.id}
                                      data-grid={chart.gridPosition}
                                    >
                                      <Errorhandler errorMessage="Error Loading Chart"
                                        removeChart={() => {
                                          handleRemoveChart(chart.id)
                                        }}
                                      >
                                        <ChartWrapper
                                          ref={refs[chart?.id]}
                                          chart={chart}
                                          reportId={report.id}
                                          report={report?.report_name}
                                          removeChart={() => {
                                            handleRemoveChart(chart.id)
                                          }}
                                          handleToggleEditDrawer={(
                                            chart,
                                            handleChartChange
                                          ) =>
                                            setEditDrawerState({
                                              ...editDrawerState,
                                              isOpen: true,
                                              chart: chart,
                                              grid: null,
                                              handleChartChange: handleChartChange,
                                            })
                                          }
                                        />
                                      </Errorhandler>
                                    </div>
                                  )}
                                </ReactGridLayout>
                                {checkGridRemove(grid) &&
                                  <div style={{ display: "flex", alignItems: "center", marginLeft: "-20px", marginRight: "10px" }}>
                                    <RemoveChartLogo style={{ width: "30px", height: "30px" }} onClick={() => removeAddBlock(grid)} />
                                    <div style={{ width: "100%", height: "8px", background: "#D9D9D999" }}></div>
                                  </div>
                                }
                                <div style={{ display: "flex", alignItems: "center", marginLeft: "-20px", marginRight: "10px" }}>
                                  <AddChartLogo style={{ width: "30px", height: "30px" }} onClick={() => addNewBlock(grid)} />
                                  <div style={{ width: "100%", height: "8px", background: "#D9D9D999" }}></div>
                                </div>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </TabPanel>
                    )}
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Main>
        }
        {/* <EditNameModal
          key={updateNameModal.show}
          oldName={updateNameModal.update ? (updateNameModal.show === "Report" ? report.report_name : report.tabs[currentTabIndex]?.name) : null}
          oldIcon={updateNameModal.update ? report.report_icon : CustomReportIcon}
          modal={updateNameModal}
          setModal={setUpdateNameModal}
          update={updateNameModal.update ? updateName : handleAddNew}
          allNames={updateNameModal.show === "Report" ? allReports.map(report => report.report_name) : report.tabs.map(tab => tab.path)}
        /> */}
        <Sidebar
          drawerwidth={drawerwidth}
          editDrawerState={editDrawerState}
          onClose={() =>
            setEditDrawerState({ ...editDrawerState, isOpen: false })
          }
          handleAllChartsForceReload={handleAllChartsForceReload}
        />
        <Snackbar open={open} autoHideDuration={3000} onClose={closeErrorMsg}>
          <Alert onClose={closeErrorMsg} severity="error" sx={{ width: '100%' }}>
            Cannot delete header. Must have at least one header in a tab.
          </Alert>
        </Snackbar>
      </Box >
    </>
  );
}

const mapStateToProps = state => ({
  selectedAccounts: state.dashboard.selectedAccounts,
  allReports: state.dashboard.allReports,
  reports: state.dashboard.reports,
  // reportDownloaded: state.report.reportDownloaded,
  dateRange: state.dashboard.dateRange,
  // filterChartMap: state.dashboard.filterChartMap,
  // blends: state.blends.blends
});

const mapDispatchToProps = dispatch => ({
  // checkAuth: () => dispatch(checkAuth()),
  // getUser: () => dispatch(getUser()),
  // createReport: (_) => dispatch(createReport(_)),
  // updateReport: (_) => dispatch(updateReport(_)),
  // deleteReport: (_) => dispatch(deleteReport(_)),
  // getAllReports: () => dispatch(getAllReports()),
  // getReport: (_) => dispatch(getReport(_)),
  updateChart: (_) => dispatch(updateChart(_)),
  // updateGrid: (_) => dispatch(updateGrid(_)),
  // updateTab: (_) => dispatch(updateTab(_)),
  changeDate: (_) => dispatch(changeDate(_)),
  // updateFilterChartMap: (_) => dispatch(updateFilterChartMap(_)),
  // blendsList: () => dispatch(blendsList()),
  // getAllCustomColumns: () => dispatch(getAllCustomColumns()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
