import healthcare1 from '../../../assets/images/healthcare1.jpeg';
import healthcare2 from '../../../assets/images/healthcare2.jpeg';
import healthcare3 from '../../../assets/images/healthcare3.jpeg';
import consumerService1 from '../../../assets/images/consumer-service1.jpeg';
import consumerService2 from '../../../assets/images/consumer-service2.jpeg';
import consumerService3 from '../../../assets/images/consumer-service3.jpeg';

const mappedIndustry=new Map([
    [
        "Healthcare",
        {
            links:[
                        "https://lineardesign.com/case-studies/burkhart-chiropractic/",
                        "https://lineardesign.com/case-studies/stanlick-chiropractic/",
                        "https://lineardesign.com/case-studies/all-star-chiropractic/"
                 ],
            imgs:[healthcare1,healthcare2,healthcare3],
            type:["B2C","B2C","B2C"],
            title:["Burkhart Chiropractic","Stanlick Chiropractic","All Star Chiropractic"],
            logo:["https://lineardesign.com/wp-content/uploads/2021/02/burkhart-svg-logo-large.svg","https://lineardesign.com/wp-content/uploads/2020/12/stanlick-svg-logo-large.svg","https://lineardesign.com/wp-content/uploads/2021/02/allstar-svg-large.svg"],
            testimony:["Linear added 30% to our collections in the first three months and it’s only continued to grow.  I would recommend Linear to anyone looking to grow their business…","I have been with Linear for 2 years now, and the results have been remarkable. We’ve gone from 50 new patients a month to over 100 new patients a month…","We were always a growing office, but since working with Linear we’ve added 20-30 new patients per month and upwards of $15,000 in additional monthly revenue…"],
            head:["Dr. Tim Burkhart","Mitch Stanlick","Dr. Bob Coppola"],
            subTitle:["Partner – Burkhart Chiropractic","Founder – Stanlick Chiropractic","Owner – All Star Chiropractic"]
        }
    ],
    [
        "Consumer Service",
        {
            links:[
                "https://lineardesign.com/case-studies/penji/",
                "https://lineardesign.com/case-studies/ordermark/",
                "https://lineardesign.com/case-studies/nextbite/"
            ],
            imgs:[consumerService1,consumerService2,consumerService3],
            type:["B2C","B2C","B2C"],
            title:["Penji","Ordermark","Nextbite"],
            logo:["https://lineardesign.com/wp-content/uploads/2022/05/pnj_large_svg.svg","https://lineardesign.com/wp-content/uploads/2022/05/ordermark_large_svg.svg","https://lineardesign.com/wp-content/uploads/2022/05/nextbite_large_svg.svg"],
            testimony:["Aside from the results, the best thing about working with Linear is the transparency. We’ve learned what does and doesn’t work and been able to apply those learnings in other areas.","Linear meets all of our performance expectations for an agency partnership. They’ve kept up with our communication as much as an internal team, helping us reach milestones more quickly.","We just keep learning more from working with Linear. They have well-defined systems and a highly tenured team. Our input is always valued, but they also know when to point us in the right direction."],
            head:["Khai Tran","Chris Miller","Joseph Ortega"],
            subTitle:["CEO/Co-Founder – Penji","Director B2B Marketing – Ordermark","B2B Lead Gen Manager – Nextbite"]
        }
    ],
    [
        "Apparel",
        {
            links:[
                        "https://lineardesign.com/case-studies/burkhart-chiropractic/",
                        "https://lineardesign.com/case-studies/stanlick-chiropractic/",
                        "https://lineardesign.com/case-studies/all-star-chiropractic/"
                 ],
            imgs:[healthcare1,healthcare2,healthcare3],
            type:["B2C","B2C","B2C"],
            title:["Burkhart Chiropractic","Stanlick Chiropractic","All Star Chiropractic"],
            logo:["https://lineardesign.com/wp-content/uploads/2021/02/burkhart-svg-logo-large.svg","https://lineardesign.com/wp-content/uploads/2020/12/stanlick-svg-logo-large.svg","https://lineardesign.com/wp-content/uploads/2021/02/allstar-svg-large.svg"],
            testimony:["Linear added 30% to our collections in the first three months and it’s only continued to grow.  I would recommend Linear to anyone looking to grow their business…","I have been with Linear for 2 years now, and the results have been remarkable. We’ve gone from 50 new patients a month to over 100 new patients a month…","We were always a growing office, but since working with Linear we’ve added 20-30 new patients per month and upwards of $15,000 in additional monthly revenue…"],
            head:["Dr. Tim Burkhart","Mitch Stanlick","Dr. Bob Coppola"],
            subTitle:["Partner – Burkhart Chiropractic","Founder – Stanlick Chiropractic","Owner – All Star Chiropractic"]
        }
    ],
    [
        "Fitness",
        {
            links:[
                "https://lineardesign.com/case-studies/penji/",
                "https://lineardesign.com/case-studies/ordermark/",
                "https://lineardesign.com/case-studies/nextbite/"
            ],
            imgs:[consumerService1,consumerService2,consumerService3],
            type:["B2C","B2C","B2C"],
            title:["Penji","Ordermark","Nextbite"],
            logo:["https://lineardesign.com/wp-content/uploads/2022/05/pnj_large_svg.svg","https://lineardesign.com/wp-content/uploads/2022/05/ordermark_large_svg.svg","https://lineardesign.com/wp-content/uploads/2022/05/nextbite_large_svg.svg"],
            testimony:["Aside from the results, the best thing about working with Linear is the transparency. We’ve learned what does and doesn’t work and been able to apply those learnings in other areas.","Linear meets all of our performance expectations for an agency partnership. They’ve kept up with our communication as much as an internal team, helping us reach milestones more quickly.","We just keep learning more from working with Linear. They have well-defined systems and a highly tenured team. Our input is always valued, but they also know when to point us in the right direction."],
            head:["Khai Tran","Chris Miller","Joseph Ortega"],
            subTitle:["CEO/Co-Founder – Penji","Director B2B Marketing – Ordermark","B2B Lead Gen Manager – Nextbite"]
        }
    ],
    [
        "Retail",
        {
            links:[
                        "https://lineardesign.com/case-studies/burkhart-chiropractic/",
                        "https://lineardesign.com/case-studies/stanlick-chiropractic/",
                        "https://lineardesign.com/case-studies/all-star-chiropractic/"
                 ],
            imgs:[healthcare1,healthcare2,healthcare3],
            type:["B2C","B2C","B2C"],
            title:["Burkhart Chiropractic","Stanlick Chiropractic","All Star Chiropractic"],
            logo:["https://lineardesign.com/wp-content/uploads/2021/02/burkhart-svg-logo-large.svg","https://lineardesign.com/wp-content/uploads/2020/12/stanlick-svg-logo-large.svg","https://lineardesign.com/wp-content/uploads/2021/02/allstar-svg-large.svg"],
            testimony:["Linear added 30% to our collections in the first three months and it’s only continued to grow.  I would recommend Linear to anyone looking to grow their business…","I have been with Linear for 2 years now, and the results have been remarkable. We’ve gone from 50 new patients a month to over 100 new patients a month…","We were always a growing office, but since working with Linear we’ve added 20-30 new patients per month and upwards of $15,000 in additional monthly revenue…"],
            head:["Dr. Tim Burkhart","Mitch Stanlick","Dr. Bob Coppola"],
            subTitle:["Partner – Burkhart Chiropractic","Founder – Stanlick Chiropractic","Owner – All Star Chiropractic"]
        }
    ],
    [
        "Technology",
        {
            links:[
                "https://lineardesign.com/case-studies/penji/",
                "https://lineardesign.com/case-studies/ordermark/",
                "https://lineardesign.com/case-studies/nextbite/"
            ],
            imgs:[consumerService1,consumerService2,consumerService3],
            type:["B2C","B2C","B2C"],
            title:["Penji","Ordermark","Nextbite"],
            logo:["https://lineardesign.com/wp-content/uploads/2022/05/pnj_large_svg.svg","https://lineardesign.com/wp-content/uploads/2022/05/ordermark_large_svg.svg","https://lineardesign.com/wp-content/uploads/2022/05/nextbite_large_svg.svg"],
            testimony:["Aside from the results, the best thing about working with Linear is the transparency. We’ve learned what does and doesn’t work and been able to apply those learnings in other areas.","Linear meets all of our performance expectations for an agency partnership. They’ve kept up with our communication as much as an internal team, helping us reach milestones more quickly.","We just keep learning more from working with Linear. They have well-defined systems and a highly tenured team. Our input is always valued, but they also know when to point us in the right direction."],
            head:["Khai Tran","Chris Miller","Joseph Ortega"],
            subTitle:["CEO/Co-Founder – Penji","Director B2B Marketing – Ordermark","B2B Lead Gen Manager – Nextbite"]
        }
    ],
    [
        "Home Improvement",
        {
            links:[
                        "https://lineardesign.com/case-studies/burkhart-chiropractic/",
                        "https://lineardesign.com/case-studies/stanlick-chiropractic/",
                        "https://lineardesign.com/case-studies/all-star-chiropractic/"
                 ],
            imgs:[healthcare1,healthcare2,healthcare3],
            type:["B2C","B2C","B2C"],
            title:["Burkhart Chiropractic","Stanlick Chiropractic","All Star Chiropractic"],
            logo:["https://lineardesign.com/wp-content/uploads/2021/02/burkhart-svg-logo-large.svg","https://lineardesign.com/wp-content/uploads/2020/12/stanlick-svg-logo-large.svg","https://lineardesign.com/wp-content/uploads/2021/02/allstar-svg-large.svg"],
            testimony:["Linear added 30% to our collections in the first three months and it’s only continued to grow.  I would recommend Linear to anyone looking to grow their business…","I have been with Linear for 2 years now, and the results have been remarkable. We’ve gone from 50 new patients a month to over 100 new patients a month…","We were always a growing office, but since working with Linear we’ve added 20-30 new patients per month and upwards of $15,000 in additional monthly revenue…"],
            head:["Dr. Tim Burkhart","Mitch Stanlick","Dr. Bob Coppola"],
            subTitle:["Partner – Burkhart Chiropractic","Founder – Stanlick Chiropractic","Owner – All Star Chiropractic"]
        }
    ]
]);

export default mappedIndustry;
