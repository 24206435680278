import { Button, TextField, Typography } from "@mui/material";
import { Popover } from "react-tiny-popover";
import "./pmaxcampaigncreation.css";
import { text_field_icon as textFieldIcon } from "../../../assets/icons/campaign-approval/campaign_approval";
import { objective_icon as objectiveIcon } from "../../../assets/icons/campaign-approval/campaign_approval";
import CampainLocationSelect from "../../AccountManager/CampaignApproval/CampainLocationSelect";
import CampainLanguageSelect from "../../AccountManager/CampaignApproval/CampainLanguageSelect";
import { useState } from "react";
import { location_icon as locationIcon } from "../../../assets/icons/campaign-approval/campaign_approval";
import MenuCheckList from "./components/MenuCheckList";
import { bidding_icon as biddingIcon } from "../../../assets/icons/campaign-approval/campaign_approval";
import MenuRadioList from "./components/MenuRadioList";
import { conversion_goals_icon as conversionGoalIcon } from "../../../assets/icons/campaign-approval/campaign_approval";
import { budget_icon as budgetIcon } from "../../../assets/icons/campaign-approval/campaign_approval";
import { language_icon as languageIcon } from "../../../assets/icons/campaign-approval/campaign_approval";
import Success from "./StatusIndicator/Success";
import Publishing from "./StatusIndicator/Publishing";
import Failed from "./StatusIndicator/Failed";
import PrimaryButton from "../../ButtonPrimary/Buttons";
const objectiveList = [
  "Leads",
  "Sales",
  "Website Traffic",
  "Product and brand consideration",
  "Brand Awareness and reach",
  "App promotion",
  "Local store visits and promotions",
  "Create a campaign without a goal's guidance",
];

const biddingList = ["Conversions", "Conversion value"];

const conversionGoalsList = [
  "Book Appointments",
  "Contacts",
  "Sign-ups",
  "Submit lead forms",
  "Other",
  "Page views",
  "Qualified leads",
  "Store visits",
];
const PMaxCampaignType = (props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [focusBudget, setFocusBudget] = useState(false);
  const [isPopoverOpenLanguage, setIsPopoverOpenLanguage] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "97.5%",
          margin: "auto",
          marginTop: "2rem",
          opacity: !props.isStrategyCreated ? "0.3" : "",
          //   paddingBottom: props.title === "Services Campaign" ? "1.75rem" : "",
        }}
      >
        <div
          className="pmax-indicator"
          style={{
            background: props.indicator ? "rgba(26, 191, 161, 0.1)" : "#EAF2FF",
          }}
        ></div>
        <div className="pmax-campaign-sub-container">
          <div className="pmax-campaign-creation-field-header">
            <Typography
              className="pmax-campaign-creation-field-header__title"
              style={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              {props.title}
              {props.isPublished ||
              props.isPublishing ||
              props.publishedErrors?.length ? (
                <>
                  {props.isPublished ? (
                    <Success campaignId={props.campaignId} />
                  ) : props.isPublishing ? (
                    <Publishing />
                  ) : (
                    props.publishedErrors?.length && (
                      <Failed errors={props.publishedErrors} />
                    )
                  )}
                </>
              ) : (
                ""
              )}
            </Typography>

            <Button
              variant="outlined"
              className={"pmaxdetailButton"}
              onClick={() => props.viewAssetsGroup(props.title)}
              sx={{
                "&.Mui-disabled": {
                  opacity: "0.3",
                },
              }}
              disabled={props.disableCampaignASLevel}
            >
              Asset Group Level
            </Button>
          </div>

          <div style={{ display: "flex" }}>
            <div className="pmax-campaign-form-container">
              <div className="pmax-campaign-form-element2">
                <div className="pmax-icon-container">
                  <img src={textFieldIcon} alt="" />
                </div>
                <div className="pmax-form-input-element2">
                  <Typography className="pmax-form-input-label2">
                    Name
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Enter Text"
                    value={props.name}
                    disabled={props.isPublished || props.isPublishing}
                    inputProps={{
                      style: {
                        color: "#0869FB",

                        height: 8,
                        background: "#FFFFFF",
                        border: "1px solid #CCECFE",
                        borderRadius: 4,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 12,
                      },
                    }}
                    onChange={(e) => {
                      props.setHasChange(true);
                      props.setName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="pmax-campaign-form-element">
                <div className="pmax-icon-container">
                  <img src={objectiveIcon} alt="" />
                </div>
                <div
                  className="pmax-form-input-element"
                  style={{ width: "173px" }}
                >
                  <Typography className="pmax-form-input-label">
                    Objective
                  </Typography>
                  <MenuRadioList
                    list={objectiveList}
                    selectedOption={props.objective || objectiveList[0]}
                    setSelectedOption={props.setObjective}
                    width={130}
                    setHasChange={props.setHasChange}
                    disabled={props.isPublished || props.isPublishing}
                  />
                </div>
              </div>
              <div
                className="pmax-campaign-form-element"
                style={{ width: "175px" }}
              >
                <div className="pmax-icon-container">
                  <img src={locationIcon} alt="" />
                </div>
                <div className="pmax-form-input-element">
                  <Typography className="pmax-form-input-label">
                    Location
                  </Typography>

                  <Popover
                    isOpen={isPopoverOpen}
                    align="start"
                    positions={["bottom", "left"]}
                    reposition={true}
                    // containerStyle={{ left: "-300px", top: "27px" }}
                    onClickOutside={() => setIsPopoverOpen(false)}
                    content={
                      <div
                        className="question"
                        style={{
                          background: "#FFF",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          padding: "16px 8px",
                          gap: "16px",
                          width: "372px",
                          minHeight: "100px",
                          border: "1px solid #F0F0F0",
                          boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
                          borderRadius: "8px",
                          zIndex: 99,
                        }}
                      >
                        <CampainLocationSelect
                          campType={props.title}
                          locations={props.locations}
                          setLocations={props.setLocations}
                          disabled={props.isPublished || props.isPublishing}
                          setHasChange={props.setHasChange}
                        />
                      </div>
                    }
                  >
                    <div
                      onClick={() => {
                        setIsPopoverOpen(!isPopoverOpen);
                      }}
                      style={{
                        width: 130,
                        height: 27,
                        background: "#FFFFFF",
                        border: "1px solid #CCECFE",
                        borderRadius: 4,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 12,
                        outline: "none",
                        color: props.isCampaignComplete
                          ? "rgba(0, 0, 0, 0.38)"
                          : "#0869FB",
                        cursor: props.isCampaignComplete ? "revert" : "pointer",
                        padding: "5px 10px",
                      }}
                    >
                      {props.locations.length
                        ? `${props.locations?.length} Selected`
                        : "Select"}
                    </div>
                  </Popover>
                </div>
              </div>

              <div className="pmax-campaign-form-element2">
                <div className="pmax-icon-container">
                  <img src={conversionGoalIcon} alt="" />
                </div>
                <div className="pmax-form-input-element2">
                  <Typography className="pmax-form-input-label2">
                    Conversion Goals
                  </Typography>
                  <MenuCheckList
                    list={conversionGoalsList}
                    selectedOption={props.conversionGoals}
                    setSelectedOption={props.setConversionGoals}
                    setHasChange={props.setHasChange}
                    disabled={props.isPublished || props.isPublishing}
                  />
                </div>
              </div>
              <div className="pmax-campaign-form-element">
                <div className="pmax-icon-container">
                  <img src={budgetIcon} alt="" />
                </div>
                <div className="pmax-form-input-element">
                  <Typography className="pmax-form-input-label">
                    Daily Budget
                  </Typography>

                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        left: 1.5,
                        top: 1,
                        zIndex: 1,
                        width: 24,
                        height: 24,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: 8,
                        borderTopLeftRadius: 4,
                        borderBottomLeftRadius: 4,
                        background: focusBudget ? "#E6F6FF" : "#F7F7F7",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: 10,
                          color: "#0869FB",
                        }}
                      >
                        $
                      </Typography>
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      size="small"
                      placeholder="Enter Budget"
                      type="number"
                      value={props.budget}
                      disabled={props.isPublished || props.isPublishing}
                      onChange={(e) => {
                        props.setHasChange(true);
                        props.setBudget(parseInt(e.target.value));
                      }}
                      onFocus={() => setFocusBudget(!focusBudget)}
                      inputProps={{
                        style: {
                          color: "#0869FB",
                          boxSizing: "border-box",
                          width: 128,
                          height: 26.75,
                          background: "#FFFFFF",
                          border: "1px solid #CCECFE",
                          borderRadius: 4,
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: 12,
                          paddingLeft: 30,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="pmax-campaign-form-element">
                <div className="pmax-icon-container">
                  <img src={languageIcon} alt="" />
                </div>
                <div className="pmax-form-input-element">
                  <Typography className="pmax-form-input-label">
                    Language
                  </Typography>

                  <Popover
                    isOpen={isPopoverOpenLanguage}
                    align="start"
                    positions={["right", "bottom"]}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    reposition={true}
                    // containerStyle={{ left: "-180px", top: "0" }}
                    onClickOutside={() => setIsPopoverOpenLanguage(false)}
                    content={
                      <div
                        className="question"
                        style={{
                          background: "#FFF",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          padding: "16px 8px",
                          gap: "16px",
                          width: "372px",
                          minHeight: "100px",
                          border: "1px solid #F0F0F0",
                          boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
                          borderRadius: "8px",
                          zIndex: 99,
                        }}
                      >
                        <CampainLanguageSelect
                          campType={props.title}
                          languages={props.languages}
                          setLanguages={props.setLanguages}
                          setHasChange={props.setHasChange}
                          disabled={props.isPublished || props.isPublishing}
                        />
                      </div>
                    }
                  >
                    <div
                      onClick={() => {
                        setIsPopoverOpenLanguage(!isPopoverOpenLanguage);
                      }}
                      style={{
                        width: 130,
                        height: 27,
                        background: "#FFFFFF",
                        border: "1px solid #CCECFE",
                        borderRadius: 4,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 12,
                        outline: "none",
                        color: props.isCampaignComplete
                          ? "rgba(0, 0, 0, 0.38)"
                          : "#0869FB",
                        padding: "5px 10px",
                        cursor: props.isCampaignComplete ? "revert" : "pointer",
                      }}
                    >
                      {props.languages.length
                        ? `${props.languages.length} Selected`
                        : "Select"}
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
            <div className="pmax-dual-form-element">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="pmax-icon-container">
                  <img src={biddingIcon} alt="" />
                </div>
                <div className="pmax-form-input-element">
                  <Typography className="pmax-form-input-label">
                    Bidding
                  </Typography>
                  <MenuRadioList
                    list={biddingList}
                    selectedOption={props.bidding || biddingList[0]}
                    setSelectedOption={props.setBidding}
                    width={290}
                    setHasChange={props.setHasChange}
                    disabled={props.isPublished || props.isPublishing}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="pmax-icon-container">
                  <img src={biddingIcon} alt="" />
                </div>
                <div className="pmax-form-input-element">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Typography
                        className="pmax-form-input-label"
                        style={{
                          marginBottom: "5px",
                          opacity: props.bidding === "Conversions" ? "0.3" : "",
                        }}
                      >
                        Target ROAS (optional)
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        type="number"
                        placeholder="Enter Text"
                        value={props.targetRoas}
                        onChange={(e) => {
                          props.setHasChange(true);
                          props.setTargetRoas(e.target.value);
                        }}
                        disabled={
                          props.bidding === "Conversions" ||
                          props.isPublished ||
                          props.isPublishing
                        }
                        inputProps={{
                          style: {
                            color: "#0869FB",
                            width: 120,
                            height: 8,
                            background: "#FFFFFF",
                            border: "1px solid #CCECFE",
                            borderRadius: 4,
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: 12,
                          },
                        }}
                      />
                    </div>
                    <div style={{ marginLeft: "10px" }}>
                      <Typography
                        className="pmax-form-input-label3"
                        style={{
                          marginBottom: "5px",
                          opacity:
                            props.bidding === "Conversion value" ? "0.3" : "",
                        }}
                      >
                        Target CPA (optional)
                      </Typography>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="number"
                        size="small"
                        placeholder="Enter Text"
                        value={props.targetCpa}
                        onChange={(e) => {
                          props.setHasChange(true);
                          props.setTargetCpa(e.target.value);
                        }}
                        disabled={
                          props.bidding === "Conversion value" ||
                          props.isPublished ||
                          props.isPublishing
                        }
                        inputProps={{
                          style: {
                            color: "#0869FB",
                            width: 120,
                            height: 8,
                            background: "#FFFFFF",
                            border: "1px solid #CCECFE",
                            borderRadius: 4,
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 400,
                            fontSize: 12,
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <PrimaryButton
              disabled={props.isPublished || props.isPublishing}
              style={{ background: "#ffffff", color: "#0869FB" }}
              title="Confirm and Publish"
              onClick={() => props.confirmAndPublish(props.title)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PMaxCampaignType;
