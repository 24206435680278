import React from 'react';
import { Typography } from '@mui/material';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import {publish_campaign_loader as loaderGif} from '../../../assets/icons/campaign-approval/campaign_approval';
import './publishcampaign.css';
const PublishingCampaignLoader = () => {
  const headingStyle = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 24,
    color: '#000000',
    textAlign: 'center',
    lineHeight: '27px',
  };
  const paraStyle = {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: '#6F6C90',
    textAlign: 'center',
    lineHeight: '25.5px',
  };
  return (
    <div className='publish-campaign-container'>
      <div className='loader-image-container'>
        <img src={loaderGif} alt='' width={72} height={72} />
      </div>
      <div className='publish-campaign-description-container'>
        <Typography style={headingStyle}>Publishing Campaigns</Typography>
        <Typography style={paraStyle}>
          Please sit back and browse our website's homepage, while we work on
          generating your campaigns. We will notify you once they are published.
        </Typography>
        <PrimaryButton title='Home' />
      </div>
    </div>
  );
};

export default PublishingCampaignLoader;
