import {ready as readyGif} from "../../assets/icons/proposal/proposal";
import {timeprocess as TimeProcess} from '../../assets/icons/icon'
import { Button } from "@mui/material";

const AccessSharingWaitPage = ({description}) => {
  return (
    <div
      className="layout_submission"
      style={{
        width: "100%",
        margin: "auto",
      }}
    >
      <div className="center">
        <img
          src={TimeProcess}
          className="img-submit"
          style={{
            width: 180,
            height: 180,
          }}
        />
        <div className="submit-title">
          {description}
        </div>
        {/* <div className="submit-desc">
           This usually takes 30 mins to 1 hour.
          <br />
          
        </div> */}
        <Button
          variant="contained"
          style={{
            marginTop: "2rem",
            width: 110,
          }}
          onClick={() => {
            window.location.href = "/brands"
          }}
        >
          Home
        </Button>
      </div>
    </div>
  );
};
export default AccessSharingWaitPage;
