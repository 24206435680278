import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { concat } from 'lodash';
import { api } from "../api";
import { BACKEND_STATUS } from "../utils/accountManagerUtil";
import { queryClient } from "../utils/queryClient";

const getPmaxGoogleSegmentQueryKey = formId => ["pmax-segment/:formId", formId];

export const getPmaxCampaignSegmentByIdQueryKey = (formId, segmentId) => ["pmax-segment/:formId/segment/:segmentId", formId, segmentId];

export const useGetListPmaxCampaignSegments = (formId, generationType = null, options = {}) => {
  return useQuery(
    getPmaxGoogleSegmentQueryKey(formId),
    async () => {
      const searchParams = new URLSearchParams();
      if (generationType) searchParams.set("generationType", generationType);

      const response = await api.get(`/pmax-ads-campaigns/${formId}/segment?${searchParams.toString()}`);
      return response.data;
    },
    options
  );
}

export const useGetPmaxCampaignSegmentById = (formId, segmentId, options = {}) => {
  return useQuery(
    getPmaxCampaignSegmentByIdQueryKey(formId, segmentId),
    async () => {
      const response = await api.get(`/pmax-ads-campaigns/${formId}/segment/${segmentId}`);
      return response.data;
    },
    options,
  );
}

export const usePostPmaxCampaignSegment = (formId, options = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (payload = {}) => {
      const body = {
        ...payload,
        status: BACKEND_STATUS.ONGOING
      }
      const response = await api.post(`/pmax-ads-campaigns/${formId}/segment`, body);
      return response.data;
    },
    {
      ...options,
      onSuccess: (data, variables, ctx) => {
        queryClient.setQueryData(
          getPmaxGoogleSegmentQueryKey(formId),
          (prev) => ({
            ...prev,
            segments: concat(prev?.segments, data),
            totalResultsCount: (prev?.segments?.length || 0) + 1,
          })
        );

        options.onSuccess?.(data, variables, ctx);
      },
    }
  );
}

export const usePutPmaxCampaignSegment = (formId, options = {}) => {
  return useMutation(
    async ({ segmentId, ...fields }) => {
      const response = await api.put(`/pmax-ads-campaigns/${formId}/segment/${segmentId}`, fields);
      return response.data;
    },
    options,
  );
}

export const useDeletePmaxCampaignSegment = (formId, options = {}) => {
  return useMutation(
    async ({ segmentId }) => {
      const response = await api.delete(`/pmax-ads-campaigns/${formId}/segment/${segmentId}`);
      return response.data;
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        const { segmentId } = variables;
        queryClient.setQueryData(
          getPmaxGoogleSegmentQueryKey(formId),
          (prev) => ({
            ...prev,
            segments: prev?.segments?.filter(segment => segment.id !== segmentId),
            totalResultsCount: (prev?.segments?.length || 0) + 1,
          })
        );
        options.onSuccess?.(data, variables, ctx);
      },
    }
  );
}

export const usePostPublishPmaxCampaignSegment = (formId, options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ segmentId }) => {
      try{
        const response = await api.post(`/pmax-ads-campaigns/${formId}/segment/${segmentId}:generate`);
        queryClient.invalidateQueries({
          queryKey: getPmaxCampaignSegmentByIdQueryKey(formId, segmentId),
        });
        return response.data;
      } catch(err){
        queryClient.invalidateQueries({
          queryKey: getPmaxCampaignSegmentByIdQueryKey(formId, segmentId),
        });
        throw err;
      }
      
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        const { segmentId } = variables;
        if (data.success) {
          queryClient.setQueryData(
            getPmaxGoogleSegmentQueryKey(formId),
            (prev) => ({
              ...prev,
              segments: prev?.segments?.map(segment => segment.id !== segmentId
                ? segment
                : { ...segment, plublishmentStatus: "PUBLISHING" }),
            })
          );
        }
        options.onSuccess?.(data, variables, ctx);
      }
    },
  );
}