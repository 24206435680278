import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import MetricList from "./MetricList";
import { Metric } from "../../models/chart";
import { GetChannel, SupportedChannels } from "../../models/channel";
import SwitchChart from "./SwitchChart";
import EditChartType3 from "./EditChartType3";
import EditChartType2 from "./EditChartType2";
import EditChartType1 from "./EditChartType1";
import EditChartType4 from "./EditChartType4";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { ChartFilterSupport } from "../Charts/ChartUtils";

export function BlendsAllAccountsSelected(channel, selectedAccounts) {
    const state = useSelector(state => channel.getState(state));
    const accounts = channel.transformer.getAccountId(selectedAccounts, state);
    for (let account in accounts) {
        if (!(account in selectedAccounts)) {
            return false;
        }
    }
    return true;
}

function EditChart({ handleAllChartsForceReload, editDrawerState, newChart = false, setChartType, blends = [], authenticatedAccounts = [], selectedAccounts = {} }) {
    const isChannelAuthenticated = channel => !!authenticatedAccounts.find(type => type === channel?.type);
    const isChannelAccountSelected = channel => {
        if (channel.isBlend) {
            // return BlendsAllAccountsSelected(channel, selectedAccounts)
        } else {
            return channel.type in selectedAccounts
        }
    };
    const allChannelsList = SupportedChannels
        // .filter(channel => (isChannelAuthenticated(channel)))
        // .concat(blends.map(GetChannel))
        .map(channel => ({
            id: channel.type, name: channel.title, icon: channel.icon, isBlend: channel.isBlend,
            disabled: !isChannelAccountSelected(channel)
        }));

    const { chart, handleChartChange } = editDrawerState;
    const [leftMetrics, setLeftMetrics] = React.useState(chart.leftMetrics?.concat([null]));
    const [rightMetrics, setRightMetrics] = React.useState(chart.rightMetrics?.concat([null]));
    const [chartMetrics, setChartMetrics] = React.useState(([null]))
    const [chartDimensions, setChartDimensions] = React.useState(chart.dimensions?.concat([null]))
    const [table, setTable] = React.useState(chart.table);
    const blendChannelData = chart.channelType?.id && blends.find(blend => blend.id === chart.channelType.id)
    const [channel, setChannel] = React.useState(GetChannel(blendChannelData ?? chart.channelType));
    const [currentMetricState, setCurrentMetricState] = React.useState(
        chart.type === "KPI" ?
            {
                isOpen: true,
                metric: leftMetrics[0] ?? Metric.new(chart),
                axis: "left",
                index: 0,
            }
            :
            {
                isOpen: false,
                metric: Metric.new(chart),
                axis: "left",
                index: -1,
            }
    );
    const [currentChartType, setCurrentChartType] = React.useState(chart.type)
    React.useEffect(() => {
        handleChartChange("type", currentChartType);
    }, [currentChartType]);

    React.useEffect(() => {
        if (channel.type !== chart.channelType && channel.type !== chart.channelType.id) {
            handleChartChange("channel", channel);
            setTable(null);
            setLeftMetrics([null]);
            setRightMetrics(chart.rightMetrics ? [null] : null);
            setChartDimensions([null]);
            setChartType(chart.type)
        }
    }, [channel])

    return (
        <div style={{ padding: "24px" }}>
            {(!currentMetricState.isOpen || chart.type === "KPI") ?
                <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                        sx={{ maxHeight: "30px" }}
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <h4 className="inter bold">
                            Edit Source
                        </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MetricList
                            channel={channel}
                            metric={{ id: channel.type, name: channel.title }}
                            metrics={allChannelsList}
                            isEditable={true}
                            onClick={channelObject => setChannel(GetChannel(channelObject.isBlend ? channelObject : channelObject.id))}
                            defaultTitle="Data platform"
                        />
                    </AccordionDetails>
                </Accordion>
                : null
            }
            {(!currentMetricState.isOpen || (chart.type === "KPI" && newChart)) ?
                <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                        sx={{ maxHeight: "30px" }}
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <h4 className="inter bold" >
                            Edit Chart Type
                        </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SwitchChart key={channel + newChart} chartMetrics={chartMetrics} chartDimensions={chartDimensions} chartType={currentChartType} setChartType={(newChart && setChartType) ? setChartType : setCurrentChartType} newChart={newChart} />
                    </AccordionDetails>
                </Accordion> : null}

            {currentChartType === "KPI"
                ? <EditChartType4 key={channel + currentChartType} handleAllChartsForceReload={handleAllChartsForceReload} editDrawerState={editDrawerState} currentMetricState={currentMetricState} setCurrentMetricState={setCurrentMetricState} channel={channel} />
                : ChartFilterSupport(currentChartType)
                    ? <EditChartType3 key={channel + currentChartType} handleAllChartsForceReload={handleAllChartsForceReload} editDrawerState={editDrawerState} currentChartType={currentChartType} setSwitchChartMetrics={setChartMetrics} setSwitchChartDimensions={setChartDimensions} channel={channel} />
                    : currentChartType === "PIE" || currentChartType === "DONUT" || currentChartType === "FUNNEL"
                        ? <EditChartType2 key={channel + currentChartType} handleAllChartsForceReload={handleAllChartsForceReload} editDrawerState={editDrawerState} currentMetricState={currentMetricState} setCurrentMetricState={setCurrentMetricState} channel={channel} />
                        : <EditChartType1 key={channel + currentChartType} handleAllChartsForceReload={handleAllChartsForceReload} editDrawerState={editDrawerState} currentMetricState={currentMetricState} setCurrentMetricState={setCurrentMetricState} channel={channel} />
            }

        </div>
    );
}

const mapStateToProps = state => ({
    // blends: state.blends.blends,
    selectedAccounts: state.dashboard.selectedAccounts,
    // authenticatedAccounts: state.channel.authenticatedAccounts
});

export default connect(mapStateToProps)(EditChart);
