import React from 'react';
import errorIcon from '../PMaxStrategyCreation/pmaxIcons/error-icon.svg';
import { Typography, Tooltip } from '@mui/material';
const ErrorTooltip = ({ error }) => (
  <Tooltip
    PopperProps={{
      sx: {
        '& .MuiTooltip-arrow': {
          color: 'white',
        },
        '& .MuiTooltip-tooltip': {
          backgroundColor: 'white',
          color: 'black',
          marginBottom: '20px',
          filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
          padding: '10px 12px 10px 13px',
          width: 181,
          borderRadius: 2,
        },
      },
    }}
    title={
      <>
        { Array.isArray(error) ? 
         error.map((value, index) => 
           <Typography
             key={index}
             style={{
               fontFamily: 'Inter',
               fontStyle: 'normal',
               fontWeight: 400,
               fontSize: 12,
               lineHeight: '20px',
               color: '#333333',
             }}
           >
             {index + 1}. {value}
           </Typography>
         ) :
           <Typography
             style={{
               fontFamily: 'Inter',
               fontStyle: 'normal',
               fontWeight: 400,
               fontSize: 12,
               lineHeight: '20px',
               color: '#333333',
             }}
           >
            {error}
           </Typography>
        }
      </>
    }
    arrow
    placement='right'
  >
    <img src={errorIcon} alt='campaign-budget' width={16} height={16} />
  </Tooltip>
);

export default ErrorTooltip;
