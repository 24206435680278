import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Description from "./Description";
import Headline from "./Headline";
// import readIcon from '../../../assets/icons/creative/read-icon.svg';
// import writeIcon from '../../../assets/icons/creative/write-icon.svg';
// import trashIcon from '../../../assets/icons/creative/trash-icon.svg';
// import googleIcon from '../../../assets/icons/creative/google-icon.svg';
// import selectedReadIcon from '../../../assets/icons/creative/selected-read-icon.svg';
// import selectedWriteIcon from '../../../assets/icons/creative/selected-write-icon.svg';
import DeletionConfirmationModal from "../DeletionConfirmationModal";
// import approvalIcon from '../../../assets/icons/creative/approved-icon.svg';
// import disapprovedIcon from '../../../assets/icons/creative/disapproved-icon.svg';
import { google_icon as googleIcon } from '../../../assets/icons/campaignpreview/campaignpreview';
import {
  writeIcon,
  selectedWriteIcon,
  readIcon,
  selectedReadIcon,
  trashIcon,
  approvalIcon,
  disapprovedIcon,
} from "../../../assets/icons/creative/creative";
import "./headline.css";
import { useDispatch, useSelector } from "react-redux";
import {} from "../../../api/creativeApproval/slice";
import {
  CREATIVE_OBJECT_STATUS_ENUM,
  PLATFORM_NAME,
} from "../../../api/creativeApproval/enums";
import { selectSingleAdCopy } from "../../../utils/creativeApprovalUtil";
import {
  updateAdCopyStatus,
  updateAdCopyContent,
} from "../../../api/creativeApproval/slice";
const ExpandIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0387 14.8495L17.8779 8.97756C18.0759 8.7779 18.2012 8.536 18.2393 8.27993C18.2774 8.02385 18.2267 7.76412 18.0931 7.53088C17.9594 7.29764 17.7484 7.10047 17.4844 6.96225C17.2204 6.82402 16.9144 6.75043 16.6017 6.75H4.92331C4.61069 6.75043 4.30462 6.82402 4.04065 6.96225C3.77668 7.10047 3.56563 7.29764 3.43201 7.53088C3.29838 7.76412 3.24766 8.02385 3.28574 8.27993C3.32382 8.536 3.44913 8.7779 3.64713 8.97756L9.48635 14.8495C9.64138 15.005 9.83622 15.1303 10.0569 15.2163C10.2775 15.3022 10.5185 15.3468 10.7625 15.3468C11.0065 15.3468 11.2475 15.3022 11.4682 15.2163C11.6888 15.1303 11.8837 15.005 12.0387 14.8495Z"
        fill="black"
      />
    </svg>
  );
};

const GoogleAdCopy = (props) => {
  const [theme, setTheme] = useState(null);
  const [campaignName, setCampaignName] = useState(null);
  const [headlineList, setHeadlineList] = useState([]);
  const [descriptionList, setDescriptionList] = useState([]);
  const [expand, setExpand] = useState(false);
  const [read, setRead] = useState(true);
  const [deleteCampaign, setDeleteCampaign] = useState(false);
  const [id, setId] = useState(null);
  const [showError, setShowError] = useState(false);

  const textFieldEmpty = Boolean(
    !theme ||
      !campaignName ||
      !headlineList.length ||
      !descriptionList.length ||
      !headlineList[0] ||
      !descriptionList[0]
  );

  useEffect(() => {
    setTheme(props.theme);
    setCampaignName(props.campaignName);
    setHeadlineList(props.headlineList);
    setDescriptionList(props.descriptionList);
    setExpand(props.expand);
    setRead(props.read);
    setId(props.id);
  }, [JSON.stringify(props)]);

  const saveHandler = () => {
    if (textFieldEmpty) {
      setShowError(true);
      return;
    }
    props.onSaveHandler({
      id: id,
      theme: theme,
      campaignName: campaignName,
      headlines: headlineList,
      descriptions: descriptionList,
    });
    setRead(true);
    setExpand(false);
  };

  const approveHandler = () => {
    if (textFieldEmpty) {
      setShowError(true);
      return;
    }
    props.onApproveHandler({
      id,
      theme: theme,
      campaignName: campaignName,
      headlines: headlineList,
      descriptions: descriptionList,
    });
    setRead(true);
  };

  const disapproveHandler = () => {
    if (textFieldEmpty) {
      setShowError(true);
      return;
    }
    props.onDisapproveHandler({
      id,
      theme: theme,
      campaignName: campaignName,
      headlines: headlineList,
      descriptions: descriptionList,
    });
    setRead(true);
  };

  const removeHandler = () => {
    // if (textFieldEmpty) return;
    props.onRemoveHandler({
      id,
      theme: theme,
      campaignName: campaignName,
      headlines: headlineList,
      descriptions: descriptionList,
    });
    setDeleteCampaign(false);
  };

  const toggleAccordian = () => {
    setExpand((prev) => !prev);
  };

  return (
    <div>
      <Accordion
        expanded={expand}
        style={{
          marginBottom: 16,
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          style={{
            background:
              props.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED && !expand
                ? "rgba(26, 191, 161, 0.05)"
                : props.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED &&
                  !expand
                ? "rgba(232, 75, 71, 0.05)"
                : "#F7FAFF",
            height: "64px",
            cursor: "pointer",
          }}
          expandIcon={<ExpandIcon />}
          IconButtonProps={{
            onClick: toggleAccordian,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "0.5rem",
                gap: "0.5rem",
              }}
            >
              <img src={googleIcon} width={35} height={35} alt="" />
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontWeight: 500,
                  fontSize: 18,
                  // lineHeight: '21.78px',
                }}
              >
                Campaign :{" "}
                {read || props.isClient ? (
                  campaignName
                ) : (
                  <TextField
                    variant="standard"
                    value={campaignName}
                    inputProps={{
                      style: {
                        fontFamily: "Inter",
                        fontWeight: 500,
                        fontSize: 18,
                        fontStyle: "normal",
                        lineHeight: "31px",
                        width: 200,
                        paddingBottom: 2,
                        border: 0,
                      },
                    }}
                    onChange={(e) => {
                      setShowError(false);
                      setCampaignName(e.target.value);
                    }}
                  />
                )}
                {!expand && theme && (
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 13,
                      color: "gray",
                    }}
                  >
                    Theme: {theme}
                  </Typography>
                )}
              </Typography>
            </div>
            {props.status === CREATIVE_OBJECT_STATUS_ENUM.APPROVED &&
              !expand && (
                <div
                  style={{
                    color: "#10AB99",
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    paddingRight: 16,
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "15px",
                  }}
                >
                  <img src={approvalIcon} alt="approved" />
                  <span>Approved</span>
                </div>
              )}
            {props.status === CREATIVE_OBJECT_STATUS_ENUM.DISAPPROVED &&
              !expand && (
                <div
                  style={{
                    color: "#E83155",
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    paddingRight: 16,
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "15px",
                  }}
                >
                  <img src={disapprovedIcon} alt="approved" />
                  <span>Disapproved</span>
                </div>
              )}
            {expand &&
              (props.isClient
                ? props.isClient &&
                  props.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING
                : true) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 16,
                    paddingRight: 16,
                  }}
                >
                  <div
                    className="read-write-icon"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      className="read-icon"
                      style={{
                        width: 40,
                        height: 40,
                        border: read
                          ? "1px solid #0869FB"
                          : "1px solid #CCCCCC",
                        borderRadius: "8px 0px 0px 8px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        background: read ? "rgba(8, 105, 251, 0.07)" : "",
                      }}
                      onClick={() => setRead(true)}
                    >
                      <img src={read ? selectedReadIcon : readIcon} alt="" />
                    </div>
                    <div
                      className="write-icon"
                      style={{
                        width: 40,
                        height: 40,
                        border: !read
                          ? "1px solid #0869FB"
                          : "1px solid #CCCCCC",
                        borderRadius: "0px 8px 8px 0px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        background: !read ? "rgba(8, 105, 251, 0.07)" : "",
                      }}
                      onClick={() => setRead(false)}
                    >
                      <img src={!read ? selectedWriteIcon : writeIcon} alt="" />
                    </div>
                  </div>
                  {!props.isClient && (
                    <div
                      className="trash-icon"
                      style={{
                        width: 40,
                        height: 40,
                        border: "1px solid #CCCCCC",
                        borderRadius: "8px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => setDeleteCampaign(true)}
                    >
                      <img src={trashIcon} alt="" />
                    </div>
                  )}
                </div>
              )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                marginTop: 18,
                gap: 16,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                Theme
              </Typography>
              <TextField
                variant="outlined"
                placeholder="Theme Goes Here"
                value={theme}
                disabled={props.isClient || read}
                style={{
                  width: "100%",
                  height: 42,
                }}
                inputProps={{
                  style: {
                    color: "#333333",
                    height: 0,
                    background: "#FFFFFF",
                    borderRadius: 12,
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: 14,
                    outline: "none",
                  },
                }}
                onChange={(e) => {
                  setShowError(false);
                  setTheme(e.target.value);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: 20,
              }}
            >
              <Headline
                isRead={read}
                setShowError={setShowError}
                setIsRead={setRead}
                headlineList={headlineList}
                isClient={props.isClient}
                status={props.status}
                setHeadlineList={setHeadlineList}
                platform={PLATFORM_NAME.GOOGLE}
              />
              <Description
                isRead={read}
                setShowError={setShowError}
                setIsRead={setRead}
                descriptionList={descriptionList}
                isClient={props.isClient}
                status={props.status}
                setDescriptionList={setDescriptionList}
                platform={PLATFORM_NAME.GOOGLE}
              />
            </div>
          </div>
        </AccordionDetails>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            background: "#F7FAFF",
            height: "67px",
            paddingRight: 12,
            gap: 12,
          }}
        >
          {showError && (
            <p style={{ color: "red" }}>Please fill all the fields</p>
          )}
          {props.isClient &&
            props.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING && (
              <Button
                // disabled={textFieldEmpty}
                style={{
                  width: "109px",
                  height: "40px",
                  background: "rgba(8, 105, 251, 0.12)",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#0869FB",
                  textTransform: "capitalize",
                }}
                onClick={saveHandler}
              >
                Save
              </Button>
            )}
          {!props.isClient && (
            <Button
              // disabled={textFieldEmpty}
              style={{
                width: "109px",
                height: "40px",
                background: "rgba(8, 105, 251, 0.12)",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#0869FB",
                textTransform: "capitalize",
              }}
              onClick={saveHandler}
            >
              Save
            </Button>
          )}
          {props.isClient &&
            props.status === CREATIVE_OBJECT_STATUS_ENUM.PENDING && (
              <>
                <Button
                  // disabled={textFieldEmpty}
                  style={{
                    width: "109px",
                    height: "40px",
                    background: "rgba(232, 49, 85, 0.1)",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#E84B47",
                    textTransform: "capitalize",
                  }}
                  onClick={disapproveHandler}
                >
                  Disapprove
                </Button>
                <Button
                  // disabled={textFieldEmpty}
                  style={{
                    width: "109px",
                    height: "40px",
                    background: "#0869FB",
                    borderRadius: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    textTransform: "capitalize",
                  }}
                  onClick={approveHandler}
                >
                  Approve
                </Button>
              </>
            )}
        </div>
      </Accordion>
      {deleteCampaign && (
        <DeletionConfirmationModal
          cancelHandler={setDeleteCampaign}
          adCopy={true}
          deleteHandler={removeHandler}
        />
      )}
    </div>
  );
};

export default GoogleAdCopy;
