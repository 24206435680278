import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  googleAdCustomers: [],
  selectedCustomerId: null,
  selectedCustomerName: null,
  selectedLoginCustomerId: null,
  selectedLoginCustomerName: null,
  auditReport: {},
  insights: [],
  audience: [],
  affinityCategories:[],
  inmarketCategories:[],
  lifeEvents:[],
  detailedDemographics:[],
  users: [],
  gotInsights: false,
  haveInsights: false,
  forAnotherAccount: false,
  gotCustomers: false,
};

export const googleCustomersSlice = createSlice({
  name: "googleCustomer",
  initialState,
  reducers: {
    getGoogleCustomersReducers() { },
    createGoogleAdsAuditReducer() { },
    updateReviewPointsReducer() { },
    postAuditForGoogle() {},
    getInsightsForFormIdReducer() { },
    publishCampaignReducer() { },
    publishSpecificCampaignTypeReducer() {},
    fetchAudience() {},
    fetchCriteria() {},
    fetchUserList() {},
    updateData: (state, action) => {
      state.googleAdCustomers = action.payload;
    },
    updateSelectedCustomerId: (state, action) => {
      state.selectedCustomerId = action.payload;
    },
    updateSelectedLoginCustomerId: (state, action) => {
      state.selectedLoginCustomerId = action.payload;
    },
    updateSelectedCustomerName: (state, action) => {
      state.selectedCustomerName = action.payload;
    },
    updateSelectedLoginCustomerName: (state, action) => {
      state.selectedLoginCustomerName = action.payload;
    },
    updateAuditReport: (state, action) => {
      state.auditReport = action.payload;
    },
    updateInsights: (state, action) => {
      state.insights = action.payload;
    },
    updateGotInsights: (state, action) => {
      state.gotInsights = action.payload;
    },
    updateHaveInsights: (state, action) => {
      state.haveInsights = action.payload;
    },
    updateAppData: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
    updateForAnotherAccount: (state, action) => {
      state.forAnotherAccount = action.payload;
    },
    updateAudience: (state, action) => {
      state.audience = action.payload;
    },
    updateGotCustomers: (state, action) => {
      state.gotCustomers = action.payload;
    },
    resetGoogleCustomerState: (state, action) => {
      state = initialState;
    },
    updateGoogleData: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key];
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateData,
  getGoogleCustomersReducers,
  updateSelectedCustomerId,
  updateSelectedCustomerName,
  updateSelectedLoginCustomerName,
  updateSelectedLoginCustomerId,
  createGoogleAdsAuditReducer,
  postAuditForGoogle,
  updateAuditReport,
  updateInsights,
  updateReviewPointsReducer,
  getInsightsForFormIdReducer,
  updateGotInsights,
  updateHaveInsights,
  updateForAnotherAccount,
  updateAppData,
  publishCampaignReducer,
  resetGoogleCustomerState,
  publishSpecificCampaignTypeReducer,
  fetchAudience,
  updateAudience,
  fetchCriteria,
  updateGoogleData,
  fetchUserList,
  updateGotCustomers,
} = googleCustomersSlice.actions;

export default googleCustomersSlice.reducer;
