import { Route, Routes, useParams } from "react-router-dom";
import bpAmRoutePaths from "./constants/bpAmRoutePaths";
import { Suspense, lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getFormDetailsReducer } from "../../api/clientforms/slice";
import { useGetClientForms } from "../../services/clientForms";
import { setLocalStorage } from "../../api/localStorage/localStorage";

// pages
// const ReadSharedAccess = lazy(() => import("./ReadSharedAccess/index"));

// nested routers
const ClientOnboardingInputsRouter = lazy(() =>
  import("./ClientOnboardingInputs/Router")
);
const AuditForGoogleRouter = lazy(() => import("./AuditForGoogle/index"));
const AuditForFacebookRouter = lazy(() => import("./AuditForFacebook/Router"));
const ProposalRouter = lazy(() => import("./Proposal/Router"));
const ReviewIntakeFormRouter = lazy(() => import("./ReviewIntakeForm/Router"));
const GoogleStrategyRouter = lazy(() => import("./StrategyGoogle/Router"));
const FacebookStrategyRouter = lazy(() => import("./StrategyFacebook/Router"));
const StrategyDeckRouter = lazy(() => import("./StrategyDeck/Router"));
const AccessSharingRouter = lazy(() => import("./AccessSharing/Router"));
// layouts
const SideNavigationStepsLayout = lazy(() =>
  import("./layouts/SideNavigationStepsLayout")
);

export default function BPAMRootRouter() {
  const { formId } = useParams();
  const { data: form } = useGetClientForms();
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getFormDetailsReducer(formId))
  // }, [])
  useEffect(() => {
    if (formId) {
      setLocalStorage("formId", formId);
    }
  }, [formId]);

  return (
    <Suspense fallback={<>loading...</>}>
      <Routes>
        <Route element={<SideNavigationStepsLayout />}>
          <Route
            path={`${bpAmRoutePaths.salesQuestionnaire.root}/*`}
            element={<ClientOnboardingInputsRouter />}
          />
          <Route
            path={`${bpAmRoutePaths.accessSharing.root}/*`}
            element={<AccessSharingRouter />}
          />
          <Route
            path={bpAmRoutePaths.auditForGoogle.root}
            element={<AuditForGoogleRouter />}
          />
          <Route
            path={`${bpAmRoutePaths.auditForFacebook.root}/*`}
            element={<AuditForFacebookRouter />}
          />
          <Route
            path={`${bpAmRoutePaths.proposol.root}/*`}
            element={<ProposalRouter />}
          />
          <Route
            path={`${bpAmRoutePaths.reviewIntakeForm.root}/*`}
            element={<ReviewIntakeFormRouter />}
          />
          <Route
            path={`${bpAmRoutePaths.strategyGoogle.root}/*`}
            element={<GoogleStrategyRouter />}
          />
          <Route
            path={`${bpAmRoutePaths.strategyFacebook.root}/*`}
            element={<FacebookStrategyRouter />}
          />
          <Route
            path={`${bpAmRoutePaths.strategyDeck.root}/*`}
            element={<StrategyDeckRouter />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
}
