export const updateCompoReduxHDMAM = () => {
  return {
    type: "UPDATE_CURRENT_COMPONENT_HDMAM",
  };
};

export const goBackHDMAmCompo = () => {
  return {
    type: "GO_BACK_AM_COMPO",
  };
};

export const updateTabHDMAM = (payload) => {
  return {
    type: "UPDATE_TAB_HDMAM",
    payload: payload,
  };
};

export const updateSideBarHDMAM = (payload) => {
  return {
    type: "UPDATE_SIDE_BAR_HDMAM",
    payload: payload,
  };
};

export const goToSpecificCompoHDMAM = (payload) => {
  return {
    type: "GO_TO_SPECIFIC_COMPO_HDMAM",
    payload: payload,
  };
};

export const openHDMClientView = () => {
  return {
    type: "OPEN_HDM_CLIENT_VIEW",
  };
};

export const closeHDMClientView = () => {
  return {
    type: "CLOSE_HDM_CLIENT_VIEW",
  };
};
