import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import { Tabs, useTabState, Panel } from "../../CustomTab/customTab";

import NavigationSteps from "../NavigationSteps";
import { facebook_icon as facebookIcon } from "../../../assets/icons/social/social";
import googleIcon from "../../../assets/icons/social/google_icon.png";

import { getSharedAssetsReducer } from "../../../api/accountManager/sharedAssets/slice";
import {
  getAllFormsReducer,
  resetFormState,
} from "../../../api/accountManager/forms/slice";
import Avatar from "@mui/material/Avatar";
import {
  BACKEND_STATUS,
  ldComponentWithIndexAm,
} from "../../../utils/accountManagerUtil";
import { showAnalyticsDashboard } from "../../../utils/clientDashboardIdsUtil";
import { haveAccess, platformAccessKey } from "../../../utils/notificationUtil";
import { resetData } from "../../../api/channels/action";
import { resetGoogleCustomerState } from "../../../api/accountManager/googleAdsCustomer/slice";
import { resetPricingState } from "../../../api/accountManager/pricing/slice";
import { generatePath, useNavigate, useNavigation } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import PleaseWaitLoaderModal from "../../modals/PleaseWaitLoaderModal";
import { useGetBrandList } from "../../../services/brandList";
import {
  StyledButtonAddAccount,
  StyledLinkAccount,
} from "../../Admin/UserListTable/styled";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";
import {
  redirectToFacebookAccountConnect,
  redirectToGoogleAccountConnectFromBrandList,
} from "../../../utils/AdminUtils/utils";
import {
  useDeleteAdAccount,
  usePutAdAccountforAccountsList,
} from "../../../services/adAccount";
import SelectAccountFromList from "../../Admin/AccountSelectionFromList/AccountSelection/SelectAccountFromList";
import SelectedAccountListModal from "../../Admin/SelectedAccounts/SelectedAccountListModal";
import WarningModal from "../../Admin/AccountSelectionFromList/WarningModal/WarningModal";
import useGoogleAuthConnection from "../../../hooks/useGoogleAuthConnection";
import { isPlatformSelectedLD } from "../../../utils/LDUtils/commonUtils";
// const cn = (...args) => args.filter(Boolean).join(' ')

// const Tab = ({ children }) => {
//     const { isActive, onClick } = useTabState()

//     return (
//         <button className={cn('tab', isActive && 'active')} onClick={onClick}>
//             {children}
//         </button>
//     )
// }

const getStatusTitle = (form, allProposals) => {
  let title = "";
  let classes = "";
  if (["ONGOING", "PENDING"].includes(form?.onboardingInfo?.status)) {
    title = "Onboarding Form";
    classes = "progress";
  }
  if (["ONGOING"].includes(form?.sowPricingInfo?.status)) {
    title = "Pricing";
    classes = "progress";
  }
  if (form?.sowPricingInfo?.status === "COMPLETED") {
    if (["ONGOING"].includes(form?.accessSharing?.status)) {
      title = "Access Sharing";
      classes = "progress";
    }
  }
  if (
    form?.googleAuditData?.status === "ONGOING" ||
    form?.facebookAuditStatus === "ONGOING"
  ) {
    title = "Audit";
    classes = "progress";
  }
  if (form?.accessSharing?.status === BACKEND_STATUS.COMPLETED) {
    title = "Audit";
    classes = "progress";
  }
  if (
    form?.googleAuditData?.status === "COMPLETED" &&
    isPlatformSelectedLD(form, "facebook-ads")
  ) {
    title = "Audit";
    classes = "progress";
  }
  if (
    form?.googleAuditData?.status === "COMPLETED" &&
    !isPlatformSelectedLD(form, "facebook-ads")
  ) {
    title = "Proposal";
    classes = "progress";
  }
  if (form?.facebookAuditStatus === "COMPLETED") {
    title = "Proposal";
    classes = "progress";
  }
  if (form?.proposalIterations && form?.proposalIterations.length) {
    title = "Proposal";
    classes = "progress";
    if (
      (form?.proposalIterations[form?.proposalIterations.length - 1] || {})
        .state === "APPROVED"
    ) {
      title = "Review Intake Form";
      classes = "progress";
      // if (allProposals[form._id]) {
      //   title = "Shared Assets: Complete";
      //   classes = "progress";
      // }
    }
  }
  if (
    form?.proposalIterations?.length &&
    (form?.proposalIterations[form?.proposalIterations.length - 1] || {})
      .state === "CHANGES_SUGGESTED"
  ) {
    title = "Proposal";
    classes = "progress";
  }
  if (form?.intakeInfo?.status === "ONGOING") {
    title = "Review Intake Form";
    classes = "progress";
  }
  if (form?.reviewIntakeForm === "ONGOING") {
    title = "Review Intake Form";
    classes = "progress";
  }
  if (
    form?.intakeInfo?.status === "COMPLETED" &&
    form?.reviewIntakeForm === "COMPLETED"
  ) {
    title = "Strategy Creation";
    classes = "progress";
  }
  if ((form?.strategyIterations || []).length) {
    if (
      ["GENERATED", "CHANGES_APPLIED"].includes(
        (form?.strategyIterations[form?.strategyIterations.length - 1] || {})
          .state
      )
    ) {
      title = "Strategy Creation";
      classes = "progress";
    }
    if (
      (form?.strategyIterations[form?.strategyIterations.length - 1] || {})
        .state === "CHANGES_SUGGESTED"
    ) {
      title = "Strategy Creation";
      classes = "progress";
    }

    if (
      (form?.strategyIterations[form?.strategyIterations.length - 1] || {})
        .state === "APPROVED"
    ) {
      title = "Campaign Creation";
      classes = "progress";
    }
  }
  if (form?.anyGoogleCampaignPublished || form?.anyFbCampaignPublished) {
    title = "Campaign Preview";
    classes = "progress";
  }
  if (showAnalyticsDashboard(form)) {
    title = "Campaign Preview";
    classes = "progress";
  }

  return [title, classes];
};

const BrandPage = () => {
  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [formId, setFormId] = useState(null);
  const [url, setUrl] = useState("");
  const [removeAlert, setRemoveAlert] = useState(false);
  const [removeAdAccount, setRemoveAdAccount] = useState({
    brandId: "",
    adAccountId: "",
  });
  const { data: brandList, isLoading, isFetching } = useGetBrandList();
  // console.log("Brand List : ", brandList);
  const googleAdsAccess = useSelector(
    (store) => store.sharedAssetsAM.googleAdsAccess
  );
  const allProposals = brandList?.values;
  const open = true;
  // const [isLoading, setIsLoading] = useState(true);
  const {
    payloadFromGoogleAuth,
    setPayloadFromaGoogleAuth,
    accountListModal,
    openAccountListModal,
    selectAccounts,
    openSelectAccounts,
    accountSelected,
    setAccountSelected,
    warningModal,
    setWarningModal,
    enableAllSelectedAccounts,
    setEnableAllSelectedAccounts,
    adAccountLocallyList,
    setAdAccountLocallyList,
    onProceedCallback,
    onGoBackCallback,
    adAccountList,
    isLoadingAdAccounts,
    brandSelected,
    setBrandSelected,
  } = useGoogleAuthConnection("ld-am/brands");
  // useEffect(() => {
  //   dispatch(getAllFormsReducer({ callback: () => setIsLoading(false) }));
  //   dispatch(resetData());
  //   dispatch(resetFormState());
  //   dispatch(resetGoogleCustomerState());
  //   dispatch(resetPricingState());
  //   localStorage.removeItem("formId");
  // }, []);
  // useEffect(() => {
  //   if (allProposals.length) {
  //     dispatch(
  //       getSharedAssetsReducer({
  //         // formIds: allProposals.map(proposal => proposal._id)
  //       })
  //     );
  //   }
  // }, [allProposals]);
  const { mutate: mutateDeleteAdAccount } = useDeleteAdAccount();
  useEffect(() => {
    if (formId)
      setUrl(
        generatePath(ldAmRoutePaths.onboardingForm.fullbase, {
          formId,
        })
      );
  }, [formId]);
  if (isLoadingAdAccounts || isFetching) {
    return <PmaxLoader />;
  }
  return (
    <main class={`main_layout accountManger`}>
      {/*<PleaseWaitLoaderModal open={isLoading} />*/}
      <section className="nav_section">
        <NavigationSteps />
      </section>
      <section className={`right_layout`}>
        <div className={`right_layout_inner`}>
          <div className={`right_main_section`}>
            <div className="brand_selection_layout">
              <div className="brand_selection_header">
                <h4>Hello</h4>
                {/* <h2>Brand Selection</h2> */}
                <p>Please Select the brand that you want to work with</p>
              </div>
              <div className="brand_selection_body">
                <div className="selection_inner_layout success">
                  {(allProposals || []).map((proposal, idx) => {
                    // console.log("Proposal id : ", proposal._id);
                    const [title, classes] = getStatusTitle(
                      proposal,
                      googleAdsAccess
                    );
                    // const haveFbAccess = haveAccess(
                    //   proposal?.accessSharing,
                    //   platformAccessKey.FACEBOOK_ACCESS
                    // );
                    // const haveGoogleAccess = haveAccess(
                    //   proposal?.accessSharing,
                    //   platformAccessKey.GOOGLE_ACCESS
                    // );
                    const googleConnectedAccounts =
                      proposal.connectedAdAccounts.filter(
                        (account) => account.platform === "GOOGLE"
                      );
                    const facebookConnectedAccounts =
                      proposal.connectedAdAccounts.filter(
                        (account) => account.platform === "FACEBOOK"
                      );
                    return (
                      <div
                        className={`selection_brand_block ${
                          currentIndex === idx ? `selected ${classes}` : classes
                        }`}
                        key={idx}
                        onClick={() => {
                          setCurrentIndex(idx);
                          setFormId(proposal._id);
                        }}
                      >
                        <div className="brand_blocks_head">
                          <div className="block_brand_logo">
                            <Avatar
                              className="brand_avatar"
                              alt={proposal.brandName || ""}
                              src={"avatar.jpg"}
                            />
                          </div>
                          <div className="brand_selection_status">{title}</div>
                        </div>
                        <div className="brand_blocks_details">
                          <h3>{proposal.brandName}</h3>
                          <div className="brand_campaing_row">
                            <div className="brand_name_selection">
                              <div className="campaign_connect_logo">
                                <img src={facebookIcon} />
                              </div>
                              <p className="campaign_connect_title">
                                Facebook Ads
                              </p>
                            </div>
                            {facebookConnectedAccounts?.length ? (
                              <StyledLinkAccount
                                onClick={() => {
                                  setAccountSelected("facebook");
                                  openAccountListModal(true);
                                  setBrandSelected({
                                    brandId: proposal.brandId,
                                    sub: proposal.sub,
                                    brandName: proposal.brandName,
                                  });
                                }}
                              >
                                {facebookConnectedAccounts.length}{" "}
                                {facebookConnectedAccounts.length > 1
                                  ? "Accounts"
                                  : "Account"}
                              </StyledLinkAccount>
                            ) : (
                              <StyledButtonAddAccount
                                onClick={() =>
                                  redirectToFacebookAccountConnect(
                                    proposal.brandName,
                                    proposal.brandId,
                                    proposal.sub,
                                    "facebook-ads",
                                    false,
                                    "ld-am/brands"
                                  )
                                }
                              >
                                Add Account
                              </StyledButtonAddAccount>
                            )}
                          </div>
                          <div className="brand_campaing_row">
                            <div className="brand_name_selection">
                              <div className="campaign_connect_logo connected">
                                <img src={googleIcon} />
                              </div>
                              <p className="campaign_connect_title">
                                Google Ads
                              </p>
                            </div>
                            {googleConnectedAccounts?.length ? (
                              <StyledLinkAccount
                                onClick={() => {
                                  setAccountSelected("google");
                                  openAccountListModal(true);
                                  setBrandSelected({
                                    brandId: proposal.brandId,
                                    sub: proposal.sub,
                                    brandName: proposal.brandName,
                                  });
                                }}
                              >
                                {googleConnectedAccounts.length}{" "}
                                {googleConnectedAccounts.length > 1
                                  ? "Accounts"
                                  : "Account"}
                              </StyledLinkAccount>
                            ) : (
                              <StyledButtonAddAccount
                                onClick={() =>
                                  redirectToGoogleAccountConnectFromBrandList(
                                    proposal.brandName,
                                    proposal.brandId,
                                    proposal.sub,
                                    "g-ads",
                                    false,
                                    "ld-am/brands"
                                  )
                                }
                              >
                                Add Account
                              </StyledButtonAddAccount>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="preview_footer_row">
                <div
                  className="footer_content"
                  style={{ justifyContent: "flex-end" }}
                >
                  <PrimaryButton
                    title="Next"
                    style={{
                      backgroundColor:
                        currentIndex !== null ? "#0869FB" : "#B9CCE9",
                      color: "#ffff",
                      textTransform: "none",
                      fontSize: 13,
                    }}
                    disabled={
                      currentIndex === null
                      // ["PENDING", "ONGOING"].includes(
                      //   allProposals[currentIndex]?.sowPricingInfo?.status
                      // )
                    }
                    onClick={() => {
                      // if (
                      //   !["PENDING", "ONGOING"].includes(
                      //     allProposals[currentIndex]?.sowPricingInfo?.status
                      //   )
                      // ) {
                      //   localStorage.setItem(
                      //     "formId",
                      //     allProposals[currentIndex]._id
                      //   );
                      //   localStorage.setItem(
                      //     "brandId",
                      //     allProposals[currentIndex].brandId
                      //   );
                      //   localStorage.setItem("isNextClicked", true);
                      //   window.location.href = "/account-manager";
                      // }
                      localStorage.setItem(
                        "formId",
                        allProposals[currentIndex]._id
                      );
                      localStorage.setItem(
                        "brandId",
                        allProposals[currentIndex].brandId
                      );
                      localStorage.setItem(
                        "brandName",
                        allProposals[currentIndex].brandName
                      );
                      localStorage.setItem("isNextClicked", true);
                      window.location.href = `${url}`;
                    }}
                  ></PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {selectAccounts && (
        <SelectAccountFromList
          openSelectAccounts={openSelectAccounts}
          selectAccounts={selectAccounts}
          accountSelected={accountSelected}
          adAccountList={adAccountList}
          payloadFromGoogleAuth={payloadFromGoogleAuth}
          warningModal={warningModal}
          setWarningModal={setWarningModal}
          enableAllSelectedAccounts={enableAllSelectedAccounts}
          setEnableAllSelectedAccounts={setEnableAllSelectedAccounts}
          adAccountLocallyList={adAccountLocallyList}
          setAdAccountLocallyList={setAdAccountLocallyList}
          accountListModelView={false}
          openAccountListModal={openAccountListModal}
        />
      )}
      {warningModal && (
        <WarningModal
          warningModal={warningModal}
          setWarningModal={setWarningModal}
          onProceedCallback={onProceedCallback}
          onGoBackCallback={onGoBackCallback}
          message={`This will let you select accounts which are already connected to a
          brand. Using this option is not recommended since this would
          disrupt the future flow of this dashboard and some functionalities
          will stop working properly.Please contact the Pixis Team first if
          you are facing issues with connecting ad accounts.`}
        />
      )}
      {removeAlert && (
        <WarningModal
          warningModal={removeAlert}
          setWarningModal={setRemoveAlert}
          onProceedCallback={() => {
            mutateDeleteAdAccount(
              {
                brandId: removeAdAccount.brandId,
                accountId: removeAdAccount.adAccountId,
              },
              {
                onSuccess: () => {
                  setRemoveAlert(false);
                  openAccountListModal(true);
                },
              }
            );
          }}
          onGoBackCallback={() => {
            setRemoveAlert(false);
            openAccountListModal(true);
          }}
          message={`This will delink the account from this brand. To add it again, you can use the "+ Add More Accounts" button`}
        />
      )}
      {accountListModal && (
        <SelectedAccountListModal
          accountSelected={accountSelected}
          accountListModal={accountListModal}
          openAccountListModal={openAccountListModal}
          brand={brandSelected}
          pageFromWhichCalled="brands-list"
          accountListModelView={true}
          payloadFromGoogleAuth={payloadFromGoogleAuth}
          removeAlert={removeAlert}
          setRemoveAlert={setRemoveAlert}
          setRemoveAdAccount={setRemoveAdAccount}
          removeAdAccount={setRemoveAdAccount}
        />
      )}
    </main>
  );
};
export default BrandPage;
