import { forwardRef, useRef } from "react";
import { uploadFile } from "../../../../api/fileHandling/saga";
import { ReactComponent as IconNoImage } from "../../../../assets/icons/placeholder-image-icon.svg";
import PrimaryButton from "../../../ButtonPrimary/Buttons";
import "./upload-image-field.scss";

const NULL_VALUE = null;

const UploadImageField = forwardRef(
  (
    {
      value = NULL_VALUE,
      onChange, // (imageUrl: string) => void
      style,
      className,
    },
    ref
  ) => {
    const elFileInput = useRef(null);

    const _onChange = (event) => {
      const form = new FormData();
      form.append("file", event.target.files[0]);
      uploadFile(form).then((res) => {
        onChange?.(res.fileId);
      });
    };

    const onBrowseFiles = () => elFileInput.current?.click();

    return (
      <div
        className={`upload-image-field upload-image-field__container ${className}`}
        style={style}
        ref={ref}
      >
        {!!value ? (
          <div className="upload-image-field__image-container">
            <img
              src={`${process.env.REACT_APP_BACKEND_BASE_URL}/files/${value}`}
              alt="ad-creative"
            />
          </div>
        ) : (
          <label htmlFor="select-image" className="upload-image-field__label">
            <input
              ref={elFileInput}
              type="file"
              id="select-image"
              style={{ display: "none" }}
              accept="image/*"
              onChange={_onChange}
            />
            <IconNoImage />
            <span className="upload-image-field__text">No Image</span>
            <PrimaryButton
              className="upload-image-field__upload-button"
              onClick={onBrowseFiles}
              title="Upload New Image"
              icon="camera"
            />
          </label>
        )}
      </div>
    );
  }
);

export default UploadImageField;
