import React, { useEffect, useState } from "react";
import { pixis as PixisIcon } from "../../assets/icons/icon";
import { Button, Grid, Typography } from "@mui/material";
import SignInIcon from "../../assets/images/SignIn.svg";
import { getPermissionsAPI, userDetailsApi } from "../../api/auth/saga";
import { setLocalStorage } from "../../api/localStorage/localStorage";
import jwt_decode from "jwt-decode";
import roles, { permissionAccess, permissions } from "../../utils/roles";
import agencyTypes from "../../utils/agencyType";
import { getHDMForms } from "../../api/HDMClient/saga";
import { getFormIdWhileLogin } from "../../api/client/saga";
import { useAuth0 } from "@auth0/auth0-react";
import { generatePath, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import usePostLogout from "../../services/auth";

const LoginPage = (props) => {
  const { resetError } = props;
  const navigate = useNavigate();
  const cookies = new Cookies();
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const { logoutFromUser } = usePostLogout();

  const [state, setState] = React.useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState({
    email: null,
    password: null,
  });
  const [dbloginError, setDbLoginError] = useState("");
  const [modal, setModal] = useState(false);
  const [proposals, setProposals] = useState([]);
  const validateData = (property) => {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    switch (property) {
      case "email":
        if (state.email.length === 0) {
          return "Email is required";
        } else if (!emailRegex.test(state.email)) {
          return "Invalid Email";
        }
        break;
      case "password":
        if (state.password.length === 0) {
          return "Password is required";
        }
        break;
      default:
        return null;
    }
    return null;
  };

  const loginAuth0 = () => {
    localStorage.removeItem("logingError");
    loginWithRedirect();
  };
  const [isLoadingLogin, setIsLoadingLogin] = useState(isAuthenticated);

  useEffect(() => {
    setIsLoadingLogin(isAuthenticated);
    const getAccessToken = async () => {
      return await getAccessTokenSilently();
    };
    console.log("before authentication call", isAuthenticated);
    if (isAuthenticated) {
      getAccessToken().then((access_token) => {
        console.log("after authentication call", isAuthenticated, access_token);
        setLocalStorage("accessToken", access_token);
        userDetailsApi()
          .then(async (res) => {
            console.log(jwt_decode(res.accessToken));
            const {
              sub,
              role,
              agencyId,
              brandId,
              name,
              agencyType,
              brandName,
              email,
            } = jwt_decode(res.accessToken);

            // setLocalStorage("accessToken", res.access_token);
            setLocalStorage("role", role);
            setLocalStorage("sub", sub);
            setLocalStorage("agencyId", agencyId);
            setLocalStorage("agencyType", agencyType);
            setLocalStorage("brandId", brandId);
            setLocalStorage("brandName", brandName);
            setLocalStorage("amName", name);
            setLocalStorage("email", email);
            // console.log(roles.HDM_CLIENT,role,agencyType,agencyTypes.HDM, roles.HDM_CLIENT === role && (agencyType === agencyTypes.HDM))
            const permissionsApi = await getPermissionsAPI();
            if (
              permissionsApi.includes(
                permissions.LOGIN_REDIRECT_ADMIN_TABLE.concat(
                  permissionAccess.EXECUTE
                ) || permissions.ADMIN_FLOW.concat(permissionAccess.VIEW)
              )
            ) {
              window.location.href = "/admin/client";
            } else if (
              permissionsApi.includes(
                permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                  permissionAccess.EXECUTE
                ) ||
                  permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                    permissionAccess.VIEW
                  )
              ) &&
              agencyType === agencyTypes.LINEAR
            ) {
              setLocalStorage("isNextClicked", true);
              window.location.href = "/ld-am/brands";
            } else if (
              permissionsApi.includes(
                permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                  permissionAccess.EXECUTE
                ) ||
                  permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                    permissionAccess.VIEW
                  )
              ) &&
              agencyType === agencyTypes.LINEAR
            ) {
              try {
                const res = await getFormIdWhileLogin();
                res.values.length &&
                  setLocalStorage(
                    "formId",
                    res.values.length && res.values[0]._id
                  );
                window.location.href = "/ld-client/homepage";
              } catch (err) {
                console.log(err);
              }
            } else if (
              permissionsApi.includes(
                permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                  permissionAccess.EXECUTE
                ) ||
                  permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                    permissionAccess.VIEW
                  )
              ) &&
              agencyType === agencyTypes.HDM
            ) {
              try {
                const res = await getHDMForms();
                res.values.length === 1 &&
                  setLocalStorage(
                    "formId",
                    res.values.length === 1 && res.values[0]._id
                  );
                window.location.href = "/hdm-client/homepage";
              } catch (err) {
                console.log(err);
              }
            } else if (
              permissionsApi.includes(
                permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                  permissionAccess.EXECUTE
                ) ||
                  permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                    permissionAccess.VIEW
                  )
              ) &&
              agencyType === agencyTypes.HDM
            ) {
              //(agencyId == 2 || agencyId == 13)) {
              window.location.href = "/hdm-am/brands";
            } else if (
              permissionsApi.includes(
                permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                  permissionAccess.EXECUTE
                ) ||
                  permissions.LOGIN_REDIRECT_BRAND_PAGE.concat(
                    permissionAccess.VIEW
                  )
              ) &&
              agencyType === agencyTypes.BLACK_PROPELLER
            ) {
              window.location.href = "/bp-am/brands";
            } else if (
              permissionsApi.includes(
                permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                  permissionAccess.EXECUTE
                ) ||
                  permissions.LOGIN_REDIRECT_HOME_PAGE.concat(
                    permissionAccess.VIEW
                  )
              ) &&
              agencyId &&
              agencyType == agencyTypes.BLACK_PROPELLER
            ) {
              try {
                const res = await getFormIdWhileLogin();
                res.values.length &&
                  setLocalStorage(
                    "formId",
                    res.values.length && res.values[0]._id
                  );
                window.location.href = "/bp-client/homepage";
              } catch (err) {
                console.log(err);
              }
            }
          })
          .catch((err) => {
            setIsLoadingLogin(false);
            logoutFromUser();
            localStorage.setItem(
              "logingError",
              "User Not Found on the Agency Onboarding Dashboard. Please try logging in with different credentials, or contact the Pixis Support Team if this issue persists."
            );
          });
      });
    } else {
      // sessionStorage.clear();
      // localStorage.clear();
      // Object.keys(cookies.getAll()).map((cookieName) => {
      //     cookies.remove(cookieName);
      //   });
    }
    return () => {};
  }, [isAuthenticated]);
  return (
    <div>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        style={{
          minHeight: "100vh",
          position: "relative",
          backgroundColor: "#ffff",
        }}
      >
        <Grid
          sx={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FCFCFF",
          }}
          item
          sm={7}
          lg={7}
        >
          <img style={{ height: "60%" }} src={SignInIcon} alt="Login" />
        </Grid>
        <Grid
          container
          spacing={0}
          sx={{
            backgroundColor: "#fff",
            textAlign: "center",
            overflow: "auto",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          item
          sm={5}
          xs={12}
          lg={5}
        >
          <Grid xs={12} md={7} lg={7} id="parent_id">
            <Grid item xs={12}>
              <img src={PixisIcon} style={{ width: 100 }} alt="Pixis icon" />
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{ color: "#0869FB" }}
                variant="h4"
                gutterBottom
              >
                Onboarding
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid sx={{ marginBottom: "30px" }} item xs={12}>
              <Typography
                fontSize={14}
                style={{ color: "#222222", fontSize: 14, opacity: 0.6 }}
                variant="subtitle1"
                gutterBottom
              >
                Sign In to access your account
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                // onClick={() => handleCustomLogin()}
                onClick={loginAuth0}
                style={{ width: "100%", height: "50px", borderRadius: "8px" }}
                variant="contained"
                color="primary"
                disabled={isLoadingLogin}
              >
                {isLoadingLogin ? <>Signing In...</> : <>Sign In</>}
              </Button>
              {localStorage.getItem("logingError") ? (
                <Typography
                  style={{
                    marginTop: 8,
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#F27976",
                    // line-height: 22px;
                  }}
                >
                  {localStorage.getItem("logingError")}
                </Typography>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Grid>
        {/* <CreateNewProposal modal={modal} setModal={setModal} proposals={proposals}/> */}
      </Grid>
    </div>
  );
};
export default LoginPage;
