export const getChunks = (array, chunkSize = 2) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
};

const getCampaignId = campaignGeneratedInfo => {
    const lastInfo = campaignGeneratedInfo[campaignGeneratedInfo?.length - 1];
    return lastInfo?.responseData?.data?.campaign_id || lastInfo?.campaignId;
}

const CAMPAIGN_LABELS = {
  "BRAND": "Brand Campaign",
  COMPETITOR: "Competitor Campaign",
  SERVICE: "Service Campaign",
  top_of_the_funnel_lookalike: "TOF Lookalike" ,
  top_of_the_funnel_interest: "TOF Interest",
  middle_of_the_funnel: "MOF",
  bottom_of_the_funnel: "BOF" 
};

export const getGoogleSearchCampaigns = (googleSearchAds) => {
  const generatedCampaignsObj = googleSearchAds?.filter(el => el.campaignGeneratedInfoStatus === 'SUCCESS').map((el, index) => ({
        id: getCampaignId(el.campaignGeneratedInfo),
        name: el?.name,
        label: el.title,
  })) || [];
  return generatedCampaignsObj;
}

export const getGooglePmaxCampaigns = (pmaxAds) => {
  return pmaxAds?.filter(el => el.campaignGeneratedInfoStatus === 'SUCCESS').map((el, index) => ({
    id: getCampaignId(el.campaignGeneratedInfo),
    name: el?.name,
    // label: el.campaignNumber, // el.requestPayload?.campaign?.campaignNumber, 
    label: el.title,
  })) || [];
};


export const getPmaxSegments = (pmaxSegments) => {
  return pmaxSegments?.filter(el => el.publishmentStatus === 'SUCCESS').map((el, index) => ({
    id: el?.resourceName,
    name: el?.name,
    // label: el.campaignNumber, // el.requestPayload?.campaign?.campaignNumber, 
    label: el.title,
  })) || [];
};

export const getFacebookCampaigns = (fbAds) => {
  const generatedCampaignsObj =
    fbAds
      ?.filter((el) => el?.campaignGeneratedInfoStatus === 'SUCCESS')
      .map((el, index) => ({
            id: getCampaignId(el?.campaignGeneratedInfo),
            name: el?.name,
            label: el?.title,
      })) || [];
    return generatedCampaignsObj;
}

export const isAnyCampaignsPublished = (clientForm) => {
  if(!clientForm) return false;
  return [
    getGoogleSearchCampaigns(clientForm.googleSearchAdsGeneratedCampaignsInfo),
    getGooglePmaxCampaigns(clientForm.pmaxAdsGeneratedCampaignsInfo),
    getFacebookCampaigns(clientForm.fbAdsGeneratedCampaignsInfo),
  ].flat().length > 0;
}

export const isAllCampaignsPublished = (clientForm) => {
  if(!clientForm) return false;
  const isSearchComplete = !!clientForm.pmaxCAICronStatus ? getGoogleSearchCampaigns(clientForm.googleSearchAdsGeneratedCampaignsInfo).length === 3 : true;
  const isPmaxComplete = !!clientForm.pmaxTAICronStatus ? getGooglePmaxCampaigns(clientForm.pmaxAdsGeneratedCampaignsInfo).length > 1 : true;
  const isFacebookComplete = getFacebookCampaigns(clientForm.fbAdsGeneratedCampaignsInfo).length === 4;
  
  return isSearchComplete && isPmaxComplete && isFacebookComplete;
}
