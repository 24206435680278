import NoContentView from './NoContentView';
import grayInfoIcon from './pmaxIcons/gray-info-icon.svg';
import {
  Typography,
  Button,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

const PMaxThemes = ({
    theme="",
    themes=[],
    change=()=>{},
    handleSelectTheme=()=>{},
    handleGenerateThemes=()=>{},
    handleGenerateAdCopies=()=>{},
}) => {

  const selectedThemesCountGreaterThanFive = () => {
    const selectedThemes = [];
    themes.forEach((theme) => {
      if (theme.status === true) selectedThemes.push(theme);
    });
    if (selectedThemes.length >= 5) return true;
    else return false;
  };

    return (
          <div className='pmax-themes-search-view'>
             <div style={{
                 paddingTop: "15px",
                 paddingLeft: "15px"
             }}>
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: '19.36px',
                  color: ' #333333',
                }}
              >
                Theme
              </Typography>
              </div>
            <div>
            <div className='pmax-search-view'>
              <TextField
                variant='outlined'
                placeholder='Default Theme Goes Here'
                value={theme}
                inputProps={{
                  style: {
                    color: '#333333',
                    width: 375,
                    height: 42,
                    background: '#FFFFFF',
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 12,
                    padding: '0 0 0 16px',
                  },
                }}
                onChange={(e) => change(e)}
              />
              <div className='search-button-container'>
                <Button
                  className='search-button'
                  onClick={() => handleGenerateThemes()}
                >
                  Search
                </Button>
              </div>
             </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                padding: 16,
                paddingLeft: 24,
                paddingTop: 0,
                marginTop: 9
              }}
            >
              <img
                src={grayInfoIcon}
                alt='info'
                style={{
                  width: 16,
                  height: 16,
                }}
              />
              <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 11,
                  lineHeight: '13px',
                  color: '#9798A1',
                }}
              >
                'Please select a maximum of 5 themes.'
              </Typography>
            </div>
            <div className='pmax-theme-list'>
              <FormGroup>
                {themes.length === 0 ? (
                  <NoContentView tab='Theme' buttonName='Generate Ad Copies' />
                ) : (
                  themes.map((theme, index) => (
                    <FormControlLabel
                      disabled={
                        selectedThemesCountGreaterThanFive() && !theme?.status
                      }
                      key={index}
                      control={
                        <Checkbox checked={theme?.status ? true : false} />
                      }
                      label={
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: '20px',
                            color: '#333333',
                          }}
                        >
                          {theme?.name}
                        </Typography>
                      }
                      onClick={() => handleSelectTheme(index)}
                    />
                  ))
                )}
              </FormGroup>
            </div>
            <div className='generate-add-container'>
              <Button
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '20px',
                  color: '#0869FB',
                  textTransform: 'capitalize',
                  backgroundColor: 'transparent',
                }}
                onClick={() => handleGenerateAdCopies()}
              >
                Generate Ad Copies
              </Button>
            </div>
          </div>
    );
}
export default PMaxThemes;