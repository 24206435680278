import { useEffect, useState } from "react";
import { Accordion, Typography } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { editicon as EDITICON } from "../../../assets/icons/icon";
import { useDispatch, useSelector } from "react-redux";
import "./AccountPreview.css";
import GoToNextCompoButton from "../GoToNextCompoButton";
import { clientDashboardIds } from "../../../utils/clientDashboardIdsUtil";
import { clientDetailsMapping } from "./RouteMappingWithCompo";
import PrimaryButton from "../../ButtonPrimary/Buttons";
import {
  getFormByFormIdReducer,
  updateCompanyInfoReducer,
  updateFormReducer,
} from "../../../api/accountManager/forms/slice";

import {
  DB_KEY_MAP_GROWTH,
  DB_KEY_MAP_PERSONAL,
  DB_KEY_MAP_PLATFORM,
} from "../../../utils/dbKeyMapping";
import {
  BASIC_INFORMATION_LD,
  PLATFORM_INFORMATION_LD,
  GROWTH_GOALS_LD,
} from "../../../utils/questionsOrderToPreview";
import { openClientView } from "../../../api/accountManager/componentTracking/action";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import {
  useGetClientForms,
  usePostClientForms,
} from "../../../services/clientForms";

const AccountPreviewScrn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  const { data: clientForm } = useGetClientForms(formId);
  // const form = useSelector((store) => store.form.form);
  // const basicInform = useSelector(
  //   (state) => state.form?.form?.onboardingInfo?.personalInfo
  // );
  const basicInform = clientForm?.onboardingInfo?.personalInfo;
  // const platformInform = useSelector(
  //   (state) => state.form?.form?.onboardingInfo?.platformInfo
  // );
  const platformInform = clientForm?.onboardingInfo?.platformInfo;
  // const growthGoals = useSelector(
  //   (state) => state.form?.form?.onboardingInfo?.growthGoal
  // );
  const growthGoals = clientForm?.onboardingInfo?.growthGoal;
  const companyInfo = clientForm?.companyInfo;
  const [basicFormQuestions, setBasicFormQuestions] = useState({});
  const [platformInformationQuestions, setPlatformInformationQuestions] =
    useState({});
  const [growthGoalQuestions, setGrowthGoalQuestions] = useState({});

  // useEffect(() => {
  //   dispatch(getFormByFormIdReducer());
  // }, []);

  useEffect(() => {
    if (basicInform) {
      const informations = {};
      BASIC_INFORMATION_LD.forEach((question) => {
        informations[question] = basicInform[question];
      });
      setBasicFormQuestions(informations);
    }
  }, [basicInform]);

  useEffect(() => {
    if (platformInform) {
      const informations = {};
      PLATFORM_INFORMATION_LD.forEach((question) => {
        if (question === "adsManaged") {
          informations[question] = "";
        } else {
          informations[question] = platformInform[question];
        }
      });
      setPlatformInformationQuestions(informations);
    }
  }, [platformInform]);

  useEffect(() => {
    if (growthGoals) {
      const informations = {};
      GROWTH_GOALS_LD.forEach((question) => {
        informations[question] = growthGoals[question];
      });
      setGrowthGoalQuestions(informations);
    }
  }, [growthGoals]);

  const mappedKey = {
    competitors: "name",
    platforms: "platform",
  };
  const desiredData = (key, value) => {
    if (key === "platforms") {
      let str = "";
      value.forEach(({ platform, spends, cpa }) => {
        if (platform.length !== 0) {
          str +=
            "(" + platform + "=>(spends=$" + spends + ",cpa=$" + cpa + ")),";
        }
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : "";
    }
    if (key === "crm" || key === "leadSource") {
      let str = "";
      value.forEach((val) => {
        if (val && val !== "Other") {
          str += val + ",";
        }
      });
      return str.length !== 0 ? str.substring(0, str.length - 1) : "";
    }

    if (key === "competitors") {
      let compNameValue = "";
      value.map((key, index) => {
        compNameValue += "(" + key.name + " => " + key.website + ")" + ",";
      });
      return compNameValue.slice(0, compNameValue.length - 1);
    }
    const tempArr = value.map((infoType) => infoType[mappedKey[key]]);
    return tempArr.join(",");
  };
  const adsManaged = () => {
    let str = "";
    [
      { key: "googleAdsManaged", value: "Google" },
      { key: "fbAdsManaged", value: "Facebook" },
      { key: "implementCro", value: "CRO" },
    ].forEach(({ key, value }, index) => {
      if (companyInfo[key]) {
        str = str + "(" + value + "=>" + "yes" + "),";
      } else {
        str = str + "(" + value + "=>" + "no" + "),";
      }
    });
    console.log("##########", str);
    return str.length !== 0 ? str.substring(0, str.length - 1) : str;
  };
  const { mutate: mutatePostClientForm } = usePostClientForms(
    {},
    {
      onSuccess: () => {
        dispatch(getFormByFormIdReducer());
      },
    }
  );
  const clickHandler = () => {
    //temporary fix
    mutatePostClientForm({
      onboardingInfo: {
        status: BACKEND_STATUS.COMPLETED,
      },
      sowPricingInfo: {
        ...(clientForm?.sowPricingInfo || {}),
        status:
          (clientForm?.sowPricingInfo || {})?.status === BACKEND_STATUS.PENDING
            ? BACKEND_STATUS.ONGOING
            : BACKEND_STATUS.COMPLETED,
      },
      currentStep:
        clientForm.onboardingInfo.status === BACKEND_STATUS.COMPLETED
          ? clientForm.currentStep
          : "Pricing",
    });

    navigate(generatePath(ldAmRoutePaths.pricing.fullbase, { formId }));
  };
  return (
    <>
      <div style={{ height: "100%", overflow: "auto" }}>
        <div className="top_head">
          <div className="resp">
            <h3 className="pr_heading">REVIEW</h3>
            <h1 className="header2">Client Responses</h1>
            <span
              style={{
                fontFamily: "Inter",
                fontSize: "18px",
                lineHeight: "30px",
                fontWeight: "400",
                color: "#6F6C90",
                textAlign: "center",
              }}
            >
              Please review these responses carefully.{" "}
            </span>

            {/* <button className="btn back_button"></button> */}

            <PrimaryButton
              type="bordered"
              className="edit_response_button"
              title="Edit Responses"
              icon="edit"
              onClick={() => {
                navigate(
                  generatePath(
                    `${ldAmRoutePaths.onboardingForm.fullbase}/${ldAmRoutePaths.onboardingForm.sub.basicInformation}`,
                    { formId }
                  )
                );
              }}
            ></PrimaryButton>
          </div>
        </div>

        <div className="accordian">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontWeight={600}>Basic Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(basicFormQuestions)?.map(
                  ([key, value], index) => {
                    if (DB_KEY_MAP_PERSONAL[key]) {
                      return (
                        <div className="tag">
                          <div className="content">
                            <span>{DB_KEY_MAP_PERSONAL[key]} : </span>
                            <span
                              style={{ color: "#619BF3", fontWeight: "bolder" }}
                            >
                              {(value || "").length !== 0 ? value : ""}
                            </span>{" "}
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Platform Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(platformInformationQuestions)?.map(
                  ([key, value], index) => {
                    if (DB_KEY_MAP_PLATFORM[key]) {
                      return (
                        <div className="tag" key={index}>
                          <div className="content">
                            <span>{DB_KEY_MAP_PLATFORM[key]} : </span>
                            <span
                              style={{ color: "#619BF3", fontWeight: "bolder" }}
                            >
                              {Array.isArray(value)
                                ? desiredData(key, value)
                                : key === "adsManaged"
                                ? adsManaged(key)
                                : (value || "").length === 0
                                ? ""
                                : ["averageTicketPrice", "cltv"].includes(key)
                                ? `$${value}`
                                : value
                                ? value
                                : ""}
                            </span>{" "}
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="accordian">
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={600}>Growth Goals</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="wrap">
                {Object.entries(growthGoalQuestions)?.map(
                  ([key, value], index) => {
                    if (DB_KEY_MAP_GROWTH[key]) {
                      return (
                        <div className="tag">
                          <div className="content">
                            <span>{DB_KEY_MAP_GROWTH[key]} : </span>
                            <span
                              style={{ color: "#619BF3", fontWeight: "bolder" }}
                            >
                              {(value || "").length !== 0 ? value : ""}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>

      <div className="preview_footer_row">
        <div className="footer_con" style={{ justifyContent: "end" }}>
          <PrimaryButton
            disabled={
              clientForm?.onboardingInfo?.status === BACKEND_STATUS.PENDING
            }
            onClick={clickHandler}
            title="PROCEED"
          />
          {/* <GoToNextCompoButton title="PROCEED" /> */}
        </div>
      </div>
    </>
  );
};
export default AccountPreviewScrn;
