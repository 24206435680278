import { Typography, TextField } from '@material-ui/core';
import React from 'react';
import { AB_TEST_SECTION_ENUM } from '../../../api/creativeApproval/enums';
import ABTestListStatus from './ABTestListStatus';
import DisplayTests from './DisplayTests';

const Question = (props) => {

    const onChangeHandler = ({ question=props.info.question, status=props.info.status }) => {
        const id = props.id;
        console.log('#####', question, status, id);
        props.onChangeHandler({ question, status, id });
    };

    return (
      <>
        <li>
          <DisplayTests
            text={props.info.question}
            read={props.read}
            onChangeHandler={onChangeHandler}
          />
        </li>
        <div
          style={{
            marginLeft: -8,
          }}
        >
          {!props.isClient ? (
            <ABTestListStatus onChangeHandler={onChangeHandler} status={props.info.status}/>
          ) : (
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 10,
                lineHeight: '40px',
              color:
                props.info.status === 'Not Started'
                  ? '#F8862D'
                  : props.info.status === 'Ongoing'
                  ? '#3482F7'
                  : '#1ABFA1',
              }}
            >
              {props.info.status}
            </Typography>
          )}
        </div>
      </>
    )
}

const MessagingText = ({ read, isClient, questions=[], onUpdateHandler }) => {
  // const messagingTests = useSelector(state => selectAbTestSection(state, AB_TEST_SECTION_ENUM.MESSAGING_TESTS));
  const onChangeHandler = ({ id, question, status }) => {
      onUpdateHandler({
          section: AB_TEST_SECTION_ENUM.MESSAGING_TESTS,
          id,
          question,
          status
      });
  };

  const questionsList = questions.map((info, index) => 
                            <Question info={info} read={read} isClient={isClient} key={index} id={index} onChangeHandler={onChangeHandler}/>
                      );
  return (
    <div className='messaging-text-tests'>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 18,
          lineHeight: '40px',
          color: 'rgba(0, 0, 0, 0.75)',
        }}
      >
        Messaging Tests
      </Typography>
      <ul>
        {questionsList}
      </ul>
    </div>
  );
};

export default MessagingText;
