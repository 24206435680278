import React, {useState, useEffect} from 'react';
import { Typography, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
const AdLevelChild = (props) => {
  const [value, setValue] = useState(props.value || '');
  useEffect(() => {
    setValue((props.value || {}).value || '')
  }, [props])
  return (
    <div className='ad-group-results'>
      <div className='ad-group-detail'>
        {props.read ? (
          <Typography className='ad-group-result__title'>{(props.value || {}).value || ""}</Typography>
        ) : (
          <div style={{ paddingLeft: '0.5rem' }}>
            <TextField
              className='ad-group-result__title'
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => {
                if(e.key === "Enter" && value){
                  props.editResource(props.campaignType, props.adSetName, (props.value || {}).value, value);
                }
              }}
              variant='standard'
              sx={{
                '& .css-6bsk74-MuiInputBase-root-MuiInput-root:before': {
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                },
                // border: '1px solid green',
              }}
              inputProps={{
                style: {
                  // borderBottom: '1px solid #E8E8E8',
                  // width: '95%',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 14,
                  color: '#444444',
                  padding: '0.5rem 0',
                },
              }}
              color='info'
            />
          </div>
        )}
        <div className='ad-level-description-container'>
          <Typography className='ad-group-result__description'>
            {(props.value || {}).category}
          </Typography>
          <Typography className='ad-group-result__description'>
            {((props.value || {}).value  || "").length}/{props.totalCharacter}
          </Typography>
        </div>
      </div>
      {!props.isDisabled && (
        <Close 
          style={{ width: 12, height: 12 }} 
          onClick={() => props.deleteResource(props.campaignType, props.adSetName, props.index)}
        />
      )}
    </div>
  );
};

export default AdLevelChild;
