import { UPDATE_CURRENT_COMPONENT } from "../../api/componentTracking/enum";
import {
  clientDashboardIds,
  LDcomponentWithIndexClient,
} from "../../utils/clientDashboardIdsUtil";
import { listOfIds, headerTabs } from "../../utils/navbarUtil";

const INITIAL_STATE = {
  currentComp: 0,
  currentLeftSidebar: null,
  currentHeaderTab: null,
  lastActiveComp: 0,
  visitedLastTime: 0,
};

export const updateNav = (currentComp) => {
  if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.RENDER_HOME_PAGE]
  ) {
    return null;
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST1] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.GROWTH_GOALS_QUEST1]
  ) {
    return listOfIds.questionnaire;
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[clientDashboardIds.SCOPE_GOOGLE_ADS_SETUP] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.SCOPE_PRICING_ADS_SETUP]
  ) {
    return listOfIds.accountScopeOfWorkAndPricing;
  } else if (
    currentComp === LDcomponentWithIndexClient[clientDashboardIds.READ_ACCESS]
  ) {
    return listOfIds.readAcess;
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[clientDashboardIds.PROPOSAL_TAB] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.SUGGEST_CHANGES_TAB]
  ) {
    return listOfIds.proposal;
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM1] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING5]
  ) {
    return listOfIds.form;
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.CLIENT_COMPLETE_ACCESS]
  ) {
    return listOfIds.completeAccess;
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_INTRO] &&
    currentComp <=
      LDcomponentWithIndexClient[
        clientDashboardIds.STRATEGY_DECK_SUGGEST_CHANGES
      ]
  ) {
    return listOfIds.strategy;
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.CAMPAIGN_PREVIEW_CLIENT]
  ) {
    return listOfIds.approval;
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.CAMPAIGN_PREVIEW_FACEBOOK]
  ) {
    return listOfIds.campaignPreviewFb;
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[
        clientDashboardIds.CREATIVE_APPROVAL_ADCOPIES
      ] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.CREATIVE_APPROVAL_AB_TEST]
  ) {
    return listOfIds.creativeApproval;
  } else {
    return null;
  }
};

export const updateHeader = (currentComp) => {
  if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.RENDER_HOME_PAGE]
  ) {
    return null;
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST1] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_BASIC_QUEST10]
  ) {
    return headerTabs["BASIC INFORMATION"];
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[
        clientDashboardIds.PLATFORM_INFORMATION_QUEST1
      ] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.PLATFORM_INFORMATION_QUEST6]
  ) {
    return headerTabs["PLATFORM INFORMATION"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.GROWTH_GOALS_QUEST1]
  ) {
    return headerTabs["GROWTH GOALS"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.SCOPE_GOOGLE_ADS_SETUP]
  ) {
    return headerTabs["GOOGLE ADS SETUP"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.SCOPE_FACEBOOK_ADS_SETUP]
  ) {
    return headerTabs["FACEBOOK ADS SETUP"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.SCOPE_CRO_ADS_SETUP]
  ) {
    return headerTabs["CRO SETUP"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.SCOPE_PRICING_ADS_SETUP]
  ) {
    return headerTabs["PRICING"];
  } else if (
    currentComp === LDcomponentWithIndexClient[clientDashboardIds.PROPOSAL_TAB]
  ) {
    return headerTabs["PROPOSAL"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.SUGGEST_CHANGES_TAB]
  ) {
    return headerTabs["SUGGEST CHANGES"];
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM1] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM9]
  ) {
    return headerTabs["PRODUCT INFO"];
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES1
      ] &&
    currentComp <=
      LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_PREFERENCES5
      ]
  ) {
    return headerTabs["PREFERENCES"];
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE1
      ] &&
    currentComp <=
      LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_AUDIENCE12
      ]
  ) {
    return headerTabs["AUDIENCE"];
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_BRANDING1
      ] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_INTAKE_FORM_BRANDING7]
  ) {
    return headerTabs["BRANDING"];
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF1
      ] &&
    currentComp <=
      LDcomponentWithIndexClient[
        clientDashboardIds.QUEST_INTAKE_FORM_SOCIAL_PROOF3
      ]
  ) {
    return headerTabs["SOCIAL PROOF"];
  } else if (
    currentComp >=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING1] &&
    currentComp <=
      LDcomponentWithIndexClient[clientDashboardIds.QUEST_FB_TARGETING5]
  ) {
    return headerTabs["FB TARGETING"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_INTRO]
  ) {
    return headerTabs["INTODUCTION"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_GOOGLE]
  ) {
    return headerTabs["GOOGLE"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_FACEBOOK]
  ) {
    return headerTabs["FACEBOOK"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.STRATEGY_DECK_SUGGEST_CHANGES]
  ) {
    return headerTabs["SUGGEST CHANGES"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.CREATIVE_APPROVAL_ADCOPIES]
  ) {
    return headerTabs["AD COPIES"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.CREATIVE_APPROVAL_CREATIVES]
  ) {
    return headerTabs["CREATIVES"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[
      clientDashboardIds.CREATIVE_APPROVAL_LANDING_PAGES
    ]
  ) {
    return headerTabs["LANDING PAGES"];
  } else if (
    currentComp ===
    LDcomponentWithIndexClient[clientDashboardIds.CREATIVE_APPROVAL_AB_TEST]
  ) {
    return headerTabs["AB TEST"];
  } else {
    return null;
  }
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_COMPONENT:
      return {
        ...state,
        currentHeaderTab: updateHeader(state.currentComp + 1),
        currentLeftSidebar: updateNav(state.currentComp + 1),
        currentComp: state.currentComp + 1,
        visitedLastTime: Math.max(state.currentComp + 1, state.visitedLastTime),
      };
    case "EDIT_CLIENT_RESPO_CLIENT":
      return {
        ...state,
        currentComp: 1,
        currentLeftSidebar: listOfIds.questionnaire,
        currentHeaderTab: headerTabs["BASIC INFORMATION"],
      };
    case "GO_TO_HOME_CLIENT":
      return {
        ...state,
        currentComp: 0,
        currentLeftSidebar: null,
        currentHeaderTab: null,
      };
    case "GO_BACK_CLIENT_COMPO":
      const currentComp = state.currentComp - 1;
      return {
        ...state,
        currentComp: state.currentComp - 1,
        currentHeaderTab: updateHeader(currentComp),
        currentLeftSidebar: updateNav(currentComp),
      };
    case "GO_TO_SPECIFIC_COMPO_CLIENT":
      const currentComp1 = action.payload;
      return {
        ...state,
        currentComp: currentComp1,
        currentHeaderTab: updateHeader(currentComp1),
        currentLeftSidebar: updateNav(currentComp1),
        visitedLastTime: Math.max(state.visitedLastTime, currentComp1),
      };
    case "UPDATE_TAB_CLIENT":
      const currentComp2 = action.payload;
      return {
        ...state,
        currentComp: currentComp2,
        currentHeaderTab: updateHeader(currentComp2),
        currentLeftSidebar: updateNav(currentComp2),
      };

    case "REVIEW_QUESTIONIER":
      return { ...state, ...action.payload };

    case "GO_BACK_REVIEW_QUESTIONIER": {
      const currentComp3 = action.payload;
      return {
        ...state,
        currentComp: currentComp3.currentComp,
        currentHeaderTab: updateHeader(currentComp3.currentComp),
        currentLeftSidebar: updateNav(currentComp3.currentComp),
        lastActiveComp: 0,
        visitedLastTime: Math.max(
          state.visitedLastTime,
          currentComp3.currentComp
        ),
      };
    }
    case "DISABLE_SUGGEST_CHANGES_DECK_CLIENT":
      return {
        ...state,
        visitedLastTime:
          LDcomponentWithIndexClient[
            clientDashboardIds.STRATEGY_DECK_SUGGEST_CHANGES
          ],
      };
    case "RESET":
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
