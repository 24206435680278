import {ReactComponent as IconSegmentView} from "../../../assets/icons/campaign/segment-view.svg";
import {ReactComponent as IconPmaxView} from "../../../assets/icons/campaign/google-pmax-view.svg";
import {ReactComponent as IconSearchView} from "../../../assets/icons/campaign/google-search-view.svg";


export const PMAX_OPTION = {
  label: "Google Performance Max",
  icon: <IconPmaxView />,
  id: "g-pmax"
};

export const SEARCH_OPTION = {
  label: "Google Search",
  icon: <IconSearchView />,
  id: "g-search"
};

export const SEGEMENT_OPTION = {
  label: "Segment",
  icon: <IconSegmentView />,
  id: "segment",
};

export const ALL_OPTIONS = [
  PMAX_OPTION,
  SEARCH_OPTION,
  SEGEMENT_OPTION, 
];

export const PMAX_OPTIONS = [
  PMAX_OPTION,
  SEGEMENT_OPTION,
];

export const SEARCH_OPTIONS = [
  SEARCH_OPTION
];