import React from "react";
import GoToNextCompoButton from "../../ButtonPrimary/GoToNextCompoButton";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import PrimaryButton from "../ButtonPrimary/Buttons";
const TrackingTemplateModal = ({
  setModal,
  websiteUtmUrl,
  setWebsiteUtmUrl,
  utmSource,
  utmMedium,
  utmCampaign,
  setUtmSource,
  setUtmMedium,
  setUtmCampaign,
  isDisabled,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
    // height: '380px',
    borderRadius: "15px",
    outline: "none",
  };
  const textFieldStyle = {
    background: "#FFFFFF",
    border: "1px solid #DDDDDD",
    borderRadius: 8,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: "#262626",
  };
  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Close
          onClick={() => setModal(false)}
          style={{
            position: "absolute",
            top: "1.65rem",
            right: "1.5rem",
            cursor: "pointer",
            background: "#F2F2F2",
            borderRadius: 4,
          }}
        />
        <div
        //   style={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     alignItems: 'center',
        //     justifyContent: 'center',
        //     height: '100%',
        //     gap: '0.5rem',
        //   }}
        >
          <div
            style={{
              padding: "0 0 1rem 0",
              borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 20,
                color: "#1C1E21",
              }}
            >
              Tracking Template
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1.5rem",
              gap: "1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#1C1E21",
                }}
              >
                Website URL{" "}
                {/* <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: 10,
                    color: "#8d8e90",
                  }}
                >
                  e.g.newsletter, twitter, google etc
                </span> */}
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={websiteUtmUrl}
                onChange={(e) => setWebsiteUtmUrl(e.target.value)}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                inputProps={
                  {
                    // readOnly: true
                  }
                }
                style={textFieldStyle}
                disabled={isDisabled}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#1C1E21",
                }}
              >
                UTM Source{" "}
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: 10,
                    color: "#8d8e90",
                  }}
                >
                  e.g.newsletter, twitter, google etc
                </span>
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={utmSource}
                onChange={(e) => setUtmSource(e.target.value)}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                inputProps={
                  {
                    // readOnly: true
                  }
                }
                style={textFieldStyle}
                disabled={isDisabled}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#1C1E21",
                }}
              >
                UTM Medium{" "}
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: 10,
                    color: "#8d8e90",
                  }}
                >
                  e.g.email, social, CPC etc
                </span>
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={utmMedium}
                onChange={(e) => setUtmMedium(e.target.value)}
                inputProps={
                  {
                    // readOnly: true
                  }
                }
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                style={textFieldStyle}
                disabled={isDisabled}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 14,
                  color: "#1C1E21",
                }}
              >
                UTM Campaign{" "}
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: 10,
                    color: "#8d8e90",
                  }}
                >
                  e.g.promotion, sale etc
                </span>
              </Typography>
              <TextField
                id="outlined-basic"
                variant="outlined"
                value={utmCampaign}
                onChange={(e) => setUtmCampaign(e.target.value)}
                sx={{
                  boxShadow: "none",
                  ".MuiOutlinedInput-notchedOutline": { border: 0 },
                }}
                style={textFieldStyle}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div
            style={{
              //   margin: '2rem 0',
              paddingTop: "2rem",
              display: "flex",
              gap: "1.5rem",
              justifyContent: "end",
            }}
          >
            <PrimaryButton title="Submit" onClick={() => setModal(false)} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default TrackingTemplateModal;
