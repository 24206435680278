export const adsetsObjectDb={
  "audience": {
    "id": "",
    "name": "",
    "minReach": "",
    "maxReach": "",
    "subType": ""
  },
  "minRange": "",
  "maxRange": "",
  "adsetName": "",
  "keywords": [],
  "facebookAds": [
    {
      "iamgeUrl": "",
      "headline": "",
      "description": "",
      "primaryText": "",
      "adName": ""
    },
    {
      "iamgeUrl": "",
      "headline": "",
      "description": "",
      "primaryText": "",
      "adName": ""
    }
  ]
}

export const adsetsObjFunc = (keywords,adsetName) => {
   return {
    "audience": {
      "id": "",
      "name": "",
      "minReach": "",
      "maxReach": "",
      "subType": ""
    },
    "minRange": "",
    "maxRange": "",
    "adsetName": adsetName,
    "keywords": keywords,
    "facebookAds": [
      {
        "iamgeUrl": "",
        "headline": "",
        "description": "",
        "primaryText": "",
        "adName": ""
      },
      {
        "iamgeUrl": "",
        "headline": "",
        "description": "",
        "primaryText": "",
        "adName": ""
      }
    ]
  }
}

export const defaultAdsets=()=>{
  const arr=[];
  [1,2,3].forEach((ele,index)=>{
    const obj={...adsetsObjectDb};
    obj["adsetName"]="Adset "+(index+1);
    arr.push(obj)
  })
  return arr;
}

export const defaultAdsetsInInterest = (keywords) => {
   const topThree=keywords.length >= 3 ? 3 : keywords.length;
   const arrAdsets=[];
   for(let i=0;i<topThree;i++){
      const keywordsNameArray=[];
      keywords[i]["keywords"].forEach((keyword) => {
        keywordsNameArray.push(keyword);
      });
      arrAdsets.push(adsetsObjFunc(keywordsNameArray,keywords[i]["adsetName"]));
   }
   return arrAdsets;
}

export const dbKeysHdmAm={
    "brandId": "",
    "onboardingInfo": {
      "status": "PENDING",
      "clientAccess": {
        "status": "PENDING",
        "businessName": "",
        "primaryContactPerson": "",
        "fbPageUrl": "",
        "websiteUrl": "",
        "shopifyStoryAvailable": true,
        "preferredEmailAddress": '',
        "preferredPhoneNumber": '',
        "streetAddress": '',
        "city": '',
        "provinceOrState": '',
        "postalCode": null,
        "country": ''
      },
      "brandMessaging": {
        "status": "PENDING",
        "esthetic": "",
        "tagline": "",
        "emotionalResponse": "",
        "feelWithBrand": "",
        "engageWithBrand": "",
        "uniqueSellingPoint": "",
        "whatSetsYouApart": "",
        "benefitsOfProducts": [
          ""
        ],
        "painPoints": [
          ""
        ],
        "storyToPromote": "",
        "brandMessagingExampels": [
          ""
        ],
        "brandFont": "",
        "brandColor": "",
        "logoLink": ""
      },
      "facebookTargeting": {
        "status": "PENDING",
        "minAgeRange": 0,
        "maxAgeRange": 0,
        "gender": "string",
        "advertisingLocations": [
          {
            "key": "",
            "name": "",
            "type": "",
            "countryName": "",
            "region": "string",
            "supportsRegion": "",
            "supportsCity": ""
          }
        ],
        "activitiesInterest": [
          {
            "id": "",
            "name": "",
            "audeinceSize": ""
          }
        ],
        "excludes": "",
        "formalCustomerPersonas": [
          ""
        ],
        "otherCharacteristics": ""
      },
      "aboutBusiness": {
        "status": "PENDING",
        "topProducts": [
          ""
        ],
        "noPromotionProduct": "",
        "offers": "",
        "averageOrderValue": "",
        "cogsPerAov": "",
        "shippingCostPerAov": "",
        "breakEvenPoint": "",
        "fbCamaignBudget": "",
        "googleCamaignBudget": "",
        "tiktokCamaignBudget": "",
        "countryTargeted": [
          ""
        ],
        "keyMessages": "",
        "keyCompetitors": [
          {
            "name": "",
            "website": ""
          }
        ],
        "brandsInterests": [
          ""
        ],
        "commonJargons": "",
        "brandNoNos": "",
        "monthlyRevenueGoal3Months": "",
        "monthlyRevenueGoal6Months": "",
        "monthlyRevenueGoal1Year": "",
        "maximizeEnterpriseValueProfit": "",
        "requirementsForUs": "",
        "dobHomeAddress": ""
      },
      "creativeAndMontageAds": {
        "status": "PENDING",
        "videoReviews": "",
        "googleDriveEmail": "",
        "googleDriveLink": "",
        "pocForProductDeliveries": "",
        "products": "",
        "creatorPersona": "",
        "shootNotes": "",
        "desiredCta": "",
        "promoCode": "",
        "uspOfProductsSent": "",
        "visualReferences": "",
        "others": ""
      }
    },
    "accessSharing": {
      "status": "PENDING",
      "fbAccess": {
        "page": {
          "status": "VERIFYING",
          "accountId": ""
        },
        "adAccount": {
          "status": "VERIFYING",
          "accountId": ""
        },
        "catalog": {
          "status": "VERIFYING",
          "accountId": ""
        },
        "pixel": {
          "status": "VERIFYING",
          "accountId": ""
        }
      },
      "googleAccess": {
        "googleAnalytics": {
          "status": "VERIFYING",
          "accountId": ""
        },
        "googleAdAccount": {
          "status": "VERIFYING",
          "accountId": ""
        },
        "googleMerchant": {
          "status": "VERIFYING",
          "accountId": ""
        },
        "googleAndShopifyLinked": {
          "status": "VERIFYING",
          "accountId": ""
        }
      },
      "tiktokAccess": {
        "tiktokAdsManager": {
          "status": "VERIFYING",
          "accountId": ""
        },
        "tiktokPixel": {
          "status": "VERIFYING",
          "accountId": ""
        }
      },
      "shopifyAccess": {
        "fbSalesChannel": {
          "status": "VERIFYING",
          "accountId": ""
        },
        "tiktokSalesChannel": {
          "status": "VERIFYING",
          "accountId": ""
        }
      }
    },
    "auditInfo": {
      "status": "PENDING",
      "accountAuditInfo": {
        "status": "PENDING",
        "currentMonthlyRevenue": "",
        "spend": "",
        "historicalAvgPlatformRoas": "",
        "historicalAvgBlendedRoas": ""
      }
    },
    "accountAnalysis": {
      "status": "PENDING",
      "google": "",
      "tiktok": "",
      "website": "",
      "creatives": "",
      "competitor": ""
    },
    "fbAdsStrategyInfo": {
      "isFirstTimeStrategyCreation": false,
      "status": "PENDING",
      "topOfTheFunnelLookalike": {
        "status": "PENDING",
        "budget": 0,
        "adsets": []
      },
      "topOfTheFunnelInterest": {
        "status": "PENDING",
        "budget": 0,
        "adsets": []
      },
      "middleOfTheFunnel": {
        "status": "PENDING",
        "budget": 0,
        "adsets": []
      },
      "bottomOfTheFunnel": {
        "status": "PENDING",
        "budget": 0,
        "adsets": []
      }
    },
    "strategyIterations": [],
    "fbAdsAccountId": "",
    "proposalStatus": "PENDING"
  }
