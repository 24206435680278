import { useEffect, useState } from "react"
import CommonBrandPage from "../CommonBrandPage"
import { generatePath } from "react-router-dom";
import bpAmRoutePaths from "../../../pages/(bp-am)/constants/bpAmRoutePaths";

export default function BPBrandPage() {
  const [formId, setFormId] = useState(null)
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (formId)
    setUrl(generatePath(bpAmRoutePaths.salesQuestionnaire.fullbase, {
        formId
    }))
  }, [formId])


  return <CommonBrandPage formId={formId} setFormId={setFormId} url={url} />
}