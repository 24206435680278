import { Close } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import React from "react";
import {
  StyledButtonsWrapper,
  StyledGoBackButton,
  StyledImageContainer,
  StyledProceedButton,
  StyledWarningDescriptionText,
  StyledWarningHeaderDescriptionWrapper,
  StyledWarningHeaderText,
} from "./styled";
import { warningInfoIcon } from "../../../../assets/icons/admin/admin";

const style = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 444,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3.5,
  height: "auto",
  borderRadius: "20px",
  outline: "none",
};
const WarningModal = ({
  warningModal,
  setWarningModal,
  onProceedCallback,
  onGoBackCallback,
  message,
}) => {
  return (
    <Modal
      open={warningModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Close
          onClick={() => {
            setWarningModal(false);
          }}
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            cursor: "pointer",
          }}
        />
        <div>
          <StyledImageContainer>
            <img src={warningInfoIcon} alt="" />
          </StyledImageContainer>
          <StyledWarningHeaderDescriptionWrapper>
            <StyledWarningHeaderText>Warning!</StyledWarningHeaderText>
            <StyledWarningDescriptionText>
              {message}
            </StyledWarningDescriptionText>
          </StyledWarningHeaderDescriptionWrapper>
          <StyledButtonsWrapper>
            <StyledGoBackButton onClick={() => onGoBackCallback()}>
              Go Back
            </StyledGoBackButton>
            <StyledProceedButton onClick={() => onProceedCallback()}>
              Proceed
            </StyledProceedButton>
          </StyledButtonsWrapper>
        </div>
      </Box>
    </Modal>
  );
};

export default WarningModal;
