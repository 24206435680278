import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { api } from '../api';

const getFbCreativeResourcesQueryKey = (formId) => ["creative-resources/fb-ads/:formId", formId];
export const useGetFBCreativeResources = (formId, options = {}) => {
  return useQuery(
    getFbCreativeResourcesQueryKey(formId),
    async () => {
      const response = await api.get("/creative-resource/fb-ads/" + formId);
      return response.data;
    },
    options,
  )
}

export function usePutUpdateFbCreativeResources({ formId }, options = {}) {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ adId, response, responseId }) => {
      const apiResponse = await api.put(`/creative-resource/fb-ads/${formId}/${adId}/${responseId}`, response);
      return apiResponse.data;
    },
    {
      ...options,
      onSuccess(data, variables, ctx) {
        const { adId, responseId } = variables;
        queryClient.setQueryData(
          getFbCreativeResourcesQueryKey(formId),
          (prev) => ({
            ...prev,
            ads: prev.ads.map(ad => ad.id === adId ? {
              ...ad,
              responses: ad.responses.map(response => response.id === responseId ? ({
                ...response,
                ...variables.response,
              }) : response)
            } : ad)
          })
        )
        options.onSuccess?.(data, variables, ctx);
      }
    },
  );
};