import { TextField, Typography, Button, Checkbox } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormReducer } from '../../../api/accountManager/forms/slice';
import {
  updateForAnotherAccount,
  updateReviewPointsReducer,
} from '../../../api/accountManager/googleAdsCustomer/slice';
import {
  pencil as pencilIcon,
  trash as trashIcon,
} from '../../../assets/icons/common/common';
import { add_field_icon as addFieldIcon } from '../../../assets/icons/icon';
import {
  BACKEND_STATUS,
  isPlatformSelectedHDM,
} from '../../../utils/accountManagerUtil';
import PrimaryButton from '../../AccountManager/ButtonPrimary/Buttons';
import './review.css';
// import { urlValidator } from '../../ClientDashboard/Questions/Validators/urlValidator';
const CommonReviewPoints = ({
  form,
  onClick,
  onChangeAccountClick,
  displayAdditionalInfo = true,
}) => {
  const dispatch = useDispatch();
  const [reviewPoints, setReviewPoints] = useState([]);
  const [addedText, setAddedText] = useState('');
  const insights = useSelector((store) => store.googleCustomers.insights);
  const forAnotherAccount = useSelector(
    (store) => store.googleCustomers.forAnotherAccount
  );
  const [editIdx, setEditIdx] = useState(null);
  const [videoLinkText, setVideoLinkText] = useState(
    form?.googleAuditData?.videoLink ? form?.googleAuditData?.videoLink : ''
  );
  const [checkedAuditPointer, setCheckedAuditPointer] = useState(
    form?.googleAuditData?.showAuditPointer
      ? form?.googleAuditData?.showAuditPointer
      : false
  );
  const [checkedVideoLink, setCheckedVideoLink] = useState(
    form?.googleAuditData?.showAddVideo
      ? form?.googleAuditData?.showAddVideo
      : false
  );
  const [error, setError] = useState(false);
  // useEffect(() => {
  //   console.log('Audit Pointers selected : ', checkedAuditPointer);
  //   console.log('Checked Video Link : ', checkedVideoLink);
  //   console.log('Video link text : ', videoLinkText);
  // }, [checkedAuditPointer, checkedVideoLink, videoLinkText]);
  useEffect(() => {
    if (insights.length) {
      setReviewPoints(insights);
    }
  }, [insights]);

  useEffect(() => {
    if (forAnotherAccount) {
      onChangeAccountClick?.();
    }
  }, [forAnotherAccount]);
  const onClickHandler = () => {
    if (form?.googleAuditData?.status !== BACKEND_STATUS.COMPLETED) {
      if (checkedVideoLink) {
        if (videoLinkText) {
          const data = {
            googleAuditData: {
              showAuditPointer: checkedAuditPointer,
              showAddVideo: checkedVideoLink,
              videoLink: videoLinkText,
              isSkipped: false,
              status: videoLinkText
                ? BACKEND_STATUS.COMPLETED
                : BACKEND_STATUS.ONGOING,
            },
          };
          dispatch(updateFormReducer(data));
          onClick?.();
        } else {
          setError(true);
        }
      } else {
        const data = {
          googleAuditData: {
            showAuditPointer: checkedAuditPointer,
            isSkipped: false,
            status: BACKEND_STATUS.COMPLETED
          },
        };
        dispatch(updateFormReducer(data));
        onClick?.();
      }
      // dispatch(updateFormReducer(data));
      // if (isPlatformSelectedHDM(form, 'facebook') === -1) {
      //   data.accountAnalysis.status = BACKEND_STATUS.ONGOING;
      // }
    } else {
      if (checkedVideoLink) {
        if (!!videoLinkText) {
          onClick?.();
          dispatch(
            updateFormReducer({
              googleAuditData: {
                showAuditPointer: checkedAuditPointer,
                showAddVideo: checkedVideoLink,
                videoLink: videoLinkText,
              },
            })
          );
        } else {
          setError(true);
        }
      } else if (checkedAuditPointer) {
        onClick?.();
        dispatch(
          updateFormReducer({
            googleAuditData: {
              showAuditPointer: checkedAuditPointer,
              showAddVideo: checkedVideoLink,
            },
          })
        );
      } else {
        onClick?.();
        dispatch(
          updateFormReducer({
            googleAuditData: {
              showAuditPointer: checkedAuditPointer,
              showAddVideo: checkedVideoLink,
              videoLink: videoLinkText,
            },
          })
        );
      }
    }
  };
  const checkedIcon = (
    <svg
      width='20'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='19' height='19' rx='3.5' fill='#0869FB' />
      <path
        d='M6.92188 10.0332L9.78413 12.9L15.6811 7'
        stroke='white'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
  const uncheckedIcon = (
    <>
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          x='0.5'
          y='0.5'
          width='19'
          height='19'
          rx='3.5'
          fill='white'
          stroke='#ABACB4'
        />
      </svg>
    </>
  );
  // useEffect(() => {
  //   return () => {
  //     dispatch(updateAppData({
  //       selectedCustomerId: null,
  //       selectedLoginCustomerId: null,
  //       auditReport: {},
  //       insights: [],
  //       gotInsights: false,
  //       haveInsights: true,
  //     }))
  //   }
  // }, [])

  const addClickHandler = () => {
    const reviewPointsBuffer = [...reviewPoints, addedText];
    setReviewPoints(reviewPointsBuffer);
    setAddedText('');
    dispatch(updateReviewPointsReducer({ reviewPoints: reviewPointsBuffer }));
  };
  const editClickHandler = (idx) => {
    if (editIdx) {
      setEditIdx(null);
      dispatch(updateReviewPointsReducer({ reviewPoints: reviewPoints }));
    } else {
      setEditIdx(idx);
    }
  };

  const deleteClickHandler = (idx) => {
    let reviewPointsBuffer = [...reviewPoints];
    reviewPointsBuffer.splice(idx, 1);
    setReviewPoints(reviewPointsBuffer);
    dispatch(updateReviewPointsReducer({ reviewPoints: reviewPointsBuffer }));
  };

  const changeAccountHandler = () => {
    onChangeAccountClick?.();
  };

  return (
    <div
      style={{
        padding: '24px',
      }}
      className='right_main_section'
    >
      <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 20,
          color: '#242731',
          marginBottom: '1rem',
        }}
      >
        Review Points
      </Typography>

      {reviewPoints.map((reviewPoint, idx) => {
        return (
          <div
            key={idx}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '3rem',
              marginBottom: '1.5rem',
              width: '100%',
            }}
          >
            {editIdx === idx ? (
              <TextField
                variant='outlined'
                size='small'
                style={{ width: '70%' }}
                inputProps={{ style: { height: 16, fontSize: 12 } }}
                value={reviewPoint}
                onChange={(e) => {
                  const reviewPointsBuffer = [...reviewPoints];
                  reviewPointsBuffer.splice(idx, 1, e.target.value);
                  setReviewPoints(reviewPointsBuffer);
                }}
              />
            ) : (
              <Typography
                style={{
                  background: '#FAFAFA',
                  borderRadius: 8,
                  padding: '0.5rem',
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12,
                  width: '70%',
                  lineHeight: '20px',
                }}
              >
                {reviewPoint}
              </Typography>
            )}
            <div style={{ display: 'flex', gap: '1rem' }}>
              <div
                style={{
                  width: 32,
                  height: 32,
                  background: '#F8F8F8',
                  borderRadius: 6,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={pencilIcon}
                  alt=''
                  onClick={() => editClickHandler(idx)}
                />
              </div>
              <div
                style={{
                  width: 32,
                  height: 32,
                  background: '#F8F8F8',
                  borderRadius: 6,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  src={trashIcon}
                  alt=''
                  onClick={() => deleteClickHandler(idx)}
                />
              </div>
            </div>
          </div>
        );
      })}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '3rem',
          marginBottom: '1rem',
        }}
      >
        <TextField
          variant='outlined'
          size='small'
          style={{ width: '70%' }}
          inputProps={{ style: { height: 16, fontSize: 12 } }}
          placeholder='Add new field here'
          value={addedText}
          onChange={(e) => setAddedText(e.target.value)}
        />
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.45rem',
              cursor: 'pointer',
            }}
            onClick={addClickHandler}
          >
            <img src={addFieldIcon} alt='' />
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 14,
                color: '#0869FB',
              }}
            >
              Add Point
            </Typography>
          </div>
        </div>
      </div>
      <Button
        // disabled={disabled}
        onClick={changeAccountHandler}
        style={{
          textDecoration: 'none',
          width: '183px',
          height: '32px',
          borderRadius: '8px',
          border: '1.5px solid #0869FB',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#0869FB',
          textTransform: 'capitalize',
          background: '#ffffff',
          marginBottom: 48,
          // pointerEvents: disabled && 'none',
          // opacity: disabled ? '0.4' : '1.0',
          fontWeight: 500,
        }}
      >
        Change Audit Account
      </Button>
      {displayAdditionalInfo && (
        <div
          style={{
            width: '84%',
            // height: 100,
            background: '#FFFFFF',
            border: '1px solid #E7E8F2',
            boxShadow: '0px 20px 40px rgba(20, 20, 43, 0.04)',
            borderRadius: 12,
            padding: 24,
          }}
        >
          <div className='audit-pointers-check-container'>
            <Checkbox
              checked={checkedAuditPointer}
              onChange={(e) => setCheckedAuditPointer(e.target.checked)}
              checkedIcon={checkedIcon}
              icon={uncheckedIcon}
            />
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '20px',
                color: '#2E3044',
              }}
            >
              Show audit pointers in the proposal.
            </Typography>
          </div>
          <div className='video-link-check-container'>
            <Checkbox
              checked={checkedVideoLink}
              onChange={(e) => setCheckedVideoLink(e.target.checked)}
              checkedIcon={checkedIcon}
              icon={uncheckedIcon}
            />
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                lineHeight: '20px',
                color: '#2E3044',
              }}
            >
              Add a video explaining the audit pointers in the proposal:
            </Typography>
          </div>
          <div className='video-link'>
            <TextField
              placeholder='Add link here'
              variant='standard'
              disabled={!checkedVideoLink}
              value={videoLinkText}
              onChange={(e) => setVideoLinkText(e.target.value)}
              inputProps={{
                style: {
                  color: setVideoLinkText ? '#170F49' : '#ABACB4',
                  width: 357,
                  height: 16,
                  background: '#FFFFFF',
                  padding: '10px 16px 10px 16px',
                  border: `1px solid ${error ? 'red' : '#EAEAEC'}`,
                  borderRadius: 6,
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 13,
                  outline: 'none',
                },
              }}
            />
          </div>
        </div>
      )}
      <div className='preview_footer_row'>
        <div className='footer_down'>
          <PrimaryButton title='Save & Proceed' onClick={onClickHandler} />
        </div>
      </div>
    </div>
  );
};

export default CommonReviewPoints;
