import React, { useState } from 'react';
import { Typography, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';
const AdLevelHeadlineInputChild = (props) => {
  const [value, setValue] = useState("")
  return (
    <div className='ad-group-results'>
      <div className='ad-group-detail'>
        <div style={{ paddingLeft: '0.5rem' }}>
          <TextField
            className='ad-group-result__title'
            variant='standard'
            sx={{
              '& .css-6bsk74-MuiInputBase-root-MuiInput-root:before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              },
              // border: '1px solid green',
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if(e.key === "Enter" && value){
                props.addHeadline(props.campaignType, props.adSetName, {value: value, category: 'Custom'});
                props.deleteHeadline(props.id);
              }
            }}
            onBlur={(e) => {
              if(!value) return;
              props.addHeadline(props.campaignType, props.adSetName, {value: value, category: 'Custom'});
              props.deleteHeadline(props.id);
            }}
            inputProps={{
              style: {
                // borderBottom: '1px solid #E8E8E8',
                // width: '95%',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 14,
                color: '#444444',
                padding: '0.5rem 0',
              },
            }}
            color='info'
          />
        </div>

        <div className='ad-level-description-container'>
          <Typography className='ad-group-result__description'>
            Custom
          </Typography>
          <Typography className='ad-group-result__description'>
            {value.length}/{props.totalCharacter}
          </Typography>
        </div>
      </div>
      {!props.idDisabled && (
        <Close
          style={{ width: 12, height: 12 }}
          onClick={() => props.deleteHeadline(props.id)}
        />
      )}
    </div>
  );
};

export default AdLevelHeadlineInputChild;
