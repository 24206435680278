export const Languages = [
  {
    "language": "Arabic",
    "languageConstant": 1019
  },
  {
    "language": "Bengali",
    "languageConstant": 1056
  },
  {
    "language": "Bulgarian",
    "languageConstant": 1020
  },
  {
    "language": "Catalan",
    "languageConstant": 1038
  },
  {
    "language": "Chinese (simplified)",
    "languageConstant": 1017
  },
  {
    "language": "Chinese (traditional)",
    "languageConstant": 1018
  },
  {
    "language": "Croatian",
    "languageConstant": 1039
  },
  {
    "language": "Czech",
    "languageConstant": 1021
  },
  {
    "language": "Danish",
    "languageConstant": 1009
  },
  {
    "language": "Dutch",
    "languageConstant": 1010
  },
  {
    "language": "English",
    "languageConstant": 1000
  },
  {
    "language": "Estonian",
    "languageConstant": 1043
  },
  {
    "language": "Filipino",
    "languageConstant": 1042
  },
  {
    "language": "Finnish",
    "languageConstant": 1011
  },
  {
    "language": "French",
    "languageConstant": 1002
  },
  {
    "language": "German",
    "languageConstant": 1001
  },
  {
    "language": "Greek",
    "languageConstant": 1022
  },
  {
    "language": "Gujarati",
    "languageConstant": 1072
  },
  {
    "language": "Hebrew",
    "languageConstant": 1027
  },
  {
    "language": "Hindi",
    "languageConstant": 1023
  },
  {
    "language": "Hungarian",
    "languageConstant": 1024
  },
  {
    "language": "Icelandic",
    "languageConstant": 1026
  },
  {
    "language": "Indonesian",
    "languageConstant": 1025
  },
  {
    "language": "Italian",
    "languageConstant": 1004
  },
  {
    "language": "Japanese",
    "languageConstant": 1005
  },
  {
    "language": "Kannada",
    "languageConstant": 1086
  },
  {
    "language": "Korean",
    "languageConstant": 1012
  },
  {
    "language": "Latvian",
    "languageConstant": 1028
  },
  {
    "language": "Lithuanian",
    "languageConstant": 1029
  },
  {
    "language": "Malay",
    "languageConstant": 1102
  },
  {
    "language": "Malayalam",
    "languageConstant": 1098
  },
  {
    "language": "Marathi",
    "languageConstant": 1101
  },
  {
    "language": "Norwegian",
    "languageConstant": 1013
  },
  {
    "language": "Persian",
    "languageConstant": 1064
  },
  {
    "language": "Polish",
    "languageConstant": 1030
  },
  {
    "language": "Portuguese",
    "languageConstant": 1014
  },
  {
    "language": "Punjabi",
    "languageConstant": 1110
  },
  {
    "language": "Romanian",
    "languageConstant": 1032
  },
  {
    "language": "Russian",
    "languageConstant": 1031
  },
  {
    "language": "Serbian",
    "languageConstant": 1035
  },
  {
    "language": "Slovak",
    "languageConstant": 1033
  },
  {
    "language": "Slovenian",
    "languageConstant": 1034
  },
  {
    "language": "Spanish",
    "languageConstant": 1003
  },
  {
    "language": "Swedish",
    "languageConstant": 1015
  },
  {
    "language": "Tamil",
    "languageConstant": 1130
  },
  {
    "language": "Telugu",
    "languageConstant": 1131
  },
  {
    "language": "Thai",
    "languageConstant": 1044
  },
  {
    "language": "Turkish",
    "languageConstant": 1037
  },
  {
    "language": "Ukrainian",
    "languageConstant": 1036
  },
  {
    "language": "Urdu",
    "languageConstant": 1041
  },
  {
    "language": "Vietnamese",
    "languageConstant": 1040
  }
]