import { v4 as uuid } from 'uuid';
import { ChannelType } from "./channel";
import { Chart, Metric } from "./chart";
import CustomReportIcon from "../../assets/images/Analytics/custom_report.svg";

export class Tab {
  constructor(name, position, grids) {
    this.name = name;
    this.path = Report.getTabPathFromTitle(name);
    this.position = position ?? 0;
    this.grids = grids;
    this.id = uuid();
  }

  toJSON() {
    return {
      id: this.id,
      name: this.name,
      path: this.path,
      position: this.position,
    }
  }

  static fromJSON(json) {
    const tab = new Tab(json.name, json.position);
    tab.path = json.path;
    tab.id = json.id;
    return tab;
  }

  static new(...params) {
    const tab = new Tab(...params);
    const grid = new Grid(tab.id);
    grid.charts = Array.from(Array(4)).map((_, index) => {
      const chart = Chart.new();
      chart.gridPosition = { ...chart.gridPosition, x: parseInt(index / 2) * 6, y: index % 2 * 2 };
      chart.gridId = grid.id;
      return chart;
    });
    tab.grids = [grid];
    return tab;
  }

}

export class Grid {
  constructor(tabId, title, subtitle, charts,
    gridStyle = { titleStyle: { font: 'Inter', fontSize: '16', color: '#000000', fontFormat: ['bold'] }, subTitleStyle: { font: 'Inter', fontSize: '16', color: '#00000088', fontFormat: ['bold'] } },
  ) {
    this.tabId = tabId;
    this.title = title || "Overall";
    this.subtitle = subtitle;
    this.charts = charts;
    this.gridStyle = gridStyle;
    this.id = uuid();
  }

  toJSON() {
    return {
      id: this.id,
      tabId: this.tabId,
      title: this.title,
      subtitle: this.subtitle,
      gridStyle: this.gridStyle
    }
  }

  static fromJSON(json) {
    const grid = new Grid(json.tabId, json.title, json.subtitle, [], json.gridStyle);
    grid.id = json.id;
    return grid;
  }

  static new(...params) {
    const grid = new Grid(...params);
    grid.charts = Array.from(Array(4)).map((_, index) => {
      const chart = Chart.new();
      chart.gridPosition = { ...chart.gridPosition, x: parseInt(index / 2) * 6, y: index % 2 * 2 };
      chart.gridId = grid.id;
      return chart;
    });
    return grid;
  }

}

export class Report {
  constructor(report_type, report_name, tabs, icon = CustomReportIcon, updatedAt = new Date()) {
    this.report_type = report_type;
    this.report_name = report_name;
    this.tabs = tabs;
    this.report_icon = icon;
    this.updatedAt = updatedAt;
  }

  toJSON() {
    return {
      report_name: this.report_name,
      report_type: this.report_type,
      report_icon: this.report_icon,
      tabs: this.tabs.map(tab => tab.toJSON()),
      grids: this.tabs.reduce((prev, curr) => prev.concat(curr.grids), []).map(grid => grid.toJSON()),
      charts: this.tabs.reduce((prev, curr) => prev.concat(curr.grids), []).reduce((prev, curr) => prev.concat(curr.charts), []).map(chart => chart.toJSON()),
    }
  }

  static duplicate(json) {
    if (!json) { return null; }
    const report = new Report(
      json.report_type,
      json.report_name,
      json.tabs.sort((prev, curr) => prev.position - curr.position).map(tabJSON => {
        const oldTabId = tabJSON.id;
        const tab = new Tab(tabJSON.name, tabJSON.position, tabJSON.grids);
        tab.grids = json.grids.filter(grid => grid.tabId === oldTabId).map(gridJSON => {
          const oldGridId = gridJSON.id;
          const grid = new Grid(tab.id, gridJSON.title, gridJSON.subtitle, gridJSON.charts, gridJSON.gridStyle);
          grid.charts = json.charts.filter(chart => chart.gridId === oldGridId).map(chart => Chart.fromJSON({ ...chart, gridId: grid.id, id: uuid() }))
          return grid;
        });
        return tab;
      }),
      json.report_icon,
      json.updated_at
    );
    report.id = json._id;
    return report;
  }

  static fromJSON(json) {
    if (!json) { return null; }
    const report = new Report(
      json.report_type,
      json.report_name,
      json.tabs.sort((prev, curr) => prev.position - curr.position).map(tabJSON => {
        const tab = Tab.fromJSON(tabJSON);
        tab.grids = json.grids.filter(grid => grid.tabId === tab.id).map(gridJSON => {
          const grid = Grid.fromJSON(gridJSON);
          grid.charts = json.charts.filter(chart => chart.gridId === grid.id).map(chart => Chart.fromJSON(chart))
          return grid;
        });
        return tab;
      }),
      json.report_icon,
      json.updated_at
    );
    report.id = json._id;
    return report;
  }

  static new(name, icon, tabName, gridName) {
    const tab = new Tab(tabName ?? "Overall");
    const grid = new Grid(tab.id, gridName);
    grid.charts = Array.from(Array(4)).map((_, index) => {
      const chart = Chart.new();
      chart.gridPosition = { ...chart.gridPosition, x: parseInt(index / 2) * 6, y: index % 2 * 2 };
      chart.gridId = grid.id;
      return chart;
    });
    tab.grids = [grid];
    return new Report(
      null,
      name ?? "New",
      [tab],
      icon
    )
  }

  static getTabPathFromTitle(title) {
    return title.toLowerCase().replaceAll(" ", "-");
  }

}

const allCharts = [
  new Chart(ChannelType.GoogleAds, "Conversion Over time", "LINE", { id: "customer", name: "Customer" },
    { x: 0, y: 0, w: 6, h: 2, static: false },
    [new Metric({ id: "metrics.conversions", name: "Conversions" })],
  ),
  new Chart(ChannelType.FacebookAds, "Conversion Funnel", "FUNNEL", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [
      new Metric({ id: "impressions", name: "Impressions" }),
      new Metric({ id: "reach", name: "Reach" }),
      new Metric({ id: "clicks", name: "Clicks (all)" }),
    ],
  ),
  new Chart(ChannelType.GoogleAnalytics, "Conversions", "BAR", null,
    { x: 6, y: 0, w: 6, h: 2, static: false },
    [new Metric({ id: "ga:goalCompletionsAll", name: "Goal Completions" })],
  ),
  new Chart(ChannelType.ShopifyAds, "Products", "TABLE", { id: "sales", name: "Sales" },
    { x: 0, y: 6, w: 6, h: 2, static: false },
    [
      new Metric({ id: "orders", name: "Orders" }),
      new Metric({ id: "total_sales", name: "Total Sales" }),
      new Metric({ id: "gross_sales", name: "Gross Sales" }),
      new Metric({ id: "ordered_item_quantity", name: "Ordered Item Quantity" }),
      new Metric({ id: "returned_item_quantity", name: "Returned Item Quantity" }),
    ],
    null,
    [{ id: "product_title", name: "Product Title" }, { id: "variant_title", name: "Variant Title" }]
  ),
]

const allGrid = [
  new Grid(null, null, null, allCharts),
];

const tabs = [
  new Tab("Overall", 0, allGrid),
]

tabs.forEach(tab => tab.grids.forEach(grid => {
  grid.tabId = tab.id;
  grid.charts.forEach(chart => chart.gridId = grid.id);
}));

const allReport = new Report("template", "All Sources Report", tabs);
