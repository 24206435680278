import { FormControl, MenuItem, NativeSelect, Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import { updateHDMClientDetailsReducer } from '../../../api/HDMClient/action';
import { mappedReduxType } from '../../../api/HDMClient/enum';
import { updatedSharedAssetsLdAm } from '../../../api/accountManager/forms/slice';
import { dbStatus } from '../../ClientDashboard/HomePage/HomePage';

const StatusDropdown = (props) => {
  const dispatch = useDispatch();
  const readAccess = (props, e) => {
    if (props.accessType === 'LD') {
      dispatch(
        updatedSharedAssetsLdAm({
          infoType: props.infoType,
          key: props.objKey,
          key2: props.objKey2,
          key3: props.objKey3,
          value: e.target.value,
        })
      );
    } else {
      dispatch(
        updateHDMClientDetailsReducer(mappedReduxType[props.infoType], {
          infoType: props.infoType,
          key: props.objKey,
          key2: props.objKey2,
          key3: props.objKey3,
          value: e.target.value,
        })
      );
      dispatch({
        type: 'UPDATE_ACCESS_SHARING_HDM_AM',
        payload: {
          infoType: props.infoType,
          key: props.objKey,
          key2: props.objKey2,
          key3: props.objKey3,
          value: e.target.value,
        },
      });
    }
  };
  return (
    <>
      {props.type === 'COMPLETE_ACCESS' && props.accessType !== 'HDM' ? (
        <Select
          defaultValue={props.value}
          onChange={(e) => {
            readAccess(props, e);
          }}
          className='shared-asset-menu'
          sx={{
            width: 116,
            height: 30,
            '&.MuiSelect-select': {
              padding: 0,
            },
          }}
        >
          <MenuItem value='VERIFYING_COMPLETE_ACCESS'>
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11,
                // display: 'flex',
                // alignItems: 'flex-end',
                color: '#F8862D',
              }}
            >
              Verifying
            </span>
          </MenuItem>
          <MenuItem value='COMPLETED_ACCESS_NOT_SHARED'>
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11,
                // display: 'flex',
                // alignItems: 'flex-end',
                color: 'red',
              }}
            >
              Read Access
            </span>
          </MenuItem>
          <MenuItem value='COMPLETE_ACCESS'>
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11,
                // display: 'flex',
                // alignItems: 'flex-end',
                color: '#12C8B2',
              }}
            >
              Complete Access
            </span>
          </MenuItem>
        </Select>
      ) : props.accessType === 'HDM' ? (
        <Select
          defaultValue={props.value}
          onChange={(e) => {
            readAccess(props, e);
          }}
          className='shared-asset-menu'
          sx={{
            width: 116,
            height: 30,
            '&.MuiSelect-select': {
              padding: 0,
            },
          }}
        >
          <MenuItem value='VERIFYING'>
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11,
                // display: 'flex',
                // alignItems: 'flex-end',
                color: '#F8862D',
              }}
            >
              Verifying
            </span>
          </MenuItem>
          <MenuItem value='ACCESS_REVOKED'>
            {' '}
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11,
                // display: 'flex',
                // alignItems: 'flex-end',
                color: 'red',
              }}
            >
              {' '}
              Not Shared
            </span>
          </MenuItem>
          {/* <MenuItem value="READ_ACCESS">
            <span
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 14,
                display: "flex",
                alignItems: "flex-end",
                color: "#12C8B2"
              }}
            >
              Read Access
            </span>
          </MenuItem> */}
          <MenuItem value='COMPLETE_ACCESS'>
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11,
                // display: 'flex',
                // alignItems: 'flex-end',
                color: '#12C8B2',
              }}
            >
              Complete Access
            </span>
          </MenuItem>
        </Select>
      ) : (
        <Select
          defaultValue={props.value}
          onChange={(e) => {
            readAccess(props, e);
          }}
          className='shared-asset-menu'
          sx={{
            width: 116,
            height: 30,
            '&.MuiSelect-select': {
              padding: 0,
            },
          }}
          disabled={[
            dbStatus.COMPLETE_ACCESS,
            dbStatus.VERIFYING_COMPLETE_ACCESS,
            dbStatus.COMPLETED_ACCESS_NOT_SHARED,
          ].includes(props.value)}
        >
          <MenuItem value='VERIFYING'>
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11,
                // display: 'flex',
                // alignItems: 'flex-end',
                color: '#F8862D',
              }}
            >
              Verifying
            </span>
          </MenuItem>
          <MenuItem value='ACCESS_REVOKED'>
            {' '}
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11,
                // display: 'flex',
                // alignItems: 'flex-end',
                color: 'red',
              }}
            >
              {' '}
              Not Shared
            </span>
          </MenuItem>
          <MenuItem value='READ_ACCESS'>
            <span
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11,
                // display: 'flex',
                // alignItems: 'flex-end',
                color: '#12C8B2',
              }}
            >
              Read Access
            </span>
          </MenuItem>
        </Select>
      )}
    </>
  );
};

export default StatusDropdown;
