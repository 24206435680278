import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "../api";
import { BACKEND_STATUS } from "../utils/accountManagerUtil";
import { getLocalStorage } from "../api/localStorage/localStorage";

const getFbPixelEventsQuery = (pixelId) => [
  "fb-pixel-events/:pixelId",
  pixelId,
];

const getFbPagesQuery = ["fb-pages"];

const getFbPixelsQuery = (fbAdId) => ["fb-pixels/:fbAdId", fbAdId];

const getLocationQuery = (loc) => ["fb-location/:loc", loc];

export const useGetFbPixelEvents = (pixelId = null, options = {}) => {
  const formId = getLocalStorage("formId");
  return useQuery(
    getFbPixelEventsQuery(pixelId),
    async () => {
      if (!pixelId) return;
      const response = await api.get(
        `/facebook-ads/ad-pixels/${pixelId}/events/${formId}`
      );
      return response.data;
    },
    options
  );
};

export const useGetFbPages = (options = {}) => {
  const formId = getLocalStorage("formId");
  return useQuery(
    getFbPagesQuery,
    async () => {
      const response = await api.get(`/facebook-ads/pages/${formId}`);
      return response.data;
    },
    options
  );
};

export const useGetFbPixels = (fbAdsAccountId = null, options = {}) => {
  const formId = getLocalStorage("formId");
  return useQuery(
    getFbPixelsQuery(fbAdsAccountId),
    async () => {
      if (!fbAdsAccountId) return;
      const response = await api.get(`/facebook-ads/ad-pixels/${formId}`);
      return response.data;
    },
    options
  );
};

export const useGetCampaignLocation = (loc = null, options = {}) => {
  const formId = getLocalStorage("formId");
  return useQuery(
    getLocationQuery(loc),
    async () => {
      if (!loc) return;
      const response = await api.get(
        `/facebook-ads/locations/${formId}?location_q=${loc}`
      );
      return response.data;
    },
    options
  );
};
