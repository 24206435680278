import React from "react";
import { Funnel } from "@ant-design/plots";
import { ChartTable, UnitDataFormatter, GetColor, TooltipContentType2 } from "./ChartUtils";
import {displayCurrency} from "./ChartUtils";

export default function FunnelChart({ data, chartStyle,type }) {
  const mappedData = data.leftMetrics.map(metric => ({
    name: metric.name,
    value: data.data[0][metric.name],
    unit: metric.unit,
    currency:metric.currency && displayCurrency(type) === metric.unit.toUpperCase() ? metric.currency : null
  }));
  return (
    <div style={{ display: "flex", height: "90%", justifyContent: "center", alignItems: "center" }}>
      <div style={{ width: "50%" }}>
        {ChartTable(mappedData.map((v, i) => ({ name: v.name, value: UnitDataFormatter(v.value, v.unit), color: GetColor(i, chartStyle.palette),currency:v.currency})))}
      </div>
      <div style={{ height: "75%", display: "flex", justifyContent: "center", alignItems: "center", width: '50%' }}>
        <Funnel
          data={mappedData}
          xField="name"
          yField="value"
          dynamicHeight={true}
          legend={false}
          color={field => GetColor(data.leftMetrics.findIndex(m => m.name === field.name), chartStyle.palette)}
          label={false}
          conversionTag={false}
          tooltip={{
            customContent: (title, items) => TooltipContentType2(items)
          }}
        />
      </div>
    </div>
  );
}
