import { useDispatch, useSelector } from "react-redux";
import CommonFacebookAccounts from "./CommonFacebookAccounts";
import { useCallback } from "react";
import {
  getAuditFacebook,
} from "../../../api/HDMAM/audit/saga";
import { generatePath, useNavigate, useParams } from "react-router";
import hdmAmRoutePaths from "../../../pages/hdm-am/constants/hdmAmRoutePaths";
import { BACKEND_STATUS } from "../../../utils/accountManagerUtil";
import { updateFormReducer } from "../../../api/accountManager/forms/slice";

export default function HDMFacebookAccounts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();

  const fbAccountId = useSelector((state) => state?.hdmFormAm?.fbAdsAccountId);

  // return a promise
  const onGenerateReport = useCallback(
    (options) => {
      return getAuditFacebook(options, "HDM").then(async (res) => {
        dispatch({ type: "GET_AUDIT_DETAILS_HDM", payload: res });
        dispatch(updateFormReducer({
          fbAdsAccountId: options.accountId,
          fbAdsAccountName: options.selectedCustomerName,
          auditInfo: {
            status: BACKEND_STATUS.ONGOING
          }
        }))
        navigate(
          generatePath(
            `${hdmAmRoutePaths.auditForFacebook.fullbase}/${hdmAmRoutePaths.auditForFacebook.sub.account}`,
            { formId }
          )
        );
      });
    },
    [dispatch, navigate]
  );

  return (
    <CommonFacebookAccounts
      fbAccountId={fbAccountId}
      onGenerateReport={onGenerateReport}
    />
  );
}
