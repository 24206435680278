import googleAdsLogo from "../../assets/images/logos_google-ads.svg";
import facebookAdsLogo from "../../assets/images/logos_facebook.svg";
import { google_analytics as googleAnalytics } from "../../assets/icons/icon";

export const ChannelType = {
  GoogleAds: "google-ads",
  FacebookAds: "facebook-ads",
};

export const AdsList = [
  {
    type: ChannelType.GoogleAds,
    name: "Google Ads",
    logo: googleAdsLogo,
    text: "Connect and source data from Google Ads",
    hasArrived: true,
  },
  {
    type: ChannelType.FacebookAds,
    name: "Facebook Ads",
    logo: facebookAdsLogo,
    text: "Connect and source data from Facebook Ads",
    hasArrived: true,
  },
];
