import { zodResolver } from "@hookform/resolvers/zod";
import { Button, TextField, Typography } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import {
  bidding_icon as biddingIcon,
  budget_icon as budgetIcon,
  conversion_goals_icon as conversionGoalIcon,
  language_icon as languageIcon,
  location_icon as locationIcon,
  networks_icon as networksIcon,
  objective_icon as objectiveIcon,
  text_field_icon as textFieldIcon,
} from "../../../../assets/icons/campaign-approval/campaign_approval";
import Success from "../../../StatusIndicator/Success";
import Failed from "../../../StatusIndicator/Failed";
import Publishing from "../../../StatusIndicator/Publishing";
import CampainLanguageSelect from "./CampainLanguageSelect";
import CampainLocationSelect from "./CampainLocationSelect";
import MenuCheckList from "./MenuCheckList";
import MenuRadioList from "./MenuRadioList";
import {
  BIDDING_OPTIONS,
  CAMPAIGN_DEFAULTS,
  CONVERSION_GOAL_OPTIONS,
  NETWORK_OPTIONS,
  OBJECTIVE_OPTIONS,
  getBiddingExtraField,
} from "./constants";
import { campaignTypeFormSchema } from "./schema";
import "./CampaignType.css";
import { Popover } from 'react-tiny-popover';
import useGoogleCampaignStatus from './useGoogleCampaignStatus';

export default forwardRef(function CampaignType({
  title,
  campaignId,
  /** () => viewDetailsClickHandler(campType) */
  onViewDetails,
  campaignType,
  defaultValues,
  agencyType,
  confirmAndPublish,
  isConfirmed,
  apiError,
  isPublishingForced,
  isFormDirty,
}, ref) {
  const {errors, isCompleted, isPublishing} = useGoogleCampaignStatus({ agencyType, campaignType });
  // const errors = ["error 1"], isCompleted = false, isPublishing = false; // mock data
  const _errors = [apiError, ...errors].filter(Boolean);
  const hasErrors = !!_errors.length
  const _isPublishing = isPublishingForced || isPublishing;
  const isEditingDisabled = isCompleted || _isPublishing;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isPopoverOpenLanguage, setIsPopoverOpenLanguage] = useState(false);

  const { register, handleSubmit, control, formState, getValues, setValue, reset } = useForm({
    mode: "onSubmit",
    resolver: zodResolver(campaignTypeFormSchema),
    defaultValues: {
      ...CAMPAIGN_DEFAULTS,
      ...defaultValues,
    },
  });

  useImperativeHandle(ref, () => ({
    getValues: () => getValues(),
    reset: () => reset({}, {keepValues: true}),
    getIsDirty: () => formState.isDirty,
  }), [getValues, reset, formState.isDirty]);
  
  const biddingValue = useWatch({ control, name: "bidding" });
  const biddingExtraField = getBiddingExtraField(biddingValue);

  const languagesValue = useWatch({ control, name: "languages" });
  const locationsValue = useWatch({ control, name: "locations" });

  const onErrors = (errors) => {
    console.log({errors});
  }

  const onSubmit = (values) => {
    console.log({ values });
    confirmAndPublish(values);
  };

  const _isFormDirty = isFormDirty||formState.isDirty;

  return (
    <div className="campaign-type">
      <div
        className="campaign-type__success-indicator"
        data-is-published={isConfirmed || isCompleted || hasErrors || _isPublishing}
        data-is-dirty={_isFormDirty}
      />
      <div className="campaign-type__main">
        <div className="campaign-type__main-header">
          <Typography className="campaign-type__title">
            {title}
            {!_isFormDirty && (
              <>
                {isCompleted 
                  ? <Success campaignId={campaignId} />
                  : _isPublishing 
                    ? <Publishing />
                    : hasErrors && <Failed errors={_errors} />
                }
              </>
            )}
          </Typography>

          <Button
            variant="outlined"
            className="campaign-type__view-details-button"
            onClick={()=>onViewDetails(isEditingDisabled)}
            sx={{
              "&.Mui-disabled": {
                opacity: "0.3",
              },
            }}
          >
            Ad Group Level
          </Button>
        </div>
        <form onSubmit={handleSubmit(onSubmit, onErrors)}>
          <div className="campaign-form-container-1">
            <div
              className="campaign-form-element"
              style={{
                width: 422,
              }}
            >
              <div className="icon-container">
                <img src={textFieldIcon} alt="" />
              </div>
              <div className="form-input-element">
                <Typography className="form-input-label">Name</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder="Enter Text"
                  disabled={isEditingDisabled}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-disabled": {
                      "& > fieldset": { border: "1px solid #CCECFE" },
                    },
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { border: "1px solid #CCECFE" },
                    },
                    "& .Mui-disabled": {
                      color: "#0869FB !important",
                      "-webkit-text-fill-color": "#0869FB !important",
                    },
                    "& input": {
                      color: "#0869FB",
                    }
                  }}
                  inputProps={{
                    style: {
                      color: "#0869FB !important",
                      width: 324,
                      height: 8,
                      background: "#FFFFFF",
                      border: "1px solid #CCECFE",
                      borderRadius: 4,
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 12,
                    },
                  }}
                  {...register("campaignName")}
                />
              </div>
            </div>
            <div
              className="campaign-form-element"
              style={{
                width: 194,
              }}
            >
              <div className="icon-container">
                <img src={objectiveIcon} alt="" />
              </div>
              <div className="form-input-element">
                <Typography className="form-input-label">Objective</Typography>
                <Controller
                  name="objective"
                  control={control}
                  render={({ field }) => (
                    <MenuRadioList
                      options={OBJECTIVE_OPTIONS}
                      value={field.value}
                      onChange={field.onChange}
                      disabled={isEditingDisabled}
                      isEditable={false}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className="campaign-form-element"
              style={{
                width: 194,
              }}
            >
              <div className="icon-container">
                <img src={networksIcon} alt="" />
              </div>
              <div className="form-input-element">
                <Typography className="form-input-label">Networks</Typography>
                <Controller
                  name="networks"
                  control={control}
                  render={({ field }) => (
                    <MenuCheckList
                      options={NETWORK_OPTIONS}
                      value={field.value}
                      onChange={field.onChange}
                      disabled={isEditingDisabled}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className="campaign-form-element"
              style={{
                width: 194,
              }}
            >
              <div className="icon-container">
                <img src={budgetIcon} alt="" />
              </div>
              <div className="form-input-element">
                <Typography className="form-input-label">
                  Daily Budget (in $)
                </Typography>
                <div style={{ position: "relative" }}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    size="small"
                    placeholder="Enter Daily Budget"
                    type="number"
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-disabled": {
                        "& > fieldset": { border: "1px solid #CCECFE" },
                      },
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { border: "1px solid #CCECFE" },
                      },
                      "& .Mui-disabled": {
                        color: "#0869FB !important",
                        "-webkit-text-fill-color": "#0869FB !important",
                      }
                    }}
                    disabled={isEditingDisabled}
                    inputProps={{
                      style: {
                        color: "#0869FB",
                        boxSizing: "border-box",
                        width: 128,
                        height: 26.75,
                        background: "#FFFFFF",
                        border: "1px solid #CCECFE",
                        borderRadius: 4,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 400,
                        fontSize: 12,
                        paddingLeft: 12,
                      },
                    }}
                    {...register("budget")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="campaign-form-container-1">
            <div className="bidding-target-cpa-container">
              <div
                className="bidding-target-cpa-element"
                style={{
                  width: 194,
                }}
              >
                <div className="icon-container">
                  <img src={biddingIcon} alt="" />
                </div>
                <div className="form-input-element">
                  <Typography className="form-input-label">Bidding</Typography>
                  <Controller
                    control={control}
                    name="bidding"
                    render={({ field }) => (
                      <MenuRadioList
                        options={BIDDING_OPTIONS}
                        value={field.value}
                        onChange={field.onChange}
                        disabled={isEditingDisabled}
                      />
                    )}
                  />
                </div>
              </div>
              {biddingExtraField && (
                <div
                  className="bidding-target-cpa-element"
                  style={{
                    width: 216,
                  }}
                  key={biddingExtraField.key}
                >
                  <div className="icon-container">
                    <img src={biddingIcon} alt="" />
                  </div>
                  <div className="form-input-element">
                    {/* Add the corresponding bidding element with the extra field label here */}
                    <Typography className="form-input-label">
                      {biddingExtraField.label}
                    </Typography>
                    <Controller
                      name={biddingExtraField.key}
                      control={control}
                      render={({field}) => (
                        <TextField
                          {...field}
                          id="outlined-basic"
                          variant="outlined"
                          size="small"
                          type="number"
                          placeholder={biddingExtraField.placeholder}
                          disabled={isEditingDisabled}
                          sx={{
                            "& .MuiOutlinedInput-root.Mui-disabled": {
                              "& > fieldset": { border: "1px solid #CCECFE" },
                            },
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": { border: "1px solid #CCECFE" },
                            },
                            "& .Mui-disabled": {
                              color: "#0869FB !important",
                              "-webkit-text-fill-color": "#0869FB !important",
                            }
                          }}
                          onChange={(event) => {
                            setValue("maxCpcBidLimit", undefined);
                            setValue("targetCpa", undefined);
                            setValue("selectTargetRoas", undefined);
                            field.onChange(event);
                          }}
                          inputProps={{
                            style: {
                              color: "#0869FB",
                              width: 124,
                              height: 8,
                              background: "#FFFFFF",
                              border: "1px solid #CCECFE",
                              borderRadius: 4,
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: 12,
                            },
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="campaign-form-element"
              style={{
                width: 194,
              }}
            >
              <div className="icon-container">
                <img src={conversionGoalIcon} alt="" />
              </div>
              <div className="form-input-element">
                <Typography className="form-input-label">
                  Conversion Goals
                </Typography>
                <Controller
                  name="conversionGoals"
                  control={control}
                  render={({ field }) => (
                    <MenuCheckList
                      options={CONVERSION_GOAL_OPTIONS}
                      value={field.value}
                      onChange={field.onChange}
                      disabled={isEditingDisabled}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className="campaign-form-element"
              style={{
                width: 194,
              }}
            >
              <div className="icon-container">
                <img src={languageIcon} alt="" />
              </div>
              <div className="form-input-element">
                <Typography className="form-input-label">Language</Typography>
                <Popover
                  isOpen={isPopoverOpenLanguage}
                  align="start"
                  positions={["right", "bottom"]}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "center",
                  }}
                  reposition={true}
                  // containerStyle={{ left: "-180px", top: "0" }}
                  onClickOutside={() => setIsPopoverOpenLanguage(false)}
                  content={
                    <div
                      className="question"
                      style={{
                        background: "#FFF",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "16px 8px",
                        gap: "16px",
                        width: "372px",
                        minHeight: "100px",
                        border: "1px solid #F0F0F0",
                        boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
                        borderRadius: "8px",
                        zIndex: 99,
                      }}
                    >
                      <Controller
                        name="languages"
                        control={control}
                        render={({ field }) => (
                          <CampainLanguageSelect
                            campType={campaignType}
                            languages={field.value}
                            setLanguages={field.onChange}
                            disabled={isEditingDisabled}
                          />
                        )}
                      />
                    </div>
                  }
                >
                  <div
                    onClick={() => setIsPopoverOpenLanguage(prev => !prev)}
                    role="button"
                    style={{
                      width: 130,
                      height: 27,
                      background: "#FFFFFF",
                      border: "1px solid #CCECFE",
                      borderRadius: 4,
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 12,
                      outline: "none",
                      color: "#0869FB",
                      padding: "5px 10px",
                      cursor: isEditingDisabled ? "revert" : "pointer",
                    }}
                  >
                    {languagesValue.length
                      ? `${languagesValue.length} Selected`
                      : "Select"}
                  </div>
                </Popover>
              </div>
            </div>

            <div
              className="campaign-form-element"
              style={{
                width: 194,
              }}
            >
              <div className="icon-container">
                <img src={locationIcon} alt="" />
              </div>
              <div className="form-input-element">
                <Typography className="form-input-label">Location</Typography>
                <Popover
                  isOpen={isPopoverOpen}
                  align="start"
                  positions={["bottom", "left"]}
                  reposition={true}
                  // containerStyle={{ left: "-300px", top: "27px" }}
                  onClickOutside={() => setIsPopoverOpen(false)}
                  content={
                    <div
                      className="question"
                      style={{
                        background: "#FFF",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        padding: "16px 8px",
                        gap: "16px",
                        width: "372px",
                        minHeight: "100px",
                        border: "1px solid #F0F0F0",
                        boxShadow: "0px 10px 24px rgba(35, 40, 60, 0.05)",
                        borderRadius: "8px",
                        zIndex: 99,
                      }}
                    >
                      <Controller
                        name="locations"
                        control={control}
                        render={({ field }) => (
                          <CampainLocationSelect
                            campType={campaignType}
                            locations={field.value}
                            setLocations={field.onChange}
                            disabled={isEditingDisabled}
                          />
                        )}
                      />
                    </div>
                  }
                >
                  <div
                    onClick={() => {
                      setIsPopoverOpen(!isPopoverOpen);
                    }}
                    style={{
                      width: 130,
                      height: 27,
                      background: "#FFFFFF",
                      border: "1px solid #CCECFE",
                      borderRadius: 4,
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: 12,
                      outline: "none",
                      color: "#0869FB",
                      cursor: isEditingDisabled ? "revert" : "pointer",
                      padding: "5px 10px",
                    }}
                  >
                    {locationsValue.length
                      ? `${locationsValue.length} Selected`
                      : "Select"}
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                "&.Mui-disabled": {
                  opacity: "0.3",
                },
              }}
              style={{
                width: 167,
                height: 32,
                border: "1.5px solid #0869FB",
                borderRadius: 6,
                padding: "8px 16px",
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 12,
                lineHeight: "16px",
                color: "#0869FB",
                textTransform: "none",
              }}
              disabled={isEditingDisabled || (hasErrors && !_isFormDirty)}
              type="submit"
            >
              Confirm and Publish
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
})
