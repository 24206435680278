const INITIAL_STATE = {
    googleSharedAssetsAccounts: []
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "GET_SHARED_ASSETS_ACCOUNTS":
            return { ...state, googleSharedAssetsAccounts: action.payload };
        case "RESET":
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default reducer;
