export const STRATEGY_STATUS = {
  PENDING: "PENDING",
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
};

export const ADSET_STATUS = {
  PENDING: "PENDING",
  ONGOING: "ONGOING",
  COMPLETED: "COMPLETED",
};

export const GENERATION_TYPE = {
  GUIDED_MANUAL: "GUIDED_MANUAL",
  GUIDED_DEFAULT: "GUIDED_DEFAULT",
  AUTOMATED_MANUAL: "AUTOMATED_MANUAL",
  AUTOMATED_DEFAULT: "AUTOMATED_DEFAULT",
};

export const CAMPAIGN_TYPE = {
  LOOKALIKE: "top_of_the_funnel_lookalike",
  INTEREST: "top_of_the_funnel_interest",
  MOF: "middle_of_the_funnel",
  bottom_of_the_funnel: "bottom_of_the_funnel",
};

export const TARGETING_TYPE = {
  INTERESTKEYWORDS: "INTERESTKEYWORDS",
  AUDIENCE: "AUDIENCE",
  LOOKALIKE: "LOOKALIKE",
};

export const ADSET_CRON_STATUS = {
  RUNNING: "RUNNING",
  DONE: "DONE",
  FAILED: "FAILED",
  PENDING: "PENDING",
};

export const STRATEGY_FLOW_BACKEND = {
  GUIDED: "GUIDED",
  SMART: "SMART",
};


export const getFBStrategyGenerationFilterType = (strategyFlow) =>
  ({
    [STRATEGY_FLOW_BACKEND.GUIDED]: [
      GENERATION_TYPE.GUIDED_DEFAULT,
      GENERATION_TYPE.GUIDED_MANUAL,
    ],
    [STRATEGY_FLOW_BACKEND.SMART]: [
      GENERATION_TYPE.AUTOMATED_DEFAULT,
      GENERATION_TYPE.AUTOMATED_MANUAL,
    ],
  }[strategyFlow]);
