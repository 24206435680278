import { useSelector } from "react-redux";
import CommonGoogleAdsAccount from "./CommonGoogleAdsAccount";
import { useCallback, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../pages/ld-am/constants/ldAmRoutePaths";
import { useGetClientForms } from "../../services/clientForms";
import { BACKEND_STATUS } from "../../utils/accountManagerUtil";
import PmaxLoader from "../PMaxStrategyCreation/PmaxLoader";
import { isPlatformSelectedLD } from "../../utils/LDUtils/commonUtils";
export default function LDGoogleAdsAccount({
  generateAudit,
  clientFormMutate,
  redirectUrl,
}) {
  const navigate = useNavigate();
  const { formId, again } = useParams();

  const [toNavigate, setToNavigate] = useState(false);
  const { data: form, isLoading } = useGetClientForms(formId);
  // const { data: form } = useGetClientForms(formId);

  // const { data: form = {} } = useGetClientForms(formId, {});
  useEffect(() => {
    if (toNavigate && !again) {
      navigate(
        generatePath(
          `${ldAmRoutePaths.auditForGoogle.fullbase}/${ldAmRoutePaths.auditForGoogle.sub.reviewPoints}`,
          { formId }
        )
      );
    }
  }, [toNavigate]);

  const onHaveInsights = () => {
    setToNavigate(true);
  };

  const onGotInsights = () => {
    navigate(
      generatePath(
        `${ldAmRoutePaths.auditForGoogle.fullbase}/${ldAmRoutePaths.auditForGoogle.sub.reviewPoints}`,
        { formId }
      )
    );
  };

  const onSkip = () => {
    if (
      (form?.onboardingInfo?.platformInfo?.platforms || []).find(
        (pf) => pf.platform === "facebook-ads"
      )
    ) {
      if (
        [BACKEND_STATUS.COMPLETED, BACKEND_STATUS.ONGOING].includes(
          form?.auditInfo?.status
        )
      ) {
        navigate(
          generatePath(
            `${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.account}`,
            { formId }
          )
        );
      } else {
        navigate(
          generatePath(ldAmRoutePaths.auditForFacebook.fullbase, { formId })
        );
      }
    } else {
      navigate(generatePath(ldAmRoutePaths.proposol.fullbase, { formId }));
    }
  };
  const navigateToFbAuditOrProposal = () => {
    if (isPlatformSelectedLD(form, "facebook-ads")) {
      // have to handle logic to redirect to facebook to sub account
      navigate(
        generatePath(ldAmRoutePaths.auditForFacebook.fullbase, {
          formId,
        })
      );
    } else {
      // navigate to proposal sub page
      navigate(
        generatePath(`${ldAmRoutePaths.proposol.fullbase}`, {
          formId,
        })
      );
    }
  };
  const onNext = async () => {
    if (form?.googleAuditStatus !== BACKEND_STATUS.COMPLETED) {
      const data = {
        googleAuditStatus: BACKEND_STATUS.COMPLETED,
        googleAuditData: {
          status: BACKEND_STATUS.COMPLETED,
        },
        facebookAuditStatus: BACKEND_STATUS.ONGOING,
        currentStep: form?.currentStep
          ? form?.currentStep
          : isPlatformSelectedLD(form, "facebook-ads")
          ? "Audit"
          : "Proposal",
      };
      await clientFormMutate.mutateAsync(data);
    }
    navigateToFbAuditOrProposal();
  };
  return isLoading ? (
    <PmaxLoader />
  ) : (
    <CommonGoogleAdsAccount
      form={form}
      onHaveInsights={onHaveInsights}
      onGotInsights={onGotInsights}
      onSkip={onSkip}
      again={again}
      performanceAuditBoolean={true}
      generateAudit={generateAudit}
      onNext={onNext}
      redirectUrl={redirectUrl}
    />
  );
}
