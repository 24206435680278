import {
  Box,
  Button,
  Modal,
  TextField,
} from "@mui/material";
// import "../../../styles/filterpopup.scss";
import React from "react";
import SegmentBox from "./SegmentBox";
import FilterBox from "./FilterBox";
import { Filter, NEW_FILTER } from "../../../models/chart";
import { useSelector } from "react-redux";
import { GetChannel } from "../../../models/channel";
import { defaultFBState } from "../../../models/defaultData";
// import { ChannelType } from "../../../models/channel";
// import { transformToFilterDataObject } from "../Utils";
// import { createFilter, updateFilter } from "../../../api/filter/saga";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  maxHeight: "82vh",
  overflowY: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  p: 4,
};

export default function FilterPopup({ chart, chartFilter, currentFilter, onChange, onClose }) {
  const nameRef = React.useRef(currentFilter.name);
  const segmentRef = React.useRef(currentFilter.segment);
  const dimensionFiltersRef = React.useRef(currentFilter.dimensionFilters.length === 0 ? [NEW_FILTER] : (currentFilter.dimensionFilters[currentFilter.dimensionFilters.length - 1].boolOperator ? currentFilter.dimensionFilters.concat(NEW_FILTER) : currentFilter.dimensionFilters));
  const metricFiltersRef = React.useRef(currentFilter.metricFilters?.length === 0 ? [NEW_FILTER] : (currentFilter.metricFilters[currentFilter.metricFilters.length - 1]?.boolOperator ? currentFilter.metricFilters.concat(NEW_FILTER) : currentFilter.metricFilters));
  const [nameError, setNameError] = React.useState(false);

  const channel = GetChannel(currentFilter.channelType);
  const state = useSelector(state => channel.getState(state)) ?? defaultFBState;
  const segments = state.segments;
  const dimensions = Array.isArray(state.dimensions) ? state.dimensions : state.dimensions?.[chart?.table?.id] ?? [];
  const metrics = Array.isArray(state.metrics) ? state.metrics : state.metrics?.[chart?.table?.id] ?? [];
  const dimensionsBing = dimensions.filter(f => f.filterable);

  const saveFilter = (filter) => {
    // if (!filter.isEqualAll(currentFilter)) {
    //   const filterObject = transformToFilterDataObject({ ...filter, tableId: chart?.table?.id })
    //   if (!currentFilter.name) {
    //     createFilter(filterObject).then(filterId => {
    //       onChange(Filter.fromJSON({ ...filter, id: filterId }));
    //     });
    //   } else {
    //     updateFilter(filterObject).then(data => {
    //       onChange(filter, true);
    //     });
    //   }
    // } else if (chartFilter && !filter.isEqualAll(chartFilter)) {
    onChange(filter);
    // }
  }

  return (
    <div>
      <Modal
        open={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                marginBottom: "40px"
              }}>
              <img height="25px" width="25px" src={channel.icon} alt={channel.title} />
              <TextField
                className="filter-name"
                sx={{ marginLeft: "12px" }}
                variant="standard"
                placeholder="Filter name*"
                defaultValue={nameRef.current}
                error={nameError}
                label={nameError ? "Required" : ""}
                required
                onChange={e => { nameRef.current = e.target.value; setNameError(nameRef.current.length === 0); }}
                InputProps={{ disableUnderline: true }}
              />
            </Box>
            {segments && <SegmentBox channel={channel} segments={segments} segmentRef={segmentRef} />}
            <FilterBox
              title={"Column Filter"}
              channel={channel}
              dimensions={dimensions.map(dim => ({ ...dim, type: "DIMENSION" }))}
              metrics={metrics.map(metric => ({ ...metric, type: "METRIC" }))}
              filterListRef={dimensionFiltersRef}
            />
            <br />
            {/* {
              channel.type === ChannelType.GoogleAds && <FilterBox
                title="Metric Filter"
                channel={channel}
                filters={metrics}
                filterListRef={metricFiltersRef}
              />
            } */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "50px",
              }}>
              <Button
                sx={{ marginRight: "10px" }}
                onClick={onClose}>
                Cancel
              </Button>
              <Button onClick={() => {
                if (nameRef.current.length === 0) { setNameError(true); return; }
                const filter = Filter.new(
                  chart,
                  nameRef.current,
                  segmentRef.current,
                  dimensionFiltersRef.current.filter(filter => filter.filter && filter.operator && (filter.value && (!Array.isArray(filter.value) || filter.value[0]))),
                  metricFiltersRef.current.filter(filter => filter.filter && filter.operator && filter.value),
                  currentFilter.id
                );
                if (filter.isEmpty()) { alert("Filter is empty"); return; }
                saveFilter(filter)
                onClose();
              }} variant="contained">
                {chart.reportFilter ?
                  (!currentFilter.name ? "Save Filter" : "Update and Save Filter") :
                  (!currentFilter.name ? "Save and Apply Filter" : "Update and Apply Filter")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
