import { Close } from '@mui/icons-material';
import { Typography, Select, MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './adgroup.css';
import { ArrowDownward, ExpandMore } from '@mui/icons-material';
const AdGroup = (props) => {
  const [addKeywordInput, setAddKeywordInput] = useState([]);
  const onClickHandler = (id) => {
    setAddKeywordInput([
      ...addKeywordInput,
      {
        id: id,
        value: '',
      },
    ]);
    window.scroll({
      bottom: document.querySelector('.ad-group-search-lists').offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
  };
  const onDeleteAdInput = (id) => {
    setAddKeywordInput([...addKeywordInput.filter((value) => value.id !== id)]);
  };
  const adGroupkeywordNameTextStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 16,
    color: '#333333',
  };
  const resultTypographyStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    color: '#333333',
  };
  const reachTypographyStyling = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: '#333333',
  };
  const addKeywordTextStyling = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: '#0869FB',
  };
  const AdGroupChild = ({keyword, idx}) => {
    const [search, setSearch] = useState(keyword.matchType);
    const [value, setValue] = useState(keyword.keyword);
    return (
      <div className='ad-group'>
        <div className='result'>
          {props.read ? (
            <Typography style={resultTypographyStyling}>
              {keyword.keyword}
            </Typography>
          ) : (
            <TextField
              sx={{
                '& .css-whebh7-MuiInputBase-root-MuiInput-root:before': {
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                },
                // border: '1px solid green',
              }}
              inputProps={{
                style: {
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: 15,
                  width: 230,
                },
              }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              onKeyDown={(e) => {
                if(e.key === "Enter" && value){
                  props.editKeyWord(props.campaignType, props.adSetName, keyword, {keyword: value, matchType: search});
                }
              }}
              variant='standard'
            />
          )}
        </div>
        <div className='reach'>
          <Select
            size='small'
            defaultValue='Exact'
            value={search}
            disabled={props.isDisabled}
            onChange={(event) => {
              props.editKeyWord(props.campaignType, props.adSetName, keyword, {keyword: keyword.keyword, matchType: event.target.value});
              setSearch(event.target.value)
            }}
            className='select-box'
            // IconComponent={ArrowDown}
            // IconComponent={() => (
            //   <ExpandMore
            //     fontSize='small'
            //     sx={{ position: 'absolute', right: 7 }}
            //   />
            // )}
            style={{
              width: 76,
              height: 23,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 12,
              color: '#626262',
            }}
          >
            <MenuItem value='exact'>Exact</MenuItem>
            <MenuItem value='phrase'>Phrase</MenuItem>
            <MenuItem value='broad'>Broad</MenuItem>
          </Select>
          {/* <Typography style={reachTypographyStyling}>24</Typography> */}
          {!props.isDisabled && (
            <Close 
              style={{ width: 12, height: 12 }}
              onClick={() => props.deleteKeyWord(props.campaignType, props.adSetName, keyword)}
            />
          )}
        </div>
      </div>
    );
  };
  const AdGroupInput = ({id}) => {
    const [search, setSearch] = useState('exact');
    const [value, setValue] = useState("");
    return (
      <div className='ad-group'>
        <div className='result'>
          <TextField
            sx={{
              '& .css-whebh7-MuiInputBase-root-MuiInput-root:before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
              },
              // border: '1px solid green',
            }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
              if(e.key === "Enter" && value){
                props.addKeyWords(props.campaignType, props.adSetName, {keyword: value, matchType: search});
                onDeleteAdInput(id);
              }
            }}
            inputProps={{
              style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 12,
                lineHeight: 15,
                width: 230,
              },
            }}
            variant='standard'
          />
        </div>
        <div className='reach'>
          <Select
            size='small'
            defaultValue='exact'
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className='select-box'
            // IconComponent={ArrowDown}
            // IconComponent={() => (
            //   <ExpandMore
            //     fontSize='small'
            //     sx={{ position: 'absolute', right: 7 }}
            //   />
            // )}
            style={{
              width: 76,
              height: 23,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 12,
              color: '#626262',
            }}
          >
            <MenuItem value='exact'>Exact</MenuItem>
            <MenuItem value='phrase'>Phrase</MenuItem>
            <MenuItem value='broad'>Broad</MenuItem>
          </Select>
          {/* <Typography style={reachTypographyStyling}>23</Typography> */}
          <Close
            style={{ width: 12, height: 12 }}
            onClick={() => onDeleteAdInput(id)}
          />
        </div>
      </div>
    );
  };
  return (
    <div className='ad-group-container'>
      <div className='ad-group-title'>
        <Typography style={adGroupkeywordNameTextStyling}>
          Keywords Recommended : {props.adSetName}
        </Typography>
      </div>
      <div className='ad-group-search-lists'>
        {(props.keywords || []).map((keyword, idx) => {
        return (<AdGroupChild keyword={keyword} idx={idx}/>);
        })}
        {addKeywordInput.map((adInput, index) => (
          <AdGroupInput id={adInput.id} key={index} />
        ))}
      </div>
      <div
        className='add-keyword-container'
        onClick={() => onClickHandler(addKeywordInput.length + 1)}
        style={{
          ...(props.isDisabled ? {opacity: 0.6, pointerEvents: "none", userSelect: "none"}: {})
        }}
      >
        <Typography style={addKeywordTextStyling}>+ Add Keyword</Typography>
      </div>
    </div>
  );
};

export default AdGroup;
