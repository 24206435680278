import { put, takeLatest, select, call, all } from "redux-saga/effects";
import { api } from "../../index";
import {
  getFormByFormIdReducer,
  updateCompanyInfoReducer,
  updateGoogleAdsInfoReducer,
  updateFacebookAdsInfoReducer,
  updateCROInfoReducer,
  updateData,
  publishProposalReducer,
  updateGoogleAdData,
  updateFacebookAdData,
  updateCROData,
  updateFormReducer,
  validateGoogleAdsAccessReducer,
  updateAllForms,
  getAllFormsReducer,
  updateFormForCampaignReducer,
  updateCampaignConfirmModal,
} from "./slice";
import { updatePricing, updateSelectedPriceCard } from "../pricing/slice";
import { getLocalStorage } from "../../localStorage/localStorage";
import {
  BACKEND_STATUS,
  PROPOSAL_STATE,
} from "../../../utils/accountManagerUtil";
import agencyTypes from "../../../utils/agencyType";
import { getImageUrl } from "../../fileHandling/saga";
import { setClientForm, updateFormData } from "../../clientforms/slice";
import { queryClient } from "../../../utils/queryClient";
import { getClientFormQueryKey } from "../../../services/clientForms";

function* getForm(action) {
  let formId = "";
  if (getLocalStorage("formId") && getLocalStorage("formId") !== "undefined") {
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  const formResponse = yield api.get("/client-forms/" + formId);
  yield put(updateData(formResponse.data));
  yield put({
    type: "UPDATE_WHOLE_HDM_AM_DETAILS",
    payload: formResponse.data,
  });
  yield put({
    type: "UPDATE_WHOLE_HDM_CLIENT_FORMID",
    payload: formResponse.data,
  });

  if (
    formResponse?.data?.sowPricingInfo?.clientPricing?.status ===
    BACKEND_STATUS.COMPLETED
  ) {
    let priceIndex = 0;
    const minAdSpend =
      formResponse.data.sowPricingInfo.clientPricing.adSpendRange.minSpend;
    if (minAdSpend === 5000) {
      priceIndex = 1;
    } else if (minAdSpend === 25000) {
      priceIndex = 2;
    } else if (minAdSpend === 75000) {
      priceIndex = 3;
    } else if (minAdSpend === 150000) {
      priceIndex = 4;
    } else {
      priceIndex = 5;
    }

    if (formResponse?.data?.sowPricingInfo?.clientPricing) {
      yield put(
        updatePricing({
          priceIndex,
          pricing: formResponse?.data?.sowPricingInfo?.clientPricing,
        })
      );
      yield put(updateSelectedPriceCard(priceIndex));
    }

    action.payload.callback?.(null, formResponse);
  }
}

function* updateCompanyInfo(action) {
  const url = `/client-forms`;
  const store = yield select();
  //const form = ["1", "12"].includes(localStorage.getItem("agencyId")) ? store.form.form : store.hdmFormAm;
  let form;
  if (localStorage.getItem("agencyType") === agencyTypes.LINEAR)
    form = store.form.form;
  else if (localStorage.getItem("agencyType") === agencyTypes.HDM)
    form = store.hdmFormAm;

  const body = { ...form };
  const companyInfo = { ...body.companyInfo, ...action.payload };
  body.companyInfo = companyInfo;
  const formResponse = yield api.post(url, body);
  yield put(updateData(formResponse.data));
  yield put(updateGoogleAdData(formResponse.data.sowPricingInfo.googleAdsInfo));
  yield put(
    updateFacebookAdData(formResponse.data.sowPricingInfo.facebookAdsInfo)
  );
  yield put(updateCROData(formResponse.data.sowPricingInfo.croInfo));
}

function* updateGoogleAdsInfo(action) {
  const url = `/client-forms`;
  const store = yield select();
  //const form = ["1", "12"].includes(localStorage.getItem("agencyId")) ? store.form.form : store.hdmFormAm;
  const form =
    localStorage.getItem("agencyType") === agencyTypes.LINEAR
      ? store.form.form
      : store.hdmFormAm;

  const body = { ...form };
  const googleAdsInfo = {
    ...body.sowPricingInfo.googleAdsInfo,
    ...action.payload,
  };
  const sowPricingInfo = { ...body.sowPricingInfo };
  sowPricingInfo.googleAdsInfo = googleAdsInfo;
  body.sowPricingInfo = sowPricingInfo;
  const formResponse = yield api.post(url, body);
  yield put(updateData(formResponse.data));
  yield put(updateGoogleAdData(formResponse.data.sowPricingInfo.googleAdsInfo));
  yield put(
    updateFacebookAdData(formResponse.data.sowPricingInfo.facebookAdsInfo)
  );
  yield put(updateCROData(formResponse.data.sowPricingInfo.croInfo));
}

function* updateFacebookAdsInfo(action) {
  const url = `/client-forms`;
  const store = yield select();
  //const form = ["1", "12"].includes(localStorage.getItem("agencyId")) ? store.form.form : store.hdmFormAm;
  const form =
    localStorage.getItem("agencyType") === agencyTypes.LINEAR
      ? store.form.form
      : store.hdmFormAm;

  const body = { ...form };
  const sowPricingInfo = { ...body.sowPricingInfo };
  const facebookAdsInfo = {
    ...body.sowPricingInfo.facebookAdsInfo,
    ...action.payload,
  };
  sowPricingInfo.facebookAdsInfo = facebookAdsInfo;
  body.sowPricingInfo = sowPricingInfo;
  const formResponse = yield api.post(url, body);
  yield put(updateData(formResponse.data));
  yield put(updateGoogleAdData(formResponse.data.sowPricingInfo.googleAdsInfo));
  yield put(
    updateFacebookAdData(formResponse.data.sowPricingInfo.facebookAdsInfo)
  );
  yield put(updateCROData(formResponse.data.sowPricingInfo.croInfo));
}

function* updateCROInfo(action) {
  const url = `/client-forms`;
  const store = yield select();
  //const form = ["1", "12"].includes(localStorage.getItem("agencyId")) ? store.form.form : store.hdmFormAm;
  const form =
    localStorage.getItem("agencyType") === agencyTypes.LINEAR
      ? store.form.form
      : store.hdmFormAm;

  const body = { ...form };
  const sowPricingInfo = { ...body.sowPricingInfo };
  const croInfo = { ...body.sowPricingInfo.croInfo, ...action.payload };
  sowPricingInfo.croInfo = croInfo;
  body.sowPricingInfo = sowPricingInfo;
  const formResponse = yield api.post(url, body);
  yield put(updateData(formResponse.data));
  yield put(updateGoogleAdData(formResponse.data.sowPricingInfo.googleAdsInfo));
  yield put(
    updateFacebookAdData(formResponse.data.sowPricingInfo.facebookAdsInfo)
  );
  yield put(updateCROData(formResponse.data.sowPricingInfo.croInfo));
}

function* publishProposal(action) {
  const url = `/client-forms`;
  const store = yield select();
  //const form = ["1", "12"].includes(localStorage.getItem("agencyId")) ? store.form.form : store.hdmFormAm;
  let form = {};
  if (localStorage.getItem("agencyType") === agencyTypes.LINEAR)
    form = store.form.form;
  if (localStorage.getItem("agencyType") === agencyTypes.HDM)
    form = store.hdmFormAm;
  if (localStorage.getItem("agencyType") === agencyTypes.BLACK_PROPELLER)
    form = store.forms.form;

  let formId = "";
  if (getLocalStorage("formId") && getLocalStorage("formId") !== "undefined") {
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  let brandId = "";
  if (
    getLocalStorage("brandId") &&
    getLocalStorage("brandId") !== "undefined"
  ) {
    brandId = getLocalStorage("brandId");
  } else {
    brandId = getLocalStorage("amSelectedBrandId");
  }
  // also adding proposalStatus to onGoing in form
  const body = { proposalStatus: BACKEND_STATUS.ONGOING };
  body.published = true;
  body._id = formId;
  body.brandId= brandId;
  const suggestChanges = form.proposalIterations?.length
    ? [
        ...(form.proposalIterations[form.proposalIterations.length - 1]
          .suggestedChanges || []),
      ]
    : [];

  const proposalIterations = [...(form.proposalIterations || [])];
  proposalIterations.push(
    form.proposalIterations?.length
      ? {
          state: PROPOSAL_STATE.CHANGES_APPLIED,
          suggestedChanges: suggestChanges,
        }
      : { state: PROPOSAL_STATE.GENERATED }
  );
  body.proposalIterations = proposalIterations;
  const formResponse = yield api.post(url, body);
  yield put(updateData(formResponse.data));
  if (localStorage.getItem("agencyType") === agencyTypes.BLACK_PROPELLER)
    yield put(setClientForm(formResponse.data));
  yield put(updateGoogleAdData(formResponse.data.sowPricingInfo.googleAdsInfo));
  yield put(
    updateFacebookAdData(formResponse.data.sowPricingInfo.facebookAdsInfo)
  );
  yield put(updateCROData(formResponse.data.sowPricingInfo.croInfo));
}

function* updateForm(action) {
  console.log("^^^^^^^^^^^^^^^^^^^", action.payload);
  const { callback, ...payload } = action.payload;
  const url = `/client-forms`;
  const store = yield select();
  let formId = "";
  const currentCompoAm = yield select(
    (state) => state.amCurrentComp.currentCompAm
  );
  console.log("currentCompoAm", currentCompoAm);
  if (getLocalStorage("formId") && getLocalStorage("formId") !== "undefined") {
    formId = getLocalStorage("formId");
  } else {
    formId = getLocalStorage("amSelectedFormId");
  }
  let brandId = "";
  if (
    getLocalStorage("brandId") &&
    getLocalStorage("brandId") !== "undefined"
  ) {
    brandId = getLocalStorage("brandId");
  } else {
    brandId = getLocalStorage("amSelectedBrandId");
  }

  const body = { ...payload, _id: formId, brandId: brandId };
  const formResponse = yield api.post(url, body);
  console.log("in form saga", formResponse);
  callback?.(null, formResponse.data);
  queryClient.invalidateQueries({ queryKey: getClientFormQueryKey({formId}) });
  console.log("######## in uupdate reducre saga", brandId);
  yield put(updateData(formResponse.data));
  yield put(setClientForm(formResponse.data));
  yield put({
    type: "UPDATE_WHOLE_HDM_AM_DETAILS",
    payload: formResponse.data,
  });
  yield put({
    type: "UPDATE_WHOLE_HDM_CLIENT_FORMID",
    payload: formResponse.data,
  });
}

function* updateFormForCampaign(action) {
  const url = `/client-forms`;
  const campaignInfo = action.payload.googleSearchAdsCampaignInfo;
  const store = yield select();
  //const form = ["1", "12"].includes(localStorage.getItem("agencyId")) ? store.form.form : store.hdmFormAm;
  const form =
    localStorage.getItem("agencyType") === agencyTypes.LINEAR
      ? store.form.form
      : store.hdmFormAm;

  const body = { ...form, googleSearchAdsCampaignInfo: { ...campaignInfo } };
  try {
    const formResponse = yield api.post(url, body);
    console.log("in saga for form", formResponse);
    if (action.payload.campaignConfirmModal) {
      yield put(updateCampaignConfirmModal(true));
    }
    yield put(updateData(formResponse.data));
    yield put({
      type: "UPDATE_WHOLE_HDM_AM_DETAILS",
      payload: formResponse.data,
    });
    action.payload.callback?.(null, formResponse.data);
  } catch (error) {
    action.payload.callback?.(true, null);
    console.error("Error while updating form for campaign, " + error.message);
  }
}

function* validateGoogleAdsAcess(action) {
  const formIds = action.payload.formIds;
  const googleAdsAccess = {};
  yield* formIds.map(function* (formId) {
    try {
      const formResponse = yield api.get(
        "/client-forms/validate-g-ads-access/" + formId
      );
      googleAdsAccess[formId] = formResponse.data.hasAdminAccess;
    } catch (error) {
      console.log(
        "error while fetching google-ads-access for formId:" +
          formId +
          "- " +
          error.message
      );
    }
  });
}

function* getAllForms(action) {
  const { callback } = action?.payload || {};
  try {
    const formsResponse = yield api.get("/client-forms");
    yield put(updateAllForms(formsResponse.data.values));
    callback?.(null, formsResponse.data.values);
  } catch (error) {
    callback?.(error, null);    
  }
  // const allForms = [];
  // if(formsResponse.data.values) {
  //   yield all(formsResponse.data.values.map(function* (form) {
  //     const companyInfo = {...form.companyInfo};
  //     try{
  //       if(companyInfo.companyLogo){
  //         const companyLogo = yield getImageUrl(companyInfo.companyLogo);
  //         if(companyLogo){
  //           companyInfo.companyLogo = URL.createObjectURL(companyLogo);
  //         }
  //       }
  //     }catch(error) {
  //       console.log("error while fetching company logo image url for formId:" + form._id + "- " + error.message)
  //     }
  //     try{
  //       if(companyInfo.signature){
  //         const signature = yield getImageUrl(companyInfo.signature);
  //         if(signature){
  //           companyInfo.signature = URL.createObjectURL(signature);
  //         }
  //       }
  //     }catch(error) {
  //       console.log("error while fetching signature image url for formId:" + form._id + "- " + error.message)
  //     }

  //     allForms.push({...form, companyInfo: {...companyInfo}});
  //   }));
  // }
  // const resultantForm = formsResponse.data.values.map(form => {
  //   return allForms.find(aggForm => aggForm._id === form._id);
  // })
  // yield put(updateAllForms(formsResponse.data.values));
}

export const ldFbAuditComplete = (obj) => {
  const url = `/client-forms`;
  const json = JSON.parse(JSON.stringify(obj));
  const facebookAuditStatusObj = {
    facebookAuditStatus: BACKEND_STATUS.COMPLETED,
    isSkippedAuditFacebook: false
  };
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api.post(
        url,
        { ...json, ...facebookAuditStatusObj },
        false
      );
      resolve(res);
    } catch (err) {
      reject(err);
    }
  });
};

export default function* formSaga() {
  yield all([
    yield takeLatest(getFormByFormIdReducer.type, getForm),
    yield takeLatest(getAllFormsReducer.type, getAllForms),
    yield takeLatest(updateCompanyInfoReducer.type, updateCompanyInfo),
    yield takeLatest(updateGoogleAdsInfoReducer.type, updateGoogleAdsInfo),
    yield takeLatest(updateFacebookAdsInfoReducer.type, updateFacebookAdsInfo),
    yield takeLatest(updateCROInfoReducer.type, updateCROInfo),
    yield takeLatest(publishProposalReducer.type, publishProposal),
    yield takeLatest(updateFormReducer.type, updateForm),
    yield takeLatest(updateFormForCampaignReducer.type, updateFormForCampaign),
    yield takeLatest(
      validateGoogleAdsAccessReducer.type,
      validateGoogleAdsAcess
    ),
  ]);
}
