import { Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { getClientDetailsByFormId } from "../../api/client/action";
import hdmClientRoutePaths from "./constants/hdmClientRoutePaths";

// pages
const HomePage = lazy(() => import("./HomePage"));
const AccessSharing = lazy(() => import("./AccessSharing"));
const CampaignPreview = lazy(() => import("./CampaignPreview"));
const AnalyticDashboard = lazy(() => import("./AnalyticDashboard"));



// nested routers
const ClientOnboardingFormRouter = lazy(() => import("./ClientOnboardingInputs/Router"));
const OnboardingDocRouter = lazy(() => import("./OnboardingDoc/Router"));
const StrategyDeckRouter = lazy(() => import('./StrategyDeck/Router')); 



// layouts
const SideNavigationStepsLayout = lazy(() => import("./layouts/SideNavigationStepsLayout"));

export default function HDMClientRootRouter() {
  // const { formId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getClientDetailsByFormId())
  }, [])

  return (
    <Suspense fallback={<>loading...</>}>
      <Routes>
        <Route element={<SideNavigationStepsLayout />}>
          {/* <Route path={`${ldAmRoutePaths.onboardingForm.root}/*`} element={<ClientOnboardingInputsRouter />} /> */}
          <Route index element={<HomePage />} />
          <Route path={hdmClientRoutePaths.homePage.root} element={<HomePage />} />
          <Route path={`${hdmClientRoutePaths.onboardingForm.root}/*`} element={<ClientOnboardingFormRouter />} />
          <Route path={hdmClientRoutePaths.accessSharing.root} element={<AccessSharing />} />
          <Route path={`${hdmClientRoutePaths.onboardingDoc.root}/*`} element={<OnboardingDocRouter />} />
          <Route path={`${hdmClientRoutePaths.strategyDeck.root}/*`} element={<StrategyDeckRouter />} />
          <Route path={hdmClientRoutePaths.campaignPreview.root} element={<CampaignPreview />} />
          <Route path={hdmClientRoutePaths.analyticsDashboard.root} element={<AnalyticDashboard />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
