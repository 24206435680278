import moment from "moment";
import { api } from "../../api";
import { ChannelType } from "../../components/Admin/channel.js";
import { useLocation, useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../api/localStorage/localStorage";
import { getClientIdForPlatforms } from "../../api/auth/saga";

export const clientColumns = [
  {
    id: "name",
    name: "Name",
    isSelected: false,
    isSortable: false,
    ascOrder: false,
  },
  {
    id: "email",
    name: "Email",
    isSelected: false,
    isSortable: false,
    ascOrder: false,
  },
  {
    id: "createdAt",
    name: "Creation Date",
    isSelected: true,
    isSortable: true,
    ascOrder: false,
  },
  {
    id: "lastUpdateDate",
    name: "Last Used Date",
    isSelected: false,
    isSortable: false,
    ascOrder: false,
  },
  {
    id: "currentStep",
    name: "Current Step",
    isSelected: false,
    isSortable: false,
    ascOrder: false,
  },
  {
    id: "google",
    name: "Google Accounts",
  },
  {
    id: "facebook",
    name: "FB Accounts",
  },
];

export const currentStepsSortingConstantsForLD = {
  "Onboarding Form": 0,
  Pricing: 1,
  "Access Sharing": 2,
  Audit: 3,
  Proposal: 4,
  "Review Intake Form": 5,
  "Strategy Creation": 6,
  "Strategy Deck": 7,
  "Campaign Creation": 8,
  "Campaign Preview": 9,
  "Creative Approval": 10,
  "Analytics Dashboard": 11,
  "-": 12,
};

const currentStepsSortingConstantsForBP = {
  "Sales Questionnaire": 0,
  "Access Sharing": 1,
  Audit: 2,
  Proposal: 3,
  "Review Intake Form": 4,
  "Strategy Creation": 5,
  "Strategy Deck": 6,
  "Campaign Creation": 7,
  "Campaign Preview": 8,
  "Creative Approval": 9,
  "Analytics Dashboard": 10,
  "-": 11,
};

export const AMColumns = [
  {
    id: "name",
    name: "Name",
    isSelected: false,
    isSortable: false,
    ascOrder: false,
  },
  {
    id: "email",
    name: "Email",
    isSelected: false,
    isSortable: false,
    ascOrder: false,
  },
  {
    id: "createdAt",
    name: "Creation Date",
    isSelected: true,
    isSortable: true,
    ascOrder: false,
  },
  // {
  //   id: "lastUpdateDate",
  //   name: "Last Used Date",
  //   isSelected: false,
  // },
];
const sortFunctionForStrings = (a, b, stringVal) => {
  let firstString = a[stringVal].toLowerCase();
  let secondString = b[stringVal].toLowerCase();
  if (firstString < secondString) {
    return -1;
  }
  if (firstString > secondString) {
    return 1;
  }
  return 0;
};
export const sortingDataAccordingToTableHeaderLabel = (
  rowsData,
  label,
  isSortAsc
) => {
  // console.table({ label, isSortAsc });
  switch (label) {
    case "email":
      return isSortAsc
        ? rowsData?.sort((a, b) => sortFunctionForStrings(a, b, "email"))
        : rowsData?.sort((a, b) => sortFunctionForStrings(b, a, "email"));

    case "name":
      return isSortAsc
        ? rowsData?.sort((a, b) => sortFunctionForStrings(a, b, "name"))
        : rowsData?.sort((a, b) => sortFunctionForStrings(b, a, "name"));
    case "createdAt":
      return isSortAsc
        ? rowsData?.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          )
        : rowsData?.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
    case "lastUpdateDate":
      return isSortAsc
        ? rowsData?.sort(
            (a, b) => new Date(a.lastUpdateDate) - new Date(b.lastUpdateDate)
          )
        : rowsData?.sort(
            (a, b) => new Date(b.lastUpdateDate) - new Date(a.lastUpdateDate)
          );

    case "currentStep":
      return isSortAsc
        ? rowsData?.sort((data1, data2) =>
            getLocalStorage("agencyType") === "ld"
              ? currentStepsSortingConstantsForLD[data1.currentStep] -
                currentStepsSortingConstantsForLD[data2.currentStep]
              : currentStepsSortingConstantsForBP[data1.currentStep] -
                currentStepsSortingConstantsForBP[data2.currentStep]
          )
        : rowsData?.sort((data1, data2) =>
            getLocalStorage("agencyType") === "ld"
              ? currentStepsSortingConstantsForLD[data2.currentStep] -
                currentStepsSortingConstantsForLD[data1.currentStep]
              : currentStepsSortingConstantsForBP[data2.currentStep] -
                currentStepsSortingConstantsForBP[data1.currentStep]
          );

    default:
      return [];
  }
};

export const redirectToGoogleAccountConnect = async (
  name,
  brandId,
  sub,
  platform,
  isCalledFromConnectedListAdAccount
) => {
  const clientId = await getClientIdForPlatforms(platform);
  const obj = encodeURI(
    JSON.stringify({
      brandName: name,
      brandId: brandId,
      sub: sub,
      platform: platform,
      isCalledFromConnectedListAdAccount: isCalledFromConnectedListAdAccount
        ? true
        : false,
    }).replace("&", "%26")
  );
  window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${process.env.REACT_APP_BASE_URL}/admin/client&state=${obj}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`;
};

export const redirectToGoogleAccountConnectFromBrandList = async (
  name,
  brandId,
  sub,
  platform,
  isCalledFromConnectedListAdAccount,
  redirectURL
) => {
  const clientId = await getClientIdForPlatforms(platform);
  const obj = encodeURI(
    JSON.stringify({
      brandName: name,
      brandId: brandId,
      sub: sub,
      platform: platform,
      isCalledFromConnectedListAdAccount: isCalledFromConnectedListAdAccount,
    }).replace("&", "%26")
  );
  window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${process.env.REACT_APP_BASE_URL}/${redirectURL}&state=${obj}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`;
};

export const redirectToGoogleAccountConnectFromBrandListBP = async (
  name,
  brandId,
  sub,
  platform,
  isCalledFromConnectedListAdAccount
) => {
  const clientId = await getClientIdForPlatforms(platform);
  const obj = encodeURI(
    JSON.stringify({
      brandName: name,
      brandId: brandId,
      sub: sub,
      platform: platform,
      isCalledFromConnectedListAdAccount: isCalledFromConnectedListAdAccount,
    }).replace("&", "%26")
  );
  window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${clientId}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${process.env.REACT_APP_BASE_URL}/bp-am/brands&state=${obj}&scope=https://www.googleapis.com/auth/adwords https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile`;
};

export const redirectToFacebookAccountConnect = async (
  name,
  brandId,
  sub,
  platform,
  isCalledFromConnectedListAdAccount,
  redirectURL
) => {
  const clientId = await getClientIdForPlatforms(platform);
  const obj = encodeURI(
    JSON.stringify({
      brandName: name,
      brandId: brandId,
      sub: sub,
      platform: platform,
      isCalledFromConnectedListAdAccount: isCalledFromConnectedListAdAccount
        ? true
        : false,
    }).replace("&", "%26")
  );
  const scope =
    "public_profile,ads_read,email,pages_show_list,ads_management,ads_read,business_management,pages_read_engagement,pages_read_user_content,public_profile,read_insights";
  window.location.href = `https://www.facebook.com/v14.0/dialog/oauth?state=${obj}&client_id=${clientId}&redirect_uri=${process.env.REACT_APP_BASE_URL}/${redirectURL}&scope=${scope}`;
  //Commenting this out can be used later for fb authentication using facebook SDK....
  // try {
  //   window.fbLogin((response) => {
  //     console.log(response);
  //     const { accessToken, code } = response.authResponse;
  //     console.log("Response :: ", response);
  //     (async () => {
  //       const dbResponse = await api.put(
  //         `/ad-accounts/${brandId}/${platform}`,
  //         {
  //           authCode: code,
  //           sub: sub,
  //           redirectUrl: "https://localhost:3000/admin/client",
  //         }
  //       );
  //       console.log("dbResponse::", dbResponse);
  //       window.location.href = "/admin/client" + `?platform="facebook"&success`;
  //     })();
  //   });
  // } catch (err) {
  //   console.log(err);
  // }
  // window.location.href = `https://www.facebook.com/v18.0/dialog/oauth?client_id=105625104726669&redirect_uri=https://onboarding-dev.pixis.ai&state="{st=state123abc,ds=123456789}&response_type=code"`;
};
