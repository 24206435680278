import React from 'react';
import { Typography } from '@material-ui/core';
import checkedIcon from '../assets/check-icon.svg';
const CompletedButton = () => {
  return (
    <div className='completed-button'>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: 12,
          lineHeight: '20px',
          color: '#1ABFA1',
        }}
      >
        Completed
      </Typography>
      <div className='lock-icon-image-wrapper'>
        <img src={checkedIcon} alt='' />
      </div>
    </div>
  );
};

export default CompletedButton;
