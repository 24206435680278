import { Typography, Button } from '@mui/material';
import React from 'react';
import openImage from './pmaxIcons/open-campaign-img.svg';
import './openCampaignView.scss';
import { useDispatch } from 'react-redux';
import { addNewAsset, postAssetGroup } from '../../api/pmaxStrategy/slice';
import { BACKEND_STATUS } from '../../utils/accountManagerUtil';
import { updateFormReducer } from '../../api/accountManager/forms/slice';
const OpenCampaignView = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div className='pmax-open-view-wrapper'>
        <img src={openImage} alt='open' />
        <div className='pmax-open-view-details'>
          <Typography
            style={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: 24,
              lineHeight: '27px',
              textAlign: 'center',
              color: '#000000',
            }}
          >
            Open Campaign
          </Typography>
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: 14,
              lineHeight: '26px',
              textAlign: 'center',
              color: '#6F6C90',
              maxWidth: '30rem',
            }}
          >
            Please click here to open this campaign. It can be useful to create
            multiple campaigns to fulfil different user goals and objectives.
          </Typography>
          <Button
            style={{
              width: 85,
              height: 40,
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 14,
              lineHeight: '20px',
              color: 'white',
              background: '#0869FB',
              borderRadius: 8,
              textTransform: 'capitalize',
              marginTop: 18,
            }}
            onClick={() => {
                const data = {
                    googlePmaxStrategy: { status: BACKEND_STATUS.ONGOING }
                };
                dispatch(updateFormReducer(data));
                dispatch(postAssetGroup())
            } }
          >
            Open
          </Button>
        </div>
      </div>
    </>
  );
};

export default OpenCampaignView;
