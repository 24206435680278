import { Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { checkedIcon, uncheckedIcon } from "../../assets/icons/admin/admin";
import UncheckedIcon from "./UncheckedIcon";
import CheckedIcon from "./CheckedIcon";
import {
  StyledAccountContainer,
  StyledAccountDetailCheckBoxContainer,
  StyledAccountDetails,
  StyledAccountId,
  StyledAccountName,
  StyledAlreadyConnectedText,
  StyledStatusLabel,
} from "./styled";
import { useInView } from "react-intersection-observer";

const AccountSelectCheckbox = ({
  adAccountName,
  adAccountId,
  permissions,
  adAccountLinked,
  alreadyConnectedBySelf,
  adAccountIdsSelected,
  setAdAccountIdsSelected,
  enableAllSelectedAccounts,
  adAccount,
  adAccountLocallyList,
  setAdAccountLocallyList,
}) => {
  const { ref, inView } = useInView({ threshold: 0, fallbackInView: true });

  return (
    <StyledAccountContainer
      ref={ref}
      isAccountLinked={
        !enableAllSelectedAccounts &&
        (adAccountLinked || alreadyConnectedBySelf)
      }
    >
      {inView && (
        <>
          <StyledAccountDetailCheckBoxContainer>
            <Checkbox
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
              // checked={true}
              disabled={
                !enableAllSelectedAccounts &&
                (adAccountLinked || alreadyConnectedBySelf)
              }
              value={adAccountId}
              checked={adAccountIdsSelected.includes(adAccountId)}
              onChange={(event) => {
                if (event.target.checked) {
                  setAdAccountLocallyList([...adAccountLocallyList, adAccount]);
                  setAdAccountIdsSelected([
                    ...adAccountIdsSelected,
                    event.target.value,
                  ]);
                } else {
                  setAdAccountLocallyList((olddAccounts) => {
                    return olddAccounts.filter(
                      (adAccount) =>
                        adAccount.adAccountId !== event.target.value
                    );
                  });
                  setAdAccountIdsSelected((adAccounts) => {
                    return adAccounts.filter(
                      (adAccountId) => adAccountId !== event.target.value
                    );
                  });
                }
              }}
            />
            <StyledAccountDetails>
              <StyledAccountName>{adAccountName}</StyledAccountName>
              <StyledAccountId>{adAccountId}</StyledAccountId>
            </StyledAccountDetails>
          </StyledAccountDetailCheckBoxContainer>
          {!adAccountLinked && !alreadyConnectedBySelf ? (
            permissions === "READ" ? (
              <StyledStatusLabel bgColor="#FEF5EB" textColor="#F99F35">
                Read
              </StyledStatusLabel>
            ) : permissions === "WRITE" ? (
              <StyledStatusLabel bgColor="#E8F9F6" textColor="#1ABFA1">
                Write
              </StyledStatusLabel>
            ) : (
              <StyledAlreadyConnectedText>
                Not Enough Permissions
              </StyledAlreadyConnectedText>
            )
          ) : adAccountLinked ? (
            <StyledAlreadyConnectedText>
              Connected to another brand
            </StyledAlreadyConnectedText>
          ) : (
            <StyledAlreadyConnectedText>
              Already connected to this brand
            </StyledAlreadyConnectedText>
          )}
        </>
      )}
    </StyledAccountContainer>
  );
};

export default AccountSelectCheckbox;
