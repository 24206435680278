//order of components to render
export const clientDashboardIds = {
  RENDER_HOME_PAGE: "RENDER_HOME_PAGE",
  QUEST_BASIC_QUEST1: "QUEST_BASIC_QUEST1",
  QUEST_BASIC_QUEST2: "QUEST_BASIC_QUEST2",
  QUEST_BASIC_QUEST3: "QUEST_BASIC_QUEST3",
  QUEST_BASIC_QUEST4: "QUEST_BASIC_QUEST4",
  QUEST_BASIC_QUEST5: "QUEST_BASIC_QUEST5",
  QUEST_BASIC_QUEST6: "QUEST_BASIC_QUEST6",
  QUEST_BASIC_QUEST7: "QUEST_BASIC_QUEST7",
  QUEST_BASIC_QUEST8: "QUEST_BASIC_QUEST8",
  QUEST_BASIC_QUEST9: "QUEST_BASIC_QUEST9",
  QUEST_BASIC_QUEST10: "QUEST_BASIC_QUEST10",
  PLATFORM_INFORMATION_QUEST1: "PLATFORM_INFORMATION_QUEST1",
  PLATFORM_INFORMATION_QUEST2: "PLATFORM_INFORMATION_QUEST2",
  PLATFORM_INFORMATION_QUEST3: "PLATFORM_INFORMATION_QUEST3",
  PLATFORM_INFORMATION_QUEST4: "PLATFORM_INFORMATION_QUEST4",
  PLATFORM_INFORMATION_QUEST5: "PLATFORM_INFORMATION_QUEST5",
  PLATFORM_INFORMATION_QUEST6: "PLATFORM_INFORMATION_QUEST6",
  // PLATFORM_INFORMATION_QUEST7: "PLATFORM_INFORMATION_QUEST7",
  // PLATFORM_INFORMATION_QUEST8:"PLATFORM_INFORMATION_QUEST8",
  GROWTH_GOALS_QUEST1: "GROWTH_GOALS_QUEST1",
  REVIEW_DETAILS_CLIENT: "REVIEW_DETAILS_CLIENT",
  SCOPE_GOOGLE_ADS_SETUP: "SCOPE_GOOGLE_ADS_SETUP",
  SCOPE_FACEBOOK_ADS_SETUP: "SCOPE_FACEBOOK_ADS_SETUP",
  SCOPE_CRO_ADS_SETUP: "SCOPE_CRO_ADS_SETUP",
  SCOPE_PRICING_ADS_SETUP: "SCOPE_PRICING_ADS_SETUP",
  READ_ACCESS: "READ_ACCESS",
  PROPOSAL_TAB: "PROPOSAL_TAB",
  SUGGEST_CHANGES_TAB: "SUGGEST_CHANGES_TAB",
  QUEST_INTAKE_FORM1: "QUEST_INTAKE_FORM1",
  QUEST_INTAKE_FORM2: "QUEST_INTAKE_FORM2",
  QUEST_INTAKE_FORM3: "QUEST_INTAKE_FORM3",
  QUEST_INTAKE_FORM4: "QUEST_INTAKE_FORM4",
  QUEST_INTAKE_FORM5: "QUEST_INTAKE_FORM5",
  QUEST_INTAKE_FORM6: "QUEST_INTAKE_FORM6",
  QUEST_INTAKE_FORM7: "QUEST_INTAKE_FORM7",
  QUEST_INTAKE_FORM8: "QUEST_INTAKE_FORM8",
  QUEST_INTAKE_FORM9: "QUEST_INTAKE_FORM9",
  QUEST_INTAKE_FORM_PREFERENCES1: "QUEST_INTAKE_FORM_PREFERENCES1",
  QUEST_INTAKE_FORM_PREFERENCES2: "QUEST_INTAKE_FORM_PREFERENCES2",
  QUEST_INTAKE_FORM_PREFERENCES3: "QUEST_INTAKE_FORM_PREFERENCES3",
  QUEST_INTAKE_FORM_PREFERENCES4: "QUEST_INTAKE_FORM_PREFERENCES4",
  QUEST_INTAKE_FORM_PREFERENCES5: "QUEST_INTAKE_FORM_PREFERENCES5",
  QUEST_INTAKE_FORM_PREFERENCES6: "QUEST_INTAKE_FORM_PREFERENCES6",
  QUEST_INTAKE_FORM_AUDIENCE1: "QUEST_INTAKE_FORM_AUDIENCE1",
  QUEST_INTAKE_FORM_AUDIENCE2: "QUEST_INTAKE_FORM_AUDIENCE2",
  QUEST_INTAKE_FORM_AUDIENCE3: "QUEST_INTAKE_FORM_AUDIENCE3",
  QUEST_INTAKE_FORM_AUDIENCE4: "QUEST_INTAKE_FORM_AUDIENCE4",
  QUEST_INTAKE_FORM_AUDIENCE5: "QUEST_INTAKE_FORM_AUDIENCE5",
  QUEST_INTAKE_FORM_AUDIENCE6: "QUEST_INTAKE_FORM_AUDIENCE6",
  QUEST_INTAKE_FORM_AUDIENCE7: "QUEST_INTAKE_FORM_AUDIENCE7",
  QUEST_INTAKE_FORM_AUDIENCE8: "QUEST_INTAKE_FORM_AUDIENCE8",
  QUEST_INTAKE_FORM_AUDIENCE9: "QUEST_INTAKE_FORM_AUDIENCE9",
  QUEST_INTAKE_FORM_AUDIENCE10: "QUEST_INTAKE_FORM_AUDIENCE10",
  QUEST_INTAKE_FORM_AUDIENCE11: "QUEST_INTAKE_FORM_AUDIENCE11",
  QUEST_INTAKE_FORM_AUDIENCE12: "QUEST_INTAKE_FORM_AUDIENCE12",
  QUEST_INTAKE_FORM_BRANDING1: "QUEST_INTAKE_FORM_BRANDING1",
  QUEST_INTAKE_FORM_BRANDING2: "QUEST_INTAKE_FORM_BRANDING2",
  QUEST_INTAKE_FORM_BRANDING3: "QUEST_INTAKE_FORM_BRANDING3",
  QUEST_INTAKE_FORM_BRANDING4: "QUEST_INTAKE_FORM_BRANDING4",
  QUEST_INTAKE_FORM_BRANDING5: "QUEST_INTAKE_FORM_BRANDING5",
  QUEST_INTAKE_FORM_BRANDING6: "QUEST_INTAKE_FORM_BRANDING6",
  QUEST_INTAKE_FORM_BRANDING7: "QUEST_INTAKE_FORM_BRANDING7",
  QUEST_INTAKE_FORM_SOCIAL_PROOF1: "QUEST_INTAKE_FORM_SOCIAL_PROOF1",
  QUEST_INTAKE_FORM_SOCIAL_PROOF2: "QUEST_INTAKE_FORM_SOCIAL_PROOF2",
  QUEST_INTAKE_FORM_SOCIAL_PROOF3: "QUEST_INTAKE_FORM_SOCIAL_PROOF3",
  QUEST_FB_TARGETING1: "QUEST_FB_TARGETING1",
  QUEST_FB_TARGETING2: "QUEST_FB_TARGETING2",
  QUEST_FB_TARGETING3: "QUEST_FB_TARGETING3",
  QUEST_FB_TARGETING4: "QUEST_FB_TARGETING4",
  QUEST_FB_TARGETING5: "QUEST_FB_TARGETING5",
  EDIT_RESPONSES_INTAKE_FORM: "EDIT_RESPONSES_INTAKE_FORM",
  CLIENT_COMPLETE_ACCESS: "CLIENT_COMPLETE_ACCESS",
  STRATEGY_DECK_INTRO: "STRATEGY_DECK_INTRO",
  STRATEGY_DECK_GOOGLE: "STRATEGY_DECK_GOOGLE",
  STRATEGY_DECK_FACEBOOK: "STRATEGY_DECK_FACEBOOK",
  STRATEGY_DECK_SUGGEST_CHANGES: "STRATEGY_DECK_SUGGEST_CHANGES",
  CAMPAIGN_PREVIEW_CLIENT: "CAMPAIGN_PREVIEW_CLIENT",
  CAMPAIGN_PREVIEW_FACEBOOK: "CAMPAIGN_PREVIEW_FACEBOOK",
  REVIEW_QUESTIONIER_RESPONSES: "REVIEW_QUESTIONIER_RESPONSES",
  CREATIVE_APPROVAL_ADCOPIES: "CREATIVE_APPROVAL_ADCOPIES",
  CREATIVE_APPROVAL_CREATIVES: "CREATIVE_APPROVAL_CREATIVES",
  CREATIVE_APPROVAL_LANDING_PAGES: "CREATIVE_APPROVAL_LANDING_PAGES",
  CREATIVE_APPROVAL_AB_TEST: "CREATIVE_APPROVAL_AB_TEST",
};

export const mappedLdClientCompoNameWithIndex = new Map(
  Object.keys(clientDashboardIds).map((key, index) => [index, key])
);

export const LDcomponentWithIndexClient = Object.keys(
  clientDashboardIds
).reduce((obj, value, index) => {
  return { ...obj, [value]: index };
}, {});

// return index of plaformName in platforms
const isPlatformSelected = (form, platformName) => {
  return form?.onboardingInfo?.platformInfo?.platforms?.findIndex(
    (platform) => platform.platform === platformName
  );
};

// return [campaignObj, isGeneratingCampaign]
export const getFBCampaignObjectClient = (generatedCampaignsInfo = []) => {
  // {campaignType: campaignId}
  const campaignObj = {
    lookalike: null,
    interest: null,
    mof: null,
    bof: null,
  };

  (generatedCampaignsInfo || []).forEach((info) => {
    if (
      info.campaignType === "top_of_the_funnel_lookalike" &&
      info.responseData &&
      info.responseData.status === "SUCCESS"
    ) {
      campaignObj.lookalike = info.responseData.data.campaign_id;
    }
    if (
      info.campaignType === "top_of_the_funnel_interest" &&
      info.responseData &&
      info.responseData.status === "SUCCESS"
    ) {
      campaignObj.interest = info.responseData.data.campaign_id;
    }
    if (
      info.campaignType === "middle_of_the_funnel" &&
      info.responseData &&
      info.responseData.status === "SUCCESS"
    ) {
      campaignObj.mof = info.responseData.data.campaign_id;
    }
    if (
      info.campaignType === "bottom_of_the_funnel" &&
      info.responseData &&
      info.responseData.status === "SUCCESS"
    ) {
      campaignObj.bof = info.responseData.data.campaign_id;
    }
  });

  return campaignObj;
};

export const getGoogleCampaignObjectClient = (generatedCampaignsInfo = []) => {
  const campaignObj = {
    brandCampaign: null,
    competitorCampaign: null,
    serviceCampaign: null,
  };

  (generatedCampaignsInfo || []).forEach((info) => {
    if (info.campaignType === "BRAND" && info.campaignId) {
      campaignObj.brandCampaign = info.campaignId;
    }
    if (info.campaignType === "COMPETITOR" && info.campaignId) {
      campaignObj.competitorCampaign = info.campaignId;
    }
    if (info.campaignType === "SERVICE" && info.campaignId) {
      campaignObj.serviceCampaign = info.campaignId;
    }
  });

  return campaignObj;
};
// return whether analytics dashboard should be enable or not
// return - true or false
export const showAnalyticsDashboard = (form) => {
  let canShow = false;
  const googleCampaignObj = getGoogleCampaignObjectClient(
    form?.googleSearchAdsGeneratedCampaignsInfo
  );
  const fbCampaignObject = getFBCampaignObjectClient(
    form?.fbAdsGeneratedCampaignsInfo
  );
  if (
    isPlatformSelected(form, "google-ads") !== -1 &&
    isPlatformSelected(form, "facebook-ads") !== -1
  ) {
    if (
      googleCampaignObj.brandCampaign &&
      googleCampaignObj.competitorCampaign &&
      googleCampaignObj.serviceCampaign &&
      fbCampaignObject.lookalike &&
      fbCampaignObject.interest &&
      fbCampaignObject.mof &&
      fbCampaignObject.mof
    ) {
      canShow = true;
    }
  } else if (
    isPlatformSelected(form, "google-ads") !== -1 &&
    googleCampaignObj.brandCampaign &&
    googleCampaignObj.competitorCampaign &&
    googleCampaignObj.serviceCampaign
  ) {
    canShow = true;
  } else if (
    isPlatformSelected(form, "facebook-ads") !== -1 &&
    fbCampaignObject.lookalike &&
    fbCampaignObject.interest &&
    fbCampaignObject.mof &&
    fbCampaignObject.mof
  ) {
    canShow = true;
  }
  return canShow;
};

export const showCampaignPreviewClient = (form) => {
  let canShow = false;

  const fbCampaignObject = getFBCampaignObjectClient(
    form?.fbAdsGeneratedCampaignsInfo
  );
  const googleCampaignObj = getGoogleCampaignObjectClient(
    form?.googleSearchAdsGeneratedCampaignsInfo
  );

  if (
    (googleCampaignObj.brandCampaign &&
      googleCampaignObj.competitorCampaign &&
      googleCampaignObj.serviceCampaign) ||
    (fbCampaignObject.lookalike &&
      fbCampaignObject.interest &&
      fbCampaignObject.mof &&
      fbCampaignObject.mof)
  ) {
    canShow = true;
  }
  return canShow;
};

export const googleCampaignPreview = (form) => {
  let canPreview = false;
  const googleCampaignObj = getGoogleCampaignObjectClient(
    form?.googleSearchAdsGeneratedCampaignsInfo
  );
  if (
    googleCampaignObj.brandCampaign &&
    googleCampaignObj.competitorCampaign &&
    googleCampaignObj.serviceCampaign
  ) {
    canPreview = true;
  }
  return canPreview;
};

export const facebookCampaignPreview = (form) => {
  let canPreview = false;
  const fbCampaignObject = getFBCampaignObjectClient(
    form?.fbAdsGeneratedCampaignsInfo
  );
  if (
    fbCampaignObject.lookalike &&
    fbCampaignObject.interest &&
    fbCampaignObject.mof &&
    fbCampaignObject.mof
  ) {
    canPreview = true;
  }
  return canPreview;
};
