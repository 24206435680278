import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { AddCircleOutline, ArrowBackIosNew, Clear, ExpandMore } from "@mui/icons-material";
import React from "react";
import { Filter, Metric } from "../../models/chart";
import TextFieldElement from "./TextFieldElement";
import MetricList from "./MetricList";
import { useSelector } from "react-redux";
// import { ReactComponent as FilterIcon } from "../../Assets/Icons/openFilter.svg";
import FilterPopup from "../Filteration/filterpopup/FilterPopup";
import { useDispatch } from "react-redux";
import { defaultFBState } from "../../models/defaultData";
// import { updateFilterChartMap } from "../../api/dashboard/action";

export default function SingleMetricSettings({ chart, channel, currentMetric, dimension, isKPI, onChange, onClose, handleChartChange, updateFilter, handleAllChartsForceReload }) {
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [table, setTable] = React.useState(chart.table);
  const state = useSelector(state => channel.getState(state)) ?? defaultFBState;
  const metrics = Array.isArray(state.metrics) ? state.metrics : state.metrics?.[table?.id] ?? [];
  const tables = state.tables;

  const dispatch = useDispatch();
  // const _updateFilterChartMap = (_) => dispatch(updateFilterChartMap(_));
  // const filterChartMap = useSelector(state => state.dashboard.filterChartMap);

  const [filterListOpen, setFilterListOpen] = React.useState(false);
  const [metricFilter, setMetricFilter] = React.useState(currentMetric.filter)
  const [filterString, setFilterString] = React.useState(metricFilter.toString());

  const updateMetricFilter = (filter) => {
    updateFilter(filter);
    setMetricFilter(filter)
    setFilterString(filter.toString());
    if (!filter.id) return;
    // let allCharts = filterChartMap[filter.id] ?? []
    // if (!allCharts.includes(chart.id)) {
    //   // _updateFilterChartMap({ ...filterChartMap, [filter.id]: (filterChartMap[filter.id] ?? []).concat(chart.id) })
    // }
  }

  React.useEffect(() => {
    if (table && table !== chart.table) {
      handleChartChange("table", table);
      onChange(Metric.new(chart));
    }
  }, [table]);

  React.useEffect(() => {
    if (channel.type !== chart.channelType && channel.type !== chart.channelType.id) {
      setTable(null);
      onChange(Metric.new(chart));
    }
  }, [channel])


  return (
    <div>
      {!isKPI && <IconButton
        onClick={onClose}
        sx={{ marginRight: "100px", marginLeft: "10px" }}>
        <ArrowBackIosNew fontSize="small" />
      </IconButton>}
      <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
        <AccordionSummary
          sx={{ maxHeight: "30px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <h4 className="inter bold" >
            Edit Metric
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          {/* report type */}
          {isKPI && tables && <div>
            <h4 className="inter bold">
              Report type
            </h4>
            <MetricList
              channel={channel}
              metric={table}
              metrics={tables}
              isEditable={true}
              onClick={setTable}
              defaultTitle="Add Report Type"
            />
            <br />
          </div>}

          {dimension && <div>
            <h4 className="inter bold" style={{ marginBottom: "10px" }}>Dimension</h4>
            <TextFieldElement channel={channel} defaultValue={dimension} />
          </div>}

          <div style={{ marginTop: "15px", marginRight: "66px" }}>
            <h4 className="inter bold">Metric</h4>
            <MetricList
              key={channel + metrics?.length}
              channel={channel}
              metric={currentMetric?.metric}
              metrics={metrics}
              isEditable={true}
              onClick={metric => onChange(Metric.new(chart, metric))}
            />
          </div>

          {!channel.isBlend && <div style={{ marginTop: "15px" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "15px", width: "338px" }}>
              <h4 className="inter bold" style={{ margin: "auto 0px" }}>
                Filters
              </h4>
              <Typography
                style={{ color: "#0869FB", margin: "auto 0px" }}
                onClick={() => { setFilterListOpen(true) }}
              >
                {/* <FilterIcon /> See All */}
              </Typography>
            </div>
            {filterString &&
              <div
                style={{
                  marginTop: "15px",
                  marginBottom: "5px",
                  display: "flex",
                  flexDirection: "row",
                  width: "400px"
                }}>
                <div className="filter-list">
                  {filterString}
                </div>
                <IconButton
                  onClick={() => {
                    setFilterString(null);
                    setMetricFilter(Filter.new(chart))
                    onChange(Metric.new(chart, currentMetric.metric))
                  }}
                  disableRipple
                  disableFocusRipple
                  aria-label="delete">
                  <Clear />
                </IconButton>
              </div>
            }
            <Button
              sx={{
                justifyContent: "flex-start",
                color: "#333333",
                borderColor: "#DDDDDD",
                borderRadius: "8px",
                height: "52px",
                width: "340px",
                padding: "12px",
                marginTop: "12px"
              }}
              fullWidth
              variant="outlined"
              size="small"
              startIcon={<AddCircleOutline />}
              onClick={() => setIsFilterOpen(true)}>
              Add a filter
            </Button>
          </div>}
        </AccordionDetails>
      </Accordion>
      {isFilterOpen &&
        <FilterPopup
          chart={chart}
          onClose={() => setIsFilterOpen(false)}
          currentFilter={metricFilter}
          onChange={filter => {
            updateMetricFilter(filter);
            handleAllChartsForceReload(filter.id)
          }}
        />
      }
    </div>
  );
};
