import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Box,
  FormControl,
  MenuItem,
  Select
} from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { next_icon as nextIcon, previous_icon as previousIcon } from "../../../assets/icons/Audit/Audit";
import {
  adTabAds,
  adTabCopies,
  adTabCreatives,
} from "../../../utils/auditHdmUtil";
import GoToNextCompoButton from "../GoToNextCompoButton";
import AdCopies from "../../HDMAccountManager/Audit/Ad/AdCopies";
import Ads from "../../HDMAccountManager/Audit/Ad/Ads";
import ButtonToggle from "../../HDMAccountManager/Audit/Ad/ButtonToggle";
import Creative from "../../HDMAccountManager/Audit/Ad/Creative";
import "./ad.css";

const CommonAuditAd = ({ 
  onboardingInfo,
  onClick,
  nextDisable,
  onBack,
  onNext,
}) => {
  const [value, setValue] = React.useState("1");
  //ads type
  const [adsType, setAdsType] = React.useState("CPA");
  const [creativesType, setCreativesType] = useState("CPA");
  const [adCopiesType, setAdCopiesType] = useState("CPA");
  //best worst
  const [adsBestWorst, setAdBestWorst] = useState("best");
  const [creativesBestWorst, setCreativesBestWorst] = useState("best");
  const [adCopiesBestWorst, setAdCopiesBestWorst] = useState("best");
  //ref
  const [creativeSliderRef, setCreativeSliderRef] = React.useState(null);
  const [adCopiesSliderRef, setadCopiesSliderRef] = React.useState(null);
  const [adsSliderRef, setAdsSliderRef] = React.useState(null);
  const campaign = useSelector((state) => state.hdmAudit.auditDetails);
  const [isClicked, setIsClicked] = useState(false);

  const onClickHandler = () => {
      onClick?.();
  }
  const onNextClickHandler = () => {
    if(!isClicked){
      onNext?.();
      setIsClicked(true);
    }
  }
  //best worst onClickHandler
  const onClickHandlerAds = () => {
    if (adsBestWorst === "best") {
      setAdBestWorst("worst");
    } else {
      setAdBestWorst("best");
    }
  };
  const onClickHandlerCreatives = () => {
    if (creativesBestWorst === "best") {
      setCreativesBestWorst("worst");
    } else {
      setCreativesBestWorst("best");
    }
  };
  const onClickHandlerAdCopies = () => {
    if (adCopiesBestWorst === "best") {
      setAdCopiesBestWorst("worst");
    } else {
      setAdCopiesBestWorst("best");
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    infinite: false,
    variableWidth: true,
    arrows: false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div style={{ width: "100%", height: "75vh", overflowX: "hidden" }}>
      <Box
        sx={{
          typography: "body1",
          margin: "1.5rem 1.5rem 0 1.5rem",
          background: "#FFFFFF",
          border: "0.7699px solid #EFF0F7",
          boxShadow: "0px 1.5398px 9.2388px rgba(20, 20, 43, 0.08)",
          borderRadius: 4,
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab
                label="Ads"
                value="1"
                // icon={<CheckedIcon />}
                style={{
                  width: "calc(100%/4)",
                  textTransform: "capitalize",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 14,
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.25rem",
                }}
              />
              <Tab
                label="Creatives"
                value="2"
                // icon={<CheckedIcon />}
                style={{
                  width: "calc(100%/4)",
                  textTransform: "capitalize",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 14,
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.25rem",
                }}
              />
              <Tab
                label="Ad Copies"
                value="3"
                // icon={<CheckedIcon />}
                style={{
                  width: "calc(100%/4)",
                  textTransform: "capitalize",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 14,
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.25rem",
                }}
              />
              <Tab
                label="Placements"
                value="4"
                // icon={<CheckedIcon />}
                style={{
                  width: "calc(100%/4)",
                  textTransform: "capitalize",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: 14,
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.25rem",
                }}
                disabled
              />
            </TabList>
          </Box>
          {/*ads*/}
          <TabPanel value="1" style={{ paddingTop: "12px" }}>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "end",
                  marginRight: "1.5rem",
                }}
              >
                <ButtonToggle
                  selectedValue={adsBestWorst}
                  clickHandler={onClickHandlerAds}
                />
                <FormControl style={{ minWidth: "165px" }} size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={adsType}
                    onChange={(e) => setAdsType(e.target.value)}
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "22px",
                    }}
                  >
                    <MenuItem value={"CPA"}>CPA</MenuItem>
                    <MenuItem value={"CTR"}>CTR</MenuItem>
                    <MenuItem value={"CPM"}>CPM</MenuItem>
                    <MenuItem value={"CVR"}>CVR</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                onClick={adsSliderRef?.slickPrev}
                style={{
                  position: "absolute",
                  left: "-3.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 999,
                  cursor: "pointer",
                }}
              >
                <img src={previousIcon} alt="" />
              </div>
              <div
                onClick={adsSliderRef?.slickNext}
                style={{
                  position: "absolute",
                  right: "-4.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 999,
                  cursor: "pointer",
                }}
              >
                <img src={nextIcon} alt="" />
              </div>
              <Slider
                ref={setAdsSliderRef}
                {...sliderSettings}
                style={{
                  marginTop: "0.5rem",
                  overflow: "hidden",
                  minHeight: "454px",
                }}
              >
                {campaign &&
                  campaign?.ad &&
                  adTabAds(adsBestWorst, adsType, campaign?.ad?.values)?.map(
                    (ad, index) => (
                      <Ads
                        imageUrl={ad[15]}
                        imageUrl2={ad[17]}
                        ctr={ad[7]}
                        cpm={ad[8]}
                        cvr={ad[9]}
                        cpa={ad[6]}
                        primaryText={ad[10]}
                        caption={ad[16]}
                        ctaUrl={ad[13]}
                        headline={ad[12]}
                        description={ad[11]}
                        websiteUrl={onboardingInfo?.clientAccess?.websiteUrl}
                      />
                    )
                  )}
              </Slider>
            </div>
          </TabPanel>
          {/*creatives*/}
          <TabPanel value="2" style={{ paddingTop: "12px" }}>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "end",
                  marginRight: "1.5rem",
                }}
              >
                <ButtonToggle
                  selectedValue={creativesBestWorst}
                  clickHandler={onClickHandlerCreatives}
                />
                <FormControl style={{ minWidth: "165px" }} size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={creativesType}
                    onChange={(e) => setCreativesType(e.target.value)}
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "22px",
                    }}
                  >
                    <MenuItem value={"CPA"}>CPA</MenuItem>
                    <MenuItem value={"CTR"}>CTR</MenuItem>
                    <MenuItem value={"CPM"}>CPM</MenuItem>
                    <MenuItem value={"CVR"}>CVR</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                onClick={creativeSliderRef?.slickPrev}
                style={{
                  position: "absolute",
                  left: "-3.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 999,
                  cursor: "pointer",
                }}
              >
                <img src={previousIcon} alt="" />
              </div>
              <div
                onClick={creativeSliderRef?.slickNext}
                style={{
                  position: "absolute",
                  right: "-4.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 999,
                  cursor: "pointer",
                }}
              >
                <img src={nextIcon} alt="" />
              </div>
              <Slider
                ref={setCreativeSliderRef}
                {...sliderSettings}
                style={{ marginTop: "0.5rem" }}
              >
                {campaign &&
                  campaign?.ad &&
                  adTabCreatives(
                    creativesBestWorst,
                    creativesType,
                    campaign?.ad?.values
                  )?.map((ad, index) => (
                    <Creative
                      imageUrl={ad[15]}
                      imageUrl2={ad[17]}
                      ctr={ad[7]}
                      cpm={ad[8]}
                      cvr={ad[9]}
                      cpa={ad[6]}
                    />
                  ))}
              </Slider>
            </div>
          </TabPanel>
          {/*ad copies*/}
          <TabPanel value="3" style={{ paddingTop: "12px" }}>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "end",
                  marginRight: "1.5rem",
                }}
              >
                <ButtonToggle
                  selectedValue={adCopiesBestWorst}
                  clickHandler={onClickHandlerAdCopies}
                />
                <FormControl style={{ minWidth: "165px" }} size="small">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={adCopiesType}
                    onChange={(e) => setAdCopiesType(e.target.value)}
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginLeft: "22px",
                    }}
                  >
                    <MenuItem value={"CPA"}>CPA</MenuItem>
                    <MenuItem value={"CTR"}>CTR</MenuItem>
                    <MenuItem value={"CPM"}>CPM</MenuItem>
                    <MenuItem value={"CVR"}>CVR</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                onClick={adCopiesSliderRef?.slickPrev}
                style={{
                  position: "absolute",
                  left: "-3.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 999,
                  cursor: "pointer",
                }}
              >
                <img src={previousIcon} alt="" />
              </div>
              <div
                onClick={adCopiesSliderRef?.slickNext}
                style={{
                  position: "absolute",
                  right: "-4.75rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 999,
                  cursor: "pointer",
                }}
              >
                <img src={nextIcon} alt="" />
              </div>
              <div
                style={{
                  marginTop: "0.5rem",
                  width: "100%",
                }}
              >
                <Slider ref={setadCopiesSliderRef} {...sliderSettings}>
                  {campaign &&
                    campaign?.ad &&
                    adTabCopies(
                      adCopiesBestWorst,
                      adCopiesType,
                      campaign?.ad?.values
                    )?.map((ad, index) => (
                      <AdCopies
                        imageUrl={ad[11]}
                        ctr={ad[7]}
                        cpm={ad[8]}
                        cvr={ad[9]}
                        cpa={ad[6]}
                        primaryText={ad[10]}
                        description={ad[11]}
                        headline={ad[12]}
                      />
                    ))}
                </Slider>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
      <div className="preview_footer_row">
        <div className="footer_con">
          <button
            className="back"
            onClick={onBack}
          >
            BACK
          </button>
          <GoToNextCompoButton
            title="Next"
            disabled={nextDisable()}
            onNext={onNextClickHandler}
            onClickHandler={onClickHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default CommonAuditAd;
