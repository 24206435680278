const INITIAL_STATE = {
  audiences: [],
  hdmAdsFacebook: [],
  keywords: [],
  facebookAds: [],
  lookalikeChanges: false,
  interestChange: false,
  mofChanges: false,
  bofChanges: false,
  roas: 0,
  roas3Months: 0,
  roas6Months: 0,
  roas12Months: 0,
  hasCalledCAI: false
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_AUDIENCE_HDM_AM":
      return { ...state, audiences: action.payload };
    case "UPDATE_HDM_ADS_AM_FACEBOOK":
      return { ...state, hdmAdsFacebook: action.payload };
    case "UPDATE_HDM_KEYWORDS_AM_FACEBOOK":
      return { ...state, keywords: action.payload };
    case "UPDATE_HDM_FACEBOOK_ADS":
      return { ...state, facebookAds: action.payload };
    case "UPDATE_CHANGES_STATUS":
      return {
        ...state,
        [action.payload.campaignType]: action.payload.value,
      };
    case "UPDATE_FACEBOOK_ROAS":
      return {
        ...state,
        roas: action.payload,
      };
    case "UPDATE_FACEBOOK_ROAS_3MONTHS":
      return {
        ...state,
        roas3Months: action.payload,
      };
    case "UPDATE_FACEBOOK_ROAS_6MONTHS":
      return {
        ...state,
        roas6Months: action.payload,
      };
    case "UPDATE_FACEBOOK_ROAS_12MONTHS":
      return {
        ...state,
        roas12Months: action.payload,
      };
    case "UPDATE_HAS_CALLED_CAI":
      return {
        ...state,
        hasCalledCAI: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
