const imageBaseUrl = process.env.REACT_APP_BACKEND_BASE_IMAGE_URL;
export const AddTab  = `${imageBaseUrl}/assets/24324366-d06d-42eb-bbff-13a550798986`
export const bar  = `${imageBaseUrl}/assets/c2fcbdfd-c437-4126-839e-76791e2b30b4`
export const custom_report  = `${imageBaseUrl}/assets/844ffe44-7adc-4e5d-a909-9290a0e1c6a0`
export const DataBlend  = `${imageBaseUrl}/assets/7378ff02-3f6f-4c2c-89c9-9297bde6eb40`
export const delete_icon  = `${imageBaseUrl}/assets/2bfdf101-1a91-4a03-9b58-a29f545f301b`
export const Delete2  = `${imageBaseUrl}/assets/d5b8c7e0-2246-4866-9a29-641b6566fd5d`
export const funnel = `${imageBaseUrl}/assets/aa4f1dc7-a3d9-4e8e-8386-9fba44ac49e4`
export const Group = `${imageBaseUrl}/assets/4544b964-d238-4ae0-a6a9-712db73bc021`
export const kpi = `${imageBaseUrl}/assets/21d1c0d0-cf6c-471d-a653-7ecf9866defe`
export const line = `${imageBaseUrl}/assets/501a4447-ab14-4b00-80a6-67656bc36369`
export const linebar = `${imageBaseUrl}/assets/33943aed-a9a0-4eaa-b865-1a8a314fa2e9`
export const logos_add_chart = `${imageBaseUrl}/assets/2485e516-1bdc-45ed-b300-f52a43f89fa5`
export const logos_calander = `${imageBaseUrl}/assets/a3f7c4db-532e-4c36-ad99-e8cdfa0cb9cf`
export const logos_facebook = `${imageBaseUrl}/assets/84704a2a-19ac-4297-82f2-a1001e4fc27f`
export const logos_google_ads = `${imageBaseUrl}/assets/405d9f56-59d0-4977-bcf7-d62f6b84c3f5`
export const logos_graphs = `${imageBaseUrl}/assets/85ebcf3a-960f-4563-bf03-b3c1e9c15cd8`
export const logos_pixis = `${imageBaseUrl}/assets/4a87cca1-09d7-447d-b490-1450b17dffe9`
export const logos_remove_chart = `${imageBaseUrl}/assets/10a22419-ed7a-41c2-a9ee-abcd15f65794`
export const logos_sixdots = `${imageBaseUrl}/assets/aeae267b-cbb0-468d-930d-3d34e2d65213`
export const logos_table = `${imageBaseUrl}/assets/3c37281a-72c8-4ad4-8f58-9d9f79e4dfe0`
export const logos_threedots = `${imageBaseUrl}/assets/ce0d5634-a61b-41bd-a650-c9b6e05dc38e`
export const table = `${imageBaseUrl}/assets/110eff89-8dd1-43d7-acfd-99e6c7996a26`
