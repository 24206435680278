import { all, delay, put, takeLatest, select } from "redux-saga/effects";
import { api } from "../../../api/index";
import { BACKEND_STATUS, getFBCampaignObject } from "../../../utils/accountManagerUtil";
import { getFormByFormIdReducer } from "../../accountManager/forms/slice";
import {
  getPages,
  updatePages,
  getAdPixels,
  getPixelEvents,
  updatePixels,
  updatePixelEvents,
} from "./action";
import {
  updateLookalike,
  updateInterest,
  updatemof,
  updatebof,
} from "./action";

function* getCampaignLocation(data) {
  try {
    yield delay(500);
    const res = yield api.get(
      `/facebook-ads/locations?location_q=${data.payload.value}`
    );
    console.log("in saga", res.data);
    yield put({
      type: "updateLocationCampaign",
      payload: { ...res.data, value: data.payload.value },
    });
  } catch (err) {
    console.log(err);
  }
}

function* getFbPages() {
  try {
    const res = yield api.get("/facebook-ads/pages");
    yield put(updatePages(res?.data?.pages));
  } catch (err) {
    console.log(err);
  }
}

function* getFbPixels() {
  let accountId="";
  //ld am
  if(localStorage.getItem("agencyId") === "1"){
    accountId=yield select((state) => state.form?.form?.fbAdsAccountId);
  }else{
    //hdm am
    accountId = yield select((state) => state.hdmFormAm?.fbAdsAccountId);
  }
  try {
    const res = yield api.get(
      `/facebook-ads/ad-accounts/${accountId}/ad-pixels`
    );
    yield put(updatePixels(res?.data?.pixels));
  } catch (err) {
    console.log(err);
  }
}

function* getFbPixelEvents(action) {
  try {
    const res = yield api.get(
      `/facebook-ads/ad-pixels/${action.payload}/events`
    );
    yield put(updatePixelEvents({ [action.payload]: res?.data?.events }));
  } catch (err) {
    console.log(err);
  }
}

function* saveHdmAdmFormDataForCampaign(action) {
  yield delay(500);
  let client = yield select((state) => action.payload.agencyType === 'LD' ? state.form.form :state.hdmFormAm);
  const campInfo = yield select((state) => state.hdmCampaign);
  let campaingInfo = { ...(client.fbAdsCampaignInfo || {}) };

  if (action.payload.campaignType === "topOfTheFunnelLookalike") {
    campaingInfo.topOfTheFunnelLookalike = {
      ...campInfo.lookalike,
      adsets: (campInfo?.lookalike?.adsets || []).map((adset) => {
        return {
          ...adset,
          status: BACKEND_STATUS.COMPLETED
        }
      }),
      status: BACKEND_STATUS.COMPLETED,
    };
  }

  if (action.payload.campaignType === "topOfTheFunnelInterest") {
    campaingInfo.topOfTheFunnelInterest = {
      ...campInfo.interest,
      adsets: (campInfo?.interest?.adsets || []).map((adset) => {
        let tempAdset = {...adset};
        delete tempAdset.audience;
        return {
          ...tempAdset,
          status: BACKEND_STATUS.COMPLETED
        }
      }),
      status: BACKEND_STATUS.COMPLETED,
    };
  }

  if (action.payload.campaignType === "middleOfTheFunnel") {
    campaingInfo.middleOfTheFunnel = {
      ...campInfo.MOF,
      adsets: (campInfo?.MOF?.adsets || []).map((adset) => {
        return {
          ...adset,
          status: BACKEND_STATUS.COMPLETED
        }
      }),
      status: BACKEND_STATUS.COMPLETED,
    };
  }
  if (action.payload.campaignType === "bottomOfTheFunnel") {
    campaingInfo.bottomOfTheFunnel = {
      ...campInfo.BOF,
      adsets: (campInfo?.BOF?.adsets || []).map((adset) => {
        return {
          ...adset,
          status: BACKEND_STATUS.COMPLETED
        }
      }),
      status: BACKEND_STATUS.COMPLETED,
    };
  }
  let clientForm = {
    _id: client._id,
    brandId: client.brandId,
    fbAdsCampaignInfo: campaingInfo,
  };
  try {
    const res = yield api.post("/client-forms", clientForm, false);
    yield put({
      type: "UPDATE_WHOLE_HDM_AM_DETAILS",
      payload: res.data,
    });
    yield put({
      type: "UPDATE_HDM_CAMPAIGN_CONFIRM_MODAL",
    });
    yield put(getFormByFormIdReducer());
    if (res?.data?.fbAdsCampaignInfo) {
      // yield* [
      //   "topOfTheFunnelLookalike",
      //   "topOfTheFunnelInterest",
      //   "middleOfTheFunnel",
      //   "bottomOfTheFunnel",
      // ].map(function* (strategyType) {
      //   switch (strategyType) {
      //     case "topOfTheFunnelLookalike":
      //       yield put(
      //         updateLookalike({
      //           ...(res?.data?.campaignInfo?.topOfTheFunnelLookalike || {}),
      //         })
      //       );
      //     case "topOfTheFunnelInterest":
      //       yield put(
      //         updateInterest({
      //           ...(res?.data?.campaignInfo?.topOfTheFunnelInterest || {}),
      //         })
      //       );
      //     case "middleOfTheFunnel":
      //       yield put(
      //         updatemof({
      //           ...(res?.data?.campaignInfo?.middleOfTheFunnel || {}),
      //         })
      //       );
      //     case "bottomOfTheFunnel":
      //       yield put(
      //         updatebof({
      //           ...(res?.data?.campaignInfo?.bottomOfTheFunnel || {}),
      //         })
      //       );
      //   }
      // });
      console.log("!!!!!!!!!");
      if (action.payload.campaignType === "topOfTheFunnelLookalike") {
        yield put(
          updateLookalike({
            ...(res?.data?.campaignInfo?.topOfTheFunnelLookalike || {}),
          })
        );
        yield put({
          type: "UPDATE_CHANGES_STATUS",
          payload: {
            campaignType: "lookalikeChanges",
            value: false,
          },
        });
      } else if (action.payload.campaignType === "topOfTheFunnelInterest") {
        yield put(
          updateInterest({
            ...(res?.data?.campaignInfo?.topOfTheFunnelInterest || {}),
          })
        );
        yield put({
          type: "UPDATE_CHANGES_STATUS",
          payload: {
            campaignType: "interestChange",
            value: false,
          },
        });
      } else if (action.payload.campaignType === "middleOfTheFunnel") {
        console.log("#########");
        yield put(
          updatemof({
            ...(res?.data?.campaignInfo?.middleOfTheFunnel || {}),
          })
        );
        yield put({
          type: "UPDATE_CHANGES_STATUS",
          payload: {
            campaignType: "mofChanges",
            value: false,
          },
        });
      } else {
        yield put(
          updatebof({
            ...(res?.data?.campaignInfo?.bottomOfTheFunnel || {}),
          })
        );
        yield put({
          type: "UPDATE_CHANGES_STATUS",
          payload: {
            campaignType: "bofChanges",
            value: false,
          },
        });
      }
    }
  } catch (err) {
    console.log("error ", err);
  }
}

function* publishFBCampaign(action) {
  const campaignTypes = [
    "top_of_the_funnel_lookalike",
    "top_of_the_funnel_interest",
    "middle_of_the_funnel",
    "bottom_of_the_funnel",
  ];
  const campaignTypesCampaignMapping = {
    "top_of_the_funnel_lookalike" : "lookalike",
    "top_of_the_funnel_interest": "interest",
    "middle_of_the_funnel": "mof",
    "bottom_of_the_funnel": "bof",
  }
  let campaignObj = {
    lookalike : null,
    interest: null,
    mof: null,
    bof: null,
  }

  const store = yield select();
  const form = store.hdmFormAm;
  if(form?.generatedCampaignsInfo){
    const result = getFBCampaignObject(form.generatedCampaignsInfo);
    console.log("########## in campaign obj", result);
    campaignObj = result[0];
  }

  console.log("in campaign sag", campaignObj);
  const formId = localStorage.getItem("amSelectedFormId") || localStorage.getItem("formId");
  const url = `/facebook-ads-campaigns/${formId}`;

  try{
    yield all(
      campaignTypes.map((campaign) => {
        try {
          if(!campaignObj[campaignTypesCampaignMapping[campaign]]){
            return api.post(`${url}?campaignTypes=${campaign}`);
          } else {
            return null;
          }
        } catch (error) {
          console.log(`in ${campaign} - error is`, error.message);
        }
      })
    );
  }catch(error){
    console.log("error is", error.message);
  }
  yield put({
    type: "UPDATE_WHOLE_HDM_AM_DETAILS_SAGA",
  });
  yield put(getFormByFormIdReducer());
}
export default function* root() {
  yield all([
    takeLatest("getCampaignLocation", getCampaignLocation),
    takeLatest(getPages().type, getFbPages),
    takeLatest(getAdPixels().type, getFbPixels),
    takeLatest(getPixelEvents().type, getFbPixelEvents),
    takeLatest("SUBMIT_FORM_FOR_HDM_CAMPAIGN", saveHdmAdmFormDataForCampaign),
    takeLatest("PUBLISH_FB_CAMPAIGN", publishFBCampaign),
  ]);
}
