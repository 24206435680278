export const CREATIVE_OBJECT_STATUS_ENUM = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    DISAPPROVED: 'DISAPPROVED',
    REMOVED: 'REMOVED'
}

export const PLATFORM_NAME = {
    FACEBOOK: "FACEBOOK",
    GOOGLE: "GOOGLE"
}

export const AB_TEST_SECTION_ENUM = {
    MESSAGING_TESTS: 'Messaging Tests',
    FORM_TESTS: 'Form Tests',
    ADDITIONAL_TESTS: 'Additional Tests'
}

export const AB_TEST_STATUS_ENUM = {
    NOT_STARTED: 'Not Started',
    ON_GOING: 'Ongoing',
    COMPLETED: 'Completed'
}

export const HEADLINE_CHAR_LIMIT = {
    GOOGLE: 30,
    FACEBOOK: 40
}

export const DESCRIPTION_CHAR_LIMIT = {
    GOOGLE: 90,
    FACEBOOK: 30,
}

export const PRIMARY_TEXT_LIMIT = {
    FACEBOOK: 125,
}
