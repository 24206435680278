import {
  Autocomplete,
  FormControl,
  IconButton,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import React from "react";
import TextFieldElement from "./TextFieldElement";

export default function MetricList({ channel, metric, metrics, isEditable, defaultTitle = "Add New Metric", onClick, onDelete }) {
  return (
    <FormControl
      sx={{ marginTop: "10px", display: "flex", flexDirection: "row", width: "400px" }}>
      {(isEditable || !metric) ? (
        <Autocomplete
          id="grouped-dimensions"
          sx={{ width: "340px" }}
          value={metric}
          options={metrics}
          size="small"
          className="bg-read-only metric-input"
          groupBy={(metric) => metric.group}
          getOptionLabel={(metric) => metric.name}
          getOptionDisabled={(option) => option.disabled}
          renderOption={(props, option, { selected }) => {
            return (
              <li {...props} >
                {option.icon && <img height="20px" width="20px" style={{ marginLeft: "10px" }} src={option.icon} alt={option.id} />}
                <p style={{ margin: "0px 5px", paddingLeft: "10px", minWidth: "70%" }}>{option.name}</p>
                {option.group === "Customized" && <span style={{ width: "30%", textAlign: "right", color: "#00000088", fontSize: "12px", fontWeight: "bold" }}> Cal </span>}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          disableClearable={!onDelete}
          renderInput={(params) => <TextFieldElement channel={channel} label={metric ? channel.title : defaultTitle} isEmpty={!metric} {...params} />}
          onChange={(event, newValue) => onClick(newValue)}
        />)
        :
        <TextFieldElement channel={channel} defaultValue={metric.metric ? metric.metric.name : metric.name} onClick={() => onClick(metric)} />
      }

      {
        metric && onDelete &&
        <IconButton
          disableRipple
          disableFocusRipple
          sx={{ paddingRight: "0" }}
          aria-label="delete"
          onClick={onDelete}
        >
          <Clear />
        </IconButton>
      }
    </FormControl>
  );
}
