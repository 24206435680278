import { FormControl, Typography } from '@mui/material';
import React from 'react';
import { useGetClientForms } from '../../../services/clientForms';
import { useGetAudiences } from '../../../services/facebook-ads';
import AudienceDropdown from '../../FacebookStrategyCreation/AdsetLevel/AudienceDropdown';

const NULL_CLIENTFORM = {};

const AudienceTargettingFields = ({ disabled, audience, setAudience, formId }) => {
  const { data: clientForm = NULL_CLIENTFORM } = useGetClientForms(formId);
  const { clientCampaignFacebookAdsInfo } = clientForm;

  const { data: audiences } = useGetAudiences(clientCampaignFacebookAdsInfo?.accountId, {
    enabled: !!clientCampaignFacebookAdsInfo?.accountId,
    select: (responseData) => responseData.audiences,
  });

  return (
    <div className='audience-targetting-form'>
      <Typography
        style={{
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 10,
          lineHeight: '12px',
          color: '#333333',
        }}
      >
        Audience
      </Typography>
      <FormControl
        variant='standard'
        sx={{ m: 1, minWidth: 280, marginLeft: 0 }}
      >
        <AudienceDropdown
          fullWidth
          audiences={audiences}
          style={{ maxWidth: "none" }}
          value={audience}
          onChange={setAudience}
          placeholder="Audience Name"
        />
      </FormControl>
    </div>
  );
};

export default AudienceTargettingFields;
