import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locationList: [],
};

export const campaignLocation = createSlice({
  name: "campainLocation",
  initialState,
  reducers: {
    updateLocations: (state, action) => {
      state.locationList = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateLocations } = campaignLocation.actions;

export default campaignLocation.reducer;
