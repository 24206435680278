import { useState, useEffect } from 'react';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import { useTheme, styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import InputBase from '@mui/material/InputBase';
import { no_preview_icon as noIreviewIcon } from '../../../assets/icons/common/common';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Autocomplete from '@mui/material/Autocomplete';
import Input from '@mui/material/Input';
import { useDispatch, useSelector } from 'react-redux';
import {
  getKeywordsReducer,
  updateBudgets,
  updateKeywordsStatusReducer,
  updateKeywordStatusReducer,
  updateSelectedServiceKeywords,
  updateUnselectedServiceKeywords,
  updateRemovedServiceKeywords,
  updateSelectedHeadlines,
  updateSelectedDescriptions,
  editKeywordStatusReducer,
  updateSelectedServiceHeadlines,
  updateUnselectedServiceHeadlines,
  updateRemovedServiceHeadlines,
  updateSelectedServiceDescriptions,
  updateUnselectedServiceDescriptions,
  updateRemovedServiceDescriptions,
  updateAffectedServiceKeywords,
} from '../../../api/accountManager/targetAI/slice';
import {
  generateResourceReducer,
  getResourcesReducer,
  updateAllResourceData,
  updateResourceReducer,
  updateResourcesStatusReducer,
  editResourceStatusReducer,
} from '../../../api/accountManager/creativeAI/slice';
import { updateFormReducer } from '../../../api/accountManager/forms/slice';
import GoToNextCompoButton from '../GoToNextCompoButton';
import {
  accountManagerIds,
  accountManagerIdsHDM,
  BACKEND_STATUS,
  componentWithIndexHDMAm,
  isPlatformSelected,
  isPlatformSelectedHDM,
  ldComponentWithIndexAm,
} from '../../../utils/accountManagerUtil';
import useInterval from '../../../hooks/useInterval';
import {
  goBackAmCompo,
  goToSpecificCompoClient,
  goToSpecificCompoClientAm,
  updateCompoReduxAM,
} from '../../../api/accountManager/componentTracking/action';
import GenerateModal from '../GeneratingadsModal/generatingadsmodal';
import {
  leftSidebarAMWithCurrentCompIds,
  listOfIds,
} from '../../../utils/navbarUtil';
import {
  goBackHDMAmCompo,
  goToSpecificCompoHDMAM,
  updateCompoReduxHDMAM,
} from '../../../api/HDMAM/componentTracking/action';
import useGoogleCampaignBudget from '../useGoogleCampaignBudget';
import TourTooltip from '../../Common/TourTooltip';
import InfoTip from '../../Common/InfoTip';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import ldAmRoutePaths from '../../../pages/ld-am/constants/ldAmRoutePaths';
import hdmAmRoutePaths from '../../../pages/hdm-am/constants/hdmAmRoutePaths';

const StrategyInputService = ({ agencyType = 'LD',type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {formId} = useParams();
  const [clusterName, setClusterName] = useState({ 0: 'Cluster_1' });
  const [headlineResourceId, setHeadlineResourceId] = useState({});
  const [descriptionResourceId, setDescriptionResourceId] = useState({});

  const { serviceCampaignBudget } = useGoogleCampaignBudget({ agencyType });
  const [budget, setBudget] = useState('');
  const [keywordValue, setKeywordValue] = useState('');
  const [generateKeyword, setGenerateKeyWord] = useState(false);
  const [clusterNumber, setClusterNumber] = useState(0);
  const [competitorResourceId, setCompetitorResourceId] = useState(null);
  const [totalClusterNumber, setTotalClusterNumber] = useState(0);
  const [emptyArray, setEmptyArray] = useState([]);
  const campaigns = useSelector((state) => state.targetAI.campaigns);
  const selectedKeywords = useSelector(
    (state) => state.targetAI.selectedServiceKeywords
  );
  const unselectedKeywords = useSelector(
    (state) => state.targetAI.unselectedServiceKeywords
  );
  const removedKeywords = useSelector(
    (state) => state.targetAI.removedServiceKeywords
  );
  const competitorBudget = useSelector((state) => state.targetAI.ServiceBudget);
  const form = useSelector((store) =>
    agencyType === 'HDM' ? store.hdmFormAm : store.form.form
  );
  const generated = useSelector((state) => state.creativeAI.generated);
  const resourceIds = useSelector((state) => state.creativeAI.resourceIds);

  const [selectedTab, setSelectedTab] = useState('HEADLINE');
  const selectedHeadlines = useSelector(
    (state) => state.targetAI.selectedServiceHeadlines
  );
  const nonSelectedHeadlines = useSelector(
    (state) => state.targetAI.unselectedServiceHeadlines
  );
  const removedHeadlines = useSelector(
    (state) => state.targetAI.removedServiceHeadlines
  );
  const selectedDescription = useSelector(
    (state) => state.targetAI.selectedServiceDescription
  );
  const nonSelectedDescription = useSelector(
    (state) => state.targetAI.unselectedServiceDescription
  );
  const removedDescription = useSelector(
    (state) => state.targetAI.removedServiceDescription
  );

  const affectedKeywords = useSelector(
    (state) => state.targetAI.affectedServiceKeywords
  );
  const [generateHeadline, setGenerateHeadline] = useState(false);
  const [generateDesc, setGenerateDesc] = useState(false);
  const [isGeneratingModal, setIsGeneratingModal] = useState(false);

  const resources = useSelector((state) => state.creativeAI.serviceResources);
  const [selectedKeyValue, setSelectedKeyValue] = useState('');
  const [selectedKey, setSelectedKey] = useState(null);
  const [selectedKeyType, setSelectedKeyType] = useState(null);

  const [selectedHeadValue, setSelectedHeadValue] = useState('');
  const [selectedHead, setSelectedHead] = useState(null);
  const [headType, setHeadType] = useState(null);

  const [selectedDescValue, setSelectedDescValue] = useState('');
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [descType, setDescType] = useState(null);

  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    dispatch(getKeywordsReducer());
  }, []);
  useEffect(() => {
    if (campaigns.length) {
      const competitorCluster = campaigns.find(
        (campaign) => campaign.campaignName === 'service_keyword'
      ).adGroupClusters;
      const clusterNames = {};
      const [selected, unselected, removed] = [{}, {}, {}];
      setTotalClusterNumber(competitorCluster.length);
      competitorCluster.map((groupCluster, idx) => {
        clusterNames[idx] = competitorCluster[idx].clusterName;
        selected[idx] = [];
        unselected[idx] = [];
        removed[idx] = [];
        groupCluster.keywords.map((keyword) => {
          if (keyword.status === 'SELECTED') {
            selected[idx].push(keyword.keyword);
          } else if (keyword.status === 'NOT_SELECTED') {
            unselected[idx].push(keyword.keyword);
          } else {
            removed[idx].push(keyword.keyword);
          }
        });
      });

      setClusterName(clusterNames);
      if (!selectedKeywords.length && !unselectedKeywords.length) {
        dispatch(updateUnselectedServiceKeywords(unselected));
        dispatch(updateRemovedServiceKeywords(removed));
        dispatch(updateSelectedServiceKeywords(selected));
      }
    }
  }, [campaigns]);

  useEffect(() => {
    if ((resources || []).length) {
      const campaignResource = resources.filter(
        (resource) => resource.campaignType === 'service_keyword'
      );
      let [selectedHead, unselectedHead, removedHead] = [{}, {}, {}];
      let [selectedDesc, unselectedDesc, removedDesc] = [{}, {}, {}];
      const [headResourceIds, descResourceIds] = [{}, {}];

      for (let id = 0; id < totalClusterNumber; id++) {
        selectedHead[id] = [];
        unselectedHead[id] = [];
        removedHead[id] = [];
        selectedDesc[id] = [];
        unselectedDesc[id] = [];
        removedDesc[id] = [];
        const clusterResource = campaignResource.filter(
          (resource) => resource.adgroupName === clusterName[id]
        );

        clusterResource.map((resource) => {
          if (resource.resourceType === 'HEADLINE') {
            headResourceIds[id] = resource.resourcesId;
            (resource.resources || []).map((keyword) => {
              if (keyword.status === 'SELECTED') {
                selectedHead[id].push(keyword);
              } else if (keyword.status === 'NOT_SELECTED') {
                unselectedHead[id].push(keyword);
              } else {
                removedHead[id].push(keyword);
              }
            });
          }
          if (resource.resourceType === 'DESCRIPTION') {
            descResourceIds[id] = resource.resourcesId;
            (resource.resources || []).map((keyword) => {
              if (keyword.status === 'SELECTED') {
                selectedDesc[id].push(keyword);
              } else if (keyword.status === 'NOT_SELECTED') {
                unselectedDesc[id].push(keyword);
              } else {
                removedDesc[id].push(keyword);
              }
            });
          }
        });
      }

      if (!(selectedHead[clusterNumber] || []).length) {
        delete selectedHead[clusterNumber];
      }
      if (!(unselectedHead[clusterNumber] || []).length) {
        delete unselectedHead[clusterNumber];
      }
      if (!(removedHead[clusterNumber] || []).length) {
        delete removedHead[clusterNumber];
      }
      if (!(selectedDesc[clusterNumber] || []).length) {
        delete selectedDesc[clusterNumber];
      }
      if (!(unselectedDesc[clusterNumber] || []).length) {
        delete unselectedDesc[clusterNumber];
      }
      if (!(removedDesc[clusterNumber] || []).length) {
        delete removedDesc[clusterNumber];
      }

      selectedHead = { ...selectedHead, ...selectedHeadlines };
      unselectedHead = { ...unselectedHead, ...nonSelectedHeadlines };
      removedHead = { ...removedHead, ...removedHeadlines };

      selectedDesc = { ...selectedDesc, ...selectedDescription };
      unselectedDesc = { ...unselectedDesc, ...nonSelectedDescription };
      removedDesc = { ...removedDesc, ...removedDescription };

      setHeadlineResourceId(headResourceIds);
      setDescriptionResourceId(descResourceIds);
      dispatch(updateUnselectedServiceHeadlines(unselectedHead));
      dispatch(updateUnselectedServiceDescriptions(unselectedDesc));
      dispatch(updateRemovedServiceHeadlines(removedHead));
      dispatch(updateRemovedServiceDescriptions(removedDesc));
      dispatch(updateSelectedServiceHeadlines(selectedHead));
      dispatch(updateSelectedServiceDescriptions(selectedDesc));
    }
  }, [resources, totalClusterNumber]);

  useEffect(() => {
    if (form) {
      if (form.googleSearchAdsStrategyInfo?.serviceCampaign?.budget === '0') {
        dispatch(
          updateBudgets({
            ServiceBudget: '',
          })
        );
      } else {
        dispatch(
          updateBudgets({
            ServiceBudget:
              parseInt(
                form.googleSearchAdsStrategyInfo?.serviceCampaign?.budget
              ) || serviceCampaignBudget,
          })
        );
      }
    }
  }, [form, serviceCampaignBudget]);

  useEffect(() => {
    if (generated) {
      dispatch(
        generateResourceReducer({
          campaignType: 'service_keyword',
          interestKeywords: selectedKeywords[clusterNumber],
          adgroupName: clusterName[clusterNumber],
          resourceType: 'DESCRIPTION',
        })
      );
    } else {
      setIsGeneratingModal(false);
    }
  }, [generated]);

  useEffect(() => {
    if (totalClusterNumber) {
      const numbers = [];
      for (let i = 0; i < totalClusterNumber; i++) {
        numbers.push(i + 1);
      }

      setEmptyArray(numbers);
    }
  }, [totalClusterNumber]);

  useEffect(() => {
    if (competitorBudget) {
      setBudget(competitorBudget);
    } else {
      setBudget('');
    }
  }, [competitorBudget]);

  useEffect(() => {
    if (!isEdit) {
      setSelectedKey(null);
      setSelectedKeyValue('');
      setSelectedKeyType(null);
      setSelectedHeadValue('');
      setSelectedHead(null);
      setHeadType(null);
      setDescType(null);
      setSelectedDescValue('');
      setSelectedDesc(null);
    }
  }, [isEdit]);

  const changeCompetitorBudget = (e) => {
    dispatch(
      updateBudgets({
        ServiceBudget: e.target.value || '',
      })
    );
  };

  const addKeyword = (keyword, keywordToBeRemoved, currentState) => {
    let tempSelectedKeyword = [...(selectedKeywords[clusterNumber] || [])];
    let tempUnselectedKeyword = [...(unselectedKeywords[clusterNumber] || [])];
    let tempRemovedKeyword = [...(removedKeywords[clusterNumber] || [])];
    let tempAffectedKeywords = [...(affectedKeywords[clusterNumber] || [])];
    if (isEdit && keywordToBeRemoved) {
      const keywordInAffected = tempAffectedKeywords.find(
        (keyword) =>
          keyword.keyword === keywordToBeRemoved &&
          keyword.campaignName === 'service_keyword' &&
          keyword.clusterName === clusterName[clusterNumber]
      );
      if (keywordInAffected) {
        tempAffectedKeywords.splice(
          tempAffectedKeywords.indexOf(keywordInAffected),
          1
        );
      }
      tempAffectedKeywords.push({
        keyword: keywordToBeRemoved,
        status: 'REMOVED',
        campaignName: 'service_keyword',
        clusterName: clusterName[clusterNumber],
      });
      if (currentState === 'SELECTED') {
        tempSelectedKeyword.splice(
          tempSelectedKeyword.indexOf(keywordToBeRemoved),
          1
        );
      } else {
        tempUnselectedKeyword.splice(
          tempUnselectedKeyword.indexOf(keywordToBeRemoved),
          1
        );
        dispatch(
          updateUnselectedServiceKeywords({
            ...unselectedKeywords,
            [clusterNumber]: tempUnselectedKeyword,
          })
        );
      }
      dispatch(
        updateSelectedServiceKeywords({
          ...selectedKeywords,
          [clusterNumber]: [keyword, ...tempSelectedKeyword],
        })
      );
      dispatch(
        updateRemovedServiceKeywords({
          ...removedKeywords,
          [clusterNumber]: [keywordToBeRemoved, ...tempRemovedKeyword],
        })
      );
      setSelectedKey(null);
      setSelectedKeyValue('');
      setSelectedKeyType(null);
    } else {
      dispatch(
        updateSelectedServiceKeywords({
          ...selectedKeywords,
          [clusterNumber]: [keyword, ...tempSelectedKeyword],
        })
      );
    }
    tempAffectedKeywords.push({
      keyword: keyword,
      status: 'SELECTED',
      campaignName: 'service_keyword',
      clusterName: clusterName[clusterNumber],
    });
    dispatch(
      updateAffectedServiceKeywords({
        ...affectedKeywords,
        [clusterNumber]: tempAffectedKeywords,
      })
    );
    setGenerateKeyWord(false);
    setKeywordValue('');
  };
  const updateKeyword = (keyword, status) => {
    let tempAffectedKeywords = [...(affectedKeywords[clusterNumber] || [])];
    const keywordInAffected = tempAffectedKeywords.find(
      (keywordAffected) =>
        keywordAffected.keyword === keyword &&
        keywordAffected.campaignName === 'service_keyword' &&
        keywordAffected.clusterName === clusterName[clusterNumber]
    );
    if (keywordInAffected) {
      tempAffectedKeywords.splice(
        tempAffectedKeywords.indexOf(keywordInAffected),
        1
      );
    }
    let tempSelectedKeyword = [...(selectedKeywords[clusterNumber] || [])];
    let tempUnselectedKeyword = [...(unselectedKeywords[clusterNumber] || [])];
    if (status === 'SELECTED') {
      tempUnselectedKeyword.splice(tempUnselectedKeyword.indexOf(keyword), 1);
      dispatch(
        updateSelectedServiceKeywords({
          ...selectedKeywords,
          [clusterNumber]: [keyword, ...tempSelectedKeyword],
        })
      );
      dispatch(
        updateUnselectedServiceKeywords({
          ...unselectedKeywords,
          [clusterNumber]: tempUnselectedKeyword,
        })
      );
      tempAffectedKeywords.push({
        keyword: keyword,
        status: 'SELECTED',
        campaignName: 'service_keyword',
        clusterName: clusterName[clusterNumber],
      });
    }
    if (status === 'NOT_SELECTED') {
      tempSelectedKeyword.splice(tempSelectedKeyword.indexOf(keyword), 1);
      dispatch(
        updateSelectedServiceKeywords({
          ...selectedKeywords,
          [clusterNumber]: tempSelectedKeyword,
        })
      );
      dispatch(
        updateUnselectedServiceKeywords({
          ...unselectedKeywords,
          [clusterNumber]: [keyword, ...tempUnselectedKeyword],
        })
      );
      tempAffectedKeywords.push({
        keyword: keyword,
        status: 'SELECTED',
        campaignName: 'service_keyword',
        clusterName: clusterName[clusterNumber],
      });
    }
    dispatch(
      updateAffectedServiceKeywords({
        ...affectedKeywords,
        [clusterNumber]: tempAffectedKeywords,
      })
    );
  };

  const removeKeyword = (keyword) => {
    let tempAffectedKeywords = [...(affectedKeywords[clusterNumber] || [])];
    const keywordInAffected = tempAffectedKeywords.find(
      (keywordAffected) =>
        keywordAffected.keyword === keyword &&
        keywordAffected.campaignName === 'service_keyword' &&
        keywordAffected.clusterName === clusterName[clusterNumber]
    );
    if (keywordInAffected) {
      tempAffectedKeywords.splice(
        tempAffectedKeywords.indexOf(keywordInAffected),
        1
      );
    }
    let tempSelectedKeyword = [...(selectedKeywords[clusterNumber] || [])];
    let tempUnselectedKeyword = [...(unselectedKeywords[clusterNumber] || [])];
    if (tempSelectedKeyword.indexOf(keyword) !== -1) {
      tempSelectedKeyword.splice(tempSelectedKeyword.indexOf(keyword), 1);
      dispatch(
        updateSelectedServiceKeywords({
          ...selectedKeywords,
          [clusterNumber]: tempSelectedKeyword,
        })
      );
    } else {
      tempUnselectedKeyword.splice(tempUnselectedKeyword.indexOf(keyword), 1);
      dispatch(
        updateUnselectedServiceKeywords({
          ...unselectedKeywords,
          [clusterNumber]: tempUnselectedKeyword,
        })
      );
    }
    dispatch(
      updateRemovedServiceKeywords({
        ...removedKeywords,
        [clusterNumber]: [keyword, ...removedKeywords],
      })
    );
    tempAffectedKeywords.push({
      keyword: keyword,
      status: 'REMOVED',
      campaignName: 'service_keyword',
      clusterName: clusterName[clusterNumber],
    });
    dispatch(
      updateAffectedServiceKeywords({
        ...affectedKeywords,
        [clusterNumber]: tempAffectedKeywords,
      })
    );
  };

  const generateAdsHandler = () => {
    setIsGeneratingModal(true);
    dispatch(
      updateAllResourceData({
        serviceResources: [],
      })
    );

    let tempSelectedHeadlines = { ...selectedHeadlines };
    delete tempSelectedHeadlines[clusterNumber];
    let tempNonSelectedHeadlines = { ...nonSelectedHeadlines };
    delete tempNonSelectedHeadlines[clusterNumber];
    let tempRemovedHeadlines = { ...removedHeadlines };
    delete tempRemovedHeadlines[clusterNumber];
    let tempSelectedDescription = { ...selectedDescription };
    delete tempSelectedDescription[clusterNumber];
    let tempNonSelectedDescription = { ...nonSelectedDescription };
    delete tempNonSelectedDescription[clusterNumber];
    let tempRemovedDescription = { ...removedDescription };
    delete tempRemovedDescription[clusterNumber];

    dispatch(
      updateSelectedServiceHeadlines({
        ...tempSelectedHeadlines,
      })
    );
    dispatch(
      updateUnselectedServiceHeadlines({
        ...tempNonSelectedHeadlines,
      })
    );
    dispatch(updateRemovedServiceHeadlines({ ...tempRemovedHeadlines }));
    dispatch(
      updateSelectedServiceDescriptions({
        ...tempSelectedDescription,
      })
    );
    dispatch(
      updateUnselectedServiceDescriptions({
        ...tempNonSelectedDescription,
      })
    );
    dispatch(
      updateRemovedServiceDescriptions({
        ...tempRemovedDescription,
      })
    );
    const items = affectedKeywords[clusterNumber] || [];
    dispatch(editKeywordStatusReducer({ items }));
    dispatch(
      generateResourceReducer({
        campaignType: 'service_keyword',
        interestKeywords: selectedKeywords[clusterNumber],
        adgroupName: clusterName[clusterNumber],
        resourceType: 'HEADLINE',
      })
    );
  };

  const onClickHandler = () => {
    const items = affectedKeywords[clusterNumber] || [];
    dispatch(editKeywordStatusReducer({ items }));
    const resourceItems = [];
    [...(selectedHeadlines[clusterNumber] || [])].forEach((headline) => {
      resourceItems.push({
        resourcesId: headlineResourceId[clusterNumber],
        resourceValue: headline.value,
        resourceStatus: 'SELECTED',
      });
    });
    [...(nonSelectedHeadlines[clusterNumber] || [])].forEach((headline) => {
      resourceItems.push({
        resourcesId: headlineResourceId[clusterNumber],
        resourceValue: headline.value,
        resourceStatus: 'NOT_SELECTED',
      });
    });
    [...(removedHeadlines[clusterNumber] || [])].forEach((headline) => {
      resourceItems.push({
        resourcesId: headlineResourceId[clusterNumber],
        resourceValue: headline.value,
        resourceStatus: 'REMOVED',
      });
    });
    [...(selectedDescription[clusterNumber] || [])].forEach((description) => {
      resourceItems.push({
        resourcesId: descriptionResourceId[clusterNumber],
        resourceValue: description.value,
        resourceStatus: 'SELECTED',
      });
    });
    [...(nonSelectedDescription[clusterNumber] || [])].forEach(
      (description) => {
        resourceItems.push({
          resourcesId: descriptionResourceId[clusterNumber],
          resourceValue: description.value,
          resourceStatus: 'NOT_SELECTED',
        });
      }
    );
    [...(removedDescription[clusterNumber] || [])].forEach((description) => {
      resourceItems.push({
        resourcesId: descriptionResourceId[clusterNumber],
        resourceValue: description.value,
        resourceStatus: 'REMOVED',
      });
    });
    dispatch(
      editResourceStatusReducer({
        items: resourceItems,
      })
    );

    dispatch(
      updateFormReducer({
        googleSearchAdsStrategyInfo: {
          ...form?.googleSearchAdsStrategyInfo,
          status: BACKEND_STATUS.COMPLETED,
          serviceCampaign: {
            status: BACKEND_STATUS.COMPLETED,
            budget: budget || '0',
            adsets: Object.keys(clusterName).map((index) => {
              return {
                name: clusterName[index],
                keywords: selectedKeywords[index],
                headlines: [...(selectedHeadlines[index] || [])].map(
                  (headline) => {
                    return {
                      value: headline.value,
                      category: headline.category,
                    };
                  }
                ),
                descriptions: [...(selectedDescription[index] || [])].map(
                  (desc) => {
                    return {
                      value: desc.value,
                      category: desc.category,
                    };
                  }
                ),
              };
            }),
          },
        },
      })
    );

    if (agencyType === 'HDM') {
      navigate(
        generatePath(
          `${hdmAmRoutePaths.strategyGoogle.fullbase}`,
          {formId}
        )
      );
    } else {
      navigate(
        generatePath(
          `${ldAmRoutePaths.strategyGoogle.fullbase}/${ldAmRoutePaths.strategyGoogle.sub.guidedFlow.selector}`,
          {formId}
        )
      );
    }
  };

  const removeResource = (resource) => {
    console.log('@@@@@@@@@', resource);
    const tempSelectedResource =
      selectedTab === 'HEADLINE'
        ? [...(selectedHeadlines[clusterNumber] || [])]
        : [...(selectedDescription[clusterNumber] || [])];
    const tempUnselectedResource =
      selectedTab === 'HEADLINE'
        ? [...(nonSelectedHeadlines[clusterNumber] || [])]
        : [...(nonSelectedDescription[clusterNumber] || [])];
    if ((tempSelectedResource || []).indexOf(resource) !== -1) {
      tempSelectedResource.splice(tempSelectedResource.indexOf(resource), 1);
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateSelectedServiceHeadlines({
              ...selectedHeadlines,
              [clusterNumber]: tempSelectedResource,
            })
          )
        : dispatch(
            updateSelectedServiceDescriptions({
              ...selectedDescription,
              [clusterNumber]: tempSelectedResource,
            })
          );
    } else {
      tempUnselectedResource.splice(
        tempUnselectedResource.indexOf(resource),
        1
      );
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateUnselectedServiceHeadlines({
              ...nonSelectedHeadlines,
              [clusterNumber]: tempUnselectedResource,
            })
          )
        : dispatch(
            updateUnselectedServiceDescriptions({
              ...nonSelectedDescription,
              [clusterNumber]: tempUnselectedResource,
            })
          );
    }

    selectedTab === 'HEADLINE'
      ? dispatch(
          updateRemovedServiceHeadlines({
            [clusterNumber]: [
              resource,
              ...(removedHeadlines[clusterNumber] || []),
            ],
          })
        )
      : dispatch(
          updateRemovedServiceDescriptions({
            ...removedDescription,
            [clusterNumber]: [
              resource,
              ...(removedDescription[clusterNumber] || []),
            ],
          })
        );
  };

  const updateResource = (resource, status) => {
    const tempSelectedResource =
      selectedTab === 'HEADLINE'
        ? [...(selectedHeadlines[clusterNumber] || [])]
        : [...(selectedDescription[clusterNumber] || [])];
    const tempUnselectedResource =
      selectedTab === 'HEADLINE'
        ? [...(nonSelectedHeadlines[clusterNumber] || [])]
        : [...(nonSelectedDescription[clusterNumber] || [])];
    if (status) {
      tempSelectedResource.splice(tempSelectedResource.indexOf(resource), 1);
      if (selectedTab === 'HEADLINE') {
        dispatch(
          updateSelectedServiceHeadlines({
            ...selectedHeadlines,
            [clusterNumber]: tempSelectedResource,
          })
        );
        dispatch(
          updateUnselectedServiceHeadlines({
            ...nonSelectedHeadlines,
            [clusterNumber]: [
              resource,
              ...(nonSelectedHeadlines[clusterNumber] || []),
            ],
          })
        );
      } else {
        dispatch(
          updateSelectedServiceDescriptions({
            ...selectedDescription,
            [clusterNumber]: tempSelectedResource,
          })
        );
        dispatch(
          updateUnselectedServiceDescriptions({
            ...nonSelectedDescription,
            [clusterNumber]: [
              resource,
              ...(nonSelectedDescription[clusterNumber] || []),
            ],
          })
        );
      }
    } else {
      tempUnselectedResource.splice(
        tempUnselectedResource.indexOf(resource),
        1
      );

      if (selectedTab === 'HEADLINE') {
        dispatch(
          updateSelectedServiceHeadlines({
            ...selectedHeadlines,
            [clusterNumber]: [
              resource,
              ...(selectedHeadlines[clusterNumber] || []),
            ],
          })
        );
        dispatch(
          updateUnselectedServiceHeadlines({
            ...nonSelectedHeadlines,
            [clusterNumber]: tempUnselectedResource,
          })
        );
      } else {
        dispatch(
          updateSelectedServiceDescriptions({
            ...selectedDescription,
            [clusterNumber]: [
              resource,
              ...(selectedDescription[clusterNumber] || []),
            ],
          })
        );
        dispatch(
          updateUnselectedServiceDescriptions({
            ...nonSelectedDescription,
            [clusterNumber]: tempUnselectedResource,
          })
        );
      }
    }
  };
  const addResource = (resourceVal, resourceToBeRemoved, currentState) => {
    const tempSelectedResource =
      selectedTab === 'HEADLINE'
        ? [...(selectedHeadlines[clusterNumber] || [])]
        : [...(selectedDescription[clusterNumber] || [])];
    const tempUnselectedResource =
      selectedTab === 'HEADLINE'
        ? [...(nonSelectedHeadlines[clusterNumber] || [])]
        : [...(nonSelectedDescription[clusterNumber] || [])];
    if (isEdit && resourceToBeRemoved) {
      if (currentState === 'SELECTED') {
        tempSelectedResource.splice(
          tempSelectedResource.indexOf(resourceToBeRemoved),
          1
        );
      } else {
        tempUnselectedResource.splice(
          tempUnselectedResource.indexOf(resourceToBeRemoved),
          1
        );
        selectedTab === 'HEADLINE'
          ? dispatch(
              updateUnselectedServiceHeadlines({
                ...nonSelectedHeadlines,
                [clusterNumber]: tempUnselectedResource,
              })
            )
          : dispatch(
              updateUnselectedServiceDescriptions({
                ...nonSelectedDescription,
                [clusterNumber]: tempUnselectedResource,
              })
            );
      }
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateSelectedServiceHeadlines({
              ...selectedHeadlines,
              [clusterNumber]: [
                { value: resourceVal, category: 'custom', status: 'SELECTED' },
                tempSelectedResource,
              ],
            })
          )
        : dispatch(
            updateSelectedServiceDescriptions({
              ...selectedDescription,
              [clusterNumber]: [
                { value: resourceVal, category: 'custom', status: 'SELECTED' },
                ...tempSelectedResource,
              ],
            })
          );
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateRemovedServiceHeadlines({
              ...removedHeadlines,
              [clusterNumber]: [
                resourceToBeRemoved,
                ...(removedHeadlines[clusterNumber] || []),
              ],
            })
          )
        : dispatch(
            updateRemovedServiceDescriptions({
              ...removedDescription,
              [clusterNumber]: [
                resourceToBeRemoved,
                ...(removedDescription[clusterNumber] || []),
              ],
            })
          );
    } else {
      selectedTab === 'HEADLINE'
        ? dispatch(
            updateSelectedServiceHeadlines({
              ...selectedHeadlines,
              [clusterNumber]: [
                { value: resourceVal, category: 'custom', status: 'SELECTED' },
                ...(selectedHeadlines[clusterNumber] || []),
              ],
            })
          )
        : dispatch(
            updateSelectedServiceDescriptions({
              ...selectedDescription,
              [clusterNumber]: [
                { value: resourceVal, category: 'custom', status: 'SELECTED' },
                ...(selectedDescription[clusterNumber] || []),
              ],
            })
          );
    }
    selectedTab === 'HEADLINE'
      ? setGenerateHeadline(false)
      : setGenerateDesc(false);
    setKeywordValue('');
  };

  const toNotCallGetResource = () => {
    let stop = true;
    const currentAdsetResources = resources.filter(
      (resource) => resource.adgroupName === clusterName[clusterNumber]
    );
    currentAdsetResources.forEach((resource) => {
      if (!resource.resources) {
        stop = false;
      }
    });
    return stop;
  };
  useInterval(
    () => {
      dispatch(getResourcesReducer());
    },
    resources && resources.length && toNotCallGetResource() ? null : 5 * 1000
  );

  const textStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
  };
  const budgetTextStyle = {
    ...textStyle,
    paddingRight:'5px',
  };
  return (
    <div className='intro_strategy_main'>
      <div className='strategy_row_layout'>
        <div className='strategy_google_row_main'>
          <div className='strategy_input_header'>
            <div className='flex-center-space strategy_input_header_row'>
              <div className='flex-center-space input_strategy_form' data-tut={type}>
                
                <label style={budgetTextStyle}>Daily Budget : (in $)</label>
                <InfoTip infotext='70% of the total budget allocated for Search Campaigns.' />

                <Input
                  value={budget}
                  onChange={(e) => changeCompetitorBudget(e)}
                  className='budget_unput'
                  inputProps={{ 'aria-label': 'description' }}
                />
              </div>
              <div className='input_strategy_actions'>
                <div>
                  <button
                    className={`switch_actions_strategy preview_icon ${
                      !isEdit ? 'active_icon' : ''
                    } `}
                    onClick={() => setIsEdit(false)}
                  ></button>
                  <button
                    className={`switch_actions_strategy edit_icon ${
                      isEdit ? 'active_icon' : ''
                    }`}
                    onClick={() => setIsEdit(true)}
                  ></button>
                </div>
              </div>
            </div>
          </div>
          <div className='fulltab_item'>
            <Tabs selectedIndex={clusterNumber}>
              <div className='custom_tab_header_full'>
                <TabList>
                  {emptyArray.map((item, idx) => {
                    return (
                      <Tab
                        setClusterNumber={setClusterNumber}
                        number={idx}
                        key={idx}
                      >
                        <p onClick={() => setClusterNumber(idx)}>
                          Ad Group {idx + 1}
                        </p>
                      </Tab>
                    );
                  })}
                </TabList>
              </div>
              {emptyArray.map((item, idx) => {
                return (
                  <TabPanel key={idx}>
                    <div className='strategy_accordion_layout'>
                      <div className='strategy_row_input'>
                        <div className='strategy_column_input cluster_column_input'>
                          <div className='input_column_header'>
                            <h3>{clusterName[clusterNumber]}</h3>
                            <p>
                              Use this cluster to choose your top keywords to
                              present in your client strategy. You can hit ‘x’
                              to remove and regenerate a new ‘top keyword’
                            </p>
                          </div>
                          <div className='strategy_list_row_input'>
                            {(selectedKeywords[clusterNumber] || []).map(
                              (keyword, idx) => {
                                return (
                                  <div
                                    className={
                                      'flex-center-space strategy_list_item selected'
                                    }
                                    key={idx}
                                    onClick={() => {
                                      if (isEdit) {
                                        setSelectedKey(idx);
                                        setSelectedKeyValue(keyword);
                                        setSelectedKeyType('SELECTED');
                                      } else {
                                        updateKeyword(keyword, 'NOT_SELECTED');
                                      }
                                    }}
                                  >
                                    <div>
                                      {isEdit ? (
                                        <Input
                                          value={
                                            idx === selectedKey &&
                                            selectedKeyType === 'SELECTED'
                                              ? selectedKeyValue
                                              : keyword
                                          }
                                          className='cluster_edit_input'
                                          style={{
                                            caretColor: selectedKey
                                              ? ''
                                              : 'transparent',
                                          }}
                                          onChange={(e) =>
                                            setSelectedKeyValue(e.target.value)
                                          }
                                          onKeyDown={(e) => {
                                            if (
                                              e.key === 'Enter' &&
                                              isEdit &&
                                              selectedKey == idx &&
                                              selectedKeyType === 'SELECTED'
                                            ) {
                                              addKeyword(
                                                selectedKeyValue,
                                                keyword,
                                                'SELECTED'
                                              );
                                            }
                                          }}
                                          inputProps={{
                                            'aria-label': 'description',
                                          }}
                                        />
                                      ) : (
                                        <p>{keyword}</p>
                                      )}
                                    </div>
                                    {/* <span>1</span> */}
                                    <a
                                      className='clear_button'
                                      onClick={(e) => {
                                        removeKeyword(keyword);
                                        e.stopPropagation();
                                      }}
                                    ></a>
                                  </div>
                                );
                              }
                            )}
                            {(selectedKeywords[clusterNumber] || []).length <
                              50 &&
                              (unselectedKeywords[clusterNumber] || [])
                                .slice(
                                  0,
                                  50 -
                                    (selectedKeywords[clusterNumber] || [])
                                      .length
                                )
                                .map((keyword, idx) => {
                                  return (
                                    <div
                                      className={
                                        'flex-center-space strategy_list_item'
                                      }
                                      key={idx}
                                      onClick={() => {
                                        if (isEdit) {
                                          setSelectedKey(idx);
                                          setSelectedKeyValue(keyword);
                                          setSelectedKeyType('NOT_SELECTED');
                                        } else {
                                          updateKeyword(keyword, 'SELECTED');
                                        }
                                      }}
                                    >
                                      <div>
                                        {isEdit ? (
                                          <Input
                                            placeholder='Enter Keyword Here'
                                            value={
                                              idx === selectedKey &&
                                              selectedKeyType === 'NOT_SELECTED'
                                                ? selectedKeyValue
                                                : keyword
                                            }
                                            className='cluster_edit_input'
                                            onChange={(e) =>
                                              setSelectedKeyValue(
                                                e.target.value
                                              )
                                            }
                                            onKeyDown={(e) => {
                                              if (
                                                e.key === 'Enter' &&
                                                isEdit &&
                                                selectedKey == idx
                                              ) {
                                                addKeyword(
                                                  selectedKeyValue,
                                                  keyword
                                                );
                                              }
                                            }}
                                            inputProps={{
                                              'aria-label': 'description',
                                            }}
                                          />
                                        ) : (
                                          <p>{keyword}</p>
                                        )}
                                      </div>
                                      {/* <span>1</span> */}
                                      <a
                                        className='clear_button'
                                        onClick={(e) => {
                                          removeKeyword(keyword);
                                          e.stopPropagation();
                                        }}
                                      ></a>
                                    </div>
                                  );
                                })}

                            {generateKeyword && (
                              <div className='flex-center-space strategy_list_item edit_cluster_row'>
                                <Input
                                  value={keywordValue}
                                  onChange={(e) =>
                                    setKeywordValue(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      addKeyword(keywordValue);
                                    }
                                  }}
                                  onBlur={(e) => {
                                    if(!keywordValue) return;
                                    addKeyword(keywordValue);
                                  }}
                                  className='cluster_edit_input'
                                  inputProps={{ 'aria-label': 'description' }}
                                />
                                {/* <span>1</span> */}
                                <a
                                  className='clear_button'
                                  onClick={() => {
                                    setGenerateKeyWord(false);
                                    setKeywordValue('');
                                  }}
                                ></a>
                              </div>
                            )}
                          </div>
                          <div className='flex-center-space strategy_input_list_footer'>
                            <a
                              href='javascript:;'
                              className='link_footer'
                              onClick={() => setGenerateKeyWord(true)}
                            >
                              + Add Keyword
                            </a>
                            <PrimaryButton
                              title='Generate Ads'
                              onClick={generateAdsHandler}
                            />
                          </div>
                        </div>
                        <div className='strategy_column_input'>
                          <div className='input_column_header'>
                            <h3>Ad level</h3>
                            {selectedTab !== 'PREVIEW' && (
                              <p>{` Choose your top ${
                                selectedTab === 'HEADLINE'
                                  ? 'headlines'
                                  : 'descriptions'
                              }  to present in your client strategy.You can hit ‘x’ to remove and regenerate a new ‘top ${
                                selectedTab === 'HEADLINE'
                                  ? 'headlines'
                                  : 'descriptions'
                              }`}</p>
                            )}
                          </div>
                          <Tabs
                            onSelect={(index) => {
                              setSelectedTab(
                                ['HEADLINE', 'DESCRIPTION', 'PREVIEW'][index]
                              );
                            }}
                          >
                            <div className='custom_tab_header'>
                              <TabList>
                                {['HEADLINE', 'DESCRIPTION', 'PREVIEW'].map(
                                  (head, idx) => (
                                    <Tab
                                      key={idx}
                                      setClusterNumber={setSelectedTab}
                                      number={head}
                                    >
                                      {head}
                                    </Tab>
                                  )
                                )}
                              </TabList>
                            </div>
                            <TabPanel>
                              <div className={`tabpanel_strategy`}>
                                {(selectedHeadlines[clusterNumber] || []).map(
                                  (headline, idx) => {
                                    return (
                                      <div
                                        className='headerline_row_strategy '
                                        key={idx}
                                      >
                                        <div
                                          className='headline_column_strategy selected '
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            if (isEdit) {
                                              setSelectedHead(idx);
                                              setSelectedHeadValue(
                                                headline.value
                                              );
                                              setHeadType('SELECTED');
                                            } else {
                                              updateResource(
                                                headline,
                                                'NOT_SELECTED'
                                              );
                                            }
                                          }}
                                        >
                                          {/* <input className="cluster_edit_input" type="text" value={headline} readOnly/> */}
                                          {/* <Input placeholder="Enter Headline Here" value={headline} readOnly={!isEdit} className="cluster_edit_input" inputProps={{ 'aria-label': 'description' }} /> */}
                                          {isEdit ? (
                                            <Input
                                              placeholder='Enter Headline Here'
                                              value={
                                                isEdit &&
                                                idx === selectedHead &&
                                                headType === 'SELECTED'
                                                  ? selectedHeadValue
                                                  : headline.value
                                              }
                                              readOnly={!isEdit}
                                              className='cluster_edit_input'
                                              onChange={(e) =>
                                                setSelectedHeadValue(
                                                  e.target.value
                                                )
                                              }
                                              onKeyDown={(e) => {
                                                if (
                                                  e.key === 'Enter' &&
                                                  isEdit &&
                                                  selectedHead == idx &&
                                                  headType === 'SELECTED'
                                                ) {
                                                  addResource(
                                                    selectedHeadValue,
                                                    headline,
                                                    'SELECTED'
                                                  );
                                                  setSelectedHead(null);
                                                  setSelectedHeadValue('');
                                                  setHeadType(null);
                                                }
                                              }}
                                              inputProps={{
                                                'aria-label': 'description',
                                                maxLength: 30,
                                              }}
                                            />
                                          ) : (
                                            <h4>{headline.value}</h4>
                                          )}
                                          <div className='headline_keyword_details'>
                                            <p>{headline.category}</p>
                                            <span>
                                              {idx === selectedHead &&
                                              headType === 'SELECTED'
                                                ? selectedHeadValue.length
                                                : (headline.value || '').length}
                                              /30
                                            </span>
                                          </div>
                                        </div>
                                        <div className='headline_column_actions'>
                                          <button
                                            className='actions_headline_strategy clear_icon'
                                            onClick={() =>
                                              removeResource(headline)
                                            }
                                          ></button>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}

                                {(selectedHeadlines[clusterNumber] || [])
                                  .length < 30 &&
                                  (nonSelectedHeadlines[clusterNumber] || [])
                                    .slice(
                                      0,
                                      30 -
                                        (selectedHeadlines[clusterNumber] || [])
                                          .length
                                    )
                                    .map((headline, idx) => {
                                      return (
                                        <div
                                          className='headerline_row_strategy'
                                          key={idx}
                                        >
                                          <div
                                            className='headline_column_strategy'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              if (isEdit) {
                                                setSelectedHead(idx);
                                                setSelectedHeadValue(
                                                  headline.value
                                                );
                                                setHeadType('NOT_SELECTED');
                                              } else {
                                                updateResource(headline);
                                              }
                                            }}
                                          >
                                            {isEdit ? (
                                              <Input
                                                placeholder='Enter Headline Here'
                                                value={
                                                  isEdit &&
                                                  idx === selectedHead &&
                                                  headType === 'NOT_SELECTED'
                                                    ? selectedHeadValue
                                                    : headline.value
                                                }
                                                readOnly={!isEdit}
                                                className='cluster_edit_input'
                                                onChange={(e) =>
                                                  setSelectedHeadValue(
                                                    e.target.value
                                                  )
                                                }
                                                onKeyDown={(e) => {
                                                  if (
                                                    e.key === 'Enter' &&
                                                    isEdit &&
                                                    selectedHead == idx &&
                                                    headType === 'NOT_SELECTED'
                                                  ) {
                                                    addResource(
                                                      selectedHeadValue,
                                                      headline
                                                    );
                                                    setSelectedHead(null);
                                                    setSelectedHeadValue('');
                                                    setHeadType(null);
                                                  }
                                                }}
                                                inputProps={{
                                                  'aria-label': 'description',
                                                  maxLength: 30,
                                                }}
                                              />
                                            ) : (
                                              <h4>{headline.value}</h4>
                                            )}
                                            <div className='headline_keyword_details'>
                                              <p>{headline.category}</p>
                                              <span>
                                                {idx === selectedHead &&
                                                headType === 'NOT_SELECTED'
                                                  ? selectedHeadValue.length
                                                  : (headline.value || '')
                                                      .length}
                                                /30
                                              </span>
                                            </div>
                                          </div>
                                          <div className='headline_column_actions'>
                                            <button
                                              className='actions_headline_strategy clear_icon'
                                              onClick={() =>
                                                removeResource(headline)
                                              }
                                            ></button>
                                          </div>
                                        </div>
                                      );
                                    })}

                                {generateHeadline && (
                                  <div className='headerline_row_strategy'>
                                    <div className='headline_column_strategy'>
                                      <Input
                                        value={keywordValue}
                                        onChange={(e) =>
                                          setKeywordValue(e.target.value)
                                        }
                                        className='cluster_edit_input'
                                        inputProps={{
                                          'aria-label': 'description',
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            addResource(keywordValue);
                                          }
                                        }}
                                        onBlur={(e) => {
                                          if(!keywordValue) return;
                                          addResource(keywordValue);
                                        }}
                                      />
                                      <div className='headline_keyword_details'>
                                        <p>Custom</p>
                                        <span>{keywordValue.length}/30</span>
                                      </div>
                                    </div>
                                    <div className='headline_column_actions'>
                                      <button
                                        className='actions_headline_strategy clear_icon'
                                        onClick={() => {
                                          setKeywordValue('');
                                          setGenerateHeadline(false);
                                        }}
                                      ></button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className={`tabpanel_strategy`}>
                                {(selectedDescription[clusterNumber] || []).map(
                                  (desc, idx) => {
                                    return (
                                      <div
                                        className='headerline_row_strategy '
                                        key={idx}
                                      >
                                        <div
                                          className='headline_column_strategy selected '
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            if (isEdit) {
                                              setSelectedDesc(idx);
                                              setSelectedDescValue(desc.value);
                                              setDescType('SELECTED');
                                            } else {
                                              updateResource(
                                                desc,
                                                'NOT_SELECTED'
                                              );
                                            }
                                          }}
                                        >
                                          {isEdit ? (
                                            <Input
                                              value={
                                                isEdit &&
                                                idx === selectedDesc &&
                                                descType === 'SELECTED'
                                                  ? selectedDescValue
                                                  : desc.value
                                              }
                                              className='cluster_edit_input'
                                              onChange={(e) =>
                                                setSelectedDescValue(
                                                  e.target.value
                                                )
                                              }
                                              onKeyDown={(e) => {
                                                if (
                                                  e.key === 'Enter' &&
                                                  isEdit &&
                                                  selectedDesc == idx &&
                                                  descType === 'SELECTED'
                                                ) {
                                                  addResource(
                                                    selectedDescValue,
                                                    desc,
                                                    'SELECTED'
                                                  );
                                                  setSelectedDesc(null);
                                                  setSelectedDescValue('');
                                                  setDescType(null);
                                                }
                                              }}
                                              inputProps={{
                                                'aria-label': 'description',
                                              }}
                                            />
                                          ) : (
                                            <h4>{desc.value}</h4>
                                          )}
                                          <div className='headline_keyword_details'>
                                            <p>{desc.category}</p>
                                            <span>
                                              {idx === selectedHead &&
                                              headType === 'SELECTED'
                                                ? selectedDescValue.length
                                                : (desc.value || '').length}
                                              /90
                                            </span>
                                          </div>
                                        </div>
                                        <div className='headline_column_actions'>
                                          <button
                                            className='actions_headline_strategy clear_icon'
                                            onClick={() => removeResource(desc)}
                                          ></button>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}

                                {(
                                  ((selectedDescription[clusterNumber] || [])
                                    .length < 30 &&
                                    nonSelectedDescription[clusterNumber]) ||
                                  []
                                )
                                  .slice(
                                    0,
                                    30 -
                                      (selectedDescription[clusterNumber] || [])
                                        .length
                                  )
                                  .map((desc, idx) => {
                                    return (
                                      <div
                                        className='headerline_row_strategy'
                                        key={idx}
                                      >
                                        <div
                                          className='headline_column_strategy'
                                          style={{ cursor: 'pointer' }}
                                          onClick={() => {
                                            if (isEdit) {
                                              setSelectedDesc(idx);
                                              setSelectedDescValue(desc.value);
                                              setDescType('NOT_SELECTED');
                                            } else {
                                              updateResource(desc);
                                            }
                                          }}
                                        >
                                          {isEdit ? (
                                            <Input
                                              value={
                                                isEdit &&
                                                idx === selectedDesc &&
                                                descType === 'NOT_SELECTED'
                                                  ? selectedDescValue
                                                  : desc.value
                                              }
                                              readOnly={!isEdit}
                                              className='cluster_edit_input'
                                              onChange={(e) =>
                                                setSelectedDescValue(
                                                  e.target.value
                                                )
                                              }
                                              onKeyDown={(e) => {
                                                if (
                                                  e.key === 'Enter' &&
                                                  isEdit &&
                                                  selectedDesc == idx &&
                                                  descType === 'NOT_SELECTED'
                                                ) {
                                                  addResource(
                                                    selectedDescValue,
                                                    desc
                                                  );
                                                  setSelectedDesc(null);
                                                  setSelectedDescValue('');
                                                  setDescType(null);
                                                }
                                              }}
                                              inputProps={{
                                                'aria-label': 'description',
                                              }}
                                            />
                                          ) : (
                                            <h4>{desc.value}</h4>
                                          )}
                                          <div className='headline_keyword_details'>
                                            <p>{desc.category}</p>
                                            <span>
                                              {idx === selectedHead &&
                                              headType === 'NOT_SELECTED'
                                                ? selectedDescValue.length
                                                : (desc.value || '').length}
                                              /90
                                            </span>
                                          </div>
                                        </div>
                                        <div className='headline_column_actions'>
                                          <button
                                            className='actions_headline_strategy clear_icon'
                                            onClick={() => removeResource(desc)}
                                          ></button>
                                        </div>
                                      </div>
                                    );
                                  })}

                                {generateDesc && (
                                  <div className='headerline_row_strategy'>
                                    <div className='headline_column_strategy'>
                                      <Input
                                        placeholder='Enter Description Here'
                                        value={keywordValue}
                                        onChange={(e) =>
                                          setKeywordValue(e.target.value)
                                        }
                                        className='cluster_edit_input'
                                        inputProps={{
                                          'aria-label': 'description',
                                        }}
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            addResource(keywordValue);
                                          }
                                        }}
                                        onBlur={(e) => {
                                          if(!keywordValue) return;
                                          addResource(keywordValue);
                                        }}
                                      />
                                      <div className='headline_keyword_details'>
                                        <p>Custom</p>
                                        <span>{keywordValue.length}/90</span>
                                      </div>
                                    </div>
                                    <div className='headline_column_actions'>
                                      <button
                                        className='actions_headline_strategy clear_icon'
                                        onClick={() => {
                                          setKeywordValue('');
                                          setGenerateDesc(false);
                                        }}
                                      ></button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className={`tabpanel_strategy`}>
                                <div className='strategy_campaign_preview'>
                                  <p className='campaign_preview_title'>
                                    {selectedHeadlines[clusterNumber]
                                      ? selectedHeadlines[clusterNumber]
                                          .slice(0, 3)
                                          .map((head) => head.value)
                                          .join(' - ')
                                      : ''}
                                  </p>
                                  <p className='campaign_preview_link'>
                                    {
                                      form?.onboardingInfo?.personalInfo
                                        ?.companyWebsite
                                    }
                                  </p>
                                  <p className='campaign_preview_desc'>
                                    {selectedDescription[clusterNumber]
                                      ? selectedDescription[clusterNumber]
                                          .slice(0, 1)
                                          .map((desc) => desc.value)
                                          .join(' - ')
                                      : ''}
                                  </p>
                                </div>
                                <div className='strategy_campaign_preview'>
                                  <p className='campaign_preview_title'>
                                    {selectedHeadlines[clusterNumber]
                                      ? selectedHeadlines[clusterNumber]
                                          .slice(3, 6)
                                          .map((head) => head.value)
                                          .join(' - ')
                                      : ''}
                                  </p>
                                  <p className='campaign_preview_link'>
                                    {
                                      form?.onboardingInfo?.personalInfo
                                        ?.companyWebsite
                                    }
                                  </p>
                                  <p className='campaign_preview_desc'>
                                    {selectedDescription[clusterNumber]
                                      ? selectedDescription[clusterNumber]
                                          .slice(1, 2)
                                          .map((desc) => desc.value)
                                          .join(' - ')
                                      : ''}
                                  </p>
                                </div>
                              </div>
                            </TabPanel>
                          </Tabs>
                          {selectedTab !== 'PREVIEW' && (
                            <div className='flex-center-center strategy_input_list_footer'>
                              <a
                                href='javascript:;'
                                className='link_footer'
                                onClick={() => {
                                  if (selectedTab === 'HEADLINE') {
                                    setGenerateHeadline(true);
                                  } else {
                                    setGenerateDesc(true);
                                  }
                                }}
                              >
                                + Add{' '}
                                {selectedTab === 'HEADLINE'
                                  ? 'Headline'
                                  : 'Description'}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                );
              })}
            </Tabs>
          </div>
        </div>
      </div>

      <div className='preview_footer_row'>
        <div
          className='footer_content'
          style={{ justifyContent: 'space-between' }}
        >
          <button
            className='back'
            onClick={() => {
              if (agencyType === 'LD') {
                dispatch(
                  goToSpecificCompoClientAm(
                    ldComponentWithIndexAm[
                      accountManagerIds.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY
                    ]
                  )
                );
              } else {
                dispatch(
                  goToSpecificCompoHDMAM(
                    componentWithIndexHDMAm[
                      accountManagerIdsHDM.CAMPAIGN_SELECTOR_GOOGLE_STRATEGY
                    ]
                  )
                );
              }
            }}
          >
            BACK
          </button>
          {clusterNumber != totalClusterNumber - 1 ? (
            <PrimaryButton
              title='Confirm'
              onClick={() => {
                setClusterNumber(clusterNumber + 1);
              }}
            />
          ) : (
            <PrimaryButton title='Confirm' onClick={onClickHandler} />
          )}
        </div>
      </div>
      {isGeneratingModal && <GenerateModal />}
    </div>
  );
};
export default StrategyInputService;
