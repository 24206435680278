import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { info_icon as INFO } from '../../../assets/icons/common/common';
import {
  bestPerformingCampaigns,
  inefficientCampaigns,
} from '../../../utils/auditHdmUtil';
import GoToNextCompoButton from '../GoToNextCompoButton';
import './campaign.css';

const CommonFBCampaign = ({ form, onFBAccountIdExists, onBack, onNext }) => {
  console.log('control:campaign');
  const [typeInefficient, setTypeInefficient] = useState('CPA');
  // const [typeBest, setTypeBest] = useState('CPA');
  const campaign = useSelector((state) => state.hdmAudit.auditDetails);
  console.log('Audit campaign : ', campaign);
  const InfoTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 150,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #F0F0F0',
      fontFamily: 'Inter',
      borderRadius: 8,
    },
  }));
  useEffect(() => {
    if (form?.fbAdsAccountId?.length !== 0) {
      onFBAccountIdExists?.();
    }
  }, [form]);
  return (
    <div
      style={{
        marginTop: '24.5px',
        marginLeft: '23.5px',
        marginRight: '24.5px',
        overflowY: 'auto',
      }}
    >
      <Box
        style={{
          marginRight: '19px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <FormControl style={{ minWidth: '165px' }} size='small'>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            onChange={(e) => {
              setTypeInefficient(e.target.value);
            }}
            value={typeInefficient}
            style={{ fontSize: '10px', marginTop: '5px' }}
          >
            <MenuItem value={'CPA'}>CPA</MenuItem>
            <MenuItem value={'CTR'}>CTR</MenuItem>
            <MenuItem value={'CPM'}>CPM</MenuItem>
            <MenuItem value={'CVR'}>CVR</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className='common3' style={{ marginTop: '30px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '18px',
              fontWeight: '600',
              lineHeight: '16px',
              color: '#170F49',
              marginBottom: '16px',
              padding: '15px 0px 0px 17.25px ',
            }}
          >
            Inefficient Campaigns
          </Typography>
        </div>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ background: '#F0F3FF' }}>
                <TableCell style={{ width: '390px' }}>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    Campaign Name
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    Budget (in $)
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    CPA($)
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          >
                            Cost per Acquisition
                          </Typography>
                          <b
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '400',
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          >
                            {'Spends / Conversions'}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt='' />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    CTR Outbound{' '}
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          >
                            Click Through Rate
                          </Typography>
                          <b
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '400',
                              fontSize: '12px',
                              lineHeight: '20px',
                              textAlign: 'center',
                            }}
                          >
                            {'Link clicks / Impressions'}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt='' />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    CPM($){' '}
                  </b>
                  <InfoTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '20px',
                          }}
                        >
                          Cost Per Thousand
                        </Typography>
                        <b
                          style={{
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            textAlign: 'center',
                          }}
                        >
                          {'Spends * 1000 / Impressions'}
                        </b>
                      </React.Fragment>
                    }
                  >
                    <IconButton>
                      <img src={INFO} alt='' />
                    </IconButton>
                  </InfoTooltip>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    CVR{' '}
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          >
                            Conversion Ratio
                          </Typography>
                          <b
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '400',
                              fontSize: '12px',
                              lineHeight: '20px',
                              textAlign: 'center',
                            }}
                          >
                            {'Conversions / Link Clicks'}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt='' />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaign?.campaign &&
                inefficientCampaigns(
                  typeInefficient,
                  campaign?.campaign?.values
                )?.map((campaign, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[0]}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[2].toFixed(2)}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[6].toFixed(2)}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[7].toFixed(2)}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[8].toFixed(2)}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[9].toFixed(2)}
                      </b>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box className='common3' style={{ marginTop: '30px' }}>
        {/* <Typography  style={{fontFamily:'Inter',fontSize:'18px',fontWeight:'600',lineHeight:'16px',color:'#170F49',marginBottom:'16px',padding:'15px 0px 0px 17.25px '}}>Best Performing Campaigns</Typography> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            style={{
              fontFamily: 'Inter',
              fontSize: '18px',
              fontWeight: '600',
              lineHeight: '16px',
              color: '#170F49',
              marginBottom: '16px',
              padding: '15px 0px 0px 17.25px ',
            }}
          >
            Best Performing Campaigns
          </Typography>
        </div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow style={{ background: '#F0F3FF' }}>
                <TableCell style={{ width: '390px' }}>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    Campaign Name
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    Budget (in $)
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    CPA($)
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          >
                            Cost per Acquisition
                          </Typography>
                          <b
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '400',
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          >
                            {'Spends / Conversions'}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt='' />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    CTR Outbound{' '}
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          >
                            Click Through Rate
                          </Typography>
                          <b
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '400',
                              fontSize: '12px',
                              lineHeight: '20px',
                              textAlign: 'center',
                            }}
                          >
                            {'Link clicks / Impressions'}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt='' />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    CPM($){' '}
                  </b>
                  <InfoTooltip
                    title={
                      <React.Fragment>
                        <Typography
                          style={{
                            fontFamily: 'Inter',
                            fontWeight: '600',
                            fontSize: '12px',
                            lineHeight: '20px',
                          }}
                        >
                          Cost Per Thousand
                        </Typography>
                        <b
                          style={{
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '12px',
                            lineHeight: '20px',
                            textAlign: 'center',
                          }}
                        >
                          {'Spends * 1000 / Impressions'}
                        </b>
                      </React.Fragment>
                    }
                  >
                    <IconButton>
                      <img src={INFO} alt='' />
                    </IconButton>
                  </InfoTooltip>
                </TableCell>
                <TableCell>
                  <b
                    style={{
                      fontFamily: 'Inter',
                      fontSize: '12px',
                      fontWeight: '600',
                      color: '#000000',
                      lineHeight: '15px',
                    }}
                  >
                    CVR{' '}
                    <InfoTooltip
                      title={
                        <React.Fragment>
                          <Typography
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              fontSize: '12px',
                              lineHeight: '20px',
                            }}
                          >
                            Conversion Ratio
                          </Typography>
                          <b
                            style={{
                              fontFamily: 'Inter',
                              fontWeight: '400',
                              fontSize: '12px',
                              lineHeight: '20px',
                              textAlign: 'center',
                            }}
                          >
                            {'Conversions / Link Clicks'}
                          </b>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                        <img src={INFO} alt='' />
                      </IconButton>
                    </InfoTooltip>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {campaign?.campaign &&
                bestPerformingCampaigns(
                  typeInefficient,
                  campaign?.campaign?.values
                )?.map((campaign, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[0]}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[2].toFixed(2)}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[6].toFixed(2)}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[7].toFixed(2)}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[8].toFixed(2)}
                      </b>
                    </TableCell>
                    <TableCell>
                      <b
                        style={{
                          fontFamily: 'Inter',
                          fontSize: '12px',
                          fontWeight: '400',
                          color: '#000000',
                          lineHeight: '15px',
                        }}
                      >
                        {campaign[9].toFixed(2)}
                      </b>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <div className='preview_footer_row'>
        <div className='footer_con'>
          <button className='back' onClick={onBack}>
            BACK
          </button>
          <GoToNextCompoButton title='Next' onNext={onNext} />
        </div>
      </div>
    </div>
  );
};
export default CommonFBCampaign;
