import React from "react";
import Alert from "@mui/material/Alert"
import { IconButton } from "@mui/material";
import { ReactComponent as DeleteIcon } from "../../../assets/images/Analytics/Delete2.svg";
export class Errorhandler extends React.Component {
  constructor(props) {
    super(props);
    this.errorMessage = "";
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.errorMessage = errorInfo;
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          {
            this.props.removeChart ?
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <IconButton onClick={this.props.removeChart} >
                  <DeleteIcon />
                </IconButton>
                <Alert style={{ background: "#fff", height: "90%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} severity="error">
                  {this.props.errorMessage || this.errorMessage}
                </Alert>
              </div> :
              <Alert style={{ background: "#fff", height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} severity="error">
                {this.props.errorMessage || this.errorMessage}
              </Alert>
          }
        </>
      );
    }

    return this.props.children;
  }
}