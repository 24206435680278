import { useDispatch, useSelector } from "react-redux";
import CommonAuditAd from "./CommonAuditAd";
import {
  BACKEND_STATUS,
  isPlatformSelected,
} from "../../../utils/accountManagerUtil";
import { onNextLD } from "../GoToNextCompoButton";
import { goBackAmCompo } from "../../../api/accountManager/componentTracking/action";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import { useGetClientForms } from "../../../services/clientForms";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";

export default function LDAuditAd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  const { data: form, isLoading } = useGetClientForms(formId);
  const onboardingInfo = useSelector(
    (state) => state?.form?.form?.onboardingInfo
  );

  const navigation = () => {
    navigate(generatePath(`${ldAmRoutePaths.proposol.fullbase}`, { formId }));
  };

  const nextDisable = () => {
    return (
      isPlatformSelected(form, "google-ads") !== -1 &&
      form.googleAuditData.status !== BACKEND_STATUS.COMPLETED
    );
  };

  const onBack = () => {
    navigate(
      generatePath(
        `${ldAmRoutePaths.auditForFacebook.fullbase}/${ldAmRoutePaths.auditForFacebook.sub.adset}`,
        { formId }
      )
    );
  };
  if (isLoading) {
    return <PmaxLoader />;
  }
  return (
    <CommonAuditAd
      onboardingInfo={onboardingInfo}
      nextDisable={nextDisable}
      /** onNextLD, is a curried function, no need to wrap the call in a callback */
      onNext={onNextLD(dispatch, navigation, {
        type: "AUDIT_AD",
        data: form,
      })}
      onBack={onBack}
    />
  );
}
