import { useDispatch } from 'react-redux';
import { goBackHDMAmCompo } from '../../../api/HDMAM/componentTracking/action';
import { onNextHDM } from '../GoToNextCompoButton';
import CommonAuditAdset from './CommonAuditAdset';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import hdmAmRoutePaths from '../../../pages/hdm-am/constants/hdmAmRoutePaths';

export default function HDMAuditAdset() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {formId} = useParams();

  const onBack = () => {
    dispatch(goBackHDMAmCompo())
  };
  
  const navigation = () => {
    navigate(
      generatePath(
        `${hdmAmRoutePaths.auditForFacebook.fullbase}/${hdmAmRoutePaths.auditForFacebook.sub.ad}`,
        { formId }
      )
    );
  }
  return (
    <CommonAuditAdset
      onBack={onBack}
      /** onNextHDM, is a curried function, no need to wrap the call in a callback */
      onNext={onNextHDM(dispatch, navigation)}
    />
  );
}