import React from "react";
import SingleMetricSettings from "./SingleMetricSettings";
import { Metric } from "../../models/chart";

export default function EditChartType4({ handleAllChartsForceReload, editDrawerState, currentMetricState, setCurrentMetricState, channel }) {

    const { chart, handleChartChange } = editDrawerState;
    const [leftMetrics, setLeftMetrics] = React.useState(chart.leftMetrics.concat([null]));
    const [rightMetrics, setRightMetrics] = React.useState(chart.rightMetrics?.concat([null]));
    const [table, setTable] = React.useState(chart.table);

    if (!currentMetricState.metric.channelType) {
        currentMetricState.metric.channelType = channel.type;
        currentMetricState.metric.validate();
    }

    React.useEffect(() => {
        if (table !== chart.table) {
            handleChartChange("table", table);
            setLeftMetrics([null]);
            setRightMetrics(chart.rightMetrics ? [null] : null);
        }
    }, [table]);

    React.useEffect(() => {
        handleChartChange("leftMetrics", leftMetrics.filter(metric => metric && metric.metric));
    }, [leftMetrics]);

    React.useEffect(() => {
        handleChartChange("rightMetrics", rightMetrics?.filter(metric => metric && metric.metric));
    }, [rightMetrics]);

    const updateFilter = (filter) => {
        if (currentMetricState.axis === "left") {
            const newMetrics = Array.from(leftMetrics);
            const metric = Metric.copy(newMetrics[currentMetricState.index]);
            metric.filter = filter;
            newMetrics[currentMetricState.index] = metric;
            setLeftMetrics(newMetrics);
            setCurrentMetricState({ ...currentMetricState, metric: metric });
        } else {
            const newMetrics = Array.from(rightMetrics);
            const metric = Metric.copy(newMetrics[currentMetricState.index]);
            metric.filter = filter;
            newMetrics[currentMetricState.index] = metric;
            setRightMetrics(newMetrics);
            setCurrentMetricState({ ...currentMetricState, metric: metric });
        }
    }

    return (
        <div>
            <SingleMetricSettings
                chart={chart}
                channel={channel}
                currentMetric={currentMetricState.metric}
                isKPI={chart.type === "KPI"}
                handleChartChange={handleChartChange}
                onChange={metric => {
                    if (currentMetricState.axis === "left") {
                        const newMetrics = Array.from(leftMetrics);
                        newMetrics[currentMetricState.index] = metric;
                        if (metric && currentMetricState.index === newMetrics.length - 1) {
                            newMetrics.push(null);
                        }
                        setLeftMetrics(newMetrics);
                    } else {
                        const newMetrics = Array.from(rightMetrics);
                        newMetrics[currentMetricState.index] = metric;
                        if (metric && currentMetricState.index === newMetrics.length - 1) {
                            newMetrics.push(null);
                        }
                        setRightMetrics(newMetrics);
                    }
                    setCurrentMetricState({ ...currentMetricState, metric: metric });
                }}
                updateFilter={updateFilter}
                handleAllChartsForceReload={handleAllChartsForceReload}
                onClose={() => setCurrentMetricState({ ...currentMetricState, isOpen: false })}
            />

        </div>
    );
}
