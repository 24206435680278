import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { pixis_icon as pixisIcon } from '../../../assets/icons/icon';
import backgroundImage from '../../../assets/images/leftsidepanelbg.svg';
import { profile_icon as profileIcon } from '../../../assets/icons/icon';
import { arrow_down_icon as arrowDown } from '../../../assets/icons/icon';
import { useDispatch } from 'react-redux';
import { Box, Typography, Tooltip } from '@mui/material';
import { redirect_new_icon as redirectIcon } from '../../../assets/icons/icon';
import { log_out as logOutIcon } from '../../../assets/icons/icon';
import { brand_nav_illustration as brandNavIllustration } from '../../../assets/icons/icon';

import './navigationSteps.css';

import { updateSideBar } from '../../../api/accountManager/componentTracking/action';
import {
  leftSidebarAMWithCurrentCompIds,
  listOfIds,
} from '../../../utils/navbarUtil';
import {
  accountManagerIds,
  getGoogleCampaignObject,
  getStatusForLeftBar,
  ldComponentWithIndexAm,
  leftBarStatus,
} from '../../../utils/accountManagerUtil';
import { switchRedirect } from '../../../api/analytics/action';
import { goToSpecificCompoClient } from '../../../api/accountManager/componentTracking/action';
import { changeCampaign } from '../../../api/pmaxStrategy/slice';
import usePostLogout from '../../../services/auth';

const NavigationSteps = ({ navigateSteps, active }) => {
  const form = useSelector((state) => state.form);
  const [brandName, setBrandName] = useState('');
  const [profilePopUp, setProfilePopUp] = useState(false);
  const clientState = useSelector((state) => state.client);
  const sharedAssets = useSelector(
    (state) => state.sharedAssets.googleSharedAssetsAccounts
  );
  const hdmAudit = useSelector((state) => state.hdmAudit);
  const currentCompo = useSelector(
    (state) => state.amCurrentComp.currentCompAm
  );
  const currentLeftSidebar = useSelector(
    (state) => state.amCurrentComp.currentLeftSidebarAm
  );
  console.log(currentLeftSidebar + ' on line 51');
  const [loginEmail, setLoginEmail] = useState();
  const [loginName, setLoginName] = useState('');

  const [completedNavbarObj, setCompletedNavBarObj] = useState({});
  console.log({ completedNavbarObj });
  const haveInsights = useSelector(
    (store) => store.googleCustomers.haveInsights
  );
  const dispatch = useDispatch();
  const [campaignsObj, setCampaignsObj] = useState({});

  useEffect(() => {
    if (clientState?.generatedCampaignsInfo) {
      const [campaignObject, isGenerating] = getGoogleCampaignObject(
        clientState.generatedCampaignsInfo
      );
      setCampaignsObj(campaignObject);
    }
  }, [clientState]);

  useEffect(() => {
    setLoginName(localStorage.getItem('amName') || 'Jim Halpert');
    setLoginEmail(localStorage.getItem('email'));
  }, []);

  useEffect(() => {
    if (form && form['form']) {
      console.log('hdmAudit');
      setBrandName(form.form.brandName);
      setCompletedNavBarObj(
        getStatusForLeftBar(form?.form || {}, currentCompo)
      );
    }
  }, [form, hdmAudit, currentCompo]);
  const {logoutFromUser} = usePostLogout();
  const onClickHandler = (sideOption) => {
    // setStep(sideOption);
    console.log({ sideOption });
    // console.log(step)
    if (active !== sideOption) {
      if (completedNavbarObj[sideOption] !== leftBarStatus.toBeUnlocked) {
        if (sideOption === listOfIds.STRATEGY_INPUT_PMAX) {
          dispatch(changeCampaign('campaign1'));
          dispatch(
            goToSpecificCompoClient(
              ldComponentWithIndexAm[accountManagerIds.PMAX_STRATEGY_CAMPAIGN_1]
            )
          );
        }
        if (sideOption === 'AnalyticDashboard') {
          if (window.location.pathname !== '/analytic-dashboard') {
            window.location.href = '/analytic-dashboard';
          }
        } else {
          if (
            sideOption === 'AUDIT_FACEBOOK' &&
            form?.form?.accountAnalysis?.status === 'COMPLETED'
          ) {
            dispatch(
              goToSpecificCompoClient(
                ldComponentWithIndexAm[accountManagerIds.HDM_AUDIT_ACCOUNT]
              )
            );
          } else {
            dispatch(
              updateSideBar(leftSidebarAMWithCurrentCompIds[sideOption])
            );
            if (window.location.pathname === '/analytic-dashboard') {
              dispatch(switchRedirect());
              setTimeout(() => {
                window.location.href = '/accountmanager';
              }, 500);
            }
          }
        }
      }
    }
  };
  const truncateText = (text) => {
    return text?.length > 20 ? text.substr(0, 20) + '...' : text;
  };
  return (
    <Box
      style={{
        background: `url(${backgroundImage}),white`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPositionY: -38,
        height: '100vh',
      }}
    >
      <div className='sidebar_layout amlayout'>
        <div className='logoContainer'>
          <img src={pixisIcon} alt='' />
          <Typography
            variant='h4'
            component='h3'
            style={{
              color: '#0869FB',
              marginTop: 10,
            }}
          >
            Onboarding
          </Typography>
          {/* <h2>Automations</h2> */}
        </div>
        <div className='steps'>
          <div className='steps_inner'>
            {navigateSteps ? (
              navigateSteps?.map((step, key) => (
                <div className='stepHelper' key={key}>
                  <div
                    className={`step ${
                      step.id === currentLeftSidebar &&
                      [leftBarStatus.ongoing, leftBarStatus.completed].includes(
                        completedNavbarObj[step.id]
                      )
                        ? 'stepHighlight'
                        : ''
                    }`}
                    id={step.id}
                    onClick={() => onClickHandler(step.id)}
                  >
                    <div
                      className={
                        completedNavbarObj[step.id] === leftBarStatus.completed
                          ? 'question-logo completedQuest'
                          : completedNavbarObj[step.id] ===
                            leftBarStatus.ongoing
                          ? 'question-logo selectedBackground '
                          : 'question-logo'
                      }
                    >
                      <span className='left_nav_icon'></span>
                    </div>
                    <div className='step-description'>
                      <Typography
                        style={{
                          marginBottom: 0,
                          fontSize: '14px',
                          color:
                            completedNavbarObj[step.id] ===
                            leftBarStatus.completed
                              ? '#000'
                              : step.id === active
                              ? '#000000'
                              : '#adadad',
                        }}
                      >
                        {step.name}
                      </Typography>
                      <Typography
                        style={{
                          marginTop: 0,
                          fontSize: 12,
                          color: '#aeaeae',
                        }}
                      >
                        {step.id === 'AnalyticDashboard' ||
                        step.id === 'CREATIVE_APPROVAL'
                          ? ' '
                          : completedNavbarObj[step.id] || 'To Be Unlocked'}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className='brand_navigation'>
                <div className='brand_nav_illustration'>
                  <img src={brandNavIllustration} />
                </div>
              </div>
            )}
          </div>
        </div>
        {profilePopUp && (
          <div
            style={{
              width: 200,
              textAlign: 'center',
              height: 'auto',
              position: 'absolute',
              bottom: '5rem',
              right: '0rem',
              backgroundColor: 'white',
              // border: '1px solid #d3d3d3',
              zIndex: 1,
              cursor: 'pointer',
              background: '#FFFFFF',
              border: '0.35px solid #E9E9E9',
              boxShadow: '0px 7px 80px rgba(0, 0, 0, 0.05)',
              borderRadius: '8px 8px 0px 8px',
              padding: '0.6rem',
            }}
          >
            <p
              style={{
                borderBottom: '1px solid #EFEFEF',
                paddingBottom: '1rem',
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                gap: 8,
                marginBottom: 0,
              }}
            >
              Client : {brandName || 'Brand Name'} {/* Brand Page */}
              {/* <div
                style={{
                  width: 20,
                  height: 20,
                }}
                onClick={() => {
                  window.location.href = "/brands"
                }}
              >
                <img src={redirectIcon} alt='' width={16} height={16} />
              </div> */}
            </p>
            <p
              style={{
                borderBottom: '1px solid #EFEFEF',
                paddingBottom: '1rem',
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                gap: 8,
                marginBottom: 0,
              }}
              onClick={() => {
                setTimeout(() => {
                  window.location.href = '/brands';
                }, 200);
              }}
            >
              {/* Client : {brandName || 'Brand Name'}{' '} */}
              Brand Page
              <div
                style={{
                  width: 20,
                  height: 20,
                }}
              >
                <img src={redirectIcon} alt='' width={16} height={16} />
              </div>
            </p>
            <p
              style={{
                paddingBottom: '1rem',
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                gap: 8,
                marginBottom: 0,
              }}
              onClick={() => logoutFromUser()}
            >
              <Tooltip
                PopperProps={{
                  sx: {
                    '& .MuiTooltip-arrow': {
                      color: 'white',
                    },
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: 'white',
                      color: 'black',
                      marginBottom: '20px',
                      filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                      padding: '10px 8px 8px 12px',
                    },
                  },
                }}
                title={loginName}
                arrow
                placement='right'
              >
                <span>{truncateText(loginName)}</span>
              </Tooltip>
              <div
                style={{
                  width: 20,
                  height: 20,
                }}
              >
                <img src={logOutIcon} alt='' width={18} height={18} />
              </div>
            </p>
          </div>
        )}
        <div className='navigation_footer'>
          <div className='nav-footer-icons'>
            <div className='account_manger_detials'>
              <p>
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 8px 8px 12px',
                      },
                    },
                  }}
                  title={loginName}
                  arrow
                  placement='right'
                >
                  <span>{truncateText(loginName)}</span>
                </Tooltip>
              </p>
              <p>
                <Tooltip
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-arrow': {
                        color: 'white',
                      },
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'white',
                        color: 'black',
                        marginBottom: '20px',
                        filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                        padding: '10px 8px 8px 12px',
                      },
                    },
                  }}
                  title={loginEmail}
                  arrow
                  placement='right'
                >
                  <span>{truncateText(loginEmail)}</span>
                </Tooltip>
              </p>
            </div>
            <div
              className='footer-icon profile-icon'
              onClick={() => setProfilePopUp(!profilePopUp)}
            >
              <img src={profileIcon} alt='' />
              <img src={arrowDown} alt='' className='arrow-img' />
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default NavigationSteps;
