import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  resourceIds: {},
  resources: [],
  brandedResources: [],
  competitorResources: [],
  serviceResources: [],
  generated: false,
}

export const creativeSlice = createSlice({
  name: 'creativeAI',
  initialState,
  reducers: {
    generateResourceReducer() {},
    getResourcesReducer() {},
    updateResourceReducer() {},
    updateResourcesStatusReducer() {},
    editResourceStatusReducer() {},
    udpateResourceIds: (state, action) => {
      state.resourceIds = {...state.resourceIds, ...action.payload};
    },
    updateResourcesData: (state, actions) => {
      state.resources = actions.payload;
    },
    updateAllResourceData: (state, action) => {
      Object.keys(action.payload).map(key => {
        state[key] = action.payload[key]
      })
    },
    updatedGenerated : (state, action) => {
      state.generated = !state.generated
    }
  },
})

// Action creators are generated for each case reducer function
export const { udpateResourceIds, generateResourceReducer, updateResourcesData, getResourcesReducer, updateResourceReducer, updatedGenerated, updateResourcesStatusReducer,updateAllResourceData, editResourceStatusReducer} = creativeSlice.actions

export default creativeSlice.reducer; 