import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import LineChartLogo from "../../../assets/images/Analytics/line.svg";
import BarChartLogo from "../../../assets/images/Analytics/bar.svg";
import FunnelChartLogo from "../../../assets/images/Analytics/funnel.svg";
import KPIChartLogo from "../../../assets/images/Analytics/kpi.svg";
import LinebarChartLogo from "../../../assets/images/Analytics/linebar.svg";
import TableChartLogo from "../../../assets/images/Analytics/table.svg";
import { DimensionsLength, MetricsLength } from "../Charts/ChartUtils";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Slide from '@material-ui/core/Slide';

const allChartTypes = {
  LINE: { type: "LINE", name: "Line", image: LineChartLogo, compatible: 1 },
  BAR: { type: "BAR", name: "Bar", image: BarChartLogo, compatible: 1 },
  FUNNEL: { type: "FUNNEL", name: "Funnel", image: FunnelChartLogo, compatible: 2 },
  KPI: { type: "KPI", name: "KPI", image: KPIChartLogo, compatible: 4 },
  LINEBAR: { type: "LINEBAR", name: "Line bar", image: LinebarChartLogo, compatible: 1 },
  TABLE: { type: "TABLE", name: "Table", image: TableChartLogo, compatible: 3 },
}

const normalBorder = "1px solid #EFEFEF";
const selectedBorder = "4px solid rgba(8, 105, 251, 0.4)";

export default function SwitchChart({ chartMetrics, chartDimensions, chartType, setChartType, newChart }) {

  const convertableDimensions = (chartType) => {
    return (newChart || chartDimensions.filter(dim => dim)?.length <= DimensionsLength[chartType])
  }
  const convertableMetrics = (chartType) => {
    return (newChart || chartMetrics.filter(metric => metric)?.length <= MetricsLength[chartType])
  }

  const specificCharts = newChart ? Object.values(allChartTypes) : Object.values(allChartTypes).filter(chart => chart.compatible === allChartTypes[chartType].compatible);

  const [{ start, end }, setPagination] = useState({ start: 0, end: 8 })

  const navigate = (type) => {
    if (type === "Next") {
      setPagination({ start: start + 9, end: end + 9 })
    } else {
      setPagination({ start: start - 9, end: end - 9 })
    }
  }

  return (
    <div
      className="smooth-shadow"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "370px",
        padding: "18px 13px",
        borderRadius: "10px",
        overflow: "hidden"
      }}
    >
      {/* <SimpleSlide /> */}
      {(specificCharts.length > 9) &&
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton sx={{ color: "black" }} style={{ background: "#F8F8F8", margin: "5px", padding: "5px" }}
            onClick={() => { navigate("BACK"); }}
            disabled={start === 0}>
            <ArrowLeftIcon />
          </IconButton>
          <IconButton sx={{ color: "black" }} style={{ background: "#F8F8F8", margin: "5px", padding: "5px" }}
            onClick={() => { navigate("Next"); }}
            disabled={specificCharts.length <= (end + 1)}>
            <ArrowRightIcon />
          </IconButton>
        </div>
      }
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {specificCharts.filter((chart, index) => index >= start && index <= end).map((chartImage, index) => (
          <Tooltip
            title={
              (DimensionsLength[chartImage.type] && !convertableDimensions(chartImage.type))
                ? `Unable to convert for dimensions greater than ${DimensionsLength[chartImage.type]}`
                : (MetricsLength[chartImage.type] && !convertableMetrics(chartImage.type))
                  ? `Unable to convert for metrics greater than ${MetricsLength[chartImage.type]}`
                  : ""
            }
            placement="bottom"
          >
            <div
              key={index}
              style={
                ((DimensionsLength[chartImage.type] && !convertableDimensions(chartImage.type)) ||
                  (MetricsLength[chartImage.type] && !convertableMetrics(chartImage.type)))
                  ? {
                    margin: "10px 5px",
                    opacity: "0.5",
                    cursor: "default",
                  }
                  : { margin: "10px 5px" }
              }
            >
              <h5 className="inter bold" style={{ marginBottom: "5px" }}>
                {chartImage.name}
              </h5>
              <div
                style={{
                  width: "100px",
                  height: "80px",
                  backgroundColor: "rgba(200, 200, 200, 0.2)",
                  border:
                    chartType === chartImage.type
                      ? selectedBorder
                      : normalBorder,
                  borderRadius: "5px",
                  cursor:
                    ((DimensionsLength[chartImage.type] && !convertableDimensions(chartImage.type)) ||
                      (MetricsLength[chartImage.type] && !convertableMetrics(chartImage.type)))
                      ? "default"
                      : "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (
                    !((DimensionsLength[chartImage.type] && !convertableDimensions(chartImage.type)) ||
                      (MetricsLength[chartImage.type] && !convertableMetrics(chartImage.type)))
                  )
                    setChartType(chartImage.type);
                }}
              >
                <img
                  style={{ width: "70px", maxHeight: "60px" }}
                  src={chartImage.image}
                  alt={chartImage.name}
                />
              </div>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
