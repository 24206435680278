import { Box, FormControlLabel, MenuItem, Modal, Radio, RadioGroup, Select, Tooltip } from '@mui/material';
import { useMemo, useState } from 'react';
import Slider from 'react-slick';
import { SLIDDER_SETTINGS } from '.';
import { useGetFBCreativeResources, usePutUpdateFbCreativeResources } from '../../../services/creative-resources-fb-ads';
import { useGetFBGaiResources, usePutUpdateFbGaiResources } from '../../../services/facebook-gai-resources';
import { numberOfAds } from '../../../utils/strategyCreationFacebook';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import { STRATEGY_FLOW_BACKEND } from '../utils';
import UploadImageField from './UploadImageField';
import { findUrl, truncateString } from './utils';

const NULL_CREATIVE_ADS = [];
const NULL_GAI_ADCOPY_SETS = [];

const MODAL_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1004,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  padding: '46px 47px 42px',
  boxShadow: 24,
};

export default function AddNewAdModal({
  open,
  onClose,
  websiteUrl = "",
  onAddNewAd,
  onCreateNewAdd, // () => void
  formId,
  strategyFlow,
  strategyId,
}) {
  const { data: facebookAds = NULL_CREATIVE_ADS } = useGetFBCreativeResources(formId, {
    select: (responseData) => responseData.ads,
    enabled: strategyFlow === STRATEGY_FLOW_BACKEND.GUIDED,
  });

  const { data: gaiAdCopySets = NULL_GAI_ADCOPY_SETS } = useGetFBGaiResources({ formId, strategyId }, {
    select: (responseData) => {
      return responseData.data.map((adcopyset) => ({
        ...adcopyset,
        ads: adcopyset.ads.map(ad => ({ ...ad, adSetCopyId: adcopyset.id })),
      }));
    },
    enabled: strategyFlow === STRATEGY_FLOW_BACKEND.SMART,
  });

  const mappedCreativeAd = useMemo(() => {
    return facebookAds.map((ad, index) => ({
      ads: ad.response.map((response) => ({
        ...response,
        imageUrl: response.adImageUrl || ad.imageUrl,
        requestId: ad?.requestId,
        responseId: response.id,
        adId: ad.id,
      })),
      name: `CREATIVE${index + 1}`,
    }));
  }, [facebookAds]);

  const [currentCreativeIndex, setCurrentCreativeIndex] = useState(0);

  const [addToThisAdsetList, setAddToThisAdsetList] = useState({});
  const [addToAllAdsetList, setAddToAllAdsetList] = useState({});

  const adSets = {
    [STRATEGY_FLOW_BACKEND.GUIDED]: mappedCreativeAd,
    [STRATEGY_FLOW_BACKEND.SMART]: gaiAdCopySets,
  }[strategyFlow] || [];

  const currentAdsList = adSets[currentCreativeIndex]?.ads || [];

  const { mutateAsync: updateGaiAdAsync } = usePutUpdateFbGaiResources({ formId });
  const { mutateAsync: updateCreativeAdAsync } = usePutUpdateFbCreativeResources({ formId });
  const onGaiImageUpload = (adSetCopyId, adId) => async (imageUrl) => {
    try {
      await updateGaiAdAsync({
        resourceId: adSetCopyId,
        adId,
        ad: { imageUrl },
        strategyId,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onCreativeImageUpload = (adId, responseId) => async (adImageUrl) => {
    try {
      await updateCreativeAdAsync({
        adId,
        responseId,
        response: { adImageUrl },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // returns (imageUrl) => Promise<void>
  const onImageUpload = (ad) => {
    switch (strategyFlow) {
      case STRATEGY_FLOW_BACKEND.SMART: {
        return onGaiImageUpload(ad.adSetCopyId, ad.id);
      }
      case STRATEGY_FLOW_BACKEND.GUIDED: {
        return onCreativeImageUpload(ad.adId, ad.responseId);
      }
      default: break;
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='large_addad_popup'
      onClose={onClose}
    >
      <Box sx={MODAL_STYLE}>
        <div className='addad_popup_layout'>
          <div className='addad_modal_header'>
            <p>Add Ad</p>
            <i
              className='popup_close_button'
              onClick={onClose}
            >
              <span></span>
            </i>
          </div>
          <div className='addad_modal_layout' style={{
            maxHeight: "calc(100vh - 9rem)",
            overflowY: "auto",
          }}>
            <div
              className='addad_modal_dropdown'
              style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
            >
              <Select
                labelId='demo-multiple-checkbox-label'
                id='demo-multiple-checkbox'
                value={currentCreativeIndex}
                onChange={(e) => setCurrentCreativeIndex(e.target.value)}
                sx={{
                  "& .drop_custom_list p": {
                    fontSize: "14px",
                    lineHeight: "14px",
                  },
                }}
              >
                {adSets.map(({ ads, name }, index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    className='drop_custom_list_row popup_drop_list'
                  >
                    <div className='drop_custom_list'>
                      <div>
                        <Radio
                          value={index}
                          checked={index === currentCreativeIndex}
                        />
                        <p>{name}</p>
                      </div>
                      <span>
                        {strategyFlow === STRATEGY_FLOW_BACKEND.GUIDED && (
                          <img
                            src={
                              process.env.REACT_APP_BACKEND_BASE_URL +
                              '/files/' +
                              findUrl(facebookAds, ads[0]?.requestId)
                            }
                            style={{ width: '50px', height: '50px' }}
                            alt="ad_image"
                          />
                        )}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>

              {onCreateNewAdd && (
                <PrimaryButton
                  type="light"
                  className="edit_response_button"
                  title="Create New Ad"
                  onClick={onCreateNewAdd}
                />
              )}
            </div>
            <div className='addad_modal_slider'>
              <div className='adset_slider'>
                <Slider {...SLIDDER_SETTINGS}>
                  {currentAdsList.map(
                    (ad, index) => (
                      <div className='adset_slide' key={index}>
                        <div className='adset_slide_block'>
                          <div className='adset_slide_head'>
                            <h3>{ad.name ? ad.name : <>Ad {index + 1}</>}</h3>
                          </div>
                          <div className='adset_slide_head_skip'>
                            <Tooltip title={ad.primaryText} arrow>
                              <p>
                                {truncateString(ad.primaryText)}
                              </p>
                            </Tooltip>
                          </div>
                          <UploadImageField
                            style={{ fontSize: "12px" }}
                            value={ad.imageUrl}
                            onChange={onImageUpload(ad)}
                          />
                          {/* <div className='adset_slide_image'>
                            <img
                              src={
                                process.env.REACT_APP_BACKEND_BASE_URL +
                                '/files/' +
                                ad.imageUrl
                              }
                              alt="adset_slide_image"
                            />
                          </div> */}
                          <div className='adset_slide_footer flex-center-space'>
                            <div className='adset_slide_footer_info'>
                              <h2>
                                {websiteUrl}
                              </h2>
                              <p>{ad.headline}</p>
                              <span>{ad.description}</span>
                            </div>
                            <div className='adset_slide_footer_action'>
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a
                                // eslint-disable-next-line no-script-url
                                href='javascript:;'
                                className='adset_book_button'
                              >
                                Book Now
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className='adset_slide_action_row flex-center-center'>
                          <div className='addad_action_row'>
                            <RadioGroup
                              aria-labelledby={`${currentCreativeIndex + '_' + index
                                }-demo-controlled-radio-buttons-group`}
                              className='radio_group_popup'
                              name={`${currentCreativeIndex + '_' + index
                                }-controlled-radio-buttons-group`}
                              onChange={(e) => {
                                var value = e.target.value.split('-');
                                if (value[1] === 'ADD_TO_THIS_ADSET') {
                                  const obj1 = {
                                    ...addToThisAdsetList,
                                  };
                                  const obj2 = { ...addToAllAdsetList };
                                  if (
                                    obj2[currentCreativeIndex + '-' + index]
                                  ) {
                                    delete obj2[
                                      currentCreativeIndex + '-' + index
                                    ];
                                    obj1[
                                      currentCreativeIndex + '-' + index
                                    ] = {
                                      ...ad,
                                      ...{
                                        adName: ad.name ||
                                          'AD ' +
                                          Math.floor(
                                            Math.random() *
                                            numberOfAds(facebookAds)
                                          ),
                                      },
                                    };
                                  } else {
                                    obj1[
                                      currentCreativeIndex + '-' + index
                                    ] = {
                                      ...ad,
                                      ...{
                                        adName: ad.name ||
                                          'AD ' +
                                          Math.floor(
                                            Math.random() *
                                            numberOfAds(facebookAds)
                                          ),
                                      },
                                    };
                                  }
                                  setAddToThisAdsetList(obj1);
                                  setAddToAllAdsetList(obj2);
                                } else {
                                  const obj1 = { ...addToAllAdsetList };
                                  const obj2 = {
                                    ...addToThisAdsetList,
                                  };
                                  if (
                                    obj2[currentCreativeIndex + '-' + index]
                                  ) {
                                    delete obj2[
                                      currentCreativeIndex + '-' + index
                                    ];
                                    obj1[
                                      currentCreativeIndex + '-' + index
                                    ] = {
                                      ...ad,
                                      ...{
                                        adName: ad.name ||
                                          'AD ' +
                                          Math.floor(
                                            Math.random() *
                                            numberOfAds(facebookAds)
                                          ),
                                      },
                                    };
                                  } else {
                                    obj1[
                                      currentCreativeIndex + '-' + index
                                    ] = {
                                      ...ad,
                                      ...{
                                        adName: ad.name ||
                                          'AD ' +
                                          Math.floor(
                                            Math.random() *
                                            numberOfAds(facebookAds)
                                          ),
                                      },
                                    };
                                  }
                                  setAddToAllAdsetList(obj1);
                                  setAddToThisAdsetList(obj2);
                                }
                              }}
                            >
                              <FormControlLabel
                                className='addad_lable_radio'
                                value={`${currentCreativeIndex + '_' + index
                                  }-ADD_TO_THIS_ADSET`}
                                control={
                                  <Radio className='addad_radio_action' />
                                }
                                label='Add to this adset'
                              />
                              <FormControlLabel
                                className='addad_lable_radio'
                                value={`${currentCreativeIndex + '_' + index
                                  }-ADD_TO_ALL_ADSET`}
                                control={
                                  <Radio className='addad_radio_action' />
                                }
                                label='Add to all adsets'
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </Slider>
              </div>
            </div>
          </div>

          <div className='addad_modal_footer'>
            <div className='addad_row_footer'>
              <button
                className='cancle_button_modal'
                onClick={() => {
                  setAddToThisAdsetList({});
                  setAddToAllAdsetList({});
                  onClose?.();
                }}
              >
                Cancel
              </button>
              <PrimaryButton
                className='edit_response_button'
                title='Save Changes'
                onClick={() => {
                  onAddNewAd({
                    addToThisAdsetList: Object.values(addToThisAdsetList),
                    addToAllAdsetList: Object.values(addToAllAdsetList),
                  });
                  setAddToThisAdsetList({});
                  setAddToAllAdsetList({});
                  onClose?.();
                }}
              ></PrimaryButton>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
