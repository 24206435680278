import { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import React from "react";

import { pencil as pencilIcon } from "../../../assets/icons/common/common";
import TextField from "@material-ui/core/TextField";
import { checked_icon as checkedIcon } from "../../../assets/icons/common/common";
import BpRadio from "../../ClientDashboard/CustomRadio/Radio";
const OptionalAdOns = (props) => {
  const [addLPPriceEdit, setaddLPPriceEdit] = useState(false);
  const [addPUPriceEdit, setaddPUPriceEdit] = useState(false);
  const [addSBPriceEdit, setaddSBPriceEdit] = useState(false);
  const [callRailPriceEdit, setCallRailPriceEdit] = useState(false);
  const [hotjarPriceEdit, setHotjarPriceEdit] = useState(false);
  const [growFormsEdit, setGrowFormsEdit] = useState(false);
  useEffect(() => {
    console.log("Addon extra service : ");
    console.log("Call rail : ", props.selectCallRail);
    console.log("Hotjar : ", props.selectHotjar);
    console.log("Grow Forms : ", props.selectGrowforms);
  }, [props.selectCallRail, props.selectGrowforms, props.selectHotjar]);

  return (
    <>
      <ul
        style={{
          // listStyleType: "none",
          paddingLeft: 0,
        }}
      >
        <li
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "1rem",
            gap: 28,
          }}
        >
          <div style={{ display: "flex", gap: "0.75rem" }}>
            <img src={props.iconBullet} alt="" />
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 12,
                color: "#170F49",
              }}
            >
              Additional Landing Pages
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              //  marginLeft: '2.2rem',
              //  gap: '1.5rem'
              alignItems: "center",
              gap: 16,
            }}
          >
            <div
            // style={{
            //   display: 'flex',
            //   flexDirection: 'column',
            //   gap: '0.25rem',
            // }}
            >
              {/* <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Quantity
              </Typography> */}
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#170F49",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={
                    props.isRateCardSelected && props.decreaseLPQuantityHandler
                  }
                >
                  -
                </span>
                <span
                  style={{
                    padding: "0 0.8rem",
                    margin: "0 0.25rem",
                    border: "0.5px solid #E1E1E1",
                    borderRadius: 4,
                  }}
                >
                  {props.additionalLPQuantity}
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={
                    props.isRateCardSelected && props.increaseLPQuantityHandler
                  }
                >
                  +
                </span>
              </Typography>
            </div>
            <div
              style={{
                backgroundColor: "#E7E8F2",
                height: 35,
                width: 0.75,
              }}
            ></div>
            <div
            // style={{
            //   display: 'flex',
            //   flexDirection: 'column',
            //   gap: '0.25rem',
            // }}
            >
              {/* <Typography
                style={{
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 10,
                  color: '#878787',
                }}
              >
                Price
              </Typography> */}
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#170F49",
                  display: "flex",
                  gap: addLPPriceEdit ? 3 : 5,
                }}
              >
                $
                {addLPPriceEdit ? (
                  <TextField
                    inputProps={{
                      style: {
                        width: "24px",
                        padding: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#170F49",
                      },
                    }}
                    value={props.additionalLPPrice}
                    onChange={(e) => {
                      props.setHasChange(true);
                      props.setToBeSave(true);
                      props.setAdditionalLPPrice(e.target.value);
                    }}
                  />
                ) : (
                  props.additionalLPPrice
                )}
                {/* &nbsp;&nbsp; */}
                <img
                  src={pencilIcon}
                  alt=""
                  style={{
                    width: 12,
                    height: 12,
                  }}
                  onClick={() => {
                    props.isRateCardSelected &&
                      setaddLPPriceEdit(!addLPPriceEdit);
                  }}
                />
              </Typography>
            </div>
          </div>
        </li>
        <li
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "1rem",
            gap: 28,
          }}
        >
          <div style={{ display: "flex", gap: "0.75rem" }}>
            <img src={props.iconBullet} alt="" />
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 12,
                color: "#170F49",
              }}
            >
              Additional Popup
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              // marginLeft: 31,
              //  gap: '1.5rem'
              alignItems: "center",
              gap: 16,
            }}
          >
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#170F49",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={
                    props.isRateCardSelected && props.decreasePUQuantityHandler
                  }
                >
                  -
                </span>
                <span
                  style={{
                    padding: "0 0.8rem",
                    margin: "0 0.25rem",
                    border: "0.5px solid #E1E1E1",
                    borderRadius: 4,
                  }}
                >
                  {props.additionalPUQuantity}
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={
                    props.isRateCardSelected && props.increasePUQuantityHandler
                  }
                >
                  +
                </span>
              </Typography>
            </div>
            <div
              style={{
                backgroundColor: "#E7E8F2",
                height: 35,
                width: 0.75,
              }}
            ></div>
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#170F49",
                  display: "flex",
                  gap: addPUPriceEdit ? 3 : 5,
                  alignItems: "center",
                }}
              >
                $
                {addPUPriceEdit ? (
                  <TextField
                    inputProps={{
                      style: {
                        width: "24px",
                        padding: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#170F49",
                      },
                    }}
                    value={props.additionalPUPrice}
                    onChange={(e) => {
                      props.setHasChange(true);
                      props.setToBeSave(true);
                      props.setAdditionalPUPrice(e.target.value);
                    }}
                  />
                ) : (
                  props.additionalPUPrice
                )}
                {/* &nbsp;&nbsp; */}
                <img
                  src={pencilIcon}
                  alt=""
                  style={{
                    width: 12,
                    height: 12,
                  }}
                  onClick={() => {
                    props.isRateCardSelected &&
                      setaddPUPriceEdit(!addPUPriceEdit);
                  }}
                />
              </Typography>
            </div>
          </div>
        </li>
        <li
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "1rem",
            gap: 28,
          }}
        >
          <div style={{ display: "flex", gap: "0.75rem" }}>
            <img src={props.iconBullet} alt="" />
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 12.85,
                color: "#170F49",
              }}
            >
              Additional Sticky Bar
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              //  marginLeft: '2.2rem',
              //  gap: '1.5rem'
              alignItems: "center",
              gap: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                //  marginLeft: '2.2rem',
                //  gap: '1.5rem'
                alignItems: "center",
                gap: 16,
              }}
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#170F49",
                }}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={
                    props.isRateCardSelected && props.decreaseSBQuantityHandler
                  }
                >
                  -
                </span>
                <span
                  style={{
                    padding: "0 0.8rem",
                    margin: "0 0.25rem",
                    border: "0.5px solid #E1E1E1",
                    borderRadius: 4,
                  }}
                >
                  {props.additionalSBQuantity}
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={
                    props.isRateCardSelected && props.increaseSBQuantityHandler
                  }
                >
                  +
                </span>
              </Typography>
            </div>
            <div
              style={{
                backgroundColor: "#E7E8F2",
                height: 35,
                width: 0.75,
              }}
            ></div>
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#170F49",
                  display: "flex",
                  gap: addSBPriceEdit ? 3 : 5,
                }}
              >
                $
                {addSBPriceEdit ? (
                  <TextField
                    inputProps={{
                      style: {
                        width: "24px",
                        padding: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#170F49",
                      },
                    }}
                    value={props.additionalSBPrice}
                    onChange={(e) => {
                      props.setHasChange(true);
                      props.setToBeSave(true);
                      props.setAdditionalSBPrice(e.target.value);
                    }}
                  />
                ) : (
                  props.additionalSBPrice
                )}
                {/* &nbsp;&nbsp; */}
                <img
                  src={pencilIcon}
                  alt=""
                  style={{
                    width: 12,
                    height: 12,
                  }}
                  onClick={() => {
                    props.isRateCardSelected &&
                      setaddSBPriceEdit(!addSBPriceEdit);
                  }}
                />
              </Typography>
            </div>
          </div>
        </li>
        <li
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "1rem",
            gap: 28,
          }}
        >
          <div style={{ display: "flex", gap: "0.75rem" }}>
            <img src={props.iconBullet} alt="" />
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 12.85,
                color: "#170F49",
              }}
            >
              Call Rail
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              //  marginLeft: '2.2rem',
              //  gap: '1.5rem'
              alignItems: "center",
              gap: 19,
            }}
          >
            <div
              style={{
                display: "flex",
                //  marginLeft: '2.2rem',
                //  gap: '1.5rem'
                alignItems: "center",
                gap: 4,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BpRadio
                  checked={props.selectCallRail}
                  onChange={() => {
                    props.setToBeSave(true);
                    props.isSelectCallRail(true);
                    props.increaseCallRailQuantityHandler();
                  }}
                  disabled={!props.isRateCardSelected}
                />
                <Typography
                  style={{
                    color: "#2E3044",
                    fontFamily: "Inter",
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Yes
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BpRadio
                  checked={!props.selectCallRail}
                  onChange={() => {
                    props.setToBeSave(true);
                    props.isSelectCallRail(false);
                    props.decreaseCallRailQuantityHandler();
                  }}
                  disabled={!props.isRateCardSelected}
                />
                <Typography
                  style={{
                    color: "#2E3044",
                    fontFamily: "Inter",
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  No
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#170F49",
                  display: "flex",
                  gap: callRailPriceEdit ? 3 : 5,
                  alignItems: "center",
                }}
              >
                $
                {callRailPriceEdit ? (
                  <TextField
                    inputProps={{
                      style: {
                        width: "24px",
                        padding: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#170F49",
                      },
                    }}
                    value={props.callRailPrice}
                    onChange={(e) => {
                      props.setHasChange(true);
                      props.setToBeSave(true);
                      props.setCallRailPrice(e.target.value);
                    }}
                  />
                ) : (
                  props.callRailPrice
                )}
                {/* &nbsp;&nbsp; */}
                <img
                  src={pencilIcon}
                  alt=""
                  style={{
                    width: 12,
                    height: 12,
                  }}
                  onClick={() =>
                    props.isRateCardSelected &&
                    setCallRailPriceEdit(!callRailPriceEdit)
                  }
                />
              </Typography>
            </div>
          </div>
        </li>
        <li
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "1rem",
            gap: 39,
          }}
        >
          <div style={{ display: "flex", gap: "0.75rem" }}>
            <img src={props.iconBullet} alt="" />
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 12.85,
                color: "#170F49",
              }}
            >
              Hotjar
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              //  marginLeft: '2.2rem',
              //  gap: '1.5rem'
              alignItems: "center",
              gap: 19,
            }}
          >
            <div
              style={{
                display: "flex",
                //  marginLeft: '2.2rem',
                //  gap: '1.5rem'
                alignItems: "center",
                gap: 4,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BpRadio
                  checked={props.selectHotjar}
                  onChange={() => {
                    props.isSelectHotjar(true);
                    props.setToBeSave(true);
                    props.increaseHotjarQuantityHandler();
                  }}
                  disabled={!props.isRateCardSelected}
                />
                <Typography
                  style={{
                    color: "#2E3044",
                    fontFamily: "Inter",
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Yes
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BpRadio
                  checked={!props.selectHotjar}
                  onChange={() => {
                    props.isSelectHotjar(false);
                    props.setToBeSave(true);
                    props.decreaseHotjarQuantityHandler();
                  }}
                  disabled={!props.isRateCardSelected}
                />
                <Typography
                  style={{
                    color: "#2E3044",
                    fontFamily: "Inter",
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  No
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#170F49",
                  display: "flex",
                  gap: hotjarPriceEdit ? 3 : 5,
                  alignItems: "center",
                }}
              >
                $
                {hotjarPriceEdit ? (
                  <TextField
                    inputProps={{
                      style: {
                        width: "24px",
                        padding: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#170F49",
                      },
                    }}
                    value={props.hotjarPrice}
                    onChange={(e) => {
                      props.setToBeSave(true);
                      props.setHasChange(true);
                      props.setHotjarPrice(e.target.value);
                    }}
                  />
                ) : (
                  props.hotjarPrice
                )}
                {/* &nbsp;&nbsp; */}
                <img
                  src={pencilIcon}
                  alt=""
                  style={{
                    width: 12,
                    height: 12,
                  }}
                  onClick={() =>
                    props.isRateCardSelected &&
                    setHotjarPriceEdit(!hotjarPriceEdit)
                  }
                />
              </Typography>
            </div>
          </div>
        </li>
        <li
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "1rem",
            gap: 28,
          }}
        >
          <div style={{ display: "flex", gap: "0.75rem" }}>
            <img src={props.iconBullet} alt="" />
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 12.85,
                color: "#170F49",
              }}
            >
              Grow Forms
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              //  marginLeft: '2.2rem',
              //  gap: '1.5rem'
              alignItems: "center",
              gap: 19,
            }}
          >
            <div
              style={{
                display: "flex",
                //  marginLeft: '2.2rem',
                //  gap: '1.5rem'
                alignItems: "center",
                gap: 4,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BpRadio
                  checked={props.selectGrowforms}
                  onChange={() => {
                    props.setToBeSave(true);
                    props.isSelectGrowforms(true);
                    props.increaseGrowFormsQuantityHandler();
                  }}
                  disabled={!props.isRateCardSelected}
                />
                <Typography
                  style={{
                    color: "#2E3044",
                    fontFamily: "Inter",
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Yes
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BpRadio
                  checked={!props.selectGrowforms}
                  onChange={() => {
                    props.setToBeSave(true);
                    props.isSelectGrowforms(false);
                    props.decreaseGrowFormsQuantityHandler();
                  }}
                  disabled={!props.isRateCardSelected}
                />
                <Typography
                  style={{
                    color: "#2E3044",
                    fontFamily: "Inter",
                    fontSize: 12,
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  No
                </Typography>
              </div>
            </div>
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: 12,
                  color: "#170F49",
                  display: "flex",
                  gap: growFormsEdit ? 3 : 5,
                  alignItems: "center",
                }}
              >
                $
                {growFormsEdit ? (
                  <TextField
                    inputProps={{
                      style: {
                        width: "24px",
                        padding: 0,
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 12,
                        color: "#170F49",
                      },
                    }}
                    value={props.growFormsPrice}
                    onChange={(e) => {
                      props.setToBeSave(true);
                      props.setHasChange(true);
                      props.setGrowFormsPrice(e.target.value);
                    }}
                  />
                ) : (
                  props.growFormsPrice
                )}
                {/* &nbsp;&nbsp; */}
                <img
                  src={pencilIcon}
                  alt=""
                  style={{
                    width: 12,
                    height: 12,
                  }}
                  onClick={() =>
                    props.isRateCardSelected && setGrowFormsEdit(!growFormsEdit)
                  }
                />
              </Typography>
            </div>
          </div>
        </li>
      </ul>
    </>
  );
};

export default OptionalAdOns;
