const BASE = "/admin";

const adminRoutePaths = /** @type {const} */ ({
  root: BASE,
  fullbase: `${BASE}`,
  sub: {
    client: "client",
    am: "am",
  },
});

export default adminRoutePaths;
