import { useMutation, useQuery } from "@tanstack/react-query";
import { api } from "../../api";

const taiCronEndpoint = formId => `/targeting-keywords/pmax-ads/${formId}`;

export const usePostGenerateSegments = (formId, options={}) => {
    const { onSuccess, ...otherOptions } = options;
    return useMutation({
        mutationFn: async () => {
            const response = await api.post(taiCronEndpoint(formId));
            return response.data;
        },
        onSuccess: (...args) => {
            onSuccess?.(...args);
        },
        ...otherOptions
    })
}

export const useGetPmaxGeneratedSegments = ({formId}, options={}) => {
    return useQuery(
        ["/targeting-keywords/pmax-ads/{formId}", formId],
        async () => {
            const response = await api.get(`/targeting-keywords/pmax-ads/${formId}`);
            return response.data;
        },
        options,
    );
}