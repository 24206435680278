import { leftPanelNameInUrlPath } from "../../../utils/LDUtils/client/utils";

const BASE = "/ld-client";

const ldClientRoutePaths = /** @type {const} */ ({
  root: BASE,
  homePage: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.HOMEPAGE}`,
    root: `${leftPanelNameInUrlPath.HOMEPAGE}`, // should matches the end of fullbase
    sub: {},
  },
  readSharedAssets: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.READ_SHARED_ASSETS}`,
    root: `${leftPanelNameInUrlPath.READ_SHARED_ASSETS}`, // should matches the end of fullbase
    sub: {},
  },
  proposol: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.PROPOSAL}`,
    root: `${leftPanelNameInUrlPath.PROPOSAL}`, // should matches the end of fullbase
    sub: {
      proposalView: "",
      suggestChanges: "suggest-changes",
    },
  },
  intakeForm: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.INTAKE_FORM}`,
    root: `${leftPanelNameInUrlPath.INTAKE_FORM}`, // should matches the end of fullbase
    sub: {
      review: "",
      productInfo: "product-info",
      productInfoWithQuestionNumber: "product-info/:questionNumber",
      preferences: "preferences",
      preferencesWithQuestionNumber: "preferences/:questionNumber",
      googleTargeting: "google-targeting",
      googleTargetingWithQuestionNumber: "google-targeting/:questionNumber",
      branding: "branding",
      brandingWithQuestionNumber: "branding/:questionNumber",
      socialProof: "social-proof",
      socialProofWithQuestionNumber: "social-proof/:questionNumber",
      facebookTargeting: "facebook-targeting",
      facebookTargetingWithQuestionNumber: "facebook-targeting/:questionNumber"
    },
  },
  completeSharedAssets: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.COMPLETE_SHARED_ASSETS}`,
    root: `${leftPanelNameInUrlPath.COMPLETE_SHARED_ASSETS}`, // should matches the end of fullbase
    sub: {},
  },
  strategyDeck: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.STRATEGY_DECK}`,
    root: `${leftPanelNameInUrlPath.STRATEGY_DECK}`, // should matches the end of fullbase
    sub: {
      introduction: '',
      google: 'google',
      facebook: 'facebook',
      suggestChanges: "suggest-changes",
    },
  },
  campaignPreview: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CAMPAIGN_PREVIEW}`,
    root: `${leftPanelNameInUrlPath.CAMPAIGN_PREVIEW}`, // should matches the end of fullbase
    sub: {},
  },
  creativeApproval: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.CREATIVE_APPROVAL}`,
    root: `${leftPanelNameInUrlPath.CREATIVE_APPROVAL}`, // should matches the end of fullbase
    sub: {
      adCopies: "",
      creatives: "creatives",
      landingPages: "landing-pages",
      abTest: 'ab-test',
    },
  },
  analyticsDashboard: {
    fullbase: `${BASE}/${leftPanelNameInUrlPath.ANALYTICS_DASHBOARD}`,
    root: `${leftPanelNameInUrlPath.ANALYTICS_DASHBOARD}`, // should matches the end of fullbase
    sub: {},
  },

});

export default ldClientRoutePaths;
