import React, { useState } from "react";
import {
  Grid,
  Typography,
  keyframes,
  Slide,
  Hidden,
  Checkbox,
  TextField,
} from "@mui/material";
import { spends as spendsIcon } from "../../../assets/icons/questionnare/questionnare";
import { cpa_icon as cpaIcon } from "../../../assets/icons/questionnare/questionnare";
import { HighlightTwoTone } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { updateArrayCheckbox } from "../../../api/client/action";

const CheckBoxOption = (props) => {
  const [highlight, setHighlight] = useState(false);
  const dispatch = useDispatch();
  const onChangeHandler = (e) => {
    // console.log(props.objKey.includes("platform"));
    // console.log(
    //   `Platform : ${e.target.value} , isChecked : ${e.target.checked}`
    // );
    const tempArr = props.platforms;
    const platform = e.target.value;
    const isSelected = e.target.checked;
    if (platform === "google-ads" && isSelected) {
      tempArr.push({
        platform: props.platform,
        cpa: 0,
        spends: 0,
        currency: "",
      });
    } else if (platform === "google-ads" && !isSelected) {
      const index = tempArr.findIndex(
        (platform) => platform.platform === "google-ads"
      );
      tempArr.splice(index, 1);
    } else if (platform === "facebook-ads" && isSelected) {
      tempArr.push({
        platform: props.platform,
        cpa: 0,
        spends: 0,
        currency: "",
      });
    } else if (platform === "facebook-ads" && !isSelected) {
      const index = tempArr.findIndex(
        (platform) => platform.platform === "facebook-ads"
      );
      tempArr.splice(index, 1);
    }
    props.setPlatformInfo((prevState) => {
      return {
        ...prevState,
        platforms: tempArr,
      };
    });
    // dispatch(
    //   updateArrayCheckbox({
    //     platform: props.platform,
    //     infoType: props.infoType,
    //     objKey: props.objKey,
    //   })
    // );
  };
  const spends =
    props.platforms.find((plat) => plat.platform === props.platform)?.spends ??
    null;
  const cpa =
    props.platforms.find((plat) => plat.platform === props.platform)?.cpa ??
    null;
  return (
    <div
      style={{
        background: props?.disabled ? "#eee" : "#FFFFFF",
        border: `0.770024px solid ${highlight ? "#0869FB" : "#D7D8DC"}`,
        boxShadow: "0px 1.54005px 3.29771px rgba(20, 20, 43, 0.04)",
        borderRadius: 16.4885,
        position: "relative",
        width: 232,
        padding: "1.2rem",
      }}
    >
      <div style={{ position: "absolute", right: 6, top: -1 }}>
        <Checkbox
          size="small"
          onChange={onChangeHandler}
          checked={props.platforms.find(
            (platform) => platform.platform === props.platform
          )}
          value={props.platform}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "1.5rem",
          marginBottom: "2rem",
          gap: "1rem",
        }}
      >
        <div>
          <img
            src={props.imgIcon}
            alt=""
            style={{
              width: 42,
              height: 42,
            }}
          />
        </div>
        <div>
          {/* <span
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 13.1908,
              color: '#6F6C90',
            }}
          >
            Lorem Ipsum
          </span> */}
          <Typography
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 18.1374,
              color: "#170F49",
            }}
          >
            {props.iconName}
          </Typography>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", gap: "0.75rem", marginBottom: "1rem" }}>
          <div
            style={{
              background: "#EEF5FF",
              borderRadius: 6.59542,
              width: 35,
              height: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={spendsIcon} alt="" style={{ width: 20, height: 20 }} />
          </div>
          <div>
            <TextField
              width="100px"
              height="auto"
              variant="outlined"
              placeholder={"Enter Spends"}
              size="small"
              value={spends}
              type="number"
              onChange={
                (e) => {
                  const matchIndex = props.platforms.findIndex(
                    (platform) => platform.platform === props.platform
                  );
                  props.setPlatformInfo((prevState) => {
                    return {
                      ...prevState,
                      platforms: props.platforms.map((platform, index) =>
                        matchIndex === index
                          ? {
                              ...platform,
                              spends: e.target.value,
                            }
                          : {
                              ...platform,
                            }
                      ),
                    };
                  });
                }
                // dispatch({
                //   type: "SET_SPENDS_TARGET_CPA",
                //   payload: {
                //     type: props.platform,
                //     key: "spends",
                //     value: e.target.value,
                //   },
                // })
              }
              disabled={
                !props.platforms.find(
                  (platform) => platform.platform === props.platform
                )
              }
              inputProps={{ style: { fontSize: 12 } }}
            />
          </div>
        </div>
        {/* <div style={{ display: 'flex', gap: '0.75rem', marginBottom: '1rem' }}>
          <div
            style={{
              background: '#EEF5FF',
              borderRadius: 6.59542,
              width: 35,
              height: 35,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img src={cpaIcon} alt='' style={{ width: 20, height: 20 }} />
          </div>
          <div>
            <TextField
              width='100px'
              height='auto'
              variant='outlined'
              placeholder={'Enter Current CPA'}
              value={cpa}
              size='small'
              inputProps={{ style: { fontSize: 12 } }}
              onChange={(e) =>
                dispatch({
                  type: 'SET_SPENDS_TARGET_CPA',
                  payload: {
                    type: props.platform,
                    key: 'cpa',
                    value: e.target.value,
                  },
                })
              }
              disabled={props.isActive ? false : true}
            />
          </div>
        </div> */}
        <div style={{ display: "flex", gap: "0.75rem", marginBottom: "1rem" }}>
          <div
            style={{
              background: "#EEF5FF",
              borderRadius: 6.59542,
              width: 35,
              height: 35,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={cpaIcon} alt="" style={{ width: 20, height: 20 }} />
          </div>
          <div>
            <TextField
              width="100px"
              height="auto"
              variant="outlined"
              placeholder={"Enter Current CPA"}
              value={cpa}
              size="small"
              type="number"
              inputProps={{ style: { fontSize: 12 } }}
              onChange={
                (e) => {
                  const matchIndex = props.platforms.findIndex(
                    (platform) => platform.platform === props.platform
                  );
                  props.setPlatformInfo((prevState) => {
                    return {
                      ...prevState,
                      platforms: props.platforms.map((platform, index) =>
                        matchIndex === index
                          ? {
                              ...platform,
                              cpa: e.target.value,
                            }
                          : {
                              ...platform,
                            }
                      ),
                    };
                  });
                }
                // dispatch({
                //   type: "SET_SPENDS_TARGET_CPA",
                //   payload: {
                //     type: props.platform,
                //     key: "cpa",
                //     value: e.target.value,
                //   },
                // })
              }
              disabled={
                !props.platforms.find(
                  (platform) => platform.platform === props.platform
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckBoxOption;
