import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 504,
  height: 371,
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '1rem 3rem 1rem 3rem',
  borderRadius: '14px',
  outline: 'none',
};

export default function GenericConfirmModal({
  open,
  onCancel,
  onConfirm,
  icon = null,
  title = "",
  description = "",
  cancleText = "",
  confirmText = ""
}) {
  return (
    <Modal
      open={open}
      onClose={onCancel}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            gap: '0.5rem',
          }}
        >
          <div>
            {icon}
          </div>
          <div style={{marginTop: "2rem"}}>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 18,
                textAlign: 'center',
                width: '18rem',
                lineHeight: 1.25,
              }}
            >
              {title}
            </Typography>
          </div>
          <div>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 16,
                textAlign: 'center',
                color: "#6F6C90",
              }}
            >
              {description}
            </Typography>
          </div>
          <Box sx={{display: "flex", gap: "1.5rem", mt: "3rem"}}>
            <Button
              style={{
                textDecoration: 'none',
                marginTop: '10px',
                width: '172px',
                height: '40px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#0869FB',
                textTransform: 'capitalize',
                border: '1.5px solid #0869FB',
                background: "transparent"
              }}
              onClick={onCancel}
            >
              {cancleText}
            </Button>
            <Button
              style={{
                textDecoration: 'none',
                marginTop: '10px',
                width: '172px',
                height: '40px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                textTransform: 'capitalize',
                background: '#0869FB',
              }}
              onClick={onConfirm}
            >
              {confirmText}
            </Button>
          </Box>
        </div>
      </Box>
    </Modal>
  );
};


