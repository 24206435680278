import { Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import Slider from 'react-slick';
import { man_give_presentation as manGivePresentation } from '../../../assets/icons/icon';
import PrimaryButton from '../../ButtonPrimary/Buttons';
import UploadImageField from './UploadImageField';
import { SLIDDER_SETTINGS } from './index';
import { truncateString } from './utils';

export default function AdsList({
  onOpenEditModal,
  onDeleteAd,
  activeAdsetIndex,
  websiteUrl = "",
  openAddNewAdModal,
  ads,
}) {
  const { setValue } = useFormContext();

  return (
    <>
      <Slider {...SLIDDER_SETTINGS}>
        {ads?.map((ad, index) => (
          <div className='adset_slide' key={ad.key || index}>
            <div className='adset_slide_block'>
              <div className='adset_slide_head'>
                <h3>
                  {ad?.adName ? ad.adName : 'AD ' + (index + 1)}
                </h3>
              </div>
              <div className='adset_slide_head_skip'>
                <Tooltip title={ad?.primaryText} arrow>
                  <p>{truncateString(ad?.primaryText)}</p>
                </Tooltip>
              </div>
              <UploadImageField
                value={ad.imageUrl}
                onChange={(imageUrl) => setValue(`adsets.${activeAdsetIndex}.facebookAds.${index}.imageUrl`, imageUrl)}
              />
              <div className='adset_slide_footer flex-center-space'>
                <div className='adset_slide_footer_info'>
                  <h2>
                    {websiteUrl}
                  </h2>
                  <p>{ad?.headline}</p>
                  <span>{ad?.description}</span>
                </div>
                <div className='adset_slide_footer_action'>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    // eslint-disable-next-line no-script-url
                    href='javascript:;'
                    className='adset_book_button'
                  >
                    Book Now
                  </a>
                </div>
              </div>
            </div>
            <div className='adset_slide_action_row flex-center-center'>
              <button
                className='edit_button'
                onClick={onOpenEditModal(index)}
                type="button"
              ></button>
              <button
                className='delete_button'
                onClick={onDeleteAd(index)}
                type="button"
              ></button>
            </div>
          </div>
        ))}
      </Slider>
      {ads?.length === 0 && (
        <div className='noadd_screen_hdm'>
          <img src={manGivePresentation} alt="create-new-ad" />
          <p>Please add a few ads to this adset to proceed. </p>
          <PrimaryButton
            type='light'
            className='edit_response_button'
            title='+ Add New Ad'
            onClick={openAddNewAdModal}
            buttonType="button"
          ></PrimaryButton>
        </div>
      )}
    </>
  )
}
