import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import PriceRate from "./PriceRate";
import "./clientpricing.css";
import ScopeofWorkModal from "../../modals/ScopeofWorkModal";
import { getFormByFormIdReducer } from "../../../api/accountManager/forms/slice";
import {
  createProposalReducer,
  updateSelectedPriceCard,
  updateProposalData,
  updatePricing,
} from "../../../api/accountManager/pricing/slice";
import {
  goToSpecificCompoClientAm,
  updateCompoReduxAM,
} from "../../../api/accountManager/componentTracking/action";
import PrimaryButton from "../ButtonPrimary/Buttons";
import GoToNextCompoButton from "../GoToNextCompoButton";
import { clientDashboardIds } from "../../../utils/clientDashboardIdsUtil";
import {
  BACKEND_STATUS,
  accessSharingType,
  accountManagerIds,
  getAccessStatus,
  ldComponentWithIndexAm,
} from "../../../utils/accountManagerUtil";
import newPriceIconBlue from "../../../assets/icons/new-price-icon-blue.svg";
import newPriceIconViolet from "../../../assets/icons/new-price-icon-violet.svg";
import newPriceIconPink from "../../../assets/icons/new-price-icon-pink.svg";
import blueBullet from "./blue-bullet.svg";
import violetBullet from "./violet-bullet.svg";
import pinkBullet from "./pink-bullet.svg";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import ldAmRoutePaths from "../../../pages/ld-am/constants/ldAmRoutePaths";
import {
  useGetClientForms,
  usePostClientForms,
} from "../../../services/clientForms";
import PmaxLoader from "../../PMaxStrategyCreation/PmaxLoader";
import { isPlatformSelectedLD } from "../../../utils/LDUtils/commonUtils";
import {
  GROWTH_PRICING_CARD_RANGE,
  STARTER_PRICING_CARD_RANGE,
  PREMIER_PRICING_CARD_RANGE,
} from "../../../utils/commonEnums";

const ClientPricing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formId } = useParams();
  const [formSubm, setFormSubm] = useState(false);
  const proposalAdded = useSelector((state) => state.pricing.proposalAdded);
  const selectedCard = useSelector((state) => state.pricing.selectedPriceCard);
  const { data: form } = useGetClientForms(formId);
  const pricing = useSelector((state) => state.pricing.pricing);
  const [growthMonthlyFee, setGrowthMonthlyFee] = useState(5250);
  const [premierMonthlyFee, setPremierMonthlyFee] = useState(12500);
  const [selected, setSelected] = useState(1);
  const { mutate: mutateClientForm, isLoading } = usePostClientForms({
    formId,
  });

  useEffect(() => {
    // dispatch(getFormByFormIdReducer());

    return () => {
      dispatch(updateProposalData(false));
    };
  }, []);

  useEffect(() => {
    if (selectedCard === 0) {
      let totalSpends = 0;
      if (
        form?.onboardingInfo?.platformInfo?.platforms &&
        form?.onboardingInfo?.platformInfo?.platforms.length
      ) {
        (form?.onboardingInfo?.platformInfo?.platforms || []).map(
          (platform) => {
            totalSpends += parseInt(platform.spends);
          }
        );
        let selectedPriceCard = -1;
        if (totalSpends <= 25000) {
          selectedPriceCard = 1;
        } else if (totalSpends > 25000 && totalSpends <= 75000) {
          selectedPriceCard = 2;
          const tempGrowthMonthlyFee = Math.max(
            growthMonthlyFee,
            parseInt(totalSpends * 0.1)
          );
          console.log(
            "monthly fee",
            tempGrowthMonthlyFee,
            growthMonthlyFee,
            totalSpends,
            parseInt(totalSpends * 0.1),
            pricing[selectedPriceCard].basePricePerMonth,
            Math.max(
              pricing[selectedPriceCard].basePricePerMonth,
              tempGrowthMonthlyFee
            )
          );
          setGrowthMonthlyFee(tempGrowthMonthlyFee);
          dispatch(
            updatePricing({
              priceIndex: selectedPriceCard,
              pricing: {
                ...(pricing[selectedPriceCard] || {}),
                basePricePerMonth: Math.max(
                  pricing[selectedPriceCard].basePricePerMonth,
                  tempGrowthMonthlyFee
                ),
              },
            })
          );
        } else {
          selectedPriceCard = 3;
          const tempPremierMonthlyFee = Math.max(
            premierMonthlyFee,
            parseInt(totalSpends * 0.1)
          );
          setPremierMonthlyFee(tempPremierMonthlyFee);
          dispatch(
            updatePricing({
              priceIndex: selectedPriceCard,
              pricing: {
                ...(pricing[selectedPriceCard] || {}),
                basePricePerMonth: Math.max(
                  pricing[selectedPriceCard].basePricePerMonth,
                  tempPremierMonthlyFee
                ),
              },
            })
          );
        }
        selectHandler(selectedPriceCard);
      }
    }
  }, [form]);
  useEffect(() => {
    if (form?.sowPricingInfo?.clientPricing) {
      const { minSpend, maxSpend } =
        form?.sowPricingInfo?.clientPricing?.adSpendRange;
      if (
        minSpend === STARTER_PRICING_CARD_RANGE.minPrice &&
        maxSpend === STARTER_PRICING_CARD_RANGE.maxPrice
      ) {
        dispatch(updateSelectedPriceCard(1));
      } else if (
        minSpend === GROWTH_PRICING_CARD_RANGE.minPrice &&
        maxSpend === GROWTH_PRICING_CARD_RANGE.maxPrice
      ) {
        dispatch(updateSelectedPriceCard(2));
      } else if (
        minSpend === PREMIER_PRICING_CARD_RANGE.minPrice &&
        maxSpend === PREMIER_PRICING_CARD_RANGE.maxPrice
      ) {
        dispatch(updateSelectedPriceCard(3));
      } else {
        dispatch(updateSelectedPriceCard(1));
      }
    }
  }, [dispatch, form?.sowPricingInfo?.clientPricing]);
  useEffect(() => {
    setSelected(selectedCard);
  }, [selectedCard]);

  useEffect(() => {
    setFormSubm(true);
  }, [proposalAdded]);

  const selectHandler = (id) => {
    dispatch(updateSelectedPriceCard(id));
  };

  const onClickHandler = () => {
    // debugger;
    console.log("Selected Card :: ", selected);
    // dispatch(createProposalReducer(selected));
    mutateClientForm(
      {
        accessSharing: {
          status:
            form?.accessSharing?.status === BACKEND_STATUS.PENDING
              ? BACKEND_STATUS.ONGOING
              : form?.accessSharing?.status || BACKEND_STATUS.ONGOING,
        },
        sowPricingInfo: {
          clientPricing: pricing[selected],
          status: BACKEND_STATUS.COMPLETED,
        },
        currentStep:
          form?.sowPricingInfo?.status === BACKEND_STATUS.COMPLETED
            ? form?.currentStep
            : "Access Sharing",
      },
      {
        onSuccess: () => {
          dispatch(updateSelectedPriceCard(selected));
          if (
            isPlatformSelectedLD(form, "google-ads") &&
            isPlatformSelectedLD(form, "facebook-ads")
          ) {
            navigate(
              generatePath(ldAmRoutePaths.accessSharing.fullbase, { formId })
            );
          } else if (isPlatformSelectedLD(form, "google-ads")) {
            navigate(
              generatePath(ldAmRoutePaths.accessSharing.fullbase, { formId })
            );
          } else {
            navigate(
              generatePath(
                `${ldAmRoutePaths.accessSharing.fullbase}/${ldAmRoutePaths.accessSharing.sub.facebook}`,
                {
                  formId,
                }
              )
            );
          }
        },
      }
    );
  };
  if (isLoading) {
    return <PmaxLoader />;
  }
  return (
    <>
      <div style={{ height: "auto", overflowY: "auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "3rem",
            gap: "1.25rem",
          }}
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 32,
              color: "#170F49",
              lineHeight: 0.5,
            }}
          >
            CLIENT PRICING
          </Typography>
          <Typography
            style={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: 16,
              color: "#6F6C90",
            }}
          >
            {/* Select and edit pricing to be sent to client proposal */}
            Please select the pricing package that should be shared with the
            client in the proposal.
          </Typography>
          {/* <div style={{ display: "flex", gap: "1rem" }}>
            {form?.companyInfo?.googleAdsManaged && (
              <span
                style={{
                  background: "rgba(8, 105, 251, 0.08)",
                  borderRadius: 20,
                  padding: "6px 12px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#2471F7",
                }}
              >
                Google
              </span>
            )}
            {form?.companyInfo?.fbAdsManaged && (
              <span
                style={{
                  background: "rgba(8, 105, 251, 0.08)",
                  borderRadius: 20,
                  padding: "6px 12px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#2471F7",
                }}
              >
                Facebook
              </span>
            )}
            {form?.companyInfo?.implementCro && (
              <span
                style={{
                  background: "rgba(8, 105, 251, 0.08)",
                  borderRadius: 20,
                  padding: "6px 12px",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 16,
                  color: "#2471F7",
                }}
              >
                CRO
              </span>
            )}
          </div> */}
        </div>
        {/* Pricing Component  */}
        <div
          style={{
            display: "flex",
            overflow: "auto",
            gap: "2.5rem",
            marginTop: "2rem",
            marginBottom: "1.5rem",
            marginLeft: "41px",
            paddingBottom: "20px",
          }}
        >
          <div>
            <PriceRate
              title="Starter"
              minAdSpend={5000}
              maxAdSpend={25000}
              adSpendRange="5k-25k"
              landingPages="1-2"
              popUps="1"
              stickyBars="1"
              monthlyFee="$4000"
              priceCardNumber={1}
              onClickHandler={selectHandler}
              selected={selected === 1 ? true : false}
              color="#4CA8F5"
              backgroundColor="#EDF6FE"
              iconImg={newPriceIconBlue}
              iconBullet={blueBullet}
            />
          </div>
          <div>
            <PriceRate
              title="Growth"
              minAdSpend={25000}
              maxAdSpend={75000}
              adSpendRange="25k-75k"
              landingPages="1-3"
              popUps="1"
              stickyBars="1"
              monthlyFee={`$${growthMonthlyFee}`}
              priceCardNumber={2}
              onClickHandler={selectHandler}
              selected={selected === 2 ? true : false}
              color="#A754F0"
              backgroundColor="#F6EEFE"
              iconImg={newPriceIconViolet}
              iconBullet={violetBullet}
            />
          </div>
          <div>
            <PriceRate
              title="Premier"
              minAdSpend={75000}
              maxAdSpend={150000}
              adSpendRange="75k-150k"
              landingPages="1-5"
              popUps="1-2"
              stickyBars="1-2"
              monthlyFee={`$${premierMonthlyFee}`}
              priceCardNumber={3}
              onClickHandler={selectHandler}
              selected={selected === 3 ? true : false}
              color="#EF64B3"
              backgroundColor="#FDEFF7"
              iconImg={newPriceIconPink}
              iconBullet={pinkBullet}
            />
          </div>
          {/* <div>
            <PriceRate
              minAdSpend={150000}
              maxAdSpend={250000}
              adSpendRange='150k-250k'
              landingPages='1-10'
              popUps='1-3'
              stickyBars='1-3'
              monthlyFee='$22500 or 10%'
              priceCardNumber={4}
              onClickHandler={selectHandler}
              selected={selected === 4 ? true : false}
            />
          </div>
          <div>
            <PriceRate
              minAdSpend={250000}
              maxAdSpend={1000000}
              adSpendRange='250k+'
              landingPages='Custom'
              popUps='Custom'
              stickyBars='Custom'
              monthlyFee='Custom'
              priceCardNumber={5}
              onClickHandler={selectHandler}
              selected={selected === 5 ? true : false}
            />
          </div> */}
        </div>
      </div>
      <div className="preview_footer_row">
        <div className="footer_con">
          <button
            className="back"
            onClick={() =>
              navigate(
                generatePath(`${ldAmRoutePaths.onboardingForm.fullbase}`, {
                  formId,
                })
              )
            }
          >
            BACK
          </button>
          <PrimaryButton title="PROCEED" onClick={onClickHandler} />
        </div>
      </div>
      {/* {proposalAdded && (
        <div>
          <ScopeofWorkModal formSubm={formSubm} setFormSubm={setFormSubm} />
        </div>
      )} */}
    </>
  );
};

export default ClientPricing;
