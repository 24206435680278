import React from 'react';
import { Modal, Typography, Box } from '@mui/material';
import preloader from '../../assets/icons/readaccess/preloader.gif';

const PmaxLoader = ({
    description=""
}) => {
  const [confirmModal, setConfirmModal] = React.useState(true);
  const handleConfirmClose = () => setConfirmModal(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 455,
    minHeight: '305px',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '1.5rem',
    outline: 'none',
    boxShadow: '0px 10px 24px rgba(35, 40, 60, 0.05)',
    paddingTop: '2rem',
    paddingBottom: '1rem',
  };
  return (
    <Modal
      open={confirmModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      // onClose={handleConfirmClose}
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={preloader} alt='' width={120} height={120} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '11px',
              //   width: '50%',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Lato',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 30,
                color: '#333333',
              }}
            >
              Please Wait!
            </Typography>
            <Typography
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 14,
                color: '#727272',
                width: '18rem',
                lineHeight: 1.5,
                textAlign: 'center',
              }}
            >
              {description}
            </Typography>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PmaxLoader;
