import React from 'react';
import InfoIcon from "../../../assets/icons/HDMAccount/information.png";
import GenericConfirmModal from '../../modals/GenericConfirmModal';

export default function ConfirmAdsetDeleteModal({
  open,
  onCancel,
  onConfirm,
}) {
  return (
    <GenericConfirmModal
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      cancleText='Go Back'
      confirmText='Delete'
      description='You won’t be able to fetch this data later.'
      title='Are you sure you want to delete adset?'
      icon={<img src={InfoIcon} alt='' width={64} height={64} />}
    />
  );
};