import { Box, Modal } from '@mui/material';
import KeywordsSearchInput from './KeywordsSearchInput';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 475,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  padding: '46px 47px 42px',
  boxShadow: 24,
};

export default function AddEditKeywordModal({
  open,
  onClose,
  replace,
  remove,
  keywords,
  adsetName,
}) {
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='small_added_popup'
      onClose={onClose}
    >
      <Box sx={style}>
        <div className='addad_popup_layout'>
          <div className='addad_modal_header'>
            <p>{adsetName}</p>
            <i className='popup_close_button' onClick={onClose} />
          </div>
          <div className='addad_modal_layout'>
            <div className='keyword_modal_sections'>
              <div className='tag_selected_blocks_popup'>
                <div className='tag_selected_chips_popup' style={{ maxHeight: "13rem", overflowY: "auto" }}>
                  {keywords.map((keyword, index) => (
                    <span className='chips_tag' index={index}>
                      {keyword.name}
                      <button
                        className='clearchip'
                        onClick={() => remove(index)}
                        style={{ marginLeft: '5px' }}
                      ></button>
                    </span>
                  ))}
                </div>
              </div>
              <div className='adset_list_hdm_popup'>
                <p className='addNew_keyword_title'>Add New Keyword</p>
                <KeywordsSearchInput keywords={keywords} replace={replace} />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}
