import React from 'react';

const CheckedIcon = () => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.99999 0.0712891C12.4177 0.0712891 16 3.6527 16 8.07128C16 12.489 12.4177 16.0713 7.99999 16.0713C3.58141 16.0713 0 12.489 0 8.07128C0 3.6527 3.58141 0.0712891 7.99999 0.0712891Z'
        fill='#1ABFA1'
      />
      <path
        d='M5 8.07629L6.892 9.97129L10.79 6.07129'
        stroke='white'
        stroke-width='1.5'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

export default CheckedIcon;
