import React from 'react';
import {
  FormControlLabel,
  Typography,
  Select,
  MenuItem,
  RadioGroup,
  Button,
  TextField,
} from '@mui/material';
import BpRadio from '../ClientDashboard/CustomRadio/Radio';
import crossIcon from './pmaxIcons/cross-icon.svg';
import ErrorTooltip from '../TooltipIndicators/ErrorTooltip';

const PMaxSearchTerms = ({
  read,
  setIsRead,
  addedNewKeyword=false,
  assetNumber=0,
  keywordRef={},
  segmentNamePredefined,
  setSegmentNamePredefined,
  segmentNameNew,
  setSegmentNameNew,
  type,
  setType,
  targetingType,
  setTargetingType,
  keywords,
  errors,
  customSegments,
  addKeywords,
  changeStatus,
  setSavedStatus,
  changeKeywords,
  removeKeywords,
}) => {
  const textStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '19px',
    marginBottom: 30,
  };
  const searchTermTextStyle = {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '15px',
    color: '#333333',
  };

  const displayValidtionError = () => {
    const { segmentName, segment} = errors;
    if (segmentName && segment?.length) {
      return [segmentName, segment[0]];
    } else if (segmentName) {
      return segmentName;
    } else if (segment?.length) {
      return segment[0];
    }
  };
  return (
    <div className='pmax-existing-new-segment-view'>
      <div className='pmax-new-segment'>
        <div
          style={{
            opacity: 1,
          }}
        >
          <Typography
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 600,
              fontSize: 16,
              lineHeight: '19px',
              color: '#333333',
              padding: '16px 0 0 16px',
            }}
          >
            Custom Segment
          </Typography>
        </div>
        <div
          style={{
            borderBottom: '1.92886px solid #e6f6ff',
          }}
        >
          <div
            className='pmax-existing-segment'
          >
            <RadioGroup>
              <FormControlLabel
                value='Existing Segment'
                control={
                  <BpRadio checked={type === 'PREDEFINED' ? true : false} />
                }
                label='Existing Segment'
                onClick={() => { 
                    setType('PREDEFINED')
                    changeStatus();
                    setSavedStatus(false);
                }}
              />
            </RadioGroup>

            <Select
              displayEmpty
              disabled={type !== 'PREDEFINED' && true}
              value={segmentNamePredefined || ''}
              placeholder='Choose'
              variant='outlined'
              style={{
                width: 219,
                height: 41,
                background: '#FFFFFF',
                border: '0.964432px solid #DDDDDD',
                borderRadius: 7.71,
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 11.5,
                opacity: 1,
                outline: 'none',
                boxShadow: 'none',
              }}
            >
              {customSegments.map((segment, index) => (
                <MenuItem
                  key={index}
                  value={segment.name}
                  onClick={() => {
                    setSegmentNamePredefined(segment.name);
                    changeStatus();
                    setSavedStatus(false);
                  }}
                >
                  {segment.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
        <RadioGroup
          style={{
            paddingLeft: 16,
            paddingTop: 8,
          }}
        >
          <>
            <FormControlLabel
              value='Create New Segment'
              control={<BpRadio checked={type === 'NEW' ? true : false} />}
              style={{
                marginRight: 0,
              }}
              label={
                <Typography
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  Create New Segment
                  {type === 'NEW' &&
                    (errors?.segmentName || Array.isArray(errors?.segment) ) && (
                      <ErrorTooltip error={displayValidtionError()} />
                    )}
                </Typography>
              }
              className='new-segment-radio'
              onClick={() => {
                setType('NEW');
                // if (type !== 'NEW') setSegmentName('');
                changeStatus();
                setSavedStatus(false);
              }}
            />
          </>
          <FormControlLabel
            value='interests or purchase intentions'
            control={
              <BpRadio
                width={14}
                height={14}
                checked={
                  targetingType ===
                    'People with any of these interests or purchase intentions' &&
                  true
                }
              />
            }
            style={{
              paddingLeft: 2,
              opacity: type !== 'NEW' ? 0.3 : 1,
            }}
            disabled={type === 'NEW' ? false : true}
            label='People with any of these interests or purchase intentions'
            className='interests-purchase-radio'
            onClick={() => {
              if (type !== 'NEW') return;
              setTargetingType(
                'People with any of these interests or purchase intentions'
              );
              changeStatus();
              setSavedStatus(false);
            }}
          />
          <FormControlLabel
            value='searched for any of these terms on Google'
            control={
              <BpRadio
                width={14}
                height={14}
                checked={
                  targetingType ===
                    'People who searched for any of these terms on Google' &&
                  true
                }
              />
            }
            style={{
              paddingLeft: 2,
              opacity: type !== 'NEW' ? 0.3 : 1,
            }}
            disabled={type === 'NEW' ? false : true}
            label='People who searched for any of these terms on Google'
            className='search-for-terms-radio'
            onClick={() => {
              if (type !== 'NEW') return;
              setTargetingType(
                'People who searched for any of these terms on Google'
              );
              changeStatus();
              setSavedStatus(false);
            }}
          />
        </RadioGroup>
        <Typography
          style={{
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 13.502,
            lineHeight: '19px',
            color: '#333333',
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 18,
            display: 'flex',
            alignItems: 'center',
            opacity: type !== 'NEW' ? 0.3 : 1,
            gap: 4,
          }}
        >
          Segment Name :{' '}
          {read ? (
            type === 'NEW' ? (
              segmentNameNew || ''
            ) : (
              ''
            )
          ) : (
            <TextField
              variant='standard'
              value={type === 'NEW' ? segmentNameNew : ''}
              placeholder={ type === 'NEW' && 'Segment name goes here' }
              inputProps={{
                style: {
                  fontFamily: 'Inter',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: 12.5,
                  lineHeight: 1,
                  width: 180,
                  paddingBottom: 2,
                  paddingTop: 0,
                  border: 0,
                },
              }}
              onChange={(e) => {
                setSegmentNameNew(e.target?.value || '');
                changeStatus();
                setSavedStatus(false);
              }}
            />
          )}
        </Typography>
        <div className='google-search-term-list'>
          <>
            { !addedNewKeyword && (
                read
              ? type === 'NEW' &&
                keywords.map((keyword, index) => (
                  <div className='google-search-term'>
                    <Typography key={index} style={searchTermTextStyle}>
                      {keyword}
                    </Typography>
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => removeKeywords(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))
              : type === 'NEW' &&
                keywords.map((keyword, index) => (
                  <div className='google-search-term'>
                    <TextField
                      key={index}
                      variant='standard'
                      value={keyword}
                      inputProps={{
                        style: {
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: 12,
                          color: '#333333',
                          width: 280,
                          paddingBottom: 6,
                          border: 0,
                        },
                      }}
                      onChange={(e) => changeKeywords(e, index)}
                    />
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => removeKeywords(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                )))}
            { addedNewKeyword && (
                read
              ? type === 'NEW' &&
                keywords.map((keyword, index) => (
                    (index === keywords.length - 1) ?
                  <div className='google-search-term'>
                    <TextField
                      key={index}
                      inputRef={keywordRef}
                      variant='standard'
                      value={keyword}
                      inputProps={{
                        style: {
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: 12,
                          color: '#333333',
                          width: 280,
                          paddingBottom: 6,
                          border: 0,
                        },
                      }}
                      onChange={(e) => changeKeywords(e, index)}
                    />
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => removeKeywords(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div> :
                  <div className='google-search-term'>
                    <Typography key={index} style={searchTermTextStyle}>
                      {keyword}
                    </Typography>
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => removeKeywords(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                ))
              : type === 'NEW' &&
                keywords.map((keyword, index) => (
                    (index === keywords.length - 1) ?
                  <div className='google-search-term'>
                    <TextField
                      key={index}
                      inputRef={keywordRef}
                      variant='standard'
                      value={keyword}
                      inputProps={{
                        style: {
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: 12,
                          color: '#333333',
                          width: 280,
                          paddingBottom: 6,
                          border: 0,
                        },
                      }}
                      onChange={(e) => changeKeywords(e, index)}
                    />
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => removeKeywords(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div> :
                  <div className='google-search-term'>
                    <TextField
                      key={index}
                      variant='standard'
                      value={keyword}
                      inputProps={{
                        style: {
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: 500,
                          fontSize: 12,
                          color: '#333333',
                          width: 280,
                          paddingBottom: 6,
                          border: 0,
                        },
                      }}
                      onChange={(e) => changeKeywords(e, index)}
                    />
                    <img
                      src={crossIcon}
                      alt='cross'
                      onClick={() => removeKeywords(index)}
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </div>
                )))}
          </>
        </div>
        <div className='add-search-term-container'>
          <Button
            style={{
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 12,
              lineHeight: '20px',
              color: '#0869FB',
              textTransform: 'capitalize',
              backgroundColor: 'transparent',
            }}
            disabled={type === 'NEW' ? false : true}
            onClick={() => {
              addKeywords();
            }}
          >
            + Add Google Search Terms
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PMaxSearchTerms;
