import { Typography, Tooltip } from "@mui/material";
import React, { Fragment } from "react";
import FailedIcon from "./failed-icon.svg";

const Failed = ({errors}) => {
  return (
    <Tooltip
      PopperProps={{
        sx: {
          "& .MuiTooltip-arrow": {
            color: "white",
          },
          "& .MuiTooltip-tooltip": {
            backgroundColor: "white",
            color: "black",
            marginBottom: "20px",
            filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
            padding: "10px 8px 8px 12px",
          },
        },
      }}
      title={
        <>
         {errors.map((error, index) => (
            <Fragment key={index}>
              {index !== 0 && <><br /><br /></>}
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: 12,
                  lineHeight: "20px",
                  color: "#333333",
                }}
              >
                {error}
              </Typography>
            </Fragment>
          ))}
        </>
      }
      arrow
      placement="right"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "4px 8px",
          gap: 6,
          width: 66,
          height: 24,
          background: "#FDEEEE",
          borderRadius: 6,
          cursor: "auto",
        }}
      >
        <img
          src={FailedIcon}
          alt=""
          style={{
            width: 12,
            height: 12,
          }}
        />
        <Typography
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: 12,
            lineHeight: "20px",
            color: "#EF5854",
          }}
        >
          Failed
        </Typography>
      </div>
    </Tooltip>
  );
};

export default Failed;
