import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { updateTab } from "../../../api/accountManager/componentTracking/action";
import {
  headerNavbarAMWithCurrentCompIds,
  headerNavbarAM,
  listOfIds,
  headerTabsAccountManager,
} from "../../../utils/navbarUtil";
import { updateTab as clientUpdateTab } from "../../../api/componentTracking/action";
import { PROPOSAL_STATE } from "../../../utils/accountManagerUtil";
import { isAnyAdsThereInAdsetHdm } from "../../../utils/strategyCreationFacebook";
import { changeCampaign, updateToBeStore, updateToBeStored } from "../../../api/pmaxStrategy/slice";
import { useEffect } from "react";

export const scopeWorkMapping = {
  "GOOGLE ADS SETUP": "googleAdsManaged",
  "FACEBOOK ADS SETUP": "fbAdsManaged",
  "CRO SETUP": "implementCro",
  pricing: "pricing",
  ["ONBOARDING FORM"]: "ONBOARDING FORM",
  ["PROPOSER DETAILS"]: "PROPOSER DETAILS",
  ["REQUIREMENT DETAILS"]: "REQUIREMENT DETAILS",
  ["GOOGLE ADS AUDIT"]: "GOOGLE ADS",
  ["PROPOSAL"]: "PROPOSAL",
  ["CHANGES SUGGESTED"]: "CHANGES SUGGESTED",
};
const HeaderNavSteps = ({ headerNavList, active, clientView }) => {
  const dispatch = useDispatch();
  const form = useSelector((store) => store.form.form);
  const formHdm = useSelector((store) => store.hdmFormAm);
  const interestTof = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo?.topOfTheFunnelInterest?.adsets
  );
  const lookalikeTof = useSelector(
    (state) =>
      state.hdmFormAm.fbAdsStrategyInfo?.topOfTheFunnelLookalike?.adsets
  );
  const mofTof = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo?.middleOfTheFunnel?.adsets
  );
  const bofTof = useSelector(
    (state) => state.hdmFormAm.fbAdsStrategyInfo?.bottomOfTheFunnel?.adsets
  );
  const platformSelected = form?.onboardingInfo?.platformInfo?.platforms;

  const onClickHandler = (tab) => {
    console.log("%%%%%%%", headerNavList, tab, active);
    if (active !== tab) {
      if (clientView) {
        console.log("client view", headerNavbarAMWithCurrentCompIds[tab]);
        dispatch(clientUpdateTab(headerNavbarAMWithCurrentCompIds[tab]));
      } else if (
        headerNavbarAM[listOfIds.accountScopeOfWorkAndPricing] === headerNavList
      ) {
        if (tab === "PRICING" || form?.companyInfo[scopeWorkMapping[tab]]) {
          dispatch(updateTab(headerNavbarAMWithCurrentCompIds[tab]));
        }
      } else if (headerNavbarAM[listOfIds.audit] === headerNavList) {
        if (tab === "CHANGES SUGGESTED" && !form?.published) {
          return;
        } else {
          dispatch(updateTab(headerNavbarAMWithCurrentCompIds[tab]));
        }
      } else if (
        tab === headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_1]
      ) {
        dispatch(updateTab(headerNavbarAMWithCurrentCompIds[tab]));
      }
        else if (
        tab === headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_2]
        ) {
         dispatch(updateTab(headerNavbarAMWithCurrentCompIds[tab]));
        }
        else if (
        tab === headerTabsAccountManager[listOfIds.PMAX_STRATEGY_CAMPAIGN_3]
        ) {
         dispatch(updateTab(headerNavbarAMWithCurrentCompIds[tab]));
        }
        else {
        console.log("$$$$$$$$$", headerNavbarAMWithCurrentCompIds[tab]);
        dispatch(updateTab(headerNavbarAMWithCurrentCompIds[tab]));
      }
    }
  };
  const checkStrategyCreationFBTab = (
    name,
    lookalikeTof,
    interestTof,
    mofTof,
    bofTof
  ) => {
    const lookalike = isAnyAdsThereInAdsetHdm(lookalikeTof);
    const interest = isAnyAdsThereInAdsetHdm(interestTof);
    const mof = isAnyAdsThereInAdsetHdm(mofTof);
    const bof = isAnyAdsThereInAdsetHdm(bofTof);
    if (
      form?.fbAdsStrategyInfo?.status === "PENDING" &&
      [
        headerTabsAccountManager["INTEREST"],
        headerTabsAccountManager["BOF"],
        headerTabsAccountManager["MOF"],
      ].includes(name)
    ) {
      return true;
    }
    if (
      (lookalike || interest || mof || bof) &&
      [
        headerTabsAccountManager["LOOKALIKE"],
        headerTabsAccountManager["INTEREST"],
        headerTabsAccountManager["BOF"],
        headerTabsAccountManager["MOF"],
      ].includes(name)
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
      {Object.values(headerNavList || {}).map(({ name, isActive, icon }, index) => {
        return (
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background:
                name === active
                  ? "#F1F7FF"
                  : [
                      "GOOGLE ADS SETUP",
                      "FACEBOOK ADS SETUP",
                      "CRO SETUP",
                    ].includes(name) ||
                    (name === "CHANGES SUGGESTED" &&
                      (!(
                        (
                          form?.proposalIterations[
                            form?.proposalIterations.length - 1
                          ] || {}
                        ).state === PROPOSAL_STATE.CHANGES_SUGGESTED
                      ) ||
                        form?.published)) ||
                    form?.companyInfo[scopeWorkMapping[name]] === false ||
                    (name === "SUGGESTED CHANGES" &&
                      !(
                        (
                          form?.strategyIterations[
                            form?.strategyIterations?.length - 1
                          ] || {}
                        ).state === PROPOSAL_STATE.CHANGES_SUGGESTED
                      )) ||
                    checkStrategyCreationFBTab(
                      name,
                      lookalikeTof,
                      interestTof,
                      mofTof,
                      bofTof
                    ) ||
                    (name === "GOOGLE" &&
                      platformSelected?.findIndex(
                        (platform) => platform.platform === "google-ads"
                      ) === -1) ||
                    (name === "FACEBOOK" &&
                      platformSelected?.findIndex(
                        (platform) => platform.platform === "facebook-ads"
                      ) === -1) ||
                    (name === "GOOGLE TARGETING" &&
                      platformSelected?.findIndex(
                        (platform) => platform.platform === "google-ads"
                      ) === -1) ||
                    (name === "FB TARGETING" &&
                      platformSelected?.findIndex(
                        (platform) => platform.platform === "facebook-ads"
                      ) === -1)
                  ? "rgb(235 235 228 / 90%)"
                  : "",
              color: name === active ? "#0869FB" : "",
              cursor: "pointer",
              width: `calc(100%/${Object.values(headerNavList).length})`,
            }}
            key={index}
            // disabled={(name === "CHANGES SUGGESTED" && !form?.published) || (!form?.companyInfo[scopeWorkMapping[name]])}
            disabled={
              (name === "CHANGES SUGGESTED" &&
                (!(
                  (
                    form?.proposalIterations[
                      form?.proposalIterations.length - 1
                    ] || {}
                  ).state === PROPOSAL_STATE.CHANGES_SUGGESTED
                ) ||
                  form?.published)) ||
              form?.companyInfo[scopeWorkMapping[name]] === false ||
              (name === "SUGGESTED CHANGES" &&
                !(
                  (
                    form?.strategyIterations[
                      form?.strategyIterations?.length - 1
                    ] || {}
                  ).state === PROPOSAL_STATE.CHANGES_SUGGESTED
                )) ||
              checkStrategyCreationFBTab(
                name,
                lookalikeTof,
                interestTof,
                mofTof,
                bofTof
              ) ||
              (name === "GOOGLE" &&
                platformSelected?.findIndex(
                  (platform) => platform.platform === "google-ads"
                ) === -1) ||
              (name === "FACEBOOK" &&
                platformSelected?.findIndex(
                  (platform) => platform.platform === "facebook-ads"
                ) === -1) ||
              (name === "GOOGLE TARGETING" &&
                platformSelected?.findIndex(
                  (platform) => platform.platform === "google-ads"
                ) === -1) ||
              (name === "FB TARGETING" &&
                platformSelected?.findIndex(
                  (platform) => platform.platform === "facebook-ads"
                ) === -1)
            }
            onClick={() => onClickHandler(name)}
            
          >
            
            <Typography
              style={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 16,
                color: name === active ? "#0869FB" : "#505050",
                display: "flex",
                alignItems: "center",
                gap: ["PRICING", "CRO SETUP"].includes(name) ? 8 : 0,
              }}
            >
              {icon && <img src={icon} alt="" />}
              {name}
              {console.log(name)}
            </Typography>
          </Button>
        );
      })}
    </>
  );
};

export default HeaderNavSteps;
