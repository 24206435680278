import React, { useEffect, useState } from 'react';
import ABTests from '../CreativeResources/ABTests/ABTests';
import { Button } from '@material-ui/core';
import ABTestAddModal from '../CreativeResources/ABTests/ABTestAddModal';
import { useDispatch, useSelector } from 'react-redux';
import { addAbTests, fetchAbTests, updateAbTests, putAbTests } from '../../api/creativeApproval/slice';
import ConfirmDialogModal from '../CreativeResources/ConfirmDialogModal';
import { updateCompoReduxAM } from '../../api/accountManager/componentTracking/action';

const ABTesting = ({ isClient=false, onProceed=()=>{} }) => {
  const dispatch = useDispatch();
  const abTests = useSelector(state => state.creativeApproval.abTests);
  const [modal, setmodal] = useState(false);
  const toBeSaved = useSelector((state) => state.creativeApproval.toBeSaved);
  const [read, isRead] = useState(true);
  const [formSubm, setFormSubm] = useState(false);
  const [confirm, setConfirm] = useState(false);

  console.log('ABTEST', abTests);

  useEffect(() => {
      dispatch(fetchAbTests());
  }, []);

  useEffect(() => {
      if (confirm) {
          setConfirm(false);
      }
  }, [formSubm]);

  const onConfirm = () => {
      setConfirm(true);
      dispatch(putAbTests());
      isRead(true);
  }

  const onUpdateHandler = ({
      id,
      section,
      question,
      status
  }) => {
      console.log('$$$$', id, section, question, status);
      dispatch(updateAbTests({
          id,
          section,
          question,
          status
      }));
  };

  const handleAddNewTest = ({
      section,
      question
  }) => {
      dispatch(addAbTests({
          section,
          question
      }));
  };

  const handleProceed = () => {
      onProceed();
  }
 
  return (
    <>
      <div 
        className='intro_strategy_main'
        style={{
            position: 'relative',
            overflowY: 'scroll',
            height: 'calc(100vh - 72px)',
            paddingBottom: '44px'
        }}
      >
      <ABTests 
        isClient={isClient} 
        abTests={abTests}
        onUpdateHandler={onUpdateHandler}
        read={read}
        isRead={isRead}
      />
      </div>
      <div className='preview_footer_row'>
        <div className='footer_con' style={{ justifyContent: 'end', gap: 16 }}>
          {!isClient && (
            <Button
              style={{
                width: '172px',
                height: '40px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'capitalize',
                background: '#FFFFFF',
                border: '1.5px solid #0869FB',
                color: '#0869FB',
                cursor: 'pointer'
              }}
              onClick={() => { 
                  setmodal(true) 
              }}
            >
              + Add New A/B Test
            </Button>
          )}
          <Button
            style={{
              width: '172px',
              height: '40px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
              textTransform: 'capitalize',
              background: '#0869FB',
              cursor: 'pointer'
            }}
            onClick={onConfirm}
          >
            Save & Proceed
          </Button>
        </div>
        {modal && <ABTestAddModal setModal={setmodal} handleAddNewTest={handleAddNewTest}/>}
        {confirm && <ConfirmDialogModal
                      formSubm={formSubm}
                      setFormSubm={setFormSubm}
                      publishHandler={handleProceed}
                      title='PROCEED'
                    />
        }
      </div>
    </>
  );
};

export default ABTesting;
